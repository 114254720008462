import React, { useEffect, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { Button, Container, Paper, Stack, Tooltip, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { isValid } from 'date-fns'

import { ContactInfo as ContactInfoData, ScenarioDetails } from '@common/dto'
import { ContactInfo } from './ContactInfo'
import { LoadingPage } from 'src/main/pages/LoadingPage'
import { ScenarioSubmittedAlert } from 'src/shared/components/Alerts'
import { NotOwnerAlert } from '@components/Alerts/NotOwnerAlert'
import { IndividualItemTableWithGrouping } from 'src/merchandising/pages/Scenarios/ScenarioPreparation/OverviewPage/IndividualItemTableWithGrouping'
import { useScenarioDetailsData } from 'src/merchandising/pages/Scenarios/ScenarioPreparation/ScenarioPreparation'
import {
  isBackendApiErrorResponse,
  isBackendValidationErrorResponse,
  useGetPreviewLinkMutation,
  useSubmitWithContactInfoMutation,
  useUpdateScenarioGroupsMutation,
} from 'src/merchandising/store/'
import { ScenarioGroup } from '@common/types/scenario'
import { MIN_TRUE_PRICE_VALUE } from '@common/constants'
import { MerchandisingRoutes } from 'src/merchandising/routes'
import { LumpSumsTable } from './LumpSumsTable'
import { isLocalMarketStage } from '@common/utils'
import { isEmailValue } from '@common/utils/validation'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { CustomDealsOverview } from './CustomDealsOverview'
import { OpenNegotiationButtons } from './OpenNegotiationButtons'

const defaultValues: ContactInfoData = {
  companyName: '',
  contactEmail: '',
  contactName: '',
  contactPhone: '',
  deadlineDate: null,
  localMarketGsamEmail: null,
}

export const OverviewPage = () => {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { activeOrgTag, activeProjectTag } = useActiveProject()

  const { detailsData, setDetailsData, sessionKey } = useScenarioDetailsData()
  const [contactInfo, setContactInfo] = useState<ContactInfoData>(defaultValues)

  // TODO this needs to be part of `ScenarioDetails`
  const allowUpdates = !detailsData.submittedAt && detailsData.isOwner

  const [updateScenarioGroups, { isLoading: isGroupsUpdateLoading }] =
    useUpdateScenarioGroupsMutation()
  const [submitWithContactInfo, { isLoading: isSubmitLoading }] = useSubmitWithContactInfoMutation()
  const [getPreviewLink, { isLoading: isPreviewLinkSubmitLoading }] = useGetPreviewLinkMutation()

  useEffect(() => {
    setContactInfo(detailsData.contactInfo ?? defaultValues)
  }, [setContactInfo, detailsData])

  const submitGroups = async (groups: ScenarioGroup[]) => {
    try {
      const response = await updateScenarioGroups({
        sessionKey,
        projectTag: activeProjectTag,
        payload: groups,
      }).unwrap()
      setDetailsData({
        ...detailsData,
        groups: response,
      })
    } catch (e) {
      let errorMessage = 'There was an error during scenario update'

      if (isBackendApiErrorResponse(e)) {
        errorMessage = e.data.message
      } else if (isBackendValidationErrorResponse(e)) {
        errorMessage = JSON.stringify(e.data.details)
      }

      enqueueSnackbar(errorMessage, { variant: 'error' })
    }
  }

  const getAndOpenNegotiationPreview = async () => {
    const validationError = findValidationError(contactInfo, detailsData)
    if (validationError) {
      enqueueSnackbar(validationError, { variant: 'error' })
      return
    }

    try {
      const link = await getPreviewLink({
        sessionKey,
        projectTag: activeProjectTag,
        payload: contactInfo,
      }).unwrap()

      window.open(link, '_blank')
    } catch (e) {
      let errorMessage = 'Failed to get negotiation preview'

      if (isBackendApiErrorResponse(e)) {
        errorMessage = e.data.message
      } else if (isBackendValidationErrorResponse(e)) {
        errorMessage = JSON.stringify(e.data.details)
      }

      enqueueSnackbar(errorMessage, { variant: 'error' })
    }
  }

  const submit = async () => {
    const validationError = findValidationError(contactInfo, detailsData)
    if (validationError) {
      enqueueSnackbar(validationError, { variant: 'error' })
      return
    }
    try {
      await submitWithContactInfo({
        sessionKey,
        projectTag: activeProjectTag,
        payload: contactInfo,
      }).unwrap()
      enqueueSnackbar('Scenario successfully submitted for negotiation', { variant: 'success' })
      navigate(
        generatePath(MerchandisingRoutes.SCENARIOS_LIST, {
          orgTag: activeOrgTag!,
          projectTag: activeProjectTag!,
        }),
      )
    } catch (e) {
      enqueueSnackbar(
        isBackendApiErrorResponse(e)
          ? e.data.message
          : 'There was an error during suppliers data submit',
        { variant: 'error' },
      )
    }
  }

  if (isSubmitLoading) {
    return <LoadingPage />
  }

  return (
    <Container maxWidth='xl' sx={{ p: 2 }}>
      <Stack spacing={2}>
        <Stack spacing={1}>
          {!detailsData.isOwner && <NotOwnerAlert />}
          {detailsData.submittedAt && <ScenarioSubmittedAlert />}
        </Stack>
        <Paper sx={{ p: 1 }}>
          <Stack spacing={2} sx={{ p: 2 }}>
            <Typography variant='h4' component='h2' sx={{ fontSize: '1.875rem' }}>
              Overview
            </Typography>
            <Typography variant='body1' mt={1}>
              The following table summarises the input for the autonomous negotiation.
            </Typography>
            <Typography variant='h5'>Individual items</Typography>
            <IndividualItemTableWithGrouping
              rows={detailsData.state.current.items.filter((item) => item.enabled)}
              allowUpdates={allowUpdates}
              groups={detailsData.groups}
              defaultValues={detailsData.defaultInputs}
              isGroupsUpdateLoading={isGroupsUpdateLoading}
              submitGroups={submitGroups}
            />
            <LumpSumsTable
              lumpSums={detailsData.userInputs.lump_sums}
              itemsInfo={detailsData.state.item_info}
              currency={detailsData.userInputs.targetCurrency || 'EUR'}
              title='Item-level Lump Sums'
            />
            {detailsData.userInputs.additionalLumpSums !== undefined && (
              <LumpSumsTable
                lumpSums={detailsData.userInputs.additionalLumpSums}
                itemsInfo={detailsData.state.item_info}
                currency={detailsData.userInputs.targetCurrency || 'EUR'}
                title='Additional Lump Sums'
              />
            )}
            <CustomDealsOverview deals={detailsData.userInputs.mandatoryDeals} />
          </Stack>
        </Paper>
        <ContactInfo
          contactInfo={contactInfo}
          disabled={!allowUpdates}
          setContactInfo={setContactInfo}
          isLocalMarketStage={isLocalMarketStage(detailsData)}
        />
        <Stack direction='row' justifyContent='flex-end' spacing={2} mt={2}>
          <Button
            variant='contained'
            color='primary'
            onClick={() =>
              navigate(
                generatePath(MerchandisingRoutes.SCENARIO_DETAILS, {
                  orgTag: activeOrgTag!,
                  projectTag: activeProjectTag!,
                  sessionKey,
                }),
              )
            }
          >
            Back
          </Button>

          {!detailsData.submittedAt && (
            <>
              <Button
                color='info'
                variant='contained'
                onClick={getAndOpenNegotiationPreview}
                disabled={isPreviewLinkSubmitLoading}
              >
                {isPreviewLinkSubmitLoading
                  ? 'Creating negotiation preview...'
                  : 'View negotiation preview'}
              </Button>
              <Tooltip
                placement='top'
                title='After you Submit the scenario it can no longer be modified.
            The values on this page will be used for the autonomous negotiation.'
              >
                <Button
                  color='secondary'
                  variant='contained'
                  onClick={submit}
                  disabled={!allowUpdates}
                >
                  Submit for negotiation
                </Button>
              </Tooltip>
            </>
          )}
          {detailsData.submittedAt && <OpenNegotiationButtons details={detailsData} />}
        </Stack>
      </Stack>
    </Container>
  )
}

function findValidationError(
  contactInfo: ContactInfoData,
  detailsData: ScenarioDetails,
): string | null {
  const enabledItems = detailsData.state.current.items.filter((item) => item.enabled)
  const itemInfo = detailsData.state.item_info
  if (contactInfo.companyName.length === 0) {
    return 'Please specify Supplier Company Name'
  } else if (contactInfo.contactName.length === 0) {
    return 'Please specify Contact Name'
  } else if (contactInfo.contactEmail.length === 0) {
    return 'Please specify Contact Email'
  } else if (contactInfo.contactPhone.length === 0) {
    return 'Please specify Contact Phone'
  } else if (!contactInfo.deadlineDate) {
    return 'Please specify Negotiation deadline date'
  } else if (!isValid(new Date(contactInfo.deadlineDate))) {
    return 'Negotiation deadline date is not valid'
  } else if (
    isLocalMarketStage(detailsData) &&
    (!contactInfo.localMarketGsamEmail || !isEmailValue(contactInfo.localMarketGsamEmail))
  ) {
    return 'Please specify a valid GSAM email'
  }
  for (const item of enabledItems) {
    if (item.volumes.target <= 0) {
      return (
        `${
          itemInfo[item.commercial_id].name
        } volume at Target Net Price is 0. Volume estimate at Target Net Price must be greater than 0. ` +
        'Please change Net Price-Volume model values of this item before submitting or disable this item.'
      )
    } else if (item.invoice_prices.target < MIN_TRUE_PRICE_VALUE) {
      return (
        `${itemInfo[item.commercial_id].name} Target Invoice Price is ${
          item.invoice_prices.target
        }. This value is too small, and likely an error. ` +
        'Please change Target Net Price of this item before submitting or disable this item.'
      )
    } else if (item.net_prices.target < MIN_TRUE_PRICE_VALUE) {
      return (
        `${itemInfo[item.commercial_id].name} Target Net Price is ${
          item.net_prices.target
        }. This value is too small, and likely an error. ` +
        'Please change Target Net Price of this item before submitting or disable this item.'
      )
    }
  }
  return null
}
