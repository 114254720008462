import { CommercialTermsNegotiation, Contract } from '@campaigns/types/negotiation'
import { FiltersList } from '@components/FiltersList'
import { Box, Typography, styled, useTheme } from '@mui/material'
import { useFormatter } from '@shared/hooks'
import { useState } from 'react'
import { sumNumbers } from '@common/utils'
import { CustomTermsConfiguration } from '@campaigns/types/custom-terms'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useGetProjectConfigQuery } from '../../store'

const DISPLAY_MODE_TOTAL = 'Total'
const DISPLAY_MODE_PERCENTAGE = 'Percentage'

type DisplayMode = typeof DISPLAY_MODE_TOTAL | typeof DISPLAY_MODE_PERCENTAGE

const DISPLAY_MODES = [DISPLAY_MODE_TOTAL, DISPLAY_MODE_PERCENTAGE]

interface Props {
  negotiation: CommercialTermsNegotiation
  currency: CommercialTermsNegotiation['currency']
}

export const SavingsBreakdown = ({ negotiation, currency }: Props) => {
  const formatter = useFormatter()
  const { activeProjectTag } = useActiveProject()

  const { data: projectConfig } = useGetProjectConfigQuery(activeProjectTag)
  const customTerms = projectConfig?.customTerms

  const [displayMode, setDisplayMode] = useState<DisplayMode>(DISPLAY_MODE_TOTAL)

  const totalSavingsCurrency = negotiation.achievedSavingsInCurrency ?? 0
  const totalSavingsRelative = negotiation.achievedSavingsPercent ?? 0

  const savingsItems = getSavingsItems(negotiation.contracts)

  return (
    <Box>
      <Box sx={{ pt: 2, pb: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant='body1' fontSize={'1rem'} fontWeight={400} lineHeight={'1.5rem'}>
            {displayMode === DISPLAY_MODE_TOTAL ? 'Total savings' : 'Savings relative to spend'}
          </Typography>
          <FiltersList
            options={DISPLAY_MODES}
            selectedOption={displayMode}
            onClick={(option) => setDisplayMode(option as DisplayMode)}
          />
        </Box>
        <Typography variant='h1' sx={{ pt: 1 }}>
          {displayMode === DISPLAY_MODE_TOTAL
            ? formatter.currency(totalSavingsCurrency, { currency: currency ?? undefined })
            : formatter.percent0To1(totalSavingsRelative / 100)}
        </Typography>
      </Box>
      <SavingsBar>
        {totalSavingsCurrency === 0 ? (
          <NoSavingsItem />
        ) : (
          <SavingsItems
            savingsItems={savingsItems}
            totalSavingsCurrency={totalSavingsCurrency}
            displayMode={displayMode}
            currency={currency}
          />
        )}
      </SavingsBar>
      <SavingsLegends savingsItems={savingsItems} customTermsConfiguration={customTerms} />
    </Box>
  )
}

const NoSavingsItem = () => {
  const theme = useTheme()

  return (
    <SavingsItem color={theme.palette.lightGrey.main} share={1}>
      No savings
    </SavingsItem>
  )
}

const SavingsItems = ({
  savingsItems,
  totalSavingsCurrency,
  displayMode,
  currency,
}: {
  savingsItems: SavingsItems
  totalSavingsCurrency: number
  displayMode: DisplayMode
  currency: CommercialTermsNegotiation['currency']
}) => {
  const theme = useTheme()
  const formatter = useFormatter()

  const totalAbsoluteStandardTermsSavings = [
    savingsItems.paymentTermsSavings,
    savingsItems.discountSavings,
    savingsItems.rebatesSavings,
    savingsItems.growthRebatesSavings,
  ]
    .flat()
    .map((savings) => (savings ? Math.abs(savings) : 0))
    .reduce((acc, savings) => acc + savings, 0)

  const totalCustomTermsSavings = Object.values(savingsItems.customTermsSavings)
    .flat()
    .map((savings) => (savings ? Math.abs(savings) : 0))
    .reduce((acc, savings) => acc + savings, 0)

  const totalAbsoluteSavings = totalAbsoluteStandardTermsSavings + totalCustomTermsSavings

  const currencyOrPercentage = (savings: number) =>
    displayMode === DISPLAY_MODE_TOTAL
      ? formatter.currency(savings, { currency: currency ?? undefined })
      : formatter.percent0To1(savings / totalSavingsCurrency)

  const sumPaymentTermsSavings =
    savingsItems.paymentTermsSavings.length > 0
      ? sumNumbers(savingsItems.paymentTermsSavings)
      : undefined
  const sumDiscountSavings =
    savingsItems.discountSavings.length > 0 ? sumNumbers(savingsItems.discountSavings) : undefined
  const sumRebatesSavings =
    savingsItems.rebatesSavings.length > 0 ? sumNumbers(savingsItems.rebatesSavings) : undefined
  const sumGrowthRebatesSavings =
    savingsItems.growthRebatesSavings.length > 0
      ? sumNumbers(savingsItems.growthRebatesSavings)
      : undefined

  const customTermsColors = [
    theme.palette.common.charts.fourth,
    theme.palette.common.charts.fifth,
    theme.palette.common.charts.sixth,
    theme.palette.common.charts.seventh,
    theme.palette.common.charts.eight,
  ]

  return (
    <>
      {sumPaymentTermsSavings && (
        <SavingsItem
          color={theme.palette.common.charts.first}
          share={sumPaymentTermsSavings / totalAbsoluteSavings}
        >
          {currencyOrPercentage(sumPaymentTermsSavings)}
        </SavingsItem>
      )}
      {sumDiscountSavings && (
        <SavingsItem
          color={theme.palette.common.charts.second}
          share={sumDiscountSavings / totalAbsoluteSavings}
        >
          {currencyOrPercentage(sumDiscountSavings)}
        </SavingsItem>
      )}
      {sumRebatesSavings && (
        <SavingsItem
          color={theme.palette.common.charts.third}
          share={sumRebatesSavings / totalAbsoluteSavings}
        >
          {currencyOrPercentage(sumRebatesSavings)}
        </SavingsItem>
      )}
      {sumGrowthRebatesSavings && (
        <SavingsItem
          color={theme.palette.common.charts.second}
          share={sumGrowthRebatesSavings / totalAbsoluteSavings}
        >
          {currencyOrPercentage(sumGrowthRebatesSavings)}
        </SavingsItem>
      )}
      {Object.entries(savingsItems.customTermsSavings).map(([key, value], index) => (
        <SavingsItem
          key={key}
          color={customTermsColors[index % customTermsColors.length]}
          share={sumNumbers(value) / totalAbsoluteSavings}
        >
          {currencyOrPercentage(sumNumbers(value))}
        </SavingsItem>
      ))}
    </>
  )
}

const SavingsLegends = ({
  savingsItems,
  customTermsConfiguration,
}: {
  savingsItems: SavingsItems
  customTermsConfiguration?: CustomTermsConfiguration
}) => {
  const theme = useTheme()

  const {
    paymentTermsSavings,
    discountSavings,
    rebatesSavings,
    growthRebatesSavings,
    customTermsSavings,
  } = savingsItems

  const customTermsLegendColors = [
    theme.palette.common.charts.fourth,
    theme.palette.common.charts.fifth,
    theme.palette.common.charts.sixth,
    theme.palette.common.charts.seventh,
    theme.palette.common.charts.eight,
  ]

  return (
    <Box sx={{ display: 'flex', marginTop: 1 }}>
      {paymentTermsSavings.length > 0 && (
        <SavingsLegendContainer>
          <SavingsLegendDot color={theme.palette.common.charts.first} /> Payment terms
        </SavingsLegendContainer>
      )}
      {discountSavings.length > 0 && (
        <SavingsLegendContainer>
          <SavingsLegendDot color={theme.palette.common.charts.second} /> Discounts
        </SavingsLegendContainer>
      )}
      {rebatesSavings.length > 0 && (
        <SavingsLegendContainer>
          <SavingsLegendDot color={theme.palette.common.charts.third} /> Rebates
        </SavingsLegendContainer>
      )}
      {growthRebatesSavings.length > 0 && (
        <SavingsLegendContainer>
          <SavingsLegendDot color={theme.palette.common.charts.second} /> Growth rebates
        </SavingsLegendContainer>
      )}
      {Object.keys(customTermsSavings).map((key, index) => (
        <SavingsLegendContainer key={key}>
          <SavingsLegendDot
            color={customTermsLegendColors[index % customTermsLegendColors.length]}
          />{' '}
          {customTermsConfiguration?.[key]?.label ?? key}
        </SavingsLegendContainer>
      ))}
    </Box>
  )
}

interface SavingsItems {
  paymentTermsSavings: number[]
  discountSavings: number[]
  rebatesSavings: number[]
  growthRebatesSavings: number[]
  customTermsSavings: Record<string, number[]>
}

const getSavingsItems = (contracts: Contract[]): SavingsItems => {
  const paymentTermsSavings = contracts
    .map(
      (contract) =>
        (contract.standardTerms?.paymentDays?.savings?.savingsInCurrency ?? 0) +
        (contract.standardTerms?.earlyPaymentDiscount?.savings?.savingsInCurrency ?? 0),
    )
    .filter((savings) => savings) as number[]

  const discountSavings = contracts
    .map((contract) => contract.standardTerms?.generalDiscount?.savings?.savingsInCurrency)
    .filter((savings) => savings) as number[]

  const rebatesSavings = contracts
    .map((contract) => contract.standardTerms?.flatRebate?.savings?.savingsInCurrency)
    .filter((savings) => savings) as number[]

  const growthRebatesSavings = contracts
    .map((contract) => contract.standardTerms?.growthRebateStructure?.savings?.savingsInCurrency)
    .filter((savings) => savings) as number[]

  const customTermsSavingsFlattened = contracts
    .flatMap((contract) => Object.entries(contract.customTerms ?? {}))
    .map(([key, term]) => [key, term.savings?.savingsInCurrency])
    .filter(([_, savings]) => savings) as [string, number][]

  const customTermsSavingsGroupedByTerm = customTermsSavingsFlattened.reduce(
    (acc, [key, savings]) => {
      const current = acc[key] ?? []
      current.push(savings)
      acc[key] = current
      return acc
    },
    {} as Record<string, number[]>,
  )

  return {
    paymentTermsSavings,
    discountSavings,
    rebatesSavings,
    growthRebatesSavings,
    customTermsSavings: customTermsSavingsGroupedByTerm,
  }
}

const SavingsLegendContainer = styled(Box)(({ theme }) => ({
  paddingRight: theme.spacing(2),
  fontSize: '0.875rem',
}))

const SavingsLegendDot = styled(Box)<{ color: string }>(({ color }) => ({
  height: '10px',
  width: '10px',
  borderRadius: '100%',
  backgroundColor: color,
  display: 'inline-block',
}))

const SavingsBar = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  borderRadius: theme.shape.borderRadius,
  height: '36px',
  '*:first-child': {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
  },
  '*:last-child': {
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
}))

const SavingsItem = styled(Box)<{ color: string; share: number }>(({ theme, color, share }) => ({
  backgroundColor: color,
  fontWeight: 'bold',
  color: 'white',
  paddingLeft: theme.spacing(2),
  height: '100%',
  lineHeight: '36px',
  width: `${Math.abs(share) * 100}%`,
  minWidth: '20%',
}))
