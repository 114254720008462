import { User } from '@auth0/auth0-spa-js'
import { Organization } from '@pactum/core-backend-types'

export enum AuthenticationStatus {
  LOADING_USER = 'LOADING_USER',
  USER_NOT_LOGGED_IN = 'USER_NOT_LOGGED_IN',
  USER_LOADING_ORGS = 'USER_LOADING_ORGS',
  USER_LOGIN_FINISHED = 'USER_LOGIN_FINISHED',
  ERROR_AUTHENTICATING = 'ERROR_AUTHENTICATING',
  ERROR_NO_ORGS_AVAILABLE = 'ERROR_NO_ORGS_AVAILABLE',
}

export const getAuthenticationStatus = (
  isUserLoaded: boolean,
  user: User | null,
  organizations: Organization[] | undefined,
  error: string | null,
): AuthenticationStatus | null => {
  if (error) {
    return AuthenticationStatus.ERROR_AUTHENTICATING
  }

  if (!isUserLoaded) {
    return AuthenticationStatus.LOADING_USER
  }

  if (isUserLoaded && !user) {
    return AuthenticationStatus.USER_NOT_LOGGED_IN
  }

  if (isUserLoaded && user && !organizations) {
    return AuthenticationStatus.USER_LOADING_ORGS
  }

  if (isUserLoaded && user && organizations?.length === 0) {
    return AuthenticationStatus.ERROR_NO_ORGS_AVAILABLE
  }

  if (isUserLoaded && user) {
    return AuthenticationStatus.USER_LOGIN_FINISHED
  }

  return null
}
