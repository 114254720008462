import { CurrencyCode, ISO3CountryCode } from '@pactum/common'
import { createContext, useCallback, useContext, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

export type QueryParamsValue = string | string[] | null | undefined

interface DashboardQueryFilterActions<T> {
  set(key: keyof T, value: QueryParamsValue): void
}

interface DashboardQueryFilter<T> extends DashboardQueryFilterActions<T> {
  values: T
}

export interface DashboardQueryParams {
  countries: ISO3CountryCode[]
  regions: string[]
  categories1: string[]
  categories2: string[]
  businessUnits: string[]
  currency: CurrencyCode
  queryVariant: string
  excludeNonnegotiableSpend?: string
}

const DashboardQueryParamsContext = createContext<unknown>(null)

export const DashboardFiltersProvider = DashboardQueryParamsContext.Provider

export const useFilters = () =>
  useContext(DashboardQueryParamsContext) as DashboardQueryParams &
    DashboardQueryFilterActions<DashboardQueryParams>

export const useDashboardFilters = <T extends DashboardQueryParams>(
  initial: T = {} as T,
): DashboardQueryFilter<T> => {
  const [searchParams, setSearchParams] = useSearchParams()
  const computed = useCallback(() => {
    const params: Record<string, unknown> = {}

    Object.keys(initial).forEach((k) => {
      const v = searchParams.get(k) as string

      // console.log(k, typeof initial[k as keyof T])

      if (typeof initial[k as keyof T] === 'string') {
        params[k] = !v ? initial[k as keyof T] : v
      }

      if (typeof initial[k as keyof T] === 'object' && Array.isArray(initial[k as keyof T])) {
        params[k] = !v ? initial[k as keyof T] : v.split(',')
      }
    })

    return params
  }, [initial, searchParams])

  const setParams = useCallback(
    (key: keyof T, value: string | string[] | null | undefined) => {
      return setSearchParams((params) => {
        if (value?.length === 0 || !value) {
          params.delete(key as string)

          return params
        }

        params.set(key as string, typeof value === 'string' ? value : value?.join(','))

        return params
      })
    },
    [setSearchParams],
  )

  return useMemo(
    () => ({
      values: computed() as T,
      set: setParams,
    }),
    [computed, setParams],
  )
}

export const DashboarFiltersDebug = () => {
  const f = useFilters()

  return (
    <>
      <button onClick={() => f.set('countries', [ISO3CountryCode.AFG])}>set countries</button>
      <pre>{JSON.stringify(f, null, 2)}</pre>
    </>
  )
}
