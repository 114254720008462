import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import { ExpandableSection } from 'src/shared/components/ExpandableSection'
import { DetailedCostModel } from './DetailedCostModel'
import { SupplierCommoditiesReflections } from './SupplierCommoditiesReflections'
import { useGetNegotiationPreparationQuery } from 'src/merchandising/store/'

export const CostModelInsights = (): JSX.Element | null => {
  const { negotiationPreparationId } = useParams<{ negotiationPreparationId: string }>()
  const { data } = useGetNegotiationPreparationQuery(negotiationPreparationId!, {
    skip: !negotiationPreparationId,
  })

  if (!data) {
    return null
  }

  const { supplierCommodityReflections, detailedCostModel } = data.analysis

  const anyArticleCommoditiesNeedsToBeShownInReflections = (): boolean => {
    if (!supplierCommodityReflections) {
      return false
    }
    return Object.values(supplierCommodityReflections)
      .flat()
      .map((commodityReflection) => commodityReflection.showInReflections ?? false)
      .reduce((acc, showInReflection) => acc || showInReflection)
  }

  if (
    (!supplierCommodityReflections || !anyArticleCommoditiesNeedsToBeShownInReflections()) &&
    !detailedCostModel
  ) {
    console.warn('No Cost Model Insights data')
    return null
  }

  return (
    <Box px={6} pb={4}>
      <ExpandableSection
        title='Cost Model Insights'
        description='The following table shows cost model insights from different sources.'
      >
        <SupplierCommoditiesReflections />
        <DetailedCostModel />
      </ExpandableSection>
    </Box>
  )
}
