import { DialogActions } from '@mui/material'
import { PropsWithChildren } from 'react'

/**
 * Footer to be used in large dialogs
 * - has less padding
 * - aligns buttons to the right
 */
export const PactumLargeDialogFooter = ({ children }: PropsWithChildren<{}>) => {
  return <DialogActions sx={{ px: 4, pt: 2, pb: 2 }}>{children}</DialogActions>
}

/**
 * Footer to be used in small dialogs
 * - has more padding
 * - aligns buttons to the center
 */
export const PactumSmallDialogFooter = ({ children }: PropsWithChildren<{}>) => {
  return (
    <DialogActions sx={{ px: 5.5, pt: 2, pb: 4.5, justifyContent: 'center' }}>
      {children}
    </DialogActions>
  )
}
