import { CheckboxCtrl, SelectCtrl } from '@components/Form'
import { MultiSelectCtrl } from '../../MultiSelectCtrl/MultiSelectCtrl'
import { FormRow, HideableBlock, createGetFieldControllerParams } from '../../FormCommon/FormCommon'
import { Grid, Typography } from '@mui/material'
import { PurchasingUIConfig } from '../../../store/types'
import { useFormContext } from 'react-hook-form'
import { useContext } from 'react'
import { NegotiationEventFormContext } from '../NegotiationEventFormContext'
import { NegotiationEventFormData } from '../schema'

interface Props {
  allowedIncoterms?: PurchasingUIConfig['suite']['allowedIncoterms']
}

export const Incoterms = ({ allowedIncoterms }: Props) => {
  const { watch } = useFormContext<NegotiationEventFormData>()
  const { disabledFields, requiredFields, visibleFields } = useContext(NegotiationEventFormContext)
  const getFieldControllerParams = createGetFieldControllerParams({
    requiredFields,
    disabledFields,
  })
  const contractIncotermsChecked = watch('contractIncotermsEnabled')

  return (
    <HideableBlock hidden={!visibleFields.includes('contractIncoterms')}>
      <FormRow>
        <Grid item xs={12}>
          <CheckboxCtrl
            labelProps={{ fontWeight: 700 }}
            {...getFieldControllerParams('contractIncotermsEnabled', 'Incoterms')}
          />
        </Grid>
        <Grid item xs={12} pb={1} mt={-1}>
          <Typography variant='body2'>
            If this section is enabled, incoterms are negotiated once for all the items at the same
            time.
          </Typography>
        </Grid>
      </FormRow>
      <HideableBlock hidden={!contractIncotermsChecked}>
        <FormRow>
          <Grid item xs={6} sx={{ mt: 0.5 }}>
            <SelectCtrl
              options={allowedIncoterms ?? []}
              fullWidth
              label={'Current Incoterm'}
              name={'suppliers.0.negotiationSettings.contractIncoterms.benchmark'}
              helperText={'This is used to calculate the best negotiation results'}
            />
          </Grid>
          <Grid item xs={6} sx={{ mt: 0.5 }}>
            <MultiSelectCtrl
              options={allowedIncoterms ?? []}
              fullWidth
              label={'Acceptable Incoterms'}
              name={'suppliers.0.negotiationSettings.contractIncoterms.acceptable'}
              helperText={'Select only the incoterms that are acceptable'}
            />
          </Grid>
        </FormRow>
      </HideableBlock>
    </HideableBlock>
  )
}
