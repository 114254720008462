import React, { useState } from 'react'
import { Grid, Paper, Stack, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers-pro'
import { format } from 'date-fns'
import { ContactInfo as ContactInfoData } from '@common/dto'
import { isEmailValue } from '@common/utils/validation'
import { GSAMContactInfo } from './GSAMContactInfo'
import { InputError } from 'src/merchandising/types'

type FieldErrors = Record<keyof ContactInfoData, InputError>

interface Props {
  contactInfo: ContactInfoData
  disabled: boolean
  isLocalMarketStage: boolean
  setContactInfo: (contactInfo: ContactInfoData) => void
}

export const ContactInfo = ({
  contactInfo,
  disabled,
  isLocalMarketStage,
  setContactInfo,
}: Props) => {
  const [errors, setErrors] = useState<FieldErrors>(getInitialNoErrorState(contactInfo))

  const onChange = (value: string, field: keyof ContactInfoData) => {
    checkAndSetErrors(value, field)
    setContactInfo({ ...contactInfo, [field]: value })
  }

  const checkAndSetErrors = (value: string, field: keyof ContactInfoData) => {
    if (['companyName', 'contactName', 'contactPhone'].includes(field)) {
      const isError = !value
      const error = isError ? 'This field is required' : undefined
      setErrors({ ...errors, [field]: { isError, error } })
    } else if (field === 'contactEmail' || field === 'localMarketGsamEmail') {
      const isError = !value || !isEmailValue(value)
      const error = isError ? 'Please enter a valid email address' : undefined
      setErrors({ ...errors, [field]: { isError, error } })
    }
  }

  return (
    <Paper sx={{ p: 1 }}>
      <Stack spacing={2} sx={{ p: 2 }}>
        <Typography variant='h4' component='h2' sx={{ fontSize: '1.875rem' }}>
          Contact Information
        </Typography>
        <Typography variant='body1' mt={1}>
          Please fill in all the fields to ensure that the negotiation will have all sufficient
          information.
        </Typography>

        <Typography variant='h5'>Supplier information</Typography>

        <Grid container rowGap={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label='Supplier Company Name'
                disabled={disabled}
                error={errors['companyName'].isError}
                value={contactInfo.companyName}
                helperText={errors['companyName'].isError ? errors['companyName'].error : null}
                onChange={(e) => onChange(e.target.value, 'companyName')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                label='Negotiation deadline'
                disablePast
                disabled={disabled}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: errors['deadlineDate'].isError,
                    inputProps: { readOnly: true },
                  },
                }}
                value={contactInfo.deadlineDate ? new Date(contactInfo.deadlineDate) : null}
                onChange={(newValue) =>
                  setContactInfo({
                    ...contactInfo,
                    deadlineDate: newValue ? format(new Date(newValue), 'yyyy-MM-dd') : null,
                  })
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                label='Contact Name'
                disabled={disabled}
                error={errors['contactName'].isError}
                helperText={errors['contactName'].isError ? errors['contactName'].error : null}
                value={contactInfo.contactName}
                onChange={(e) => onChange(e.target.value, 'contactName')}
                inputProps={{ inputMode: 'text' }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                label='Contact Email'
                disabled={disabled}
                error={errors['contactEmail'].isError}
                value={contactInfo.contactEmail}
                onChange={(e) => onChange(e.target.value, 'contactEmail')}
                inputProps={{ inputMode: 'email' }}
                helperText={errors['contactEmail'].isError ? errors['contactEmail'].error : null}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                fullWidth
                label='Contact Phone'
                disabled={disabled}
                error={errors['contactPhone'].isError}
                value={contactInfo.contactPhone}
                onChange={(e) => onChange(e.target.value, 'contactPhone')}
                inputProps={{ inputMode: 'tel' }}
                helperText={errors['contactPhone'].isError ? errors['contactPhone'].error : null}
              />
            </Grid>
          </Grid>
        </Grid>
        {isLocalMarketStage && (
          <GSAMContactInfo
            disabled={disabled}
            inputError={errors['localMarketGsamEmail']}
            value={contactInfo.localMarketGsamEmail}
            onChange={(value) => onChange(value, 'localMarketGsamEmail')}
          />
        )}
      </Stack>
    </Paper>
  )
}

const getInitialNoErrorState = (contactInfo: ContactInfoData) =>
  Object.keys(contactInfo).reduce(
    (acc, key) => ({ ...acc, [key]: { isError: false } }),
    {} as FieldErrors,
  )
