import { User } from '@auth0/auth0-spa-js'
import { useEffect, useState } from 'react'
import { getAuth0Client, startAuthentication } from './useAuthInternals'

export const useUser = () => {
  const [user, setUser] = useState<User | null>(null)

  useEffect(() => {
    const getUser = async () => {
      const client = await getAuth0Client()
      const fetchedUser = await client?.getUser()
      if (fetchedUser === null) {
        await startAuthentication()
      }
      setUser(fetchedUser ?? null)
    }

    getUser()
  }, [])

  return { user }
}
