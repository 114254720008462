import { Box, styled } from '@mui/material'

export const SettingsContainer = styled(Box)`
  display: flex;
  align-items: start;
`

export const ContentContainer = styled('div')`
  width: 100%;
`

export const BrandingContainer = styled('div')(() => ({}))

export const BrandingInnerContainer = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(6),
  marginRight: theme.spacing(6),
}))
