import { CompetitorsPrices, NamedPrice } from '@common/types'
import { ascendingOrderBy } from '@common/utils'

export function getPriceFor(
  name: NamedPrice['name'],
  competitorsPrices?: CompetitorsPrices['competitors'],
): CompetitorsPrices['competitors'][number]['price'] | undefined {
  return competitorsPrices?.find((price) => price.name === name)?.price
}

export function getPriceIndex(
  prices?: CompetitorsPrices['competitors'],
): CompetitorsPrices['competitors'][number]['price'] | undefined {
  return prices?.slice().sort(ascendingOrderBy('priority')).find(Boolean)?.price
}
