import React, { useState } from 'react'
import { Box, Button, Grid, Stack, Tooltip, Typography } from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import AddIcon from '@mui/icons-material/Add'
import { useSnackbar } from 'notistack'
import { PrimaryCustomDeal } from '@common/types'
import { UpdateScenarioRequest } from '@common/dto'
import { useScenarioDetailsData } from 'src/merchandising/pages/Scenarios/ScenarioPreparation/ScenarioPreparation'
import { isBackendApiErrorResponse, useUpdateScenarioInputsMutation } from 'src/merchandising/store'
import { isAlternativeDealValid, isTitleValid } from './validations'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { CustomDealRow } from './CustomDealRow'
import { ITEM_UPDATE_DEBOUNCE_TIMEOUT } from '../../../../constants'
import { useDebounce } from '@hooks'

interface Props {
  onDealsValidated: (lumpSumsValid: boolean) => void
}

export const CustomDeals = ({ onDealsValidated }: Props) => {
  const { activeProjectTag } = useActiveProject()
  const { detailsData, setDetailsData } = useScenarioDetailsData()

  const [deals, setDeals] = useState<PrimaryCustomDeal[]>(
    detailsData.userInputs.mandatoryDeals ?? [],
  )
  const [triggerRowUpdate] = useUpdateScenarioInputsMutation()

  const { enqueueSnackbar } = useSnackbar()

  const handleChange = (dealUpdateIndex: number, updatedDeal: PrimaryCustomDeal) => {
    const updatedDeals = deals.map((deal, index) =>
      index === dealUpdateIndex ? updatedDeal : deal,
    )

    const allDealsValid = validate(updatedDeals)
    setDeals(updatedDeals)

    if (allDealsValid) {
      saveDeals(updatedDeals)
    }
  }

  const handleDelete = (deletedDealIndex: number) => {
    const dealsWithoutDeletedOne = deals.filter((_, index) => index !== deletedDealIndex)

    const allDealsValid = validate(dealsWithoutDeletedOne)

    setDeals(dealsWithoutDeletedOne)

    if (allDealsValid) {
      saveDeals(dealsWithoutDeletedOne)
    }
  }

  const addDeal = () => setDeals([...deals, getEmptyDeal()])

  const validate = (deals: PrimaryCustomDeal[]) => {
    const areDealsValid = deals.every((deal) => isDealValid(deal))

    onDealsValidated(areDealsValid)

    return areDealsValid
  }

  const saveDeals = useDebounce(async (deals: PrimaryCustomDeal[]) => {
    const payload: UpdateScenarioRequest = {
      userInputs: {
        ...detailsData.userInputs,
        mandatoryDeals: deals,
      },
    }

    try {
      const updatedScenarioData = await triggerRowUpdate({
        sessionKey: detailsData.sessionKey,
        projectTag: activeProjectTag,
        payload,
      }).unwrap()

      setDetailsData({
        ...detailsData,
        userInputs: payload.userInputs,
        state: { ...detailsData.state, ...updatedScenarioData.state },
      })
    } catch (e) {
      enqueueSnackbar(
        isBackendApiErrorResponse(e) ? e.data.message : 'There was an error during scenario update',
        { variant: 'error' },
      )
    }
  }, ITEM_UPDATE_DEBOUNCE_TIMEOUT)

  return (
    <Box>
      <Button startIcon={<AddIcon />} onClick={addDeal}>
        New entry
      </Button>
      <Grid container spacing={2} pt={2}>
        <Grid item xs={6}>
          <Typography variant='h2'>Main deal</Typography>
        </Grid>
        <Grid item xs={6}>
          <Stack direction='row'>
            <Typography variant='h2'>Alternative deal</Typography>
            <Tooltip title='In case the main deal is not accepted, an alternative one will be presented in the final offer.'>
              <HelpIcon sx={{ ml: 0.5, mt: 0.5, fontSize: 'inherit' }} />
            </Tooltip>
          </Stack>
        </Grid>
        {deals.map((deal, index) => (
          <CustomDealRow
            key={index}
            deal={deal}
            onChange={(updatedLumpSum) => handleChange(index, updatedLumpSum)}
            onDelete={() => handleDelete(index)}
          />
        ))}
      </Grid>
    </Box>
  )
}

const getEmptyDeal = (): PrimaryCustomDeal => ({
  title: '',
})

const isDealValid = (deal: PrimaryCustomDeal) =>
  isTitleValid(deal.title) && isAlternativeDealValid(deal)
