import { NotAvailableCell } from 'src/shared/components/table'
import { useFormatter } from '@shared/hooks'
import { getPriceChangeDirectionColor } from 'src/merchandising/pages/ChangeRequests/NegotiationPreparation/Adjustments/utils'
import { PriceChangeDirectionIcon } from 'src/merchandising/pages/ChangeRequests/NegotiationPreparation/Adjustments/PriceChangeDirectionIcon'
import { Typography } from '@mui/material'

interface PriceProps {
  currentPrice?: number
  previousPrice?: number
}

export const NamedPriceWithChangeCell = ({
  currentPrice,
  previousPrice,
}: PriceProps): JSX.Element => {
  const formatter = useFormatter()
  if (!currentPrice) {
    return <NotAvailableCell />
  }

  if (currentPrice && previousPrice) {
    const proportionalChange = currentPrice / previousPrice - 1
    const color = getPriceChangeDirectionColor(proportionalChange)
    return (
      <Typography variant='body2' component='span'>
        {formatter.currency(currentPrice)}
        <Typography variant='body2' component='span' color={color}>
          {` (`}
          <PriceChangeDirectionIcon proportionalChange={proportionalChange} color={color} />
          {`${formatter.percent0To1(proportionalChange)})`}
        </Typography>
      </Typography>
    )
  }

  return (
    <Typography variant='body2' component='span'>
      {formatter.currency(currentPrice)}
    </Typography>
  )
}
