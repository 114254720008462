import { Box, Stack, styled } from '@mui/material'
import React from 'react'
import { sumNumbers } from '@common/utils'

const barColors = [
  //Generated by Copilot
  '#0B8E18', // original green from Figma
  '#1F77B4', // blue
  '#FF7F0E', // orange
  '#D62728', // red
  '#9467BD', // purple
  '#8C564B', // brown
  '#E377C2', // pink
  '#7F7F7F', // gray
  '#BCBD22', // yellow-green
  '#17BECF', // cyan
]
interface Props {
  segments: Segment[]
  format?: (n: number) => string
}

export interface Segment {
  value: number
  label: string
  color?: string
}

const defaultFormat = (n: number) => '' + n

const barHeight = 32

/*
Renders a simple stacked bar chart, consisting of segments representing value + label
of a figure. Arguably using chart.js with some stacked bar chart is also an option,
however chart.js seemed not to be able to be set the desired height (it was 75px
minimum, no matter what we set in `<Chart>` height attribute), plus it wasn't
able to display labels on the segments, thus a custom solution.
* */
export const StackedBarChart = ({ segments, format = defaultFormat }: Props) => {
  if (!segments.length) {
    return null
  }
  const sum = sumNumbers(segments.map(({ value }) => Math.abs(value)))
  const chooseColor = (index: number) => barColors[index % barColors.length]
  return (
    <>
      <BarContainer>
        {segments.map((segment, index) => {
          const displayValue = format(segment.value)
          const title = `${segment.label}: ${displayValue}`

          return (
            <Bar
              key={title}
              title={title}
              width={Math.abs((segment.value / sum) * 100) + '%'}
              sx={{ background: segment.color ?? chooseColor(index) }}
            >
              {displayValue}
            </Bar>
          )
        })}
      </BarContainer>
      <Stack direction='row' flexWrap='wrap' gap={1.5}>
        {segments.map((segment, index) => (
          <LegendItem key={`${segment.label}-${segment.value}`}>
            <LegendCircle sx={{ backgroundColor: segment.color ?? chooseColor(index) }} />
            {segment.label}
          </LegendItem>
        ))}
      </Stack>
    </>
  )
}

const BarContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  height: barHeight,
  borderRadius: 4,
  overflow: 'hidden',
  alignItems: 'stretch',
}))

const Bar = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  color: '#FFF',
  fontWeight: 'bold',
  lineHeight: `${barHeight}px`,
  fontSize: 14,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  padding: `0 ${theme.spacing(1)}`,
}))

const LegendItem = styled(Stack)(({ theme }) => ({
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(0.5),
}))

const LegendCircle = styled(Box)(() => ({
  width: 8,
  height: 8,
  borderRadius: 4,
}))
