import React, { useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { Box, Paper, Stack, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { ScenarioTable } from './ScenarioTable'
import {
  isBackendApiErrorResponse,
  useCreateScenarioMutation,
  useGetScenariosQuery,
} from 'src/merchandising/store/'
import { MarketSelectionDialog } from './MarketSelectionDialog'
import { Market } from 'src/merchandising/pages/Scenarios/ScenariosList/types'
import { ValidationError } from 'src/merchandising/pages/Scenarios/ScenariosList/ValidationError'
import { MerchandisingRoutes } from '../../../routes'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { readAndValidateMarketsInScope } from './excel/utils'
import { ExcelDataFileUpload } from '../../../components/ExcelDataFileUpload'
import { fileToFormData } from '../../../../main/utils/file'
import { ProtectedComponent } from '@components/ProtectedComponent/ProtectedComponent'
import { SuitePermission } from '@common/types'

export const ScenariosList = () => {
  const { activeOrgTag, activeProjectTag } = useActiveProject()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const { isLoading: isListLoading, data: scenarioListData } =
    useGetScenariosQuery(activeProjectTag)
  const [createScenario, { isLoading: isCreateLoading }] = useCreateScenarioMutation()
  const [showMarketSelectionDialog, setShowMarketSelectionDialog] = useState(false)
  const [uploadedFile, setUploadedFile] = useState<Blob | null>(null)
  const [markets, setMarkets] = useState<Market[]>([])

  const readAndValidateExcel = (file: Blob) => {
    readAndValidateMarketsInScope(file)
      .then((marketsInScope) => {
        setMarkets(marketsInScope)
        setUploadedFile(file)
        setShowMarketSelectionDialog(true)
      })
      .catch((e: ValidationError | Error) => {
        if (e.name === 'ValidationError') {
          enqueueSnackbar(`There was an error reading the uploaded file: ${e.message}`, {
            variant: 'error',
          })
        } else {
          enqueueSnackbar(`Unexpected error. Please contact technical support.`, {
            variant: 'error',
          })
          throw e
        }
      })
  }

  const onMarketSelected = async (file: Blob | null, marketId: Market['id'] | null) => {
    setShowMarketSelectionDialog(false)
    if (file) {
      const formData = fileToFormData(file)
      if (marketId !== null) {
        formData.append('marketId', marketId)
      }
      try {
        const { sessionKey } = await createScenario({
          data: formData,
          projectTag: activeProjectTag,
        }).unwrap()
        navigate(
          generatePath(MerchandisingRoutes.SCENARIO_DETAILS, {
            orgTag: activeOrgTag!,
            projectTag: activeProjectTag!,
            sessionKey,
          }),
        )
      } catch (e) {
        enqueueSnackbar(
          isBackendApiErrorResponse(e)
            ? e.data.message
            : 'There was an error when submitting new scenario',
          { variant: 'error' },
        )
      }
    } else {
      enqueueSnackbar('Unable to read the uploaded file', { variant: 'error' })
    }
  }

  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <Stack spacing={2}>
        <ProtectedComponent requiredPerm={SuitePermission.MERCHANDISING_SCENARIO_MANAGEMENT}>
          <ExcelDataFileUpload fileCallback={readAndValidateExcel} isLoading={isCreateLoading} />
        </ProtectedComponent>
        <MarketSelectionDialog
          isOpen={showMarketSelectionDialog}
          markets={markets}
          onMarketSelected={(selectedMarketId) => onMarketSelected(uploadedFile, selectedMarketId)}
          onClose={() => setShowMarketSelectionDialog(false)}
        />
        <Paper sx={{ p: 1 }}>
          <Stack spacing={2} sx={{ p: 2 }}>
            <Typography variant='h4' component='h2' sx={{ fontSize: '1.875rem' }}>
              Negotiation Scenarios
            </Typography>
            <Typography variant='body1' mt={1}>
              A list of scenarios you own or have uploaded before.
            </Typography>
            <ScenarioTable data={scenarioListData ?? []} isLoading={isListLoading} />
          </Stack>
        </Paper>
      </Stack>
    </Box>
  )
}
