import { Organization, ProjectListEntity } from '@pactum/core-backend-types'
import { createApi } from '@reduxjs/toolkit/query/react'
import { FunctionComponent } from 'react'
import { ProjectTag, SuitePermission } from '@common/types'

export type SuiteModule = GenericSuiteModule | MerchandisingSuiteModule

export type GenericSuiteModule = {
  name: string
  api: ReturnType<typeof createApi>
  entrypoint: FunctionComponent
  basePath: string
  projectSwitchPath: (
    userPermissions?: Set<SuitePermission>,
  ) => `${string}:orgTag${string}:projectTag${string}`
  isProjectCompatible: (project: ProjectListEntity) => boolean
  navigationItems: NavigationItem[]
}

export type NavigationItem = {
  name: string
  icon: () => JSX.Element
  path:
    | string
    | ((activeOrgTag: Organization['tag'], activeProjectTag: ProjectTag | null) => string)
  isExternal?: boolean
  requiredPermission: SuitePermission
}

export type MerchandisingSuiteModule = {
  name: 'merchandising'
  basePath: string
  api: ReturnType<typeof createApi>
  entrypoint: FunctionComponent
  navigationItems: []
}

export const isGenericSuiteModule = (module: SuiteModule): module is GenericSuiteModule =>
  module.name !== 'merchandising'
