import { createContext, useContext } from 'react'

type DashboardRangeProps = {
  range: {
    start: Date | null
    end: Date | null
  }
}

const DashboardRangeContext = createContext<DashboardRangeProps>({
  range: {
    start: null,
    end: null,
  },
})

export const DashboardRangeProvider = DashboardRangeContext.Provider

export const useDashboardRange = () => useContext(DashboardRangeContext)
