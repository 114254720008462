import { NegotiationEvent, NegotiationStatus, NegotiationEventSupplier } from '../../store/types'
import { TermGrid, TermItem } from '@procurement-shared/TermGrid/TermGrid'
import { isNumber } from 'lodash/fp'
import { buildConfigurableFields } from '@procurement-shared/NegotiationEventForm/NegotiationEventFormContext'
import { useGetConfigurationQuery } from '../../store/purchasing'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { formatContractDate } from '../../utils/formatting'
import { useFormatter } from '@shared/hooks'
import { FINAL_COMPLETED_NEGOTIATION_STATUSES } from '../../constants'
import { getBaselineSupplier } from '../../utils/negotiationEventGetters'
import pluralize from 'pluralize'
import { isSpendNegotiation } from '../NegotiationEventForm/utils/spend'

interface Props {
  event: NegotiationEvent
  supplier: NegotiationEventSupplier
}

export const Terms = ({ event, supplier }: Props) => {
  const { activeProjectTag } = useActiveProject()
  const formatter = useFormatter()
  const { data: configuration } = useGetConfigurationQuery({ projectTag: activeProjectTag })
  const { visibleFields } = buildConfigurableFields(configuration?.data.suite.requisitionFormFields)

  const contractIncotermsEnabled = visibleFields.includes('contractIncoterms')
  const warrantyEnabled = visibleFields.includes('warrantyEnabled')
  const contractDatesEnabled = visibleFields.includes('contractDatesEnabled')
  const estimatedSpendNegotiation = isSpendNegotiation(event.lineItems[0])

  const { negotiationSettings, negotiation } = supplier
  const baselineSupplier = getBaselineSupplier({ negotiationEvent: event, supplier })
  const { negotiationSettings: baselineNegotiationSettings } = baselineSupplier

  const contractLengthAlternativeMonths =
    negotiationSettings?.contractLengthAlternatives?.map(
      ({ contractLengthMonths }) => contractLengthMonths,
    ) ?? []

  const shouldDisplayCompletedScreen = (status: NegotiationStatus | undefined) =>
    status && FINAL_COMPLETED_NEGOTIATION_STATUSES.includes(status)

  const getTermsForCompletedNegotiation = (): TermItem[] => {
    const items: TermItem[] = []
    if (!negotiation) {
      return items
    }
    const outcomes = negotiation.outcomes

    if (contractIncotermsEnabled) {
      const baselineIncoterms = baselineNegotiationSettings?.contractIncoterms?.benchmark
      items.push({
        title: 'Incoterms',
        subtitle: 'Agreed',
        value: outcomes.incoterm ?? '-',
        secondaryValue:
          (negotiationSettings?.contractIncoterms?.acceptable &&
            'Acceptable: ' + negotiationSettings?.contractIncoterms?.acceptable?.join(', ')) ??
          '',
        altTitle: 'Baseline',
        altValue: baselineIncoterms ?? '-',
      })
    }

    if (warrantyEnabled) {
      const baselineWarranty = baselineNegotiationSettings?.warranty?.benchmark

      items.push({
        title: 'Warranty',
        subtitle: 'Agreed',
        value: outcomes.warranty !== undefined ? `${outcomes.warranty} months` : '-',
        secondaryValue:
          (negotiationSettings?.warranty &&
            `Acceptable: ${negotiationSettings?.warranty?.minLength} - ${negotiationSettings?.warranty?.maxLength} months`) ??
          '',
        altTitle: 'Baseline',
        altValue: baselineWarranty ? `${baselineWarranty} months` : 'No warranty',
      })
    }

    if (contractDatesEnabled) {
      items.push({
        title: 'Agreement start date',
        subtitle: 'Agreed start date',
        value:
          (outcomes?.contractStartDate &&
            formatContractDate(outcomes?.contractStartDate, formatter)) ??
          '-',
        altTitle: 'Baseline',
        altValue:
          (baselineNegotiationSettings?.contractStartDate &&
            formatContractDate(baselineNegotiationSettings?.contractStartDate, formatter)) ??
          '-',
      })

      const contractLengthAlternatives = [
        ...contractLengthAlternativeMonths,
        negotiationSettings?.contractLength,
      ]
      items.push({
        title: 'Contract length',
        subtitle: 'Agreed length',
        value: outcomes.contractLength ? `${outcomes.contractLength} months` : '-',
        secondaryValue: contractLengthAlternatives.length
          ? 'Acceptable: ' +
            contractLengthAlternatives.filter(isNumber).sort().join(', ') +
            ' months'
          : '',
        altTitle: 'Baseline',
        altValue: baselineNegotiationSettings?.contractLength
          ? `${baselineNegotiationSettings.contractLength} months`
          : '-',
      })
    }

    if (estimatedSpendNegotiation) {
      const previousContractLength = negotiationSettings?.previousContractLength

      items.push({
        title: 'Estimated spend',
        subtitle: 'Spend or quote duration',
        value: previousContractLength
          ? `${previousContractLength} ${pluralize('month', previousContractLength)}`
          : '-',
      })
    }

    return items
  }

  const getTermsForReadyState = (): TermItem[] => {
    const items: TermItem[] = []
    if (contractIncotermsEnabled) {
      items.push({
        title: 'Incoterms',
        subtitle: 'Acceptable incoterms',
        value: negotiationSettings?.contractIncoterms?.acceptable?.join(', ') ?? '-',
        altTitle: 'Current incoterms',
        altValue: negotiationSettings?.contractIncoterms?.benchmark ?? '-',
      })
    }

    if (warrantyEnabled) {
      items.push({
        title: 'Warranty',
        subtitle: 'Acceptable warranty',
        value:
          (negotiationSettings?.warranty &&
            `${negotiationSettings?.warranty?.minLength} - ${negotiationSettings?.warranty?.maxLength} months`) ??
          '-',
        altTitle: 'Current warranty',
        altValue: negotiationSettings?.warranty?.benchmark
          ? `${negotiationSettings.warranty.benchmark} months`
          : '-',
      })
    }

    if (contractDatesEnabled) {
      items.push({
        title: 'Agreement start date',
        subtitle: 'Acceptable start date',
        value:
          (negotiationSettings?.contractStartDate &&
            formatContractDate(negotiationSettings?.contractStartDate, formatter)) ??
          '-',
      })

      const contractLengthAlternatives = [
        ...contractLengthAlternativeMonths,
        negotiationSettings?.contractLength,
      ]

      items.push({
        title: 'Contract length',
        subtitle: 'Acceptable contract lengths',
        value: contractLengthAlternatives.filter(isNumber).sort().join(', ') + ' months',
      })
    }

    if (estimatedSpendNegotiation) {
      const previousContractLength = negotiationSettings?.previousContractLength

      items.push({
        title: 'Estimated spend',
        subtitle: 'Spend or quote duration',
        value: previousContractLength
          ? `${previousContractLength} ${pluralize('month', previousContractLength)}`
          : '-',
      })
    }

    return items
  }

  const items: TermItem[] =
    shouldDisplayCompletedScreen(negotiation?.status) && negotiation?.outcomes
      ? getTermsForCompletedNegotiation()
      : getTermsForReadyState()

  return <TermGrid items={items} />
}
