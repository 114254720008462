import { Alert, Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import {
  useGetConfigurationQuery,
  useGetNegotiationEventListItemsQuery,
} from '../../store/purchasing'
import { PactumLoader } from '@components/PactumLoader'
import { UploadNegotiationEventsDialog } from './UploadNegotiationEventsDialog/UploadNegotiationEventsDialog'
import { NegotiationEventListTable } from './NegotiationEventListTable/NegotiationEventListTable'
import { isBackendApiErrorResponse } from '@shared/backend/error/typeGuards'
import { useSnackbar } from 'notistack'
import { ProductType } from '@pactum/core-backend-types'
import { WelcomePage } from '@procurement/pages/NegotiationEventList/WelcomePage/WelcomePage'
import { useLayoutContext } from '@components/Layout'

const LAYOUT_PADDING = 6

export const NegotiationEventList = () => {
  const [uploadDialogOpened, setUploadDialogOpen] = useState(false)
  const { activeProjectTag } = useActiveProject()
  const { enqueueSnackbar } = useSnackbar()
  const { setIsReadonlyWorkspace, setNegotiationEvent } = useLayoutContext()

  const {
    data: configuration,
    error: configurationLoadError,
    isError: isConfigurationLoadError,
    isLoading: isLoadingProjectConfiguration,
  } = useGetConfigurationQuery({
    projectTag: activeProjectTag,
  })

  const {
    data: negotiationEventsResponse,
    error: negotiationEventsLoadError,
    isError: isNegotiationEventsLoadError,
    isLoading: isLoadingNegotiationEventsList,
  } = useGetNegotiationEventListItemsQuery({
    projectTag: activeProjectTag,
  })

  const negotiationEvents = negotiationEventsResponse?.data

  const openUploadDialog = () => {
    setUploadDialogOpen(true)
  }

  const closeUploadDialog = () => {
    setUploadDialogOpen(false)
  }

  useEffect(() => {
    setNegotiationEvent(null)
  }, [setNegotiationEvent])

  useEffect(() => {
    if (isNegotiationEventsLoadError && negotiationEventsLoadError) {
      enqueueSnackbar(
        isBackendApiErrorResponse(negotiationEventsLoadError)
          ? negotiationEventsLoadError.data.message
          : 'There was an error fetching negotiation events.',
        { variant: 'error' },
      )
    }
  }, [isNegotiationEventsLoadError, negotiationEventsLoadError, enqueueSnackbar])

  useEffect(() => {
    if (configuration?.data.suite?.isReadonly === true) {
      setIsReadonlyWorkspace(true)
    }
    return () => setIsReadonlyWorkspace(false)
  }, [configuration, setIsReadonlyWorkspace])

  useEffect(() => {
    if (isConfigurationLoadError && configurationLoadError) {
      enqueueSnackbar(
        isBackendApiErrorResponse(configurationLoadError)
          ? configurationLoadError.data.message
          : 'There was an error fetching workspace configuration.',
        { variant: 'error' },
      )
    }
  }, [isConfigurationLoadError, configurationLoadError, enqueueSnackbar])

  if (isLoadingNegotiationEventsList || isLoadingProjectConfiguration) {
    return <PactumLoader />
  }

  if (!negotiationEvents || !configuration) {
    return (
      <Box px={LAYOUT_PADDING} py={4}>
        <Alert severity='error'>We were unable to load negotiation events, please try again!</Alert>
      </Box>
    )
  }

  const { hideSpreadsheetUpload, productType, useCase, isReadonly } = configuration.data.suite

  if (productType !== ProductType.ContractCost && productType !== ProductType.Purchasing) {
    return (
      <Box px={LAYOUT_PADDING} py={4}>
        <Alert severity='error'>This feature is not available for the selected product type.</Alert>
      </Box>
    )
  }

  const onOpenUploadDialog = hideSpreadsheetUpload || isReadonly ? undefined : openUploadDialog
  const showNegotiationEventsTable = negotiationEvents.length > 0

  return (
    <>
      {!hideSpreadsheetUpload && (
        <UploadNegotiationEventsDialog
          open={uploadDialogOpened}
          onClose={closeUploadDialog}
          productType={productType}
        />
      )}
      {showNegotiationEventsTable ? (
        <NegotiationEventListTable
          useCase={useCase}
          productType={productType}
          negotiationEvents={negotiationEvents}
          onOpenUploadDialog={onOpenUploadDialog}
        />
      ) : (
        <WelcomePage onOpenUploadDialog={onOpenUploadDialog} />
      )}
    </>
  )
}
