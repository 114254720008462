import { useFormContext } from 'react-hook-form'
import { FormControlLabel, Grid, Radio, Typography } from '@mui/material'
import { ConsignmentAgreementStatusEnum } from '@procurement/store/types'
import { FormRow } from '@procurement/components/FormCommon/FormCommon'
import { NegotiationEventFormData } from '../../schema'

export interface ConsignmentAgreementProps {
  row: NegotiationEventFormData['lineItems'][number]
}

export const ConsignmentAgreement = ({ row }: ConsignmentAgreementProps) => {
  const { getValues, setValue } = useFormContext<NegotiationEventFormData>()
  const handleChange = (value: string) => {
    const currentLineItems = getValues('lineItems')
    const updatedLineItems = currentLineItems.map((item) => {
      if (item.numberInCollection === row.numberInCollection) {
        return { ...item, consignmentAgreementStatus: value }
      }
      return item
    })
    setValue('lineItems', updatedLineItems)
  }

  const options = {
    [ConsignmentAgreementStatusEnum.Existing]: 'Existing agreement',
    [ConsignmentAgreementStatusEnum.Needed]: 'Negotiate agreement',
  }

  return (
    <FormRow>
      <Grid item xs={2.5}>
        <Typography variant='body2' sx={{ mt: 1 }}>
          Consignment:
        </Typography>
      </Grid>
      <Grid item xs={9.5}>
        {Object.entries(options).map(([value, label]) => (
          <FormControlLabel
            key={value}
            checked={row.consignmentAgreementStatus === value}
            onChange={() => handleChange(value)}
            control={<Radio />}
            label={<Typography variant='body2'>{label}</Typography>}
          />
        ))}
        <Typography variant='body2'>Quantity is treated as an estimate of actual need.</Typography>
        <Typography variant='body2'>
          Terms of the existing Consignment inventory agreement apply
        </Typography>
      </Grid>
    </FormRow>
  )
}
