import { Grid, Stack, Typography } from '@mui/material'
import { useContext, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useGetConfigurationQuery } from '@procurement/store/purchasing'
import {
  CONTRACT_MODELS,
  getContractById,
  NON_SAAS_MODEL_ID,
} from '@procurement/utils/contractModel'
import { ContractModelOption } from '@procurement/components/NegotiationEventForm/sections/ContractModel/ContractModel'
import {
  buildConfigurableFields,
  NegotiationEventFormContext,
} from '../../NegotiationEventFormContext'
import { NegotiationEventFormData } from '../../schema'

interface Props {
  defaultContractModelId?: string
}

export const ContractModelSelector = ({ defaultContractModelId }: Props) => {
  const { activeProjectTag } = useActiveProject()
  const {
    data: configuration,
    isFetching,
    isLoading,
  } = useGetConfigurationQuery({ projectTag: activeProjectTag })
  const { requiredFields, updateFields, setFields } = useContext(NegotiationEventFormContext)
  const { watch, getValues, setValue } = useFormContext<NegotiationEventFormData>()
  const selectedContractModelId = watch('contractModel.id')

  useEffect(() => {
    if (!isFetching && !isLoading && configuration && selectedContractModelId === undefined) {
      const fields = buildConfigurableFields(configuration.data?.suite?.requisitionFormFields)
      const selectedContract = getContractById(defaultContractModelId)

      if (selectedContract) {
        setFields({
          requiredFields: fields.requiredFields.concat(
            selectedContract.properties,
            selectedContract.otherRequiredFields,
          ),
          visibleFields: fields.visibleFields,
          disabledFields: fields.disabledFields,
        })
      }
    }
  }, [
    configuration,
    defaultContractModelId,
    isFetching,
    isLoading,
    selectedContractModelId,
    setFields,
  ])

  const isSelectedContract = (contract: ContractModelOption) => {
    if (contract.id === NON_SAAS_MODEL_ID && !selectedContractModelId) {
      return true
    }

    return contract.id === selectedContractModelId
  }

  const handleContractChange = (contract: ContractModelOption) => {
    const previousContractId = getValues('contractModel.id')
    const previousContract = getContractById(previousContractId)
    const { properties = [], otherRequiredFields = [] } = previousContract ?? {}
    const allPreviousContractFields = [...properties, ...otherRequiredFields]

    const filteredRequiredFields = requiredFields.filter(
      (field) => !allPreviousContractFields.includes(field),
    )

    updateFields({
      requiredFields: [
        ...filteredRequiredFields,
        ...contract.properties,
        ...contract.otherRequiredFields,
      ],
    })
    setValue('contractModel.id', contract.id)
  }

  if (defaultContractModelId) {
    return null
  }

  return (
    <Grid mt={1} item container xs={12} spacing={2}>
      {CONTRACT_MODELS.map((contract) => (
        <Grid key={contract.id} item xs={4}>
          <SelectorBlock
            contract={contract}
            selected={isSelectedContract(contract)}
            onSelect={() => handleContractChange(contract)}
          />
        </Grid>
      ))}
    </Grid>
  )
}

const SelectorBlock = ({
  contract,
  selected,
  onSelect,
}: {
  contract: ContractModelOption
  selected: boolean
  onSelect: (contract: ContractModelOption) => void
}) => (
  <Stack
    p={1}
    pb={2}
    direction='row'
    flexWrap='wrap'
    alignContent='flex-start'
    sx={{
      height: '100%',
      color: selected ? 'common.white' : undefined,
      backgroundColor: selected ? 'accent.main' : undefined,
      border: '1px solid',
      borderColor: 'borders.main',
      borderRadius: '4px',
      cursor: 'pointer',
    }}
    onClick={() => onSelect(contract)}
  >
    <Typography color='inherit' variant='body2' fontWeight={700} sx={{ width: '100%' }}>
      {contract.title}
    </Typography>
    <Typography pt={1} color='inherit' variant='body2' sx={{ width: '100%' }}>
      {contract.description}
    </Typography>
  </Stack>
)
