import React, { useEffect, useState } from 'react'
import { IndividualItemTable } from 'src/merchandising/components/IndividualItemTable'
import { ScenarioGroup, ItemData, DefaultUserInput } from '@common/types'
import { Button, Divider, Stack, TextField, useTheme } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-pro'
import { GridRenderCellParams, GridRowSelectionModel } from '@mui/x-data-grid-pro'
import { getVodafoneColors } from 'src/merchandising/pages/Scenarios/ScenarioPreparation/ScenarioDetails/HistoricalData/utils'
import { ColorChip } from 'src/merchandising/pages/Scenarios/ScenarioPreparation/OverviewPage/ColorChip'
import { PactumLoader } from 'src/shared/components/PactumLoader'

const extraColumns: GridColDef<ItemData>[] = [
  {
    field: 'group',
    headerName: 'Group',
    flex: 1,
    disableColumnMenu: true,
    filterable: false,
    sortable: false,
    renderCell: (params: GridRenderCellParams<ItemData, ScenarioGroup>) => (
      <ColorChip size='small' label={params.value?.name} maincolor={params.value?.color || null} />
    ),
  },
]

const reorder = (list: Group[], startIndex: number, endIndex: number): Group[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  const defaultGroupIndex = result.findIndex((group) => group.color === null)

  // Move default group always to the end of the list
  if (defaultGroupIndex >= 0 && defaultGroupIndex !== result.length - 1) {
    return reorder(result, defaultGroupIndex, result.length - 1)
  }

  return result
}

interface Props {
  rows: ItemData[]
  allowUpdates: boolean
  defaultValues: DefaultUserInput
  groups: ScenarioGroup[]
  isGroupsUpdateLoading: boolean
  submitGroups: (groups: ScenarioGroup[]) => void
}

type Group = Omit<ScenarioGroup, 'commercialIds'>

export const IndividualItemTableWithGrouping = ({
  rows,
  allowUpdates,
  isGroupsUpdateLoading,
  submitGroups,
  defaultValues,
  groups,
}: Props) => {
  const theme = useTheme()
  const colors = getVodafoneColors(rows.length + 1)

  const [tempGroups, setTempGroups] = useState<Group[]>([])
  const [groupedRows, setGroupedRows] = useState<(ItemData & { group: Group })[]>([])
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([])
  const [groupName, setGroupName] = useState<string>('')
  const [groupOrder, setGroupOrder] = useState<number>(1)
  const [readyToSubmit, setReadyToSubmit] = useState<boolean>(false)

  const getGroupsByCommercialId = () => {
    return groups.reduce(
      (result, group) => {
        group.commercialIds.forEach((commercialId) => {
          result[commercialId] = group
        })
        return result
      },
      {} as Record<string, ScenarioGroup>,
    )
  }

  useEffect(() => {
    if (groups.length && rows.length) {
      const groupsByCommercialId = getGroupsByCommercialId()

      setGroupedRows(
        rows.map((row) => ({
          ...row,
          group: groupsByCommercialId[row.commercial_id],
        })),
      )

      setTempGroups(groups)
    }
  }, [groups, rows])

  useEffect(() => {
    if (tempGroups.length && readyToSubmit) {
      updateGroups(tempGroups)
      setReadyToSubmit(false)
    }
  }, [readyToSubmit])

  const getNewGroupsFromRows = (newGroups: Group[]) => {
    return newGroups
      .map((newGroup) => ({
        ...newGroup,
        commercialIds: groupedRows.reduce((result, row) => {
          if (row.group.name === newGroup.name) {
            return [...result, row.commercial_id]
          }
          return result
        }, [] as string[]),
      }))
      .filter((group) => group.commercialIds.length)
  }

  const updateGroups = (newGroups: Group[]) => {
    const result: ScenarioGroup[] = getNewGroupsFromRows(newGroups)

    submitGroups(result)
  }

  const getNextColor = (): string => {
    const usedColors = groups.map((group) => group.color)
    const unusedColors = colors.filter((color) => !usedColors.includes(color))

    return unusedColors[0]
  }

  const addGroup = (group?: Group) => {
    const newGroupName = group?.name ?? groupName

    if (newGroupName) {
      const existingGroup = tempGroups.find((group) => group.name === newGroupName)
      const color = existingGroup ? existingGroup.color : getNextColor()
      const newGroup = { color, name: newGroupName }

      if (!existingGroup) {
        setTempGroups(reorder([...tempGroups, newGroup], tempGroups.length, groupOrder - 1))
      }

      setGroupedRows(
        groupedRows.map((row) => ({
          ...row,
          group: selectionModel.includes(row.commercial_id) ? newGroup : row.group,
        })),
      )
      setGroupName('')
      setGroupOrder(1)
      setSelectionModel([])
      setReadyToSubmit(true)
    }
  }

  const updateNewGroupOrder = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value) <= 0 ? 1 : Number(event.target.value)
    setGroupOrder(value)
  }

  return (
    <Stack spacing={2}>
      <Stack
        direction='row'
        spacing={1}
        minHeight={theme.spacing(5)}
        alignItems='center'
        justifyContent='flex-start'
        divider={<Divider orientation='vertical' flexItem />}
      >
        <Stack direction='row' spacing={1} alignItems='center'>
          {tempGroups.map((group, index) => (
            <ColorChip
              key={group.name + index}
              label={group.name}
              onClick={selectionModel.length ? () => addGroup(group) : undefined}
              maincolor={group.color}
              size='small'
            />
          ))}
        </Stack>
        {selectionModel.length ? (
          <Stack direction='row' spacing={1}>
            <TextField
              label='New Group Name'
              value={groupName}
              size='small'
              onChange={(e) => setGroupName(e.target.value)}
            />
            <TextField
              sx={{ width: '70px' }}
              label='Order'
              InputProps={{
                inputProps: { min: 1 },
              }}
              value={groupOrder}
              type='number'
              size='small'
              onChange={updateNewGroupOrder}
            />
            <Button disabled={!groupName} onClick={() => addGroup()}>
              Add
            </Button>
          </Stack>
        ) : null}
        {isGroupsUpdateLoading ? (
          <div>
            <PactumLoader sizePx={20} />
          </div>
        ) : null}
      </Stack>
      <IndividualItemTable
        rows={groupedRows}
        isLoading={isGroupsUpdateLoading}
        defaultValues={defaultValues}
        checkboxSelection={allowUpdates}
        extraColumns={extraColumns}
        extraColumnPlacement='prepend'
        onRowSelectionModelChange={setSelectionModel}
        rowSelectionModel={selectionModel}
      />
    </Stack>
  )
}
