import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { LogisticsRoutes } from '@logistics/routes'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import {
  useGetRfpDetailsQuery,
  useGetRfpNegotiationCountQuery,
} from '@logistics/store/contractedLanesApi'
import { PactumLoader } from '@components/PactumLoader'
import { BackendHttpError, isBackendHttpError } from '../../../../main/types/http-errors'
import { ErrorPage } from '@pages/ErrorPage'
import { useLayoutContext } from '@components/Layout'
import { useEffect } from 'react'
import { RFPDetailsList } from './RFPDetailsList'
import { parseISO } from 'date-fns'
import { RFPActions } from '@logistics/pages/contractedLanes/RFPListPage/RFPList/RFPActions'

export const RFPDetailsPage = () => {
  const { id: rfpId } = useParams()

  const navigate = useNavigate()

  const { activeProjectTag, activeOrgTag } = useActiveProject()

  const { setStatisticsHeaderData, setActionsComponent } = useLayoutContext()

  const {
    data: requestForPrice,
    isLoading: isLoadingRfp,
    error: rfpLoadingError,
  } = useGetRfpDetailsQuery({
    projectTag: activeProjectTag,
    id: Number(rfpId),
  })

  const {
    data: negotiationCount,
    isLoading: isLoadingNegotiationCount,
    error: negotiationCountLoadingError,
  } = useGetRfpNegotiationCountQuery({
    projectTag: activeProjectTag,
    id: Number(rfpId),
  })

  useEffect(() => {
    // Need to set something, otherwise actions won't show
    setStatisticsHeaderData([
      {
        title: 'Total Negotiations',
        primaryValue: isLoadingNegotiationCount
          ? 'Loading...'
          : (negotiationCount?.total_negotiation_count ?? 0),
      },
    ])
    if (requestForPrice) {
      setActionsComponent(
        <RFPActions rfp={requestForPrice} displayReviewAction={false} size={'large'} />,
      )
    }

    return () => {
      setActionsComponent(null)
      setStatisticsHeaderData([])
    }
  }, [
    isLoadingRfp,
    isLoadingNegotiationCount,
    negotiationCount,
    requestForPrice,
    setActionsComponent,
    setStatisticsHeaderData,
  ])

  if (isLoadingRfp || isLoadingNegotiationCount) {
    return <PactumLoader />
  }

  if (isBackendHttpError(rfpLoadingError)) {
    return renderError(rfpLoadingError)
  }

  if (isBackendHttpError(negotiationCountLoadingError)) {
    return renderError(negotiationCountLoadingError)
  }

  if (!requestForPrice) {
    return (
      <ErrorPage title='RFP Not Found'>
        <Typography variant='h2'>RFP with ID {rfpId} could not be found!</Typography>
      </ErrorPage>
    )
  }

  return (
    <Box>
      <Stack direction='row' alignItems='center' mt={4} pl={2}>
        <Box position='relative' right={(theme) => theme.spacing(0.75)}>
          <IconButton
            onClick={() =>
              navigate(
                generatePath(LogisticsRoutes.PROJECT_PATH, {
                  orgTag: activeOrgTag,
                  projectTag: activeProjectTag,
                }),
              )
            }
            sx={{ alignSelf: 'flex-start' }}
          >
            <ArrowBackIcon fontSize='small' sx={{ color: 'black' }} />
          </IconButton>
        </Box>
        <Typography variant='h2' component='h2'>
          {requestForPrice.name}
        </Typography>
      </Stack>
      <Box sx={{ px: 2, py: 4 }}>
        <RFPDetailsList
          rfpId={requestForPrice.id}
          rfpExpiresAt={parseISO(requestForPrice.expires_at)}
        />
      </Box>
    </Box>
  )
}

const renderError = (error: BackendHttpError) => {
  return (
    <ErrorPage title='Error loading RFP details'>
      {error && <pre>{error.data.message}</pre>}
    </ErrorPage>
  )
}
