import { ActionConfigMap, ActionsButton } from '@components/ActionsButton'
import { useState } from 'react'
import { styled, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { NegotiationEvent, NegotiationEventListItem } from '@procurement/store/types'
import {
  NegotiationEventAction,
  useNegotiationEventActions,
} from '@procurement/hooks/useNegotiationEventActions'
import { NegotiationEventFormContextProvider } from '@procurement/components/NegotiationEventForm/NegotiationEventFormContext'
import { useLazyGetSingleNegotiationEventQuery } from '@procurement/store/purchasing'
import { isBackendApiErrorResponse } from '@shared/backend/error/typeGuards'
import { NegotiationEventFormDialog } from '@procurement/components/NegotiationEventForm/NegotiationEventFormDialog'
import { StartConfirmationModal } from '@procurement/pages/NegotiationEventList/NegotiationEventListTable/StartConfirmationModal/StartConfirmationModal'
import { DeleteConfirmationModal } from '@procurement/pages/NegotiationEventList/NegotiationEventListTable/DeleteConfirmationModal/DeleteConfirmationModal'
import { WithdrawConfirmationModal } from '@procurement/pages/NegotiationEventList/NegotiationEventListTable/WithdrawConfirmationModal/WithdrawConfirmationModal'

interface Props {
  listItem: NegotiationEventListItem
  actionsMenuMinWidth: number
}

export const NegotiationEventActions = ({ listItem, actionsMenuMinWidth }: Props) => {
  const [startConfirmationModalOpen, setStartConfirmationModalOpen] = useState(false)
  const [startConfirmationModalLoading, setStartConfirmationModalLoading] = useState(false)
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false)
  const [deleteConfirmationModalLoading, setDeleteConfirmationModalLoading] = useState(false)
  const [withdrawConfirmationModalOpen, setWithdrawConfirmationModalOpen] = useState(false)
  const [isGetNegotiationEventLoading, setIsGetNegotiationEventLoading] = useState(false)
  const [negotiationEvent, setNegotiationEvent] = useState<NegotiationEvent | null>(null)
  const { enqueueSnackbar } = useSnackbar()
  const {
    availableActions,
    handleNegotiationEventStartAction,
    handleNegotiationEventDeleteAction,
    handleNegotiationEventsDownloadAction,
  } = useNegotiationEventActions([listItem])

  const [getNegotiationEvent] = useLazyGetSingleNegotiationEventQuery()

  const onEditModalOpen = async () => {
    setIsGetNegotiationEventLoading(true)
    try {
      const data = await getNegotiationEvent({
        projectTag: listItem.projectTag,
        negotiationEventId: listItem.id,
      }).unwrap()

      setNegotiationEvent(data)
    } catch (error) {
      setNegotiationEvent(null)

      // TODO: Refactor all usages of enqueueSnackbar to get rid of duplicated code
      const errorMessage = isBackendApiErrorResponse(error)
        ? error.data.message
        : (error as Error).message

      enqueueSnackbar(errorMessage, { variant: 'error' })
    } finally {
      setIsGetNegotiationEventLoading(false)
    }
  }

  const actionConfig: ActionConfigMap<NegotiationEventAction, NegotiationEvent['id']> = {
    [NegotiationEventAction.START]: {
      label: listItem.negotiationStatus === 'WITHDRAWN' ? 'Restart' : 'Start',
      onClick: () => setStartConfirmationModalOpen(true),
    },
    [NegotiationEventAction.DELETE]: {
      label: 'Delete',
      onClick: () => setDeleteConfirmationModalOpen(true),
    },
    [NegotiationEventAction.WITHDRAW]: {
      label: 'Withdraw',
      onClick: () => setWithdrawConfirmationModalOpen(true),
    },
    [NegotiationEventAction.DOWNLOAD]: {
      label: 'Download',
      onClick: () => handleNegotiationEventsDownloadAction(),
    },
    [NegotiationEventAction.EDIT]: {
      label: 'Edit',
      onClick: onEditModalOpen,
    },
  }

  if (availableActions.length === 0) {
    const actionPlaceholder = ['IN_PROGRESS'].includes(listItem.negotiationStatus!)
      ? 'Awaiting results...'
      : 'No actions'

    return <StyledNoActionsText>{actionPlaceholder}</StyledNoActionsText>
  }

  return (
    <>
      <ActionsButton
        id={listItem.id}
        allowedActions={availableActions}
        actionConfig={actionConfig}
        menuMinWidth={actionsMenuMinWidth}
        loading={isGetNegotiationEventLoading}
      />
      <DeleteConfirmationModal
        open={deleteConfirmationModalOpen}
        onCancel={() => setDeleteConfirmationModalOpen(false)}
        onSubmit={async () => {
          try {
            setDeleteConfirmationModalLoading(true)
            await handleNegotiationEventDeleteAction()
          } finally {
            setDeleteConfirmationModalOpen(false)
            setDeleteConfirmationModalLoading(false)
          }
        }}
        loading={deleteConfirmationModalLoading}
      />
      <WithdrawConfirmationModal
        open={withdrawConfirmationModalOpen}
        negotiationEventListItem={listItem}
        onCancel={() => setWithdrawConfirmationModalOpen(false)}
      />
      <StartConfirmationModal
        open={startConfirmationModalOpen}
        onCancel={() => setStartConfirmationModalOpen(false)}
        onSubmit={async () => {
          try {
            setStartConfirmationModalLoading(true)
            await handleNegotiationEventStartAction()
          } finally {
            setStartConfirmationModalOpen(false)
            setStartConfirmationModalLoading(false)
          }
        }}
        loading={startConfirmationModalLoading}
      />
      <NegotiationEventFormContextProvider>
        <NegotiationEventFormDialog
          negotiationEvent={negotiationEvent}
          onClose={() => setNegotiationEvent(null)}
          open={!!negotiationEvent}
        />
      </NegotiationEventFormContextProvider>
    </>
  )
}

const StyledNoActionsText = styled(Typography)(({ theme }) => ({
  width: '100%',
  fontSize: '14px',
  color: theme.palette.text.secondary,
  textAlign: 'center',
  fontWeight: 700,
}))
