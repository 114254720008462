import { PriceVolumePoint } from '@common/types/scenario'
import { PriceVolumePointInputs } from './PriceVolumeAdjustment'

export type FieldError = (keyof PriceVolumePoint)[]

export interface InputFieldErrors {
  [index: number]: FieldError
}

export const arePointNumbersDefined = (point: PriceVolumePointInputs): boolean => {
  return isPositiveNumber(point.volume) && isPositiveNumber(point.price)
}

export const updateFieldErrors = (
  newPriceVolumePoints: PriceVolumePoint[] | PriceVolumePointInputs[],
  errors: InputFieldErrors,
): InputFieldErrors => {
  let allNewErrors: InputFieldErrors = {}

  newPriceVolumePoints.forEach((point, index) => {
    const previousPointErrors = errors[index] || []
    let newPointErrors: FieldError = []
    const keys = Object.keys(point) as (keyof typeof point)[]

    keys.forEach((key) => {
      previousPointErrors.splice(previousPointErrors.indexOf(key), 1)

      if (
        (key === 'price' &&
          !isUniquePrice(index, newPriceVolumePoints[index], newPriceVolumePoints)) ||
        !isPositiveNumber(newPriceVolumePoints[index][key])
      ) {
        newPointErrors = Array.from(new Set([...previousPointErrors, key]))
      }

      if (key === 'volume') {
        newPointErrors = validateVolumeOrder(point, index, newPriceVolumePoints, newPointErrors)
      }
    })

    if (newPointErrors.length) {
      allNewErrors = { ...allNewErrors, [index]: newPointErrors }
    } else {
      const { [index]: _omit, ...rest } = allNewErrors

      allNewErrors = rest
    }
  })

  return allNewErrors
}

export const arePriceVolumePointsValid = (
  points: PriceVolumePointInputs[] | PriceVolumePoint[],
): points is PriceVolumePoint[] => points.every(isPointValid)

export const isPointValid = (
  point: PriceVolumePointInputs | PriceVolumePoint | null,
): point is PriceVolumePoint =>
  !!point &&
  point.price !== '' &&
  point.volume !== '' &&
  !Number.isNaN(point.volume) &&
  !Number.isNaN(point.price)

const isPositiveNumber = (value: number | ''): boolean =>
  value !== '' && Number.isFinite(value) && value >= 0

const isUniquePrice = (
  currentIndex: number,
  currentPoint: PriceVolumePointInputs,
  allPoints: PriceVolumePointInputs[], // including provided point
) => !allPoints.find((point, index) => index !== currentIndex && point.price === currentPoint.price)

const validateVolumeOrder = (
  point: PriceVolumePoint | PriceVolumePointInputs,
  currentIndex: number,
  newPriceVolumePoints: PriceVolumePoint[] | PriceVolumePointInputs[],
  previousErrors: FieldError,
): FieldError => {
  if (
    (currentIndex && !point.volume) ||
    isPreviousVolumeSmaller(currentIndex, point, newPriceVolumePoints) ||
    isNextVolumeGreater(currentIndex, point, newPriceVolumePoints)
  ) {
    const key: keyof PriceVolumePoint = 'volume'

    return Array.from(new Set([...previousErrors, key]))
  }
  return previousErrors
}

const isPreviousVolumeSmaller = (
  index: number,
  point: PriceVolumePoint | PriceVolumePointInputs,
  allPoints: PriceVolumePoint[] | PriceVolumePointInputs[],
): boolean => !!index && point.volume > allPoints[index - 1].volume

const isNextVolumeGreater = (
  index: number,
  point: PriceVolumePoint | PriceVolumePointInputs,
  allPoints: PriceVolumePoint[] | PriceVolumePointInputs[],
): boolean => !!index && allPoints[index + 1] && point.volume < allPoints[index + 1].volume
