import React from 'react'

import { Box, Card, CardContent, CardMedia, Grid, Stack, Typography } from '@mui/material'

import { ChatLinksButton } from './ChatLinksButton'
import { useGetUseCasesQuery } from 'src/main/store/useCase'
import { PactumLoader } from '@components/PactumLoader'
import { StyledTab } from '@components/StyledTab'
import { PactumTabs } from '@components/PactumTab'

const CARD_HEIGHT = 152

export const WelcomePage = () => {
  const { data: useCases, isLoading } = useGetUseCasesQuery()
  const [activeUseCaseTab, setActiveUseCaseTab] = React.useState('all-use-cases')
  const showLoader = isLoading || !useCases

  if (showLoader) {
    return <PactumLoader />
  } else {
    const productTypes = useCases
      .map((useCase) => useCase.productType)
      .filter((value, index, array) => {
        return array.indexOf(value) === index
      })
      .filter((value) => value)

    const activeUsesCases = useCases.filter((useCase) => {
      if (activeUseCaseTab === 'all-use-cases') {
        return true
      }
      return useCase.productType === activeUseCaseTab
    })

    return (
      <Stack px={6} py={1} direction='column' spacing={4}>
        <Stack direction='column' spacing={3}>
          <PactumTabs
            value={activeUseCaseTab}
            onChange={(_, newTab) => {
              setActiveUseCaseTab(newTab)
            }}
          >
            <StyledTab label='All use cases' value='all-use-cases' />
            {productTypes.map((title) => (
              <StyledTab key={title} label={title} value={title} />
            ))}
          </PactumTabs>
          <Typography
            variant='normal'
            color='text.secondary'
            sx={{ width: { xs: '100%', lg: '60%' } }}
          >
            This is the world’s largest set of predefined autonomous negotiation use cases.
          </Typography>
        </Stack>
        <Box>
          <Grid container spacing={6}>
            {activeUsesCases.map((useCase) => (
              <Grid item xs={12} md={6} xl={4} key={useCase.title}>
                <Card
                  elevation={0}
                  sx={(theme) => ({
                    border: `1px solid ${theme.palette.borders.main}`,
                    height: CARD_HEIGHT,
                  })}
                >
                  <CardContent sx={{ padding: 0, height: CARD_HEIGHT }}>
                    <Stack direction='row' sx={{ padding: 0, height: CARD_HEIGHT }}>
                      <CardMedia
                        component='img'
                        alt={`${useCase.title}-image`}
                        src={useCase.backgroundImageUrl}
                        loading='lazy'
                        sx={{
                          width: '56px',
                          height: '100%',
                        }}
                      />
                      <Stack
                        direction='column'
                        justifyContent='space-between'
                        gap={1}
                        sx={{ p: 2 }}
                      >
                        <Stack direction='column' gap={1}>
                          <Typography
                            variant='subtitle2'
                            sx={{
                              display: '-webkit-box',
                              textOverflow: 'ellipsis',
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: 'vertical',
                            }}
                          >
                            {useCase.title}
                          </Typography>
                          <Typography
                            variant='normal'
                            color='text.secondary'
                            lineHeight='1rem'
                            sx={{
                              display: '-webkit-box',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: 'vertical',
                            }}
                          >
                            {useCase.description}
                          </Typography>
                        </Stack>
                        {useCase.chatLinks && useCase.chatLinks.length > 0 && (
                          <ChatLinksButton chatLinks={useCase.chatLinks} />
                        )}
                      </Stack>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Stack>
    )
  }
}
