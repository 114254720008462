import React, { useEffect, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { Chart as ChartJS } from 'chart.js'
import 'chart.js/auto'
import annotationPlugin from 'chartjs-plugin-annotation'
import zoomPlugin from 'chartjs-plugin-zoom'
import {
  Box,
  Button,
  Container,
  IconButton,
  ListItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useSnackbar } from 'notistack'

import { HistoricalChart } from 'src/merchandising/pages/Scenarios/ScenarioPreparation/ScenarioDetails/HistoricalData'
import { ExpandableSection } from 'src/shared/components/ExpandableSection'
import { UpdateScenarioRequest } from '@common/dto'
import { NotOwnerAlert, ScenarioSubmittedAlert } from 'src/shared/components/Alerts'
import { IndividualItems } from 'src/merchandising/pages/Scenarios/ScenarioPreparation/ScenarioDetails/IndividualItems'
import { BSRRatioContainer } from './BSRRatio'
import { SectionsContainer } from 'src/shared/components/SectionsContainer'
import {
  isBackendApiErrorResponse,
  useUpdateScenarioInputsMutation,
  useUpdateScenarioNameMutation,
} from 'src/merchandising/store/'
import { useScenarioDetailsData } from 'src/merchandising/pages/Scenarios/ScenarioPreparation/ScenarioPreparation'
import { ItemData, ScenarioState } from '@common/types/scenario/ScenarioState'
import { LumpSums } from './LumpSums'
import { CustomDeals } from './CustomDeals'
import { MerchandisingRoutes } from 'src/merchandising/routes'
import { StyledList } from './components/StyledList'
import { OwnerChangeDialog } from './OwnerChangeDialog'
import { bsrTooltipTexts, lumpSumsTexts, negotiationDetailsTexts } from './constants'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { ProjectTag } from '@common/types'
import { AddItemsDialog } from './AddItemsDialog'
import { isLocalMarketStage } from '@common/utils'
import { CurrencySelect } from '../../../../components/CurrencySelect'

ChartJS.register(zoomPlugin, annotationPlugin)

type IndexToLabels = Required<ScenarioState>['historical']['metrics']['index_to_labels']
export const IndexToLabelsContext = React.createContext<IndexToLabels>({} as IndexToLabels[0])

export const ScenarioDetails = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { activeProjectTag, activeOrgTag } = useActiveProject()

  const { detailsData, setDetailsData, sessionKey } = useScenarioDetailsData()

  const [showOwnerChangeDialog, setShowOwnerChangeDialog] = useState(false)
  const [showAddItemsDialog, setShowAddItemsDialog] = useState(false)
  const [expandAll, setExpandAll] = useState<boolean>(false)
  const [isAnyItemInError, setIsAnyItemInError] = useState(false)
  const [areLumpSumsValid, setAreLumpSumsValid] = useState(true)
  const [areDealsValid, setAreDealsValid] = useState(true)

  const enabledItems = detailsData.state.current.items.filter((item) => item.enabled)

  // TODO should be part of `ScenarioDetails`
  const allowUpdates = !detailsData.submittedAt && detailsData.isOwner

  const [triggerRowUpdate, { isLoading: isRowUpdateLoading }] = useUpdateScenarioInputsMutation()

  const updateItems = async (requestData: UpdateScenarioRequest) => {
    try {
      const enabledItemIds = requestData.userInputs.items
        .filter((item) => item.enabled)
        .map((item) => item.commercial_id)

      const lumpSumsWithDisabledItemsRemoved = requestData.userInputs.lump_sums
        ? {
            lump_sums: requestData.userInputs.lump_sums.map((lumpSum) => ({
              ...lumpSum,
              commercial_ids: lumpSum.commercial_ids.filter((lumpSumItemId) =>
                enabledItemIds.includes(lumpSumItemId),
              ),
            })),
          }
        : {}

      const additionalLumpSumsWithDisabledItemsRemoved = requestData.userInputs.additionalLumpSums
        ? {
            additionalLumpSums: requestData.userInputs.additionalLumpSums.map((lumpSum) => ({
              ...lumpSum,
              commercial_ids: lumpSum.commercial_ids.filter((lumpSumItemId) =>
                enabledItemIds.includes(lumpSumItemId),
              ),
            })),
          }
        : {}

      const updatedScenarioData = await triggerRowUpdate({
        sessionKey,
        projectTag: activeProjectTag,
        payload: {
          userInputs: {
            ...requestData.userInputs,
            ...lumpSumsWithDisabledItemsRemoved,
            ...additionalLumpSumsWithDisabledItemsRemoved,
          },
        },
      }).unwrap()

      setDetailsData({
        ...detailsData,
        userInputs: updatedScenarioData.userInputs,
        state: {
          ...detailsData.state,
          ...updatedScenarioData.state,
        },
      })
    } catch (e) {
      enqueueSnackbar(
        isBackendApiErrorResponse(e) ? e.data.message : 'There was an error during scenario update',
        { variant: 'error' },
      )
    }
  }

  const currencies =
    detailsData.currencies != null
      ? Object.entries(detailsData.currencies.rates).map(([key, _]) => ({ label: key, value: key }))
      : [{ label: 'EUR', value: 'EUR' }]

  const handleCurrencyChange = async (currency: string) => {
    await updateItems({
      userInputs: {
        ...detailsData.userInputs,
        targetCurrency: currency,
      },
    })
  }

  return (
    <Container maxWidth='xl' sx={{ p: 3 }}>
      <Stack spacing={1}>
        {!detailsData.isOwner && <NotOwnerAlert />}
        {detailsData.submittedAt && <ScenarioSubmittedAlert />}
        <Stack direction='row'>
          <Box position='relative' right={(theme) => theme.spacing(1.5)}>
            <IconButton
              onClick={() =>
                navigate(
                  generatePath(MerchandisingRoutes.SCENARIOS_LIST, {
                    orgTag: activeOrgTag!,
                    projectTag: activeProjectTag!,
                  }),
                )
              }
            >
              <ArrowBackIcon
                fontSize='small'
                sx={{ color: (theme) => theme.palette.common.black }}
              />
            </IconButton>
          </Box>
          <ScenarioName projectTag={activeProjectTag} savedScenarioName={detailsData.name} />
          <ScenarioObjective
            targetMarketName={
              isLocalMarketStage(detailsData)
                ? detailsData.state.market_info[detailsData.userInputs.market_id].name
                : undefined
            }
          />
          <Stack direction='row' sx={{ marginLeft: 'auto' }}>
            <CurrencySelect
              disabled={!allowUpdates || isRowUpdateLoading}
              selected={detailsData.userInputs.targetCurrency ?? 'EUR'}
              currencies={currencies}
              sx={{ mr: 1 }}
              onCurrencyChange={handleCurrencyChange}
            />
            {allowUpdates && (
              <Button
                variant='contained'
                color='secondary'
                onClick={() => setShowAddItemsDialog(true)}
              >
                Add items
              </Button>
            )}
            {allowUpdates && (
              <Button
                variant='contained'
                color='secondary'
                sx={{ ml: 1 }}
                onClick={() => setShowOwnerChangeDialog(true)}
              >
                Change owner
              </Button>
            )}
            <Button
              variant='contained'
              color='primary'
              sx={{ ml: 1 }}
              onClick={() => setExpandAll(!expandAll)}
            >
              Expand All
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <SectionsContainer>
        <IndexToLabelsContext.Provider
          value={detailsData.state.historical!.metrics.index_to_labels}
        >
          <ExpandableSection
            allExpanded={expandAll}
            title='Historical Overview'
            description='The following figures show the historical values for items in the provided data.'
          >
            <HistoricalChart
              allItems={detailsData.state.item_info}
              allMarkets={detailsData.state.market_info}
              historical={detailsData.state.historical!}
              defaultInputs={{ marketId: detailsData.userInputs.market_id }}
            />
          </ExpandableSection>
          <ExpandableSection
            allExpanded={expandAll}
            title='Benefit and Spend'
            descriptionComponent={
              <Typography variant='body1' mt={1}>
                Figure showing historical and predicted benefit and spend values.
                <Tooltip
                  placement='top'
                  title={
                    <StyledList>
                      {bsrTooltipTexts.map((text, index) => (
                        <ListItem key={index}>{text}</ListItem>
                      ))}
                    </StyledList>
                  }
                >
                  <HelpIcon sx={{ marginLeft: 0.5, fontSize: '100%' }} />
                </Tooltip>
              </Typography>
            }
          >
            <BSRRatioContainer
              allItems={detailsData.state.item_info}
              allMarkets={detailsData.state.market_info}
              historical={detailsData.state.historical!}
              current={detailsData.state.current.metrics}
              defaultInputs={{ marketId: detailsData.userInputs.market_id }}
              currency={detailsData.userInputs.targetCurrency ?? 'EUR'}
            />
          </ExpandableSection>
          <ExpandableSection
            allExpanded={expandAll}
            title='Negotiation Item Details'
            descriptionComponent={
              <Typography variant='body1' mt={1}>
                The following table shows key metrics for individual items based on key values that
                can be adjusted for each item.
                <Tooltip
                  placement='top'
                  title={
                    <StyledList>
                      {negotiationDetailsTexts.map((text, index) => (
                        <ListItem key={index}>{text}</ListItem>
                      ))}
                    </StyledList>
                  }
                >
                  <HelpIcon sx={{ marginLeft: 0.5, fontSize: '100%' }} />
                </Tooltip>
              </Typography>
            }
          >
            <IndividualItems
              items={detailsData.state.current.items}
              userInputs={detailsData.userInputs}
              marketInfo={detailsData.state.market_info}
              defaultValues={detailsData.defaultInputs}
              onItemsUpdated={updateItems}
              onError={setIsAnyItemInError}
              allowUpdates={allowUpdates}
              isLoading={isRowUpdateLoading}
              isFinalNegotiationStage={!!detailsData.userInputs.market_id}
            />
          </ExpandableSection>
          <CustomDealsSection expandAll={expandAll} onDealsValidated={setAreDealsValid} />
          <LumpSumSection
            items={enabledItems}
            expandAll={expandAll}
            onLumpSumsValidated={setAreLumpSumsValid}
          />
        </IndexToLabelsContext.Provider>
      </SectionsContainer>
      <Stack direction='row' justifyContent='flex-end' mt={2}>
        <ProceedToReviewButtonTooltip
          isAnyItemInError={isAnyItemInError}
          areLumpSumsInError={!areLumpSumsValid}
          areDealsInError={!areDealsValid}
        >
          <Button
            disabled={isAnyItemInError || !areLumpSumsValid || !areDealsValid}
            color='primary'
            variant='contained'
            onClick={() =>
              navigate(
                generatePath(MerchandisingRoutes.SCENARIO_OVERVIEW, {
                  orgTag: activeOrgTag!,
                  projectTag: activeProjectTag!,
                  sessionKey,
                }),
              )
            }
          >
            Proceed to review
          </Button>
        </ProceedToReviewButtonTooltip>
      </Stack>
      <OwnerChangeDialog
        isOpen={showOwnerChangeDialog}
        sessionKey={detailsData.sessionKey}
        onOwnerChanged={() => setShowOwnerChangeDialog(false)}
        onClose={() => setShowOwnerChangeDialog(false)}
      />
      <AddItemsDialog
        isOpen={showAddItemsDialog}
        sessionKey={detailsData.sessionKey}
        onScenarioUpdated={() => setShowAddItemsDialog(false)}
        onClose={() => setShowAddItemsDialog(false)}
      />
    </Container>
  )
}

interface LumpSumsSectionProps {
  expandAll: boolean
  items: ItemData[]
  onLumpSumsValidated: (valid: boolean) => void
}

const LumpSumSection = ({ expandAll, items, onLumpSumsValidated }: LumpSumsSectionProps) => (
  <ExpandableSection
    allExpanded={expandAll}
    title='Lump Sums'
    descriptionComponent={
      <Typography variant='body1' mt={1}>
        The following table defines the lump sums the supplier can choose to include in the
        agreement. Lump sums that <b>affect previously entered item level prices</b> and those which
        do not are listed in separate sections.
        <Tooltip
          placement='top'
          title={
            <StyledList>
              {lumpSumsTexts.map((text, index) => (
                <ListItem key={index}>{text}</ListItem>
              ))}
            </StyledList>
          }
        >
          <HelpIcon sx={{ marginLeft: 0.5, fontSize: '100%' }} />
        </Tooltip>
      </Typography>
    }
  >
    <LumpSums items={items} onLumpSumsValidated={onLumpSumsValidated} />
  </ExpandableSection>
)

interface DealsSectionProps {
  expandAll: boolean
  onDealsValidated: (valid: boolean) => void
}

const CustomDealsSection = ({ expandAll, onDealsValidated }: DealsSectionProps) => {
  return (
    <ExpandableSection
      allExpanded={expandAll}
      title='Custom Deals'
      descriptionComponent={
        <Typography variant='body1' mt={1}>
          Define custom deal(s) and their viable alternatives, to which the supplier must provide
          consent for at least one option. These requirements are stringent; failure for the
          supplier to agree on any of these options or their corresponding alternatives will finish
          the negotiation with no agreement.
        </Typography>
      }
    >
      <CustomDeals onDealsValidated={onDealsValidated} />
    </ExpandableSection>
  )
}

const ScenarioName = ({
  projectTag,
  savedScenarioName,
}: {
  projectTag: ProjectTag
  savedScenarioName: string
}) => {
  const { sessionKey } = useScenarioDetailsData()
  const [scenarioName, setScenarioName] = useState(savedScenarioName)
  const [editMode, setEditMode] = useState(false)
  const [updateScenarioName] = useUpdateScenarioNameMutation()

  useEffect(() => {
    setScenarioName(savedScenarioName)
  }, [savedScenarioName])

  const handleScenarioNameUpdate = () => {
    updateScenarioName({
      sessionKey,
      projectTag,
      payload: {
        name: scenarioName,
      },
    })

    setEditMode(false)
  }

  return (
    <Stack direction='row' alignItems='center'>
      Scenario name:
      <Stack ml={1} direction='row' alignItems='center'>
        {!editMode && (
          <>
            <Typography variant='body1'>
              <b>{scenarioName}</b>
            </Typography>
            <Box ml={0.5}>
              <IconButton color='primary' onClick={() => setEditMode(true)}>
                <EditIcon sx={{ fontSize: '0.75rem' }} />
              </IconButton>
            </Box>
          </>
        )}
        {editMode && (
          <>
            <TextField
              size='small'
              value={scenarioName}
              onChange={(e) => setScenarioName(e.target.value)}
            />
            <Box ml={0.5}>
              <IconButton color='primary' onClick={handleScenarioNameUpdate}>
                <CheckIcon sx={{ fontSize: '1rem' }} />
              </IconButton>
            </Box>
          </>
        )}
      </Stack>
    </Stack>
  )
}

const ScenarioObjective = ({ targetMarketName }: { targetMarketName?: string }) => (
  <Stack direction='row' alignItems='center'>
    &rarr; Preparing for {targetMarketName ? 'Local' : 'Global'} Pricing negotiations
    {targetMarketName ? ` with ${targetMarketName}.` : '.'}
  </Stack>
)

const ProceedToReviewButtonTooltip = ({
  isAnyItemInError,
  areLumpSumsInError,
  areDealsInError,
  children,
}: {
  isAnyItemInError: boolean
  areLumpSumsInError: boolean
  areDealsInError: boolean
  children: React.ReactElement
}) => {
  let tooltip = ''
  if (isAnyItemInError) {
    tooltip = 'Please fix errors in the item details table before proceeding to review'
  } else if (areLumpSumsInError) {
    tooltip = 'Please fix errors in the lump sums table before proceeding to review'
  } else if (areDealsInError) {
    tooltip = 'Please fix errors in the custom deals table before proceeding to review'
  }
  return (
    <Tooltip placement='left' title={tooltip}>
      <span>{children}</span>
    </Tooltip>
  )
}
