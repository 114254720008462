import styled from '@emotion/styled'
import { CurrencyCode } from '@pactum/common'
import { ValueProjectionScale } from '@common/dto/dashboard/dashboard'
import { getTicksFormatting } from '../../../main/pages/DashboardPage/Widgets/TimeSeriesWidget/TimeSeriesWidget'

export const scaleValues: Record<ValueProjectionScale, number[]> = {
  xxxxs: [0, 500_000, 1_000_000],
  xxxs: [0, 2_500_000, 5_000_000],
  xxs: [0, 5_000_000, 10_000_000],
  xs: [0, 25_000_000, 50_000_000],
  s: [0, 50_000_000, 100_000_000],
  sm: [0, 250_000_000, 500_000_000],
  m: [0, 500_000_000, 1_000_000_000],
  l: [0, 5_000_000_000, 10_000_000_000],
  xl: [0, 50_000_000_000, 100_000_000_000],
}

interface RangeBarProps {
  values?: number[]
  scale?: ValueProjectionScale
  currency?: CurrencyCode
}

const RangeBarLine = styled.ul`
  border-top: 1px solid #000;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
`

const RangeBarLabel = styled.li`
  width: 100%;
  text-align: center;
  color: #6c6c6c;
  font-size: 10px;

  &:first-of-type {
    text-align: left;
  }

  &:last-of-type {
    text-align: right;
  }
`

export const RangeBarScale = ({ values = [], scale, currency }: RangeBarProps) => {
  return (
    <RangeBarLine>
      {(scale ? scaleValues[scale] : values).map((v) => (
        <RangeBarLabel key={v}>
          {currency
            ? Intl.NumberFormat('en-US', getTicksFormatting('currency', currency)).format(v)
            : v}
        </RangeBarLabel>
      ))}
    </RangeBarLine>
  )
}
