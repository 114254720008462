import { SingleFileUpload } from '@components/SingleFileUpload'
import { styled } from '@mui/material'

const ACCEPTED_FILE_TYPES = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
]

interface Props {
  fileCallback: (file: Blob) => void
  isLoading: boolean
}

export const ExcelDataFileUpload = ({ fileCallback, isLoading }: Props) => {
  return (
    <SingleFileUpload callback={fileCallback} isLoading={isLoading} fileTypes={ACCEPTED_FILE_TYPES}>
      Drag and Drop or&nbsp;
      <HoverText>Select the Historical & Forecast Supplier Data File</HoverText>
    </SingleFileUpload>
  )
}

const HoverText = styled('span')({
  ':hover': {
    textDecoration: 'underline',
  },
})
