import { isBackendApiErrorResponse } from '@shared/backend/error/typeGuards'
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack'

export type EnqueueSnackbar = (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey

export const enqueIfError = (error: unknown, enqueueSnackbar: EnqueueSnackbar) => {
  if (isBackendApiErrorResponse(error)) {
    enqueueSnackbar(error.data.message, { variant: 'error' })
  }
}
