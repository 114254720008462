import { CurrencyPickerCtrl } from '@components/Form/CurrencyPickerCtrl'
import { Grid } from '@mui/material'
import {
  createGetFieldControllerParams,
  FormRow,
  FormSectionTitle,
  HideableBlock,
} from '../../../FormCommon/FormCommon'
import { useContext } from 'react'
import { NegotiationEventFormContext } from '../../NegotiationEventFormContext'

interface Props {
  isRebatesNegotiation: boolean
  required?: boolean
}

export const Currency = ({ required = false, isRebatesNegotiation }: Props) => {
  const { requiredFields, visibleFields, disabledFields } = useContext(NegotiationEventFormContext)
  const getFieldControllerParams = createGetFieldControllerParams({
    requiredFields: required ? requiredFields.concat('currency') : requiredFields,
    disabledFields,
  })

  const sectionTitle = isRebatesNegotiation ? 'Price and Rebate' : 'Price'

  const { disabled, name, label } = getFieldControllerParams('currency', 'Currency')

  return (
    <HideableBlock hidden={!visibleFields.includes('currency')}>
      <FormSectionTitle>{sectionTitle}</FormSectionTitle>
      <FormRow>
        <Grid item xs={4}>
          <CurrencyPickerCtrl label={label} name={name} disabled={disabled} disableClearable />
        </Grid>
      </FormRow>
    </HideableBlock>
  )
}
