import { Grid } from '@mui/material'
import { AutocompleteFieldCtrl, DatePickerCtrl } from '@components/Form'
import { FormRow } from '../../../FormCommon/FormCommon'
import { getLocaleDateFormat } from '@shared/utils/date'

const contractLengthOptions = [...Array(72).keys()].map((key) => key + 1)

export const StartDateAndLength = () => {
  const helperText = getLocaleDateFormat().toUpperCase()

  return (
    <FormRow>
      <Grid item xs={6}>
        <DatePickerCtrl
          inputProps={{ helperText }}
          fullWidth
          timezone='UTC'
          label='Agreement start date'
          name='suppliers.0.negotiationSettings.contractStartDate'
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteFieldCtrl
          freeSolo={false}
          options={contractLengthOptions}
          getOptionLabel={(option) => `${option} months`}
          variant='outlined'
          valueAsNumber
          fullWidth
          label='Duration'
          name='suppliers.0.negotiationSettings.contractLength'
        />
      </Grid>
    </FormRow>
  )
}
