import { InputError } from 'src/merchandising/types'

export const greaterThanZero = (value: number | null): InputError => {
  if (value === null) {
    return { isError: true, error: 'Must not be empty' }
  }
  const isValid = value > 0
  return { isError: !isValid, error: !isValid ? 'Must be greater than zero' : undefined }
}

export const equalOrGreaterThanZero = (value: number | null): InputError => {
  if (value === null) {
    return { isError: true, error: 'Must not be empty' }
  }
  const isValid = value >= 0
  return { isError: !isValid, error: !isValid ? 'Must be equal or greater than zero' : undefined }
}
