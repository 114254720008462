import { Box, Grid, Typography } from '@mui/material'
import { ValueProjection, ValueProjectionScale } from '@common/dto/dashboard/dashboard'
import { useEffect, useState } from 'react'
import { StatisticsItem } from '../../../../shared/components/Layout/Statistics/StatisticsItem'
import { RangeBar } from '../../../../shared/components/RangeBar/RangeBar'
import { RangeBarScale, scaleValues } from '../../../../shared/components/RangeBar/RangeBarScale'
import { useFilters } from '../useDashboardQueryParams'
import { getTicksFormatting } from './TimeSeriesWidget/TimeSeriesWidget'
import { WidgetSection } from './WidgetSection'

interface Props {
  widgetConfig: ValueProjection
}

interface ValueProjectionState {
  percentage: number
  totalSpend: number
  negotiatedSpend: number
  savings: number

  totalSpendPercentage: number
  negotiatedSpendPercentage: number
  savingsPercentage: number

  customSpendScale?: ValueProjectionScale
}

interface Scales {
  savingsScale: ValueProjectionScale
  spendScale: ValueProjectionScale
}

export const ValueProjectionWidget = ({ widgetConfig }: Props) => {
  const {
    successRate,
    savingsRate,
    spendScale,
    savingsScale,
    concluded,
    customSuccessRate = 0,
    customSavingsRate = 0,
  } = widgetConfig.values

  const { currency } = useFilters()

  const [percentage, setPercentage] = useState<number>(50)

  const [scales, setScales] = useState<Scales>({
    savingsScale: savingsScale ?? 'm',
    spendScale: spendScale ?? 'm',
  })

  const [valueProjectionValues, setValueProjectionValues] = useState<ValueProjectionState>({
    percentage: 50,
    totalSpend: 0,
    negotiatedSpend: 0,
    savings: 0,

    totalSpendPercentage: 0,
    negotiatedSpendPercentage: 0,
    savingsPercentage: 0,
  })

  const maxSpendScale = scaleValues[scales.spendScale][scaleValues[scales.spendScale].length - 1]
  const maxSavingsScale =
    scaleValues[scales.savingsScale][scaleValues[scales.savingsScale].length - 1]

  const useCustomRates =
    (successRate < customSuccessRate && savingsRate < customSavingsRate) || concluded < 10
  const maxSuccessRate = useCustomRates ? customSuccessRate : successRate
  const maxSavingsRate = useCustomRates ? customSavingsRate : savingsRate

  const updatePercent = (percentage: number) => setPercentage(percentage)

  useEffect(() => {
    const maxTotal = Math.round(
      Math.round(maxSpendScale * (maxSuccessRate / 100)) * (maxSavingsRate / 100),
    )

    let selectedSpendScale: ValueProjectionScale = 's'
    let selectedSavingsScale: ValueProjectionScale = 'xxxs'

    if (!spendScale) {
      if ((widgetConfig.values.totalSpend * 10).toString().length >= 8) {
        selectedSpendScale = 'm'
      }

      if ((widgetConfig.values.totalSpend * 10).toString().length >= 9) {
        selectedSpendScale = 'l'
      }
    }

    if (maxTotal <= 1_000_000) {
      selectedSavingsScale = 'xxxxs'
    }

    if (widgetConfig.values.totalSaved > 0 && maxTotal > 1_000_000 && maxTotal <= 5_000_000) {
      selectedSavingsScale = 'xxxs'
    }

    if (maxTotal > 5_000_000 && maxTotal <= 10_000_000) {
      selectedSavingsScale = 'xxs'
    }

    if (maxTotal > 10_000_000 && maxTotal <= 50_000_000) {
      selectedSavingsScale = 'xs'
    }

    if (maxTotal > 50_000_000 && maxTotal <= 100_000_000) {
      selectedSavingsScale = 's'
    }

    if (maxTotal > 100_000_000 && maxTotal <= 500_000_000) {
      selectedSavingsScale = 'sm'
    }

    if (maxTotal > 500_000_000) {
      selectedSavingsScale = 'm'
    }

    if (maxTotal > 1_000_000_000) {
      selectedSavingsScale = 'l'
    }

    setScales({ savingsScale: selectedSavingsScale, spendScale: selectedSpendScale })
  }, [
    concluded,
    maxSavingsRate,
    maxSpendScale,
    maxSuccessRate,
    spendScale,
    widgetConfig.values.totalSaved,
    widgetConfig.values.totalSpend,
  ])

  useEffect(() => {
    setValueProjectionValues((v) => ({
      ...v,
      percentage: percentage,
      totalSpend: Math.round(maxSpendScale * (percentage / 100)),
      negotiatedSpend: Math.round(maxSpendScale * (percentage / 100) * (maxSuccessRate / 100)),
      savings: Math.round(
        Math.round(maxSpendScale * (percentage / 100) * (maxSuccessRate / 100)) *
          (maxSavingsRate / 100),
      ),

      totalSpendPercentage: Math.round((percentage / 100) * 100),
      negotiatedSpendPercentage: Math.round((percentage / 100) * (maxSuccessRate / 100) * 100),
      savingsPercentage:
        (Math.round(
          Math.round(maxSpendScale * (percentage / 100) * (maxSuccessRate / 100)) *
            (maxSavingsRate / 100),
        ) /
          maxSavingsScale) *
        100,
    }))
  }, [
    maxSpendScale,
    maxSavingsScale,
    percentage,
    successRate,
    savingsRate,
    maxSuccessRate,
    maxSavingsRate,
  ])

  const wide = true

  return (
    <Grid item xs={12} lg={wide ? 12 : 6}>
      <Box pt={0} pb={0}>
        <Grid container>
          <Grid item xs={4}>
            <WidgetSection
              title={widgetConfig.title}
              subtitle={widgetConfig.subtitle}
              description={widgetConfig.description}
              wide={true}
            >
              <>
                <StatisticsItem title='Success rate' primaryValue={`${maxSuccessRate}%`} />

                <StatisticsItem title='Savings rate' primaryValue={`${maxSavingsRate}%`} />

                <StatisticsItem
                  title='Total projected savings'
                  primaryValue={Intl.NumberFormat(
                    'en-US',
                    getTicksFormatting('currency', currency),
                  ).format(valueProjectionValues.savings)}
                />
              </>
            </WidgetSection>
          </Grid>

          <Grid item xs={8}>
            <Box pt={5} pb={7} pl={4}>
              <Typography variant='subtitle2'>Spend</Typography>

              <Box pt={2}>
                <RangeBar
                  onChange={updatePercent}
                  displayValue={Intl.NumberFormat(
                    'en-US',
                    getTicksFormatting('currency', currency),
                  ).format(valueProjectionValues.totalSpend)}
                  defaultValue={valueProjectionValues.percentage}
                  description='Drag to increase spend'
                />

                <RangeBar
                  displayValue={Intl.NumberFormat(
                    'en-US',
                    getTicksFormatting('currency', currency),
                  ).format(valueProjectionValues.negotiatedSpend)}
                  value={valueProjectionValues.negotiatedSpendPercentage}
                  description='Successful spend'
                />

                <RangeBarScale scale={scales.spendScale} currency={currency} />

                <RangeBar
                  variant='positive'
                  displayValue={Intl.NumberFormat(
                    'en-US',
                    getTicksFormatting('currency', currency),
                  ).format(valueProjectionValues.savings)}
                  value={valueProjectionValues.savingsPercentage}
                  description='Savings'
                />

                <RangeBarScale scale={scales.savingsScale} currency={currency} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}
