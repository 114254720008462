// Importing from product modules allowed here as an exception
/* eslint-disable import/no-restricted-paths */

import { Supplier } from '@logistics/types/Supplier'
import { NegotiationEvent as PriceListNegotiationEvent } from '@price-list/store/types'
import { NegotiationEvent } from '@procurement/store/types'
import { Settings } from '@store/settings'
import {
  createContext,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  useContext,
  useState,
} from 'react'
import { StatisticsItemData } from './Statistics'

interface LayoutContextValue {
  actionsComponent: ReactNode
  isReadonlyWorkspace: boolean
  negotiationEvent: NegotiationEvent | null
  priceListNegotiationEvent: PriceListNegotiationEvent | null
  setActionsComponent: Dispatch<SetStateAction<ReactNode | null>>
  setIsReadonlyWorkspace: Dispatch<SetStateAction<boolean>>
  setNegotiationEvent: Dispatch<SetStateAction<NegotiationEvent | null>>
  setPriceListNegotiationEvent: Dispatch<SetStateAction<PriceListNegotiationEvent | null>>
  setStatisticsHeaderData: Dispatch<SetStateAction<StatisticsItemData[]>>
  setSupplier: Dispatch<SetStateAction<Supplier | null>>
  setSettings: Dispatch<SetStateAction<Settings | null>>
  statisticsHeaderData: StatisticsItemData[]
  supplier: Supplier | null
  settings: Settings | null
}

export const LayoutContext = createContext<LayoutContextValue>({
  actionsComponent: null,
  isReadonlyWorkspace: false,
  negotiationEvent: null,
  priceListNegotiationEvent: null,
  setActionsComponent: () => {},
  setIsReadonlyWorkspace: () => {},
  setNegotiationEvent: () => {},
  setPriceListNegotiationEvent: () => {},
  setStatisticsHeaderData: () => {},
  setSupplier: () => {},
  setSettings: () => {},
  statisticsHeaderData: [],
  supplier: null,
  settings: null,
})

type LayoutProviderProps = {
  children: ReactNode
}

export const LayoutProvider = (props: LayoutProviderProps) => {
  const [actionsComponent, setActionsComponent] = useState<ReactNode>(null)
  const [isReadonlyWorkspace, setIsReadonlyWorkspace] = useState<boolean>(false)
  const [negotiationEvent, setNegotiationEvent] = useState<NegotiationEvent | null>(null)
  const [priceListNegotiationEvent, setPriceListNegotiationEvent] =
    useState<PriceListNegotiationEvent | null>(null)
  const [statisticsHeaderData, setStatisticsHeaderData] = useState<StatisticsItemData[]>([])
  const [supplier, setSupplier] = useState<Supplier | null>(null)
  const [settings, setSettings] = useState<Settings | null>(null)

  return (
    <LayoutContext.Provider
      value={{
        actionsComponent,
        isReadonlyWorkspace,
        negotiationEvent,
        priceListNegotiationEvent,
        setActionsComponent,
        setIsReadonlyWorkspace,
        setNegotiationEvent,
        setPriceListNegotiationEvent,
        setStatisticsHeaderData,
        setSupplier,
        setSettings,
        statisticsHeaderData,
        supplier,
        settings,
      }}
    >
      {props.children}
    </LayoutContext.Provider>
  )
}

export const useLayoutContext = () => useContext(LayoutContext)
