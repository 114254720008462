import { alpha, styled, Theme, Typography } from '@mui/material'
import { MUIStyledCommonProps } from '@mui/system'
import { useController } from 'react-hook-form'

type ColorPickerProps = {
  name: string
  label: string
  disabled: boolean
}

const Container = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`

const ColorInput = styled('input')(({ theme }) => ({
  background: 'transparent',
  padding: 0,
  borderRadius: theme.shape.borderRadius,
  borderColor: alpha('#000000', 0.3),

  '&::-webkit-color-swatch': {
    borderRadius: '3px',
    border: 'none',
  },
  '&::-webkit-color-swatch-wrapper': {
    padding: '4px',
  },
  '&::-moz-color-swatch': {
    borderRadius: '3px',
    border: 'none',
  },
  '&::-moz-color-swatch-wrapper': {
    padding: '4px',
  },
}))

export const ColorPicker = ({
  name,
  label,
  disabled,
  ...rest
}: ColorPickerProps & MUIStyledCommonProps<Theme>) => {
  const { field } = useController({ name })

  return (
    <Container {...rest}>
      <ColorInput
        type='color'
        name={name}
        onChange={field.onChange}
        value={!field.value ? '#000000' : (field.value as string)}
        disabled={disabled}
      />

      <Typography variant='body2' style={{ marginLeft: '8px' }}>
        {label}
      </Typography>
    </Container>
  )
}
