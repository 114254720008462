import { createAction, Priority, useRegisterActions } from 'kbar'
import { useGetOrganizationBrandingsQuery } from 'src/main/store/branding'
import { Organization } from '@pactum/core-backend-types'
import { useBrandedChatLink } from '@shared/hooks/useBrandedChatLink'
import { resultAvatar } from '@components/CommandBar/CommandBar'

export function useBrandingActions(
  activeOrg: Pick<Organization, 'id' | 'tag'>,
  isAdmin: boolean,
): void {
  const { data } = useGetOrganizationBrandingsQuery(activeOrg.tag)

  const { clearBranding, setBranding, getBrandingUuid } = useBrandedChatLink()
  const actions = []

  const currentBrandUuid = getBrandingUuid()

  const mainAction = createAction({
    name: 'Apply branding',
    section: 'Demos',
    keywords: 'brand branding',
    priority: Priority.NORMAL,
  })

  if (data && data.length) {
    actions.push(mainAction)
  }

  const activeBranding = (data ?? []).find((branding) => branding.uuid === currentBrandUuid)

  if (activeBranding) {
    actions.push(
      createAction({
        name: `Clear ${activeBranding.label} branding`,
        section: 'Demos',
        keywords: 'brand clear',
        icon: resultAvatar(activeBranding.label, activeBranding.botAvatar),
        perform: () => {
          clearBranding()
        },
      }),
    )
  }

  actions.push(
    ...(data ?? []).map((branding) =>
      createAction({
        name: branding.label,
        parent: mainAction.id,
        icon: resultAvatar(branding.label, branding.botAvatar),
        perform: () => {
          setBranding(branding)
        },
      }),
    ),
  )

  useRegisterActions(actions, [currentBrandUuid, isAdmin, activeOrg.id, data])
}
