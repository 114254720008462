import { alpha, Grid, Paper, styled } from '@mui/material'
import { MultiFileUpload } from '@components/MultiFileUpload'

export const StyledFileUpload = styled(MultiFileUpload)(({ theme }) => ({
  background: 'transparent',
  height: '104px',
  border: `1px dashed ${theme.palette.grey.A400}`,
  cursor: 'pointer',
}))

export const StyledBackground = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(0.5),
  background: alpha(theme.palette.borders.main, 0.3),
}))

export const StyledGrid = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(3, 0, 0, 0),
}))
