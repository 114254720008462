import { useFormatter } from '@shared/hooks'
import { CaptionedValueCell, NotAvailableCell } from 'src/shared/components/table'
import { getPriceChangeDirectionColor } from '../utils'

interface Props {
  currentIncomePerArticle: number | undefined
  newIncomePerArticle: number | undefined
  lastYearVolume: number | undefined
}

export const IncomeWithYearlyCell = ({
  currentIncomePerArticle,
  newIncomePerArticle,
  lastYearVolume,
}: Props) => {
  const formatter = useFormatter()
  if (!newIncomePerArticle || !lastYearVolume) {
    return <NotAvailableCell />
  }
  const currentIncomePerArticleOrZero = currentIncomePerArticle ? currentIncomePerArticle : 0
  const lastYearIncome = currentIncomePerArticleOrZero * lastYearVolume
  const projectedIncome = newIncomePerArticle * lastYearVolume
  const changeValue = projectedIncome - lastYearIncome
  const color = getPriceChangeDirectionColor(changeValue)
  return (
    <CaptionedValueCell
      mainContent={formatter.currency(projectedIncome, { fractionDigits: 0 })}
      captionContent={`From ${formatter.currency(lastYearIncome, { fractionDigits: 0 })}`}
      removeCaptionOpacity={!!color}
    />
  )
}
