import { ProductType } from '@pactum/core-backend-types'

interface UILabels {
  listViewSearchPlaceholder: string
  listViewTitle: string
  listViewIdColumn: string
  detailsViewLeftColumnTitle: string
  detailsViewIdLine: string
  newNegotiationButtonLabel: string
  negotiationEventFormNewTitle: string
  negotiationEventFormEditTitle: string
  negotiationEventFormDetailsTitle: string
  negotiationEventFormDescriptionHelperText: string
  negotiationEventFormReferenceIdHelperText: string
  negotiationEventFormSubmitButtonText: string
  negotiationEventFormDeliveryDate: string
  negotiationEventFormLineItemNumberInCollection: string
  actionItemName: string
  contractScopeOfServicesDescription: string
}

const purchasingLabels: UILabels = {
  listViewSearchPlaceholder: 'Search requisitions',
  listViewTitle: 'Requisitions',
  listViewIdColumn: 'PR number',
  detailsViewLeftColumnTitle: 'Purchase details',
  detailsViewIdLine: 'PR number',
  newNegotiationButtonLabel: 'New negotiation',
  negotiationEventFormNewTitle: 'New negotiation',
  negotiationEventFormEditTitle: 'Edit negotiation',
  negotiationEventFormDetailsTitle: 'Purchase details',
  negotiationEventFormDescriptionHelperText: 'Also known as requisition name or sourcing name',
  negotiationEventFormReferenceIdHelperText: 'Requisition or sourcing ID',
  negotiationEventFormSubmitButtonText: 'Create negotiation',
  negotiationEventFormDeliveryDate: 'Delivery date',
  negotiationEventFormLineItemNumberInCollection: 'Item ID',
  actionItemName: 'requisition',
  contractScopeOfServicesDescription:
    'Provide the Software name, description of the Software and outline the work to be done on this project, such as deliverables, timeline and milestones. The SOW is important for both the client and the contractor, as it helps to ensure that everyone is on the same page and that the project is completed successfully.',
}

const contractCostLabels: UILabels = {
  listViewSearchPlaceholder: 'Search events',
  listViewTitle: 'Negotiation events',
  listViewIdColumn: 'ID',
  detailsViewLeftColumnTitle: 'Event details',
  detailsViewIdLine: 'ID',
  newNegotiationButtonLabel: 'New negotiation event',
  negotiationEventFormNewTitle: 'New negotiation event',
  negotiationEventFormEditTitle: 'Edit negotiation event',
  negotiationEventFormDetailsTitle: 'Negotiation event details',
  negotiationEventFormDescriptionHelperText: '',
  negotiationEventFormReferenceIdHelperText: '',
  negotiationEventFormSubmitButtonText: 'Create negotiation event',
  negotiationEventFormDeliveryDate: 'Need by date',
  negotiationEventFormLineItemNumberInCollection: '#',
  actionItemName: 'negotiation event',
  contractScopeOfServicesDescription:
    'Provide the name and description of the goods or services being procured, and outline any work to be done, such as deliverables, timelines, etc. The SOW is important for both the client and the supplier, as it helps to ensure that everyone is on the same page.',
}

export const labelForProduct = (productType: ProductType | undefined, label: keyof UILabels) => {
  switch (productType) {
    case ProductType.Purchasing:
      return purchasingLabels[label]
    case ProductType.ContractCost:
      return contractCostLabels[label]
    default:
      return purchasingLabels[label]
  }
}
