import { NegotiationEvent, NegotiationEventSupplier } from '../../../store/types'
import { DataCell, DataCellProps } from '@procurement-shared/DataCell/DataCell'
import { Formatter } from '@utils'
import { formatCurrency } from '@shared/utils/formatCurrency'

export const MaximumAcceptablePriceCell = ({
  negotiationEvent,
  supplier,
  size,
  formatter,
}: {
  negotiationEvent: NegotiationEvent
  supplier: NegotiationEventSupplier
  size: DataCellProps['size']
  formatter: Formatter
}) => {
  const supplierQuotedAmount = supplier.lineItems.reduce(
    (acc, item) => acc + (item.quotedUnitPrice ?? 0) * item.quantity,
    0,
  )
  const maxTotalPrice = supplier.maxAcceptablePrice
    ? supplier.maxAcceptablePrice
    : supplierQuotedAmount
  const hasMaxTotalPrice = Boolean(maxTotalPrice)

  return (
    <DataCell label={'Maximum acceptable price'} size={size}>
      {!hasMaxTotalPrice && '-'}
      {hasMaxTotalPrice && formatCurrency(maxTotalPrice, negotiationEvent.currency, formatter)}
    </DataCell>
  )
}
