import { SuitePermission } from '@common/types'
import { ProtectedRoute } from '@components/ProtectedRoute'
import { useUser, useUserPermissions } from '@hooks'
import { OrganizationSettingsPage } from '@pages/SettingsPage/OrganizationSettingsPage'
import { Navigate, Route, Routes } from 'react-router-dom'
import { SettingsPageContainer } from './SettingsPageContainer'
import { UserManagementPage } from './UserManagementPage'
import { WorkspacesSettingsPage } from './WorkspacesSettingsPage'
import { SettingsRoutes } from './routes'

export const SettingsEntrypoint = () => {
  const { user } = useUser()
  const isInternalUser = !!user?.email?.endsWith('@pactum.com')
  const { userPermissionsInActiveOrg } = useUserPermissions()
  const canManageConfig = userPermissionsInActiveOrg?.has(SuitePermission.SUITE_CONFIG_MANAGE)

  return (
    <>
      <Routes>
        <Route path={'*'} element={<Navigate to={SettingsRoutes.ROOT.replace('/*', '')} />} />
        <Route
          path={SettingsRoutes.ROOT}
          element={
            <ProtectedRoute anyOf={[SuitePermission.READ, SuitePermission.SUITE_CONFIG_MANAGE]}>
              <OrganizationSettingsPage
                isInternalUser={isInternalUser}
                canManageConfig={canManageConfig}
              />
            </ProtectedRoute>
          }
        />

        <Route
          path={SettingsRoutes.WORKSPACES}
          element={
            <ProtectedRoute anyOf={[SuitePermission.READ, SuitePermission.SUITE_CONFIG_MANAGE]}>
              <SettingsPageContainer>
                <WorkspacesSettingsPage
                  isInternalUser={isInternalUser}
                  canManageConfig={canManageConfig}
                />
              </SettingsPageContainer>
            </ProtectedRoute>
          }
        />

        <Route
          path={SettingsRoutes.USERS}
          element={
            <ProtectedRoute anyOf={[SuitePermission.SUITE_USERS_MANAGE]}>
              <SettingsPageContainer>
                <UserManagementPage />
              </SettingsPageContainer>
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  )
}
