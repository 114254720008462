import { NegotiationEvent, NegotiationEventSupplier } from '../../../store/types'
import { DataCell, DataCellProps } from '@procurement-shared/DataCell/DataCell'
import { Formatter } from '@utils'
import { getBaselineSupplier } from '../../../utils/negotiationEventGetters'
import { isNumber } from 'lodash/fp'
import { TermItem, TermsTooltip } from '@procurement-shared/TermsTooltip/TermsTooltip'
import { formatCurrency } from '@shared/utils/formatCurrency'

export const RebatesCell = ({
  negotiationEvent,
  supplier,
  size,
  formatter,
}: {
  negotiationEvent: NegotiationEvent
  supplier: NegotiationEventSupplier
  size: DataCellProps['size']
  formatter: Formatter
}) => {
  const { negotiationSettings, negotiation } = supplier
  const { currency } = negotiationEvent
  const baselineSupplier = getBaselineSupplier({ negotiationEvent: negotiationEvent, supplier })
  const { negotiationSettings: baselineNegotiationSettings } = baselineSupplier
  const supplierRebateBenchmark = negotiationSettings?.rebates?.benchmark
  const baselineRebate = baselineNegotiationSettings?.rebates?.benchmark

  const formattedBaseLineRebateValue = isNumber(baselineRebate)
    ? `${formatter.percent0To1(baselineRebate / 100)}`
    : '-'
  const formattedBaseLineRebate = isNumber(baselineRebate)
    ? `Benchmark: ${formattedBaseLineRebateValue}`
    : undefined

  if (
    negotiation === null &&
    (negotiationSettings?.rebates?.minimum || negotiationSettings?.rebates?.target)
  ) {
    return (
      <DataCell label={'Rebate'} size={size} footnote={formattedBaseLineRebate}>
        {negotiationSettings?.rebates?.minimum &&
          `${formatter.percent0To1(negotiationSettings?.rebates?.minimum / 100)}`}
        {negotiationSettings?.rebates?.target &&
          ` - ${formatter.percent0To1(negotiationSettings?.rebates?.target / 100)}`}
      </DataCell>
    )
  }

  if (negotiation?.outcomes?.rebate) {
    const isMultiSupplier = negotiationEvent.suppliers.length > 1
    const rebateSavings = negotiation?.outcomes?.savingsBreakdown?.rebate ?? 0
    const terms: TermItem[] = isMultiSupplier
      ? [
          {
            title: 'Original',
            value: isNumber(supplierRebateBenchmark)
              ? formatter.percent0To1(supplierRebateBenchmark / 100)
              : '-',
          },
          { title: 'Baseline', value: formattedBaseLineRebateValue },
          { title: 'Agreed', value: formatter.percent0To1(negotiation.outcomes.rebate / 100) },
          { title: 'Savings', value: formatCurrency(rebateSavings, currency, formatter) },
        ]
      : []

    const formattedBaseLineRebate = isNumber(baselineRebate)
      ? `Baseline: ${formattedBaseLineRebateValue}`
      : undefined

    return (
      <TermsTooltip terms={terms}>
        <DataCell label={'Agreed rebate'} size={size} footnote={formattedBaseLineRebate}>
          {formatter.percent0To1(negotiation.outcomes.rebate / 100)}
        </DataCell>
      </TermsTooltip>
    )
  }
  return null
}
