import { useState } from 'react'
import EditOff from '@mui/icons-material/EditOff'
import { MarkAsNotSignedConfirmationModal } from 'src/shared/components/ConfirmationModal'
import { BatchActionButton } from './BatchActionButton'
import { CommercialTermsNegotiation } from '@campaigns/types/negotiation'

export type DownloadActionProps = {
  enabled: boolean
  totalNegotiationsCount: number
  actionableNegotiations: CommercialTermsNegotiation[]
  onExecute: () => void
}

export const MarkAsNotSignedAction = ({
  enabled,
  totalNegotiationsCount,
  actionableNegotiations,
  onExecute,
}: DownloadActionProps) => {
  const [isModalOpen, setModalOpen] = useState(false)

  return (
    <>
      <BatchActionButton
        startIcon={<EditOff />}
        disabled={!enabled}
        onClick={() => setModalOpen(true)}
      >
        Mark as not signed
      </BatchActionButton>
      <MarkAsNotSignedConfirmationModal
        allNegotiations={totalNegotiationsCount}
        actionableNegotiations={actionableNegotiations.length}
        open={isModalOpen}
        onCancel={() => setModalOpen(false)}
        onSubmit={() => {
          setModalOpen(false)
          onExecute()
        }}
      />
    </>
  )
}
