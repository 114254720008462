import {
  ConfirmationModal,
  ConfirmationModalProps,
} from '@shared/components/ConfirmationModal/ConfirmationModal'
import { ReactNode, useState } from 'react'

interface ConfirmOptions {
  type: 'positive' | 'negative'
  buttonText?: string
  title: string
  content?: string
}

const defaultDialogOptions: ConfirmationModalProps = {
  confirmType: 'positive',
  confirmButtonText: 'Yes',
  open: false,
  title: '',
  content: '',
  onCancel: () => {},
  onSubmit: () => {},
}

interface UseConfirm {
  confirm: (options: ConfirmOptions, fn: () => void) => void
  dialogProps: ConfirmationModalProps
  dialog: ReactNode
}

export const useConfirm = (): UseConfirm => {
  const [dialogProps, setDialogProps] = useState<ConfirmationModalProps>(defaultDialogOptions)

  const close = () => setDialogProps({ ...dialogProps, open: false })

  const confirm = ({ type, title, content, buttonText }: ConfirmOptions, fn: () => void) =>
    setDialogProps({
      confirmType: type,
      open: true,
      title,
      content,
      confirmButtonText: buttonText || 'Yes',
      onCancel: close,
      onSubmit: () => {
        fn()
        close()
      },
    })

  const dialog = <ConfirmationModal {...dialogProps} />

  return {
    confirm,
    dialogProps,
    dialog,
  }
}
