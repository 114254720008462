import { Box, styled } from '@mui/material'
import { COMMENT_POPUP_CONTAINER_CLASS, CommentPopup } from './CommentPopup'
import React, { useEffect, useState } from 'react'
import { BenefitInputs, UserInput } from '@common/types'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useScenarioDetailsData } from '../../pages/Scenarios/ScenarioPreparation/ScenarioPreparation'
import { isBackendApiErrorResponse, useUpdateScenarioInputsMutation } from '../../store'
import { useSnackbar } from 'notistack'
import { UpdateScenarioRequest } from '@common/dto'
import { CommentTooltip } from './CommentTooltip'

interface BenefitCellWrapperProps {
  commercialId: string
  benefit: keyof BenefitInputs
  readonly: boolean
}

export const CommentableBenefitCellWrapper = ({
  commercialId,
  benefit,
  children,
  readonly,
}: React.PropsWithChildren<BenefitCellWrapperProps>) => {
  const { activeProjectTag } = useActiveProject()
  const { detailsData, setDetailsData, sessionKey } = useScenarioDetailsData()
  const [triggerRowUpdate] = useUpdateScenarioInputsMutation()
  const { enqueueSnackbar } = useSnackbar()
  const [comment, setComment] = useState<string | undefined>(
    getBenefitCommentUserInput(commercialId, benefit, detailsData.userInputs),
  )

  useEffect(() => {
    // Update strategy when Reset values clicked
    setComment(getBenefitCommentUserInput(commercialId, benefit, detailsData.userInputs))
  }, [commercialId, benefit, detailsData])

  const updateComment = async (comment: string) => {
    setComment(comment)

    try {
      const payload = generateUpdatePayload(commercialId, benefit, comment, detailsData.userInputs)
      const updatedScenarioData = await triggerRowUpdate({
        sessionKey,
        payload,
        projectTag: activeProjectTag,
      }).unwrap()

      setDetailsData({
        ...detailsData,
        userInputs: updatedScenarioData.userInputs,
        state: {
          ...detailsData.state,
          ...updatedScenarioData.state,
        },
      })
    } catch (err) {
      enqueueSnackbar(
        isBackendApiErrorResponse(err)
          ? err.data.message
          : 'There was an error during scenario update',
        { variant: 'error' },
      )
    }
  }

  return (
    <CellWithComment className={COMMENT_POPUP_CONTAINER_CLASS}>
      <Box className='cell-content' sx={{ minWidth: 0, overflow: 'hidden' }}>
        {children}
      </Box>
      <Box className='cell-comment' component='span'>
        {readonly ? (
          <CommentTooltip comment={comment} />
        ) : (
          <CommentPopup
            comment={comment}
            onChange={(updatedComment) => updateComment(updatedComment)}
          />
        )}
      </Box>
    </CellWithComment>
  )
}

const CellWithComment = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  '& > .cell-comment > span': {
    marginLeft: theme.spacing(0.5),
    verticalAlign: 'middle',
  },
})) as typeof Box

const generateUpdatePayload = (
  updatedItemCommercialId: string,
  updatedBenefit: keyof BenefitInputs,
  comment: string | undefined,
  currentUserInput: UserInput,
): UpdateScenarioRequest => ({
  userInputs: {
    ...currentUserInput,
    items: currentUserInput.items.map((item) =>
      item.commercial_id === updatedItemCommercialId
        ? {
            ...item,
            benefits: {
              ...item.benefits,
              [updatedBenefit]: {
                ...item.benefits[updatedBenefit],
                comment,
              },
            },
          }
        : item,
    ),
  },
})

const getBenefitCommentUserInput = (
  commercialId: string,
  benefit: keyof BenefitInputs,
  userInput: UserInput,
): string | undefined => {
  const foundItem = userInput.items.find((item) => item.commercial_id === commercialId)
  return foundItem?.benefits[benefit]?.comment
}
