export interface RequestForPrice {
  id: number
  project_tag: string
  name: string
  file_url: string

  contract_start: string
  contract_end: string
  expires_at: string
  created_at: string
  updated_at: string

  total_lane_count: number
  priced_lane_count: number

  status: RequestForPriceStatus

  created_by: string
  created_from: RequestForPriceCreatedFrom

  allowed_actions: RequestForPriceAction[]
  disabled_actions: RequestForPriceAction[]
}

export enum RequestForPriceCreatedFrom {
  SUITE = 'SUITE',
  API = 'API',
}

export enum RequestForPriceStatus {
  PENDING = 'PENDING', // uploaded, units not created yet
  READY = 'READY', // processed, created units
  IN_PROGRESS = 'IN_PROGRESS', // at least one round of negotiations created
  CLOSED = 'CLOSED', // final round of negotiations concluded
  DELETED = 'DELETED', // soft deleted
}

export enum RequestForPriceAction {
  REVIEW = 'REVIEW',
  DOWNLOAD = 'DOWNLOAD',
  START = 'START',
  DELETE = 'DELETE',
  CREATE_FOLLOWUPS = 'CREATE_FOLLOWUPS',
  RECREATE_NEGOTIATIONS = 'RECREATE_NEGOTIATIONS',
}

export interface ContractedLanesUnit {
  id: number
  projectLocale: string
  status: ContractedLanesUnitStatus
  attributes: {
    origin: LocationData
    destination: LocationData
    equipmentType: string
    equipmentSize: string
    distanceKm: number
    isHazardous: boolean
    hazardousClass?: string
    shipmentMode: 'FCL' | 'LCL'
    tripType: 'One Way' | 'Roundtrip'
    lane_id: string
    requires_team: boolean
    max_price?: number
    max_price_currency?: string
    expected_annual_volume?: number
    bid_max_percentage_of_volume?: number
  }

  negotiations: ContractedLanesNegotiationUnit[]
}

export enum ContractedLanesUnitStatus {
  NOT_STARTED = 'NOT_STARTED',
  ONGOING = 'ONGOING',
  RATE_CAPTURED = 'RATE_CAPTURED',
  AGREEMENT_REACHED = 'AGREEMENT_REACHED',
  EXPIRED = 'EXPIRED',
}

export interface LocationData {
  fullName: string
  countryCode: string
  city: string
  postcode: string
  latitude: number
  longitude: number
  region?: string
  clientSpecificCode?: string
}

export type ContractedLanesNegotiationUnit = {
  negotiation: {
    id: number
    status: ContractedLanesNegotiationStatus
    chat_started_at: string | null
    chat_url_client: string | null
    chat_url_test: string | null
    is_visible_supplier: boolean
  }
  supplier: {
    id: string
    created_at: string
    updated_at: string
    name: string
    rating: number
    organization_id: string
    external_id: string
    mc_number: null
    is_enabled: boolean
    attributes: unknown
    contacts: {
      id: string
      suppler_id: string
      is_primary: boolean
      email: string
    }[]
  }
  negotiationUnit: {
    id: number
    negotiation_id: number
    unit_id: number
    status: ContractedLanesNegotiationToUnitStatus
    attributes: {
      anchor: number
      limit: number
      currency: string
      referenceType: 'SAME_CORRIDOR' | 'SIMILAR_CORRIDOR' | 'FAKE'
    }
    outcomes?: {
      capturedRate: number
      declineReason: string | null
      declineReasonFeedback: string | null
    }
    created: string
  }
}

export enum ContractedLanesNegotiationStatus {
  NOT_STARTED = 'NOT_STARTED',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
  LINK_EMAIL_0 = 'LINK_EMAIL_0',
  LINK_EMAIL_1 = 'LINK_EMAIL_1',
  ONGOING = 'ONGOING',
  DECLINED_CONCLUDED = 'DECLINED_CONCLUDED',
  AGREEMENT_REACHED = 'AGREEMENT_REACHED',
  PENDING = 'PENDING',
}

export enum ContractedLanesNegotiationToUnitStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  NOT_STARTED = 'NOT_STARTED',
}
