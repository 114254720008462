import { styled } from '@mui/material'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {
  DataGridPro,
  DataGridProProps,
  UncapitalizedGridSlotsComponent,
} from '@mui/x-data-grid-pro'
import { DataGridLoader } from 'src/shared/components/DataGridLoader'
import { PaginationFooter } from '@components/table/pagination/PaginationFooter'

const dataGridComponents: Partial<UncapitalizedGridSlotsComponent> = {
  loadingOverlay: DataGridLoader,
  columnSortedAscendingIcon: () => <ArrowDropUpIcon fontSize='medium' />,
  columnSortedDescendingIcon: () => <ArrowDropDownIcon fontSize='medium' />,
  detailPanelExpandIcon: () => <ArrowDropDownIcon fontSize='medium' sx={{ rotate: '-90deg' }} />,
  detailPanelCollapseIcon: () => <ArrowDropDownIcon fontSize='medium' />,
  pagination: PaginationFooter,
}

export const HEADER_HEIGHT = 24
export const ROW_HEIGHT = 52
export const CELL_X_PADDING = 10

export const dataGridProps: Partial<DataGridProProps> = {
  autoHeight: true,
  hideFooter: true,
  disableColumnReorder: true,
  disableColumnResize: true,
  slots: dataGridComponents,
  isRowSelectable: () => false,
  columnHeaderHeight: HEADER_HEIGHT,
  rowHeight: ROW_HEIGHT,
}

export const DataGrid = styled(DataGridPro)(({ theme }) => ({
  '&.MuiDataGrid-root': {
    position: 'relative',
    left: '-10px', // compensate paddings for the first cell
    width: 'calc(100% + 10px)',
    border: 0,
  },
  '& .MuiDataGrid-detailPanelToggleCell': {
    padding: theme.spacing(0.75),
  },
  '&.align-items-start .MuiDataGrid-cell': {
    marginTop: theme.spacing(0.75),
    display: 'flex',
    alignItems: 'flex-start',
  },
  '&.dg-expandable .MuiDataGrid-cell:first-of-type': {
    position: 'relative',
    left: '12px',
    justifyContent: 'flex-end',
  },
  '& .MuiDataGrid-cell': {
    border: 0,
    '&.align-items-center': {
      alignItems: 'center',
    },
    '&:focus': {
      outline: 'none',
    },
    '&:focus-within': {
      outline: 'none',
    },
  },
  '& .MuiDataGrid-columnHeaders': {
    color: theme.palette.text.secondary,
    fontSize: '0.625rem',
    border: 0,
    '.MuiDataGrid-columnHeader': {
      '&--sorted': {
        color: theme.palette.accent.main,
        '.MuiSvgIcon-root': {
          color: theme.palette.accent.main,
        },
      },
      '&:focus': {
        outline: 'none',
      },
      '&.MuiDataGrid-columnHeader--alignRight .MuiDataGrid-iconButtonContainer': {
        marginRight: 'auto',
      },
      ' .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-iconButtonContainer': {
        marginLeft: 'auto',
      },
      ' .MuiDataGrid-iconButtonContainer .MuiButtonBase-root': {
        padding: 0,
      },
    },
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-row': {
    cursor: 'pointer',
  },
  '& .MuiDataGrid-cellContent': {
    lineHeight: '1.5rem',
  },

  '& .MuiDataGrid-footerContainer': {
    border: 'none',
    paddingLeft: theme.spacing(4.5),
  },
  '& .MuiDataGrid-overlayWrapperInner': {
    position: 'relative',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
})) as typeof DataGridPro
