import { Box } from '@mui/material'
import { LAYOUT_CONTAINER_X_PADDING } from '@constants'
import { SpotLanesList } from '@logistics/pages/spotLane/ActiveLanes/SpotLanesList'

export const SpotLanesPage = () => {
  return (
    <Box px={LAYOUT_CONTAINER_X_PADDING} py={4}>
      <SpotLanesList />
    </Box>
  )
}
