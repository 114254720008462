import { generatePath, Navigate, useLocation } from 'react-router-dom'

import { LoadingPage } from 'src/main/pages/LoadingPage'
import { ErrorPage } from 'src/main/pages/ErrorPage'
import { useProjects } from '@hooks'
import { Organization, ProductType } from '@pactum/core-backend-types'
import { homeRouteWithoutOrg, MerchandisingRoutes } from './routes'
import { merchandisingSuiteModule } from './index'

export const RedirectToProjectPath = () => {
  const { orgs, activeOrgTag, projects, activeProjectTag, loadingOrgs, loadingProjects } =
    useProjects()

  const location = useLocation()

  if (!orgs || !projects || loadingOrgs || loadingProjects) {
    return <LoadingPage />
  }

  if (!activeOrgTag) {
    throw new Error('Active organization is missing, something went wrong')
  }

  if (activeProjectTag) {
    throw new Error('Already have active project tag, something went wrong')
  }

  const currentOrg = orgs.find((org) => org.tag === activeOrgTag)!

  const possibleCostDecreaseProject = getCostDecreaseProject(currentOrg)
  if (possibleCostDecreaseProject) {
    if (isMerchandisingHomeRoute(location.pathname)) {
      return (
        <Navigate
          to={generatePath(MerchandisingRoutes.SCENARIOS_LIST, {
            orgTag: activeOrgTag,
            projectTag: possibleCostDecreaseProject.tag,
          })}
        />
      )
    }
    // TODO not needed after deprecated scenario paths are not used anymore
    const pathNameWithProjectTag = location.pathname.replace(
      `/${merchandisingSuiteModule.basePath}/`,
      `/${merchandisingSuiteModule.basePath}/:projectTag/`,
    )
    return (
      <Navigate
        to={generatePath(pathNameWithProjectTag, {
          orgTag: activeOrgTag,
          projectTag: possibleCostDecreaseProject.tag,
        })}
      />
    )
  }

  return (
    <ErrorPage title='Not found'>
      <p>No cost decrease project found for current organization.</p>
    </ErrorPage>
  )
}

const isMerchandisingHomeRoute = (path: string) => path.endsWith(homeRouteWithoutOrg)

const getCostDecreaseProject = (org: Organization) =>
  org.projects.find(
    (project) =>
      project.productType === ProductType.RetailCostDecrease &&
      project.compatibleWithNegotiationSuite,
  )
