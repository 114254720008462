import { MiddleEllipsis } from '@components/MiddleEllipsis'
import { CaptionedValueCell } from '@components/table'
import { TooltipCell } from '@components/TooltipCell'
import { Box, Typography } from '@mui/material'
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridRenderCellParams,
  GridSortCellParams,
} from '@mui/x-data-grid-pro'
import { ProductType } from '@pactum/core-backend-types'
import { dateFormats, Formatter } from '@utils'
import { addDays, parseISO } from 'date-fns'
import { NegotiationSavings } from '@procurement-shared/NegotiationSavings/NegotiationSavings'
import { NegotiationEventListStatusChip } from '@procurement-shared/NegotiationStatusChip/NegotiationStatusChip'
import { NegotiationEventListItem, PaymentTerms, UseCase } from '../store/types'
import { formatContractDate } from './formatting'
import { labelForProduct } from './labelForProduct'
import { NegotiationEventActions } from '../pages/NegotiationEventList/NegotiationEventListTable/NegotiationEventActions'
import {
  formatCurrentTerms,
  formatDeadline,
  formatInitialAmount,
  formatSupplierName,
} from './columnFormatters'
import { isNumber } from '@shared/utils/type-guards'
import { calculateSpendAmount } from './spend-calculation'

const ACTIONS_BUTTON_WIDTH = 144
const TABLE_CELL_PADDING = 10
const ACTIONS_BUTTON_COLUMN_WIDTH = ACTIONS_BUTTON_WIDTH + 2 * TABLE_CELL_PADDING

const COMMON_COLUMN_PARAMETERS = {
  disableColumnMenu: true,
  filterable: true,
  resizable: true,
  flex: 1,
}

export const uniqueName = (productType?: ProductType): GridColDef<NegotiationEventListItem> => ({
  ...COMMON_COLUMN_PARAMETERS,
  field: 'uniqueName',
  headerName: labelForProduct(productType, 'listViewIdColumn'),
  flex: 2,
  type: 'string',
  renderCell: ({
    row,
    value,
  }: GridRenderCellParams<NegotiationEventListItem, NegotiationEventListItem['uniqueName']>) => (
    <CaptionedValueCell
      captionContent={
        <Typography noWrap variant='inherit'>
          {row.title}
        </Typography>
      }
      mainContent={
        <MiddleEllipsis>
          <Box component='span'>{value}</Box>
        </MiddleEllipsis>
      }
      tooltip={{
        id: `${row.title} ${value}`,
        title: `${value}\n${row.title}`,
      }}
    />
  ),
})

export const supplierName: GridColDef<NegotiationEventListItem> = {
  ...COMMON_COLUMN_PARAMETERS,
  field: 'supplierName',
  headerName: 'Supplier company name',
  flex: 2,
  type: 'string',
  valueGetter: (params: { row: NegotiationEventListItem }) =>
    params.row.suppliers?.map(({ name }) => name).join(','),
  renderCell: ({ row }: { row: NegotiationEventListItem }) => formatSupplierName(row),
}

export const buyerContactEmail: GridColDef<NegotiationEventListItem> = {
  ...COMMON_COLUMN_PARAMETERS,
  field: 'buyerContactEmail',
  headerName: 'Buyer email',
  flex: 2,
  type: 'string',
  renderCell: ({
    value,
  }: GridRenderCellParams<
    NegotiationEventListItem,
    NegotiationEventListItem['buyerContactEmail']
  >) => <TooltipCell tooltip={value}>{value}</TooltipCell>,
}

export const negotiationsExpireTime = (
  formatter: Formatter,
  negotiationEvents: NegotiationEventListItem[],
): GridColDef<NegotiationEventListItem> => ({
  ...COMMON_COLUMN_PARAMETERS,
  field: 'negotiationsExpireTime',
  headerName: 'Deadline',
  type: 'string',
  flex: 1.5,
  sortComparator: (
    firstValue: NegotiationEventListItem['negotiationsExpireTime'],
    secondValue: NegotiationEventListItem['negotiationsExpireTime'],
    firstParam: GridSortCellParams,
    secondParam: GridSortCellParams,
  ) => {
    const firstRow = negotiationEvents.find((requisition) => requisition.id === firstParam.id)
    const secondRow = negotiationEvents.find((requisition) => requisition.id === secondParam.id)

    let firstDate = new Date(firstValue)
    let secondDate = new Date(secondValue)

    if (firstValue === null && firstRow?.negotiationsExpireDays) {
      firstDate = addDays(new Date(), firstRow.negotiationsExpireDays)
    }

    if (secondValue === null && secondRow?.negotiationsExpireDays) {
      secondDate = addDays(new Date(), secondRow.negotiationsExpireDays)
    }

    return firstDate.getTime() - secondDate.getTime()
  },
  renderCell: ({ row }: { row: NegotiationEventListItem }) =>
    formatDeadline(row.negotiationsExpireTime, row.negotiationsExpireDays, formatter),
})

export const contractStartDate = (formatter: Formatter): GridColDef<NegotiationEventListItem> => ({
  ...COMMON_COLUMN_PARAMETERS,
  field: 'contractStartDate',
  flex: 1.5,
  type: 'string',
  headerName: 'Contract start',
  renderCell: ({ value }: GridRenderCellParams) =>
    value ? formatContractDate(value, formatter) : '-',
})

export const initialAmount = (
  formatter: Formatter,
  useCase?: UseCase,
): GridColDef<NegotiationEventListItem> => ({
  ...COMMON_COLUMN_PARAMETERS,
  field: 'initialAmount',
  headerName: 'Original amount',
  flex: 2,
  type: 'number',
  sortComparator: (
    firstValue: NegotiationEventListItem['initialAmount'],
    secondValue: NegotiationEventListItem['initialAmount'],
  ) => {
    if (firstValue === null) {
      return -1
    }

    if (secondValue === null) {
      return 1
    }

    return firstValue - secondValue
  },
  renderCell: ({ row }: { row: NegotiationEventListItem }) => {
    let initialAmount = row.initialAmount
    const isSpendNegotiation = isNumber(row.previousContractLength) as boolean

    if (isSpendNegotiation) {
      initialAmount = calculateSpendAmount({
        spendItemUnitPrice: row.initialAmount,
        spendDurationMonths: row.previousContractLength,
        agreementDurationMonths: row.contractLength,
      })
    }

    return formatInitialAmount(
      initialAmount,
      row.currency,
      row.negotiationStatus,
      formatter,
      useCase,
    )
  },
})

export const paymentTerm = (useCase?: UseCase): GridColDef<NegotiationEventListItem> => ({
  ...COMMON_COLUMN_PARAMETERS,
  field: 'paymentTerm',
  flex: 1.4,
  type: 'string',
  headerName: 'Current terms',
  valueGetter: ({ row }) =>
    (row.negotiatedPaymentTerms ?? row.initialPaymentTerms ?? ({} as PaymentTerms))
      .paymentDaysObject?.netDays,
  renderCell: ({ row }: { row: NegotiationEventListItem }) =>
    formatCurrentTerms(row, row.negotiationStatus, useCase),
})

export const savings: GridColDef<NegotiationEventListItem> = {
  ...COMMON_COLUMN_PARAMETERS,
  field: 'savings',
  headerName: 'Savings',
  headerAlign: 'right',
  flex: 1.8,
  type: 'number',
  renderCell: ({ row }: { row: NegotiationEventListItem }) => (
    <NegotiationSavings
      savings={row.savings}
      improvement={row.improvement}
      currency={row.currency}
    />
  ),
}

export const createdAt: GridColDef<NegotiationEventListItem> = {
  ...COMMON_COLUMN_PARAMETERS,
  field: 'createdAt',
  headerName: 'Created at',
  type: 'dateTime',
}

export const statusUpdatedAt = (formatter: Formatter): GridColDef<NegotiationEventListItem> => ({
  ...COMMON_COLUMN_PARAMETERS,
  field: 'statusUpdatedAt',
  headerName: 'Last modified',
  flex: 1.5,
  renderCell: ({ value }: GridRenderCellParams) => {
    if (!value) {
      return 'N/A'
    }
    return (
      <TooltipCell tooltip={formatter.date(parseISO(value), dateFormats.textDateTime)}>
        {formatter.dateTimeRelative(parseISO(value), dateFormats.longDate)}
      </TooltipCell>
    )
  },
  sortComparator: (a: string, b: string) => parseISO(a).getTime() - parseISO(b).getTime(),
})

export const negotiationStatus: GridColDef<NegotiationEventListItem> = {
  ...COMMON_COLUMN_PARAMETERS,
  field: 'negotiationStatus',
  headerName: 'Status',
  flex: 1.5,
  type: 'string',
  valueGetter: (cell: { row: NegotiationEventListItem }) => {
    if (cell.row.negotiationStatus === 'DECLINED') {
      return 'Declined ' + cell.row.statusLabel // bit of a hack to make searching more convenient
    }
    return cell.row.statusLabel
  },
  renderCell: (cell: { row: NegotiationEventListItem }) => (
    <NegotiationEventListStatusChip
      status={cell.row.negotiationStatus}
      statusLabel={cell.row.statusLabel}
    />
  ),
}

export const selectCheckBox: GridColDef<NegotiationEventListItem> = {
  ...GRID_CHECKBOX_SELECTION_COL_DEF,
  field: '__check__',
  cellClassName: 'select-cell',
  headerClassName: 'select-header',
  align: 'left',
}

export const actions: GridColDef<NegotiationEventListItem> = {
  ...COMMON_COLUMN_PARAMETERS,
  field: '__actions__',
  headerName: 'Actions',
  cellClassName: 'actions-cell',
  minWidth: ACTIONS_BUTTON_COLUMN_WIDTH,
  sortable: false,
  type: 'string',
  renderCell: ({ row }: { row: NegotiationEventListItem }) => (
    <NegotiationEventActions listItem={row} actionsMenuMinWidth={ACTIONS_BUTTON_WIDTH} />
  ),
}
