import { Avatar, Box, styled } from '@mui/material'
import {
  ActionId,
  ActionImpl,
  KBarAnimator,
  KBarPortal,
  KBarPositioner,
  KBarResults,
  KBarSearch,
  useMatches,
} from 'kbar'
import React from 'react'
import tinycolor from 'tinycolor2'
import { Organization, Project } from '@pactum/core-backend-types'
import { useMerchandisingRouteActions } from '@components/CommandBar/actions/merchandising-routes'
import { useJwtCopyAction } from '@components/CommandBar/actions/jwt-copy'
import { useOrgSwitchActions } from '@components/CommandBar/actions/org-switch'
import { useBrandingActions } from '@components/CommandBar/actions/brandings'
import { useOpenInAdminAction } from './actions/open-in-admin'
import { useFixPactRatesAction } from './actions/fix-pact-rates'
import { useProjectSwitchActions } from './actions/project-switch'

export interface Props {
  activeNegotationEvent: { externalId: string } | null
  activeOrg: Pick<Organization, 'id' | 'projects' | 'tag'>
  activeProject: Pick<Project, 'id'> | null
  isAdmin: boolean
  organizations: Pick<Organization, 'name' | 'tag'>[]
}

export const CommandBar = ({
  activeNegotationEvent,
  activeOrg,
  activeProject,
  isAdmin,
  organizations,
}: Props) => {
  useMerchandisingRouteActions(activeOrg, isAdmin)
  useJwtCopyAction(isAdmin)
  useFixPactRatesAction(isAdmin)
  useBrandingActions(activeOrg, isAdmin)
  useOrgSwitchActions(organizations, isAdmin)
  useOpenInAdminAction(isAdmin, activeOrg, activeProject, activeNegotationEvent)
  useProjectSwitchActions(activeOrg.tag, isAdmin)

  return (
    <KBarPortal>
      <StyledKBarPositioner>
        <StyledKBarAnimator>
          <StyledKBarSearch />
          <Results />
        </StyledKBarAnimator>
      </StyledKBarPositioner>
    </KBarPortal>
  )
}

export const resultAvatar = (alt: string, imageUrl: string | null) => (
  <Avatar src={imageUrl ?? undefined} alt={alt} sx={{ width: 16, height: 16 }} />
)

// ---- Everything below is copied from admin-frontend

const Results = () => {
  const { results, rootActionId } = useMatches()

  return (
    <KBarResults
      items={results}
      onRender={({ item, active }) =>
        typeof item === 'string' ? (
          <GroupName>{item}</GroupName>
        ) : (
          <ResultItem action={item} active={active} currentRootActionId={rootActionId} />
        )
      }
    />
  )
}

const ResultItem = React.forwardRef(
  (
    {
      action,
      active,
      currentRootActionId,
    }: {
      action: ActionImpl
      active: boolean
      currentRootActionId?: ActionId | null
    },
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const ancestors = React.useMemo(() => {
      if (!currentRootActionId) return action.ancestors
      const index = action.ancestors.findIndex((ancestor) => ancestor.id === currentRootActionId)
      // +1 removes the currentRootAction; e.g.
      // if we are on the "Set theme" parent action,
      // the UI should not display "Set theme… > Dark"
      // but rather just "Dark"
      return action.ancestors.slice(index + 1)
    }, [action.ancestors, currentRootActionId])

    return (
      <ResultItemWrapper active={active.toString()} ref={ref}>
        <ResultItemContent>
          {action.icon}
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
              {ancestors.length > 0 &&
                ancestors.map((ancestor) => (
                  <React.Fragment key={ancestor.id}>
                    <AncestorContainer>{ancestor.name}</AncestorContainer>
                    <Box component='span' sx={{ mr: 1 }}>
                      &rsaquo;
                    </Box>
                  </React.Fragment>
                ))}
              <span>{action.name}</span>
            </Box>
            {action.subtitle && (
              <Box component='span' sx={{ mr: 1 }}>
                {action.subtitle}
              </Box>
            )}
          </Box>
        </ResultItemContent>
      </ResultItemWrapper>
    )
  },
)

const StyledKBarPositioner = styled(KBarPositioner)({
  // mui dialog is 1300
  zIndex: 1301,
})
const StyledKBarSearch = styled(KBarSearch)(({ theme }) => ({
  padding: theme.spacing(2),
  fontSize: theme.spacing(2),
  width: '100%',
  boxSizing: 'border-box',
  outline: 'none',
  border: 'none',
  background: theme.palette.primary.main,
  color: '#ffffff',
  '::placeholder': {
    color: tinycolor(theme.palette.primary.main).brighten(50).toHexString(),
  },
}))

const StyledKBarAnimator = styled(KBarAnimator)(({ theme }) => ({
  maxWidth: '600px',
  width: '100%',
  background: theme.palette.primary.main,
  color: '#ffffff',
  borderRadius: theme.spacing(1),
  overflow: 'hidden',
  boxShadow: `0px ${theme.spacing(0.75)} ${theme.spacing(2.5)} rgb(0 0 0 / 20%)`,
}))

const GroupName = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  fontSize: '10px',
  textTransform: 'uppercase',
  opacity: 0.5,
}))

const AncestorContainer = styled('span')({
  opacity: 0.5,
  marginRight: 8,
})

const ResultItemWrapper = styled(Box)<{ active: string }>(({ active, theme }) => ({
  padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
  background:
    active === 'true'
      ? tinycolor(theme.palette.primary.main).brighten(7.5).toHexString()
      : 'transparent',
  borderLeft: `${theme.spacing(0.25)} solid ${
    active === 'true' ? 'var(--foreground)' : 'transparent'
  }`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
}))

const ResultItemContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  fontSize: 14,
}))
