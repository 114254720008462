import { AverageAccumulator, ObjectKey } from './types'

export const identity = <O>(item: O): O => item

// not lambda which can be used in array filter to negate the result
export const not =
  <T>(fn: (arg: T) => boolean) =>
  (arg: T) =>
    !fn(arg)

export const last = <T>(array: T[]): T => array[array.length - 1]

export const firstNonNull = <T>(...args: T[]): T | null => args.find((arg) => arg !== null) ?? null

export const sum = <O>(array: O[], propertyAccessor: (arrayObject: O) => number): number =>
  array.reduce(sumBy(propertyAccessor), 0)

export const sumNumbers = (array: number[]): number => array.reduce(sumBy(identity), 0)

export const sumBy =
  <T>(propertyAccessor: (object: T) => number) =>
  (sumAccumulator: number, currentValue: T) =>
    sumAccumulator + propertyAccessor(currentValue)

export const average = <O>(array: O[], propertyAccessor: (arrayObject: O) => number): number =>
  array.reduce(
    (sumAccumulator, currentObject) => sumAccumulator + propertyAccessor(currentObject),
    0,
  ) / array.length

// using running average approach, which can be used more directly in `reduce` op; access result by .avg prop
export const averageBy =
  <O>(propertyAccessor: (arrayObject: O) => number) =>
  ({ avg, n }: AverageAccumulator, currentValue: O): AverageAccumulator => ({
    avg: (propertyAccessor(currentValue) + n * avg) / (n + 1),
    n: n + 1,
  })

export const averageNumbers = (array: number[]): number => average(array, identity)

export const standardDeviation = (array: number[]): number => {
  const avg = averageNumbers(array)
  return Math.sqrt(average(array, (num) => Math.pow(num - avg, 2)))
}

export const calculatePercentile = <T>(
  data: T[],
  comparator: (a: T, b: T) => number,
  percentile: number,
): T | null => {
  if (percentile < 0 || percentile > 100) {
    throw new Error('Percentile must be between 0 and 100')
  }

  if (data.length === 0) {
    return null
  }

  const sortedData = [...data].sort(comparator)

  const index = Math.ceil((percentile / 100) * sortedData.length) - 1
  return sortedData[index]
}

// preserves order
export const unique = <T>(arr: T[]): T[] => arr.filter(onlyUnique)

export const onlyUnique = <T>(value: T, index: number, self: T[]): boolean => {
  return self.indexOf(value) === index
}

export const onlyUniqueBy =
  <T>(property: keyof T) =>
  (value: T, index: number, self: T[]): boolean =>
    self.map((selfValue) => selfValue[property]).indexOf(value[property]) === index

export const ascendingOrder =
  <O>(propertyAccessor: (object: O) => number) =>
  (sortableA: O, sortableB: O): number =>
    propertyAccessor(sortableA) - propertyAccessor(sortableB)

export const ascendingOrderBy =
  <O extends Record<K, number>, K extends ObjectKey<O, number>>(property: K) =>
  (sortableA: O, sortableB: O): number =>
    sortableA[property] - sortableB[property]

export const descendingOrder =
  <O>(propertyAccessor: (object: O) => number) =>
  (sortableA: O, sortableB: O): number =>
    ascendingOrder(propertyAccessor).call(this, sortableB, sortableA)

export const descendingOrderBy =
  <O extends Record<K, number>, K extends ObjectKey<O, number>>(property: K) =>
  (sortableA: O, sortableB: O): number =>
    sortableB[property] - sortableA[property]

export const earliestFirst =
  <O extends Record<K, string>, K extends ObjectKey<O, string>>(property: K) =>
  (sortableA: O, sortableB: O): number =>
    new Date(sortableA[property]).getTime() - new Date(sortableB[property]).getTime()

export const latestFirst =
  <O extends Record<K, string>, K extends ObjectKey<O, string>>(property: K) =>
  (sortableA: O, sortableB: O): number =>
    new Date(sortableB[property]).getTime() - new Date(sortableA[property]).getTime()

export const group = <T, K extends PropertyKey>(
  arr: T[],
  propertyAccessor: (arrayObject: T) => K,
): Record<K, T[]> => {
  return arr.reduce(
    (accumulator, val) => {
      const groupedKey = propertyAccessor(val)
      if (!accumulator[groupedKey]) {
        accumulator[groupedKey] = []
      }
      accumulator[groupedKey].push(val)
      return accumulator
    },
    {} as Record<K, T[]>,
  )
}

export const associate = <T, K extends PropertyKey>(
  arr: T[],
  keySelector: (arrayObject: T) => K,
): Record<K, T> =>
  arr.reduce(
    (accumulator: Record<K, T>, val: T) => {
      accumulator[keySelector(val)] = val
      return accumulator
    },

    {} as Record<K, T>,
  )

export const isEqual = <T>(a: T[], b: T[]) =>
  a.length === b.length && a.every((element, index) => element === b[index])

export const isEqualIgnoringOrder = <T>(a: T[], b: T[]) => {
  const aSet = new Set(a)
  const bSet = new Set(b)
  return aSet.size === bSet.size && [...aSet].every((element) => bSet.has(element))
}

export const dedupeArrayByAllObjectProperties = <T>(array: T[]): T[] => {
  return array.filter(
    (value, i, aa) => aa.findIndex((v2) => JSON.stringify(v2) === JSON.stringify(value)) === i,
  )
}

export const findDuplicates = <T>(array: T[], keySelector: (arrayObject: T) => string): T[] => {
  const seen = new Set<string>()
  const duplicates = new Map<string, T>()

  for (const item of array) {
    const key = keySelector(item)

    if (seen.has(key)) {
      duplicates.set(key, item)
    } else {
      seen.add(key)
    }
  }

  return Array.from(duplicates.values())
}
