import { AutocompleteFieldCtrl, NumberFieldCtrl } from '@components/Form'
import { CurrencyPickerCtrl } from '@components/Form/CurrencyPickerCtrl'
import { Grid, InputAdornment } from '@mui/material'
import { getCurrencySymbol } from '../../../utils/currency'
import { useFormContext } from 'react-hook-form'
import { FormRow, FormSectionTitle } from '../../FormCommon/FormCommon'
import { NegotiationEventFormData } from '../schema'

export const RebatesWithApplicableSpend = () => {
  const { watch } = useFormContext<NegotiationEventFormData>()
  const currency = watch('currency')

  return (
    <>
      <FormSectionTitle>Price and limits</FormSectionTitle>
      <FormRow>
        <Grid item xs={6}>
          <NumberFieldCtrl
            fullWidth
            helperText='Amount paid for the supplier in the past or to be paid in future'
            variant='outlined'
            InputProps={{
              startAdornment: <CurrencySign selectedCurrency={currency} />,
            }}
            name={'spendAmount'}
            label={'Estimated spend or quote'}
          />
        </Grid>
        <Grid item xs={6}>
          <CurrencyPickerCtrl
            label={'Currency'}
            name={'currency'}
            helperText={'Currency in which the items are negotiated'}
            disabled={false}
            disableClearable
          />
        </Grid>
      </FormRow>
      <FormRow>
        <Grid item xs={6}>
          <AutocompleteFieldCtrl
            freeSolo={false}
            options={spendDurationOptions}
            getOptionLabel={(option) => `${option} months`}
            variant='outlined'
            valueAsNumber
            fullWidth
            label={'Estimated spend or quote duration'}
            helperText={'The period that the spend estimation covers'}
            name={'suppliers.0.negotiationSettings.previousContractLength'}
          />
        </Grid>
        <Grid item xs={6}>
          <NumberFieldCtrl
            style={{ width: '100%' }}
            helperText='Rebate that has already been agreed with the supplier'
            variant='outlined'
            name={'suppliers.0.negotiationSettings.rebates.benchmark'}
            label={'Benchmark rebate (%)'}
          />
        </Grid>
      </FormRow>
      <FormRow>
        <Grid item xs={6}>
          <NumberFieldCtrl
            style={{ width: '100%' }}
            helperText='Deal will not be made unless we get at least this rebate'
            variant='outlined'
            name={'suppliers.0.negotiationSettings.rebates.minimum'}
            label={'Minimum acceptable rebate (%)'}
          />
        </Grid>
        <Grid item xs={6}>
          <NumberFieldCtrl
            style={{ width: '100%' }}
            helperText='This number needs to be greater than the minimum acceptable rebate'
            variant='outlined'
            name={'suppliers.0.negotiationSettings.rebates.target'}
            label={'Rebate I am willing to consider for this supplier (%)'}
          />
        </Grid>
      </FormRow>
    </>
  )
}

const CurrencySign = ({ selectedCurrency }: { selectedCurrency?: string }) => {
  return <InputAdornment position='start'>{getCurrencySymbol(selectedCurrency)}</InputAdornment>
}

const MAX_WARRANTY_MONTHS = 72
const spendDurationOptions = [...Array(MAX_WARRANTY_MONTHS).keys()].map((key) => key + 1)
