import React from 'react'
import { Grid } from '@mui/material'

type Props = {
  children: React.ReactNode
}

export const GridItem = ({ children }: Props) => (
  <Grid item xs={4}>
    {children}
  </Grid>
)
