import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { AutocompleteFieldCtrl, CheckboxCtrl } from '@components/Form'
import styled from '@emotion/styled'
import { Grid, Typography } from '@mui/material'
import { PaymentDayObject } from '@procurement/store/types'
import {
  getMinMaxPaymentDaysLabel,
  getPaymentDaysLabel,
  transformPaymentDaysOptions,
} from '@procurement/utils/paymentDays'
import { ExistingPaymentDaysFieldCtrl } from '@procurement/components/ExistingPaymentDaysFieldCtrl/ExistingPaymentDaysFieldCtrl'
import {
  createGetFieldControllerParams,
  FormRow,
  HideableBlock,
} from '@procurement/components/FormCommon/FormCommon'
import { NegotiationEventFormContext } from '../NegotiationEventFormContext'
import { NegotiationEventFormData } from '../schema'

interface Props {
  paymentDays: (number | PaymentDayObject)[]
}

export const PaymentDays = ({ paymentDays }: Props) => {
  const { watch } = useFormContext<NegotiationEventFormData>()
  const { requiredFields, visibleFields, disabledFields } = useContext(NegotiationEventFormContext)
  const getFieldControllerParams = createGetFieldControllerParams({
    requiredFields,
    disabledFields,
  })
  const paymentDaysChecked = watch('paymentDaysEnabled')
  const paymentDayOptions: PaymentDayObject[] = transformPaymentDaysOptions(paymentDays)

  const isPaymentDaysRequired = requiredFields.includes('paymentDaysEnabled')

  const formatMinMaxPaymentDays = getMinMaxPaymentDaysLabel(paymentDays)

  return (
    <HideableBlock hidden={!visibleFields.includes('paymentDaysEnabled')}>
      <FormRow>
        <Grid item xs={12}>
          <CheckboxCtrl
            labelProps={{ fontWeight: 700 }}
            {...getFieldControllerParams('paymentDaysEnabled', 'Payment days')}
            disabled={isPaymentDaysRequired && !!paymentDaysChecked}
          />
        </Grid>
      </FormRow>
      <HideableBlock hidden={!paymentDaysChecked}>
        <FormRow>
          <Grid item container xs={12} rowSpacing={2}>
            <Typography variant='body2' sx={{ mb: 1 }}>
              Setting the minimum and maximum payment days requires an agreement to be in this
              range. Existing payment days are used for negotiation tactics.{' '}
              <Underlined>Only use payment days if they apply to this contact only.</Underlined>
            </Typography>
          </Grid>
        </FormRow>
        <FormRow>
          <Grid item xs={3.5}>
            <ExistingPaymentDaysFieldCtrl
              helperText='Current agreed payment days'
              options={paymentDayOptions}
              getOptionLabel={getPaymentDaysLabel}
              variant='outlined'
              freeSolo={true}
              fullWidth
              label='Existing payment days'
              name='suppliers.0.initialPaymentTerms.paymentDays'
            />
          </Grid>
          <Grid item xs={3.5}>
            <AutocompleteFieldCtrl
              helperText='Do not accept anything shorter'
              options={paymentDayOptions}
              getOptionLabel={formatMinMaxPaymentDays}
              variant='outlined'
              freeSolo={false}
              fullWidth
              label='Minimum payment days'
              name='suppliers.0.negotiationSettings.minPaymentDays'
            />
          </Grid>
          <Grid item xs={3.5}>
            <AutocompleteFieldCtrl
              helperText='Do not accept anything longer'
              options={paymentDayOptions}
              getOptionLabel={formatMinMaxPaymentDays}
              variant='outlined'
              freeSolo={false}
              fullWidth
              label='Maximum payment days'
              name='suppliers.0.negotiationSettings.maxPaymentDays'
            />
          </Grid>
        </FormRow>
      </HideableBlock>
    </HideableBlock>
  )
}

const Underlined = styled.span`
  text-decoration: underline;
`
