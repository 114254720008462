export const isNameValid = (name: string) => name.length > 0

export const isMinimumAmountValid = (minimumAmount: number) => minimumAmount >= 0

export const isTargetAmountValid = (targetAmount: number | null, minimumAmount: number) => {
  if (targetAmount === null) {
    return true
  } else {
    return targetAmount > 0 && targetAmount > minimumAmount
  }
}
