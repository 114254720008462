import { downloadFile } from '@utils'
import { useLazyDownloadNegotiationEventTemplateQuery } from '../store/purchasing'
import { useSnackbar } from 'notistack'
import { isBackendApiErrorResponse } from '@shared/backend/error/typeGuards'
import { ProductType } from '@pactum/core-backend-types'

export const useDownloadTemplateData = (
  projectTag: string,
  productType: ProductType.ContractCost | ProductType.Purchasing,
) => {
  const { enqueueSnackbar } = useSnackbar()
  const [downloadTemplateFileQuery] = useLazyDownloadNegotiationEventTemplateQuery()

  return async () => {
    try {
      const templateFile = await downloadTemplateFileQuery({ productType, projectTag }).unwrap()
      downloadFile(templateFile.filename, templateFile.fileContent)
    } catch (error) {
      const errorMessage = isBackendApiErrorResponse(error)
        ? error.data.message
        : 'Error occurred while downloading template file'

      enqueueSnackbar(errorMessage, { variant: 'error' })
    }
  }
}
