import React from 'react'
import { Divider, Stack, styled, Typography } from '@mui/material'

interface FiltersListProps {
  options: string[]
  selectedOption: string
  onClick: (option: string) => void
}

export const FiltersList = ({ options, selectedOption, onClick }: FiltersListProps) => {
  return (
    <Stack direction='row'>
      {options.map((option, idx) => (
        <Stack key={option} direction='row'>
          <FilterItem selected={option === selectedOption} onClick={() => onClick(option)}>
            {option}
          </FilterItem>
          {idx !== options.length - 1 ? (
            <Divider
              orientation='vertical'
              variant='middle'
              flexItem
              sx={{ mx: 0.5, my: 0.25, borderColor: 'text.secondary' }}
            />
          ) : null}
        </Stack>
      ))}
    </Stack>
  )
}

const FilterItem = styled(Typography)<{ selected: boolean }>(({ theme, selected }) => ({
  color: theme.palette.text.secondary,
  fontWeight: selected ? 'bold' : 'normal',
  fontSize: '0.625rem',
  textDecoration: selected ? 'underline' : 'none',
  textUnderlineOffset: '3px',
  cursor: 'pointer',
}))
