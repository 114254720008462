import { ConfirmationModal } from '@components/ConfirmationModal'
import { SupplierContact } from '@logistics/types/Supplier'

interface Props {
  open: boolean
  contact: SupplierContact
  onCancel: () => void
  onSubmit: () => void
}

export const RemoveSupplierContactModal = ({ open, onCancel, onSubmit, contact }: Props) => {
  return (
    <ConfirmationModal
      confirmType='negative'
      open={open}
      onCancel={onCancel}
      onSubmit={onSubmit}
      title='Remove contact?'
      content={`Are you sure you want to remove contact "${contact.email}"?`}
      confirmButtonText='Remove'
    />
  )
}
