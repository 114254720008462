import { Box, SvgIcon, keyframes, styled, SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'

interface Props {
  sizePx?: number
  sx?: SxProps<Theme>
}

export const PactumLoader = ({ sizePx = 60, sx }: Props): JSX.Element => {
  return (
    <Box
      role='progressbar'
      sx={{ ...sx, width: '100%', textAlign: 'center', color: '#4A4A4A', p: 1 }}
    >
      <SvgIcon
        width={24}
        height={24}
        viewBox='0 0 48 50'
        shapeRendering='geometricPrecision'
        textRendering='geometricPrecision'
        stroke='currentColor'
        sx={{ fontSize: `${sizePx}px` }}
      >
        <StyledPath d='M17,25C17,25,13,25,9,25C5,25,2,22,2.000020,19C2.000040,16,2,11,2,8C2,5,5,2.000010,8,2.000010C11,2.000010,6.916140,2,14,2C25,2,23,48,34,48C36,48,35,48,39,48C43,48,46,45,46,42C46,39,46,34,46,31C46,28,43,25,39,25C35,25,31,25,31,25' />
      </SvgIcon>
    </Box>
  )
}

const strokeAnimation = keyframes`
  0% {
    stroke-dashoffset: 141.57;
    animation-timing-function: cubic-bezier(0.550000, 0.085000, 0.680000, 0.530000);
  }

  40% {
    stroke-dashoffset: 0;
    animation-timing-function: cubic-bezier(0.250000, 0.460000, 0.450000, 0.940000);
  }

  80% {
    stroke-dashoffset: -141.57;
  }

  100% {
    stroke-dashoffset: -141.57;
  }
`

const StyledPath = styled('path')({
  strokeDasharray: 141.57,
  strokeDashoffset: 141.57,
  strokeWidth: '4px',
  fill: 'none',
  strokeLinecap: 'square',
  animation: `${strokeAnimation} 2500ms linear infinite normal forwards`,
})
