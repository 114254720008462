import { User as OrgUser } from '@common/types/suite'
import { DataGrid, dataGridProps } from '@components/table'
import { useUser } from '@hooks'
import { Button, Stack, styled } from '@mui/material'
import { GridRowModesModel, GridToolbarExport } from '@mui/x-data-grid-pro'
import { GridProSlotProps } from '@mui/x-data-grid-pro/models/gridProSlotProps'
import { AddUserDialog } from '@pages/SettingsPage/ManageUsersPage/dialogs/AddUserModalDialog'
import React from 'react'
import { ProjectIdToNameMap, createColumns } from './columns'

type Props = {
  users: OrgUser[]
  projects: ProjectIdToNameMap
  refetchUsers: () => void
}

const StyledDataGrid = styled(DataGrid)(() => ({
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
})) as typeof DataGrid

interface ToolbarProps {
  isAddUserDialogOpen: boolean
  setIsAddUserDialogOpen: (value: boolean) => void
  refetchUsers: () => void
}

function Toolbar(props: ToolbarProps) {
  const handleClick = () => {
    props.setIsAddUserDialogOpen(true)
  }

  return (
    <Stack sx={{ pl: '10px', pb: 1 }} direction='row' justifyContent='space-between'>
      <Button variant='outlined' size='medium' sx={{ color: '#6C6C6C' }} onClick={handleClick}>
        + Add user
      </Button>
      <AddUserDialog
        refetchUsers={props.refetchUsers}
        isOpen={props.isAddUserDialogOpen}
        onClose={() => props.setIsAddUserDialogOpen(false)}
      />
      <GridToolbarExport
        printOptions={{ disableToolbarButton: true }}
        csvOptions={{
          fields: ['name', 'email', 'role', 'status', 'projectIdsAccessList'],
        }}
      />
    </Stack>
  )
}

export const ManageUsersList: React.FC<Props> = ({ users, projects, refetchUsers }) => {
  const { user: currentUser } = useUser()
  const columns = createColumns(currentUser, projects, refetchUsers)
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})

  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = React.useState(false)

  return (
    <StyledDataGrid
      {...dataGridProps}
      slots={{ ...dataGridProps.slots, toolbar: Toolbar }}
      slotProps={
        {
          toolbar: { isAddUserDialogOpen, setIsAddUserDialogOpen, refetchUsers },
        } as GridProSlotProps
      }
      columns={columns}
      rows={users}
      hideFooter={false}
      rowModesModel={rowModesModel}
      onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
      getRowId={(row: OrgUser) => row.email}
    />
  )
}
