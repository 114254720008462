import { useDeleteNegotiationEventAttachments } from '@procurement/hooks/useDeleteNegotiationEventAttachments'
import { useUploadNegotiationEventAttachments } from '@procurement/hooks/useUploadNegotiationEventAttachments'
import { Attachment, DetachedAttachment } from '@procurement/store/schema'
import { NegotiationEvent, NegotiationEventSupplier } from '@procurement/store/types'
import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

type UseSyncNegotiationEventAttachments = () => [
  /** Updates (uploads and deletes) negotiation event attachments to match local form state. */
  (params: {
    data: { attachments: Attachment[]; detachedAttachments: DetachedAttachment[] }
    negotiationEventId: NegotiationEvent['id']
    supplierId?: NegotiationEventSupplier['id']
  }) => Promise<void>,
  {
    error: FetchBaseQueryError | SerializedError | undefined
    isError: boolean
    isLoading: boolean
  },
]

export const useSyncNegotiationEventAttachments: UseSyncNegotiationEventAttachments = () => {
  const [
    deleteNegotiationEventAttachments,
    { error: deleteError, isError: isDeleteError, isLoading: isDeleteLoading },
  ] = useDeleteNegotiationEventAttachments()
  const [
    uploadNegotiationEventAttachments,
    { error: uploadError, isError: isUploadError, isLoading: isUploadLoading },
  ] = useUploadNegotiationEventAttachments()

  return [
    async ({ data, negotiationEventId, supplierId }) => {
      const removedAttachments = data.attachments.filter((a) => {
        return data.detachedAttachments.every((da) => !hasSameFileName(a, da))
      })

      const addedAttachments = data.detachedAttachments.filter((da) => {
        return data.attachments.every((a) => !hasSameFileName(a, da))
      })

      if (removedAttachments.length) {
        await deleteNegotiationEventAttachments({
          attachments: removedAttachments,
          negotiationEventId,
        })
      }

      if (addedAttachments.length) {
        await uploadNegotiationEventAttachments({
          attachments: addedAttachments,
          negotiationEventId,
          supplierId,
        })
      }
    },
    {
      error: deleteError || uploadError,
      isError: isDeleteError || isUploadError,
      isLoading: isDeleteLoading || isUploadLoading,
    },
  ]
}

function hasSameFileName(a: Attachment, da: DetachedAttachment) {
  return a.fileName === da.file.name
}
