import {
  getValidatedLocaleOrDefault,
  kilometersFormatter,
  milesFormatter,
} from '@logistics/pages/contractedLanes/RFPDetailsPage/utils'
import { DistanceFormatter, Locale } from '@logistics/types'
import { DEFAULT_LOCALE, KM_TO_MILES } from '@logistics/constants'

export function formatDistance(
  distanceAmount: number,
  formatter: DistanceFormatter,
  locale: string,
) {
  if (distanceAmount < 1) {
    return '<' + formatter(locale, 0).format(1)
  }

  if (distanceAmount < 100) {
    return formatter(locale, 1).format(distanceAmount)
  }

  return formatter(locale, 0).format(distanceAmount)
}

export function getDistanceCaption(distanceInKms: number, locale: string) {
  if (distanceInKms < 0) {
    return 'Distance N/A'
  }

  const validatedLocale = getValidatedLocaleOrDefault(locale, DEFAULT_LOCALE)

  if (validatedLocale === Locale.enUS) {
    const distanceInMiles = distanceInKms * KM_TO_MILES
    return formatDistance(distanceInMiles, milesFormatter, validatedLocale)
  }

  return formatDistance(distanceInKms, kilometersFormatter, validatedLocale)
}
