import { FormHelperText, Grid } from '@mui/material'
import { TextFieldCtrl } from '@components/Form'
import { RatingCtrl } from '@components/Form/RatingCtrl'
import { camelCaseToTitleCase } from '@logistics/utils/nameHelper'

interface Props {
  requiredAttributes: string[]
}

export const SupplierModalForm = ({ requiredAttributes }: Props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextFieldCtrl
          name='externalId'
          label='Carrier ID'
          required
          fullWidth
          placeholder='#### #### ####'
        />
        <FormHelperText color={'text-secondary'} sx={{ ml: 1 }}>
          ID that uniquely identifies this carrier
        </FormHelperText>
      </Grid>
      <Grid item xs={6}>
        <TextFieldCtrl name='mcNumber' label='Carrier MC Number' fullWidth placeholder='########' />
        <FormHelperText color={'text-secondary'} sx={{ ml: 1 }}>
          Optional MC Number for the carrier
        </FormHelperText>
      </Grid>
      <Grid item xs={12}>
        <TextFieldCtrl
          name='name'
          label='Carrier Name'
          required
          fullWidth
          placeholder='#### #### ####'
        />
        <FormHelperText color={'text-secondary'} sx={{ ml: 1 }}>
          The legal name of the carrier company
        </FormHelperText>
      </Grid>
      {requiredAttributes
        ? requiredAttributes.map((a) => (
            <Grid key={a} item xs={12}>
              <TextFieldCtrl
                name={a}
                label={camelCaseToTitleCase(a)}
                required={true}
                fullWidth
                placeholder={camelCaseToTitleCase(a)}
              />
              <FormHelperText color={'text-secondary'} sx={{ ml: 1 }}>
                The {camelCaseToTitleCase(a).toLowerCase()} of the carrier company
              </FormHelperText>
            </Grid>
          ))
        : null}
      <Grid item xs={12}>
        <TextFieldCtrl
          name='notes'
          label='Notes'
          fullWidth
          placeholder=''
          multiline={true}
          rows={3}
        />
        <FormHelperText color={'text-secondary'} sx={{ ml: 1 }}>
          Notes and remarks about this carrier
        </FormHelperText>
      </Grid>
      <Grid item xs={12} ml={1}>
        <FormHelperText color={'text-secondary'} sx={{ mb: 1 }}>
          Ratings
        </FormHelperText>
        <RatingCtrl name='rating' />
        <FormHelperText color={'text-secondary'}>
          From 1-5, how satisfied are you with carrier performance?
        </FormHelperText>
      </Grid>
    </Grid>
  )
}
