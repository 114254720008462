import { useContext } from 'react'
import { Grid } from '@mui/material'
import { DatePickerCtrl, TextFieldCtrl } from '@components/Form'
import { NegotiationEventFormContext } from '../NegotiationEventFormContext'
import {
  createGetFieldControllerParams,
  FormRow,
  FormSectionTitle,
  HideableBlock,
} from '../../FormCommon/FormCommon'
import { ProductType } from '@pactum/core-backend-types'
import { labelForProduct } from '../../../utils/labelForProduct'

interface Props {
  productType?: ProductType
}

export const Delivery = ({ productType }: Props) => {
  const { requiredFields, visibleFields, disabledFields } = useContext(NegotiationEventFormContext)
  const getFieldControllerParams = createGetFieldControllerParams({
    requiredFields,
    disabledFields,
  })

  return (
    <>
      <HideableBlock hidden={!visibleFields.includes('deliveryTitle')}>
        <FormSectionTitle>Delivery</FormSectionTitle>
      </HideableBlock>
      <FormRow>
        <HideableBlock hidden={!visibleFields.includes('deliveryDate')}>
          <Grid item xs={6}>
            <DatePickerCtrl
              inputProps={{ helperText: 'Delivery date that can not be exceeded' }}
              fullWidth
              timezone='UTC'
              {...getFieldControllerParams(
                'deliveryDate',
                labelForProduct(productType, 'negotiationEventFormDeliveryDate'),
              )}
            />
          </Grid>
        </HideableBlock>
      </FormRow>
      <HideableBlock hidden={!visibleFields.includes('deliveryLocation')}>
        <FormRow>
          <Grid item xs={12}>
            <TextFieldCtrl
              fullWidth
              {...getFieldControllerParams('deliveryLocation', 'Delivery address')}
            />
          </Grid>
        </FormRow>
      </HideableBlock>
    </>
  )
}
