import { Checkbox, Divider, MenuItem, SelectChangeEvent } from '@mui/material'
import { FilterSelect } from '../FilterSelect'

export type MultiSelectItemValue = string | number

export interface MultiSelectItem {
  name?: string
  value: MultiSelectItemValue
}

export interface MultiSelectProps {
  label: string
  multiple?: boolean
  selectedAllLabel: string
  data: MultiSelectItem[]
  selected: MultiSelectItemValue[]
  setSelected: (values: MultiSelectItemValue[]) => void
}

const SELECT_ALL_VALUE = 'select_all'

const getSingle = (e: SelectChangeEvent<MultiSelectItemValue>) => e.target.value

const getMultiple = (
  e: SelectChangeEvent<MultiSelectItemValue[]>,
  selected: MultiSelectItemValue[],
  data: MultiSelectItem[],
) => {
  const values = e.target.value as MultiSelectItemValue[]

  let selection: MultiSelectItemValue[] = []

  if (values.includes(SELECT_ALL_VALUE)) {
    if (selected.length === data.length || selected.length > 0) {
      selection = []
    } else {
      selection = data.map((item) => item.value)
    }
  } else {
    selection = values
  }

  return selection
}

export const MultiSelectFilter = ({
  label,
  multiple = true,
  selectedAllLabel,
  data,
  selected,
  setSelected,
}: MultiSelectProps) => {
  const handleChange = (e: SelectChangeEvent<unknown>) =>
    setSelected(
      multiple
        ? getMultiple(e as SelectChangeEvent<MultiSelectItemValue[]>, selected, data)
        : [getSingle(e as SelectChangeEvent<MultiSelectItemValue>)],
    )

  const renderLabel = () => {
    if (!multiple) {
      return `${selected}`
    }

    if (selected.length === data.length) {
      return selectedAllLabel
    }

    if (selected.length === 0) {
      return label
    }

    return `${selected.length} selected`
  }

  return (
    <>
      <FilterSelect
        label={renderLabel()}
        multiple={multiple}
        value={selected}
        onChange={handleChange}
        isValuePresent={(value: unknown) => Array.isArray(value) && value.length > 0}
        sx={{ height: '40px' }}
        renderValue={renderLabel}
      >
        {multiple && (
          <MenuItem sx={{ height: '32px' }} value={SELECT_ALL_VALUE}>
            <Checkbox
              size='small'
              indeterminate={selected.length > 0 && selected.length < (data?.length ?? 0)}
              checked={selected.length === data.length}
              disableRipple={true}
            />
            {selected.length === 0 ? 'Select all' : 'Unselect all'}
          </MenuItem>
        )}

        {multiple && <Divider />}

        {data.map((item) => (
          <MenuItem key={item.value} value={item.value} sx={{ height: '32px' }}>
            {multiple && (
              <Checkbox
                size='small'
                checked={!!selected?.find((val) => val === item.value)}
                disableRipple={true}
              />
            )}
            {item.name ?? item.value}
          </MenuItem>
        ))}
      </FilterSelect>
    </>
  )
}
