import React, { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
  styled,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

interface Props {
  title: string
  description?: string
  descriptionComponent?: React.ReactNode
  children: React.ReactNode
  allExpanded?: boolean
}

export const ExpandableSection = ({
  title,
  description,
  descriptionComponent,
  children,
  allExpanded,
}: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  useEffect(() => {
    if (allExpanded) {
      setExpanded(true)
    }
  }, [allExpanded])

  const shownDescriptionComponent = descriptionComponent ?? (
    <Typography variant='body1' mt={1}>
      {description}
    </Typography>
  )

  return (
    <StyledAccordion expanded={expanded} onChange={() => setExpanded(!expanded)} disableGutters>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box sx={{ width: '100%' }}>
          <Typography variant='h4' component='h2' sx={{ fontSize: '1.875rem' }}>
            {title}
          </Typography>
          {shownDescriptionComponent}
        </Box>
      </StyledAccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </StyledAccordion>
  )
}

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  '& .MuiAccordionSummary-expandIconWrapper': {
    alignSelf: 'flex-start',
    marginTop: theme.spacing(2),
  },

  '& .Mui-expanded': {
    margin: '12px 0',
  },
}))

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(1),

  ':first-of-type': {
    borderRadius: theme.spacing(0.5),
  },

  ':last-of-type': {
    borderRadius: theme.spacing(0.5),
  },

  ':before': {
    display: 'none',
  },
}))
