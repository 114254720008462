import { suiteBaseApi } from '@shared/api/suite-base-api'
import { responseAsObjectUrl } from '../utils/file'

export const attachmentApi = suiteBaseApi.injectEndpoints({
  endpoints: (build) => ({
    downloadAttachment: build.query<string, number>({
      query: (attachmentId) => ({
        url: `suite/negotiations/files/${attachmentId}/download`,
        responseHandler: responseAsObjectUrl,
      }),
    }),
  }),
})

export const { useLazyDownloadAttachmentQuery } = attachmentApi
