import { isEqualIgnoringOrder } from '@common/utils'
import {
  SuiteDataGridProvider,
  useSuiteDataGrid,
} from '@components/SuiteDataGrid/SuiteDataGridProvider'
import { styled } from '@mui/material'
import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_FIELD,
  GridColDef,
  gridPaginatedVisibleSortedGridRowIdsSelector,
  GridRowSelectionModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { useFormatter } from '@shared/hooks'
import { useGridPagination } from '@shared/hooks/useGridPagination'
import React, { ReactNode, useMemo, useState } from 'react'
import { DataGrid, dataGridProps } from 'src/shared/components/table'
import { NegotiationUnitEntity } from '@common/dto/pact/negotiation-unit-entity'
import { Toolbar } from '@pages/NegotiationUnitsPage/Toolbar'
import { createColumns } from '@pages/NegotiationUnitsPage/columns'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { Routes } from '../../../suite/routes'

type Props = {
  negotiationUnits: NegotiationUnitEntity[]
}

const VISIBLE_COLUMNS_KEY = 'visibleColumns'

export const NegotiationUnits: React.FC<Props> = ({ negotiationUnits }) => {
  const navigate = useNavigate()
  const location: {
    state: {
      from: string
    }
  } = useLocation()
  const formatter = useFormatter()
  const { negotiationId } = useParams()
  const paginationProps = useGridPagination()
  const { activeProjectTag, activeOrgTag } = useActiveProject()
  const [anchorEl, setAnchorElMenu] = useState<ReactNode | null>()

  const apiRef = useGridApiRef()
  const allNegotiationUnitIds = negotiationUnits.map((n) => n.id)
  const [selectedNegotiationUnitIds, setSelectedNegotiationUnitIds] =
    useState<GridRowSelectionModel>([])
  const [showSelectAllNotification, setShowSelectAllNotification] = useState<boolean>(false)

  const gridState = useSuiteDataGrid({
    sort: [{ field: 'createdAt', sort: 'desc' }],
  })

  const areAllInPageSelected = (selection: GridRowSelectionModel): boolean => {
    const rowIdsSelectedOnCurrentPage = gridPaginatedVisibleSortedGridRowIdsSelector(apiRef)
    const selectedIds = selection.map(Number)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return isEqualIgnoringOrder(selectedIds, rowIdsSelectedOnCurrentPage)
  }

  const handleSelection = (selection: GridRowSelectionModel) => {
    if (areAllInPageSelected(selection)) {
      setShowSelectAllNotification(true)
    } else {
      setShowSelectAllNotification(false)
    }

    setSelectedNegotiationUnitIds(selection)
  }

  const columns: GridColDef<NegotiationUnitEntity>[] = useMemo(
    () => createColumns(formatter),
    [formatter],
  )

  const initialColumnVisibilityState = useMemo(
    () => (): Record<string, boolean> => {
      const storedVisibility = localStorage.getItem(VISIBLE_COLUMNS_KEY)

      if (storedVisibility) {
        return {
          ...(JSON.parse(storedVisibility) as Record<string, boolean>),
          [GRID_CHECKBOX_SELECTION_FIELD]: true,
        }
      }

      return {
        ...Object.fromEntries(columns.map((column) => [column.field, true])),
        [GRID_CHECKBOX_SELECTION_FIELD]: true,
      }
    },
    [columns],
  )

  const [columnVisibility, setColumnVisibility] = useState<Record<string, boolean>>(
    initialColumnVisibilityState,
  )

  const updateVisibility = (state: Record<string, boolean>) => {
    localStorage.setItem(VISIBLE_COLUMNS_KEY, JSON.stringify(state))

    setColumnVisibility(state)
  }

  const getTogglableColumns = () => {
    return columns
      .filter((column) => column.field !== GRID_CHECKBOX_SELECTION_FIELD)
      .map((column) => column.field)
  }

  const navigateBack = () => {
    if (location.state?.from) {
      navigate(-1)
      return
    }

    navigate(
      generatePath(Routes.NEGOTIATION_LIST, {
        orgTag: activeOrgTag,
        projectTag: activeProjectTag,
      }),
    )
  }

  return (
    <SuiteDataGridProvider value={gridState}>
      <StyledDataGrid
        {...dataGridProps}
        {...paginationProps}
        apiRef={apiRef}
        checkboxSelection
        checkboxSelectionVisibleOnly
        isRowSelectable={() => true}
        rowSelectionModel={selectedNegotiationUnitIds}
        onRowSelectionModelChange={handleSelection}
        className={selectedNegotiationUnitIds.length > 0 ? 'show-selection' : ''}
        hideFooterSelectedRowCount
        slots={{ ...dataGridProps.slots, toolbar: Toolbar }}
        slotProps={{
          panel: {
            children: anchorEl,
          },
          toolbar: {
            negotiationId,
            navigateBack,
            setAnchorElMenu,
            showSelectAllNotification,
            showBatchActions: selectedNegotiationUnitIds.length > 0,
            selectedNegotiationUnits: negotiationUnits.filter((negotiationUnit) =>
              selectedNegotiationUnitIds.includes(negotiationUnit.id),
            ),
            allNegotiationUnitsCount: negotiationUnits.length,
            onSelectAllNotifications: () => setSelectedNegotiationUnitIds(allNegotiationUnitIds),
            onSelectAllNotificationClose: () => setShowSelectAllNotification(false),
            onClearSelection: () => handleSelection([]),
          },
          columnsPanel: {
            getTogglableColumns,
          },
        }}
        columns={columns}
        columnVisibilityModel={columnVisibility}
        onColumnVisibilityModelChange={updateVisibility}
        rows={negotiationUnits}
        sortModel={gridState.sort}
        paginationModel={gridState.pagination}
        filterModel={gridState.filters}
        onSortModelChange={gridState.setSort}
        onPaginationModelChange={gridState.setPagination}
        onFilterModelChange={gridState.setFilters}
      />
    </SuiteDataGridProvider>
  )
}

const StyledDataGrid = styled(DataGrid)({
  ' .MuiDataGrid-columnHeader .MuiBadge-badge': {
    display: 'none',
  },
  ' .select-cell, .select-header': {
    opacity: 0,
  },
  ' .MuiDataGrid-row:hover .select-cell': {
    opacity: 1,
  },
  ' .MuiDataGrid-columnHeaders:hover .select-header': {
    opacity: 1,
  },
  '&.show-selection': {
    ' .select-cell, .select-header': {
      opacity: 1,
    },
  },
}) as typeof DataGridPro
