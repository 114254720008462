import MoreHoriz from '@mui/icons-material/MoreHoriz'
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import { useState } from 'react'

interface Props {
  onStatusAndDeadlineChangeClick: () => void
  onChangeEditorClick: () => void
  onReUploadClick: () => void
  onDeletePreparationClick: () => void
}

export const ActionMenu = ({
  onStatusAndDeadlineChangeClick,
  onChangeEditorClick,
  onReUploadClick,
  onDeletePreparationClick,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchorEl)
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
  const handleMenuClose = () => setAnchorEl(null)

  const handleChangeStatusAndDeadlineMenuClick = () => {
    handleMenuClose()
    onStatusAndDeadlineChangeClick()
  }

  const handleEditorChangeClick = () => {
    handleMenuClose()
    onChangeEditorClick()
  }

  const handleReUploadMenuClick = () => {
    handleMenuClose()
    onReUploadClick()
  }

  const handleDeleteMenuClick = () => {
    handleMenuClose()
    onDeletePreparationClick()
  }

  return (
    <>
      <Tooltip title='Actions' placement='left'>
        <IconButton onClick={handleMenuClick}>
          <MoreHoriz />
        </IconButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleMenuClose}>
        <MenuItem onClick={handleChangeStatusAndDeadlineMenuClick}>Change status/deadline</MenuItem>
        <MenuItem onClick={handleEditorChangeClick}>Change editor</MenuItem>
        <MenuItem onClick={handleReUploadMenuClick}>Re-upload</MenuItem>
        <MenuItem onClick={handleDeleteMenuClick}>Delete preparation</MenuItem>
      </Menu>
    </>
  )
}
