import { ContextValues, TargetAndLacValues } from '@common/types'
import {
  GridCellParams,
  GridFilterItem,
  GridFilterOperator,
  GridValidRowModel,
} from '@mui/x-data-grid-pro'
import { getNumberFormatter, isNumberZeroInclusive } from '@utils'
import { InputValue } from './FilterInput'
import { getCurrencySymbol } from '@utils'

export interface TargetLacRangePreFormat {
  target: string
  lac: string
  separator: string
  tooltip?: string
}

export type NumberType = 'decimal' | 'percent'

export const getTargetLacRange = (
  values: TargetAndLacValues,
  unit: NumberType = 'decimal',
  fractionDigits: number = 2,
  currency?: string | null,
): TargetLacRangePreFormat | 0 | null => {
  const formatter = getNumberFormatter(fractionDigits, unit)

  const target = values.target
  const lac = values.lac

  if (target === 0 && lac === 0) {
    return 0
  }

  if (target === null && lac === null) {
    return null
  }

  const currencySymbol = currency !== undefined ? getCurrencySymbol(currency || 'EUR') : ''

  return {
    target: `${formatter.format(target)}${currencySymbol}`,
    lac: `${formatter.format(lac)}${currencySymbol}`,
    separator: ' ... ',
  }
}

export const targetLacRangeToString = (values: TargetLacRangePreFormat | 0) => {
  if (values === 0) {
    return '0'
  }

  return `${values.target}${values.separator}${values.lac}`
}

export const isTargetLacRangeValue = (value: unknown): value is TargetLacRangePreFormat => {
  return !!(value && typeof value === 'object' && 'target' in value && 'lac' in value)
}

export const getEmptyValueFilter =
  (operator: GridFilterOperator) => (filterItem: GridFilterItem) => {
    if (!filterItem.value) {
      return null
    }
    const filterInputValue = filterItem.value as InputValue
    return (
      params: GridCellParams<GridValidRowModel, TargetAndLacValues & ContextValues>,
    ): boolean => {
      const field = filterInputValue.field
      const itemFieldValue = params.value?.[field]
      switch (operator.value) {
        case 'isEmpty':
          return !isNumberZeroInclusive(itemFieldValue)
        case 'isNotEmpty':
          return isNumberZeroInclusive(itemFieldValue)
        default:
          return false
      }
    }
  }

export const getNumberValueFilter =
  (operator: GridFilterOperator) => (filterItem: GridFilterItem) => {
    if (!filterItem.value) {
      return null
    }
    const filterInputValue = filterItem.value as InputValue
    return (
      params: GridCellParams<GridValidRowModel, TargetAndLacValues & ContextValues>,
    ): boolean => {
      const field = filterInputValue.field
      if (
        params.value === undefined ||
        !(field in params.value) ||
        filterInputValue.value === null
      ) {
        return false
      }
      const fieldValue = params.value[field]
      if (fieldValue === undefined) {
        return false
      }
      switch (operator.value) {
        case '=':
          return fieldValue === filterInputValue.value
        case '!=':
          return fieldValue !== filterInputValue.value
        case '>':
          return fieldValue > filterInputValue.value
        case '>=':
          return fieldValue >= filterInputValue.value
        case '<':
          return fieldValue < filterInputValue.value
        case '<=':
          return fieldValue <= filterInputValue.value
        default:
          return false
      }
    }
  }
