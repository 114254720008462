import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { LoadingButton } from '@mui/lab'
import {
  alpha,
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
  CircularProgress,
  lighten,
  Menu,
  MenuItem,
  MenuListProps,
  MenuProps,
  styled,
  useTheme,
} from '@mui/material'
import React, { useRef, useState } from 'react'
import { ActionConfigMap } from './index'
import { OptionalTooltipWrapper } from '@components/OptionalTooltipWrapper'

export type SupportedSizes = Extract<ButtonProps['size'], 'medium' | 'small' | 'large'>

const BUTTONGROUP_OVERLAP = 5

const SIZES: Record<SupportedSizes, number> = {
  large: 138 + BUTTONGROUP_OVERLAP,
  medium: 108 + BUTTONGROUP_OVERLAP,
  small: 72,
} as const

const SupportedMenuListProps: Readonly<Record<SupportedSizes, MenuListProps | undefined>> = {
  small: { dense: true, sx: { py: 0.5 } },
  medium: undefined,
  large: undefined,
}

const getPrimaryButtonWidth = (
  actions: unknown[],
  size: SupportedSizes,
  arrowButtonWidth?: string,
  menuMinWidth?: number,
  isCustomButtonWidth?: boolean,
) => {
  if (actions.length === 1 && menuMinWidth) {
    return menuMinWidth
  }
  if (isCustomButtonWidth && menuMinWidth) return `calc(${menuMinWidth}px - ${arrowButtonWidth})`

  return SIZES[size]
}

interface Props<Action extends string, NegotiationId> {
  id: NegotiationId
  allowedActions: Action[]
  actionConfig: ActionConfigMap<Action, NegotiationId>
  size?: SupportedSizes
  menuMinWidth?: number
  isCustomButtonWidth?: boolean
  loading?: boolean
  disabledActions?: Action[]
}

const ActionsButtonComponent = <Action extends string, NegotiationId>({
  id,
  size = 'medium',
  allowedActions,
  disabledActions,
  actionConfig,
  menuMinWidth,
  isCustomButtonWidth,
  loading,
}: Props<Action, NegotiationId>) => {
  const theme = useTheme()
  const [actionsOpen, setActionsOpen] = useState<HTMLElement | null>(null)
  const buttonGroupRef = useRef<HTMLDivElement | null>(null)

  const mainAction = allowedActions[0]
  const additionalActions = allowedActions.slice(1)

  const arrowButtonWidth = theme.spacing(size === 'small' ? 3 : 4)
  const buttonWidth = getPrimaryButtonWidth(
    allowedActions,
    size,
    arrowButtonWidth,
    menuMinWidth,
    isCustomButtonWidth,
  )

  if (allowedActions.length === 0) {
    return null
  }
  return (
    <ActionButtonGroup
      ref={buttonGroupRef}
      size={size}
      disableElevation
      minWidth={arrowButtonWidth}
    >
      <OptionalTooltipWrapper tooltip={actionConfig[mainAction].tooltip}>
        <LoadingButton
          size={size}
          color='accent'
          variant='contained'
          onClick={() => actionConfig[mainAction].onClick(id)}
          loading={loading}
          sx={{
            width: buttonWidth,
            pl: 2,
            justifyContent: additionalActions.length ? 'left' : undefined,
          }}
        >
          {actionConfig[mainAction].label}
        </LoadingButton>
      </OptionalTooltipWrapper>
      {!!additionalActions.length && (
        <>
          <ArrowButton
            size={size}
            color='accent'
            variant='contained'
            aria-haspopup='menu'
            onClick={() => setActionsOpen(buttonGroupRef.current)}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={10} thickness={5} />
            ) : (
              <ExpandMoreIcon fontSize='extra-small' />
            )}
          </ArrowButton>
          <StyledMenu
            open={!!actionsOpen}
            anchorEl={actionsOpen}
            minWidth={menuMinWidth}
            MenuListProps={SupportedMenuListProps[size]}
            onClose={() => setActionsOpen(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {additionalActions.map((action) => (
              <OptionalTooltipWrapper
                tooltip={actionConfig[action].tooltip}
                key={actionConfig[action].label}
              >
                <MenuItem
                  key={actionConfig[action].label}
                  sx={(theme) => ({
                    color: 'common.white',
                    py: 0.5,
                    fontSize: '0.875rem',
                    ':hover': { backgroundColor: alpha(theme.palette.common.white, 0.2) },
                  })}
                  onClick={() => {
                    actionConfig[action].onClick(id)
                    setActionsOpen(null)
                  }}
                >
                  {actionConfig[action].label}
                </MenuItem>
              </OptionalTooltipWrapper>
            ))}
            {disabledActions?.map((action) => (
              <OptionalTooltipWrapper
                tooltip={actionConfig[action].tooltip}
                key={actionConfig[action].label}
              >
                <MenuItem
                  key={actionConfig[action].label}
                  sx={(theme) => ({
                    color: 'lightGrey.light',
                    py: 0.5,
                    fontSize: '0.875rem',
                    ':hover': { backgroundColor: alpha(theme.palette.lightGrey.light, 0.2) },
                  })}
                  onClick={() => {
                    actionConfig[action].onClick(id)
                    setActionsOpen(null)
                  }}
                >
                  {actionConfig[action].label}
                </MenuItem>
              </OptionalTooltipWrapper>
            ))}
          </StyledMenu>
        </>
      )}
    </ActionButtonGroup>
  )
}

const ArrowButton = styled(Button)(({ theme }) => ({
  padding: 0,
  border: 0,
  background: theme.palette.menu.main,
  '&:hover': {
    background: lighten(theme.palette.menu.main, 0.2),
  },
}))

const ActionButtonGroup = styled(ButtonGroup, {
  shouldForwardProp: (prop) => !['minWidth'].includes(prop as string),
})<ButtonGroupProps & { minWidth: string }>(({ theme, minWidth }) => ({
  color: theme.palette.common.white,
  ' & .MuiButtonGroup-grouped:not(:first-of-type)': {
    marginLeft: 0,
  },
  '& .MuiButtonGroup-grouped': {
    minWidth,
  },
}))

interface StyledMenuProps extends MenuProps {
  minWidth?: number
}

const StyledMenu = styled(Menu, {
  shouldForwardProp: (prop) => prop !== 'minWidth',
})<StyledMenuProps>(({ theme, minWidth }) => ({
  marginTop: '2px',
  borderRadius: '4px',
  '& .MuiMenu-paper': {
    background: theme.palette.menu.main,
  },

  '& .MuiPaper-root': {
    minWidth: minWidth ?? 'unset',
  },
}))

export const ActionsButton = React.memo(ActionsButtonComponent) as typeof ActionsButtonComponent
