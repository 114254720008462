export enum WebsocketServerEvent {
  NEGOTIATION_UPDATED = 'NEGOTIATION_UPDATED',
  UNIT_STATISTICS_UPDATED = 'UNIT_STATISTICS_UPDATED',
  UNIT_UPDATED = 'UNIT_UPDATED',
  UNIT_BULK_UPDATED = 'UNIT_BULK_UPDATED',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export enum WebsocketClientEvent {
  SUBSCRIBE = 'SUBSCRIBE',
}
