import { Box, Button, Typography, styled } from '@mui/material'
import { GridToolbarContainer } from '@mui/x-data-grid-pro'
import UploadIcon from '@mui/icons-material/FileUploadOutlined'
import { useState } from 'react'
import { UploadDialog } from './UploadDialog'

export const Toolbar = () => {
  const [uploadOpen, setUploadOpen] = useState(false)

  return (
    <ToolbarContainer>
      <Typography variant='subtitle1'>Requests for Proposal</Typography>
      <Separator />
      <ToolbarButton
        startIcon={<UploadIcon />}
        style={{ marginBottom: '4px' }}
        onClick={() => setUploadOpen(true)}
      >
        Upload
      </ToolbarButton>
      {uploadOpen && <UploadDialog isOpen={uploadOpen} onClose={() => setUploadOpen(false)} />}
    </ToolbarContainer>
  )
}

const ToolbarContainer = styled(GridToolbarContainer)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  marginLeft: '10px',
}))

const ToolbarButton = styled(Button)(({ theme }) => ({
  fontSize: '12px',
  color: theme.palette.text.secondary,
  marginRight: theme.spacing(1),
  '.MuiButton-startIcon': {
    marginRight: '4px',
  },
}))

const Separator = styled(Box)({ flex: 1 })
