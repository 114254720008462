import { FormDialog } from '@components/FormDialog'
import {
  AddOrEditUserFormSchema,
  AddOrEditUserSchema,
} from '@pages/SettingsPage/ManageUsersPage/AddOrEditUserSchema'
import { zodResolver } from '@hookform/resolvers/zod'
import { AddUserModalForm } from '@pages/SettingsPage/ManageUsersPage/dialogs/AddUserModalForm'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import { CreateUserDto, getRoleFromDisplayName } from '@common/types'
import { captureException } from '@sentry/react'
import { useAddOrganizationUserMutation } from '@store/userManagement'
import { useOrgTag } from '../../../../hooks/useOrgTag'
import { isBackendApiErrorResponse, isQuerySuccessResponse } from '@store'
import { useSnackbar } from 'notistack'

interface Props {
  isOpen: boolean
  onClose: () => void
  refetchUsers: () => void
}

export const AddUserDialog = ({ isOpen, onClose, refetchUsers }: Props) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const form = useForm<AddOrEditUserFormSchema>({
    resolver: zodResolver(AddOrEditUserSchema),
  })
  const [addOrganizationUserMutation] = useAddOrganizationUserMutation()
  const orgTag = useOrgTag()
  const { enqueueSnackbar } = useSnackbar()

  const resetAndClose = () => {
    onClose()
    form.reset({ name: '', email: '', role: '', workspaces: null })
    setErrorMessage(null)
  }

  const onSubmit = async (data: AddOrEditUserFormSchema) => {
    const role = getRoleFromDisplayName(data.role)
    if (role === null) {
      const errorMessage = `Manage users: unknown user role ${data.role}`
      console.error(errorMessage)
      captureException(new Error(errorMessage))
      setErrorMessage(errorMessage)
    } else {
      const createUserRequest: CreateUserDto = {
        name: data.name,
        email: data.email,
        role: role,
        projectIdsAccessList: data.workspaces,
      }

      const response = await addOrganizationUserMutation({
        orgTag: orgTag,
        user: createUserRequest,
      })

      if (isQuerySuccessResponse(response)) {
        resetAndClose()
        enqueueSnackbar(`User ${data.name} created successfully!`, {
          variant: 'success',
        })
        refetchUsers()
      } else {
        const message = isBackendApiErrorResponse(response.error)
          ? `Error creating user: ${response.error.data.message}`
          : 'Unknown error creating user'
        setErrorMessage(message)
      }
    }
  }

  return (
    <FormDialog
      title={'New user'}
      open={isOpen}
      onSubmit={onSubmit}
      form={form}
      onCancel={resetAndClose}
      buttons={[
        { type: 'cancel', label: 'Discard changes' },
        { type: 'submit', label: 'Create' },
      ]}
      error={errorMessage}
      resolver={zodResolver(AddOrEditUserSchema)}
    >
      <AddUserModalForm form={form} />
    </FormDialog>
  )
}
