import { dateFormats, Formatter } from '@utils'
import { GridColDef } from '@mui/x-data-grid-pro'
import {
  SpotLaneLaneNegotiationDto,
  SpotLaneNegotiationStatus,
  SpotLaneNegotiationToUnitStatus,
} from '@logistics/pages/spotLane/types'
import { Chip, Typography } from '@mui/material'
import { CaptionedValueCell } from '@components/table'
import { parseISO } from 'date-fns'
import React from 'react'
import { getDistanceCaption } from '@logistics/pages/common/utils'
import { StatusConfig } from '@logistics/types'
import { ThumbDown, ThumbUp } from '@mui/icons-material'

const LANE_NEGOTIATION_STATUS_CONFIG: StatusConfig<SpotLaneNegotiationToUnitStatus> = {
  NOT_STARTED: { label: 'Not Started', color: 'stoneBlue' },
  FAILED: { label: 'Failed', color: 'error' },
  DECLINED: { label: 'Declined', color: 'error' },
  SUCCESS: { label: 'Success', color: 'success' },
}

export const createLaneNegotiationColumns = (
  formatter: Formatter,
): GridColDef<SpotLaneLaneNegotiationDto>[] => [
  {
    field: 'negotiation.id',
    headerName: 'Negotiation ID',
    disableColumnMenu: true,
    renderCell: ({ row }) => <Typography>{row.negotiation.id}</Typography>,
  },
  {
    field: 'supplier.id',
    headerName: 'Supplier ID',
    disableColumnMenu: true,
    renderCell: ({ row }) => <CaptionedValueCell mainContent={row.supplier.id} spacing={0} />,
  },
  {
    field: 'supplier.name',
    headerName: 'Supplier Name',
    disableColumnMenu: true,
    width: 300,
    renderCell: ({ row }) => (
      <CaptionedValueCell
        mainContent={row.supplier.name}
        captionContent={row.supplier.primaryEmail}
        spacing={0}
      />
    ),
  },
  {
    field: 'negotiationUnit.distanceInMs',
    headerName: 'Distance',
    disableColumnMenu: true,
    width: 150,
    renderCell: ({ row }) => (
      <Typography>
        {getDistanceCaption(row.negotiationUnit.distanceInMs / 1000, row.negotiation.projectLocale)}
      </Typography>
    ),
  },
  {
    field: 'negotiationUnit.currency',
    headerName: 'Currency',
    disableColumnMenu: true,
    renderCell: ({ row }) => <Typography>{row.negotiationUnit.currency}</Typography>,
  },
  {
    field: 'negotiationUnit.modelCost',
    headerName: 'Cost',
    disableColumnMenu: true,
    renderCell: ({ row }) => <Typography>{row.negotiationUnit.modelCost}</Typography>,
  },
  {
    field: 'negotiationUnit.limit',
    headerName: 'Limit',
    disableColumnMenu: true,
    renderCell: ({ row }) => <Typography>{row.negotiationUnit.limit}</Typography>,
  },
  {
    field: 'negotiationUnit.minVesselEta',
    headerName: 'Min Vessel ETA',
    disableColumnMenu: true,
    width: 120,
    renderCell: ({ row }) =>
      formatter.date(parseISO(row.negotiationUnit.minVesselEta), dateFormats.longDate),
  },
  {
    field: 'negotiationUnit.status',
    headerName: 'Status',
    disableColumnMenu: true,
    width: 150,
    renderCell: ({ row }) => (
      <Chip
        size='medium'
        variant='filled'
        {...LANE_NEGOTIATION_STATUS_CONFIG[row.negotiationUnit.status]}
      ></Chip>
    ),
  },
  {
    field: 'negotiation.status',
    headerName: 'Closed?',
    disableColumnMenu: true,
    width: 150,
    renderCell: ({ row }) =>
      row.negotiation.status === SpotLaneNegotiationStatus.CLOSED ? <ThumbUp /> : <ThumbDown />,
  },
]
