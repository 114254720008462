import { useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { Typography } from '@mui/material'
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded'
import { StyledBackground, StyledFileUpload, StyledGrid } from './AttachmentsUpload.styles'
import { DetachedAttachment } from '@procurement/store/schema'
import { MAX_FILE_SIZE, MAX_TOTAL_FILE_SIZE } from '@procurement/constants'

const ALLOWED_MIME_TYPES = [
  'text/csv',
  'image/png',
  'image/jpeg',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
]

export const AttachmentsUpload = () => {
  const { control, getValues } = useFormContext<{ detachedAttachments: DetachedAttachment[] }>()
  const { append } = useFieldArray({ control, name: 'detachedAttachments' })
  const [errors, setErrors] = useState<string[]>([])

  const handleFilesSelect = (selectedFiles: File[]) => {
    const selectedFilesErrors = []
    const currentAttachmentNames = getValues('detachedAttachments').map(
      (attachment) => attachment.file.name,
    )
    let totalFileSize = 0

    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i]

      if (currentAttachmentNames.includes(file.name)) {
        continue
      }
      totalFileSize += file.size

      if (!ALLOWED_MIME_TYPES.includes(file.type)) {
        selectedFilesErrors.push(`Type of ${file.name} file is not supported`)
        continue
      }

      if (totalFileSize > MAX_TOTAL_FILE_SIZE) {
        selectedFilesErrors.push(`Selected files total size exceeds the maximum of 50Mb`)
      }

      if (file.size > MAX_FILE_SIZE) {
        selectedFilesErrors.push(`File ${file.name} is too big`)
        continue
      }

      append({
        file,
        isVisibleToSupplier: true,
      })
    }

    setErrors(selectedFilesErrors)
  }

  return (
    <>
      <StyledGrid item xs={4}>
        <StyledBackground elevation={0}>
          <StyledFileUpload callback={handleFilesSelect}>
            <UploadFileRoundedIcon fontSize='large' sx={{ width: '100%', mt: 1.5, mb: 0.5 }} />
            <Typography fontSize='0.625rem' sx={{ width: '100%' }}>
              Drag the filled in template here, or
            </Typography>
            <Typography fontSize='0.625rem' sx={{ mb: 1.5, width: '100%', color: 'link.main' }}>
              select from your computer
            </Typography>
          </StyledFileUpload>
        </StyledBackground>
        {errors.map((errorMessage) => (
          <Typography
            key={errorMessage}
            color='error'
            variant='caption'
            component='p'
            sx={{ pt: 1, pl: 0.5 }}
          >
            {errorMessage}
          </Typography>
        ))}
      </StyledGrid>
    </>
  )
}
