import { Formatter } from '@utils'
import { NegotiationEventListItem, UseCase } from '../../../store/types'
import { GridColDef } from '@mui/x-data-grid-pro'
import { ProductType } from '@pactum/core-backend-types'
import {
  actions,
  buyerContactEmail,
  contractStartDate,
  createdAt,
  initialAmount,
  negotiationsExpireTime,
  negotiationStatus,
  paymentTerm,
  savings,
  selectCheckBox,
  statusUpdatedAt,
  supplierName,
  uniqueName,
} from '../../../utils/columns'

export const createColumns = ({
  formatter,
  negotiationEvents,
  productType,
  useCase,
}: {
  formatter: Formatter
  negotiationEvents?: NegotiationEventListItem[]
  productType: ProductType.ContractCost | ProductType.Purchasing
  useCase?: UseCase
}): GridColDef<NegotiationEventListItem>[] => {
  if (!negotiationEvents || negotiationEvents.length === 0) {
    return []
  }

  const isContractCost = productType === ProductType.ContractCost

  return [
    selectCheckBox,
    uniqueName(productType),
    supplierName,
    buyerContactEmail,
    negotiationsExpireTime(formatter, negotiationEvents),
    ...(isContractCost ? [contractStartDate(formatter)] : []),
    initialAmount(formatter, useCase),
    ...(isContractCost ? [paymentTerm(useCase)] : []),
    savings,
    createdAt,
    statusUpdatedAt(formatter),
    negotiationStatus,
    actions,
  ]
}
