import { ContractModelTypes } from '@procurement/store/types'
import { ProductType } from '@pactum/core-backend-types'
import { ZodError, ZodIssueCode } from 'zod'
import get from 'lodash.get'
import { NegotiationEventFormData, NegotiationMode } from './schema'
import { isNullish } from '@procurement/utils/isNullish'
import { MAX_TOTAL_FILE_SIZE } from '@procurement/constants'

const invoiceDatesValid = (formData: NegotiationEventFormData, visibleFields: string[]) => {
  const invoiceFieldRequired = visibleFields.includes('lineItems.invoiceDate')

  if (!invoiceFieldRequired || formData.contractModel?.id !== ContractModelTypes.SignedOrderForm) {
    return true
  }

  return formData.lineItems.every(
    (item) => formData.contractModel?.properties?.invoiceDates?.[item.numberInCollection!],
  )
}

const spendAmountValid = (formData: NegotiationEventFormData) => {
  return formData.negotiationMode === NegotiationMode.Spend ? Boolean(formData.spendAmount) : true
}

const lineItemDeliveryAddressValid = (
  formData: NegotiationEventFormData,
  visibleFields: string[],
) => {
  const deliveryAddressFieldRequired = visibleFields.includes('lineItems.deliveryLocation')

  if (!deliveryAddressFieldRequired) {
    return true
  }

  return formData.lineItems.every((item) => item.deliveryLocation)
}

const paymentTermsValid = (formData: NegotiationEventFormData) => {
  if (!formData.paymentDaysEnabled) {
    return true
  }

  const supplier = formData.suppliers[0]
  const existingDays = supplier?.initialPaymentTerms?.paymentDays
  const maxDays = supplier?.negotiationSettings?.maxPaymentDays
  const minDays = supplier?.negotiationSettings?.minPaymentDays

  const params = [existingDays, maxDays, minDays]
  const all = params.every(Boolean)
  const none = params.every((param) => !param)

  return all || none
}

const attachmentsSizeValid = (formData: NegotiationEventFormData) =>
  formData.detachedAttachments.reduce((size, attachment) => size + attachment.file.size, 0) <
  MAX_TOTAL_FILE_SIZE

export const validateNegotiationEventForm = (
  formData: NegotiationEventFormData,
  visibleFields: string[],
  productType?: ProductType,
) => {
  const isPurchasing = productType === ProductType.Purchasing
  const isContractCost = productType === ProductType.ContractCost

  if (!spendAmountValid(formData)) {
    throw new Error('Estimated spend amount and duration must be specified')
  }

  if (isPurchasing && !lineItemDeliveryAddressValid(formData, visibleFields)) {
    throw new Error('Every line item must have Incoterm location specified')
  }

  if (isContractCost && !invoiceDatesValid(formData, visibleFields)) {
    throw new Error('Every line item must have Invoice date specified')
  }

  if (isContractCost && !paymentTermsValid(formData)) {
    throw new Error(
      'Either existing, minimum and maximum payment days must all be specified or none of them',
    )
  }

  if (!attachmentsSizeValid(formData)) {
    throw new Error('You can only upload files with a total size of up to 50Mb at a time')
  }
}

const getValidationErrors = (formData: NegotiationEventFormData, requiredFields: string[]) =>
  requiredFields
    .map((field) => ({
      code: ZodIssueCode.custom,
      path: [field],
      message: isNullish(get(formData, field)) ? 'Required' : '',
    }))
    .filter((issue) => issue.message)

export const createRequiredFieldsValidationErrorCallback =
  (requiredFields: string[], visibleFields: string[], productType?: ProductType) =>
  (formData: NegotiationEventFormData) => {
    let filteredRequiredFields = requiredFields.filter((field) => visibleFields.includes(field))
    if (productType === ProductType.ContractCost) {
      const contractDatesFields = [
        'suppliers.0.negotiationSettings.contractStartDate',
        'suppliers.0.negotiationSettings.contractLength',
      ]
      const warrantyFields = [
        'suppliers.0.negotiationSettings.warranty.benchmark',
        'suppliers.0.negotiationSettings.warranty.minLength',
        'suppliers.0.negotiationSettings.warranty.maxLength',
      ]
      const paymentDaysFields = [
        'suppliers.0.initialPaymentTerms.paymentDays',
        'suppliers.0.negotiationSettings.minPaymentDays',
        'suppliers.0.negotiationSettings.maxPaymentDays',
      ]
      const contractIncotermFields = [
        'suppliers.0.negotiationSettings.contractIncoterms.benchmark',
        'suppliers.0.negotiationSettings.contractIncoterms.acceptable',
      ]

      filteredRequiredFields = [
        ...filteredRequiredFields
          .filter((field) => !contractDatesFields.includes(field))
          .filter((field) => !warrantyFields.includes(field))
          .filter((field) => !paymentDaysFields.includes(field))
          .filter((field) => !contractIncotermFields.includes(field)),
        ...(formData.contractDatesEnabled ? contractDatesFields : []),
        ...(formData.warrantyEnabled ? warrantyFields : []),
        ...(formData.paymentDaysEnabled ? paymentDaysFields : []),
        ...(formData.contractIncotermsEnabled ? contractIncotermFields : []),
      ]
    }

    const validationErrors = getValidationErrors(formData, filteredRequiredFields)

    if (validationErrors.length) {
      throw new ZodError(validationErrors)
    }

    return true
  }
