import { ROWS_PER_PAGE_OPTIONS } from '@components/table/pagination'
import { RowsPerPageOption } from '@components/table/pagination/PaginationFooter'
import { DataGridProProps, GridPaginationModel } from '@mui/x-data-grid-pro'
import { useState } from 'react'

type UseGridPaginationProps = {
  defaultPageSize?: RowsPerPageOption
}

export const useGridPagination = (
  props: UseGridPaginationProps = {},
): Partial<DataGridProProps> => {
  const { defaultPageSize = ROWS_PER_PAGE_OPTIONS[0] } = props
  const [paginationModel, onPaginationModelChange] = useState<GridPaginationModel>({
    page: 0,
    pageSize: defaultPageSize,
  })

  return {
    paginationModel,
    onPaginationModelChange,
    pagination: true,
    hideFooter: false,
    paginationMode: 'client',
  }
}
