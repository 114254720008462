export function getAdminBaseUrl(): string | undefined {
  const env = import.meta.env
  if (env.VITE_REACT_APP_ENV === 'development') {
    return 'http://localhost:3000'
  }
  if (env.VITE_REACT_APP_ENV === 'sandbox') {
    return 'https://admin-sandbox.pactum.com'
  }
  if (env.VITE_REACT_APP_ENV === 'staging' && env.VITE_REACT_APP_REGION === 'us') {
    return 'https://admin-staging.pactum.com'
  }
  if (env.VITE_REACT_APP_ENV === 'staging' && env.VITE_REACT_APP_REGION === 'eu') {
    return 'https://admin-staging.eu-pactum.com'
  }
  if (env.VITE_REACT_APP_ENV === 'production' && env.VITE_REACT_APP_REGION === 'us') {
    return 'https://admin.pactum.com'
  }
  if (env.VITE_REACT_APP_ENV === 'production' && env.VITE_REACT_APP_REGION === 'eu') {
    return 'https://admin.eu-pactum.com'
  }
  return undefined
}

export function getAdminNegotiationEventUrl(
  projectId: number,
  negotiationEventExternalId: string,
): string {
  return `${getAdminBaseUrl()}/purchasing/requisition/${negotiationEventExternalId}?project=${projectId}`
}

export function getAdminWorkspaceUrl(projectId: number): string {
  return `${getAdminBaseUrl()}/?project=${projectId}`
}

export function openNegotiationInAdmin(id: string) {
  window.open(`${getAdminBaseUrl()}/negotiations/${id}`, '_blank')
}
