import React, { createContext, useContext, useEffect, useState } from 'react'
import { APP_HEADER_HEIGHT } from '@constants'
import { Alert, Box, Container, styled, Typography } from '@mui/material'

interface Props {
  message: string | null
  setMessage: (message: string | null) => void
}

const SuccessAlert = ({ message, setMessage }: Props) => {
  useEffect(() => {
    if (message === null) {
      return
    }
    const timeoutId = setTimeout(() => {
      setMessage(null)
    }, 30_000)
    return () => clearTimeout(timeoutId)
  }, [message, setMessage])

  if (message === null) {
    return null
  }

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: 'rgba(11, 142, 24, 0.4)',
        position: 'sticky',
        top: APP_HEADER_HEIGHT,
        zIndex: theme.zIndex.contentOverlay,
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      })}
    >
      <Container sx={{ px: 6, height: '100%' }} maxWidth='xl' disableGutters>
        <StyledAlert onClose={() => setMessage(null)} icon={false}>
          <Typography variant='normal' fontWeight={700}>
            {message}
          </Typography>
        </StyledAlert>
      </Container>
    </Box>
  )
}

const StyledAlert = styled(Alert)(() => ({
  backgroundColor: 'inherit',
  padding: '1px 0',

  '.MuiAlert-message': {
    height: '100%',
    width: '100%',
    alignSelf: 'center',
  },

  '.MuiAlert-message, .MuiAlert-action': {
    padding: 0,
  },
}))

const SuccessAlertContext = createContext<{
  message: string | null
  setMessage: (message: string | null) => void
}>({
  message: null,
  setMessage: () => {},
})

export const SuccessAlertProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [message, setMessage] = useState<string | null>(null)

  return (
    <SuccessAlertContext.Provider value={{ message, setMessage }}>
      <SuccessAlert message={message} setMessage={setMessage} />
      {children}
    </SuccessAlertContext.Provider>
  )
}

export const useSuccessAlert = () => useContext(SuccessAlertContext)
