import { UpdateArticleInputsRequest } from '@common/dto'
import { ArticleWithInput, PreparationStatus } from '@common/types'
import { Autocomplete, Box, Stack, TextField } from '@mui/material'
import { useFormatter } from '@shared/hooks'
import debounce from 'lodash.debounce'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  isBackendApiErrorResponse,
  negotiationPreparationApi,
  useSubmitNegotiationPreparationMutation,
  useUpdateArticleInputsMutation,
} from 'src/merchandising/store/'
import { PactumButton } from 'src/shared/components/PactumButton'
import { DataGrid, dataGridProps } from 'src/shared/components/table'
import { useNegotiationPreparationData } from '../NegotiationPreparationPage'
import { CostModelInsights } from './CostModelInsights'
import { PricingInsights } from './PricingInsights'
import { InPlaceValues, RowDetailsPanel } from './RowDetailsPanel'
import { SupportiveInsights } from './SupportiveInsights'
import { createColumns } from './columns'

type ArticleSearchOption = {
  name: string
  ean: string
}

const defaultSearchValue = { name: '', ean: '' } as const

export const NegotiationConfiguration = () => {
  const formatter = useFormatter()
  const { data } = useNegotiationPreparationData()
  const { enqueueSnackbar } = useSnackbar()
  const { negotiationPreparationId } = useParams<{ negotiationPreparationId: string }>()
  const [changedArticleValues, setChangedArticleValues] =
    useState<UpdateArticleInputsRequest | null>(null)
  const [searchValue, setSearchValue] = useState<ArticleSearchOption>(defaultSearchValue)

  const [updateArticleValues, { isLoading: isUpdateLoading }] = useUpdateArticleInputsMutation()
  const { isFetching: isReFetchingArticles } =
    negotiationPreparationApi.endpoints.getNegotiationPreparation.useQueryState(
      negotiationPreparationId ?? '',
    )
  const [submitNegotiationPreparation, { isLoading: isSubmitLoading }] =
    useSubmitNegotiationPreparationMutation()

  const onNegotiationPreparationSubmit = async () => {
    if (negotiationPreparationId) {
      try {
        await submitNegotiationPreparation(negotiationPreparationId).unwrap()
        enqueueSnackbar('Negotiation preparation was successfully submitted', {
          variant: 'success',
        })
      } catch (e) {
        enqueueSnackbar(
          isBackendApiErrorResponse(e)
            ? e.data.message
            : 'There was an error while submitting negotiation',
          {
            variant: 'error',
          },
        )
      }
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceRequest = useCallback(
    debounce(async (changedArticleValues: UpdateArticleInputsRequest) => {
      if (negotiationPreparationId) {
        try {
          await updateArticleValues({
            negotiationPreparationId,
            payload: changedArticleValues,
          }).unwrap()
        } catch (e) {
          enqueueSnackbar(
            isBackendApiErrorResponse(e)
              ? e.data.message
              : 'There was an error when submitting new inputs',
            { variant: 'error' },
          )
        }
      }
    }, 600),
    [],
  )

  const onArticleChanged = async (ean: string, values: InPlaceValues) => {
    setChangedArticleValues({ ean: ean, ...values })
  }

  useEffect(() => {
    if (changedArticleValues) {
      debounceRequest(changedArticleValues)
    }
  }, [changedArticleValues, debounceRequest])

  const columns = createColumns(formatter)
  const filteredArticles =
    searchValue.ean && searchValue.name
      ? data.articles.filter(
          (article) =>
            article.name.toLowerCase().includes(searchValue.name.toLowerCase()) ||
            article.ean.includes(searchValue.ean),
        )
      : data.articles

  const searchOptions: ArticleSearchOption[] = data.articles.map((article) => ({
    name: article.name,
    ean: article.ean,
  }))

  return (
    <>
      <Box mt={2} px={6} sx={{ width: '100%' }}>
        <Autocomplete
          value={searchValue}
          options={searchOptions}
          getOptionLabel={(option) => option.ean && `${option.name} (${option.ean})`}
          isOptionEqualToValue={(option, value) => option.ean === value.ean}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Search items'
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          onChange={(_, selectedValue) => setSearchValue(selectedValue ?? defaultSearchValue)}
        />
      </Box>
      <Box mt={3} pr={6}>
        <DataGrid
          {...dataGridProps}
          columns={columns}
          rows={filteredArticles}
          getRowId={(row: ArticleWithInput) => row.ean}
          className='dg-expandable'
          getDetailPanelContent={({ row }: { row: ArticleWithInput }) => (
            <RowDetailsPanel
              isEditingDisabled={!data.isEditingAllowed}
              article={row}
              analysis={data.analysis}
              onValueChanged={(ean: string, values: InPlaceValues) => onArticleChanged(ean, values)}
              defaultInputs={data.defaultInputs.articles[row.ean]}
              competitorPrices={data.analysis.competitorPrices?.[row.ean]}
            />
          )}
          getDetailPanelHeight={() => 'auto'}
          loading={isUpdateLoading || isReFetchingArticles}
          localeText={{
            noRowsLabel: 'No articles present',
          }}
        />
      </Box>
      <Stack spacing={4} mt={4} px={6}>
        <PricingInsights />
        <CostModelInsights />
        <SupportiveInsights />
      </Stack>
      <Stack direction='row' justifyContent='flex-end' spacing={2} mt={4} pr={6}>
        {data.status === PreparationStatus.ACCEPT_THRESHOLDS && (
          <PactumButton
            sx={{ ml: 'auto' }}
            disabled={!data.isEditingAllowed || isSubmitLoading}
            onClick={onNegotiationPreparationSubmit}
            color='secondary'
          >
            Submit for negotiation
          </PactumButton>
        )}
      </Stack>
      <Box sx={{ height: (theme) => theme.spacing(4) }} />
    </>
  )
}
