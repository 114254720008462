import { useCallback, useMemo, useState } from 'react'
import { Button, Stack, Typography } from '@mui/material'
import { CONTAINER_X_PADDING } from '@logistics/constants'
import { DataGrid, dataGridProps, DetailsPanelBorders } from '@components/table'
import { useFormatter } from '@shared/hooks'
import { HEADER_HEIGHT, ROW_HEIGHT } from '@components/table/DataGrid'
import { ActionConfigMap } from '@components/ActionsButton'
import { extractErrorMessage } from '@logistics/utils/error'
import { useSnackbar } from 'notistack'
import { applyNames } from '@logistics/utils/nameHelper'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { DeleteConfirmationModal, StartConfirmationModal } from '@components/ConfirmationModal'
import { capitalizeFirstLetter } from '@utils'
import { createInnerNegotiationColumns } from '@logistics/pages/spotLoad/UnitPage/UnitList/innerNegotiationColumns'
import isNil from 'lodash/isNil'
import { SpotLoadUnit } from '@logistics/pages/spotLoad/types'
import {
  SpotLoadNegotiation,
  SpotLoadNegotiationAction,
} from '@logistics/pages/spotLoad/types/negotiation'
import { useApplyNegotiationActionMutation } from '@logistics/store/negotiationApi'

interface Props {
  unit: SpotLoadUnit
}

const DRAGGABLE_COLUMN_WIDTH = '50px'
const MAX_ROWS = 5

const getHeight = (itemCount: number) => {
  const rows = itemCount > MAX_ROWS ? MAX_ROWS : itemCount
  const peekNextRow = itemCount > MAX_ROWS ? ROW_HEIGHT / 2 : 0

  return HEADER_HEIGHT + rows * ROW_HEIGHT + peekNextRow + 'px'
}

export const UnitDetailsPanel = ({ unit }: Props) => {
  const { activeProjectTag } = useActiveProject()
  const formatter = useFormatter()
  const { enqueueSnackbar } = useSnackbar()

  const [callApplyNegotiationAction, { isLoading }] = useApplyNegotiationActionMutation()

  const [confirmationModal, setConfirmationModal] = useState<SpotLoadNegotiationAction | null>(null)
  const [currentNegotiationId, setCurrentNegotiationId] = useState<number>()

  const applyNegotiationAction = useCallback(
    async (id: number, action: SpotLoadNegotiationAction) => {
      try {
        await callApplyNegotiationAction({
          negotiationIds: [id],
          action,
          projectTag: activeProjectTag,
        }).unwrap()

        enqueueSnackbar('Success!', { variant: 'success' })
      } catch (e) {
        const message = extractErrorMessage(e)

        enqueueSnackbar(
          message ?? `Error occurred while performing "${action.toLowerCase()}" action`,
          {
            variant: 'error',
          },
        )
      }
    },
    [callApplyNegotiationAction, enqueueSnackbar, activeProjectTag],
  )

  const negotiationActionConfig = Object.values(SpotLoadNegotiationAction).reduce(
    (acc, action: SpotLoadNegotiationAction) => ({
      ...acc,
      [action]: {
        label: capitalizeFirstLetter(action.toLowerCase()),
        onClick: async (id: number) => {
          setCurrentNegotiationId(id)

          if (
            action === SpotLoadNegotiationAction.START ||
            action === SpotLoadNegotiationAction.DELETE
          ) {
            setConfirmationModal(action)
          } else {
            await applyNegotiationAction(id, action)
          }
        },
      },
    }),
    {} as ActionConfigMap<SpotLoadNegotiationAction, SpotLoadNegotiation['id']>,
  )

  const height = useMemo(() => getHeight(unit.negotiations.length), [unit])
  const columns = useMemo(
    () => createInnerNegotiationColumns(formatter, negotiationActionConfig),
    [formatter, negotiationActionConfig],
  )

  return (
    <Stack pl={CONTAINER_X_PADDING + 1.5}>
      <StartConfirmationModal
        open={!isNil(currentNegotiationId) && confirmationModal === SpotLoadNegotiationAction.START}
        onCancel={() => setConfirmationModal(null)}
        onSubmit={async () => {
          setConfirmationModal(null)

          if (currentNegotiationId) {
            await applyNegotiationAction(currentNegotiationId, SpotLoadNegotiationAction.START)
          }
        }}
      />
      <DeleteConfirmationModal
        open={
          !isNil(currentNegotiationId) && confirmationModal === SpotLoadNegotiationAction.DELETE
        }
        onCancel={() => {
          setConfirmationModal(null)
        }}
        onSubmit={async () => {
          setConfirmationModal(null)

          if (currentNegotiationId) {
            await applyNegotiationAction(currentNegotiationId, SpotLoadNegotiationAction.DELETE)
          }
        }}
      />
      <DetailsPanelBorders py={2} spacing={2}>
        <Typography variant='subtitle2'>
          Negotiations ({unit.negotiations.length} carriers)
        </Typography>
        <Stack height={height} width='80%'>
          <DataGrid
            {...dataGridProps}
            slots={{ ...dataGridProps.slots, noRowsOverlay: NoRowsOverlay }}
            autoHeight={false}
            loading={isLoading}
            columns={columns}
            rows={unit.negotiations}
            getRowId={(row: SpotLoadNegotiation) => row.supplier_name}
            columnVisibilityModel={{
              // TODO not supported yet
              actions: false,
            }}
            rowReordering
          />
        </Stack>
        <Stack direction='row' pl={DRAGGABLE_COLUMN_WIDTH}>
          <Button sx={{ display: 'none' }} variant='outlined' size='small' color='tertiary'>
            + Add Carriers
          </Button>
        </Stack>
      </DetailsPanelBorders>
    </Stack>
  )
}

const NoRowsOverlay = () => {
  return (
    <Stack height='100%' alignItems='center' justifyContent='center'>
      {applyNames(
        'No carriers were found for the unit, please try adding some by clicking the button below.',
      )}
    </Stack>
  )
}
