import { useContext } from 'react'
import { Grid } from '@mui/material'
import { AutocompleteFieldCtrl, NumberFieldCtrl, TextFieldCtrl } from '@components/Form'
import { PaymentDayObject } from '../../../store/types'
import { getPaymentDaysLabel, transformPaymentDaysOptions } from '../../../utils/paymentDays'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useGetConfigurationQuery } from '../../../store/purchasing'
import { ExistingPaymentDaysFieldCtrl } from '../../ExistingPaymentDaysFieldCtrl/ExistingPaymentDaysFieldCtrl'
import { NegotiationEventFormContext } from '../NegotiationEventFormContext'
import {
  createGetFieldControllerParams,
  FormRow,
  FormSectionTitle,
  HideableBlock,
} from '../../FormCommon/FormCommon'
import { ProductType } from '@pactum/core-backend-types'

interface Props {
  paymentDays: (number | PaymentDayObject)[]
  productType?: ProductType
}

export const SupplierInformation = ({ paymentDays, productType }: Props) => {
  const { activeProjectTag } = useActiveProject()
  const { data: configuration } = useGetConfigurationQuery({ projectTag: activeProjectTag })
  const { requiredFields, visibleFields, disabledFields } = useContext(NegotiationEventFormContext)
  const getFieldControllerParams = createGetFieldControllerParams({
    requiredFields,
    disabledFields,
  })

  const isNotPurchasing = productType !== ProductType.Purchasing

  // TODO: check if all suite configs have language and locale options matching
  const localeOptions = configuration?.data.suite?.localeOptions ?? []
  const languageOptions = configuration?.data.suite?.languageOptions ?? []
  const getLanguageName = (locale: string) =>
    languageOptions.find((lang) => lang.locale === locale)?.name

  const paymentDayObjects: PaymentDayObject[] = transformPaymentDaysOptions(paymentDays)

  return (
    <>
      <HideableBlock
        hidden={!visibleFields.includes('supplierInformationTitle') && isNotPurchasing}
      >
        <FormSectionTitle>Supplier information</FormSectionTitle>
      </HideableBlock>
      <FormRow>
        <HideableBlock hidden={!visibleFields.includes('suppliers.0.name') && isNotPurchasing}>
          <Grid item xs={6}>
            <TextFieldCtrl
              helperText='This is the company name of your supplier'
              fullWidth
              {...getFieldControllerParams('suppliers.0.name', 'Supplier company name')}
            />
          </Grid>
        </HideableBlock>
        <HideableBlock
          hidden={!visibleFields.includes('suppliers.0.externalId') && isNotPurchasing}
        >
          <Grid item xs={6}>
            <TextFieldCtrl
              helperText='Supplier ID is the code you use to identify the supplier'
              fullWidth
              {...getFieldControllerParams('suppliers.0.externalId', 'Supplier ID')}
            />
          </Grid>
        </HideableBlock>
      </FormRow>
      <FormRow>
        <HideableBlock
          hidden={!visibleFields.includes('suppliers.0.contactName') && isNotPurchasing}
        >
          <Grid item xs={6}>
            <TextFieldCtrl
              helperText='Name of the person who will receive the emails'
              fullWidth
              {...getFieldControllerParams('suppliers.0.contactName', 'Supplier contact name')}
            />
          </Grid>
        </HideableBlock>
        <HideableBlock hidden={!visibleFields.includes('suppliers.0.email') && isNotPurchasing}>
          <Grid item xs={6}>
            <TextFieldCtrl
              type='email'
              helperText='Negotiation invitations are sent to this address'
              fullWidth
              {...getFieldControllerParams('suppliers.0.email', 'Supplier email address')}
            />
          </Grid>
        </HideableBlock>
      </FormRow>
      <FormRow>
        <HideableBlock hidden={!visibleFields.includes('suppliers.0.phone') && isNotPurchasing}>
          <Grid item xs={6}>
            <TextFieldCtrl
              helperText='Contact phone number for the supplier'
              fullWidth
              {...getFieldControllerParams('suppliers.0.phone', 'Supplier phone number')}
            />
          </Grid>
        </HideableBlock>
        <HideableBlock hidden={!visibleFields.includes('suppliers.0.locale') && isNotPurchasing}>
          <Grid item xs={6}>
            <AutocompleteFieldCtrl
              helperText='Negotiations are held in this language'
              freeSolo={false}
              options={isNotPurchasing ? languageOptions.map((lang) => lang.locale) : localeOptions}
              getOptionLabel={(locale) => getLanguageName(locale as string) ?? (locale as string)}
              variant='outlined'
              fullWidth
              {...getFieldControllerParams('suppliers.0.locale', 'Negotiation language')}
            />
          </Grid>
        </HideableBlock>
      </FormRow>

      <HideableBlock
        hidden={!visibleFields.includes('suppliers.0.initialPaymentTerms') && isNotPurchasing}
      >
        <FormRow>
          <Grid item xs={12} md={4}>
            <NumberFieldCtrl
              variant='outlined'
              helperText='Current agreed discount (%)'
              fullWidth
              {...getFieldControllerParams(
                'suppliers.0.initialPaymentTerms.discount',
                'Early discount (%)',
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ExistingPaymentDaysFieldCtrl
              variant='outlined'
              helperText='Current agreed discount days'
              // TODO: provide early discount days options from procurement backend
              options={[]}
              getOptionLabel={getPaymentDaysLabel}
              fullWidth
              {...getFieldControllerParams(
                'suppliers.0.initialPaymentTerms.discountDays',
                'Early discount days',
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ExistingPaymentDaysFieldCtrl
              helperText='Current agreed payment days'
              options={paymentDayObjects}
              getOptionLabel={getPaymentDaysLabel}
              variant='outlined'
              freeSolo={true}
              fullWidth
              {...getFieldControllerParams(
                'suppliers.0.initialPaymentTerms.paymentDays',
                'Payment days',
              )}
            />
          </Grid>
        </FormRow>
      </HideableBlock>
    </>
  )
}
