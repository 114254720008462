import { omit, pick } from 'lodash'
import { useUser } from '@hooks'
import { NegotiationEvent } from '@procurement/store/types'
import { validateNegotiationEventForm } from '@procurement/components/NegotiationEventForm/validation'
import {
  useCreateNegotiationEventMutation,
  useGetConfigurationQuery,
  useUpdateNegotiationEventMutation,
} from '@procurement/store/purchasing'
import { useNegotiationEventForm } from '@procurement/components/NegotiationEventForm/NegotiationEventFormContext'
import {
  enrichForRebates,
  enrichForRebatesWithApplicableSpend,
} from '@procurement/components/NegotiationEventForm/utils/formDataEnricher'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useSyncNegotiationEventAttachments } from '@procurement/hooks/useSyncNegotiationEventAttachments'
import { mapFormDataToDto } from '../mappers'
import { NegotiationEventFormData } from '../schema'

export type UpsertNegotiationEventParams = {
  formData: NegotiationEventFormData
  negotiationEvent: NegotiationEvent | null
}

const firstNonEmpty = (...args: (string | null | undefined)[]) => {
  // Note: '' ?? 'value' => ''
  return args.find((s) => !!s && s.length)
}

export const useNegotiationEventMutations = () => {
  const { activeProjectTag } = useActiveProject()
  const { data: configResponse } = useGetConfigurationQuery({ projectTag: activeProjectTag })
  const { visibleFields } = useNegotiationEventForm()
  const { user } = useUser()

  const [updateNegotiationEventRequest] = useUpdateNegotiationEventMutation()
  const [createNegotiationEventRequest] = useCreateNegotiationEventMutation()
  const [syncNegotiationEventAttachments] = useSyncNegotiationEventAttachments()

  const config = configResponse?.data
  const productType = config?.suite?.productType

  const suiteConfig = config?.suite
  const rebatesEnabled = visibleFields.includes('rebatesEnabled')
  const rebatesEnabledWithApplicableSpend = rebatesEnabled && !visibleFields.includes('lineItems')

  const upsertNegotiationEvent = async ({
    negotiationEvent,
    formData,
  }: UpsertNegotiationEventParams) => {
    let negotiationEventFormData = { ...formData }

    if (rebatesEnabled) {
      negotiationEventFormData = enrichForRebates(formData)
    } else if (rebatesEnabledWithApplicableSpend) {
      negotiationEventFormData = enrichForRebatesWithApplicableSpend(formData)
    }

    validateNegotiationEventForm(negotiationEventFormData, visibleFields, productType)

    const buyerContactName = firstNonEmpty(
      negotiationEventFormData.buyerContactName,
      negotiationEvent?.buyerContactName,
      user?.name,
    )

    const buyerContactEmail = firstNonEmpty(
      negotiationEventFormData.buyerContactEmail,
      negotiationEvent?.buyerContactEmail,
      user?.email,
    )

    const negotiationsExpireDays =
      formData.negotiationsExpireDays ??
      negotiationEvent?.negotiationsExpireDays ??
      suiteConfig?.negotiationsExpireDays

    const negotiationEventDto = mapFormDataToDto(
      negotiationEventFormData,
      {
        additionalData: {
          buyerContactName,
          buyerContactEmail,
          negotiationsExpireDays,
        },
      },
      suiteConfig,
    )

    let negotiationEventId = negotiationEvent?.id

    const commonArgs = {
      negotiationEventData: suiteConfig?.onlySubmitAttachments
        ? pick(negotiationEventDto, 'attachments')
        : omit(negotiationEventDto, 'attachments'),

      projectTag: activeProjectTag,
    }

    if (negotiationEventId) {
      await updateNegotiationEventRequest({ ...commonArgs, negotiationEventId }).unwrap()
    } else {
      const createdNegotiationEvent = await createNegotiationEventRequest(commonArgs).unwrap()
      negotiationEventId = createdNegotiationEvent.id
    }

    if (negotiationEventId) {
      await syncNegotiationEventAttachments({
        data: formData,
        negotiationEventId,
      })
    }
  }

  return {
    createNegotiationEvent: upsertNegotiationEvent,
    updateNegotiationEvent: upsertNegotiationEvent,
  }
}
