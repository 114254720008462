import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import { ItemThresholds } from '@common/types'
import { asNumberOrNull } from 'src/main/utils/number'

interface Props {
  open: boolean
  onCancel: () => void
  onChange: (updatedThresholds: ItemThresholds) => void
  onApply: () => void
  thresholds: ItemThresholds
  enableWeek2NegotiationQuantityRequirementEditing: boolean
}

export const ItemThresholdsDialog = ({
  open,
  onCancel,
  onChange,
  onApply,
  thresholds,
  enableWeek2NegotiationQuantityRequirementEditing,
}: Props) => {
  return (
    <Dialog open={open} PaperProps={{ sx: { maxWidth: '500px' } }}>
      <DialogTitle>Item thresholds</DialogTitle>
      <DialogContent>
        <Grid container rowGap={2} p={1}>
          <Grid container rowGap={1} columnSpacing={2}>
            <Grid item xs={12}>
              <Typography variant='body1'>
                Thresholds for inconclusive price agreement warning
                <Tooltip
                  placement={'right'}
                  title='Should a price above the set threshold be considered (e.g. in an offer made by the supplier),
                  then the supplier will be notified that the agreement can not be considered conclusive without further
                  offline confirmation from Vodafone. This only applies if the price under consideration is still at or
                  below the Least Acceptable Agreement (LAA) threshold.'
                >
                  <HelpIcon sx={{ marginLeft: 0.5, fontSize: '100%' }} />
                </Tooltip>
              </Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                label='Invoice price'
                type='number'
                value={
                  thresholds.conclusive_invoice_price === null
                    ? ''
                    : thresholds.conclusive_invoice_price
                }
                onChange={(e) =>
                  onChange({
                    ...thresholds,
                    conclusive_invoice_price: asNumberOrNull(e.target.value),
                  })
                }
                margin='dense'
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                label='Net price'
                type='number'
                value={
                  thresholds.conclusive_net_price === null ? '' : thresholds.conclusive_net_price
                }
                onChange={(e) =>
                  onChange({
                    ...thresholds,
                    conclusive_net_price: asNumberOrNull(e.target.value),
                  })
                }
                margin='dense'
              />
            </Grid>
          </Grid>
          {enableWeek2NegotiationQuantityRequirementEditing && (
            <Grid container rowGap={1}>
              <Grid item xs={12}>
                <Typography>
                  Volume threshold for proceeding to Local negotiations
                  <Tooltip
                    placement='right'
                    title='Should the estimated order volume be below the set threshold, for a price
                  that is being considered (e.g. an offer the supplier makes for an item), then the
                  supplier will be notified that such agreement on the item would preclude it being
                  included in the Local Market Pricing negotiations. In the case of the threshold being 0, and the
                  estimated order volume being 0, the supplier will be informed that the volume may
                  be reconsidered during Local Market Pricing negotiations.'
                  >
                    <HelpIcon sx={{ marginLeft: 0.5, fontSize: '100%' }} />
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  label='Volume'
                  type='number'
                  value={thresholds.week2_negotiation_quantity_requirement ?? ''}
                  onChange={(e) =>
                    onChange({
                      ...thresholds,
                      week2_negotiation_quantity_requirement: asNumberOrNull(e.target.value),
                    })
                  }
                  margin='dense'
                />
              </Grid>
            </Grid>
          )}
          <Grid container>
            <Grid item xs={12}>
              <Stack direction='row' justifyContent='end'>
                <Button color='secondary' variant='contained' onClick={onCancel}>
                  Cancel
                </Button>
                <Button color='primary' variant='contained' sx={{ ml: 1 }} onClick={onApply}>
                  Apply
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
