import { GridColDef } from '@mui/x-data-grid-pro'
import { ScenarioState } from '@common/types/scenario/ScenarioState'
import {
  formatPercentageValue,
  formatValue,
  getAggregation,
  getAllItemMetricsAcrossMarkets,
  getQoqAggregationValues,
} from './utils'
import { QoQChange, QoQChangeSource } from './types'
import { DataGrid, dataGridProps } from 'src/shared/components/table/DataGrid'

const qoqSources: QoQChangeSource[] = [
  {
    label: 'Total Spend by Invoice Price',
    field: 'spend',
    fieldAccessor: (itemMetrics) => itemMetrics.spend.invoice.values,
  },
  {
    label: 'Total Spend by Net Price (Local)',
    field: 'spend',
    fieldAccessor: (itemMetrics) => itemMetrics.spend.net['W2'].values,
  },
  {
    label: 'Average Invoice Price',
    field: 'price',
    fieldAccessor: (itemMetrics) => itemMetrics.price.invoice.values,
  },
  {
    label: 'Average Net Price (Local)',
    field: 'price',
    fieldAccessor: (itemMetrics) => itemMetrics.price.net['W2'].values,
  },
  {
    label: 'Total Volume',
    field: 'volume',
    fieldAccessor: (itemMetrics) => itemMetrics.volume.values,
  },
]

type QoQChangeRow = { id: number; label: QoQChangeSource['label'] } & QoQChange

interface Props {
  historicalData: Required<ScenarioState>['historical']
}

export const QoQChangesTable = ({ historicalData }: Props): JSX.Element => {
  const quarterlyChangeRows = getRows(historicalData)
  const quarterlyChangeCols = getColumns()

  return (
    <DataGrid
      {...dataGridProps}
      getRowId={(row: QoQChangeRow) => row.id}
      density={'compact'}
      columns={quarterlyChangeCols}
      rows={quarterlyChangeRows}
    />
  )
}

const getRows = (historicalData: Props['historicalData']): QoQChangeRow[] => {
  const allItemMetrics = getAllItemMetricsAcrossMarkets(historicalData)

  return qoqSources.map(({ label, field, fieldAccessor }, index) => ({
    id: index,
    label: label,
    ...getQoqAggregationValues(allItemMetrics, fieldAccessor, getAggregation(field)),
  }))
}

const getColumns = (): GridColDef<QoQChangeRow>[] => {
  return [
    {
      field: 'label',
      headerName: '',
      disableColumnMenu: true,
      sortable: false,
      resizable: false,
      flex: 0.4,
    },
    {
      field: 'change',
      headerName: 'Change',
      disableColumnMenu: true,
      sortable: false,
      flex: 0.3,
      valueGetter: ({ row }) => formatValue(row.change),
    },
    {
      field: 'proportionalChange',
      headerName: 'Proportional change',
      disableColumnMenu: true,
      sortable: false,
      flex: 0.3,
      valueGetter: ({ row }) => formatPercentageValue(row.proportionalChange),
    },
  ]
}
