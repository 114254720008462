import { Typography } from '@mui/material'
import { ChangelogItem } from './ChangelogItem'
import styled from '@emotion/styled'
import { ChangeLogWithWorkspaces } from './ChangelogPage'

interface ChangelogListProps {
  items: ChangeLogWithWorkspaces[]
}

const List = styled.ul`
  list-style: none;
  padding-left: 0;
`

const Wrapper = styled.div`
  padding-left: 46px;
  padding-top: 46px;
`

const Title = styled(Typography)`
  padding-bottom: 30px;
  padding-left: 5px;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
`

export const ChangelogList = ({ items = [] }: ChangelogListProps) => (
  <Wrapper>
    <Title>What's new?</Title>

    <List>
      {items.map((item) => (
        <ChangelogItem key={item.title + item.date} item={item} />
      ))}
    </List>
  </Wrapper>
)
