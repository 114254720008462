import { LinearProgress, Stack, styled, Typography } from '@mui/material'
import { useInterval } from '@shared/hooks/useInterval'
import { Color } from '@shared/types/color'
import { formatTime, getTimeDiffInSeconds } from '@shared/utils/time'
import { millisecondsInSecond } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'

export type TimerProps = {
  absoluteEndTime: Date
  absoluteStartTime: Date
  relativeEndTime?: Date | null
  colorSwitchDisabled?: boolean
  label?: string
  onFinished?: () => unknown
}

const COLOR_BREAKPOINTS: Record<Color, number> = {
  success: 50,
  warning: 10,
  error: 0,
}

export const Timer = ({
  absoluteStartTime,
  absoluteEndTime,
  relativeEndTime,
  colorSwitchDisabled,
  label,
  onFinished,
}: TimerProps) => {
  const totalTimeInSeconds = getTimeDiffInSeconds(absoluteEndTime, absoluteStartTime)

  const [isFinished, setIsFinished] = useState(false)
  const [remainingTimeInSeconds, setRemainingTimeInSeconds] = useState(
    getTimeDiffInSeconds(relativeEndTime ?? absoluteEndTime),
  )
  const getProgressPercentage = useCallback(
    () => (remainingTimeInSeconds / totalTimeInSeconds) * 100,
    [remainingTimeInSeconds, totalTimeInSeconds],
  )
  const updateRemainingTime = useCallback(
    () =>
      setRemainingTimeInSeconds(() =>
        Math.max(getTimeDiffInSeconds(relativeEndTime ?? absoluteEndTime), 0),
      ),
    [absoluteEndTime, relativeEndTime],
  )
  const getColorByProgressPercent = useCallback((): Color => {
    const progressPercentage = getProgressPercentage()

    if (colorSwitchDisabled) {
      return 'success'
    }

    for (const [color, breakpoint] of Object.entries(COLOR_BREAKPOINTS)) {
      if (progressPercentage > breakpoint) {
        return color as Color
      }
    }

    return 'error'
  }, [getProgressPercentage, colorSwitchDisabled])

  useEffect(() => {
    if (remainingTimeInSeconds === 0 && !isFinished) {
      setIsFinished(true)
      onFinished?.()
    }
  }, [isFinished, onFinished, remainingTimeInSeconds])

  useInterval(updateRemainingTime, millisecondsInSecond)

  return (
    <Stack height='100%' width='100%' justifyContent='center' spacing={1}>
      <Stack flex={0.45} justifyContent='end'>
        <StyledLinearProgress
          color={getColorByProgressPercent()}
          variant='determinate'
          value={getProgressPercentage()}
        />
      </Stack>
      <Stack flex={0.2}>
        <Typography
          sx={{
            lineHeight: '0.625rem',
          }}
          component='span'
          variant='normal'
          color='text.secondary'
          fontSize='0.6rem'
        >
          {`${formatTime(remainingTimeInSeconds)} ${label ?? ''}`.trim()}
        </Typography>
      </Stack>
    </Stack>
  )
}

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '8px',
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  width: '100%',
  backgroundColor: theme.palette.common.charts.seventh,

  '& .MuiLinearProgress-bar': {
    borderRadius: theme.shape.borderRadius,
  },
}))
