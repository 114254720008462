import { Stack, Typography, styled } from '@mui/material'
import { Week } from '@common/types/scenario/ScenarioState'

interface Props {
  selectedWeek: Week
  onWeekSelected: (week: Week) => void
}

const WEEKS = ['W1' as Week, 'W2' as Week] as const

export const BSRWeekSelector = ({ selectedWeek, onWeekSelected }: Props) => {
  const numberOfWeeks = WEEKS.length
  const getWeekString = (index: number) => (index === 0 ? 'Global Pricing' : 'Local Pricing')

  return (
    <Stack direction='row'>
      {WEEKS.map((week, index) => (
        <Stack key={week} direction='row'>
          <StyledWeekItem
            selected={week === selectedWeek}
            data-content={getWeekString(index)}
            onClick={() => onWeekSelected(week)}
          >
            {getWeekString(index)}
          </StyledWeekItem>
          <Typography
            component='pre'
            color={(theme) => theme.palette.text.secondary}
            fontSize='0.625rem'
          >
            {index === numberOfWeeks - 1 ? '' : ' | '}
          </Typography>
        </Stack>
      ))}
    </Stack>
  )
}

const StyledWeekItem = styled(Typography)<{ selected: boolean }>(({ theme, selected }) => ({
  position: 'relative',
  color: theme.palette.text.secondary,
  fontWeight: selected ? 'bold' : 'normal',
  fontSize: '0.625rem',
  textDecoration: selected ? 'underline' : 'none',
  textUnderlineOffset: '3px',
  cursor: 'pointer',
  '&:before': {
    display: 'block',
    fontWeight: 'bold',
    height: 0,
    overflow: 'hidden',
    visibility: 'hidden',
    content: 'attr(data-content)',
  },
}))
