import { NegotiationEventFormData } from '@procurement/components/NegotiationEventForm/schema'

export const getTotalLineItemsPrice = (lineItems: NegotiationEventFormData['lineItems']) =>
  lineItems.reduce((sum, lineItem) => {
    const { initialUnitPrice, quantity } = lineItem

    if (initialUnitPrice && quantity) {
      return sum + initialUnitPrice * quantity
    }

    return sum
  }, 0)
