import { logisticsBaseApi } from './baseApi'
import { SupplierListResponse } from '@logistics/types/SupplierListResponse'

import { CreateSupplierDto } from '@logistics/types/CreateSupplierDto'
import { RequiredSupplierAttributes, Supplier } from '@logistics/types/Supplier'
import {
  AllSupplierStatisticsHeaderData,
  SingleSupplierStatisticsHeaderData,
} from '@logistics/types/SupplierStatisticsHeaderData'

export const supplierApi = logisticsBaseApi.injectEndpoints({
  endpoints: (build) => ({
    getSupplierList: build.query<
      SupplierListResponse,
      { skip: number; limit: number; projectTag: string }
    >({
      query: ({ projectTag, skip, limit }) => ({
        url: `/suite/${projectTag}/suppliers`,
        method: 'GET',
        params: {
          limit,
          skip,
        },
      }),
      providesTags: ['SupplierList'],
    }),
    getSupplierStatisticsHeaderData: build.query<
      AllSupplierStatisticsHeaderData,
      { projectTag: string }
    >({
      query: ({ projectTag }) => ({
        url: `/suite/${projectTag}/suppliers/statistics/all`,
        method: 'GET',
      }),
      providesTags: ['SupplierStatisticsHeader'],
    }),
    getSingleSupplierStatisticsHeaderData: build.query<
      SingleSupplierStatisticsHeaderData,
      { projectTag: string; supplierId: string }
    >({
      query: ({ projectTag, supplierId }) => ({
        url: `/suite/${projectTag}/suppliers/${supplierId}/statistics`,
        method: 'GET',
      }),
      providesTags: ['SingleSupplierStatisticsHeader'],
    }),
    getSingleSupplier: build.query<Supplier, { projectTag: string; supplierId: string }>({
      query: ({ projectTag, supplierId }) => ({
        url: `/suite/${projectTag}/suppliers/${supplierId}`,
        method: 'GET',
      }),
      providesTags: ['Supplier'],
    }),
    updateSingleSupplier: build.mutation<Supplier, { supplier: Supplier; projectTag: string }>({
      query: ({ supplier, projectTag }) => ({
        url: `/suite/${projectTag}/suppliers`,
        method: 'PUT',
        body: supplier,
      }),
      async onQueryStarted({ projectTag }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedSupplier } = await queryFulfilled
          dispatch(
            supplierApi.util.upsertQueryData(
              'getSingleSupplier',
              { projectTag, supplierId: updatedSupplier.id },
              updatedSupplier,
            ),
          )
        } catch {
          dispatch(supplierApi.util.invalidateTags(['Supplier']))
        }
      },
      invalidatesTags: ['Supplier', 'SupplierList', 'SupplierStatisticsHeader'],
    }),
    createSingleSupplier: build.mutation<
      Supplier,
      { supplier: CreateSupplierDto; projectTag: string }
    >({
      query: ({ supplier, projectTag }) => ({
        url: `/suite/${projectTag}/suppliers`,
        method: 'POST',
        body: supplier,
      }),
      invalidatesTags: ['SupplierList', 'SupplierStatisticsHeader'],
    }),
    enableSingleSupplier: build.mutation<boolean, { supplierId: string; projectTag: string }>({
      query: ({ supplierId, projectTag }) => ({
        url: `/suite/${projectTag}/suppliers/${supplierId}/enable`,
        method: 'PUT',
      }),
      invalidatesTags: ['SupplierList', 'Supplier'],
    }),
    disableSingleSupplier: build.mutation<boolean, { supplierId: string; projectTag: string }>({
      query: ({ supplierId, projectTag }) => ({
        url: `/suite/${projectTag}/suppliers/${supplierId}/disable`,
        method: 'PUT',
      }),
      invalidatesTags: ['SupplierList', 'Supplier'],
    }),
    getRequiredSupplierAttributesForProject: build.query<
      RequiredSupplierAttributes,
      { projectTag: string }
    >({
      query: ({ projectTag }) => ({
        url: `/suite/${projectTag}/required-supplier-attributes`,
        method: 'GET',
      }),
      providesTags: ['Supplier', 'SupplierList'],
    }),
  }),
})

export const {
  useGetSupplierListQuery,
  useGetSupplierStatisticsHeaderDataQuery,
  useGetSingleSupplierStatisticsHeaderDataQuery,
  useGetSingleSupplierQuery,
  useUpdateSingleSupplierMutation,
  useCreateSingleSupplierMutation,
  useEnableSingleSupplierMutation,
  useDisableSingleSupplierMutation,
  useGetRequiredSupplierAttributesForProjectQuery,
} = supplierApi
