import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'

type UseDetectOffline = {
  isOffline: boolean
}

export const useDetectOffline = (): UseDetectOffline => {
  const { enqueueSnackbar } = useSnackbar()
  const [isOffline, setIsOffline] = useState(false)

  const handleOffline = useCallback(() => {
    setIsOffline(true)
    enqueueSnackbar(
      'Looks like you might be having connection issues. Please make sure you are connected to the internet or contact our support if you keep having issues.',
      { variant: 'error' },
    )
  }, [enqueueSnackbar])

  const handleOnline = useCallback(() => {
    setIsOffline(false)
    enqueueSnackbar('You are back online.', { variant: 'success' })
  }, [enqueueSnackbar])

  useEffect(() => {
    window.addEventListener('offline', handleOffline)
    window.addEventListener('online', handleOnline)

    return () => {
      window.removeEventListener('offline', handleOffline)
      window.removeEventListener('online', handleOnline)
    }
  }, [handleOffline, handleOnline])

  return {
    isOffline,
  }
}
