import { ChipProps } from '@mui/material'
import React from 'react'
import { NegotiationStatus } from './store/types'

export type StatusConfig = Readonly<{
  label: string | React.ReactNode
  color: ChipProps['color']
  variant?: ChipProps['variant']
}>

type NegotiationEventStatusMap = Readonly<Record<NonNullable<NegotiationStatus>, StatusConfig>>

export const NEGOTIATION_EVENT_STATUS_CONFIG: NegotiationEventStatusMap = {
  READY: { label: 'Ready', color: 'stoneBlue' },
  IN_PROGRESS: { label: 'In progress', color: 'lightGrey' },
  AGREEMENT_REACHED: { label: 'Agreement reached', color: 'success' },
  EXPIRED: { label: 'Expired', color: 'darkGrey' },
  DECLINED: { label: 'Declined', color: 'error' },
}

export const FINAL_NEGOTIATION_STATUSES: NegotiationStatus[] = [
  'EXPIRED',
  'DECLINED',
  'AGREEMENT_REACHED',
]
