import { Route } from 'react-router-dom'

import { Layout } from 'src/shared/components/Layout'
import { ProtectedRoute } from 'src/shared/components/ProtectedRoute'
import { PriceListRoutes } from './priceListRoutes'
import { DashboardPage } from '@pages/DashboardPage/DashboardPage'
import { NegotiationsListPage } from '@pages/NegotiationsPage/NegotiationListPage'
import { locales } from '@common/constants'
import { SuitePermission } from '@common/types'
import { NegotiationEventsPage } from './pages/NegotiationEvents/NegotiationEventsPage'
import { NegotiationEventDetailsPage } from './pages/NegotiationEventDetails/NegotiationEventDetailsPage'
import { NegotiationUnitsPage } from '@pages/NegotiationUnitsPage/NegotiationUnitsPage'

export const PriceListEntryPoint = () => {
  return (
    <>
      <Route element={<Layout locale={locales.us} />}>
        <Route
          path={PriceListRoutes.DASHBOARD}
          element={
            <ProtectedRoute requiredPerm={SuitePermission.READ}>
              <DashboardPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={PriceListRoutes.NEGOTIATION_LIST}
          element={
            <ProtectedRoute requiredPerm={SuitePermission.MODIFY}>
              <NegotiationsListPage unitsRoute={PriceListRoutes.NEGOTIATION_UNITS} />
            </ProtectedRoute>
          }
        />
        <Route
          path={PriceListRoutes.NEGOTIATION_UNITS}
          element={
            <ProtectedRoute requiredPerm={SuitePermission.MODIFY}>
              <NegotiationUnitsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={PriceListRoutes.NEGOTIATION_EVENTS}
          element={
            <ProtectedRoute requiredPerm={SuitePermission.MODIFY}>
              <NegotiationEventsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={PriceListRoutes.NEGOTIATION_EVENT_DETAILS}
          element={
            <ProtectedRoute requiredPerm={SuitePermission.MODIFY}>
              <NegotiationEventDetailsPage />
            </ProtectedRoute>
          }
        />
      </Route>
    </>
  )
}
