import { ChipConfig } from '@logistics/types'
import { SpotLoadNegotiationStatus } from '@logistics/pages/spotLoad/types/negotiation'
import { SpotLoadUnitStatus } from '@logistics/pages/spotLoad/types'

export const getChipForNegotiationStatus = (status: SpotLoadNegotiationStatus): ChipConfig => {
  switch (status) {
    case SpotLoadNegotiationStatus.NOT_STARTED:
      return { label: 'Not started', color: 'stoneBlue' }
    case SpotLoadNegotiationStatus.LINK_EMAIL_0:
    case SpotLoadNegotiationStatus.LINK_EMAIL_1:
      return { label: 'Waiting', color: 'stoneBlue' }
    case SpotLoadNegotiationStatus.ONGOING:
    case SpotLoadNegotiationStatus.LINK_OPENED:
      return { label: 'In progress', color: 'stoneBlue' }
    case SpotLoadNegotiationStatus.AGREEMENT_REACHED:
      return { label: 'Agreement reached', color: 'success' }
    case SpotLoadNegotiationStatus.AGREEMENT_DECLINED:
      return { label: 'No agreement', color: 'error' }
    case SpotLoadNegotiationStatus.EXPIRED:
      return { label: 'Expired', color: 'error' }
    case SpotLoadNegotiationStatus.CANCELLED:
      return { label: 'Cancelled', color: 'error' }
    case SpotLoadNegotiationStatus.CLOSED_MANUAL:
      return { label: 'Closed', color: 'stoneBlue' }
  }
}

export const getChipForUnitStatus = (status: SpotLoadUnitStatus): ChipConfig => {
  switch (status) {
    case SpotLoadUnitStatus.READY:
      return { label: 'Ready', color: 'stoneBlue' }
    case SpotLoadUnitStatus.IN_PROGRESS:
      return { label: 'In progress', color: 'stoneBlue' }
    case SpotLoadUnitStatus.RATE_CAPTURED:
      return { label: 'Rate captured', color: 'stoneBlue' }
    case SpotLoadUnitStatus.AGREEMENT_REACHED:
      return { label: 'Completed', color: 'success' }
    case SpotLoadUnitStatus.AGREEMENT_DECLINED:
      return { label: 'No agreement', color: 'error' }
    case SpotLoadUnitStatus.EXPIRED:
      return { label: 'Expired', color: 'error' }
    case SpotLoadUnitStatus.CANCELLED:
      return { label: 'Cancelled', color: 'error' }
  }
}
