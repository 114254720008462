import { CurrencyCode } from '@pactum/common'
import { MultiSelectFilter, MultiSelectItemValue } from './MultiSelectFilter'
import { Grid, Tooltip } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'

interface CurrencyFilterProps {
  currencies: CurrencyCode[]
  selectedCurrency: CurrencyCode
  onChange: (currency: CurrencyCode) => void
}

export const CurrencyFilter = ({ currencies, selectedCurrency, onChange }: CurrencyFilterProps) => {
  const handleChange = (vals: MultiSelectItemValue[]) => {
    onChange(vals[0] as CurrencyCode)
  }

  const pickerLength = currencies.length > 1 ? 11 : 12

  return (
    <>
      <Grid container spacing={0} alignItems='center'>
        <Grid item xs={pickerLength}>
          <MultiSelectFilter
            label='Currency'
            multiple={false}
            selectedAllLabel='All currencies'
            data={currencies.map((currency) => ({
              name: currency,
              value: currency,
            }))}
            setSelected={handleChange}
            selected={[selectedCurrency]}
          />
        </Grid>

        {currencies.length > 1 ? (
          <Grid item xs={1}>
            <Tooltip
              title={
                'Changing the display currency may alter the relative savings rate due to variations in exchange rates over time. Items recorded at different times were subject to different rates, which affects the aggregated total spend and savings.'
              }
              placement='top'
              arrow
            >
              <ErrorIcon sx={{ color: 'text.secondary', fontSize: '1rem', ml: 1 }} />
            </Tooltip>
          </Grid>
        ) : null}
      </Grid>
    </>
  )
}
