import { Route } from 'react-router-dom'
import { Layout } from 'src/shared/components/Layout'
import { ProtectedRoute } from 'src/shared/components/ProtectedRoute'
import { ProcurementRoutes } from './routes'
import { locales } from '@common/constants'
import { SuitePermission } from '@common/types'
import { DashboardPage } from '@pages/DashboardPage/DashboardPage'
import { NegotiationEventList } from './pages/NegotiationEventList/NegotiationEventList'
import { NegotiationEventDetails } from './pages/NegotiationEventDetails/NegotiationEventDetails'

export const ProcurementEntryPoint = () => {
  return (
    <>
      <Route element={<Layout locale={locales.us} />}>
        <Route
          path={ProcurementRoutes.NEGOTIATION_EVENTS}
          element={
            <ProtectedRoute requiredPerm={SuitePermission.MODIFY}>
              <NegotiationEventList />
            </ProtectedRoute>
          }
        />
        <Route
          path={ProcurementRoutes.NEGOTIATION_EVENT_DETAILS}
          element={
            <ProtectedRoute requiredPerm={SuitePermission.MODIFY}>
              <NegotiationEventDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path={ProcurementRoutes.DASHBOARD}
          element={
            <ProtectedRoute requiredPerm={SuitePermission.READ}>
              <DashboardPage />
            </ProtectedRoute>
          }
        />
      </Route>
    </>
  )
}
