import { Route } from 'react-router-dom'

import { Layout } from 'src/shared/components/Layout'
import { ProtectedRoute } from 'src/shared/components/ProtectedRoute'
import {
  ChangeFormsPage,
  CostIncreaseFormConfiguration,
  CostIncreaseFormsPage,
  NegotiationConfiguration,
  NegotiationPreparationPage,
  OverviewPage,
  ScenarioDetails,
  ScenarioModelPage,
  ScenarioPreparation,
  ScenariosList,
} from 'src/main/pages/index'
import { locales } from '@common/constants'
import { NegotiationPreparationModelPage } from 'src/merchandising/pages/ChangeRequests/NegotiationPreparationModelPage/NegotiationPreparationModelPage'
import { createWmTheme } from 'src/main/constants/theme'
import { MerchandisingRoutes } from './routes'
import { RedirectToProjectPath } from './RedirectToProjectPath'
import { CoreBackendPermission } from '@pactum/core-backend-types'
import { SuitePermission } from '@common/types'

export const MerchandisingEntrypoint = () => {
  return (
    <>
      <Route element={<Layout />}>
        <Route
          path={MerchandisingRoutes.CHANGE_REQUESTS_LIST}
          element={
            <ProtectedRoute
              requiredPerm={SuitePermission.MERCHANDISING_NEGOTIATION_PREP_MANAGEMENT}
            >
              <ChangeFormsPage />
            </ProtectedRoute>
          }
        />
        <Route
          element={
            <ProtectedRoute
              requiredPerm={SuitePermission.MERCHANDISING_NEGOTIATION_PREP_MANAGEMENT}
            >
              <NegotiationPreparationPage />
            </ProtectedRoute>
          }
        >
          <Route
            path={MerchandisingRoutes.NEGOTIATION_PREPARATION_ADJUSTMENTS}
            element={<NegotiationConfiguration />}
          />
        </Route>
        <Route
          path={MerchandisingRoutes.SCENARIOS_LIST}
          element={
            <ProtectedRoute requiredPerm={SuitePermission.READ}>
              <ScenariosList />
            </ProtectedRoute>
          }
        />
        <Route
          element={
            <ProtectedRoute requiredPerm={SuitePermission.MERCHANDISING_SCENARIO_MANAGEMENT}>
              <ScenarioPreparation />
            </ProtectedRoute>
          }
        >
          <Route path={MerchandisingRoutes.SCENARIO_DETAILS} element={<ScenarioDetails />} />
          <Route path={MerchandisingRoutes.SCENARIO_OVERVIEW} element={<OverviewPage />} />
        </Route>
        <Route
          path={MerchandisingRoutes.SCENARIO_MODEL}
          element={
            <ProtectedRoute requiredPerm={SuitePermission.MERCHANDISING_SCENARIO_MANAGEMENT}>
              <ScenarioModelPage />
            </ProtectedRoute>
          }
        />
        {[
          MerchandisingRoutes.DEPRECATED_SCENARIOS_LIST,
          MerchandisingRoutes.DEPRECATED_SCENARIO_DETAILS,
          MerchandisingRoutes.DEPRECATED_SCENARIO_OVERVIEW,
        ].map((route) => (
          // TODO remove after deprecated (non-projectTag) routes are not needed anymore
          <Route key={route} path={route} element={<RedirectToProjectPath />} />
        ))}
        <Route
          path={MerchandisingRoutes.NEGOTIATION_PREPARATION_MODEL}
          element={
            <ProtectedRoute
              requiredPerm={SuitePermission.MERCHANDISING_NEGOTIATION_PREP_MANAGEMENT}
            >
              <NegotiationPreparationModelPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={MerchandisingRoutes.COST_AVOIDANCE}
          element={
            <ProtectedRoute requiredPerm={CoreBackendPermission.AUTH_ADMIN}>
              <CostIncreaseFormsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={MerchandisingRoutes.COST_AVOIDANCE_OVERVIEW}
          element={
            <ProtectedRoute requiredPerm={CoreBackendPermission.AUTH_ADMIN}>
              <CostIncreaseFormConfiguration />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route element={<Layout locale={locales.us} createInnerTheme={createWmTheme} />}>
        <Route
          path={MerchandisingRoutes.COST_INCREASE}
          element={
            <ProtectedRoute requiredPerm={CoreBackendPermission.AUTH_ADMIN}>
              <CostIncreaseFormsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={MerchandisingRoutes.COST_INCREASE_OVERVIEW}
          element={
            <ProtectedRoute requiredPerm={CoreBackendPermission.AUTH_ADMIN}>
              <CostIncreaseFormConfiguration />
            </ProtectedRoute>
          }
        />
      </Route>
    </>
  )
}
