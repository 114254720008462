import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'

import { NegotiationPreparationInputs, PurchasePriceChangeForm } from '@common/types'
import {
  isBackendApiErrorResponse,
  useUpdateNegotiationPreparationMutation,
} from 'src/merchandising/store/'
import { PactumButton } from 'src/shared/components/PactumButton'
import { SingleFileUpload } from 'src/shared/components/SingleFileUpload'

interface Props {
  isOpen: boolean
  negotiationPreparationId: string
  ppFormId: PurchasePriceChangeForm['id']
  onFileReUploaded: () => void
  onClose: () => void
}

export const FileReUploadDialog = ({
  isOpen,
  ppFormId,
  negotiationPreparationId,
  onFileReUploaded,
  onClose,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar()

  const [updateUserInputs, setUpdateUserInputs] = useState(false)
  const [uploadedFilePayload, setUploadedFilePayload] =
    useState<NegotiationPreparationInputs | null>(null)

  useEffect(() => {
    setUpdateUserInputs(false)
  }, [isOpen])

  const [reUploadNegotiationPreparation, { isLoading: isReUploadLoading }] =
    useUpdateNegotiationPreparationMutation()

  const fileReUploadCallback = async (acceptedFile: Blob) => {
    const text = await acceptedFile.text()
    try {
      const payload = JSON.parse(text) as NegotiationPreparationInputs
      setUploadedFilePayload(payload)
    } catch (e) {
      enqueueSnackbar('Invalid JSON')
      return
    }
  }

  const findPreparationInputByPpFormId = useCallback(
    (payload: NegotiationPreparationInputs) =>
      Object.values(payload ?? {}).find(
        (uploadedNegoPrepInput) => uploadedNegoPrepInput.purchasePriceChangeForm.id === ppFormId,
      ),
    [ppFormId],
  )

  const reUploadNegotiationPreparationForCurrentPrepId = useCallback(async () => {
    if (!uploadedFilePayload) {
      return
    }
    const negotiationPreparationInput = findPreparationInputByPpFormId(uploadedFilePayload)
    if (!negotiationPreparationInput) {
      enqueueSnackbar(`Uploaded JSON did not contain PP form ID of ${ppFormId}`, {
        variant: 'error',
      })
      return
    }

    try {
      await reUploadNegotiationPreparation({
        negotiationPreparationId,
        payload: {
          ...negotiationPreparationInput,
          updateUserInputs: updateUserInputs,
        },
      }).unwrap()
      enqueueSnackbar('Re-uploaded change form', { variant: 'success' })
      onFileReUploaded()
    } catch (e) {
      enqueueSnackbar(
        isBackendApiErrorResponse(e) ? e.data.message : 'Change form re-upload failed',
        { variant: 'error' },
      )
    }
  }, [
    enqueueSnackbar,
    findPreparationInputByPpFormId,
    negotiationPreparationId,
    onFileReUploaded,
    ppFormId,
    reUploadNegotiationPreparation,
    updateUserInputs,
    uploadedFilePayload,
  ])

  useEffect(() => {
    reUploadNegotiationPreparationForCurrentPrepId().then(() => setUploadedFilePayload(null))
  }, [reUploadNegotiationPreparationForCurrentPrepId, uploadedFilePayload])

  return (
    <>
      {isOpen && (
        <Dialog open={isOpen} onClose={onClose}>
          <DialogTitle>Re-upload change form</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Typography variant='body2'>
                Re-uploading a new change form overrides current negotiation preparation's articles
                and analytics
              </Typography>
              <Stack direction='row'>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={updateUserInputs}
                      onChange={() => setUpdateUserInputs(!updateUserInputs)}
                    />
                  }
                  label={<Typography variant='body2'>Update user inputs</Typography>}
                />
              </Stack>
              <Box>
                <SingleFileUpload
                  callback={fileReUploadCallback}
                  isLoading={isReUploadLoading}
                  fileTypes={['application/json']}
                >
                  Select file or drag and drop the PP change form JSON
                </SingleFileUpload>
              </Box>
              <Stack direction='row' justifyContent='flex-end'>
                <PactumButton color='secondary' onClick={() => onClose()}>
                  Cancel
                </PactumButton>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
