import { Box, Stack, styled, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { HeaderPrimaryButton, useLayoutContext } from '@components/Layout'
import { SupplierList } from './SupplierList'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { noValueText } from '@constants'
import {
  useGetRequiredSupplierAttributesForProjectQuery,
  useGetSupplierStatisticsHeaderDataQuery,
} from '@logistics/store/supplierApi'
import { SupplierPageContext } from '@logistics/pages/common/SupplierPage/context'
import { SupplierModal } from './SupplierModal'
import { useFormatter } from '@shared/hooks'
import { PactumLoader } from '@components/PactumLoader'
import { FormDialog } from '@components/FormDialog'
import { UseFormReturn } from 'react-hook-form'
import { CreateSupplierForm } from '@logistics/pages/common/SupplierPage/SupplierModal/form'

export const SupplierPage = () => {
  const [isFailureModalOpen, setIsFailureModalOpen] = useState(false)

  const { activeProjectTag } = useActiveProject()
  const formatter = useFormatter()

  const { setStatisticsHeaderData, setActionsComponent } = useLayoutContext()
  const { isCreateSupplierModalOpen, setIsCreateSupplierModalOpen } =
    useContext(SupplierPageContext)

  const { data: headerStatisticsResponse } = useGetSupplierStatisticsHeaderDataQuery({
    projectTag: activeProjectTag,
  })

  const {
    data: requiredSupplierAttributes,
    isLoading: loadingRequiredSupplierAttributes,
    isSuccess: loadedRequiredSupplierAttributes,
  } = useGetRequiredSupplierAttributesForProjectQuery({
    projectTag: activeProjectTag,
  })

  const successfullyLoadedRequiredSupplierAttributes =
    !loadingRequiredSupplierAttributes && loadedRequiredSupplierAttributes

  useEffect(() => {
    setStatisticsHeaderData([
      {
        title: 'Total Carriers',
        primaryValue: headerStatisticsResponse?.totalSuppliers ?? noValueText,
        secondaryValue: `${
          headerStatisticsResponse?.newSuppliersThisMonth ?? noValueText
        } from last month`,
      },
      {
        title: 'Total Contract Value',
        primaryValue: headerStatisticsResponse?.totalContractValue
          ? formatter.currency(headerStatisticsResponse?.totalContractValue, { fractionDigits: 0 })
          : noValueText,
        secondaryValue: 'Year to date',
      },
      {
        title: 'New Suppliers',
        primaryValue: headerStatisticsResponse?.newSuppliersThisMonth ?? noValueText,
        secondaryValue: 'This month',
      },
      {
        title: 'Negotiations Concluded',
        primaryValue: headerStatisticsResponse?.negotiationsThisMonth
          ? formatter.percent0To1(
              headerStatisticsResponse.negotiationsConcludedThisMonth /
                headerStatisticsResponse.negotiationsThisMonth,
              { fractionDigits: 0, showChangeSign: false },
            )
          : 0,
        secondaryValue: 'This month',
      },
    ])

    setActionsComponent(
      <HeaderPrimaryButton
        onClick={() => {
          setIsCreateSupplierModalOpen(successfullyLoadedRequiredSupplierAttributes)
          setIsFailureModalOpen(!successfullyLoadedRequiredSupplierAttributes)
        }}
      >
        New carrier
      </HeaderPrimaryButton>,
    )

    return () => {
      setActionsComponent(null)
      setStatisticsHeaderData([])
    }
  }, [
    setStatisticsHeaderData,
    headerStatisticsResponse,
    setActionsComponent,
    setIsCreateSupplierModalOpen,
    formatter,
    successfullyLoadedRequiredSupplierAttributes,
  ])

  const resetAndClose = (form: UseFormReturn<CreateSupplierForm>) => {
    form.reset()
    setIsFailureModalOpen(false)
  }

  return (
    <>
      {loadingRequiredSupplierAttributes ? <PactumLoader /> : null}

      <Box m={4}>
        <Stack spacing={2}>
          <Typography variant='h2' component='h2'>
            Carriers
          </Typography>
          <Box pl={1}>
            <SupplierList />
          </Box>
        </Stack>
      </Box>

      {!successfullyLoadedRequiredSupplierAttributes ? (
        <StyledFormDialog
          fullWidth
          title='Unavailable'
          open={isFailureModalOpen}
          onCancel={resetAndClose}
          buttons={[{ type: 'cancel', label: 'Close' }]}
        >
          <Typography variant='caption' component='h2'>
            Project configuration could not be loaded for the carrier. Please try refreshing the
            page or contact us.
          </Typography>
        </StyledFormDialog>
      ) : null}

      {!loadingRequiredSupplierAttributes && loadedRequiredSupplierAttributes ? (
        <SupplierModal
          isOpen={isCreateSupplierModalOpen}
          onClose={() => {
            setIsCreateSupplierModalOpen(false)
          }}
          requiredAttributes={requiredSupplierAttributes}
        />
      ) : null}
    </>
  )
}

const StyledFormDialog = styled(FormDialog)(() => ({
  '& .MuiPaper-root': {
    maxWidth: '800px',
  },
})) as typeof FormDialog
