import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { BackendApiErrorResponse, BackendValidationErrorResponse } from './types'
import { SerializedError } from '@reduxjs/toolkit'

export const isBackendApiErrorResponse = (error: unknown): error is BackendApiErrorResponse => {
  const queryError = error as FetchBaseQueryError
  return (
    queryError &&
    !!queryError.data &&
    typeof queryError.data === 'object' &&
    'message' in queryError.data &&
    'statusCode' in queryError.data &&
    'timestamp' in queryError.data &&
    'path' in queryError.data
  )
}

export const isBackendValidationErrorResponse = <
  T extends BackendApiErrorResponse = BackendValidationErrorResponse,
>(
  error: unknown,
): error is T => {
  return isBackendApiErrorResponse(error) && 'details' in error.data
}

export const isQuerySuccessResponse = <T>(
  response: { data: T } | { error: FetchBaseQueryError | SerializedError },
): response is { data: T } => {
  return 'data' in response && !('error' in response)
}

export const isQueryErrorResponse = <T>(
  response: { data: T } | { error: FetchBaseQueryError | SerializedError },
): response is { error: FetchBaseQueryError | SerializedError } => {
  return !isQuerySuccessResponse(response)
}
