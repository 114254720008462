import { ProjectTag } from '@common/types'
import { CommercialTermsNegotiation } from '@campaigns/types/negotiation'
import { fileToFormData, responseAsObjectUrl } from 'src/main/utils/file'
import { suiteBaseApi } from '@shared/api/suite-base-api'
import { createFileDownloadHook } from '@shared/hooks/createFileDownloadHook'
import { UpdateNegotiation } from '@campaigns/dto/update-negotiation.dto'
import { ImportResult } from '@campaigns/types/ImportResult'
import { ProjectConfig } from '@campaigns/dto/project-config.dto'

export const commercialTermsApi = suiteBaseApi
  .enhanceEndpoints({
    addTagTypes: ['CommercialTermsNegotiations', 'CommercialTermsNegotiationDetails'],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      downloadNegotiationTemplateCsv: build.query<string, ProjectTag>({
        query: (projectTag) => ({
          url: `/suite/commercial-terms/${projectTag}/template/csv`,
          responseHandler: responseAsObjectUrl,
        }),
      }),
      downloadNegotiationTemplateExcel: build.query<string, ProjectTag>({
        query: (projectTag) => ({
          url: `/suite/commercial-terms/${projectTag}/template/excel`,
          responseHandler: responseAsObjectUrl,
        }),
      }),
      getProjectConfig: build.query<ProjectConfig, ProjectTag>({
        query: (projectTag) => ({
          url: `/suite/commercial-terms/${projectTag}/project-config`,
          method: 'GET',
        }),
      }),
      uploadNegotiationTemplate: build.mutation<ImportResult, { file: Blob; projectTag: string }>({
        query: ({ file, projectTag }) => ({
          url: `/suite/commercial-terms/${projectTag}/upload`,
          method: 'POST',
          body: fileToFormData(file),
        }),
        invalidatesTags: ['CommercialTermsNegotiations'],
      }),
      getNegotiations: build.query<CommercialTermsNegotiation[], ProjectTag>({
        query: (projectTag) => ({
          url: `/suite/commercial-terms/${projectTag}/negotiations`,
          method: 'GET',
        }),
        providesTags: ['CommercialTermsNegotiations'],
      }),
      getSingleNegotiation: build.query<
        CommercialTermsNegotiation,
        { projectTag: ProjectTag; negotiationId: CommercialTermsNegotiation['id'] }
      >({
        query: ({ projectTag, negotiationId }) => ({
          url: `/suite/commercial-terms/${projectTag}/negotiations/${negotiationId}`,
          method: 'GET',
        }),
        providesTags: ['CommercialTermsNegotiationDetails'],
      }),
      startNegotiations: build.mutation<void, { projectTag: ProjectTag; negotiationIds: string[] }>(
        {
          query: ({ projectTag, negotiationIds }) => ({
            url: `/suite/commercial-terms/${projectTag}/negotiation/start`,
            method: 'POST',
            body: {
              negotiationIds,
            },
          }),
          invalidatesTags: ['CommercialTermsNegotiations', 'CommercialTermsNegotiationDetails'],
        },
      ),
      markNegotiationsSigned: build.mutation<
        void,
        { projectTag: ProjectTag; negotiationIds: string[] }
      >({
        query: ({ projectTag, negotiationIds }) => ({
          url: `/suite/commercial-terms/${projectTag}/negotiation/mark-signed`,
          method: 'POST',
          body: {
            negotiationIds,
          },
        }),
        invalidatesTags: ['CommercialTermsNegotiations', 'CommercialTermsNegotiationDetails'],
      }),
      markNegotiationsNotSigned: build.mutation<
        void,
        { projectTag: ProjectTag; negotiationIds: string[] }
      >({
        query: ({ projectTag, negotiationIds }) => ({
          url: `/suite/commercial-terms/${projectTag}/negotiation/mark-not-signed`,
          method: 'POST',
          body: {
            negotiationIds,
          },
        }),
        invalidatesTags: ['CommercialTermsNegotiations', 'CommercialTermsNegotiationDetails'],
      }),
      deleteNegotiations: build.mutation<
        void,
        { projectTag: ProjectTag; negotiationIds: string[] }
      >({
        query: ({ projectTag, negotiationIds }) => ({
          url: `/suite/commercial-terms/${projectTag}/negotiation/delete`,
          method: 'POST',
          body: {
            negotiationIds,
          },
        }),
        invalidatesTags: ['CommercialTermsNegotiations', 'CommercialTermsNegotiationDetails'],
      }),
      downloadNegotiationsResultsFile: build.query<
        string,
        { projectTag: ProjectTag; negotiationIds: string[] }
      >({
        query: ({ projectTag, negotiationIds }) => ({
          url: `/suite/commercial-terms/${projectTag}/negotiation/excel?ids=${negotiationIds.join(
            ',',
          )}
          `,
          responseHandler: responseAsObjectUrl,
        }),
      }),
      downloadAllNegotiationsResultsFile: build.query<string, { projectTag: ProjectTag }>({
        query: ({ projectTag }) => ({
          url: `/suite/commercial-terms/${projectTag}/negotiation/excel/all`,
          responseHandler: responseAsObjectUrl,
        }),
      }),
      getNegotiationTestLink: build.query<
        { link: string },
        { projectTag: string; negotiationId: CommercialTermsNegotiation['id'] }
      >({
        query: ({ projectTag, negotiationId }) => ({
          url: `/suite/commercial-terms/${projectTag}/negotiation/${negotiationId}/test-link`,
        }),
      }),
      updateNegotiation: build.mutation<
        CommercialTermsNegotiation,
        {
          projectTag: ProjectTag
          negotiationId: CommercialTermsNegotiation['id']
          updateNegotiationDto: UpdateNegotiation
        }
      >({
        query: ({ projectTag, negotiationId, updateNegotiationDto }) => ({
          url: `/suite/commercial-terms/${projectTag}/negotiations/${negotiationId}`,
          method: 'PUT',
          body: updateNegotiationDto,
        }),
        invalidatesTags: ['CommercialTermsNegotiations', 'CommercialTermsNegotiationDetails'],
      }),
    }),
  })

export const {
  useUploadNegotiationTemplateMutation,
  useGetNegotiationsQuery,
  useGetSingleNegotiationQuery,
  useStartNegotiationsMutation,
  useDeleteNegotiationsMutation,
  useMarkNegotiationsSignedMutation,
  useMarkNegotiationsNotSignedMutation,
  useLazyDownloadNegotiationsResultsFileQuery,
  useLazyDownloadAllNegotiationsResultsFileQuery,
  useLazyGetNegotiationTestLinkQuery,
  useUpdateNegotiationMutation,
  useGetProjectConfigQuery,
} = commercialTermsApi

/**
 * Returns template downloading function and error.
 * See {@link createFileDownloadHook} for more details.
 */
export const useDownloadTemplateCsv = createFileDownloadHook(
  'template.csv',
  commercialTermsApi.endpoints.downloadNegotiationTemplateCsv.useLazyQuery,
)

export const useDownloadTemplateXlsx = createFileDownloadHook(
  'template.xlsx',
  commercialTermsApi.endpoints.downloadNegotiationTemplateExcel.useLazyQuery,
)
