import { Locale, locales } from '@common/constants'
import { GridValueFormatterParams } from '@mui/x-data-grid-pro'
// eslint-disable-next-line import/no-duplicates
import { Locale as DateFNSLocale, format, formatRelative, isToday, isYesterday } from 'date-fns'
// eslint-disable-next-line import/no-duplicates
import gbLocale from 'date-fns/locale/en-GB'
// eslint-disable-next-line import/no-duplicates
import usLocale from 'date-fns/locale/en-US'
import { noValueText } from 'src/main/constants'

type AvailableDateFormats = keyof typeof dateFormats
type FormatOptions = {
  fractionDigits?: number
  maxFractionDigits?: number
  showChangeSign?: boolean
}
type DateFormatOptions = {
  slashSeparators?: boolean
}
export type CurrencyOptions = FormatOptions & {
  currency?: string
}

export const dateFormats = {
  shortDate: 'P',
  longDate: 'PP',
  shortTime: 'p',
  longTime: 'pp',
  textDateTime: 'PPP, hh:mm a',
  monthAndYear: 'MM/yyyy',
  yearMonthDay: 'yyyy/MM/dd',
} as const

export const getCurrencySymbol = (currency?: string, locale: Locale = 'en-US') =>
  currency
    ? new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
      })
        .formatToParts()
        .find((part) => part.type === 'currency')?.value
    : ''

export class Formatter {
  private readonly locale: Locale

  constructor(locale?: Locale) {
    this.locale = locale ?? locales.us
  }

  private getNumberFormatter = (customOptions: Intl.NumberFormatOptions) => {
    const options: Intl.NumberFormatOptions = {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      ...customOptions,
    }

    return Intl.NumberFormat([this.locale], options)
  }

  currency = (rawValue: number, options?: CurrencyOptions) => {
    const defaultCurrency = 'EUR'
    const localeToCurrencyMap: Record<Locale, string> = {
      [locales.us]: 'USD',
      [locales.ee]: defaultCurrency,
    }

    const value = options?.fractionDigits === 0 ? Math.floor(rawValue) : rawValue

    return this.getNumberFormatter({
      style: 'currency',
      currency: options?.currency ?? localeToCurrencyMap[this.locale],
      minimumFractionDigits: options?.fractionDigits ?? 2,
      maximumFractionDigits: options?.maxFractionDigits ?? options?.fractionDigits ?? 2,
      signDisplay: options?.showChangeSign ? 'exceptZero' : undefined,
    }).format(value)
  }

  percent0To1 = (value: number, options?: FormatOptions) => {
    return this.getNumberFormatter({
      style: 'percent',
      minimumFractionDigits: options?.fractionDigits ?? 2,
      maximumFractionDigits: options?.fractionDigits ?? 2,
      signDisplay: options?.showChangeSign ? 'exceptZero' : undefined,
    }).format(value)
  }

  date = (
    value: Date,
    dateFormat: (typeof dateFormats)[AvailableDateFormats],
    options?: DateFormatOptions,
  ) => {
    // Use en-GB locale as default to preserve English language
    const defaultLocale = gbLocale
    const localesMap: Record<Locale, DateFNSLocale> = {
      [locales.us]: usLocale,
      [locales.ee]: defaultLocale,
    }

    const formattedValue = format(value, dateFormat, {
      locale: localesMap[this.locale],
    })

    return options?.slashSeparators ? formattedValue : formattedValue.replaceAll('/', '-')
  }

  dateTimeRelative = (value: Date, dateFormat: (typeof dateFormats)[AvailableDateFormats]) => {
    const defaultLocale = gbLocale
    const localesMap: Record<Locale, DateFNSLocale> = {
      [locales.us]: usLocale,
      [locales.ee]: defaultLocale,
    }

    if (isToday(value) || isYesterday(value)) {
      const dateString = formatRelative(value, new Date(), {
        locale: localesMap[this.locale],
      })
      return `${dateString.charAt(0).toUpperCase()}${dateString.slice(1)}`
    }

    return format(value, dateFormat, {
      locale: localesMap[this.locale],
    })
  }

  number = (value: number, options?: FormatOptions) => {
    return this.getNumberFormatter({
      style: 'decimal',
      minimumFractionDigits: options?.fractionDigits ?? 0,
      maximumFractionDigits: options?.fractionDigits ?? 0,
    }).format(value)
  }
}

const getNumberFormatter = (
  fractionDigits?: number,
  style: 'decimal' | 'unit' | 'currency' | 'percent' = 'decimal',
  locale: string = locales.ee,
) =>
  Intl.NumberFormat(
    [locale],
    fractionDigits !== undefined
      ? {
          style,
          minimumFractionDigits: fractionDigits,
          maximumFractionDigits: fractionDigits,
        }
      : undefined,
  )

const twoFractionsNumberFormatter = getNumberFormatter(2).format

const percentFormatter = getNumberFormatter(2, 'percent').format

const zeroFractionsPercentFormatter = getNumberFormatter(0, 'percent').format

const integerFormatter = getNumberFormatter(0).format

const eurCellFormatter = (params: GridValueFormatterParams<number>) => eurFormatter(params.value)

const eurFormatter = (value: number): string => `${twoFractionsNumberFormatter(value)} €`

const nonDecimalEurFormatter = (value: number): string => `${integerFormatter(Math.trunc(value))} €`

const usdCellFormatter = (params: GridValueFormatterParams<number>) => usdFormatter(params.value)

const usdFormatter = (value: number): string =>
  `$${getNumberFormatter(2, 'decimal', 'en-US').format(value)}`

const nonDecimalUsdFormatter = (value: number): string => `$${integerFormatter(Math.trunc(value))}`

const percentCellFormatter = (params: GridValueFormatterParams<number>) =>
  !isNaN(params.value) ? percentFormatter(params.value) : noValueText

const percentCellFormatterWithSign = (params: GridValueFormatterParams) => {
  const formattedValue = percentCellFormatter(params)

  return params.value > 0 ? `+${formattedValue}` : formattedValue
}

export {
  eurCellFormatter,
  eurFormatter,
  getNumberFormatter,
  integerFormatter,
  nonDecimalEurFormatter,
  nonDecimalUsdFormatter,
  percentCellFormatter,
  percentCellFormatterWithSign,
  percentFormatter,
  twoFractionsNumberFormatter,
  usdCellFormatter,
  usdFormatter,
  zeroFractionsPercentFormatter,
}
