import { Box, Stack, Typography } from '@mui/material'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { DataGrid, dataGridProps } from '@components/table/DataGrid'
import { TooltipCell } from '@components/TooltipCell'
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowModel,
  MuiEvent,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { useFormatter } from '@shared/hooks'
import { EditOutlined } from '@mui/icons-material'
import { NegotiationEventSupplierFormData } from '../../schema'
import { getTotalLineItemsPrice } from '../../utils/lineItems'

export const LineItemsTable = () => {
  const itemsApiRef = useGridApiRef()
  const formatter = useFormatter()
  const { control, watch } = useFormContext<NegotiationEventSupplierFormData>()
  const { replace } = useFieldArray({ control, name: 'lineItems' })
  const lineItems = watch('lineItems')
  const currency = watch('currency')

  const totalLineItemsPrice = getTotalLineItemsPrice(lineItems)

  const handleRowUpdate = (
    row: GridRowModel<NegotiationEventSupplierFormData['lineItems'][number]>,
  ) => {
    const updatedLineItems = lineItems.map((lineItem) =>
      lineItem.numberInCollection === row.numberInCollection ? row : lineItem,
    )

    replace(updatedLineItems)

    return row
  }

  const handleEditClick = async (
    id: number,
    event: MuiEvent<React.MouseEvent<HTMLElement, MouseEvent>>,
  ) => {
    event.stopPropagation()
    itemsApiRef.current?.startCellEditMode({ id: id, field: 'quotedUnitPrice' })
  }

  const columns: GridColDef<NegotiationEventSupplierFormData['lineItems'][number]>[] = [
    {
      ...commonColumnProps,
      editable: false,
      field: 'numberInCollection',
      headerName: 'Order',
      type: 'number',
    },
    {
      ...commonColumnProps,
      field: 'name',
      editable: false,
      headerName: 'Name',
      flex: 2,
      renderCell: ({
        value,
      }: GridRenderCellParams<NegotiationEventSupplierFormData['lineItems'][number], string>) => (
        <TooltipCell tooltip={value}>{value}</TooltipCell>
      ),
    },
    {
      ...commonColumnProps,
      field: 'quantity',
      editable: false,
      headerName: 'Quantity',
      type: 'number',
      flex: 2,
    },
    {
      ...commonColumnProps,
      field: 'unit',
      editable: false,
      headerName: 'Unit of measure',
      flex: 2,
    },
    {
      ...commonColumnProps,
      field: 'quotedUnitPrice',
      editable: true,
      flex: 2,
      type: 'number',
      headerName: 'Price per unit',
      renderCell: ({ value }) => formatter.currency(value, { currency, maxFractionDigits: 6 }),
    },
    {
      ...commonColumnProps,
      field: '__actions__',
      type: 'actions',
      headerName: '',
      flex: 1,
      align: 'right',
      getActions: ({ row }) => [
        <GridActionsCellItem
          label='Edit'
          icon={<EditOutlined />}
          onClick={(event) => handleEditClick(Number(row.numberInCollection), event)}
        />,
      ],
    },
  ]

  const noItemsAdded = lineItems.length === 0

  return (
    <>
      <Box sx={{ height: noItemsAdded ? '75px' : 'auto' }}>
        <DataGrid
          {...dataGridProps}
          apiRef={itemsApiRef}
          columns={columns}
          rows={lineItems}
          getRowId={(row: NegotiationEventSupplierFormData['lineItems'][number]) =>
            row.numberInCollection as number
          }
          autoHeight={!noItemsAdded}
          processRowUpdate={handleRowUpdate}
          localeText={{
            noRowsLabel: '',
          }}
        />
      </Box>
      <Stack sx={{ mt: 1 }} direction='row' justifyContent='space-between'>
        <Typography variant='subtitle2' fontSize='0.875rem'>
          Total: {formatter.currency(totalLineItemsPrice, { currency })}
        </Typography>
      </Stack>
    </>
  )
}

const commonColumnProps = {
  disableColumnMenu: true,
  flex: 1,
}
