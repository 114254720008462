import { ChangeDirectionColor } from './types'
import { Article, ArticleWithInput } from '@common/types'
import { ArticleVolumes } from '@common/types/negotiation-preparation/ArticleVolumes'

type ArticleRetailPriceInput = ArticleWithInput['inputs']['retailPrice']['lac']
type ArticleCurrentRetailPrice = Article['retailPrice']['current']
type ArticleRppPriceInput = ArticleWithInput['inputs']['rpp']['lac']
type ArticleCurrentRpp = Article['rpp']['current']
type ArticleLastYearVolume = ArticleVolumes['lastYear'] | undefined

export const getSingleArticleProfit = ({
  retailPrice,
  rpp,
  vat,
}: {
  retailPrice: ArticleRetailPriceInput | ArticleCurrentRetailPrice
  rpp: ArticleRppPriceInput | ArticleCurrentRpp
  vat: Article['vat']
}): number | undefined => {
  if (!vat) {
    return undefined
  }
  const articleRetailPriceWithoutVat = retailPrice / (1 + vat)
  return articleRetailPriceWithoutVat - rpp
}

export const getInputTotalArticleProfit = (
  article: ArticleWithInput,
  volumeAccessor: (article: Article) => ArticleLastYearVolume = (article) =>
    article.volume?.lastYear,
): number | undefined => {
  return getTotalArticleProfit({
    retailPrice: article.inputs.retailPrice.lac,
    rpp: article.inputs.rpp.lac,
    vat: article.vat,
    volume: volumeAccessor(article),
  })
}

export const getCurrentTotalArticleProfit = (
  article: ArticleWithInput,
  volumeAccessor: (article: ArticleWithInput) => ArticleLastYearVolume = (article) =>
    article.volume?.lastYear,
): number | undefined => {
  return (
    getTotalArticleProfit({
      retailPrice: article.retailPrice.current,
      rpp: article.rpp.current,
      vat: article.vat,
      volume: volumeAccessor(article),
    }) ?? 0
  )
}

export const getTotalArticleProfit = ({
  retailPrice,
  rpp,
  vat,
  volume,
}: {
  retailPrice: ArticleRetailPriceInput | ArticleCurrentRetailPrice
  rpp: ArticleRppPriceInput | ArticleCurrentRpp
  vat: Article['vat']
  volume: ArticleLastYearVolume
}): number | undefined => {
  if (!volume) {
    return undefined
  }
  const profitPerArticle = getSingleArticleProfit({
    retailPrice: retailPrice,
    rpp: rpp,
    vat: vat,
  })
  if (!profitPerArticle) {
    return undefined
  }
  return volume * profitPerArticle
}

export const getPriceChangeDirectionColor = (
  priceChange: number,
  inverted: boolean = false,
): ChangeDirectionColor => {
  const hasIncreased = priceChange > 0
  const hasDecreased = priceChange < 0
  if (hasIncreased) {
    return inverted ? 'red' : 'green'
  }
  if (hasDecreased) {
    return inverted ? 'green' : 'red'
  }
  return undefined
}

export const makeParagraphs = (longText: string): string => {
  const maxLettersInParagraph = 60 as const

  const words = longText
    .replace(/(\r\n|\n|\r)/gm, ' ')
    .split('\n')
    .filter((value) => value !== '\n')
    .map((row) => row.split(' '))
    .flat()

  const result = words.reduce(
    (result, currentWord) => {
      const letterCount = currentWord.length

      if (currentWord.trim().length === 0) {
        return result
      }

      const potentialRowLetterCount = result.letterCounter + 1 + letterCount

      if (potentialRowLetterCount > maxLettersInParagraph) {
        return {
          paragraphs: [...result.paragraphs, currentWord.trim()],
          letterCounter: letterCount,
        }
      }

      const rest = [...result.paragraphs]
      const lastParagraph = rest.splice(-1, 1)[0]

      return {
        paragraphs: [
          ...rest,
          `${lastParagraph}${lastParagraph.length ? ' ' : ''}${currentWord.trim()}`,
        ],
        letterCounter: result.letterCounter + letterCount,
      }
    },
    { paragraphs: [''], letterCounter: 0 } as { paragraphs: string[]; letterCounter: number },
  )

  return result.paragraphs.join('\n')
}
