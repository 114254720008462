import { useNavigate } from 'react-router-dom'
import { GridColDef, GridRowParams } from '@mui/x-data-grid-pro'
import { Chip, styled } from '@mui/material'
import { PurchasePriceChangeFormListItem } from '@common/dto'
import { Formatter, dateFormats } from '@utils'
import { DataGrid, CaptionedValueCell, dataGridProps } from 'src/shared/components/table'
import { useFormatter } from '@shared/hooks'
import { applyParamToRoute } from 'src/main/constants'
import { PageConfig } from 'src/merchandising/pages/ChangeRequests/ChangeFormsPage/types'

function createColumns(formatter: Formatter): GridColDef<PurchasePriceChangeFormListItem>[] {
  return [
    {
      field: 'supplierName',
      headerName: 'Name',
      disableColumnMenu: true,
      flex: 0.8,
      renderCell: ({ row }) => (
        <CaptionedValueCell
          mainContent={row.supplierName}
          captionContent={`ID: ${row.supplierId}`}
          spacing={0}
        />
      ),
    },
    {
      field: 'isMostRecent',
      renderHeader: () => null,
      disableColumnMenu: true,
      flex: 0.3,
      renderCell: (params) => {
        return params.value ? <Chip label='New' color='error' size='small' /> : null
      },
    },
    {
      field: 'submittedAt',
      headerName: 'Submitted',
      disableColumnMenu: true,
      flex: 0.6,
      renderCell: ({ row, value }) => (
        <CaptionedValueCell
          mainContent={formatter.date(new Date(value), dateFormats.longDate)}
          captionContent={`ID: ${row.negotiationPreparationId.split('-')[0]}`}
          spacing={0}
        />
      ),
    },
    {
      field: 'articleCount',
      headerName: 'Items to negotiate',
      headerAlign: 'right',
      align: 'right',
      disableColumnMenu: true,
      flex: 0.6,
    },
    {
      field: 'totalCogs',
      headerName: 'Total COGS affected',
      headerAlign: 'right',
      align: 'right',
      disableColumnMenu: true,
      flex: 0.8,
      valueFormatter: ({ value }) => formatter.currency(value),
    },
    {
      field: 'averageCostChange',
      headerName: 'Average change in Cost',
      headerAlign: 'right',
      align: 'right',
      disableColumnMenu: true,
      valueGetter: ({ row }) => row.averageCostChange.changePercentage,
      valueFormatter: ({ value }) => formatter.percent0To1(value, { showChangeSign: true }),
      flex: 0.6,
    },
    {
      field: 'deadline',
      headerName: 'Deadline',
      disableColumnMenu: true,
      flex: 0.6,
      valueFormatter: ({ value }) => formatter.date(new Date(value), dateFormats.longDate),
    },
    {
      field: 'owner',
      headerName: 'Owned by',
      disableColumnMenu: true,
      flex: 0.8,
      renderCell: () => (
        <CaptionedValueCell
          mainContent='' // TODO MRCH-158 we don't have the catman name atm.
          captionContent='Category Manager'
          removeCaptionOpacity={true}
          spacing={0}
        />
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      disableColumnMenu: true,
      flex: 0.9,
      renderCell: (params) => {
        const value = params.row.status

        // TODO MRCH-159 Update status naming in backend to prevent mapping
        if (value === 'Conduct Analysis') {
          return <StyledChip label='In Preparation' size='small' greyedOut />
        } else if (value === 'Accept Thresholds') {
          return <StyledChip label='Review' size='small' />
        }

        return <StyledChip label='Waiting results' size='small' whiteOut />
      },
    },
  ]
}

const setMostRecentItemInItems = (
  listItems: PurchasePriceChangeFormListItem[],
  newNegotiationPrepId: string | null,
) => {
  if (!newNegotiationPrepId) {
    return listItems
  }

  return listItems.map((item) => {
    if (item.negotiationPreparationId === newNegotiationPrepId) {
      return {
        ...item,
        isMostRecent: true,
      }
    }

    return item
  })
}

interface Props {
  isLoading: boolean
  items: PurchasePriceChangeFormListItem[]
  itemNavigateTo: PageConfig['itemNavigateTo']
  newNegotiationPrepId: string | null
}

export const ChangeFormsTable = ({
  isLoading,
  items,
  itemNavigateTo,
  newNegotiationPrepId,
}: Props): JSX.Element => {
  const formatter = useFormatter()
  const navigate = useNavigate()

  const listItems = setMostRecentItemInItems(items, newNegotiationPrepId)

  const columns = createColumns(formatter)

  return (
    <DataGrid
      {...dataGridProps}
      loading={isLoading}
      columns={columns}
      className='align-items-start'
      rows={listItems}
      getRowId={(row: PurchasePriceChangeFormListItem) => row.negotiationPreparationId}
      onRowClick={(params: GridRowParams<PurchasePriceChangeFormListItem>) =>
        navigate(applyParamToRoute(itemNavigateTo, params.row.negotiationPreparationId))
      }
    />
  )
}

type StyledChipProps = {
  greyedOut?: boolean
  whiteOut?: boolean
}

const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => !['greyedOut', 'whiteOut'].includes(prop as string),
})<StyledChipProps>(({ theme, greyedOut, whiteOut }) => ({
  marginTop: theme.spacing(0.75),
  fontWeight: 'bold',
  color: whiteOut ? theme.typography.body2.color : theme.palette.common.white, // TODO not fully aligned with design
  opacity: greyedOut || whiteOut ? 0.3 : 1, // TODO not fully aligned with design
  background: whiteOut ? 'transparent' : theme.palette.accent.light,
  borderRadius: '2px',
  cursor: 'pointer',
  width: '124px',
}))
