import { ProjectListEntity } from '@pactum/core-backend-types'
import { cyrb53a } from './utils/hash'

// this need to be removed at some point and moved to the backend instead,
// i.e. looks like ProjectListEntity should rather have a special flag for this
const genericProjectTagHashes = new Set([
  3658154857940660, // veritiv
  7073840412415161, // wm_chile_logistics
])

export function renderProjectAsBaselineModule(project: ProjectListEntity): boolean {
  return genericProjectTagHashes.has(cyrb53a(project.tag))
}
