import { ExpandableSection } from 'src/shared/components/ExpandableSection'
import { PricingStrategySummary } from './PricingStrategySummary'
import { HistoricalNumbersGraph } from './HistoricalNumbersGraph'
import { useNegotiationPreparationData } from 'src/merchandising/pages/ChangeRequests/NegotiationPreparation/NegotiationPreparationPage'
import { CompetitorPricesTableV2 } from './CompetitorPricesTableV2'

export const PricingInsights = (): JSX.Element | null => {
  const { data } = useNegotiationPreparationData()

  const { pricingStrategy, competitorPrices, historical } = data.analysis

  if (!pricingStrategy && !competitorPrices && !historical) {
    console.warn('No Pricing Insights data')
    return null
  }

  return (
    <ExpandableSection
      title='Pricing Insights'
      description='The following section shows pricing insights from different sources.'
    >
      <PricingStrategySummary />
      <CompetitorPricesTableV2 />
      <HistoricalNumbersGraph />
    </ExpandableSection>
  )
}
