import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material'
import React, { ReactElement } from 'react'

interface Props {
  title: ReactElement
  children: ReactElement
}

export const ProcurementTermsTooltip = ({ title, children }: Props) => {
  return <StyledTooltip title={title}>{children}</StyledTooltip>
}

const StyledTooltip = styled(({ className, children, ...props }: TooltipProps) => (
  <Tooltip
    placement='top-end'
    classes={{ popper: className }}
    children={children}
    arrow
    {...props}
  />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: '150px',
    maxWidth: '375px',
    background: 'white',
    color: 'black',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,1)',
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      border: '1px solid #E6E8ED',
    },
    color: 'white',
  },
})
