import React from 'react'
import { Stack, Typography } from '@mui/material'
import { NegotiationEvent, NegotiationEventSupplier } from '../../../store/types'
import { DataCell, DataCellProps } from '@procurement-shared/DataCell/DataCell'
import { Formatter } from '@utils'
import {
  getBaselineSupplier,
  getNegotiatedPaymentTermsFromNegotiation,
} from '../../../utils/negotiationEventGetters'
import { formatPaymentTerms } from '../../../utils/formatting'
import { TermItem, TermsTooltip } from '@procurement-shared/TermsTooltip/TermsTooltip'
import { formatCurrency } from '@shared/utils/formatCurrency'
import InfoIcon from '@mui/icons-material/Info'
import { yellow } from '@mui/material/colors'

type Props = {
  event: NegotiationEvent
  supplier: NegotiationEventSupplier
  size: DataCellProps['size']
  formatter: Formatter
}

export const PaymentTermsCell = ({ event, supplier, size, formatter }: Props) => {
  const { initialPaymentTerms, negotiation } = supplier
  const { currency } = event
  const unsuitablePaymentTermsFeedback =
    supplier.negotiation?.outcomes?.feedback?.unsuitablePaymentTerms

  const negotiatedPaymentTerms = getNegotiatedPaymentTermsFromNegotiation(negotiation)

  const formattedInitialPaymentTerms = formatPaymentTerms(initialPaymentTerms)

  const initialPaymentTermsExist = formattedInitialPaymentTerms !== '-'
  const isConcluded = negotiation?.status === 'AGREEMENT_REACHED'
  const agreedPaymentTermsAreMissing =
    negotiatedPaymentTerms.paymentDaysObject === null &&
    negotiatedPaymentTerms.discountDaysObject === null &&
    negotiatedPaymentTerms.discount === null

  if (negotiation === null || agreedPaymentTermsAreMissing) {
    return (
      <DataCell label={'Existing payment terms'} size={size}>
        {formattedInitialPaymentTerms}
        {initialPaymentTermsExist && isConcluded && unsuitablePaymentTermsFeedback && (
          <Stack direction={'row'} gap={1} alignContent='center' alignItems='center'>
            <InfoIcon style={{ color: yellow[800] }} />
            <Typography variant='body2' style={{ color: yellow[800] }}>
              New terms not agreed
            </Typography>
          </Stack>
        )}
      </DataCell>
    )
  }

  const isMultiSupplier = event.suppliers.length > 1
  const baselineSupplier = getBaselineSupplier({ negotiationEvent: event, supplier })
  const { initialPaymentTerms: baselineInitialPaymentTerms } = baselineSupplier
  const formattedBaselinePaymentTerms = formatPaymentTerms(baselineInitialPaymentTerms)
  const formattedAgreedPaymentTerms = formatPaymentTerms(negotiatedPaymentTerms)

  const workingCapitalSavings = negotiation?.outcomes?.savingsBreakdown?.workingCapital ?? 0

  const terms: TermItem[] = isMultiSupplier
    ? [
        { title: 'Existing', value: formattedInitialPaymentTerms },
        { title: 'Baseline', value: formattedBaselinePaymentTerms },
        { title: 'Agreed', value: formattedAgreedPaymentTerms },
        { title: 'Savings', value: formatCurrency(workingCapitalSavings, currency, formatter) },
      ]
    : []

  return (
    <TermsTooltip terms={terms}>
      <DataCell
        label={'Agreed payment terms'}
        size={size}
        footnote={
          formattedBaselinePaymentTerms ? `Baseline ${formattedBaselinePaymentTerms}` : undefined
        }
      >
        {formattedAgreedPaymentTerms}
      </DataCell>
    </TermsTooltip>
  )
}
