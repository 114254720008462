import { Tab, styled } from '@mui/material'

export const StyledTab = styled(Tab)({
  paddingTop: '5px',
  paddingBottom: '5px',
  minHeight: 'unset',
  color: '#6C6C6C',
  textTransform: 'none',
  fontWeight: 'bold',
  '&.Mui-selected': {
    color: '#0F3A4F',
  },
})
