import React, { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Button, ClickAwayListener, styled, Tooltip } from '@mui/material'
import LinkIcon from '@mui/icons-material/Link'
import { MerchandisingRoutes } from '../../../routes'
import { generatePath } from 'react-router-dom'
import { useProjects } from '@hooks'

interface Props {
  sessionKey?: string
}

export const CopyLink = ({ sessionKey }: Props) => {
  const [open, setOpen] = useState(false)

  const { activeOrgTag, activeProjectTag } = useProjects()

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
    setTimeout(() => handleTooltipClose(), 700)
  }

  const routeToSessionOrList = sessionKey
    ? generatePath(MerchandisingRoutes.SCENARIO_DETAILS, {
        orgTag: activeOrgTag!,
        projectTag: activeProjectTag!,
        sessionKey,
      })
    : generatePath(MerchandisingRoutes.SCENARIOS_LIST, {
        orgTag: activeOrgTag!,
        projectTag: activeProjectTag!,
      })

  return (
    <ClickAwayListener onClickAway={handleTooltipClose} disableReactTree>
      <CopyToClipboard
        text={`${window.location.origin}${routeToSessionOrList}`}
        onCopy={handleTooltipOpen}
      >
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement='left'
          title='Link copied'
        >
          <StyledCopyButton size='small' startIcon={<LinkIcon fontSize='inherit' />}>
            Copy
          </StyledCopyButton>
        </Tooltip>
      </CopyToClipboard>
    </ClickAwayListener>
  )
}

const StyledCopyButton = styled(Button)({
  fontSize: 'inherit',
  fontWeight: 400,
})
