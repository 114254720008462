import { GridColDef, GridRowModel } from '@mui/x-data-grid-pro'
import { CommercialTermsNegotiation } from '@campaigns/types/negotiation'
import { commonColumnParameters } from '../helpers'
import { Formatter } from '@utils'
import { CaptionedValueCell } from '@components/table'
import { average } from '@common/utils'
import { GrowthRebatesTooltip } from '../../GrowthRebatesTooltip'

export const getGrowthRebatesColumns = (
  formatter: Formatter,
): GridColDef<CommercialTermsNegotiation>[] => [
  {
    ...commonColumnParameters,
    field: 'achievedGrowthRebates',
    headerName: 'Achieved growth rebates',
    // used for sorting
    valueGetter: (cell) => {
      const agreedGrowthRebates =
        cell.row.contracts[0]?.standardTerms?.growthRebateStructure?.agreed
      return agreedGrowthRebates
        ? average(agreedGrowthRebates.tiers, (tier) => tier.rebatePercent)
        : 0
    },
    renderCell: (cell) => getAchievedGrowthRebatesColumn(cell.row, formatter),
  },
]

const getAchievedGrowthRebatesColumn = (
  row: GridRowModel<CommercialTermsNegotiation>,
  formatter: Formatter,
) => {
  const agreedGrowthRebates = row.contracts[0]?.standardTerms?.growthRebateStructure?.agreed

  if (!agreedGrowthRebates) {
    return <CaptionedValueCell mainContent={''} />
  }

  const sortedGrowthRebateTiers = [...agreedGrowthRebates.tiers].sort((a, b) => a.min - b.min)

  if (sortedGrowthRebateTiers.length === 0) {
    return <CaptionedValueCell mainContent={'N/A'} />
  }

  const { rebatePercent: firstGrowthRebatePercent } = sortedGrowthRebateTiers[0]
  const { rebatePercent: lastGrowthRebatePercent } =
    sortedGrowthRebateTiers[sortedGrowthRebateTiers.length - 1]

  return (
    <GrowthRebatesTooltip negotiation={row}>
      <CaptionedValueCell
        mainContent={
          firstGrowthRebatePercent === lastGrowthRebatePercent
            ? formatter.percent0To1(firstGrowthRebatePercent / 100)
            : `${formatter.percent0To1(firstGrowthRebatePercent / 100)} - ${formatter.percent0To1(lastGrowthRebatePercent / 100)}`
        }
      />
    </GrowthRebatesTooltip>
  )
}
