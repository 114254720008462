export interface SupplierWithUnitCount extends Supplier {
  totalUnits: number
  successfulUnits: number
}

export interface Supplier {
  id: string
  name: string
  rating?: number | null
  projectTag: string
  externalId: string
  mcNumber?: string | null
  isEnabled: boolean
  attributes: SupplierAttributes
  contacts: SupplierContact[]
}

export interface SupplierAttributes {
  notes?: string
  vendorCode?: string
  [key: string]: {} | undefined
}

export type RequiredSupplierAttributes = string[]

export interface SupplierContact {
  id: string
  timeZone: string
  isPrimary: boolean
  email: string
  phone: string
  address: Region[]
}

export interface Region {
  country: string
  states: string[]
}

export enum SupplierAction {
  DETAILS = 'DETAILS',
  DISABLE = 'DISABLE',
  ENABLE = 'ENABLE',
}
