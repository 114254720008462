import { isPositiveNumber } from '@pactum/purchasing-lib1/dist/common/typeGuards'
import { NegotiationEventFormData, NegotiationMode } from '../schema'

export const enrichForRebates = (data: NegotiationEventFormData) => {
  if (data.negotiationMode === NegotiationMode.Prices) {
    return data
  }
  const lineItem = {
    ...data.lineItems.at(0),
    name: 'Estimated spend',
    numberInCollection: 1,
    unit: 'month',
    quantity: data.lineItems.at(0)?.quantity ?? 1,
  }

  return {
    ...data,
    lineItems: [lineItem],
  }
}

export const enrichForRebatesWithApplicableSpend = (data: NegotiationEventFormData) => {
  if (data.negotiationMode === NegotiationMode.Prices) {
    return data
  }

  const rebateLineItem = data.lineItems.at(0)
  if (!rebateLineItem)
    throw new Error(`
      No rebate line item found in the requisition data
    `)

  const spendOrQuoteDuration = data.suppliers.at(0)?.negotiationSettings.contractLength
  if (!isPositiveNumber(spendOrQuoteDuration))
    throw new Error(`
      Spend or quote duration is not a positive number: ${spendOrQuoteDuration}
    `)

  const applicableSpend = rebateLineItem.initialUnitPrice
  if (!isPositiveNumber(applicableSpend))
    throw new Error(`
      Applicable spend is not a positive number: ${applicableSpend}
    `)

  const lineItem = {
    ...rebateLineItem,
    quantity: 1,
    name: 'Estimated spend',
    numberInCollection: 1,
    unit: 'month',
  }

  return {
    ...data,
    lineItems: [lineItem],
  }
}
