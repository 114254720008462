import { ConfirmationModal } from '@components/ConfirmationModal'
import { NegotiationEventSupplier } from '../../../store/types'
import { useDeleteNegotiationEventSupplierMutation } from '../../../store/purchasing'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useSnackbar } from 'notistack'
import { isQuerySuccessResponse } from '@shared/backend/error/typeGuards'

interface Props {
  open: boolean
  supplier: NegotiationEventSupplier | null
  negotiationEventId: number
  onCancel: () => void
  onSubmit: () => void
}

export const DeleteSupplierConfirmationModal = ({
  open,
  onCancel,
  onSubmit,
  supplier,
  negotiationEventId,
}: Props) => {
  const [deleteNegotiationEventSupplier] = useDeleteNegotiationEventSupplierMutation()
  const { activeProjectTag: projectTag } = useActiveProject()
  const { enqueueSnackbar } = useSnackbar()

  const submit = async () => {
    if (!supplier) {
      return
    }

    const response = await deleteNegotiationEventSupplier({
      projectTag,
      requisitionId: negotiationEventId,
      supplierId: supplier.id,
    })

    if (isQuerySuccessResponse(response)) {
      enqueueSnackbar('Supplier deleted successfully!', {
        variant: 'success',
      })
    } else {
      enqueueSnackbar('Deleting supplier failed!', {
        variant: 'error',
      })
    }

    onSubmit()
  }

  return (
    <ConfirmationModal
      confirmType='negative'
      open={open}
      onCancel={onCancel}
      onSubmit={submit}
      title='Remove supplier?'
      content={`Supplier ${supplier?.name} will be removed from the requisition. This action cannot be undone.`}
      confirmButtonText='Delete'
    />
  )
}
