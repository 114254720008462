import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { IconButton, Stack } from '@mui/material'
import {
  gridPageSelector,
  gridPageSizeSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro'

interface PaginationActionComponentProps {
  rowCount: number
}

export const PaginationActionComponent = ({ rowCount }: PaginationActionComponentProps) => {
  const apiRef = useGridApiContext()
  const page = useGridSelector(apiRef, gridPageSelector)
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector)
  const pageCount = getPageCount(rowCount, pageSize)

  return (
    <Stack
      direction='row'
      justifyContent='flex-end'
      flex={1}
      ml={2}
      sx={{
        svg: {
          fontSize: (theme) => theme.typography.subtitle2.fontSize,
        },
      }}
    >
      <IconButton
        color='primary'
        aria-label='first page'
        disabled={page === 0}
        onClick={() => apiRef.current.setPage(0)}
      >
        <ArrowBackIosNewIcon />
        <ArrowBackIosNewIcon
          sx={{
            ml: -1,
          }}
        />
      </IconButton>
      <IconButton
        color='primary'
        aria-label='previous page'
        disabled={page === 0}
        onClick={() => apiRef.current.setPage(page - 1)}
      >
        <ArrowBackIosNewIcon />
      </IconButton>
      <IconButton
        color='primary'
        aria-label='next page'
        disabled={pageCount === 0 || page === pageCount - 1}
        onClick={() => apiRef.current.setPage(page + 1)}
      >
        <ArrowForwardIosIcon />
      </IconButton>
      <IconButton
        color='primary'
        aria-label='last page'
        disabled={pageCount === 0 || page === pageCount - 1}
        onClick={() => apiRef.current.setPage(pageCount)}
      >
        <ArrowForwardIosIcon />
        <ArrowForwardIosIcon
          sx={{
            ml: -1,
          }}
        />
      </IconButton>
    </Stack>
  )
}

const getPageCount = (rowCount: number, pageSize: number): number => {
  if (pageSize > 0 && rowCount > 0) {
    return Math.ceil(rowCount / pageSize)
  }

  return 0
}
