import { CoreBackendPermission } from '@pactum/core-backend-types'
import { useEffect, useState } from 'react'
import { generatePath } from 'react-router-dom'
import { useGetUserOrganizationsQuery } from 'src/main/store/userOrganizations'
import { ChangeFormUpload } from 'src/merchandising/pages/ChangeRequests/ChangeFormsPage/ChangeFormUpload'
import { MerchandisingRoutes } from 'src/merchandising/routes'
import { useGetChangeRequestsQuery } from 'src/merchandising/store/'
import { useOrgTag } from '../../../../main/hooks/useOrgTag'
import { ChangeFormContent } from './ChangeFormContent'

export const ChangeFormsPage = (): JSX.Element => {
  const orgTag = useOrgTag()
  const [showFileUpload, setShowFileUpload] = useState(false)
  const [newNegotiationPrepId, setNewNegotiationPrepId] = useState<string | null>(null)

  const { data: changeRequests, isFetching } = useGetChangeRequestsQuery()
  const { data: organizations } = useGetUserOrganizationsQuery(undefined)

  useEffect(() => {
    const checkSetPermission = async () => {
      const permissions = organizations?.flatMap(({ permissions }) => permissions) ?? []

      setShowFileUpload(permissions.includes(CoreBackendPermission.AUTH_ADMIN))
    }

    checkSetPermission()
  }, [organizations])

  return (
    <ChangeFormContent
      pageConfig={{
        newNegotiationPrepId,
        listTitle: 'Requested Purchase Price Change Forms',
        itemNavigateTo: generatePath(MerchandisingRoutes.NEGOTIATION_PREPARATION_ADJUSTMENTS, {
          orgTag,
          negotiationPreparationId: ':negotiationPreparationId',
        }),
      }}
      items={changeRequests?.items ?? []}
      isLoading={isFetching}
    >
      {showFileUpload && (
        <ChangeFormUpload onNewNegotiationPrep={(prepId) => setNewNegotiationPrepId(prepId)} />
      )}
    </ChangeFormContent>
  )
}
