import { DataGrid, dataGridProps } from '@components/table'
import { Incentive } from '@procurement/store/schema'
import { Chip, Grid, Typography } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-pro'
import { NegotiationEventSupplier } from '../../../store/types'

interface OfferedIncentivesProps {
  incentives: Incentive[]
  supplier: NegotiationEventSupplier
}

export const OfferedIncentives = ({ incentives, supplier }: OfferedIncentivesProps) => {
  const supplierIncentives = supplier.negotiationSettings?.incentives
  const outcomes = supplier.negotiation?.outcomes
  const hasOutcomes = Boolean(outcomes)

  if (!supplierIncentives?.length) {
    return null
  }

  const offeredIncentives = incentives.filter((incentive) =>
    supplierIncentives?.includes(incentive.id),
  )

  const isAccepted = (incentive: Incentive) => outcomes?.incentives?.includes(incentive.id)

  const columns: GridColDef<Incentive>[] = [
    {
      field: 'primaryWording',
      type: 'string',
      headerName: 'Incentive',
      editable: false,
      flex: 1,
      disableColumnMenu: true,
      sortable: true,
    },
    {
      field: 'secondaryWording',
      type: 'string',
      headerName: 'Description',
      editable: false,
      flex: 3,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'id',
      type: 'string',
      headerName: 'Results',
      editable: false,
      sortable: false,
      flex: 1,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        if (!hasOutcomes) {
          return '-'
        }

        return isAccepted(row) ? (
          <Chip label='Accepted' size='small' color='success' />
        ) : (
          <Chip
            label='Not accepted'
            size='small'
            sx={{
              backgroundColor: 'text.secondary',
              color: 'background.default',
            }}
          />
        )
      },
    },
  ]

  return (
    <>
      <Typography variant='h3' sx={{ mt: 4 }}>
        Offered incentives
      </Typography>
      <Grid item xs={12} sx={{ mt: 2, ml: 1 }}>
        <DataGrid
          {...dataGridProps}
          sx={(theme) => ({
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
              py: theme.spacing(1),
            },
          })}
          density='standard'
          getRowHeight={() => 'auto'}
          columns={columns}
          rows={offeredIncentives}
          localeText={{
            noRowsLabel: 'No offered incentives',
          }}
        />
      </Grid>
    </>
  )
}
