import { UserOrganization } from '@pactum/core-backend-types'
import { suiteBaseApi } from '@shared/api/suite-base-api'

export const userOrganizationsApi = suiteBaseApi.injectEndpoints({
  endpoints: (build) => ({
    getUserOrganizations: build.query<UserOrganization[], void>({
      query: () => `/suite/user/organizations`,
    }),
  }),
})

export const { useGetUserOrganizationsQuery } = userOrganizationsApi
