import { useProjects, useUserPermissions } from '@hooks'
import { CoreBackendPermission } from '@pactum/core-backend-types'
import { ErrorPage } from '@pages/ErrorPage'
import { Navigate } from 'react-router-dom'
import { useOrgTag } from '../../hooks/useOrgTag'
import { useGetOrganizationsConfigurationQuery } from '../../store/projects'

export const OrgHomePage = () => {
  const orgTag = useOrgTag()
  const { data, isLoading } = useGetOrganizationsConfigurationQuery({ orgTag })
  const { getActiveOrgRedirectPath, projects } = useProjects()
  const { userPermissionsInActiveOrg } = useUserPermissions()

  if (isLoading || !data) {
    return null
  }

  const amountOfWorkspacesThatAreNotSandbox = projects.filter(
    (project) => project.isClientSandbox === false,
  ).length

  // only navigate to the first menu item if it exists and if there are more than one non-sandbox project
  if (data.menuItems.length && amountOfWorkspacesThatAreNotSandbox > 1) {
    return <Navigate to={data.menuItems[0].path} />
  } else {
    const redirectPath = getActiveOrgRedirectPath(orgTag)

    if (redirectPath) {
      return <Navigate to={redirectPath} />
    }

    if (!userPermissionsInActiveOrg) {
      return null
    }

    if (userPermissionsInActiveOrg.has(CoreBackendPermission.AUTH_ADMIN)) {
      return (
        <ErrorPage title='No Suite-compatible project found'>
          <p>There aren't any Suite-compatible projects, or you don't have access to them.</p>
          <p>You can try to use cmd+k (macOS) or ctrl+k (Linux/Windows) for some other usecases.</p>
        </ErrorPage>
      )
    }
  }

  return null
}
