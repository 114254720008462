import { createTheme, Theme, ThemeOptions } from '@mui/material/styles'

const getWmThemeOptions = (outerTheme: Theme): ThemeOptions => ({
  ...outerTheme,
  palette: {
    ...outerTheme.palette,
    primary: {
      main: '#306FD5',
      dark: '#F6C44A',
      contrastText: '#222222',
    },
    lightGrey: {
      main: '#8D8D8D',
    },
    darkGrey: {
      main: '#6C6C6C',
    },
    success: {
      main: '#F6C44A',
    },
    accent: {
      main: '#F6C44A',
      light: '#306FD5',
    },
  },
})

export const createWmTheme = (outerTheme: Theme) => createTheme(getWmThemeOptions(outerTheme))
