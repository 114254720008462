import { DetailedCostModelBreakdown, NegotiationAnalysis } from '@common/types'
import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useNegotiationPreparationData } from 'src/merchandising/pages/ChangeRequests/NegotiationPreparation/NegotiationPreparationPage'
import { useMemo } from 'react'
import { HeaderTableCell } from './SupplierCommoditiesReflections'
import { useFormatter } from '@shared/hooks'

type Row = DetailedCostModelBreakdown[string][number] & { costType?: string }

const getRows = (costModel: NegotiationAnalysis['detailedCostModel']): Row[] => {
  const breakdown = costModel?.breakdown

  const rows: Row[][] = []

  if (!breakdown) {
    return rows.flat()
  }

  Object.keys(breakdown).forEach((costType) => {
    const breakdownElements = breakdown[costType]

    rows.push(
      breakdownElements.map((element) => {
        if (element.commodity === '') {
          return { ...element, costType }
        }
        return { ...element }
      }),
    )
  })

  return rows.flat()
}

export const DetailedCostModel = (): JSX.Element | null => {
  const formatter = useFormatter()
  const { data } = useNegotiationPreparationData()

  const rows = useMemo(() => getRows(data.analysis.detailedCostModel), [data])

  if (!data.analysis.detailedCostModel || !rows.length) {
    console.warn('No data for Detailed Cost Model')
    return null
  }

  return (
    <Box mt={4}>
      <Typography variant='h5'>Detailed Cost Model</Typography>
      <TableContainer sx={{ mt: 2 }}>
        <Table sx={{ minWidth: 300 }}>
          <TableHead>
            <TableRow>
              <HeaderTableCell>Cost type</HeaderTableCell>
              <HeaderTableCell>Commodity</HeaderTableCell>
              <HeaderTableCell>% of total value</HeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={JSON.stringify(row)}>
                <HighlightedTableCell isHighlighted={row.isHighlighted}>
                  {row.costType}
                </HighlightedTableCell>
                <HighlightedTableCell isHighlighted={row.isHighlighted}>
                  {row.commodity}
                </HighlightedTableCell>
                <HighlightedTableCell isHighlighted={row.isHighlighted}>
                  {formatter.percent0To1(row.percentOfTotalValue)}
                </HighlightedTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

const HighlightedTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'isHighlighted',
})(({ isHighlighted }: { isHighlighted: boolean }) => ({
  ...(isHighlighted && {
    fontWeight: 'bold',
  }),
}))
