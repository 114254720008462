import { useSnackbar } from 'notistack'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { downloadFile } from '@utils'
import { useLazyDownloadPactNegotiationsCsvQuery } from '../store/pact'
import { NegotiationListEntity } from '@common/dto/pact/negotiation-list-entity'
import { enqueIfError } from '@shared/utils/snackbar'

export type GenericNegotiationAction = 'DOWNLOAD'

export const useGenericSingleNegotiationAction = (negotiation: NegotiationListEntity) =>
  useGenericNegotiationsActions(-1, [negotiation]) // to not trigger downloading all negotiations

export const useGenericNegotiationsActions = (
  allNegotiationsCount: number,
  negotiations: NegotiationListEntity[],
): {
  availableActions: GenericNegotiationAction[]
  handleDownloadNegotiations: () => Promise<void>
} => {
  const { enqueueSnackbar } = useSnackbar()
  const [downloadResultsCsv] = useLazyDownloadPactNegotiationsCsvQuery()
  const { activeProjectTag } = useActiveProject()

  const handleDownloadNegotiations = async () => {
    const ids =
      allNegotiationsCount === negotiations.length
        ? undefined
        : negotiations.map((negotiation) => negotiation.id)

    try {
      const downloadResultsBlob = await downloadResultsCsv({
        projectTag: activeProjectTag,
        ids,
      }).unwrap()
      const fileName = ids?.length === 1 ? `results-${ids[0]}.csv` : 'results.csv'

      downloadFile(fileName, downloadResultsBlob)
    } catch (error) {
      return enqueIfError(error, enqueueSnackbar)
    }
  }

  return {
    availableActions: getAvailableActions(negotiations),
    handleDownloadNegotiations,
  }
}

const getAvailableActions = (negotiations: NegotiationListEntity[]): GenericNegotiationAction[] => {
  const areAllConcluded = negotiations.every((negotiation) => negotiation.isConcluded)
  const areAllSuccessful = negotiations.every((negotiation) => negotiation.isSuccessful)

  return areAllConcluded && areAllSuccessful ? ['DOWNLOAD'] : []
}
