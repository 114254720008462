import { useProjects } from '@hooks'
import { ErrorPage } from '@pages/ErrorPage'
import { LoadingPage } from '@pages/LoadingPage'
import { useMemo } from 'react'
import { useWorkspaceId } from 'src/main/hooks/useWorkspaceId'
import { useGetOrganizationsQuery, useGetProjectsQuery } from 'src/main/store/projects'
import { Navigate } from 'react-router-dom'
import { getProjectSwitchPath } from 'src/main/hooks/useProjects'

export const RedirectToWorkspace = () => {
  const workspaceId = useWorkspaceId()
  const { data: orgs, isLoading: loadingOrgs } = useGetOrganizationsQuery()
  const { data: projects, isLoading: loadingProjects } = useGetProjectsQuery()

  const { orgTag, projectTag } = useMemo(() => {
    if (!orgs) {
      return { orgTag: undefined, projectTag: undefined }
    }
    const foundOrg = orgs.find((org) => org.projects.some((project) => project.id === workspaceId))
    const projectTag = foundOrg?.projects.find((project) => project.id === workspaceId)?.tag ?? null
    return { orgTag: foundOrg?.tag ?? null, projectTag }
  }, [orgs, workspaceId])

  const { setActiveProject } = useProjects()

  if (loadingOrgs || loadingProjects || orgTag === undefined || projectTag === undefined) {
    return <LoadingPage />
  }

  if (orgTag === null || projectTag === null) {
    return (
      <ErrorPage title='Something went wrong'>
        <p>You either don't have access to the workspace or the workspace does not exist.</p>
        <p>Workspace ID to redirect to: {workspaceId}</p>
      </ErrorPage>
    )
  }

  const redirectPath = getProjectSwitchPath(projectTag, orgTag, projects)

  if (!redirectPath) {
    return <LoadingPage />
  }

  setActiveProject(projectTag)
  return <Navigate to={redirectPath} />
}
