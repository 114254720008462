import { SpotLoadCreateUnitDto } from '@logistics/pages/spotLoad/types'

export interface CreateUnitForm extends Omit<SpotLoadCreateUnitDto, 'pickupDateTime'> {
  pickupDate: Date
  pickupTime: Date
}

export const getCreateUnitFormDefaultValues = (): CreateUnitForm => {
  const pickupDateTime = new Date()
  pickupDateTime.setHours(19, 0, 0, 0)
  pickupDateTime.setDate(new Date().getDate() + 7)

  return {
    origin: '',
    destination: '',
    pickupDate: pickupDateTime,
    pickupTime: pickupDateTime,
    froNumber: '',
    totalWeight: 0,
    equipmentType: 'Dry van',
    comments: '',
    useCase: 'SPOT_LOAD',
  }
}
