import { styled } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { DataGrid, dataGridProps } from '@components/table'
import { DataGridPro, GridPaginationModel } from '@mui/x-data-grid-pro'
import { ROWS_PER_PAGE_OPTIONS } from '@components/table/pagination'
import { createRFPColumns } from './columns'
import { useFormatter } from '@shared/hooks'
import { useGetRFPListQuery } from '@logistics/store/contractedLanesApi'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { Toolbar } from './Toolbar'
import { RequestForPrice } from '../../types'

const DEFAULT_PAGE_SIZE = 10

export const RFPList = () => {
  const { activeProjectTag } = useActiveProject()

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  })

  const {
    data: rfpList,
    isLoading,
    isFetching,
  } = useGetRFPListQuery({
    projectTag: activeProjectTag,
    skip: paginationModel.page * paginationModel.pageSize,
    limit: paginationModel.pageSize,
  })

  const formatter = useFormatter()

  const columns = useMemo(() => createRFPColumns(formatter), [formatter])

  return (
    <StyledDataGrid
      {...dataGridProps}
      pagination
      hideFooter={false}
      pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      paginationMode='server'
      columns={columns}
      rows={rfpList?.items ?? []}
      rowCount={rfpList?.total ?? 0}
      loading={isLoading || isFetching}
      className='dg-expandable'
      getRowId={(row: RequestForPrice) => row.id}
      slots={{
        ...dataGridProps.slots,
        toolbar: Toolbar,
      }}
    />
  )
}

const StyledDataGrid = styled(DataGrid)(() => ({
  '& .MuiDataGrid-row:hover': {
    backgroundColor: 'unset',
  },
  '& .MuiDataGrid-row': {
    cursor: 'default',
  },
})) as typeof DataGridPro
