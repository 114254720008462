import { CommercialTermsRoutes, commercialTermsNavigationItems } from './routes'
import { createApi } from '@reduxjs/toolkit/query/react'
import { CommercialTermsEntryPoint } from './entrypoint'
import { ProductType, ProjectListEntity } from '@pactum/core-backend-types'
import { commercialTermsApi } from './store'
import { GenericSuiteModule } from 'src/main/types/suite-module'
import { renderProjectAsBaselineModule } from '@shared/projectsSpecific'
import { SuitePermission } from '@common/types'

export const commercialTermsSuiteModule: GenericSuiteModule = {
  name: 'commercial_terms',
  api: commercialTermsApi as unknown as ReturnType<typeof createApi>,
  entrypoint: CommercialTermsEntryPoint,
  basePath: 'commercial-terms',
  projectSwitchPath: (_?: Set<SuitePermission>) => CommercialTermsRoutes.HOMEPAGE,
  isProjectCompatible: (project: ProjectListEntity) =>
    !renderProjectAsBaselineModule(project) &&
    project.productType === ProductType.CommercialTermsPaymentTerms &&
    project.compatibleWithNegotiationSuite,
  navigationItems: commercialTermsNavigationItems,
}
