import { logisticsBaseApi } from '@logistics/store/baseApi'
import { SpotLoadNegotiationAction } from '@logistics/pages/spotLoad/types/negotiation' // TODO: deprecate -- currently not used

// TODO: deprecate -- currently not used
export const negotiationApi = logisticsBaseApi.injectEndpoints({
  endpoints: (build) => ({
    applyNegotiationAction: build.mutation<
      void,
      {
        action: SpotLoadNegotiationAction
        projectTag: string
        negotiationIds: number[]
      }
    >({
      query: ({ projectTag, negotiationIds, action }) => ({
        url: `/suite/${projectTag}/negotiations/${action}`,
        method: 'POST',
        body: { negotiationIds },
      }),
      invalidatesTags: ['UnitList', 'StatisticsHeader'],
    }),
  }),
})

export const { useApplyNegotiationActionMutation } = negotiationApi
