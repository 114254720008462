import { GridColDef } from '@mui/x-data-grid-pro'
import React from 'react'
import { CaptionedValueCell, NotAvailableCell } from '@components/table'
import { dateFormats, Formatter } from '@utils'
import { SyncAlt } from '@mui/icons-material'
import { UnitStatusCell as SpotLoadUnitStatusCell } from '@logistics/pages/spotLoad/UnitPage/UnitList/UnitStatusCell'
import { noValueText } from '@constants'
import { SpotLoadUnit } from '@logistics/pages/spotLoad/types'
import { OUTER_COLUMN_WIDTHS } from '@logistics/pages/spotLoad/UnitPage/UnitList/constants'

// TODO: uses spot load unit typedef & endpoint, needs generalising
export const createColumns = (
  formatter: Formatter,
  supplierExternalId: string,
): GridColDef<SpotLoadUnit>[] => [
  {
    field: 'origin',
    headerName: 'Origin',
    disableColumnMenu: true,
    sortable: false,
    flex: 0.25,
    renderCell: ({ row }) => (
      <CaptionedValueCell
        mainContent={row.origin}
        captionContent={formatter.date(
          new Date(row.origin_date ?? row.created_at),
          dateFormats.longDate,
        )}
        spacing={0}
      />
    ),
  },
  {
    field: 'arrows',
    headerName: '',
    width: 16,
    disableColumnMenu: true,
    sortable: false,
    disableReorder: true,
    renderCell: () => (
      <SyncAlt
        fontSize='medium'
        sx={{
          color: 'text.secondary',
        }}
      />
    ),
  },
  {
    field: 'destination',
    headerName: 'Destination',
    disableColumnMenu: true,
    sortable: false,
    flex: 0.25,
    renderCell: ({ row }) => (
      <CaptionedValueCell
        mainContent={row.destination}
        captionContent={
          row.destination_date ? (
            formatter.date(new Date(row.destination_date), dateFormats.longDate)
          ) : (
            <></>
          )
        }
        spacing={0}
      />
    ),
  },
  {
    field: 'created_at',
    headerName: 'Date',
    disableColumnMenu: true,
    sortable: false,
    valueFormatter: ({ value }) =>
      value ? formatter.date(new Date(value), dateFormats.shortDate) : noValueText,
  },
  {
    field: 'captured_price',
    headerName: 'Negotiated Price',
    disableColumnMenu: true,
    sortable: false,
    width: OUTER_COLUMN_WIDTHS.NEGOTIATED_PRICE,
    renderCell: ({ row: { captured_price } }) =>
      captured_price === null ? <NotAvailableCell /> : formatter.currency(captured_price),
  },
  {
    field: 'status',
    headerName: 'Status',
    disableColumnMenu: true,
    sortable: false,
    width: OUTER_COLUMN_WIDTHS.STATUS,
    renderCell: ({ row }) => (
      <SpotLoadUnitStatusCell unit={row} supplierExternalId={supplierExternalId} />
    ),
  },
]
