import { PropsWithChildren } from 'react'
import { Box, styled, Typography } from '@mui/material'
import { basicFont } from './NegotiationDetailsView'

interface Props extends PropsWithChildren {
  label: string
  secondaryLabel?: string
  size?: 'SMALL' | 'MEDIUM' | 'LARGE'
}

export const DataCell = ({ children, label, secondaryLabel, size = 'SMALL' }: Props) => {
  const getValueWrapperStyles = () => {
    if (size === 'LARGE') {
      return { fontSize: '2rem', fontWeight: 600, lineHeight: '100%' }
    } else if (size === 'MEDIUM') {
      return { fontSize: '1.25rem', fontWeight: 600, lineHeight: '100%' }
    } else {
      return basicFont
    }
  }

  return (
    <Box mt={2}>
      <Typography variant='body1' sx={{ ...basicFont, color: 'text.secondary', mb: 0.75 }}>
        {label}
      </Typography>
      <ValueWrapper variant='body1' {...getValueWrapperStyles()}>
        {children}
      </ValueWrapper>
      {secondaryLabel && (
        <Typography variant='body1' sx={{ ...basicFont, color: 'text.secondary' }}>
          {secondaryLabel}
        </Typography>
      )}
    </Box>
  )
}

interface ValueWrapperProps {
  fontSize: string
  fontWeight: number
  lineHeight: string
}

const ValueWrapper = styled(Typography, {
  shouldForwardProp: (prop) =>
    prop !== 'fontSize' && prop !== 'fontWeight' && prop !== 'lineHeight',
})<ValueWrapperProps>(({ fontSize, fontWeight, lineHeight }) => ({
  fontSize,
  fontWeight,
  lineHeight,
}))
