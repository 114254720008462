import React from 'react'
import { PageConfig } from './types'
import { Stack, Typography } from '@mui/material'
import { ChangeFormsTable } from './ChangeFormsTable'
import { PurchasePriceChangeFormListItem } from '@common/dto'

interface Props {
  pageConfig: PageConfig
  isLoading: boolean
  items: PurchasePriceChangeFormListItem[]
  children?: React.ReactNode
}

export const ChangeFormContent = ({
  pageConfig: { itemNavigateTo, newNegotiationPrepId, listTitle },
  items,
  isLoading,
  children,
}: Props): JSX.Element => (
  <Stack px={6} py={4} spacing={3} width='100%'>
    {children}
    <Typography variant='h6' fontWeight={600}>
      {listTitle}
    </Typography>
    <ChangeFormsTable
      isLoading={isLoading}
      items={items}
      itemNavigateTo={itemNavigateTo}
      newNegotiationPrepId={newNegotiationPrepId}
    />
  </Stack>
)
