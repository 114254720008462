import { Tooltip } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import React from 'react'

interface Props {
  content: string | number
  colDef: GridRenderCellParams['colDef']
  children?: JSX.Element
}

export const TooltipCell = ({ content, colDef, children }: Props) => {
  if (colDef.computedWidth > 105 && String(content).length < 17) {
    return children ? children : <>{content}</>
  }

  return <Tooltip title={content}>{children ? children : <span>{content}</span>}</Tooltip>
}
