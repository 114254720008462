import { NegotiationPreparationWithInputs } from '@common/dto'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Alert, Box, IconButton, Stack, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { Outlet, generatePath, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { LoadingPage, NoRoutePage } from 'src/main/pages/index'
import { ActionMenu } from 'src/merchandising/pages/ChangeRequests/NegotiationPreparation/ActionMenu'
import { FileReUploadDialog } from 'src/merchandising/pages/ChangeRequests/NegotiationPreparation/FileReUploadDialog'
import { StatusChangeDialog } from 'src/merchandising/pages/ChangeRequests/NegotiationPreparation/StatusChangeDialog'
import {
  isBackendApiErrorResponse,
  useDeleteNegotiationPreparationMutation,
  useGetNegotiationPreparationQuery,
} from 'src/merchandising/store/'
import {
  NegotiationPreparationSubmittedAlert,
  NotAnEditorAlert,
} from 'src/shared/components/Alerts'

import { PreparationStatus } from '@common/types'
import { useUserPermissions } from '@hooks'
import { CoreBackendPermission } from '@pactum/core-backend-types'
import { MerchandisingRoutes } from 'src/merchandising/routes'
import { useOrgTag } from '../../../../main/hooks/useOrgTag'
import { EditorChangeDialog } from './EditorChangeDialog'

type ContextType = { data: NegotiationPreparationWithInputs }
export const useNegotiationPreparationData = () => useOutletContext<ContextType>()

export const NegotiationPreparationPage = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const orgTag = useOrgTag()
  const { negotiationPreparationId } = useParams<{ negotiationPreparationId: string }>()
  const {
    data: negotiationPreparationData,
    isLoading,
    isUninitialized,
  } = useGetNegotiationPreparationQuery(negotiationPreparationId!, {
    skip: !negotiationPreparationId,
  })
  const [deleteNegotiationPreparation] = useDeleteNegotiationPreparationMutation()

  const [showActionMenu, setShowActionMenu] = useState(false)
  const [showStatusChangeDialog, setShowStatusChangeDialog] = useState(false)
  const [showEditorChangeDialog, setShowEditorChangeDialog] = useState(false)
  const [showReUploadDialog, setShowReUploadDialog] = useState(false)

  const { userPermissionsInActiveOrg } = useUserPermissions()

  useEffect(() => {
    const checkSetPermission = async () => {
      setShowActionMenu(userPermissionsInActiveOrg.has(CoreBackendPermission.AUTH_ADMIN))
    }

    checkSetPermission()
  }, [userPermissionsInActiveOrg])

  const deleteNegotiationPrep = async () => {
    if (negotiationPreparationId) {
      try {
        await deleteNegotiationPreparation(negotiationPreparationId).unwrap()
        enqueueSnackbar('Negotiation preparation was successfully deleted', { variant: 'success' })
        navigate(
          generatePath(MerchandisingRoutes.CHANGE_REQUESTS_LIST, {
            orgTag,
          }),
        )
      } catch (e) {
        enqueueSnackbar(
          isBackendApiErrorResponse(e)
            ? e.data.message
            : 'There was an error during negotiation deletion',
          { variant: 'error' },
        )
      }
    }
  }

  if (isUninitialized || isLoading) {
    return <LoadingPage />
  }

  if (!negotiationPreparationData) {
    return <NoRoutePage />
  }

  if (!negotiationPreparationId) {
    return (
      <Alert severity='error'>
        We were unable to get negotiation preparation ID. Probably you followed the broken link.
      </Alert>
    )
  }

  return (
    <>
      <Box px={6}>
        {negotiationPreparationData.status === PreparationStatus.SUBMITTED && (
          <Box pt={3}>
            <NegotiationPreparationSubmittedAlert />
          </Box>
        )}
        {negotiationPreparationData.status !== PreparationStatus.SUBMITTED &&
          !negotiationPreparationData.isEditingAllowed && (
            <Box pt={3}>
              <NotAnEditorAlert />
            </Box>
          )}
      </Box>
      <Stack direction='row' alignItems='center' pt={4} pl={1.5} pr={6}>
        <Box position='relative' right={(theme) => theme.spacing(0.75)}>
          <IconButton
            onClick={() => {
              navigate(
                generatePath(MerchandisingRoutes.CHANGE_REQUESTS_LIST, {
                  orgTag,
                }),
              )
            }}
          >
            <ArrowBackIcon fontSize='small' sx={{ color: (theme) => theme.palette.common.black }} />
          </IconButton>
        </Box>
        <Typography variant='h6' fontWeight={600}>
          {negotiationPreparationData.purchasePriceChangeForm.supplierName}
        </Typography>
        {showActionMenu && (
          <Box ml='auto'>
            <ActionMenu
              onStatusAndDeadlineChangeClick={() => setShowStatusChangeDialog(true)}
              onChangeEditorClick={() => setShowEditorChangeDialog(true)}
              onReUploadClick={() => setShowReUploadDialog(true)}
              onDeletePreparationClick={deleteNegotiationPrep}
            />
          </Box>
        )}
      </Stack>
      <Outlet context={{ data: negotiationPreparationData }} />
      <StatusChangeDialog
        isOpen={showStatusChangeDialog}
        data={negotiationPreparationData}
        onStatusChanged={() => setShowStatusChangeDialog(false)}
        onClose={() => setShowStatusChangeDialog(false)}
      />
      <EditorChangeDialog
        isOpen={showEditorChangeDialog}
        data={negotiationPreparationData}
        onEditorChanged={() => setShowEditorChangeDialog(false)}
        onClose={() => setShowEditorChangeDialog(false)}
      />
      <FileReUploadDialog
        isOpen={showReUploadDialog}
        ppFormId={negotiationPreparationData.purchasePriceChangeForm.id}
        negotiationPreparationId={negotiationPreparationId}
        onFileReUploaded={() => setShowReUploadDialog(false)}
        onClose={() => setShowReUploadDialog(false)}
      />
    </>
  )
}
