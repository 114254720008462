import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import { useProjects } from '@hooks'
import { ProductType } from '@pactum/core-backend-types/dist/core-backend-api/project/project'
import { ProjectListEntity } from '@pactum/core-backend-types'
import { AppEnv, AppRegion } from '../../../main/types/environment'

interface Props {
  startDate: Date
  endDate: Date
  text: string
  productTypesForScheduledMaintenance?: ProductType[]
  region?: AppRegion
  env?: AppEnv
}

export const ScheduledMaintenanceBanner = ({
  text,
  startDate,
  endDate,
  productTypesForScheduledMaintenance,
  region,
  env: propsEnv,
}: Props) => {
  const { projects } = useProjects()

  if (hideBannerBasedOnDateInterval(startDate, endDate)) {
    return null
  }

  if (hideBannerIfNotRelevantProductType(projects, productTypesForScheduledMaintenance)) {
    return null
  }

  const env = import.meta.env
  if (region && env.VITE_REACT_APP_REGION !== region) {
    return null
  }

  if (propsEnv && env.VITE_REACT_APP_ENV !== propsEnv) {
    return null
  }

  return (
    <Box
      sx={() => ({
        backgroundColor: 'rgb(255, 191, 0)', // amber orange color #FFBF00
        position: 'sticky',
        top: 0,
        padding: '5px',
        zIndex: '-1',
      })}
    >
      <Container sx={{ px: 6, height: '100%' }} maxWidth='xl' disableGutters>
        <Typography variant='h3' fontWeight={500}>
          {text}
        </Typography>
      </Container>
    </Box>
  )
}

export function hideBannerIfNotRelevantProductType(
  projects: ProjectListEntity[],
  productTypesForScheduledMaintenance?: ProductType[],
): boolean {
  if (!productTypesForScheduledMaintenance) {
    return false
  }

  const productTypes = projects
    .map((project) => project.productType)
    .filter((productType): productType is ProductType => !!productType)
  return !productTypes.find((productType) =>
    productTypesForScheduledMaintenance.includes(productType),
  )
}

export function hideBannerBasedOnDateInterval(startDate: Date, endDate: Date): boolean {
  const now = new Date()
  return now < startDate || now > endDate
}
