export const formatDurationMinutes = (durationMinutes: number | null): string => {
  if (!durationMinutes) {
    return ''
  }
  const duration = convertMinutesToDuration(durationMinutes)
  return formatDurationAsString(duration)
}

interface Duration {
  weeks: number
  days: number
  hours: number
  minutes: number
  seconds: number
}

const convertMinutesToDuration = (durationMinutes: number): Duration => {
  const minutesInWeek = 7 * 24 * 60
  const minutesInDay = 24 * 60
  const minutesInHour = 60

  const weeks = Math.floor(durationMinutes / minutesInWeek)
  const remainingMinutesAfterWeeks = durationMinutes % minutesInWeek

  const days = Math.floor(remainingMinutesAfterWeeks / minutesInDay)
  const remainingMinutesAfterDays = remainingMinutesAfterWeeks % minutesInDay

  const hours = Math.floor(remainingMinutesAfterDays / minutesInHour)

  const remainingMinutes = remainingMinutesAfterDays % minutesInHour
  const seconds = Math.round((remainingMinutes - Math.floor(remainingMinutes)) * 60)

  return {
    weeks,
    days,
    hours,
    minutes: Math.floor(remainingMinutes),
    seconds,
  }
}

const formatDurationAsString = ({ weeks, days, hours, minutes, seconds }: Duration): string => {
  const parts: string[] = []

  if (weeks > 0) {
    parts.push(`${weeks} week${weeks > 1 ? 's' : ''}`)
  }

  if (days > 0) {
    parts.push(`${days} day${days > 1 ? 's' : ''}`)
  }

  if (hours > 0) {
    parts.push(`${hours} hour${hours > 1 ? 's' : ''}`)
  }

  if (minutes > 0) {
    parts.push(`${minutes} minute${minutes > 1 ? 's' : ''}`)
  }

  if (seconds > 0) {
    parts.push(`${seconds} second${seconds > 1 ? 's' : ''}`)
  }

  if (parts.length <= 1) {
    return parts.join('')
  }

  const lastPart = parts.pop()
  return `${parts.join(', ')} and ${lastPart}`
}
