import { ConfirmationModal } from '@components/ConfirmationModal'
import { User } from '@common/types/suite/User'

interface Props {
  open: boolean
  user: User
  onCancel: () => void
  onSubmit: () => void
}

export const RemoveUserModal = ({ open, onCancel, onSubmit, user }: Props) => {
  const submit = async () => {
    onSubmit()
  }

  return (
    <ConfirmationModal
      confirmType='negative'
      open={open}
      onCancel={onCancel}
      onSubmit={submit}
      title='Remove user access?'
      content={`User "${user.name} <${user.email}>" will no longer have access to the Pactum Negotiation Suite`}
      confirmButtonText='Remove'
    />
  )
}
