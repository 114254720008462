const ROW_X_PADDING = 10
export const OUTER_ACTIONS_WIDTH = 144
export const OUTER_COLUMN_WIDTHS = {
  ACTIONS: OUTER_ACTIONS_WIDTH + 2 * ROW_X_PADDING,
  STATUS: 96 + 2 * ROW_X_PADDING,
  NEGOTIATED_PRICE: 96 + 2 * ROW_X_PADDING,
} as const
export const SCROLLBAR_SPACE = 16

export const INNER_ACTIONS_WIDTH = 96
export const INNER_COLUMN_WIDTHS = {
  ACTIONS: INNER_ACTIONS_WIDTH + 2 * ROW_X_PADDING,
  STATUS: 128 + 2 * ROW_X_PADDING,
  NEGOTIATION_LINK: 128 + 2 * ROW_X_PADDING,
  NEGOTIATED_PRICE: OUTER_COLUMN_WIDTHS.NEGOTIATED_PRICE,
} as const
