import { TextField } from '@mui/material'

export const FeedbackReasonText = ({ label, value }: { label: string; value: string }) => (
  <TextField
    disabled
    multiline
    label={label}
    value={value}
    sx={{
      '& .MuiInputBase-input.Mui-disabled': {
        color: (theme) => theme.palette.text.primary,
        '-webkit-text-fill-color': (theme) => theme.palette.text.primary,
        cursor: 'text',
      },
      '& .MuiInputLabel-root.Mui-disabled': {
        color: (theme) => theme.palette.text.primary,
      },
    }}
  />
)
