import { Box, Theme, Typography, useTheme } from '@mui/material'
import { Chart } from 'react-chartjs-2'
import { ChartOptions, TooltipItem } from 'chart.js'
import { ArticleHistoricalData, MonthlyChartData } from '@common/types'
import { useFormatter } from '@shared/hooks'
import { dateFormats, Formatter } from '@utils'

const getChartOptions = (theme: Theme, formatter: (value: number) => string) =>
  ({
    scales: {
      yAxes: {
        position: 'right',
        ticks: {
          callback: (value) => formatter(value as number),
        },
      },
    },
    plugins: {
      tooltip: {
        titleColor: theme.palette.common.black,
        bodyColor: theme.palette.common.black,
        titleFont: {
          weight: 'normal',
        },
        bodyFont: {
          weight: 'bold',
        },
        backgroundColor: theme.palette.common.white,
        displayColors: false,
        borderColor: theme.palette.grey[400],
        borderWidth: 1,
        yAlign: 'bottom',
        callbacks: {
          label: (tooltipItem: TooltipItem<'line'>) => `${formatter(tooltipItem.raw as number)}`,
        },
      },
      legend: {
        position: 'bottom',
        align: 'start',
        labels: {
          usePointStyle: true,
        },
      },
      datalabels: {
        display: false,
      },
    },
  }) as ChartOptions

const getLabels = (data: MonthlyChartData[], formatter: Formatter) =>
  data.map((datapoint) =>
    formatter.date(new Date(datapoint.month), dateFormats.monthAndYear, { slashSeparators: true }),
  )

interface Props {
  historicalData?: ArticleHistoricalData
}

export const HistoricalData = ({ historicalData }: Props) => {
  const formatter = useFormatter()
  const theme = useTheme()
  const billingLabels = getLabels(Object.values(historicalData?.billingMargin ?? {}), formatter)
  const purchaseAndSalesLabels = getLabels(
    Object.values(historicalData?.retailPrice ?? {}),
    formatter,
  )

  const billingDatasets = [
    {
      type: 'line' as const,
      data: historicalData?.billingMargin?.map((datapoint) => datapoint.value) ?? [],
      backgroundColor: theme.palette.common.charts.first,
      borderColor: theme.palette.common.charts.first,
      label: 'Margin',
    },
  ]

  const purchaseAndSalesDatasets = [
    {
      type: 'line' as const,
      data: historicalData?.retailPrice?.map((datapoint) => datapoint.value) ?? [],
      backgroundColor: theme.palette.common.charts.third,
      borderColor: theme.palette.common.charts.third,
      label: 'Sales price',
    },
    {
      type: 'line' as const,
      data: historicalData?.rpp?.map((datapoint) => datapoint.value) ?? [],
      backgroundColor: theme.palette.common.charts.first,
      borderColor: theme.palette.common.charts.first,
      label: 'Purchase price',
    },
  ]

  return (
    <>
      <Box mt={4} mb={2} width='100%'>
        <Typography variant='body2' fontWeight={700}>
          Purchase and sales price over time
        </Typography>
      </Box>
      <Chart
        type='line'
        data={{
          labels: purchaseAndSalesLabels,
          datasets: purchaseAndSalesDatasets,
        }}
        options={getChartOptions(theme, formatter.currency)}
      />
      <Box mt={4} mb={2} width='100%'>
        <Typography variant='body2' fontWeight={700}>
          Billing margin
        </Typography>
      </Box>
      <Chart
        type='line'
        data={{
          labels: billingLabels,
          datasets: billingDatasets,
        }}
        options={getChartOptions(theme, formatter.percent0To1)}
      />
    </>
  )
}
