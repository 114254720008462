import { OrganizationMemberProjectsAccess } from '@pactum/core-backend-types'

export const UserRoleDisplayName = {
  admin: 'Admin',
  user: 'User',
  viewer: 'Viewer',
} as const

export type UserRole = keyof typeof UserRoleDisplayName

export const roleDisplayNames: readonly string[] = Object.values(UserRoleDisplayName)

export const isUserRole = (role: string): role is UserRole => {
  return Object.keys(UserRoleDisplayName).includes(role)
}

export const getRoleFromDisplayName = (displayName: string): UserRole | null => {
  const entries = Object.entries(UserRoleDisplayName)
  for (const [key, value] of entries) {
    if (value === displayName) {
      return key as UserRole
    }
  }
  return null
}

export const getDisplayNameFromRole = (role: UserRole): string | null => {
  return UserRoleDisplayName[role] ?? null
}

export const UserStatusDisplayName = {
  invited: 'Invited',
  expired: 'Expired',
  active: 'Active',
} as const

export type UserStatus = keyof typeof UserStatusDisplayName

export interface User {
  userId: number
  email: string
  name: string
  projectIdsAccessList: OrganizationMemberProjectsAccess
  role: UserRole
  status: UserStatus
}

export interface UserDetails extends User {
  federatedIdentity: boolean
}

export interface CreateUserDto {
  email: string
  name: string
  projectIdsAccessList: OrganizationMemberProjectsAccess
  role: UserRole
}

export type UpdateUserDto = {
  userId: number
  name?: string
  projectIdsAccessList: OrganizationMemberProjectsAccess
  role: UserRole
}
