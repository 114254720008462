import { CompetitorsPrices } from '@common/types'
import { ascendingOrderBy, descendingOrder } from '@common/utils'
import { Box, Theme, Typography, useTheme } from '@mui/material'
import { useFormatter } from '@shared/hooks'
import { Formatter, capitalizeFirstLetter, dateFormats } from '@utils'
import { useCallback, useMemo } from 'react'
import { getPriceFor } from 'src/merchandising/pages/ChangeRequests/NegotiationPreparation/Adjustments/PricingInsights/utils'
import { DynamicMultilineRow } from 'src/shared/components/DynamicMultilineRow'
import {
  CellColor,
  MultilineDataCellData,
} from 'src/shared/components/DynamicMultilineRow/MultilineCell'
import { DenseButton } from 'src/shared/components/PactumButton'
import { PricesWithPrevious } from './types'

const notAvailable = 'N/A'

interface Props {
  prices: CompetitorsPrices[]
  onUseCompetitorPrice: (value: number) => void
  isEditingDisabled: boolean
}

export const CompetitorSalesPriceBenchmark = ({
  prices,
  onUseCompetitorPrice,
  isEditingDisabled,
}: Props) => {
  const formatter = useFormatter()
  const theme = useTheme()

  const getCompetitorPriceCellsWithPrevious = useCallback(
    (
      competitorPrices: PricesWithPrevious[],
      latestBaseline: PricesWithPrevious | undefined,
      hidePreviousValue: boolean,
    ): MultilineDataCellData[] =>
      competitorPrices.map((competitor) => ({
        title: `${capitalizeFirstLetter(competitor.name)} (% of us)`,
        primaryValue: `${formatter.currency(competitor.price)}${
          latestBaseline?.price
            ? getPercentageOf(formatter, competitor.price, latestBaseline.price)
            : notAvailable
        }`,
        secondaryValue: getFormattedPreviousPrice(
          formatter,
          competitor,
          latestBaseline,
          hidePreviousValue,
        ),
        actionComponent: (
          <DenseButton onClick={() => onUseCompetitorPrice(competitor.price)}>
            Use this price
          </DenseButton>
        ),
        options: {
          color: {
            primary: getPriceChangeDirectionColor(competitor, latestBaseline),
          },
          outerStyle: isEditingDisabled
            ? {
                ...getOuterPaddingAndBorderStyle(theme),
              }
            : {
                ...getOuterPaddingAndBorderStyle(theme),
                '&:hover': {
                  border: `${theme.spacing(0.25)} solid ${theme.palette.accent.light}`,
                  borderRadius:
                    typeof theme.shape.borderRadius === 'number'
                      ? theme.shape.borderRadius / 4
                      : theme.shape.borderRadius,
                },
              },
          showActionComponent: !isEditingDisabled && !!competitor.price,
        },
      })),
    [formatter, isEditingDisabled, onUseCompetitorPrice, theme],
  )

  const getUpdatedDatesCell = useCallback(
    (
      latestDate: Date,
      previousToLatestDate: Date | undefined,
      showPreviousToLatestDate: boolean,
    ) => {
      const shownPreviousLatestDate =
        previousToLatestDate && !showPreviousToLatestDate
          ? formatter.date(new Date(previousToLatestDate), dateFormats.shortDate, {
              slashSeparators: true,
            })
          : undefined
      return {
        title: 'Updated',
        primaryValue: formatter.date(new Date(latestDate), dateFormats.shortDate, {
          slashSeparators: true,
        }),
        secondaryValue: shownPreviousLatestDate,
        options: {
          outerStyle: getOuterPaddingAndBorderStyle(theme),
        },
      }
    },
    [formatter, theme],
  )

  const getSalesBenchmarkCells = useCallback(
    (prices: Props['prices']): MultilineDataCellData[] | undefined => {
      const pricesSortedByDateDesc: CompetitorsPrices[] = getPricesSortedByDateDesc(prices)
      const latestPrices = pricesSortedByDateDesc[0]

      if (!latestPrices || latestPrices.competitors.length === 0) {
        return undefined
      }

      const previousToLatestPrices = pricesSortedByDateDesc[1]

      const latestCompetitorPricesWithPrevious: PricesWithPrevious[] = latestPrices.competitors
        .map((latestPrice) => ({
          ...latestPrice,
          previousPrice: getPriceFor(latestPrice.name, previousToLatestPrices?.competitors),
        }))
        .sort(ascendingOrderBy('priority'))

      const hidePreviousDateAndValues = isNoCompetitorWithPreviousPrice(
        latestCompetitorPricesWithPrevious,
      )

      const latestBaselineWithPrevious = {
        ...latestPrices.baseline,
        previousPrice: previousToLatestPrices?.baseline.price,
      }

      return [
        ...getCompetitorPriceCellsWithPrevious(
          latestCompetitorPricesWithPrevious,
          latestBaselineWithPrevious,
          hidePreviousDateAndValues,
        ),
        getUpdatedDatesCell(
          latestPrices.date,
          previousToLatestPrices?.date,
          hidePreviousDateAndValues,
        ),
      ]
    },
    [getCompetitorPriceCellsWithPrevious, getUpdatedDatesCell],
  )

  const salesPriceBenchmarkCells = useMemo(
    () => getSalesBenchmarkCells(prices),
    [getSalesBenchmarkCells, prices],
  )

  if (!salesPriceBenchmarkCells) {
    return null
  }

  return (
    <Box>
      <Typography variant='h6' mb={1}>
        Sales Price Benchmark
      </Typography>
      <DynamicMultilineRow data={salesPriceBenchmarkCells} />
    </Box>
  )
}

const getPricesSortedByDateDesc = (competitorPrices: Props['prices']): Props['prices'] =>
  competitorPrices.slice().sort(descendingOrder((price) => new Date(price.date).getTime()))

const getFormattedPreviousPrice = (
  formatter: Formatter,
  competitor: PricesWithPrevious,
  latestBaseline: PricesWithPrevious | undefined,
  hidePreviousValue: boolean,
): string | undefined => {
  if (hidePreviousValue) {
    return undefined
  }
  if (competitor.previousPrice) {
    const percentageOfUs = latestBaseline?.previousPrice
      ? getPercentageOf(formatter, competitor.previousPrice, latestBaseline.previousPrice)
      : ''
    return `${formatter.currency(competitor.previousPrice)}${percentageOfUs}`
  }
  return notAvailable
}

const getOuterPaddingAndBorderStyle = (theme: Theme) => ({
  padding: theme.spacing(1),
  paddingBottom: theme.spacing(1.5),
  border: `${theme.spacing(0.25)} solid ${theme.palette.common.white}`,
})

const isNoCompetitorWithPreviousPrice = (prices: PricesWithPrevious[]) => {
  return !prices.some((price) => !!price.previousPrice)
}

const getPriceChangeDirectionColor = (
  competitor: PricesWithPrevious,
  baseline?: PricesWithPrevious,
): CellColor | undefined => {
  if (!competitor.previousPrice || !baseline || !baseline.price || !baseline.previousPrice) {
    return undefined
  }
  if (competitor.price === baseline.price && competitor.previousPrice === baseline.previousPrice) {
    return undefined
  }
  if (competitor.price / baseline.price > competitor.previousPrice / baseline.previousPrice) {
    return 'red'
  }
  return 'green'
}

const getPercentageOf = (formatter: Formatter, competitorPrice: number, baselinePrice: number) =>
  ' (' + formatter.percent0To1(competitorPrice / baselinePrice) + ')'
