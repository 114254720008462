import { PaymentDayObject } from '../store/types'
import { dateFormats, Formatter } from '@utils'
import { parseIsoDateLocalTime } from './date'
import { PaymentTerms } from '../store/types'

export const formatDeliveryDate = (date: string, formatter: Formatter): string =>
  formatter.date(parseIsoDateLocalTime(date), dateFormats.longDate)

export const formatContractDate = (date: string, formatter: Formatter): string =>
  formatter.date(parseIsoDateLocalTime(date), dateFormats.longDate)

export const formatInvoiceDate = (date: string, formatter: Formatter): string =>
  formatter.date(parseIsoDateLocalTime(date), dateFormats.longDate)

export const formatDate = (date: string, formatter: Formatter): string =>
  formatter.date(new Date(date), dateFormats.longDate)

export const formatDeadlineDate = (
  date: string,
  daysUntilDeadline: number | null,
  formatter: Formatter,
): string => {
  if (date) {
    return formatDate(date, formatter)
  }

  return `${daysUntilDeadline} business ${daysUntilDeadline! > 1 ? 'days' : 'day'} from the start`
}

export const formatPercent = (value: number, formatter: Formatter): string | null => {
  if (typeof value !== 'number' || Number.isNaN(value)) {
    return null
  }

  return formatter.percent0To1(value)
}

export const formatPaymentTerms = (terms: PaymentTerms): string | null => {
  if (!terms.paymentDaysObject) {
    return '-'
  }

  const paymentDaysString = formatPaymentDaysObject(terms.paymentDaysObject)

  if (!terms.discount || !terms.discountDaysObject) {
    return paymentDaysString
  }

  const discountPercentage = Number((terms.discount * 100).toFixed(1))
  return `${discountPercentage}/${terms.discountDaysObject.netDays} ${paymentDaysString}`
}

const formatPaymentDaysObject = (pdo: PaymentDayObject): string => {
  switch (pdo.type) {
    case 'net':
      return `NET ${pdo.netDays}`
    case 'eom':
      return `${pdo.netDays} EOM ${pdo.eomDays}`
    case 'eow':
      return `${pdo.netDays} EOW ${pdo.eowDays}`
    default:
      return ''
  }
}
