import styled from '@emotion/styled'
import { useProjects } from '@hooks'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { ReactNode, useState } from 'react'
import { Outlet } from 'react-router-dom'
import {
  useFetchTermsAndConditionsDocumentQuery,
  useSaveUserConsentMutation,
} from 'src/main/store/projects'

interface TermsAndConditionsProps {
  activeOrgTag: string
}

interface DialogProps {
  children: ReactNode
  title: string
  open: boolean
  onClose: (value: boolean) => void
}

const TocIframe = styled.iframe`
  width: 100%;
  height: 500px;
  border: none;
`

const DeclineButton = styled.button`
  width: 176px;
  background: #fff;
  border: none;
  padding: 12px 8px;
  color: #0f3a4f;
  font-weight: bold;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  margin-right: 16px;

  &:hover {
    background: #f0f0f0;
  }

  &:active {
    background: #e5e5e5;
  }
`

const AcceptButton = styled.button`
  width: 176px;
  background: #0f3a4f;
  border: none;
  padding: 12px 8px;
  color: #fff;
  font-weight: bold;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    background: #264d60;
  }

  &:active {
    background: #0c2e3f;
  }
`

const Actions = styled.div`
  margin: auto;
`

const TermsAndConditionsDialog = ({ title, open, onClose, children }: DialogProps) => {
  const handleDecline = () => onClose(false)

  const handleAccept = () => onClose(true)

  return (
    <Dialog
      open={open}
      slotProps={{
        backdrop: { sx: { background: 'rgba(9, 35, 48, 0.5)', backdropFilter: 'blur(7px)' } },
      }}
      maxWidth='md'
      fullWidth
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent
        sx={{
          overflow: 'hidden',
        }}
      >
        {children}
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
        }}
      >
        <Actions>
          <DeclineButton onClick={handleDecline}>Decline</DeclineButton>
          <AcceptButton onClick={handleAccept} autoFocus>
            Accept
          </AcceptButton>
        </Actions>
      </DialogActions>
    </Dialog>
  )
}

export const TermsAndConditions = ({ activeOrgTag }: TermsAndConditionsProps) => {
  const [show, toggle] = useState(true)

  const fetchTermsAndConditionsFile = useFetchTermsAndConditionsDocumentQuery(activeOrgTag ?? '')

  const [sentConsentStatus] = useSaveUserConsentMutation()

  const handleChoice = (state: boolean) =>
    sentConsentStatus({
      consentStatus: state,
      termsAndConditionsChecksum: fetchTermsAndConditionsFile.data?.checkSum ?? '',
    }).then(() => {
      if (!state) {
        window.location.href = 'https://pactum.com'
      } else {
        toggle(false)
      }
    })

  if (fetchTermsAndConditionsFile.isLoading) {
    return null
  }

  return (
    <>
      {fetchTermsAndConditionsFile?.data?.fileContent &&
      fetchTermsAndConditionsFile?.data?.checkSum ? (
        <>
          {show ? (
            <TermsAndConditionsDialog
              title='Please review and accept'
              open={show}
              onClose={handleChoice}
            >
              <TocIframe
                title='Please review and accept'
                src={
                  fetchTermsAndConditionsFile?.data?.fileContent +
                  '#toolbar=0&navpanes=0&scrollbar=0'
                }
              />
            </TermsAndConditionsDialog>
          ) : (
            <Outlet />
          )}
        </>
      ) : (
        <Outlet />
      )}
    </>
  )
}

export const TermsAndConditionsWrapper = () => {
  const { activeOrgTag } = useProjects()

  return activeOrgTag ? <TermsAndConditions activeOrgTag={activeOrgTag} /> : <></>
}
