import { useEffect, useMemo, useRef, useState } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { ZodError } from 'zod'
import cloneDeep from 'lodash.clonedeep'
import unset from 'lodash.unset'
import { ProductType } from '@pactum/core-backend-types'
import { FormDialog, FormDialogButtonConfig, FormDialogProps } from '@components/FormDialog'
import { NegotiationEvent } from '@procurement/store/types'
import { labelForProduct } from '@procurement/utils/labelForProduct'
import { capitalizeFirstLetter } from '@utils'
import { useGetConfigurationQuery } from '@procurement/store/purchasing'
import { isBackendApiErrorResponse } from '@shared/backend/error/typeGuards'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useNegotiationEventMutations } from '@procurement/components/NegotiationEventForm/hooks/useNegotiationEventMutations'
import { NegotiationEventForm } from '@procurement/components/NegotiationEventForm/NegotiationEventForm'
import { createRequiredFieldsValidationErrorCallback } from '@procurement/components/NegotiationEventForm/validation'
import { useNegotiationEventForm } from './NegotiationEventFormContext'
import { mapNegotiationEventToFormData } from './mappers'
import { NegotiationEventFormData, negotiationEventFormDataSchema } from './schema'

interface Props {
  negotiationEvent?: NegotiationEvent | null
  onClose: () => void
  open: boolean
}

export const NegotiationEventFormDialog = ({ open, negotiationEvent, onClose }: Props) => {
  const { activeProjectTag: projectTag } = useActiveProject()
  const { data: configuration } = useGetConfigurationQuery({ projectTag })
  const { requiredFields, visibleFields } = useNegotiationEventForm()

  const [loading, setLoading] = useState(false)
  const [generalFormError, setGeneralFormError] = useState('')

  const errorRef = useRef<HTMLDivElement>(null)

  const suiteConfig = configuration?.data.suite
  const productType = suiteConfig?.productType as ProductType

  const { createNegotiationEvent, updateNegotiationEvent } = useNegotiationEventMutations()

  const defaultValues: NegotiationEventFormData = useMemo(() => {
    try {
      const formData = mapNegotiationEventToFormData({
        negotiationEvent: negotiationEvent ?? undefined,
        suiteConfig,
      })
      return negotiationEventFormDataSchema.parse(formData)
    } catch (err) {
      if (err instanceof ZodError) {
        const eventWithoutBadFields = cloneDeep(negotiationEvent)

        err.issues.forEach((issue) => {
          unset(eventWithoutBadFields, issue.path.join('.'))
        })

        const formData = mapNegotiationEventToFormData({
          negotiationEvent: eventWithoutBadFields ?? undefined,
          suiteConfig,
        })
        return negotiationEventFormDataSchema.parse(formData)
      }

      const formData = mapNegotiationEventToFormData({ suiteConfig })
      return negotiationEventFormDataSchema.parse(formData)
    }
  }, [negotiationEvent, suiteConfig])

  const schema = negotiationEventFormDataSchema.refine(
    createRequiredFieldsValidationErrorCallback(requiredFields, visibleFields, productType),
  )

  const form = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  })

  const formSubmitButtons: FormDialogButtonConfig[] = [
    { type: 'cancel', label: 'Cancel' },
    {
      type: 'submit',
      label: negotiationEvent
        ? 'Save'
        : labelForProduct(productType, 'negotiationEventFormSubmitButtonText'),
    },
  ]

  const formTitle = labelForProduct(
    productType,
    negotiationEvent ? 'negotiationEventFormEditTitle' : 'negotiationEventFormNewTitle',
  )

  useEffect(() => {
    form.reset(defaultValues)
  }, [defaultValues, form])

  useEffect(() => {
    if (generalFormError) {
      errorRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [generalFormError, errorRef])

  const handleFormErrors = (error: unknown, form: UseFormReturn<NegotiationEventFormData>) => {
    if (isBackendApiErrorResponse(error)) {
      if (Array.isArray(error.data.message)) {
        const formErrors: string[] = error.data.message

        formErrors.forEach((error, index) => {
          const [field, ...messageParts] = error.split(' ')
          const message = capitalizeFirstLetter(messageParts.join(' '))

          form.setError(
            field as keyof NegotiationEventFormData,
            { message },
            { shouldFocus: index === 0 },
          )
        })
      } else {
        setGeneralFormError(error.data.message)
      }
    } else if (error instanceof Error) {
      setGeneralFormError(error.message)
    } else {
      console.error('Unknown error:', error)
    }
  }

  const onSubmit = async (
    formData: NegotiationEventFormData,
    form: UseFormReturn<NegotiationEventFormData>,
  ) => {
    setLoading(true)

    try {
      if (negotiationEvent) {
        await updateNegotiationEvent({ negotiationEvent, formData })
      } else {
        await createNegotiationEvent({ negotiationEvent: null, formData })
      }
      resetAndClose()
    } catch (error) {
      console.log(error)
      handleFormErrors(error, form)
    } finally {
      setLoading(false)
    }
  }

  const onErrors: FormDialogProps<NegotiationEventFormData>['onErrors'] = (errors) => {
    if (errors) {
      console.error('Form errors:', errors)
    }
  }

  const resetAndClose = () => {
    form.reset()
    setGeneralFormError('')
    onClose()
  }

  return (
    <FormDialog
      buttons={formSubmitButtons}
      error={generalFormError}
      errorRef={errorRef}
      form={form}
      fullWidth
      loading={loading}
      maxWidth='md'
      onCancel={resetAndClose}
      onErrors={onErrors}
      onSubmit={onSubmit}
      open={open}
      title={formTitle}
    >
      <NegotiationEventForm
        suiteConfig={suiteConfig}
        productType={productType}
        isEditing={Boolean(negotiationEvent)}
      />
    </FormDialog>
  )
}
