import { createApi } from '@reduxjs/toolkit/query/react'
import { purchasingApi } from '../../store/purchasing'
import { PurchasingEntryPoint } from './entrypoint'
import { ProductType, ProjectListEntity } from '@pactum/core-backend-types'
import { purchasingNavigationItems, PurchasingRoutes } from './routes'
import { GenericSuiteModule } from '../../../main/types/suite-module'
import { renderProjectAsBaselineModule } from '@shared/projectsSpecific'
import { SuitePermission } from '@common/types'

export const purchasingSuiteModule: GenericSuiteModule = {
  name: 'purchasing',
  api: purchasingApi as unknown as ReturnType<typeof createApi>,
  entrypoint: PurchasingEntryPoint,
  basePath: 'purchasing',
  projectSwitchPath: (userPermissions?: Set<SuitePermission>) =>
    userPermissions?.has(SuitePermission.MODIFY)
      ? PurchasingRoutes.REQUISITION_LIST
      : PurchasingRoutes.DASHBOARD,
  isProjectCompatible: (project: ProjectListEntity) =>
    !renderProjectAsBaselineModule(project) &&
    project.compatibleWithNegotiationSuite &&
    project.productType === ProductType.Purchasing,
  navigationItems: purchasingNavigationItems,
}
