import { NegotiationStatus } from '@campaigns/types'
import { CommercialTermsNegotiation } from '@campaigns/types/negotiation'
import { ACTIONS_BUTTON_COLUMN_WIDTH, ACTIONS_BUTTON_WIDTH } from '@components/ActionsButton'
import {
  hasAnyOfOptionsSelectFilterOperator,
  inDateRangeFilterOperator,
} from '@components/FilterSelect'
import { hasNothingSelectFilterOperator } from '@components/FilterSelect/filterOperators'
import { CaptionedValueCell } from '@components/table'
import { GRID_CHECKBOX_SELECTION_COL_DEF, GridColDef, GridRowModel } from '@mui/x-data-grid-pro'
import { Formatter } from '@utils'
import { parseISO } from 'date-fns'
import { hasAttachments } from 'src/commercial-terms/utils/attachments'
import { statusText } from '../../negotiationStatus'
import { ListItemActions } from '../ListItemActions'
import { SignedIndicators } from '../SignedIndicators'
import { SignersTooltip } from '../SignersTooltip'
import { StatusChip } from '../StatusChip'
import { AttachmentCaption, commonColumnParameters, getImprovementValue } from './helpers'
import { getDiscountsColumns } from './usecases/discounts'
import { getPaymentTermsColumns } from './usecases/paymentTerms'
import { getRebatesColumns } from './usecases/rebates'
import { getGrowthRebatesColumns } from './usecases/growthRebates'
import { getMultiContractColumns } from './usecases/multiContract'

export const createColumns = (
  formatter: Formatter,
  negotiations: CommercialTermsNegotiation[],
): GridColDef<CommercialTermsNegotiation>[] => {
  const contracts = negotiations.flatMap((negotiation) => negotiation.contracts)

  const negotiationsHaveMultipleContracts = negotiations.some(
    (negotiation) => negotiation.contracts.length > 1,
  )

  const negotiationsHaveDiscounts = contracts.some(
    (contract) => contract.standardTerms.generalDiscount?.agreed,
  )
  const negotiationsHaveRebates = contracts.some(
    (contract) => contract.standardTerms.flatRebate?.agreed,
  )

  const negotiationsHaveGrowthRebates = contracts.some(
    (contract) => contract.standardTerms.growthRebateStructure?.agreed,
  )

  const negotiationsHaveBusinessUnits = negotiations.some((negotiation) => negotiation.businessUnit)

  const usecaseColumns: GridColDef<CommercialTermsNegotiation>[] = []

  if (!negotiationsHaveMultipleContracts) {
    usecaseColumns.push(...getPaymentTermsColumns())
  }

  if (negotiationsHaveDiscounts) {
    usecaseColumns.push(...getDiscountsColumns(formatter))
  } else if (negotiationsHaveRebates) {
    usecaseColumns.push(...getRebatesColumns(formatter))
  }

  if (negotiationsHaveGrowthRebates) {
    usecaseColumns.push(...getGrowthRebatesColumns(formatter))
  }

  if (negotiationsHaveMultipleContracts) {
    usecaseColumns.push(...getMultiContractColumns())
  }

  return [
    ...getGeneralColumns(formatter, negotiationsHaveBusinessUnits),
    ...usecaseColumns,
    ...getSavingsColumns(formatter),
    ...statusActionColumns,
  ]
}

const getGeneralColumns = (
  formatter: Formatter,
  hasBusinessUnits: boolean,
): GridColDef<CommercialTermsNegotiation>[] => [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    cellClassName: 'select-cell',
    headerClassName: 'select-header',
    align: 'left',
    field: '__check__',
  },
  {
    ...commonColumnParameters,
    field: 'sentOut',
    headerName: 'Sent out',
    renderCell: ({ value }) => (value ? formatter.dateTimeRelative(parseISO(value), 'PP') : 'N/A'),
    sortComparator: (a: string, b: string) => parseISO(a).getTime() - parseISO(b).getTime(),
    filterOperators: [inDateRangeFilterOperator],
  },
  {
    ...commonColumnParameters,
    field: 'lastStatusChange',
    headerName: 'Last modified',
    renderCell: ({ value }) => (value ? formatter.dateTimeRelative(parseISO(value), 'PP') : ''),
    sortComparator: (a: string, b: string) => parseISO(a).getTime() - parseISO(b).getTime(),
  },
  {
    ...commonColumnParameters,
    field: 'supplierCompanyName',
    headerName: 'Supplier',
    renderCell: ({ row }) => (
      <CaptionedValueCell
        mainContent={row.supplierCompanyName ?? ''}
        captionContent={hasAttachments(row.fileUploads) ? <AttachmentCaption /> : ''}
        spacing={0}
        tooltip={{
          id: row.id.toString(),
          title: row.supplierCompanyName ?? '',
        }}
      />
    ),
  },
  // hidden column used for filtering
  {
    ...commonColumnParameters,
    type: 'boolean',
    field: 'hasAttachments',
    headerName: 'Has attachments',
    flex: 0.5,
    valueGetter: ({ row }) => (row.fileUploads ?? []).length > 0,
  },
  ...(hasBusinessUnits
    ? [
        {
          ...commonColumnParameters,
          field: 'businessUnit',
          headerName: 'Business unit',
          filterOperators: [hasAnyOfOptionsSelectFilterOperator, hasNothingSelectFilterOperator],
        },
      ]
    : []),
  {
    ...commonColumnParameters,
    field: 'pastSpend',
    type: 'number',
    headerName: 'Spend last year',
    sortComparator: (a: number, b: number) => {
      const castA = a ?? 0
      const castB = b ?? 0

      return castA - castB
    },
    renderCell: ({ row }) =>
      row.pastSpend && row.currency
        ? formatter.currency(row.pastSpend, { currency: row.currency })
        : 'N/A',
  },
]

const getSignersColumn = (row: GridRowModel<CommercialTermsNegotiation>) => {
  const { docusignEnvelopeSigners } = row.signatures
  const nrSigned = docusignEnvelopeSigners.filter((signer) => signer.hasSigned).length
  const nrNotSigned = docusignEnvelopeSigners.length - nrSigned

  return (
    <SignersTooltip negotiation={row}>
      <CaptionedValueCell
        mainContent={<SignedIndicators nrSigned={nrSigned} nrNotSigned={nrNotSigned} />}
        captionContent={`${nrSigned}/${docusignEnvelopeSigners.length} signed`}
      />
    </SignersTooltip>
  )
}

const getStatusColumn = (row: GridRowModel<CommercialTermsNegotiation>) => {
  if (row.signatures.docusignEnvelopeSigners.length === 0) {
    return <StatusChip status={row.status} />
  }
  return getSignersColumn(row)
}

const statusActionColumns: GridColDef<CommercialTermsNegotiation>[] = [
  {
    ...commonColumnParameters,
    field: 'status',
    headerName: 'Status',
    valueGetter: ({ row }) => row.status,
    valueFormatter: ({ value }: { value: NegotiationStatus }) => statusText(value),
    renderCell: ({ row }) => getStatusColumn(row),
    filterOperators: [hasAnyOfOptionsSelectFilterOperator],
  },
  {
    ...commonColumnParameters,
    field: '__actions__',
    headerName: 'Actions',
    minWidth: ACTIONS_BUTTON_COLUMN_WIDTH,
    cellClassName: 'actions-cell',
    renderCell: (cell) => (
      <ListItemActions negotiation={cell.row} actionsMenuMinWidth={ACTIONS_BUTTON_WIDTH} />
    ),
  },
]

const getSavingsColumns = (formatter: Formatter): GridColDef<CommercialTermsNegotiation>[] => [
  {
    ...commonColumnParameters,
    field: 'forecastedSavingsInCurrency',
    headerName: 'Savings',
    type: 'number',
    renderCell: ({ row }) => getImprovementValue(row, formatter),
    valueGetter: ({ row }) => row.achievedSavingsInCurrency ?? row.forecastedSavingsInCurrency ?? 0,
    sortComparator: (a: number, b: number) => {
      const castA = a ?? -1
      const castB = b ?? -1

      return castA - castB
    },
  },
]
