import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material'
import { useState } from 'react'
import { Market } from 'src/merchandising/pages/Scenarios/ScenariosList/types'
import { Week } from '@common/types/scenario/ScenarioState'

const weekNames: Record<Week, string> = {
  W1: 'Global Pricing',
  W2: 'Local Pricing',
}

interface Props {
  isOpen: boolean
  markets: Market[]
  onMarketSelected: (id: Market['id'] | null) => void
  onClose: () => void
}

export const MarketSelectionDialog = ({ isOpen, markets, onMarketSelected, onClose }: Props) => {
  const [selectedWeek, setSelectedWeek] = useState<Week>('W1')
  const [selectedMarketId, setSelectedMarketId] = useState<Market['id'] | ''>('')

  return (
    <>
      {isOpen && (
        <Dialog open={isOpen} onClose={onClose}>
          <DialogTitle>Stage and market selection</DialogTitle>
          <DialogContent>
            <Stack p={2} spacing={1}>
              <Box pb={2} px={0.5}>
                <FormLabel>Select negotiation stage</FormLabel>
                <RadioGroup
                  row
                  value={selectedWeek}
                  onChange={(e) => setSelectedWeek(e.target.value as Week)}
                >
                  {Object.entries(weekNames).map(([week, weekName]) => (
                    <FormControlLabel
                      key={`${week}`}
                      value={week}
                      control={<Radio />}
                      label={weekName}
                    />
                  ))}
                </RadioGroup>
              </Box>
              {selectedWeek === 'W2' && (
                <TextField
                  select
                  value={selectedMarketId}
                  label='Market'
                  onChange={(e) => setSelectedMarketId(e.target.value)}
                  fullWidth
                >
                  {markets.map(({ id, name }) => (
                    <MenuItem key={`${id}-${name}`} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Stack>

            <Stack direction='row' p={2} spacing={1} justifyContent='flex-end'>
              <Button variant='contained' color='secondary' onClick={onClose}>
                Cancel
              </Button>
              <Button
                disabled={!selectedWeek || (selectedWeek === ('W2' as Week) && !selectedMarketId)}
                variant='contained'
                color='primary'
                sx={{ mr: 1 }}
                onClick={() =>
                  onMarketSelected(
                    selectedWeek === ('W1' as Week) ? null : (selectedMarketId ?? null),
                  )
                }
              >
                Create scenario
              </Button>
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
