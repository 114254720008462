import { SupplierCommodity, SupplierCommodityReflection } from '@common/types'
import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useNegotiationPreparationData } from 'src/merchandising/pages/ChangeRequests/NegotiationPreparation/NegotiationPreparationPage'
import { useMemo } from 'react'
import { NegotiationPreparationWithInputs } from '@common/dto'
import { useFormatter } from '@shared/hooks'

const getCombinedCommodityData = (
  commodityReflections: SupplierCommodityReflection[],
  commodityData: SupplierCommodity[],
) => {
  const commodityReflectionMap = commodityReflections
    .filter((commodity) => Boolean(commodity.showInReflections))
    .reduce(
      (acc, reflection) => ({ ...acc, [reflection.commodityName]: reflection }),
      {} as { [key: string]: SupplierCommodityReflection },
    )

  const commodityDataMap = commodityData.reduce(
    (acc, commodity) => ({ ...acc, [commodity.commodityName]: commodity }),
    {} as { [key: string]: SupplierCommodity },
  )

  const allCommodities = Array.from(new Set([...Object.keys(commodityReflectionMap)]))

  return allCommodities.map((commodityName) => ({
    ...commodityReflectionMap[commodityName],
    ...commodityDataMap[commodityName],
  }))
}

const findFirstArticleEanWithShownCommodityReflections = (
  data: NegotiationPreparationWithInputs,
): keyof Required<NegotiationPreparationWithInputs['analysis']>['supplierCommodityReflections'] =>
  Object.entries(data.analysis.supplierCommodityReflections!)
    .map(([ean, supplierCommodities]) => {
      return { ean: ean, supplierCommodities: supplierCommodities }
    })
    .filter(
      ({ supplierCommodities }) =>
        !!supplierCommodities.find((commodity) => Boolean(commodity.showInReflections)),
    )
    .find(Boolean)?.ean ?? Object.keys(data.analysis.supplierCommodityReflections!)[0]

export const SupplierCommoditiesReflections = (): JSX.Element | null => {
  const { data } = useNegotiationPreparationData()

  const articleEan = useMemo(() => findFirstArticleEanWithShownCommodityReflections(data), [data])

  const commodityReflections = useMemo(
    () => data.analysis.supplierCommodityReflections![articleEan],
    [data, articleEan],
  )
  const supplierCommodityData = useMemo(
    () =>
      data.articles.find((article) => article.ean === articleEan)!.purchasePriceChangeDetails
        .commodities!.supplierCommodities,
    [data, articleEan],
  )

  const combinedCommodityData = useMemo(
    () => getCombinedCommodityData(commodityReflections, supplierCommodityData),
    [commodityReflections, supplierCommodityData],
  )

  if (!data.analysis.supplierCommodityReflections) {
    console.warn('No Supplier Commodity Reflections data')
    return null
  }

  if (combinedCommodityData.length === 0) {
    return null
  }

  return (
    <Box mt={4}>
      <Typography variant='h5' mb={2}>
        Supplier Commodities and Reflections
      </Typography>
      <TableContainer sx={{ mt: 2 }}>
        <Table sx={{ minWidth: 300 }}>
          <TableHead>
            <TableRow>
              <HeaderTableCell>Commodity</HeaderTableCell>
              <HeaderTableCell>% of Article</HeaderTableCell>
              <HeaderTableCell>Change</HeaderTableCell>
              <HeaderTableCell>Actual Change</HeaderTableCell>
              <HeaderTableCell>Index name</HeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {combinedCommodityData.map((commodity) => (
              <TableRow key={JSON.stringify(commodity)}>
                <TableCell>{commodity.commodityName}</TableCell>
                <TableCellWithNA value={commodity.costSharePercentage} />
                <TableCellWithNA value={commodity.priceChangeFromPreviousPercentage} />
                <TableCellWithNA value={commodity.actualChangePercentage} />
                <TableCellWithNA value={commodity.indexName} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

const TableCellWithNA = ({ value }: { value?: number | string | null }) => {
  const formatter = useFormatter()
  if (value === '' || value === null || typeof value === 'undefined') {
    return <TableCell sx={{ opacity: 0.5 }}>N/A</TableCell>
  }

  if (typeof value === 'string') {
    return <TableCell>{value}</TableCell>
  }

  return <TableCell>{formatter.percent0To1(value)}</TableCell>
}

export const HeaderTableCell = styled(TableCell)({
  fontWeight: 'bold',
})
