import { Theme } from '@mui/material'

export const linkTheme = {
  MuiLink: {
    styleOverrides: {
      root: ({ theme }: { theme: Theme }) => ({
        color: theme.palette.link.main,
        textDecoration: 'none',
      }),
    },
  },
}
