import { DistanceFormatter } from '@logistics/types'
import { DEFAULT_LOCALE } from '@logistics/constants'

export function isInvalidOffer(
  availableOfferAmount: number | undefined,
  availableOfferCurrency: string | undefined,
) {
  return (
    !availableOfferAmount ||
    !isFinite(availableOfferAmount) ||
    !availableOfferCurrency ||
    !availableOfferCurrency.trim()
  )
}

export function getValidatedLocaleOrDefault(locale: string, defaultLocale: string) {
  try {
    new Intl.Locale(locale)
  } catch (e) {
    return defaultLocale
  }
  return locale
}

export const milesFormatter: DistanceFormatter = (locale: string, fractionDigits: number) =>
  new Intl.NumberFormat(getValidatedLocaleOrDefault(locale, DEFAULT_LOCALE), {
    style: 'unit',
    unit: 'mile',
    unitDisplay: 'long',
    maximumFractionDigits: fractionDigits,
  })

export const kilometersFormatter: DistanceFormatter = (locale: string, fractionDigits: number) =>
  new Intl.NumberFormat(getValidatedLocaleOrDefault(locale, DEFAULT_LOCALE), {
    style: 'unit',
    unit: 'kilometer',
    unitDisplay: 'long',
    maximumFractionDigits: fractionDigits,
  })
