import { MarketRowData, SpreadsheetMarketID } from './types'
import { ValidationError } from '../ValidationError'
import { hasNonUniqueElements } from '@shared/utils/array'
import { MARKET_FORECAST_SHEET_NAME, MARKETS_SHEET_NAME } from './constants'

export function validateMarketData(data: MarketRowData) {
  if (data.marketForecasts.length === 0) {
    throw new ValidationError(
      `Could not find "${MARKET_FORECAST_SHEET_NAME}" sheet with values in the file.`,
    )
  } else if (data.markets.length === 0) {
    throw new ValidationError(
      `Could not find sheet "${MARKETS_SHEET_NAME}" sheet with values in the file.`,
    )
  }
  const uniqueMarketIdsInScope: Set<SpreadsheetMarketID> = new Set(
    data.marketForecasts.map((forecast) => forecast.MarketID),
  )
  const allMarketIds = data.markets.map((market) => market.MarketID)
  if (hasNonUniqueElements(allMarketIds)) {
    throw new ValidationError(
      `All MarketID values in "${MARKETS_SHEET_NAME}" sheet must be unique.`,
    )
  }
  const marketsInScope = allMarketIds.filter((marketId) => uniqueMarketIdsInScope.has(marketId))
  if (marketsInScope.length > uniqueMarketIdsInScope.size) {
    throw new Error(
      'It should not happen that marketsInScope has more elements than uniqueMarketIdsInScope ' +
        'because it is meant to have unique values filtered on uniqueMarketIdsInScope.',
    )
  } else if (marketsInScope.length < uniqueMarketIdsInScope.size) {
    throw new ValidationError(
      `"${MARKETS_SHEET_NAME}" sheet must have MarketID value for every unique MarketID value
            in "${MARKET_FORECAST_SHEET_NAME}" sheet.`,
    )
  }
}
