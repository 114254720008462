import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import React from 'react'

type PopoverContentProps = {
  fieldValue: string
  disabled: boolean
  setFieldValue(value: string): void
  handleLocationChange(): void
}

export const PopoverContent = ({
  fieldValue,
  disabled,
  setFieldValue,
  handleLocationChange,
}: PopoverContentProps) => (
  <Box
    sx={{
      p: 1,
      bgcolor: 'grey.A200',
      position: 'relative',
      borderRadius: '4px',
      minWidth: '600px',
      mt: '10px',
      '&::before': {
        backgroundColor: 'grey.A200',
        content: '""',
        display: 'block',
        position: 'absolute',
        width: 12,
        height: 12,
        top: -6,
        transform: 'rotate(45deg)',
        right: '14px',
      },
    }}
  >
    <FormControl sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          variant='outlined'
          fullWidth
          style={{ backgroundColor: 'white' }}
          size='small'
          value={fieldValue}
          disabled={disabled}
          onChange={(e) => {
            setFieldValue(e.currentTarget.value)
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  onClick={() => setFieldValue('')}
                  edge='end'
                  disabled={fieldValue === ''}
                >
                  <RemoveCircleIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          color='primary'
          variant='contained'
          onClick={handleLocationChange}
          sx={{ marginLeft: 2 }}
          disabled={disabled}
        >
          Done
        </Button>
      </Box>
      <FormHelperText>Enter a incoterm location for this item.</FormHelperText>
    </FormControl>
  </Box>
)
