import { QuickFilter } from '@components/QuickFilter'
import { styled, Typography } from '@mui/material'
import { GridToolbarColumnsButton, GridToolbarContainer } from '@mui/x-data-grid-pro'
import { Dispatch, SetStateAction } from 'react'
import { LAYOUT_CONTAINER_X_PADDING } from '@constants'
import { SelectAllNotification } from './SelectAllNotification'
import { BatchActionsBar } from './BatchActionsBar'
import { NegotiationListEntity } from '@common/dto/pact/negotiation-list-entity'

export const Toolbar = ({
  showBatchActions,
  showSelectAllNotification,
  selectedNegotiations,
  allNegotiationsCount,
  setAnchorElMenu,
  onSelectAllNotifications,
  onClearSelection,
  onSelectAllNotificationClose,
}: {
  showBatchActions: boolean
  showSelectAllNotification: boolean
  selectedNegotiations: NegotiationListEntity[]
  allNegotiationsCount: number
  setAnchorElMenu: Dispatch<SetStateAction<HTMLButtonElement | null>>
  onSelectAllNotifications: () => void
  onClearSelection: () => void
  onSelectAllNotificationClose: () => void
}) => (
  <ToolbarContainer>
    <Typography variant='subtitle1'>Negotiations</Typography>
    {showBatchActions && (
      <BatchActionsBar
        allNegotiationsCount={allNegotiationsCount}
        selectedNegotiations={selectedNegotiations}
      />
    )}
    <ColumnSelectButton ref={setAnchorElMenu} />
    <QuickFilter placeholder='Search negotiations' />
    <SelectAllNotification
      show={showSelectAllNotification}
      allNegotiationsCount={allNegotiationsCount}
      selectedNegotiationsCount={selectedNegotiations.length}
      onSelectAll={onSelectAllNotifications}
      onClearSelection={onClearSelection}
      onClose={onSelectAllNotificationClose}
    />
  </ToolbarContainer>
)

const ColumnSelectButton = styled(GridToolbarColumnsButton)(() => ({
  marginLeft: 'auto',
}))

const ToolbarContainer = styled(GridToolbarContainer)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  marginLeft: '10px',
  paddingLeft: theme.spacing(LAYOUT_CONTAINER_X_PADDING),
}))
