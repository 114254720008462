import { Button, Grid, Stack, TextField } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { CustomDeal, PrimaryCustomDeal } from '@common/types'
import { validateTitle } from './validations'
import { DeleteButton } from '../../../../../components/DeleteButton'
import React, { useState } from 'react'
import { InputError } from 'src/merchandising/types'

interface Props {
  deal: PrimaryCustomDeal
  onChange: (deal: PrimaryCustomDeal) => void
  onDelete: () => void
}

export const CustomDealRow = ({ deal, onChange, onDelete }: Props) => {
  const addAlternativeDeal = () =>
    onChange({
      ...deal,
      alternativeDeal: {
        title: '',
      },
    })

  return (
    <Grid container item spacing={2}>
      <Grid item xs={2}>
        <TitleTextInput
          value={deal.title}
          onChange={(e) =>
            onChange({
              ...deal,
              title: e.target.value,
            })
          }
        />
      </Grid>
      <Grid item xs={4}>
        <DetailsTextInput
          value={deal.details}
          onChange={(e) =>
            onChange({
              ...deal,
              details: e.target.value,
            })
          }
        />
      </Grid>
      <Grid container item xs={6}>
        {deal.alternativeDeal && (
          <AlternativeDeal
            alternativeDeal={deal.alternativeDeal}
            onChange={(alternativeDeal) =>
              onChange({
                ...deal,
                alternativeDeal,
              })
            }
            onDelete={() =>
              onChange({
                ...deal,
                alternativeDeal: undefined,
              })
            }
          />
        )}
        {!deal.alternativeDeal && (
          <Grid item sx={{ mt: 1.5 }}>
            <Stack direction='row'>
              <DeleteButton onDelete={onDelete} />
              <Button startIcon={<AddIcon />} onClick={addAlternativeDeal}>
                Add alternative
              </Button>
            </Stack>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

interface AlternativeDealProps {
  alternativeDeal: CustomDeal
  onChange: (deal: CustomDeal) => void
  onDelete: () => void
}

const AlternativeDeal = ({ alternativeDeal, onChange, onDelete }: AlternativeDealProps) => {
  return (
    <Grid container item spacing={2}>
      <Grid item xs={4}>
        <TitleTextInput
          value={alternativeDeal.title}
          onChange={(e) =>
            onChange({
              ...alternativeDeal,
              title: e.target.value,
            })
          }
        />
      </Grid>
      <Grid item xs={7}>
        <DetailsTextInput
          value={alternativeDeal.details}
          onChange={(e) =>
            onChange({
              ...alternativeDeal,
              details: e.target.value,
            })
          }
        />
      </Grid>
      <Grid item xs={1} sx={{ mt: 1.5 }}>
        <DeleteButton onDelete={onDelete} />
      </Grid>
    </Grid>
  )
}

interface TitleInputProps {
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const TitleTextInput = ({ value, onChange }: TitleInputProps) => {
  const [inputError, setInputError] = useState<InputError>({ isError: false })

  return (
    <TextField
      required
      label='Title'
      value={value}
      error={inputError.isError}
      helperText={!inputError.isError ? 'Title in supplier chat' : inputError.error}
      onChange={(e) => {
        setInputError(validateTitle(e.target.value))
        onChange(e)
      }}
    />
  )
}

interface DetailsInputProps {
  value: string | undefined
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const DetailsTextInput = ({ value, onChange }: DetailsInputProps) => (
  <TextField
    label='Details'
    helperText='Details of the offered deal in free-form text'
    multiline
    fullWidth
    maxRows={3}
    value={value}
    onChange={onChange}
  />
)
