import { CommercialTermsNegotiation, Contract } from '@campaigns/types/negotiation'
import {
  Box,
  Grid,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import {
  isNegotiationCompleted,
  isNegotiationCompletedSuccessfully,
} from '../../../utils/isNegotiationCompleted'
import { NegotiationDatesTooltip } from './NegotiationDatesTooltip'
import { dateFormats, Formatter } from '@utils'
import { formatCompletionDays } from '../../../utils/formatCompletionDays'
import { formatDurationMinutes } from '../../../utils/formatDurationMinutes'
import { useFormatter } from '@shared/hooks'
import { SavingsBreakdown } from '../SavingsBreakdown'
import { DataCell } from '../DataCell'
import { CustomFields } from '../CustomFields'
import React, { useState } from 'react'
import { ContractTabs } from './ContractTabs'
import { CustomTerms } from '../CustomTerms'
import {
  formatNewTerms,
  formatPreviousTerms,
} from '../../HomePage/NegotiationsList/columns/usecases/paymentTerms'
import { GrowthRebateRange } from '../../HomePage/NegotiationsList/GrowthRebatesTooltip'
import { ISO3Countries } from '@campaigns/dto/countries'
import { ISO2Languages } from '@campaigns/dto/languages'

const NO_VALUE_TEXT = 'N/A'

interface Props {
  negotiation: CommercialTermsNegotiation
}

export const SummaryDetails = ({ negotiation }: Props) => {
  const formatter = useFormatter()

  const { contracts } = negotiation
  const [selectedContract, setSelectedContract] = useState(contracts[0])

  const setContract = (contractIndex: number) => {
    setSelectedContract(contracts[contractIndex])
  }

  const negotiationCompleted = isNegotiationCompleted(negotiation)

  return (
    <Box pb={10}>
      <Typography variant='h1'>Negotiation summary</Typography>
      <NegotiationDatesTooltip negotiation={negotiation}>
        <Typography
          variant='body2'
          sx={{ mt: 1, display: 'inline', color: '#6C6C6C' }}
          fontWeight={500}
        >
          {getDurationText(negotiation, formatter, negotiationCompleted)}
        </Typography>
      </NegotiationDatesTooltip>
      <Grid container spacing={2.5}>
        {negotiationCompleted && (
          <Grid item xs={12} mt={4}>
            <SavingsBreakdown negotiation={negotiation} currency={negotiation.currency} />
          </Grid>
        )}

        {!negotiationCompleted && (
          <Grid item xs={4}>
            <ExpectedSavingsInCurrency negotiation={negotiation} formatter={formatter} />
          </Grid>
        )}

        <Grid item xs={4}>
          <SpendLastYear negotiation={negotiation} formatter={formatter} />
        </Grid>

        {contracts.length > 1 && (
          <Grid item xs={12} mt={4}>
            <ContractTabs
              contracts={contracts}
              selectedContract={selectedContract}
              setContract={setContract}
            />
          </Grid>
        )}

        {negotiationCompleted && (
          <>
            <Grid item xs={4}>
              <PaymentTerms contract={selectedContract} negotiation={negotiation} />
            </Grid>
            <Grid item xs={4}>
              <NewRebatesOrDiscounts contract={selectedContract} formatter={formatter} />
            </Grid>
            <Grid item xs={12}>
              <GrowthRebateStructure negotiation={negotiation} contract={selectedContract} />
            </Grid>
          </>
        )}

        {!negotiationCompleted && (
          <>
            <Grid item xs={4}>
              <PreviousPaymentTerms contract={selectedContract} />
            </Grid>
            {contracts.length <= 1 && (
              <>
                <Grid item xs={4}>
                  <Country negotiation={negotiation} />
                </Grid>
                <Grid item xs={4}>
                  <Language negotiation={negotiation} />
                </Grid>
              </>
            )}
          </>
        )}

        <Grid item xs={12}>
          <CustomTerms contract={selectedContract} currency={negotiation.currency ?? 'USD'} />
        </Grid>
        <UserFeedback negotiation={negotiation} />
        <Grid item xs={12}>
          <CustomFields negotiation={negotiation} />
        </Grid>
        <Grid item xs={12}>
          <SupplierPreferences negotiation={negotiation} />
        </Grid>
      </Grid>
    </Box>
  )
}

const getDurationText = (
  negotiation: CommercialTermsNegotiation,
  formatter: Formatter,
  completed: boolean,
): string => {
  if (completed) {
    const completedAt = negotiation.completedAt
      ? formatter.date(new Date(negotiation.completedAt), dateFormats.textDateTime)
      : 'N/A'

    const formatted = formatDurationMinutes(negotiation.durationMin)
    const durationMinutestText = formatted ? `in (${formatted})` : ''

    return `Completed on: ${completedAt} 
    ${durationMinutestText}`
  }

  const createdAt = formatter.date(new Date(negotiation.createdAt), dateFormats.longDate)
  const predictedCompletionIn = formatCompletionDays(negotiation.forecastedCompletionDays)
  return `Created on: ${createdAt}, estimated completion in ${predictedCompletionIn}`
}

const UserFeedback = ({ negotiation }: { negotiation: CommercialTermsNegotiation }) => {
  const negotiationHasFeedback = Boolean(
    negotiation.supplierFeedback?.score || negotiation.supplierFeedback?.comment,
  )
  if (!negotiationHasFeedback) {
    return null
  }

  return (
    <>
      <Grid item xs={8}>
        <DataCell label='Feedback' size='MEDIUM'>
          {negotiation.supplierFeedback?.comment}
        </DataCell>
      </Grid>
      <Grid item xs={4}>
        <DataCell label='Rating' size='MEDIUM'>
          {negotiation.supplierFeedback?.score ?? '-'}/5
        </DataCell>
      </Grid>
    </>
  )
}

const SupplierPreferences = ({ negotiation }: { negotiation: CommercialTermsNegotiation }) => {
  const formatter = useFormatter()
  return (
    <Box mt={5}>
      <Typography variant='body1' sx={{ fontWeight: 700 }}>
        Supplier preferences
      </Typography>
      <Grid container mt={1}>
        {/* Temporarily hiding it completely instead of showing N/A */}
        {negotiation.agreedGrowthProportionPercent !== null && (
          <SupplierPreference label='Selected growth ambition'>
            {formatter.percent0To1(negotiation.agreedGrowthProportionPercent / 100)}
          </SupplierPreference>
        )}
        <AgreedSupplierIncentives negotiation={negotiation} />
      </Grid>
    </Box>
  )
}

const AgreedSupplierIncentives = ({ negotiation }: { negotiation: CommercialTermsNegotiation }) => {
  const { selectedIncentives } = negotiation

  if (!selectedIncentives?.length) {
    return <SupplierPreference label='Selected incentives'>{'N/A'}</SupplierPreference>
  }

  return (
    <SupplierPreference label='Selected incentives'>
      <List sx={{ pt: 0 }}>
        {selectedIncentives?.map((incentive, index) => (
          <ListItem key={index} sx={{ pl: 0, pt: index === 0 ? 0 : undefined }}>
            {incentive}
          </ListItem>
        ))}
      </List>
    </SupplierPreference>
  )
}

const SupplierPreference = ({
  label,
  children,
}: {
  label: string
  children: string | JSX.Element
}) => {
  return (
    <>
      <Grid item xs={6} mt={2}>
        {label}
      </Grid>
      <Grid item xs={6} mt={2}>
        {children}
      </Grid>
    </>
  )
}

const PreviousPaymentTerms = ({ contract }: { contract: Contract }) => {
  return (
    <DataCell label='Current payment days' secondaryLabel={'Will be negotiated'} size='LARGE'>
      {formatPreviousTerms(contract) ?? NO_VALUE_TEXT}
    </DataCell>
  )
}

const ExpectedSavingsInCurrency = ({
  negotiation,
  formatter,
}: {
  negotiation: CommercialTermsNegotiation
  formatter: Formatter
}) => (
  <DataCell
    label={'Forecasted savings'}
    size='LARGE'
    secondaryLabel={formatter.percent0To1(negotiation.forecastedSavingsPercent / 100) ?? null}
  >
    {negotiation.forecastedSavingsInCurrency && negotiation.currency
      ? formatter.currency(negotiation.forecastedSavingsInCurrency, {
          currency: negotiation.currency,
        })
      : NO_VALUE_TEXT}
  </DataCell>
)

const Country = ({ negotiation }: { negotiation: CommercialTermsNegotiation }) => (
  <DataCell label='Supplier Country' size='MEDIUM'>
    {negotiation.country ? ISO3Countries[negotiation.country] : NO_VALUE_TEXT}
  </DataCell>
)

const Language = ({ negotiation }: { negotiation: CommercialTermsNegotiation }) => (
  <DataCell label='Negotiation language' size='MEDIUM'>
    {negotiation.language ? ISO2Languages[negotiation.language] : NO_VALUE_TEXT}
  </DataCell>
)

const SpendLastYear = ({
  negotiation,
  formatter,
}: {
  negotiation: CommercialTermsNegotiation
  formatter: Formatter
}) => (
  <DataCell label='Spend last year' size='LARGE'>
    {negotiation.pastSpend && negotiation.currency
      ? formatter.currency(negotiation.pastSpend, { currency: negotiation.currency })
      : NO_VALUE_TEXT}
  </DataCell>
)

const GrowthRebateStructure = ({
  contract,
  negotiation,
}: {
  contract: Contract
  negotiation: CommercialTermsNegotiation
}) => {
  const formatter = useFormatter()

  const growthRebateStructure = contract.standardTerms.growthRebateStructure?.agreed

  if (!growthRebateStructure) {
    return null
  }

  const { tiers, type: rebateType, displayType } = growthRebateStructure
  const currency = negotiation.currency

  if (!rebateType) {
    return null
  }

  return (
    <Box mt={5}>
      <Typography variant='body1' sx={{ fontWeight: 700 }}>
        Growth rebates
      </Typography>
      <Grid container mt={1}>
        {rebateType === 'spend' && (
          <Typography variant='body2'>Rebate applicable to entire spend.</Typography>
        )}
        {rebateType === 'growth' && (
          <Typography variant='body2'>Rebate applicable to only growth.</Typography>
        )}
        <TableContainer
          sx={{
            mt: 2,
            '& .MuiTableCell-root': {
              borderBottom: 0,
              paddingLeft: 0,
              fontSize: '1em',
            },
          }}
        >
          <Table sx={{ maxWidth: '75%' }}>
            <TableHead>
              <TableRow>
                <TableCell>Tier</TableCell>
                <TableCell>Rebate</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tiers.map((tier) => (
                <TableRow key={tier.min}>
                  <TableCell>
                    <GrowthRebateRange tier={tier} displayType={displayType} currency={currency} />
                  </TableCell>
                  <TableCell>{formatter.percent0To1(tier.rebatePercent / 100)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Box>
  )
}

const PaymentTerms = ({
  negotiation,
  contract,
}: {
  negotiation: CommercialTermsNegotiation
  contract: Contract
}) => {
  const previousTerm = formatPreviousTerms(contract)

  if (isNegotiationCompletedSuccessfully(negotiation)) {
    const newTerms = formatNewTerms(contract)
    return (
      <DataCell
        label='New payment days'
        size='LARGE'
        secondaryLabel={previousTerm ? `Was ${previousTerm}` : ''}
      >
        {newTerms ?? NO_VALUE_TEXT}
      </DataCell>
    )
  }

  return (
    <DataCell label='Previous payment days' size='LARGE'>
      {previousTerm ?? NO_VALUE_TEXT}
    </DataCell>
  )
}

const NewRebatesOrDiscounts = ({
  contract,
  formatter,
}: {
  contract: Contract
  formatter: Formatter
}) => {
  const generalDiscount = contract.standardTerms.generalDiscount?.agreed
  if (generalDiscount) {
    return (
      <DataCell label='New general discount' size='LARGE'>
        {formatter.percent0To1(generalDiscount / 100)}
      </DataCell>
    )
  }

  const flatRebate = contract.standardTerms.flatRebate?.agreed

  if (flatRebate) {
    return (
      <DataCell label='New rebate' size='LARGE'>
        {formatter.percent0To1(flatRebate / 100)}
      </DataCell>
    )
  }

  return null
}
