import { Box } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { HeaderPrimaryButton, useLayoutContext } from '@components/Layout'
import { UnitList } from './UnitList'
import { useGetStatisticsHeaderDataQuery } from '@logistics/store/unitApi'
import { LAYOUT_CONTAINER_X_PADDING, noValueText } from '@constants'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useFormatter } from '@shared/hooks'
import { UnitModal } from './UnitModal'
import { UnitPageContext } from './context'
import { isSpotLoadStatisticsData } from '@logistics/pages/spotLoad/utils/statisticsHeader'
import { cyrb53a } from '@shared/utils/hash'

const DAYS_IN_MONTH = 31

export const UnitPage = () => {
  const { activeProjectTag } = useActiveProject()
  const formatter = useFormatter()

  const { setStatisticsHeaderData, setActionsComponent } = useLayoutContext()
  const { isCreateNegotiationModalOpen, setIsCreateNegotiationModalOpen } =
    useContext(UnitPageContext)
  const { data: headerStatisticsResponse } = useGetStatisticsHeaderDataQuery({
    projectTag: activeProjectTag,
    periodInDays: DAYS_IN_MONTH,
  })

  useEffect(() => {
    if (isSpotLoadStatisticsData(headerStatisticsResponse)) {
      setStatisticsHeaderData([
        {
          title: 'In progress',
          primaryValue: headerStatisticsResponse?.inProgress ?? noValueText,
          secondaryValue: `${headerStatisticsResponse?.total ?? noValueText} total loads`,
        },
        {
          title: 'Agreed',
          primaryValue: headerStatisticsResponse?.agreed ?? noValueText,
          secondaryValue: 'This month',
        },
        {
          title: 'Declined',
          primaryValue: headerStatisticsResponse?.declined ?? noValueText,
          secondaryValue: 'This month',
        },
        {
          title: 'Expired',
          primaryValue:
            headerStatisticsResponse?.expiredPercentage === undefined
              ? noValueText
              : formatter.percent0To1(headerStatisticsResponse.expiredPercentage),
          secondaryValue: 'This month',
        },
      ])
    }

    // TODO: This is a temporary solution to hide the "New negotiation" button for Maersk NAM Spot Load
    if (cyrb53a(activeProjectTag) !== 5561181359702070) {
      setActionsComponent(
        <HeaderPrimaryButton onClick={() => setIsCreateNegotiationModalOpen(true)}>
          New negotiation
        </HeaderPrimaryButton>,
      )
    }

    return () => {
      setActionsComponent(null)
      setStatisticsHeaderData([])
    }
  }, [
    setStatisticsHeaderData,
    headerStatisticsResponse,
    formatter,
    setActionsComponent,
    setIsCreateNegotiationModalOpen,
    activeProjectTag,
  ])

  return (
    <>
      <Box pr={LAYOUT_CONTAINER_X_PADDING} py={4}>
        <UnitList />
      </Box>

      <UnitModal
        isOpen={isCreateNegotiationModalOpen}
        onClose={() => {
          setIsCreateNegotiationModalOpen(false)
        }}
      />
    </>
  )
}
