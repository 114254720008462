import { useContext } from 'react'
import { Grid, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { CheckboxCtrl } from '@components/Form'
import {
  createGetFieldControllerParams,
  FormRow,
  HideableBlock,
} from '@procurement/components/FormCommon/FormCommon'
import { NegotiationEventFormContext } from '../../NegotiationEventFormContext'
import { NegotiationEventFormData, NegotiationMode } from '../../schema'
import { StartDateAndLength } from './StartDateAndLength'
import { ContractLengthAlternatives } from './ContractLengthAlternatives'
import { ContractLengthAlternativesRebates } from './ContractLengthAlternativesRebates'

export const ContractLength = () => {
  const { watch } = useFormContext<NegotiationEventFormData>()
  const { requiredFields, visibleFields, disabledFields } = useContext(NegotiationEventFormContext)
  const getFieldControllerParams = createGetFieldControllerParams({
    requiredFields,
    disabledFields,
  })

  const isContractDatesRequired = requiredFields.includes('contractDatesEnabled')
  const [contractDatesChecked, negotiationMode] = watch(['contractDatesEnabled', 'negotiationMode'])
  const isSpendNegotiation = negotiationMode === NegotiationMode.Spend

  return (
    <HideableBlock hidden={!visibleFields.includes('contractDatesEnabled')}>
      <FormRow>
        <Grid item xs={12}>
          <CheckboxCtrl
            labelProps={{ fontWeight: 700 }}
            {...getFieldControllerParams('contractDatesEnabled', 'Agreement dates')}
            disabled={isContractDatesRequired && !!contractDatesChecked}
          />
        </Grid>
      </FormRow>
      <HideableBlock hidden={!contractDatesChecked}>
        <StartDateAndLength />
        <HideableBlock hidden={isSpendNegotiation}>
          <FormRow>
            <Grid item container xs={12} rowSpacing={2}>
              <Typography variant='body2' sx={{ mt: 2, mb: 1 }}>
                To include alternative agreement lengths in the table below, specify the
                corresponding price adjustments. Use a positive percentage to increase the maximum
                acceptable total price for alternative agreement durations. This will raise the
                maximum acceptable total price based on the increase of the original agreement term
                (e.g. x2) plus the specified percentage over the new maximum total price. To reduce
                it, use a negative percentage (e.g. -10%).
              </Typography>
            </Grid>
          </FormRow>
          <FormRow>
            <Grid item container xs={12} rowSpacing={2}>
              <ContractLengthAlternatives />
            </Grid>
          </FormRow>
        </HideableBlock>
        <HideableBlock hidden={!isSpendNegotiation}>
          <FormRow>
            <Grid item container xs={12} rowSpacing={2}>
              <Typography variant='body2' sx={{ mt: 2, mb: 1 }}>
                Include alternative contract lengths in the table below and specify the
                corresponding rebate adjustments. The Total estimated spend for each alternative
                contract length will be calculated proportionately to the term indicated for the
                Last spend above. After specifying the alternative length, insert the rebate
                adjustment for that length, eg a “10%” rebate will decrease the calculated Total
                estimated spend for this alternative length by the same percentage.
              </Typography>
            </Grid>
          </FormRow>
          <FormRow>
            <Grid item container xs={12} rowSpacing={2}>
              <ContractLengthAlternativesRebates />
            </Grid>
          </FormRow>
        </HideableBlock>
      </HideableBlock>
    </HideableBlock>
  )
}
