import { FormControlLabel, FormGroup, Radio } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { NegotiationEventFormData, NegotiationMode } from '../schema'

type Props = {
  pricesComponent: () => JSX.Element
  spendComponent: () => JSX.Element
  isEditing?: boolean
}

export const SpendOrPricesSwitch = ({ pricesComponent, spendComponent, isEditing }: Props) => {
  const { watch, setValue } = useFormContext<NegotiationEventFormData>()
  const negotiationMode = watch('negotiationMode')

  const setMode = (mode: NegotiationMode) => setValue('negotiationMode', mode)

  return (
    <>
      {!isEditing && (
        <FormGroup>
          <FormControlLabel
            checked={negotiationMode === NegotiationMode.Prices}
            onChange={() => setMode(NegotiationMode.Prices)}
            control={<Radio />}
            label='Negotiate line items'
          />
          <FormControlLabel
            checked={negotiationMode === NegotiationMode.Spend}
            onChange={() => setMode(NegotiationMode.Spend)}
            control={<Radio />}
            label='Negotiate reductions against previous spend'
          />
        </FormGroup>
      )}
      {negotiationMode === NegotiationMode.Prices ? pricesComponent() : spendComponent()}
    </>
  )
}
