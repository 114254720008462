import { useProjects } from '@hooks'
import { ProjectTag } from '@common/types'

export const useActiveProject = <P extends ProjectTag = ProjectTag>() => {
  const { activeProjectTag, activeOrgTag } = useProjects()

  if (!activeProjectTag) {
    throw new Error('Active project is missing, something went wrong')
  }
  if (!activeOrgTag) {
    throw new Error('Active organization is missing, something went wrong')
  }

  return { activeProjectTag: activeProjectTag as P, activeOrgTag }
}
