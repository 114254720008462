import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { ExpandableSection } from 'src/shared/components/ExpandableSection'
import { HistoricalNumbersGraph } from './HistoricalNumbersGraph'
import { useGetNegotiationPreparationQuery } from 'src/merchandising/store/'
import { PricingStrategySummary } from './PricingStrategySummary'
import { CompetitorPricesTableV2 } from './CompetitorPricesTableV2'

export const PricingInsights = (): JSX.Element | null => {
  const { negotiationPreparationId } = useParams<{ negotiationPreparationId: string }>()
  const { data } = useGetNegotiationPreparationQuery(negotiationPreparationId!, {
    skip: !negotiationPreparationId,
  })

  if (!data) return null

  const { pricingStrategy, competitorPrices, historical } = data.analysis

  if (!pricingStrategy && !competitorPrices && !historical) {
    console.warn('No Pricing Insights data')
    return null
  }

  return (
    <Box px={6} mt={4} pb={4}>
      <ExpandableSection
        title='Pricing Insights'
        description='The following section shows pricing insights from different sources.'
      >
        <PricingStrategySummary />
        <CompetitorPricesTableV2 />
        <HistoricalNumbersGraph />
      </ExpandableSection>
    </Box>
  )
}
