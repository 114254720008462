import {
  alpha,
  Button,
  ClickAwayListener,
  Divider,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  PopperPlacementType,
  styled,
  SxProps,
  Theme,
} from '@mui/material'
import { MutableRefObject, PropsWithChildren } from 'react'

export const ListPopover = ({
  buttonsRef,
  open,
  setOpen,
  children,
  maxWidth,
  placement,
  sx,
}: PropsWithChildren<{
  buttonsRef: MutableRefObject<null>
  open: boolean
  setOpen: (open: boolean) => void
  maxWidth?: string
  sx?: SxProps<Theme>
  placement?: PopperPlacementType
}>) => {
  return (
    <Popper
      open={open}
      anchorEl={buttonsRef.current}
      transition
      disablePortal
      sx={{
        width: 'auto',
        maxWidth: maxWidth ?? 'unset',
        ...sx,
      }}
      placement={placement}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom-end' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={() => setOpen(false)}>
              {<StyledMenuList>{children}</StyledMenuList>}
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
}

const StyledMenuList = styled(MenuList)(({ theme }) => ({
  marginTop: theme.spacing(0.25),
  borderRadius: theme.shape.borderRadius,
  background: theme.palette.menu.main,
  maxWidth: '100%',
  padding: theme.spacing(1, 0),
}))

export const ListPopoverButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  background: theme.palette.menu.main,
  '&:hover': {
    background: theme.palette.menu.main,
  },
}))

export const ListPopoverMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.common.white,
  whiteSpace: 'normal',
  padding: theme.spacing(0.5, 2),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.2),
  },
}))

export const ListPopoverDivider = styled(Divider)(({ theme }) => ({
  color: 'rgba(255, 255, 255, 0.8)',
  fontSize: theme.typography.pxToRem(14),
  borderColor: 'rgba(231, 224, 236, 0.2)',

  '::before': {
    maxWidth: '8px',
    width: '100%',
    borderColor: 'rgba(231, 224, 236, 0.2)',
  },
  '::after': {
    borderColor: 'rgba(231, 224, 236, 0.2)',
  },
}))
