import { Formatter } from '@utils'

export const formatCurrency = (
  value: number,
  currency: string,
  formatter: Formatter,
  maxFractionDigits = 2,
): string | null => {
  if (typeof value !== 'number' || Number.isNaN(value)) {
    return null
  }

  return formatter.currency(value, { currency, maxFractionDigits })
}

export const formatOptionalCurrency = (
  value: number,
  formatter: Formatter,
  currency?: string,
  maxFractionDigits?: number,
) => (currency ? formatCurrency(value, currency, formatter, maxFractionDigits) : value)
