import {
  Checkbox,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material'

interface Props {
  acceptableIncoterms: string[]
  allowedIncoterms: string[]
  onChange: (updated: string[]) => void
}

export const AcceptableIncotermsCell = ({
  acceptableIncoterms,
  allowedIncoterms,
  onChange,
}: Props) => {
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event

    const acceptableIncoterms = typeof value === 'string' ? value.split(', ') : value

    onChange(acceptableIncoterms)
  }

  return (
    <Select
      multiple
      value={acceptableIncoterms}
      onChange={handleChange}
      sx={{
        '.MuiSelect-select': {
          fontSize: '14px',
          paddingLeft: 0,
        },
      }}
      input={<OutlinedInput />}
      renderValue={(selected: string[]) => selected.join(', ')}
      fullWidth
    >
      {allowedIncoterms.map((incoterm) => (
        <MenuItem key={incoterm} value={incoterm} sx={{ pl: 0, pr: 0 }}>
          <Checkbox checked={acceptableIncoterms.indexOf(incoterm) > -1} />
          <ListItemText primary={incoterm} />
        </MenuItem>
      ))}
    </Select>
  )
}
