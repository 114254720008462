import { useEffect } from 'react'
import { Box } from '@mui/material'
import { useSnackbar } from 'notistack'
import { NegotiationsList } from './NegotiationsList'
import { isBackendApiErrorResponse, useGetNegotiationsQuery } from 'src/commercial-terms/store'
import { PactumLoader } from 'src/shared/components/PactumLoader'
import { WelcomePage } from './WelcomePage'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { LAYOUT_CONTAINER_X_PADDING } from '@constants'
import { BatchActionLoadingNegotiationsProvider } from './NegotiationsList/BatchActionLoadingNegotiations'

export const HomePage = () => {
  const { activeProjectTag } = useActiveProject()
  const { enqueueSnackbar } = useSnackbar()

  const {
    data: negotiations,
    isLoading,
    isError,
    error,
  } = useGetNegotiationsQuery(activeProjectTag)

  useEffect(() => {
    if (isError && !!error) {
      enqueueSnackbar(
        isBackendApiErrorResponse(error)
          ? error.data.message
          : 'There was an error fetching negotiations',
        { variant: 'error' },
      )
    }
  }, [isError, error, enqueueSnackbar])

  if (isLoading || !negotiations) {
    return (
      <Box mt={4}>
        <PactumLoader />
      </Box>
    )
  }

  if (negotiations.length === 0) {
    return <WelcomePage />
  }

  return (
    <Box pr={LAYOUT_CONTAINER_X_PADDING}>
      <BatchActionLoadingNegotiationsProvider>
        <NegotiationsList negotiations={negotiations} />
      </BatchActionLoadingNegotiationsProvider>
    </Box>
  )
}
