import { Typography } from '@mui/material'
import { useFormatter } from '@shared/hooks'
import { Segment } from '../StackedBarChart/StackedBarChart'
import { isNumber } from 'lodash/fp'
import { Stack } from '@mui/material'
import { DataCell } from '../DataCell/DataCell'
import { StackedBarChart } from '../StackedBarChart/StackedBarChart'
import React from 'react'
import { NegotiationEvent } from '../../store/types'

interface Props {
  negotiationEvent: NegotiationEvent
}

export const SavingsBreakdown = ({ negotiationEvent }: Props) => {
  const formatter = useFormatter()
  const { currency } = negotiationEvent
  const winner = negotiationEvent.suppliers.find(({ isWinner }) => !!isWinner)
  if (!winner) {
    return null
  }
  const { negotiation } = winner

  if (!negotiation?.outcomes || negotiation?.outcomes?.savings < 0) {
    return null
  }
  const { savingsBreakdown } = negotiation.outcomes
  const chartSegments = savingsBreakdown
    ? [
        segmentWithLabelOrNone(savingsBreakdown.price, 'Price', '#0B8E18'),
        segmentWithLabelOrNone(savingsBreakdown.workingCapital, 'Payment terms', '#1F77B4'),
        segmentWithLabelOrNone(savingsBreakdown.rebate, 'Rebate', '#FF7F0E'),
        segmentWithLabelOrNone(savingsBreakdown.warranty, 'Warranty', '#D62728'),
        segmentWithLabelOrNone(savingsBreakdown.incoterm, 'Incoterms', '#9467BD'),
        segmentWithLabelOrNone(savingsBreakdown.contractLength, 'Contract length', '#8C564B'),
      ].filter(function (segment): segment is Segment {
        return segment !== undefined
      })
    : undefined

  const isMultiSupplier = negotiationEvent.suppliers.length > 1
  const baseline = negotiationEvent.suppliers.find(({ isBaseline }) => !!isBaseline)

  const headerText =
    isMultiSupplier && baseline
      ? `Total savings over the baseline: ${baseline.name}`
      : 'Total savings'

  return (
    <>
      <Stack gap={1} marginBottom={4}>
        <DataCell label={headerText} size='large'>
          {formatter.currency(negotiation.outcomes.savings, { currency })}
          <Typography color='text.secondary' component='span' ml={1}>
            {`Savings rate: ${formatter.percent0To1(negotiation.outcomes.improvement)}`}
          </Typography>
        </DataCell>
        {chartSegments !== undefined ? (
          <StackedBarChart
            key={negotiation.id}
            segments={chartSegments}
            format={(n) => formatter.currency(n, { currency })}
          />
        ) : null}
      </Stack>
    </>
  )
}

const segmentWithLabelOrNone = (
  value: number | undefined,
  label: string,
  color: string,
): Segment | undefined => {
  if (isNumber(value) && value != 0) {
    return { value, label, color }
  }
  return undefined
}
