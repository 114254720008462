import { SideBlockFileUpload } from '@components/SideBlock'

export interface RFPFileUploadProps {
  isLoading: boolean
  onUploadDone: (file: Blob) => void
}

const SUPPORTED_FILE_TYPES = [
  'text/csv',
  'application/vnd.ms-excel',
  'application/msexcel',
  'application/x-msexcel',
  'application/x-ms-excel',
  'application/x-excel',
  'application/x-dos_ms_excel',
  'application/xls',
  'application/x-xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]

export const RFPFileUpload = ({ isLoading, onUploadDone }: RFPFileUploadProps) => {
  return (
    <SideBlockFileUpload
      isLoading={isLoading}
      callback={onUploadDone}
      fileTypes={SUPPORTED_FILE_TYPES}
    />
  )
}
