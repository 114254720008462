import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd'
import { DragIndicator } from '@mui/icons-material'
import { Box, List, ListItemButton, ListItemText } from '@mui/material'

export interface ListFormItem {
  label?: string
  value: string | number
}

export interface DraggableFormListProps {
  items: ListFormItem[]
  selected?: ListFormItem | null
  activeLabel?: string
  disabled?: boolean

  onChange: (items: ListFormItem[]) => void
  onSelect?: (item: ListFormItem) => void
}

export const reorder = <TList extends unknown[]>(
  list: TList,
  startIndex: number,
  endIndex: number,
): TList => {
  const result = Array.from(list) as TList
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const DraggableFormList = ({
  items = [],
  activeLabel = 'primary',
  selected,
  disabled,
  onChange,
  onSelect,
}: DraggableFormListProps) => {
  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return

    const newItems = reorder(items, source.index, destination.index)

    onChange(newItems)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable'>
        {(droppableProvided) => (
          <List ref={droppableProvided.innerRef}>
            {items.map((item, index) => (
              <Draggable
                key={item.value.toString()}
                draggableId={item.value.toString()}
                index={index}
              >
                {(draggableProvided, snapShot) => (
                  <ListItemButton
                    disabled={disabled}
                    onClick={() => onSelect?.(item)}
                    selected={selected?.value === item.value}
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                    sx={() => ({
                      p: 0,
                      ...(snapShot.isDragging
                        ? { background: 'rgba(236, 241, 242, .5)' }
                        : {
                            background:
                              selected?.value === item.value
                                ? 'rgba(236, 241, 242, 1)'
                                : 'transparent',
                          }),
                    })}
                  >
                    <Box display='flex' alignItems='center' gap={1} fontSize={14} padding={'4px'}>
                      <DragIndicator fontSize='extra-small' />
                      <ListItemText
                        primary={item.label ?? item.value}
                        primaryTypographyProps={{
                          fontSize: '14px',
                        }}
                      />
                      {index === 0 && (
                        <>
                          <span>-</span>
                          <span>{activeLabel}</span>
                        </>
                      )}
                    </Box>
                  </ListItemButton>
                )}
              </Draggable>
            ))}
            {droppableProvided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  )
}
