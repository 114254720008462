import { Article, CompetitorPrice, CompetitorsPrices, NegotiationAnalysis } from '@common/types'
import { useFormatter } from '@shared/hooks'
import { Box, Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro'
import { ReactNode, useMemo, useState } from 'react'
import { useNegotiationPreparationData } from '../../NegotiationPreparationPage'
import { Formatter, capitalizeFirstLetter, dateFormats } from '@utils'
import { ArticleWithCompetitorPrices } from './types'
import { NamedPriceWithChangeCell } from '../NegotiationConfigurationCells'
import { DataGrid, NotAvailableCell, dataGridProps } from 'src/shared/components/table'
import { ascendingOrder, ascendingOrderBy, onlyUniqueBy } from '@common/utils'
import { getPriceFor, getPriceIndex } from './utils'

const renderNumericCellOrNa = (
  params: GridRenderCellParams,
  formatter: (value: number) => string,
): ReactNode => {
  if (typeof params.value === 'number') return formatter(params.value)

  return <NotAvailableCell />
}

const createStaticColumns = (formatter: Formatter): GridColDef<ArticleWithCompetitorPrices>[] => [
  {
    field: 'displayedEan',
    headerName: 'EAN',
    disableColumnMenu: true,
    filterable: false,
    resizable: false,
    sortable: false,
    flex: 1.25,
  },
  {
    field: 'name',
    headerName: 'Article',
    disableColumnMenu: true,
    filterable: false,
    resizable: false,
    sortable: false,
    flex: 2,
  },
  {
    field: 'date',
    headerName: 'Date',
    disableColumnMenu: true,
    filterable: false,
    resizable: false,
    sortable: false,
    flex: 1,
    valueFormatter: ({ value }) => formatter.date(new Date(value), dateFormats.shortDate),
  },
]

const getColumns = (
  baseline: CompetitorsPrices['baseline']['name'],
  competitorNames: CompetitorPrice['name'][],
  formatter: Formatter,
): GridColDef<ArticleWithCompetitorPrices>[] => {
  const competitorColumns: GridColDef<ArticleWithCompetitorPrices>[] = competitorNames.map(
    (competitorName) => ({
      field: competitorName,
      headerName: capitalizeFirstLetter(competitorName),
      disableColumnMenu: true,
      filterable: false,
      resizable: false,
      sortable: false,
      flex: 1.25,
      renderCell: ({ row }) => (
        <NamedPriceWithChangeCell
          currentPrice={getPriceFor(competitorName, row.competitors)}
          previousPrice={getPriceFor(competitorName, row.previousPrice?.competitors)}
        />
      ),
    }),
  )
  const baselineColumn: GridColDef<ArticleWithCompetitorPrices> = {
    field: 'baseline',
    headerName: capitalizeFirstLetter(baseline),
    disableColumnMenu: true,
    filterable: false,
    resizable: false,
    sortable: false,
    flex: 1.25,
    renderCell: ({ row }) => (
      <NamedPriceWithChangeCell
        currentPrice={row.baseline.price}
        previousPrice={row.previousPrice?.baseline.price}
      />
    ),
  }
  const priceIndexColumn: GridColDef<ArticleWithCompetitorPrices> = {
    field: 'priceIndexPercentage',
    headerName: 'Price Index (Main competitor)',
    disableColumnMenu: true,
    filterable: false,
    resizable: false,
    sortable: false,
    flex: 1.25,
    renderCell: (params) => renderNumericCellOrNa(params, formatter.percent0To1),
    valueGetter: ({ row }) =>
      getPriceIndex(row.competitors)
        ? row.baseline.price / getPriceIndex(row.competitors)!
        : undefined,
  }

  const staticColumns = createStaticColumns(formatter)
  const dynamicColumns = [...competitorColumns, baselineColumn, priceIndexColumn]
  return [...staticColumns, ...dynamicColumns]
}

const getPreviousCompetitorPrice = (
  competitorPricesMap: NegotiationAnalysis['competitorPrices'],
  currentPriceDate: Date,
  article: Article,
): CompetitorsPrices | undefined => {
  // sort by date desc and take first date where date is less than currentPriceDate, i.e. right before it
  return competitorPricesMap![article.ean]
    .slice()
    .sort(ascendingOrder((price) => new Date(price.date).getTime()))
    .find((prices) => currentPriceDate > prices.date)
}

const getRows = (
  articles: Article[],
  competitorPricesByEan: NegotiationAnalysis['competitorPrices'],
  alwaysAddEanName?: boolean,
): ArticleWithCompetitorPrices[] =>
  articles
    .map((article) => {
      // for each article, gather all the competitor prices samplings that have been done and merge it into a single object
      const articlePriceRows = competitorPricesByEan![article.ean].map(
        (competitorPricesSample, idx) => ({
          ...article,
          ...competitorPricesSample,
          displayedEan: idx === 0 || alwaysAddEanName ? article.ean : '',
          // only show name and ean for first row of given item, unless flag says otherwise (when sorting)
          name: idx === 0 || alwaysAddEanName ? article.name : '',

          id: Math.floor(Math.random() * 2e16).toString(16),
          previousPrice: getPreviousCompetitorPrice(
            competitorPricesByEan,
            competitorPricesSample.date,
            article,
          ),
        }),
      )

      // ean required for ID - currently breaks sorting, not sure if there is another way
      // const spacerRow = { ean: `${article.ean}-spacer` } as ArticleWithCompetitorPrices
      return [...articlePriceRows]
    })
    .flat()

const getAllCompetitorPrices = (
  competitorPrices: Required<NegotiationAnalysis>['competitorPrices'],
): Array<CompetitorPrice> =>
  Object.values(competitorPrices)
    .flat()
    .flatMap((competitorPrices) => competitorPrices.competitors ?? [])

const getAllUniqueCompetitorNames = (competitorPrices: Array<CompetitorPrice>) =>
  competitorPrices
    .filter(onlyUniqueBy('name'))
    .sort(ascendingOrderBy('priority'))
    .map((competitors) => competitors.name)

const getBaselineName = (competitorsPrices: Required<NegotiationAnalysis>['competitorPrices']) =>
  Object.values(competitorsPrices)
    .flat()
    .map((competitorPrices) => competitorPrices.baseline.name)[0]

export const CompetitorPricesTableV2 = (): JSX.Element | null => {
  const formatter = useFormatter()
  const { data } = useNegotiationPreparationData()

  const [sortNonDefault, setSortNonDefault] = useState(false)

  const allCompetitorPrices = useMemo(
    () => getAllCompetitorPrices(data.analysis.competitorPrices!),
    [data],
  )
  const baselineName = useMemo(() => getBaselineName(data.analysis.competitorPrices!), [data])
  const competitorNames = useMemo(
    () => getAllUniqueCompetitorNames(allCompetitorPrices),
    [allCompetitorPrices],
  )
  const columns = useMemo(
    () => getColumns(baselineName, competitorNames, formatter),
    [baselineName, competitorNames, formatter],
  )
  const rows = useMemo(
    () => getRows(data.articles, data.analysis.competitorPrices, sortNonDefault),
    [data, sortNonDefault],
  )

  if (!data.analysis.competitorPrices) {
    console.warn('No data for Competitor Retail Prices')
    return null
  }

  return (
    <Box mt={6}>
      <Typography variant='h5' mb={2}>
        Competitor Retail Prices
      </Typography>
      <DataGrid
        {...dataGridProps}
        columns={columns}
        rows={rows}
        getRowId={(row: ArticleWithCompetitorPrices) => row.id}
        localeText={{
          noRowsLabel: 'No articles present',
        }}
        onSortModelChange={(model) => setSortNonDefault(model.length > 0)}
      />
    </Box>
  )
}
