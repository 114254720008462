import { CancelOutlined } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material'
import { useController, useFormContext } from 'react-hook-form'
import { styleProps, errorProps } from './sharedProps'

type NumberFieldCtrlProps = TextFieldProps & {
  name: string
  min?: number
  max?: number
}

/**
 * MUI TextField for numeric input controlled by react-hook-form.
 * Includes a clear button to reset the field and min/max props for validation.
 */
export const NumberFieldCtrl = ({
  name,
  required,
  min,
  max,
  InputProps,
  ...rest
}: NumberFieldCtrlProps) => {
  const {
    fieldState: { error },
  } = useController({ name, rules: { required, min, max } })
  const { register, setValue } = useFormContext()
  const field = register(name, { required, min, max, valueAsNumber: true })

  return (
    <TextField
      {...styleProps}
      variant='filled'
      type='number'
      required={field.required}
      inputProps={{ min: field.min, max: field.max }}
      InputProps={{
        endAdornment: <ClearButton onClick={() => setValue(name, null)} />,
        ...InputProps,
      }}
      inputRef={field.ref}
      name={field.name}
      onChange={field.onChange}
      onBlur={field.onBlur}
      {...rest}
      {...errorProps(error)}
    />
  )
}

const ClearButton = ({ onClick }: { onClick: () => void }) => (
  <InputAdornment position='end'>
    <IconButton edge='end' color='primary' onClick={onClick}>
      <CancelOutlined titleAccess='Clear' />
    </IconButton>
  </InputAdornment>
)
