export const isDefined = <T>(value: T | undefined): value is T => value !== undefined

export const isNullish = (value: unknown): value is null | undefined =>
  value === null || value === undefined

export const isNumber = (value: unknown): value is number => {
  return Number(value) === value && Number.isFinite(value)
}

export const isInteger = (value: unknown): value is number => {
  return Number.isInteger(value)
}

export const isPositiveInteger = (value: unknown): value is number => {
  return isInteger(value) && value > 0
}

export const isString = (value: unknown): value is string => {
  return typeof value === 'string' || value instanceof String
}

export const isObject = (value: unknown): value is Record<string, unknown> => {
  return typeof value === 'object' && value !== null && !Array.isArray(value)
}

export const isArrayOfNumbers = (value: unknown): value is number[] => {
  return Array.isArray(value) && value.every(isNumber)
}

export const isArrayOfStrings = (value: unknown): value is string[] => {
  return Array.isArray(value) && value.every(isString)
}

export const isArrayOfIntegers = (value: unknown): value is number[] => {
  return Array.isArray(value) && value.every(isInteger)
}

export const isArrayOfPositiveIntegers = (value: unknown): value is number[] => {
  return Array.isArray(value) && value.every(isPositiveInteger)
}
