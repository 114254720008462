import { useLayoutContext } from '@components/Layout'
import { PactumLoader } from '@components/PactumLoader'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Alert, Box, Grid, IconButton, Stack } from '@mui/material'
import { isBackendApiErrorResponse } from '@shared/backend/error/typeGuards'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useGetConfigurationQuery, useGetSingleNegotiationEventQuery } from '../../store/purchasing'
import { BasicAttributes } from '@procurement/pages/NegotiationEventDetails/BasicAttributes/BasicAttributes'
import { NegotiationEventSummary } from './NegotiationEventSummary/NegotiationEventSummary'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { ProcurementRoutes } from '@procurement/routes'

const LAYOUT_PADDING = 6

export const NegotiationEventDetails = () => {
  const navigate = useNavigate()
  const location: {
    state: {
      from: string
    }
  } = useLocation()
  const { activeProjectTag, activeOrgTag } = useActiveProject()
  const { negotiationEventId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const { setIsReadonlyWorkspace, setNegotiationEvent } = useLayoutContext()

  const {
    data: configuration,
    isError: isConfigurationLoadError,
    error: configurationLoadError,
    isLoading: isLoadingConfiguration,
  } = useGetConfigurationQuery({
    projectTag: activeProjectTag,
  })
  const productType = configuration?.data.suite.productType

  const {
    data: negotiationEvent,
    isLoading: isLoadingNegotiationEVent,
    isError: isNegotiationEventLoadError,
    error: negotiationEventLoadError,
  } = useGetSingleNegotiationEventQuery(
    negotiationEventId
      ? { projectTag: activeProjectTag, negotiationEventId: Number(negotiationEventId) }
      : skipToken,
  )

  useEffect(() => {
    setNegotiationEvent(negotiationEvent ?? null)
  }, [negotiationEvent, setNegotiationEvent])

  useEffect(() => {
    if (isNegotiationEventLoadError && negotiationEventLoadError) {
      enqueueSnackbar(
        isBackendApiErrorResponse(negotiationEventLoadError)
          ? negotiationEventLoadError.data.message
          : 'There was an error fetching negotiation event details',
        { variant: 'error' },
      )
    }
  }, [isNegotiationEventLoadError, negotiationEventLoadError, enqueueSnackbar])

  useEffect(() => {
    if (isConfigurationLoadError && configurationLoadError) {
      enqueueSnackbar(
        isBackendApiErrorResponse(configurationLoadError)
          ? configurationLoadError.data.message
          : 'There was an error fetching workspace configuration',
        { variant: 'error' },
      )
    }
  }, [isConfigurationLoadError, configurationLoadError, enqueueSnackbar])

  useEffect(() => {
    if (configuration?.data.suite?.isReadonly === true) {
      setIsReadonlyWorkspace(true)
    }
    return () => setIsReadonlyWorkspace(false)
  }, [configuration, setIsReadonlyWorkspace])

  const navigateBack = () => {
    if (location.state?.from) {
      navigate(-1)
      return
    }

    navigate(
      generatePath(ProcurementRoutes.NEGOTIATION_EVENTS, {
        orgTag: activeOrgTag,
        projectTag: activeProjectTag,
      }),
    )
  }

  if (isLoadingNegotiationEVent || isLoadingConfiguration) {
    return <PactumLoader />
  }

  if (!negotiationEvent || !configuration) {
    return (
      <Box pr={LAYOUT_PADDING} pl={LAYOUT_PADDING} py={4}>
        <Alert severity='error'>
          We were unable to load negotiation event details, please try again!
        </Alert>
      </Box>
    )
  }

  return (
    <Stack flexDirection='row' mt={4} pl={1.5}>
      <Box position='relative' right={(theme) => theme.spacing(0.75)}>
        <IconButton onClick={() => navigateBack()} sx={{ alignSelf: 'flex-start' }}>
          <ArrowBackIcon fontSize='small' sx={{ color: 'black' }} />
        </IconButton>
      </Box>
      <Grid pr={6} container spacing={10}>
        <Grid item xs={3}>
          <Box p={0} pt={0.5}>
            <BasicAttributes negotiationEvent={negotiationEvent} productType={productType} />
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Box px={1} pt={0.5} pb={4}>
            <NegotiationEventSummary
              negotiationEvent={negotiationEvent}
              productType={productType}
              configuration={configuration}
            />
          </Box>
        </Grid>
      </Grid>
    </Stack>
  )
}
