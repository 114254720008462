import { useState } from 'react'
import { Box, Stack } from '@mui/material'
import { Chart } from 'react-chartjs-2'
import { SimpleBarChart } from '@common/dto/dashboard/dashboard'
import { FiltersList } from '@shared/components/FiltersList'
import { BASE_COLOR, getColorVariations } from './colorUtils'
import { WidgetSection } from './WidgetSection'

interface Props {
  widgetConfig: SimpleBarChart
}

export const SimpleBarChartWidget = ({ widgetConfig }: Props) => {
  const { title, description, labels, series, showCountInLabel } = widgetConfig
  const [selectedSeriesFilter, setSelectedSeriesFilter] = useState(series[0].label)

  const selectedDataset = series.find((d) => d.label === selectedSeriesFilter)!

  const labelsWithDatasetNumbers = labels.map(
    (label, idx) => `${selectedDataset.data[idx]}   ${label}`,
  )

  return (
    <WidgetSection
      title={title}
      description={description}
      filters={
        <Stack direction='row' justifyContent='end' gap={4}>
          <FiltersList
            options={series.map((s) => s.label)}
            selectedOption={selectedSeriesFilter}
            onClick={(option) => setSelectedSeriesFilter(option)}
          />
        </Stack>
      }
    >
      {/* negative margin to align correctly with the title */}
      <Box sx={{ minHeight: '200px', ml: -0.75 }}>
        <Chart
          type='bar'
          data={{
            labels: showCountInLabel ? labelsWithDatasetNumbers : labels,
            datasets: [
              {
                data: selectedDataset.data,
                borderRadius: 4,
                backgroundColor: getColorVariations(BASE_COLOR, selectedDataset.data.length),
              },
            ],
          }}
          options={{
            indexAxis: 'y',
            scales: {
              y: {
                position: 'right',
                beginAtZero: true,
              },
              x: {
                ticks: {
                  precision: 0,
                },
              },
            },
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              datalabels: {
                display: false,
              },
            },
          }}
        />
      </Box>
    </WidgetSection>
  )
}
