import { Button, IconButton, InputAdornment, Stack, TextField } from '@mui/material'
import { CancelOutlined } from '@mui/icons-material'
import React from 'react'
import { PriceVolumePointInputs } from './PriceVolumeAdjustment'
import { FieldError } from './validations'
import { PriceVolumePoint } from '@common/types'
import { getCurrencySymbol } from '@utils'
import { isNumber } from 'lodash/fp'

interface Props {
  isEditMode: boolean
  point: PriceVolumePointInputs
  index: number
  currency: string
  updatePriceVolume: (value: string, index: number, key: keyof PriceVolumePointInputs) => void
  removeRow: (index: number) => void
  confirmRow?: (index: number, point: PriceVolumePointInputs) => void
  errors?: FieldError
  onBlur?: () => void
  onFocus?: (point: PriceVolumePoint | PriceVolumePointInputs) => void
}

export const PriceVolumeAdjustmentInputs = ({
  errors,
  point,
  index,
  currency,
  isEditMode,
  updatePriceVolume,
  removeRow,
  confirmRow,
  onBlur,
  onFocus,
}: Props) => {
  const currencySymbol = getCurrencySymbol(currency)

  return (
    <Stack direction='row' spacing={2}>
      <TextField
        onFocus={onFocus && isEditMode ? () => onFocus(point) : undefined}
        onBlur={onBlur ? onBlur : undefined}
        type='number'
        sx={{ maxWidth: '170px' }}
        disabled={!isEditMode}
        label='Price'
        value={isNumber(point.price) ? point.price.toFixed(2) : point.price}
        size='small'
        InputProps={{
          endAdornment: <InputAdornment position='end'>{currencySymbol}</InputAdornment>,
        }}
        error={errors?.includes('price')}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        onChange={(e) => updatePriceVolume(e.target.value, index, 'price')}
      />
      <TextField
        onFocus={onFocus && isEditMode ? () => onFocus(point) : undefined}
        type='number'
        sx={{ maxWidth: '170px' }}
        disabled={!isEditMode}
        label='Volume'
        value={point.volume}
        size='small'
        error={errors?.includes('volume')}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
        onChange={(e) => updatePriceVolume(e.target.value, index, 'volume')}
      />
      {isEditMode ? (
        <Stack direction='row'>
          <IconButton color='error' onClick={() => removeRow(index)}>
            <CancelOutlined />
          </IconButton>
          {confirmRow ? (
            <Button
              color='success'
              onClick={() => confirmRow(index, point)}
              disabled={!!errors?.length}
              size='small'
              variant='text'
              sx={{ textTransform: 'capitalize', lineHeight: '1.5' }}
            >
              Add
            </Button>
          ) : null}
        </Stack>
      ) : null}
    </Stack>
  )
}
