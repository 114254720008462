import { DialogTitle, Divider, IconButton, IconButtonProps, Stack, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'

interface Props {
  title: string
  onClose: IconButtonProps['onClick']
}

export const PactumDialogHeader = ({ title, onClose }: Props) => {
  return (
    <>
      <DialogTitle
        sx={{
          px: 4,
          pt: 2.625, // This is because top padding is calculated from baseline of the text, 21px makes the gap 48px
          pb: 0,
        }}
      >
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Typography variant='h2' sx={{ lineHeight: '1.5rem' }}>
            {title}
          </Typography>
          <IconButton onClick={onClose} sx={{ mr: -1, color: 'text.primary' }} title='Close'>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Divider sx={{ pt: 0.25 }} />
    </>
  )
}
