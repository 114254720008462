import React, { ChangeEvent, useState } from 'react'
import { Box, FormControl, InputLabel, Select, SelectChangeEvent, TextField } from '@mui/material'
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro'
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'
import { RowValue } from 'src/merchandising/types'
import { asNumberOrNull } from '../../../main/utils/number'
import { operatorsNotNeedingValueInput } from './constants'

interface Props extends GridFilterInputValueProps<GridApiPro> {}

const rowDetailsFields: { key: keyof RowValue; name: string }[] = [
  { key: 'target', name: 'Negotiation Target' },
  { key: 'lac', name: 'Negotiation LAA' },
  { key: 'erfq', name: 'eRFQ' },
  { key: 'preliminary', name: 'Global' },
  { key: 'last_quarter', name: 'Last quarter' },
  { key: 'predict', name: 'Prediction' },
  { key: 'batna', name: 'LAA' },
  { key: 'stretch', name: 'Stretch' },
  { key: 'bau', name: 'BAU' },
]

export type InputValue = {
  value: number | null
  field: keyof RowValue
}

export const FilterInput = ({ item, applyValue }: Props) => {
  const [fieldValue, setFieldValue] = useState<InputValue>(
    item.value ?? { value: null, field: rowDetailsFields[0].key },
  )

  const onSelectedFieldChange = (e: SelectChangeEvent<keyof RowValue>) => {
    const newSelectedField = e.target.value as keyof RowValue

    setFieldValue({ ...fieldValue, field: newSelectedField })

    applyValue({
      ...item,
      value: { ...fieldValue, field: newSelectedField } as InputValue,
    })
  }

  const onFieldValueChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFieldValue({ ...fieldValue, value: asNumberOrNull(e.target.value) })

    applyValue({
      ...item,
      value: { ...fieldValue, value: asNumberOrNull(e.target.value) } as InputValue,
    })
  }

  const isValueInputNeeded = !operatorsNotNeedingValueInput.includes(item.operator ?? '')

  return (
    <Box sx={{ display: 'flex' }}>
      <FormControl>
        <InputLabel id='field-input-label'>Field</InputLabel>
        <Select
          labelId='field-input-label'
          value={fieldValue.field}
          variant='standard'
          onChange={(e) => onSelectedFieldChange(e)}
          native
        >
          {rowDetailsFields.map((row) => (
            <option key={row.key} value={row.key}>
              {row.name}
            </option>
          ))}
        </Select>
      </FormControl>
      {isValueInputNeeded && (
        <TextField
          label='Value'
          type='number'
          value={fieldValue.value !== null ? String(fieldValue.value) : ''}
          variant='standard'
          onChange={(e) => onFieldValueChange(e)}
        />
      )}
    </Box>
  )
}
