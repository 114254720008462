import { ArrowRight } from '@mui/icons-material'
import { ListItemIcon } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem'
import * as React from 'react'
import {
  ElementType,
  FocusEvent,
  forwardRef,
  HTMLAttributes,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

export type NestedMenuItemProps = Omit<MenuItemProps, 'button'> & {
  parentMenuOpen: boolean
  component?: ElementType
  label?: string
  children?: ReactNode
  className?: string
  tabIndex?: number
  disabled?: boolean
  ContainerProps?: HTMLAttributes<HTMLElement> & { ref: React.Ref<HTMLDivElement> | undefined }
  MenuProps?: Partial<Omit<MenuProps, 'children'>>
  button?: true | undefined
  checked: boolean
  checkboxValue: string
  onCheckboxChange: React.ChangeEventHandler<HTMLInputElement>
}

const NestedMenuParentItem = forwardRef<HTMLLIElement | null, NestedMenuItemProps>(
  function NestedMenuParentItem(props, ref) {
    const {
      parentMenuOpen,
      label,
      children,
      className,
      checked,
      checkboxValue,
      onCheckboxChange,
      tabIndex: tabIndexProp,
      ContainerProps: ContainerPropsProp = { ref: undefined },
      MenuProps,
    } = props

    const { ref: containerRefProp, ...ContainerProps } = ContainerPropsProp

    const menuItemRef = useRef<HTMLLIElement | null>(null)
    useImperativeHandle(ref, () => menuItemRef.current!) // eslint-disable-line @typescript-eslint/no-non-null-assertion

    const containerRef = useRef<HTMLDivElement | null>(null)
    useImperativeHandle(containerRefProp, () => containerRef.current!)

    const menuContainerRef = useRef<HTMLDivElement | null>(null)

    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false)

    const handleMouseEnter = (e: MouseEvent<HTMLElement>) => {
      setIsSubMenuOpen(true)

      if (ContainerProps.onMouseEnter) {
        ContainerProps.onMouseEnter(e)
      }
    }
    const handleMouseLeave = (e: MouseEvent<HTMLElement>) => {
      setIsSubMenuOpen(false)

      if (ContainerProps.onMouseLeave) {
        ContainerProps.onMouseLeave(e)
      }
    }

    // Check if any immediate children are active
    const isSubmenuFocused = () => {
      const active = containerRef.current?.ownerDocument.activeElement ?? null
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      for (const child of menuContainerRef.current!.children) {
        if (child === active) {
          return true
        }
      }

      return false
    }

    const handleFocus = (e: FocusEvent<HTMLElement>) => {
      if (e.target === containerRef.current) {
        setIsSubMenuOpen(true)
      }

      if (ContainerProps.onFocus) {
        ContainerProps.onFocus(e)
      }
    }

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        return
      }

      if (isSubmenuFocused()) {
        e.stopPropagation()
      }

      const active = containerRef.current?.ownerDocument.activeElement

      if (e.key === 'ArrowLeft' && isSubmenuFocused()) {
        containerRef.current?.focus()
      }

      if (e.key === 'ArrowRight' && e.target === containerRef.current && e.target === active) {
        const firstChild = menuContainerRef.current?.children[0] as HTMLDivElement
        firstChild?.focus()
      }
    }

    const open = isSubMenuOpen && parentMenuOpen

    // Root element must have a `tabIndex` attribute for keyboard navigation
    let tabIndex
    if (!props.disabled) {
      tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1
    }

    return (
      <div
        {...ContainerProps}
        ref={containerRef}
        onFocus={handleFocus}
        tabIndex={tabIndex}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onKeyDown={handleKeyDown}
      >
        <MenuItem className={className} ref={menuItemRef}>
          <Checkbox onChange={onCheckboxChange} checked={checked} value={checkboxValue} />
          <ListItemText primary={label} />
          <ListItemIcon>
            <ArrowRight />
          </ListItemIcon>
        </MenuItem>

        <Menu
          // Set pointer events to 'none' to prevent the invisible Popover div
          // from capturing events for clicks and hovers
          style={{ pointerEvents: 'none' }}
          anchorEl={menuItemRef.current}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          transformOrigin={{
            horizontal: 'left',
            vertical: 'top',
          }}
          open={open}
          autoFocus={false}
          disableAutoFocus
          disableEnforceFocus
          onClose={() => {
            setIsSubMenuOpen(false)
          }}
          {...MenuProps}
        >
          <div ref={menuContainerRef} style={{ pointerEvents: 'auto' }}>
            {children}
          </div>
        </Menu>
      </div>
    )
  },
)

NestedMenuParentItem.displayName = 'NestedMenuItem'
export { NestedMenuParentItem }
