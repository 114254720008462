import React from 'react'
import { GridColDef } from '@mui/x-data-grid-pro'
import { BenefitInputs, DefaultUserInput, ItemData } from '@common/types'
import { DataGridLoader } from 'src/shared/components/DataGridLoader'
import {
  DataGridPro,
  DataGridProProps,
  getGridStringOperators,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro'
import {
  getTargetLacRange,
  isTargetLacRangeValue,
  TargetLacRangePreFormat,
  targetLacRangeToString,
} from './utils'
import { TooltipCell } from 'src/merchandising/components/IndividualItemTable/TooltipCell'
import { styled, Tooltip, Typography } from '@mui/material'
import { HighlightedCell } from './HighlightedCell'
import { NameCell } from './NameCell'
import { CustomColumnHeaderFilterIconButton } from '@components/CustomColumnHeaderFilterIconButton/CustomColumnHeaderFilterIconButton'
import { laaComparator, numericFilterOperators } from './constants'
import { APP_HEADER_HEIGHT } from '@constants'
import { getMinMaxVolumes } from '@common/utils'
import { IsRfqCell } from './IsRfqCell'
import { CommentableBenefitCellWrapper } from './CommentableBenefitCellWrapper'

interface TableOwnProps {
  rows: ItemData[]
  itemErrors?: Record<ItemData['commercial_id'], boolean>
  columns: GridColDef<ItemData>[]
  isLoading: boolean
  extraColumns?: GridColDef<ItemData>[]
  extraColumnPlacement?: ExtraColumnPlacement
}

type TableProps = TableOwnProps & Partial<DataGridProProps>

type TableWrapperProps = {
  defaultValues?: DefaultUserInput
} & Omit<TableProps, 'columns'>

export const IndividualItemTable = (props: TableWrapperProps) => (
  <StyledIndividualItemTable
    {...props}
    columns={getColumns(props.itemErrors, props.defaultValues, props.extraColumns?.length)}
  />
)

const StyledIndividualItemTable = ({
  rows,
  columns,
  isLoading,
  extraColumns,
  extraColumnPlacement,
  ...otherProps
}: TableProps) => {
  let adjustedColumns = columns
  if (extraColumns?.length) {
    adjustedColumns =
      extraColumnPlacement === 'prepend'
        ? [...extraColumns, ...columns]
        : [...columns, ...extraColumns]
  }
  return (
    <StickyColumnHeaderDataGrid
      getRowId={(item: ItemData) => item.commercial_id}
      autoHeight
      hideFooter
      columns={adjustedColumns}
      rows={rows}
      loading={isLoading}
      slots={{
        loadingOverlay: DataGridLoader,
        columnHeaderFilterIconButton: CustomColumnHeaderFilterIconButton,
      }}
      slotProps={{
        filterPanel: {
          sx: {
            '& .MuiDataGrid-filterFormValueInput': {
              width: 320,
            },
          },
        },
      }}
      disableRowSelectionOnClick
      {...otherProps}
    />
  )
}

const emptyRowValues: TargetLacRangePreFormat = {
  target: '',
  lac: '',
  separator: '',
}

export const DEFAULT_COLUMNS: (GridColDef<ItemData> & {
  targetAndLacRangeGetter?: (item: ItemData) => 0 | TargetLacRangePreFormat | null
  tooltipGetter?: (item: ItemData) => string | undefined
})[] = [
  {
    field: 'name',
    headerName: 'Item',
    flex: 2,
    disableColumnMenu: true,
    minWidth: 200,
    filterOperators: getGridStringOperators(),
  },
  {
    field: 'invoice_prices',
    headerName: 'Invoice Price',
    disableColumnMenu: true,
    flex: 1,
    sortComparator: laaComparator,
    filterOperators: numericFilterOperators,
    targetAndLacRangeGetter: (item) =>
      item.enabled
        ? getTargetLacRange(item.invoice_prices, 'decimal', 2, item.currency ?? null)
        : emptyRowValues,
  },
  {
    field: 'ipr',
    headerName: 'Invoice Price Reduction',
    flex: 1,
    disableColumnMenu: true,
    valueGetter: ({ row }) => ({
      // needed for filtering to work when entering percentages instead of quotients
      target: 100 * row.ipr.target,
      lac: 100 * row.ipr.lac,
    }),
    sortComparator: laaComparator,
    filterOperators: numericFilterOperators,
    targetAndLacRangeGetter: (item) =>
      item.enabled ? getTargetLacRange(item.ipr, 'percent', 2) : emptyRowValues,
  },
  {
    field: 'net_prices',
    headerName: 'Net Price',
    flex: 1,
    disableColumnMenu: true,
    sortComparator: laaComparator,
    filterOperators: numericFilterOperators,
    targetAndLacRangeGetter: (item) =>
      item.enabled
        ? getTargetLacRange(item.net_prices, 'decimal', 2, item.currency ?? null)
        : emptyRowValues,
  },
  {
    field: 'is_rfq',
    type: 'boolean',
    editable: true,
    headerName: 'RFQ?',
    flex: 0.5,
    disableColumnMenu: true,
    filterable: false,
    sortable: false,
  },
  {
    field: 'volumes',
    headerName: 'Volume',
    renderHeader: () => (
      <Tooltip
        placement='top'
        title='Forecast volume corresponding to Net Price aggregated across markets'
      >
        <Typography variant='body2' sx={{ fontWeight: 500 }}>
          Volume
        </Typography>
      </Tooltip>
    ),
    flex: 1,
    disableColumnMenu: true,
    sortComparator: laaComparator,
    filterOperators: numericFilterOperators,
    targetAndLacRangeGetter: (item) =>
      item.enabled ? getTargetLacRange(item.volumes, 'decimal', 0) : emptyRowValues,
    tooltipGetter: (item) => {
      const minMaxVolumes = getMinMaxVolumes(item)
      if (!item.enabled || !minMaxVolumes) {
        return undefined
      }
      const { min, max } = minMaxVolumes
      return `Estimated volumes corresponding to Target and LAA Net Prices based on values in Net Price-Volume graph - where volume values range from ${max} to ${min}.
      Specifying price above corresponding maximum price-volume point will result in 0 volume for that price.`
    },
  },
  {
    field: 'gtd',
    headerName: 'GTD',
    flex: 1,
    disableColumnMenu: true,
    valueGetter: ({ row }) => row.benefits.gtd,
    sortComparator: laaComparator,
    filterOperators: numericFilterOperators,
    targetAndLacRangeGetter: (item) =>
      item.enabled
        ? getTargetLacRange(item.benefits.gtd, 'decimal', 2, item.currency ?? null)
        : emptyRowValues,
  },
  {
    field: 'gmf',
    headerName: 'GMF',
    flex: 1,
    disableColumnMenu: true,
    valueGetter: ({ row }) => row.benefits.gmf,
    sortComparator: laaComparator,
    filterOperators: numericFilterOperators,
    targetAndLacRangeGetter: (item) =>
      item.enabled
        ? getTargetLacRange(item.benefits.gmf, 'decimal', 2, item.currency ?? null)
        : emptyRowValues,
  },
  {
    field: 'ctd',
    headerName: 'CTD',
    flex: 1,
    disableColumnMenu: true,
    valueGetter: ({ row }) => row.benefits.ctd,
    sortComparator: laaComparator,
    filterOperators: numericFilterOperators,
    targetAndLacRangeGetter: (item) =>
      item.enabled
        ? getTargetLacRange(item.benefits.ctd, 'decimal', 2, item.currency ?? null)
        : emptyRowValues,
  },
  {
    field: 'cmf',
    headerName: 'CMF',
    flex: 1,
    disableColumnMenu: true,
    valueGetter: ({ row }) => row.benefits.cmf,
    sortComparator: laaComparator,
    filterOperators: numericFilterOperators,
    targetAndLacRangeGetter: (item) =>
      item.enabled
        ? getTargetLacRange(item.benefits.cmf, 'decimal', 2, item.currency ?? null)
        : emptyRowValues,
  },
]

type ExtraColumnPlacement = 'append' | 'prepend'

const COMMENTABLE_BENEFIT_FIELDS: (keyof BenefitInputs)[] = ['ctd', 'cmf']

const getColumns = (
  itemErrors?: Record<ItemData['commercial_id'], boolean>,
  defaultValues?: DefaultUserInput,
  extraColumns = 0,
): GridColDef[] => {
  if (defaultValues) {
    return DEFAULT_COLUMNS.map((column) => ({
      ...column,
      renderCell: (params: GridRenderCellParams<ItemData>) => {
        if (params.field === 'name') {
          return (
            <NameCell
              row={params.row}
              canChangeStrategy={extraColumns === 0}
              errorMsg={
                itemErrors && itemErrors[params.row.commercial_id] ? 'Item has error(s)' : undefined
              }
            />
          )
        }
        if (params.field === 'is_rfq') {
          return <IsRfqCell {...params} />
        }
        if (column.targetAndLacRangeGetter) {
          const targetAndLacRange = column.targetAndLacRangeGetter(params.row)
          if (!targetAndLacRange) {
            return getTooltipCell(targetAndLacRange, params)
          }
          return (
            <HighlightedCell
              defaultValues={defaultValues}
              row={params.row}
              id={params.id}
              value={targetAndLacRange}
              colDef={params.colDef}
              field={params.field}
              tooltip={column.tooltipGetter && column.tooltipGetter(params.row)}
            />
          )
        }

        return getTooltipCell(params.value, params)
      },
    })).map((column) => {
      if ((COMMENTABLE_BENEFIT_FIELDS as string[]).includes(column.field)) {
        return {
          ...column,
          renderCell: (params: GridRenderCellParams<ItemData>) => {
            return (
              <CommentableBenefitCellWrapper
                commercialId={params.row.commercial_id}
                benefit={column.field as keyof BenefitInputs}
                readonly={extraColumns > 0}
              >
                {column.renderCell(params)}
              </CommentableBenefitCellWrapper>
            )
          },
        }
      }
      return column
    })
  }

  return DEFAULT_COLUMNS.map((column) => ({
    ...column,
    renderCell: (params: GridRenderCellParams<ItemData, TargetLacRangePreFormat>) => {
      const content = isTargetLacRangeValue(params.value)
        ? targetLacRangeToString(params.value)
        : params.value

      return getTooltipCell(content, params)
    },
  }))
}

const getTooltipCell = (content: unknown, params: GridRenderCellParams) => {
  const contentString = content === null || content === undefined ? 'Unavailable' : String(content)

  return <TooltipCell content={contentString} colDef={params.colDef} />
}

const StickyColumnHeaderDataGrid = styled(DataGridPro)(({ theme }) => ({
  // https://github.com/mui/mui-x/issues/5189#issuecomment-1154864589 sticky column header row
  '& .MuiDataGrid-columnHeaders': {
    position: 'sticky',
    // Replace background colour if necessary
    backgroundColor: theme.palette.background.paper,
    // Display header above grid data, but below any popups
    zIndex: theme.zIndex.mobileStepper - 1,
    top: APP_HEADER_HEIGHT,
  },
  '& .MuiDataGrid-virtualScroller': {
    // Undo the margins that were added to push the rows below the previously fixed header
    marginTop: '0 !important',
  },
  '& .MuiDataGrid-main': {
    // Not sure why it is hidden by default, but it prevented the header from sticking
    overflow: 'unset',
  },
})) as typeof DataGridPro
