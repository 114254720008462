import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useImportNegotiationEventsMutation } from '../../../store/purchasing'
import { useValidationErrorEffect } from '@components/ValidationErrors'
import { useSnackbarSuccessEffect } from '@shared/hooks/useSnackbarSuccessEffect'
import { useRef } from 'react'
import { ProductType } from '@pactum/core-backend-types'
import { NegotiationsFileUpload } from '@procurement/components/NegotiationsUpload'

const MIME_TYPE_XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
const MIME_TYPE_CSV = 'text/csv'

const supportedFileTypesByProductTypeMap = {
  [ProductType.ContractCost]: [MIME_TYPE_XLSX],
  [ProductType.Purchasing]: [MIME_TYPE_CSV],
}

type Props = {
  onFinishUpload: () => void
  productType: ProductType.ContractCost | ProductType.Purchasing
}

export const NegotiationEventsImport = ({ onFinishUpload, productType }: Props) => {
  const { activeProjectTag } = useActiveProject()
  const activeProjectTagRef = useRef(activeProjectTag)
  const [importNegotiationEvents, { isLoading, isSuccess, error }] =
    useImportNegotiationEventsMutation()

  useSnackbarSuccessEffect(isSuccess, 'Negotiation events imported successfully!')
  useValidationErrorEffect(
    error,
    'Error occurred while importing negotiation events. Please try again later.',
  )

  return (
    <NegotiationsFileUpload
      callback={(file) => {
        importNegotiationEvents({ productType, projectTag: activeProjectTagRef.current, file })
          .then(onFinishUpload)
          .catch(onFinishUpload)
      }}
      isLoading={isLoading}
      fileTypes={supportedFileTypesByProductTypeMap[productType]}
    />
  )
}
