import { Box, Typography, useTheme } from '@mui/material'
import { DataGrid, dataGridProps } from 'src/shared/components/table'
import {
  ArticleWithInput,
  NegotiationAnalysis,
  SupplierCommodity,
  SupplierCommodityReflection,
} from '@common/types'
import { HorizontallyStackedBar, BarDataEntry } from 'src/shared/components/HorizontallyStackedBar'
import { useFormatter } from '@shared/hooks'
import {
  createSupplierReasoningColumns,
  createKnownReflectionsColumns,
  createDetailedCostModelColumns,
  FormattedCostData,
  SupplierCommodityReflectionWithCostShare,
} from './columns'

interface StackedBarTooltipProps {
  dataEntry: BarDataEntry
  price: number
}

const StackedBarTooltip = ({ dataEntry, price }: StackedBarTooltipProps) => {
  const formatter = useFormatter()
  return (
    <Box>
      <Typography variant='body2' component='div'>
        {dataEntry.title}:
      </Typography>
      <Typography variant='body2' component='div' fontWeight={700}>
        {formatter.percent0To1(dataEntry.value)}
      </Typography>
      <Typography variant='body2' component='div' fontWeight={700}>
        {formatter.currency(price * dataEntry.value)}
      </Typography>
    </Box>
  )
}

interface Props {
  article: ArticleWithInput
  analysis: NegotiationAnalysis
}

export const SupplierRequest = ({ article, analysis }: Props) => {
  const formatter = useFormatter()
  const theme = useTheme()

  const supplierCommodities: Record<string, SupplierCommodity> | undefined =
    article.purchasePriceChangeDetails.commodities?.supplierCommodities.reduce(
      (acc, cmd) => ({ ...acc, [cmd.commodityName]: cmd }),
      {},
    )

  const commoditiesReflections: Record<string, SupplierCommodityReflection> | undefined =
    analysis.supplierCommodityReflections?.[article.ean]?.reduce(
      (acc, reflection) => ({ ...acc, [reflection.commodityName]: reflection }),
      {},
    )

  const commodityReflectionsWithCostShare = analysis.supplierCommodityReflections?.[
    article.ean
  ]?.map((cmd) => ({
    ...cmd,
    costSharePercentage: supplierCommodities?.[cmd.commodityName].costSharePercentage,
  })) as SupplierCommodityReflectionWithCostShare[]

  const supplierCommoditiesWithIndexData =
    article.purchasePriceChangeDetails.commodities?.supplierCommodities.map((cmd) => ({
      ...cmd,
      indexData: commoditiesReflections?.[cmd.commodityName],
    }))

  const getFormattedDetailedCostData: () => FormattedCostData[] = () => {
    let previousType = ''
    let index = 0

    return Object.entries(analysis.detailedCostModel?.breakdown ?? {}).flatMap(
      ([costType, costCommodities]) =>
        costCommodities.map((commodity) => {
          const row = {
            ...commodity,
            index: index++,
            type: costType === previousType ? '' : costType, // we don't want to show the same type on every row
          }

          previousType = costType

          return row
        }),
    )
  }

  const costModelChartData: BarDataEntry[] = Object.entries(
    analysis.detailedCostModel?.breakdown ?? {},
  ).map(([costType, costData]) => ({
    title: costType,
    value: costData[0].percentOfTotalValue,
  }))

  const salesPrice = article.inputs.retailPrice.lac
  const supplierReasoningColumns = createSupplierReasoningColumns(formatter)
  const knownReflectionsColumns = createKnownReflectionsColumns(formatter)
  const detailedCostModelColumns = createDetailedCostModelColumns(formatter)

  return (
    <Box mt={4} width='100%'>
      <Typography variant='body2' fontWeight={700}>
        Supplier reasoning
      </Typography>
      <Box mt={0.5}>
        <Typography variant='body2' color={theme.palette.text.secondary}>
          {article.purchasePriceChangeDetails.commodities?.reasonOfPriceChange}
        </Typography>
        <Typography variant='body2' color={theme.palette.text.secondary}>
          {article.purchasePriceChangeDetails.commodities?.comment}
        </Typography>
      </Box>
      <Box mt={1}>
        <DataGrid
          {...dataGridProps}
          columns={supplierReasoningColumns}
          rows={supplierCommoditiesWithIndexData ?? []}
          getRowId={(row: SupplierCommodity) => row.commodityName}
          localeText={{
            noRowsLabel: 'No commodities present',
          }}
          rowHeight={24}
          columnHeaderHeight={34}
        />
      </Box>
      <Box mt={3} mb={1}>
        <Typography variant='body2' fontWeight={700}>
          Known commodities and reflections
        </Typography>
      </Box>
      <DataGrid
        {...dataGridProps}
        columns={knownReflectionsColumns}
        rows={commodityReflectionsWithCostShare ?? []}
        getRowId={(row: SupplierCommodityReflectionWithCostShare) => row.commodityName}
        localeText={{
          noRowsLabel: 'No commodities present',
        }}
        rowHeight={24}
        columnHeaderHeight={34}
      />
      <Box mt={3} mb={1}>
        <Typography variant='body2' fontWeight={700}>
          Detailed cost model
        </Typography>
      </Box>
      {costModelChartData.length > 0 && (
        <Box mb={1}>
          <HorizontallyStackedBar
            label={'New sales price: ' + formatter.currency(salesPrice)}
            data={costModelChartData}
            renderTooltip={(entry) => <StackedBarTooltip dataEntry={entry} price={salesPrice} />}
          />
        </Box>
      )}
      <DataGrid
        {...dataGridProps}
        columns={detailedCostModelColumns}
        rows={getFormattedDetailedCostData() ?? []}
        getRowId={(row: FormattedCostData) => row.index}
        localeText={{
          noRowsLabel: 'No cost model data',
        }}
        rowHeight={24}
        columnHeaderHeight={34}
      />
    </Box>
  )
}
