import { UserStatusDisplayName, UserStatus } from '@common/types/suite'

interface UserStatusStyle {
  color: string
  backgroundColor: string
}

const statusMap: Record<UserStatus, UserStatusStyle> = {
  expired: {
    color: '#FFFFFF',
    backgroundColor: '#CA0808',
  },
  active: {
    color: '#FFFFFF',
    backgroundColor: '#0B8E18',
  },
  invited: {
    color: '#FFFFFF',
    backgroundColor: '#8D8D8D',
  },
}

const defaultStyle: UserStatusStyle = {
  color: '#FFFFFF',
  backgroundColor: '#809EA5',
}

export const userStatusDisplayName = (status: UserStatus): string => UserStatusDisplayName[status]

export const userStatusStyle = (status: UserStatus): UserStatusStyle => {
  if (status in statusMap) {
    return statusMap[status]
  } else {
    return defaultStyle
  }
}
