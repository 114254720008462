import { ChartType, FilterData, FilterOption, FilterOptionValue, Price } from './types'
import { IndexToLabelMapping, ScenarioState } from '@common/types/scenario/ScenarioState'
import { ascendingOrder, descendingOrder } from '@common/utils'

export const mapToFilterValues = <
  T extends IndexToLabelMapping | ScenarioState['item_info'] | ScenarioState['market_info'],
>(
  rawValues: T,
  labelKey?: keyof T[keyof T],
): FilterOption<string>[] =>
  Object.entries(rawValues).map(([key, value]) => ({
    label: labelKey ? value[labelKey] : value,
    value: key,
    type: 'SINGLE',
  }))

export const mapConstantToFilterValues = <T extends Price | ChartType>(
  values: T[],
): FilterOption<T>[] =>
  values.map((value) => ({
    label: value,
    value: value,
    type: 'SINGLE',
  }))

export const getSortedIndicesToLabels = <T extends FilterOptionValue>(
  indicesToLabels: IndexToLabelMapping,
  ordering: typeof ascendingOrder | typeof descendingOrder = ascendingOrder,
): FilterOption<T>[] =>
  Object.keys(indicesToLabels)
    .slice()
    .sort(ordering(Number))
    .map(
      (index) =>
        ({
          label: indicesToLabels[Number(index)],
          value: index,
          type: 'SINGLE',
        }) as FilterOption<T>,
    )

export const isBenefitToSpendRatioAggregation = (filters: FilterData) =>
  filters.chartType?.value === 'Benefit to spend ratio'
