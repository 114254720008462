import { Box } from '@mui/material'
import { ErrorPage } from '../ErrorPage'

export const WorkspaceUnavailablePage = () => {
  return (
    <Box
      sx={{
        height: '50vh',
        position: 'fixed',
        zIndex: 1000,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ErrorPage title='Not available'>
        <p>Workspace currently not available.</p>
      </ErrorPage>
    </Box>
  )
}
