import { capitalizeFirstLetter } from '@common/utils/string'

export { capitalizeFirstLetter }

// makes a word pascal case, i.e. HELLO -> Hello
export const pascalCase = (word: string): string =>
  (word[0] ?? '').toUpperCase() + word.slice(1).toLowerCase()

export const isPopulatedString = (str: unknown): str is string => {
  if (str === null || str === undefined) {
    return false
  }
  return typeof str === 'string' && str.trim().length > 0
}
