import { useCallback } from 'react'
import { DEFAULT_TIME_FILTER, TimeFilters } from './Widgets/constants'
import { useSearchParams } from 'react-router-dom'

export const PERIOD_QUERY_PARAM = 'period'

export const useDashboardPeriodSelection = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const raw = searchParams.get(PERIOD_QUERY_PARAM)

  const setDashboardPeriod = useCallback(
    (value: TimeFilters) => {
      setSearchParams((params) => {
        if (!value) {
          params.delete(PERIOD_QUERY_PARAM)
        } else {
          params.set(PERIOD_QUERY_PARAM, value)
        }

        return params
      })
    },
    [setSearchParams],
  )

  return { period: raw ? (raw as TimeFilters) : DEFAULT_TIME_FILTER, setDashboardPeriod }
}
