export const round = (num: number, decimals = 2) => Number(num.toFixed(decimals))

export const roundToTwoDecimals = (num: number | null): number | null =>
  num !== null ? round(num) : null

export const nullSafeRoundNumberToTwoDecimals = (num: number | null) =>
  num ? Number(num.toFixed(2)) : +num!

export const getChangePercentage = (currentValue: number, previousValue: number): number =>
  (currentValue - previousValue) / Math.abs(previousValue)
