import { PactumButton } from 'src/shared/components/PactumButton'
import { Box, Dialog, DialogContent, DialogTitle, MenuItem, Stack, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { NegotiationPreparation } from '@common/types'

type PpFormId = NegotiationPreparation['purchasePriceChangeForm']['id']

interface Props {
  open: boolean
  ids: PpFormId[]
  onSubmit: (id: PpFormId) => Promise<void>
  onClose: () => void
}

export const ChangeFormIdSelectionDialog = ({ open, ids, onSubmit, onClose }: Props) => {
  const [selectedId, setSelectedId] = useState<PpFormId>('')

  useEffect(() => {
    setSelectedId('')
  }, [open])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Select one PP form ID</DialogTitle>
      <DialogContent>
        <Box p={2}>
          <TextField
            select
            value={selectedId}
            label='Form ID'
            onChange={(e) => setSelectedId(e.target.value)}
            fullWidth
          >
            {ids.map((ppFormId) => (
              <MenuItem key={ppFormId} value={ppFormId}>
                {ppFormId}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Stack direction='row' p={2}>
          <PactumButton color='secondary' onClick={onClose}>
            Cancel
          </PactumButton>
          <PactumButton
            sx={{ ml: 1 }}
            disabled={!selectedId}
            onClick={async () => {
              selectedId && (await onSubmit(selectedId))
              onClose()
            }}
          >
            Create
          </PactumButton>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
