import React from 'react'
import { NegotiationEvent } from '../../store/types'
import { GridContainer } from '../GridContainer/GridContainer'
import { GridItem } from '../GridItem/GridItem'
import { DataCell } from '../DataCell/DataCell'
import { NoValueCell } from '@components/table'
import { NegotiationLink } from '../NegotiationLink/NegotiationLink'

type Props = {
  negotiationEvent: NegotiationEvent
}
export const SupplierSummary = ({ negotiationEvent }: Props) => {
  const { supplier } = negotiationEvent
  return (
    <GridContainer>
      <GridItem>
        <DataCell label='Supplier name' size='medium'>
          {supplier.name}
        </DataCell>
      </GridItem>
      <GridItem>
        <DataCell label='Supplier e-mail' size='medium'>
          {supplier?.email}
        </DataCell>
      </GridItem>
      <GridItem>
        <DataCell label='Supplier ID' size='medium'>
          {supplier?.externalId}
        </DataCell>
      </GridItem>
      <GridItem>
        <DataCell label='Negotiation' size='small'>
          <NegotiationLink
            negotiationEvent={negotiationEvent}
            negotiation={supplier?.negotiation}
          />
        </DataCell>
      </GridItem>
      <GridItem>
        <DataCell label='Overall feedback' size='small'>
          {supplier?.negotiation?.cesFeedback.comment ?? <NoValueCell />}
        </DataCell>
      </GridItem>
      <GridItem>
        <DataCell label='Rating' size='small'>
          {supplier?.negotiation?.cesFeedback.score ?? <NoValueCell />}
        </DataCell>
      </GridItem>
    </GridContainer>
  )
}
