import { AppEnv, AppRegion } from '../main/types/environment'

export const sharedEnvironment = {
  REACT_APP_DATADOG_CLIENT_TOKEN: import.meta.env.VITE_REACT_APP_DATADOG_CLIENT_TOKEN as string,
  REACT_APP_ENV: import.meta.env.VITE_REACT_APP_ENV as AppEnv,
  REACT_APP_REGION: import.meta.env.VITE_REACT_APP_REGION as AppRegion,
}

export const sharedBackendRelativePaths = {
  REACT_APP_SERVER_BASE_URL: '/api/v1',
  REACT_CORE_BACKEND_BASE_URL: '/core/api/v1',
  REACT_APP_PURCHASING_BACKEND_URL: '/purchasing/api/v1',
  REACT_APP_DIRECT_PROCUREMENT_BACKEND_URL: '/direct-procurement/api/v1',
}

export const logisticsBackendRelativePaths = {
  REACT_APP_LOGISTICS_BACKEND_URL: '/logistics/api/v1',
  REACT_APP_LOGISTICS_BACKEND_RELATIVE_WEBSOCKET_URL_PATH: '/logistics',
}
