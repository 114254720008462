export const getContractLengthOptions = (
  baseContractLength?: number | null,
  maxAllowedLength: number = 72,
) => {
  if (!baseContractLength) {
    return []
  }

  const largerMultiplesOfBase = (length: number) =>
    length > baseContractLength && length % baseContractLength === 0

  return [...Array(maxAllowedLength).keys()].filter(largerMultiplesOfBase)
}

export const adjustTotalForAlternative = (p: {
  baseContractLength: number
  total: number
  alternativeContractLength: number
  priceChangePercentage: number
  priceChangeDirection: 'increase' | 'decrease'
}) => {
  const percentScaled = p.priceChangePercentage / 100
  const proportion = p.alternativeContractLength / p.baseContractLength
  const scaledMaxTotal = proportion * p.total
  const priceChangeScale = p.priceChangeDirection === 'increase' ? 1 : -1

  return scaledMaxTotal + priceChangeScale * scaledMaxTotal * percentScaled
}
