import { UserOrganization } from '@pactum/core-backend-types'
import { LoadingPage } from '@pages/LoadingPage'
import { ManageUsersList } from './ManageUsersPage/ManageUsersList'
import { useOrgTag } from 'src/main/hooks/useOrgTag'
import { useGetOrganizationsQuery } from 'src/main/store/projects'
import { useGetOrganizationUsersQuery } from 'src/main/store/userManagement'
import { SettingsContainer } from './SettingsContainers'

export const UserManagementPage = () => {
  const createProjectMap = (orgs: UserOrganization[]): Record<number, string> => {
    return orgs.reduce<Record<number, string>>((acc, org) => {
      const projectMap = org.projects.reduce((projAcc, project) => {
        return { ...projAcc, [project.id]: project.name }
      }, {})
      return Object.assign(acc, projectMap)
    }, {})
  }

  const orgTag = useOrgTag()
  const { data: organizations } = useGetOrganizationsQuery()

  const {
    data: users,
    isLoading,
    refetch,
  } = useGetOrganizationUsersQuery({
    orgTag: orgTag,
  })

  const projects = createProjectMap(organizations ?? [])

  if (isLoading || !users) {
    return <LoadingPage />
  }

  return (
    <>
      <SettingsContainer py={3}>
        <ManageUsersList refetchUsers={refetch} users={users} projects={projects} />
      </SettingsContainer>
    </>
  )
}
