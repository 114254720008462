import { Chart as ChartJS } from 'chart.js'

import { useProjects } from '@hooks'
import { Dashboard } from '@pages/DashboardPage/Dashboard'
import { ErrorPage } from '@pages/ErrorPage'
import { LoadingPage } from '@pages/LoadingPage'

import { DashboardType } from '@pages/DashboardPage/DashboardPage'
import { fontFamily } from 'src/main/constants/theme/pactum'
import { useGetWorkspaceDisplayCurrenciesQuery } from 'src/main/store/configuration'

ChartJS.defaults.font.size = 10
ChartJS.defaults.font.family = fontFamily

export const DashboardPage = () => {
  const { activeProjectTag, loadingProjects } = useProjects()
  const { data: workspaceDisplayCurrencies, isLoading: workspaceDisplayCurrenciesLoading } =
    useGetWorkspaceDisplayCurrenciesQuery(
      { projectTag: activeProjectTag! },
      { skip: !activeProjectTag },
    )

  if (loadingProjects || workspaceDisplayCurrenciesLoading) {
    return <LoadingPage />
  }

  if (activeProjectTag) {
    return (
      <Dashboard
        type={DashboardType.PROJECT}
        tag={activeProjectTag}
        defaultCurrency={workspaceDisplayCurrencies![0]}
      />
    )
  }
  return (
    <ErrorPage title={`No active project found`}>
      <pre></pre>
    </ErrorPage>
  )
}
