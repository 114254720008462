import { Fragment, PropsWithChildren } from 'react'
import { CommercialTermsNegotiation, Signer } from '@campaigns/types'
import { Grid, Typography } from '@mui/material'
import { CommercialTermsTooltip } from '../../../components/CommercialTermsTooltip'

export const SignersTooltip = ({
  negotiation,
  children,
}: PropsWithChildren<{ negotiation: CommercialTermsNegotiation }>) => {
  const { docusignEnvelopeSigners } = negotiation.signatures

  return (
    <CommercialTermsTooltip title={<TooltipText signers={docusignEnvelopeSigners} />}>
      <span>{children}</span>
    </CommercialTermsTooltip>
  )
}

interface TooltipTextProps {
  signers: Signer[]
}

const TooltipText = ({ signers }: TooltipTextProps) => {
  return (
    <Grid container sx={{ m: 1 }}>
      {signers.map((signer) => (
        <Fragment key={signer.name}>
          <Grid item xs={8}>
            <Typography variant='body2'>{signer.name}:</Typography>
          </Grid>
          <Grid item xs={4}>
            {signer.hasSigned ? (
              <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                Signed
              </Typography>
            ) : (
              <Typography variant='body2'>Waiting</Typography>
            )}
          </Grid>
        </Fragment>
      ))}
    </Grid>
  )
}
