import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useLazyGitbookVisitorRedirectQuery } from '@store/gitbookVisitor'

export const useRedirectGitbook = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const locationParam = queryParams.get('location') || ''

  const [fetchRedirectUrl, { isFetching, error: apiError }] = useLazyGitbookVisitorRedirectQuery()
  const [redirectError, setRedirectError] = useState<Error | null>(null)

  useEffect(() => {
    const fetchAndRedirect = async () => {
      try {
        const { data } = await fetchRedirectUrl(locationParam)

        if (data?.redirectUrl) {
          window.location.href = data.redirectUrl // perform the redirection
        } else {
          setRedirectError(new Error('No redirect URL provided by the server.'))
        }
      } catch (err) {
        setRedirectError(
          err instanceof Error ? err : new Error('Unexpected error during redirection.'),
        )
      }
    }

    void fetchAndRedirect()
  }, [locationParam, fetchRedirectUrl])

  return {
    isFetching,
    error: redirectError || apiError,
  }
}
