import { Box, IconButton, InputAdornment, styled, TextField, Tooltip } from '@mui/material'
import { InputDefaultValues, TableColDef } from './RowDetailsPanel'
import { formatValue } from './utils'
import { PactumLoader } from 'src/shared/components/PactumLoader'
import { QueryStats } from '@mui/icons-material'
import { unavailableCellStyle } from './StyledCells'
import { InputError } from 'src/merchandising/types'
import { ErrorTooltip } from 'src/merchandising/components/ErrorTooltip'
import { getCurrencySymbol } from '@utils'

interface Props {
  column: TableColDef
  currentValues: InputDefaultValues
  currency?: string
  disabled?: boolean
  onChange: (value: number | null, columnKey: string) => void
  error: InputError
  isLoading?: boolean
  openPriceVolumeModal: () => void
}

export const EditableCell = ({
  column,
  disabled,
  currentValues,
  currency,
  onChange,
  error,
  isLoading,
  openPriceVolumeModal,
}: Props) => {
  const valueMissing = currentValues[column.key] === null
  const { isError, error: errorMsg } = error
  const unit = column.unit === 'currency' ? getCurrencySymbol(currency) : column.unit

  return (
    <InputContainer>
      {!column.editable ? (
        <Box sx={{ ...(valueMissing ? unavailableCellStyle : {}), pl: 1 }}>
          {valueMissing ? 'Unavailable' : formatValue(column, currentValues[column.key], currency)}
        </Box>
      ) : (
        <>
          <NoSpinnerTextField
            size='small'
            disabled={disabled}
            type='number'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end' sx={{ mt: isLoading ? '5px' : 0 }}>
                  {isLoading ? <PactumLoader sizePx={20} /> : unit}
                </InputAdornment>
              ),
            }}
            error={isError}
            value={currentValues[column.key] === null ? '' : currentValues[column.key]}
            onChange={(e) => {
              const value = e.target.value === '' ? null : Number(e.target.value)
              onChange(value, column.key)
            }}
          />
          {isError && <ErrorTooltip error={errorMsg} />}
        </>
      )}
      {column.key === 'volumes' ? (
        <>
          {isError && <ErrorTooltip error={errorMsg} />}
          <Tooltip title='View Net Price-Volume Model'>
            <IconButton size='small' sx={{ ml: 0.5 }} onClick={openPriceVolumeModal}>
              <QueryStats fontSize='small' />
            </IconButton>
          </Tooltip>
        </>
      ) : null}
    </InputContainer>
  )
}

const InputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: '56px',
  padding: theme.spacing(0, 1),
  borderBottom: '1px solid rgba(224, 224, 224, 1)',
}))

const NoSpinnerTextField = styled(TextField)({
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
  'input[type=number]': {
    MozAppearance: 'textfield',
  },
})
