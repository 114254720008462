import { CommercialTermsNegotiation } from '@campaigns/types/negotiation'
import { Formatter } from '@utils'
import { CaptionedValueCell, NotAvailableCell } from '@components/table'
import { AttachFile } from '@mui/icons-material'

export const commonColumnParameters = {
  disableColumnMenu: true,
  filterable: true,
  resizable: true,
  flex: 1,
}

export const getImprovementValue = (
  negotiation: CommercialTermsNegotiation,
  formatter: Formatter,
) => {
  const { achievedSavingsInCurrency, achievedSavingsPercent } = negotiation || {}

  if (!negotiation.currency) {
    return <NotAvailableCell />
  }

  if (['DEAL_REACHED', 'SIGNED'].includes(negotiation.status) && achievedSavingsInCurrency) {
    const suffix = achievedSavingsPercent && achievedSavingsPercent > 0 ? 'saved' : null
    return (
      <CaptionedValueCell
        mainContent={formatter.currency(achievedSavingsInCurrency, {
          currency: negotiation.currency,
        })}
        mainContentColor='success.main'
        captionContent={formatSavingsPercentCaption(achievedSavingsPercent, suffix)}
        spacing={0}
      />
    )
  }

  if (['NO_AGREEMENT', 'NOT_COMPLETED', 'CANCELLED'].includes(negotiation.status)) {
    return (
      <CaptionedValueCell
        mainContent={formatter.currency(0, { currency: negotiation.currency })}
        captionContent='No deal reached'
        spacing={0}
      />
    )
  }

  if (!negotiation.forecastedSavingsInCurrency) {
    return <NotAvailableCell />
  }

  return (
    <CaptionedValueCell
      mainContent={formatter.currency(negotiation.forecastedSavingsInCurrency, {
        currency: negotiation.currency,
      })}
      captionContent={formatSavingsPercentCaption(negotiation.forecastedSavingsPercent, 'expected')}
      spacing={0}
    />
  )
}

const formatSavingsPercentCaption = (
  savingsPercent: number | null | undefined,
  suffix: string | null,
) => {
  if (!savingsPercent) {
    return ''
  }
  const sign = savingsPercent < 0 ? '-' : '+'
  const formattedSuffix = suffix ? `, ${suffix}` : ''
  return `${sign}${Math.abs(savingsPercent)}%${formattedSuffix}`
}

export const AttachmentCaption = () => (
  <>
    <AttachFile style={{ fontSize: '10px' }} />
    Attachment
  </>
)
