import { CommonStatisticsHeaderData } from '@logistics/types/statisticsHeaderData'

export type SpotLoadStatisticsHeaderData = {
  agreed: number
  declined: number
} & CommonStatisticsHeaderData

export const isSpotLoadStatisticsData = (
  data?: Record<string, unknown>,
): data is SpotLoadStatisticsHeaderData => {
  return typeof data === 'object' && 'agreed' in data && 'declined' in data
}
