import { taskCompletionImage } from '@components/Images'
import React from 'react'
import { Box, Button, Grid, Stack, styled, Typography } from '@mui/material'

interface Props {
  title: string
  buttonText: string
  onButtonClick: () => void
}

export const NoEntities = ({ title, buttonText, onButtonClick }: Props) => {
  return (
    <Grid container justifyContent='center' alignItems='center'>
      <Grid item xs={8} pt={12}>
        <Stack direction='column' spacing={1.5} alignItems='center'>
          <Image
            src={taskCompletionImage as string}
            alt='Successful task completions image'
            loading='lazy'
          />
          <Typography variant='normal' color='text.secondary'>
            {title}
          </Typography>
          <Box width='auto'>
            <Button variant='contained' size='medium' color='accent' onClick={onButtonClick}>
              {buttonText}
            </Button>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  )
}

const Image = styled('img')({
  width: '100%',
  height: '100%',
  maxWidth: '288px',
})
