import React, { ReactElement } from 'react'
import { Tooltip, TooltipProps } from '@mui/material'

export type SimpleTooltip = Pick<TooltipProps, 'title' | 'enterDelay' | 'placement' | 'arrow'>

export interface TooltipWrapperProps {
  children: ReactElement
  tooltip?: SimpleTooltip
}

export const OptionalTooltipWrapper: React.FC<TooltipWrapperProps> = ({
  children,
  tooltip,
}: TooltipWrapperProps) => {
  return tooltip ? (
    <Tooltip
      {...{
        enterDelay: 1000,
        enterNextDelay: 1000,
        placement: 'right-end',
        arrow: true,
        ...tooltip,
      }}
    >
      {children}
    </Tooltip>
  ) : (
    children
  )
}
