import { useState } from 'react'
import { useGitbookVisitorRedirectQuery } from '@store/gitbookVisitor'

export const useOpenGitbook = () => {
  const { refetch, isFetching, error: apiError } = useGitbookVisitorRedirectQuery()
  const [redirectError, setRedirectError] = useState<Error | null>(null)

  const openGitbook = async () => {
    try {
      const { data } = await refetch() // fetch the redirect URL

      if (data?.redirectUrl) {
        window.open(data.redirectUrl, '_blank', 'noopener,noreferrer') // open in a new tab
      } else {
        setRedirectError(new Error('No redirect URL provided by the server.'))
      }
    } catch (err) {
      setRedirectError(
        err instanceof Error ? err : new Error('Unexpected error during GitBook redirection.'),
      )
    }
  }

  return {
    openGitbook,
    isFetching,
    error: redirectError || apiError,
  }
}
