import { Route } from 'react-router-dom'

import { Layout } from 'src/shared/components/Layout'
import { ProtectedRoute } from 'src/shared/components/ProtectedRoute'
import { Routes } from './routes'
import { DashboardPage } from '@pages/DashboardPage/DashboardPage'
import { NegotiationsListPage } from '@pages/NegotiationsPage/NegotiationListPage'
import { locales } from '@common/constants'
import { SuitePermission } from '@common/types'
import { NegotiationUnitsPage } from '@pages/NegotiationUnitsPage/NegotiationUnitsPage'

export const SuiteEntryPoint = () => {
  return (
    <>
      <Route element={<Layout locale={locales.us} />}>
        <Route
          path={Routes.DASHBOARD}
          element={
            <ProtectedRoute requiredPerm={SuitePermission.READ}>
              <DashboardPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={Routes.NEGOTIATION_LIST}
          element={
            <ProtectedRoute requiredPerm={SuitePermission.MODIFY}>
              <NegotiationsListPage unitsRoute={Routes.NEGOTIATION_UNITS} />
            </ProtectedRoute>
          }
        />
        <Route
          path={Routes.NEGOTIATION_UNITS}
          element={
            <ProtectedRoute requiredPerm={SuitePermission.MODIFY}>
              <NegotiationUnitsPage />
            </ProtectedRoute>
          }
        />
      </Route>
    </>
  )
}
