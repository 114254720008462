import { IconButton, SxProps, Theme } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'

interface Props {
  onDelete: () => void
  sx?: SxProps<Theme>
}

export const DeleteButton = ({ onDelete, sx }: Props) => (
  <IconButton size='small' onClick={onDelete} sx={{ ...sx, alignSelf: 'center' }}>
    <ClearIcon fontSize='inherit' />
  </IconButton>
)
