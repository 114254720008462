import { Format, PieChart } from '@common/dto/dashboard/dashboard'
import { Box, Stack } from '@mui/material'
import { CurrencyCode } from '@pactum/common'
import { Chart as ChartJS, Color, LegendItem, TooltipItem } from 'chart.js'
import { Chart } from 'react-chartjs-2'
import { useFilters } from '../useDashboardQueryParams'
import { getTicksFormatting } from './TimeSeriesWidget/TimeSeriesWidget'
import { WidgetSection } from './WidgetSection'
import { getColorVariations } from './colorUtils'
import { useState } from 'react'
import { FiltersList } from '@components/FiltersList'

interface Props {
  widgetConfig: PieChart
}

const BASE_COLOR = {
  r: 208,
  g: 144,
  b: 71,
}

const MAX_LEGEND_NAME_LENGTH = 24

export const PieChartWidget = ({ widgetConfig }: Props) => {
  const { title, subtitle, description, labels, series } = widgetConfig
  const colors = getColorVariations(BASE_COLOR, Object.keys(labels).length || 1)
  const { currency } = useFilters()

  const [selectedSeriesFilter, setSelectedSeriesFilter] = useState(series[0].label)
  const seriesLabels = series.map((s) => s.label).filter((label): label is string => !!label)
  const hasLabels = seriesLabels.length === series.length

  const selectedDataset = series.find((d) => d.label === selectedSeriesFilter)!
  const { format } = selectedDataset

  const filters = selectedSeriesFilter && hasLabels && (
    <Stack direction='row' justifyContent='end' gap={4}>
      <FiltersList
        options={seriesLabels}
        selectedOption={selectedSeriesFilter}
        onClick={(option) => setSelectedSeriesFilter(option)}
      />
    </Stack>
  )

  return (
    <WidgetSection title={title} subtitle={subtitle} description={description} filters={filters}>
      {/* negative margin to align correctly with the title */}
      <Box sx={{ minHeight: '200px', maxHeight: '200px', ml: -0.75 }}>
        <Chart
          type='pie'
          data={{
            labels: labels,
            datasets: [
              {
                data: selectedDataset.data.filter((d) => d >= 0),
                borderRadius: 4,
                backgroundColor: colors,
              },
              {
                data: selectedDataset.data,
                hidden: true,
                backgroundColor: colors,
              },
            ],
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            layout: {
              padding: {
                left: 0,
              },
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: (tooltipItem: TooltipItem<'line'>) =>
                    `${Intl.NumberFormat('en-US', getTicksFormatting(format, currency)).format(
                      tooltipItem.raw as number,
                    )}`,
                },
              },
              legend: {
                display: true,
                position: 'right',
                labels: {
                  usePointStyle: true,
                  font: {
                    size: 14,
                  },
                  padding: 20,
                  generateLabels: getGenerateLabels(format, currency),
                },
              },
              datalabels: {
                display: false,
              },
            },
          }}
        />
      </Box>
    </WidgetSection>
  )
}

const formatLabel = (
  name: string,
  share: number,
  value: number,
  format: Format,
  currency: CurrencyCode,
) => {
  const shareFormatted = Intl.NumberFormat('en-US', getTicksFormatting('percent', currency)).format(
    share,
  )
  const valueFormatted = Intl.NumberFormat('en-US', getTicksFormatting(format, currency)).format(
    value,
  )

  const nameShortenedIfNeeded =
    name.length > MAX_LEGEND_NAME_LENGTH ? `${name.slice(0, MAX_LEGEND_NAME_LENGTH)}...` : name

  return `${nameShortenedIfNeeded} | ${value < 0 ? '-' : shareFormatted} | ${valueFormatted}`
}

const getGenerateLabels =
  (format: Format, currency: CurrencyCode) =>
  (chart: ChartJS): LegendItem[] => {
    const labels = chart.data.labels
    const dataset = chart.data.datasets[1] // get the hidden dataset, which contains the actual data, not the filtered one w/o negative values
    const colors = dataset.backgroundColor as Array<Color>
    const total = dataset.data.reduce(
      (acc, curr) => (acc as number) + Math.max(curr as number, 0),
      0,
    ) as number
    if (!labels) return []
    return labels.map((label, idx) => {
      return {
        text: formatLabel(
          label as string,
          total !== 0 ? (dataset.data[idx] as number) / total : 0,
          dataset.data[idx] as number,
          format,
          currency,
        ),
        fillStyle: colors[idx],
        lineWidth: 0,
      } as LegendItem
    })
  }
