export enum SpotLaneNegotiationStatus {
  NOT_STARTED = 'NOT_STARTED',
  CLOSED = 'CLOSED',
  ONGOING = 'ONGOING',
  LINK_CALL = 'LINK_CALL',
  LINK_OPENED = 'LINK_OPENED',
  LINK_EMAIL_1 = 'LINK_EMAIL_1',
  ONGOING_CALL = 'ONGOING_CALL',
  CLOSED_MANUAL = 'CLOSED_MANUAL',
  CLOSED_EMAIL_1 = 'CLOSED_EMAIL_1',
  LINK_EMAIL_2_DEADLINE = 'LINK_EMAIL_2_DEADLINE',
  ONGOING_DEADLINE_EMAIL = 'ONGOING_DEADLINE_EMAIL',
  ONGOING_ESCALATE_CLIENT = 'ONGOING_ESCALATE_CLIENT',
  LINK_OPENED_DEADLINE_EMAIL = 'LINK_OPENED_DEADLINE_EMAIL',
  ONGOING_ESCALATE_INTERNAL_1 = 'ONGOING_ESCALATE_INTERNAL_1',
  ONGOING_ESCALATE_INTERNAL_2 = 'ONGOING_ESCALATE_INTERNAL_2',
}

export enum SpotLaneNegotiationToUnitStatus {
  SUCCESS = 'SUCCESS', // rate under limit
  FAILED = 'FAILED', // rate was captured but over limit
  DECLINED = 'DECLINED', // carrier declined to negotiate due to reason(s)
  NOT_STARTED = 'NOT_STARTED', // negotiation has not been finished
}

export enum SpotLanesUnitStatus {
  ACTIVE = 'ACTIVE', // unit not expired, no successful negotiation yet
  EXPIRED = 'EXPIRED', // unit expired, no successful negotiation
  SUCCESSFUL = 'SUCCESSFUL', // unit has successful negotiation
}

export type SpotLanePageResponseDto = {
  items: SpotLaneLaneResponseDto[]
  total: number
}

export interface SpotLaneLaneResponseDto {
  id: string
  status: SpotLanesUnitStatus
  originCode: string
  destinationCode: string
  equipmentType: string
  equipmentSize: string
  negotiations: SpotLaneLaneNegotiationDto[]
}

export interface SpotLaneNegotiationDto {
  id: string
  status: SpotLaneNegotiationStatus
  projectLocale: string
}

export interface SpotLanesBookingDto {
  id: string
  unitId: string
  lastCyEta: string | Date
  dischargeCode: string
  containerCount: string
  weight: string
  isHazardous: boolean
}

export interface SpotLaneNegotiationToUnitDto {
  unit_id: string
  negotiation_id: string
  status: SpotLaneNegotiationToUnitStatus
  currency: string
  distanceInMs: number
  limit: string
  modelCost: string
  customerName: string | null
  customerCode: string | null
  minVesselEta: string
  maxVesselEta: string
  bookings: SpotLanesBookingDto[]
}

export interface SpotLaneSupplierDto {
  id: string
  name: string
  primaryEmail: string
}

export interface SpotLaneLaneNegotiationDto {
  negotiation: SpotLaneNegotiationDto
  negotiationUnit: SpotLaneNegotiationToUnitDto
  supplier: SpotLaneSupplierDto
}
