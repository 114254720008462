import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-pro'
import * as React from 'react'
import { SyntheticEvent, useEffect, useRef } from 'react'
import { TIMEZONES } from '@shared/utils/timezone'
import { Autocomplete, Box, TextField } from '@mui/material'
import { SupplierContact } from '@logistics/types/Supplier'

export const TimezoneEditInputCell = (props: GridRenderCellParams<SupplierContact, string>) => {
  const { id, value, field, hasFocus } = props
  const apiRef = useGridApiContext()
  const ref = useRef<HTMLElement>()

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue })
  }

  useEffect(() => {
    if (hasFocus && ref.current) {
      const input = ref.current.querySelector<HTMLInputElement>(`input[value="${value}"]`)
      input?.focus()
    }
  }, [hasFocus, value])

  return (
    <Box sx={{ width: 300 }}>
      <Autocomplete
        value={value}
        options={TIMEZONES}
        disableClearable
        renderInput={(params) => <TextField variant={'standard'} {...params} size='small' />}
        onChange={handleChange}
      />
    </Box>
  )
}
