import { Formatter } from '@utils'
import { GridColDef } from '@mui/x-data-grid-pro'
import { SpotLaneLaneResponseDto, SpotLanesUnitStatus } from '@logistics/pages/spotLane/types'
import { Chip, Typography } from '@mui/material'
import { StatusConfig } from '@logistics/types'
import { equalsOneSelectedOptionFilterOperator } from '@components/FilterSelect/filterOperators'

export const STATUS_COLUMN_NAME = 'status'

const LANE_STATUS_CONFIG: StatusConfig<SpotLanesUnitStatus> = {
  ACTIVE: { label: 'Active', color: 'info' },
  SUCCESSFUL: { label: 'Successful', color: 'success' },
  EXPIRED: { label: 'Expired', color: 'error' },
}

export const createLaneColumns = (_formatter: Formatter): GridColDef<SpotLaneLaneResponseDto>[] => [
  {
    field: 'id',
    headerName: 'ID',
    hideable: false,
    filterable: false,
    width: 400,
    renderCell: ({ row }) => <Typography>{row.id}</Typography>,
  },
  {
    field: 'originCode',
    headerName: 'Origin',
    filterable: false,
    width: 150,
    renderCell: ({ row }) => <Typography>{row.originCode}</Typography>,
  },
  {
    field: 'destinationCode',
    headerName: 'Destination',
    filterable: false,
    width: 150,
    renderCell: ({ row }) => <Typography>{row.destinationCode}</Typography>,
  },
  {
    field: 'equipmentType',
    headerName: 'Equipment Type',
    filterable: false,
    width: 150,
    renderCell: ({ row }) => <Typography>{row.equipmentType}</Typography>,
  },
  {
    field: 'equipmentSize',
    headerName: 'Equipment Size',
    filterable: false,
    width: 150,
    renderCell: ({ row }) => <Typography>{row.equipmentSize}</Typography>,
  },
  {
    field: STATUS_COLUMN_NAME,
    headerName: 'Status',
    filterOperators: [
      equalsOneSelectedOptionFilterOperator(
        Object.values(SpotLanesUnitStatus),
        SpotLanesUnitStatus.ACTIVE,
      ),
    ],
    width: 200,
    renderCell: ({ row }) => (
      <Chip size='medium' variant='filled' {...LANE_STATUS_CONFIG[row.status]}></Chip>
    ),
  },
]
