import {
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { getCurrencySymbol } from '../../../../utils/currency'
import { useFormatter } from '@shared/hooks'
import { formatOptionalCurrency } from '@shared/utils/formatCurrency'
import { useContext, useEffect, useMemo } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { getTotalLineItemsPrice } from '../../utils/lineItems'
import { NegotiationSettings } from '@procurement/store/schema'
import { NegotiationEventFormContext } from '../../NegotiationEventFormContext'
import { FormRow, HideableBlock } from '../../../FormCommon/FormCommon'
import { NegotiationEventFormData } from '../../schema'

const NA = '-'

export const getDiscountedMaxTotal = (maxTotal: number, discount: number) =>
  discount > 0 ? maxTotal - maxTotal * (discount / 100) : maxTotal

export const getIncreasedMaxTotal = (maxTotal: number, increase: number) =>
  increase > 0 ? maxTotal + maxTotal * (increase / 100) : maxTotal

const MaxTotalThresholdTypeRadio = ({
  type,
}: {
  type: NegotiationSettings['maxTotalThresholdType']
}) => {
  const { field: maxTotalThresholdType } = useController({
    name: 'suppliers.0.negotiationSettings.maxTotalThresholdType',
  })

  return (
    <Radio
      checked={maxTotalThresholdType.value === type}
      value={type}
      name={maxTotalThresholdType.name}
      onChange={maxTotalThresholdType.onChange}
      onBlur={maxTotalThresholdType.onBlur}
      ref={maxTotalThresholdType.ref}
    />
  )
}

const ThresholdTypeTextField = ({
  name,
  type,
  currency,
  disabled,
}: {
  name: string
  type: NegotiationSettings['maxTotalThresholdType']
  currency?: string
  disabled?: boolean
}) => {
  const min = 0
  const max = type === 'absolute' ? undefined : 100
  const symbol = type === 'absolute' ? getCurrencySymbol(currency) : '%'
  const endAdornment = <InputAdornment position='end'>{symbol}</InputAdornment>
  const { register } = useFormContext()
  const field = register(name, { min, max, valueAsNumber: true })

  return (
    <Grid item xs={12} sx={{ pl: 5, mt: 0 }}>
      <TextField
        sx={{
          width: '70%',
          '& input': {
            height: 2,
          },
          '.MuiFormControl-root': {
            marginLeft: 1,
            marginRight: 1,
          },
        }}
        disabled={disabled}
        InputProps={{ endAdornment, type: 'number' }}
        inputProps={{ min, max }}
        name={field.name}
        onChange={field.onChange}
        onBlur={field.onBlur}
        ref={field.ref}
      />
    </Grid>
  )
}

export const TotalMaxPriceThresholds = () => {
  const theme = useTheme()
  const formatter = useFormatter()
  const { watch, setValue } = useFormContext<NegotiationEventFormData>()
  const { visibleFields } = useContext(NegotiationEventFormContext)
  const [currency, maxTotalThresholdType, lineItems, discount, increase] = watch([
    'currency',
    'suppliers.0.negotiationSettings.maxTotalThresholdType',
    'lineItems',
    'suppliers.0.negotiationSettings.minTotalPriceDiscountPercent',
    'suppliers.0.negotiationSettings.maxTotalPriceIncreasePercent',
  ])

  useEffect(() => {
    if (!maxTotalThresholdType) {
      // Default to Max total price
      setValue('suppliers.0.negotiationSettings.maxTotalThresholdType', 'absolute')
    }
  }, [maxTotalThresholdType, setValue])

  const totalPrice = useMemo(() => getTotalLineItemsPrice(lineItems ?? []), [lineItems])
  const discountedPrice = getDiscountedMaxTotal(totalPrice, discount ?? 0)
  const increasedPrice = getIncreasedMaxTotal(totalPrice, increase ?? 0)
  const selectedType = {
    absolute: maxTotalThresholdType === 'absolute',
    discount: maxTotalThresholdType === 'discount',
    increase: maxTotalThresholdType === 'increase',
  }

  return (
    <HideableBlock
      hidden={!visibleFields.includes('suppliers.0.negotiationSettings.maxTotalThresholdType')}
    >
      <FormRow>
        <Grid item xs={4} sx={{ mb: 1 }}>
          <Stack direction='column' spacing={1}>
            <FormControlLabel
              control={<MaxTotalThresholdTypeRadio type='absolute' />}
              label='Maximum total price'
            />
            <ThresholdTypeTextField
              disabled={!selectedType.absolute}
              name='suppliers.0.negotiationSettings.maxTotalPrice'
              type='absolute'
              currency={currency}
            />
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ mb: 1 }}>
          <Stack direction='column' spacing={1}>
            <FormControlLabel
              control={<MaxTotalThresholdTypeRadio type='discount' />}
              label='Minimum discount'
            />
            <ThresholdTypeTextField
              disabled={!selectedType.discount}
              name='suppliers.0.negotiationSettings.minTotalPriceDiscountPercent'
              type='discount'
              currency={currency}
            />
            <Typography variant='caption' color={theme.palette.lightGrey.main} sx={{ pl: 5 }}>
              Maximum total:{' '}
              {selectedType.discount
                ? formatOptionalCurrency(discountedPrice, formatter, currency)
                : NA}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4} sx={{ mb: 1 }}>
          <Stack direction='column' spacing={1}>
            <FormControlLabel
              control={<MaxTotalThresholdTypeRadio type='increase' />}
              label='Maximum price increase'
            />
            <ThresholdTypeTextField
              disabled={!selectedType.increase}
              name='suppliers.0.negotiationSettings.maxTotalPriceIncreasePercent'
              type='increase'
              currency={currency}
            />
            <Typography variant='caption' color={theme.palette.lightGrey.main} sx={{ pl: 5 }}>
              Maximum total:{' '}
              {selectedType.increase
                ? formatOptionalCurrency(increasedPrice, formatter, currency)
                : NA}
            </Typography>
          </Stack>
        </Grid>
      </FormRow>
    </HideableBlock>
  )
}
