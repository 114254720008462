import { TextFieldCtrl } from '@components/Form'
import { useLayoutContext } from '@components/Layout'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNotifications } from '@hooks'
import { Check, Edit } from '@mui/icons-material'
import BackArrowIcon from '@mui/icons-material/ArrowBack'
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  styled,
} from '@mui/material'
import { SupportedChatLocale } from '@pactum/common'
import { useCreateBrandingMutation, useUpsertBrandingContentMutation } from '@store/branding'
import { useGetUserOrganizationsQuery } from '@store/userOrganizations'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { BrandingInnerContainer } from '../SettingsContainers'
import {
  BrandingPayload,
  CreateBrandingPayload,
  brandingDefaultsFormData,
  createBrandingSchema,
} from './types'

const DEFAULT_NAME = 'Business Identity Name'

const StyledInput = styled('input')(({ theme }) => ({
  fontSize: theme.typography.pxToRem(32),
  fontWeight: 600,
  fontFamily: 'Inter',
  border: 'none',
  padding: 0,
  margin: 0,
  outline: 'none',
  width: '100%',
  lineHeight: '1',
  borderBottom: `1px solid ${theme.palette.primary.main}`,
}))

const BrandingDetailsHeader = ({ canManageConfig }: { canManageConfig: boolean }) => {
  const navigate = useNavigate()
  const [isEditing, setEditing] = useState(false)
  const form = useFormContext<CreateBrandingPayload>()

  const toggleEditing = () => setEditing((prev) => !prev)

  return (
    <Stack
      direction='row'
      alignItems='center'
      sx={(theme) => ({
        my: theme.spacing(4),
        ml: theme.spacing(1.4),
      })}
    >
      <IconButton onClick={() => navigate(-1)}>
        <BackArrowIcon fontSize='small' sx={{ color: 'black' }} />
      </IconButton>

      <StyledInput
        {...form.register('label')}
        name='label'
        sx={{ display: isEditing ? 'block' : 'none', width: '512px' }}
      />

      <Typography
        variant='h3'
        sx={(theme) => ({
          fontSize: theme.typography.pxToRem(32),
          fontWeight: 600,
          display: isEditing ? 'none' : 'block',
          lineHeight: '1',
          padding: 0,
          margin: 0,
          marginRight: theme.spacing(1),
        })}
      >
        {form.getValues().label}
      </Typography>

      {canManageConfig && (
        <IconButton onClick={toggleEditing} disabled={isEditing && !form.formState.isValid}>
          {isEditing ? (
            <Check fontSize='small' color={form.formState.isValid ? 'success' : 'error'} />
          ) : (
            <Edit fontSize='small' sx={{ color: 'black' }} />
          )}
        </IconButton>
      )}
    </Stack>
  )
}

const languageNames = new Intl.DisplayNames(['en'], {
  type: 'language',
})

const locales = Object.values(SupportedChatLocale)
  .map((locale) => ({
    value: locale,
    label: languageNames.of(locale) || locale,
  }))
  .toSorted((a, b) => a.label.localeCompare(b.label))

export const CreateBranding = ({ canManageConfig }: { canManageConfig: boolean }) => {
  const { showSuccess, showError } = useNotifications()
  const { setSettings } = useLayoutContext()
  const { orgTag } = useParams<{
    orgTag: string
  }>()

  const [createBranding] = useCreateBrandingMutation()
  const [upsertBrandingContent] = useUpsertBrandingContentMutation()
  const { data: organizations } = useGetUserOrganizationsQuery()

  const [locale, setLocale] = useState<SupportedChatLocale>(locales[0].value)
  const navigate = useNavigate()
  const form = useForm<BrandingPayload>({
    defaultValues: {
      label: DEFAULT_NAME,
    },
    resolver: zodResolver(createBrandingSchema),
  })

  const updateLocale = (event: SelectChangeEvent) =>
    setLocale(event.target.value as SupportedChatLocale)

  const organization = useMemo(
    () => organizations?.find((o) => o.tag === orgTag),
    [organizations, orgTag],
  )

  const save = useCallback(async () => {
    if (!organization?.id) {
      return
    }

    const data = form.getValues()
    const formData = brandingDefaultsFormData()

    const createdBranding = await createBranding({
      label: data.label,
      description: data.description,
      organizationId: organization?.id,
    })

    if ('error' in createdBranding) {
      showError('Failed to save business identity')

      return
    }

    const createdContent = await upsertBrandingContent({
      brandingId: createdBranding.data.uuid,
      data: formData,
      locale,
    })

    if ('error' in createdContent) {
      showError('Failed to save business identity')

      return
    }

    showSuccess('Business identity created')

    navigate(
      generatePath('/:orgTag/settings/general/branding/:brandingId', {
        orgTag: orgTag as string,
        brandingId: createdBranding.data.uuid,
      }),
    )
  }, [
    createBranding,
    form,
    locale,
    navigate,
    orgTag,
    organization?.id,
    showError,
    showSuccess,
    upsertBrandingContent,
  ])

  useEffect(() => {
    setSettings({
      createBrandingDisabled: !form.formState.isValid,
      discardChanges: () => navigate(-1),
      createBranding: save,
    })
  }, [form.formState.isValid, navigate, save, setSettings])

  return (
    <>
      <FormProvider {...form}>
        <BrandingDetailsHeader canManageConfig={canManageConfig} />
        <BrandingInnerContainer>
          <TextFieldCtrl
            name='description'
            label='Description'
            multiline
            minRows={3}
            variant='outlined'
            margin='normal'
            sx={{ width: '512px' }}
            helperText='You can leave your notes what this entry is for'
            disabled={!canManageConfig}
          />
        </BrandingInnerContainer>

        <BrandingInnerContainer>
          <FormControl sx={(theme) => ({ width: 512, my: theme.spacing(4) })}>
            <InputLabel id='new-locale-label'>New locale</InputLabel>
            <Select
              labelId='new-locale-label'
              id='new-locale-label-select'
              label='New locale'
              value={locale}
              onChange={updateLocale}
              disabled={!canManageConfig}
            >
              {locales.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              Business identities need a primary language. You can add other languages later.
              Languages can not be removed.
            </FormHelperText>
          </FormControl>
        </BrandingInnerContainer>
      </FormProvider>
    </>
  )
}
