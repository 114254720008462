import { PactumButton } from 'src/shared/components/PactumButton'
import { alpha, ButtonProps, styled } from '@mui/material'

const DensePactumButton = (props: ButtonProps): JSX.Element => (
  <PactumButton {...props}>{props.children}</PactumButton>
)

export const DenseButton = styled(DensePactumButton)(({ theme }) => ({
  fontWeight: 700,
  borderRadius:
    typeof theme.shape.borderRadius === 'number'
      ? theme.shape.borderRadius / 2
      : theme.shape.borderRadius,
  backgroundColor: theme.palette.accent.main,
  fontSize: '0.625rem',
  lineHeight: '100%',
  whiteSpace: 'nowrap',
  minWidth: 'auto',
  padding: theme.spacing(0.5),
  boxShadow: 'none',
  ':hover': {
    boxShadow: 'none',
    backgroundColor: alpha(theme.palette.accent.main, 0.8),
  },
}))
