import NorthEastIcon from '@mui/icons-material/NorthEast'
import SouthWestIcon from '@mui/icons-material/SouthWest'
import { useTheme } from '@mui/material'

interface Props {
  proportionalChange: number
  color: 'green' | 'red' | undefined
}

export const PriceChangeDirectionIcon = ({
  proportionalChange,
  color,
}: Props): JSX.Element | null => {
  const theme = useTheme()
  const style = { fontSize: theme.typography.caption, color: color }
  const hasIncreased = proportionalChange > 0
  const hasDecreased = proportionalChange < 0
  if (hasIncreased) {
    return <NorthEastIcon sx={style} />
  }
  if (hasDecreased) {
    return <SouthWestIcon sx={style} />
  }
  return null
}
