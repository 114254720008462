import { GridColDef } from '@mui/x-data-grid-pro'
import { CommercialTermsNegotiation } from '@campaigns/types'
import { commonColumnParameters } from '../helpers'

export const getMultiContractColumns = (): GridColDef<CommercialTermsNegotiation>[] => [
  {
    ...commonColumnParameters,
    field: 'numberOfContracts',
    headerName: 'Number of contracts',
    valueGetter: (cell) => cell.row.contracts.length,
  },
  {
    ...commonColumnParameters,
    field: 'numberOfAgreedContracts',
    headerName: 'Number of Agreed contracts',
    valueGetter: (cell) =>
      cell.row.contracts.filter((contract) => contract.status === 'AGREED').length,
  },
]
