import { Grid, InputAdornment, Typography } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { NumberFieldCtrl } from '@components/Form'
import { CurrencyPickerCtrl } from '@components/Form/CurrencyPickerCtrl'
import { getCurrencySymbol } from '@procurement/utils/currency'
import { FormRow } from '@procurement/components/FormCommon/FormCommon'
import { NegotiationEventSupplierFormData } from '../schema'

export const TotalPriceTarget = () => {
  const { watch } = useFormContext<NegotiationEventSupplierFormData>()
  const currency = watch('currency')

  return (
    <>
      <FormRow>
        <Grid item xs={12} md={12} sx={{ mt: 2 }}>
          <Typography variant='subtitle2' gutterBottom>
            Prices and limits
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <NumberFieldCtrl
            fullWidth
            helperText='We will try to bring the bid down to this price'
            variant='outlined'
            InputProps={{
              startAdornment: <CurrencySign selectedCurrency={currency} />,
            }}
            name='negotiationSettings.totalPriceTarget'
            label='Target price'
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CurrencyPickerCtrl
            label={'Currency'}
            name={'currency'}
            disabled={true}
            disableClearable
          />
        </Grid>
      </FormRow>
    </>
  )
}

const CurrencySign = ({ selectedCurrency }: { selectedCurrency: string }) => {
  return <InputAdornment position='start'>{getCurrencySymbol(selectedCurrency)}</InputAdornment>
}
