import {
  Autocomplete,
  AutocompleteChangeDetails,
  Checkbox,
  Divider,
  ListItemText,
  styled,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material'
import { FilterOption, Filters } from './types'
import { ALL_ITEMS_OPTION } from '../../constants'

interface MultiSelectFilterProps {
  value: FilterOption<string>[]
  options: FilterOption<string>[]
  label: string
  onChange: (selectedValues: FilterOption<string>[]) => void
  fieldProps?: TextFieldProps
  conf?: {
    renderAllOptionWhenAllSelected?: boolean
  }
}

export const MultiSelectFilter = ({
  value,
  options,
  label,
  onChange,
  fieldProps,
  conf,
}: MultiSelectFilterProps) => {
  const filterOutAllOption = (options: FilterOption<string>[]) =>
    options.filter((option) => !Filters.isAllSelection(option))

  const renderSelectedValuesLabel = () => {
    let label = value[0].label

    const allSingleSelected =
      value.filter(Filters.isSingleSelection).length ===
      options.filter(Filters.isSingleSelection).length
    if (conf?.renderAllOptionWhenAllSelected && allSingleSelected) {
      label = options.find(Filters.isAllSelection)?.label ?? ALL_ITEMS_OPTION.label
    } else if (value.length > 1) {
      label += ` + ${value.length - 1} more`
    }

    return (
      <Typography noWrap sx={{ maxWidth: '80%' }}>
        {label}
      </Typography>
    )
  }

  const handleValueSelect = (
    updatedOptions: FilterOption<string>[],
    selectedOption?: AutocompleteChangeDetails<FilterOption<string>>,
  ) => {
    const updatedOptionsWithoutAllOption = filterOutAllOption(updatedOptions)

    if (selectedOption?.option?.type && Filters.isAllSelection(selectedOption.option)) {
      const allValuesSelected = updatedOptionsWithoutAllOption.length === options.length - 1

      onChange(allValuesSelected ? [] : filterOutAllOption(options))
    } else {
      onChange(updatedOptionsWithoutAllOption)
    }
  }

  const getCheckboxIndeterminateState = (option: FilterOption<string>) => {
    if (Filters.isAllSelection(option)) {
      return value.length > 0 && value.length < options.length - 1
    }
  }

  const getCheckboxState = (option: FilterOption<string>) => {
    if (Filters.isAllSelection(option) && value.length === options.length - 1) {
      return true
    }

    return value.map(({ value }) => value).includes(option.value)
  }

  return (
    <StyledAutocomplete
      multiple
      disableCloseOnSelect
      options={options}
      value={value}
      renderTags={renderSelectedValuesLabel}
      isOptionEqualToValue={(option, value) => option.value === value?.value}
      renderInput={(params) => (
        <TextField
          {...params}
          {...fieldProps}
          label={label}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
      onChange={(_, selectedValues, reason, selectedOption) =>
        handleValueSelect(selectedValues, selectedOption)
      }
      renderOption={(props, option) => (
        <StyledAutocompleteOption {...props}>
          <Checkbox
            checked={getCheckboxState(option)}
            indeterminate={getCheckboxIndeterminateState(option)}
          />
          <ListItemText primary={option.label} />
          {option.withDivider && <StyledAutocompleteDivider />}
        </StyledAutocompleteOption>
      )}
    />
  )
}

const StyledAutocomplete = styled(Autocomplete)({
  ' .MuiFormControl-root .MuiInputBase-root': {
    flexWrap: 'nowrap',
  },
}) as typeof Autocomplete

const StyledAutocompleteOption = styled('li')({
  '&.MuiAutocomplete-option': {
    display: 'flex',
    flexWrap: 'wrap',
  },
})

const StyledAutocompleteDivider = styled(Divider)(({ theme }) => ({
  position: 'relative',
  left: `-${theme.spacing(2)}`,
  top: theme.spacing(0.75),
  width: `calc(100% + ${theme.spacing(4)})`,
}))
