import { Tooltip } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'

export const ErrorTooltip = ({ error }: { error?: string }) => {
  return error ? (
    <Tooltip placement='top' title={error}>
      <ErrorIcon sx={{ ml: 0.5 }} color='error' />
    </Tooltip>
  ) : null
}
