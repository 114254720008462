import { Tooltip } from '@mui/material'
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined'
import * as React from 'react'

interface Props {
  comment: string | undefined
}

export const CommentTooltip = ({ comment }: Props) => {
  if (!comment) {
    return null
  }
  return (
    <Tooltip
      title={comment}
      placement='top-start'
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }}
    >
      <ModeCommentOutlinedIcon sx={{ fontSize: '0.875rem' }} />
    </Tooltip>
  )
}
