import { MultiSelectFilter, MultiSelectItemValue } from './MultiSelectFilter'

interface CategoryFilterProps {
  categories: string[]
  selectedCategories: string[]
  onChange: (regions: string[]) => void
}

export const CategoryFilter = ({
  categories,
  selectedCategories,
  onChange,
}: CategoryFilterProps) => {
  const handleChange = (vals: MultiSelectItemValue[]) => onChange(vals as string[])

  return (
    <>
      <MultiSelectFilter
        label='Category'
        selectedAllLabel='All Categories'
        data={categories.map((category) => ({ value: category }))}
        setSelected={handleChange}
        selected={selectedCategories}
      />
    </>
  )
}
