import { CustomDisplayField } from '@common/types'
import { CommercialTermsNegotiation, CustomFieldValue } from '@campaigns/types'
import { Box, Grid, Typography } from '@mui/material'
import { useFormatter } from '@shared/hooks'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useGetProjectConfigQuery } from 'src/commercial-terms/store'

export const CustomFields = ({ negotiation }: { negotiation: CommercialTermsNegotiation }) => {
  const { activeProjectTag } = useActiveProject()

  const { data: projectConfig } = useGetProjectConfigQuery(activeProjectTag)
  const customDisplayFields = projectConfig?.customDisplayFields ?? []

  if (customDisplayFields.length === 0) {
    return null
  }

  return (
    <Box mt={5}>
      <Typography variant='body1' sx={{ fontWeight: 700 }}>
        Other information
      </Typography>
      <Grid container mt={1}>
        {customDisplayFields.map((field) => (
          <CustomField
            key={field.name}
            field={field}
            customFieldValue={negotiation.customFields?.[field.name] ?? null}
          />
        ))}
      </Grid>
    </Box>
  )
}

const CustomField = ({
  field,
  customFieldValue,
}: {
  field: CustomDisplayField
  customFieldValue: CustomFieldValue
}) => {
  return (
    <>
      <Grid item xs={6} mt={2}>
        {field.label}
      </Grid>
      <Grid item xs={6} mt={2}>
        <FieldValue fieldType={field.type} value={customFieldValue} />
      </Grid>
    </>
  )
}

const FieldValue = ({
  fieldType,
  value,
}: {
  fieldType: CustomDisplayField['type']
  value: CustomFieldValue
}) => {
  const formatter = useFormatter()

  if (value === null) {
    return <>N/A</>
  }

  if (fieldType === 'boolean') {
    return <>{value === true ? 'Yes' : 'No'}</>
  }

  if (fieldType === 'number') {
    return <>{formatter.number(value as number)}</>
  }

  if (fieldType === 'percent') {
    return <>{formatter.percent0To1((value as number) / 100)}</>
  }

  return <>{value}</>
}
