import { DraggableFormListCtrl, ListFormItem } from '@components/DraggableList'
import {
  Autocomplete,
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import { CurrencyCode, currencyNames } from '@pactum/common'
import { useState } from 'react'
import { useController } from 'react-hook-form'
import z from 'zod'

type DisplayCurrenciesListProps = {
  description: string
  disabled: boolean
  name: 'currencies'
}

const ButtonLabel = styled(`span`)(({ theme }) => ({
  color: theme.palette.darkGrey.main,
}))

const Schema = z.object({
  currencies: z.array(z.nativeEnum(CurrencyCode)).or(z.null()),
})

type Schema = z.infer<typeof Schema>

export const DisplayCurrenciesList = ({
  name,
  description,
  disabled,
}: DisplayCurrenciesListProps) => {
  const [selectedCurrencyItem, setSelectedCurrencyItem] = useState<ListFormItem | null>(null)
  const [showCurrencyPicker, setShowCurrencyPicker] = useState(false)
  const { field } = useController<Schema>({ name })

  const fieldValue = (field.value as CurrencyCode[]) ?? []

  return (
    <>
      <Typography variant='h3' component='h3' mb={2}>
        Display currencies
      </Typography>

      <FormHelperText sx={{ mb: 2 }}>{description}</FormHelperText>

      <DraggableFormListCtrl
        name={name}
        disabled={disabled}
        itemTransformer={(currency) => ({
          label: currencyNames[currency as CurrencyCode],
          value: currency as CurrencyCode,
        })}
        selected={selectedCurrencyItem}
        onSelect={(item) => setSelectedCurrencyItem(item)}
      />

      {showCurrencyPicker && (
        <Box mt={2}>
          <Autocomplete
            id='display-currency'
            disabled={disabled}
            options={Object.keys(CurrencyCode)}
            onChange={(_, val) => val && field.onChange([...fieldValue, val as CurrencyCode])}
            getOptionLabel={(key: string) => `${key} - ${currencyNames[key as CurrencyCode]}`}
            getOptionDisabled={(option) => fieldValue.includes(option as CurrencyCode)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                label='Available currencies'
                margin='dense'
              />
            )}
          />
        </Box>
      )}

      <Box display='flex' alignItems='center' gap={1} width='100%' mt={2}>
        <Button
          variant='outlined'
          color='tertiary'
          disabled={disabled || showCurrencyPicker}
          onClick={() => setShowCurrencyPicker(true)}
        >
          <ButtonLabel>+ Add currency</ButtonLabel>
        </Button>

        <Button
          variant='outlined'
          color='tertiary'
          disabled={selectedCurrencyItem === null}
          onClick={() => {
            field.onChange(
              fieldValue.filter((currency) => currency !== selectedCurrencyItem?.value),
            )
            setSelectedCurrencyItem(null)
          }}
        >
          <ButtonLabel>- Remove currency</ButtonLabel>
        </Button>

        {showCurrencyPicker && (
          <Button
            variant='outlined'
            color='tertiary'
            sx={{ marginLeft: 'auto' }}
            onClick={() => setShowCurrencyPicker(false)}
          >
            <ButtonLabel>Close</ButtonLabel>
          </Button>
        )}
      </Box>
    </>
  )
}
