import { useFieldArray, useFormContext } from 'react-hook-form'
import { ContractLengthAlternative } from '@procurement/store/types'
import { getContractLengthOptions } from '@procurement/utils/contractLength'
import { NegotiationEventFormData } from '../schema'

const DEFAULT_PERCENTAGE = -10
const DEFAULT_MAX_CONTRACT_LENGTH = 72
const FIELD = 'suppliers.0.negotiationSettings.contractLengthAlternatives'

export const useAlternativeContractLength = (
  {
    defaultPriceChangePercentage = DEFAULT_PERCENTAGE,
    maxContractLength = DEFAULT_MAX_CONTRACT_LENGTH,
    rebates,
  } = {
    defaultPriceChangePercentage: DEFAULT_PERCENTAGE,
    maxContractLength: DEFAULT_MAX_CONTRACT_LENGTH,
    rebates: false,
  },
) => {
  const { control, watch } = useFormContext<NegotiationEventFormData>()
  const alternatives = watch(FIELD) ?? []
  const contractLength = watch('suppliers.0.negotiationSettings.contractLength')

  const priceChangeInterval = rebates ? [0, 100] : [-100, 100]
  const [priceChangeMin, priceChangeMax] = [priceChangeInterval[0], priceChangeInterval[1]]

  const contractLengthOptions = getContractLengthOptions(contractLength, maxContractLength)

  const { replace, append } = useFieldArray({
    control,
    name: FIELD,
  })

  const onRowAdd = () => {
    const lastItem = alternatives[alternatives.length - 1]
    const nextId = lastItem ? lastItem.id + 1 : 1

    append({
      id: nextId,
      contractLengthMonths: contractLengthOptions[0],
      priceChangePercentage: defaultPriceChangePercentage,
    })
  }

  const clampPercentageValue = (value: number) =>
    Math.min(priceChangeMax, Math.max(priceChangeMin, value))

  const transformToPositivePercentageValue = (item: ContractLengthAlternative) => ({
    ...item,
    priceChangePercentage: clampPercentageValue(item.priceChangePercentage),
  })

  const onRowUpdate = (toUpdate: ContractLengthAlternative) => {
    const rows = alternatives
      .map((item) => (item.id === toUpdate.id ? toUpdate : item))
      .map(transformToPositivePercentageValue)

    replace(rows)
    return toUpdate
  }

  const onRowDelete = (id: number) => {
    const updated = alternatives.filter((item) => item.id !== id)

    replace(updated)
  }

  return {
    onRowAdd,
    onRowUpdate,
    onRowDelete,
  }
}
