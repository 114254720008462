import { isBackendApiErrorResponse } from '@shared/backend/error/typeGuards'
import pluralize from 'pluralize'
import { useSnackbar } from 'notistack'
import { NegotiationEvent } from '@price-list/store/types'
import { useStartNegotiationsMutation } from '@price-list/store/price-list'

export const enum NegotiationEventAction {
  START = 'START',
}

export const useNegotiationEventActions = (negotiationEvents: NegotiationEvent[]) => {
  const [startNegotiations] = useStartNegotiationsMutation()
  const { enqueueSnackbar } = useSnackbar()

  const projectTag = negotiationEvents[0]?.projectTag

  const availableActions: NegotiationEventAction[] = [
    ...new Set(negotiationEvents.flatMap(getAvailableActions)),
  ]

  const getNegotiationEventsForAction = (action: NegotiationEventAction) =>
    negotiationEvents.filter((it) => getAvailableActions(it).includes(action))

  const handleNegotiationEventStartAction = async () => {
    const negotiationEventIds = getNegotiationEventsForAction(NegotiationEventAction.START).map(
      ({ id }) => id,
    )
    try {
      await startNegotiations({
        projectTag,
        ids: negotiationEventIds,
      }).unwrap()
      enqueueSnackbar(
        `Negotiations started for ${pluralize('negotiation event', negotiationEventIds.length, true)}`,
        {
          variant: 'success',
        },
      )
    } catch (error) {
      setError(error, 'Error occurred while starting negotiations')
    }
  }

  const setError = (error: unknown, message: string) => {
    const errorMessage = isBackendApiErrorResponse(error) ? error.data.message : message
    enqueueSnackbar(errorMessage, { variant: 'error' })
  }

  return {
    availableActions,
    getNegotiationEventsForAction: getNegotiationEventsForAction,
    handleNegotiationEventStartAction: handleNegotiationEventStartAction,
  }
}

const getAvailableActions = ({ status }: NegotiationEvent) => {
  const actions: NegotiationEventAction[] = []

  if (status === 'READY') {
    actions.push(NegotiationEventAction.START)
  }

  return actions
}
