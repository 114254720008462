import { Country, countriesList } from '@pactum/common'
import { MultiSelectFilter, MultiSelectItemValue } from './MultiSelectFilter'

interface CountryFilterProps {
  countries: Country[]
  selectedCountries: Country[]
  onChange: (countries: Country[]) => void
}

export const CountryFilter = ({ countries, selectedCountries, onChange }: CountryFilterProps) => {
  const handleChange = (vals: MultiSelectItemValue[]) =>
    onChange(countriesList.filter((country) => vals.includes(country.iso3code)))

  return (
    <>
      <MultiSelectFilter
        label='Country'
        selectedAllLabel='All countries'
        data={countries.map((country) => ({ name: country.name, value: country.iso3code }))}
        setSelected={handleChange}
        selected={selectedCountries.map((country) => country.iso3code)}
      />
    </>
  )
}
