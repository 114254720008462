import { Grid, Tooltip, Typography } from '@mui/material'
import { PerformanceNumbers } from '@common/dto/dashboard/dashboard'
import { WidgetSection } from './WidgetSection'

interface Props {
  widgetConfig: PerformanceNumbers
}

export const PerformanceNumbersWidget = ({ widgetConfig }: Props) => {
  const { figures: figureGroups } = widgetConfig

  return (
    <WidgetSection
      title={widgetConfig.title}
      subtitle={widgetConfig.subtitle}
      description={widgetConfig.description}
      wide={false}
    >
      <Grid container>
        {figureGroups.map((figures, index) => (
          <Grid item xs={6} pr={3} pl={0} key={`grid-item-${index}`}>
            {figures.map((figure, figureIndex) => (
              <Tooltip
                title={figure.tooltip}
                placement='right'
                arrow
                key={`tooltip-${figureIndex}-${index}`}
              >
                <Grid container p={1}>
                  <Grid item xs={9}>
                    <Typography component='span' variant='normal'>
                      {figure.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} textAlign='right'>
                    <Typography component='span' variant='normal' noWrap>
                      {figure.primaryValue}
                    </Typography>
                  </Grid>
                </Grid>
              </Tooltip>
            ))}
          </Grid>
        ))}
      </Grid>
    </WidgetSection>
  )
}
