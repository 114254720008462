import { ItemData } from '@common/types'
import { ScenarioState } from '@common/types/scenario/ScenarioState'
import { useFormatter } from '@shared/hooks'
import { Box, ListItem, Tooltip, Typography } from '@mui/material'
import { StyledList } from '../components/StyledList'
import HelpIcon from '@mui/icons-material/Help'

interface Props {
  row: ItemData
  marketInfo: ScenarioState['market_info']
}

export const LastQuarterTooltip = ({ row, marketInfo }: Props) => {
  const formatter = useFormatter()
  if (
    !row.previous_invoice_price_details ||
    !row.previous_invoice_price_details.fyq ||
    !row.previous_invoice_price_details.values?.length
  ) {
    return null
  }

  const { fyq, values } = row.previous_invoice_price_details

  return (
    <Tooltip
      placement='top'
      title={
        <>
          <Typography color='inherit' fontSize='inherit' fontWeight='inherit'>
            The value is based on {fyq}
          </Typography>
          <StyledList>
            {values.map((value, index) => (
              <ListItem key={index} sx={{ fontSize: 'inherit', fontWeight: 'inherit' }}>
                {marketInfo[value.market_id].name} - {formatter.currency(value.price)}
              </ListItem>
            ))}
          </StyledList>
        </>
      }
    >
      <Box mt='2px'>
        <HelpIcon sx={{ mt: '1px', ml: 0.5, fontSize: '100%' }} />
      </Box>
    </Tooltip>
  )
}
