import React from 'react'
import { RowValidationError } from './RowValidationError'

const ValidationErrorsContext = React.createContext<{
  validationErrors: RowValidationError[]
  setValidationErrors: (errors: RowValidationError[]) => void
}>({ validationErrors: [], setValidationErrors: () => {} })

/**
 * Use this provider to wrap {@link ValidationErrorsBar}
 * and other components which make use of {@link useValidationErrorEffect} hook.
 */
export const ValidationErrorsProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [validationErrors, setValidationErrors] = React.useState<RowValidationError[]>([])

  return (
    <ValidationErrorsContext.Provider value={{ validationErrors, setValidationErrors }}>
      {children}
    </ValidationErrorsContext.Provider>
  )
}

/**
 * Returns two values: current validation errors and a function to set them.
 * Prefer using {@link useValidationErrorEffect} hook outside of components/ValidationErrors/ dir.
 */
export const useValidationErrors = () => React.useContext(ValidationErrorsContext)
