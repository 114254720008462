import { createAction, Priority, useRegisterActions } from 'kbar'
import { useSnackbar } from 'notistack'
import { useFixPactCurrencyRatesMutation } from 'src/platform/store/maintenance'

export function useFixPactRatesAction(isAdmin: boolean): void {
  const { enqueueSnackbar } = useSnackbar()
  const [fixPactCurrencyRates] = useFixPactCurrencyRatesMutation()

  useRegisterActions(
    [
      createAction({
        name: 'Fix Pact currency rates',
        section: 'Development',
        keywords: 'fix pact currency rates',
        priority: Priority.LOW,
        perform: async () => {
          try {
            const fixRatesResult = await fixPactCurrencyRates().unwrap()
            // if currenciesProcessed is empty, it means there are no currencies to fix left
            if (fixRatesResult.currenciesProcessed.length < 1) {
              enqueueSnackbar('No currencies to fix left', { variant: 'success' })
              return
            }
            console.log(fixRatesResult)
            enqueueSnackbar(
              `Fixed ${fixRatesResult.currenciesProcessed.length} rates, ${fixRatesResult.unfixedCurrenciesLeft} left`,
              { variant: 'success' },
            )
          } catch (e) {
            console.log(e)
            enqueueSnackbar(`Failed to fix rates, error: ${e}`, { variant: 'error' })
          }
        },
      }),
    ],
    [isAdmin],
  )
}
