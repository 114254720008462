import { createAction, Priority, useRegisterActions } from 'kbar'
import { generatePath, useNavigate } from 'react-router-dom'
// TODO: Remove dependency on product module
// eslint-disable-next-line import/no-restricted-paths
import { MerchandisingRoutes } from '../../../../merchandising/routes'
import { Organization } from '@pactum/core-backend-types'

export function useMerchandisingRouteActions(
  activeOrg: Pick<Organization, 'tag'>,
  isAdmin: boolean,
): void {
  const navigate = useNavigate()
  const orgTag = activeOrg.tag

  useRegisterActions(
    [
      createAction({
        name: 'Change Requests',
        section: 'Usecases',
        keywords: 'change',
        priority: Priority.NORMAL,
        perform: () =>
          navigate(
            generatePath(MerchandisingRoutes.CHANGE_REQUESTS_LIST, {
              orgTag,
            }),
          ),
      }),
      createAction({
        name: 'Cost Increase',
        section: 'Usecases',
        keywords: 'increase',
        priority: Priority.NORMAL,
        perform: () =>
          navigate(
            generatePath(MerchandisingRoutes.COST_INCREASE, {
              orgTag,
            }),
          ),
      }),
      createAction({
        name: 'Cost avoidance / increase (Generic demo)',
        section: 'Usecases',
        keywords: 'avoidance demo',
        priority: Priority.NORMAL,
        perform: () =>
          navigate(
            generatePath(MerchandisingRoutes.COST_AVOIDANCE, {
              orgTag,
            }),
          ),
      }),
    ],
    [isAdmin, orgTag],
  )
}
