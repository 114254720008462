import { ProductType, ProjectListEntity } from '@pactum/core-backend-types'
import { createApi } from '@reduxjs/toolkit/query/react'
import { renderProjectAsBaselineModule } from '@shared/projectsSpecific'
import { GenericSuiteModule } from '../main/types/suite-module'
import { PriceListEntryPoint } from './entrypoint'
import { priceListNavigationItems, PriceListRoutes } from './priceListRoutes'
import { SuitePermission } from '@common/types'
import { priceListApi } from './store/price-list'

export const priceListSuiteModule: GenericSuiteModule = {
  name: 'price-list',
  api: priceListApi as unknown as ReturnType<typeof createApi>,
  entrypoint: PriceListEntryPoint,
  basePath: 'price-list',
  projectSwitchPath: (_?: Set<SuitePermission>) => PriceListRoutes.DASHBOARD,
  isProjectCompatible: (project: ProjectListEntity) =>
    !renderProjectAsBaselineModule(project) &&
    project.compatibleWithNegotiationSuite &&
    project.productType === ProductType.DirectMaterialsPriceList,
  navigationItems: priceListNavigationItems,
}
