import { FormDialog } from '@components/FormDialog'
import { useUpdateNegotiationEventSupplierMutation } from '@procurement/store/purchasing'
import { NegotiationEvent, NegotiationEventSupplier, UseCase } from '@procurement/store/types'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { useEffect, useMemo, useRef, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { isBackendApiErrorResponse, isQuerySuccessResponse } from '@shared/backend/error/typeGuards'
import { validateNegotiationEventSupplierForm } from '@procurement/components/NegotiationEventSupplierForm/validation'
import { PostBidNegotiationEventSupplierForm } from '@procurement/components/NegotiationEventSupplierForm/PostBidNegotiationEventSupplierForm'
import { mapToUpdateNegotiationEventSupplierDto } from '@procurement/components/NegotiationEventSupplierForm/mappers'
import { NegotiationEventSupplierForm } from '@procurement/components/NegotiationEventSupplierForm/NegotiationEventSupplierForm'
import { useSyncNegotiationEventAttachments } from '@procurement/hooks/useSyncNegotiationEventAttachments'
import {
  NegotiationEventSupplierFormData,
  negotiationEventSupplierFormDataSchema,
} from '@procurement/components/NegotiationEventSupplierForm/schema'

interface Props {
  open: boolean
  onClose: () => void
  negotiationEvent: NegotiationEvent
  supplier: NegotiationEventSupplier
  useCase?: UseCase
}

export const UpdateSupplierDialog = ({
  open,
  onClose,
  negotiationEvent,
  supplier,
  useCase,
}: Props) => {
  const { activeProjectTag: projectTag } = useActiveProject()
  const { enqueueSnackbar } = useSnackbar()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const errorRef = useRef<HTMLDivElement>(null)

  const [updateNegotiationEventSupplier, { isLoading: isUpdateSupplierLoading }] =
    useUpdateNegotiationEventSupplierMutation()
  const [syncNegotiationEventAttachments, { isLoading: isSyncAttachmentsLoading }] =
    useSyncNegotiationEventAttachments()

  useEffect(() => {
    if (errorMessage) {
      errorRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [errorMessage, errorRef])

  const defaultValues = useMemo(
    () => ({
      id: supplier.id,
      attachments: supplier.attachments ?? [],
      contactName: supplier.contactName,
      currency: negotiationEvent.currency,
      detachedAttachments: supplier.attachments.map((attachment) => ({
        file: new File([], attachment.fileName),
        isVisibleToSupplier: attachment.isVisibleToSupplier,
      })),
      discount: supplier.initialPaymentTerms?.discount
        ? Number((supplier.initialPaymentTerms.discount * 100).toFixed(1))
        : undefined,
      discountDays: supplier.initialPaymentTerms?.discountDaysObject,
      paymentDays: supplier.initialPaymentTerms?.paymentDaysObject,
      email: supplier.email,
      externalId: supplier.externalId,
      lineItems: useCase === 'integratedPostBid' ? supplier.lineItems : [],
      locale: supplier.locale,
      name: supplier.name,
      negotiationSettings: {
        incentives: supplier.negotiationSettings?.incentives ?? [],
        maxPaymentDays: supplier.negotiationSettings?.maxPaymentDays,
        maxTotalPrice: supplier.negotiationSettings?.maxTotalPrice,
        minPaymentDays: supplier.negotiationSettings?.minPaymentDays,
        totalPriceTarget: supplier.negotiationSettings?.totalPriceTarget,
      },
      phone: supplier.phone,
      requisitionId: negotiationEvent.id,
    }),
    [supplier, negotiationEvent, useCase],
  )

  const form = useForm<NegotiationEventSupplierFormData>({
    defaultValues,
    resolver: zodResolver(negotiationEventSupplierFormDataSchema),
  })

  useEffect(() => {
    form.reset(defaultValues)
  }, [defaultValues])

  const update = async (data: NegotiationEventSupplierFormData) => {
    try {
      validateNegotiationEventSupplierForm(data)
    } catch (error) {
      setErrorMessage((error as Error).message)
      return
    }

    const response = await updateNegotiationEventSupplier({
      negotiationEventId: negotiationEvent.id,
      projectTag,
      supplierId: supplier.id,
      updateNegotiationEventSupplierDto: mapToUpdateNegotiationEventSupplierDto(data, supplier),
    })

    if (isQuerySuccessResponse(response)) {
      await syncNegotiationEventAttachments({
        data,
        negotiationEventId: negotiationEvent.id,
        supplierId: supplier.id,
      })
      onClose()
      enqueueSnackbar('Supplier updated successfully!', {
        variant: 'success',
      })
      setErrorMessage(null)
    } else {
      const message = isBackendApiErrorResponse(response.error)
        ? response.error.data.message
        : 'Error occurred while updating supplier'
      setErrorMessage(message)
    }
  }

  const cancel = () => {
    onClose()
    form.reset()
    setErrorMessage(null)
  }

  const onErrors = (errors: unknown) => {
    if (errors) {
      console.error('Form errors:', errors)
    }
  }

  return (
    <FormDialog
      buttons={[
        { type: 'cancel', label: 'Discard changes' },
        { type: 'submit', label: 'Save' },
      ]}
      error={errorMessage}
      errorRef={errorRef}
      form={form}
      fullWidth
      onCancel={cancel}
      onErrors={onErrors}
      onSubmit={update}
      loading={isUpdateSupplierLoading || isSyncAttachmentsLoading}
      maxWidth='md'
      open={open}
      title='Edit supplier'
    >
      {useCase === 'integratedPostBid' ? (
        <PostBidNegotiationEventSupplierForm />
      ) : (
        <NegotiationEventSupplierForm />
      )}
    </FormDialog>
  )
}
