import {
  Format,
  TimeSeriesStackedWidget as TimeSeriesStackedWidgetType,
} from '@common/dto/dashboard/dashboard'
import { Box, Stack, useTheme } from '@mui/material'
import { FiltersList } from '@shared/components/FiltersList'
import { useRef, useState } from 'react'
import { Chart } from 'react-chartjs-2'
import { WidgetSection } from '../WidgetSection'
import { BASE_COLOR, getColorVariations } from '../colorUtils'
import { getAggregatedCategoryData, getDaysGroupedByPeriod } from './timeSeriesUtils'
// eslint-disable-next-line import/no-unresolved
import { LegendBar } from '@pages/DashboardPage/Widgets/Legend/LegendBar'
import { legendExtractorPlugin } from '@pages/DashboardPage/Widgets/Legend/LegendExtractorPlugin'
import { useDashboardPeriod } from '@pages/DashboardPage/useDashboardPeriod'
import { LegendItem } from 'chart.js'
// eslint-disable-next-line import/no-unresolved
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'

export type CategoryDataset = {
  label: string
  periods: CategoryDataByPeriod
  format: Format
}

export type CategoryDataByPeriod = Record<string, Record<string, number>>

interface Props {
  widgetConfig: TimeSeriesStackedWidgetType
}

const transformPeriodsToDatasets = (periods: CategoryDataByPeriod) => {
  const datasets: Record<string, number[]> = {}

  for (const period in periods) {
    const periodData = periods[period]
    for (const category in periodData) {
      if (!datasets[category]) {
        datasets[category] = []
      }
      datasets[category].push(periodData[category])
    }
  }

  return datasets
}

export const TimeSeriesStackedWidget = ({ widgetConfig }: Props) => {
  const theme = useTheme()
  const { title, description, series, labels, days, style } = widgetConfig
  const [selectedSeriesFilter, setSelectedSeriesFilter] = useState(series[0].label)
  const selectedTimeFilter = useDashboardPeriod()

  const [legendItems, setLegendItems] = useState<LegendItem[]>([])

  const chartEl = useRef<ChartJSOrUndefined>(null)

  const getCategoryDatasets = (): CategoryDataset[] => {
    return series.map(({ label, func, format, arguments: fields }) => {
      const dataPointsByPeriod = getDaysGroupedByPeriod(days, selectedTimeFilter)

      return {
        label,
        periods: getAggregatedCategoryData(dataPointsByPeriod, labels, func, fields),
        format,
      }
    })
  }

  const categoryDatasets = getCategoryDatasets()
  const selectedCategory = categoryDatasets.find((d) => d.label === selectedSeriesFilter)!
  const datasetLabels = Object.keys(selectedCategory.periods)

  const datasets = transformPeriodsToDatasets(selectedCategory.periods)
  const backgroundColors = getColorVariations(BASE_COLOR, Object.keys(datasets).length || 1)

  const items = legendItems.map((tick) => ({
    datasetIndex: tick.datasetIndex,
    text: tick.text,
    textColor: typeof tick.fontColor === 'string' ? tick.fontColor : '#222222',
    bulletColor: typeof tick.fillStyle === 'string' ? tick.fillStyle : '#222222',
  }))

  const orderMap: Record<string, number> = {}

  items.forEach((item) => (orderMap[item.text] = items.length - (item?.datasetIndex ?? 0)))

  return (
    <WidgetSection
      title={title}
      description={description}
      filters={
        <Stack direction='row' justifyContent='end' gap={4}>
          <FiltersList
            options={series.map((s) => s.label)}
            selectedOption={selectedSeriesFilter}
            onClick={(option) => setSelectedSeriesFilter(option)}
          />
        </Stack>
      }
    >
      <Box sx={{ minHeight: '200px' }}>
        <Chart
          type={style === 'stacked-bar' ? 'bar' : 'line'}
          ref={chartEl}
          data={{
            labels: datasetLabels,
            datasets: Object.entries(datasets).map(([category, data], idx) => ({
              order: orderMap[category],
              label: category,
              data: data,
              borderRadius: 4,
              backgroundColor: backgroundColors.slice(0)[idx],
              pointStyle: 'circle',
              pointRadius: 3,
              pointHoverRadius: 15,
              borderWidth: 1,
            })),
          }}
          plugins={[legendExtractorPlugin]}
          options={{
            scales: {
              y: {
                position: 'right',
                beginAtZero: true,
              },
              x: {
                stacked: style === 'stacked-bar',
                grid: {
                  display: false,
                },
              },
            },
            maintainAspectRatio: false,
            plugins: {
              legendExtractor: {
                setLegendItems: (updatedLegendItems: LegendItem[]) => {
                  if (JSON.stringify(updatedLegendItems) !== JSON.stringify(legendItems)) {
                    setLegendItems(updatedLegendItems)
                  }
                },
              },
              legend: {
                display: false,
                labels: {
                  generateLabels: (chart) =>
                    chart.data.datasets.map((dataset, i) => {
                      const datasetVisible = chart.isDatasetVisible(i)
                      const label = dataset.label ?? ''
                      const disabledColor = theme.palette.grey[400]
                      const datasetBackgroundColor =
                        typeof dataset.backgroundColor === 'string'
                          ? dataset.backgroundColor
                          : '#222222'

                      return {
                        text: label,
                        lineWidth: 0,
                        fillStyle: datasetVisible ? datasetBackgroundColor : disabledColor,
                        fontColor: datasetVisible ? '#222222' : disabledColor,
                        datasetIndex: i,
                      }
                    }),
                },
              },
              datalabels: {
                display: false,
              },
            },
          }}
        />
      </Box>
      <Box>
        <LegendBar items={items} chart={chartEl.current} />
      </Box>
    </WidgetSection>
  )
}
