import { DateRange } from '@mui/x-date-pickers-pro'
import format from 'date-fns/format'
import { useCallback, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export const RANGE_START_PARAM = 'range_start'
export const RANGE_END_PARAM = 'range_end'

const DATE_FORMAT = 'yyyy-MM-dd'

type DashboardRangeSelectionProps = {
  range: {
    start: Date | null
    end: Date | null
  }
  setDashboardRange: (value: DateRange<Date>) => void
}

export const useDashboardRangeSelection = (): DashboardRangeSelectionProps => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [start, end] = [searchParams.get(RANGE_START_PARAM), searchParams.get(RANGE_END_PARAM)]

  useEffect(() => {
    if ([start, end].filter(Boolean).length === 1) {
      setSearchParams((params) => {
        params.delete(RANGE_START_PARAM)
        params.delete(RANGE_END_PARAM)
        return params
      })
    }
  }, [start, end, setSearchParams])

  const setDashboardRange = useCallback(
    (dates: DateRange<Date>) => {
      const [from, to] = dates

      setSearchParams((params) => {
        if (from && to) {
          try {
            params.set(RANGE_START_PARAM, format(from, DATE_FORMAT))
            params.set(RANGE_END_PARAM, format(to, DATE_FORMAT))
          } catch (ex) {
            params.delete(RANGE_START_PARAM)
            params.delete(RANGE_END_PARAM)

            console.warn(ex)
          }
        }

        if (from === null && to === null) {
          params.delete(RANGE_START_PARAM)
          params.delete(RANGE_END_PARAM)
        }

        return params
      })
    },
    [setSearchParams],
  )

  return {
    range: {
      start: start ? new Date(start) : null,
      end: end ? new Date(end) : null,
    },
    setDashboardRange,
  }
}
