export interface SpotLoadNegotiation {
  id: number
  supplier_id: string
  supplier_email: string
  supplier_name: string
  negotiation_link: string
  negotiation_test_link: string
  negotiation_review_link: string
  max_limit: number
  captured_price: number | null
  status: SpotLoadNegotiationStatus
  allowed_actions: SpotLoadNegotiationAction[]
}

export enum SpotLoadNegotiationStatus {
  EXPIRED = 'EXPIRED',
  ONGOING = 'ONGOING',
  CANCELLED = 'CANCELLED',
  LINK_OPENED = 'LINK_OPENED',
  LINK_EMAIL_0 = 'LINK_EMAIL_0',
  LINK_EMAIL_1 = 'LINK_EMAIL_1',
  CLOSED_MANUAL = 'CLOSED_MANUAL',
  AGREEMENT_REACHED = 'AGREEMENT_REACHED',
  AGREEMENT_DECLINED = 'AGREEMENT_DECLINED',
  NOT_STARTED = 'NOT_STARTED',
}

export const SPOT_LOAD_NEGOTIATION_STATUS_BY_INDEX: Record<SpotLoadNegotiationStatus, number> = {
  [SpotLoadNegotiationStatus.NOT_STARTED]: 0,
  [SpotLoadNegotiationStatus.LINK_EMAIL_0]: 1,
  [SpotLoadNegotiationStatus.LINK_EMAIL_1]: 2,
  [SpotLoadNegotiationStatus.LINK_OPENED]: 3,
  [SpotLoadNegotiationStatus.ONGOING]: 4,
  [SpotLoadNegotiationStatus.AGREEMENT_DECLINED]: 5,
  [SpotLoadNegotiationStatus.AGREEMENT_REACHED]: 6,
  [SpotLoadNegotiationStatus.CLOSED_MANUAL]: 7,
  [SpotLoadNegotiationStatus.CANCELLED]: 8,
  [SpotLoadNegotiationStatus.EXPIRED]: 9,
}

export enum SpotLoadNegotiationAction {
  START = 'START',
  DELETE = 'DELETE',
}

export function getNegotiationStatusIndex(status: SpotLoadNegotiationStatus): number {
  return SPOT_LOAD_NEGOTIATION_STATUS_BY_INDEX[status] ?? Number.MAX_SAFE_INTEGER
}
