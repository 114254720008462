import { Grid, Typography } from '@mui/material'
import { AutocompleteFieldCtrl, TextFieldCtrl } from '@components/Form'

type SupplierContactInformationProps = {
  showPhoneField?: boolean
  localeOptions?: string[]
}

export const SupplierContactInformation = ({
  showPhoneField = false,
  localeOptions,
}: SupplierContactInformationProps) => {
  const hasLocaleOptions = localeOptions && localeOptions.length > 0

  return (
    <>
      <Grid item xs={12} md={12}>
        <Typography variant='subtitle2' gutterBottom>
          Contact details
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextFieldCtrl
          name='name'
          label='Supplier company name'
          required
          fullWidth
          helperText='This is the company name of your supplier'
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextFieldCtrl
          name='externalId'
          label='Supplier ID (optional)'
          fullWidth
          helperText='Supplier ID is the code you use to identify the supplier'
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextFieldCtrl
          name='contactName'
          label='Supplier name (optional)'
          fullWidth
          helperText='Name of the person who will receive the emails'
        />
      </Grid>
      {showPhoneField && (
        <Grid item xs={12} md={6}>
          <TextFieldCtrl
            name='phone'
            label='Supplier phone number (optional)'
            fullWidth
            helperText='Contact number for the supplier'
          />
        </Grid>
      )}
      {hasLocaleOptions && (
        <Grid item xs={12} md={6}>
          <AutocompleteFieldCtrl
            helperText='Negotiations are held in this language'
            freeSolo={false}
            options={localeOptions}
            variant='outlined'
            fullWidth
            label='Negotiation language (optional)'
            name='locale'
          />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <TextFieldCtrl
          name='email'
          label='Supplier email address'
          required
          fullWidth
          type='email'
          helperText='Negotiation invitations are sent to this address'
        />
      </Grid>
    </>
  )
}
