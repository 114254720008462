import { RefObject } from 'react'
import { PactumDialogHeader } from '@components/PactumDialog'
import { PactumLoader } from '@components/PactumLoader'
import {
  Alert,
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  styled,
} from '@mui/material'
import {
  DeepPartial,
  FormProvider,
  useForm,
  UseFormReturn,
  FieldValues,
  Resolver,
  FieldErrors,
} from 'react-hook-form'

export interface FormDialogProps<T extends FieldValues> extends Omit<DialogProps, 'onSubmit'> {
  /**
   * The header text shown at the top of the dialog.
   */
  title?: string
  /**
   * Specifies the buttons at the bottom of the dialog, like:
   *
   *     buttons={[
   *       { type: 'submit', label: 'Save' },
   *       { type: 'cancel', label: 'Cancel' },
   *     ]}
   */
  buttons?: FormDialogButtonConfig[]
  /**
   * The react-hook-form context.
   * If not specified, a new context will be created.
   * If specified, the form will be controlled by the caller.
   *
   * See {@link https://react-hook-form.com/api/useform/useformcontext}
   * for more details.
   *
   * @default useForm()
   *
   * @example
   * const form = useForm({ defaultValues: { name: 'John' } })
   * return <FormDialog form={form} />
   */
  form?: UseFormReturn<T>
  /**
   * Initial values of form fields.
   */
  defaultValues?: DeepPartial<T>
  /**
   * Called when submit button is clicked and the form is valid.
   */
  onSubmit?: (data: T, form: UseFormReturn<T>) => void

  /**
   * Called when submit button is clicked and the form is invalid.
   * @param errors
   */
  onErrors?: (errors: FieldErrors<T>) => void
  /**
   * Called when cancel or "X" button is clicked.
   */
  onCancel?: (form: UseFormReturn<T>) => void
  /**
   * True to disable the form and show a loading overlay.
   */
  loading?: boolean
  /**
   * Resolver for form validation
   */
  resolver?: Resolver<T>
  /**
   * Error message to show at the bottom of the dialog.
   */
  error?: string | null
  /**
   * Ref to the errors container, could be helpful if you need to scroll it into view
   */
  errorRef?: RefObject<HTMLDivElement> | null
}

export interface FormDialogButtonConfig {
  type: 'submit' | 'cancel'
  label: string
}

/**
 * Displays a dialog with a form inside.
 *
 * It sets up the react-hook-form context,
 * so you job is to simply pass in form controls as children.
 *
 * Accepts all props that the MUI Dialog component accepts,
 * plus some additional props to control the form.
 * See {@link FormDialogProps}.
 *
 * Just like with MUI dialog, you need to set open={true} to show the dialog.
 *
 * To reset the form data, call form.reset() from the onCancel or onSubmit callbacks.
 */
export const FormDialog = <T extends object>({
  title = '',
  buttons = [],
  form: externalForm,
  defaultValues = {} as DeepPartial<T>,
  onSubmit = () => {},
  onErrors = () => {},
  onCancel = () => {},
  loading = false,
  error = null,
  errorRef,
  resolver,
  children,
  ...dialogProps
}: FormDialogProps<T>) => {
  const internalForm = useForm<T>({ defaultValues, resolver })
  const form = externalForm ?? internalForm

  const submitForm = form.handleSubmit(
    (data) => onSubmit(data, form),
    (errors) => onErrors(errors),
  )
  const cancelForm = () => {
    onCancel(form)
  }

  return (
    <FormProvider {...form}>
      <Dialog {...dialogProps}>
        <PactumDialogHeader title={title} onClose={cancelForm} />

        {/* DialogContent component must be child of Dialog. */}
        {/* This ensures that only the content area is scrollable. */}
        <FormDialogContent>
          {!!error && (
            <Alert ref={errorRef} sx={{ mb: 2 }} severity='error'>
              {error}
            </Alert>
          )}
          <form onSubmit={submitForm}>{children}</form>
        </FormDialogContent>

        <FormDialogButtons buttons={buttons} onSubmit={submitForm} onCancel={cancelForm} />
        {loading && <LoaderOverlay />}
      </Dialog>
    </FormProvider>
  )
}

interface FormDialogButtonsProps {
  buttons: FormDialogButtonConfig[]
  onSubmit: () => void
  onCancel: () => void
}

const FormDialogButtons = ({ buttons, onSubmit, onCancel }: FormDialogButtonsProps) => (
  <FormDialogActions>
    {buttons.map(({ type, label }) => {
      if (type === 'submit') {
        return (
          <SubmitButton key={label} onClick={onSubmit}>
            {label}
          </SubmitButton>
        )
      } else {
        return (
          <CancelButton key={label} onClick={onCancel}>
            {label}
          </CancelButton>
        )
      }
    })}
  </FormDialogActions>
)

const FormDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(2, 4, 0, 4),
}))

const FormDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2, 4),
}))

const SubmitButton = ({ onClick, children }: Pick<ButtonProps, 'onClick' | 'children'>) => (
  <Button variant='contained' size='large' color='secondary' onClick={onClick}>
    {children}
  </Button>
)

const CancelButton = ({ onClick, children }: Pick<ButtonProps, 'onClick' | 'children'>) => (
  <Button onClick={onClick}>{children}</Button>
)

const LoaderOverlay = () => (
  <Box
    sx={(theme) => ({
      zIndex: theme.zIndex.contentOverlay,
      position: 'absolute',
      height: '100%',
      width: '100%',
      backgroundColor: theme.palette.common.white,
      opacity: '0.5',
    })}
  >
    <PactumLoader sx={{ marginTop: '20%' }} />
  </Box>
)
