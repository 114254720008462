import { Box } from '@mui/material'
import { LAYOUT_CONTAINER_X_PADDING } from '@constants'
import { RFPList } from './RFPList/RFPList'

export const RFPListPage = () => {
  return (
    <Box px={LAYOUT_CONTAINER_X_PADDING} py={4}>
      <RFPList />
    </Box>
  )
}
