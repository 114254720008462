import { Environment } from '../main/types/environment'
import {
  logisticsBackendRelativePaths,
  sharedBackendRelativePaths,
  sharedEnvironment,
} from './shared-environment'

export const environment: Environment = {
  NODE_ENV: 'production',
  REACT_APP_AUTH0_DOMAIN: 'login.pactum.com',
  REACT_APP_AUTH0_CLIENT: '0a91TAPg29lkJIsagM1zaEDL57M1gAuv',
  REACT_APP_AUTH0_BACKEND_API: 'https://api.pactum.com/api/v1',
  REACT_APP_GOOGLE_PLACES_API_KEY: 'AIzaSyBEf0T67O9YLxPUq-C8tRuLd_I6pXP0f8U',
  ...sharedBackendRelativePaths,
  ...logisticsBackendRelativePaths,
  ...sharedEnvironment,
}
