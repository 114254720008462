export const NAME_MAPPING_DEFAULT = {
  unit: 'load',
}

export const applyNames = (
  text: string,
  mapping: Record<string, string> = NAME_MAPPING_DEFAULT,
): string => {
  let result = text

  Object.entries(mapping).forEach(([key, value]) => {
    result = result.replaceAll(key, value)

    const capitalKey = capitalize(key)
    const capitalValue = capitalize(value)

    result = result.replaceAll(capitalKey, capitalValue)
  })

  return result
}

const capitalize = (str: string) => str[0].toUpperCase() + str.slice(1)

export const camelCaseToTitleCase = (str: string) => {
  const result = str.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export const snakeCaseToSentenceCase = (str: string) => {
  return str
    .split('_')
    .map((word, index) => {
      if (index === 0) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      }
      return word.toLowerCase()
    })
    .join(' ')
}
