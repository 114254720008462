import { useFormContext } from 'react-hook-form'
import { Grid, Typography } from '@mui/material'
import { ProductType } from '@pactum/core-backend-types'
import { DatePickerCtrl, TextFieldCtrl } from '@components/Form'
import { CONTRACT_MODEL_PREFIX, getContractById } from '@procurement/utils/contractModel'
import { labelForProduct } from '@procurement/utils/labelForProduct'
import { HideableBlock } from '@procurement/components/FormCommon/FormCommon'
import { ContractModelOption } from '@procurement/components/NegotiationEventForm/sections/ContractModel/ContractModel'
import { NegotiationEventFormData } from '../../schema'

interface Props {
  productType?: ProductType
}

export const ContractModelFields = ({ productType }: Props) => {
  const { watch } = useFormContext<NegotiationEventFormData>()
  const selectedContractId = watch('contractModel.id')
  const selectedContract = getContractById(selectedContractId)
  const noPrefixProperties = getNoPrefixProperties(selectedContract)

  return (
    <Grid container spacing={2}>
      <HideableBlock hidden={!noPrefixProperties.includes('orderFormNumber')}>
        <Grid item xs={6}>
          <TextFieldCtrl
            name={`${CONTRACT_MODEL_PREFIX}.orderFormNumber`}
            label='Order form number'
            fullWidth
          />
        </Grid>
      </HideableBlock>
      <HideableBlock hidden={!noPrefixProperties.includes('previousContractDate')}>
        <Grid item xs={6}>
          <DatePickerCtrl
            name={`${CONTRACT_MODEL_PREFIX}.previousContractDate`}
            label='Original order form date'
            fullWidth
            timezone='UTC'
          />
        </Grid>
      </HideableBlock>
      <HideableBlock hidden={!noPrefixProperties.includes('buyerOrganisationLegalName')}>
        <Grid item xs={6}>
          <TextFieldCtrl
            name={`${CONTRACT_MODEL_PREFIX}.buyerOrganisationLegalName`}
            label='Buyer legal entity'
            fullWidth
          />
        </Grid>
      </HideableBlock>
      <HideableBlock hidden={!noPrefixProperties.includes('buyerOrganisationLegalAddress')}>
        <Grid item xs={6}>
          <TextFieldCtrl
            name={`${CONTRACT_MODEL_PREFIX}.buyerOrganisationLegalAddress`}
            label='Buyer legal entity address'
            fullWidth
          />
        </Grid>
      </HideableBlock>
      <HideableBlock hidden={!noPrefixProperties.includes('buyerSignerName')}>
        <Grid item xs={6}>
          <TextFieldCtrl
            name={`${CONTRACT_MODEL_PREFIX}.buyerSignerName`}
            label='Buyer signee'
            fullWidth
          />
        </Grid>
      </HideableBlock>
      <HideableBlock hidden={!noPrefixProperties.includes('buyerSignerEmail')}>
        <Grid item xs={6}>
          <TextFieldCtrl
            name={`${CONTRACT_MODEL_PREFIX}.buyerSignerEmail`}
            label='Email of the signee'
            fullWidth
          />
        </Grid>
      </HideableBlock>
      <HideableBlock hidden={!noPrefixProperties.includes('scopeOfServices')}>
        <Grid item xs={12} mt={2}>
          <Typography variant='subtitle2' fontSize='0.875rem'>
            Scope of services
          </Typography>
          <Typography variant='body2' my={2}>
            {labelForProduct(productType, 'contractScopeOfServicesDescription')}
          </Typography>
          <TextFieldCtrl
            name={`${CONTRACT_MODEL_PREFIX}.scopeOfServices`}
            label='Text goes here'
            fullWidth
            multiline
            rows={4}
          />
        </Grid>
      </HideableBlock>
    </Grid>
  )
}

const getNoPrefixProperties = (contract?: ContractModelOption) =>
  contract
    ? contract.properties.map((property) => property.replace(`${CONTRACT_MODEL_PREFIX}.`, ''))
    : []
