import { Checkbox, MenuItem, SelectChangeEvent } from '@mui/material'
import { FilterSelect } from '@components/FilterSelect'
import { allStatuses, statusText } from '../../negotiationStatus'
import { StatusChip } from '../StatusChip'
import { NegotiationStatus } from '@campaigns/types'

const NEGOTIATION_STATUSES = allStatuses()

export const StatusFilter = ({
  currentNegotiationStatuses,
  selectedStatuses,
  setSelectedStatuses,
}: {
  currentNegotiationStatuses: Set<NegotiationStatus>
  selectedStatuses: NegotiationStatus[]
  setSelectedStatuses: (statuses: NegotiationStatus[]) => void
}) => {
  const handleChange = (e: SelectChangeEvent<unknown>) => {
    setSelectedStatuses(e.target.value as NegotiationStatus[])
  }
  return (
    <FilterSelect
      label='Status'
      multiple={true}
      value={selectedStatuses}
      onChange={handleChange}
      isValuePresent={(value: unknown) => Array.isArray(value) && value.length > 0}
      renderValue={(value) => {
        if (selectedStatuses.length === 1) {
          return `Status: ${statusText(value as NegotiationStatus)}`
        }

        return `Status: ${selectedStatuses.length} selected`
      }}
    >
      {NEGOTIATION_STATUSES.filter((status) => currentNegotiationStatuses.has(status)).map(
        (status) => (
          <MenuItem key={status} value={status} sx={{ height: '32px' }}>
            <Checkbox
              size='small'
              checked={selectedStatuses.includes(status)}
              disableRipple={true}
            />
            <StatusChip status={status} />
          </MenuItem>
        ),
      )}
    </FilterSelect>
  )
}
