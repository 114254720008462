import React from 'react'
import { alpha, Button, ButtonProps, styled } from '@mui/material'

/** @deprecated */
export const PactumButton = (props: ButtonProps): JSX.Element => {
  return <StyledButton {...props} variant='contained' />
}

const StyledButton = styled(Button)(({ color }) => ({
  backgroundColor: `${color === 'secondary' ? '#D06D4C' : '#092330'}`,
  borderRadius: '100px',
  textTransform: 'none',

  ':hover': {
    backgroundColor: alpha(`${color === 'secondary' ? '#D06D4C' : '#092330'}`, 0.8),
  },
}))
