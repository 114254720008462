import { suiteBaseApi } from '@shared/api/suite-base-api'
import { CurrencyCode } from '@pactum/common'

type OrganizationConfiguration = {
  currencies?: CurrencyCode[] | null
  internalContactName?: string | null
  internalContactEmail?: string | null
}

type WorkspaceConfiguration = {
  currencies?: CurrencyCode[] | null
  timezone?: string | null
}

const configurationApi = suiteBaseApi
  .enhanceEndpoints({
    addTagTypes: [
      'WorkspaceConfiguration',
      'OrganizationConfiguration',
      'OrganizationDisplayCurrencies',
      'WorkspaceDisplayCurrencies',
      'Currencies',
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getCurrencies: build.query<
        {
          popularCurrencies: CurrencyCode[]
          displayCurrencies: CurrencyCode[]
          availableCurrencies: CurrencyCode[]
        },
        { orgTag: string; projectTag: string }
      >({
        query: ({ orgTag, projectTag }) =>
          `/configuration/currencies?orgTag=${orgTag}&projectTag=${projectTag}`,
        providesTags: ['Currencies'],
      }),
      getWorkspaceDisplayCurrencies: build.query<CurrencyCode[], { projectTag: string }>({
        query: ({ projectTag }) =>
          `/configuration/currencies/workspace/display?projectTag=${projectTag}`,
        providesTags: ['WorkspaceDisplayCurrencies'],
      }),
      getOrganizationDisplayCurrencies: build.query<CurrencyCode[], { orgTag: string }>({
        query: ({ orgTag }) => `/configuration/currencies/organization/display?orgTag=${orgTag}`,
        providesTags: ['OrganizationDisplayCurrencies'],
      }),
      getOrganizationConfiguration: build.query<OrganizationConfiguration, { orgTag: string }>({
        query: ({ orgTag }) => `/configuration/organization/${orgTag}`,
        providesTags: ['OrganizationConfiguration'],
      }),
      updateOrganizationConfiguration: build.mutation<
        OrganizationConfiguration,
        { orgTag: string; config: OrganizationConfiguration }
      >({
        query: ({ orgTag, config }) => ({
          url: `/configuration/organization/${orgTag}`,
          method: 'PATCH',
          body: config,
        }),
        invalidatesTags: [
          'OrganizationConfiguration',
          'Currencies',
          'OrganizationDisplayCurrencies',
        ],
      }),
      getWorkspaceConfiguration: build.query<WorkspaceConfiguration, { workspaceTag: string }>({
        query: ({ workspaceTag }) => `/configuration/workspace/${workspaceTag}`,
        providesTags: ['WorkspaceConfiguration'],
      }),
      updateWorkspaceConfiguration: build.mutation<
        WorkspaceConfiguration,
        { workspaceTag: string; config: WorkspaceConfiguration }
      >({
        query: ({ workspaceTag, config }) => ({
          url: `/configuration/workspace/${workspaceTag}`,
          method: 'PATCH',
          body: config,
        }),
        invalidatesTags: ['WorkspaceConfiguration', 'WorkspaceDisplayCurrencies', 'Currencies'],
      }),
    }),
  })

export const {
  useGetCurrenciesQuery,
  useGetWorkspaceDisplayCurrenciesQuery,
  useGetOrganizationDisplayCurrenciesQuery,
  useGetOrganizationConfigurationQuery,
  useUpdateOrganizationConfigurationMutation,
  useGetWorkspaceConfigurationQuery,
  useUpdateWorkspaceConfigurationMutation,
} = configurationApi
