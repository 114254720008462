import { FixCurrencyRatesResult } from '@common/dto/pact/fix-currency-rates-result'
import { suiteBaseApi } from '@shared/api/suite-base-api'

export const pactMaintenanceApi = suiteBaseApi
  .enhanceEndpoints({ addTagTypes: ['rates'] })
  .injectEndpoints({
    endpoints: (build) => ({
      fixPactCurrencyRates: build.mutation<FixCurrencyRatesResult, void>({
        query: () => ({
          url: `/pact/maintenance/currency/rates/fix`,
          method: 'PUT',
        }),
        invalidatesTags: ['rates'],
      }),
    }),
  })

export const { useFixPactCurrencyRatesMutation } = pactMaintenanceApi
