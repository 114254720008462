import { Button, Grid, styled } from '@mui/material'
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro'
import { useFormContext } from 'react-hook-form'
import { DataGrid, dataGridProps } from '@components/table'
import { ContractLengthAlternative } from '@procurement/store/types'
import { NegotiationEventFormData } from '../../schema'

export type ContractLengthGridProps = {
  columns: GridColDef<ContractLengthAlternative>[]
  onRowAdd: () => void
  onRowUpdate: (alternative: ContractLengthAlternative) => ContractLengthAlternative
}

export const ContractLengthGrid = ({ columns, onRowAdd, onRowUpdate }: ContractLengthGridProps) => {
  const { watch } = useFormContext<NegotiationEventFormData>()
  const alternatives = watch('suppliers.0.negotiationSettings.contractLengthAlternatives') ?? []
  const contractLength = watch('suppliers.0.negotiationSettings.contractLength')

  return (
    <>
      <Grid item xs={8}>
        <StyledDataGrid
          {...dataGridProps}
          columns={columns}
          rows={alternatives}
          localeText={{
            noRowsLabel: 'No alternative agreement lengths',
          }}
          getRowId={(row: ContractLengthAlternative) => row.id}
          processRowUpdate={onRowUpdate}
          sx={(theme) => ({
            minHeight: theme.spacing(16),
          })}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant='outlined'
          size='small'
          color='tertiary'
          onClick={onRowAdd}
          disabled={!contractLength}
        >
          + Add alternative agreement length
        </Button>
      </Grid>
    </>
  )
}

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  ' .MuiDataGrid-overlay': {
    minHeight: theme.spacing(13),
  },
  ' fieldset.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
})) as typeof DataGridPro
