import { useSnackbar } from 'notistack'
import { isBackendApiErrorResponse } from '@shared/backend/error/typeGuards'
import { useCreateNewSpotLanesNegotiationsMutation } from '@logistics/store/spotLanesApi'
import { useActiveProject } from '@shared/hooks/useActiveProject'

export const useNegotiationEventActions = () => {
  const { activeProjectTag } = useActiveProject()
  const { enqueueSnackbar } = useSnackbar()
  const [createNewNegotiations] = useCreateNewSpotLanesNegotiationsMutation()
  const setError = (error: unknown, message: string) => {
    const errorMessage = isBackendApiErrorResponse(error) ? error.data.message : message
    enqueueSnackbar(errorMessage, { variant: 'error' })
  }
  const handleNegotiationEventStartAction = async () => {
    try {
      await createNewNegotiations({ projectTag: activeProjectTag })
      enqueueSnackbar(`Negotiations started for project ${activeProjectTag}!`, {
        variant: 'success',
      })
    } catch (error) {
      setError(error, `Error occurred while starting negotiations for project ${activeProjectTag}`)
    }
  }
  return {
    handleNegotiationEventStartAction,
  }
}
