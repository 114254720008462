import { useParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { useGetNegotiationPreparationQuery } from 'src/merchandising/store/'

export const PricingStrategySummary = (): JSX.Element | null => {
  const { negotiationPreparationId } = useParams<{ negotiationPreparationId: string }>()
  const { data } = useGetNegotiationPreparationQuery(negotiationPreparationId!, {
    skip: !negotiationPreparationId,
  })

  if (!data) return null

  if (!data.analysis.pricingStrategy?.conclusionSentence) {
    console.warn('No data for conclusion sentence')
    return null
  }

  return (
    <Box mt={2}>
      <Typography variant='h5' mb={2}>
        Pricing Strategy Summary
      </Typography>
      <Typography mt={1} variant='body1' sx={{ whiteSpace: 'pre' }}>
        {data.analysis.pricingStrategy.conclusionSentence}
      </Typography>
    </Box>
  )
}
