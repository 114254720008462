import { Region } from '@pactum/common'
import { MultiSelectFilter, MultiSelectItemValue } from './MultiSelectFilter'

interface RegionFilterProps {
  regions: Region[]
  selectedRegions: Region[]
  onChange: (countries: Region[]) => void
}

export const RegionFilter = ({ regions, selectedRegions, onChange }: RegionFilterProps) => {
  const handleChange = (vals: MultiSelectItemValue[]) => onChange(vals as Region[])

  return (
    <>
      <MultiSelectFilter
        label='Region'
        selectedAllLabel='All regions'
        data={regions.map((region) => ({ name: region, value: region }))}
        setSelected={handleChange}
        selected={selectedRegions}
      />
    </>
  )
}
