import { styled, TablePagination } from '@mui/material'
import {
  gridExpandedRowCountSelector,
  GridFeatureMode,
  GridFilterModel,
  gridFilterModelSelector,
  gridPageSelector,
  gridPageSizeSelector,
  gridRowCountSelector,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from '@mui/x-data-grid-pro'
import { PaginationActionComponent } from '@components/table/pagination/PaginationActionComponent'
import { isPopulatedArray } from '@utils/array'

export const ROWS_PER_PAGE_OPTIONS = [10, 15, 25, 50]
export type RowsPerPageOption = (typeof ROWS_PER_PAGE_OPTIONS)[number]

interface PaginationFooterProps {
  rowsPerPageOptions?: number[]
}

export const PaginationFooter = ({ rowsPerPageOptions }: PaginationFooterProps) => {
  const apiRef = useGridApiContext()
  const gridRootProps = useGridRootProps()
  const page = useGridSelector(apiRef, gridPageSelector)
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector)
  const totalRowCount = useGridSelector(apiRef, gridRowCountSelector)
  const filteredRowsCount = useGridSelector(apiRef, gridExpandedRowCountSelector)
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector)
  const rowCount = isServerSidePagination(gridRootProps.paginationMode)
    ? totalRowCount
    : isFiltering(filterModel)
      ? filteredRowsCount
      : totalRowCount

  return (
    <StyledTablePagination
      component='div'
      color='primary'
      showFirstButton
      showLastButton
      rowsPerPage={pageSize}
      rowsPerPageOptions={rowsPerPageOptions ?? ROWS_PER_PAGE_OPTIONS}
      page={page}
      count={rowCount}
      onRowsPerPageChange={(event) => apiRef.current.setPageSize(Number(event.target.value))}
      onPageChange={(event, value) => apiRef.current.setPage(value)}
      ActionsComponent={() => PaginationActionComponent({ rowCount })}
    />
  )
}

function isServerSidePagination(gridFeatureMode: GridFeatureMode): boolean {
  return gridFeatureMode === 'server'
}

function isFiltering(filterModel: GridFilterModel): boolean {
  return (
    Object.values(filterModel.items).some((item) => item.value) ||
    isPopulatedArray(filterModel.quickFilterValues)
  )
}

const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
  width: '100%',
  '& .MuiTablePagination-spacer': {
    flex: 0,
  },

  '& .MuiInputBase-root': {
    marginLeft: 0,
    marginRight: theme.spacing(2),
  },
})) as typeof TablePagination
