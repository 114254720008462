import { NavigationItem } from 'src/main/types/suite-module'
import DashboardIcon from '@mui/icons-material/Dashboard'
import ReceiptLong from '@mui/icons-material/ReceiptLong'
import { SuitePermission } from '@common/types'

export enum ProcurementRoutes {
  DASHBOARD = '/:orgTag/procurement/:projectTag/dashboard',
  NEGOTIATION_EVENTS = '/:orgTag/procurement/:projectTag',
  NEGOTIATION_EVENT_DETAILS = '/:orgTag/procurement/:projectTag/event/:negotiationEventId',
}

export const procurementNavigationItems: NavigationItem[] = [
  {
    name: 'Negotiation events',
    icon: () => <ReceiptLong />,
    path: (activeOrgTag, activeProjectTag) => `/${activeOrgTag}/procurement/${activeProjectTag}`,
    requiredPermission: SuitePermission.MODIFY,
  },
  {
    name: 'Dashboard',
    icon: () => <DashboardIcon />,
    path: (activeOrgTag, activeProjectTag) =>
      `/${activeOrgTag}/procurement/${activeProjectTag}/dashboard`,
    requiredPermission: SuitePermission.READ,
  },
]
