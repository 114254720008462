import { useSnackbar } from 'notistack'
import { FileUpload, NegotiationStatus } from '@campaigns/types'
import { CommercialTermsNegotiation } from '@campaigns/types/negotiation'
import {
  useStartNegotiationsMutation,
  useDeleteNegotiationsMutation,
  useLazyDownloadNegotiationsResultsFileQuery,
  useGetProjectConfigQuery,
  useMarkNegotiationsSignedMutation,
  useMarkNegotiationsNotSignedMutation,
} from 'src/commercial-terms/store'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { downloadFile } from '@utils'
import {
  getFileSupplierIdentifier,
  hasAttachments,
  useDownloadAttachment,
} from '../utils/attachments'
import { enqueIfError } from '@shared/utils/snackbar'
import { useUserPermissions } from '../../main/hooks'
import { SuitePermission } from '@common/types'

export type NegotiationAction =
  | 'START'
  | 'DELETE'
  | 'DOWNLOAD'
  | 'VIEW'
  | 'DOWNLOAD_ATTACHMENT'
  | 'EDIT'
  | 'MARK_SIGNED'
  | 'MARK_NOT_SIGNED'

const viewAllowedActions: Set<NegotiationAction> = new Set([
  'DOWNLOAD',
  'VIEW',
  'DOWNLOAD_ATTACHMENT',
])

export const useNegotiationsActions = (negotiations: CommercialTermsNegotiation[]) => {
  const { userPermissionsInActiveOrg } = useUserPermissions()
  const canModify = userPermissionsInActiveOrg.has(SuitePermission.MODIFY)

  const { enqueueSnackbar } = useSnackbar()
  const [startNegotiations] = useStartNegotiationsMutation()
  const [markSigned] = useMarkNegotiationsSignedMutation()
  const [markNotSigned] = useMarkNegotiationsNotSignedMutation()
  const [deleteNegotiations] = useDeleteNegotiationsMutation()
  const [downloadResults] = useLazyDownloadNegotiationsResultsFileQuery()
  const downloadAttachment = useDownloadAttachment()
  const { activeProjectTag } = useActiveProject()

  const { data: projectConfig } = useGetProjectConfigQuery(activeProjectTag)
  const signatureType = projectConfig?.signatureType ?? null

  const availableActions: Set<NegotiationAction> = new Set(
    negotiations.flatMap((negotiation) =>
      getActionsForNegotiation(negotiation, signatureType ?? null, canModify),
    ),
  )

  const getNegotiationsForAction = (action: NegotiationAction) =>
    negotiations.filter((negotiation) =>
      getActionsForNegotiation(negotiation, signatureType ?? null, canModify).includes(action),
    )

  const handleStartNegotiations = async () => {
    const negotiationsToStart = getNegotiationsForAction('START').map(
      (negotiation) => negotiation.id,
    )

    try {
      return await startNegotiations({
        projectTag: activeProjectTag,
        negotiationIds: negotiationsToStart,
      }).unwrap()
    } catch (error) {
      return enqueIfError(error, enqueueSnackbar)
    }
  }

  const handleDeleteNegotiations = async () => {
    const negotiationsToDelete = getNegotiationsForAction('DELETE').map(
      (negotiation) => negotiation.id,
    )

    try {
      return await deleteNegotiations({
        projectTag: activeProjectTag,
        negotiationIds: negotiationsToDelete,
      }).unwrap()
    } catch (error) {
      return enqueIfError(error, enqueueSnackbar)
    }
  }

  const handleMarkSignedNegotiations = async () => {
    const negotiationsToMark = getNegotiationsForAction('MARK_SIGNED').map(
      (negotiation) => negotiation.id,
    )

    try {
      return await markSigned({
        projectTag: activeProjectTag,
        negotiationIds: negotiationsToMark,
      }).unwrap()
    } catch (error) {
      return enqueIfError(error, enqueueSnackbar)
    }
  }

  const handleMarkNotSignedNegotiations = async () => {
    const negotiationsToMark = getNegotiationsForAction('MARK_NOT_SIGNED').map(
      (negotiation) => negotiation.id,
    )

    try {
      return await markNotSigned({
        projectTag: activeProjectTag,
        negotiationIds: negotiationsToMark,
      }).unwrap()
    } catch (error) {
      return enqueIfError(error, enqueueSnackbar)
    }
  }

  const handleDownloadNegotiations = async () => {
    const negotiationsToDownload = getNegotiationsForAction('DOWNLOAD').map(
      (negotiation) => negotiation.id,
    )

    try {
      const downloadResultsBlob = await downloadResults({
        projectTag: activeProjectTag,
        negotiationIds: negotiationsToDownload,
      }).unwrap()
      const fileName =
        negotiations.length === 1 ? `results-${negotiations[0].id}.xlsx` : 'results.xlsx'

      downloadFile(fileName, downloadResultsBlob)
    } catch (error) {
      return enqueIfError(error, enqueueSnackbar)
    }
  }

  const handleDownloadAttachment = async () => {
    const negotiationsWithFiles = getNegotiationsForAction('DOWNLOAD_ATTACHMENT')

    for (const negotiation of negotiationsWithFiles) {
      for (const file of negotiation.fileUploads ?? []) {
        if (!file.fileUploaded) {
          continue
        }

        if (!file.negotiationFileId) {
          continue
        }

        try {
          downloadAttachment(file, getFileSupplierIdentifier(negotiation) as string)
        } catch (error) {
          enqueIfError(error, enqueueSnackbar)
          continue
        }
      }
    }
  }

  return {
    availableActions,
    getNegotiationsForAction,
    handleStartNegotiations,
    handleMarkSignedNegotiations,
    handleMarkNotSignedNegotiations,
    handleDeleteNegotiations,
    handleDownloadNegotiations,
    handleDownloadAttachment,
  }
}

const getActionsForNegotiationOutcomes = (
  fileUploads: FileUpload[] | null,
): NegotiationAction[] => {
  if (hasAttachments(fileUploads)) {
    return ['DOWNLOAD_ATTACHMENT']
  }

  return []
}

const getActionsForManualSigning = (
  negotiation: CommercialTermsNegotiation,
  signingType: 'MANUAL' | 'DOCUSIGN' | null,
): NegotiationAction[] => {
  if (signingType !== 'MANUAL') {
    return []
  }

  if (negotiation.status === 'DEAL_REACHED') {
    return ['MARK_SIGNED']
  }

  if (negotiation.status === 'SIGNED') {
    return ['MARK_NOT_SIGNED']
  }

  return []
}

const getActionsForNegotiationStatus = (status: NegotiationStatus): NegotiationAction[] => {
  const statusesToActions: Record<NegotiationStatus, NegotiationAction[]> = {
    MISSING_DATA: ['EDIT', 'DELETE'],
    READY: ['START', 'EDIT', 'DELETE'],
    PROCESSING: [],

    CONTACTED: [],
    SENT: [],
    STARTED: [],

    DEAL_REACHED: ['VIEW', 'DOWNLOAD'],
    NO_AGREEMENT: ['VIEW', 'DOWNLOAD'],

    SIGNED: ['VIEW', 'DOWNLOAD'],
    CANCELLED: [],
    STARTING_FAILED: ['START', 'EDIT', 'DELETE'],
  }

  return statusesToActions[status]
}

const getActionsForNegotiation = (
  negotiation: CommercialTermsNegotiation,
  signatureType: 'MANUAL' | 'DOCUSIGN' | null,
  canEdit: boolean,
): NegotiationAction[] => {
  const actions = [
    ...getActionsForNegotiationStatus(negotiation.status),
    ...getActionsForNegotiationOutcomes(negotiation.fileUploads),
    ...getActionsForManualSigning(negotiation, signatureType),
  ]

  if (canEdit) {
    return actions
  }

  return actions.filter((action) => viewAllowedActions.has(action))
}
