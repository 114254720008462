import { useState } from 'react'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import { StartConfirmationModal } from 'src/shared/components/ConfirmationModal'
import { BatchActionButton } from './BatchActionButton'

export type StartActionProps = {
  enabled: boolean
  totalNegotiationsCount: number
  actionableNegotiationsCount: number
  onExecute: () => void
}

export const StartAction = ({
  enabled,
  totalNegotiationsCount,
  actionableNegotiationsCount,
  onExecute,
}: StartActionProps) => {
  const [isModalOpen, setModalOpen] = useState(false)

  return (
    <>
      <BatchActionButton
        startIcon={<PlayCircleOutlineIcon />}
        disabled={!enabled}
        onClick={() => setModalOpen(true)}
      >
        Start
      </BatchActionButton>
      <StartConfirmationModal
        allNegotiations={totalNegotiationsCount}
        actionableNegotiations={actionableNegotiationsCount}
        open={isModalOpen}
        onCancel={() => setModalOpen(false)}
        onSubmit={() => {
          setModalOpen(false)
          onExecute()
        }}
      />
    </>
  )
}
