import { MultiSelectFilter, MultiSelectItemValue } from './MultiSelectFilter'

interface BusinessUnitFilterProps {
  units: string[]
  selectedUnits: string[]
  onChange: (units: string[]) => void
}

export const BusinessUnitFilter = ({ units, selectedUnits, onChange }: BusinessUnitFilterProps) => {
  const handleChange = (vals: MultiSelectItemValue[]) => onChange(vals as string[])

  return (
    <>
      <MultiSelectFilter
        label='Business unit'
        selectedAllLabel='All Units'
        data={units.map((category) => ({ value: category }))}
        setSelected={handleChange}
        selected={selectedUnits}
      />
    </>
  )
}
