import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'

interface Props {
  label: string
  labelId: string
  options: string[]
  value: string
  onChange: (selectedOption: string) => void
}

export const SingleSelectFilter = ({ label, labelId, options, value, onChange }: Props) => (
  <FormControl fullWidth>
    <InputLabel id={labelId}>{label}</InputLabel>
    <Select
      labelId={labelId}
      label={label}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    >
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)
