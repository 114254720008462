import { DateFilterSelect } from '@components/FilterSelect'
import { DateRange } from '@mui/x-date-pickers-pro'

export const SentOutFilter = ({
  dateRange,
  onDateRangeChanged,
}: {
  dateRange: { start: Date; end: Date } | null

  onDateRangeChanged: (dates: { start: Date; end: Date } | null) => unknown
}) => {
  const handleDateFilterChange = ([start, end]: DateRange<unknown>) => {
    if (start instanceof Date && end instanceof Date) {
      onDateRangeChanged({ start, end })
    } else if (start === null && end === null) {
      onDateRangeChanged(null)
    }
  }

  return (
    <DateFilterSelect
      label='Sent out'
      value={dateRange ? [dateRange.start, dateRange.end] : [null, null]}
      onChange={handleDateFilterChange}
    />
  )
}
