import { GridColDef } from '@mui/x-data-grid-pro'
import { SyncAlt } from '@mui/icons-material'
import { dateFormats, Formatter } from '@utils'
import { CaptionedValueCell, NotAvailableCell } from '@components/table'
import React from 'react'
import { UnitStatusCell } from './UnitStatusCell'
import { OUTER_COLUMN_WIDTHS } from './constants'
import { SpotLoadUnit } from '@logistics/pages/spotLoad/types'
import { UnitActions } from '@logistics/pages/spotLoad/UnitPage/UnitList/UnitActions'

export const createOuterUnitColumns = (formatter: Formatter): GridColDef<SpotLoadUnit>[] => [
  {
    field: 'origin',
    headerName: 'Origin',
    disableColumnMenu: true,
    sortable: false,
    flex: 0.25,
    renderCell: ({ row }) => (
      <CaptionedValueCell
        mainContent={row.origin}
        captionContent={formatter.date(new Date(row.created_at), dateFormats.textDateTime)}
        tooltip={{ id: row.unit_id, title: row.origin, placement: 'top-start' }}
        spacing={0}
      />
    ),
  },
  {
    field: 'arrows',
    headerName: '',
    width: 16,
    disableColumnMenu: true,
    sortable: false,
    disableReorder: true,
    renderCell: () => (
      <SyncAlt
        fontSize='medium'
        sx={{
          color: 'text.secondary',
        }}
      />
    ),
  },
  {
    field: 'destination',
    headerName: 'Destination',
    disableColumnMenu: true,
    sortable: false,
    flex: 0.25,
    renderCell: ({ row }) => (
      <CaptionedValueCell
        mainContent={row.destination}
        captionContent={
          row.destination_date
            ? formatter.date(new Date(row.destination_date), dateFormats.longDate)
            : null
        }
        tooltip={{ id: row.unit_id, title: row.destination, placement: 'top-start' }}
        spacing={0}
      />
    ),
  },
  {
    field: 'computed_unit_id',
    headerName: 'FRO Number',
    disableColumnMenu: true,
    sortable: false,
    flex: 0.2,
    renderCell: ({ row }) => (
      <CaptionedValueCell
        mainContent={row.computed_unit_id}
        tooltip={{ id: row.unit_id, title: row.computed_unit_id, placement: 'top-start' }}
        spacing={0}
      />
    ),
  },
  {
    field: 'equipment',
    headerName: 'Equipment',
    disableColumnMenu: true,
    sortable: false,
    flex: 0.2,
  },
  {
    field: 'max_limit',
    headerName: 'Limit',
    disableColumnMenu: true,
    sortable: false,
    flex: 0.2,
    renderCell: ({ row: { unit_id, max_limit } }) =>
      max_limit === null ? (
        <NotAvailableCell />
      ) : (
        <CaptionedValueCell
          mainContent={formatter.currency(max_limit)}
          tooltip={{ id: unit_id, title: formatter.currency(max_limit), placement: 'top-start' }}
          spacing={0}
        />
      ),
  },
  {
    field: 'captured_price',
    headerName: 'Negotiated Price',
    disableColumnMenu: true,
    sortable: false,
    width: OUTER_COLUMN_WIDTHS.NEGOTIATED_PRICE,
    renderCell: ({ row: { unit_id, captured_price } }) =>
      captured_price === null ? (
        <NotAvailableCell />
      ) : (
        <CaptionedValueCell
          mainContent={formatter.currency(captured_price)}
          tooltip={{
            id: unit_id,
            title: formatter.currency(captured_price),
            placement: 'top-start',
          }}
          spacing={0}
        />
      ),
  },
  {
    field: 'status',
    headerName: 'Status',
    disableColumnMenu: true,
    sortable: false,
    width: OUTER_COLUMN_WIDTHS.STATUS,
    renderCell: ({ row }) => <UnitStatusCell unit={row} />,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    disableColumnMenu: true,
    sortable: false,
    width: OUTER_COLUMN_WIDTHS.ACTIONS,
    renderCell: ({ row }) => <UnitActions unit={row} />,
  },
]
