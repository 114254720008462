import React, { useState, useMemo, MouseEvent } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Box, Button, Menu, MenuItem, styled, SxProps } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { Item } from '@pages/DashboardPage/Widgets/Legend/LegendBar'
import { LegendBarItem } from '@pages/DashboardPage/Widgets/Legend/LegendBarItem'

interface Props {
  visibilityMap: Record<string, boolean>
  items: Item[]
  onClick: (datasetIndex?: number) => void
  sx?: SxProps<Theme>
}

const ToggleButton = styled(Button)(({ theme }) => ({
  fontWeight: 'normal',
  marginLeft: theme.spacing(1),
}))

export function OverflowMenu({ sx, visibilityMap, items, onClick }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const shouldShowMenu = useMemo(
    () => Object.values(visibilityMap).some((v) => !v),
    [visibilityMap],
  )
  if (!shouldShowMenu) {
    return null
  }

  return (
    <Box sx={sx}>
      <ToggleButton
        variant='text'
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        startIcon={<MoreVertIcon />}
        onClick={handleClick}
      >
        more
      </ToggleButton>
      <Menu id='long-menu' anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {items
          .filter((item) => !visibilityMap[String(item.datasetIndex)])
          .map((item) => {
            return (
              <MenuItem key={item.datasetIndex} onClick={handleClose}>
                <LegendBarItem
                  datasetIndex={item.datasetIndex}
                  onClick={onClick}
                  text={item.text}
                  textColor={item.textColor}
                  bulletColor={item.bulletColor}
                />
              </MenuItem>
            )
          })}
      </Menu>
    </Box>
  )
}
