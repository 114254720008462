import { CustomImportSchemaFieldWithExample } from '@common/types'
import { CustomInputFieldString } from './CustomInputFieldString'
import { CustomInputFieldBoolean } from './CustomInputFieldBoolean'
import { CustomInputFieldNumber } from './CustomInputFieldNumber'

export const CustomInputField = ({ field }: { field: CustomImportSchemaFieldWithExample }) => {
  if (field.type === 'STRING') {
    return <CustomInputFieldString field={field} />
  }

  if (field.type === 'BOOL') {
    return <CustomInputFieldBoolean field={field} />
  }

  if (field.type === 'NUMBER') {
    return <CustomInputFieldNumber field={field} />
  }

  return null
}
