import {
  BenefitName,
  ItemMetrics,
  ValueRecord,
  WeeklyOrSingularMetric,
} from '@common/types/scenario/ScenarioState'
import { ChartData } from './types'
import { FilterOption } from 'src/merchandising/pages/Scenarios/ScenarioPreparation/ScenarioDetails/components/Filters'
import { sumBy, sumNumbers } from '@common/utils'
import { FilterOptionValue } from '../../components/Filters/types'

export const toSpendsByBenefitName = (benefitSpends: [BenefitName, number][]): ChartData[0] =>
  benefitSpends.reduce(
    (acc, [benefitName, benefitSpendSum]) => ({
      ...acc,
      [benefitName]: benefitSpendSum,
    }),
    {} as ChartData[0],
  )

export const toSpendRatiosByBenefitName = (
  benefitSpends: [BenefitName, number][],
  spendInvoiceSum: number,
): ChartData[0] =>
  benefitSpends.reduce(
    (acc, [benefitName, benefitSpendSum]) => ({
      ...acc,
      [benefitName]: spendInvoiceSum !== 0 ? benefitSpendSum / spendInvoiceSum : 0, // FIXME: handle benefits or invoices are null
    }),
    {} as ChartData[0],
  )

export const getSpendInvoiceSum = (
  allItemMetrics: ItemMetrics<WeeklyOrSingularMetric>[],
  selectedIndexFilter?: FilterOption<FilterOptionValue>,
): number =>
  allItemMetrics
    .flatMap((itemMetrics) => itemMetrics?.spend.invoice.values)
    .filter(optionalFilterValueMatchesIndexValue(selectedIndexFilter))
    .reduce(
      sumBy((valueRecord) => valueRecord.value ?? 0),
      0,
    )

export const calculateSpendAndBenefitsAggregations = (
  benefitSpends: [BenefitName, number][],
  spendInvoiceSum: number,
): ChartData[0] => {
  const benefitSpendSum = sumNumbers(benefitSpends.map(([, benefitSpend]) => benefitSpend))
  const benefitSpendsByName = toSpendsByBenefitName(benefitSpends)

  return {
    Total: spendInvoiceSum,
    Net: spendInvoiceSum - benefitSpendSum,
    ...benefitSpendsByName,
  }
}

export const optionalFilterValueMatchesIndexValue =
  (selectedIndexFilter: FilterOption<FilterOptionValue> | undefined) =>
  (valueRecord: ValueRecord): boolean =>
    selectedIndexFilter ? valueRecord?.index === Number(selectedIndexFilter.value) : true
