import { Box, Typography } from '@mui/material'
import { useNegotiationPreparationData } from 'src/merchandising/pages/ChangeRequests/NegotiationPreparation/NegotiationPreparationPage'

export const PricingStrategySummary = (): JSX.Element | null => {
  const { data } = useNegotiationPreparationData()

  if (!data.analysis.pricingStrategy?.conclusionSentence) {
    console.warn('No data for conclusion sentence')
    return null
  }

  return (
    <Box mt={2}>
      <Typography variant='h5' mb={2}>
        Pricing Strategy Summary
      </Typography>
      <Typography mt={1} variant='body1' sx={{ whiteSpace: 'pre' }}>
        {data.analysis.pricingStrategy.conclusionSentence}
      </Typography>
    </Box>
  )
}
