import { NavigationItem } from 'src/main/types/suite-module'
import DashboardIcon from '@mui/icons-material/Dashboard'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import { SuitePermission } from '@common/types'

export enum Routes {
  DASHBOARD = '/:orgTag/suite/dashboard/:projectTag',
  NEGOTIATION_LIST = '/:orgTag/suite/negotiations/:projectTag',
  NEGOTIATION_UNITS = '/:orgTag/suite/negotiations/:projectTag/:negotiationId/units',
}

export const navigationItems: NavigationItem[] = [
  {
    name: 'Negotiations',
    icon: () => <MenuBookIcon />,
    path: (activeOrgTag, activeProjectTag) =>
      `/${activeOrgTag}/suite/negotiations/${activeProjectTag}`,
    requiredPermission: SuitePermission.MODIFY,
  },
  {
    name: 'Dashboard',
    icon: () => <DashboardIcon />,
    path: (activeOrgTag, activeProjectTag) =>
      `/${activeOrgTag}/suite/dashboard/${activeProjectTag}`,
    requiredPermission: SuitePermission.READ,
  },
]
