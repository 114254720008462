import { GridColDef } from '@mui/x-data-grid-pro'
import { CommercialTermsNegotiation } from '@campaigns/types/negotiation'
import { commonColumnParameters } from '../helpers'
import { Formatter } from '@utils'

export const getDiscountsColumns = (
  formatter: Formatter,
): GridColDef<CommercialTermsNegotiation>[] => [
  {
    ...commonColumnParameters,
    field: 'achievedDiscount',
    headerName: 'Achieved discount',
    valueGetter: (cell) => {
      const agreedDiscount = cell.row.contracts[0]?.standardTerms.generalDiscount?.agreed
      return agreedDiscount ? formatter.percent0To1(agreedDiscount / 100) : 'N/A'
    },
  },
]
