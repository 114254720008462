import { createApi } from '@reduxjs/toolkit/query/react'
import { purchasingApi } from './store/purchasing'
import { ProcurementEntryPoint } from './entrypoint'
import { ProductType, ProjectListEntity } from '@pactum/core-backend-types'
import { GenericSuiteModule } from 'src/main/types/suite-module'
import { renderProjectAsBaselineModule } from '@shared/projectsSpecific'
import { SuitePermission } from '@common/types'
import { procurementNavigationItems, ProcurementRoutes } from './routes'

export const procurementSuiteModule: GenericSuiteModule = {
  name: 'procurement',
  api: purchasingApi as unknown as ReturnType<typeof createApi>,
  entrypoint: ProcurementEntryPoint,
  basePath: 'procurement',
  projectSwitchPath: (userPermissions?: Set<SuitePermission>) =>
    userPermissions?.has(SuitePermission.MODIFY)
      ? ProcurementRoutes.NEGOTIATION_EVENTS
      : ProcurementRoutes.DASHBOARD,
  isProjectCompatible: (project: ProjectListEntity) =>
    !renderProjectAsBaselineModule(project) &&
    project.compatibleWithNegotiationSuite &&
    (project.productType === ProductType.Purchasing ||
      project.productType == ProductType.ContractCost),
  navigationItems: procurementNavigationItems,
}
