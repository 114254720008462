import { GRID_CHECKBOX_SELECTION_COL_DEF, GridColDef } from '@mui/x-data-grid-pro'
import { Formatter } from '@utils'
import { NegotiationUnitEntity } from '@common/dto/pact/negotiation-unit-entity'
import { isToday, isYesterday } from 'date-fns'

export const commonColumnParameters = {
  disableColumnMenu: true,
  filterable: true,
  resizable: true,
}

export const createColumns = (formatter: Formatter): GridColDef<NegotiationUnitEntity>[] => [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    cellClassName: 'select-cell',
    headerClassName: 'select-header',
    align: 'left',
    field: '__check__',
    hideable: false,
  },
  {
    ...commonColumnParameters,
    field: 'startTime',
    headerName: 'Created',
    type: 'dateTime',
    flex: 3,
    valueGetter: ({ row }) => new Date(row.startTime),
    renderCell: ({ row }) => {
      const date = new Date(row.startTime)
      if (isToday(date)) {
        return `Today, ${formatter.date(date, 'p')}`
      }
      if (isYesterday(date)) {
        return `Yesterday, ${formatter.date(date, 'p')}`
      }
      return formatter.date(date, 'P')
    },
  },
  {
    ...commonColumnParameters,
    field: 'endTime',
    headerName: 'Updated',
    type: 'dateTime',
    flex: 3,
    valueGetter: ({ row }) => new Date(row.endTime),
    renderCell: ({ row }) => {
      const date = new Date(row.endTime)
      if (isToday(date)) {
        return `Today, ${formatter.date(date, 'p')}`
      }
      if (isYesterday(date)) {
        return `Yesterday, ${formatter.date(date, 'p')}`
      }
      return formatter.date(date, 'P')
    },
  },
  {
    ...commonColumnParameters,
    flex: 5,
    field: 'unitIdExternal',
    headerName: 'External ID',
  },
  {
    ...commonColumnParameters,
    field: 'spend',
    headerName: 'Spend',
    align: 'right',
    headerAlign: 'right',
    flex: 3,
    renderCell: ({ value, row }) =>
      formatter.currency(value, { currency: row.currency.replaceAll('*', '') }),
  },
  {
    ...commonColumnParameters,
    field: 'savings',
    headerName: 'Savings',
    align: 'right',
    headerAlign: 'right',
    flex: 3,
    renderCell: ({ value, row }) =>
      formatter.currency(value, { currency: row.currency.replaceAll('*', '') }),
  },
  {
    ...commonColumnParameters,
    field: 'id',
    headerName: 'Savings rate',
    align: 'right',
    headerAlign: 'right',
    flex: 3,
    renderCell: ({ row }) => {
      if (!row.savings || !row.spend) {
        return formatter.percent0To1(0)
      }
      return formatter.percent0To1(row.savings / row.spend)
    },
  },
]
