import { NegotiationPreparationWithInputs } from '@common/dto'
import { Article, ArticlePriceElasticity } from '@common/types'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useFormatter } from '@shared/hooks'
import { Formatter, isReactElement } from '@utils'
import { ReactNode, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useGetNegotiationPreparationQuery } from 'src/merchandising/store/'
import { TooltipWrapper } from './TooltipWrapper'

interface ElasticityRows extends ArticlePriceElasticity {
  title?: string
  ean?: Article['ean']
  articleName?: Article['name']
}

export const PriceElasticityTable = (): JSX.Element | null => {
  const formatter = useFormatter()
  const { negotiationPreparationId } = useParams<{ negotiationPreparationId: string }>()
  const { data } = useGetNegotiationPreparationQuery(negotiationPreparationId!, {
    skip: !negotiationPreparationId,
  })

  const rows = useMemo(() => (data ? getRows(data) : []), [data])

  if (!data) {
    return null
  }

  if (!rows.length) {
    console.warn('No data for price elasticity')
    return null
  }

  return (
    <Box mt={2}>
      <Typography variant='h5' mb={2}>
        Price Elasticity
      </Typography>
      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {columnsAndKeys.map(({ header }) => (
                <TableCell
                  sx={{ fontWeight: 'bold' }}
                  key={
                    // eslint-disable-next-line
                    isReactElement(header) ? header?.props?.title : header
                  }
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((cog, idx) => (
              <TableRow key={idx}>
                {columnsAndKeys.map(({ key, formatterMethod }) =>
                  formatAndRenderCell(cog, idx, key, formatter[formatterMethod!]),
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

const columnsAndKeys: {
  header: ReactNode
  key: keyof ElasticityRows
  formatterMethod?: keyof Pick<Formatter, 'number' | 'percent0To1'>
}[] = [
  { header: 'EAN', key: 'ean' },
  { header: 'Article Name', key: 'articleName' },
  {
    header: (
      <TooltipWrapper
        title='Total sales quantity in three full months before the last purchase price change'
        value='Quantity Before 3 months'
      />
    ),
    key: 'quantityBeforeThreeMonths',
    formatterMethod: 'number',
  },
  {
    header: (
      <TooltipWrapper
        title='Total sales quantity in three full months after the last purchase price change'
        value='Quantity After 3 months'
      />
    ),
    key: 'quantityAfterThreeMonths',
    formatterMethod: 'number',
  },
  { header: 'Change', key: 'relativeChangePercentage', formatterMethod: 'percent0To1' },
]

const formatAndRenderCell = (
  cog: ElasticityRows,
  idx: number,
  key: keyof ElasticityRows,
  formatter?: (value: number) => string,
): ReactNode => {
  let value = cog[key]

  if (formatter && typeof value === 'number') {
    value = formatter(value)
  }

  return <TableCell key={`${key}-${idx}`}>{value}</TableCell>
}

const getRows = (data: NegotiationPreparationWithInputs) => {
  const rows = [] as ElasticityRows[]

  const articleNames = data.articles.reduce(
    (acc, article) => {
      acc[article.ean] = article.name
      return acc
    },
    {} as Record<string, string>,
  )

  const elasticityData = data.analysis.priceElasticity

  if (elasticityData) {
    rows.push(
      ...Object.entries(elasticityData).map(([ean, value]) => ({
        ...value,
        ean,
        articleName: articleNames[ean],
      })),
    )
  }

  return rows
}
