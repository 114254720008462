import React, { createContext, useContext, useState } from 'react'

interface Props {
  loadingIds: Set<string>
  addLoadingIds: (loadingIds: Set<string>) => void
  removeLoadingIds: (loadingIds: Set<string>) => void
}

const BatchActionLoadingNegotiationsContext = createContext<Props>({
  loadingIds: new Set<string>(),
  addLoadingIds: () => {},
  removeLoadingIds: () => {},
})

export const BatchActionLoadingNegotiationsProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const [loadingIds, setLoadingIds] = useState(new Set<string>())

  const addLoadingIds = (newLoadingIds: Set<string>) => {
    setLoadingIds(new Set([...newLoadingIds, ...loadingIds]))
  }

  const removeLoadingIds = (toRemoveLoadingIds: Set<string>) => {
    const updatedLoadingIds = new Set([...loadingIds])
    toRemoveLoadingIds.forEach((id) => updatedLoadingIds.delete(id))
    setLoadingIds(updatedLoadingIds)
  }

  return (
    <BatchActionLoadingNegotiationsContext.Provider
      value={{ loadingIds, addLoadingIds, removeLoadingIds }}
    >
      {children}
    </BatchActionLoadingNegotiationsContext.Provider>
  )
}

export const useBatchActionLoadingNegotiationsContext = () =>
  useContext(BatchActionLoadingNegotiationsContext)
