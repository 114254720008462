import { NegotiationEvent, NegotiationSummary } from '../../../store/types'
import { DataCell, DataCellProps } from '@procurement-shared/DataCell/DataCell'
import { Formatter } from '@utils'
import {
  getSavingsAmountFromNegotiation,
  getSavingsPercentFromNegotiation,
} from '../../../utils/negotiationEventGetters'
import { formatPercent } from '../../../utils/formatting'
import { formatCurrency } from '@shared/utils/formatCurrency'

export const SavingsCell = ({
  negotiationEvent,
  negotiation,
  size,
  formatter,
}: {
  negotiationEvent: NegotiationEvent
  negotiation: NegotiationSummary | null
  size: DataCellProps['size']
  formatter: Formatter
}) => {
  const savingsAmount = getSavingsAmountFromNegotiation(negotiation)
  const savingsPercent = getSavingsPercentFromNegotiation(negotiation)
  let footnote: DataCellProps['footnote']

  if (savingsPercent !== null && savingsPercent !== 0) {
    footnote = `${formatPercent(savingsPercent, formatter)} saved`
  }

  return (
    <DataCell label='Savings' size={size} footnote={footnote}>
      {!savingsAmount && '-'}
      {savingsAmount && formatCurrency(savingsAmount, negotiationEvent.currency, formatter)}
    </DataCell>
  )
}
