import { Link, LinkProps } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

export const LinkWithArrow = (props: LinkProps) => {
  const { children, ...linkProps } = props
  return (
    <Link variant='normal' href='#' {...linkProps}>
      {children}
      <ArrowForwardIcon sx={{ margin: '0.3125rem', verticalAlign: 'top' }} fontSize='inherit' />
    </Link>
  )
}
