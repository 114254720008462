import { GridColDef } from '@mui/x-data-grid-pro'
import { CommercialTermsNegotiation, Contract, TermStage } from '@campaigns/types/negotiation'
import { formatPaymentTerms } from 'src/commercial-terms/utils/formatPaymentTerms'
import { commonColumnParameters } from '../helpers'
import { getCustomFieldRenderer } from '@campaigns/types'

export const getPaymentTermsColumns = (): GridColDef<CommercialTermsNegotiation>[] => [
  {
    ...commonColumnParameters,
    flex: 1.2,
    field: 'agreedTermsDiscount',
    headerName: 'Current terms',
    valueGetter: (cell) => getFirstContractTermsValue(cell.row),
  },
]

const getFirstContractTermsValue = (nego: CommercialTermsNegotiation) => {
  const firstContract = nego.contracts[0]

  const newTerms = formatNewTerms(firstContract)

  if (newTerms) {
    return newTerms
  }

  const previousTerms = formatPreviousTerms(firstContract)

  if (previousTerms) {
    return previousTerms
  }

  return 'N/A'
}

export const formatNewTerms = (contract: Contract) => {
  const terms = contract?.standardTerms
  if (!terms.paymentDays?.agreed) {
    return null
  }

  const customPaymentDaysConfig = getCustomFieldRenderer(terms, TermStage.agreed)
  if (customPaymentDaysConfig) {
    return customPaymentDaysConfig.rendering.render(terms, TermStage.agreed)
  }

  return formatPaymentTerms(
    terms.earlyPaymentDiscount?.agreed,
    terms.earlyPaymentDiscountDays?.agreed,
    terms.paymentDays?.agreed,
  )
}

export const formatPreviousTerms = (contract: Contract) => {
  const terms = contract?.standardTerms
  if (!terms) {
    return null
  }

  const customPaymentDaysConfig = getCustomFieldRenderer(terms, TermStage.previous)
  if (customPaymentDaysConfig) {
    return customPaymentDaysConfig.rendering.render(terms, TermStage.previous)
  }

  if (terms.paymentDays?.previous === null) {
    return 'N/A'
  }

  return formatPaymentTerms(
    terms.earlyPaymentDiscount?.previous,
    terms.earlyPaymentDiscountDays?.previous,
    terms.paymentDays?.previous,
  )
}
