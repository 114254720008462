import styled from '@emotion/styled'
import { Box, Popper } from '@mui/material'
import { CurrencyCode } from '@pactum/common'
import React, { useState } from 'react'

const CurrencyRatesInfo = styled.span`
  color: #858585;
`

const UL = styled.ul`
  list-style: none;
  padding: 10px;
  margin: 0;
`

const ExchangeRatesInfo = styled.span`
  color: #3f51b5;
  cursor: pointer;
  text-decoration: underline;
`

const ExchangeRatesDate = styled.li`
  color: #858585;
  padding: 0 0 10px 0;
`

const ExchageRatesWidget = styled.div`
  margin: 10px 0;
`

export const ExchangeRatesPopover = ({
  currency,
  currencyRates,
  lastUpdated,
}: {
  currency: CurrencyCode
  currencyRates: Record<string, number>
  lastUpdated?: string
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const currencies = Object.keys(currencyRates ?? {}).filter((c) => c !== currency)

  return currencies.length === 0 ? (
    <></>
  ) : (
    <ExchageRatesWidget>
      <CurrencyRatesInfo>
        Currencies are converted by these{' '}
        <ExchangeRatesInfo onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
          exchange rates
        </ExchangeRatesInfo>
      </CurrencyRatesInfo>
      <Popper
        sx={{
          pointerEvents: 'none',
        }}
        placement='top'
        open={open}
        anchorEl={anchorEl}
      >
        {() => (
          <Box
            sx={{
              border: 1,
              borderColor: '#858585',
              fontSize: 12,
              borderRadius: 1,
              bgcolor: 'background.paper',
            }}
          >
            <UL>
              <ExchangeRatesDate>Exchange rates: {lastUpdated}</ExchangeRatesDate>

              {Object.keys(currencyRates ?? {})
                .filter((c) => c !== currency)
                .map((c) => (
                  <li key={c}>
                    1 {currency} = {currencyRates?.[c]} {c}
                  </li>
                ))}
            </UL>
          </Box>
        )}
      </Popper>
    </ExchageRatesWidget>
  )
}
