import { useState } from 'react'
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent'
import { Button, Link, Typography, Paper, useTheme, styled } from '@mui/material'
import { shouldShowCookieConsent } from '@pactum/frontend-utils'
import { initPosthog } from 'src/main/utils'

export const CookiesConsent = () => {
  const [interactionHappened, setInteractionHappened] = useState(false)
  const theme = useTheme()

  const buttonWrapperStyle = {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
    width: '100%',
    marginTop: theme.spacing(3),
  }

  const handleCookieAccept = () => {
    initPosthog()
    setInteractionHappened(true)
  }

  const handleCookieDecline = () => {
    setInteractionHappened(true)
  }

  if (!shouldShowCookieConsent() || getCookieConsentValue() || interactionHappened) {
    return null
  }

  return (
    <BannerContainer>
      <CookieConsent
        location='none'
        buttonText='Allow all'
        declineButtonText='Deny'
        enableDeclineButton
        expires={182} // in days; which means half a year (or actually a bit less, but this is fine)
        onAccept={handleCookieAccept}
        onDecline={handleCookieDecline}
        ButtonComponent={ConsentButton}
        customButtonWrapperAttributes={{ style: { ...buttonWrapperStyle } }}
        disableStyles
      >
        <Typography align='left' color='common.white'>
          <span>
            We use cookies to provide you with the best user experience. By clicking "Allow all",
            you consent to the use of all cookies. Selecting "Deny", you consent to functional
            cookies. Read more about our{' '}
          </span>
          <Link
            variant='subtitle2'
            underline='hover'
            color='common.white'
            href='https://pactum.com/cookie-policy/'
            target='_blank'
          >
            Cookie Policy
          </Link>
          <span>{' and '}</span>
          <Link
            variant='subtitle2'
            underline='hover'
            color='common.white'
            href='https://pactum.com/privacy/'
            target='_blank'
          >
            Privacy Policy.
          </Link>
        </Typography>
      </CookieConsent>
    </BannerContainer>
  )
}

const BannerContainer = styled(Paper)(({ theme }) => ({
  position: 'fixed',
  right: theme.spacing(1),
  bottom: theme.spacing(2),
  marginRight: theme.spacing(2),
  maxWidth: '400px',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius * 2,
  backgroundColor: theme.palette.common.petrol,
  color: theme.palette.common.white,
  zIndex: theme.zIndex.modal,
}))

const ConsentButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1),
  width: '100%',
  background: theme.palette.primary.dark,
  color: theme.palette.common.white,
  fontWeight: 'bold',
  textTransform: 'none',
  ':hover': {
    background: theme.palette.primary.dark,
  },
  ':first-of-type': {
    marginLeft: theme.spacing(1),
  },
  ':last-of-type': {
    marginRight: theme.spacing(1),
  },
}))
