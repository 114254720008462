import { SuitePermission } from '@common/types'
import { CommercialTermsNegotiation } from '@campaigns/types/negotiation'
import { SearchFilter } from '@components/FilterSearch'
import { LAYOUT_CONTAINER_X_PADDING } from '@constants'
import { useUserPermissions } from '@hooks'
import UploadIcon from '@mui/icons-material/FileUploadOutlined'
import { Alert, Box, Button, Link, styled, Typography } from '@mui/material'
import { GridFilterModel, GridToolbarContainer } from '@mui/x-data-grid-pro'
import { useState } from 'react'
import { BatchActionsBar } from './BatchActionsBar'
import { FileUploadDialog } from './FileUploadDialog'
import { Filters } from './filters'

interface ToolbarProps {
  negotiations: CommercialTermsNegotiation[]
  displayedNegotiationIds: string[]
  showBatchActions: boolean
  selectedNegotiations: CommercialTermsNegotiation[]
  filterModel: GridFilterModel
  setFilterModel: (filterModel: GridFilterModel) => void
  showSelectAllNotification: boolean
  showClearSelectionNotification: boolean
  onSelectAll: (selected: boolean) => void
  onClearSelection: () => void
}

const ToolbarButton = styled(Button)(({ theme }) => ({
  fontSize: '12px',
  color: theme.palette.text.secondary,
  marginRight: theme.spacing(1),
  '.MuiButton-startIcon': {
    marginRight: '4px',
  },
}))

export const Toolbar = ({
  negotiations,
  displayedNegotiationIds,
  showBatchActions,
  selectedNegotiations,
  filterModel,
  setFilterModel,
  showSelectAllNotification,
  showClearSelectionNotification,
  onSelectAll,
  onClearSelection,
}: ToolbarProps) => {
  const [isUploadOpen, setUploadOpen] = useState(false)

  const { userPermissionsInActiveOrg } = useUserPermissions()
  const canModify = userPermissionsInActiveOrg.has(SuitePermission.MODIFY)

  return (
    <ToolbarContainer>
      <Typography variant='subtitle1'>Negotiations</Typography>
      {showBatchActions && <BatchActionsBar selectedNegotiations={selectedNegotiations} />}
      <Separator />
      {canModify && (
        <>
          <ToolbarButton
            startIcon={<UploadIcon />}
            style={{ marginBottom: '4px' }}
            onClick={() => setUploadOpen(true)}
          >
            Upload
          </ToolbarButton>
          <FileUploadDialog isOpen={isUploadOpen} onClose={() => setUploadOpen(false)} />
        </>
      )}
      <SearchFilter filterModel={filterModel} setFilterModel={setFilterModel} />
      <Filters
        negotiations={negotiations}
        filterModel={filterModel}
        setFilterModel={setFilterModel}
      />
      <SelectAllNotification
        showSelectAllNotification={showSelectAllNotification}
        showClearSelection={showClearSelectionNotification}
        selectedNegotiationsCount={selectedNegotiations.length}
        allNegotiationsCount={displayedNegotiationIds.length}
        onSelectAll={onSelectAll}
        onClearSelection={onClearSelection}
      />
    </ToolbarContainer>
  )
}

const SelectAllNotification = ({
  showSelectAllNotification,
  showClearSelection,
  selectedNegotiationsCount,
  allNegotiationsCount,
  onSelectAll,
  onClearSelection,
}: {
  showSelectAllNotification: boolean
  showClearSelection: boolean
  selectedNegotiationsCount: number
  allNegotiationsCount: number
  onSelectAll: (selected: boolean) => void
  onClearSelection: () => void
}) => {
  if (!showSelectAllNotification) {
    return null
  }

  if (showClearSelection) {
    return (
      <Alert severity='info' sx={{ width: '100%' }} onClose={() => onSelectAll(false)}>
        All {selectedNegotiationsCount} negotiations are selected.{' '}
        <Link
          onClick={() => onClearSelection()}
          underline='always'
          sx={{
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
        >
          Clear selection
        </Link>
      </Alert>
    )
  }

  return (
    <Alert severity='info' sx={{ width: '100%' }} onClose={() => onSelectAll(false)}>
      All {selectedNegotiationsCount} negotiations on this page selected.{' '}
      <Link
        onClick={() => onSelectAll(true)}
        underline='always'
        sx={{
          cursor: 'pointer',
          textDecoration: 'underline',
        }}
      >
        Select all {allNegotiationsCount} negotiations
      </Link>
    </Alert>
  )
}

const ToolbarContainer = styled(GridToolbarContainer)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  marginLeft: '10px',
  paddingLeft: theme.spacing(LAYOUT_CONTAINER_X_PADDING),
}))

const Separator = styled(Box)({ flex: 1 })
