import React from 'react'
import { Box, Link, Stack, styled, Typography } from '@mui/material'
import { InnerContainer } from '@components/Layout/Containers'
import { SetupYourFirstNegotiation } from './SetupYourFirstNegotiation'

export const WelcomePage = () => (
  <StyledStack>
    <InnerContainer>
      <SetupYourFirstNegotiation />
    </InnerContainer>
    <StyledBox textAlign='center'>
      <Typography variant='body1' sx={{ color: 'text.secondary' }}>
        If you run into problems, contact us at{' '}
        <Link href='mailto:support@pactum.com' title='Contact support'>
          support@pactum.com
        </Link>{' '}
        and we’ll get it sorted out.
      </Typography>
    </StyledBox>
  </StyledStack>
)

const StyledBox = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(6),
  paddingBottom: theme.spacing(1),
}))

const StyledStack = styled(Stack)({
  flexGrow: 1,
  justifyContent: 'space-between',
})
