import z from 'zod'

export const BackendHttpErrorSchema = z.object({
  status: z.number(),
  data: z.object({
    statusCode: z.number(),
    message: z.string(),
    path: z.string(),
  }),
})
export type BackendHttpError = z.infer<typeof BackendHttpErrorSchema>

export const isBackendHttpError = (error: unknown): error is BackendHttpError => {
  return BackendHttpErrorSchema.safeParse(error).success
}
