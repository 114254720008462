import { NegotiationStatus } from '@campaigns/types'
import { CommercialTermsNegotiation } from '@campaigns/types/negotiation'
import { Replay } from '@mui/icons-material'
import { Box, IconButton, styled, Tooltip } from '@mui/material'
import { GridFilterItem, GridFilterModel } from '@mui/x-data-grid-pro'
import { useMemo } from 'react'
import { BusinessUnitFilter, MISSING_BUSINESS_UNIT_OPTION } from './BusinessUnitFilter'
import { HasAttachmentsFilter } from './HasAttachmentsFilter'
import { SentOutFilter } from './SentOutFilter'
import { StatusFilter } from './StatusFilter'

type DateRange = { start: string | Date; end: string | Date }

export const Filters = ({
  negotiations,
  filterModel,
  setFilterModel,
}: {
  negotiations: CommercialTermsNegotiation[]
  filterModel: GridFilterModel
  setFilterModel: (model: GridFilterModel) => void
}) => {
  const statusFilter = filterModel.items.find(({ id }) => id === 'status') ?? { value: [] }
  const selectedStatuses = statusFilter.value as NegotiationStatus[]

  const handleSelectedStatusesChange = (statuses: NegotiationStatus[]) => {
    const newFilterModelItems = filterModelItemsWithoutPreviousFilter(filterModel.items, 'status')

    if (statuses.length === 0) {
      setFilterModel({ items: newFilterModelItems })
    } else {
      setFilterModel({
        items: [
          ...filterModelItemsWithoutPreviousFilter(filterModel.items, 'status'),
          { id: 'status', field: 'status', operator: 'has_any_of', value: statuses },
        ],
      })
    }
  }

  const businessUnitFilter = filterModel.items.find(({ id }) => id === 'businessUnit') ?? {
    value: [],
  }
  const selectedBusinessUnits = businessUnitFilter.value as string[]
  const allBusinessUnits = [
    ...new Set(
      negotiations
        .map(({ businessUnit }) => businessUnit)
        .filter((unit) => Boolean(unit)) as string[],
    ),
  ]

  const handleSelectedBusinessUnitsChange = (businessUnits: string[]) => {
    const newFilterModelItems = filterModelItemsWithoutPreviousFilter(
      filterModel.items,
      'businessUnit',
    )

    if (businessUnits.length === 0) {
      setFilterModel({
        items: newFilterModelItems,
      })
    } else if (businessUnits?.includes(MISSING_BUSINESS_UNIT_OPTION)) {
      setFilterModel({
        items: [
          ...newFilterModelItems,
          {
            id: 'businessUnit',
            field: 'businessUnit',
            operator: 'has_nothing',
            value: [MISSING_BUSINESS_UNIT_OPTION],
          },
        ],
      })
    } else {
      setFilterModel({
        items: [
          {
            id: 'businessUnit',
            field: 'businessUnit',
            operator: 'has_any_of',
            value: businessUnits,
          },
        ],
      })
    }
  }

  const hasAttachmentsFilter = filterModel.items.find(({ id }) => id === 'hasAttachments') ?? {
    value: 'all',
  }
  const hasAttachmentsValue = hasAttachmentsFilter.value as boolean | 'all'

  const handleHasAttachmentsChanged = (hasAttachments: boolean | 'all') => {
    const newFilterModelItems = filterModelItemsWithoutPreviousFilter(
      filterModel.items,
      'hasAttachments',
    )

    if (hasAttachments === 'all') {
      setFilterModel({ items: newFilterModelItems })
    } else {
      setFilterModel({
        items: [
          ...newFilterModelItems,
          {
            id: 'hasAttachments',
            field: 'hasAttachments',
            operator: 'is',
            // boolean value needs to be string for MUI :) https://github.com/mui/mui-x/issues/10049
            value: hasAttachments.toString(),
          },
        ],
      })
    }
  }

  const sentOutFilter = useMemo(() => {
    const values = filterModel.items.find(({ id }) => id === 'sentOut')

    const value = values?.value as DateRange | null | undefined

    if (value) {
      return {
        value: {
          start: new Date(value.start),
          end: new Date(value.end),
        },
      }
    }

    return { value: null }
  }, [filterModel.items])

  const handleSentOutChanged = (sentOutDates: { start: Date; end: Date } | null) => {
    const newFilterModelItems = filterModelItemsWithoutPreviousFilter(filterModel.items, 'sentOut')

    if (sentOutDates === null) {
      setFilterModel({ items: newFilterModelItems })
    } else {
      setFilterModel({
        items: [
          ...newFilterModelItems,
          {
            id: 'sentOut',
            field: 'sentOut',
            operator: 'in_date_range',
            value: { start: sentOutDates.start, end: sentOutDates.end },
          },
        ],
      })
    }
  }

  const clearFilters = () =>
    setFilterModel({
      items: [],
      quickFilterValues: [],
    })

  return (
    <FilterContainer>
      <FilterSubContainer>
        <StatusFilter
          currentNegotiationStatuses={new Set(negotiations.map(({ status }) => status))}
          selectedStatuses={selectedStatuses}
          setSelectedStatuses={handleSelectedStatusesChange}
        />
        <BusinessUnitFilter
          allBusinessUnits={allBusinessUnits}
          selectedBusinessUnits={selectedBusinessUnits}
          setSelectedBusinessUnits={handleSelectedBusinessUnitsChange}
        />
        <HasAttachmentsFilter
          hasAttachments={hasAttachmentsValue}
          setHasAttachments={handleHasAttachmentsChanged}
        />
        <SentOutFilter dateRange={sentOutFilter.value} onDateRangeChanged={handleSentOutChanged} />
        <ClearWrapper>
          <Tooltip title='Clear all filters'>
            <IconButton onClick={clearFilters}>
              <Replay fontSize='small' />
            </IconButton>
          </Tooltip>
        </ClearWrapper>
      </FilterSubContainer>
    </FilterContainer>
  )
}

const FilterContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(4),
  display: 'flex',
  justifyItems: 'space-between',
  alignItems: 'center',
}))

const FilterSubContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  '& > .MuiFormControl-root': {
    maxWidth: '240px',
    marginRight: theme.spacing(2),
  },
}))

const ClearWrapper = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(6),
}))

const filterModelItemsWithoutPreviousFilter = (items: GridFilterItem[], oldFilterId: string) =>
  items.filter(({ id }) => id !== oldFilterId)
