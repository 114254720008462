import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { isBackendApiErrorResponse, useGetSingleNegotiationQuery } from 'src/commercial-terms/store'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { PactumLoader } from 'src/shared/components/PactumLoader'
import { useSnackbar } from 'notistack'
import { NegotiationDetailsView } from './NegotiationDetailsView'

export const NegotiationDetails = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { activeProjectTag } = useActiveProject()
  const { id: negotiationId } = useParams()

  const {
    data: negotiation,
    isLoading,
    isError,
    error,
  } = useGetSingleNegotiationQuery(
    {
      projectTag: activeProjectTag,
      negotiationId: negotiationId!,
    },
    { skip: negotiationId === undefined },
  )

  useEffect(() => {
    if (isError && error) {
      enqueueSnackbar(
        isBackendApiErrorResponse(error)
          ? error.data.message
          : 'There was an error fetching negotiation details',
        { variant: 'error' },
      )
    }
  }, [isError, error, enqueueSnackbar])

  if (isLoading || !negotiation) {
    return <PactumLoader />
  } else {
    return <NegotiationDetailsView {...negotiation} />
  }
}
