import { NegotiationSummary, OutcomesPaymentTerms } from '../store/types'
import { NegotiationEvent } from '../store/types'
import { NegotiationEventSupplier } from '../store/types'

export const getNegotiatedPaymentTermsFromNegotiation = (
  negotiation: NegotiationSummary | null,
): OutcomesPaymentTerms => {
  return {
    discount: negotiation?.outcomes?.paymentTerms.discount ?? null,
    discountDaysObject: negotiation?.outcomes?.paymentTerms.discountDaysObject ?? null,
    paymentDaysObject: negotiation?.outcomes?.paymentTerms.paymentDaysObject ?? null,
  }
}

export const getNegotiatedAmountFromNegotiation = (negotiation: NegotiationSummary | null) => {
  return negotiation?.outcomes?.pricing.total ?? 0
}

export const getSavingsAmountFromNegotiation = (
  negotiation: NegotiationSummary | null,
): number | null => {
  const savings = negotiation?.outcomes?.savings
  return !savings || savings === 0 ? null : savings
}

export const getSavingsPercentFromNegotiation = (
  negotiation: NegotiationSummary | null,
): number | null => {
  return negotiation?.outcomes?.improvement ?? null
}

export const getBaselineSupplier = ({
  negotiationEvent,
  supplier,
}: {
  negotiationEvent: NegotiationEvent
  supplier: NegotiationEventSupplier
}): NegotiationEventSupplier => {
  return negotiationEvent.suppliers.find(({ id }) => id === supplier.baselineSupplierId)!
}
