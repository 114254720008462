import { Box, Button, styled, Typography } from '@mui/material'
import { GridToolbarContainer } from '@mui/x-data-grid-pro'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import { ConfirmationModal } from '@components/ConfirmationModal'
import { useState } from 'react'
import { useNegotiationEventActions } from '@logistics/pages/spotLane/hooks/useNegotiationEventActions'

export const Toolbar = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { handleNegotiationEventStartAction } = useNegotiationEventActions()
  const withLoading = async (action: () => Promise<void>) => {
    setLoading(true)
    await action()
    setLoading(false)
  }

  return (
    <>
      <ToolbarContainer>
        <Typography variant='subtitle1'>Spot Lanes</Typography>
        <Separator />
        <ToolbarButton
          startIcon={<PlayCircleOutlineIcon />}
          style={{ marginBottom: '4px' }}
          onClick={() => setModalOpen(true)}
        >
          Start negotiations
        </ToolbarButton>
      </ToolbarContainer>
      <StartNegotiationsModal
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        onSubmit={async () => {
          try {
            return withLoading(() => handleNegotiationEventStartAction())
          } finally {
            setModalOpen(false)
            setLoading(false)
          }
        }}
        loading={loading}
      />
    </>
  )
}

export type StartNegotiationsModalProps = {
  open: boolean
  onCancel: () => void
  onSubmit: () => void
  loading?: boolean
}

export const StartNegotiationsModal = ({ ...props }: StartNegotiationsModalProps) => {
  return (
    <ConfirmationModal
      confirmType='positive'
      title='Start negotiations'
      content='Are you sure you want to start negotiations?'
      confirmButtonText='Start negotiations'
      {...props}
    />
  )
}

const ToolbarContainer = styled(GridToolbarContainer)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  marginLeft: '10px',
}))

const ToolbarButton = styled(Button)(({ theme }) => ({
  fontSize: '12px',
  color: theme.palette.text.secondary,
  marginRight: theme.spacing(1),
  '.MuiButton-startIcon': {
    marginRight: '4px',
  },
}))

const Separator = styled(Box)({ flex: 1 })
