import React, { useState } from 'react'
import { Box, styled, Tab, Tabs, TextField, Typography } from '@mui/material'
import { ArticleWithInput } from '@common/types'
import EditIcon from '@mui/icons-material/Edit'
import { SidePanel } from 'src/shared/components/SidePanel'
import { makeParagraphs } from 'src/merchandising/pages/ChangeRequests/NegotiationPreparation/Adjustments/utils'
import {
  ReasoningWithVisibility,
  SaveReasoningSentenceCallback,
  UpdateExplanationCallback,
} from '../RowDetailsPanel'

type ReasoningTabTypes = 'internal' | 'external'

interface Props {
  open: boolean
  explanations: ReasoningWithVisibility[]
  article: ArticleWithInput
  isEditingDisabled: boolean
  onPanelClose: () => void
  onSaveChanges: SaveReasoningSentenceCallback
  onUpdateInPlaceExplanation: UpdateExplanationCallback
}

const topMarginForReasonings = 2
const topMarginForReasoningsWhenEditing = 1

export const ReasoningPanel = ({
  open,
  explanations,
  article,
  isEditingDisabled,
  onSaveChanges,
  onUpdateInPlaceExplanation,
  onPanelClose,
}: Props) => {
  const [selectedReasoningTab, setSelectedReasoningTab] = useState<ReasoningTabTypes>('internal')
  const [reasoningEditMode, setReasoningEditMode] = useState(false)

  const handleCloseSidePanel = () => {
    setSelectedReasoningTab('internal')
    setReasoningEditMode(false)
    onPanelClose()
  }

  const renderEditReasoningButton = () => {
    const numberOfVisibleSentences = explanations.filter(
      (explanation) => explanation.visible,
    ).length

    if (numberOfVisibleSentences > 0)
      return (
        <StyledEditButton
          ml='auto'
          mt={topMarginForReasoningsWhenEditing}
          onClick={() => setReasoningEditMode(!reasoningEditMode)}
        >
          <Typography component='span'>
            {reasoningEditMode ? 'Cancel editing' : 'Click to edit'}
          </Typography>
          {!reasoningEditMode && <EditIcon sx={{ fontSize: '0.625rem' }} />}
        </StyledEditButton>
      )
  }

  return (
    <SidePanel open={open} title='Review reasoning' onClose={handleCloseSidePanel}>
      <Tabs
        value={selectedReasoningTab}
        TabIndicatorProps={{ sx: { backgroundColor: '#0F3A4F' } }}
        sx={{ marginTop: '32px', width: '100%', minHeight: 'unset' }}
        onChange={(e, newTab) => setSelectedReasoningTab(newTab)}
      >
        <StyledTab label='Internal reasoning' value='internal' />
        <StyledTab label='External reasoning' value='external' />
      </Tabs>
      {selectedReasoningTab === 'internal' && (
        <Typography
          mt={!isEditingDisabled ? topMarginForReasoningsWhenEditing + 3 : topMarginForReasonings}
          variant='body2'
        >
          {makeParagraphs(article.reasoning.internal)}
        </Typography>
      )}
      {selectedReasoningTab === 'external' && (
        <>
          {!isEditingDisabled && renderEditReasoningButton()}
          <Box
            mt={!isEditingDisabled ? topMarginForReasoningsWhenEditing : topMarginForReasonings}
            sx={{ width: '100%' }}
          >
            {explanations.map(
              (externalReasoning, index) =>
                externalReasoning.visible && (
                  <React.Fragment key={externalReasoning.id}>
                    {reasoningEditMode ? (
                      <StyledTextField
                        disabled={isEditingDisabled}
                        value={explanations[index].sentence}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            onSaveChanges(explanations, 'reasoning.external')
                            e.preventDefault()
                          }
                        }}
                        onChange={(e) =>
                          onUpdateInPlaceExplanation(e.target.value, externalReasoning.id)
                        }
                        onBlur={() => onSaveChanges(explanations, 'reasoning.external')}
                        sx={{ width: '100%', mt: index ? 1 : 0 }}
                        multiline
                      />
                    ) : (
                      <Typography
                        mt={index ? 2 : 0}
                        variant='body2'
                        onClick={() => !isEditingDisabled && setReasoningEditMode(true)}
                      >
                        {explanations[index].sentence}
                      </Typography>
                    )}
                  </React.Fragment>
                ),
            )}
          </Box>
        </>
      )}
    </SidePanel>
  )
}

interface StyledTabProps {
  label: string
  value: ReasoningTabTypes
}

const StyledTab = styled((props: StyledTabProps) => <Tab {...props} />)({
  paddingTop: '5px',
  paddingBottom: '5px',
  minHeight: 'unset',
  color: '#6C6C6C',
  textTransform: 'none',
  fontWeight: 'bold',
  '&.Mui-selected': {
    color: '#0F3A4F',
  },
})

const StyledEditButton = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  color: '#6C6C6C',
  cursor: 'pointer',
  span: {
    marginRight: '4px',
    fontSize: '0.625rem',
  },
})

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    fontSize: theme.typography.body2.fontSize,
  },
}))
