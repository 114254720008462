import { LineItems } from './LineItems/LineItems'
import { PurchasingUIConfig } from '../../../store/types'
import { ProductType } from '@pactum/core-backend-types'
import { useContext } from 'react'
import { NegotiationEventFormContext } from '@procurement-shared/NegotiationEventForm/NegotiationEventFormContext'
import { LineItemsWithRebate } from '@procurement-shared/NegotiationEventForm/sections/LineItems/LineItemsWithRebate'
import { RebatesWithApplicableSpend } from '@procurement-shared/NegotiationEventForm/sections/RebatesWithApplicableSpend'
import { SpendOrPricesSwitch } from './SpendOrPricesSwitch'

interface Props {
  allowedIncoterms?: PurchasingUIConfig['suite']['allowedIncoterms']
  defaultIncoterm?: PurchasingUIConfig['suite']['defaultIncoterm']
  termsBasisOptions?: PurchasingUIConfig['suite']['termsBasisOptions']
  productType?: PurchasingUIConfig['suite']['productType']
  moqEnabled?: PurchasingUIConfig['suite']['moqEnabled']
  spendEnabled?: PurchasingUIConfig['suite']['spendEnabled']
  isEditing?: boolean
}

export const Prices = ({
  allowedIncoterms,
  defaultIncoterm,
  productType,
  termsBasisOptions,
  moqEnabled,
  spendEnabled,
  isEditing,
}: Props) => {
  const { visibleFields } = useContext(NegotiationEventFormContext)
  const isRebatesNegotiation = visibleFields.includes('rebatesEnabled')
  const isLineItemsNegotiation = visibleFields.includes('lineItems')
  const isContractCost = productType === ProductType.ContractCost

  if (isContractCost && spendEnabled && isRebatesNegotiation) {
    return (
      <SpendOrPricesSwitch
        isEditing={isEditing}
        pricesComponent={() => (
          <LineItems
            productType={productType}
            allowedIncoterms={allowedIncoterms}
            defaultIncoterm={defaultIncoterm}
            termsBasisOptions={termsBasisOptions}
            moqEnabled={isContractCost ? moqEnabled : false}
            spendEnabled={spendEnabled}
          />
        )}
        spendComponent={() => <RebatesWithApplicableSpend />}
      />
    )
  }

  if (isContractCost && isRebatesNegotiation) {
    if (isLineItemsNegotiation) {
      return (
        <LineItemsWithRebate
          productType={productType}
          allowedIncoterms={allowedIncoterms}
          defaultIncoterm={defaultIncoterm}
          termsBasisOptions={termsBasisOptions}
          moqEnabled={moqEnabled}
          spendEnabled={spendEnabled}
        />
      )
    }

    return <RebatesWithApplicableSpend />
  }

  return (
    <LineItems
      productType={productType}
      allowedIncoterms={allowedIncoterms}
      defaultIncoterm={defaultIncoterm}
      termsBasisOptions={termsBasisOptions}
      moqEnabled={isContractCost ? moqEnabled : false}
      spendEnabled={spendEnabled}
    />
  )
}
