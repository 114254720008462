import { useRfpNegotiationPreviewActions } from '@logistics/pages/contractedLanes/RFPDetailsPage/NegotiationActions'
import { CircularProgress, Link } from '@mui/material'
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded'
import React from 'react'

export const RfpPreviewLinkCell = ({ negotiationId }: { negotiationId: number }) => {
  const { negotiationPreviewLink, isPreviewLinkLoading } = useRfpNegotiationPreviewActions()

  const onClick = async () => {
    if (!isPreviewLinkLoading) {
      const link = await negotiationPreviewLink(negotiationId)
      if (link) {
        window.open(link, '_blank')
      }
    }
  }

  return isPreviewLinkLoading ? (
    <CircularProgress
      size={12}
      thickness={5}
      sx={{ ml: 0.8, color: 'inherit', fontSize: 'inherit', verticalAlign: 'middle' }}
    />
  ) : (
    <Link onClick={onClick} sx={{ lineHeight: '1.5rem' }}>
      <OpenInNewRoundedIcon sx={{ verticalAlign: 'bottom' }} /> Try out
    </Link>
  )
}
