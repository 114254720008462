import { useGetChangeRequestsQuery } from 'src/merchandising/store/'
import { useEffect } from 'react'
import { useLayoutContext } from '@components/Layout'
import { ChangeFormContent } from 'src/merchandising/pages/ChangeRequests/ChangeFormsPage/ChangeFormContent'

const ALLOWED_SUPPLIER_IDS = ['120312943', '120352122', '110031101', '120443254']

export const CostIncreaseFormsPage = (): JSX.Element => {
  const { setStatisticsHeaderData } = useLayoutContext()
  const { data, isFetching } = useGetChangeRequestsQuery()

  const filteredData = data
    ? data?.items.filter((form) => ALLOWED_SUPPLIER_IDS.includes(form.supplierId))
    : []

  useEffect(() => {
    if (data?.items.length) {
      setStatisticsHeaderData([
        {
          title: 'Avg. Cost Change',
          primaryValue: '+25,45%',
          secondaryValue: 'Based on supplier requests',
          options: { primaryTrend: { direction: 'down', color: 'success' } },
        },
        {
          title: 'Avg. Billing Margin',
          primaryValue: '41,54%',
          secondaryValue: '+4,1% (our proposal)',
          options: { primaryTrend: { direction: 'up', color: 'success' } },
        },
        {
          title: 'Total Revenue Today',
          primaryValue: '$501.6M',
          secondaryValue: '+9,41% YoY',
          options: { primaryTrend: { direction: 'up', color: 'success' } },
        },
        {
          title: 'Total Profit',
          primaryValue: '$103.5M',
          secondaryValue: '+7,41% YoY',
          options: { primaryTrend: { direction: 'up', color: 'success' } },
        },
      ])
    }
  }, [setStatisticsHeaderData, data])

  useEffect(() => {
    return () => {
      setStatisticsHeaderData([])
    }
  }, [setStatisticsHeaderData])

  return (
    <ChangeFormContent
      pageConfig={{
        newNegotiationPrepId: null,
        listTitle: 'Requested Cost Change Forms',
        itemNavigateTo: ':negotiationPreparationId/overview',
      }}
      isLoading={isFetching}
      items={filteredData}
    />
  )
}
