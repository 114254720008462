import { FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/dist/query'
import { UseLazyQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { QueryDefinition } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { downloadFile } from '@utils'

/**
 * Creates a hook for downloading a file.
 *
 *     const useDownloadMyFile = createFileDownloadHook(
 *       'my-file.csv',
 *       myApi.endpoints.downloadMyFile.useLazyQuery,
 *     )
 *
 * The created hook returns a downloading function and error (when download fails).
 *
 *     const [downloadMyFile, error] = useDownloadMyFile()
 *     useSnackbarErrorEffect(error)
 *     ...
 *     <Link onClick={() => downloadMyFile(queryParameter)} />
 *
 * The download function doesn't only fetch the file,
 * but also provides it to browser for download.
 *
 * The error can be provided to useSnackbarErrorEffect() for easy handling.
 *
 * @param filename How to name the downloaded file.
 * @param useLazyQuery Redux-toolkit lazy-query hook.
 */
export const createFileDownloadHook = <TParam>(
  filename: string,
  useLazyQuery: LazyQueryWithParam<TParam>,
) => {
  return () => {
    return useInnerDownloadHook(filename, useLazyQuery)
  }
}

export const createNamedFileDownloadHook = <TParam>(useLazyQuery: LazyQueryWithParam<TParam>) => {
  return (filename: string) => {
    return useInnerDownloadHook(filename, useLazyQuery)
  }
}

const useInnerDownloadHook = <TParam>(
  filename: string,
  useLazyQuery: LazyQueryWithParam<TParam>,
) => {
  const [getFile, { error }] = useLazyQuery()
  const downloadFn = async (param: TParam) => {
    const { data } = await getFile(param)
    if (data) {
      downloadFile(filename, data)
    }
  }
  return [downloadFn, error] as const
}

type LazyQueryWithParam<TParam> = UseLazyQuery<
  QueryDefinition<
    TParam,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta>,
    string,
    string,
    string
  >
>
