import { MultilineCell, MultilineDataCellData } from './MultilineCell'
import { Grid } from '@mui/material'

interface Props {
  data: MultilineDataCellData[]
}

export const DynamicMultilineRow = ({ data }: Props): JSX.Element => (
  <Grid container direction='row'>
    {data.map((item, index) => (
      <Grid item key={`${item.title}-${index}`} xs={6} md='auto'>
        <MultilineCell
          title={item.title}
          primaryValue={item.primaryValue}
          secondaryValue={item.secondaryValue}
          actionComponent={item.actionComponent}
          options={item.options}
        />
      </Grid>
    ))}
  </Grid>
)
