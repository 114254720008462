import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import {
  ConsignmentAgreementStatusEnum,
  NegotiationEvent,
  NegotiationEventLineItem,
  NegotiationEventSupplierLineItem,
} from '../store/types'
import { Formatter } from '@utils'
import { NoValueCell } from '@components/table'
import { formatCurrency } from '@shared/utils/formatCurrency'
import { TooltipCell } from '@components/TooltipCell'
import { isIsoDate } from './date'
import { formatInvoiceDate } from './formatting'
import { LineItem } from '../pages/NegotiationEventDetails/NegotiationEventSummary/NegotiationEventSummary'

export const renderUnitPriceCell = (
  params: GridRenderCellParams<LineItem, number>,
  formatter: Formatter,
) => {
  const value = params.value
  const { currency } = params.row

  if (value === null || value === undefined) {
    return <NoValueCell />
  }

  const formattedValue = formatCurrency(value, currency, formatter, 6)

  return <TooltipCell tooltip={formattedValue}>{formattedValue}</TooltipCell>
}

export const renderTooltipCell = ({ value }: GridRenderCellParams<LineItem, string | number>) =>
  value ? <TooltipCell tooltip={value}>{value}</TooltipCell> : <NoValueCell />

export const renderTooltipUnitCell = (params: GridRenderCellParams<LineItem, string | number>) =>
  params.value && params.row.unit ? (
    <TooltipCell tooltip={`${params.value} ${params.row.unit}`}>
      {params.value} {params.row.unit}
    </TooltipCell>
  ) : (
    renderTooltipCell(params)
  )

export const renderAcceptableIncotermsCell = (
  params: GridRenderCellParams<NegotiationEventLineItem, number>,
) => {
  const acceptableIncoterms = params.row.negotiationSettings?.acceptableIncoterms ?? []

  return renderTooltipCell({
    value: acceptableIncoterms.join(', '),
  } as GridRenderCellParams)
}

export const renderInvoiceDateCell = (
  lineItem: LineItem,
  requisition: NegotiationEvent,
  formatter: Formatter,
) => {
  const invoiceDates = requisition.contractModel?.properties?.invoiceDates as Record<number, string>
  const invoiceDate = invoiceDates?.[lineItem.numberInCollection]
  let formattedInvoiceDate = invoiceDate

  if (isIsoDate(invoiceDate)) {
    formattedInvoiceDate = formatInvoiceDate(invoiceDate, formatter)
  }

  return invoiceDate ? (
    <TooltipCell tooltip={formattedInvoiceDate}>{formattedInvoiceDate}</TooltipCell>
  ) : (
    <NoValueCell />
  )
}

export const renderConsignmentInventoryCell = (
  params: GridRenderCellParams<NegotiationEventSupplierLineItem, number>,
) => {
  const lineItem = params.row

  const initialStatus = lineItem?.negotiationSettings?.consignmentAgreementStatus
  const isNegotiated = lineItem.negotiatedConsignmentInventory

  if (typeof isNegotiated != 'boolean') {
    return <NoValueCell />
  }

  let label = 'No agreement'

  if (initialStatus === ConsignmentAgreementStatusEnum.Existing && isNegotiated) {
    label = 'Existing agreement'
  }

  if (initialStatus === ConsignmentAgreementStatusEnum.Needed && isNegotiated) {
    label = 'New agreement'
  }

  return renderTooltipCell({ value: label } as GridRenderCellParams)
}
