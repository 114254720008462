import React, { useRef } from 'react'
import { LineAnnotationOptions } from 'chartjs-plugin-annotation'
import { ChartDataset, ChartOptions, Scale, TooltipItem } from 'chart.js'
import { Chart } from 'react-chartjs-2'
import { getNumberFormatter } from '@utils'
import { PriceVolumePoint } from '@common/types'
import { Box, Button, Stack, Tooltip, Typography, useTheme } from '@mui/material'
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types'
import { ZoomInMap } from '@mui/icons-material'
import HelpIcon from '@mui/icons-material/Help'
import { Theme } from '@mui/material/styles'

export interface ChartProps {
  data?: PriceVolumePoint[]
  defaultData?: PriceVolumePoint[]
  focusPoint: PriceVolumePoint | null
  itemName: string
  annotations: { target: number; lac: number }
}

export const PriceVolumeChart = ({
  itemName,
  data,
  defaultData,
  annotations,
  focusPoint,
}: ChartProps): JSX.Element => {
  const theme = useTheme()
  const chartRef = useRef<ChartJSOrUndefined<'line', PriceVolumePoint[]>>(null)
  const lineColor = theme.palette.common.charts.first

  if (!defaultData) {
    console.warn('No default Net Price-Volume Points chart available')
  }

  const combinedData = [...(defaultData ?? []), ...(data ?? [])]

  const minMaxPriceCombined = getMinMaxPricePoints(combinedData)
  const defaultMinMaxPricePoints = getMinMaxPricePoints(defaultData ?? [])
  const minMaxPricePoints = getMinMaxPricePoints(data)

  const onChartZoomReset = () => {
    chartRef.current?.resetZoom()
  }

  return (
    <Box sx={{ width: '99%' }}>
      <Stack direction='row' justifyContent='space-between' mb={1}>
        <Stack direction='row' alignItems='center'>
          <Typography variant='h6'>Net Price-Volume Points Graph</Typography>
          <Tooltip
            placement='top'
            title='This figure shows the expected purchasing volume at different Net Price values. It is
              calculated by aggregating the Forecast data across all markets, based on the Market
              Forecast sheet in the input file.'
          >
            <HelpIcon sx={{ marginLeft: 0.5, fontSize: '100%' }} />
          </Tooltip>
        </Stack>
        <Button variant='text' onClick={onChartZoomReset} startIcon={<ZoomInMap />}>
          Reset zoom
        </Button>
      </Stack>
      <Chart
        ref={chartRef}
        key={'priceVolume'}
        type={'line' as const}
        data={{
          datasets: [
            ...prepareDatasets(itemName, data, minMaxPricePoints, annotations, lineColor),
            ...prepareDatasets(
              itemName,
              defaultData,
              defaultMinMaxPricePoints,
              annotations,
              theme.palette.grey.A400,
              true,
            ),
          ],
        }}
        options={getOptions(
          itemName,
          combinedData,
          minMaxPriceCombined,
          annotations,
          focusPoint,
          lineColor,
          theme,
        )}
      />
    </Box>
  )
}

interface MinMaxPriceValues {
  minPricePoint: PriceVolumePoint
  maxPricePoint: PriceVolumePoint
  minVolume: PriceVolumePoint['volume']
  maxVolume: PriceVolumePoint['volume']
}

const zoomOptions = {
  zoom: {
    wheel: {
      enabled: true,
    },
    pinch: {
      enabled: true,
    },
    mode: 'xy',
  },
}

const getMinMaxForXScale = (
  annotations: ChartProps['annotations'],
  data?: PriceVolumePoint[],
): Pick<Scale, 'min' | 'max'> => {
  const maxPointValue =
    data
      ?.map((point) => point.price)
      .sort()
      .slice(-1)[0] ?? 0
  const targetToLacRange = annotations.lac - annotations.target
  const min = Math.max(0, Math.round(annotations.lac - 2.1 * targetToLacRange))
  const annotationMax = Math.round(annotations.lac + 0.1 * targetToLacRange)
  const max = Math.max(maxPointValue * 1.005, annotationMax)
  return { min, max }
}

const getMinMaxForYScale = (
  minMaxPricePoints: MinMaxPriceValues | null,
): Pick<Scale, 'min' | 'max'> => {
  const minVolume = minMaxPricePoints !== null ? minMaxPricePoints.minVolume : 0
  const maxVolume = minMaxPricePoints !== null ? minMaxPricePoints.maxVolume : 10
  const max = Math.round(minVolume + 1.2 * (maxVolume - minVolume))
  const min = Math.round(maxVolume + 1.05 * (minVolume - maxVolume))

  if (max === min || max === maxVolume || min === minVolume) {
    return { min: min - 1, max: max + 1 }
  }

  return { min, max }
}

const getMinMaxPricePoints = (data?: PriceVolumePoint[]): MinMaxPriceValues | null => {
  if (data && data.length) {
    return data.reduce(
      (prev, item) => ({
        minPricePoint: prev.minPricePoint.price < item.price ? prev.minPricePoint : item,
        maxPricePoint: prev.maxPricePoint.price > item.price ? prev.maxPricePoint : item,
        minVolume: Math.min(prev.minVolume, item.volume),
        maxVolume: Math.max(prev.maxVolume, item.volume),
      }),
      {
        minPricePoint: data[0],
        maxPricePoint: data[0],
        maxVolume: data[0].volume,
        minVolume: data[0].volume,
      } as MinMaxPriceValues,
    )
  }

  return null
}

const prepareDatasets = (
  itemName: ChartProps['itemName'],
  data: ChartProps['data'],
  minMaxPricePoints: MinMaxPriceValues | null,
  annotations: ChartProps['annotations'],
  color: string,
  isDefault = false,
): ChartDataset<'line', ChartProps['data']>[] => {
  const backgroundOrder = 10
  let minPricePointBorderline = {} as ChartDataset<'line', ChartProps['data']>
  let maxPricePointBorderline = {} as ChartDataset<'line', ChartProps['data']>

  if (minMaxPricePoints) {
    const { minPricePoint, maxPricePoint } = minMaxPricePoints

    minPricePointBorderline = {
      label: `Min price-volume point border ${isDefault ? 'default ' : ''}`,
      borderColor: color,
      borderDash: [10, 5],
      data: [{ ...minPricePoint, price: 0 }, minPricePoint],
      order: backgroundOrder,
    }

    if (maxPricePoint && maxPricePoint.price < annotations.lac) {
      maxPricePointBorderline = {
        label: `Max price-volume point border ${isDefault ? 'default ' : ''}`,
        borderColor: color,
        borderDash: [10, 5],
        data: [maxPricePoint, { price: annotations.lac, volume: 0 }],
        order: backgroundOrder,
      }
    }
  }

  return [
    minPricePointBorderline,
    {
      label: `${itemName} ${isDefault ? 'default ' : ''}`,
      borderColor: color,
      data,
      order: isDefault ? backgroundOrder : 0,
    },
    maxPricePointBorderline,
  ]
}

const getOptions = (
  itemName: ChartProps['itemName'],
  data: ChartProps['data'],
  minMaxPricePoints: MinMaxPriceValues | null,
  annotations: ChartProps['annotations'],
  focusPoint: PriceVolumePoint | null,
  lineColor: string,
  theme: Theme,
) => {
  const formatter = getNumberFormatter(2)

  return {
    responsive: true,
    scales: {
      x: {
        title: { display: true, text: 'Price' },
        type: 'linear',
        ...getMinMaxForXScale(annotations, data),
      },
      y: {
        title: { display: true, text: 'Volume' },
        ...getMinMaxForYScale(minMaxPricePoints),
      },
    },
    parsing: {
      xAxisKey: 'price',
      yAxisKey: 'volume',
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: () => itemName,
          label: (tooltipItem: TooltipItem<'line'>) => {
            const { volume, price } = tooltipItem.raw as PriceVolumePoint
            return `Volume: ${volume}, Price: ${formatter.format(price)}€`
          },
        },
      },
      legend: {
        display: false,
      },
      annotation: {
        annotations: {
          currentBubble: {
            type: 'point',
            xValue: focusPoint?.price,
            yValue: focusPoint?.volume,
            backgroundColor: lineColor,
            display: !!focusPoint,
          },
          targetLine: {
            type: 'line',
            label: {
              content: 'Target',
              display: true,
              rotation: 90,
              position: 'end',
            },
            xMin: annotations.target,
            xMax: annotations.target,
            borderColor: theme.palette.success.main,
            borderWidth: 2,
          },
          lacLine: {
            type: 'line',
            label: {
              content: 'LAA',
              display: true,
              rotation: 90,
              position: 'end',
            },
            xMin: annotations.lac,
            xMax: annotations.lac,
            borderColor: theme.palette.error.main,
            borderWidth: 2,
          },
        } as Record<string, LineAnnotationOptions>,
      },
      zoom: zoomOptions,
      datalabels: {
        display: false,
      },
    },
  } as ChartOptions
}
