import { nonDecimalUsdFormatter } from '@utils'
import { getPriceChangeDirectionColor } from 'src/merchandising/pages/ChangeRequests/NegotiationPreparation/Adjustments/utils'
import { CaptionedValueCell, NotAvailableCell } from 'src/shared/components/table'

interface Props {
  currentIncomePerArticle: number | undefined
  newIncomePerArticle: number | undefined
  lastYearVolume: number | undefined
}

export const IncomeWithYearlyCell = ({
  currentIncomePerArticle,
  newIncomePerArticle,
  lastYearVolume,
}: Props): JSX.Element => {
  if (!newIncomePerArticle || !lastYearVolume) {
    return <NotAvailableCell />
  }
  const currentIncomePerArticleOrZero = currentIncomePerArticle ? currentIncomePerArticle : 0
  const lastYearIncome = currentIncomePerArticleOrZero * lastYearVolume
  const projectedIncome = newIncomePerArticle * lastYearVolume
  const changeValue = projectedIncome - lastYearIncome
  const color = getPriceChangeDirectionColor(changeValue)
  return (
    <CaptionedValueCell
      mainContent={nonDecimalUsdFormatter(projectedIncome)}
      captionContent={`From ${nonDecimalUsdFormatter(lastYearIncome)}`}
      removeCaptionOpacity={!!color}
    />
  )
}
