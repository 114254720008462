import React from 'react'
import { styled, TableCell, Box } from '@mui/material'
import { formatValue } from './utils'
import { RowValue } from 'src/merchandising/types'
import { TableColDef } from './RowDetailsPanel'
import { ItemData } from '@common/types'
import { LastQuarterTooltip } from './LastQuarterTooltip'
import { ScenarioState } from '@common/types/scenario/ScenarioState'

interface Props {
  column: TableColDef
  rowKey: keyof RowValue
  row: ItemData
  marketInfo: ScenarioState['market_info']
  na?: boolean
  unavailable?: boolean
  value?: number | null
}

export const unavailableCellStyle = { color: '#FF7043' }
export const naCellStyle = { color: 'rgba(0, 0, 0, 0.40)' }

export const StyledValueCell = ({
  na,
  rowKey,
  unavailable,
  row,
  marketInfo,
  value,
  column,
}: Props): JSX.Element => {
  if (na) {
    return (
      <StyledTableCell component='div' sx={naCellStyle}>
        N/A
      </StyledTableCell>
    )
  }

  if (unavailable) {
    return (
      <StyledTableCell component='div' sx={unavailableCellStyle}>
        Unavailable
      </StyledTableCell>
    )
  }

  return (
    <StyledTableCell component='div' sx={{ display: 'flex' }}>
      <Box>{formatValue(column, value)}</Box>
      {rowKey === 'last_quarter' && column.key === 'invoice_prices' && (
        <LastQuarterTooltip row={row} marketInfo={marketInfo} />
      )}
    </StyledTableCell>
  )
}

export const StyledTableCell = styled(TableCell)({
  display: 'block',
  minHeight: '56px',

  ':first-of-type': {
    minWidth: '200px',
  },
}) as typeof TableCell
