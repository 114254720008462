import DownloadIcon from '@mui/icons-material/Download'
import { BatchActionButton, BatchActionsContainer } from '@components/BatchActions'
import { NegotiationUnitEntity } from '@common/dto/pact/negotiation-unit-entity'
import { useGenericNegotiationUnitsActions } from '../../hooks/useGenericNegotiationUnitsActions'

interface Props {
  negotiationId: number
  allNegotiationsCount: number
  selectedNegotiationUnits: NegotiationUnitEntity[]
}

export const BatchActionsBar = ({
  negotiationId,
  allNegotiationsCount,
  selectedNegotiationUnits,
}: Props) => {
  const { handleDownloadNegotiationUnits } = useGenericNegotiationUnitsActions(
    negotiationId,
    allNegotiationsCount,
    selectedNegotiationUnits,
  )

  return (
    <BatchActionsContainer>
      <BatchActionButton
        startIcon={<DownloadIcon />}
        disabled={selectedNegotiationUnits.length === 0}
        onClick={handleDownloadNegotiationUnits}
      >
        Download results
      </BatchActionButton>
    </BatchActionsContainer>
  )
}
