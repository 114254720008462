import { Locale } from '@common/constants'

export const getCurrencySymbol = (currency?: string, locale: Locale = 'en-US') =>
  currency
    ? new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
      })
        .formatToParts()
        .find((part) => part.type === 'currency')?.value
    : ''
