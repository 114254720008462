import { isEowPaymentDaysObject, isPaymentDaysObject, PaymentDayObject } from '../store/types'
import { isEomPaymentDaysObject } from '../store/types'
import { dedupeArrayByAllObjectProperties } from '@common/utils'

const getPaymentDaysObjectLabel = (option: PaymentDayObject): string => {
  if (option.type === 'eom') {
    return `${option.netDays} EOM ${option.eomDays}`
  }
  if (option.type === 'eow') {
    return `${option.netDays} EOW ${option.eowDays}`
  }
  return String(option.netDays)
}

export const getPaymentDaysLabel = (option: unknown): string => {
  if (isPaymentDaysObject(option)) {
    return getPaymentDaysObjectLabel(option)
  }
  return String(option)
}

export const getMinMaxPaymentDaysLabel =
  (paymentDayOptions: (number | PaymentDayObject)[]) => (option: unknown) => {
    if (isPaymentDaysObject(option)) {
      return getPaymentDaysObjectLabel(option)
    }
    const paymentDayObjects: PaymentDayObject[] = transformPaymentDaysOptions(paymentDayOptions)
    // Translate the numeric min / max payment days value to a possible EOM payment days object
    const paymentDayOption = paymentDayObjects?.find((pdo) => {
      return (
        (isEomPaymentDaysObject(pdo) || isEowPaymentDaysObject(pdo)) &&
        pdo.averageDays === Number(option)
      )
    })
    if (paymentDayOption) {
      return getPaymentDaysLabel(paymentDayOption)
    }

    return String(option)
  }

export const getPaymentDaysNumericValue = (input: PaymentDayObject | number | string): number => {
  if (isPaymentDaysObject(input)) {
    if (input.type === 'eom' || input.type === 'eow') {
      return input.averageDays
    }

    return input.netDays
  }

  if (typeof input === 'string') {
    return parseInt(input, 10)
  }

  return input
}

/*
 Always map paymentDays options to PaymentDayObject[]
 and remove possible duplicates that may arise from API returning payment days as numbers
 but ExistingPaymentDaysFieldCtrl component works PaymentDayObject values
 because this is the structure that the API is returning payment day values in
*/
export const transformPaymentDaysOptions = (
  paymentDays: (number | PaymentDayObject)[],
): PaymentDayObject[] => {
  return dedupeArrayByAllObjectProperties(
    paymentDays.map((day) => {
      if (typeof day === 'number') {
        return { type: 'net', netDays: day }
      } else {
        return day
      }
    }),
  )
}
