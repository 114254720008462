import { useFormContext } from 'react-hook-form'
import { Grid, IconButton } from '@mui/material'
import { useContext, useState } from 'react'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { ConsignmentAgreement } from './ConsignmentAgreement'
import { ConsignmentAgreementStatusEnum, PurchasingUIConfig } from '@procurement/store/types'
import { MinimumOrderQuantity } from './MinimumOrderQuantity'
import { NegotiationEventFormContext } from '@procurement-shared/NegotiationEventForm/NegotiationEventFormContext'
import { DropdownButton } from '@procurement-shared/NegotiationEventForm/sections/LineItems/DropdownButton'
import { NegotiationEventFormData } from '../../schema'

type Props = {
  lineItem: NegotiationEventFormData['lineItems'][number]
  moqEnabled: PurchasingUIConfig['suite']['moqEnabled']
}

export const AdditionalLineItemTerms = ({ lineItem, moqEnabled }: Props) => {
  const [showConsignmentComponent, setShowConsignmentComponent] = useState(
    Boolean(
      lineItem.consignmentAgreementStatus &&
        lineItem.consignmentAgreementStatus !== ConsignmentAgreementStatusEnum.NoAnswer,
    ),
  )

  const { getValues, setValue } = useFormContext<NegotiationEventFormData>()
  const hideAndNullifyConsignmentAgreement = () => {
    const currentLineItems = getValues('lineItems')
    const updatedLineItems = currentLineItems.map((item) => {
      if (item.numberInCollection === lineItem.numberInCollection) {
        return { ...item, consignmentAgreementStatus: ConsignmentAgreementStatusEnum.NoAnswer }
      }
      return item
    })
    setValue('lineItems', updatedLineItems)
    setShowConsignmentComponent(false)
  }

  const { visibleFields } = useContext(NegotiationEventFormContext)
  const toggleConsignmentAgreement = () => setShowConsignmentComponent(!showConsignmentComponent)

  const [showMinimumOrderQuantityComponent, setShowMinimumOrderQuantityComponent] = useState(
    Boolean(lineItem.minimumOrderQuantity || lineItem.maximumAcceptableMoq),
  )
  const hideAndNullifyMinimumOrderQuantity = () => {
    const currentLineItems = getValues('lineItems')
    const updatedLineItems = currentLineItems.map((item) => {
      if (item.numberInCollection === lineItem.numberInCollection) {
        return { ...item, minimumOrderQuantity: null, maximumAcceptableMoq: null }
      }
      return item
    })
    setValue('lineItems', updatedLineItems)
    setShowMinimumOrderQuantityComponent(false)
  }

  const toggleMinimumOrderQuantity = () =>
    setShowMinimumOrderQuantityComponent(!showMinimumOrderQuantityComponent)

  const getMenuItems = () => {
    const menuItems = []
    const isConsignmentAgreementConfigured = visibleFields.includes(
      'suppliers.0.negotiationSettings.consignmentAgreementStatuses',
    )
    const showConsignmentMenuItem = isConsignmentAgreementConfigured && !showConsignmentComponent
    if (showConsignmentMenuItem) {
      menuItems.push({ label: 'Consignment', onClick: toggleConsignmentAgreement })
    }

    const showMOQMenuItem = moqEnabled && !showMinimumOrderQuantityComponent
    if (showMOQMenuItem) {
      menuItems.push({ label: 'Minimum order quantity (MOQ)', onClick: toggleMinimumOrderQuantity })
    }

    return menuItems
  }

  const menuItems = getMenuItems()
  const showAddTermsButton = menuItems.length > 0

  return (
    <Grid container sx={{ mb: 2, pl: 14 }} direction='row'>
      {showConsignmentComponent && (
        <>
          <Grid item xs={11}>
            <ConsignmentAgreement row={lineItem} />
          </Grid>
          <Grid item xs={1}>
            <IconButton size='small' onClick={hideAndNullifyConsignmentAgreement} aria-label='hide'>
              <DeleteOutlineIcon sx={{ fontSize: '1.25rem' }} />
            </IconButton>
          </Grid>
        </>
      )}
      {showMinimumOrderQuantityComponent && (
        <>
          <Grid item xs={11}>
            <MinimumOrderQuantity row={lineItem} />
          </Grid>
          <Grid item xs={1}>
            <IconButton size='small' onClick={hideAndNullifyMinimumOrderQuantity} aria-label='hide'>
              <DeleteOutlineIcon sx={{ fontSize: '1.25rem' }} />
            </IconButton>
          </Grid>
        </>
      )}
      {showAddTermsButton && (
        <Grid item xs={12}>
          <DropdownButton variant='outlined' size='small' color='tertiary' menuItems={menuItems}>
            + Add terms
          </DropdownButton>
        </Grid>
      )}
    </Grid>
  )
}
