import React, { MouseEvent, useState } from 'react'
import { Button, ButtonProps, Menu, MenuItem, Stack } from '@mui/material'

interface DropdownButtonProps extends ButtonProps {
  menuItems: { label: string; onClick: () => void }[]
}

export const DropdownButton: React.FC<DropdownButtonProps> = ({
  children,
  menuItems,
  ...buttonProps
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Stack direction='row' spacing={2}>
      <Button {...buttonProps} onClick={handleClick}>
        {children}
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              item.onClick()
              handleClose()
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  )
}
