import { Fragment, PropsWithChildren } from 'react'
import { CommercialTermsNegotiation, GrowthRebateTier } from '@campaigns/types'
import { Grid, Typography } from '@mui/material'
import { CommercialTermsTooltip } from '../../../components/CommercialTermsTooltip'
import { useFormatter } from '@shared/hooks'

export const GrowthRebatesTooltip = ({
  negotiation,
  children,
}: PropsWithChildren<{ negotiation: CommercialTermsNegotiation }>) => {
  return (
    <CommercialTermsTooltip title={<TooltipText negotiation={negotiation} />}>
      <span>{children}</span>
    </CommercialTermsTooltip>
  )
}

const TooltipText = ({ negotiation }: { negotiation: CommercialTermsNegotiation }) => {
  const formatter = useFormatter()

  const growthRebateStructure =
    negotiation.contracts[0]?.standardTerms?.growthRebateStructure?.agreed

  if (!growthRebateStructure) {
    return null
  }

  const sortedRebateTiers = [...(growthRebateStructure.tiers ?? [])].sort((a, b) => a.min - b.min)

  const rebateType = growthRebateStructure.type
  const displayType = growthRebateStructure.displayType

  return (
    <>
      <Grid container sx={{ m: 1 }}>
        {sortedRebateTiers.map((tier) => (
          <Fragment key={`${tier.min}–${tier.rebatePercent}`}>
            <Grid item xs={8}>
              <Typography variant='body2'>
                <GrowthRebateRange
                  tier={tier}
                  displayType={displayType}
                  currency={negotiation.currency as string}
                />
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                {formatter.percent0To1(tier.rebatePercent / 100)}
              </Typography>
            </Grid>
          </Fragment>
        ))}
        {rebateType === 'spend' && (
          <Typography variant='body2' sx={{ mt: 1.5 }}>
            Rebate applicable to entire spend.
          </Typography>
        )}
        {rebateType === 'growth' && (
          <Typography variant='body2' sx={{ mt: 1.5 }}>
            Rebate applicable to only growth.
          </Typography>
        )}
      </Grid>
    </>
  )
}

export const GrowthRebateRange = ({
  tier,
  displayType,
  currency,
}: {
  tier: GrowthRebateTier
  displayType: 'percentage' | 'currency'
  currency: string | null
}) => {
  const formatter = useFormatter()
  const formatGrowthValue = (value: number, displayType: string, currency: string) => {
    if (displayType === 'percentage') {
      return formatter.percent0To1(value - 1, { fractionDigits: 0 })
    }
    return formatter.currency(value, { currency })
  }

  if (tier.max) {
    return (
      <>
        {formatGrowthValue(tier.min, displayType, currency ?? 'USD')} to{' '}
        {formatGrowthValue(tier.max, displayType, currency ?? 'USD')} growth
      </>
    )
  }

  return <>Over {formatGrowthValue(tier.min, displayType, currency ?? 'USD')} growth</>
}
