import React from 'react'
import {
  DataGridPro,
  GridColDef,
  MuiEvent,
  GridCellParams,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro'
import { ScenarioListItem } from '@common/dto'
import { Paper, styled } from '@mui/material'
import { generatePath, useNavigate } from 'react-router-dom'
import { DataGridLoader } from 'src/shared/components/DataGridLoader'
import { CopyLink } from './CopyLink'
import { DeleteScenario } from './DeleteScenario'
import { MerchandisingRoutes } from '../../../routes'
import { useOrgTag } from '../../../../main/hooks/useOrgTag'
import { useProjects } from '@hooks'

interface Props {
  data: ScenarioListItem[]
  isLoading: boolean
}

export const ScenarioTable = ({ data, isLoading }: Props) => {
  const navigate = useNavigate()
  const orgTag = useOrgTag()
  const { activeProjectTag } = useProjects()

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 0.2,
      disableColumnMenu: true,
      cellClassName: 'idCell',
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'sourceFileName',
      headerName: 'Source File',
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'owner',
      headerName: 'Owner',
      flex: 0.7,
      disableColumnMenu: true,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      flex: 0.6,
      disableColumnMenu: true,
    },
    {
      field: 'submittedAt',
      headerName: 'Submitted',
      flex: 0.6,
      disableColumnMenu: true,
    },
    {
      field: 'sessionKey',
      headerName: 'Links',
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<ScenarioListItem, string>) => (
        <CopyLink sessionKey={params.value} />
      ),
    },
    {
      field: 'delete',
      headerName: 'Delete',
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<ScenarioListItem>) => (
        <DeleteScenario scenario={params.row} />
      ),
    },
  ]

  return (
    <Paper>
      <StyledDataGrid
        autoHeight
        onCellClick={(
          params: GridCellParams<ScenarioListItem>,
          event: MuiEvent<React.MouseEvent>,
        ) => {
          if (params.field === 'id') {
            navigate(
              generatePath(MerchandisingRoutes.SCENARIO_DETAILS, {
                orgTag,
                projectTag: activeProjectTag!,
                sessionKey: params.row.sessionKey,
              }),
            )
          }
          if (params.field === 'sessionKey') event.stopPropagation()
        }}
        columns={columns}
        loading={isLoading}
        rows={data}
        disableRowSelectionOnClick
        localeText={{
          noRowsLabel: 'No Negotiation Scenarios',
        }}
        slots={{ loadingOverlay: DataGridLoader }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'createdAt', sort: 'desc' }],
          },
        }}
      />
    </Paper>
  )
}

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  '.idCell:hover': {
    cursor: 'pointer',
    backgroundColor: theme.palette.grey['300'],
    transition: 'background-color 300ms ease-out',
  },
}))
