import { Theme } from '@mui/material'
import { ChartOptions, TooltipItem } from 'chart.js'
import { AnnotationOptions } from 'chartjs-plugin-annotation'
import { Outcome } from '@common/types/scenario/ScenarioState'
import { Formatter } from '@utils'
import {
  NEXT_QUARTER_OPTION,
  QUARTER_AGGREGATE_OPTION,
} from 'src/merchandising/pages/Scenarios/ScenarioPreparation/ScenarioDetails/constants'
import { AnnotationsData, SpendBenefitName } from './types'
import { ChartType } from '../../components/Filters/types'
import { BSRFilters, Filters } from '../../components/Filters'

interface GenerateAnnotationsParameters {
  quarters: string[]
  markets: string[]
  outcome?: string
  numberOfColumnsPerQuarter: number
  barsMultiplier: number
  topLabelHeight: number
  annotationsData: AnnotationsData
  theme: Theme
}

const generateChartAnnotations = ({
  outcome,
  topLabelHeight,
  annotationsData,
  numberOfColumnsPerQuarter,
  barsMultiplier,
  theme,
}: GenerateAnnotationsParameters): AnnotationOptions[] => {
  let annotationsIndex = 0
  const annotations = []

  const singleAnnotationsData = annotationsData.filter(
    (annotationParams) =>
      annotationParams.quarter !== NEXT_QUARTER_OPTION.label &&
      annotationParams.quarter !== QUARTER_AGGREGATE_OPTION.label,
  )

  annotations.push(
    ...singleAnnotationsData.flatMap((annotationParams, index) =>
      renderAnnotationsSet({
        annotationOffset: numberOfColumnsPerQuarter * index,
        numberOfColumns: numberOfColumnsPerQuarter,
        quarter: annotationParams.quarter,
        markets: annotationParams.markets,
        topLabelHeight,
        theme,
      }),
    ),
  )

  annotationsIndex += singleAnnotationsData.length

  const nextQuarter = annotationsData.find((data) => data.quarter === NEXT_QUARTER_OPTION.label)

  // add Target or LAA for Next quarter label
  if (nextQuarter) {
    annotations.push(
      ...renderAnnotationsSet({
        annotationOffset: numberOfColumnsPerQuarter * annotationsIndex,
        numberOfColumns: barsMultiplier,
        quarter: nextQuarter.quarter,
        markets: [outcome ?? ''],
        topLabelHeight,
        theme,
      }),
    )

    annotationsIndex += 1
  }

  const allQuarters = annotationsData.find(
    (data) => data.quarter === QUARTER_AGGREGATE_OPTION.label,
  )

  if (allQuarters) {
    const nextQuarterAdjustment = nextQuarter ? numberOfColumnsPerQuarter - barsMultiplier : 0
    const annotationOffset = numberOfColumnsPerQuarter * annotationsIndex - nextQuarterAdjustment

    annotations.push(
      ...renderAnnotationsSet({
        numberOfColumns: numberOfColumnsPerQuarter,
        quarter: allQuarters.quarter,
        markets: allQuarters.markets,
        annotationOffset,
        topLabelHeight,
        theme,
      }),
    )
  }

  return annotations
}

interface GetChartOptionsParameters {
  quarters: string[]
  markets: string[]
  outcome?: Outcome
  chartType?: ChartType
  selectedFilters: Omit<BSRFilters, 'week'>
  biggestChartDataValue: number
  barsMultiplier: number
  shouldRenderAnnotations: boolean
  formatter: Formatter
  currency?: string
  theme: Theme
}

export const getChartOptions = ({
  quarters,
  markets,
  outcome,
  chartType,
  selectedFilters,
  biggestChartDataValue,
  barsMultiplier,
  shouldRenderAnnotations,
  formatter,
  currency = 'EUR',
  theme,
}: GetChartOptionsParameters): ChartOptions => {
  const topLabelSpacePercentage = 5 // How much top quarters and markets labels will take from the whole bar
  const calculatedLabelHeight = (biggestChartDataValue * topLabelSpacePercentage) / 100 // exact number of units that the labels will take
  const biggestValueTwoPercents = (biggestChartDataValue * 10) / 100 // add extra space to avoid overlapping
  const roundedBiggestValue = Math.ceil(biggestChartDataValue / 10) * 10 // because ChartJS rounds this value, so should we for a more precises resulting height
  const suggestedMax = roundedBiggestValue + calculatedLabelHeight + biggestValueTwoPercents // this is not guaranteed max value for the chart
  const topLabelHeight = (suggestedMax * topLabelSpacePercentage) / 100 // five percents of the total height
  const numberOfColumnsPerQuarter = barsMultiplier * markets.length
  const annotationsData = getAnnotationsData(selectedFilters)
  const annotations = shouldRenderAnnotations
    ? generateChartAnnotations({
        quarters,
        markets,
        outcome,
        annotationsData,
        numberOfColumnsPerQuarter,
        barsMultiplier,
        topLabelHeight,
        theme,
      })
    : []

  const marketAnnotations = annotations.filter(
    (annotation) =>
      annotation.type === 'box' && annotation.backgroundColor === theme.palette.common.charts.sixth,
  )

  // market annotations amount should be equal to the number of bars so that correct market can be shown in a tooltip
  const adjustedMarketAnnotations: AnnotationOptions<'box'>[] = marketAnnotations.flatMap(
    (annotation) =>
      Array.from({ length: barsMultiplier }).fill(annotation) as AnnotationOptions<'box'>[],
  )

  return {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'x' as const,
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        suggestedMax: suggestedMax,
        ticks: {
          callback: (value) => getFormattedValue(formatter, value, chartType, currency),
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: ([tooltipItem]: TooltipItem<'line'>[]) => {
            return `${tooltipItem.label}, ${
              adjustedMarketAnnotations[tooltipItem.dataIndex].label?.content
            }`
          },
          afterTitle: ([tooltipItem]: TooltipItem<'line'>[]) => {
            const rawDataset: unknown = tooltipItem.dataset.data[tooltipItem.dataIndex]
            const totalValue = (rawDataset as Record<SpendBenefitName, number>).Total

            if (totalValue) {
              const formattedValue = getFormattedValue(formatter, totalValue, chartType)

              return `Total: ${formattedValue}`
            }
          },
          label: (tooltipItem: TooltipItem<'line'>) => {
            const formattedValue = getFormattedValue(formatter, tooltipItem.parsed.y, chartType)

            return `${tooltipItem.dataset.label}: ${formattedValue}`
          },
        },
        itemSort: () => {
          return -1
        },
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      annotation: {
        annotations,
      },
    },
  }
}

export const getDataToLabelsMap = (
  theme: Theme,
): Record<SpendBenefitName, { label: string; color: string }> => ({
  Total: {
    label: 'Total',
    color: theme.palette.common.black,
  },
  Net: {
    label: 'Net',
    color: theme.palette.common.charts.fifth,
  },
  CMF: {
    label: 'CMF',
    color: theme.palette.common.charts.fourth,
  },
  CTD: {
    label: 'CTD',
    color: theme.palette.common.charts.third,
  },
  GMF: {
    label: 'GMF',
    color: theme.palette.common.charts.second,
  },
  GTD: {
    label: 'GTD',
    color: theme.palette.common.charts.first,
  },
})

const getFormattedValue = (
  formatter: Formatter,
  value: string | number,
  chartType?: ChartType,
  currency?: string,
) => {
  if (!chartType) {
    return 0
  }

  if (chartType === 'Benefit to spend ratio') {
    return formatter.percent0To1(Number(value) / 100)
  }

  return formatter.currency(Number(value), { currency })
}

const getAnnotationsData = (selectedFilters: Omit<BSRFilters, 'week'>): AnnotationsData => {
  const annotationsData: AnnotationsData = selectedFilters.timeRange
    .filter(Filters.isSingleSelection)
    .map((timeRange) => ({
      quarter: timeRange.label,
      markets: selectedFilters.markets.map((market) => market.label),
    }))

  if (Filters.isFutureTimeRangeSelected(selectedFilters)) {
    annotationsData.push({
      quarter: NEXT_QUARTER_OPTION.label,
      markets: ['marketThatNeedsToBeHereForLabels'],
    })
  }

  if (Filters.isTimeRangeAggregationSelected(selectedFilters)) {
    annotationsData.push({
      quarter: QUARTER_AGGREGATE_OPTION.label,
      markets: selectedFilters.markets.map((market) => market.label),
    })
  }

  return annotationsData
}

interface RenderAnnotationsSetParameters {
  annotationOffset: number
  numberOfColumns: number
  topLabelHeight: number
  quarter: string
  markets: string[]
  theme: Theme
}

const renderAnnotationsSet = ({
  annotationOffset,
  numberOfColumns,
  topLabelHeight,
  quarter,
  markets,
  theme,
}: RenderAnnotationsSetParameters) => {
  const annotationLeftSide = 0.4
  const annotationRightSide = annotationLeftSide + (numberOfColumns - 1)
  const xMin = annotationOffset - annotationLeftSide
  const xMax = annotationRightSide + annotationOffset

  const wrapperAnnotation: AnnotationOptions = {
    type: 'box',
    drawTime: 'beforeDraw',
    backgroundColor: theme.palette.common.charts.seventh,
    borderColor: 'transparent',
    xMin,
    xMax,
  }

  const labelsWrapper: AnnotationOptions = {
    type: 'box',
    backgroundColor: theme.palette.common.white,
    borderColor: 'transparent',
    borderRadius: 2,
    xMin,
    xMax,
    yMin: (context) => context.chart.scales.y.max,
    yMax: (context) => context.chart.scales.y.max - topLabelHeight * 2,
  }

  const quarterLabel: AnnotationOptions = {
    type: 'box',
    backgroundColor: theme.palette.common.charts.seventh,
    borderRadius: 2,
    borderColor: 'transparent',
    label: {
      display: true,
      content: quarter,
      color: theme.palette.text.secondary,
    },
    xMin,
    xMax,
    yMax: (context) => context.chart.scales.y.max - topLabelHeight,
    yMin: (context) => context.chart.scales.y.max,
  }

  const marketLabels: AnnotationOptions[] = markets.flatMap((market, index) => {
    const marketLabelWidth = (xMax - xMin) / markets.length
    const spaceBetweenLabels = 0.02
    const leftSpace = index === 0 ? 0 : spaceBetweenLabels
    const rightSpace = index === markets.length - 1 ? 0 : spaceBetweenLabels
    const separatorLineSpace = 0.02
    const labels = []

    const marketLabel: AnnotationOptions = {
      type: 'box',
      backgroundColor: theme.palette.common.charts.sixth,
      borderRadius: 2,
      borderColor: 'transparent',
      label: {
        display: true,
        content: market,
        color: theme.palette.common.white,
      },
      yMin: (context) => context.chart.scales.y.max - topLabelHeight,
      yMax: (context) => context.chart.scales.y.max - topLabelHeight * 2,
      xMin: xMin + marketLabelWidth * index + leftSpace,
      xMax: xMin + marketLabelWidth * (index + 1) - rightSpace,
    }

    const separator: AnnotationOptions = {
      type: 'line',
      drawTime: 'beforeDraw',
      borderColor: theme.palette.common.charts.sixth,
      borderDash: [6],
      borderWidth: 2,
      xMin: xMin + marketLabelWidth * (index + 1) - rightSpace + separatorLineSpace,
      xMax: xMin + marketLabelWidth * (index + 1) - rightSpace + separatorLineSpace,
    }

    labels.push(marketLabel)
    if (index !== markets.length - 1) {
      labels.push(separator)
    }

    return labels
  })

  return [wrapperAnnotation, labelsWrapper, quarterLabel, ...marketLabels]
}
