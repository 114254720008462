import { useController, useFormContext } from 'react-hook-form'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { PurchasingUIConfig, TermsBasisOption } from '@procurement/store/types'
import { FormRow, HideableBlock } from '@procurement/components/FormCommon/FormCommon'
import { useNegotiationEventForm } from '../../NegotiationEventFormContext'
import { NegotiationEventFormData } from '../../schema'

interface Props {
  termsBasisOptions?: PurchasingUIConfig['suite']['termsBasisOptions']
}

export const NegotiateInPercentages = ({ termsBasisOptions = [] }: Props) => {
  const { watch } = useFormContext<NegotiationEventFormData>()
  const termsBasis = watch('suppliers.0.negotiationSettings.termsBasis')
  const { visibleFields } = useNegotiationEventForm()
  const { field: termsBasisField } = useController({
    name: 'suppliers.0.negotiationSettings.termsBasis',
  })
  const { field: negotiateInPercentagesField } = useController({
    name: 'suppliers.0.negotiationSettings.negotiateInPercentages',
  })

  const defaultValue = termsBasisOptions[0]?.value ?? ''
  const enabled = Boolean(negotiateInPercentagesField.value)

  const onToggleNegotiateInPercentages = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    if (checked && !termsBasisField.value) {
      termsBasisField.onChange(defaultValue)
    }

    negotiateInPercentagesField.onChange(event, checked)
  }

  return (
    <HideableBlock hidden={!visibleFields.includes('suppliers.0.negotiationSettings.termsBasis')}>
      <FormRow>
        <Grid item xs={12} sx={{ mb: 1 }}>
          <Typography variant='body2'>
            By default, the negotiations are done over total price. You can choose to negotiate in
            percentages instead (as in discounts and price increases). If you choose this option,
            please indicate what the unit prices represent.
          </Typography>
        </Grid>
        <Grid item xs={12} display='flex' flexDirection='row' alignItems='center'>
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(negotiateInPercentagesField.value)}
                value={Boolean(negotiateInPercentagesField.value)}
                name={negotiateInPercentagesField.name}
                onChange={onToggleNegotiateInPercentages}
                onBlur={negotiateInPercentagesField.onBlur}
                ref={negotiateInPercentagesField.ref}
              />
            }
            label={
              <Typography
                variant='body2'
                sx={{ color: enabled ? 'text.primary' : 'text.secondary' }}
              >
                Negotiate in percentages. The prices represent:
              </Typography>
            }
          />
          <Select
            value={termsBasis ?? defaultValue}
            sx={{ height: 32 }}
            disabled={!enabled}
            name={termsBasisField.name}
            onChange={termsBasisField.onChange}
            onBlur={termsBasisField.onBlur}
            ref={termsBasisField.ref}
          >
            {termsBasisOptions.map((option: TermsBasisOption) => (
              <MenuItem key={option.value} value={option.value}>
                <ListItemText primary={option.name} />
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </FormRow>
    </HideableBlock>
  )
}
