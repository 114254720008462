import {
  CreateNegotiationPreparationRequest,
  CreateNegotiationPreparationResponse,
  NegotiationModel,
  NegotiationPreparationWithInputs,
  PurchasePriceChangeFormList,
  UpdateArticleInputsRequest,
} from '@common/dto'
import {
  UpdateNegotiationPreparationFieldsRequest,
  UpdateNegotiationPreparationRequest,
} from '@common/dto/UpdateNegotiationPreparation'
import { NegotiationPreparationModel } from '@common/types'
import { merchandisingBaseApi } from './api'

interface RequestParams<T> {
  negotiationPreparationId: string
  payload: T
}

export const negotiationPreparationApi = merchandisingBaseApi
  .enhanceEndpoints({ addTagTypes: ['ChangeRequests', 'NegotiationPreparation'] })
  .injectEndpoints({
    endpoints: (build) => ({
      getChangeRequests: build.query<PurchasePriceChangeFormList, void>({
        query: () => '/change-requests',
        providesTags: ['ChangeRequests'],
      }),
      createNegotiationPreparation: build.mutation<
        CreateNegotiationPreparationResponse,
        CreateNegotiationPreparationRequest
      >({
        query: (payload) => ({
          url: '/negotiation-preparation',
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: ['ChangeRequests'],
      }),
      getNegotiationPreparation: build.query<NegotiationPreparationWithInputs, string>({
        query: (negotiationPreparationId) => `/negotiation-preparation/${negotiationPreparationId}`,
        providesTags: ['NegotiationPreparation'],
      }),
      getNegotiationPreparationModel: build.query<
        NegotiationModel<NegotiationPreparationModel>,
        string
      >({
        query: (negotiationPreparationId) =>
          `/negotiation-preparation/${negotiationPreparationId}/model`,
      }),
      adjustNegotiationPreparation: build.mutation<
        void,
        RequestParams<UpdateNegotiationPreparationFieldsRequest>
      >({
        query: ({ negotiationPreparationId, payload }) => ({
          url: `/negotiation-preparation/${negotiationPreparationId}`,
          method: 'PATCH',
          body: payload,
        }),
        invalidatesTags: ['ChangeRequests', 'NegotiationPreparation'],
      }),
      updateNegotiationPreparation: build.mutation<
        void,
        RequestParams<UpdateNegotiationPreparationRequest>
      >({
        query: ({ negotiationPreparationId, payload }) => ({
          url: `/negotiation-preparation/${negotiationPreparationId}`,
          method: 'PUT',
          body: payload,
        }),
        invalidatesTags: ['NegotiationPreparation'],
      }),
      submitNegotiationPreparation: build.mutation<void, string>({
        query: (negotiationPreparationId) => ({
          url: `/negotiation-preparation/${negotiationPreparationId}/submit`,
          method: 'PATCH',
          body: {},
        }),
        invalidatesTags: ['NegotiationPreparation'],
      }),
      deleteNegotiationPreparation: build.mutation<void, string>({
        query: (negotiationPreparationId) => ({
          url: `/negotiation-preparation/${negotiationPreparationId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['ChangeRequests'],
      }),
      updateArticleInputs: build.mutation<void, RequestParams<UpdateArticleInputsRequest>>({
        query: ({ negotiationPreparationId, payload }) => ({
          url: `/negotiation-preparation/${negotiationPreparationId}/article-inputs`,
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: ['NegotiationPreparation'],
      }),
    }),
    overrideExisting: false,
  })

export const {
  useGetChangeRequestsQuery,
  useCreateNegotiationPreparationMutation,
  useGetNegotiationPreparationQuery,
  useGetNegotiationPreparationModelQuery,
  useAdjustNegotiationPreparationMutation,
  useUpdateNegotiationPreparationMutation,
  useSubmitNegotiationPreparationMutation,
  useDeleteNegotiationPreparationMutation,
  useUpdateArticleInputsMutation,
} = negotiationPreparationApi
