import React from 'react'
import { Box, Stack, Drawer, Typography, IconButton, styled } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

interface Props {
  open: boolean
  onClose: () => void
  title: string
  children: React.ReactNode
  maxWidth?: number
}
export const SidePanel = ({ open, onClose, title, maxWidth, children }: Props) => {
  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <StyledContentWrapper maxWidth={maxWidth}>
        <Stack direction='row' sx={{ width: '100%' }}>
          <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
            {title}
          </Typography>
          <Stack
            ml='auto'
            direction='row'
            alignItems='center'
            sx={{ cursor: 'pointer' }}
            onClick={onClose}
          >
            <b>Close</b>
            <StyledCloseButton size='small'>
              <CloseIcon fontSize='small' />
            </StyledCloseButton>
          </Stack>
        </Stack>
        {children}
      </StyledContentWrapper>
    </Drawer>
  )
}

const StyledCloseButton = styled(IconButton)({
  color: 'black',
  '&:hover': {
    backgroundColor: 'transparent',
  },
})

type ContentWrapperProps = {
  maxWidth?: number
  minWidth?: number
}

const StyledContentWrapper = styled(Box)<ContentWrapperProps>(({ theme, maxWidth, minWidth }) => ({
  maxWidth: maxWidth ?? 700 + 'px',
  minWidth: minWidth ?? 700 + 'px',
  height: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'flex-start',
  padding: theme.spacing(3, 4),
  overflow: 'auto',
  [theme.breakpoints.down('sm')]: {
    maxWidth: 'unset',
    minWidth: 'unset',
    width: '100vw',
  },
}))
