import { styled, Tabs } from '@mui/material'

export const PactumTabs = styled(Tabs)(({ theme }) => ({
  marginTop: theme.spacing(4),
  width: '100%',
  minHeight: 'unset',

  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.accent.main,
  },
}))
