import { Grid, MenuItem, Typography } from '@mui/material'
import { NumberFieldCtrl, SelectCtrl, TextFieldCtrl } from '@components/Form'
import { CustomPaymentDaysType } from '@campaigns/types'
import { ISO3Countries } from '@campaigns/dto/countries'
import { ISO2Language, ISO2Languages } from '@campaigns/dto/languages'
import { useGetProjectConfigQuery } from 'src/commercial-terms/store'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { CustomInputField } from './custom/CustomInputField'
import { Currency } from '@campaigns/types'

const lastTenYears = (): number[] => {
  const currentYear = new Date().getFullYear()
  return Array.from({ length: 10 }, (_, i) => currentYear - i)
}

interface Props {
  languages: ISO2Language[]
  customPaymentDaysType?: CustomPaymentDaysType
}

export const NegotiationForm = ({ languages }: Props) => {
  const { activeProjectTag } = useActiveProject()
  const { data: projectConfig } = useGetProjectConfigQuery(activeProjectTag)
  const customPaymentDaysType = projectConfig?.customPaymentDaysType ?? null
  const customImportFields = projectConfig?.customImportFields ?? []
  const shouldShowLanguageSelect = languages.length > 1
  const isEarlyPaymentDiscountDisabled = projectConfig?.isEarlyPaymentDiscountDisabled

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Typography variant='subtitle2' gutterBottom>
          Negotiation details
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <TextFieldCtrl name='organisationName' label='Business unit (optional)' fullWidth />
      </Grid>
      <Grid item xs={12} md={12} mt={2}>
        <Typography variant='subtitle2' gutterBottom>
          Supplier information
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextFieldCtrl
          name='supplierCompanyName'
          label='Supplier company name'
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextFieldCtrl name='supplierId' label='Supplier ID' required fullWidth />
      </Grid>
      <Grid item xs={12} md={6} mt={1}>
        <TextFieldCtrl name='supplierRepresentativeName' label='Supplier contact name' fullWidth />
      </Grid>
      <Grid item xs={12} md={6} mt={1}>
        <TextFieldCtrl
          name='supplierRepresentativeEmail'
          label='Supplier email address'
          fullWidth
          type='email'
        />
      </Grid>
      <Grid item xs={12} md={6} mt={1}>
        <TextFieldCtrl select name='country' label='Supplier Country' fullWidth>
          {Object.entries(ISO3Countries).map(([code, country]) => (
            <MenuItem key={code} value={code}>
              {country}
            </MenuItem>
          ))}
        </TextFieldCtrl>
      </Grid>
      {shouldShowLanguageSelect && (
        <Grid item xs={12} md={6} mt={1}>
          <TextFieldCtrl select name='language' label='Negotiation language' fullWidth required>
            {languages.map((code) => (
              <MenuItem key={code} value={code}>
                {ISO2Languages[code]}
              </MenuItem>
            ))}
          </TextFieldCtrl>
        </Grid>
      )}
      <Grid item xs={12} md={6} mt={1}>
        <TextFieldCtrl
          name='supplierRepresentativePhone'
          label='Phone number to reach the contact person (optional)'
          fullWidth
          helperText='Example: +1 425 345 4867'
        />
      </Grid>
      <Grid item xs={12} md={12} mt={2}>
        <Typography variant='subtitle2' gutterBottom>
          Commercial history with the supplier
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <NumberFieldCtrl
          name='pastContractValue'
          label='Most recent annual spend *'
          fullWidth
          variant='outlined'
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <SelectCtrl
          name='pastContractPeriod'
          label='Year of the last spend'
          options={lastTenYears()}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <SelectCtrl
          name='currency'
          label='Currency'
          options={Object.keys(Currency)}
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={12} mt={2}>
        <Typography variant='subtitle2' gutterBottom>
          Current commercial terms with the supplier
        </Typography>
        <Typography gutterBottom mt={1.5}>
          To negotiate better deals, enter the current payment terms that are in effect with this
          supplier.
        </Typography>
      </Grid>
      {!isEarlyPaymentDiscountDisabled && (
        <>
          <Grid item xs={12} md={4}>
            <NumberFieldCtrl
              name='pastContractDiscount'
              label='Discount (%)'
              fullWidth
              variant='outlined'
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <NumberFieldCtrl
              name='pastContractDiscountDays'
              label='Discount days'
              fullWidth
              variant='outlined'
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} md={4}>
        <NumberFieldCtrl
          name='pastContractPaymentDays'
          label='Payment days'
          fullWidth
          variant='outlined'
        />
      </Grid>
      {customPaymentDaysType === CustomPaymentDaysType.EOMX && (
        <Grid item xs={12} md={4}>
          <TextFieldCtrl
            name='eomPaymentDays'
            label='EOM Payment Days'
            fullWidth
            variant='outlined'
            helperText='Example: 15 EOM10'
          />
        </Grid>
      )}
      {customImportFields.length > 0 && (
        <Grid item xs={12} md={12} mt={4}>
          <Typography variant='subtitle2' gutterBottom>
            Other information
          </Typography>
        </Grid>
      )}
      {customImportFields.map((field) => (
        <CustomInputField field={field} key={field.name} />
      ))}
    </Grid>
  )
}
