import { useState, useCallback } from 'react'

import { useProjects } from '@hooks'
import { useSearchParams } from 'react-router-dom'

export const PROJECTS_URL_PARAM = 'project_ids'

export const useDashboardProjectSelection = () => {
  const { projects: allProjects } = useProjects()
  const projects = allProjects.filter((project) => project.isClientSandbox === false)

  const [searchParams, setSearchParams] = useSearchParams()
  const rawProjectIds = searchParams.get(PROJECTS_URL_PARAM) || ''

  const projectIds = rawProjectIds
    .split(',')
    .map((id) => parseInt(id, 10)) // explicitly specify radix=10 otherwise parseInt('0x10') returns 16
    .filter((id) => !isNaN(id) && id > 0)

  const [selectedProjectIds, setSelectedProjectIds] = useState<number[]>(
    projects.map((project) => project.id).filter((id) => projectIds?.includes(id)),
  )

  const handleProjectFilterChange = useCallback(
    (ids: number[]) => {
      setSearchParams((params) => {
        if (ids.length === 0) {
          params.delete(PROJECTS_URL_PARAM)
        } else {
          params.set(PROJECTS_URL_PARAM, ids.join(','))
        }
        return params
      })
      setSelectedProjectIds(ids)
    },
    [setSearchParams, setSelectedProjectIds],
  )

  return { projects, selectedProjectIds, handleProjectFilterChange }
}
