import {
  ArticleInput,
  ArticleWithInput,
  CompetitorsPrices,
  ExternalReasoningSentence,
  NegotiationAnalysis,
} from '@common/types'
import { Box, Button, Grid, styled, Typography } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { useCallback, useEffect, useState } from 'react'
import { NegotiationInputField } from './NegotiationInputField'
import { CompetitorIndexButtons } from './CompetitorIndexButtons'
import { calculateRpp, nullSafeRoundNumberToTwoDecimals, onlyUniqueBy } from '@common/utils'
import { useFormatter } from '@shared/hooks'
import { CompetitorSalesPriceBenchmark } from './CompetitorSalesPriceBenchmark'
import { AnalyticsPanel, ReasoningPanel } from './Panels'

interface Props {
  article: ArticleWithInput
  analysis: NegotiationAnalysis
  onValueChanged: (ean: string, values: InPlaceValues) => void
  isEditingDisabled: boolean
  defaultInputs: ArticleInput
  competitorPrices?: CompetitorsPrices[]
}

export type InPlaceValues = Pick<ArticleInput, 'rpp' | 'margin' | 'reasoning'>

export type ReasoningWithVisibility = ExternalReasoningSentence & { visible: boolean }

export type SaveReasoningSentenceCallback = (
  value: string | number | null | ExternalReasoningSentence[],
  key: InPlaceKey,
) => void

export type UpdateExplanationCallback = (newSentence: string, sentenceId: number) => void

const getInPlaceValues = (article: ArticleWithInput): InPlaceValues => ({
  rpp: article.inputs.rpp,
  margin: article.inputs.margin,
  reasoning: article.inputs.reasoning,
})

// needed when PP form is re-uploaded, but article reasoning sentences have changed and have
// different counts -- error happens when new article has more external sentences than previous inputs
const getMergedReasoningsById = (
  inputReasoningSentences: ExternalReasoningSentence[],
  articleReasoningSentences: ExternalReasoningSentence[],
): ReasoningWithVisibility[] => {
  const hasEmptySentenceWithId = (id: number) =>
    !inputReasoningSentences.find(
      (inputReasoningSentence) =>
        inputReasoningSentence.id === id && !inputReasoningSentence.sentence,
    )

  const doesInputReasoningsContain = (id: number) =>
    inputReasoningSentences.every((inputReasoningSentence) => inputReasoningSentence.id !== id)

  const articleReasoningsWithoutOverridenInputSentences = articleReasoningSentences.filter(
    (articleReasoningSentence) =>
      hasEmptySentenceWithId(articleReasoningSentence.id) ||
      doesInputReasoningsContain(articleReasoningSentence.id),
  )
  return [...inputReasoningSentences, ...articleReasoningsWithoutOverridenInputSentences]
    .filter(onlyUniqueBy('id'))
    .map((reasoning) => ({ ...reasoning, visible: !!reasoning.sentence }))
}

export type InPlaceKey = `${keyof InPlaceValues}.${keyof InPlaceValues['rpp'] | 'external'}`

export const RowDetailsPanel = ({
  article,
  analysis,
  onValueChanged,
  isEditingDisabled,
  defaultInputs,
  competitorPrices,
}: Props) => {
  const [reasoningPanelOpen, setReasoningPanelOpen] = useState(false)
  const [analyticsPanelOpen, setAnalyticsPanelOpen] = useState(false)
  const [inPlaceValues, setInPlaceValues] = useState<InPlaceValues>(getInPlaceValues(article))
  const [inPlaceExplanation, setInPlaceExplanation] = useState<ReasoningWithVisibility[]>(
    getMergedReasoningsById(article.inputs.reasoning.external, article.reasoning.external),
  )
  const formatter = useFormatter()
  useEffect(() => {
    setInPlaceExplanation(
      getMergedReasoningsById(article.inputs.reasoning.external, article.reasoning.external),
    )
  }, [article.inputs.reasoning.external, article.reasoning.external])

  const updateInPlaceExplanation: UpdateExplanationCallback = (newSentence, sentenceId): void => {
    setInPlaceExplanation(
      inPlaceExplanation.map((item) =>
        item.id === sentenceId ? { ...item, sentence: newSentence } : item,
      ),
    )
  }

  const onChange: SaveReasoningSentenceCallback = useCallback(
    (value, key) => {
      // TS is not smart enough for this yet
      const targetObjKey = key.split('.')[0] as 'rpp' | 'margin' | 'reasoning'
      const targetValueKey = key.split('.')[1] as keyof InPlaceValues['rpp'] | 'external'

      const updatedInplaceValues = {
        ...inPlaceValues,
        [targetObjKey]: {
          ...inPlaceValues[targetObjKey],
          [targetValueKey]: value,
        },
      }
      setInPlaceValues(updatedInplaceValues)

      onValueChanged(article.ean, updatedInplaceValues)
    },
    [article.ean, inPlaceValues, onValueChanged],
  )

  useEffect(() => {
    setInPlaceValues(getInPlaceValues(article))
  }, [article])

  return (
    <>
      <PanelWrapper>
        <Grid container direction='row' spacing={4} py={2}>
          <Grid item xs>
            <Grid container direction='row' spacing={4}>
              <Grid item xs={12} md={6}>
                <Grid container direction='row' spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant='h6' mb={2}>
                      Cost adjustment
                    </Typography>
                    <Box sx={{ lineHeight: '40px' }}>
                      I’m willing to pay at maximum{' '}
                      <NegotiationInputField
                        value={nullSafeRoundNumberToTwoDecimals(inPlaceValues.rpp.lac)}
                        valueKey={'rpp.lac'}
                        disabled={isEditingDisabled}
                        onChange={onChange}
                      />{' '}
                      to the supplier (recommendation: {formatter.currency(defaultInputs.rpp.lac)}).
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <CompetitorIndexButtons
                      isDisabled={isEditingDisabled}
                      onChange={onChange}
                      article={article}
                      competitorPrices={competitorPrices}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ lineHeight: '40px' }}>
                      I want a margin of{' '}
                      <NegotiationInputField
                        value={nullSafeRoundNumberToTwoDecimals(inPlaceValues.margin.lac * 100)}
                        valueKey={'margin.lac'}
                        disabled={isEditingDisabled}
                        onChange={onChange}
                        divider={100 as const}
                        unit={'%'}
                      />{' '}
                      or better.
                    </Box>
                  </Grid>
                  <Grid item xs={12} mt={0.5}>
                    <Button
                      color='white'
                      variant='rounded'
                      sx={{ textTransform: 'none' }}
                      startIcon={<OpenInNewIcon />}
                      onClick={() => setAnalyticsPanelOpen(true)}
                    >
                      Pactum Analytics
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{ textDecoration: 'underline', color: '#0F3A4F', cursor: 'pointer' }}
                      onClick={() => setReasoningPanelOpen(true)}
                    >
                      Review reasonings »
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                {competitorPrices && (
                  <CompetitorSalesPriceBenchmark
                    prices={competitorPrices}
                    onUseCompetitorPrice={(competitorPrice) =>
                      onChange(
                        calculateRpp(competitorPrice, article.inputs.margin.lac, article.vat),
                        'rpp.lac',
                      )
                    }
                    isEditingDisabled={isEditingDisabled}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PanelWrapper>
      <ReasoningPanel
        open={reasoningPanelOpen}
        explanations={inPlaceExplanation}
        article={article}
        isEditingDisabled={isEditingDisabled}
        onSaveChanges={onChange}
        onUpdateInPlaceExplanation={updateInPlaceExplanation}
        onPanelClose={() => setReasoningPanelOpen(false)}
      />
      <AnalyticsPanel
        open={analyticsPanelOpen}
        article={article}
        analysis={analysis}
        onPanelClose={() => setAnalyticsPanelOpen(false)}
      />
    </>
  )
}

/**
 * FIXME consider using DetailsPanelBorders component {@link import('../../../../../shared/components/table')}
 */
const PanelWrapper = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(7.5),
  width: `calc(100% - ${theme.spacing(7.5)})`,
  borderTop: `1px solid ${theme.palette.grey.A400}`,
  borderBottom: `1px solid ${theme.palette.grey.A400}`,
}))
