import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useDownloadTemplateXlsx } from '../../../store'
import { Dialog, DialogContent, Grid, Typography, styled, Button, Box } from '@mui/material'
import { PactumDialogHeader, PactumLargeDialogFooter } from '@components/PactumDialog'
import { NumberedList, NumberedListItem, LinkWithArrow } from '@components/SideBlock'
import { ProjectDataFileUpload } from '../ProjectDataFileUpload'

export interface UploadDialogProps {
  isOpen: boolean
  onClose: () => void
}

//TODO: generalize this component and move to shared
export const FileUploadDialog = (props: UploadDialogProps) => {
  const { activeProjectTag } = useActiveProject()
  const [downloadTemplateExcel] = useDownloadTemplateXlsx()

  return (
    <Dialog open={props.isOpen} maxWidth={'sm'} fullWidth>
      <PactumDialogHeader title='Upload suppliers' onClose={props.onClose} />
      <DialogContent>
        <Grid
          container
          py={4}
          columnSpacing={{ xs: 2, lg: 4 }}
          rowSpacing={{ sm: 4, md: 0 }}
          style={{ padding: 0 }}
        >
          <Grid item sm={12} md={7} width='100%'>
            <Typography variant='normal'>
              Import suppliers from your existing systems with these easy steps:
            </Typography>
            <NumberedList>
              <NumberedList>
                <NumberedListItem>
                  <Box>
                    <LinkWithArrow onClick={() => downloadTemplateExcel(activeProjectTag)}>
                      Download the negotiation template
                    </LinkWithArrow>
                  </Box>
                </NumberedListItem>
                <NumberedListItem>
                  Fill in the required details. See the template for instructions.
                </NumberedListItem>
                <NumberedListItem>Upload the template back to this tool.</NumberedListItem>
              </NumberedList>
            </NumberedList>
          </Grid>
          <Grid item sm={12} md={5}>
            <ProjectDataFileUpload callback={props.onClose} />
          </Grid>
        </Grid>
      </DialogContent>
      <PactumLargeDialogFooter>
        <SecondaryButton onClick={props.onClose}>Close</SecondaryButton>
      </PactumLargeDialogFooter>
    </Dialog>
  )
}

const SecondaryButton = styled(Button)(({ theme }) => ({
  background: theme.palette.secondary.main,
  ':hover': {
    background: theme.palette.secondary.light,
  },
}))
