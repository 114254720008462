import React, { MouseEventHandler } from 'react'
import { Box } from '@mui/material'

interface Props {
  onClick?: MouseEventHandler<unknown>
}

export const FullPactumLogo = ({ onClick }: Props) => (
  <Box
    pt={2.5}
    pb={1.5}
    lineHeight='0.5rem'
    onClick={onClick}
    sx={{ cursor: onClick ? 'pointer' : undefined }}
  >
    <img
      src='/pactum-logo-white.svg'
      alt='Pactum logo'
      style={{ width: '130px', height: '32px' }}
    />
  </Box>
)
