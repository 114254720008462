import { zodResolver } from '@hookform/resolvers/zod'
import { useDebounce, useNotifications, useProjects } from '@hooks'
import {
  Alert,
  Autocomplete,
  FormHelperText,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material'
import { CurrencyCode } from '@pactum/common'
import { DisplayCurrenciesList } from '@pages/SettingsPage/DisplayCurrenciesList'
import {
  useDetermineBrandingIdForWorkspaceQuery,
  useGetBrandingListQuery,
  useSetBrandingForWorkspaceMutation,
} from '@store/branding'
import {
  useGetWorkspaceConfigurationQuery,
  useUpdateWorkspaceConfigurationMutation,
} from '@store/configuration'
import isEqual from 'lodash/isEqual'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { NavLink, useParams } from 'react-router-dom'
import { allTimezones, useTimezoneSelect } from 'react-timezone-select'
import { z } from 'zod'

const WorkspaceConfigurationSchema = z.object({
  timezone: z.string().or(z.null()),
  currencies: z.array(z.nativeEnum(CurrencyCode)).or(z.null()),
})
type WorkspaceConfigurationSchema = z.infer<typeof WorkspaceConfigurationSchema>

export const WorkspaceGeneralSettingsPage = ({
  canManageConfig,
}: {
  isInternalUser: boolean
  canManageConfig: boolean
}) => {
  const { orgTag, workspaceTag } = useParams<{ orgTag: string; workspaceTag: string }>()

  const { projects } = useProjects()
  const project = useMemo(
    () => projects.find((project) => project.tag === workspaceTag),
    [projects, workspaceTag],
  )
  const workspaceConfigurationQuery = useGetWorkspaceConfigurationQuery({
    workspaceTag: workspaceTag!,
  })
  const [updateWorkspaceConfiguration] = useUpdateWorkspaceConfigurationMutation()
  const [setBrandingForWorkspace] = useSetBrandingForWorkspaceMutation()
  const { data: brandingList } = useGetBrandingListQuery(orgTag!)
  const { data: workspaceBrandingId } = useDetermineBrandingIdForWorkspaceQuery(workspaceTag!)
  const { showSuccess, showError } = useNotifications()
  const { options } = useTimezoneSelect(allTimezones)
  const timezones = Object.fromEntries(options.map((tz) => [tz.value, tz.label]))

  const defaultBranding = useMemo(
    () => (brandingList ?? []).find((branding) => branding.isDefault),
    [brandingList],
  )

  const brandingOptions = useMemo(
    () => (brandingList ?? []).map((branding) => ({ label: branding.label, value: branding.uuid })),
    [brandingList],
  )

  const [currentlySelectedBranding, setCurrentlySelectedBranding] = useState<
    { label: string; value: string } | undefined
  >()

  const form = useForm<WorkspaceConfigurationSchema>({
    values: workspaceConfigurationQuery.data as WorkspaceConfigurationSchema,
    resolver: zodResolver(WorkspaceConfigurationSchema),
  })

  const onResponse = useCallback(
    (response: object) => {
      if ('error' in response) {
        showError('Failed to save changes')
      } else {
        showSuccess('Changes saved')
      }
    },
    [showSuccess, showError],
  )

  const saveChanges = useDebounce(async () => {
    const updates = form.getValues()
    if (isEqual(updates, workspaceConfigurationQuery.data)) {
      return
    }

    const response = await updateWorkspaceConfiguration({
      workspaceTag: workspaceTag!,
      config: updates,
    })

    onResponse(response)
  }, 3000)

  const saveBranding = useCallback(
    async (brandingId: string) => {
      const response = await setBrandingForWorkspace({
        workspaceTag: workspaceTag!,
        brandingId: brandingId === defaultBranding?.uuid ? null : brandingId,
      })

      onResponse(response)
    },
    [workspaceTag, defaultBranding, setBrandingForWorkspace, onResponse],
  )

  useEffect(() => {
    if (!workspaceConfigurationQuery.isLoading) {
      const subscription = form.watch(saveChanges)
      return () => subscription.unsubscribe()
    }
  }, [workspaceConfigurationQuery.isLoading, form, saveChanges])

  useEffect(() => {
    saveChanges.cancel()
  }, [workspaceConfigurationQuery.data, saveChanges])

  useEffect(() => {
    setCurrentlySelectedBranding(
      brandingOptions?.find((branding) => branding.value === workspaceBrandingId),
    )
  }, [brandingOptions, workspaceBrandingId])

  return (
    <FormProvider {...form}>
      <form>
        <Grid container maxWidth={{ xs: 'sm' }} spacing={4} ml={2}>
          <Grid item xs={12}>
            <Typography variant='h2' component='h2' sx={{ mb: 0 }}>
              {project?.name}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='timezone'
              render={({ field }) => (
                <>
                  <Typography variant='h3' component='h3' mb={2}>
                    Default time zone
                  </Typography>

                  <Autocomplete
                    key={workspaceTag}
                    options={Object.keys(timezones)}
                    value={(field.value as string) ?? null}
                    disabled={!canManageConfig}
                    onChange={(_, newValue) => field.onChange(newValue)}
                    getOptionLabel={(key: string) => timezones[key] ?? timezones['Etc/GMT']}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='outlined'
                        label='Default timezone'
                        margin='normal'
                        helperText='Default timezone is used to schedule supplier communications if supplier’s timezone is
            not known.'
                        inputProps={{ ...params.inputProps, 'data-testid': 'defaultTimezone' }}
                      />
                    )}
                  />
                </>
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <DisplayCurrenciesList
              key={workspaceTag}
              name='currencies'
              disabled={!canManageConfig}
              description='Display currencies let you see negotiation results on dashboards in the currencies of your choosing.'
            />
          </Grid>

          {currentlySelectedBranding && (
            <Grid item xs={12}>
              <Typography variant='h3' component='h3' mb={2}>
                Workspace business identity
              </Typography>

              {currentlySelectedBranding?.value &&
                currentlySelectedBranding?.value === defaultBranding?.uuid && (
                  <FormHelperText sx={{ mb: 2 }}>
                    This workspace is configured to use the primary business identity for your
                    organization. You can specify a different branding for this workspace above.
                  </FormHelperText>
                )}
              <Autocomplete
                disabled={!canManageConfig}
                key={workspaceTag}
                options={brandingOptions}
                value={currentlySelectedBranding}
                onChange={(_, newValue) => {
                  setCurrentlySelectedBranding(newValue)
                  saveBranding(newValue.value)
                }}
                disableClearable={true}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => {
                  if (!value.value) {
                    return false
                  }
                  return option.value === value.value
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Workspace business identity'
                    margin='normal'
                    inputProps={{ ...params.inputProps, 'data-testid': 'businessIdentity' }}
                  />
                )}
              />
              <Link
                component={NavLink}
                to={`/${orgTag}/settings/general/branding/${currentlySelectedBranding.value}`}
                fontSize='small'
              >
                View Business identity
              </Link>
            </Grid>
          )}
          {brandingList?.length === 0 && (
            <Grid item xs={12}>
              <Alert severity='error'>
                There are no business identities available for this workspace, please contact Pactum
                support
              </Alert>
            </Grid>
          )}
        </Grid>
      </form>
    </FormProvider>
  )
}
