import { Route } from 'react-router-dom'
import { Layout } from 'src/shared/components/Layout'
import { LogisticsRoutes } from './routes'
import { locales } from '@common/constants'
import { ProtectedRoute } from '@components/ProtectedRoute'
import { SupplierPage } from '@logistics/pages/common/SupplierPage/SupplierPage'
import { SupplierContextProvider } from '@logistics/pages/common/SupplierPage/context'
import { SupplierDetailsPage } from '@logistics/pages/common/SupplierDetailsPage/SupplierDetailsPage'
import { UseCaseSelector } from '@logistics/UseCaseSelector'
import { DashboardPage } from '@logistics/pages/common/DashboardPage/DashboardPage'
import { RFPDetailsPage } from './pages/contractedLanes/RFPDetailsPage/RFPDetailsPage'
import { SuitePermission } from '@common/types'

export const LogisticsEntryPoint = () => (
  <Route element={<Layout locale={locales.us} />}>
    <Route
      path={LogisticsRoutes.PROJECT_PATH}
      element={
        <ProtectedRoute requiredPerm={SuitePermission.MODIFY}>
          <UseCaseSelector />
        </ProtectedRoute>
      }
    />
    <Route
      path={LogisticsRoutes.DASHBOARD}
      element={
        <ProtectedRoute requiredPerm={SuitePermission.READ}>
          <DashboardPage />
        </ProtectedRoute>
      }
    />
    <Route
      path={LogisticsRoutes.SUPPLIERS}
      element={
        <ProtectedRoute requiredPerm={SuitePermission.MODIFY}>
          <SupplierContextProvider>
            <SupplierPage />
          </SupplierContextProvider>
        </ProtectedRoute>
      }
    />
    <Route
      path={LogisticsRoutes.SUPPLIER_DETAILS}
      element={
        <ProtectedRoute requiredPerm={SuitePermission.MODIFY}>
          <SupplierDetailsPage />
        </ProtectedRoute>
      }
    />
    <Route
      path={LogisticsRoutes.RFP_DETAILS}
      element={
        <ProtectedRoute requiredPerm={SuitePermission.MODIFY}>
          <RFPDetailsPage />
        </ProtectedRoute>
      }
    />
    '
  </Route>
)
