import { createTheme, ThemeOptions } from '@mui/material/styles'
import { buttonTheme } from './components/button'
import { chipTheme } from './components/chip'
import { linkTheme } from './components/link'
import { svgIconTheme } from './components/svgIcon'

export const fontFamily = ["'Inter'", 'sans-serif'].join(',')

export const pactumThemeOptions: ThemeOptions = {
  zIndex: {
    contentOverlay: 1,
    fab: 1050,
    speedDial: 1050,
    secondaryHeader: 1075,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  typography: {
    fontFamily,
    allVariants: {
      color: '#222222',
    },
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
      fontSize: '2rem',
      fontWeight: 600,
      lineHeight: '2rem',
      letterSpacing: '-0.02rem',
    },
    h2: {
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: '1.875rem',
    },
    h3: {
      fontSize: '0.875rem',
      fontWeight: 800,
      lineHeight: '0.875rem',
    },
    subtitle1: {
      fontSize: '1.25rem',
      fontWeight: 600,
      lineHeight: '1rem',
    },
    subtitle2: {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: '1rem',
    },
    normal: {
      fontFamily,
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1.5rem',
    },
    buttonText: {
      fontFamily,
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: '0.875rem',
    },
  },
  palette: {
    primary: {
      main: '#092330',
      dark: '#C19627',
    },
    secondary: {
      main: '#FFCE20',
    },
    error: {
      main: '#CA0808',
    },
    success: {
      main: '#0B8E18',
    },
    warning: {
      main: '#FFCE20',
    },
    accent: {
      main: '#0F3A4F',
      light: '#0F3A4F',
    },
    menu: {
      main: '#577584',
    },
    stoneBlue: {
      main: '#809EA5',
      contrastText: '#FFFFFF',
    },
    lightGrey: {
      main: '#8D8D8D',
      contrastText: '#FFFFFF',
    },
    darkGrey: {
      main: '#6C6C6C',
      contrastText: '#FFFFFF',
    },
    tertiary: {
      main: '#9E9E9E',
    },
    text: {
      primary: '#222222',
      secondary: '#6C6C6C',
    },
    borders: {
      main: '#BFBFBF',
    },
    background: {
      default: '#FFFFFF',
    },
    link: {
      main: '#0072CE',
      dark: '#0072CE',
      light: '#0072CE',
      contrastText: '#0072CE',
    },
    common: {
      copper: '#A59080',
      orange: '#D06D4C',
      yellow: '#D09047',
      petrol: '#092330',
      charts: {
        first: '#D06D4C',
        second: '#A59080',
        third: '#809EA5',
        fourth: '#D09047',
        fifth: '#7D97A5',
        sixth: '#A9B2B7',
        seventh: '#F2F4F5',
        eight: '#8D8D8D',
      },
    },
  },
  components: {
    ...buttonTheme,
    ...linkTheme,
    ...svgIconTheme,
    ...chipTheme,
    MuiCssBaseline: {
      styleOverrides: {
        fontFamily,
      },
    },
    MuiStack: {
      defaultProps: {
        useFlexGap: true,
      },
    },
  },
}

export const pactumTheme = createTheme(pactumThemeOptions)
