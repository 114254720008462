import { Formatter } from '@utils'
import { GridColDef } from '@mui/x-data-grid-pro'
import { Chip, Link } from '@mui/material'
import {
  ContractedLanesNegotiationStatus,
  ContractedLanesNegotiationToUnitStatus,
  ContractedLanesNegotiationUnit,
} from '../types'
import { StatusConfig } from '@logistics/types'
import { formatCurrency } from '@shared/utils/formatCurrency'
import { NotAvailableCell } from '@components/table'
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded'
import React from 'react'
import { isInvalidOffer } from '@logistics/pages/contractedLanes/RFPDetailsPage/utils'
import { snakeCaseToSentenceCase } from '@logistics/utils/nameHelper'
import { RfpPreviewLinkCell } from '@logistics/pages/contractedLanes/RFPDetailsPage/RfpPreviewLinkCell'

const NEGOTIATION_INITIAL_STATUSES = [
  ContractedLanesNegotiationStatus.PENDING,
  ContractedLanesNegotiationStatus.NOT_STARTED,
  ContractedLanesNegotiationStatus.LINK_EMAIL_0,
  ContractedLanesNegotiationStatus.LINK_EMAIL_1,
]

const PREVIEWABLE_NEGOTIATION_STATUSES = [
  ContractedLanesNegotiationStatus.PENDING,
  ContractedLanesNegotiationStatus.NOT_STARTED,
]

const REVIEWABLE_NEGOTIATION_STATUSES = [
  ContractedLanesNegotiationStatus.ONGOING,
  ContractedLanesNegotiationStatus.DECLINED_CONCLUDED,
  ContractedLanesNegotiationStatus.AGREEMENT_REACHED,
  ContractedLanesNegotiationStatus.EXPIRED,
]

const NEGOTIATION_STATUS_CONFIG: StatusConfig<ContractedLanesNegotiationStatus> = {
  NOT_STARTED: { label: 'Ready', color: 'stoneBlue' },
  LINK_EMAIL_0: { label: 'Ready', color: 'stoneBlue' },
  LINK_EMAIL_1: { label: 'Waiting', color: 'stoneBlue' },
  ONGOING: { label: 'In progress', color: 'lightGrey' },
  CANCELLED: { label: 'Cancelled', color: 'warning' },
  EXPIRED: { label: 'Expired', color: 'error' },
  DECLINED_CONCLUDED: { label: 'No agreement', color: 'error' },
  AGREEMENT_REACHED: { label: 'Rate captured', color: 'success' },
  PENDING: { label: 'Pending', color: 'stoneBlue' },
}

const NEGOTIATION_UNIT_STATUS_CONFIG: StatusConfig<ContractedLanesNegotiationToUnitStatus> = {
  NOT_STARTED: { label: 'Ready', color: 'stoneBlue' },
  FAILED: { label: 'No agreement', color: 'error' },
  SUCCESS: { label: 'Rate captured', color: 'success' },
}

const SUPPLIER_INFO_COLUMN_WIDTH = 300
const STATUS_COLUMN_WIDTH = 250
const OFFER_COLUMN_WIDTH = 100
const NEGOTIATION_LINK_COLUMN_WIDTH = 150

export function getNegotiationStatusBanner(status: string) {
  if (Object.keys(ContractedLanesNegotiationStatus).includes(status)) {
    return (
      <Chip
        size='medium'
        {...NEGOTIATION_STATUS_CONFIG[status as ContractedLanesNegotiationStatus]}
        variant='outlined'
      />
    )
  }

  if (Object.keys(ContractedLanesNegotiationToUnitStatus).includes(status)) {
    return (
      <Chip
        size='medium'
        {...NEGOTIATION_UNIT_STATUS_CONFIG[status as ContractedLanesNegotiationToUnitStatus]}
        variant='outlined'
      />
    )
  }

  return (
    <Chip size='medium' label={snakeCaseToSentenceCase(status)} color='info' variant='outlined' />
  )
}

export const createRfpLanesColumns = (
  expiresAt: Date | null,
  _formatter: Formatter,
): GridColDef<ContractedLanesNegotiationUnit>[] => [
  {
    field: 'supplier_name',
    headerName: 'Carrier',
    disableColumnMenu: true,
    sortable: false,
    width: SUPPLIER_INFO_COLUMN_WIDTH,
    valueGetter: ({ row }) => row.supplier.name,
  },
  {
    field: 'supplier_email',
    headerName: 'Email',
    disableColumnMenu: true,
    sortable: false,
    width: SUPPLIER_INFO_COLUMN_WIDTH,
    valueGetter: ({ row }) => {
      const primaryContact = row.supplier.contacts.find(({ is_primary }) => is_primary)

      if (primaryContact) {
        return primaryContact.email
      }

      const contactWithEmail = row.supplier.contacts.find(({ email }) => email.length > 0)
      if (contactWithEmail) {
        return contactWithEmail.email
      }

      return 'N/A'
    },
  },
  {
    field: 'negotiation_link',
    headerName: 'Negotiation',
    disableColumnMenu: true,
    sortable: false,
    width: NEGOTIATION_LINK_COLUMN_WIDTH,
    renderCell: ({ row }) => {
      if (PREVIEWABLE_NEGOTIATION_STATUSES.includes(row.negotiation.status)) {
        return <RfpPreviewLinkCell negotiationId={row.negotiation.id} />
      }

      const negotiation = row.negotiation
      const isNegotiationReviewable = REVIEWABLE_NEGOTIATION_STATUSES.includes(negotiation.status)
      const negotiationLink = isNegotiationReviewable
        ? negotiation.chat_url_client
        : negotiation.chat_url_test

      if (!negotiationLink) {
        return <NotAvailableCell />
      }

      return (
        <Link href={`${negotiationLink}`} sx={{ lineHeight: '1.5rem' }} target='_blank'>
          <OpenInNewRoundedIcon sx={{ verticalAlign: 'bottom' }} />{' '}
          {isNegotiationReviewable ? 'Review' : 'Try out'}
        </Link>
      )
    },
  },
  {
    field: 'offer',
    headerName: 'Offer',
    disableColumnMenu: true,
    sortable: true,
    width: OFFER_COLUMN_WIDTH,
    renderCell: ({ row }) => {
      const availableOfferAmount = row.negotiationUnit.outcomes?.capturedRate
      const availableOfferCurrency = row.negotiationUnit.attributes?.currency

      if (!availableOfferAmount || isInvalidOffer(availableOfferAmount, availableOfferCurrency)) {
        return <NotAvailableCell />
      }

      return formatCurrency(availableOfferAmount, availableOfferCurrency, _formatter)
    },
  },
  {
    field: 'anchor',
    headerName: 'Anchor',
    disableColumnMenu: true,
    sortable: true,
    width: OFFER_COLUMN_WIDTH,
    renderCell: ({ row }) => {
      const availableAnchorAmount = row.negotiationUnit.attributes?.anchor
      const availableOfferCurrency = row.negotiationUnit.attributes?.currency

      return formatCurrency(availableAnchorAmount, availableOfferCurrency, _formatter)
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    disableColumnMenu: true,
    sortable: false,
    width: STATUS_COLUMN_WIDTH,
    renderCell: ({ row }) => {
      const negotiationUnit = row.negotiationUnit
      const negotiation = row.negotiation
      if (negotiationUnit.status === ContractedLanesNegotiationToUnitStatus.NOT_STARTED) {
        // This check is for historical reasons.
        // TODO: We can remove it after this ticket is done:
        // https://pactum.atlassian.net/browse/LP-1618
        if (NEGOTIATION_INITIAL_STATUSES.includes(negotiation.status)) {
          const isRfpExpired = expiresAt ? Date.now() > expiresAt.getTime() : false
          if (isRfpExpired) {
            return getNegotiationStatusBanner(ContractedLanesNegotiationStatus.EXPIRED)
          }
        }

        if (negotiation.status !== ContractedLanesNegotiationStatus.AGREEMENT_REACHED) {
          return getNegotiationStatusBanner(negotiation.status)
        }

        // This means that the unit was out of the agreement.
        return getNegotiationStatusBanner(ContractedLanesNegotiationToUnitStatus.NOT_STARTED)
      }

      const availableOfferAmount = negotiationUnit.outcomes?.capturedRate
      const availableOfferCurrency = negotiationUnit.attributes?.currency

      if (
        negotiationUnit.status === ContractedLanesNegotiationToUnitStatus.SUCCESS &&
        isInvalidOffer(availableOfferAmount, availableOfferCurrency)
      ) {
        return getNegotiationStatusBanner(ContractedLanesNegotiationToUnitStatus.FAILED)
      }

      return getNegotiationStatusBanner(negotiationUnit.status)
    },
  },
]
