import {
  GridActionsCellItem,
  GridColDef,
  GridEditInputCell,
  GridPreProcessEditCellProps,
  GridRenderEditCellParams,
} from '@mui/x-data-grid-pro'
import React from 'react'
import { SupplierContact } from '@logistics/types/Supplier'
import { Radio, Tooltip, TooltipProps } from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { REGIONS } from '@shared/utils/region'
import { RegionSelectEditInputCell } from '@logistics/pages/common/SupplierDetailsPage/ContactList/Datagrid/RegionSelectEditInputCell'
import { supplierContactSchema } from '@logistics/pages/common/SupplierDetailsPage/types'
import { TimezoneEditInputCell } from '@logistics/pages/common/SupplierDetailsPage/ContactList/Datagrid/TimezoneEditInputCell'

const ROW_X_PADDING = 10
const OUTER_ACTIONS_WIDTH = 144

const OUTER_COLUMN_WIDTHS = {
  ACTIONS: OUTER_ACTIONS_WIDTH + 2 * ROW_X_PADDING,
} as const

interface ValidationGridRenderEditCellParams extends TooltipProps {
  error: boolean
}

function TooltipInputCell({ children, error, ...props }: ValidationGridRenderEditCellParams) {
  return (
    <Tooltip open={error} {...props}>
      <span>{children}</span>
    </Tooltip>
  )
}

export const createColumns = (
  handleDeleteContact: (contact: SupplierContact) => void,
  selectPrimaryContact: (contactId: string) => void,
): GridColDef<SupplierContact>[] => [
  {
    field: 'id',
    headerName: 'Id',
    disableColumnMenu: true,
    sortable: false,
    editable: false,
  },
  {
    field: 'isPrimary',
    headerName: 'Primary contact',
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
    type: 'actions',
    align: 'left',
    headerAlign: 'left',
    getActions: ({ row }: { row: SupplierContact }) => [
      <Radio size='medium' checked={row.isPrimary} onClick={() => selectPrimaryContact(row.id)} />,
    ],
  },
  {
    field: 'email',
    headerName: 'Email',
    disableColumnMenu: true,
    sortable: false,
    editable: true,
    flex: 1,
    renderEditCell: ({ errorMessage, error, ...params }: GridRenderEditCellParams) => (
      <TooltipInputCell error={error as boolean} title={(errorMessage ?? '') as string}>
        <GridEditInputCell {...params} />
      </TooltipInputCell>
    ),
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      const result = supplierContactSchema.shape.email.safeParse(params.props.value)
      return {
        ...params.props,
        error: !result.success,
        errorMessage: result.success
          ? ''
          : result.error.issues.map((issue) => issue.message).toString(),
      }
    },
  },
  {
    field: 'phone',
    headerName: 'Phone number',
    disableColumnMenu: true,
    sortable: false,
    editable: true,
    flex: 1,
    valueGetter: (params) => params.row.phone ?? 'N/A',
    renderEditCell: ({ errorMessage, error, ...params }: GridRenderEditCellParams) => (
      <TooltipInputCell error={error as boolean} title={(errorMessage ?? '') as string}>
        <GridEditInputCell {...params} />
      </TooltipInputCell>
    ),
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      const result = supplierContactSchema.shape.phone.safeParse(params.props.value)
      return {
        ...params.props,
        error: !result.success,
        errorMessage: result.success
          ? ''
          : result.error.issues.map((issue) => issue.message).toString(),
      }
    },
  },
  {
    field: 'address',
    headerName: 'Region',
    disableColumnMenu: true,
    sortable: false,
    editable: true,
    flex: 1.2,
    renderCell: (params) =>
      params.row.address?.length > 0
        ? params.row.address
            .map((r) => REGIONS.find((region) => region.value === r.country)?.name)
            .join(', ')
        : 'N/A',
    renderEditCell: (params: GridRenderEditCellParams) => <RegionSelectEditInputCell {...params} />,
  },
  {
    field: 'timeZone',
    headerName: 'Timezone',
    disableColumnMenu: true,
    sortable: false,
    editable: true,
    flex: 1,
    renderEditCell: ({ errorMessage, error, ...params }: GridRenderEditCellParams) => (
      <TooltipInputCell
        error={error as boolean}
        title={(errorMessage ?? '') as string}
        placement='bottom-end'
      >
        <TimezoneEditInputCell {...params} />
      </TooltipInputCell>
    ),
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      const result = supplierContactSchema.shape.timeZone.safeParse(params.props.value)
      return {
        ...params.props,
        error: !result.success,
        errorMessage: result.success
          ? ''
          : result.error.issues.map((issue) => issue.message).toString(),
      }
    },
  },
  {
    field: '__actions__',
    type: 'actions',
    align: 'right',
    headerAlign: 'right',
    disableColumnMenu: true,
    sortable: false,
    width: OUTER_COLUMN_WIDTHS.ACTIONS,
    flex: 0.2,
    getActions: ({ row }: { row: SupplierContact }) => [
      <GridActionsCellItem
        icon={<DeleteOutlineIcon />}
        label='Delete'
        onClick={() => handleDeleteContact(row)}
      />,
    ],
  },
]
