import { type Project, type Organization } from '@pactum/core-backend-types'
import { createAction, Priority, useRegisterActions } from 'kbar'
import { useSnackbar } from 'notistack'
import { getAdminNegotiationEventUrl, getAdminWorkspaceUrl } from '@shared/utils/admin-links'

export function useOpenInAdminAction(
  isAdmin: boolean,
  activeOrg: Pick<Organization, 'projects'>,
  activeProject: Pick<Project, 'id'> | null,
  activeNegotiationEvent: { externalId: string } | null,
): void {
  const { enqueueSnackbar } = useSnackbar()

  useRegisterActions(
    [
      createAction({
        name: 'Open in Admin',
        section: 'Development',
        keywords: 'admin',
        priority: Priority.LOW,
        perform: async () => {
          try {
            let projectId = activeProject?.id
            if (!projectId) {
              const projectsInActiveOrg = activeOrg?.projects
                ? [...activeOrg.projects].sort((a, b) => a.name.localeCompare(b.name))
                : []
              if (projectsInActiveOrg.length > 0) {
                projectId = projectsInActiveOrg[0]?.id
              }
            }
            if (projectId) {
              const url = activeNegotiationEvent
                ? getAdminNegotiationEventUrl(projectId, activeNegotiationEvent.externalId)
                : getAdminWorkspaceUrl(projectId)
              window.open(url, '_blank')
            } else {
              enqueueSnackbar('No project found', { variant: 'error' })
            }
            enqueueSnackbar('Opening workspace in Admin...', { variant: 'success' })
          } catch (e) {
            enqueueSnackbar('Failed to open workspace in Admin', { variant: 'error' })
          }
        },
      }),
    ],
    [isAdmin, activeOrg, activeProject, activeNegotiationEvent],
  )
}
