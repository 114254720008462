import { GenericError } from '@auth0/auth0-spa-js'

const LOGIN_REQUIRED_ERROR = 'login_required' as const
const INVALID_GRANT_ERROR = 'invalid_grant' as const
const MISSING_REFRESH_ERROR = 'missing_refresh_token' as const

interface LoginRequiredError extends GenericError {
  error: typeof LOGIN_REQUIRED_ERROR
}

const isGenericAuth0Error = (error: unknown): error is GenericError => {
  return (
    typeof error === 'object' && error !== null && 'error' in error && 'error_description' in error
  )
}

export const isLoginRequiredError = (error: unknown): error is LoginRequiredError => {
  return (
    isGenericAuth0Error(error) &&
    (error.error === LOGIN_REQUIRED_ERROR ||
      error.error === INVALID_GRANT_ERROR ||
      error.error === MISSING_REFRESH_ERROR)
  )
}
