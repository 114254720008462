import { Chip, ChipProps } from '@mui/material'
import { NegotiationListEntity } from '@common/dto/pact/negotiation-list-entity'
import { PactNegotiationEventType } from '@common/dto/pact/negotiation-event'

type StatusConfig = Readonly<{ label: string; color: ChipProps['color'] }>
type NegotiationStatusConfigMap = Readonly<
  Record<NonNullable<NegotiationListEntity['status']>, StatusConfig>
>

const ONGOING_STATUS_MAP: NegotiationStatusConfigMap = {
  [PactNegotiationEventType.AGREEMENT_REACHED]: { label: 'Agreement reached', color: 'default' },
  [PactNegotiationEventType.INITIAL_CONTACT]: { label: 'Contacted', color: 'default' },
  [PactNegotiationEventType.LINK_SENT]: { label: 'Link sent', color: 'default' },
  [PactNegotiationEventType.STARTED]: { label: 'Negotiation started', color: 'default' },
  [PactNegotiationEventType.SIGNED]: { label: 'Agreement signed', color: 'default' },
} as const

const CONCLUDED_SUCCESSFUL_STATUS_MAP: NegotiationStatusConfigMap = {
  [PactNegotiationEventType.AGREEMENT_REACHED]: { label: 'Agreement reached', color: 'success' },
  [PactNegotiationEventType.INITIAL_CONTACT]: { label: 'Agreement reached', color: 'success' },
  [PactNegotiationEventType.LINK_SENT]: { label: 'Agreement reached', color: 'success' },
  [PactNegotiationEventType.STARTED]: { label: 'Agreement reached', color: 'success' },
  [PactNegotiationEventType.SIGNED]: { label: 'Agreement signed', color: 'success' },
} as const

const CONCLUDED_NOT_SUCCESSFUL_STATUS_MAP: NegotiationStatusConfigMap = {
  [PactNegotiationEventType.AGREEMENT_REACHED]: { label: 'Agreement not reached', color: 'error' },
  [PactNegotiationEventType.INITIAL_CONTACT]: { label: 'Agreement not reached', color: 'error' },
  [PactNegotiationEventType.LINK_SENT]: { label: 'Agreement not reached', color: 'error' },
  [PactNegotiationEventType.STARTED]: { label: 'Agreement not reached', color: 'error' },
  [PactNegotiationEventType.SIGNED]: { label: 'Agreement not signed', color: 'error' },
} as const

interface Props {
  status: NegotiationListEntity['status']
  isConcluded: boolean
  isSuccessful: boolean
}

export const ListItemStatus = ({ status, isSuccessful, isConcluded }: Props) => {
  const labelAndColor = mapStatusToLabelAndColor({ status, isConcluded, isSuccessful })
  if (labelAndColor === undefined) {
    return null
  }
  return <Chip size='small' {...labelAndColor} />
}

export interface InputForMappingStatusToLabel {
  status: NegotiationListEntity['status']
  isConcluded: boolean
  isSuccessful: boolean
}

export function mapStatusToLabelAndColor({
  status,
  isConcluded,
  isSuccessful,
}: InputForMappingStatusToLabel): StatusConfig | undefined {
  if (status === undefined) {
    return undefined
  }

  if (!isConcluded) {
    return status ? ONGOING_STATUS_MAP[status] : undefined
  }

  if (isSuccessful) {
    return status ? CONCLUDED_SUCCESSFUL_STATUS_MAP[status] : undefined
  }
  return status ? CONCLUDED_NOT_SUCCESSFUL_STATUS_MAP[status] : undefined
}
