import { format } from 'date-fns'
import get from 'lodash.get'
import omit from 'lodash.omit'
import { v4 } from 'uuid'
import { ProductType } from '@pactum/core-backend-types'
import {
  ConsignmentAgreementStatusEnum,
  FormField,
  NegotiationEvent,
  NegotiationEventSupplier,
  NegotiationEventSupplierLineItem,
  PurchasingUIConfig,
} from '@procurement/store/types'
import { CONTRACT_MODELS } from '@procurement/utils/contractModel'
import { adjustForLocalTimezone } from '@procurement/utils/date'
import { getPaymentDaysNumericValue } from '@procurement/utils/paymentDays'
import { isNullish } from '@procurement/utils/isNullish'
import { ContractModel, NegotiationSettings } from '@procurement/store/schema'
import {
  NegotiationEventFormData,
  NegotiationMode,
} from '@procurement/components/NegotiationEventForm/schema'
import { isEmptyObject } from '@procurement/utils/object'
import { isNumber } from '@shared/utils/type-guards'
import { isSpendNegotiation } from './utils/spend'
import { calculateSpendAmount, calculateSpendItemUnitPrice } from '../../utils/spend-calculation'

interface MapNegotiationEventToCreateFormDataParams {
  suiteConfig?: Pick<
    PurchasingUIConfig['suite'],
    'defaultContractModelId' | 'requisitionFormFields'
  >
}

const mapNegotiationEventToCreateFormData = ({
  suiteConfig,
}: MapNegotiationEventToCreateFormDataParams): Partial<NegotiationEventFormData> => {
  const visibleFields = new Set(
    suiteConfig?.requisitionFormFields?.filter((field) => field.visible).map((field) => field.id),
  )

  const isRebatesWithApplicableSpend =
    visibleFields.has('rebatesEnabled') && !visibleFields.has('lineItems')

  const withNegotiationMode = {
    negotiationMode: isRebatesWithApplicableSpend ? NegotiationMode.Spend : NegotiationMode.Prices,
  }

  if (suiteConfig?.defaultContractModelId) {
    return {
      ...withNegotiationMode,
      contractModel: {
        id: suiteConfig.defaultContractModelId,
      },
    }
  }

  return withNegotiationMode
}

const getSupplierLineItem = (
  lineItems: NegotiationEventSupplierLineItem[],
  numberInCollection: number,
): NegotiationEventSupplierLineItem | undefined =>
  lineItems.find((item) => item.numberInCollection === numberInCollection)

const getMaxTotalThresholdType = (
  settings: NegotiationEventSupplier['negotiationSettings'],
): 'discount' | 'increase' | 'absolute' => {
  if (!isNullish(settings?.minTotalPriceDiscountPercent)) {
    return 'discount'
  }

  if (!isNullish(settings?.maxTotalPriceIncreasePercent)) {
    return 'increase'
  }

  return 'absolute'
}

const mapNegotiationEventSupplierToFormData = (
  supplier: NegotiationEventSupplier,
  productType?: ProductType,
): NegotiationEventFormData['suppliers'][number] => {
  const isContractCost = productType === ProductType.ContractCost

  const initialPaymentTerms = {
    paymentDays: supplier.initialPaymentTerms.paymentDaysObject,
    discountDays: supplier.initialPaymentTerms.discountDaysObject,
    discount: supplier.initialPaymentTerms?.discount
      ? Number((supplier.initialPaymentTerms.discount * 100).toFixed(1))
      : null,
  }

  const contractLengthAlternatives = supplier.negotiationSettings?.contractLengthAlternatives?.map(
    (alternative, idx) => ({
      ...alternative,
      id: idx + 1,
    }),
  )
  const contractStartDate = adjustForLocalTimezone(supplier.negotiationSettings?.contractStartDate)
  const contractCostOnlySettings = isContractCost ? { contractStartDate } : {}

  const negotiationSettings = {
    ...supplier.negotiationSettings,
    ...contractCostOnlySettings,
    contractLengthAlternatives,
    incentives: supplier.negotiationSettings?.incentives ?? [],
    maxTotalPrice: supplier.negotiationSettings?.maxTotalPrice ?? null,
    maxTotalPriceIncreasePercent: supplier.negotiationSettings?.maxTotalPriceIncreasePercent ?? 0,
    maxTotalThresholdType: getMaxTotalThresholdType(supplier.negotiationSettings),
    minTotalPriceDiscountPercent: supplier.negotiationSettings?.minTotalPriceDiscountPercent ?? 0,
    negotiateInPercentages: Boolean(supplier.negotiationSettings?.termsBasis),
  }

  return {
    ...supplier,
    initialPaymentTerms,
    negotiationSettings,
  }
}

interface MapNegotiationEventToUpdateFormDataParams {
  negotiationEvent: NegotiationEvent
  suiteConfig?: Pick<PurchasingUIConfig['suite'], 'productType' | 'requisitionFormFields'>
}

const mapNegotiationEventToUpdateFormData = ({
  negotiationEvent,
  suiteConfig,
}: MapNegotiationEventToUpdateFormDataParams): NegotiationEventFormData => {
  const { contractModel } = negotiationEvent
  const isContractCost = suiteConfig?.productType === ProductType.ContractCost
  const firstSupplier = negotiationEvent.suppliers[0]
  const lineItemsCategories = new Set(negotiationEvent.lineItems.map((item) => item.category))

  const invoiceDates: Record<number, string> =
    (contractModel?.properties?.invoiceDates as Record<number, string>) ?? {}

  const suppliers = negotiationEvent.suppliers.map((supplier) =>
    mapNegotiationEventSupplierToFormData(supplier, suiteConfig?.productType),
  )

  const lineItems = negotiationEvent.lineItems.map((lineItem) => {
    const supplierLineItem = getSupplierLineItem(
      firstSupplier.lineItems,
      lineItem.numberInCollection,
    )

    const contractCostOnlyAttributes = isContractCost
      ? {
          invoiceDate: invoiceDates[lineItem.numberInCollection],
          minimumOrderQuantity: supplierLineItem?.minimumOrderQuantity,
          consignmentAgreementStatus:
            supplierLineItem?.negotiationSettings?.consignmentAgreementStatus,
          maximumAcceptableMoq: supplierLineItem?.negotiationSettings?.maximumAcceptableMoq,
        }
      : {}

    return {
      ...lineItem,
      ...contractCostOnlyAttributes,
      ...(supplierLineItem
        ? {
            acceptableIncoterms: supplierLineItem.negotiationSettings?.acceptableIncoterms,
            incoterm: supplierLineItem.incoterm,
          }
        : {}),
    }
  })

  const attachments = negotiationEvent.attachments.filter(
    (a) => negotiationEvent.suppliers.length === 1 || !a.supplierId || a.lineItemId,
  )
  const detachedAttachments = attachments.map((attachment) => ({
    file: new File([], attachment.fileName),
    isVisibleToSupplier: attachment.isVisibleToSupplier,
  }))
  const category =
    lineItemsCategories.size === 1 ? negotiationEvent.lineItems[0]?.category : undefined
  const deliveryDate = adjustForLocalTimezone(negotiationEvent.deliveryDate)
  const warrantyEnabled = Boolean(negotiationEvent.suppliers[0]?.negotiationSettings?.warranty)

  let contractCostOnlyAttributes: Partial<NegotiationEventFormData> = {}

  if (isContractCost) {
    contractCostOnlyAttributes = {
      contractDatesEnabled: Boolean(firstSupplier.negotiationSettings?.contractStartDate),
      contractIncotermsEnabled: Boolean(firstSupplier.negotiationSettings?.contractIncoterms),
      paymentDaysEnabled: Boolean(
        firstSupplier.initialPaymentTerms.paymentDaysObject?.netDays ||
          firstSupplier.negotiationSettings?.minPaymentDays ||
          firstSupplier.negotiationSettings?.maxPaymentDays,
      ),
    }

    if (contractModel) {
      contractCostOnlyAttributes.contractModel = {
        ...contractModel,
        properties: {
          ...contractModel.properties,
          previousContractDate: adjustForLocalTimezone(
            contractModel.properties.previousContractDate as string,
          ),
        },
      }
    }
  }

  let spendAmount = null
  if (isSpendNegotiation(lineItems[0])) {
    spendAmount = calculateSpendAmount({
      spendItemUnitPrice: lineItems[0].initialUnitPrice,
      spendDurationMonths: suppliers[0].negotiationSettings.previousContractLength,
      agreementDurationMonths: suppliers[0].negotiationSettings.contractLength,
    })
  }

  return {
    ...negotiationEvent,
    attachments,
    category,
    contractDatesEnabled: null,
    contractIncotermsEnabled: null,
    deliveryDate,
    detachedAttachments,
    lineItems,
    paymentDaysEnabled: null,
    suppliers,
    warrantyEnabled,
    spendAmount,
    negotiationMode: isSpendNegotiation(negotiationEvent.lineItems[0])
      ? NegotiationMode.Spend
      : NegotiationMode.Prices,
    ...contractCostOnlyAttributes,
  }
}

export const mapNegotiationEventToFormData = (
  params: MapNegotiationEventToCreateFormDataParams | MapNegotiationEventToUpdateFormDataParams,
): Partial<NegotiationEventFormData> => {
  if ('negotiationEvent' in params) {
    return mapNegotiationEventToUpdateFormData(params)
  }

  return mapNegotiationEventToCreateFormData(params)
}

const idForNewOrExistingItem = (id: number | null | undefined) => {
  const isNewId = (id: number | null | undefined) => (id ?? -1) < 0

  return isNewId(id) ? undefined : id
}

const generateId = () => {
  const generatedIdParts = v4().split('-')

  return generatedIdParts[generatedIdParts.length - 1]
}

const getMaxTotalThreshold = (settings: NegotiationSettings) => {
  const maxTotalThresholdType = settings?.maxTotalThresholdType

  if (!maxTotalThresholdType) {
    return {}
  }

  type ThresholdField =
    | 'maxTotalPrice'
    | 'minTotalPriceDiscountPercent'
    | 'maxTotalPriceIncreasePercent'

  const mapping = {
    absolute: 'maxTotalPrice',
    discount: 'minTotalPriceDiscountPercent',
    increase: 'maxTotalPriceIncreasePercent',
  }

  const selectedThreshold: ThresholdField = mapping[maxTotalThresholdType] as ThresholdField

  const value = settings?.[selectedThreshold]

  const leaveNotSelectedThresholds = ([key, _]: [string, string]) => key !== maxTotalThresholdType
  const emptyNotSelectedThresholds = ([_, thresholdField]: [string, string]): [string, null] => [
    thresholdField,
    null,
  ]

  const rest = Object.fromEntries(
    Object.entries(mapping).filter(leaveNotSelectedThresholds).map(emptyNotSelectedThresholds),
  )

  return {
    [selectedThreshold]: value ? Number(value) : null,
    ...rest,
  }
}

const normalizeDateValue = (value?: Date | string | null) => {
  const formatStyle = 'yyyy-MM-dd'

  if (!value) {
    return value
  }

  if (value instanceof Date) {
    return format(value, formatStyle)
  }

  if (!Number.isNaN(Date.parse(value))) {
    return format(new Date(value), formatStyle)
  }

  return value
}

const generateContractModelData = (formData: NegotiationEventFormData) => {
  const selectedContractProperties =
    CONTRACT_MODELS.find((contract) => contract.id === formData.contractModel?.id)?.properties ?? []

  if (selectedContractProperties.length === 0) {
    return null
  }

  const contractFields: ContractModel['properties'] = selectedContractProperties.reduce(
    (acc, field) => {
      const fieldPath = field.split('.')
      const fieldName: string = fieldPath[fieldPath.length - 1]

      return {
        ...acc,
        [fieldName]: get(formData, field) as Record<number, string>,
      }
    },
    {},
  )

  const formattedInvoiceDates = Object.entries(
    formData.contractModel?.properties?.invoiceDates ?? {},
  ).reduce(
    (acc, [key, date]) => ({
      ...acc,
      [key]: date as string,
    }),
    {},
  )

  return {
    ...formData.contractModel,
    properties: {
      ...contractFields,
      previousContractDate: normalizeDateValue(contractFields.previousContractDate),
      invoiceDates: isEmptyObject(formattedInvoiceDates) ? undefined : formattedInvoiceDates,
    },
  }
}

const getAcceptableIncoterms = (lineItem: NegotiationEventFormData['lineItems'][number]) => {
  const nullishIncoterm = lineItem.acceptableIncoterms ?? null
  return typeof nullishIncoterm === 'string'
    ? [lineItem.acceptableIncoterms]
    : lineItem.acceptableIncoterms
}

const getConsignmentAgreementStatus = (
  formData: NegotiationEventFormData,
  lineItem: NegotiationEventFormData['lineItems'][number],
) => {
  const consignmentAgreementStatusesExist = formData.lineItems.some(
    (lineItem) => lineItem.consignmentAgreementStatus,
  )
  if (!consignmentAgreementStatusesExist) {
    return null
  }
  return lineItem.consignmentAgreementStatus ?? ConsignmentAgreementStatusEnum.NoAnswer
}

const createNegotiationSettings = (
  negotiationSettings: NegotiationEventFormData['suppliers'][0]['negotiationSettings'],
  formData: NegotiationEventFormData,
  isPurchasing: boolean,
) => {
  // Handle both estimated spend and line items with rebates
  const rebates = {
    rebates:
      isNumber(negotiationSettings.rebates?.benchmark) &&
      isNumber(negotiationSettings.rebates?.minimum) &&
      isNumber(negotiationSettings.rebates?.target)
        ? negotiationSettings.rebates
        : undefined,
  }

  if (isPurchasing) {
    const acceptableIncoterms = formData.lineItems.reduce((acc, lineItem) => {
      const incoterms = lineItem.acceptableIncoterms
      const length = incoterms?.length

      if (!length) {
        return acc
      }

      return {
        ...acc,
        [lineItem.numberInCollection!]: incoterms,
      }
    }, {})

    return {
      ...negotiationSettings,
      contractStartDate: normalizeDateValue(negotiationSettings.contractStartDate) ?? null,
      acceptableIncoterms: Object.keys(acceptableIncoterms).length ? acceptableIncoterms : null,
      negotiateInPercentages: undefined,
      maxTotalThresholdType: undefined,
      // Terms basis applies only if negotiating in percentages
      termsBasis: negotiationSettings.negotiateInPercentages
        ? negotiationSettings.termsBasis
        : null,
      warranty: formData.warrantyEnabled ? negotiationSettings.warranty : null,
      ...rebates,
      ...getMaxTotalThreshold(negotiationSettings as NegotiationSettings),
    }
  }

  return {
    ...negotiationSettings,
    minPaymentDays: formData.paymentDaysEnabled
      ? getPaymentDaysNumericValue(negotiationSettings.minPaymentDays!)
      : null,
    maxPaymentDays: formData.paymentDaysEnabled
      ? getPaymentDaysNumericValue(negotiationSettings.maxPaymentDays!)
      : null,
    ...(formData.contractDatesEnabled
      ? {
          contractStartDate: normalizeDateValue(negotiationSettings.contractStartDate) ?? null,
        }
      : {
          contractStartDate: null,
          contractLength: null,
          contractLengthAlternatives: null,
        }),
    negotiateInPercentages: undefined,
    maxTotalThresholdType: undefined,
    // Terms basis applies only if negotiating in percentages
    termsBasis: negotiationSettings.negotiateInPercentages ? negotiationSettings.termsBasis : null,
    warranty: formData.warrantyEnabled ? negotiationSettings.warranty : null,
    contractIncoterms: formData.contractIncotermsEnabled
      ? negotiationSettings.contractIncoterms
      : null,
    ...rebates,
    ...getMaxTotalThreshold(negotiationSettings as NegotiationSettings),
  }
}

const createInitialPaymentTerms = (
  formData: NegotiationEventFormData,
  supplierData: NegotiationEventFormData['suppliers'][number],
  isPurchasing: boolean,
) => {
  if (isPurchasing) {
    return {
      discount:
        typeof supplierData.initialPaymentTerms.discount === 'number' &&
        !Number.isNaN(supplierData.initialPaymentTerms.discount)
          ? supplierData.initialPaymentTerms.discount / 100
          : null,
      paymentDaysObject: supplierData.initialPaymentTerms.paymentDays,
      discountDaysObject: supplierData.initialPaymentTerms.discountDays,
    }
  }

  return {
    ...supplierData.initialPaymentTerms,
    paymentDays: undefined,
    paymentDaysObject: formData.paymentDaysEnabled
      ? supplierData.initialPaymentTerms.paymentDays
      : null,
    discount:
      typeof supplierData.initialPaymentTerms.discount === 'number' &&
      !Number.isNaN(supplierData.initialPaymentTerms.discount)
        ? supplierData.initialPaymentTerms.discount / 100
        : null,
  }
}

const removeReadOnlyFields = (
  formData: NegotiationEventFormData,
  suiteConfig?: PurchasingUIConfig['suite'],
): NegotiationEventFormData => {
  const schema = suiteConfig?.requisitionFormFields

  if (!schema) {
    return formData
  }

  const isReadOnly = (f: FormField) => f.disabled
  const toKey = (f: FormField) => f.id
  const readOnlyFields = schema.filter(isReadOnly).map(toKey)

  return omit(formData, readOnlyFields) as NegotiationEventFormData
}

type MapFormDataToDtoArgs = {
  additionalData: Partial<NegotiationEventFormData>
}

export const mapFormDataToDto = (
  formData: NegotiationEventFormData,
  { additionalData }: MapFormDataToDtoArgs,
  suiteConfig?: PurchasingUIConfig['suite'],
): NegotiationEventFormData => {
  const generatedId = generateId()

  const productType = suiteConfig?.productType
  const isContractCost = productType === ProductType.ContractCost
  const isPurchasing = productType === ProductType.Purchasing

  const attachments = formData.attachments.map((attachment) => {
    const updatedAttachment = formData.detachedAttachments.find(
      (detachedAttachment) => detachedAttachment.file.name === attachment.fileName,
    )
    if (updatedAttachment) {
      return {
        ...attachment,
        isVisibleToSupplier: updatedAttachment.isVisibleToSupplier,
      }
    }
    return attachment
  })

  const contractModel = isContractCost ? generateContractModelData(formData) : null

  const suppliers = formData.suppliers.map((supplier) => ({
    ...supplier,
    isOriginalSupplier: true,
    initialPaymentTerms: createInitialPaymentTerms(formData, supplier, isPurchasing),
    locale: supplier.locale && supplier.locale.length ? supplier.locale : null,
    negotiationSettings: createNegotiationSettings(
      supplier.negotiationSettings,
      formData,
      isPurchasing,
    ),
  }))

  const lineItems = formData.lineItems.map(({ maximumAcceptableMoq, ...lineItem }) => {
    let initialUnitPrice = lineItem.initialUnitPrice
    const supplier = formData.suppliers[0]
    const negotiationSettings = supplier?.negotiationSettings ?? {}

    if (isSpendNegotiation(lineItem) && formData.spendAmount) {
      initialUnitPrice = calculateSpendItemUnitPrice({
        spendAmount: formData.spendAmount,
        spendDurationMonths: negotiationSettings.previousContractLength,
        agreementDurationMonths: negotiationSettings.contractLength,
      })
    }

    let settings: Pick<NegotiationEventSupplierLineItem, 'negotiationSettings'> | {} = {}
    if (isContractCost) {
      settings = {
        negotiationSettings: {
          acceptableIncoterms: getAcceptableIncoterms(lineItem),
          consignmentAgreementStatus: getConsignmentAgreementStatus(formData, lineItem),
          maximumAcceptableMoq: maximumAcceptableMoq,
        },
      }
    }

    return {
      ...lineItem,
      category: formData.category?.length ? formData.category : lineItem.category,
      currency: formData.currency?.length ? formData.currency : lineItem.currency,
      id: idForNewOrExistingItem(lineItem.id),
      acceptableIncoterms: null,
      incoterm: lineItem.incoterm === '-' ? null : lineItem.incoterm,
      initialUnitPrice,
      ...settings,
    }
  })

  const formSpecificFields = {
    spendAmount: undefined,
    negotiationMode: undefined,
    contractDatesEnabled: undefined,
    contractIncotermsEnabled: undefined,
    warrantyEnabled: undefined,
    paymentDaysEnabled: undefined,
  }

  const dtoWithReadonlyFields = {
    ...formData,
    ...additionalData,
    attachments,
    contractModel,
    deliveryDate: normalizeDateValue(formData.deliveryDate),
    externalId: formData.externalId ?? generatedId,
    lineItems,
    suppliers,
    uniqueName: formData.externalId ?? generatedId,
    ...formSpecificFields,
  }

  // @ts-expect-error - we need to remove form-specific fields before creating the DTO
  return removeReadOnlyFields(dtoWithReadonlyFields, suiteConfig)
}
