import { suiteBaseApi } from '@shared/api/suite-base-api'
import { Branding, BrandingRequest, LocalizedBranding } from '@pactum/core-backend-types'

export interface BrandingContentRequest {
  brandingId?: string
  locale?: string
}

export const brandingsApi = suiteBaseApi
  .enhanceEndpoints({
    addTagTypes: ['BrandingList', 'BrandingDetails', 'BrandingContent', 'BrandingIdForProject'],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getOrganizationBrandings: build.query<LocalizedBranding[], string>({
        query: (orgTag: string) => `suite/organization/${orgTag}/brandings`,
      }),
      getBrandingList: build.query<Branding[], string>({
        query: (organizationTag) => `brandings?organizationTag=${organizationTag}`,
        providesTags: ['BrandingList'],
      }),
      createBranding: build.mutation<Branding, BrandingRequest>({
        query: (data) => ({
          url: `brandings`,
          method: 'POST',
          body: data,
        }),
        invalidatesTags: ['BrandingList'],
      }),
      getBranding: build.query<Branding, string>({
        query: (brandingId) => `brandings/${brandingId}`,
        providesTags: ['BrandingDetails'],
      }),
      updateBranding: build.mutation<Branding, Branding>({
        query: (data) => ({
          url: `brandings/${data.uuid}`,
          method: 'PUT',
          body: data,
        }),
        invalidatesTags: ['BrandingList', 'BrandingDetails'],
      }),
      deleteBranding: build.mutation<void, string>({
        query: (uuid) => ({
          url: `brandings/${uuid}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['BrandingList', 'BrandingDetails'],
      }),
      setBrandingAsDefault: build.mutation<void, string>({
        query: (uuid) => ({
          url: `brandings/${uuid}/default`,
          method: 'PATCH',
        }),
        invalidatesTags: ['BrandingList', 'BrandingDetails'],
      }),
      getBrandingContent: build.query<LocalizedBranding, BrandingContentRequest>({
        query: ({ brandingId, locale }) => `brandings/${brandingId}/${locale}`,
        providesTags: ['BrandingDetails'],
      }),
      upsertBrandingContent: build.mutation<
        LocalizedBranding,
        BrandingContentRequest & { data: FormData }
      >({
        query: ({ brandingId, locale, data }) => ({
          url: `brandings/${brandingId}/${locale}`,
          method: 'PUT',
          body: data,
        }),
        invalidatesTags: ['BrandingContent', 'BrandingDetails'],
      }),
      deleteBrandingContent: build.mutation<void, BrandingContentRequest>({
        query: (data) => ({
          url: `brandings/${data.brandingId}/${data.locale}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['BrandingContent', 'BrandingDetails'],
      }),
      setBrandingContentAsDefault: build.mutation<void, BrandingContentRequest>({
        query: (data) => ({
          url: `brandings/${data.brandingId}/${data.locale}/default`,
          method: 'PATCH',
        }),
        invalidatesTags: ['BrandingContent', 'BrandingDetails'],
      }),
      determineBrandingIdForWorkspace: build.query<string | null, string>({
        query: (workspaceTag) => `brandings/determine-for-project/${workspaceTag}`,
        providesTags: ['BrandingIdForProject'],
        transformResponse: (response: { brandingId: string }) => response.brandingId || null,
      }),
      setBrandingForWorkspace: build.mutation<
        void,
        { workspaceTag: string; brandingId: string | null }
      >({
        query: (data) => ({
          url: `brandings/set-for-project/${data.workspaceTag}`,
          method: 'PATCH',
          body: { brandingId: data.brandingId },
        }),
        invalidatesTags: ['BrandingIdForProject'],
      }),
    }),
    overrideExisting: false,
  })

export const {
  useGetOrganizationBrandingsQuery,
  useGetBrandingListQuery,
  useDetermineBrandingIdForWorkspaceQuery,
  useCreateBrandingMutation,
  useUpdateBrandingMutation,
  useDeleteBrandingMutation,
  useDeleteBrandingContentMutation,
  useSetBrandingForWorkspaceMutation,
  useUpsertBrandingContentMutation,
  useGetBrandingQuery,
  useGetBrandingContentQuery,
  useSetBrandingAsDefaultMutation,
  useSetBrandingContentAsDefaultMutation,
} = brandingsApi
