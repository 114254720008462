import { InputAdornment, TextField, Typography } from '@mui/material'
import { InPlaceKey } from './RowDetailsPanel'

interface Props {
  value: number
  valueKey: InPlaceKey
  onChange: (value: number | null, key: InPlaceKey) => void
  disabled?: boolean
  divider?: number
  unit?: string
}

export const NegotiationInputField = ({
  value,
  valueKey,
  onChange,
  disabled,
  divider = 1,
  unit = '€',
}: Props): JSX.Element => (
  <TextField
    size='small'
    type='number'
    disabled={disabled}
    value={value}
    sx={{ maxWidth: 110 }}
    onChange={(e) => {
      if (e.target.value === '') {
        onChange(null, valueKey)
      } else {
        onChange(Number(e.target.value) / divider, valueKey)
      }
    }}
    InputProps={{
      endAdornment: (
        <InputAdornment position='start'>
          <Typography>{unit}</Typography>
        </InputAdornment>
      ),
    }}
  />
)
