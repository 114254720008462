import { Button, styled } from '@mui/material'

export const BatchActionButton = styled(Button)(({ theme }) => ({
  height: '16px',
  fontSize: '10px',
  color: theme.palette.common.white,
  textTransform: 'none',
  borderRadius: '2px',
  background: theme.palette.accent.main,
  ':hover': {
    background: theme.palette.accent.main,
  },
  '&.Mui-disabled': {
    color: theme.palette.common.white,
    background: theme.palette.text.secondary,
    opacity: 0.3,
  },

  '.MuiButton-startIcon': {
    marginRight: '4px',

    ' .MuiSvgIcon-root': {
      fontSize: '10px',
    },
  },
}))
