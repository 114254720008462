import { NoAgreementFeedback } from '@procurement/store/types'
import { Stack } from '@mui/material'
import { FeedbackTitle } from './FeedbackTitle'
import { FeedbackReasonText } from './FeedbackReasonText'

interface Props {
  feedbackDetails: NoAgreementFeedback
}

export const NoAgreement = ({ feedbackDetails }: Props) => (
  <Stack direction='column' gap={3}>
    <FeedbackTitle>No agreement reached</FeedbackTitle>
    <FeedbackReasonText label='Comment' value={feedbackDetails.reason} />
  </Stack>
)
