import { ChangeEvent } from 'react'
import { Grid, InputAdornment, TextField, Typography } from '@mui/material'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { FormRow } from '@procurement-shared/FormCommon/FormCommon'
import { asNumberOrNull } from '@utils/number'
import { NegotiationEventFormData } from '../../schema'

type Props = { row: NegotiationEventFormData['lineItems'][number] }

export const MinimumOrderQuantity = ({ row }: Props) => {
  const endAdornment = <InputAdornment position='end'>{row.unit}</InputAdornment>
  const { control, watch } = useFormContext<NegotiationEventFormData>()
  const { replace } = useFieldArray({ control, name: 'lineItems' })
  const [lineItems] = watch(['lineItems'])

  const handleFieldChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: string,
  ) => {
    const updatedLineItems = lineItems.map((item) => {
      if (item.numberInCollection === row.numberInCollection) {
        return {
          ...item,
          [fieldName]: asNumberOrNull(event.target.value),
        }
      }
      return item
    })
    replace(updatedLineItems)
  }

  return (
    <FormRow>
      <Grid item xs={2.5} sx={{ mt: 2 }}>
        <Typography variant='body2'>Minimum Order Quantity (MOQ):</Typography>
      </Grid>
      <Grid container item xs={9.5}>
        <Grid container item xs={10} sx={{ mt: 1, mb: 1, alignItems: 'center' }}>
          <Grid item xs={6}>
            <Typography variant='body2'>Current or quoted (optional):</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              sx={{
                '& input': {
                  height: 2,
                },
              }}
              InputProps={{ endAdornment, type: 'number' }}
              value={row.minimumOrderQuantity}
              name={`lineItems.${row}.minimumOrderQuantity`}
              inputProps={{ min: 0 }}
              onChange={(e) => handleFieldChange(e, 'minimumOrderQuantity')}
            />
          </Grid>
        </Grid>
        <Grid container item xs={10} sx={{ mt: 1, mb: 1, alignItems: 'center' }}>
          <Grid item xs={6}>
            <Typography variant='body2'>Maximum acceptable MOQ:</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              sx={{
                '& input': {
                  height: 2,
                },
              }}
              InputProps={{ endAdornment, type: 'number' }}
              value={row.maximumAcceptableMoq}
              name={`lineItems.${row}.maximumAcceptableMoq`}
              inputProps={{ min: 0 }}
              onChange={(e) => handleFieldChange(e, 'maximumAcceptableMoq')}
            />
          </Grid>
        </Grid>
      </Grid>
    </FormRow>
  )
}
