import { Typography } from '@mui/material'

interface Props {
  children: React.ReactNode
}
export const HighlightedCell = ({ children }: Props): JSX.Element => (
  <Typography variant='body2' fontWeight={700}>
    {children}
  </Typography>
)
