import { MenuItem } from '@mui/material'
import { TextFieldCtrl, TextFieldCtrlProps } from './TextFieldCtrl'

type OptionType = {
  label: string
  value: string | number
}

export type SelectCtrlProps = TextFieldCtrlProps & {
  options: (string | number | OptionType)[]
}

export const SelectCtrl = ({ options, ...rest }: SelectCtrlProps) => {
  const renderOption = (option: string | number | OptionType) => {
    if (typeof option === 'object') {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      )
    }
    return (
      <MenuItem key={option} value={option}>
        {option}
      </MenuItem>
    )
  }

  return (
    <TextFieldCtrl select {...rest}>
      {options.map(renderOption)}
    </TextFieldCtrl>
  )
}
