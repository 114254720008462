import { PricingStrategy } from '@common/types'
import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useNegotiationPreparationData } from '../../NegotiationPreparationPage'

const propertyOrder: (keyof Omit<PricingStrategy, 'strategyName'>)[] = [
  'sales',
  'unitSales',
  'profit',
  'grossMarginPercentage',
  'competitivePricingInfluence',
  'marginInfluence',
  'salesVolumeInfluence',
  'upPurchaseInfluence',
  'competitorPrice',
  'priceElasticity',
]

const propertyToDisplayNameMap: Record<keyof Omit<PricingStrategy, 'strategyName'>, string> = {
  sales: 'Sales',
  unitSales: 'Unit Sales',
  profit: 'Profit',
  grossMarginPercentage: 'Gross Margin %',
  competitivePricingInfluence: 'Competitive Pricing Influence',
  marginInfluence: 'Margin Influence',
  salesVolumeInfluence: 'Sales Volume Influence',
  upPurchaseInfluence: 'Up Purchase Influence',
  competitorPrice: 'Competitor price',
  priceElasticity: 'Price Elasticity',
}
export const PricingRevionicsTable = (): JSX.Element | null => {
  const { data } = useNegotiationPreparationData()

  if (!data.analysis.pricingStrategy) {
    console.warn('No data for pricing strategy')
    return null
  }

  const strategies = data.analysis.pricingStrategy.strategies
  const currentStrategy = data.analysis.pricingStrategy.current

  return (
    <Box mt={2}>
      <Typography variant='h5' mb={2}>
        Pricing Strategy by Revionics
      </Typography>
      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell />
              {strategies?.map((strategy) => {
                if (strategy.strategyName === currentStrategy) {
                  return (
                    <HighlightedTableCell
                      sx={{ fontWeight: 'bold' }}
                      key={`${strategy.strategyName}-highlighted`}
                      isFirst
                    >
                      {strategy.strategyName}
                    </HighlightedTableCell>
                  )
                }
                return (
                  <TableCell sx={{ fontWeight: 'bold' }} key={strategy.strategyName}>
                    {strategy.strategyName}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {propertyOrder.map((property, idx) => (
              <TableRow key={property}>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  {propertyToDisplayNameMap[property]}
                </TableCell>
                {strategies?.map((strategy) => {
                  if (strategy.strategyName === currentStrategy) {
                    const isLastRow = idx === propertyOrder.length - 1
                    return (
                      <HighlightedTableCell
                        key={`${strategy.strategyName}-highlighted`}
                        isLast={isLastRow}
                      >
                        {strategy[property]}
                      </HighlightedTableCell>
                    )
                  }
                  return <TableCell key={strategy.strategyName}>{strategy[property]}</TableCell>
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

const HighlightedTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => !['isLast', 'isFirst'].includes(prop as string),
})<{ isFirst?: boolean; isLast?: boolean }>(({ isFirst, isLast, theme }) => ({
  borderLeft: `2px solid ${theme.palette.success.main}`,
  borderRight: `2px solid ${theme.palette.success.main}`,
  ...(isFirst && {
    borderTop: `2px solid ${theme.palette.success.main}`,
  }),
  ...(isLast && {
    borderBottom: `2px solid ${theme.palette.success.main}`,
  }),
}))
