import { Chip } from '@mui/material'
import { UserStatus } from '@common/types/suite'
import { userStatusDisplayName, userStatusStyle } from './userStatus'

interface Props {
  status: UserStatus | undefined
}

export const ListItemUserStatus = ({ status }: Props) => {
  if (status === undefined) {
    return null
  }
  return <Chip label={userStatusDisplayName(status)} size={'small'} sx={userStatusStyle(status)} />
}
