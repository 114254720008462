import { useState } from 'react'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { DeleteConfirmationModal } from 'src/shared/components/ConfirmationModal'
import { BatchActionButton } from './BatchActionButton'

export type DeleteActionProps = {
  enabled: boolean
  totalNegotiationsCount: number
  actionableNegotiationsCount: number
  onExecute: () => void
}

export const DeleteAction = ({
  enabled,
  totalNegotiationsCount,
  actionableNegotiationsCount,
  onExecute,
}: DeleteActionProps) => {
  const [isModalOpen, setModalOpen] = useState(false)

  return (
    <>
      <BatchActionButton
        startIcon={<DeleteOutlineIcon />}
        disabled={!enabled}
        onClick={() => setModalOpen(true)}
      >
        Delete
      </BatchActionButton>
      <DeleteConfirmationModal
        allNegotiations={totalNegotiationsCount}
        actionableNegotiations={actionableNegotiationsCount}
        open={isModalOpen}
        onCancel={() => setModalOpen(false)}
        onSubmit={() => {
          setModalOpen(false)
          onExecute()
        }}
      />
    </>
  )
}
