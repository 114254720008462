import { DetailedCostModelBreakdown, NegotiationAnalysis } from '@common/types'
import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { percentFormatter } from '@utils'
import { HeaderTableCell } from './SupplierCommoditiesReflections'
import { useGetNegotiationPreparationQuery } from 'src/merchandising/store/'

type Row = DetailedCostModelBreakdown[string][number] & { costType?: string }

const getRows = (costModel: NegotiationAnalysis['detailedCostModel']): Row[] => {
  const breakdown = costModel?.breakdown

  const rows: Row[][] = []

  if (!breakdown) {
    return rows.flat()
  }

  Object.keys(breakdown).forEach((costType) => {
    const breakdownElements = breakdown[costType]

    rows.push(
      breakdownElements.map((element) => {
        if (element.commodity === '') {
          return { ...element, costType }
        }
        return { ...element }
      }),
    )
  })

  return rows.flat()
}

export const DetailedCostModel = (): JSX.Element | null => {
  const { negotiationPreparationId } = useParams<{ negotiationPreparationId: string }>()
  const { data } = useGetNegotiationPreparationQuery(negotiationPreparationId!, {
    skip: !negotiationPreparationId,
  })

  const rows = useMemo(() => (data ? getRows(data.analysis.detailedCostModel) : []), [data])

  if (!data) {
    return null
  }

  if (!data.analysis.detailedCostModel || !rows.length) {
    console.warn('No data for Detailed Cost Model')
    return null
  }

  return (
    <Box mt={4}>
      <Typography variant='h5'>Detailed Cost Model</Typography>
      <TableContainer sx={{ mt: 2 }}>
        <Table sx={{ minWidth: 300 }}>
          <TableHead>
            <TableRow>
              <HeaderTableCell>Cost type</HeaderTableCell>
              <HeaderTableCell>Commodity</HeaderTableCell>
              <HeaderTableCell>% of total value</HeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={JSON.stringify(row)}>
                <HighlightedTableCell isHighlighted={row.isHighlighted}>
                  {row.costType}
                </HighlightedTableCell>
                <HighlightedTableCell isHighlighted={row.isHighlighted}>
                  {row.commodity}
                </HighlightedTableCell>
                <HighlightedTableCell isHighlighted={row.isHighlighted}>
                  {percentFormatter(row.percentOfTotalValue)}
                </HighlightedTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

const HighlightedTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'isHighlighted',
})(({ isHighlighted }: { isHighlighted: boolean }) => ({
  ...(isHighlighted && {
    fontWeight: 'bold',
  }),
}))
