import { CaptionedValueCell } from 'src/shared/components/table'
import { usdFormatter } from '@utils'
import { isValidDate } from 'src/main/utils/date'

interface Props {
  price: number
  date: Date
  datePrefixString: string
}

export const PriceWithDateCell = ({ price, date, datePrefixString = '' }: Props): JSX.Element => {
  const dateString = isValidDate(date)
    ? `${datePrefixString}${new Date(date).toLocaleDateString()}`
    : ''
  return <CaptionedValueCell mainContent={usdFormatter(price)} captionContent={dateString} />
}
