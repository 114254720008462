import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetNegotiationPreparationModelQuery } from 'src/merchandising/store/'
import { NegotiationModelContainer } from 'src/merchandising/pages/NegotiationModelContainer'
import { NegotiationModel } from '@common/dto'
import { NegotiationPreparationModel } from '@common/types'

export const NegotiationPreparationModelPage = () => {
  const { negotiationPreparationId } = useParams<{ negotiationPreparationId: string }>()
  const { data, isFetching, isLoading, isError, error } = useGetNegotiationPreparationModelQuery(
    negotiationPreparationId!,
    {
      skip: !negotiationPreparationId,
    },
  )

  return (
    <NegotiationModelContainer
      isLoading={isLoading}
      isFetching={isFetching}
      data={data as NegotiationModel<NegotiationPreparationModel>}
      isError={isError}
      error={error}
    />
  )
}
