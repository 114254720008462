import React, { useContext } from 'react'
import { NoEntities } from '@logistics/components/NoEntities'
import { SupplierPageContext } from '@logistics/pages/common/SupplierPage/context'

export const NoSuppliers = () => {
  const { setIsCreateSupplierModalOpen } = useContext(SupplierPageContext)

  return (
    <NoEntities
      title='It’s empty here. How about you try creating your first carrier?'
      buttonText='New carrier'
      onButtonClick={() => setIsCreateSupplierModalOpen(true)}
    />
  )
}
