import { SnackbarProvider, useSnackbar } from 'notistack'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import * as Sentry from '@sentry/react'
import { initPosthog } from 'src/main/utils'
import { reportWebVitals } from './main/reportWebVitals'

import CloseIcon from '@mui/icons-material/Close'
import { CssBaseline, IconButton, ThemeProvider } from '@mui/material'
import { LicenseInfo } from '@mui/x-data-grid-pro'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'

import { App } from './main/App'
import './main/index.css'

import { ErrorPage } from 'src/main/pages/ErrorPage'

import { store } from 'src/main/store'
import { pactumTheme } from './main/constants/theme'
import { Authentication } from './main/hooks/auth/Authentication'
import { setDefaultOptions } from 'date-fns'
import { LOCALE } from '@shared/utils/date'
import { initSentry } from '@utils/sentry'

if (import.meta.env.VITE_REACT_APP_ENV !== 'development') {
  initSentry()
  if (import.meta.env.PROD) {
    initPosthog()
  }
}

LicenseInfo.setLicenseKey(
  '217e15c0c57af2c77ae7088a42ddbde7Tz05NjQzNCxFPTE3NTU2ODA2MzQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg==',
)

const FallbackPage = (): JSX.Element => (
  <ErrorPage title='Something went wrong'>
    <p>
      There was an unexpected error while loading the page. Pactum team was notified and is working
      on it.
    </p>
  </ErrorPage>
)

const CloseSnackbar = ({ snackbarId }: { snackbarId: string | number }): JSX.Element => {
  const { closeSnackbar } = useSnackbar()

  return (
    <IconButton size='small' sx={{ color: 'white' }} onClick={() => closeSnackbar(snackbarId)}>
      <CloseIcon fontSize='small' />
    </IconButton>
  )
}

// Set default locale for date-fns
setDefaultOptions({ locale: LOCALE })

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={FallbackPage}>
      <ThemeProvider theme={pactumTheme}>
        <CssBaseline />
        <BrowserRouter>
          <Provider store={store}>
            <Authentication>
              <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                autoHideDuration={10_000}
                action={(snackbarId) => <CloseSnackbar snackbarId={snackbarId} />}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={LOCALE}>
                  <App />
                </LocalizationProvider>
              </SnackbarProvider>
            </Authentication>
          </Provider>
        </BrowserRouter>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
