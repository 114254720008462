import React, { useEffect, useMemo, useState } from 'react'
import { Box, Button, Stack, styled, Tooltip, Typography } from '@mui/material'
import {
  DefaultIndividualItemInput,
  ItemData,
  ItemThresholds,
  LastChange,
  PriceVolumePoint,
} from '@common/types'
import { ScenarioState } from '@common/types/scenario/ScenarioState'
import { EditableCell } from './EditableCell'
import { checkErrors, shouldShowNA } from './utils'
import { columns, rowDetailsMap } from './constants'
import { PriceVolumeDialog } from './PriceVolumeDialog'
import { StyledTableCell, StyledValueCell } from './StyledCells'
import { ItemThresholdsDialog } from './ItemThresholdsDialog'
import { InputError, RowValue } from 'src/merchandising/types'
import { ValueTooltipIcon } from '../components/ValueTooltipIcon'
import { FlatRow, InputErrors, RowInputKey } from './types'

export interface TableColDef {
  key: RowInputKey
  editable: boolean
  unit: string
  validations?: Record<'laa' | 'target', (value: number | null) => InputError>
}

export type InputDefaultValues = Record<RowInputKey, number | null>
export type InputDefaultFilledValues = Record<RowInputKey, number>

const getDefaultInputValues = (row: FlatRow, type: 'target' | 'lac'): InputDefaultValues => {
  const columnKeys = columns.map((col) => col.key)
  return columnKeys.reduce(
    (prev, curr) => ({ ...prev, [curr]: row[curr][type] }),
    {} as InputDefaultValues,
  )
}

type OnRowUpdate = (
  commercial_id: ItemData['commercial_id'],
  switchEnableState: boolean,
  data: {
    target: InputDefaultFilledValues
    lac: InputDefaultFilledValues
    thresholds: ItemThresholds
    priceVolumePoints: PriceVolumePoint[]
  },
  lastChange?: LastChange,
) => void

interface Props {
  row: ItemData
  defaultItemInput: DefaultIndividualItemInput
  marketInfo: ScenarioState['market_info']
  isLoading: boolean
  onRowUpdate: OnRowUpdate
  onItemError: (commercialId: ItemData['commercial_id'], isError: boolean) => void
  resetRow: (commercial_id: ItemData['commercial_id']) => void
  isFinalNegotiationStage: boolean
}

export const RowDetailsPanel = ({
  row,
  defaultItemInput,
  marketInfo,
  isLoading,
  onRowUpdate,
  onItemError,
  resetRow,
  isFinalNegotiationStage,
}: Props) => {
  const flatRow: FlatRow = useMemo(() => ({ ...row, ...row.benefits }), [row])
  const [negotiationTarget, setNegotiationTarget] = useState<InputDefaultValues>(
    getDefaultInputValues(flatRow, 'target'),
  )
  const [negotiationLAA, setNegotiationLAA] = useState<InputDefaultValues>(
    getDefaultInputValues(flatRow, 'lac'),
  )
  const [targetErrors, setTargetErrors] = useState<InputErrors>(checkErrors(flatRow, 'target'))
  const [laaErrors, setLaaErrors] = useState<InputErrors>(checkErrors(flatRow, 'lac'))
  const [loadingCell, setLoadingCell] = useState<string | undefined>(undefined)
  const [openPriceVolumeModal, setOpenPriceVolumeModal] = useState<boolean>(false)
  const [openThresholdsModal, setOpenThresholdsModal] = useState<boolean>(false)
  const [thresholds, setThresholds] = useState<ItemThresholds>(row.thresholds)

  useEffect(() => {
    // reset cell loading when error is returned
    if (loadingCell && !isLoading) {
      setLoadingCell(undefined)
    }
  }, [isLoading, loadingCell])

  useEffect(() => {
    setNegotiationLAA(getDefaultInputValues(flatRow, 'lac'))
    setLaaErrors(checkErrors(flatRow, 'lac'))
    setLoadingCell(undefined)
  }, [flatRow])

  useEffect(() => {
    setNegotiationTarget(getDefaultInputValues(flatRow, 'target'))
    setTargetErrors(checkErrors(flatRow, 'target'))
    setLoadingCell(undefined)
  }, [flatRow])

  useEffect(() => {
    const anyItemFieldInError =
      Object.values(laaErrors).some((error) => error.isError) ||
      Object.values(targetErrors).some((error) => error.isError)

    onItemError(row.commercial_id, row.enabled && anyItemFieldInError)
  }, [onItemError, laaErrors, targetErrors, row.commercial_id, row.enabled])

  const onTargetValueChange = (value: number | null, key: string) => {
    const updatedNegotiationTarget = {
      ...negotiationTarget,
      [key]: value,
    }
    setNegotiationTarget(updatedNegotiationTarget)
    setError(columns.find((col) => col.key === key)!, value, 'laa')
    const change: LastChange = {
      scenario_type: 'target',
      element: key as Exclude<TableColDef['key'], 'ipr' | 'volumes' | 'thresholds'>,
      commercial_id: row.commercial_id,
    }
    onItemSubmit(updatedNegotiationTarget, negotiationLAA, change, row.price_volume_points)
  }

  const onLAAValueChange = (value: number | null, key: string) => {
    const updatedNegotiationLAA = {
      ...negotiationLAA,
      [key]: value,
    }
    setNegotiationLAA(updatedNegotiationLAA)
    setError(columns.find((col) => col.key === key)!, value, 'laa')
    const change: LastChange = {
      scenario_type: 'lac',
      element: key as Exclude<TableColDef['key'], 'ipr' | 'volumes' | 'thresholds'>,
      commercial_id: row.commercial_id,
    }
    onItemSubmit(negotiationTarget, updatedNegotiationLAA, change, row.price_volume_points)
  }

  const onThresholdsUpdate = () => {
    setOpenThresholdsModal(false)
    onRowUpdate(row.commercial_id, false, {
      target: negotiationTarget as InputDefaultFilledValues,
      lac: negotiationLAA as InputDefaultFilledValues,
      thresholds: thresholds,
      priceVolumePoints: row.price_volume_points,
    })
  }

  const onCancelingThresholdsUpdate = () => {
    setOpenThresholdsModal(false)
    setThresholds(row.thresholds)
  }

  const onItemReset = () => {
    resetRow(row.commercial_id)
    setThresholds(defaultItemInput.thresholds)
  }

  const onEnableDisable = () => {
    onRowUpdate(row.commercial_id, true, {
      target: negotiationTarget as InputDefaultFilledValues,
      lac: negotiationLAA as InputDefaultFilledValues,
      thresholds: thresholds,
      priceVolumePoints: row.price_volume_points,
    })
  }

  const onItemSubmit = (
    negotiationTarget: InputDefaultValues,
    negotiationLAC: InputDefaultValues,
    lastChange: LastChange,
    priceVolumePoints: PriceVolumePoint[],
  ) => {
    onRowUpdate(
      row.commercial_id,
      false,
      {
        target: negotiationTarget as InputDefaultFilledValues,
        lac: negotiationLAC as InputDefaultFilledValues,
        thresholds: row.thresholds,
        priceVolumePoints,
      },
      lastChange,
    )
    setLoadingCell(lastChange.commercial_id + lastChange.scenario_type + lastChange.element)
  }

  const getLoadingId = (column: TableColDef, scenarioType: 'lac' | 'target') => {
    return row.commercial_id + scenarioType + column.key
  }

  const togglePriceVolumeModal = (state = true) => {
    setOpenPriceVolumeModal(state)
  }

  const onPriceVolumeSubmit = (priceVolumePoints: PriceVolumePoint[]) => {
    onRowUpdate(row.commercial_id, false, {
      target: negotiationTarget as InputDefaultFilledValues,
      lac: negotiationLAA as InputDefaultFilledValues,
      thresholds: row.thresholds,
      priceVolumePoints,
    })
  }

  const setError = (column: TableColDef, value: number | null, scenarioType: 'laa' | 'target') => {
    const validationResult = column.validations
      ? column.validations[scenarioType](value)
      : { isError: false }
    onItemError(row.commercial_id, validationResult.isError)
    if (scenarioType === 'target') {
      setTargetErrors({ ...targetErrors, [column.key]: validationResult })
    } else {
      setLaaErrors({ ...laaErrors, [column.key]: validationResult })
    }
  }

  return (
    <Box>
      {row.enabled && (
        <TableWrapper>
          <Column sx={{ flex: 2.25 }}>
            <Tooltip
              placement='bottom'
              title='Least Acceptable Agreement values specify the threshold for agreeable terms - any worse terms will not be agreed'
            >
              <StyledTableCell component='div'>
                Negotiation LAA
                <ValueTooltipIcon />
              </StyledTableCell>
            </Tooltip>
            <Tooltip
              placement='bottom'
              title='Target or desired outcome of the autonomous negotiation'
            >
              <StyledTableCell component='div'>
                Negotiation Target
                <ValueTooltipIcon />
              </StyledTableCell>
            </Tooltip>
            {rowDetailsMap.map((col, index) => (
              <Tooltip
                key={index}
                placement='bottom'
                title={<Box sx={{ whiteSpace: 'pre-line' }}>{col.tooltipText}</Box>}
              >
                <StyledTableCell component='div' key={col.key}>
                  {col.name}
                  <ValueTooltipIcon />
                </StyledTableCell>
              </Tooltip>
            ))}
          </Column>
          {columns.map((column: TableColDef) => {
            const columnValues = flatRow[column.key]
            return (
              <>
                {/* Add empty column before 'volumes' to compensate for "Is RFQ?" */}
                {column.key === 'volumes' && (
                  <Column key='rfq' sx={{ flex: 0.5 }}>
                    {rowDetailsMap.map((detailsRow) => {
                      return (
                        <Stack key={detailsRow.key} direction='row' sx={{ width: 0 }}>
                          <StyledTableCell component='div'></StyledTableCell>
                        </Stack>
                      )
                    })}
                  </Column>
                )}
                <Column key={column.key}>
                  <EditableCell
                    column={column}
                    disabled={isLoading}
                    currentValues={negotiationLAA}
                    currency={row.currency}
                    isLoading={isLoading && getLoadingId(column, 'lac') === loadingCell}
                    onChange={onLAAValueChange}
                    error={laaErrors[column.key]}
                    openPriceVolumeModal={() => togglePriceVolumeModal()}
                  />
                  <EditableCell
                    column={column}
                    disabled={isLoading}
                    currentValues={negotiationTarget}
                    currency={row.currency}
                    isLoading={isLoading && getLoadingId(column, 'target') === loadingCell}
                    onChange={onTargetValueChange}
                    error={targetErrors[column.key]}
                    openPriceVolumeModal={() => togglePriceVolumeModal()}
                  />
                  {rowDetailsMap.map((detailsRow) => {
                    const value = (columnValues as RowValue)[detailsRow.key]

                    return (
                      <Stack key={detailsRow.key} direction='row' sx={{ width: 0 }}>
                        <StyledValueCell
                          key={detailsRow.key}
                          rowKey={detailsRow.key}
                          row={row}
                          marketInfo={marketInfo}
                          na={shouldShowNA(column.key, detailsRow.key)}
                          unavailable={value === null || value === undefined}
                          column={column}
                          value={value}
                        />
                      </Stack>
                    )
                  })}
                </Column>
              </>
            )
          })}
        </TableWrapper>
      )}
      <Stack direction='row' justifyContent='flex-end' alignItems='center' my={4} mr={1} gap={1}>
        <Typography
          variant='body2'
          sx={{ fontStyle: 'italic', fontWeight: 'light', marginRight: 1 }}
        >
          Changes are saved automatically
        </Typography>
        {row.enabled && (
          <Button
            color='primary'
            variant='contained'
            disabled={isLoading}
            onClick={() => setOpenThresholdsModal(true)}
          >
            Adjust thresholds
          </Button>
        )}
        {row.enabled && (
          <Button color='primary' variant='contained' disabled={isLoading} onClick={onItemReset}>
            {isLoading ? 'Loading...' : 'Reset values'}
          </Button>
        )}
        <Button
          color={row.enabled ? 'primary' : 'secondary'}
          variant='contained'
          disabled={isLoading}
          onClick={onEnableDisable}
        >
          {getEnableDisableButtonText(isLoading, row.enabled)}
        </Button>
      </Stack>
      <ItemThresholdsDialog
        open={openThresholdsModal}
        thresholds={thresholds}
        onChange={setThresholds}
        onCancel={onCancelingThresholdsUpdate}
        onApply={onThresholdsUpdate}
        enableWeek2NegotiationQuantityRequirementEditing={!isFinalNegotiationStage}
      />
      <PriceVolumeDialog
        onSubmit={onPriceVolumeSubmit}
        priceVolumePoints={row.price_volume_points}
        currency={row.currency || 'EUR'}
        defaultValues={defaultItemInput.price_and_volume_points}
        itemName={row.name}
        open={openPriceVolumeModal}
        toggleModalOpen={togglePriceVolumeModal}
        annotations={{
          target: row.net_prices.target,
          lac: row.net_prices.lac,
        }}
      />
    </Box>
  )
}

function getEnableDisableButtonText(isLoading: boolean, enabled: boolean): string {
  if (isLoading) {
    return 'Loading...'
  } else {
    return enabled ? 'Disable' : 'Enable'
  }
}

const TableWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  marginLeft: '50px',
  overflow: 'hidden',
})

const Column = styled(Box)({
  flex: 1,
})
