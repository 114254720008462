import { Add, ChevronLeft, ChevronRight } from '@mui/icons-material'
import { Box, IconButton, Menu, MenuItem, Theme, styled } from '@mui/material'
import { MUIStyledCommonProps } from '@mui/system'
import { PropsWithChildren, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'

const useScrollbarWidth = () => {
  const didCompute = useRef(false)
  const widthRef = useRef(0)

  if (didCompute.current) return widthRef.current

  // Creating invisible container
  const outer = document.createElement('div')
  outer.style.visibility = 'hidden'
  outer.style.overflow = 'scroll' // forcing scrollbar to appear
  document.body.appendChild(outer)

  // Creating inner element and placing it in the container
  const inner = document.createElement('div')
  outer.appendChild(inner)

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth

  // Removing temporary elements from the DOM
  outer?.parentNode?.removeChild(outer)

  didCompute.current = true
  widthRef.current = scrollbarWidth

  return scrollbarWidth
}

interface BrandingLocaleItem {
  value: string
  label: string
  supported: boolean
}

interface BrandingTabsProps {
  locales: BrandingLocaleItem[]
  onAddLocale: (selectedLocale: string) => void
  disabled?: boolean
  selectedLocalesCount: number
}

interface BrandingTabProps {
  to: string
}

const StyledTabsContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}))

const StyledTabsContainerActions = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
}))

const StyledTabs = styled('ul')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  listStyle: 'none',
  width: '100%',
  overflowX: 'scroll',
  scrollBehavior: 'smooth',
  padding: 0,
  margin: 0,
  '& > li': {
    marginRight: theme.spacing(1),
  },
}))

const StyledTabsOverflow = styled('div')(() => ({
  overflow: 'hidden',
  position: 'relative',
}))

const StyledTab = styled(NavLink)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  color: theme.palette.darkGrey.main,
  fontWeight: theme.typography.fontWeightMedium,
  padding: theme.spacing(1.5),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(0.5),
  cursor: 'pointer',
  textDecoration: 'none',
  borderBottom: `2px solid transparent`,
  whiteSpace: 'nowrap',
  '&:hover': {
    color: theme.palette.text.secondary,
  },
  '&.active': {
    color: theme.palette.accent.dark,
    borderBottom: `2px solid ${theme.palette.accent.main}`,
  },
}))

export const BrandingTabs = ({
  children,
  locales,
  onAddLocale,
  disabled,
  ...rest
}: BrandingTabsProps & MUIStyledCommonProps<Theme> & PropsWithChildren) => {
  const scrollBarSize = useScrollbarWidth()
  const localesRef = useRef<HTMLUListElement>(null)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const handleAddLocale = (selectedLocale: string) => {
    onAddLocale(selectedLocale)
    handleClose()
  }

  const scroll = (direction: 'left' | 'right') => {
    if (!localesRef.current) {
      return
    }

    if (direction === 'left') {
      localesRef.current.scrollLeft -= 100
    } else {
      localesRef.current.scrollLeft += 100
    }
  }

  return (
    <StyledTabsContainer {...rest}>
      <StyledTabsOverflow>
        <StyledTabs
          ref={localesRef}
          sx={{
            mb: `-${scrollBarSize}px`,
          }}
        >
          {children}
        </StyledTabs>
      </StyledTabsOverflow>

      <StyledTabsContainerActions>
        <IconButton onClick={() => scroll('left')}>
          <ChevronLeft fontSize='extra-small' color='primary' />
        </IconButton>

        <IconButton onClick={() => scroll('right')}>
          <ChevronRight fontSize='extra-small' color='primary' />
        </IconButton>

        {!disabled && (
          <IconButton onClick={handleClick}>
            <Add fontSize='extra-small' color='primary' />
          </IconButton>
        )}

        <Menu
          anchorEl={anchorEl}
          id='account-menu'
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {locales.map((locale) => (
            <MenuItem
              key={locale.value}
              onClick={() => handleAddLocale(locale.value)}
              disabled={locale.supported}
            >
              <Box
                component='span'
                sx={(theme) => ({
                  marginLeft: theme.spacing(1),
                  color: theme.palette.text.primary,
                  fontSize: theme.typography.body2.fontSize,
                  marginRight: theme.spacing(2),
                })}
              >
                {locale.label?.split(' (')[0]}
              </Box>
            </MenuItem>
          ))}
        </Menu>
      </StyledTabsContainerActions>
    </StyledTabsContainer>
  )
}

export const BrandingTab = ({ children, to }: BrandingTabProps & PropsWithChildren) => {
  return (
    <StyledTab to={to} replace>
      {children}
    </StyledTab>
  )
}
