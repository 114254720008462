import { useEffect } from 'react'
import { useSnackbar } from 'notistack'

/**
 * When isSuccess is true, shows a snackbar with the provided message.
 */
export const useSnackbarSuccessEffect = (isSuccess: boolean, message: string) => {
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar(message, {
        variant: 'success',
      })
    }
  }, [isSuccess, message, enqueueSnackbar])
}
