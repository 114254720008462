import { PactumLogo } from '@components/PactumLogo'
import styled from '@emotion/styled'
import { ListAltOutlined } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import HelpIcon from '@mui/icons-material/Help'
import { Button, TableBody, useTheme } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { BrandingContentPayload, BrandingPayload } from '../types'

const Container = styled.div`
  display: flex;
  height: 600px;
  border-top: none;
  border-radius: 0 0 8px 8px;
  flex-direction: column;
`
const TabContainer = styled.div`
  display: flex;
  border-bottom: 4px solid rgb(130, 130, 130);
`
const Tab = styled.div`
  display: flex;
  font-size: 10px;
  padding: 4px 8px;
  align-items: center;
  background-color: rgb(130, 130, 130);
  color: rgb(255, 255, 255);
  font-weight: 600;
  border-radius: 8px 8px 0 0;
`

const PactumIcon = styled(PactumLogo)`
  margin-right: 8px;
  height: 12px;
  width: 12px;
`

const CloseIconStyled = styled(CloseIcon)`
  margin-left: 8px;
  height: 12px;
  width: 12px;
`

const HeaderContainer = styled.div`
  box-shadow: rgba(0, 0, 0, 0.04) 0 4px 10px;
  z-index: 1;
  border-width: 0 1px 0 1px;
  border-style: solid;
  border-color: #bfbfbf;
`

const TopPanel = styled.div`
  border-top: 10px solid;
  height: 70px;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
`

const LogoImgWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const LogoImg = styled.img`
  max-height: 25px;
`

const NeedHelpButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap-reverse;
  justify-content: flex-end;
  margin-left: auto;
`
const NeedHelpButton = styled(Button)`
  background-color: rgb(242, 201, 76);
  color: #000;
  font-weight: bold;
  text-transform: none;

  :hover {
    box-shadow: none;
    background-color: rgb(169, 140, 53);
  }
`

const NeedHelpIcon = styled(HelpIcon)`
  color: rgb(159, 120, 0);
  font-size: 18px;
  margin-right: 8px;
`

const ProgressPanel = styled.div`
  display: flex;
  padding: 8px 16px;
  gap: 8px;
  width: 100%;
  float: unset;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  height: 48px;
  justify-content: space-between;
`

const ProgressPanelStep = styled.div`
  color: rgb(130, 130, 130);
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
`

const ProgressPanelSubStep = styled.div`
  color: rgb(38, 38, 38);
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
`

const AgreementButton = styled.div`
  color: rgb(130, 130, 130);
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;

  > span {
    vertical-align: middle;
  }
`
const MainContainer = styled.div`
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #bfbfbf;
`
const InnerContainer = styled.div`
  height: 100%;
  margin: 8px;
  display: flex;
  flex-direction: column;
`

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-bottom: 8px;
`

const AvatarImg = styled.img`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  object-fit: cover;
`
const AvatarLabel = styled.span`
  margin-left: 8px;
  font-weight: 400;
  line-height: 1.66;
  color: rgb(108, 108, 108);
  font-size: 10px;
`

const PhraseContainer = styled.div`
  display: flex;
`

const Phrase = styled.div`
  display: inline-block;
  max-width: 100%;
  margin: 0 0 12px;
  padding: 12px 24px 12px 16px;
  line-height: 1.5;
  text-align: left;
  white-space: pre-wrap;
  box-shadow: rgba(0, 0, 0, 0.04) 0 4px 21px 8px;
  border-radius: 0 9px 9px;
  color: rgb(51, 51, 51);
`

const ReplyContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Reply = styled.div`
  display: inline-block;
  max-width: 100%;
  margin: 0 0 12px;
  padding: 12px 24px 12px 16px;
  line-height: 1.5;
  text-align: left;
  white-space: pre-wrap;
  box-shadow: rgba(0, 0, 0, 0.04) 0 4px 21px 8px;
  border-radius: 9px 0 9px 9px;
  color: rgba(0, 0, 0, 0.87);
`

const TableContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
`

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  box-shadow: rgba(0, 0, 0, 0.04) 0 4px 21px 8px;
`

const HeaderCell = styled.th`
  padding: 8px;
  border-bottom: 1px solid rgb(224, 224, 224);
`

const TableRow = styled.tr`
  outline: 0;
  border-bottom: 1px solid rgb(242, 242, 242);
`

const TableCell = styled.td`
  line-height: 1.43;
  color: rgb(51, 51, 51);
  flex-direction: row-reverse;
  font-family: Inter, sans-serif;
  font-size: 15px;
  text-align: left;
  padding: 8px 8px 12px 16px;
  vertical-align: middle;
`

const ActionContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  gap: 8px;
  padding: 8px 20 0;
`

const ActionButton = styled(Button)`
  min-height: 50px;
  text-transform: none;
  padding: 8px 24px;
  border-radius: 26px;
`

const colorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/

export const BrandingPreview = () => {
  const form = useFormContext<BrandingPayload & BrandingContentPayload>()
  const theme = useTheme()

  const imageToUrl = (newValue: string | File | undefined) => {
    if (!newValue) {
      return undefined
    }
    return typeof newValue === 'string' ? newValue : URL.createObjectURL(newValue as File)
  }

  const branding = form.watch()
  const primaryColor = colorRegex.test(branding.color) ? branding.color : '#000'
  const secondaryColor = colorRegex.test(branding.tableColor) ? branding.tableColor : '#000'
  const logoUrl = branding.logoUrl
    ? branding.logoUrl
    : branding.logo
      ? imageToUrl(branding.logo)
      : undefined
  const botAvatarUrl = branding.botAvatarUrl
    ? branding.botAvatarUrl
    : branding.botAvatar
      ? imageToUrl(branding.botAvatar)
      : undefined
  const botAvatarLabel = branding.botAvatarLabel! || branding.clientName!
  const chatSidebarName = branding.chatSidebarName!
  const shortChatName = branding.shortChatName!

  // as string casts since we check the values from watch() before, but it does not typeguard the values
  return (
    <Container>
      <TabContainer>
        <Tab>
          <PactumIcon /> {shortChatName} <CloseIconStyled />
        </Tab>
      </TabContainer>
      <HeaderContainer>
        <TopPanel style={{ borderTopColor: primaryColor }}>
          <LogoImgWrapper>{logoUrl && <LogoImg src={logoUrl} alt='logo' />}</LogoImgWrapper>
          <NeedHelpButtonWrapper>
            <NeedHelpButton variant='contained'>
              <NeedHelpIcon />
              Need help?
            </NeedHelpButton>
          </NeedHelpButtonWrapper>
        </TopPanel>
        <ProgressPanel>
          <div>
            <ProgressPanelStep>PROGRESS</ProgressPanelStep>
            <ProgressPanelSubStep>1. Introduction</ProgressPanelSubStep>
          </div>
          <AgreementButton>
            <span>AGREEMENT&nbsp;</span>
            <span>
              <ListAltOutlined />
            </span>
          </AgreementButton>
        </ProgressPanel>
      </HeaderContainer>
      <MainContainer>
        <InnerContainer>
          <AvatarContainer>
            {botAvatarUrl && <AvatarImg src={botAvatarUrl} alt='avatar' />}
            <AvatarLabel>{botAvatarLabel}</AvatarLabel>
          </AvatarContainer>
          <PhraseContainer>
            <Phrase>Hello, and welcome to the {chatSidebarName}. How are you today?</Phrase>
          </PhraseContainer>
          <ReplyContainer>
            <Reply
              style={{
                backgroundColor: primaryColor,
                color: theme.palette.getContrastText(primaryColor),
              }}
            >
              I'm doing okay.
            </Reply>
          </ReplyContainer>
          <AvatarContainer>
            {botAvatarUrl && <AvatarImg src={botAvatarUrl} alt='avatar' />}
            <AvatarLabel>{botAvatarLabel}</AvatarLabel>
          </AvatarContainer>
          <PhraseContainer>
            <Phrase>Before we begin our discussion, lets review the terms.</Phrase>
          </PhraseContainer>
          <TableContainer>
            <Table>
              <thead>
                <tr>
                  <HeaderCell
                    style={{
                      backgroundColor: secondaryColor,
                      borderTopLeftRadius: 8,
                    }}
                  />
                  <HeaderCell
                    style={{
                      backgroundColor: secondaryColor,
                      borderTopRightRadius: 8,
                    }}
                  />
                </tr>
              </thead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <strong>Payment Days</strong>
                  </TableCell>
                  <TableCell>30 days</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Discount</strong>
                  </TableCell>
                  <TableCell>3%</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <ActionContainer>
            <ActionButton
              variant='outlined'
              style={{
                color: primaryColor,
                borderColor: primaryColor,
              }}
            >
              Yes, its correct
            </ActionButton>
            <ActionButton
              variant='outlined'
              style={{
                color: primaryColor,
                borderColor: primaryColor,
              }}
            >
              No, it's incorrect
            </ActionButton>
          </ActionContainer>
        </InnerContainer>
      </MainContainer>
    </Container>
  )
}
