import { Loader } from '@googlemaps/js-api-loader'
import { environment } from '../../environments/environment'
import parse from 'autosuggest-highlight/parse'
import { useEffect } from 'react'

const loader = new Loader({
  apiKey: environment.REACT_APP_GOOGLE_PLACES_API_KEY ?? '',
  version: 'weekly',
  libraries: ['places'],
})

const autocompleteService: { current: google.maps.places.AutocompleteService | null } = {
  current: null,
}

const placeService: { current: google.maps.places.PlacesService | null } = {
  current: null,
}

export type Part = {
  text: string
  highlight: boolean
}

export type PlacePrediction = {
  description: string
  secondaryText: string
  highlightedDescription: Part[]
  placeId: string
  types: string[]
}

export type PlaceDetails = {
  description: string
  name?: string
  address?: string
  latitude: number
  longitude: number
  city?: string
  county?: string
  state?: string
  country?: string
  postalCode?: string
}

function toPlacePrediction(result: google.maps.places.AutocompletePrediction): PlacePrediction {
  const matches = result.structured_formatting.main_text_matched_substrings || []

  return {
    description: result.description,
    secondaryText: result.structured_formatting.secondary_text,
    highlightedDescription: parse(
      result.structured_formatting.main_text,
      matches.map((match) => [match.offset, match.offset + match.length]),
    ),
    placeId: result.place_id,
    types: result.types,
  }
}

function extractComponent(
  result: google.maps.places.PlaceResult,
  type: string,
): string | undefined {
  if (!result.address_components) {
    return undefined
  }
  return result.address_components.find((c) => c.types.includes(type))?.short_name
}

function toPlaceDetails(result: google.maps.places.PlaceResult, description: string): PlaceDetails {
  return {
    description: description,
    name: result.name,
    address: result.formatted_address,
    latitude: result.geometry?.location?.lat() ?? 0,
    longitude: result.geometry?.location?.lng() ?? 0,
    city: extractComponent(result, 'locality'),
    county: extractComponent(result, 'administrative_area_level_2'),
    state: extractComponent(result, 'administrative_area_level_1'),
    country: extractComponent(result, 'country'),
    postalCode: extractComponent(result, 'postal_code'),
  }
}

function getPlacePredictions(input: string) {
  return new Promise<PlacePrediction[]>((resolve, reject) => {
    if (autocompleteService.current !== null) {
      autocompleteService.current.getPlacePredictions({ input }, (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(predictions !== null ? predictions.map(toPlacePrediction) : [])
        } else {
          reject(status)
        }
      })
    } else {
      // Resolve with empty in hopes that the library will be loaded soon
      resolve([])
    }
  })
}

function getPlaceDetails(placeId: string, description: string) {
  return new Promise<PlaceDetails | null>((resolve, reject) => {
    if (placeService.current !== null) {
      placeService.current.getDetails(
        {
          placeId: placeId,
          fields: ['name', 'formatted_address', 'address_components', 'geometry', 'plus_code'],
        },
        (result, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            resolve(result !== null ? toPlaceDetails(result, description) : null)
          } else {
            reject(status)
          }
        },
      )
    } else {
      // Resolve with empty in hopes that the library will be loaded soon
      resolve(null)
    }
  })
}

export const useGooglePlaces = () => {
  useEffect(() => {
    if (autocompleteService.current === null || placeService.current === null) {
      loader.importLibrary('places').then((places: google.maps.PlacesLibrary) => {
        autocompleteService.current = new places.AutocompleteService()
        placeService.current = new places.PlacesService(document.createElement('div'))
      })
    }
  }, [])

  return {
    getPlaceDetails,
    getPlacePredictions,
  }
}
