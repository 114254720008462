import { List, styled } from '@mui/material'

export const StyledList = styled(List)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  listStyleType: 'disc',

  ' .MuiListItem-root': {
    padding: `${theme.spacing(0.5)} 0`,
    display: 'list-item',
  },
}))
