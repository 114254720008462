import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useParams } from 'react-router-dom'
import { useLazyGetNegotiationPreviewLinkQuery } from '@logistics/store/contractedLanesApi'
import { isBackendApiErrorResponse } from '@shared/backend/error/typeGuards'
import { useSnackbar } from 'notistack'
import { EnqueueSnackbar } from '@shared/utils/snackbar'

export const useRfpNegotiationPreviewActions = () => {
  const { activeProjectTag } = useActiveProject()
  const { id: rfpId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const [getNegotiationPreviewLink, { isLoading: isPreviewLinkLoading }] =
    useLazyGetNegotiationPreviewLinkQuery()

  const negotiationPreviewLink = async (negotiationId: number): Promise<string | null> => {
    try {
      const { chatLink } = await getNegotiationPreviewLink({
        id: Number(rfpId),
        projectTag: activeProjectTag,
        negotiationId: negotiationId,
      }).unwrap()
      return chatLink
    } catch (error) {
      handleActionError(error, enqueueSnackbar)
      return null
    }
  }

  const handleActionError = (error: unknown, enqueueSnackbar: EnqueueSnackbar) => {
    if (isBackendApiErrorResponse(error)) {
      enqueueSnackbar(error.data.message, { variant: 'error' })
    }
  }

  return {
    negotiationPreviewLink,
    isPreviewLinkLoading,
  }
}
