export const isNumberZeroInclusive = (nr?: number | null): nr is number => typeof nr === 'number'

export const asNumberOrNull = (nr?: number | string | null): number | null => {
  if (nr === undefined || nr === null) {
    return null
  } else if (typeof nr === 'number') {
    return nr
  } else if (typeof nr === 'string') {
    if (nr.length === 0) {
      return null
    } else {
      return Number(nr)
    }
  } else {
    throw Error(`Unexpected type for nr argument: ${typeof nr}`)
  }
}
