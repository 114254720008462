import { PropsWithChildren } from 'react'
import { Box, styled, Typography } from '@mui/material'
import { NoValueCell } from '../NoValueCell/NoValueCell'

type DataCellSize = 'small' | 'medium' | 'large'

export interface DataCellProps extends PropsWithChildren {
  label: string
  footnote?: string
  size?: DataCellSize
}

export const DataCell = ({ children, label, footnote, size = 'small' }: DataCellProps) => {
  return (
    <Box mt={2}>
      <StyledLabel>{label}</StyledLabel>
      <StyledValue variant='body1' size={size}>
        {children ? children : <NoValueCell variant='inherit' />}
      </StyledValue>
      {footnote && <StyledFootnote>{footnote}</StyledFootnote>}
    </Box>
  )
}

const StyledLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.normal,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}))

const StyledFootnote = styled(Typography)(({ theme }) => ({
  ...theme.typography.normal,
  color: theme.palette.text.secondary,
}))

interface StyledValueProps {
  size: DataCellSize
}

const StyledValue = styled(Typography, {
  shouldForwardProp: (prop) =>
    prop !== 'fontSize' && prop !== 'fontWeight' && prop !== 'lineHeight',
})<StyledValueProps>(({ theme, size }) => {
  const sizeTypography = {
    small: theme.typography.normal,
    large: theme.typography.h1,
    medium: theme.typography.h2,
  }[size]

  return {
    ...sizeTypography,
    overflowWrap: 'break-word',
    lineHeight: 1.2,
  }
})
