import React, { useEffect } from 'react'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useSnackbar } from 'notistack'
import { useNegotiationEventsListQuery } from '../../store/price-list'
import { PactumLoader } from '@components/PactumLoader'
import { Alert, Box } from '@mui/material'
import { isBackendApiErrorResponse } from '@shared/backend/error/typeGuards'
import { NegotiationEventsList } from './NegotiationEventsList'
import { WelcomePage } from './WelcomePage/WelcomePage'
import { useSuiteDataGrid } from '@components/SuiteDataGrid/SuiteDataGridProvider'
const LAYOUT_PADDING = 6

export const NegotiationEventsPage = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { activeProjectTag } = useActiveProject()

  const gridState = useSuiteDataGrid({
    debug: true,
    defaultPageSize: 10,
    sort: [{ field: 'createdAt', sort: 'desc' }],
  })

  const {
    data: negotiationEventsResults,
    isLoading,
    isError,
    error,
  } = useNegotiationEventsListQuery({
    projectTag: activeProjectTag,
    page: gridState.pagination.page,
    pageSize: gridState.pagination.pageSize,
  })

  useEffect(() => {
    if (isError && error) {
      enqueueSnackbar(
        isBackendApiErrorResponse(error)
          ? error.data.message
          : 'There was an error fetching negotiation events.',
        { variant: 'error' },
      )
    }
  }, [isError, error, enqueueSnackbar])

  if (isLoading) {
    return <PactumLoader />
  }

  if (!negotiationEventsResults || isError) {
    return (
      <Box px={LAYOUT_PADDING} py={4}>
        <Alert severity='error'>We were unable to load negotiation events, please try again!</Alert>
      </Box>
    )
  }

  if (negotiationEventsResults.events.length > 0) {
    return (
      <NegotiationEventsList
        gridState={gridState}
        rowCount={negotiationEventsResults.total}
        negotiationEvents={negotiationEventsResults.events}
      />
    )
  }

  return <WelcomePage />
}
