import styled from '@emotion/styled'
import { Autocomplete, Box, TextField } from '@mui/material'
import { CurrencyCode, currencyNames } from '@pactum/common'
import { FocusEventHandler } from 'react'

export interface CurrencyPickerProps {
  label?: string
  displayCurrencies?: CurrencyCode[] | null
  popularCurrencies?: CurrencyCode[]
  currencies?: CurrencyCode[]
  selectedCurrency: CurrencyCode | null
  onChange: (value: CurrencyCode | null) => void
  onBlur?: FocusEventHandler<HTMLDivElement> | undefined
  disabled?: boolean
  error?: boolean
  helperText?: string
  disableClearable?: boolean
  required?: boolean
}

interface SelectOption {
  label: string
  value: CurrencyCode
}

const CurrencyName = styled.span`
  font-style: italic;
  margin-left: 16px;
  font-size: 12px;
`

const names = new Map(Object.entries(currencyNames))

export const CurrencyPicker = ({
  label,
  displayCurrencies,
  popularCurrencies,
  currencies,
  selectedCurrency,
  onChange,
  onBlur,
  disabled,
  error,
  helperText,
  disableClearable,
  required = false,
}: CurrencyPickerProps) => {
  const allCurrencies = [
    ...(popularCurrencies ?? []),
    ...(displayCurrencies ?? []),
    ...(currencies === undefined || currencies.length === 0
      ? Object.keys(CurrencyCode).map((c) => c as CurrencyCode) // remap explicitly to CurrencyCode since Object.keys returns string[]
      : currencies),
  ]
  const uniq = [...new Set(allCurrencies)]
  const opts = uniq.map((value: CurrencyCode) => ({
    label: names.get(value),
    value: value as string,
  })) as SelectOption[]

  return (
    <Autocomplete
      disabled={disabled}
      options={opts}
      autoHighlight
      getOptionLabel={(option: SelectOption) => option.value}
      onChange={(_, val) => onChange(val?.value as CurrencyCode)}
      onBlur={onBlur}
      disableClearable={disableClearable}
      value={opts.find((opt) => opt.value === selectedCurrency) ?? null}
      renderOption={(props, option) => (
        <Box component='li' {...props}>
          <span>{option.value}</span>
          <CurrencyName>{option.label}</CurrencyName>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          label={label ?? 'Currency'}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
          error={error}
          helperText={helperText}
        />
      )}
    />
  )
}
