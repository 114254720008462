import { useState } from 'react'
import { GreetingHeader } from '@components/Layout/GreetingHeader'
import { Box } from '@mui/material'
import { useGetConfigurationQuery } from '../../store/purchasing'
import { PrimaryButton } from '@components/Layout/Statistics'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { NegotiationEventFormContextProvider } from '../NegotiationEventForm/NegotiationEventFormContext'
import { ImportCoupaRequisitionFormDialog } from '../ImportCoupaRequisition/ImportCoupaRequisitionFormDialog'
import { ImportCoupaSourcingEventFormDialog } from '@procurement-shared/ImportCoupaSourcingEvent/ImportCoupaSourcingEventFormDialog'
import { labelForProduct } from '../../utils/labelForProduct'
import { ProductType } from '@pactum/core-backend-types'
import { NegotiationEventFormDialog } from '@procurement-shared/NegotiationEventForm/NegotiationEventFormDialog'

export const NegotiationEventsHeader = () => {
  const [showCreateNegotiationEventForm, setShowCreateNegotiationEventForm] = useState(false)
  const [showImportSourcingEventFromCoupaForm, setShowImportSourcingEventFromCoupaForm] =
    useState(false)
  const [showImportRequisitionFromCoupaForm, setShowImportRequisitionFromCoupaForm] =
    useState(false)
  const { activeProjectTag } = useActiveProject()
  const { data: configuration } = useGetConfigurationQuery({
    projectTag: activeProjectTag,
  })

  const showImportRequisitionFromCoupaButton =
    configuration?.data.suite.showImportRequisitionFromCoupaButton
  const showImportSourcingEventFromCoupaButton =
    configuration?.data.suite.showImportSourcingEventFromCoupaButton
  const showAddNegotiationEventButton = configuration?.data.suite.showAddRequisitionButton
  const productType = configuration?.data.suite.productType
  const isContractCost = productType === ProductType.ContractCost

  return (
    <>
      <GreetingHeader>
        <Box sx={{ mt: 1, ml: 'auto' }}>
          {showImportRequisitionFromCoupaButton && (
            <PrimaryButton
              onClick={() =>
                setShowImportRequisitionFromCoupaForm(!showImportRequisitionFromCoupaForm)
              }
            >
              Import Requisition from Coupa
            </PrimaryButton>
          )}
          {isContractCost && showImportSourcingEventFromCoupaButton && (
            <PrimaryButton
              onClick={() =>
                setShowImportSourcingEventFromCoupaForm(!showImportSourcingEventFromCoupaForm)
              }
            >
              Import Sourcing Event from Coupa
            </PrimaryButton>
          )}
          {showAddNegotiationEventButton && (
            <PrimaryButton onClick={() => setShowCreateNegotiationEventForm(true)}>
              {labelForProduct(productType, 'newNegotiationButtonLabel')}
            </PrimaryButton>
          )}
        </Box>
      </GreetingHeader>
      <ImportCoupaRequisitionFormDialog
        open={showImportRequisitionFromCoupaForm}
        onClose={() => setShowImportRequisitionFromCoupaForm(false)}
      />
      <ImportCoupaSourcingEventFormDialog
        open={showImportSourcingEventFromCoupaForm}
        onClose={() => setShowImportSourcingEventFromCoupaForm(false)}
      />
      <NegotiationEventFormContextProvider>
        <NegotiationEventFormDialog
          open={showCreateNegotiationEventForm}
          onClose={() => setShowCreateNegotiationEventForm(false)}
        />
      </NegotiationEventFormContextProvider>
    </>
  )
}
