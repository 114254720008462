import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { GridFilterInputValueProps } from '@mui/x-data-grid-pro'
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'
import React, { useState } from 'react'

interface Props extends GridFilterInputValueProps<GridApiPro> {
  availableOptions: string[]
  defaultOption?: string
}

export const DropdownFilterSelect = ({
  item,
  applyValue,
  apiRef,
  availableOptions,
  defaultOption,
}: Props) => {
  const [selectedValue, setSelectedValue] = useState<string>(item.value ?? defaultOption)

  const columnDefinition = apiRef.current.getColumn(item.field)

  const handleChange = (e: SelectChangeEvent) => {
    applyValue({ ...item, value: e.target.value as string })
    setSelectedValue(e.target.value as string)
  }

  return (
    <DropdownFilterContainer>
      <FormControl fullWidth>
        <InputLabel id='dropdown-filter-select-label'>
          {columnDefinition?.headerName ?? 'Options'}
        </InputLabel>
        <Select
          labelId='dropdown-filter-select-label'
          value={selectedValue}
          label={columnDefinition?.headerName ?? 'Options'}
          onChange={handleChange}
        >
          {availableOptions.map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </DropdownFilterContainer>
  )
}

interface ContainerProps {
  children: React.ReactNode
}

const DropdownFilterContainer = ({ children }: ContainerProps) => (
  <Box
    sx={{
      display: 'inline-flex',
      flexDirection: 'column',
      justifyContent: 'end',
      height: 1,
      pl: 2,
    }}
  >
    {children}
  </Box>
)
