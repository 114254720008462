enum Currency {
  EUR = 'EUR',
  USD = 'USD',
  CAD = 'CAD',
  KRW = 'KRW',
  MXN = 'MXN',
  JPY = 'JPY',
  GBP = 'GBP',
  VND = 'VND',
  BRL = 'BRL',
  CLP = 'CLP',
  PLN = 'PLN',
  DKK = 'DKK',
  PHP = 'PHP',
  CHF = 'CHF',
  HUF = 'HUF',
  CZK = 'CZK',
  IDR = 'IDR',
  SGD = 'SGD',
  THB = 'THB',
  OMR = 'OMR',
  AUD = 'AUD',
  TRY = 'TRY',
  UAH = 'UAH',
  EGP = 'EGP',
  ZAR = 'ZAR',
}

export { Currency }
