import { Grid, Typography } from '@mui/material'
import { LineItemsTable } from './LineItemsTable'
import { FormRow } from '../../../FormCommon/FormCommon'

export const SupplierLineItems = () => (
  <>
    <FormRow>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Typography variant='subtitle2' fontSize='0.875rem'>
          Line item negotiation
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <LineItemsTable />
      </Grid>
    </FormRow>
  </>
)
