import { NegotiationEvent, NegotiationEventSupplier } from '@procurement/store/types'
import { Formatter } from '@utils'
import { CaptionedValueCell, NoValueCell } from '@components/table'
import { formatDeliveryDate, formatPaymentTerms } from '@procurement/utils/formatting'
import { GridRenderCellParams, GridRowClassNameParams } from '@mui/x-data-grid-pro'
import {
  getNegotiatedAmountFromNegotiation,
  getNegotiatedPaymentTermsFromNegotiation,
  getSavingsAmountFromNegotiation,
} from '@procurement/utils/negotiationEventGetters'
import { formatCurrency } from '@shared/utils/formatCurrency'
import { Typography } from '@mui/material'
import { getSupplierActions } from '@procurement/pages/NegotiationEventDetails/SuppliersList/SupplierActions'
import { SupplierListActions } from '@procurement/pages/NegotiationEventDetails/SuppliersList/SupplierListActions'

export const renderDeliveryDateCell = (
  supplier: NegotiationEventSupplier,
  originalDeliveryDate: string | null | undefined,
  formatter: Formatter,
) => {
  const deliveryDate = supplier.negotiation?.outcomes?.deliveryDate ?? originalDeliveryDate

  if (!deliveryDate) {
    return <NoValueCell />
  }

  return formatDeliveryDate(deliveryDate, formatter)
}

export const renderPaymentTermsCell = (
  params: GridRenderCellParams,
  currency: string,
  formatter: Formatter,
) => {
  const { negotiation, isWinner } = params.row as NegotiationEventSupplier
  const negotiatedPaymentTerms = getNegotiatedPaymentTermsFromNegotiation(negotiation)
  if (
    negotiation === null ||
    (negotiatedPaymentTerms.paymentDaysObject === null &&
      negotiatedPaymentTerms.discountDaysObject === null &&
      negotiatedPaymentTerms.discount === null)
  ) {
    return <NoValueCell />
  }

  const workingCapitalSavings = negotiation?.outcomes?.savingsBreakdown?.workingCapital

  const formattedValue = formatPaymentTerms(negotiatedPaymentTerms)
  const caption = savingsCaption(workingCapitalSavings, currency, formatter)
  const color = isWinner ? 'success.main' : undefined

  return (
    <CaptionedValueCell
      mainContent={formattedValue}
      captionContent={caption}
      mainContentColor={color}
      fontWeight={isWinner ? 'bold' : undefined}
    />
  )
}

export const renderTotalCostCell = (
  params: GridRenderCellParams,
  currency: string,
  formatter: Formatter,
) => {
  const { negotiation, isWinner } = params.row as NegotiationEventSupplier
  if (negotiation === null) {
    return <NoValueCell />
  }

  const negotiatedAmount = getNegotiatedAmountFromNegotiation(negotiation)
  if (negotiatedAmount === 0) {
    return <NoValueCell />
  }

  const priceSavings = negotiation?.outcomes?.savingsBreakdown?.price

  const formattedValue = formatCurrency(negotiatedAmount, currency, formatter)
  const caption = savingsCaption(priceSavings, currency, formatter)
  const color = isWinner ? 'success.main' : undefined

  return (
    <CaptionedValueCell
      mainContent={formattedValue}
      captionContent={caption}
      mainContentColor={color}
      fontWeight={isWinner ? 'bold' : undefined}
    />
  )
}

export const renderSavingsCell = (
  params: GridRenderCellParams,
  currency: string,
  formatter: Formatter,
) => {
  const { negotiation } = params.row as NegotiationEventSupplier
  if (negotiation === null) {
    return <NoValueCell />
  }

  const savingsAmount = getSavingsAmountFromNegotiation(negotiation)
  if (savingsAmount === null) {
    return <NoValueCell />
  }

  return formatCurrency(savingsAmount, currency, formatter)
}

export const renderSupplierCell = (params: GridRenderCellParams) => {
  const { name, isBaseline, isWinner } = params.row as NegotiationEventSupplier
  const caption = isBaseline ? 'Baseline' : undefined
  const color = isWinner ? 'success.main' : undefined

  return (
    <CaptionedValueCell
      mainContent={name}
      captionContent={caption}
      mainContentColor={color}
      fontWeight={isWinner ? 'bold' : undefined}
    />
  )
}

export const renderActionsCell = (
  params: GridRenderCellParams,
  negotiationEvent: NegotiationEvent,
) => {
  const supplier = params.row as NegotiationEventSupplier

  if (negotiationEvent.negotiationsStartedAt && !negotiationEvent.negotiationsClosedAt) {
    if (supplier.isEnabledForNegotiation) {
      return (
        <Typography component='span' variant='normal'>
          Awaiting results...
        </Typography>
      )
    } else {
      return (
        <Typography component='span' variant='normal'>
          Disabled
        </Typography>
      )
    }
  }

  const actions = getSupplierActions(negotiationEvent, supplier)

  if (actions.length === 0) {
    if (supplier.isWinner) {
      return (
        <Typography component='span' variant='normal' fontWeight={700}>
          Winner
        </Typography>
      )
    } else {
      return <NoValueCell />
    }
  }

  return (
    <SupplierListActions
      actions={actions}
      negotiationEvent={negotiationEvent}
      supplier={supplier}
    />
  )
}

export const setRowClassName = (params: GridRowClassNameParams<NegotiationEventSupplier>) => {
  if (!params.row.isEnabledForNegotiation) {
    return 'disabled-row'
  }
  return params.row.isWinner ? 'winner-row' : ''
}

export const savingsCaption = (
  value: number | undefined,
  currency: string,
  formatter: Formatter,
): string | undefined => {
  if (value) {
    const prefix = value > 0 ? '+' : ''
    return prefix + formatCurrency(value, currency, formatter) + ' savings'
  }

  return undefined
}
