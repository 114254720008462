import React from 'react'
import { Chip, ChipProps, styled } from '@mui/material'

interface OwnProps {
  maincolor: string | null
}

export const ColorChip = (props: OwnProps & ChipProps): JSX.Element => {
  return <StyledChip {...props} />
}

const StyledChip = styled(Chip)((props: OwnProps & ChipProps) => ({
  color: `${props.maincolor ? 'white' : 'unset'}`,
  backgroundColor: `${props.maincolor}`,
  '&:hover': {
    backgroundColor: props.maincolor,
    filter: 'brightness(120%)',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: props.maincolor,
    borderColor: props.maincolor,
  },
}))
