import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { environment } from 'src/environments/environment'
import { getAccessToken } from 'src/main/hooks/auth/useAuthInternals'

const getApiV1HostUrl = (): string => {
  if (environment.REACT_APP_ENV === 'development' || !environment.REACT_APP_ENV) {
    return environment.REACT_APP_SERVER_BASE_URL
  }

  return `${window.location.protocol}//${window.location.host}/api/v1`
}

export const merchandisingBaseApi = createApi({
  reducerPath: 'merchandising-api',
  baseQuery: fetchBaseQuery({
    baseUrl: getApiV1HostUrl(),
    prepareHeaders: async (headers) => {
      const token = await getAccessToken()
      headers.set('Authorization', `Bearer ${token}`)

      return headers
    },
  }),
  endpoints: () => ({}),
})
