import {
  BENEFIT_NAMES,
  BenefitName,
  CommercialId,
  ScenarioState,
} from '@common/types/scenario/ScenarioState'
import {
  BSRFilters,
  FilterData,
} from 'src/merchandising/pages/Scenarios/ScenarioPreparation/ScenarioDetails/components/Filters'
import { Filters } from '../../components/Filters'
import { sumBy, sumNumbers } from '@common/utils'
import { ChartData } from './types'
import {
  calculateSpendAndBenefitsAggregations,
  getSpendInvoiceSum,
  optionalFilterValueMatchesIndexValue,
} from './commonDataUtils'
import { isBenefitToSpendRatioAggregation } from '../../components/Filters/utils'
import { isNumberZeroInclusive } from '@utils'

interface GetCurrentChartDataParameters {
  current: ScenarioState['current']['metrics']
  filters: BSRFilters
}

export const getCurrentChartData = ({
  current,
  filters,
}: GetCurrentChartDataParameters): ChartData => {
  const chartData: ChartData = []

  if (Filters.isFutureTimeRangeSelected(filters)) {
    chartData.push(...calculateFutureBenefits(current, filters))
  }

  return chartData
}

const calculateBenefitToSpendRatioAggregations = (
  benefitSpends: [BenefitName, number][],
  spendInvoiceSum: number,
) => {
  const aggregationValues = benefitSpends.reduce(
    (acc, [benefitName, benefitSpendSum]) => ({
      ...acc,
      [benefitName]: benefitSpendSum / spendInvoiceSum,
    }),
    {} as ChartData[0],
  )

  return {
    Total: sumNumbers(Object.values(aggregationValues)),
    ...aggregationValues,
  }
}

const calculateFutureBenefitAggregations = (
  allItemMetrics: GetCurrentChartDataParameters['current']['items'][CommercialId][],
  filters: BSRFilters,
): ChartData[0] => {
  const benefitSpends: [BenefitName, number][] = getFutureBenefitSpendSumsByName(
    allItemMetrics,
    filters.outcome!,
  )

  const spendInvoiceSum = getSpendInvoiceSum(allItemMetrics, filters.outcome)

  const chartSpecificAggregation = isBenefitToSpendRatioAggregation(filters)
    ? calculateBenefitToSpendRatioAggregations(benefitSpends, spendInvoiceSum)
    : calculateSpendAndBenefitsAggregations(benefitSpends, spendInvoiceSum)

  return { ...chartSpecificAggregation, x: 0 }
}

const getFutureBenefitSpendSumsByName = (
  allItemMetrics: GetCurrentChartDataParameters['current']['items'][CommercialId][],
  selectedOutcome: Required<FilterData>['outcome'],
): [BenefitName, number][] => {
  return BENEFIT_NAMES.map((benefitName) => [
    benefitName,
    allItemMetrics
      .flatMap((itemMetrics) => itemMetrics.spend_benefits)
      .filter((spendBenefit) => spendBenefit.name === benefitName)
      .flatMap((spendBenefit) => spendBenefit.benefit_metrics.values)
      .filter((benefitSpendValues) => benefitSpendValues.index === Number(selectedOutcome.value))
      .reduce(
        sumBy((valueRecord) => valueRecord.value ?? 0),
        0,
      ),
  ])
}

const calculateFutureBenefits = (
  currentMetrics: ScenarioState['current']['metrics'],
  filters: BSRFilters,
): ChartData => {
  const chartData: ChartData = []

  if (!filters.outcome) {
    return chartData
  }

  const selectedItemIds = Filters.getSingleSelectionValues(filters.items)
  const selectedItemMetrics = selectedItemIds
    .map((selectedItemId) => currentMetrics.items[selectedItemId])
    .filter(Boolean)

  if (selectedItemIds.length > 1) {
    chartData.push(calculateFutureBenefitAggregations(selectedItemMetrics, filters))
  } else if (selectedItemIds.length === 1) {
    const singleItemMetrics = selectedItemMetrics.find(Boolean)
    const singleItemBsr = singleItemMetrics
      ? getFutureSingleItemBenefits(singleItemMetrics, filters)
      : {}
    chartData.push({ x: 0, ...singleItemBsr })
  }

  if (Filters.isItemAggregationSelected(filters)) {
    const allSingleMarketItemMetrics = Object.values(currentMetrics.items)
    chartData.push(calculateFutureBenefitAggregations(allSingleMarketItemMetrics, filters))
  }

  return chartData
}

const getFutureSingleItemBenefits = (
  itemMetrics: GetCurrentChartDataParameters['current']['items'][CommercialId],
  filters: BSRFilters,
) => {
  if (isBenefitToSpendRatioAggregation(filters)) {
    const itemBenefits = itemMetrics.bsr.reduce(
      (bsrByBenefitName, benefitMetrics) => {
        const benefitBsrValue = benefitMetrics.benefit_metrics.values.find(
          optionalFilterValueMatchesIndexValue(filters.outcome),
        )?.value
        if (isNumberZeroInclusive(benefitBsrValue)) {
          bsrByBenefitName[benefitMetrics.name] = benefitBsrValue
        }
        return bsrByBenefitName
      },
      {} as Record<BenefitName, number>,
    )

    return {
      Total: sumNumbers(Object.values(itemBenefits)),
      ...itemBenefits,
    }
  }
  return calculateFutureBenefitAggregations([itemMetrics], filters)
}
