import { Grid, Typography } from '@mui/material'
import { createGetFieldControllerParams, FormRow, HideableBlock } from '../../FormCommon/FormCommon'
import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { AutocompleteFieldCtrl, CheckboxCtrl } from '@components/Form'
import { NegotiationEventFormContext } from '../NegotiationEventFormContext'
import { NegotiationEventFormData } from '../schema'

export const Warranty = () => {
  const { watch } = useFormContext<NegotiationEventFormData>()
  const { requiredFields, visibleFields, disabledFields } = useContext(NegotiationEventFormContext)
  const getFieldControllerParams = createGetFieldControllerParams({
    requiredFields,
    disabledFields,
  })
  const warrantyChecked = watch('warrantyEnabled')

  return (
    <HideableBlock hidden={!visibleFields.includes('warrantyEnabled')}>
      <FormRow>
        <Grid item xs={12}>
          <CheckboxCtrl
            labelProps={{ fontWeight: 700 }}
            {...getFieldControllerParams('warrantyEnabled', 'Warranty')}
          />
        </Grid>
        <Grid item xs={12} pb={1} mt={-1}>
          <Typography variant='body2'>
            If this section is enabled, warranties are negotiated once for all the items at the same
            time.
          </Typography>
          <Typography variant='body2'>
            Setting the minimum warranty period does not let the negotiations to be agreed upon
            unless it is met.
          </Typography>
        </Grid>
      </FormRow>
      <HideableBlock hidden={!warrantyChecked}>
        <FormRow>
          <Grid item xs={4}>
            <AutocompleteFieldCtrl
              freeSolo={false}
              options={[0, ...warrantyLengthOptions]}
              getOptionLabel={(option) => (!option ? 'No warranty' : `${option} months`)}
              variant='outlined'
              valueAsNumber
              helperText={'Previously agreed warranty'}
              fullWidth
              label={'Current warranty'}
              name={'suppliers.0.negotiationSettings.warranty.benchmark'}
            />
          </Grid>
          <Grid item xs={4}>
            <AutocompleteFieldCtrl
              freeSolo={false}
              options={[0, ...warrantyLengthOptions]}
              getOptionLabel={(option) => (!option ? 'No warranty' : `${option} months`)}
              variant='outlined'
              valueAsNumber
              helperText={'Leave empty for no warranty'} // TODO: but this required on backend side!
              fullWidth
              label={'Minimum acceptable warranty'}
              name={'suppliers.0.negotiationSettings.warranty.minLength'}
              defaultValue={0}
            />
          </Grid>
          <Grid item xs={4}>
            <AutocompleteFieldCtrl
              freeSolo={false}
              options={warrantyLengthOptions}
              getOptionLabel={(option) => `${option} months`}
              variant='outlined'
              valueAsNumber
              fullWidth
              label={'Desired warranty'}
              helperText={
                'Desired warranty can not be guaranteed and it will depend on negotiation results'
              }
              name={'suppliers.0.negotiationSettings.warranty.maxLength'}
            />
          </Grid>
        </FormRow>
      </HideableBlock>
    </HideableBlock>
  )
}

const MAX_WARRANTY_MONTHS = 60
const warrantyLengthOptions = [...Array(MAX_WARRANTY_MONTHS).keys()].map((key) => key + 1)
