import { alpha, Paper, styled, Typography, Box, Stack } from '@mui/material'
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded'
import {
  SingleFileUpload,
  SingleFileUploadProps,
} from '@components/SingleFileUpload/SingleFileUpload'

export const NegotiationsFileUpload = (props: SingleFileUploadProps) => (
  <StyledBackground elevation={0}>
    <StyledFileUpload {...props} customDropAreaContent={CustomDropAreaContent}>
      <Box display='flex' flexDirection='column' width={'100%'}>
        <UploadFileRoundedIcon fontSize='large' sx={{ width: '100%', mt: 1.5, mb: 0.5 }} />
        <Typography fontSize='medium' fontWeight='bold' sx={{ width: '100%' }}>
          Drag the filled in template here
        </Typography>
        <Box
          display='flex'
          flexDirection='row'
          width={'100%'}
          justifyContent='center'
          alignItems='center'
        >
          <Typography fontSize='small' sx={{ mb: 1.5 }}>
            or&nbsp;
          </Typography>
          <Typography fontSize='small' sx={{ mb: 1.5, color: 'link.main' }}>
            select from your computer
          </Typography>
        </Box>
      </Box>
    </StyledFileUpload>
  </StyledBackground>
)

const StyledFileUpload = styled(SingleFileUpload)(({ theme }) => ({
  background: 'transparent',
  height: '208px',
  border: `1px dashed ${theme.palette.grey.A400}`,
  cursor: 'pointer',
}))

const StyledBackground = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(0.5),
  background: alpha(theme.palette.borders.main, 0.3),
}))

export const CustomDropAreaContent = styled(Stack)({
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  height: '100%',
})
