import React from 'react'
import { NegotiationStatus } from '../../store/types'
import { NEGOTIATION_EVENT_STATUS_CONFIG, StatusConfig } from '../../constants'
import { Chip, ChipProps } from '@mui/material'

interface Props {
  size?: ChipProps['size']
  variant?: 'default' | 'inverted'
}

const determineNegotiationStatusLabel = (status: NegotiationStatus): StatusConfig =>
  status ? NEGOTIATION_EVENT_STATUS_CONFIG[status] : { label: 'Imported', color: 'default' }

export const NegotiationEventStatusChip = ({
  status,
  size = 'small',
  variant = 'default',
}: Props & {
  status: NegotiationStatus
}) => {
  const statusConfig: StatusConfig = determineNegotiationStatusLabel(status)

  return <Chip {...getProps({ statusConfig, size, variant })} />
}

const getProps = ({ statusConfig, size, variant }: Props & { statusConfig: StatusConfig }) => {
  const { color } = statusConfig
  const props = {
    sx: getStyleOverrides(variant, color),
    size,
    color,
    component: 'span',
  }

  return {
    ...statusConfig,
    ...props,
  }
}

const getStyleOverrides = (variant: Props['variant'], color: ChipProps['color']) => {
  if (variant === 'inverted' && color === 'default') {
    return {
      color: 'white',
      backgroundColor: 'transparent',
      borderColor: 'white',
    }
  }

  return {}
}
