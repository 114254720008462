import { NegotiationEventListItem, PaymentTerms, NegotiationStatus, UseCase } from '../store/types'
import { TooltipCell } from '@components/TooltipCell'
import { Formatter } from '@utils'
import { formatDate, formatPaymentTerms } from './formatting'
import { NoValueCell } from '@components/table'

export const formatSupplierName = (row: NegotiationEventListItem) => {
  const { title, tooltip } = getSupplierData(row)

  return <TooltipCell tooltip={tooltip}>{title}</TooltipCell>
}

export const formatDeadline = (
  negotiationsExpireTime: string,
  negotiationsExpireDays: number | null,
  formatter: Formatter,
) => {
  if (negotiationsExpireTime) {
    return formatDate(negotiationsExpireTime, formatter)
  }

  return `${negotiationsExpireDays} business ${negotiationsExpireDays! > 1 ? 'days' : 'day'}`
}

export const formatInitialAmount = (
  amount: number | null,
  currency: string,
  status: NegotiationStatus,
  formatter: Formatter,
  useCase?: UseCase,
) => {
  return tryFormatPostBidEmptyValue(status, useCase ?? null, () => {
    if (amount === null) {
      return <NoValueCell />
    }
    return formatter.currency(amount, { currency })
  })
}

export const formatCurrentTerms = (
  row: NegotiationEventListItem,
  status: NegotiationStatus,
  useCase?: UseCase,
) => {
  return tryFormatPostBidEmptyValue(status, useCase ?? null, () =>
    formatPaymentTerms(
      row.negotiatedPaymentTerms ?? row.initialPaymentTerms ?? ({} as PaymentTerms),
    ),
  )
}

export const tryFormatPostBidEmptyValue = (
  status: NegotiationStatus,
  useCase: UseCase | null,
  otherwise: () => JSX.Element | string | null,
) => {
  if (useCase === 'integratedPostBid' && status) {
    const pendingStatus = status === 'READY' || status === 'WITHDRAWN' || status === 'IN_PROGRESS'
    const winnerNotSelectedStatus = status === 'EXPIRED' || status === 'DECLINED'

    if (pendingStatus) {
      return 'Pending'
    }

    if (winnerNotSelectedStatus) {
      return <NoValueCell />
    }
  }

  return otherwise()
}

export const getSupplierData = ({ suppliers }: NegotiationEventListItem) => {
  return {
    title: suppliers.length === 1 ? suppliers[0].name : `${suppliers.length} suppliers`,
    tooltip: (
      <>
        {suppliers.map((supplier) => (
          <div>{supplier.name}</div>
        ))}
      </>
    ),
  }
}
