import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useGetConfigurationQuery } from '@procurement/store/purchasing'
import { Grid } from '@mui/material'
import { SupplierContactInformation } from '@procurement/components/NegotiationEventSupplierForm/SupplierContactInformation'
import { SupplierLineItems } from '@procurement/components/NegotiationEventSupplierForm/sections/LineItems/SupplierLineItems'
import { TotalPriceTarget } from '@procurement/components/NegotiationEventSupplierForm/sections/TotalPriceTarget'
import { PaymentDays } from '@procurement/components/NegotiationEventSupplierForm/sections/PaymentDays'
import { Incentives } from '@procurement/components/NegotiationEventSupplierForm/sections/Incentives'
import { Attachments } from '@procurement/components/FormSections/Attachments'

export const PostBidNegotiationEventSupplierForm = () => {
  const { activeProjectTag } = useActiveProject()
  const { data: configuration } = useGetConfigurationQuery({ projectTag: activeProjectTag })

  const localeOptions = configuration?.data.suite?.localeOptions ?? []
  const incentives = configuration?.data.suite.incentivesOptions ?? []
  const paymentDays = configuration?.data.suite.paymentDaysOptions ?? []

  return (
    <Grid container spacing={2}>
      <SupplierContactInformation showPhoneField={true} localeOptions={localeOptions} />
      <SupplierLineItems />
      <TotalPriceTarget />
      <PaymentDays paymentDays={paymentDays} />
      <Incentives incentives={incentives} />
      <Attachments description='You can attach files for the supplier to use as background material, such as quotes and SOWs. They will be shown at the start of the negotiation for them to download.' />
    </Grid>
  )
}
