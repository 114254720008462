import React, { SyntheticEvent, useState } from 'react'
import {
  Box,
  IconButton,
  Stack,
  styled,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { useController } from 'react-hook-form'
import { errorProps, styleProps } from '@components/Form/sharedProps'

type DataCellSize = 'small' | 'medium' | 'large'

type EditableDataCellProps = TextFieldProps & {
  label: string
  name: string
  footnote?: string
  size?: DataCellSize
  onBlur?: (event: SyntheticEvent) => void
}

export const DataCellCtrl = ({
  label,
  footnote,
  name,
  size = 'small',
  required,
  onBlur,
  ...rest
}: EditableDataCellProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name })

  const [editMode, setEditMode] = useState(false)

  function handleOnBlur(event: SyntheticEvent) {
    setEditMode(false)
    field.onBlur()
    if (onBlur) {
      onBlur(event)
    }
  }

  return (
    <Box mt={2}>
      <StyledLabel>{label}</StyledLabel>
      <Stack direction='row' alignItems='center'>
        {!editMode && (
          <>
            <StyledValue onClick={() => setEditMode(true)} variant='body1' size={size}>
              {field.value}
            </StyledValue>
            <Box ml={0.5}>
              <IconButton onClick={() => setEditMode(true)} color='primary'>
                <EditIcon sx={{ fontSize: '0.75rem' }} />
              </IconButton>
            </Box>
          </>
        )}
        {editMode && (
          <>
            <TextField
              autoFocus
              size='medium'
              fullWidth
              {...styleProps}
              required={required}
              name={field.name}
              value={(field.value as string) ?? ''}
              onChange={field.onChange}
              onBlur={handleOnBlur}
              inputRef={field.ref}
              {...rest}
              {...errorProps(error)}
            />
          </>
        )}
      </Stack>
      {footnote && <StyledFootnote>{footnote}</StyledFootnote>}
    </Box>
  )
}

const StyledLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.normal,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}))

const StyledFootnote = styled(Typography)(({ theme }) => ({
  ...theme.typography.normal,
  color: theme.palette.text.secondary,
}))

interface StyledValueProps {
  size: DataCellSize
}

const StyledValue = styled(Typography, {
  shouldForwardProp: (prop) =>
    prop !== 'fontSize' && prop !== 'fontWeight' && prop !== 'lineHeight',
})<StyledValueProps>(({ theme, size }) => {
  const sizeTypography = {
    small: theme.typography.normal,
    large: theme.typography.h1,
    medium: theme.typography.h2,
  }[size]

  return {
    ...sizeTypography,
    cursor: 'pointer',
    overflowWrap: 'break-word',
    lineHeight: 1.2,
  }
})
