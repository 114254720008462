import { CustomPaymentDaysConfiguration, CustomPaymentDaysType } from './types'

export type EOMPaymentDaysValue = {
  daysUntilEom: number | null
  daysAfterEom: number | null
}

const parseEomString = (value: string) => {
  if (!value.includes('EOM')) {
    return []
  }

  return value
    .split('EOM')
    .map((days, idx) => ({ days: days.trim(), idx }))
    .filter(({ days }) => days !== '')
    .map((obj) => ({ ...obj, days: Number(obj.days) }))
}

export const EOM_TEMPLATE_FIELD_TYPE = 'EOM'

export const eomXPaymentDaysConfig: CustomPaymentDaysConfiguration<EOMPaymentDaysValue> = {
  type: CustomPaymentDaysType.EOMX,
  template: {
    fieldDefinition: {
      name: 'eomPaymentDays',
      type: EOM_TEMPLATE_FIELD_TYPE,
      headerLabel: 'Current payment terms (EOM)',
      optional: false,
      optionalDuringImport: true,
      paymentDaysField: true,
      exampleValue: '30 EOM5',
    },

    validator: (value: string) => {
      const tokens = parseEomString(value)

      if (tokens.length !== 1 && tokens.length !== 2) {
        return 'Invalid format for EOM payment days'
      }

      if (!tokens.every(({ days }) => Number.isInteger(days))) {
        return 'EOM values need to be integers'
      }

      return null
    },

    parser: (value: string) => {
      const tokens = parseEomString(value)

      const daysUntilEom = tokens.find(({ idx }) => idx === 0)?.days ?? null
      const daysAfterEom = tokens.find(({ idx }) => idx === 1)?.days ?? null

      return { daysUntilEom, daysAfterEom }
    },
    valuePresent: ({ daysAfterEom, daysUntilEom }) =>
      daysAfterEom !== null || daysUntilEom !== null,
  },

  rendering: {
    isRendererApplicable: (terms, stage) => {
      return !!terms.eomPaymentDays?.[stage]
    },
    renderShortForm: (eomPaymentDays) => {
      if (!eomPaymentDays) {
        return null
      }
      const { daysUntilEom, daysAfterEom } = eomPaymentDays

      return `${daysUntilEom !== null ? `${daysUntilEom} ` : ''}EOM${daysAfterEom ?? ''}`.trim()
    },
    render: (terms, stage) => {
      const eom = terms.eomPaymentDays?.[stage]
      if (!eom) {
        return 'N/A'
      }

      const earlyPaymentDiscount = terms.earlyPaymentDiscount?.[stage] ?? 0
      const earlyPaymentDiscountDays = terms.earlyPaymentDiscountDays?.[stage] ?? 0

      const discountString =
        earlyPaymentDiscount > 0 ? `${earlyPaymentDiscount}/${earlyPaymentDiscountDays} ` : ''

      const eomString =
        `${eom.daysUntilEom ? `Net ${eom.daysUntilEom}` : ''} EOM${eom.daysAfterEom ?? ''}`.trim()

      return `${discountString}${eomString}`
    },
  },
}
