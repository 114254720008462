import { useGetUserOrganizationsQuery } from 'src/main/store/userOrganizations'
import { useMemo } from 'react'
import { useProjects } from '@hooks'
import { captureException } from '@sentry/react'
import { useParams } from 'react-router-dom'

export const useUserPermissions = () => {
  const { orgTag: activeOrgTag, projectTag: activeProjectTag } = useParams()
  const { data: organizations, isLoading } = useGetUserOrganizationsQuery(undefined)
  const { projects } = useProjects()
  const projectFromTag = projects.find((p) => p.tag === activeProjectTag)
  const organizationFromTag = organizations?.find(
    (org) => org.tag.toLowerCase() === activeOrgTag?.toLowerCase(),
  )

  if (projectFromTag && organizationFromTag) {
    if (projectFromTag.organizationId !== organizationFromTag.id) {
      const errorMessage = `Invalid state: project ${projectFromTag.tag} is not under organization ${organizationFromTag.tag}`
      console.error(errorMessage)
      captureException(new Error(errorMessage))
    }
  }

  const userPermissionsInActiveOrg = useMemo(() => {
    if (organizationFromTag) {
      return new Set(organizationFromTag.permissions)
    } else {
      return new Set()
    }
  }, [organizationFromTag])

  const userPermissionsInAllOrgs = useMemo(() => {
    if (organizations) {
      return new Set(organizations.flatMap(({ permissions }) => permissions))
    } else {
      return new Set()
    }
  }, [organizations])

  return { userPermissionsInActiveOrg, userPermissionsInAllOrgs, isLoading }
}
