import { useContext } from 'react'
import { Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { ProductType } from '@pactum/core-backend-types'
import { CONTRACT_MODELS } from '@procurement/utils/contractModel'
import {
  FormRow,
  FormSectionTitle,
  HideableBlock,
} from '@procurement/components/FormCommon/FormCommon'
import { NegotiationEventFormContext } from '../../NegotiationEventFormContext'
import { NegotiationEventFormData } from '../../schema'
import { ContractModelSelector } from './ContractModelSelector'
import { ContractModelFields } from './ContractModelFields'

export type ContractModelOption = {
  id: string
  title: string
  description: string
  properties: string[]
  otherRequiredFields: string[]
}

interface Props {
  productType?: ProductType
  defaultContractModelId?: string
}

export const ContractModel = ({ productType, defaultContractModelId }: Props) => {
  const { watch } = useFormContext<NegotiationEventFormData>()
  const contractModelId = watch('contractModel.id')
  const { visibleFields } = useContext(NegotiationEventFormContext)
  const selectedContract = CONTRACT_MODELS.find(
    (contract) => contract.id === (contractModelId ?? defaultContractModelId),
  )

  return (
    <HideableBlock hidden={!visibleFields.includes('contractModelOptions')}>
      <ContractModelSelector defaultContractModelId={defaultContractModelId} />
      <HideableBlock hidden={!selectedContract || selectedContract?.properties?.length === 0}>
        <FormSectionTitle>Buyer information</FormSectionTitle>
        <FormRow>
          <Grid item xs={12}>
            <ContractModelFields productType={productType} />
          </Grid>
        </FormRow>
      </HideableBlock>
    </HideableBlock>
  )
}
