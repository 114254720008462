import { useController } from 'react-hook-form'
import { DraggableFormList, ListFormItem } from './DraggableFormList'

interface DraggableFormListCtrlProps {
  name: string
  disabled?: boolean
  selected?: ListFormItem | null
  onSelect?: (item: ListFormItem | null) => void

  itemTransformer: (item: unknown) => ListFormItem
}

export const DraggableFormListCtrl = (props: DraggableFormListCtrlProps) => {
  const { field } = useController({ name: props.name })

  const value = field.value ? (field.value as unknown[]) : []
  const items = value.map(props.itemTransformer)

  return (
    <DraggableFormList
      {...props}
      selected={props.selected ?? items[0] ?? null}
      onChange={(items) => field.onChange(items.map((item) => item.value))}
      onSelect={(item) => props.onSelect?.(item)}
      items={items}
    />
  )
}
