import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro'
import {
  DetailedCostModelBreakdown,
  SupplierCommodity,
  SupplierCommodityReflection,
} from '@common/types'
import { Formatter } from '@utils'
import { DeviationCell } from 'src/merchandising/pages/ChangeRequests/NegotiationPreparation/Adjustments/NegotiationConfigurationCells'
import { HighlightedCell } from 'src/shared/components/table'

type SupplierCommodityWithIndexData = SupplierCommodity & {
  indexData?: SupplierCommodityReflection
}
export type SupplierCommodityReflectionWithCostShare = SupplierCommodityReflection & {
  costSharePercentage: SupplierCommodity['costSharePercentage']
}
export type FormattedCostData = DetailedCostModelBreakdown['costType'][0] & {
  index: number
  type: string
}

const renderCostModelCell = ({
  row,
  formattedValue,
}: GridRenderCellParams<FormattedCostData & { isHighlighted: boolean }, string>) =>
  row.isHighlighted ? <HighlightedCell>{formattedValue}</HighlightedCell> : formattedValue

export const createSupplierReasoningColumns = (
  formatter: Formatter,
): GridColDef<SupplierCommodityWithIndexData>[] => [
  {
    field: 'commodityName',
    headerName: 'Commodity',
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: 'costSharePercentage',
    headerName: '% of the item',
    disableColumnMenu: true,
    headerAlign: 'right',
    align: 'right',
    flex: 0.8,
    valueFormatter: ({ value }) => formatter.percent0To1(value),
  },
  {
    field: 'priceChangeFromPreviousPercentage',
    headerName: 'Change',
    disableColumnMenu: true,
    headerAlign: 'right',
    align: 'right',
    flex: 0.8,
    valueFormatter: ({ value }) => formatter.percent0To1(value, { showChangeSign: true }),
  },
  {
    field: 'deviation',
    headerName: 'Deviation',
    disableColumnMenu: true,
    headerAlign: 'right',
    align: 'right',
    flex: 0.8,
    renderCell: ({ row }) => (
      <DeviationCell
        supplierChange={row.priceChangeFromPreviousPercentage}
        indexChange={row.indexData?.actualChangePercentage}
      />
    ),
  },
]

export const createKnownReflectionsColumns = (
  formatter: Formatter,
): GridColDef<SupplierCommodityReflectionWithCostShare>[] => [
  {
    field: 'commodityName',
    headerName: 'Commodity',
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: 'costSharePercentage',
    headerName: '% of the item',
    disableColumnMenu: true,
    headerAlign: 'right',
    align: 'right',
    flex: 0.8,
    valueFormatter: ({ value }) => formatter.percent0To1(value),
  },
  {
    field: 'actualChangePercentage',
    headerName: 'Change',
    disableColumnMenu: true,
    headerAlign: 'right',
    align: 'right',
    flex: 0.8,
    valueFormatter: ({ value }) => formatter.percent0To1(value, { showChangeSign: true }),
  },
  {
    field: 'indexName',
    headerName: 'Index name',
    disableColumnMenu: true,
    flex: 1,
  },
]

export const createDetailedCostModelColumns = (
  formatter: Formatter,
): GridColDef<FormattedCostData>[] => [
  {
    field: 'type',
    headerName: 'Type',
    disableColumnMenu: true,
    flex: 0.8,
    renderCell: renderCostModelCell,
  },
  {
    field: 'percentOfTotalValue',
    headerName: '% of the item',
    disableColumnMenu: true,
    headerAlign: 'right',
    align: 'right',
    flex: 0.4,
    valueFormatter: ({ value }) => formatter.percent0To1(value),
    renderCell: renderCostModelCell,
  },
  {
    field: 'commodity',
    headerName: 'Commodity',
    disableColumnMenu: true,
    flex: 1,
    renderCell: renderCostModelCell,
  },
]
