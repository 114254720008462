import { GridColDef } from '@mui/x-data-grid-pro'
import { SyncAlt } from '@mui/icons-material'
import { Formatter } from '@utils'
import { CaptionedValueCell, NotAvailableCell } from '@components/table'
import {
  ContractedLanesNegotiationToUnitStatus,
  ContractedLanesUnit,
  ContractedLanesUnitStatus,
  LocationData,
} from '../types'
import { Chip } from '@mui/material'
import { StatusConfig } from '@logistics/types'
import { formatCurrency } from '@shared/utils/formatCurrency'
import { isInvalidOffer } from '@logistics/pages/contractedLanes/RFPDetailsPage/utils'
import { isPopulatedArray } from '@utils/array'
import { getDistanceCaption } from '@logistics/pages/common/utils'

const STATUS_CONFIG: StatusConfig<ContractedLanesUnitStatus> = {
  NOT_STARTED: { label: 'Ready', color: 'stoneBlue' },
  ONGOING: { label: 'In Progress', color: 'lightGrey' },
  RATE_CAPTURED: { label: 'Captured', color: 'success' },
  AGREEMENT_REACHED: { label: 'Completed', color: 'success' },
  EXPIRED: { label: 'Expired', color: 'error' },
}

const STATUS_COLUMN_WIDTH = 150
const BEST_PRICE_COLUMN_WIDTH = 100

function formatLocation(loc: LocationData): string {
  if (loc.city) {
    if (loc.region && !loc.city.endsWith(`, ${loc.region}`)) {
      return `${loc.city}, ${loc.region}`
    }
    return `${loc.city}, ${loc.postcode}`
  }

  if (loc.clientSpecificCode) {
    return `${loc.clientSpecificCode}`
  }

  if (loc.postcode && loc.countryCode) {
    return `${loc.postcode}, ${loc.countryCode}`
  }

  if (loc.postcode) {
    return loc.postcode
  }

  return 'N/A'
}

export const createRFPDetailsColumns = (
  _formatter: Formatter,
): GridColDef<ContractedLanesUnit>[] => [
  {
    field: 'origin',
    headerName: 'Origin',
    disableColumnMenu: true,
    sortable: false,
    flex: 0.1,
    renderCell: ({ row }) => (
      <CaptionedValueCell
        mainContent={formatLocation(row.attributes.origin)}
        spacing={0}
        tooltip={{
          id: row.id.toString(),
          title: formatLocation(row.attributes.origin),
          placement: 'top-start',
        }}
      />
    ),
  },
  {
    field: 'arrows',
    headerName: '',
    width: 16,
    disableColumnMenu: true,
    sortable: false,
    disableReorder: true,
    renderCell: () => (
      <SyncAlt
        fontSize='medium'
        sx={{
          color: 'text.secondary',
        }}
      />
    ),
  },
  {
    field: 'destination',
    headerName: 'Destination',
    disableColumnMenu: true,
    sortable: false,
    flex: 0.2,
    renderCell: ({ row }) => (
      <CaptionedValueCell
        mainContent={formatLocation(row.attributes.destination)}
        captionContent={getDistanceCaption(row.attributes.distanceKm, row.projectLocale)}
        spacing={0}
        tooltip={{
          id: row.id.toString(),
          title: formatLocation(row.attributes.destination),
          placement: 'top-start',
        }}
      />
    ),
  },
  {
    field: 'lane_id',
    headerName: 'Lane ID',
    disableColumnMenu: true,
    sortable: false,
    flex: 0.35,
    valueGetter: ({ row }) => row.attributes.lane_id,
  },
  {
    field: 'trip_type',
    headerName: 'Trip Type',
    disableColumnMenu: true,
    sortable: true,
    width: BEST_PRICE_COLUMN_WIDTH,
    valueGetter: ({ row }) => row.attributes.tripType,
  },
  {
    field: 'equipment',
    headerName: 'Equipment',
    disableColumnMenu: true,
    sortable: false,
    flex: 0.1,
    valueGetter: ({ row }) => `${row.attributes.equipmentType}, ${row.attributes.equipmentSize}`,
  },
  {
    field: 'max_limit',
    headerName: 'Rates captured',
    disableColumnMenu: true,
    sortable: false,
    flex: 0.1,
    valueGetter: ({ row }) => {
      const closed = row.negotiations.filter(
        ({ negotiationUnit }) =>
          negotiationUnit.status === ContractedLanesNegotiationToUnitStatus.SUCCESS &&
          negotiationUnit.outcomes?.capturedRate !== undefined &&
          negotiationUnit.attributes?.currency !== undefined,
      ).length

      const total = row.negotiations.length

      return { total, closed }
    },
    renderCell: ({ value }: { value?: { total: number; closed: number } }) =>
      `${value?.closed}/${value?.total}`,
  },
  {
    field: 'status',
    headerName: 'Status',
    disableColumnMenu: true,
    sortable: false,
    width: STATUS_COLUMN_WIDTH,
    renderCell: ({ row }) => {
      return <Chip size='medium' {...STATUS_CONFIG[row.status]} variant='filled' />
    },
  },
  {
    field: 'best_offer',
    headerName: 'Best offer',
    disableColumnMenu: true,
    sortable: false,
    width: BEST_PRICE_COLUMN_WIDTH,
    renderCell: ({ row }) => {
      const availableOffers = row.negotiations
        .filter(
          ({ negotiationUnit }) =>
            negotiationUnit.status === ContractedLanesNegotiationToUnitStatus.SUCCESS &&
            negotiationUnit.outcomes?.capturedRate !== undefined &&
            negotiationUnit.attributes?.currency !== undefined,
        )
        .map((n) => n.negotiationUnit)

      if (availableOffers.length === 0) {
        return <NotAvailableCell />
      }

      const offersSortedByCapturedRate = availableOffers.sort((a, b) => {
        // Returning undefined rates as greater for disqualifying them as the minimum.
        // It is currently possible to offer zero rates in contracted lanes negotiations.
        if (a.outcomes?.capturedRate === undefined) {
          return 1
        }

        if (b.outcomes?.capturedRate === undefined) {
          return -1
        }

        return a.outcomes?.capturedRate - b.outcomes?.capturedRate
      })
      const minimumOfferAmount = offersSortedByCapturedRate[0].outcomes?.capturedRate
      const minimumOfferCurrency = offersSortedByCapturedRate[0].attributes?.currency

      if (!minimumOfferAmount || isInvalidOffer(minimumOfferAmount, minimumOfferCurrency)) {
        return <NotAvailableCell />
      } else {
        return formatCurrency(minimumOfferAmount, minimumOfferCurrency, _formatter)
      }
    },
  },
  {
    field: 'max_anchor',
    headerName: 'Max Anchor',
    disableColumnMenu: true,
    sortable: true,
    width: BEST_PRICE_COLUMN_WIDTH,
    renderCell: ({ row }) => {
      if (!isPopulatedArray(row.negotiations)) {
        return <NotAvailableCell />
      }

      const withAvailableAnchorsDesc = [...row.negotiations].sort((a, b) => {
        return b.negotiationUnit.attributes?.anchor - a.negotiationUnit.attributes?.anchor
      })[0]
      const availableAnchorAmount = withAvailableAnchorsDesc.negotiationUnit.attributes?.anchor
      const availableAnchorCurrency = withAvailableAnchorsDesc.negotiationUnit.attributes?.currency

      return formatCurrency(availableAnchorAmount, availableAnchorCurrency, _formatter)
    },
  },
  {
    field: 'min_anchor',
    headerName: 'Min Anchor',
    disableColumnMenu: true,
    sortable: true,
    width: BEST_PRICE_COLUMN_WIDTH,
    renderCell: ({ row }) => {
      if (!isPopulatedArray(row.negotiations)) {
        return <NotAvailableCell />
      }

      const withAvailableAnchorsAsc = [...row.negotiations].sort((a, b) => {
        return a.negotiationUnit.attributes?.anchor - b.negotiationUnit.attributes?.anchor
      })[0]

      const availableAnchorAmount = withAvailableAnchorsAsc.negotiationUnit.attributes.anchor
      const availableAnchorCurrency = withAvailableAnchorsAsc.negotiationUnit.attributes.currency

      return formatCurrency(availableAnchorAmount, availableAnchorCurrency, _formatter)
    },
  },
]
