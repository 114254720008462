import { Components, Theme } from '@mui/material/styles'

export const chipTheme: Components<Theme> = {
  MuiChip: {
    styleOverrides: {
      root: {
        letterSpacing: '0.005em',
        fontWeight: 500,
      },
    },
    variants: [
      {
        props: { size: 'small' },
        style: ({ theme }) => ({
          height: '1rem',
          lineHeight: '1rem',
          fontSize: '0.625rem',
          '& .MuiChip-label': {
            padding: theme.spacing(0, 1),
          },
        }),
      },
      {
        props: { size: 'medium' },
        style: ({ theme }) => ({
          height: '1.5rem',
          lineHeight: '1rem',
          fontSize: '0.875rem',
          '& .MuiChip-label': {
            padding: theme.spacing(0.5, 1.5),
          },
        }),
      },
      {
        props: { size: 'large' },
        style: ({ theme }) => ({
          height: '2rem',
          lineHeight: '1rem',
          fontSize: '0.875rem',
          '& .MuiChip-label': {
            padding: theme.spacing(1, 2),
          },
        }),
      },
    ],
  },
}
