import z from 'zod'
import { TIMEZONES } from '@shared/utils/timezone'
import { RequiredSupplierAttributes } from '@logistics/types/Supplier'

export const supplierAddressSchema = z.object({
  country: z.string(),
  states: z.string().array().default([]),
})

export const supplierContactSchema = z.object({
  id: z.string(),
  timeZone: z
    .string()
    .min(1, { message: 'Required' })
    .refine((value) => TIMEZONES.includes(value), {
      message: 'Invalid',
    }),
  isPrimary: z.boolean(),
  email: z.string().min(1).email(),
  phone: z.string().nullable(),
  address: z.array(supplierAddressSchema).optional().default([]),
})

const RequiredSupplierAttributeTypes = z.union([
  z.number(),
  z.bigint(),
  z.string(),
  z.boolean(),
  z.array(z.number()),
  z.record(z.string()),
])

export const supplierAttributesSchema = (requiredAttributes: RequiredSupplierAttributes) => {
  const requiredFields: Record<string, typeof RequiredSupplierAttributeTypes> = {}

  for (const attribute of requiredAttributes) {
    requiredFields[attribute] = RequiredSupplierAttributeTypes
  }

  return z.intersection(
    z.object({
      notes: z.string().optional(),
    }),
    z.object({ ...requiredFields }),
  )
}

export const editSupplierFormSchema = (requiredAttributes: RequiredSupplierAttributes) => {
  return z.object({
    id: z.string(),
    name: z.string().min(1),
    rating: z.coerce.number().min(1).max(5).nullish(),
    projectTag: z.string(),
    externalId: z.string().min(1),
    mcNumber: z.string().max(10).nullish(),
    isEnabled: z.boolean(),
    attributes: supplierAttributesSchema(requiredAttributes).optional(),
    contacts: z.array(supplierContactSchema).optional().default([]),
  })
}

export const createSupplierFormSchema = (requiredAttributes: RequiredSupplierAttributes) => {
  const requiredFields: Record<string, typeof RequiredSupplierAttributeTypes> = {}

  for (const attribute of requiredAttributes) {
    requiredFields[attribute] = RequiredSupplierAttributeTypes
  }

  return z.intersection(
    z.object({
      name: z.string().min(1),
      rating: z.coerce.number().min(1).max(5).nullish(),
      externalId: z.string().min(1),
      mcNumber: z.string().max(10).nullish(),
      notes: z.string().optional(),
    }),
    z.object({ ...requiredFields }),
  )
}
