import DownloadIcon from '@mui/icons-material/Download'
import { BatchActionButton, BatchActionsContainer } from '@components/BatchActions'
import { NegotiationListEntity } from '@common/dto/pact/negotiation-list-entity'
import { useGenericNegotiationsActions } from '../../hooks/useGenericNegotiationsActions'

interface Props {
  allNegotiationsCount: number
  selectedNegotiations: NegotiationListEntity[]
}

export const BatchActionsBar = ({ allNegotiationsCount, selectedNegotiations }: Props) => {
  const { handleDownloadNegotiations } = useGenericNegotiationsActions(
    allNegotiationsCount,
    selectedNegotiations,
  )

  return (
    <>
      <BatchActionsContainer>
        <BatchActionButton
          startIcon={<DownloadIcon />}
          disabled={selectedNegotiations.length === 0}
          onClick={handleDownloadNegotiations}
        >
          Download results
        </BatchActionButton>
      </BatchActionsContainer>
    </>
  )
}
