import React, { useEffect } from 'react'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useNegotiationEventDetailsQuery } from '../../store/price-list'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { PactumLoader } from '@components/PactumLoader'
import { Alert, Box, Grid, IconButton, Stack } from '@mui/material'
import { PriceListRoutes } from '../../priceListRoutes'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { BasicAttributes } from '../../components/BasicAttributes/BasicAttributes'
import { NegotiationEventSummary } from './NegotiationEventSummary'
import { useLayoutContext } from '@components/Layout'

export const NegotiationEventDetailsPage = () => {
  const navigate = useNavigate()
  const { setPriceListNegotiationEvent } = useLayoutContext()
  const location: {
    state: {
      from: string
    }
  } = useLocation()
  const { activeProjectTag, activeOrgTag } = useActiveProject()
  const { negotiationEventId } = useParams<{ negotiationEventId: string }>()

  const {
    data: negotiationEvent,
    isLoading,
    isError,
    error,
  } = useNegotiationEventDetailsQuery({
    projectTag: activeProjectTag,
    negotiationEventId: Number(negotiationEventId),
    withAudit: true,
    withDeleted: true,
  })

  useEffect(() => {
    if (negotiationEvent) {
      setPriceListNegotiationEvent(negotiationEvent)
    }
  }, [negotiationEvent, setPriceListNegotiationEvent])

  if (isLoading) {
    return <PactumLoader />
  }

  if (isError) {
    return <Alert severity='error'>Error: {`Data request error: ${JSON.stringify(error)}`}</Alert>
  }

  if (!negotiationEvent) {
    return <Alert severity='warning'>Negotiation event not found</Alert>
  }

  const navigateBack = () => {
    if (location.state?.from) {
      navigate(-1)
      return
    }

    navigate(
      generatePath(PriceListRoutes.NEGOTIATION_EVENTS, {
        orgTag: activeOrgTag,
        projectTag: activeProjectTag,
      }),
    )
  }

  return (
    <Stack flexDirection='row' mt={4} pl={1.5}>
      <Box position='relative' right={(theme) => theme.spacing(0.75)}>
        <IconButton onClick={() => navigateBack()} sx={{ alignSelf: 'flex-start' }}>
          <ArrowBackIcon fontSize='small' sx={{ color: 'black' }} />
        </IconButton>
      </Box>
      <Grid pr={6} container spacing={10}>
        <Grid item xs={3}>
          <Box p={0} pt={0.5}>
            <BasicAttributes negotiationEvent={negotiationEvent} />
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Box px={1} pt={0.5} pb={4}>
            <NegotiationEventSummary negotiationEvent={negotiationEvent} />
          </Box>
        </Grid>
      </Grid>
    </Stack>
  )
}
