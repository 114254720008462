import React from 'react'
import { SvgIcon } from '@mui/material'

export const PactumLogo = () => (
  <SvgIcon
    data-testid='pactum-logo'
    width={24}
    height={24}
    viewBox='0 0 308.21993 322.2136'
    sx={{ color: '#A59080' }}
  >
    <path d='M228.35553,322.21367a53.70519,53.70519,0,0,1-51.20416-38.255L106.77108,45.44491a28.22088,28.22088,0,0,0-26.90668-20.106H53.39648A28.08937,28.08937,0,0,0,25.33892,53.39654v66.89184a28.08927,28.08927,0,0,0,28.05756,28.05753H96.8981l11.17907,12.88959L97.27991,173.68491H53.39648A53.457,53.457,0,0,1,0,120.28838V53.39654A53.457,53.457,0,0,1,53.39648,0H79.8644a53.705,53.705,0,0,1,51.20416,38.25506l70.3804,238.5137a28.22043,28.22043,0,0,0,26.90657,20.106h26.46792a28.08946,28.08946,0,0,0,28.05767-28.05764V201.9254a28.08946,28.08946,0,0,0-28.05767-28.05764l-44.26272-.00076-10.696-12.67636,10.48863-12.66242,44.47.00065a53.45705,53.45705,0,0,1,53.39659,53.39653v66.89174a53.457,53.457,0,0,1-53.39648,53.39653Z' />
  </SvgIcon>
)
