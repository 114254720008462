import { useRef, useMemo, useLayoutEffect } from 'react'
import debounce from 'lodash.debounce'

type Debounced = {
  cancel: () => void
}

// eslint-disable-next-line
export const useDebounce = <T extends (...args: any[]) => void>(
  callback: T,
  delay: number,
): T & Debounced => {
  const callbackRef = useRef(callback)
  useLayoutEffect(() => {
    callbackRef.current = callback
  })
  return useMemo(
    () => debounce((...args) => callbackRef.current(...args), delay) as unknown as T & Debounced,
    [delay],
  )
}
