const isIsoDate = (value: unknown): value is string =>
  typeof value === 'string' &&
  /^(?<year>-?(?:[1-9][0-9]*)?[0-9]{4})-(?<month>1[0-2]|0[1-9])-(?<day>3[01]|0[1-9]|[12][0-9])(T(?<hour>2[0-3]|[01][0-9]):(?<minute>[0-5][0-9]):(?<second>[0-5][0-9])(?<ms>\.[0-9]+)?(?<timezone>Z|[+-](?:2[0-3]|[01][0-9]):[0-5][0-9])?)?$/.test(
    value,
  )

const parseIsoDateLocalTime = (date: string): Date => {
  // Use only date part and assume local timezone
  const [year, month, day] = date.split(/\D/).map((str) => parseInt(str))
  return new Date(year, month - 1, day)
}

const adjustForLocalTimezone = (date: string | null | undefined): string | null | undefined => {
  return isIsoDate(date) ? parseIsoDateLocalTime(date).toISOString() : date
}

export { isIsoDate, parseIsoDateLocalTime, adjustForLocalTimezone }
