import { UnitPageContextProvider as SpotLoadUnitPageContextProvider } from '@logistics/pages/spotLoad/UnitPage/context'
import { UnitPage as SpotLoadUnitPage } from '@logistics/pages/spotLoad'
import { LoadingPage } from '@pages/LoadingPage'
import { ErrorPage } from '@pages/ErrorPage'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useGetProjectConfigQuery } from '@logistics/store/projectConfigApi'
import { RFPListPage } from './pages/contractedLanes/RFPListPage/RFPListPage'
import { LogisticsUseCase } from '@pactum/core-backend-types'
import { SpotLanesPage } from '@logistics/pages/spotLane/SpotLanesPage'

export const UseCaseSelector = () => {
  const { activeProjectTag } = useActiveProject()

  const {
    data: adminProjectConfig,
    isLoading,
    isError,
  } = useGetProjectConfigQuery({
    projectTag: activeProjectTag,
  })

  if (isError) {
    return <ErrorPage title='Loading error'>Cannot load project configuration</ErrorPage>
  }

  if (isLoading || !adminProjectConfig) {
    return <LoadingPage />
  }

  switch (adminProjectConfig?.useCase) {
    case LogisticsUseCase.SPOT_LOAD:
      return (
        <SpotLoadUnitPageContextProvider>
          <SpotLoadUnitPage />
        </SpotLoadUnitPageContextProvider>
      )
    case LogisticsUseCase.CONTRACTED_LANES:
      return <RFPListPage />
    case LogisticsUseCase.LANE_NEGOTIATION:
      return <SpotLanesPage />
    default:
      return <ErrorPage title='Page not found'>Unexpected use-case for logistics</ErrorPage>
  }
}
