import { Formatter } from '@utils'
import { ActionConfigMap, ActionsButton } from '@components/ActionsButton'
import { GridColDef } from '@mui/x-data-grid-pro'
import { Chip, Link } from '@mui/material'
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded'
import { NotAvailableCell } from '@components/table'
import React from 'react'
import { getChipForNegotiationStatus } from '@logistics/pages/spotLoad/constants'
import {
  SpotLoadNegotiation,
  SpotLoadNegotiationAction,
  SpotLoadNegotiationStatus,
} from '@logistics/pages/spotLoad/types/negotiation'
import { INNER_ACTIONS_WIDTH, INNER_COLUMN_WIDTHS, SCROLLBAR_SPACE } from './constants'

export const createInnerNegotiationColumns = (
  formatter: Formatter,
  actionConfig: ActionConfigMap<SpotLoadNegotiationAction, number>,
): GridColDef<SpotLoadNegotiation>[] => [
  {
    field: 'supplier_name',
    headerName: 'Carrier',
    disableColumnMenu: true,
    sortable: false,
    align: 'left',
    flex: 0.25,
  },
  {
    field: 'supplier_email',
    headerName: 'Email',
    disableColumnMenu: true,
    sortable: false,
    flex: 0.25,
    renderCell: ({ row: { supplier_email } }) => (
      <Link href={`mailto:${supplier_email}`} sx={{ lineHeight: '1.5rem' }}>
        {supplier_email}
      </Link>
    ),
  },
  {
    field: 'negotiation_link',
    headerName: 'Negotiation',
    disableColumnMenu: true,
    sortable: false,
    width: INNER_COLUMN_WIDTHS.NEGOTIATED_PRICE,
    renderCell: ({ row: { negotiation_test_link, negotiation_review_link, status } }) => {
      const link =
        status === SpotLoadNegotiationStatus.NOT_STARTED
          ? negotiation_test_link
          : negotiation_review_link

      if (!link) {
        return <NotAvailableCell />
      }

      return (
        <Link href={`${link}`} sx={{ lineHeight: '1.5rem' }} target='_blank'>
          <OpenInNewRoundedIcon sx={{ verticalAlign: 'bottom' }} />{' '}
          {status === SpotLoadNegotiationStatus.NOT_STARTED ? 'Try out' : 'Review'}
        </Link>
      )
    },
  },
  {
    field: 'captured_price',
    headerName: 'Offer',
    disableColumnMenu: true,
    sortable: false,
    width: INNER_COLUMN_WIDTHS.NEGOTIATED_PRICE,
    renderCell: ({ row: { captured_price } }) =>
      captured_price === null ? <NotAvailableCell /> : formatter.currency(captured_price),
  },
  {
    field: 'status',
    headerName: 'Status',
    disableColumnMenu: true,
    sortable: false,
    width: INNER_COLUMN_WIDTHS.STATUS,
    renderCell: ({ row: { status } }) => (
      <Chip size='small' {...getChipForNegotiationStatus(status)} variant='outlined' />
    ),
  },
  {
    field: 'actions',
    headerName: 'Actions',
    disableColumnMenu: true,
    sortable: false,
    width: INNER_COLUMN_WIDTHS.ACTIONS,
    renderCell: ({ row: { allowed_actions, id } }) => (
      <ActionsButton
        id={id}
        size='small'
        menuMinWidth={INNER_ACTIONS_WIDTH}
        allowedActions={allowed_actions}
        actionConfig={actionConfig}
      />
    ),
  },
  {
    field: 'scrollbar',
    headerName: '',
    disableColumnMenu: true,
    sortable: false,
    minWidth: SCROLLBAR_SPACE,
    width: 0,
  },
]
