import { useState } from 'react'
import DownloadIcon from '@mui/icons-material/Download'
import { DownloadConfirmationModal } from 'src/shared/components/ConfirmationModal'
import { BatchActionButton } from './BatchActionButton'

export type DownloadActionProps = {
  enabled: boolean
  totalNegotiationsCount: number
  actionableNegotiationsCount: number
  onExecute: () => void
}

export const DownloadAction = ({
  enabled,
  totalNegotiationsCount,
  actionableNegotiationsCount,
  onExecute,
}: DownloadActionProps) => {
  const [isModalOpen, setModalOpen] = useState(false)

  return (
    <>
      <BatchActionButton
        startIcon={<DownloadIcon />}
        disabled={!enabled}
        onClick={() => setModalOpen(true)}
      >
        Download results
      </BatchActionButton>
      <DownloadConfirmationModal
        allNegotiations={totalNegotiationsCount}
        actionableNegotiations={actionableNegotiationsCount}
        open={isModalOpen}
        onCancel={() => setModalOpen(false)}
        onSubmit={() => {
          setModalOpen(false)
          onExecute()
        }}
      />
    </>
  )
}
