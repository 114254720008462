import { Box, Stack, SxProps, Typography } from '@mui/material'
import { capitalizeFirstLetter } from '@utils'
import { useState } from 'react'

export type CellColor = 'red' | 'green'

export interface MultilineDataCellData {
  title: string
  primaryValue: string
  secondaryValue?: string
  actionComponent?: JSX.Element | null
  options?: {
    color?: { primary?: CellColor; secondary?: CellColor }
    outerStyle?: SxProps
    showActionComponent?: boolean
  }
}

export const MultilineCell = ({
  title,
  primaryValue,
  secondaryValue,
  actionComponent,
  options,
}: MultilineDataCellData): JSX.Element => {
  const [isMouseHovering, setIsMouseHovering] = useState<boolean>(false)
  return (
    <Stack
      textAlign='right'
      spacing={0.75}
      m={1.2}
      onMouseEnter={() => setIsMouseHovering(true)}
      onMouseLeave={() => setIsMouseHovering(false)}
      sx={options?.outerStyle}
    >
      <Typography component='span' variant='caption' sx={{ opacity: 0.7 }}>
        {capitalizeFirstLetter(title)}
      </Typography>
      <Typography component='span' variant='body2' color={options?.color?.primary}>
        {primaryValue}
      </Typography>
      {secondaryValue && (
        <Typography
          component='span'
          variant='caption'
          sx={{ color: options?.color?.secondary, opacity: options?.color?.secondary ? 1 : 0.7 }}
        >
          {secondaryValue}
        </Typography>
      )}
      {options?.showActionComponent && isMouseHovering && (
        <Box textAlign='center'>{actionComponent}</Box>
      )}
    </Stack>
  )
}
