import { useSuiteDataGrid } from '@components/SuiteDataGrid/SuiteDataGridProvider'
import { DataGrid, dataGridProps } from '@components/table'
import { ROWS_PER_PAGE_OPTIONS } from '@components/table/pagination'
import { Box, styled } from '@mui/material'
import { DataGridPro, GridEventListener, GridRowSelectionModel } from '@mui/x-data-grid-pro'
import { NegotiationEvent, NegotiationEventListItem, UseCase } from '../../../store/types'
import { Toolbar } from './Toolbar'
import { generatePath, useNavigate } from 'react-router-dom'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { ProductType } from '@pactum/core-backend-types'
import { useFormatter } from '@shared/hooks'
import { useGridPagination } from '@shared/hooks/useGridPagination'
import { useMemo, useState } from 'react'
import { ProcurementRoutes } from '../../../routes'
import { createColumns } from './createColumns'

const LAYOUT_PADDING = 6

interface Props {
  negotiationEvents: NegotiationEventListItem[]
  onOpenUploadDialog?: () => void
  productType: ProductType.ContractCost | ProductType.Purchasing
  useCase?: UseCase
}

export const NegotiationEventListTable = ({
  negotiationEvents,
  onOpenUploadDialog,
  productType,
  useCase,
}: Props) => {
  const navigate = useNavigate()
  const { activeOrgTag } = useActiveProject()
  const [selectedNegotiationEventIds, setSelectedNegotiationEventIds] =
    useState<GridRowSelectionModel>([])

  const gridState = useSuiteDataGrid({
    defaultPageSize: ROWS_PER_PAGE_OPTIONS[0],
    sort: [
      { field: 'createdAt', sort: 'desc' },
      { field: 'uniqueName', sort: 'desc' },
    ],
  })

  const paginationProps = useGridPagination({ defaultPageSize: 25 })

  const formatter = useFormatter()

  const columns = useMemo(
    () =>
      createColumns({
        formatter,
        negotiationEvents,
        productType,
        useCase,
      }),
    [formatter, negotiationEvents, productType, useCase],
  )

  const handleCellClick: GridEventListener<'cellClick'> = (cell) => {
    const actionableFields = ['__actions__', '__check__']
    const { projectTag } = cell.row as NegotiationEvent

    if (!actionableFields.includes(cell.field)) {
      navigate(
        generatePath(ProcurementRoutes.NEGOTIATION_EVENT_DETAILS, {
          negotiationEventId: String(cell.id),
          orgTag: activeOrgTag,
          projectTag,
        }),
        { state: { from: ProcurementRoutes.NEGOTIATION_EVENTS } },
      )
    }
  }

  return (
    <Box pr={LAYOUT_PADDING} py={4}>
      <StyledDataGrid
        {...dataGridProps}
        {...paginationProps}
        checkboxSelection
        disableRowSelectionOnClick
        className={selectedNegotiationEventIds.length > 0 ? 'show-selection' : ''}
        isRowSelectable={() => true}
        rowSelectionModel={selectedNegotiationEventIds}
        onRowSelectionModelChange={(selectedRows) => setSelectedNegotiationEventIds(selectedRows)}
        columns={columns}
        rows={negotiationEvents}
        initialState={{
          columns: {
            columnVisibilityModel: {
              createdAt: false,
            },
          },
        }}
        slots={{ ...dataGridProps.slots, toolbar: Toolbar }}
        slotProps={{
          toolbar: {
            productType,
            onOpenUploadDialog,
            showBatchActions: selectedNegotiationEventIds.length > 0,
            selectedNegotiationEvents: negotiationEvents.filter(({ id }) =>
              selectedNegotiationEventIds.includes(id),
            ),
          },
        }}
        sortModel={gridState.sort}
        filterModel={gridState.filters}
        paginationModel={gridState.pagination}
        onSortModelChange={gridState.setSort}
        onPaginationModelChange={gridState.setPagination}
        onFilterModelChange={gridState.setFilters}
        onCellClick={handleCellClick}
      />
    </Box>
  )
}

const StyledDataGrid = styled(DataGrid)(() => ({
  ' .MuiDataGrid-columnHeader .MuiBadge-badge': {
    display: 'none',
  },
  ' .select-cell, .select-header': {
    opacity: 0,
  },
  ' .MuiDataGrid-row:hover .select-cell': {
    opacity: 1,
  },
  ' .MuiDataGrid-columnHeaders:hover .select-header': {
    opacity: 1,
  },
  '&.show-selection': {
    ' .select-cell, .select-header': {
      opacity: 1,
    },
  },
})) as typeof DataGridPro
