import { RegionSelect } from '@logistics/pages/common/SupplierDetailsPage/ContactList/RegionSelect/RegionSelect'
import { Region } from '@logistics/types/Supplier'
import { Box } from '@mui/material'
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-pro'
import { useEffect, useRef } from 'react'

export const RegionSelectEditInputCell = (props: GridRenderCellParams<Region[]>) => {
  const { id, field, hasFocus } = props
  const value = props.value as Region[]
  const apiRef = useGridApiContext()
  const ref = useRef<HTMLElement>()

  const handleChange = (newValue: Region[]) => {
    apiRef.current.setEditCellValue({ id, field, value: newValue })
  }

  useEffect(() => {
    if (hasFocus && ref.current) {
      const input = ref.current.querySelector<HTMLInputElement>(`input[value="${value}"]`)
      input?.focus()
    }
  }, [hasFocus, value])

  return (
    <Box sx={{ width: 300 }}>
      <RegionSelect value={value} onChange={handleChange} />
    </Box>
  )
}
