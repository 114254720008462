// Importing from product modules allowed here as an exception
/* eslint-disable import/no-restricted-paths */

import { AppRoutes } from '@constants'
import { SupplierDetailsHeader } from '@logistics/components/SupplierDetailsHeader'
import { LogisticsRoutes } from '@logistics/routes'
import { SettingsContextHeader } from '@pages/SettingsPage/SettingsContextHeader'
import { PriceListNegotiationEventDetailsHeader } from '@price-list/components/NegotiationEventDetailsHeader/NegotiationEventDetailsHeader'
import { PriceListNegotiationEventsHeader } from '@price-list/components/NegotiationEventsHeader/NegotiationEventsHeader'
import { PriceListRoutes } from '@price-list/priceListRoutes'
import { NegotiationEventDetailsHeader } from '@procurement/components/NegotiationEventDetailsHeader/NegotiationEventDetailsHeader'
import { NegotiationEventsHeader } from '@procurement/components/NegotiationEventsHeader/NegotiationEventsHeader'
import { ProcurementRoutes } from '@procurement/routes'
import { useMatch } from 'react-router-dom'
import { DetailsContextHeader } from 'src/commercial-terms/pages/NegotiationDetails/DetailsContextHeader'
import { CommercialTermsRoutes } from 'src/commercial-terms/routes'
import { GreetingHeader } from './GreetingHeader'
import { StatisticsHeader } from './Statistics'
import { useLayoutContext } from './context'

export const ContextHeader = () => {
  const { supplier } = useLayoutContext()

  const isNegotiationsDetailsPage = useMatch(CommercialTermsRoutes.NEGOTIATION_DETAILS) !== null
  const isSupplierDetailsPage = useMatch(LogisticsRoutes.SUPPLIER_DETAILS) !== null

  const isNegotiationEventDetailsPage =
    useMatch(ProcurementRoutes.NEGOTIATION_EVENT_DETAILS) !== null

  const isNegotiationEventsPage = useMatch(ProcurementRoutes.NEGOTIATION_EVENTS) !== null
  const isBrandingPage = useMatch(AppRoutes.SETTINGS) !== null
  const isPriceListNegotiationEventsPage = useMatch(PriceListRoutes.NEGOTIATION_EVENTS) !== null

  const isPriceListNegotiationDetailsPage =
    useMatch(PriceListRoutes.NEGOTIATION_EVENT_DETAILS) !== null

  if (isPriceListNegotiationEventsPage) {
    return <PriceListNegotiationEventsHeader />
  }

  if (isPriceListNegotiationDetailsPage) {
    return <PriceListNegotiationEventDetailsHeader />
  }

  if (isNegotiationEventDetailsPage) {
    return <NegotiationEventDetailsHeader />
  }

  if (isNegotiationEventsPage) {
    return <NegotiationEventsHeader />
  }

  if (isNegotiationsDetailsPage) {
    return <DetailsContextHeader />
  }

  if (isSupplierDetailsPage) {
    return (
      <>
        {supplier && <SupplierDetailsHeader supplier={supplier} />}
        <StatisticsHeader />
      </>
    )
  }

  if (isBrandingPage) {
    return <SettingsContextHeader />
  }

  return (
    <>
      <GreetingHeader />
      <StatisticsHeader />
    </>
  )
}
