import { ActionsButton } from '@components/ActionsButton'
import { CellPlaceholderText } from '@components/table'
import { NegotiationListEntity } from '@common/dto/pact/negotiation-list-entity'
import {
  GenericNegotiationAction,
  useGenericSingleNegotiationAction,
} from '../../hooks/useGenericNegotiationsActions'

type ListItemAction = {
  label: string
  onClick: () => unknown
}

interface Props {
  negotiation: NegotiationListEntity
  actionsMenuMinWidth: number
}

export const ListItemActions = ({ negotiation, actionsMenuMinWidth }: Props) => {
  const { availableActions, handleDownloadNegotiations } =
    useGenericSingleNegotiationAction(negotiation)

  const allActions: Record<GenericNegotiationAction, ListItemAction> = {
    DOWNLOAD: {
      label: 'Download',
      onClick: handleDownloadNegotiations,
    },
  }

  if (!negotiation.isConcluded) {
    return <CellPlaceholderText>Awaiting results</CellPlaceholderText>
  }

  if (negotiation.isConcluded && !negotiation.isSuccessful) {
    return <CellPlaceholderText>No actions</CellPlaceholderText>
  }

  return (
    <ActionsButton
      id={negotiation.id}
      allowedActions={availableActions}
      actionConfig={allActions}
      menuMinWidth={actionsMenuMinWidth}
    />
  )
}
