import { createContext, useContext, useMemo } from 'react'
import { Locale } from '@common/constants'
import { Formatter } from '@utils'

export const FormatterContext = createContext<Formatter>(new Formatter())

interface Props {
  locale?: Locale
}

export const useFormatterContext = ({ locale }: Props): Formatter =>
  useMemo(() => new Formatter(locale), [locale])

export const useFormatter = () => useContext(FormatterContext)
