import { ActionConfigMap, ActionsButton } from '@components/ActionsButton'
import { ACTIONS_BUTTON_WIDTH } from './columns'
import { useMemo, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { LogisticsRoutes } from '@logistics/routes'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { RequestForPrice, RequestForPriceAction } from '../../types'
import { useRFPActions } from '../../hooks/useRFPActions'
import { ConfirmationModal } from '@components/ConfirmationModal'
import { SupportedSizes } from '@components/ActionsButton/ActionsButton'
import { SimpleTooltip } from '@components/OptionalTooltipWrapper'

type Props = {
  rfp: RequestForPrice
  displayReviewAction?: boolean
  size?: SupportedSizes
}

const DEFAULT_TOOLTIP_PROPS: Partial<SimpleTooltip> = {
  enterDelay: 1000,
  placement: 'right-end',
  arrow: true,
}

export const RFPActions = ({ rfp, displayReviewAction = true, size = 'medium' }: Props) => {
  const navigate = useNavigate()
  const { activeProjectTag, activeOrgTag } = useActiveProject()

  const {
    availableActions,
    disabledActions,
    handleStartNegotiations,
    handleCreateFollowups,
    handleDeleteNegotiations,
    handleDownloadResults,
    handleRecreateNegotiations,
    loading,
  } = useRFPActions(rfp)

  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const actionConfig: ActionConfigMap<RequestForPriceAction, {}> = useMemo(
    () => ({
      [RequestForPriceAction.REVIEW]: {
        label: 'Review',
        onClick: () =>
          navigate(
            generatePath(LogisticsRoutes.RFP_DETAILS, {
              id: rfp.id.toString(),
              orgTag: activeOrgTag,
              projectTag: activeProjectTag,
            }),
          ),
      },
      [RequestForPriceAction.START]: {
        label: 'Start',
        onClick: async () => {
          await handleStartNegotiations()
        },
        tooltip: getDefaultTooltipWithTitle(
          'This starts all pending negotiations and sends link emails to suppliers.',
        ),
      },
      [RequestForPriceAction.DELETE]: {
        label: 'Delete',
        onClick: () => setShowDeleteModal(true),
        tooltip: getDefaultTooltipWithTitle('This deletes the RFP and all its negotiations.'),
      },
      [RequestForPriceAction.DOWNLOAD]: {
        label: 'Download',
        onClick: async () => {
          await handleDownloadResults()
        },
        tooltip: getDefaultTooltipWithTitle('This downloads the negotiation results for this RFP.'),
      },
      [RequestForPriceAction.CREATE_FOLLOWUPS]: {
        label: 'Create Followups',
        onClick: async () => {
          await handleCreateFollowups()
        },
        tooltip: getDefaultTooltipWithTitle(
          'This creates follow-up negotiations for all pending negotiations.',
        ),
      },
      [RequestForPriceAction.RECREATE_NEGOTIATIONS]: {
        label: 'Recreate Negotiations',
        onClick: async () => {
          await handleRecreateNegotiations()
        },
        tooltip: getDefaultTooltipWithTitle(
          'This recreates all pending negotiations with refreshed anchors.',
        ),
      },
    }),
    [
      activeOrgTag,
      activeProjectTag,
      handleDownloadResults,
      handleStartNegotiations,
      handleCreateFollowups,
      handleRecreateNegotiations,
      navigate,
      rfp.id,
    ],
  )
  return (
    <>
      <ConfirmationModal
        open={showDeleteModal}
        confirmType='negative'
        title={'Delete RFP'}
        content={'Are you sure you want to delete the RFP? This action can not be undone.'}
        confirmButtonText='Delete'
        onSubmit={async () => {
          await handleDeleteNegotiations()
          setShowDeleteModal(false)
        }}
        onCancel={() => {
          setShowDeleteModal(false)
        }}
      />
      <ActionsButton
        id={rfp.id.toString()}
        loading={loading}
        menuMinWidth={ACTIONS_BUTTON_WIDTH}
        allowedActions={[
          ...(displayReviewAction ? [RequestForPriceAction.REVIEW] : []),
          ...availableActions,
        ]}
        disabledActions={disabledActions}
        actionConfig={actionConfig}
        size={size}
      />
    </>
  )
}

function getDefaultTooltipWithTitle(title: string): SimpleTooltip {
  return {
    title,
    ...DEFAULT_TOOLTIP_PROPS,
  }
}
