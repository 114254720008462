import { ListPopover, ListPopoverDivider, ListPopoverMenuItem } from '@components/ListPopover'
import { useProjects } from '@hooks'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  styled,
  useTheme,
} from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { logout } from 'src/main/hooks/auth/useAuthInternals'
import { UserProject, useGetOrganizationsConfigurationQuery } from 'src/main/store/projects'
import { useOrgTag } from '../../../../main/hooks/useOrgTag'
import { useOpenGitbook } from '../../../../main/hooks/useOpenGitbook'

type UserBadgeMenuProps = {
  buttonRef: React.MutableRefObject<null>
  open: boolean
  setOpen: (open: boolean) => void
}

const StyledAccordion = styled(Accordion)(() => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
}))

const StyledAccordionSummary = styled(AccordionSummary)(() => ({}))

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: 0,
  margin: 0,
  marginBottom: theme.spacing(1),
}))

export const UserBadgeMenu = ({ buttonRef, open, setOpen }: UserBadgeMenuProps) => {
  const { activeProjectTag, projects, setActiveProject } = useProjects()
  const orgTag = useOrgTag()
  const { data: orgConfig, isLoading } = useGetOrganizationsConfigurationQuery({
    orgTag,
  })
  const { openGitbook } = useOpenGitbook()

  const navigate = useNavigate()
  const theme = useTheme()

  const toolbarMinHeight = theme.mixins.toolbar.minHeight
  const enableSettings = false

  if (isLoading || !orgConfig) {
    return null
  }

  const amountOfWorkspacesThatAreNotSandbox = projects.filter(
    (project) => !project.isClientSandbox,
  ).length

  const prodProjects = projects.filter((p) => !p.isClientSandbox)
  const sandboxProjects = projects.filter((p) => p.isClientSandbox)

  return (
    <ListPopover
      buttonsRef={buttonRef}
      open={open}
      setOpen={setOpen}
      maxWidth='350px'
      placement='bottom-end'
      sx={{
        maxHeight: `calc(100vh - ${toolbarMinHeight}px)`,
        overflow: 'auto',
        borderRadius: theme.spacing(0.5),
      }}
    >
      {orgConfig.menuItems?.length > 0 && amountOfWorkspacesThatAreNotSandbox > 1 && (
        <ListPopoverMenuItem
          onClick={() => {
            navigate(`/${orgTag}`)
            setOpen(false)
          }}
        >
          Overview
        </ListPopoverMenuItem>
      )}

      <ListPopoverDivider />

      {prodProjects.length > 0 && (
        <UserMenuProjectList
          expanded
          projects={prodProjects}
          label='Workspaces'
          activeProjectTag={activeProjectTag}
          setActiveProject={setActiveProject}
          toggleMenu={() => setOpen(false)}
        />
      )}

      {sandboxProjects.length > 0 && (
        <UserMenuProjectList
          projects={sandboxProjects}
          label='Sandboxes'
          activeProjectTag={activeProjectTag}
          setActiveProject={setActiveProject}
          toggleMenu={() => setOpen(false)}
        />
      )}

      <ListPopoverDivider sx={{ mb: 1 }} />

      <ListPopoverMenuItem
        onClick={() => {
          navigate(`/${orgTag}/whatsnew`)
          setOpen(false)
        }}
      >
        What's new?
      </ListPopoverMenuItem>
      <ListPopoverMenuItem
        onClick={() => {
          setOpen(false)
          void openGitbook()
        }}
      >
        Help portal
      </ListPopoverMenuItem>
      <ListPopoverMenuItem
        onClick={() => {
          navigate(`/${orgTag}/settings`)
          setOpen(false)
        }}
      >
        Settings
      </ListPopoverMenuItem>
      {enableSettings && (
        <ListPopoverMenuItem
          onClick={() => {
            navigate(`/${orgTag}/settings`)
            setOpen(false)
          }}
        >
          Settings
        </ListPopoverMenuItem>
      )}
      <ListPopoverDivider />
      <ListPopoverMenuItem
        onClick={(e) => {
          e.stopPropagation()
          setOpen(false)
          void logout()
        }}
      >
        Sign out
      </ListPopoverMenuItem>
    </ListPopover>
  )
}

type UserBadgeProjectListProps = {
  setOpen: (open: boolean) => void
  activeProjectTag: string | null
  projects: UserProject[]
  setActiveProject: (projectTag: string) => void
}

const UserBadgeProjectList = ({
  setOpen,
  activeProjectTag,
  projects,
  setActiveProject,
}: UserBadgeProjectListProps) => {
  const switchProject = async (project: UserProject) => {
    setOpen(false)
    setActiveProject(project.tag)
  }

  if ((projects ?? []).length < 1) {
    return null
  }

  return (
    <Box>
      {projects.map((project) => (
        <ListPopoverMenuItem
          key={project.tag}
          onClick={(e) => {
            e.stopPropagation()
            switchProject(project)
          }}
          sx={{ fontWeight: project.tag === activeProjectTag ? 'bold' : 'inherit' }}
          data-project-name={project.name}
        >
          {project.name}
        </ListPopoverMenuItem>
      ))}
    </Box>
  )
}

interface UserBadgeProjectsAccordionProps {
  expanded?: boolean
  projects: UserProject[]
  label: string
  activeProjectTag: string | null
  setActiveProject: (projectTag: string) => void
  toggleMenu: () => void
}

const UserMenuProjectList = ({
  expanded = false,
  projects,
  label,
  toggleMenu,
  activeProjectTag,
  setActiveProject,
}: UserBadgeProjectsAccordionProps) => {
  const [show, setShow] = useState(expanded)

  const toggle = () => setShow(!show)

  return (
    <>
      <ListPopoverDivider textAlign='left' onClick={toggle}>
        <Button
          disableRipple
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            margin: 0,
            padding: 0,
            color: 'rgba(255, 255, 255, 0.8)',
            fontWeight: '400',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <span>{label}</span>
          {show ? <ArrowDropUp /> : <ArrowDropDown />}
        </Button>
      </ListPopoverDivider>

      <div>
        <StyledAccordion expanded={show} disableGutters>
          <div style={{ display: 'none' }}>
            <StyledAccordionSummary sx={{ display: 'hidden' }}></StyledAccordionSummary>
          </div>
          <StyledAccordionDetails>
            <UserBadgeProjectList
              setOpen={toggleMenu}
              activeProjectTag={activeProjectTag}
              projects={projects.sort((a, b) => a.name.localeCompare(b.name))}
              setActiveProject={setActiveProject}
            />
          </StyledAccordionDetails>
        </StyledAccordion>
      </div>
    </>
  )
}
