import { PropsWithChildren } from 'react'
import { Paper, Stack, styled, Typography } from '@mui/material'
import { FileRejection, useDropzone } from 'react-dropzone'
import { PactumLoader } from 'src/shared/components/PactumLoader'

export interface MultiFileUploadProps extends PropsWithChildren {
  callback: (file: File[], fileRejections: FileRejection[]) => void
  className?: string
  fileTypes?: string[]
  isLoading?: boolean
}

export const MultiFileUpload = ({
  callback,
  children,
  className,
  fileTypes,
  isLoading,
}: MultiFileUploadProps): JSX.Element => {
  const accept = fileTypes
    ? {
        ...fileTypes
          .map((acceptString) => ({ [acceptString]: [] }))
          .reduce((acc, acceptObj) => ({ ...acc, ...acceptObj }), {}),
      }
    : undefined

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    multiple: true,
    onDrop: callback,
    useFsAccessApi: false,
  })

  return (
    <DropArea {...getRootProps()} className={className} elevation={0}>
      {isLoading ? (
        <PactumLoader sizePx={36} />
      ) : (
        <>
          <input {...getInputProps()} />
          <DropAreaContent>
            {isDragActive ? <Typography>Drop the file here ...</Typography> : children}
          </DropAreaContent>
        </>
      )}
    </DropArea>
  )
}

const DropArea = styled(Paper)(({ theme }) => ({
  textAlign: 'center',
  border: `1px dashed ${theme.palette.grey['400']}`,
  width: '100%',
  minHeight: '84px',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',

  ':hover': {
    backgroundColor: theme.palette.grey['200'],
    transition: 'background-color 300ms ease-out',
  },
}))

const DropAreaContent = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  height: '100%',
})
