export enum AppRoutes {
  HOME = '/',
  ORG_TAG = '/:orgTag/',
  DASHBOARD = '/:orgTag/dashboard',
  WELCOME = '/:orgTag/welcome',
  CASE_STUDIES = '/:orgTag/case-studies',
  CHANGELOG = '/:orgTag/whatsnew',
  REDIRECT_TO_WORKSPACE = '/redirect_to_workspace/:workspaceId',
  REDIRECT_TO_HELP_PORTAL = '/redirect_to_help_portal',
  NOT_FOUND = '/not_found',
  WORKSPACE_UNAVAILABLE = '/workspace_unavailable',
  SETTINGS = '/:orgTag/settings/*',
  BRANDING = 'settings/general/branding/*',
}

// finds an argument starting with :
const regexExpr = new RegExp(/:\w+(?![^/])/)

// works only with routes where 1 parameter is used
export const applyParamToRoute = (route: AppRoutes | string, argument: string): string =>
  route.replace(regexExpr, argument)
