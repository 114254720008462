import { NotAvailableCell, CaptionedValueCell } from 'src/shared/components/table'
import { usdFormatter, percentFormatter } from '@utils'
import { getPriceChangeDirectionColor } from 'src/merchandising/pages/ChangeRequests/NegotiationPreparation/Adjustments/utils'

export const ValueDifferenceCell = ({
  previousValue,
  currentValue,
  invertColors = false,
  addChangeSign = false,
}: {
  previousValue?: number
  currentValue?: number
  invertColors?: boolean
  addChangeSign?: boolean
}): JSX.Element => {
  if (!previousValue || !currentValue) {
    return <NotAvailableCell />
  }
  const changeValue = currentValue - previousValue
  const changePercentage = changeValue / Math.abs(previousValue)
  const color = getPriceChangeDirectionColor(changeValue, invertColors)
  const changeSign = addChangeSign && changeValue > 0 ? '+' : ''
  return (
    <CaptionedValueCell
      mainContent={`${changeSign}${usdFormatter(changeValue)}`}
      captionContent={`${changeSign}${percentFormatter(changePercentage)}`}
      removeCaptionOpacity={!!color}
    />
  )
}
