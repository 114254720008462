import { NegotiationPreparationWithInputs } from '@common/dto'
import { Article, HistoricalCog } from '@common/types'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material'
import { useFormatter } from '@shared/hooks'
import { Formatter } from '@utils'
import { ReactNode, useMemo } from 'react'
import { useNegotiationPreparationData } from 'src/merchandising/pages/ChangeRequests/NegotiationPreparation/NegotiationPreparationPage'
import { TooltipWrapper } from './TooltipWrapper'

interface CogRows extends HistoricalCog {
  title?: string
  ean?: Article['ean']
  articleName?: Article['name']
}

const totalAllCogsTitle = 'TOTAL ALL COGS' as const
const totalThisRppTitle = 'TOTAL THIS RPP COGS' as const

const columnsAndKeys: {
  header: string
  key: keyof Omit<CogRows, 'title'>
  formatterMethod?: keyof Pick<Formatter, 'currency' | 'percent0To1'>
}[] = [
  { header: 'EAN', key: 'ean' },
  { header: 'Article Name', key: 'articleName' },
  { header: '2 Years Ago', key: 'twoYears', formatterMethod: 'currency' },
  { header: 'Last Year', key: 'lastYear', formatterMethod: 'currency' },
  { header: 'YoY Change', key: 'yoyChangePercentage', formatterMethod: 'percent0To1' },
]

export const SupplierHistoricalCogsTable = (): JSX.Element | null => {
  const formatter = useFormatter()
  const theme = useTheme()
  const { data } = useNegotiationPreparationData()
  const rows = useMemo(() => getRows(data), [data])

  if (!rows.length) {
    console.warn('No data for historical cogs')
    return null
  }

  const getBorderSetting = (cog: CogRows): string => {
    return cog.title === totalAllCogsTitle ? `3px solid ${theme.palette.grey.A400}` : 'unset'
  }

  return (
    <Box mt={6}>
      <Typography variant='h5' mb={2}>
        Supplier Historical COGS
      </Typography>
      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {columnsAndKeys.map(({ header }) => (
                <TableCell sx={{ fontWeight: 'bold' }} key={header}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((cog, idx) => (
              <TableRow key={idx} sx={{ borderTop: getBorderSetting(cog) }}>
                {columnsAndKeys.map(({ key, formatterMethod }) =>
                  formatAndRenderCell(cog, idx, key, formatter[formatterMethod!]),
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

const formatAndRenderCell = (
  cog: CogRows,
  idx: number,
  key: keyof Omit<CogRows, 'title'>,
  formatter?: (value: number) => string,
): ReactNode => {
  let value: ReactNode = cog[key]

  if (cog.title && key === 'ean' && cog.title === totalThisRppTitle) {
    value = (
      <TooltipWrapper
        title='This row represents the total Cost of Goods Sold included as part of the submitted purchase price increase form'
        value={cog['title']}
      />
    )
  } else if (cog.title && key === 'ean') {
    value = cog['title']
  }

  if (formatter && typeof value === 'number') {
    value = formatter(value)
  }

  return <TableCell key={`${key}-${idx}`}>{value}</TableCell>
}

const getRows = (data: NegotiationPreparationWithInputs) => {
  const rows = [] as CogRows[]

  const articleCogs = data.analysis.supplierHistoricalCogs?.articles
  const totalThisRPPAllCogsRow = data.analysis.supplierHistoricalCogs?.totalThisRppAllCogs
  const totalAllCogsRow = data.analysis.supplierHistoricalCogs?.totalAllCogs

  if (articleCogs && totalThisRPPAllCogsRow && totalAllCogsRow) {
    rows.push(
      ...Object.entries(articleCogs).map(([ean, value]) => ({
        ...value,
        ean,
      })),
      { ...totalAllCogsRow, title: totalAllCogsTitle },
      { ...totalThisRPPAllCogsRow, title: totalThisRppTitle },
    )
  }

  return rows
}
