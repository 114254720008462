import { FilterData } from './components/Filters'

export const ALL_ITEMS_OPTION: FilterData['items'][0] = {
  label: 'All options',
  value: 'allItems',
  type: 'ALL',
  withDivider: true,
}

export const ITEMS_AGGREGATE_OPTION: FilterData['items'][0] = {
  label: 'All items',
  value: 'itemsAggregate',
  type: 'AGGREGATE',
  withDivider: true,
}

export const ALL_MARKETS_OPTION: FilterData['markets'][0] = {
  label: 'All options',
  value: 'allMarkets',
  type: 'ALL',
  withDivider: true,
}

export const MARKET_AGGREGATE_OPTION: FilterData['markets'][0] = {
  label: 'All markets',
  value: 'marketAggregate',
  type: 'AGGREGATE',
  withDivider: true,
}

export const ALL_QUARTERS_OPTION: FilterData['timeRange'][0] = {
  label: 'All options',
  value: 'allQuarters',
  type: 'ALL',
  withDivider: true,
}

export const QUARTER_AGGREGATE_OPTION: FilterData['timeRange'][0] = {
  label: 'All quarters',
  value: '-1',
  type: 'AGGREGATE',
}

export const NEXT_QUARTER_OPTION: FilterData['timeRange'][0] = {
  label: 'Next quarter',
  value: '-2',
  type: 'FUTURE',
}

export const bsrTooltipTexts = [
  'Figure showing historical and predicted benefit and spend values at selected time points as well as selected items and markets.',
  'Values can be switches to be based on Global Pricing or Local Market Pricing negotiation outcomes.',
  'Next quarter data shows values based on the Negotiation Item Details section below, and distinguishes between the LAA and Target options.',
  'Totals are shown for All past quarters, All items, All markets, as well as any group of items simultaneously selected.',
]

export const negotiationDetailsTexts = [
  'The table below shows two scenarios for each item: Target and LAA (Least Acceptable Agreement).',
  'Target values specify the target outcome of the autonomous negotiation. LAA values specify the threshold for agreeable terms - any worse terms will not be agreed.',
  'The Target and LAA values in the negotiation are calculated based on the input data and recommended values are already in the table.',
  'The high-level overview of the table shows the Target and LAA values in read-only form.',
  'The details of any item can be viewed by clicking the "+" icon on the same line.',
  'The detailed view enables manually changing Target and LAA values.',
  'These changes are also reflected in the Benefit and Spend section above, under Next quarter.',
  'The benefit type specific values are used as guidance for the negotiation but outcomes may deviate, as priority is on achieving the Target Net Price.',
]

export const lumpSumsTexts = [
  'Setting a "Target amount" for a Lump Sum means it will be included in the first offers made to the supplier, and it will also influence the Target Net Price in the Negotiation Item Details section, as well as the Target values shown in the Benefit and Spend section.',
  'The supplier can agree to any amount for a Lump Sum, so long as it is equal to or higher than the "Minimum amount".',
  'The supplier can also exclude any and all Lump Sums from the agreement.',
  'Final agreement threshold is not based on Lump Sums. Instead, only the LAA Net Price of each item matters. So the supplier can both exclude Lump Sums and compensate with per-unit benefits or to reduce per-unit benefits and increase Lump Sums, so long as the Net Price of each item is below LAA.',
]
