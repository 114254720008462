import { CaptionedValueCell, NoValueCell } from '@components/table'
import { useFormatter } from '@shared/hooks'
import { isNumberZeroInclusive } from '@utils'

interface Props {
  savings?: number | null
  improvement?: number | null
  currency: string
}

export const NegotiationSavings = ({ savings, improvement, currency }: Props) => {
  const formatter = useFormatter()

  if (!isNumberZeroInclusive(savings) || !isNumberZeroInclusive(improvement)) {
    return <NoValueCell width='100%' textAlign='right' />
  }

  return (
    <CaptionedValueCell
      mainContent={formatter.percent0To1(improvement)}
      captionContent={formatter.currency(savings, { currency })}
      mainContentColor='success.main'
      textAlign='right'
    />
  )
}
