import { generatePath, Navigate } from 'react-router-dom'

import { LoadingPage } from 'src/main/pages/LoadingPage'
import { ErrorPage } from 'src/main/pages/ErrorPage'
// TODO: Remove dependency on product module
// eslint-disable-next-line import/no-restricted-paths
import { MerchandisingRoutes } from 'src/merchandising/routes'
import { useProjects, useUserPermissions } from '@hooks'
import { ORG_PLAYGROUND } from '@common/demo/orgTags'
import { CoreBackendPermission } from '@pactum/core-backend-types'
import { SuitePermission } from '@common/types'
import { useGetUserOrganizationsQuery } from 'src/main/store/userOrganizations'

export const RedirectToOrg = () => {
  const { orgs, projects, loadingOrgs } = useProjects()
  const { data: userOrgs, isLoading: loadingUserOrgs } = useGetUserOrganizationsQuery(undefined)
  const { userPermissionsInAllOrgs } = useUserPermissions()

  if (!userOrgs || !orgs || !projects || loadingOrgs || loadingUserOrgs) {
    return <LoadingPage />
  }

  const defaultOrg = orgs.find((org) => org.tag === ORG_PLAYGROUND)
  const nonDefaultOrg = orgs.find((org) => org.tag !== ORG_PLAYGROUND)
  const org = defaultOrg || nonDefaultOrg

  if (org) {
    const userOrg = userOrgs.find((userOrg) => userOrg.id === org.id)
    if (!userOrg?.permissions.includes(CoreBackendPermission.AUTH_ADMIN)) {
      if (
        userOrg?.permissions.includes(SuitePermission.MERCHANDISING_NEGOTIATION_PREP_MANAGEMENT)
      ) {
        return (
          <Navigate
            to={generatePath(MerchandisingRoutes.CHANGE_REQUESTS_LIST, { orgTag: org.tag })}
          />
        )
      }
    }

    return <Navigate to={`/${org.tag}`} />
  }

  if (userPermissionsInAllOrgs.has(CoreBackendPermission.AUTH_ADMIN)) {
    return (
      <ErrorPage title='No suitable organizations found'>
        <p>There aren't any organizations, or you don't have access to them.</p>
        <p>You can try to use cmd+k (macOS) or ctrl+k (Linux/Windows) for some other usecases.</p>
      </ErrorPage>
    )
  }

  return (
    <ErrorPage title='Something went wrong'>
      <p>
        There was an unexpected error while loading the page. Pactum team was notified and is
        working on it.
      </p>
    </ErrorPage>
  )
}
