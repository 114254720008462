import * as React from 'react'
import { Popover, styled, TextField } from '@mui/material'
import { Box, IconButton } from '@mui/material'
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined'
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined'

interface Props {
  comment: string | undefined
  onChange: (comment: string) => void
}

export const COMMENT_POPUP_CONTAINER_CLASS = 'comment-popup-container'

export const CommentPopup = ({ comment, onChange }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [value, setValue] = React.useState<string>('')

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setValue(comment ?? '')
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    if (value !== comment) {
      onChange(value)
    }
  }
  const open = Boolean(anchorEl)

  return (
    <StyledCommentPopupBox className={!open && !comment ? 'comment-popup--empty' : ''}>
      <IconButton size='small' onClick={handleClick}>
        {!!comment && <ModeCommentOutlinedIcon sx={{ fontSize: '0.875rem' }} />}
        {!comment && <AddCommentOutlinedIcon sx={{ fontSize: '0.875rem' }} />}
      </IconButton>
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <TextField
          placeholder='Add a comment'
          multiline
          minRows={3}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </Popover>
    </StyledCommentPopupBox>
  )
}

const StyledCommentPopupBox = styled(Box)(() => ({
  '&.comment-popup--empty': {
    width: 0,
    overflow: 'hidden',
  },
  [`.${COMMENT_POPUP_CONTAINER_CLASS}:hover &.comment-popup--empty`]: {
    width: 'auto',
    overflow: 'visible',
  },
})) as typeof Box
