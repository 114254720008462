import { BaseQueryFn, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getAccessToken } from 'src/main/hooks/auth/useAuthInternals'
import { environment } from 'src/environments/environment'
import { QueryCacheLifecycleApi } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { getSocket } from '@logistics/socket/socket'
import { ProjectTag } from '@common/types'
import { Socket } from 'socket.io-client'

export const TAGS = [
  'StatisticsHeader',
  'UnitList',
  'SpotLoadUnitList',
  'UnitCountByUseCase',
  'SupplierStatisticsHeader',
  'SingleSupplierStatisticsHeader',
  'SupplierList',
  'Supplier',
  'ProjectConfig',
  'RFPList',
  'RFPDetails',
] as const

export const logisticsBaseApi = createApi({
  reducerPath: 'logistics-api',
  baseQuery: fetchBaseQuery({
    baseUrl: environment.REACT_APP_LOGISTICS_BACKEND_URL ?? '',
    prepareHeaders: async (headers) => {
      const token = await getAccessToken()
      headers.set('Authorization', `Bearer ${token}`)

      return headers
    },
  }),

  endpoints: () => ({}),
  tagTypes: TAGS,
})

// https://redux-toolkit.js.org/rtk-query/usage/streaming-updates#websocket-chat-api
export async function handleOnCacheEntryAdded<T, K extends BaseQueryFn, V, I extends string>(
  projectTag: ProjectTag,
  api: QueryCacheLifecycleApi<T, K, V, I>,
  operation: (connectedSocket: Socket) => Promise<void>,
) {
  const { cacheDataLoaded, cacheEntryRemoved } = api
  try {
    // wait for the initial query to resolve before proceeding
    await cacheDataLoaded

    const socket = await getSocket(projectTag)

    await operation(socket)
  } catch {
    // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
    // in which case `cacheDataLoaded` will throw
  }
  // cacheEntryRemoved will resolve when the cache subscription is no longer active
  await cacheEntryRemoved
  // perform cleanup steps once the `cacheEntryRemoved` promise resolves
}
