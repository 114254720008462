/* eslint-disable import/no-unresolved */
// eslint-disable-next-line import/no-duplicates
import { Chart, LegendItem, Plugin } from 'chart.js'
// eslint-disable-next-line import/no-duplicates
import { UpdateMode } from 'chart.js/dist/types'
// eslint-disable-next-line import/no-duplicates
import { AnyObject } from 'chart.js/dist/types/basic'

export const legendExtractorPlugin: Plugin = {
  id: 'legendExtractor',

  afterUpdate: (chart: Chart, _args: { mode: UpdateMode }, options: AnyObject) => {
    if (typeof chart.options?.plugins?.legend?.labels?.generateLabels === 'undefined') {
      console.warn('Unable to extract legend, check if Legend plugin is installed')
      return
    }
    const items = chart.options.plugins.legend.labels.generateLabels(chart)
    const legendOptions = options as LegendExtractorOptions
    legendOptions.setLegendItems(items)
  },
}
export interface LegendExtractorOptions extends AnyObject {
  setLegendItems: (items: LegendItem[]) => void
}
