import { ProductType } from '@pactum/core-backend-types'
import { isObject } from '@shared/utils/type-guards'
import {
  NegotiationEventResultsDto,
  NegotiationEventSupplierDto,
  NegotiationEventSupplierPreviousContactInformationDto,
} from '@procurement/types'

export type NegotiationEvent = {
  id: number
  attachments: NegotiationEventAttachment[]
  buyerContactEmail: string
  buyerContactName: string
  complementaryRequest: boolean
  contractModel: ContractModel | null
  createdAt: string
  currency: string
  deliveryDate: string
  deliveryLocation: string
  externalId: string
  initialAmount: number
  lineItems: NegotiationEventLineItem[]
  negotiatedAmount: number | null
  negotiationStatus: NegotiationStatus
  negotiationsClosedAt: string | null
  negotiationsEndedAt: string | null
  negotiationsExpireDays: number | null
  negotiationsExpireTime: string
  negotiationsStartedAt: string | null
  projectTag: string
  quoteId: string
  status: NegotiationEventStatus
  statusLabel: string
  statusUpdatedAt: string
  suppliers: NegotiationEventSupplier[]
  title: string
  uniqueName: string
  winningNegotiationConfirmedAt: string | null
}

export interface NegotiationEventListItem
  extends Pick<
    NegotiationEvent,
    | 'buyerContactEmail'
    | 'createdAt'
    | 'currency'
    | 'id'
    | 'initialAmount'
    | 'negotiationStatus'
    | 'negotiationsEndedAt'
    | 'negotiationsExpireDays'
    | 'negotiationsExpireTime'
    | 'negotiationsStartedAt'
    | 'projectTag'
    | 'statusLabel'
    | 'statusUpdatedAt'
    | 'title'
    | 'uniqueName'
  > {
  contractLength?: number | null
  contractStartDate?: string | null
  improvement?: number | null
  initialPaymentTerms?: PaymentTerms | null
  negotiatedPaymentTerms?: PaymentTerms | null
  previousContractLength?: number | null
  savings?: number | null
  suppliers: NegotiationEventSupplierLight[]
}

export type NegotiationEventStatus =
  | 'IMPORTED'
  | 'INVALIDATED'
  | 'WITHDRAWN'
  | 'COMPLETED'
  | 'UPDATED'
  | 'UPDATE_VERIFIED'
  | 'NEGOTIATIONS_CREATED'
  | 'NEGOTIATIONS_STARTED'
  | 'NEGOTIATIONS_ENDED'
  | 'NEGOTIATIONS_CLOSED'
  | 'LISTED_FOR_NEGOTIATION'

export type NegotiationStatus =
  | 'READY'
  | 'WITHDRAWN'
  | 'IN_PROGRESS'
  | 'AGREEMENT_REACHED'
  | 'SIGNED'
  | 'EXPIRED'
  | 'DECLINED'
  | null

export type NegotiationSummaryStatus = 'EXPIRED' | 'DECLINED' | 'AGREEMENT_REACHED' | 'IN_PROGRESS'

type BaseFeedback = {
  reason: string
}

export type UnsuitablePaymentTermsFeedback = {
  preferredPaymentDays: string
  preferredGeneralDiscount?: number
}

export type InvalidSupplierContactInformationFeedback = {
  suggestedContactEmail: string
  suggestedContactName: string
}

export type UnsuitableIncentivesFeedback = {
  preferredIncentive: string
}

export type UnsuitableDeliveryDateFeedback = {
  reason: string
  preferredDeliveryDate: string
}

export type NoAgreementFeedback = BaseFeedback

export type NoIncentivesSelectedFeedback = BaseFeedback

export type UnableToOfferBetterTermsFeedback = BaseFeedback

export type InvalidBaselineFeedback = BaseFeedback

export type UntenableRequestFeedback = BaseFeedback

export type QuoteAlreadyDeliveredFeedback = BaseFeedback

export type ZeroDiscountFeedback = BaseFeedback

export type UnsuitableDeliveryLocationFeedback = BaseFeedback

export type NegotiationFeedback = {
  noAgreement?: NoAgreementFeedback
  zeroDiscount?: ZeroDiscountFeedback
  invalidBaseline?: InvalidBaselineFeedback
  untenableRequest?: UntenableRequestFeedback
  quoteAlreadyDelivered?: QuoteAlreadyDeliveredFeedback
  unsuitableIncentives?: UnsuitableIncentivesFeedback
  noIncentivesSelected?: NoIncentivesSelectedFeedback
  unsuitableDeliveryDate?: UnsuitableDeliveryDateFeedback
  unsuitablePaymentTerms?: UnsuitablePaymentTermsFeedback
  unsuitableDeliveryLocation?: UnsuitableDeliveryLocationFeedback
  unableToOfferBetterTerms?: UnableToOfferBetterTermsFeedback
  invalidSupplierContactInformation?: InvalidSupplierContactInformationFeedback
}

export type NegotiationEventAttachment = {
  contentLength: number
  contentType: string
  date: string
  externalId?: string | null
  fileName: string
  id: number
  isVisibleToSupplier: boolean
  lineItemId?: number | null
  supplierId?: number | null
  tag: AttachmentTagEnum
}

export type NegotiationEventLineItem = {
  id: number
  name: string
  description: string
  category: string
  quantity: number
  unit: string
  initialUnitPrice: number
  currency: string
  numberInCollection: number
  deliveryLocation: string | null
  incoterm?: string | null
  negotiationSettings?: SupplierLineItemNegotiationSettings | null
}

export type PaymentDayObject =
  | {
      type: 'net'
      netDays: number
      externalId?: string | null | undefined
    }
  | {
      type: 'eom'
      netDays: number
      averageDays: number
      eomDays: number
      externalId?: string | null | undefined
    }
  | {
      type: 'eow'
      netDays: number
      averageDays: number
      eowDays: number
      externalId?: string | null | undefined
    }

export const isPaymentDaysObject = (input: unknown): input is PaymentDayObject => {
  return isObject(input) && (input.type === 'net' || input.type === 'eom' || input.type === 'eow')
}

export const isEomPaymentDaysObject = (
  input: unknown,
): input is Extract<PaymentDayObject, { type: 'eom' }> => {
  return isObject(input) && input.type === 'eom'
}

export const isEowPaymentDaysObject = (
  input: unknown,
): input is Extract<PaymentDayObject, { type: 'eow' }> => {
  return isObject(input) && input.type === 'eow'
}

export type PaymentTerms = {
  discount: number | null
  discountDaysObject: PaymentDayObject | null
  paymentDaysObject: PaymentDayObject | null
}

export interface NegotiationEventSupplier
  extends Pick<NegotiationEventSupplierDto, 'origin' | 'results'> {
  id: number
  attachments: NegotiationEventAttachment[]
  baselineSupplierId?: number
  contactName: string | null
  email: string
  externalId: string
  initialPaymentTerms: PaymentTerms
  isBaseline?: boolean
  isEnabledForNegotiation: boolean
  isOriginalSupplier: boolean
  isWinner: boolean
  lineItems: NegotiationEventSupplierLineItem[]
  locale: string | null
  maxAcceptablePrice: number | null
  name: string
  negotiation: NegotiationSummary | null
  negotiationSettings: NegotiationSettings | null
  phone: string | null
  previousContactInformation: NegotiationEventSupplierPreviousContactInformationDto | null
  totalPrice: number | null
}

export type NegotiationEventSupplierLight = {
  id: number
  name: string
  results: Pick<NegotiationEventResultsDto, 'feedback'> | null
}

export type NegotiationEventSupplierLineItem = {
  name: string
  description: string
  category: string
  quantity: number
  unit: string
  quotedUnitPrice: number | null
  negotiatedUnitPrice: number | null
  negotiatedConsignmentInventory: boolean | null
  currency: string
  numberInCollection: number
  deliveryLocation: string | null
  incoterm?: Incoterm | null
  negotiationSettings?: SupplierLineItemNegotiationSettings | null
  minimumOrderQuantity?: number | null
}

export type SupplierLineItemNegotiationSettings = {
  acceptableIncoterms?: string[]
  consignmentAgreementStatus?: ConsignmentAgreementStatusEnum
  maximumAcceptableMoq?: number | null
}

export type NegotiationEventAttachmentInput = Omit<
  NegotiationEventAttachment,
  'id' | 'negotiationEvent' | 'storagePath'
>

export type CreateNegotiationEventSupplierDto = {
  contactName: string | null
  email: string
  externalId: string | null
  initialPaymentTerms: PaymentTerms
  isEnabledForNegotiation?: boolean | null
  locale?: string | null
  name: string
  phone?: string | null
}

export type UpdateNegotiationEventSupplierLineItemDto = {
  incoterm?: Incoterm | null
  numberInCollection: number
  quantity: number
  unitPrice?: number
}

export type UpdateNegotiationEventSupplierDto = Partial<
  Omit<CreateNegotiationEventSupplierDto, 'initialPaymentTerms'>
> & {
  initialPaymentTerms?: Partial<PaymentTerms>
  negotiationSettings?: NegotiationSettings | null
  lineItems?: UpdateNegotiationEventSupplierLineItemDto[] | null
}

export type ContractLengthAlternative = {
  id: number
  contractLengthMonths: number
  priceChangePercentage: number
}

export enum TermsBasis {
  CurrentAgreement = 'CurrentAgreement',
  SupplierQuote = 'SupplierQuote',
}

export type Warranty = {
  benchmark?: number | null
  minLength?: number | null
  maxLength?: number | null
}

export type Rebates = {
  benchmark?: number | null
  minimum?: number | null
  target?: number | null
}

export type ContractIncoterms = {
  benchmark?: Incoterm | null
  acceptable?: Incoterm[] | null
}

export type NegotiationSettings = {
  contractIncoterms?: ContractIncoterms | null
  previousContractLength?: number | null
  contractLength?: number | null
  contractLengthAlternatives?: ContractLengthAlternative[]
  contractStartDate?: string | null
  incentives?: string[]
  maxPaymentDays?: number | null
  maxTotalPrice?: number | null
  maxTotalPriceIncreasePercent?: number | null
  minPaymentDays?: number | null
  minTotalPriceDiscountPercent?: number | null
  rebates?: Rebates | null
  termsBasis?: TermsBasis | null
  totalPriceTarget?: number | null
  warranty?: Warranty | null
}

export type NegotiationSummary = {
  id: number
  outcomes: Outcomes
  chatUpdatedAt: string
  cesFeedback: CesFeedback
  chatLinks: ChatLinks
  createdAt: string
  updatedAt: string
  status: NegotiationSummaryStatus
}

export type OutcomesPaymentTerms = {
  discount: number | null
  discountDaysObject: PaymentDayObject | null
  paymentDaysObject: PaymentDayObject | null
}

type OutcomesPricingLineItem = {
  numberInCollection: number
  quantity: number
  unitPrice: number
}

type OutcomesPricing = {
  lineItems: OutcomesPricingLineItem[]
  total: number
}

type Outcomes = {
  savings: number
  improvement: number
  pricing: OutcomesPricing
  paymentTerms: OutcomesPaymentTerms
  incentives?: string[]
  contractStartDate?: string
  contractEndDate?: string
  contractLength?: number
  deliveryDate?: string | null
  savingsBreakdown?: SavingsBreakdown
  rebate?: number
  incoterm?: Incoterm
  warranty?: number
  feedback?: NegotiationFeedback
}

type SavingsBreakdown = {
  price?: number
  workingCapital?: number
  rebate?: number
  warranty?: number
  incoterm?: number
  contractLength?: number
}

type CesFeedback = {
  shown: boolean | null
  score: number | null
  comment: string | null
}

type ChatLinks = {
  readOnly: string
  restartable: string
  supplier: string
}

export type FormField = {
  id: string
  label: string
  visible?: boolean
  required?: boolean
  disabled?: boolean
}

type Incentive = {
  id: string
  primaryWording: string
  secondaryWording?: string
  status?: string
}

type ContractModel = {
  id: string
  properties: Record<string, unknown>
}

export enum AttachmentTagEnum {
  AdditionalInformationForSuppliers = 'additional_information_for_suppliers',
  UpdatedQuote = 'updated_quote',
  Results = 'results',
  ExistingAgreement = 'existing_agreement',
}

export type UseCase = 'purchasing' | 'contractCost' | 'integratedPostBid'

export type Incoterm = string

export type TermsBasisOption = {
  name: string
  value: TermsBasis
}
export type TermsBasisOptions = TermsBasisOption[]

export type PurchasingUIConfig = {
  suite: {
    showImportRequisitionFromCoupaButton: boolean
    showImportSourcingEventFromCoupaButton: boolean
    showAddRequisitionButton?: boolean
    showEditRequisitionButton?: boolean
    showWithdrawRequisitionButton?: boolean
    hideDeleteRequisitionButton?: boolean
    hideSpreadsheetUpload?: boolean
    hideAddSupplierButton?: boolean
    onlySubmitAttachments?: boolean
    usingStandardSequences?: boolean
    signeeOptions?: string[]
    categoryOptions?: string[]
    incentivesOptions?: Incentive[]
    deliveryLocationOptions?: string[]
    requisitionFormFields?: FormField[]
    allowedIncoterms?: Incoterm[]
    defaultIncoterm?: Incoterm
    termsBasisOptions?: TermsBasisOptions
    paymentDaysOptions: number[] | PaymentDayObject[]
    localeOptions?: string[]
    languageOptions?: NegotiationLanguage[]
    negotiationsExpireDays: number
    defaultContractModelId?: keyof typeof ContractModelTypes
    useCase?: UseCase
    productType: ProductType
    moqEnabled?: boolean
    spendEnabled?: boolean
    isReadonly?: boolean
  }
}

export type NegotiationLanguage = {
  locale: string
  name: string
}

export const ContractModelTypes = {
  SignedOrderForm: 'SignedOrderForm',
  SignedOrderFormAmendment: 'SignedOrderFormAmendment',
} as const

export enum ConsignmentAgreementStatusEnum {
  Existing = 'Existing',
  Needed = 'Needed',
  NoAnswer = 'NoAnswer',
}
