import { TextFieldCtrl } from '@components/Form'
import styled from '@emotion/styled'
import { zodResolver } from '@hookform/resolvers/zod'
import { useDebounce } from '@hooks'
import { Button } from '@mui/material'
import { Branding, BrandingStatus } from '@pactum/core-backend-types'
import { useCreateBrandingMutation, useUpdateBrandingMutation } from '@store/branding'
import isEqual from 'lodash/isEqual'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
`

interface BrandingFormProps {
  organizationId: string
  disabled: boolean
  existingBranding?: Branding
  onError?: (message: string) => void
  onBrandingSaved?: (b: Branding) => void
}

const brandingSchema = z.object({
  label: z.string().min(1, 'Label is required'),
  status: z.nativeEnum(BrandingStatus).optional(),
  description: z.string().optional(),
})

type BrandingPayload = z.infer<typeof brandingSchema>

export const BrandingForm = ({
  onBrandingSaved,
  organizationId,
  onError,
  existingBranding,
  disabled,
}: BrandingFormProps) => {
  const [createBranding] = useCreateBrandingMutation()
  const [updateBranding] = useUpdateBrandingMutation()

  const form = useForm<BrandingPayload>({
    resolver: zodResolver(brandingSchema),
    values: existingBranding as BrandingPayload,
  })

  const {
    formState: { errors },
  } = form

  const onSave = async () => {
    const data = form.getValues()
    if (isEqual(existingBranding, data)) {
      return
    }
    const response = existingBranding
      ? await updateBranding({ ...existingBranding, ...data })
      : await createBranding({ ...data, organizationId })

    if ('error' in response) {
      onError?.('Failed to save business identity metadata')
    } else {
      return onBrandingSaved?.(response.data)
    }
  }

  const onSaveDebounced = useDebounce(onSave, 1000)

  useEffect(() => {
    if (existingBranding) {
      const subscription = form.watch(onSaveDebounced)
      return () => {
        subscription.unsubscribe()
      }
    }
  }, [form, existingBranding, onSaveDebounced])

  useEffect(() => {
    return () => {
      onSaveDebounced.cancel()
    }
  }, [onSaveDebounced])

  return (
    <FormProvider {...form}>
      <TextFieldCtrl
        name='label'
        label='Business identity'
        variant='outlined'
        fullWidth
        margin='normal'
        required={true}
        error={!!errors.label}
        helperText={errors.label ? errors.label.message : ''}
        disabled={disabled}
      />

      <TextFieldCtrl
        name='description'
        label='Description'
        multiline
        minRows={3}
        variant='outlined'
        fullWidth
        margin='normal'
        disabled={disabled}
      />
      {!existingBranding && !disabled && (
        <Actions>
          <Button variant='contained' color='primary' onClick={onSave}>
            Save new business identity
          </Button>
        </Actions>
      )}
    </FormProvider>
  )
}
