import z from 'zod'

export const AddOrEditUserSchema = z.object({
  name: z.string().min(1),
  email: z.string().email(),
  role: z.string().min(1),
  workspaces: z.array(z.number()).nullable(), // list of project ids for regular user or null for admin
})

export type AddOrEditUserFormSchema = z.infer<typeof AddOrEditUserSchema>
