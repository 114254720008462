import { NegotiationEventSupplierFormData } from './schema'

export const targetPriceValid = (form: NegotiationEventSupplierFormData) => {
  const targetPrice = form.negotiationSettings?.totalPriceTarget

  if (typeof targetPrice !== 'number') {
    return true
  }

  const totalBidPrice = form.lineItems.reduce(
    (acc, item) => acc + (item.quotedUnitPrice ?? 0) * (item.quantity ?? 0),
    0,
  )

  return targetPrice < totalBidPrice
}

export const validateNegotiationEventSupplierForm = (data: NegotiationEventSupplierFormData) => {
  if (!targetPriceValid(data)) {
    throw new Error(
      'Target price must either be less than the current total price of the bid, or empty (meaning Pactum determines optimal targets to maximize savings)',
    )
  }
}
