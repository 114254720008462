import { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useSnackbar } from 'notistack'
import { ScenarioListItem } from '@common/dto'
import { isBackendApiErrorResponse, useDeleteScenarioMutation } from 'src/merchandising/store'
import { useActiveProject } from '@shared/hooks/useActiveProject'

interface Props {
  scenario: ScenarioListItem
}

export const DeleteScenario = ({ scenario }: Props) => {
  const { activeProjectTag } = useActiveProject()
  const { enqueueSnackbar } = useSnackbar()
  const [deleteScenario] = useDeleteScenarioMutation()
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false)

  const handleDeleteScenario = () => {
    setConfirmationDialogOpen(false)

    deleteScenario({ projectTag: activeProjectTag, scenarioId: scenario.id })
      .unwrap()
      .catch((e) => {
        enqueueSnackbar(
          isBackendApiErrorResponse(e) ? e.data.message : 'Scenario deletion failed',
          {
            variant: 'error',
          },
        )
      })
  }

  const deleteTooltip = !scenario.isOwner
    ? 'Only scenario owner can delete scenarios'
    : scenario.submittedAt
      ? 'Submitted scenarios cannot be deleted'
      : ''

  return (
    <>
      <Tooltip title={deleteTooltip}>
        <span>
          <IconButton
            color='primary'
            disabled={!!scenario.submittedAt || !scenario.isOwner}
            onClick={() => setConfirmationDialogOpen(true)}
          >
            <DeleteOutlineIcon fontSize='small' />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog open={confirmationDialogOpen}>
        <DialogTitle>Delete scenario?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography color={'text.secondary'} component='span'>
              Are you sure you want to delete{' '}
              <Typography fontWeight='bold' component='span' color='inherit'>
                {scenario.name}{' '}
              </Typography>
              scenario?
            </Typography>
          </DialogContentText>
          <DialogActions>
            <Button onClick={() => setConfirmationDialogOpen(false)}>No</Button>
            <Button onClick={handleDeleteScenario}>Yes</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  )
}
