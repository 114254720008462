import { alpha, Paper, styled, Typography } from '@mui/material'
import { SingleFileUpload, SingleFileUploadProps } from '../SingleFileUpload/SingleFileUpload'
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded'

export const SideBlockFileUpload = (props: SingleFileUploadProps) => (
  <StyledBackground elevation={0}>
    <StyledFileUpload {...props}>
      <UploadFileRoundedIcon fontSize='large' sx={{ width: '100%', mt: 1.5, mb: 0.5 }} />
      <Typography fontSize='0.625rem' sx={{ width: '100%' }}>
        Drag the filled in template here, or
      </Typography>
      <Typography fontSize='0.625rem' sx={{ mb: 1.5, width: '100%', color: 'link.main' }}>
        select from your computer
      </Typography>
    </StyledFileUpload>
  </StyledBackground>
)

const StyledFileUpload = styled(SingleFileUpload)(({ theme }) => ({
  background: 'transparent',
  height: '104px',
  border: `1px dashed ${theme.palette.grey.A400}`,
  cursor: 'pointer',
}))

const StyledBackground = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(0.5),
  background: alpha(theme.palette.borders.main, 0.3),
}))
