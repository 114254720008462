import { useFormatter } from '@shared/hooks'
import React, { ReactElement } from 'react'
import { Grid, Typography } from '@mui/material'
import { CommercialTermsNegotiation } from '@campaigns/types'
import { dateFormats, Formatter } from '@utils'
import { CommercialTermsTooltip } from '../../../components/CommercialTermsTooltip'
import { isNegotiationCompleted } from '../../../utils/isNegotiationCompleted'

interface Props {
  negotiation: CommercialTermsNegotiation
  children: ReactElement
}

const NO_VALUE_TEXT = 'N/A'

export const NegotiationDatesTooltip = ({ negotiation, children }: Props) => {
  const formatter = useFormatter()

  if (!isNegotiationCompleted(negotiation)) {
    return <>{children}</>
  }

  const sent = negotiation.sentOut
    ? formatToolTipDate(negotiation.sentOut, formatter)
    : NO_VALUE_TEXT

  const started = negotiation.linkOpenedAt
    ? formatToolTipDate(negotiation.linkOpenedAt, formatter)
    : NO_VALUE_TEXT

  const completed = negotiation.completedAt
    ? formatToolTipDate(negotiation.completedAt, formatter)
    : NO_VALUE_TEXT

  return (
    <CommercialTermsTooltip
      title={<TooltipText sent={sent} started={started} completed={completed} />}
    >
      {children}
    </CommercialTermsTooltip>
  )
}

interface TooltipTextProps {
  sent: string
  started: string
  completed: string
}

const TooltipText = ({ sent, started, completed }: TooltipTextProps) => {
  return (
    <Grid container sx={{ m: 1 }}>
      <Grid item xs={4}>
        <Typography variant='body2'>Sent:</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
          {sent}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant='body2'>Started on:</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
          {started}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant='body2'>Completed:</Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
          {completed}
        </Typography>
      </Grid>
    </Grid>
  )
}

const formatToolTipDate = (dateString: string | null, formatter: Formatter) => {
  if (!dateString) {
    return NO_VALUE_TEXT
  }
  const date = formatter.date(new Date(dateString), dateFormats.shortDate, {
    slashSeparators: true,
  })
  const time = formatter.date(new Date(dateString), dateFormats.shortTime, {
    slashSeparators: true,
  })

  return `${date}, ${time}`
}
