import posthog from 'posthog-js'
import { getCookieConsentValue } from 'react-cookie-consent'
import { shouldShowCookieConsent } from '@pactum/frontend-utils'

declare let window: Window & { _DATADOG_SYNTHETICS_BROWSER?: unknown }

export const initPosthog = () => {
  posthog.init('phc_zKPFXER6V7MFdE0PcftSdC8EYfiLyevlZyhEAUql4P', {
    api_host: 'https://eu.posthog.com',
    session_recording: {
      maskAllInputs: false,
      maskInputOptions: { password: true },
    },
    persistence: cookieConsentYes() ? 'localStorage+cookie' : 'memory',
    loaded: function (hog) {
      if (!shouldCapture()) {
        hog.opt_out_capturing()
      }
    },
  })
}

const shouldCapture = () => {
  if (window.location.host.includes('127.0.0.1') || window.location.host.includes('localhost')) {
    return false
  }

  if (!cookieConsentYes()) {
    return false
  }

  const isTestRun = window?._DATADOG_SYNTHETICS_BROWSER !== undefined
  return !isTestRun
}

const cookieConsentYes = () => {
  return !(shouldShowCookieConsent() && getCookieConsentValue() !== 'true')
}
