import { Grid, Typography } from '@mui/material'
import { ProductType } from '@pactum/core-backend-types'
import { PurchasingUIConfig } from '../../../../store/types'
import { Currency } from './Currency'
import { PurchasingLineItemsTable } from './PurchasingLineItemsTable'
import { ContractCostLineItemsTable } from './ContractCostLineItemsTable'
import { NegotiateInPercentages } from './NegotiateInPercentages'
import { TotalMaxPrice } from './TotalMaxPrice'
import { TotalMaxPriceThresholds } from './TotalMaxPriceThresholds'
import { FormRow, HideableBlock } from '../../../FormCommon/FormCommon'
import { useContext } from 'react'
import { NegotiationEventFormContext } from '@procurement-shared/NegotiationEventForm/NegotiationEventFormContext'

interface Props {
  allowedIncoterms?: PurchasingUIConfig['suite']['allowedIncoterms']
  defaultIncoterm?: PurchasingUIConfig['suite']['defaultIncoterm']
  termsBasisOptions?: PurchasingUIConfig['suite']['termsBasisOptions']
  productType?: PurchasingUIConfig['suite']['productType']
  moqEnabled?: PurchasingUIConfig['suite']['moqEnabled']
  spendEnabled?: PurchasingUIConfig['suite']['spendEnabled']
}

export const LineItems = ({
  allowedIncoterms,
  defaultIncoterm,
  termsBasisOptions,
  productType,
  moqEnabled,
  spendEnabled,
}: Props) => {
  const { visibleFields } = useContext(NegotiationEventFormContext)
  const isRebatesNegotiation = visibleFields.includes('rebatesEnabled') && !spendEnabled
  const isPurchasing = productType === ProductType.Purchasing

  return (
    <>
      <Currency required isRebatesNegotiation={isPurchasing ? false : isRebatesNegotiation} />
      <HideableBlock hidden={!visibleFields.includes('lineItems') && !isPurchasing}>
        <FormRow>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Typography variant='subtitle2' fontSize='0.875rem'>
              Line item negotiation
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {isPurchasing ? (
              <PurchasingLineItemsTable
                productType={productType}
                allowedIncoterms={allowedIncoterms}
                defaultIncoterm={defaultIncoterm}
              />
            ) : (
              <ContractCostLineItemsTable
                productType={productType}
                allowedIncoterms={allowedIncoterms}
                defaultIncoterm={defaultIncoterm}
                moqEnabled={moqEnabled}
              />
            )}
          </Grid>
        </FormRow>
      </HideableBlock>
      <TotalMaxPrice />
      <TotalMaxPriceThresholds />
      <NegotiateInPercentages termsBasisOptions={termsBasisOptions} />
    </>
  )
}
