import { PropsWithChildren } from 'react'
import { LoadingPage } from 'src/main/pages/LoadingPage'
import { ErrorPage } from '@pages/ErrorPage'
import { useAuthEntrypoint } from './useAuthEntrypoint'

export const Authentication = ({ children }: PropsWithChildren<{}>) => {
  const { isLoading, error } = useAuthEntrypoint()

  if (isLoading && !error) {
    return <LoadingPage />
  }

  if (error) {
    return (
      <ErrorPage title={error}>
        <p>
          There was an unexpected error while loading the page. Pactum team was notified and is
          working on it.
        </p>
      </ErrorPage>
    )
  }

  return <>{children}</>
}
