import { Box } from '@mui/material'
import { PactumLoader } from 'src/shared/components/PactumLoader'

export const LoadingPage = () => {
  return (
    <Box
      sx={{
        height: '50vh',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <PactumLoader />
    </Box>
  )
}
