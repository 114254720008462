import { GridColDef } from '@mui/x-data-grid-pro'
import { capitalizeFirstLetter, dateFormats, Formatter } from '@utils'
import { CaptionedValueCell } from '@components/table'
import { endOfDay, parseISO } from 'date-fns'
import { Chip } from '@mui/material'
import { RFPActions } from './RFPActions'
import { RequestForPrice, RequestForPriceCreatedFrom, RequestForPriceStatus } from '../../types'
import { removeTimezone } from '@logistics/utils/dateHelpers'

export const ACTIONS_BUTTON_WIDTH = 144
export const STATUS_COLUMN_WIDTH = 150

export const createRFPColumns = (formatter: Formatter): GridColDef<RequestForPrice>[] => [
  {
    field: 'name',
    headerName: 'RFP Name',
    disableColumnMenu: true,
    sortable: false,
    flex: 0.3,
    renderCell: ({ row }) => (
      <CaptionedValueCell
        mainContent={row.name}
        captionContent={`ID: ${row.id}`}
        spacing={0}
        tooltip={{ id: row.id.toString(), title: row.name, placement: 'top-start' }}
      />
    ),
  },

  {
    field: 'lastModified',
    headerName: 'Last Modified',
    disableColumnMenu: true,
    sortable: false,
    flex: 0.15,
    // Removing timezone to display in UTC.
    valueGetter: ({ row }) => removeTimezone(parseISO(row.updated_at)),
    renderCell: ({ value }) => formatter.date(value, dateFormats.longDate),
  },
  {
    field: 'pricedLanes',
    headerName: 'Priced Lanes',
    disableColumnMenu: true,
    sortable: false,
    flex: 0.1,
    renderCell: ({ row }) => `${row.priced_lane_count} / ${row.total_lane_count}`,
  },
  {
    field: 'contractStartDate',
    headerName: 'Contract start date',
    disableColumnMenu: true,
    sortable: false,
    flex: 0.15,
    valueGetter: ({ row }) => removeTimezone(new Date(row.contract_start)),
    renderCell: ({ value }) => formatter.date(value, dateFormats.longDate),
  },
  {
    field: 'contractEndDate',
    headerName: 'Contract end date',
    disableColumnMenu: true,
    sortable: false,
    flex: 0.15,
    valueGetter: ({ row }) => removeTimezone(new Date(row.contract_end)),
    renderCell: ({ value }) => formatter.date(value, dateFormats.longDate),
  },
  {
    field: 'deadline',
    headerName: 'Deadline',
    disableColumnMenu: true,
    sortable: false,
    flex: 0.15,
    valueGetter: ({ row }) => removeTimezone(new Date(row.expires_at)),
    renderCell: ({ value }) => formatter.date(value, dateFormats.longDate),
  },

  {
    field: 'status',
    headerName: 'Status',
    disableColumnMenu: true,
    sortable: false,
    width: STATUS_COLUMN_WIDTH,
    renderCell: ({ row }) => {
      // TODO: https://pactum.atlassian.net/browse/LP-1619
      const currentDate = Date.now()
      const expiryDate = endOfDay(parseISO(row.expires_at)).getTime()
      const isExpired = currentDate > expiryDate
      const atLeastOneLaneIsPriced = row.priced_lane_count > 0
      if (isExpired && atLeastOneLaneIsPriced) {
        return <Chip size='medium' {...{ label: 'Completed', color: 'success' }} />
      }
      if (isExpired) {
        return <Chip size='medium' {...{ label: 'Expired', color: 'warning' }} />
      }

      const { color, label } = statusMapping[row.status as RequestForPriceStatus]
      return <Chip size='medium' color={color as 'primary'} label={label} />
    },
  },
  {
    field: 'created_from',
    headerName: 'Created From',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: ({ row }) =>
      row.created_from === RequestForPriceCreatedFrom.API
        ? row.created_from
        : capitalizeFirstLetter(row.created_from.toLowerCase()),
  },
  {
    field: 'created_by',
    headerName: 'Created By',
    disableColumnMenu: true,
    sortable: false,
    valueGetter: ({ row }) =>
      row.created_from === RequestForPriceCreatedFrom.API ? '' : row.created_by,
    renderCell: ({ row, value }) => (
      <CaptionedValueCell
        mainContent={value as string}
        spacing={0}
        tooltip={{ id: row.id.toString(), title: row.created_by, placement: 'top-start' }}
      />
    ),
  },
  {
    field: '__actions__',
    headerName: 'Actions',
    cellClassName: 'actions-cell',
    disableColumnMenu: true,
    sortable: false,
    width: ACTIONS_BUTTON_WIDTH + 20,
    renderCell: ({ row }) => <RFPActions rfp={row} />,
  },
]

const statusMapping: Record<RequestForPriceStatus, { label: string; color: string }> = {
  [RequestForPriceStatus.PENDING]: { label: 'Pending', color: 'stoneBlue' },
  [RequestForPriceStatus.READY]: { label: 'Ready', color: 'stoneBlue' },
  [RequestForPriceStatus.IN_PROGRESS]: { label: 'In progress', color: 'lightGrey' },
  [RequestForPriceStatus.CLOSED]: { label: 'Closed', color: 'warning' },
  [RequestForPriceStatus.DELETED]: { label: 'Deleted', color: 'warning' },
}
