import { Route } from 'react-router-dom'

import { Layout } from '@components/Layout'
import { PurchasingRoutes } from './routes'
import { locales } from '@common/constants'
import { ProcurementRoutes } from '../../routes'
import { LegacyPageRedirectTo } from '../../components/LegacyPageRedirect/LegacyPageRedirectTo'

export const PurchasingEntryPoint = () => {
  return (
    <>
      <Route element={<Layout locale={locales.us} />}>
        <Route
          path={PurchasingRoutes.REQUISITION_LIST}
          element={<LegacyPageRedirectTo to={ProcurementRoutes.NEGOTIATION_EVENTS} />}
        />
        <Route
          path={PurchasingRoutes.REQUISITION_DETAILS}
          element={<LegacyPageRedirectTo to={ProcurementRoutes.NEGOTIATION_EVENT_DETAILS} />}
        />
        <Route
          path={PurchasingRoutes.DASHBOARD}
          element={<LegacyPageRedirectTo to={ProcurementRoutes.DASHBOARD} />}
        />
      </Route>
    </>
  )
}
