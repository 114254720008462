import { GenericSuiteModule, MerchandisingSuiteModule } from 'src/main/types/suite-module'
import { merchandisingBaseApi } from './store/api'
import { createApi } from '@reduxjs/toolkit/query/react'
import { MerchandisingEntrypoint } from './entrypoint'
import { costDecreaseNavicationItems, MerchandisingRoutes } from './routes'
import { ProductType, ProjectListEntity } from '@pactum/core-backend-types'
import { renderProjectAsBaselineModule } from '@shared/projectsSpecific'
import { SuitePermission } from '@common/types'

export const merchandisingSuiteModule: MerchandisingSuiteModule = {
  name: 'merchandising',
  basePath: 'merchandising',
  api: merchandisingBaseApi as unknown as ReturnType<typeof createApi>,
  entrypoint: MerchandisingEntrypoint,
  navigationItems: [],
}

export const costDecreaseModule: GenericSuiteModule = {
  name: 'cost_decrease',
  basePath: 'merchandising',
  api: merchandisingBaseApi as unknown as ReturnType<typeof createApi>,
  entrypoint: MerchandisingEntrypoint,
  projectSwitchPath: (_?: Set<SuitePermission>) => MerchandisingRoutes.SCENARIOS_LIST,
  isProjectCompatible: (project: ProjectListEntity) =>
    !renderProjectAsBaselineModule(project) &&
    project.productType === ProductType.RetailCostDecrease &&
    project.compatibleWithNegotiationSuite,
  navigationItems: costDecreaseNavicationItems,
}
