import { Box, styled, Typography } from '@mui/material'
import { ContractedLanesNegotiationUnit, ContractedLanesUnit } from '../types'
import { DataGrid, dataGridProps } from '@components/table'
import { useMemo } from 'react'
import { createRfpLanesColumns } from './rfpLaneColumns'
import { useFormatter } from '@shared/hooks'

export const RFPDetailsPanel = ({
  negotiations,
  expiresAt,
}: {
  negotiations: ContractedLanesUnit['negotiations']
  expiresAt: Date | null
}) => {
  const formatter = useFormatter()
  const columns = useMemo(() => createRfpLanesColumns(expiresAt, formatter), [expiresAt, formatter])

  return (
    <Box px={6} pb={1}>
      <Separator py={1} />
      <Typography variant='h2' pb={2}>
        Negotiations ({negotiations.length} carriers)
      </Typography>
      <DataGrid
        {...dataGridProps}
        slots={{ ...dataGridProps.slots }}
        autoHeight={false}
        columns={columns}
        rows={negotiations}
        getRowId={(row: ContractedLanesNegotiationUnit) => row.negotiationUnit.id}
      />
      <Separator py={1} />
    </Box>
  )
}

const Separator = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '1px',
  borderTop: `1px solid ${theme.palette.borders.main}`,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}))
