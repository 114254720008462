import { alpha, Button, ButtonProps, lighten, styled } from '@mui/material'

export const PrimaryButton = (props: ButtonProps) => (
  <PrimaryStyleButton size='large' variant='contained' {...props} />
)
export const OutlinedButton = (props: ButtonProps) => (
  <OutlinedStyleButton size='large' variant='outlined' {...props} />
)

const PrimaryStyleButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  flexShrink: 0,
  '&:hover': {
    backgroundColor: lighten(theme.palette.primary.dark, 0.2),
  },
  '&:disabled': {
    backgroundColor: lighten(theme.palette.primary.dark, 0.2),
  },
}))

const OutlinedStyleButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  flexShrink: 0,
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.2),
  },
  '&:disabled': {
    backgroundColor: alpha(theme.palette.common.white, 0.2),
  },
}))
