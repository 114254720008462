import React, { MouseEvent, useState } from 'react'
import { Button, ButtonProps, Menu, MenuItem, Stack } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

interface CurrencySelectProps extends ButtonProps {
  selected: string
  currencies: { label?: string; value: string }[]
  onCurrencyChange: (currency: string) => {}
}

export const CurrencySelect: React.FC<CurrencySelectProps> = ({
  selected,
  currencies,
  onCurrencyChange,
  ...buttonProps
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelect = (currency: string) => {
    onCurrencyChange(currency)
  }

  const selectedItem = currencies.find(({ value }) => value == selected)

  return (
    <Stack direction='row' spacing={2}>
      <Button {...buttonProps} onClick={handleClick}>
        <span>{selectedItem ? (selectedItem.label ?? selectedItem.value) : '-'}</span>
        <ArrowDropDownIcon />
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {currencies.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              handleSelect(item.value)
              handleClose()
            }}
          >
            {item.label ?? item.value}
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  )
}
