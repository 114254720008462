import {
  AnyAction,
  configureStore,
  createSlice,
  Dispatch,
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
  MiddlewareArray,
  Reducer,
} from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { suiteBaseApi } from '@shared/api/suite-base-api'
import { useSelector } from 'react-redux'
import { SUITE_MODULES } from '../suite-modules'
import { SuiteModule } from '../types/suite-module'

const globalUnavailableHandler: Middleware =
  (api: MiddlewareAPI) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction & { payload: { status: number } }) => {
    if (isRejectedWithValue(action)) {
      if (action.payload?.status === 503) {
        api.dispatch(setWorkspaceUnavailable())
      }
    }

    return next(action)
  }

interface GlobalErrorState {
  type: 'error' | 'workspaceUnavailable' | undefined | null
}

const initialState: GlobalErrorState = { type: undefined }

const globalErrorSlice = createSlice({
  name: 'globalErrors',
  initialState,
  reducers: {
    setWorkspaceUnavailable(state) {
      state.type = 'workspaceUnavailable'
    },

    clearError(state) {
      state.type = undefined
    },
  },
})

export const { setWorkspaceUnavailable, clearError } = globalErrorSlice.actions

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [suiteBaseApi.reducerPath]: suiteBaseApi.reducer,
    ...getApiReducers(SUITE_MODULES),
    globalErrors: globalErrorSlice.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => {
    const middlewares = [suiteBaseApi, ...SUITE_MODULES.map(({ api }) => api)]
      .map(({ middleware }) => middleware)
      .concat(globalUnavailableHandler)
    const allMiddlewares = [...middlewares]
    const defaultMiddleware: MiddlewareArray<Middleware[]> = getDefaultMiddleware()

    return allMiddlewares.reduce((acc, middleware) => acc.concat(middleware), defaultMiddleware)
  },
})

function getApiReducers(modules: SuiteModule[]) {
  const reducers: Record<string, Reducer> = {}
  for (const module of modules) {
    reducers[module.api.reducerPath] = module.api.reducer
  }
  return reducers
}

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

export type SuiteRootState = ReturnType<typeof store.getState>

export type SuiteDispatch = typeof store.dispatch

export const useSuiteSelector = () => useSelector((state: SuiteRootState) => state)

export const useSuiteDispatch = () => store.dispatch as SuiteDispatch
