import { Stack } from '@mui/material'
import { ZeroDiscountFeedback } from '@procurement/store/types'
import { FeedbackTitle } from './FeedbackTitle'
import { FeedbackReasonText } from './FeedbackReasonText'

interface Props {
  feedbackDetails: ZeroDiscountFeedback
}

export const ZeroDiscount = ({ feedbackDetails }: Props) => {
  return (
    <Stack direction='column' gap={3}>
      <FeedbackTitle>Supplier was unable to provide any discount</FeedbackTitle>
      <FeedbackReasonText label='Comment' value={feedbackDetails.reason} />
    </Stack>
  )
}
