import { TextFieldCtrl } from '@components/Form'
import { useLayoutContext } from '@components/Layout'
import { styled } from '@mui/material'
import { LocalizedBranding } from '@pactum/core-backend-types'
import { useUpsertBrandingContentMutation } from '@store/branding'
import isEqual from 'lodash/isEqual'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo } from 'react'
import { FormProvider, useFormContext } from 'react-hook-form'
import { useConfirm } from 'src/main/hooks/useConfirm'
import { z } from 'zod'
import { ColorPicker } from './ColorPicker'
import { ImageUploadWithPreview } from './ImageUploadWithPreview'

interface BrandingContentFormProps {
  brandingId: string
  locale?: string
  disabled: boolean
  existingBrandingContent?: LocalizedBranding
  onError?: (message: string) => void
  onBrandingContentSaved?: (b: LocalizedBranding) => void
  onBrandingContentDeleted?: (locale: string) => void
}

const brandingContentSchema = z.object({
  chatSidebarName: z.string().optional(),
  shortChatName: z.string().optional(),
  clientName: z.string().optional(),
  botAvatar: z.string().optional(),
  botAvatarLabel: z.string().optional(),
  logo: z.string().optional(),
  color: z.string().optional(),
  tableColor: z.string().optional(),
})

type BrandingContentPayload = z.infer<typeof brandingContentSchema>

const Form = styled('form')``

export const BrandingContentForm = forwardRef(
  (
    {
      onBrandingContentSaved,
      brandingId,
      locale,
      onError,
      existingBrandingContent,
      disabled,
    }: BrandingContentFormProps,
    ref,
  ) => {
    const [upsertBrandingContent] = useUpsertBrandingContentMutation()
    const { dialog } = useConfirm()

    const form = useFormContext<BrandingContentPayload>()

    useImperativeHandle(ref, () => form)

    const { setSettings } = useLayoutContext()

    const onSave = useCallback(async () => {
      const data = form.getValues()
      if (isEqual(existingBrandingContent, data)) {
        return
      }

      const formData = new FormData()
      Object.entries(data).forEach(([key, value]) => {
        value && formData.append(key, value)
      })

      const response = await upsertBrandingContent({
        data: formData,
        brandingId,
        locale: locale as string,
      })
      if ('error' in response) {
        onError?.('Failed to save business identity')
      } else {
        return onBrandingContentSaved?.(response.data)
      }
    }, [
      form,
      existingBrandingContent,
      upsertBrandingContent,
      brandingId,
      locale,
      onBrandingContentSaved,
      onError,
    ])

    const isFormDisabled = useMemo(() => {
      return !form.formState.isDirty || !form.formState.isValid || form.formState.isSubmitting
    }, [form.formState.isDirty, form.formState.isSubmitting, form.formState.isValid])

    useEffect(() => {
      setSettings({
        saveBrandingDisabled: isFormDisabled,
        saveBranding: onSave,
      })
    }, [isFormDisabled, setSettings, onSave, form.formState.errors, form.formState.isValid])

    return (
      <>
        <FormProvider {...form}>
          <Form sx={(theme) => ({ mb: theme.spacing(4) })}>
            <TextFieldCtrl
              name='clientName'
              label='Organization Name'
              variant='outlined'
              fullWidth
              margin='normal'
              helperText='Client name is used in emails, chat, etc'
              disabled={disabled}
            />

            <TextFieldCtrl
              name='chatSidebarName'
              label='Name in Supplier Portal'
              variant='outlined'
              fullWidth
              margin='normal'
              helperText='The organization name that is shown in Supplier Portal.'
              disabled={disabled}
              sx={{ mb: 2 }}
            />

            <ImageUploadWithPreview
              name='logo'
              label='Supplier Portal Header image'
              disabled={disabled}
            />
            <ColorPicker
              name={'color'}
              label={'Primary color'}
              disabled={disabled}
              sx={{ mb: 2 }}
            />
            <ColorPicker name={'tableColor'} label={'Secondary color'} disabled={disabled} />

            <TextFieldCtrl
              name='shortChatName'
              label='Supplier Portal URL name'
              variant='outlined'
              fullWidth
              margin='normal'
              helperText='This name is used in the URL of the Supplier Portal.'
              disabled={disabled}
            />
            <TextFieldCtrl
              name='botAvatarLabel'
              label='Chat bot name'
              variant='outlined'
              fullWidth
              margin='normal'
              helperText='This name is used in the URL of the supplier portal.'
              disabled={disabled}
            />
            <ImageUploadWithPreview
              name='botAvatar'
              label='Chat bot icon'
              direction='row'
              disabled={disabled}
              imgStyle={{
                width: '48px',
                height: '48px',
                borderRadius: '50%',
                marginRight: '16px',
              }}
            />
          </Form>
        </FormProvider>
        {!disabled && dialog}
      </>
    )
  },
)
