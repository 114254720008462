import { Checkbox, Divider, MenuItem, SelectChangeEvent } from '@mui/material'
import { FilterSelect } from '@components/FilterSelect'

const ALL_WORKSPACES = 'All workspaces'
const NO_WORKSPACES = 'No workspaces'
const SELECT_ALL = -1

interface Workspace {
  id: number
  name: string
  tag: string
}

/* as for treatNoneSelectedAsAll: for the dashboard, as it's actually a filter, it makes sense
to have it true i.e. if no workspaces are selected, this is like "no filter applied" thus everything displayed
for the workspaces picker in the manage users page, this is not the case, nothing means nothing
*/
export const WorkspaceMultiselect = ({
  allWorkspaces,
  selectedWorkspaceIds,
  setSelectedWorkspaceIds,
  treatNoneSelectedAsAll,
}: {
  allWorkspaces: Workspace[]
  selectedWorkspaceIds: number[]
  setSelectedWorkspaceIds: (workspaces: number[]) => void
  treatNoneSelectedAsAll: boolean
}) => {
  const handleChange = (e: SelectChangeEvent<unknown>) => {
    const castValue = e.target.value as number[]
    if (castValue.includes(SELECT_ALL)) {
      if (selectedWorkspaceIds.length === 0) {
        setSelectedWorkspaceIds(allWorkspaces.map((workspace) => workspace.id))
      } else {
        setSelectedWorkspaceIds([])
      }
      return
    }
    setSelectedWorkspaceIds(castValue)
  }

  const workspacesLabel = () => {
    if (selectedWorkspaceIds.length === allWorkspaces.length) {
      return ALL_WORKSPACES
    } else if (selectedWorkspaceIds.length === 0) {
      if (treatNoneSelectedAsAll) {
        return ALL_WORKSPACES
      } else {
        return NO_WORKSPACES
      }
    } else {
      return `Workspaces: ${selectedWorkspaceIds.length} selected`
    }
  }

  return (
    <FilterSelect
      label={workspacesLabel()}
      multiple={true}
      value={selectedWorkspaceIds}
      onChange={handleChange}
      isValuePresent={(value: unknown) => Array.isArray(value) && value.length > 0}
      renderValue={(_) => workspacesLabel()}
    >
      <MenuItem value={SELECT_ALL}>
        <Checkbox
          size='small'
          indeterminate={
            selectedWorkspaceIds.length > 0 && selectedWorkspaceIds.length < allWorkspaces.length
          }
          checked={selectedWorkspaceIds.length === allWorkspaces.length}
          disableRipple={true}
        />
        {selectedWorkspaceIds.length === 0 ? 'Select all' : 'Unselect all'}
      </MenuItem>
      <Divider />
      {allWorkspaces.map((workspace) => (
        <MenuItem key={workspace.id} value={workspace.id} sx={{ height: '32px' }}>
          <Checkbox
            size='small'
            checked={selectedWorkspaceIds.includes(workspace.id)}
            disableRipple={true}
          />
          {workspace.name}
        </MenuItem>
      ))}
    </FilterSelect>
  )
}
