import { useSnackbar } from 'notistack'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { downloadFile } from '@utils'
import { useLazyDownloadPactNegotiationUnitsCsvQuery } from '../store/pact'
import { enqueIfError } from '@shared/utils/snackbar'
import { NegotiationUnitEntity } from '@common/dto/pact/negotiation-unit-entity'

export type GenericNegotiationUnitAction = 'DOWNLOAD'

export const useGenericNegotiationUnitsActions = (
  negotiationId: number,
  allNegotiationUnitsCount: number,
  negotiationUnits: NegotiationUnitEntity[],
): {
  availableActions: GenericNegotiationUnitAction[]
  handleDownloadNegotiationUnits: () => Promise<void>
} => {
  const { enqueueSnackbar } = useSnackbar()
  const [downloadResultsCsv] = useLazyDownloadPactNegotiationUnitsCsvQuery()
  const { activeProjectTag } = useActiveProject()

  const handleDownloadNegotiationUnits = async () => {
    const ids =
      allNegotiationUnitsCount === negotiationUnits.length
        ? undefined
        : negotiationUnits.map((negotiation) => negotiation.id)

    try {
      const downloadResultsBlob = await downloadResultsCsv({
        projectTag: activeProjectTag,
        negotiationId,
        ids,
      }).unwrap()
      const fileName = ids?.length === 1 ? `results-${ids[0]}.csv` : 'results.csv'

      downloadFile(fileName, downloadResultsBlob)
    } catch (error) {
      return enqueIfError(error, enqueueSnackbar)
    }
  }

  return {
    availableActions: getAvailableActions(negotiationUnits),
    handleDownloadNegotiationUnits,
  }
}

const getAvailableActions = (
  negotiationUnits: NegotiationUnitEntity[],
): GenericNegotiationUnitAction[] => {
  const areAllConcluded = negotiationUnits.every((unit) => unit.isConcluded)
  const areAllSuccessful = negotiationUnits.every((unit) => unit.isSuccessful)

  return areAllConcluded && areAllSuccessful ? ['DOWNLOAD'] : []
}
