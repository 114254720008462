import { TextFieldCtrl } from '@components/Form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useDebounce, useNotifications } from '@hooks'
import { Grid, Typography } from '@mui/material'
import { CurrencyCode } from '@pactum/common'
import { BrandingList } from '@pages/SettingsPage/Branding/BrandingList'
import isEqual from 'lodash/isEqual'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import {
  useGetOrganizationConfigurationQuery,
  useUpdateOrganizationConfigurationMutation,
} from 'src/main/store/configuration'
import { z } from 'zod'
import { DisplayCurrenciesList } from './DisplayCurrenciesList'
import { ContentContainer } from './SettingsContainers'

const OrganizationConfigurationSchema = z.object({
  internalContactName: z.string().or(z.null()),
  internalContactEmail: z.string().or(z.null()),
  currencies: z.array(z.nativeEnum(CurrencyCode)).or(z.null()),
})

type OrganizationConfigurationSchema = z.infer<typeof OrganizationConfigurationSchema>

export const GeneralOrganizationSettingsPage = ({
  canManageConfig,
}: {
  isInternalUser: boolean
  canManageConfig: boolean
}) => {
  const { orgTag } = useParams<{ orgTag: string }>()
  const organizationConfigurationQuery = useGetOrganizationConfigurationQuery({
    orgTag: orgTag!,
  })
  const [updateOrganizationConfiguration] = useUpdateOrganizationConfigurationMutation()

  const { showError, showSuccess } = useNotifications()

  const saveChanges = useDebounce(async () => {
    const updates = form.getValues()
    if (isEqual(updates, organizationConfigurationQuery.data)) {
      return
    }
    const response = await updateOrganizationConfiguration({
      orgTag: orgTag!,
      config: updates,
    })
    if ('error' in response) {
      showError('Failed to save changes')
    } else {
      showSuccess('Changes saved')
    }
  }, 3000)

  const form = useForm<OrganizationConfigurationSchema>({
    values: organizationConfigurationQuery.data as OrganizationConfigurationSchema,
    resolver: zodResolver(OrganizationConfigurationSchema),
  })

  // Not to trigger saving changes on initial load
  useEffect(() => {
    if (!organizationConfigurationQuery.isLoading) {
      const subscription = form.watch(saveChanges)
      return () => subscription.unsubscribe()
    }
  }, [organizationConfigurationQuery.isLoading, form, saveChanges])

  return (
    <ContentContainer>
      <FormProvider {...form}>
        <form>
          <Typography variant='h3' component='h3' sx={{ mb: 2 }}>
            General
          </Typography>
          <Grid container maxWidth={{ xs: 'sm' }} spacing={4}>
            <Grid item xs={12}>
              <TextFieldCtrl
                name='internalContactName'
                label='Internal Contact'
                disabled={!canManageConfig}
                fullWidth
                variant='outlined'
                helperText='The main contact within your organization for questions about the Pactum Negotiation Suite'
                inputProps={{ 'data-testid': 'internalContactName' }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextFieldCtrl
                fullWidth
                disabled={!canManageConfig}
                name='internalContactEmail'
                label="Internal Contact's email"
                variant='outlined'
                helperText='The email of the main contact within your organization for questions about the Pactum Negotiation Suite'
                inputProps={{ 'data-testid': 'internalContactEmail' }}
              />
            </Grid>

            <Grid item xs={12}>
              <DisplayCurrenciesList
                name='currencies'
                disabled={!canManageConfig}
                description='Display currencies let you see negotiation results on dashboards in the currencies of your choosing.'
              />
            </Grid>

            <Grid item xs={12}>
              <BrandingList disabled={!canManageConfig} orgTag={orgTag!} />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </ContentContainer>
  )
}
