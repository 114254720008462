import { read, utils } from 'xlsx'

export const readSheet = <T>(file: Blob, sheetName: string): Promise<T[]> => {
  const reader = new FileReader()
  reader.readAsArrayBuffer(file)
  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      try {
        const workbook = read(reader.result)
        const sheet = workbook.Sheets[sheetName]
        resolve(utils.sheet_to_json(sheet) as T[])
      } catch (e) {
        reject(e)
      }
    }
  })
}
