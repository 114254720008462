import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { ExpandableSection } from 'src/shared/components/ExpandableSection'
import { SupplierHistoricalCogsTable } from './SupplierHistoricalCogsTable'
import { PriceElasticityTable } from 'src/merchandising/pages/ChangeRequests/NegotiationPreparation/Adjustments/SupportiveInsights/PriceElasticityTable'
import { PricingRevionicsTable } from './PricingRevionicsTable'
import { useGetNegotiationPreparationQuery } from 'src/merchandising/store/'

export const SupportiveInsights = (): JSX.Element | null => {
  const { negotiationPreparationId } = useParams<{ negotiationPreparationId: string }>()
  const { data } = useGetNegotiationPreparationQuery(negotiationPreparationId!, {
    skip: !negotiationPreparationId,
  })

  if (!data) {
    return null
  }

  const { pricingStrategy, priceElasticity, supplierHistoricalCogs } = data.analysis

  if (!pricingStrategy && !priceElasticity && !supplierHistoricalCogs) {
    console.warn('No Supportive Insights data')
    return null
  }

  return (
    <Box px={6} pb={4}>
      <ExpandableSection
        title='Supportive Insights'
        description='The following section shows supportive insights from different sources and perspectives.'
      >
        <PricingRevionicsTable />
        <PriceElasticityTable />
        <SupplierHistoricalCogsTable />
      </ExpandableSection>
    </Box>
  )
}
