import { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import {
  isBackendApiErrorResponse,
  isBackendValidationErrorResponse,
} from '@shared/backend/error/typeGuards'
import { RowValidationError } from './RowValidationError'
import { useValidationErrors } from './ValidationErrorsProvider'
import {
  isLogisticsValidationErrorResponse,
  isPurchasingValidationErrorResponse,
} from './validationErrorTypes'

/**
 * When error is present:
 *
 * - reports the error to snackbar (when it's a plain error)
 * - or to ValidationErrors context (when it contain an array of errors per row).
 *   See {@link ValidationErrorsBar} component, which is used to display these errors.
 *
 * Note: This hook is only usable in components wrapped with {@link ValidationErrorsProvider}.
 */
export const useValidationErrorEffect = (
  error: unknown,
  defaultMessage: string = 'Unknown error occurred',
) => {
  const { enqueueSnackbar } = useSnackbar()
  const { setValidationErrors } = useValidationErrors()

  useEffect(() => {
    if (!error) {
      setValidationErrors([])
    } else if (isPurchasingValidationErrorResponse(error)) {
      setValidationErrors(
        error.data.details.map(({ row, message }) => {
          if (typeof row === 'number') {
            return { message, row: row + 1 }
          }

          return { message }
        }),
      )
    } else if (isLogisticsValidationErrorResponse(error)) {
      setValidationErrors(
        error.data.details.flatMap((detail) =>
          Object.entries(detail.constraints).map(([key, value]) => ({
            message: `${key}: ${value}`,
          })),
        ),
      )
    } else if (isBackendValidationErrorResponse(error)) {
      setValidationErrors(error.data.details as RowValidationError[])
    } else if (isBackendApiErrorResponse(error)) {
      enqueueSnackbar(error.data.message, { variant: 'error' })
    } else {
      enqueueSnackbar(defaultMessage, {
        variant: 'error',
      })
    }
  }, [error, defaultMessage, enqueueSnackbar, setValidationErrors])
}
