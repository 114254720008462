import { LocalizedBranding } from '@pactum/core-backend-types'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'

const BRANDING_KEY = 'ACTIVE_LINK_BRANDING_UUID'

export function useBrandedChatLink() {
  const { enqueueSnackbar } = useSnackbar()
  const [brandingUuid, setBrandingUuid] = useState(sessionStorage.getItem(BRANDING_KEY))

  const setBranding = (branding: LocalizedBranding) => {
    setBrandingUuid(branding.uuid)
    sessionStorage.setItem(BRANDING_KEY, branding.uuid)
    enqueueSnackbar(`Supplier Portal links will now use ${branding.label} branding`, {
      variant: 'success',
    })
  }

  const clearBranding = () => {
    setBrandingUuid(null)
    sessionStorage.removeItem(BRANDING_KEY)
    enqueueSnackbar('Supplier Portal links will no longer use branding', {
      variant: 'success',
    })
  }

  const wrapChatLink = (link: string) => {
    const branding = getBrandingUuid()
    if (!branding) {
      return link
    }
    return `${link}?brandingUuid=${branding}`
  }

  const getBrandingUuid = () => sessionStorage.getItem(BRANDING_KEY) ?? null

  useEffect(() => {
    const currentUuid = sessionStorage.getItem(BRANDING_KEY)
    if (currentUuid !== brandingUuid) {
      setBrandingUuid(currentUuid)
    }
  }, [brandingUuid])

  return {
    setBranding,
    clearBranding,
    getBrandingUuid,
    wrapChatLink,
  }
}
