import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  TooltipProps,
  Typography,
} from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import InfoIcon from '@mui/icons-material/Info'
import { GridActionsCellItem, GridColDef, GridEditInputCell } from '@mui/x-data-grid-pro'
import { useFormContext } from 'react-hook-form'
import { useFormatter } from '@shared/hooks'
import {
  adjustTotalForAlternative,
  getContractLengthOptions,
} from '@procurement/utils/contractLength'
import { ContractLengthGrid } from './ContractLengthGrid'
import { ContractLengthAlternative } from '@procurement/store/types'
import { useAlternativeContractLength } from '../../hooks/useAlternativeContractLength'
import { getDiscountedMaxTotal, getIncreasedMaxTotal } from '../LineItems/TotalMaxPriceThresholds'
import { getTotalLineItemsPrice } from '@procurement/components/NegotiationEventForm/utils/lineItems'
import { NegotiationEventFormData } from '../../schema'

export const ContractLengthAlternatives = () => {
  const formatter = useFormatter()
  const { watch } = useFormContext<NegotiationEventFormData>()
  const [
    contractLength,
    currency,
    lineItems,
    thresholdType,
    maxTotalPrice,
    discountPercent,
    increasePercent,
  ] = watch([
    'suppliers.0.negotiationSettings.contractLength',
    'currency',
    'lineItems',
    'suppliers.0.negotiationSettings.maxTotalThresholdType',
    'suppliers.0.negotiationSettings.maxTotalPrice',
    'suppliers.0.negotiationSettings.minTotalPriceDiscountPercent',
    'suppliers.0.negotiationSettings.maxTotalPriceIncreasePercent',
  ])

  const contractLengthOptions = getContractLengthOptions(contractLength)
  const { onRowAdd, onRowUpdate, onRowDelete } = useAlternativeContractLength()

  const totalPrice =
    thresholdType === 'absolute' && Boolean(maxTotalPrice)
      ? maxTotalPrice
      : getTotalLineItemsPrice(lineItems)

  const derivedTotalPrice =
    thresholdType && totalPrice
      ? getDerivedTotalPrice(thresholdType, totalPrice, discountPercent, increasePercent)
      : totalPrice

  const columns: GridColDef<ContractLengthAlternative>[] = [
    {
      ...commonColumnProps,
      field: 'contractLengthMonths',
      editable: Boolean(contractLength),
      headerName: 'Agreement length',
      type: 'singleSelect',
      flex: 1,
      valueOptions: contractLengthOptions,
      valueFormatter: ({ value }) => `${value} months`,
    },
    {
      ...commonColumnProps,
      field: 'id',
      editable: false,
      headerName: 'Item change',
      align: 'right',
      headerAlign: 'right',
      renderCell: ({ row: { contractLengthMonths } }) => {
        if (!contractLength) {
          return null
        }

        const scale = getAlternativeProportion(contractLength, Number(contractLengthMonths))
        const scaleItemQuantity = (quantity: number) => (quantity ?? 0) * scale

        const lineItemsTooltip = (
          <TableContainer component={(props) => <Paper elevation={5} {...props} />}>
            <Table size='small'>
              <TableBody>
                {lineItems.map((item, i) => (
                  <TableRow key={i}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>
                      <Typography variant='buttonText'>
                        {`${scaleItemQuantity(item.quantity ?? 0)} ${item.unit}`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )

        const isInteger = scale % 1 === 0
        const formattedScale = isInteger ? scale : scale.toFixed(1)

        return (
          <>
            <Typography variant='body2' sx={{ pr: 1 }}>{`${formattedScale}x items`}</Typography>
            <StyledTooltip title={lineItemsTooltip} placement='top'>
              <InfoIcon fontSize='small' />
            </StyledTooltip>
          </>
        )
      },
      type: 'number',
    },
    {
      ...commonColumnProps,
      field: 'priceChangePercentage',
      editable: Boolean(contractLength),
      headerName: 'Price change',
      align: 'right',
      flex: 0.75,
      headerAlign: 'right',
      renderCell: ({ row: { priceChangePercentage } }) => `${priceChangePercentage}%`,
      renderEditCell: (params) => (
        <GridEditInputCell {...params} inputProps={{ min: 0, max: 100 }} />
      ),
      type: 'number',
    },
    {
      ...commonColumnProps,
      field: '-',
      editable: false,
      headerName: 'Max total',
      align: 'right',
      flex: 1.25,
      headerAlign: 'right',
      renderCell: ({ row: { contractLengthMonths, priceChangePercentage } }) => {
        if (!contractLength || !derivedTotalPrice) {
          return null
        }

        const alternativeMaxTotal = adjustTotalForAlternative({
          baseContractLength: contractLength,
          total: derivedTotalPrice,
          alternativeContractLength: Number(contractLengthMonths),
          priceChangePercentage: Number(priceChangePercentage),
          priceChangeDirection: 'increase',
        })

        return (
          <Typography color='GrayText' variant='body2'>
            {formatter.currency(alternativeMaxTotal, { currency })}
          </Typography>
        )
      },
    },
    {
      field: 'actions',
      type: 'actions',
      editable: true,
      flex: 0.25,
      align: 'right',
      getActions: ({ id }) => [
        <GridActionsCellItem
          icon={<DeleteOutlineIcon />}
          label='Delete'
          onClick={() => onRowDelete(Number(id))}
        />,
      ],
    },
  ]

  return <ContractLengthGrid columns={columns} onRowAdd={onRowAdd} onRowUpdate={onRowUpdate} />
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  '& .MuiTooltip-tooltip': {
    background: 'none',
  },
}))

const commonColumnProps = {
  disableColumnMenu: true,
  flex: 1,
}

const getAlternativeProportion = (baseContractLength: number, alternativeContractLength: number) =>
  alternativeContractLength / baseContractLength

const getDerivedTotalPrice = (
  thresholdType: 'absolute' | 'discount' | 'increase',
  maxTotalPrice: number,
  discountPercent?: number | null,
  increasePercent?: number | null,
) =>
  ({
    absolute: maxTotalPrice,
    discount: getDiscountedMaxTotal(maxTotalPrice, discountPercent ?? 0),
    increase: getIncreasedMaxTotal(maxTotalPrice, increasePercent ?? 0),
  })[thresholdType]
