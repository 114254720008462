import { ConfirmationModal } from './ConfirmationModal'
import pluralize from 'pluralize'

interface Props {
  title?: string
  open: boolean
  allNegotiations?: number
  actionableNegotiations?: number
  onCancel: () => void
  onSubmit: () => void
  loading?: boolean
}

export const StartConfirmationModal = ({
  allNegotiations = 1,
  actionableNegotiations,
  ...props
}: Props) => {
  const title = props.title ?? 'Start negotiation' + (allNegotiations > 1 ? 's' : '')
  const content =
    allNegotiations === 1
      ? 'This will send out email to the supplier. '
      : `This will send out email to ${actionableNegotiations} of ${allNegotiations} suppliers. `

  return (
    <ConfirmationModal
      confirmType='positive'
      title={title}
      content={content + 'This action can not be undone.'}
      confirmButtonText='Start'
      {...props}
    />
  )
}

export const DeleteConfirmationModal = ({
  allNegotiations = 1,
  actionableNegotiations,
  ...props
}: Props) => {
  const title = props.title ?? 'Delete negotiation' + (allNegotiations > 1 ? 's?' : '?')
  const numberOfNegotiationsContent =
    allNegotiations > 1
      ? `${actionableNegotiations} of ${allNegotiations} negotiations will be deleted. `
      : ''

  return (
    <ConfirmationModal
      confirmType='negative'
      title={title}
      content={numberOfNegotiationsContent + 'This action can not be undone.'}
      confirmButtonText='Delete'
      {...props}
    />
  )
}

export const DownloadConfirmationModal = ({
  allNegotiations = 1,
  actionableNegotiations,
  ...props
}: Props) => {
  const title = 'Download results'
  const content = `Download results for ${actionableNegotiations} concluded negotiations out of ${allNegotiations} selected.`

  return (
    <ConfirmationModal
      confirmType='positive'
      title={title}
      content={content}
      confirmButtonText='Download'
      {...props}
    />
  )
}

export const MarkAsSignedConfirmationModal = ({
  allNegotiations = 1,
  actionableNegotiations,
  ...props
}: Props) => {
  const content =
    allNegotiations === 1
      ? 'This will mark the negotiation as signed. '
      : `This will mark ${actionableNegotiations} of ${allNegotiations} negotiations as signed. `

  return (
    <ConfirmationModal
      confirmType='positive'
      title={'Mark negotiations as signed'}
      content={content}
      confirmButtonText='Mark as signed'
      {...props}
    />
  )
}

export const MarkAsNotSignedConfirmationModal = ({
  allNegotiations = 1,
  actionableNegotiations,
  ...props
}: Props) => {
  const content =
    allNegotiations === 1
      ? 'This will mark the negotiation as not signed. '
      : `This will mark ${actionableNegotiations} of ${allNegotiations} negotiations as not signed. `

  return (
    <ConfirmationModal
      confirmType='positive'
      title={'Mark negotiations as not signed'}
      content={content}
      confirmButtonText='Mark as not signed'
      {...props}
    />
  )
}

export const DownloadAttachmentsConfirmationModal = ({
  allNegotiations = 1,
  actionableNegotiations,
  attachmentCount,
  ...props
}: Props & { attachmentCount: number }) => {
  const title = 'Download attachments'
  const content = `Download ${attachmentCount} ${pluralize(
    'attachment',
    attachmentCount,
  )} from ${actionableNegotiations} of ${allNegotiations} selected negotiations.`

  return (
    <ConfirmationModal
      confirmType='positive'
      title={title}
      content={content}
      confirmButtonText='Download'
      {...props}
    />
  )
}
