import { UnsuitableDeliveryDateFeedback } from '@procurement/store/types'
import { Stack, Typography } from '@mui/material'
import { FeedbackTitle } from './FeedbackTitle'
import { FeedbackReasonText } from './FeedbackReasonText'

interface Props {
  feedbackDetails: UnsuitableDeliveryDateFeedback
}

export const UnsuitableDeliveryDate = ({ feedbackDetails }: Props) => {
  const supplierInput = feedbackDetails.preferredDeliveryDate
  const reason = feedbackDetails.reason

  return (
    <Stack direction='column' gap={3}>
      <FeedbackTitle>Unsuitable delivery date</FeedbackTitle>
      <Typography variant='body1'>Acceptable: {supplierInput as string}</Typography>
      {reason && <FeedbackReasonText label='Comment' value={reason} />}
    </Stack>
  )
}
