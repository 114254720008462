import { NegotiationListEntity } from '@common/dto/pact/negotiation-list-entity'
import { isEqualIgnoringOrder } from '@common/utils'
import {
  SuiteDataGridProvider,
  useSuiteDataGrid,
} from '@components/SuiteDataGrid/SuiteDataGridProvider'
import { styled } from '@mui/material'
import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_FIELD,
  GridColDef,
  GridEventListener,
  gridPaginatedVisibleSortedGridRowIdsSelector,
  GridRowSelectionModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { useFormatter } from '@shared/hooks'
import { useGridPagination } from '@shared/hooks/useGridPagination'
import React, { ReactNode, useMemo, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { dashboardApi } from 'src/main/store/dashboard'
import { DataGrid, dataGridProps } from 'src/shared/components/table'
import { Toolbar } from './Toolbar'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { createColumns } from './columns'

type Props = {
  negotiations: NegotiationListEntity[]
  unitsRoute: string
}

const VISIBLE_COLUMNS_KEY = 'visibleColumns'

const defaultWorkspaceColumns = [
  'createdAt',
  'supplierName',
  'chatLink',
  'spend',
  'savings',
  'improvement',
  'id',
  'status',
]

const defaultHiddenWorkspaceColumns = ['itemCount']

export const NegotiationsList: React.FC<Props> = ({ negotiations, unitsRoute }) => {
  const navigate = useNavigate()
  const formatter = useFormatter()
  const { activeOrgTag, activeProjectTag } = useActiveProject()
  const paginationProps = useGridPagination()
  const [anchorEl, setAnchorElMenu] = useState<ReactNode | null>()
  const params = useParams<{ projectTag: string }>()

  const apiRef = useGridApiRef()
  const allNegotiationIds = negotiations.map((n) => n.id)
  const [selectedNegotiationIds, setSelectedNegotiationIds] = useState<GridRowSelectionModel>([])
  const [showSelectAllNotification, setShowSelectAllNotification] = useState<boolean>(false)

  const gridState = useSuiteDataGrid({
    sort: [{ field: 'createdAt', sort: 'desc' }],
  })

  const areAllInPageSelected = (selection: GridRowSelectionModel): boolean => {
    const rowIdsSelectedOnCurrentPage = gridPaginatedVisibleSortedGridRowIdsSelector(apiRef)
    const selectedIds = selection.map(Number)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return isEqualIgnoringOrder(selectedIds, rowIdsSelectedOnCurrentPage)
  }

  const handleSelection = (selection: GridRowSelectionModel) => {
    if (areAllInPageSelected(selection)) {
      setShowSelectAllNotification(true)
    } else {
      setShowSelectAllNotification(false)
    }

    setSelectedNegotiationIds(selection)
  }

  const config = dashboardApi.endpoints.getProjectDashboardConfig.useQuery({
    projectTag: params.projectTag!,
  })

  const visible = config.currentData?.negotiationList?.visible ?? defaultWorkspaceColumns
  const hidden = config.currentData?.negotiationList?.hidden ?? defaultHiddenWorkspaceColumns

  const columns: GridColDef<NegotiationListEntity>[] = useMemo(
    () => createColumns(formatter).filter((col) => !hidden.includes(col.field)),
    [formatter, hidden],
  )

  const initialColumnVisibilityState = useMemo(
    () => (): Record<string, boolean> => {
      const storedVisibility = localStorage.getItem(VISIBLE_COLUMNS_KEY)

      if (storedVisibility) {
        return {
          ...(JSON.parse(storedVisibility) as Record<string, boolean>),
          [GRID_CHECKBOX_SELECTION_FIELD]: true,
        }
      }

      return {
        ...Object.fromEntries(
          columns.map((column) => [
            column.field,
            visible.includes(column.field) && !hidden.includes(column.field),
          ]),
        ),
        [GRID_CHECKBOX_SELECTION_FIELD]: true,
      }
    },
    [columns, hidden, visible],
  )

  const [columnVisibility, setColumnVisibility] = useState<Record<string, boolean>>(
    initialColumnVisibilityState,
  )

  const updateVisibility = (state: Record<string, boolean>) => {
    localStorage.setItem(VISIBLE_COLUMNS_KEY, JSON.stringify(state))

    setColumnVisibility(state)
  }

  const getTogglableColumns = () => {
    return columns
      .filter((column) => column.field !== GRID_CHECKBOX_SELECTION_FIELD)
      .map((column) => column.field)
  }

  const handleCellClick: GridEventListener<'cellClick'> = (cell) => {
    const actionableFields = ['__actions__', '__check__']

    if (!actionableFields.includes(cell.field)) {
      navigate(
        generatePath(unitsRoute, {
          projectTag: activeProjectTag,
          orgTag: activeOrgTag,
          negotiationId: String(cell.id),
        }),
      )
    }
  }

  return (
    <SuiteDataGridProvider value={gridState}>
      <StyledDataGrid
        {...dataGridProps}
        {...paginationProps}
        apiRef={apiRef}
        checkboxSelection
        checkboxSelectionVisibleOnly
        isRowSelectable={() => true}
        rowSelectionModel={selectedNegotiationIds}
        onRowSelectionModelChange={handleSelection}
        className={selectedNegotiationIds.length > 0 ? 'show-selection' : ''}
        hideFooterSelectedRowCount
        slots={{ ...dataGridProps.slots, toolbar: Toolbar }}
        slotProps={{
          panel: {
            children: <>{anchorEl}</>,
          },
          toolbar: {
            setAnchorElMenu,
            showSelectAllNotification,
            showBatchActions: selectedNegotiationIds.length > 0,
            selectedNegotiations: negotiations.filter((negotiation) =>
              selectedNegotiationIds.includes(negotiation.id),
            ),
            allNegotiationsCount: negotiations.length,
            onSelectAllNotifications: () => setSelectedNegotiationIds(allNegotiationIds),
            onSelectAllNotificationClose: () => setShowSelectAllNotification(false),
            onClearSelection: () => handleSelection([]),
          },
          columnsPanel: {
            getTogglableColumns,
          },
        }}
        columns={columns}
        columnVisibilityModel={columnVisibility}
        onColumnVisibilityModelChange={updateVisibility}
        rows={negotiations}
        sortModel={gridState.sort}
        paginationModel={gridState.pagination}
        filterModel={gridState.filters}
        onSortModelChange={gridState.setSort}
        onPaginationModelChange={gridState.setPagination}
        onFilterModelChange={gridState.setFilters}
        onCellClick={handleCellClick}
      />
    </SuiteDataGridProvider>
  )
}

const StyledDataGrid = styled(DataGrid)({
  ' .MuiDataGrid-columnHeader .MuiBadge-badge': {
    display: 'none',
  },
  ' .select-cell, .select-header': {
    opacity: 0,
  },
  ' .MuiDataGrid-row:hover .select-cell': {
    opacity: 1,
  },
  ' .MuiDataGrid-columnHeaders:hover .select-header': {
    opacity: 1,
  },
  '&.show-selection': {
    ' .select-cell, .select-header': {
      opacity: 1,
    },
  },
}) as typeof DataGridPro
