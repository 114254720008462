import { Fragment } from 'react'
import { Divider, Grid, styled, useMediaQuery, useTheme, typographyClasses } from '@mui/material'
import { StatisticsItemData } from './types'
import { StatisticsItem } from './StatisticsItem'
import { InnerContainer } from '../Containers'
import { APP_HEADER_HEIGHT } from 'src/main/constants'
import { useLayoutContext } from '../context'

const HEIGHT_PX = '90px'

const isDividerNeeded = (data: StatisticsItemData[], dataIndex: number): boolean => {
  return data.length > 1 && dataIndex !== data.length - 1
}

export const StatisticsHeader = (): JSX.Element | null => {
  const theme = useTheme()
  const isSmAndUp = useMediaQuery(theme.breakpoints.up('sm'))
  const { actionsComponent, statisticsHeaderData } = useLayoutContext()

  if (!statisticsHeaderData.length) {
    return null
  }

  return (
    <OuterContainer container justifyContent='space-between'>
      <InnerContainer>
        <Grid container justifyContent='space-between'>
          <StatisticsContainer
            sm={actionsComponent ? 10 : 'auto'}
            container
            item
            columnGap={2.5}
            rowGap={2.5}
            justifyContent='flex-start'
            direction={{ xs: 'column', sm: 'row' }}
            overflow={{ xs: 'scroll', sm: 'unset' }}
            height={{ xs: `calc(${HEIGHT_PX} + ${theme.spacing(2)})`, sm: 'unset' }}
            sx={{ pb: { xs: 2, sm: 0 } }}
          >
            {statisticsHeaderData.map((stat, index) => (
              <Fragment key={`${stat.title}-${index}`}>
                <Grid item sm='auto' minHeight={HEIGHT_PX} maxHeight={HEIGHT_PX}>
                  <StatisticsItem {...stat} />
                </Grid>
                {isDividerNeeded(statisticsHeaderData, index) && (
                  <Divider
                    orientation='vertical'
                    color={theme.palette.common.white}
                    sx={{ opacity: 0.5 }}
                    flexItem={isSmAndUp}
                  />
                )}
              </Fragment>
            ))}
          </StatisticsContainer>
          {actionsComponent && (
            <Grid
              container
              item
              sm='auto'
              alignItems='center'
              justifyContent='flex-end'
              mt={{ xs: 2, md: 0 }}
              gap={2}
            >
              {actionsComponent}
            </Grid>
          )}
        </Grid>
      </InnerContainer>
    </OuterContainer>
  )
}

const StatisticsContainer = styled(Grid)(({ theme }) => ({
  [`& .${typographyClasses.root}`]: {
    color: theme.palette.common.white,
  },
  overflowY: 'hidden',
  overflowX: 'scroll',
}))

const OuterContainer = styled(Grid)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  top: `calc(${APP_HEADER_HEIGHT}px - 0.5px)`, // fixes white space in case of zooming
  zIndex: theme.zIndex.secondaryHeader,
  position: 'sticky',
}))
