import { getPriceChangeDirectionColor } from '../utils'
import { CaptionedValueCell, NotAvailableCell } from 'src/shared/components/table'
import { getChangePercentage } from '@common/utils'
import { useFormatter } from '@shared/hooks'

export const ValueDifferenceCell = ({
  previousValue,
  currentValue,
  invertColors = false,
}: {
  previousValue?: number
  currentValue?: number
  invertColors?: boolean
}): JSX.Element => {
  const formatter = useFormatter()
  if (!previousValue || !currentValue) {
    return <NotAvailableCell />
  }
  const changeValue = currentValue - previousValue
  const changePercentage = getChangePercentage(currentValue, previousValue)
  const color = getPriceChangeDirectionColor(changeValue, invertColors)
  return (
    <CaptionedValueCell
      mainContent={formatter.currency(changeValue, { showChangeSign: true })}
      captionContent={formatter.percent0To1(changePercentage, { showChangeSign: true })}
      removeCaptionOpacity={!!color}
    />
  )
}
