import { ScenarioDetails } from '@common/dto'
import { Button, Dialog, DialogContent, DialogTitle, Stack, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { isBackendApiErrorResponse, useUpdateOwnerMutation } from 'src/merchandising/store/'
import { useSnackbar } from 'notistack'
import { isEmailValue } from '@common/utils/validation'
import { useUser } from '@hooks'
import { useActiveProject } from '@shared/hooks/useActiveProject'

interface Props {
  isOpen: boolean
  sessionKey: ScenarioDetails['sessionKey']
  onOwnerChanged: () => void
  onClose: () => void
}

export const OwnerChangeDialog = ({ isOpen, sessionKey, onOwnerChanged, onClose }: Props) => {
  const { activeProjectTag } = useActiveProject()
  const { enqueueSnackbar } = useSnackbar()
  const { user } = useUser()
  const [owner, setOwner] = useState(user?.email ?? '')
  const [updateOwner] = useUpdateOwnerMutation()

  useEffect(() => {
    if (user?.email) {
      setOwner(user.email)
    }
  }, [user])

  const handleSubmit = async () => {
    if (!isEmailValue(owner)) {
      return
    }

    try {
      await updateOwner({
        projectTag: activeProjectTag,
        sessionKey,
        payload: {
          ownerEmail: owner,
        },
      }).unwrap()
      enqueueSnackbar('Updated owner', { variant: 'success' })

      onOwnerChanged()
    } catch (e) {
      enqueueSnackbar(isBackendApiErrorResponse(e) ? e.data.message : 'Owner update failed', {
        variant: 'error',
      })
    }
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Set owner</DialogTitle>
      <DialogContent>
        <Stack p={3} minWidth={400}>
          <TextField
            value={owner}
            label='Owner'
            onChange={(e) => setOwner(e.target.value)}
            error={!isEmailValue(owner)}
            helperText={!isEmailValue(owner) ? 'Invalid email' : null}
            fullWidth
          />
        </Stack>

        <Stack direction='row' p={2} spacing={1} justifyContent='flex-end'>
          <Button variant='contained' color='secondary' onClick={onClose}>
            Cancel
          </Button>
          <Button variant='contained' color='primary' sx={{ mr: 1 }} onClick={handleSubmit}>
            Update
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
