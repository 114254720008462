import { CaptionedValueCell } from 'src/shared/components/table'
import { isValidDate } from 'src/main/utils/date'
import { useFormatter } from '@shared/hooks'
import { dateFormats } from '@utils'

interface Props {
  price: number
  date: Date
  datePrefixString: string
}

export const PriceWithDateCell = ({ price, date, datePrefixString = '' }: Props): JSX.Element => {
  const formatter = useFormatter()
  const dateString = isValidDate(date)
    ? formatter.date(new Date(date), dateFormats.shortDate, { slashSeparators: true })
    : ''
  return (
    <CaptionedValueCell
      mainContent={formatter.currency(price)}
      captionContent={datePrefixString + dateString}
    />
  )
}
