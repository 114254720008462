import { Grid, TextField, Typography } from '@mui/material'
import { InputError } from 'src/merchandising/types'
import { ContactInfo } from '@common/dto'

interface Props {
  disabled: boolean
  inputError: InputError
  value: ContactInfo['localMarketGsamEmail']
  onChange: (value: string) => void
}

export const GSAMContactInfo = ({ value, disabled, inputError, onChange }: Props) => (
  <>
    <Typography variant='h5'>GSAM information</Typography>
    <Typography variant='body1' mt={1}>
      Please provide the email of the responsible GSAM for receiving notifications upon supplier
      starting and completing the negotiation.
    </Typography>
    <Grid container>
      <Grid item xs={12} sm={4} md={4}>
        <TextField
          fullWidth
          id='gsamEmail'
          label='Contact Email'
          disabled={disabled}
          error={inputError.isError}
          value={value === null ? '' : value}
          onChange={(e) => onChange(e.target.value)}
          inputProps={{ inputMode: 'email' }}
          helperText={inputError.error}
        />
      </Grid>
    </Grid>
  </>
)
