import { BusinessUnitFilter } from '@components/DashboardFilters/BusinessUnitFilter'
import { CategoryFilter } from '@components/DashboardFilters/CategoryFilter'
import { CountryFilter } from '@components/DashboardFilters/CountryFilter'
import { CurrencyFilter } from '@components/DashboardFilters/CurrencyFilter'
import { RegionFilter } from '@components/DashboardFilters/RegionFilter'
import { ExchangeRatesPopover } from '@components/ExchangeRatesPopover/ExchangeRatesPopover'
import { InnerContainer } from '@components/Layout/Containers'
import { WorkspaceDateRangeFilter } from '@components/WorkspaceDateRangeFilter/WorkspaceDateFilter'
import { WorkspaceMultiselect } from '@components/WorkspaceMultiselect'
import { WorkspaceDateFilter } from '@components/WorkspacePeriodFilter'
import { Alert, Box, Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material'
import { CurrencyCode, Region, countriesList } from '@pactum/common'
import { ErrorBoundary } from '@sentry/react'
import { useFormatter } from '@shared/hooks'
import { dateFormats } from '@utils'
import React, { useEffect } from 'react'
import { Chart } from 'react-chartjs-2'
import { GetDashboardConfigParams, dashboardApi } from '../../store/dashboard'
import { isBackendHttpError } from '../../types/http-errors'
import { ErrorPage } from '../ErrorPage'
import { LoadingPage } from '../LoadingPage'
import { DashboardType } from './DashboardPage'
import { FiguresWidget } from './Widgets/FiguresWidget'
import { KeyValueListWidget } from './Widgets/KeyValueListWidget'
import { PerformanceNumbersWidget } from './Widgets/PerformanceNumbersWidget'
import { PieChartWidget } from './Widgets/PieChartWidget'
import { SimpleBarChartWidget } from './Widgets/SimpleBarChartWidget'
import { TableWidget } from './Widgets/TableWidget'
import { TimeSeriesStackedWidget, TimeSeriesWidget } from './Widgets/TimeSeriesWidget'
import { ValueProjectionWidget } from './Widgets/ValueProjectionWidget'
import { WidgetSection } from './Widgets/WidgetSection'
import { DashboardPeriodProvider } from './useDashboardPeriod'
import { useDashboardPeriodSelection } from './useDashboardPeriodSelection'
import { useDashboardProjectSelection } from './useDashboardProjectSelection'
import {
  DashboardFiltersProvider,
  DashboardQueryParams,
  useDashboardFilters,
} from './useDashboardQueryParams'
import { DashboardRangeProvider } from './useDashboardRange'
import { useDashboardRangeSelection } from './useDashboardRangeSelection'
import { useDownloadCTResultsExperiment } from './useDownloadCTResultsExperiment'

interface Props {
  type: DashboardType
  tag: string
  defaultCurrency: CurrencyCode
}

export const Dashboard = ({ type, tag, defaultCurrency }: Props) => {
  const formatter = useFormatter()

  const { projects, selectedProjectIds, handleProjectFilterChange } = useDashboardProjectSelection()
  const { period, setDashboardPeriod } = useDashboardPeriodSelection()
  const { range, setDashboardRange } = useDashboardRangeSelection()
  const { values, set } = useDashboardFilters<DashboardQueryParams>({
    countries: [],
    regions: [],
    currency: defaultCurrency,
    categories1: [],
    categories2: [],
    businessUnits: [],
    queryVariant: '',
    excludeNonnegotiableSpend: '',
  })
  const { handleDownloadNegotiations } = useDownloadCTResultsExperiment(tag)

  let dashboardConfiguration

  const params: GetDashboardConfigParams = {
    currency: values.currency,
    countries: values.countries,
    regions: values.regions,
    categories1: values.categories1,
    categories2: values.categories2,
    businessUnits: values.businessUnits,
    queryVariant: values.queryVariant,
    excludeNonnegotiableSpend: values.excludeNonnegotiableSpend,
  }

  if (range.start && range.end) {
    params.start = formatter.date(range.start, dateFormats.yearMonthDay, { slashSeparators: false })
    params.end = formatter.date(range.end, dateFormats.yearMonthDay, { slashSeparators: false })
  }

  switch (type) {
    case DashboardType.PROJECT:
      dashboardConfiguration = dashboardApi.endpoints.getProjectDashboardConfig.useQuery({
        ...params,
        projectTag: tag,
      })
      break
    case DashboardType.ORGANIZATION:
      dashboardConfiguration = dashboardApi.endpoints.getOrganizationDashboardConfig.useQuery({
        ...params,
        organizationTag: tag,
        projectIds: selectedProjectIds,
      })
      break
  }

  const { data: config, isLoading, error, refetch } = dashboardConfiguration

  useEffect(() => {
    if (selectedProjectIds.length > 0) {
      refetch()
    }
  }, [refetch, selectedProjectIds.length, values.currency])

  useEffect(() => {
    // if previously selected currency is not anymore in the list of workspaces currencies select default
    if (
      values.currency &&
      !!config?.availableCurrencies &&
      !config?.availableCurrencies?.includes(values.currency)
    ) {
      set('currency', defaultCurrency)
    }
  }, [config, defaultCurrency, set, values.currency])

  if (isBackendHttpError(error)) {
    return (
      <ErrorPage title='Error loading dashboard'>
        <pre>{error.data.message}</pre>
      </ErrorPage>
    )
  }

  return !config || isLoading ? (
    <LoadingPage />
  ) : (
    <>
      <DashboardFiltersProvider value={{ ...values, set }}>
        <DashboardPeriodProvider value={period}>
          <DashboardRangeProvider value={{ range }}>
            <InnerContainer>
              <Box py={5}>
                {config.alerts.length > 0
                  ? config.alerts.map((alert) => (
                      <Alert severity={alert.type} sx={{ mb: 3 }} key={alert.message}>
                        {alert.message}
                      </Alert>
                    ))
                  : null}
                <Typography variant='subtitle1'>Dashboard</Typography>

                {config.currencyRates && (
                  <ExchangeRatesPopover
                    currency={values.currency}
                    currencyRates={config.currencyRates}
                    lastUpdated={
                      config.currencyUpdated
                        ? formatter.date(new Date(config.currencyUpdated), dateFormats.textDateTime)
                        : undefined
                    }
                  />
                )}

                <Grid container alignItems={'center'} pt={3}>
                  {type === DashboardType.ORGANIZATION && config.showProjectFilter ? (
                    <Grid item sx={{ minWidth: 250 }} pr={3}>
                      <WorkspaceMultiselect
                        allWorkspaces={projects}
                        selectedWorkspaceIds={selectedProjectIds}
                        setSelectedWorkspaceIds={handleProjectFilterChange}
                        treatNoneSelectedAsAll={true}
                      />
                    </Grid>
                  ) : null}

                  {(config.availableCurrencies ?? []).length >= 1 && (
                    <Grid item sx={{ minWidth: 190 }} pr={3}>
                      <CurrencyFilter
                        currencies={[
                          ...new Set([
                            ...(config.availableCurrencies
                              ? (config.availableCurrencies as CurrencyCode[])
                              : []),
                            defaultCurrency!,
                          ]),
                        ]}
                        selectedCurrency={values.currency}
                        onChange={(v) => set('currency', v)}
                      />
                    </Grid>
                  )}

                  <Grid item sx={{ minWidth: 190 }} pr={3}>
                    <WorkspaceDateFilter period={period} setPeriod={setDashboardPeriod} />
                  </Grid>

                  <Grid item sx={{ minWidth: 280 }} pr={3}>
                    <WorkspaceDateRangeFilter
                      start={range.start}
                      end={range.end}
                      setRange={setDashboardRange}
                    />
                  </Grid>

                  {(config.filters ?? []).map((filter) => (
                    <Grid key={filter.id} item sx={{ minWidth: 190 }} pr={3}>
                      {filter.id === 'countries' && (
                        <CountryFilter
                          countries={countriesList.filter((c) =>
                            filter.values.includes(c.iso3code),
                          )}
                          selectedCountries={countriesList.filter(
                            (c) =>
                              filter.values.includes(c.iso3code) &&
                              values.countries.includes(c.iso3code),
                          )}
                          onChange={(v) =>
                            set(
                              'countries',
                              v.map((c) => c.iso3code),
                            )
                          }
                        />
                      )}

                      {filter.id === 'regions' && (
                        <RegionFilter
                          regions={filter.values as Region[]}
                          selectedRegions={(values.regions ?? []) as Region[]}
                          onChange={(v) => set('regions', v)}
                        />
                      )}

                      {filter.id === 'categories1' && (
                        <CategoryFilter
                          categories={filter.values as string[]}
                          selectedCategories={(values.categories1 ?? []) as string[]}
                          onChange={(v) => set('categories1', v)}
                        />
                      )}

                      {filter.id === 'categories2' && (
                        <CategoryFilter
                          categories={filter.values as string[]}
                          selectedCategories={(values.categories2 ?? []) as string[]}
                          onChange={(v) => set('categories2', v)}
                        />
                      )}

                      {filter.id === 'businessUnits' && (
                        <BusinessUnitFilter
                          units={filter.values as string[]}
                          selectedUnits={(values.businessUnits ?? []) as string[]}
                          onChange={(v) => set('businessUnits', v)}
                        />
                      )}

                      {filter.id === 'excludeNonnegotiableSpend' && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.excludeNonnegotiableSpend === 'true'}
                              onChange={(e) =>
                                set(
                                  'excludeNonnegotiableSpend',
                                  e.target.checked ? 'true' : undefined,
                                )
                              }
                            />
                          }
                          label='Exclude Non-Negotiable Spend'
                        />
                      )}
                    </Grid>
                  ))}

                  {config.flags?.showCSVDownloadButton && (
                    <Grid item sx={{ minWidth: 190 }}>
                      <Button variant='contained' onClick={handleDownloadNegotiations}>
                        Download results
                      </Button>
                    </Grid>
                  )}
                </Grid>
                <Grid container columnSpacing={8}>
                  {config.widgets.map((widget, index) => {
                    if (widget.type === 'figureChart') {
                      return (
                        <WidgetErrorBoundary key={`${widget.type}-${index}`}>
                          <FiguresWidget widgetConfig={widget} />
                        </WidgetErrorBoundary>
                      )
                    }

                    if (widget.type === 'performanceNumbers') {
                      return (
                        <WidgetErrorBoundary key={`${widget.type}-${index}`}>
                          <PerformanceNumbersWidget widgetConfig={widget} />
                        </WidgetErrorBoundary>
                      )
                    }

                    if (widget.type === 'simpleBarChart') {
                      return (
                        <WidgetErrorBoundary key={`${widget.type}-${index}`}>
                          <SimpleBarChartWidget widgetConfig={widget} />
                        </WidgetErrorBoundary>
                      )
                    }

                    if (widget.type === 'timeSeries') {
                      return (
                        <WidgetErrorBoundary key={`${widget.type}-${index}`}>
                          <TimeSeriesWidget widgetConfig={widget} />
                        </WidgetErrorBoundary>
                      )
                    }

                    if (widget.type === 'timeSeriesStacked') {
                      return (
                        <WidgetErrorBoundary key={`${widget.type}-${index}`}>
                          <TimeSeriesStackedWidget widgetConfig={widget} />
                        </WidgetErrorBoundary>
                      )
                    }

                    if (widget.type === 'pieChart') {
                      return (
                        <WidgetErrorBoundary key={`${widget.type}-${index}`}>
                          <PieChartWidget widgetConfig={widget} />
                        </WidgetErrorBoundary>
                      )
                    }

                    if (widget.type === 'table') {
                      return (
                        <WidgetErrorBoundary key={`${widget.type}-${index}`}>
                          <TableWidget widgetConfig={widget} />
                        </WidgetErrorBoundary>
                      )
                    }

                    if (widget.type === 'keyValueList') {
                      return (
                        <WidgetErrorBoundary key={`${widget.type}-${index}`}>
                          <KeyValueListWidget widgetConfig={widget} />
                        </WidgetErrorBoundary>
                      )
                    }

                    if (widget.type === 'valueProjection') {
                      return (
                        <WidgetErrorBoundary key={`${widget.type}-${index}`}>
                          <ValueProjectionWidget widgetConfig={widget} />
                        </WidgetErrorBoundary>
                      )
                    }

                    return null
                  })}
                </Grid>
              </Box>
            </InnerContainer>
          </DashboardRangeProvider>
        </DashboardPeriodProvider>
      </DashboardFiltersProvider>
    </>
  )
}

const WidgetErrorBoundary = ({ children }: { children: React.ReactNode }) => (
  <ErrorBoundary fallback={<ChartPlaceholder />}>{children}</ErrorBoundary>
)

const ChartPlaceholder = () => {
  return (
    <WidgetSection title='' description=''>
      <Box sx={{ minHeight: '200px' }}>
        <Chart
          type='bar'
          data={{
            labels: [],
            datasets: [],
          }}
          options={{
            scales: {
              y: {
                position: 'right',
                beginAtZero: true,
              },
              x: {
                grid: {
                  display: false,
                },
              },
            },
            maintainAspectRatio: false,
          }}
        />
      </Box>
    </WidgetSection>
  )
}
