import { Chart as ChartJS } from 'chart.js'

import { LoadingPage } from '@pages/LoadingPage'
import { useProjects } from '@hooks'
import { ErrorPage } from '@pages/ErrorPage'
import { fontFamily } from '../../constants/theme/pactum'
import { Dashboard } from './Dashboard'
import {
  useGetOrganizationDisplayCurrenciesQuery,
  useGetWorkspaceDisplayCurrenciesQuery,
} from 'src/main/store/configuration'

ChartJS.defaults.font.size = 10
ChartJS.defaults.font.family = fontFamily

export enum DashboardType {
  PROJECT,
  ORGANIZATION,
}

export const DashboardPage = () => {
  const { activeOrgTag, activeProjectTag, loadingOrgs, loadingProjects } = useProjects()
  const { data: organizationDisplayCurrencies, isLoading: organizationDisplayCurrenciesLoading } =
    useGetOrganizationDisplayCurrenciesQuery({ orgTag: activeOrgTag! }, { skip: !activeOrgTag })
  const { data: workspaceDisplayCurrencies, isLoading: workspaceDisplayCurrenciesLoading } =
    useGetWorkspaceDisplayCurrenciesQuery(
      { projectTag: activeProjectTag! },
      { skip: !activeProjectTag },
    )

  if (loadingProjects || workspaceDisplayCurrenciesLoading) {
    return <LoadingPage />
  }

  if (activeProjectTag) {
    if (workspaceDisplayCurrencies) {
      return (
        <Dashboard
          type={DashboardType.PROJECT}
          tag={activeProjectTag}
          defaultCurrency={workspaceDisplayCurrencies[0]}
        />
      )
    } else {
      return (
        <ErrorPage title={'Error'}>
          Can't get configuration for the workspace {activeProjectTag}
        </ErrorPage>
      )
    }
  }

  if (loadingOrgs || organizationDisplayCurrenciesLoading) {
    return <LoadingPage />
  }

  if (activeOrgTag) {
    if (organizationDisplayCurrencies) {
      return (
        <Dashboard
          type={DashboardType.ORGANIZATION}
          tag={activeOrgTag}
          defaultCurrency={organizationDisplayCurrencies[0]}
        />
      )
    } else {
      return (
        <ErrorPage title={'Error'}>
          Can't get configuration for the organization {activeOrgTag}
        </ErrorPage>
      )
    }
  }

  return (
    <ErrorPage title={`No active organization or project found`}>
      <pre></pre>
    </ErrorPage>
  )
}
