import { Box, Link, styled, Typography } from '@mui/material'
import { GridToolbarContainer } from '@mui/x-data-grid-pro'
import { QuickFilter } from '@components/QuickFilter'
import { NegotiationEvent } from '../../../store/types'
import { BatchActionsBar } from './BatchActionsBar/BatchActionsBar'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { labelForProduct } from '../../../utils/labelForProduct'
import { ProductType } from '@pactum/core-backend-types'

interface Props {
  showBatchActions: boolean
  onOpenUploadDialog?: () => void
  selectedNegotiationEvents: NegotiationEvent[]
  productType: ProductType.ContractCost | ProductType.Purchasing
}

export const Toolbar = ({
  showBatchActions,
  selectedNegotiationEvents,
  onOpenUploadDialog,
  productType,
}: Props) => {
  return (
    <ToolbarContainer>
      <Typography variant='subtitle1'>{labelForProduct(productType, 'listViewTitle')}</Typography>
      {showBatchActions && (
        <BatchActionsBar selectedNegotiationEvents={selectedNegotiationEvents} />
      )}
      <Separator />
      {onOpenUploadDialog && (
        <UploadLink onClick={onOpenUploadDialog} href='#' variant='normal'>
          <FileUploadIcon fontSize='small' /> Upload
        </UploadLink>
      )}
      <QuickFilter placeholder={labelForProduct(productType, 'listViewSearchPlaceholder')} />
    </ToolbarContainer>
  )
}

const UploadLink = styled(Link)(({ theme }) => ({
  '.MuiSvgIcon-root': {
    verticalAlign: 'middle',
  },
  fontSize: '12px',
  fontWeight: 500,
  paddingRight: theme.spacing(3),
  color: theme.palette.darkGrey.main,
}))

const ToolbarContainer = styled(GridToolbarContainer)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  marginLeft: '10px',
  paddingLeft: theme.spacing(6),
}))

const Separator = styled(Box)({ flex: 1 })
