import { CurrentAndPreviousValueCell } from 'src/merchandising/pages/ChangeRequests/NegotiationPreparation/Adjustments/NegotiationConfigurationCells'
import { NotAvailableCell } from 'src/shared/components/table'
import { useFormatter } from '@shared/hooks'

interface Props {
  supplierChange: number
  indexChange?: number
}

export const DeviationCell = ({ supplierChange, indexChange }: Props) => {
  const formatter = useFormatter()
  if (!indexChange) {
    return <NotAvailableCell />
  }

  return (
    <CurrentAndPreviousValueCell
      currentValue={indexChange}
      previousValue={supplierChange}
      captionPrefixString=''
      options={{
        formatter: {
          mainContent: () =>
            formatter.percent0To1(supplierChange - indexChange, { showChangeSign: true }),
          captionContent: () => '',
        },
      }}
    />
  )
}
