import { Link, Typography } from '@mui/material'
import { ProductType } from '@pactum/core-backend-types'
import { NegotiationEvent } from '../../../store/types'
import { labelForProduct } from '../../../utils/labelForProduct'
import { useFormatter } from '@shared/hooks'
import { DataCell } from '@procurement-shared/DataCell/DataCell'
import { ContractPreviewLink } from '@procurement-shared/ContractPreviewLink/ContractPreviewLink'
import { DocusignLink } from '@procurement-shared/DocusignLink/DocusignLink'
import { formatContractDate, formatDeliveryDate } from '../../../utils/formatting'

interface Props {
  negotiationEvent: NegotiationEvent
  productType?: ProductType
}

export const BasicAttributes = ({ negotiationEvent, productType }: Props) => {
  const formatter = useFormatter()
  const isContractCost = productType === ProductType.ContractCost
  const outcomes = negotiationEvent.suppliers.find(({ isWinner }) => !!isWinner)?.negotiation
    ?.outcomes

  const hasContractDates = outcomes?.contractStartDate && outcomes?.contractEndDate
  const showContractPreviewLink =
    negotiationEvent.contractModel && negotiationEvent.negotiationStatus === 'READY'
  const showDocusignLink =
    negotiationEvent.contractModel && negotiationEvent.negotiationStatus === 'SIGNED'

  return (
    <>
      <Typography variant='h2'>
        {labelForProduct(productType, 'detailsViewLeftColumnTitle')}
      </Typography>
      <DataCell label={labelForProduct(productType, 'detailsViewIdLine')}>
        {negotiationEvent.uniqueName}
      </DataCell>
      <DataCell label='Description'>{negotiationEvent.title}</DataCell>
      <DataCell label='Buyer'>
        <Typography display='block' variant='normal'>
          {negotiationEvent.buyerContactName}
        </Typography>
        <Link display='block' href={`mailto:${negotiationEvent.buyerContactEmail}`}>
          <Typography variant='normal'>{negotiationEvent.buyerContactEmail}</Typography>
        </Link>
      </DataCell>
      {negotiationEvent.deliveryDate && (
        <DataCell label='Delivery date'>
          {formatDeliveryDate(outcomes?.deliveryDate ?? negotiationEvent.deliveryDate, formatter)}
        </DataCell>
      )}
      {negotiationEvent.deliveryLocation && (
        <DataCell label='Delivery location'>{negotiationEvent.deliveryLocation}</DataCell>
      )}
      {isContractCost && hasContractDates && (
        <DataCell label='Agreement dates'>
          {outcomes?.contractStartDate &&
            formatContractDate(outcomes?.contractStartDate, formatter)}
          {' - '}
          {outcomes?.contractEndDate && formatContractDate(outcomes?.contractEndDate, formatter)}
        </DataCell>
      )}
      {showContractPreviewLink && (
        <DataCell label='SaaS order form'>
          <ContractPreviewLink event={negotiationEvent} />
        </DataCell>
      )}
      {showDocusignLink && (
        <DataCell label='Agreement'>
          <DocusignLink negotiationEvent={negotiationEvent} />
        </DataCell>
      )}
    </>
  )
}
