import { Alert, Typography, styled, Box, Container } from '@mui/material'
import React from 'react'

interface MultiErrorAlertProps {
  errorCount: number
  onClick: () => void
  onClose: () => void
  topOffset: number
}

/**
 * An Alert bar that shows errors count as a clickable link
 */
export const MultiErrorAlert = ({
  errorCount,
  onClick,
  onClose,
  topOffset,
}: MultiErrorAlertProps) => {
  const message = choosePluralForm(
    <>
      There was <Link onClick={onClick}>1 error</Link> in the upload.
    </>,
    <>
      There were <Link onClick={onClick}>{errorCount} errors</Link> in the upload.
    </>,
    errorCount,
  )

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: 'rgb(250, 235, 235)', // This is default MUI background color of Alert
        position: 'sticky',
        top: topOffset,
        zIndex: theme.zIndex.contentOverlay,
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)', // Explicit from Figma
      })}
    >
      <Container sx={{ px: 6, height: '100%' }} maxWidth='xl' disableGutters>
        <StyledAlert severity='error' onClose={onClose} icon={false}>
          <Typography variant='normal' fontWeight={700}>
            {message} Please update the template and try again
          </Typography>
        </StyledAlert>
      </Container>
    </Box>
  )
}

const choosePluralForm = (one: React.ReactNode, many: React.ReactNode, count: number) => {
  return count === 1 ? one : many
}

const Link = ({ children, onClick }: React.PropsWithChildren<{ onClick: () => void }>) => (
  <StyledLink
    component='a'
    href='#'
    onClick={(e) => {
      e.preventDefault()
      onClick()
    }}
  >
    <Typography variant='normal' fontWeight={700}>
      {children}
    </Typography>
  </StyledLink>
)

const StyledLink = styled(Typography)(({ theme }) => ({
  color: theme.palette.link.main,
  textDecoration: 'underline',
  cursor: 'pointer',
})) as typeof Typography

const StyledAlert = styled(Alert)(() => ({
  padding: '1px 0', // to achieve 32px of total height

  '.MuiAlert-message': {
    height: '100%',
    alignSelf: 'center',
  },

  '.MuiAlert-message, .MuiAlert-action': {
    padding: 0,
  },
}))
