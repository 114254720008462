import { roleDisplayNames } from '@common/types'
import { SelectCtrl, TextFieldCtrl } from '@components/Form'
import { WorkspaceMultiselect } from '@components/WorkspaceMultiselect'
import { useProjects } from '@hooks'
import { Grid } from '@mui/material'
import { AddOrEditUserFormSchema } from '@pages/SettingsPage/ManageUsersPage/AddOrEditUserSchema'
import React, { useState } from 'react'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { rolesExplained } from './common'

interface FormProps<T extends FieldValues> {
  form: UseFormReturn<T>
}

export const AddUserModalForm = (props: FormProps<AddOrEditUserFormSchema>) => {
  const [role, setRole] = React.useState('')
  const { projects } = useProjects()

  const [selectedProjectIds, setSelectedProjectIds] = useState<number[]>(
    projects.map((project) => project.id),
  )

  props.form.setValue('workspaces', selectedProjectIds)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextFieldCtrl name='name' label='Full name' required fullWidth />
      </Grid>
      <Grid item xs={12}>
        <TextFieldCtrl name='email' type='email' label='Email address' required fullWidth />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectCtrl
          name='role'
          label='Role'
          value={role}
          onChange={(data) => {
            setRole(data.target.value)
            props.form.setValue('role', data.target.value)
          }}
          options={roleDisplayNames as string[]}
          helperText={rolesExplained}
          required
          fullWidth
        />
      </Grid>
      {(role === 'User' || role === 'Viewer') && (
        <Grid item xs={12} md={6}>
          <WorkspaceMultiselect
            allWorkspaces={projects}
            selectedWorkspaceIds={selectedProjectIds}
            setSelectedWorkspaceIds={(ids) => {
              setSelectedProjectIds(ids)
              props.form.setValue('workspaces', ids)
            }}
            treatNoneSelectedAsAll={false}
          />
        </Grid>
      )}
    </Grid>
  )
}
