import { NavigationItem } from '../main/types/suite-module'
import ReceiptLong from '@mui/icons-material/ReceiptLong'
import DashboardIcon from '@mui/icons-material/Dashboard'
import InventoryIcon from '@mui/icons-material/Inventory'
import { SuitePermission } from '@common/types'

export enum LogisticsRoutes {
  PROJECT_PATH = '/:orgTag/logistics/:projectTag',
  DASHBOARD = '/:orgTag/logistics/:projectTag/dashboard',
  SUPPLIERS = '/:orgTag/logistics/:projectTag/suppliers',
  RFP_DETAILS = '/:orgTag/logistics/:projectTag/rfp/:id',
  SUPPLIER_DETAILS = '/:orgTag/logistics/:projectTag/supplier/:id',
}

export const logisticsNavigationItems: NavigationItem[] = [
  {
    name: 'Dashboard',
    icon: () => <DashboardIcon />,
    path: (activeOrgTag, activeProjectTag) =>
      `/${activeOrgTag}/logistics/${activeProjectTag}/dashboard`,
    requiredPermission: SuitePermission.READ,
  },
  {
    name: 'Negotiations',
    icon: () => <ReceiptLong />,
    path: (activeOrgTag, activeProjectTag) => `/${activeOrgTag}/logistics/${activeProjectTag}`,
    requiredPermission: SuitePermission.MODIFY,
  },
  {
    name: 'Suppliers',
    icon: () => <InventoryIcon />,
    path: (activeOrgTag, activeProjectTag) =>
      `/${activeOrgTag}/logistics/${activeProjectTag}/suppliers`,
    requiredPermission: SuitePermission.MODIFY,
  },
]
