import { Box, Stack, Tooltip, Typography, styled, useTheme, alpha } from '@mui/material'
import { sum } from '@common/utils'

export type BarDataEntry = {
  title: string
  value: number
  disabled?: boolean
}

interface Props {
  data: BarDataEntry[]
  label?: string
  renderTooltip?: (dataEntry: BarDataEntry) => JSX.Element | string
}

export const HorizontallyStackedBar = ({ data, label, renderTooltip = () => '' }: Props) => {
  const theme = useTheme()
  const disabledColor = theme.palette.common.charts.eight
  const backgroundColors = Object.values(theme.palette.common.charts).filter(
    (color) => color !== disabledColor,
  )
  const fullValue = sum(data, (entry) => entry.value)

  return (
    <>
      <Typography fontSize={10}>{label}</Typography>
      <Stack mt={0.5} direction='row'>
        {data.map((dataEntry, index) => (
          <Tooltip
            key={dataEntry.title + dataEntry.value}
            title={renderTooltip(dataEntry)}
            placement='top'
            arrow
          >
            <StyledSegment
              first={index === 0}
              last={index === data.length - 1}
              disabled={dataEntry.disabled}
              sx={{
                flex: dataEntry.value / fullValue,
                background: dataEntry.disabled ? disabledColor : backgroundColors[index],
              }}
            >
              {dataEntry.title}
            </StyledSegment>
          </Tooltip>
        ))}
      </Stack>
    </>
  )
}

type StyledSegmentProps = {
  first: boolean
  last: boolean
  disabled?: boolean
}
const StyledSegment = styled(Box, {
  shouldForwardProp: (prop) => !['first', 'last', 'disabled'].includes(prop as string),
})<StyledSegmentProps>(({ theme, disabled, first, last }) => ({
  padding: '6px 6px',
  color: alpha(theme.palette.common.white, disabled ? 0.3 : 1),
  fontSize: '14px',
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  borderRight: `2px solid ${theme.palette.common.white}`,
  borderTopLeftRadius: first ? '4px' : 0,
  borderBottomLeftRadius: first ? '4px' : 0,
  borderTopRightRadius: last ? '4px' : 0,
  borderBottomRightRadius: last ? '4px' : 0,
  overflow: 'hidden',
}))
