import { Route, Routes } from 'react-router-dom'
import { BrandingDetails } from './Branding/BrandingDetails'
import { CreateBranding } from './Branding/CreateBranding'
import { GeneralOrganizationSettingsPage } from './GeneralOrganizationSettingsPage'
import { BrandingContainer, SettingsContainer } from './SettingsContainers'
import { SettingsPageContainer } from './SettingsPageContainer'

export const OrganizationSettingsPage = ({
  isInternalUser,
  canManageConfig,
}: {
  isInternalUser: boolean
  canManageConfig: boolean
}) => {
  return (
    <>
      <Routes>
        <Route
          path=''
          element={
            <SettingsPageContainer>
              <SettingsContainer py={3}>
                <GeneralOrganizationSettingsPage
                  isInternalUser={isInternalUser}
                  canManageConfig={canManageConfig}
                />
              </SettingsContainer>
            </SettingsPageContainer>
          }
        />
        <Route
          path='/branding/new'
          element={
            <BrandingContainer>
              <CreateBranding canManageConfig={canManageConfig} />
            </BrandingContainer>
          }
        />
        <Route
          path='/branding/:brandingId/:locale?'
          element={
            <BrandingContainer>
              <BrandingDetails canManageConfig={canManageConfig} />
            </BrandingContainer>
          }
        />
        <Route
          path='/branding/:brandingId/:locale/new'
          element={
            <BrandingContainer>
              <BrandingDetails canManageConfig={canManageConfig} />
            </BrandingContainer>
          }
        />
      </Routes>
    </>
  )
}
