import { GridFilterItem, GridFilterOperator } from '@mui/x-data-grid-pro'
import { isAfter, isBefore, parseISO } from 'date-fns'
import { DropdownFilterSelect } from '@components/DropdownSelect/DropdownFilterSelect'

// stolen from admin
export const hasAnyOfOptionsSelectFilterOperator: GridFilterOperator = {
  label: 'has any of',
  value: 'has_any_of',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    const filterValues = filterItem.value as unknown
    if (!Array.isArray(filterValues) || filterValues.length === 0) {
      return null
    }

    return ({ value }): boolean => {
      const valueStr = value as string | null
      return valueStr !== null && filterValues.some((filter) => valueStr.includes(filter))
    }
  },
}

export const hasNothingSelectFilterOperator: GridFilterOperator = {
  label: 'has nothing',
  value: 'has_nothing',
  getApplyFilterFn: (_filterItem: GridFilterItem) => {
    return ({ value }): boolean => {
      return !value
    }
  },
}

export const inDateRangeFilterOperator: GridFilterOperator = {
  label: 'in date range',
  value: 'in_date_range',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    const filterValues = filterItem.value as { start: Date; end: Date } | null

    if (filterValues === null) {
      return null
    }

    return ({ value }): boolean => {
      const valueStr = value as string | null
      if (valueStr === null) {
        return false
      }

      const valueDate = parseISO(valueStr)
      return isAfter(valueDate, filterValues.start) && isBefore(valueDate, filterValues.end)
    }
  },
}

export const equalsOneSelectedOptionFilterOperator = (
  availableOptions: string[],
  defaultOption?: string,
): GridFilterOperator => ({
  label: 'equals',
  value: 'equals',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    const filterValue = filterItem.value as string
    if (!filterValue) {
      return null
    }

    return ({ value }): boolean => filterValue === value
  },
  InputComponent: DropdownFilterSelect,
  InputComponentProps: { availableOptions, defaultOption },
})
