import { CommercialTermsNegotiation } from '@campaigns/types/negotiation'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Grid, IconButton, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { SummaryDetails } from './SummaryDetails/SummaryDetails'
import { SupplierDetails } from './SupplierDetails'

export const basicFont = { fontSize: '0.875rem', fontWeight: 400, lineHeight: '1.5rem' }

export const NegotiationDetailsView = (negotiation: CommercialTermsNegotiation) => {
  const navigate = useNavigate()

  return (
    <Stack flexDirection='row' mt={4} pl={1.5}>
      <Box position='relative' right={(theme) => theme.spacing(0.75)}>
        <IconButton onClick={() => navigate(-1)} sx={{ alignSelf: 'flex-start' }}>
          <ArrowBackIcon fontSize='small' sx={{ color: 'black' }} />
        </IconButton>
      </Box>
      <Grid pr={6} container spacing={2}>
        <Grid item xs={2.5}>
          <Box p={0} pt={0.5}>
            <SupplierDetails negotiation={negotiation} />
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box p={1} pt={0.5}>
            <SummaryDetails negotiation={negotiation} />
          </Box>
        </Grid>
      </Grid>
    </Stack>
  )
}
