import pluralize from 'pluralize'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useGetConfigurationQuery } from '@procurement/store/purchasing'
import { labelForProduct } from '@procurement/utils/labelForProduct'
import { ConfirmationModal } from '@components/ConfirmationModal'
import { NegotiationEventCommonModalProps } from '../types'

export const StartConfirmationModal = ({
  selectedNegotiationEvents = 1,
  isMultiSupplier = false,
  actionableNegotiationEvents,
  ...props
}: NegotiationEventCommonModalProps) => {
  const title = `Start ${pluralize(
    'negotiation',
    selectedNegotiationEvents + (isMultiSupplier === true ? 1 : 0),
  )}`
  const defaultText = isMultiSupplier
    ? 'Negotiation links will be sent out to suppliers. '
    : 'Negotiation link will be sent out to the supplier. '
  const { activeProjectTag } = useActiveProject()
  const { data: configuration } = useGetConfigurationQuery({ projectTag: activeProjectTag })
  const itemName = labelForProduct(configuration?.data.suite.productType, 'actionItemName')
  const content =
    selectedNegotiationEvents > 1
      ? selectedNegotiationEvents === actionableNegotiationEvents
        ? `Negotiation links will be sent out for ${selectedNegotiationEvents} selected ${pluralize(
            itemName,
            selectedNegotiationEvents,
          )}. `
        : `Negotiation links will be sent out for ${actionableNegotiationEvents} out of ${selectedNegotiationEvents} selected ${pluralize(
            itemName,
            selectedNegotiationEvents,
          )}. `
      : defaultText

  return (
    <ConfirmationModal
      confirmType='positive'
      title={title}
      content={content}
      confirmButtonText='Start'
      {...props}
    />
  )
}
