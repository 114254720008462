import React, { useEffect, useState } from 'react'
import { Alert, Dialog, DialogContent, DialogTitle, Grid, IconButton, Stack } from '@mui/material'
import { Close } from '@mui/icons-material'
import { PriceVolumeChart, ChartProps } from './PriceVolumeChart'
import { PriceVolumeAdjustment } from './PriceVolumeAdjustment'
import { PriceVolumePoint } from '@common/types'
import { AreYouSureDialog } from './AreYouSureDialog'

interface Props {
  onSubmit: (points: PriceVolumePoint[]) => void
  open: boolean
  toggleModalOpen: (boolean?: boolean) => void
  defaultValues: PriceVolumePoint[]
  priceVolumePoints: PriceVolumePoint[]
  currency: string
}

export const PriceVolumeDialog = ({
  onSubmit,
  open,
  toggleModalOpen,
  defaultValues,
  priceVolumePoints,
  currency,
  itemName,
  annotations,
}: Props & Pick<ChartProps, 'itemName' | 'annotations'>) => {
  const [newPriceVolumePoints, setNewPriceVolumePoints] = useState<PriceVolumePoint[]>(
    priceVolumePoints || defaultValues || [],
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSafeClose, setIsSafeClose] = useState<boolean>(true)
  const [openAreYouSure, setOpenAreYouSure] = useState<boolean>(false)
  const [focusPoint, setFocusPoint] = useState<PriceVolumePoint | null>(null)

  const closeModal = (forceClose = false) => {
    if (!isSafeClose && !forceClose) {
      setOpenAreYouSure(true)
    } else {
      setNewPriceVolumePoints(priceVolumePoints || defaultValues)
      setFocusPoint(null)
      toggleModalOpen(false)
    }
  }

  const handleForceClose = () => {
    setIsSafeClose(true)
    setOpenAreYouSure(false)
    closeModal(true)
  }

  useEffect(() => {
    if (open || isLoading) {
      setNewPriceVolumePoints(priceVolumePoints)
      setIsLoading(false)
    }
  }, [open, isLoading, priceVolumePoints])

  const submit = () => {
    setIsLoading(true)
    onSubmit(newPriceVolumePoints)
  }

  return (
    <Dialog open={open} onClose={() => closeModal()} fullWidth maxWidth='xl'>
      {/* Could this be replaced with PactumDialogHeader? */}
      <DialogTitle sx={{ pr: 2 }}>
        <Stack direction='row' justifyContent='space-between'>
          Net Price-Volume Model for {itemName}
          <IconButton onClick={() => closeModal()}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Alert severity='info' sx={{ mb: 2 }}>
          The autonomous negotiation involves discussion with the supplier about the volume and
          limits the estimated volume for any <b>Net Price</b> value as shown in this figure.
        </Alert>
        <Grid container spacing={2} direction={{ xs: 'column-reverse', md: 'row' }}>
          <Grid item xs={12} md={7} xl={8}>
            <PriceVolumeChart
              defaultData={defaultValues}
              data={newPriceVolumePoints}
              annotations={annotations}
              itemName={itemName}
              focusPoint={focusPoint}
            />
          </Grid>
          <Grid item xs={12} md={5} xl={4}>
            <PriceVolumeAdjustment
              submitChanges={submit}
              defaultPoints={defaultValues}
              priceVolumePoints={newPriceVolumePoints}
              currency={currency}
              setPriceVolumePoints={setNewPriceVolumePoints}
              setIsSafeClose={setIsSafeClose}
              isLoading={isLoading}
              setFocusPoint={setFocusPoint}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <AreYouSureDialog
        open={openAreYouSure}
        setOpen={setOpenAreYouSure}
        handleForceClose={handleForceClose}
      />
    </Dialog>
  )
}
