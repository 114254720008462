import { NegotiationPreparationInputs, PurchasePriceChangeForm } from '@common/types'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'
import {
  isBackendApiErrorResponse,
  useCreateNegotiationPreparationMutation,
} from 'src/merchandising/store/'
import { SingleFileUpload } from 'src/shared/components/SingleFileUpload'
import { ChangeFormIdSelectionDialog } from './ChangeFormIdSelectionDialog'

interface Props {
  onNewNegotiationPrep: (negotiationPrepId: string) => void
}

export const ChangeFormUpload = ({ onNewNegotiationPrep }: Props) => {
  const { enqueueSnackbar } = useSnackbar()

  const [multiplePpFormRequestPayload, setMultiplePpFormRequestPayload] =
    useState<NegotiationPreparationInputs | null>()
  const [showPpForIdSelection, setShowPpForIdSelection] = useState(false)
  const [uploadedPpFormIds, setUploadedPpFormIds] = useState<PurchasePriceChangeForm['id'][]>([])

  const [createNegotiationPreparation, { isLoading: isCreateLoading }] =
    useCreateNegotiationPreparationMutation()

  const createNegotiationPreparationForSelectedPpFormId = useCallback(
    async (id: keyof NegotiationPreparationInputs) => {
      if (!multiplePpFormRequestPayload) {
        return
      }
      try {
        const response = await createNegotiationPreparation(
          multiplePpFormRequestPayload[id],
        ).unwrap()
        onNewNegotiationPrep(response.id)
      } catch (e) {
        enqueueSnackbar(
          isBackendApiErrorResponse(e)
            ? e.data.message
            : 'There was an error when submitting new change request',
          { variant: 'error' },
        )
      }
    },
    [
      createNegotiationPreparation,
      enqueueSnackbar,
      multiplePpFormRequestPayload,
      onNewNegotiationPrep,
    ],
  )

  const fileUploadCallback = async (acceptedFile: Blob) => {
    const text = await acceptedFile.text()
    let payload
    try {
      payload = JSON.parse(text) as NegotiationPreparationInputs
    } catch (e) {
      enqueueSnackbar('Invalid JSON', { variant: 'error' })
      return
    }
    const ppFormIds = Object.keys(payload ?? {})

    setMultiplePpFormRequestPayload(payload)
    setUploadedPpFormIds(ppFormIds)
  }

  useEffect(() => {
    if (uploadedPpFormIds.length > 1) {
      setShowPpForIdSelection(true)
    } else if (uploadedPpFormIds && uploadedPpFormIds.length === 1) {
      const singlePpFormId = uploadedPpFormIds.find(Boolean)!
      createNegotiationPreparationForSelectedPpFormId(singlePpFormId)
    } else if (!uploadedPpFormIds) {
      enqueueSnackbar('Failed finding PP form IDs from JSON', { variant: 'error' })
    }
  }, [createNegotiationPreparationForSelectedPpFormId, enqueueSnackbar, uploadedPpFormIds])

  return (
    <>
      <SingleFileUpload
        callback={fileUploadCallback}
        isLoading={isCreateLoading}
        fileTypes={['application/json']}
      >
        Drag and drop the PP change form JSON
      </SingleFileUpload>

      <ChangeFormIdSelectionDialog
        open={showPpForIdSelection}
        ids={uploadedPpFormIds}
        onSubmit={(id) => createNegotiationPreparationForSelectedPpFormId(id)}
        onClose={() => setShowPpForIdSelection(false)}
      />
    </>
  )
}
