import { Market } from '../types'
import { readSheet } from '../../../../../main/utils/excelUtils'
import { MarketForecastRow, MarketRow, MarketRowData } from './types'
import { MARKET_FORECAST_SHEET_NAME, MARKETS_SHEET_NAME } from './constants'
import { validateMarketData } from './validations'

const getMarketsInScope = (data: MarketRowData): Market[] => {
  const forecastMarketIds = new Set(
    data.marketForecasts.map((marketForecastRow) => marketForecastRow.MarketID),
  )
  return data.markets
    .filter((market) => forecastMarketIds.has(market.MarketID))
    .map((market) => ({ id: String(market.MarketID), name: market.Name }))
}

export const readAndValidateMarketsInScope = async (excelFile: Blob): Promise<Market[]> => {
  return Promise.all([
    readSheet<MarketForecastRow>(excelFile, MARKET_FORECAST_SHEET_NAME),
    readSheet<MarketRow>(excelFile, MARKETS_SHEET_NAME),
  ]).then(([marketForecastRows, marketRows]) => {
    validateMarketData({ marketForecasts: marketForecastRows, markets: marketRows })
    return getMarketsInScope({ marketForecasts: marketForecastRows, markets: marketRows })
  })
}
