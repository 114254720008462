import React, { useEffect, useRef, useState } from 'react'
import { useImportCoupaSourcingEventMutation } from '../../store/purchasing'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { FormDialog } from '@components/FormDialog'
import { Box, Grid, Typography } from '@mui/material'
import { UseFormReturn } from 'react-hook-form'
import { isBackendApiErrorResponse } from '@shared/backend/error/typeGuards'
import { TextFieldCtrl } from '@components/Form'
import { ImportCoupaSourcingEventEntityFormData } from '@procurement/store/schema'

interface Props {
  open: boolean
  onClose: () => void
}

export const ImportCoupaSourcingEventFormDialog = ({ open, onClose }: Props) => {
  const [loading, setLoading] = useState(false)
  const [generalFormError, setGeneralFormError] = useState('')
  const { activeProjectTag } = useActiveProject()
  const [importCoupaSourcingEvent] = useImportCoupaSourcingEventMutation()
  const errorRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (generalFormError) {
      errorRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [generalFormError, errorRef])

  const handleFormSubmit = async (
    data: ImportCoupaSourcingEventEntityFormData,
    form: UseFormReturn<ImportCoupaSourcingEventEntityFormData>,
  ) => {
    setLoading(true)
    setGeneralFormError('')

    await importCoupaSourcingEvent({
      projectTag: activeProjectTag,
      sourcingEventId: data.id ?? '',
    })
      .unwrap()
      .then(() => {
        form.reset()
        onClose()
      })
      .catch((err) => handleErrors(err))
      .finally(() => {
        setLoading(false)
      })
  }
  const handleErrors = (error: unknown) => {
    if (isBackendApiErrorResponse(error)) {
      setGeneralFormError(error.data.message)
    }
  }
  if (!open) {
    return null
  }

  return (
    <FormDialog
      open={open}
      loading={loading}
      title={'Import a sourcing event from Coupa'}
      error={generalFormError}
      errorRef={errorRef}
      buttons={[
        { type: 'cancel', label: 'Cancel' },
        { type: 'submit', label: 'Import' },
      ]}
      onSubmit={(data, form) => handleFormSubmit(data, form)}
      onCancel={(form) => {
        form.reset()
        onClose()
        setGeneralFormError('')
      }}
    >
      <Grid>
        <Typography my={1}>
          You can import sourcing events directly from Coupa. Simply enter the Coupa sourcing event
          ID into the field below,{' '}
          <Box component='span' fontWeight='fontWeightBold'>
            followed by the revision letter if any (e.g. 514200a).
          </Box>
        </Typography>
        <Typography my={1}>Importing can take a few seconds to complete.</Typography>
        <TextFieldCtrl
          fullWidth
          type='text'
          name='id'
          placeholder='Coupa sourcing event ID'
          required
          margin='normal'
        />
      </Grid>
    </FormDialog>
  )
}
