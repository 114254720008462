import pluralize from 'pluralize'
import { ConfirmationModal } from '@components/ConfirmationModal'

interface NegotiationEventCommonModalProps {
  open: boolean
  selectedNegotiationEvents?: number
  actionableNegotiationEvents?: number
  onCancel: () => void
  onSubmit: () => void
  loading?: boolean
}

export const StartNegotiationConfirmationModal = ({
  selectedNegotiationEvents = 1,
  actionableNegotiationEvents,
  ...props
}: NegotiationEventCommonModalProps) => {
  const title = `Start ${pluralize('negotiation', selectedNegotiationEvents)}`
  const defaultText = 'Negotiation link will be sent out to the supplier.'

  const content =
    selectedNegotiationEvents > 1
      ? selectedNegotiationEvents === actionableNegotiationEvents
        ? `Negotiation links will be sent out for ${selectedNegotiationEvents} selected ${pluralize(
            'negotiation event',
            selectedNegotiationEvents,
          )}. `
        : `Negotiation links will be sent out for ${actionableNegotiationEvents} out of ${selectedNegotiationEvents} selected ${pluralize(
            'negotiation event',
            selectedNegotiationEvents,
          )}. `
      : defaultText

  return (
    <ConfirmationModal
      confirmType='positive'
      title={title}
      content={content}
      confirmButtonText='Start'
      {...props}
    />
  )
}
