import { Grid, Typography } from '@mui/material'
import { AutocompleteFieldCtrl } from '@components/Form'
import { FormRow, FormSectionTitle } from '../../FormCommon/FormCommon'
import { useFormContext } from 'react-hook-form'
import { ExistingPaymentDaysFieldCtrl } from '../../ExistingPaymentDaysFieldCtrl/ExistingPaymentDaysFieldCtrl'
import {
  getMinMaxPaymentDaysLabel,
  getPaymentDaysLabel,
  transformPaymentDaysOptions,
} from '../../../utils/paymentDays'
import { PaymentDayObject } from '../../../store/types'
import { NegotiationEventSupplierFormData } from '../schema'

interface Props {
  paymentDays: (number | PaymentDayObject)[]
}

export const PaymentDays = ({ paymentDays }: Props) => {
  const { watch } = useFormContext<NegotiationEventSupplierFormData>()
  const existingPaymentDays = watch('paymentDays')

  const asc = (a: PaymentDayObject | number, b: PaymentDayObject | number) =>
    (typeof a === 'number' ? a : a.netDays) - (typeof b === 'number' ? b : b.netDays)

  const paymentDaysWithExisting =
    existingPaymentDays && !paymentDays.includes(existingPaymentDays)
      ? [...paymentDays, existingPaymentDays].sort(asc)
      : paymentDays

  const paymentDayOptions: PaymentDayObject[] = transformPaymentDaysOptions(paymentDays)
  const paymentDayOptionsWithExisting: PaymentDayObject[] =
    transformPaymentDaysOptions(paymentDaysWithExisting)

  const formatMinMaxPaymentDays = getMinMaxPaymentDaysLabel(paymentDays)

  return (
    <>
      <FormSectionTitle>Payment days</FormSectionTitle>
      <FormRow>
        <Grid item container xs={12} rowSpacing={2}>
          <Typography variant='body2' sx={{ mb: 1 }}>
            Setting the minimum and maximum payment days requires an agreement to be in this range.
            Existing payment days are used for negotiation tactics.
            <u>Only use payment days if they apply to this contact only.</u>
          </Typography>
        </Grid>
      </FormRow>
      <FormRow>
        <Grid item xs={3.5}>
          <ExistingPaymentDaysFieldCtrl
            helperText='Current agreed payment days'
            name='paymentDays'
            label='Existing Payment days'
            options={paymentDayOptions}
            getOptionLabel={getPaymentDaysLabel}
            variant='outlined'
            freeSolo={true}
            fullWidth
          />
        </Grid>
        <Grid item xs={3.5}>
          <AutocompleteFieldCtrl
            helperText='Do not accept anything less'
            options={paymentDayOptionsWithExisting}
            getOptionLabel={formatMinMaxPaymentDays}
            variant='outlined'
            fullWidth
            name='negotiationSettings.minPaymentDays'
            label='Minimum payment days'
            freeSolo={false}
          />
        </Grid>
        <Grid item xs={3.5}>
          <AutocompleteFieldCtrl
            helperText='Do not accept anything longer'
            options={paymentDayOptionsWithExisting}
            getOptionLabel={formatMinMaxPaymentDays}
            variant='outlined'
            fullWidth
            name='negotiationSettings.maxPaymentDays'
            label='Maximum payment days'
            freeSolo={false}
          />
        </Grid>
      </FormRow>
    </>
  )
}
