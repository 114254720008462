import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { Alert, Box, Button, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useSnackbar } from 'notistack'
import { ItemData, LumpSum, UserInput } from '@common/types'
import { UpdateScenarioRequest } from '@common/dto'
import { useScenarioDetailsData } from 'src/merchandising/pages/Scenarios/ScenarioPreparation/ScenarioPreparation'
import { isBackendApiErrorResponse, useUpdateScenarioInputsMutation } from 'src/merchandising/store'
import { LumpSumRow } from './LumpSumRow'
import { isMinimumAmountValid, isNameValid, isTargetAmountValid } from './validations'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { ITEM_UPDATE_DEBOUNCE_TIMEOUT } from '../../../../constants'
import { useDebounce } from '@hooks'

interface Props {
  items: ItemData[]
  onLumpSumsValidated: (lumpSumsValid: boolean) => void
}

type LumpSumVariant = keyof Pick<UserInput, 'lump_sums' | 'additionalLumpSums'>

export const LumpSums = ({ items, onLumpSumsValidated }: Props) => {
  const { activeProjectTag } = useActiveProject()
  const { detailsData, setDetailsData } = useScenarioDetailsData()
  const [updateScenarioInputs] = useUpdateScenarioInputsMutation()
  const { enqueueSnackbar } = useSnackbar()

  const [lumpSums, setLumpSums] = useState(detailsData.userInputs.lump_sums ?? [])
  const [additionalLumpSums, setAdditionalLumpSums] = useState(
    detailsData.userInputs.additionalLumpSums ?? [],
  )

  const lumpSumSetters: Record<LumpSumVariant, Dispatch<SetStateAction<LumpSum[]>>> = useMemo(
    () => ({
      lump_sums: setLumpSums,
      additionalLumpSums: setAdditionalLumpSums,
    }),
    [setLumpSums, setAdditionalLumpSums],
  )

  const lumpSumStates: Record<LumpSumVariant, LumpSum[]> = useMemo(
    () => ({
      lump_sums: lumpSums,
      additionalLumpSums: additionalLumpSums,
    }),
    [lumpSums, additionalLumpSums],
  )

  useEffect(() => {
    setLumpSums(detailsData.userInputs.lump_sums ?? [])
    setAdditionalLumpSums(detailsData.userInputs.additionalLumpSums ?? [])
  }, [detailsData.userInputs.lump_sums, detailsData.userInputs.additionalLumpSums])

  const lumpSumValidity: Record<LumpSumVariant, [boolean, Dispatch<SetStateAction<boolean>>]> = {
    lump_sums: useState(true),
    additionalLumpSums: useState(true),
  }

  const handleLumpSumDataChange = (
    lumpSumUpdateIndex: number,
    updatedLumpSum: LumpSum,
    variant: LumpSumVariant,
  ) => {
    const updatedLumpSums = lumpSumStates[variant].map((originalLumpSum, index) =>
      index === lumpSumUpdateIndex ? updatedLumpSum : originalLumpSum,
    )

    const lumpSumsValid = triggerLumpSumsValidation(updatedLumpSums, variant)
    lumpSumSetters[variant](updatedLumpSums)

    if (lumpSumsValid) {
      saveLumpSums(updatedLumpSums, variant)
    }
  }

  const handleLumpSumDelete = (lumpSumDeleteIndex: number, variant: LumpSumVariant) => {
    const updatedLumpSums = lumpSumStates[variant].filter(
      (_, index) => index !== lumpSumDeleteIndex,
    )

    const lumpSumsValid = triggerLumpSumsValidation(updatedLumpSums, variant)
    lumpSumSetters[variant](updatedLumpSums)

    if (lumpSumsValid) {
      saveLumpSums(updatedLumpSums, variant)
    }
  }

  const addNewLumpSum = (variant: LumpSumVariant) => {
    const updatedLumpSums = [...lumpSumStates[variant], getDefaultLumpSum(items)]

    lumpSumSetters[variant](updatedLumpSums)
    triggerLumpSumsValidation(updatedLumpSums, variant)
  }

  const triggerLumpSumsValidation = (lumpSums: LumpSum[], variant: LumpSumVariant) => {
    const [_, setValid] = lumpSumValidity[variant]
    const areLumpSumsValid = lumpSums.every((lumpSum) => isLumpSumValid(lumpSum))
    setValid(areLumpSumsValid)
    const areOtherLumpSumsValid = (Object.keys(lumpSumValidity) as LumpSumVariant[])
      .filter((v) => v !== variant)
      .map((v) => lumpSumValidity[v][0])
      .reduce((prev, curr) => prev && curr)

    onLumpSumsValidated(areLumpSumsValid && areOtherLumpSumsValid)
    return areLumpSumsValid
  }

  const saveLumpSums = useDebounce(async (lumpSums: LumpSum[], variant: LumpSumVariant) => {
    const payload: UpdateScenarioRequest = {
      userInputs: {
        ...detailsData.userInputs,
        [variant]: lumpSums,
      },
    }

    try {
      const updatedScenarioData = await updateScenarioInputs({
        sessionKey: detailsData.sessionKey,
        projectTag: activeProjectTag,
        payload,
      }).unwrap()

      setDetailsData({
        ...detailsData,
        userInputs: payload.userInputs,
        state: { ...detailsData.state, ...updatedScenarioData.state },
      })
    } catch (e) {
      enqueueSnackbar(
        isBackendApiErrorResponse(e) ? e.data.message : 'There was an error during scenario update',
        { variant: 'error' },
      )
    }
  }, ITEM_UPDATE_DEBOUNCE_TIMEOUT)

  return (
    <Box>
      <Button startIcon={<AddIcon />} onClick={() => addNewLumpSum('lump_sums')}>
        New item-level entry
      </Button>
      <Button startIcon={<AddIcon />} onClick={() => addNewLumpSum('additionalLumpSums')}>
        New additional entry
      </Button>

      {lumpSums.length > 0 && (
        <>
          <Typography variant='h2' sx={{ mt: 4 }}>
            Item-level lump sums
          </Typography>
          <Alert severity='warning' sx={{ mt: 1, mb: 2 }}>
            Adding item-level lump sums impacts item level prices. Be sure to review the final
            prices before submitting the negotiation.
          </Alert>
          {Object.values(lumpSums).map((lumpSum, index) => (
            <LumpSumRow
              key={index}
              lumpSum={lumpSum}
              items={items}
              currency={detailsData.userInputs.targetCurrency ?? 'EUR'}
              onChange={(updatedLumpSum) =>
                handleLumpSumDataChange(index, updatedLumpSum, 'lump_sums')
              }
              onDelete={() => handleLumpSumDelete(index, 'lump_sums')}
            />
          ))}
        </>
      )}

      {additionalLumpSums.length > 0 && (
        <>
          <Typography variant='h2' sx={{ mt: 4 }}>
            Additional lump sums
          </Typography>
          {Object.values(additionalLumpSums).map((lumpSum, index) => (
            <LumpSumRow
              key={index}
              lumpSum={lumpSum}
              items={items}
              currency={detailsData.userInputs.targetCurrency ?? 'EUR'}
              onChange={(updatedLumpSum) =>
                handleLumpSumDataChange(index, updatedLumpSum, 'additionalLumpSums')
              }
              onDelete={() => handleLumpSumDelete(index, 'additionalLumpSums')}
            />
          ))}
        </>
      )}
    </Box>
  )
}

const getDefaultLumpSum = (items: Props['items']): LumpSum => ({
  minimum_amount: 0,
  target_amount: null,
  description: '',
  name: '',
  type: 'GTD',
  commercial_ids: items.map((item) => item.commercial_id),
})

const isLumpSumValid = (lumpSum: LumpSum) =>
  isNameValid(lumpSum.name) &&
  isMinimumAmountValid(lumpSum.minimum_amount) &&
  isTargetAmountValid(lumpSum.target_amount, lumpSum.minimum_amount) &&
  lumpSum.commercial_ids.length > 0
