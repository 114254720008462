import React from 'react'
import { Grid } from '@mui/material'

type Props = {
  children: React.ReactNode
}

export const GridContainer = ({ children }: Props) => (
  <Grid
    container
    spacing={3}
    sx={{
      mt: 0,
      '& > .MuiGrid-item': {
        pt: 0,
      },
    }}
  >
    {children}
  </Grid>
)
