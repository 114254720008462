import { downloadFile } from '@utils'
import { useLazyDownloadNegotiationEventAttachmentQuery } from '../store/purchasing'
import { useSnackbar } from 'notistack'
import { isBackendApiErrorResponse } from '@shared/backend/error/typeGuards'
import { ProjectTag } from '@common/types'
import { AttachmentTagEnum, NegotiationEventAttachment } from '../store/types'

interface Params {
  attachmentId: number
  negotiationEventId: number
  projectTag: ProjectTag
}

export const useDownloadNegotiationEventAttachmentQuery = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [downloadAttachment] = useLazyDownloadNegotiationEventAttachmentQuery()

  return async ({ attachmentId, projectTag, negotiationEventId }: Params) => {
    try {
      const attachment = await downloadAttachment({
        attachmentId,
        projectTag,
        requisitionId: negotiationEventId,
      }).unwrap()

      downloadFile(attachment.filename, attachment.fileContent)
    } catch (error) {
      const errorMessage = isBackendApiErrorResponse(error)
        ? error.data.message
        : 'Error occurred while downloading negotiation event attachment'

      enqueueSnackbar(errorMessage, { variant: 'error' })
    }
  }
}

export const getUpdatedQuoteAttachment = (attachments: NegotiationEventAttachment[] | undefined) =>
  attachments?.find(({ tag }) => tag === AttachmentTagEnum.UpdatedQuote)
