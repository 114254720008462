import { Components } from '@mui/material/styles'

export const svgIconTheme: Components = {
  MuiSvgIcon: {
    variants: [
      {
        props: { fontSize: 'extra-small' },
        style: () => ({
          fontSize: '1rem',
        }),
      },
    ],
  },
}
