import { ActionsButton } from './ActionsButton'
import { SimpleTooltip } from '@components/OptionalTooltipWrapper'

export interface ActionConfig<I> {
  label: string
  onClick: (id: I) => unknown
  tooltip?: SimpleTooltip
}

export const ACTIONS_BUTTON_WIDTH = 144
export const TABLE_CELL_PADDING = 10
export const ACTIONS_BUTTON_COLUMN_WIDTH = ACTIONS_BUTTON_WIDTH + 2 * TABLE_CELL_PADDING

export type ActionConfigMap<E extends string, I> = Record<E, ActionConfig<I>>

export { ActionsButton }
