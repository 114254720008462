import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { ItemData, UserInput } from '@common/types'
import { useScenarioDetailsData } from 'src/merchandising/pages/Scenarios/ScenarioPreparation/ScenarioPreparation'
import { isBackendApiErrorResponse, useUpdateScenarioInputsMutation } from 'src/merchandising/store'
import { UpdateScenarioRequest } from '@common/dto'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { GridEditBooleanCell, GridRenderCellParams } from '@mui/x-data-grid-pro'

export const IsRfqCell = ({ row, ...otherParams }: GridRenderCellParams<ItemData>) => {
  const { activeProjectTag } = useActiveProject()
  const { detailsData, setDetailsData, sessionKey } = useScenarioDetailsData()
  const [triggerRowUpdate] = useUpdateScenarioInputsMutation()
  const { enqueueSnackbar } = useSnackbar()
  const [isRfq, setIsRfq] = useState<boolean>(
    getItemIsRfqFromUserInput(row.commercial_id, detailsData.userInputs),
  )

  useEffect(() => {
    // Update strategy when Reset values clicked
    setIsRfq(getItemIsRfqFromUserInput(row.commercial_id, detailsData.userInputs))
  }, [row.commercial_id, detailsData])

  const updateIsRfq = async (isRfq: boolean) => {
    setIsRfq(isRfq)

    try {
      const payload = generateUpdatePayload(row.commercial_id, isRfq, detailsData.userInputs)
      const updatedScenarioData = await triggerRowUpdate({
        sessionKey,
        payload,
        projectTag: activeProjectTag,
      }).unwrap()

      setDetailsData({
        ...detailsData,
        userInputs: updatedScenarioData.userInputs,
        state: {
          ...detailsData.state,
          ...updatedScenarioData.state,
        },
      })
    } catch (err) {
      enqueueSnackbar(
        isBackendApiErrorResponse(err)
          ? err.data.message
          : 'There was an error during scenario update',
        { variant: 'error' },
      )
    }
  }

  return (
    <GridEditBooleanCell
      row={row}
      {...otherParams}
      value={isRfq}
      onChange={(e) => updateIsRfq((e.target as HTMLInputElement).checked)}
    />
  )
}

const generateUpdatePayload = (
  updatedItemCommercialId: string,
  isRfq: boolean,
  currentUserInput: UserInput,
): UpdateScenarioRequest => ({
  userInputs: {
    ...currentUserInput,
    items: currentUserInput.items.map((item) => ({
      ...item,
      is_rfq: item.commercial_id === updatedItemCommercialId ? isRfq : item.is_rfq,
    })),
  },
})

const getItemIsRfqFromUserInput = (commercialId: string, userInput: UserInput): boolean => {
  const foundItem = userInput.items.find((item) => item.commercial_id === commercialId)

  return !!foundItem?.is_rfq
}
