import * as React from 'react'
import { ChangeEvent } from 'react'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import { NestedMenuParentItem } from './NestedMenuParentItem'
import { Region } from '@logistics/types/Supplier'
import { REGIONS } from '@shared/utils/region'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
}

interface RegionSelectProps {
  value?: Region[]
  onChange: (newValue: Region[]) => void
}

export const RegionSelect = ({ value, onChange }: RegionSelectProps) => {
  const [internalValue, setInternalValue] = React.useState<Region[]>(value ?? [])

  const [open, setOpen] = React.useState<boolean>(false)

  const handleCountryCheckboxChange = (event: ChangeEvent<HTMLInputElement>): void => {
    let newValue: Region[]
    if (event.target.checked) {
      newValue = [...internalValue, { country: event.target.value, states: [] }]
      setInternalValue(newValue)
    } else {
      newValue = internalValue.filter((region) => region.country !== event.target.value)
      setInternalValue(newValue)
    }
    onChange(newValue)
  }

  const handleStateCheckBoxChange = (
    event: ChangeEvent<HTMLInputElement>,
    country: string,
  ): void => {
    let changedRegion: Region = internalValue.find((region) => region.country === country) ?? {
      country,
      states: [],
    }

    if (event.target.checked) {
      changedRegion = { country, states: [...changedRegion.states, event.target.value] }
    } else {
      changedRegion = {
        country,
        states: [...changedRegion.states.filter((s) => s !== event.target.value)],
      }
    }
    const newValue: Region[] = [
      ...internalValue.filter((r) => r.country !== country),
      changedRegion,
    ]
    setInternalValue(newValue)
    onChange(newValue)
  }

  return (
    <FormControl sx={{ my: 1, width: 300 }} size='small' variant='standard' hiddenLabel={true}>
      <Select
        multiple
        value={internalValue}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        renderValue={(selected) =>
          selected.map((r) => REGIONS.find((region) => region.value === r.country)?.name).join(', ')
        }
        MenuProps={MenuProps}
      >
        {REGIONS.map((region) => {
          if (region.items && region.items.length > 0) {
            return (
              <NestedMenuParentItem
                key={region.value}
                checkboxValue={region.value}
                label={region.name}
                checked={internalValue.find((r) => r.country === region.value) !== undefined}
                onCheckboxChange={handleCountryCheckboxChange}
                parentMenuOpen={open}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
                      width: 350,
                    },
                  },
                }}
              >
                {region.items.map((nestedItem) => (
                  <MenuItem key={nestedItem.value} value={nestedItem.value}>
                    <Checkbox
                      onChange={(e) => handleStateCheckBoxChange(e, region.value)}
                      value={nestedItem.value}
                      checked={
                        internalValue
                          .find((r) => r.country === region.value)
                          ?.states?.find((s) => s === nestedItem.value) !== undefined
                      }
                    />
                    <ListItemText primary={nestedItem.name} />
                  </MenuItem>
                ))}
              </NestedMenuParentItem>
            )
          } else {
            return (
              <MenuItem key={region.value} value={region.value}>
                <Checkbox
                  onChange={handleCountryCheckboxChange}
                  value={region.value}
                  checked={internalValue.find((r) => r.country === region.value) !== undefined}
                />
                <ListItemText primary={region.name} />
              </MenuItem>
            )
          }
        })}
      </Select>
    </FormControl>
  )
}
