import { logisticsBaseApi } from '@logistics/store/baseApi'
import { AdminProjectConfig } from '@logistics/types/AdminProjectConfig'

export const projectConfigApi = logisticsBaseApi.injectEndpoints({
  endpoints: (build) => ({
    getProjectConfig: build.query<AdminProjectConfig, { projectTag: string }>({
      query: ({ projectTag }) => ({
        url: `/suite/${projectTag}/config`,
        method: 'GET',
      }),
      providesTags: ['ProjectConfig'],
    }),
  }),
})

export const { useGetProjectConfigQuery } = projectConfigApi
