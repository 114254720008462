import { useState } from 'react'
import { Stack, Typography } from '@mui/material'
import { InnerContainer, OuterContainer } from 'src/shared/components/Layout/Containers'
import {
  DeleteConfirmationModal,
  StartConfirmationModal,
} from 'src/shared/components/ConfirmationModal'
import { useNegotiationsActions } from 'src/main/hooks'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useGetSingleNegotiationQuery } from 'src/commercial-terms/store'
import { CommercialTermsRoutes } from 'src/commercial-terms/routes'
import { OutlinedButton, PrimaryButton } from '@components/Layout/Statistics'
import { UpdateNegotiationDialog } from '../../components/UpdateNegotiationDialog'
import { StatusChip } from '../HomePage/NegotiationsList/StatusChip'

export const DetailsContextHeader = () => {
  const [startConfirmationModalOpen, setStartConfirmationModalOpen] = useState(false)
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false)
  const [updateModalOpen, setUpdateModalOpen] = useState(false)
  const navigate = useNavigate()

  const { activeOrgTag, activeProjectTag } = useActiveProject()
  const { id: negotiationId } = useParams()

  const { data: negotiation } = useGetSingleNegotiationQuery(
    {
      projectTag: activeProjectTag,
      negotiationId: negotiationId!,
    },
    { skip: negotiationId === undefined },
  )

  const [disabled, setDisabled] = useState(false)

  const withDisabled = async (action: () => Promise<void>) => {
    setDisabled(true)
    await action()
    setDisabled(false)
  }

  const {
    availableActions,
    handleStartNegotiations,
    handleDownloadNegotiations,
    handleDeleteNegotiations,
    handleDownloadAttachment,
  } = useNegotiationsActions(negotiation ? [negotiation] : [])

  const onStartClicked = () => setStartConfirmationModalOpen(true)
  const onDeleteClicked = () => setDeleteConfirmationModalOpen(true)

  const onEditClicked = () => setUpdateModalOpen(true)

  const onStartConfirmed = () => {
    setStartConfirmationModalOpen(false)
    withDisabled(handleStartNegotiations)
  }

  const onDeleteConfirmed = () => {
    setDeleteConfirmationModalOpen(false)
    withDisabled(handleDeleteNegotiations)
    navigate(
      generatePath(CommercialTermsRoutes.HOMEPAGE, {
        orgTag: activeOrgTag,
        projectTag: activeProjectTag,
      }),
    )
  }

  if (!negotiation) {
    return (
      <OuterContainer alignContent='baseline' justifyContent='end'>
        <InnerContainer />
      </OuterContainer>
    )
  }

  const getButtons = () => {
    if (availableActions.has('START')) {
      return (
        <>
          <OutlinedButton disabled={disabled} onClick={onDeleteClicked}>
            Delete negotiation
          </OutlinedButton>
          <OutlinedButton disabled={disabled} onClick={onEditClicked}>
            Edit negotiation
          </OutlinedButton>
          <PrimaryButton disabled={disabled} onClick={onStartClicked}>
            Start negotiation
          </PrimaryButton>
        </>
      )
    } else if (availableActions.has('EDIT')) {
      return (
        <>
          <OutlinedButton disabled={disabled} onClick={onDeleteClicked}>
            Delete negotiation
          </OutlinedButton>
          <PrimaryButton disabled={disabled} onClick={onEditClicked}>
            Edit negotiation
          </PrimaryButton>
        </>
      )
    } else if (availableActions.has('DOWNLOAD_ATTACHMENT') && availableActions.has('DOWNLOAD')) {
      return (
        <>
          <OutlinedButton disabled={disabled} onClick={() => handleDownloadAttachment()}>
            Download Attachments
          </OutlinedButton>
          <PrimaryButton disabled={disabled} onClick={handleDownloadNegotiations}>
            Download results
          </PrimaryButton>
        </>
      )
    } else if (availableActions.has('DOWNLOAD')) {
      return (
        <PrimaryButton disabled={disabled} onClick={handleDownloadNegotiations}>
          Download results
        </PrimaryButton>
      )
    }
  }

  return (
    <OuterContainer alignContent='baseline' justifyContent='end'>
      <InnerContainer>
        <Stack direction='row' mb={5.25}>
          <Typography
            variant='h4'
            fontWeight={600}
            letterSpacing='-0.02em'
            sx={{ color: 'common.white' }}
          >
            {negotiation.supplierCompanyName}
            <StatusChip status={negotiation.status} sx={{ ml: 1.5 }} size='medium' />
          </Typography>
          <Stack ml='auto' direction='row' alignSelf='start' spacing={1.5}>
            {getButtons()}
          </Stack>
        </Stack>
      </InnerContainer>
      <StartConfirmationModal
        open={startConfirmationModalOpen}
        onCancel={() => setStartConfirmationModalOpen(false)}
        onSubmit={onStartConfirmed}
      />
      <DeleteConfirmationModal
        open={deleteConfirmationModalOpen}
        onCancel={() => setDeleteConfirmationModalOpen(false)}
        onSubmit={onDeleteConfirmed}
      />
      <UpdateNegotiationDialog
        key={`negotiation-details-update-negotiation-dialog-${negotiation.id}-${updateModalOpen}`}
        negotiation={negotiation}
        open={updateModalOpen}
        onClose={() => setUpdateModalOpen(false)}
      />
    </OuterContainer>
  )
}
