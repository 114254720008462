import { CheckboxCtrl, DatePickerCtrl, TextFieldCtrl } from '@components/Form'
import { LinkWithArrow } from '@components/SideBlock'
import {
  useDownloadRFPTemplateCsv,
  useDownloadRFPTemplateExcel,
} from '@logistics/store/contractedLanesApi'
import { FormHelperText, Grid, Typography } from '@mui/material'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { SelectSuppliersForRfp } from '@logistics/pages/contractedLanes/RFPListPage/SelectSuppliersForRfp'
import {
  RFPFileUpload,
  RFPFileUploadProps,
} from '@logistics/pages/contractedLanes/RFPListPage/RFPList/RFPFileUpload'
import { MAX_RFP_ADDITIONAL_REQUIREMENTS_LENGTH } from '@logistics/pages/contractedLanes/constants'

export const RFPDetailsForm = ({ isLoading, onUploadDone }: RFPFileUploadProps) => {
  const { activeProjectTag } = useActiveProject()
  const [downloadTemplateCsv] = useDownloadRFPTemplateCsv()
  const [downloadTemplateExcel] = useDownloadRFPTemplateExcel()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <TextFieldCtrl required={true} name='name' label='RFP Name' fullWidth />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DatePickerCtrl
              required={true}
              disablePast={true}
              name='start'
              label='Contract Start Date'
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <DatePickerCtrl
              required={true}
              disablePast={true}
              name='end'
              label='Contract End Date'
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <DatePickerCtrl
            required={true}
            disablePast={true}
            name='deadline'
            label='Deadline for Results'
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <TextFieldCtrl
            multiline
            maxRows={1}
            required={false}
            name='additionalRequirements'
            label='Additional Requirements (Optional)'
            inputProps={{ maxLength: MAX_RFP_ADDITIONAL_REQUIREMENTS_LENGTH }}
            fullWidth
          />
          <FormHelperText>
            This will be shown to the suppliers during the negotiation.
          </FormHelperText>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ pb: 2 }}>
        <Typography variant='h3' sx={{ pb: 2 }}>
          Upload Lanes
        </Typography>

        <Typography>
          Use one of the templates below to fill in lanes information for this RFP and upload it
          back to this UI.
        </Typography>
        <Typography>
          <LinkWithArrow onClick={() => downloadTemplateCsv(activeProjectTag)} sx={{ pb: 2 }}>
            Download the template in CSV format
          </LinkWithArrow>
        </Typography>
        <Typography>
          <LinkWithArrow onClick={() => downloadTemplateExcel(activeProjectTag)}>
            Download the template in Excel (.xlsx) format
          </LinkWithArrow>
        </Typography>

        <RFPFileUpload isLoading={isLoading} onUploadDone={onUploadDone} />
      </Grid>

      <Grid item xs={12}>
        <Typography variant='h3' sx={{ pb: 2 }}>
          Select Suppliers (Optional)
        </Typography>

        <Grid item xs={12} sx={{ pb: 2 }}>
          <SelectSuppliersForRfp name='selectedCarrierIds' projectTag={activeProjectTag} />
          <FormHelperText>
            If there are any selected suppliers, only these will be considered for the RFP.
          </FormHelperText>
        </Grid>

        <Grid item xs={12}>
          <CheckboxCtrl
            name='contactAllSelectedCarriers'
            label={'Contact All Selected Suppliers'}
            required={false}
          />
          <FormHelperText>
            When this option is enabled, all selected suppliers will be contacted for each RFP lane.
          </FormHelperText>
        </Grid>
      </Grid>
    </Grid>
  )
}
