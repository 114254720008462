import { Chip } from '@mui/material'

export const SandboxLabel = (): JSX.Element => {
  return <Chip label='sandbox' sx={style} />
}

const style = {
  fontFamily: 'Inter',
  fontSize: '10px',
  fontWeight: 400,
  lineHeight: '4px',
  letterSpacing: '0.22em',
  textAlign: 'left',
  borderRadius: '8px',
  color: 'white',
  backgroundColor: '#0072CE',
  marginLeft: '8px',
  textTransform: 'uppercase',
}
