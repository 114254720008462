import {
  BENEFIT_NAMES,
  BenefitName,
  CommercialId,
  MarketMetrics,
  ScenarioState,
  Week,
} from '@common/types/scenario/ScenarioState'
import { BSRFilters, Filters } from '../../components/Filters'
import { sumBy, sumNumbers } from '@common/utils'
import { ChartData } from './types'
import {
  getSpendInvoiceSum,
  optionalFilterValueMatchesIndexValue,
  toSpendRatiosByBenefitName,
  toSpendsByBenefitName,
} from './commonDataUtils'
import { getCurrentChartData } from './currentDataUtils'
import { isBenefitToSpendRatioAggregation } from '../../components/Filters/utils'
import { isNumberZeroInclusive } from '@utils'

interface GetChartDataParameters {
  historical: Required<ScenarioState>['historical']
  current: ScenarioState['current']['metrics']
  filters: BSRFilters
}

export const getChartData = ({
  historical,
  current,
  filters,
}: GetChartDataParameters): { chartData: ChartData } => {
  const chartData: ChartData = []

  filters.timeRange.filter(Filters.isSingleSelection).forEach((timeRangeFilter) => {
    filters.markets.filter(Filters.isSingleSelection).forEach((marketFilter) => {
      const singleMarketMetrics: MarketMetrics = historical.metrics.markets[marketFilter.value]
      const itemsForSelection = calculateItemsBenefits(
        singleMarketMetrics,
        filters,
        timeRangeFilter,
      )
      chartData.push(...itemsForSelection)
    })

    if (Filters.isMarketAggregationSelected(filters)) {
      const marketAggregateBenefits = calculateMarketAggregateBenefits(
        Object.values(historical.metrics.markets),
        filters,
        timeRangeFilter,
      )
      chartData.push(...marketAggregateBenefits)

      if (Filters.isItemAggregationSelected(filters)) {
        const allItemMetricsAcrossAllMarkets = Object.values(historical.metrics.markets).flatMap(
          (marketMetrics) => Object.values(marketMetrics.item_metrics),
        )
        chartData.push(
          calculateBenefitAggregations(allItemMetricsAcrossAllMarkets, filters, timeRangeFilter),
        )
      }
    }
  })

  const currentChartData = getCurrentChartData({ current, filters })

  chartData.push(...currentChartData)

  if (Filters.isTimeRangeAggregationSelected(filters)) {
    filters.markets.filter(Filters.isSingleSelection).forEach((marketFilter) => {
      const singleMarketMetrics: MarketMetrics = historical.metrics.markets[marketFilter.value]
      chartData.push(...calculateItemsBenefits(singleMarketMetrics, filters))
    })

    if (Filters.isMarketAggregationSelected(filters)) {
      const marketAggregateBenefits = calculateMarketAggregateBenefits(
        Object.values(historical.metrics.markets),
        filters,
      )
      chartData.push(...marketAggregateBenefits)

      if (Filters.isItemAggregationSelected(filters)) {
        const allItemMetricsAcrossAllMarkets = Object.values(historical.metrics.markets).flatMap(
          (marketMetrics) => Object.values(marketMetrics.item_metrics),
        )
        chartData.push(calculateBenefitAggregations(allItemMetricsAcrossAllMarkets, filters))
      }
    }
  }

  return { chartData }
}

const calculateItemsBenefits = (
  singleMarketMetrics: MarketMetrics,
  filters: BSRFilters,
  selectedTimeRange?: BSRFilters['timeRange'][0],
): ChartData => {
  const chartData: ChartData = []
  const selectedItemIds = Filters.getSingleSelectionValues(filters.items)
  const selectedItemMetrics = selectedItemIds
    .map((selectedItemId) => singleMarketMetrics?.item_metrics[selectedItemId])
    .filter(Boolean)

  if (selectedItemIds.length > 1 || (selectedItemIds.length === 1 && !selectedTimeRange)) {
    chartData.push(calculateBenefitAggregations(selectedItemMetrics, filters, selectedTimeRange))
  } else if (selectedItemIds.length === 1 && selectedTimeRange) {
    const singleItemBsr = getSingleItemBenefits(selectedItemMetrics[0], filters, selectedTimeRange)
    chartData.push(singleItemBsr)
  }

  if (Filters.isItemAggregationSelected(filters) && singleMarketMetrics) {
    const allSingleMarketItemMetrics = Object.values(singleMarketMetrics.item_metrics)
    chartData.push(
      calculateBenefitAggregations(allSingleMarketItemMetrics, filters, selectedTimeRange),
    )
  }

  return chartData
}

const getSingleItemBenefits = (
  singleItemMetrics: MarketMetrics['item_metrics'][CommercialId],
  filters: BSRFilters,
  selectedTimeRange: BSRFilters['timeRange'][0],
): ChartData[0] => {
  if (isBenefitToSpendRatioAggregation(filters) && singleItemMetrics) {
    const itemBenefits = singleItemMetrics.bsr.reduce(
      (bsrByBenefitName, benefitMetrics) => {
        const benefitMetricsValues = benefitMetrics.benefit_metrics[filters.week].values
        const benefitBsrValue = benefitMetricsValues.find(
          optionalFilterValueMatchesIndexValue(selectedTimeRange),
        )?.value

        if (isNumberZeroInclusive(benefitBsrValue)) {
          bsrByBenefitName[benefitMetrics.name] = benefitBsrValue
        }
        return bsrByBenefitName
      },
      {} as Record<BenefitName, number>,
    )

    return {
      x: 0,
      Total: sumNumbers(Object.values(itemBenefits)),
      ...itemBenefits,
    }
  }
  return calculateBenefitAggregations([singleItemMetrics], filters, selectedTimeRange)
}

const calculateMarketAggregateBenefits = (
  marketsMetrics: MarketMetrics[],
  filters: BSRFilters,
  timeRangeFilter?: BSRFilters['timeRange'][0],
) => {
  const chartData: ChartData = []
  const selectedItemIds = Filters.getSingleSelectionValues(filters.items)
  if (selectedItemIds.length === 0) {
    return chartData
  }
  const selectedItemMetricsAcrossMarkets = marketsMetrics
    .flatMap((marketMetrics) => selectedItemIds.map((itemId) => marketMetrics.item_metrics[itemId]))
    .filter(Boolean)
  chartData.push(
    calculateBenefitAggregations(selectedItemMetricsAcrossMarkets, filters, timeRangeFilter),
  )
  return chartData
}

const calculateBenefitAggregations = (
  allItemMetrics: MarketMetrics['item_metrics'][CommercialId][],
  filters: BSRFilters,
  selectedTimeRange?: BSRFilters['timeRange'][0],
): ChartData[0] => {
  const benefitSpends: [BenefitName, number][] = getBenefitSpendSumsByName(
    allItemMetrics,
    filters.week,
    selectedTimeRange,
  )

  const spendInvoiceSum = getSpendInvoiceSum(allItemMetrics, selectedTimeRange)

  const chartSpecificAggregation = isBenefitToSpendRatioAggregation(filters)
    ? calculateBenefitToSpendRatioAggregations(benefitSpends, spendInvoiceSum)
    : calculateSpendAndBenefitsAggregations(benefitSpends, spendInvoiceSum)

  return { ...chartSpecificAggregation, x: 0 }
}

const calculateBenefitToSpendRatioAggregations = (
  benefitSpends: [BenefitName, number][],
  spendInvoiceSum: number,
): ChartData[0] => {
  const aggregationValues = toSpendRatiosByBenefitName(benefitSpends, spendInvoiceSum)

  return {
    Total: sumNumbers(Object.values(aggregationValues)),
    ...aggregationValues,
  }
}

const calculateSpendAndBenefitsAggregations = (
  benefitSpends: [BenefitName, number][],
  spendInvoiceSum: number,
): ChartData[0] => {
  const benefitSpendSum = sumNumbers(benefitSpends.map(([, benefitSpend]) => benefitSpend))
  const benefitSpendsByName = toSpendsByBenefitName(benefitSpends)

  return {
    Total: spendInvoiceSum,
    Net: spendInvoiceSum - benefitSpendSum,
    ...benefitSpendsByName,
  }
}

const getBenefitSpendSumsByName = (
  allItemMetrics: MarketMetrics['item_metrics'][CommercialId][],
  week: Week,
  selectedTimeRange?: BSRFilters['timeRange'][0],
): [BenefitName, number][] =>
  BENEFIT_NAMES.map((benefitName) => [
    benefitName,
    allItemMetrics
      .flatMap((itemMetrics) => itemMetrics?.spend_benefits)
      .filter((spendBenefit) => spendBenefit?.name === benefitName)
      .flatMap((spendBenefit) => spendBenefit.benefit_metrics[week].values)
      .filter(optionalFilterValueMatchesIndexValue(selectedTimeRange))
      .reduce(
        sumBy((valueRecord) => valueRecord.value ?? 0),
        0,
      ),
  ])
