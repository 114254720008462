import { FormDialog } from '@components/FormDialog'
import {
  CommercialTermsNegotiation,
  CustomFieldValue,
  CustomFieldValues,
} from '@campaigns/types/negotiation'
import { UseFormReturn } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMemo, useState } from 'react'
import {
  isBackendApiErrorResponse,
  isQuerySuccessResponse,
  useGetProjectConfigQuery,
  useUpdateNegotiationMutation,
} from '../store'
import { useSnackbar } from 'notistack'
import { NegotiationForm } from './NegotiationForm'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { CustomPaymentDaysType, eomXPaymentDaysConfig } from '@campaigns/types'
import { NegotiationBaseFormSchema, getNegotiationSchema } from './NegotiationSchema'
import { CustomFormValueKey, getCustomFieldName } from './custom/getCustomFieldName'
import { CustomImportSchemaFieldWithExample } from '@common/types'
import { UpdateNegotiation } from '@campaigns/dto/update-negotiation.dto'
import { ISO3Country } from '@campaigns/dto/countries'
import { DEFAULT_LANGUAGE, ISO2Language } from '@campaigns/dto/languages'

interface Props {
  negotiation: CommercialTermsNegotiation
  open: boolean
  onClose: () => void
}

type BaseFormValues = Omit<Record<keyof UpdateNegotiation, string | number | undefined>, 'custom'>

type CustomFormValues = Record<CustomFormValueKey, CustomFieldValue>

type FormValues = BaseFormValues & CustomFormValues

const getDefaultValues = (
  negotiation: CommercialTermsNegotiation,
  customImportFields: CustomImportSchemaFieldWithExample[],
): FormValues => {
  const paymentTerms = negotiation.contracts[0]?.standardTerms

  const hasEomPaymentDays = paymentTerms?.eomPaymentDays?.previous

  return {
    supplierId: negotiation.supplierId || undefined,
    organisationName: negotiation.businessUnit || undefined,
    supplierRepresentativePhone: negotiation.supplierPhone || undefined,
    supplierCompanyName: negotiation.supplierCompanyName || undefined,
    supplierRepresentativeName: negotiation.supplierRepresentativeName || undefined,
    supplierRepresentativeEmail: negotiation.supplierEmail || undefined,
    pastContractPeriod: negotiation.pastSpendYear ?? undefined,
    pastContractValue: negotiation.pastSpend ?? undefined,
    currency: negotiation.currency || undefined,
    pastContractDiscount: paymentTerms?.earlyPaymentDiscount?.previous ?? undefined,
    pastContractDiscountDays: paymentTerms?.earlyPaymentDiscountDays?.previous ?? undefined,
    pastContractPaymentDays: paymentTerms?.paymentDays?.previous ?? undefined,
    country: (negotiation.country as ISO3Country) || undefined,
    language: (negotiation.language as ISO2Language) || undefined,
    eomPaymentDays: hasEomPaymentDays
      ? (eomXPaymentDaysConfig.rendering.renderShortForm(
          paymentTerms?.eomPaymentDays?.previous ?? null,
        ) ?? undefined)
      : undefined,
    ...getDefaultCustomValues(negotiation, customImportFields),
  }
}

const getDefaultCustomValues = (
  negotiation: CommercialTermsNegotiation,
  customImportFields: CustomImportSchemaFieldWithExample[],
): CustomFormValues => {
  return customImportFields.reduce(
    (acc, field) => ({
      ...acc,
      [getCustomFieldName(field)]: negotiation.customFields?.[field.name] ?? null,
    }),
    {},
  )
}

const getSerializedCustomFieldValues = (
  formSchema: NegotiationBaseFormSchema,
): CustomFieldValues => {
  const customEntries = Object.entries(formSchema ?? {}).filter(([key]) =>
    key.startsWith('custom-'),
  )

  const patchedCustomEntries = customEntries.map(([key, value]) => [
    key.replace('custom-', ''),
    value,
  ])

  return Object.fromEntries(patchedCustomEntries) as CustomFieldValues
}

export const UpdateNegotiationDialog = ({ negotiation, open, onClose }: Props) => {
  const [error, setError] = useState<string | null>(null)
  const { enqueueSnackbar } = useSnackbar()
  const { activeProjectTag } = useActiveProject()

  const [updateNegotiationSupplier, { isLoading }] = useUpdateNegotiationMutation()
  const { data: projectConfig } = useGetProjectConfigQuery(activeProjectTag)
  const { languages, customPaymentDaysType, customImportFields } = projectConfig ?? {}

  const negotiationSchema = useMemo(
    () => getNegotiationSchema(customImportFields ?? []),
    [customImportFields],
  )

  const defaultValues = useMemo(
    () => getDefaultValues(negotiation, customImportFields ?? []) as NegotiationBaseFormSchema,
    [negotiation, customImportFields],
  )

  const save = async (
    data: NegotiationBaseFormSchema,
    form: UseFormReturn<NegotiationBaseFormSchema>,
  ) => {
    const updateNegotiationDto: UpdateNegotiation = {
      organisationName: data.organisationName || null,
      supplierRepresentativePhone: data.supplierRepresentativePhone || null,
      supplierId: data.supplierId || null,
      supplierCompanyName: data.supplierCompanyName,
      supplierRepresentativeName: data.supplierRepresentativeName || null,
      supplierRepresentativeEmail: data.supplierRepresentativeEmail || null,
      pastContractPeriod: data.pastContractPeriod,
      pastContractValue: data.pastContractValue ?? null,
      currency: data.currency || null,
      pastContractDiscount: data.pastContractDiscount ?? null,
      pastContractDiscountDays: data.pastContractDiscountDays ?? null,
      pastContractPaymentDays: data.pastContractPaymentDays ?? null,
      language: (data.language as ISO2Language) ?? null,
      country: (data.country as ISO3Country) ?? null,
      ...(customPaymentDaysType === CustomPaymentDaysType.EOMX
        ? {
            eomPaymentDays: data.eomPaymentDays,
          }
        : {}),

      ...((customImportFields ?? [])?.length > 0
        ? { custom: getSerializedCustomFieldValues(data) }
        : {}),
    }

    const response = await updateNegotiationSupplier({
      projectTag: activeProjectTag,
      negotiationId: negotiation.id,
      updateNegotiationDto,
    })

    if (isQuerySuccessResponse(response)) {
      resetAndClose(form)
      if (response.data.status === 'MISSING_DATA') {
        enqueueSnackbar('Negotiation draft updated successfully!', {
          variant: 'warning',
        })
      } else {
        enqueueSnackbar('Negotiation updated successfully!', {
          variant: 'success',
        })
      }
    } else {
      const message = isBackendApiErrorResponse(response.error)
        ? response.error.data.message
        : 'Error occurred while updating negotiation'
      setError(message)
    }
  }

  const resetAndClose = (form: UseFormReturn<NegotiationBaseFormSchema>) => {
    onClose()
    setError(null)
    form.reset()
  }

  return (
    <FormDialog
      open={open}
      title='Edit negotiation'
      fullWidth
      maxWidth='md'
      buttons={[
        { type: 'cancel', label: 'Discard changes' },
        { type: 'submit', label: 'Save' },
      ]}
      onCancel={resetAndClose}
      onSubmit={save}
      resolver={zodResolver(negotiationSchema)}
      loading={isLoading}
      error={error}
      defaultValues={defaultValues}
    >
      <NegotiationForm
        languages={languages ?? [DEFAULT_LANGUAGE]}
        customPaymentDaysType={customPaymentDaysType}
      />
    </FormDialog>
  )
}
