import { CurrencyPicker } from '@components/CurrencyPicker'
import { useProjects } from '@hooks'
import { CurrencyCode } from '@pactum/common'
import { useController } from 'react-hook-form'
import { useGetCurrenciesQuery } from 'src/main/store/configuration'

interface Props {
  name: string
  required?: boolean
  label: string
  disabled: boolean
  helperText?: string
  disableClearable: boolean
}

export const CurrencyPickerCtrl = ({ name, helperText, ...rest }: Props) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name })
  const { activeOrgTag, activeProjectTag } = useProjects()
  const { data: currencyConfig } = useGetCurrenciesQuery(
    {
      orgTag: activeOrgTag!,
      projectTag: activeProjectTag!,
    },
    { skip: !activeOrgTag || !activeProjectTag },
  )
  return (
    <CurrencyPicker
      {...rest}
      selectedCurrency={field.value as CurrencyCode}
      error={!!error}
      helperText={error?.message ?? helperText}
      onChange={(newCode) => {
        field.onChange(newCode)
      }}
      onBlur={field.onBlur}
      displayCurrencies={currencyConfig?.displayCurrencies}
      popularCurrencies={currencyConfig?.popularCurrencies}
      currencies={currencyConfig?.availableCurrencies}
    />
  )
}
