import { styled } from '@mui/material'
import { PactumLoader } from 'src/shared/components/PactumLoader'

import React from 'react'

export const DataGridLoader = (): JSX.Element => (
  <StyledLoaderOverlay>
    <PactumLoader sizePx={36} />
  </StyledLoaderOverlay>
)

const StyledLoaderOverlay = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  zIndex: theme.zIndex.contentOverlay,
  backgroundColor: 'rgba(255 , 255, 255, 0.5)',
  position: 'absolute',
  fill: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))
