import { downloadFile } from '@utils'
import { useSnackbar } from 'notistack'
import {
  isBackendApiErrorResponse,
  useLazyDownloadAllNegotiationsResultsFileQuery,
  // TODO: Remove dependency on product module
  // eslint-disable-next-line import/no-restricted-paths
} from 'src/commercial-terms/store'

export const useDownloadCTResultsExperiment = (projectTag: string) => {
  const [downloadResults] = useLazyDownloadAllNegotiationsResultsFileQuery()
  const { enqueueSnackbar } = useSnackbar()

  const handleDownloadNegotiations = async () => {
    try {
      const downloadResultsBlob = await downloadResults({
        projectTag,
      }).unwrap()

      downloadFile('results.xlsx', downloadResultsBlob)
    } catch (error) {
      if (isBackendApiErrorResponse(error)) {
        enqueueSnackbar(error.data.message, { variant: 'error' })
      }
    }
  }

  return { handleDownloadNegotiations }
}
