import { PactumLoader } from 'src/shared/components/PactumLoader'
import { Box, Paper, TextField } from '@mui/material'
import { NegotiationPreparationModel, ScenarioNegotiationModel } from '@common/types'
import { NegotiationModel } from '@common/dto'
import { isBackendApiErrorResponse } from 'src/merchandising/store/'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { SerializedError } from '@reduxjs/toolkit'

interface Props {
  isLoading: boolean
  isFetching: boolean
  data: NegotiationModel<ScenarioNegotiationModel | NegotiationPreparationModel>
  isError: boolean
  error?: FetchBaseQueryError | SerializedError | undefined
}

export const NegotiationModelContainer = ({
  isLoading,
  isFetching,
  data,
  isError,
  error,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(isBackendApiErrorResponse(error) ? error.data.message : 'Unspecified error', {
        variant: 'error',
      })
    }
  }, [isError, error, enqueueSnackbar])

  if (isLoading || isFetching) {
    return (
      <Box sx={{ width: '100%', p: 2 }}>
        <Paper sx={{ p: 1 }}>
          <PactumLoader />
        </Paper>
      </Box>
    )
  }

  return (
    <Box sx={{ width: '100%', height: '100%', p: 2 }}>
      <Paper sx={{ p: 3 }}>
        <TextField
          fullWidth
          multiline
          defaultValue={JSON.stringify(data, null, 2)}
          inputProps={{ readOnly: true }}
        />
      </Paper>
    </Box>
  )
}
