import { UpdateArticleInputsRequest } from '@common/dto'
import { ArticleWithInput } from '@common/types'
import { HeaderPrimaryButton, useLayoutContext } from '@components/Layout'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
  Autocomplete,
  Box,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import debounce from 'lodash.debounce'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingPage } from 'src/main/pages/LoadingPage'
import {
  isBackendApiErrorResponse,
  useGetNegotiationPreparationQuery,
  useUpdateArticleInputsMutation,
} from 'src/merchandising/store/'
import { DataGrid, dataGridProps } from 'src/shared/components/table'
import { CostModelInsights } from './CostModelInsights'
import { PricingInsights } from './PricingInsights'
import { InPlaceValues, RowDetailsPanel } from './RowDetailsPanel'
import { SupportiveInsights } from './SupportiveInsights'
import { COLUMNS } from './columns'

type ArticleSearchOption = {
  name: string
  ean: string
}

const getFilteredArticles = (articles: ArticleWithInput[], searchValue: ArticleSearchOption) =>
  searchValue.ean && searchValue.name
    ? articles.filter(
        (article) =>
          article.name.toLowerCase().includes(searchValue.name.toLowerCase()) ||
          article.ean.includes(searchValue.ean),
      )
    : articles

const defaultSearchValue = { name: '', ean: '' } as const

export const CostIncreaseFormConfiguration = () => {
  const theme = useTheme()
  const { setActionsComponent, setStatisticsHeaderData } = useLayoutContext()
  const [searchValue, setSearchValue] = useState<ArticleSearchOption>(defaultSearchValue)
  const { negotiationPreparationId } = useParams<{ negotiationPreparationId: string }>()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { data, isLoading, isUninitialized } = useGetNegotiationPreparationQuery(
    negotiationPreparationId!,
    {
      skip: !negotiationPreparationId,
    },
  )
  const [updateArticleValues, { isLoading: isUpdateLoading }] = useUpdateArticleInputsMutation()
  const [changedArticleValues, setChangedArticleValues] =
    useState<UpdateArticleInputsRequest | null>(null)

  const filteredArticles = getFilteredArticles(data?.articles ?? [], searchValue)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceRequest = useCallback(
    debounce(async (changedArticleValues: UpdateArticleInputsRequest) => {
      if (negotiationPreparationId) {
        try {
          await updateArticleValues({
            negotiationPreparationId,
            payload: changedArticleValues,
          }).unwrap()
        } catch (e) {
          enqueueSnackbar(
            isBackendApiErrorResponse(e)
              ? e.data.message
              : 'There was an error when submitting new inputs',
            { variant: 'error' },
          )
        }
      }
    }, 600),
    [],
  )

  useEffect(() => {
    if (changedArticleValues) {
      debounceRequest(changedArticleValues)
    }
  }, [changedArticleValues, debounceRequest])

  useEffect(() => {
    if (data?.articles.length) {
      setStatisticsHeaderData([
        {
          title: 'Avg. Cost Change',
          primaryValue: '+23,45%',
          secondaryValue: 'Based on supplier requests',
          options: { primaryTrend: { direction: 'down', color: 'success' } },
        },
        {
          title: 'Avg. Billing Margin',
          primaryValue: '44,54%',
          secondaryValue: '+2,2% (our proposal)',
          options: { primaryTrend: { direction: 'up', color: 'success' } },
        },
        {
          title: 'Total Revenue Today',
          primaryValue: '$10.6M',
          secondaryValue: '+4,12% YoY',
          options: { primaryTrend: { direction: 'up', color: 'success' } },
        },
        {
          title: 'Total Profit',
          primaryValue: '$634k',
          secondaryValue: '+5,34% YoY',
          options: { primaryTrend: { direction: 'up', color: 'success' } },
        },
      ])

      setActionsComponent(<HeaderPrimaryButton>Negotiate all items</HeaderPrimaryButton>)
    }
  }, [setActionsComponent, data, setStatisticsHeaderData])

  useEffect(() => {
    return () => {
      setStatisticsHeaderData([])
      setActionsComponent(null)
    }
  }, [setActionsComponent, setStatisticsHeaderData])

  const onArticleChanged = async (ean: string, values: InPlaceValues) => {
    setChangedArticleValues({ ean: ean, ...values })
  }

  const searchOptions =
    data?.articles.map((article) => ({ name: article.name, ean: article.ean })) ?? []

  if (isLoading || isUninitialized) {
    return <LoadingPage />
  }

  return (
    <>
      <Stack direction='row' alignItems='center' px={1.5} pt={4}>
        <Box position='relative' right={theme.spacing(0.75)}>
          <IconButton onClick={() => navigate('./../..')}>
            <ArrowBackIcon fontSize='small' sx={{ color: 'black' }} />
          </IconButton>
        </Box>
        <Typography variant='h6' fontWeight={600}>
          {data!.purchasePriceChangeForm.supplierName}
        </Typography>
      </Stack>
      <Box mt={2} px={6} sx={{ width: '100%' }}>
        <Autocomplete
          value={searchValue}
          options={searchOptions}
          getOptionLabel={(option) => option.ean && `${option.name} (${option.ean})`}
          isOptionEqualToValue={(option, value) => option.ean === value.ean}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Search items'
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          onChange={(_, selectedValue) => setSearchValue(selectedValue ?? defaultSearchValue)}
        />
      </Box>
      <Box pr={6}>
        <DataGrid
          {...dataGridProps}
          loading={isUpdateLoading}
          columns={COLUMNS}
          rows={filteredArticles || []}
          getRowId={(row: ArticleWithInput) => row.ean}
          className='dg-expandable'
          getDetailPanelContent={({ row }: { row: ArticleWithInput }) => (
            <RowDetailsPanel
              isEditingDisabled={!data!.isEditingAllowed}
              article={row}
              onValueChanged={(ean: string, values: InPlaceValues) => onArticleChanged(ean, values)}
              defaultInputs={data!.defaultInputs.articles[row.ean]}
              competitorPrices={data!.analysis.competitorPrices?.[row.ean]}
            />
          )}
          getDetailPanelHeight={() => 'auto'}
          localeText={{
            noRowsLabel: 'No articles present',
          }}
        />
      </Box>
      <PricingInsights />
      <CostModelInsights />
      <SupportiveInsights />
    </>
  )
}
