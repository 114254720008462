import { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import {
  IndexToLabelMapping,
  ItemInfo,
  MarketInfo,
  Outcome,
  ScenarioInfo,
} from '@common/types/scenario/ScenarioState'
import { MultiSelectFilter } from './MultiSelectFilter'
import { SingleSelectFilter } from './SingleSelectFilter'
import {
  ALL_ITEMS_OPTION,
  ALL_MARKETS_OPTION,
  ALL_QUARTERS_OPTION,
  ITEMS_AGGREGATE_OPTION,
  MARKET_AGGREGATE_OPTION,
  NEXT_QUARTER_OPTION,
  QUARTER_AGGREGATE_OPTION,
} from '../../constants'
import { CHART_TYPES, PRICES } from './constants'
import { getSortedIndicesToLabels, mapConstantToFilterValues, mapToFilterValues } from './utils'
import { AvailableDefaultFilters, FilterData, FilterOption } from './types'
import { descendingOrder } from '@common/utils'

const getInitialQuartersFilter = (quarters: FilterData['timeRange']) => {
  const numberOfQuartersToAdd = 2

  return [
    QUARTER_AGGREGATE_OPTION,
    NEXT_QUARTER_OPTION,
    ...quarters.slice(0, numberOfQuartersToAdd),
  ]
}

const getInitialMarketsFilter = (
  markets: ScenarioInfo<MarketInfo>,
  defaultMarket = '',
): FilterData['markets'] => {
  const foundMarket = markets[defaultMarket]

  if (!defaultMarket || !foundMarket) {
    return [MARKET_AGGREGATE_OPTION]
  }

  return [
    {
      label: foundMarket.name,
      value: defaultMarket,
      type: 'SINGLE',
    },
  ]
}

interface Props {
  items: ScenarioInfo<ItemInfo>
  markets: ScenarioInfo<MarketInfo>
  quarters?: IndexToLabelMapping
  outcomes?: IndexToLabelMapping
  filtersToShow: Array<keyof FilterData>
  defaultFilters?: Partial<Record<AvailableDefaultFilters, string>>
  onChange: (filterData: FilterData) => void
}

export const ChartFilter = ({
  items,
  markets,
  quarters = {},
  outcomes = {},
  filtersToShow,
  defaultFilters = {},
  onChange,
}: Props) => {
  const sortedQuarterOptions = getSortedIndicesToLabels(
    quarters,
    descendingOrder,
  ) as FilterOption<string>[]
  const sortedOutcomeOptions = getSortedIndicesToLabels(outcomes) as FilterOption<Outcome>[]

  const [selectedItems, setSelectedItems] = useState<FilterData['items']>([ITEMS_AGGREGATE_OPTION])
  const [selectedMarkets, setSelectedMarkets] = useState<FilterData['markets']>(
    getInitialMarketsFilter(markets, defaultFilters.markets),
  )
  const [selectedTimeRange, setSelectedTimeRange] = useState<FilterData['timeRange']>(
    getInitialQuartersFilter(sortedQuarterOptions),
  )
  const [selectedOutcome, setSelectedOutcome] = useState<Required<FilterData>['outcome']>(
    sortedOutcomeOptions[0],
  )
  const [selectedPrice, setSelectedPrice] = useState<Required<FilterData>['price']>({
    label: PRICES[0],
    value: PRICES[0],
    type: 'SINGLE',
  })
  const [selectedChartType, setSelectedChartType] = useState<Required<FilterData>['chartType']>({
    label: CHART_TYPES[0],
    value: CHART_TYPES[0],
    type: 'SINGLE',
  })

  const handleFilterChange = (updatedOptions: Partial<FilterData>) => {
    onChange({
      items: selectedItems,
      markets: selectedMarkets,
      timeRange: selectedTimeRange,
      price: selectedPrice,
      outcome: selectedOutcome,
      chartType: selectedChartType,
      ...updatedOptions,
    })
  }

  useEffect(() => {
    // Set initial filter data to the parent component
    handleFilterChange({})
  }, [])

  const formattedItems = [
    ALL_ITEMS_OPTION,
    ITEMS_AGGREGATE_OPTION,
    ...mapToFilterValues(items, 'name'),
  ]

  const formattedMarkets = [
    ALL_MARKETS_OPTION,
    MARKET_AGGREGATE_OPTION,
    ...mapToFilterValues(markets, 'name'),
  ]

  const formattedTimeRanges = [
    ALL_QUARTERS_OPTION,
    QUARTER_AGGREGATE_OPTION,
    NEXT_QUARTER_OPTION,
    ...sortedQuarterOptions,
  ]

  const chartTypeFilters = mapConstantToFilterValues(CHART_TYPES)
  const priceFilters = mapConstantToFilterValues(PRICES)

  return (
    <Grid container spacing={3}>
      {filtersToShow.includes('chartType') && (
        <Grid item xs={6} md>
          <SingleSelectFilter
            value={selectedChartType.label}
            options={chartTypeFilters.map((chartType) => chartType.label)}
            label='Show'
            labelId='show-label'
            onChange={(selectedChartType) => {
              const selectedChartTypeFilter = chartTypeFilters.find(
                (chartType) => chartType.label === selectedChartType,
              )
              if (selectedChartTypeFilter) {
                setSelectedChartType(selectedChartTypeFilter)
                handleFilterChange({ chartType: selectedChartTypeFilter })
              }
            }}
          />
        </Grid>
      )}
      {filtersToShow.includes('items') && (
        <Grid item xs={6} md>
          <MultiSelectFilter
            value={selectedItems}
            options={formattedItems}
            label='Select items'
            onChange={(options) => {
              setSelectedItems(options)
              handleFilterChange({ items: options })
            }}
          />
        </Grid>
      )}
      {filtersToShow.includes('markets') && (
        <Grid item xs={6} md>
          <MultiSelectFilter
            value={selectedMarkets}
            options={formattedMarkets}
            label='Select markets'
            onChange={(options) => {
              setSelectedMarkets(options)
              handleFilterChange({ markets: options })
            }}
          />
        </Grid>
      )}
      {filtersToShow.includes('outcome') && selectedOutcome && (
        <Grid item xs={6} md>
          <SingleSelectFilter
            label='Negotiation outcome'
            labelId='negotiation-outcome-label'
            options={sortedOutcomeOptions.map((outcomeFilter) => outcomeFilter.label)}
            value={selectedOutcome.label}
            onChange={(selectedOutcome) => {
              const selectedOutcomeFilter = sortedOutcomeOptions.find(
                (outcomeFilter) => outcomeFilter.label === selectedOutcome,
              )
              if (selectedOutcomeFilter) {
                setSelectedOutcome(selectedOutcomeFilter)
                handleFilterChange({ outcome: selectedOutcomeFilter })
              }
            }}
          />
        </Grid>
      )}
      {filtersToShow.includes('timeRange') && (
        <Grid item xs={6} md>
          <MultiSelectFilter
            value={selectedTimeRange}
            options={formattedTimeRanges}
            label='Time range'
            onChange={(options) => {
              setSelectedTimeRange(options)
              handleFilterChange({ timeRange: options })
            }}
          />
        </Grid>
      )}
      {filtersToShow.includes('price') && (
        <Grid item xs={6} md>
          <SingleSelectFilter
            label='Price'
            labelId='weeks-label'
            options={priceFilters.map((price) => price.label)}
            value={selectedPrice.label}
            onChange={(selectedPrice) => {
              const selectedPriceFilter = priceFilters.find(
                (priceFilter) => priceFilter.label === selectedPrice,
              )
              if (selectedPriceFilter) {
                setSelectedPrice(selectedPriceFilter)
                handleFilterChange({ price: selectedPriceFilter })
              }
            }}
          />
        </Grid>
      )}
    </Grid>
  )
}
