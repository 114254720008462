import { SuitePermission } from '@common/types'
import { useUserPermissions } from '@hooks'
import { useLocation } from 'react-router-dom'
import { NavItem, PageNavBar } from './PageNavBar'
import { SettingsEntrypoint } from './entrypoint'

export const SettingsPage = () => {
  const loc = useLocation()
  const { userPermissionsInActiveOrg } = useUserPermissions()

  const canManageUsers = userPermissionsInActiveOrg?.has(SuitePermission.SUITE_USERS_MANAGE)

  return (
    <>
      <PageNavBar hidden={loc.pathname.includes('/branding')}>
        <NavItem to='./general'>Organization</NavItem>
        <NavItem to='./workspaces'>Workspaces</NavItem>
        {canManageUsers && <NavItem to='./users'>User management</NavItem>}
      </PageNavBar>

      <SettingsEntrypoint />
    </>
  )
}
