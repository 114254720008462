import { Box, Button, Stack, styled } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { DataGrid, dataGridProps } from '@components/table'
import {
  DataGridPro,
  GridCellParams,
  GridRowModel,
  GridRowModesModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { createColumns } from './columns'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useUpdateSingleSupplierMutation } from '@logistics/store/supplierApi'
import { Supplier, SupplierContact } from '@logistics/types/Supplier'
import { RemoveSupplierContactModal } from '@logistics/pages/common/SupplierDetailsPage/ContactList/RemoveSupplierContactModal'
import { useSnackbar } from 'notistack'

type Props = {
  supplierData: Supplier
}

const NEW_CONTACT_ID = '999999999999'

export const ContactList = ({ supplierData }: Props) => {
  const { activeProjectTag } = useActiveProject()
  const [updateSingleSupplier, { isLoading }] = useUpdateSingleSupplierMutation()
  const [supplierContacts, setSupplierContacts] = useState(supplierData?.contacts ?? [])
  const [contactToRemove, setContactToRemove] = useState<SupplierContact | undefined>()
  const apiRef = useGridApiRef()
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({})
  const [removeContactDialogOpen, setRemoveContactDialogOpen] = React.useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    setSupplierContacts(supplierData?.contacts ?? [])
  }, [supplierData])

  const addContact = useCallback(() => {
    const id = NEW_CONTACT_ID
    setSupplierContacts([
      ...supplierContacts,
      {
        id,
        timeZone: '',
        isPrimary: false,
        email: '',
        phone: '',
        address: [],
      },
    ])
    apiRef.current.startRowEditMode({ id, fieldToFocus: 'email' })
    apiRef.current.setEditCellValue({ id, field: 'email', value: '', debounceMs: 200 })
  }, [apiRef, supplierContacts])

  const deleteContact = useCallback(
    (id: string) => {
      const filteredContacts: SupplierContact[] = supplierContacts.filter((c) => c.id !== id)

      if (id === NEW_CONTACT_ID) {
        apiRef.current.stopRowEditMode({ id, ignoreModifications: true })
        setSupplierContacts(filteredContacts)
      } else {
        updateSingleSupplier({
          projectTag: activeProjectTag,
          supplier: {
            ...supplierData,
            contacts: filteredContacts,
          },
        })
      }
    },
    [activeProjectTag, apiRef, supplierContacts, supplierData, updateSingleSupplier],
  )

  const selectPrimaryContact = useCallback(
    (id: string) => {
      const contactsWithNewPrimaryContact: SupplierContact[] = supplierContacts.map((contact) => ({
        ...contact,
        isPrimary: contact.id === id,
      }))

      updateSingleSupplier({
        projectTag: activeProjectTag,
        supplier: {
          ...supplierData,
          contacts: contactsWithNewPrimaryContact,
        },
      })
    },
    [activeProjectTag, supplierContacts, supplierData, updateSingleSupplier],
  )

  function handleRowUpdate(contactRow: GridRowModel<SupplierContact>): SupplierContact {
    if (!supplierData) {
      return contactRow
    }
    const supplierDataForUpdate: Supplier = {
      ...supplierData,
      contacts: supplierContacts.map((c) => (c.id === contactRow.id ? contactRow : c)) ?? [],
    }
    updateSingleSupplier({ projectTag: activeProjectTag, supplier: supplierDataForUpdate })
    return contactRow
  }

  function handleRowUpdateError(): void {
    enqueueSnackbar('There was an error saving carrier contact', { variant: 'error' })
  }

  function handleDeleteContact(contact: SupplierContact) {
    if (contact.id !== NEW_CONTACT_ID) {
      setContactToRemove(contact)
      setRemoveContactDialogOpen(true)
    } else {
      deleteContact(contact.id)
    }
  }

  const handleCellClick = useCallback(
    (params: GridCellParams<SupplierContact>, event: React.MouseEvent) => {
      if (!params.isEditable) {
        return
      }

      // Ignore portal
      if (!event.currentTarget.contains(event.target as Element)) {
        return
      }

      if (apiRef.current.getRowMode(params.row.id) === 'view') {
        apiRef.current.startRowEditMode({ id: params.row.id, fieldToFocus: params.field })
      }
    },
    [apiRef],
  )

  const columns = createColumns(handleDeleteContact, selectPrimaryContact)

  return (
    <>
      <Stack spacing={1}>
        <StyledDataGrid
          {...dataGridProps}
          apiRef={apiRef}
          processRowUpdate={handleRowUpdate}
          onProcessRowUpdateError={handleRowUpdateError}
          columns={columns}
          rowCount={supplierContacts.length ?? 0}
          rows={supplierContacts ?? []}
          editMode={'row'}
          loading={isLoading}
          onCellClick={handleCellClick}
          rowModesModel={rowModesModel}
          onRowModesModelChange={(model) => setRowModesModel(model)} // keeps original functionality (double click to edit)
          sortModel={[{ field: 'id', sort: 'asc' }]}
          initialState={{ columns: { columnVisibilityModel: { id: false } } }}
        />

        {!supplierContacts.find((c) => c.id === NEW_CONTACT_ID) && (
          <Box pl={1}>
            <Button variant='outlined' size='small' color='tertiary' onClick={addContact}>
              New contact
            </Button>
          </Box>
        )}
      </Stack>

      {contactToRemove && (
        <RemoveSupplierContactModal
          open={removeContactDialogOpen}
          onCancel={() => {
            setRemoveContactDialogOpen(false)
            setContactToRemove(undefined)
          }}
          onSubmit={async () => {
            deleteContact(contactToRemove.id)
            setRemoveContactDialogOpen(false)
            setContactToRemove(undefined)
          }}
          contact={contactToRemove}
        />
      )}
    </>
  )
}

const StyledDataGrid = styled(DataGrid)(() => ({
  '& .MuiDataGrid-row:hover': {
    backgroundColor: 'unset',
    cursor: 'unset',
  },
})) as typeof DataGridPro
