import { Checkbox, MenuItem, SelectChangeEvent } from '@mui/material'
import { FilterSelect } from '@components/FilterSelect'

export const MISSING_BUSINESS_UNIT_OPTION = 'BUSINESS_UNIT_MISSING'

type BusinessUnit = string | typeof MISSING_BUSINESS_UNIT_OPTION

export const BusinessUnitFilter = ({
  allBusinessUnits,
  selectedBusinessUnits,
  setSelectedBusinessUnits,
}: {
  allBusinessUnits: string[]
  selectedBusinessUnits: BusinessUnit[]
  setSelectedBusinessUnits: (businessUnits: BusinessUnit[]) => void
}) => {
  const handleChange = (e: SelectChangeEvent<unknown>) => {
    const castValue = e.target.value as string[]

    if (
      castValue.includes(MISSING_BUSINESS_UNIT_OPTION) &&
      !selectedBusinessUnits.includes(MISSING_BUSINESS_UNIT_OPTION)
    ) {
      setSelectedBusinessUnits([MISSING_BUSINESS_UNIT_OPTION])
    } else {
      setSelectedBusinessUnits(castValue.filter((value) => value !== MISSING_BUSINESS_UNIT_OPTION))
    }
  }
  return (
    <FilterSelect
      label='Business unit'
      multiple={true}
      value={selectedBusinessUnits}
      onChange={handleChange}
      isValuePresent={(value: unknown) => Array.isArray(value) && value.length > 0}
      renderValue={(value) => {
        if (selectedBusinessUnits.includes(MISSING_BUSINESS_UNIT_OPTION)) {
          return 'Without business unit'
        }

        if (selectedBusinessUnits.length === 1) {
          return `Business unit: ${value}`
        }

        return `Business unit: ${selectedBusinessUnits.length} selected`
      }}
    >
      <MenuItem value={MISSING_BUSINESS_UNIT_OPTION} sx={{ height: '32px' }}>
        <Checkbox
          size='small'
          checked={selectedBusinessUnits.includes(MISSING_BUSINESS_UNIT_OPTION)}
          disableRipple={true}
        />

        {'Without business unit'}
      </MenuItem>

      {allBusinessUnits.map((businessUnit) => (
        <MenuItem key={businessUnit} value={businessUnit} sx={{ height: '32px' }}>
          <Checkbox
            size='small'
            checked={selectedBusinessUnits.includes(businessUnit)}
            disableRipple={true}
          />

          {businessUnit}
        </MenuItem>
      ))}
    </FilterSelect>
  )
}
