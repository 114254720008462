import { UseCase } from '@common/types/suite'
import { suiteBaseApi } from '@shared/api/suite-base-api'

export const useCaseApi = suiteBaseApi.injectEndpoints({
  endpoints: (build) => ({
    getUseCases: build.query<UseCase[], void>({
      query: () => `suite/usecase`,
    }),
  }),
})

export const { useGetUseCasesQuery } = useCaseApi
