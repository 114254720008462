import { percentFormatter, integerFormatter } from '@utils'
import { NotAvailableCell, CaptionedValueCell } from 'src/shared/components/table'
import { getPriceChangeDirectionColor } from 'src/merchandising/pages/ChangeRequests/NegotiationPreparation/Adjustments/utils'

export const UnitsSoldWithYoyChangeCell = ({
  lastYearValue,
  yearBeforeValue,
}: {
  lastYearValue?: number
  yearBeforeValue?: number
}): JSX.Element => {
  if (lastYearValue && yearBeforeValue) {
    const changeValue = lastYearValue - yearBeforeValue
    const changePercentage = lastYearValue / yearBeforeValue - 1
    const color = getPriceChangeDirectionColor(changeValue)
    return (
      <CaptionedValueCell
        mainContent={integerFormatter(lastYearValue)}
        captionContent={`${percentFormatter(changePercentage)}`}
        removeCaptionOpacity={!!color}
      />
    )
  } else if (lastYearValue && !yearBeforeValue) {
    return <CaptionedValueCell mainContent={integerFormatter(lastYearValue)} />
  }
  return <NotAvailableCell />
}
