import { useState } from 'react'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import DownloadIcon from '@mui/icons-material/Download'
import { NegotiationEvent } from '../../../../store/types'
import {
  NegotiationEventAction,
  useNegotiationEventActions,
} from '../../../../hooks/useNegotiationEventActions'
import { BatchActionButton, BatchActionsContainer } from '@components/BatchActions'
import { StartConfirmationModal } from '@procurement/pages/NegotiationEventList/NegotiationEventListTable/StartConfirmationModal/StartConfirmationModal'
import { DeleteConfirmationModal } from '@procurement/pages/NegotiationEventList/NegotiationEventListTable/DeleteConfirmationModal/DeleteConfirmationModal'
import { DownloadConfirmationModal } from '@procurement/pages/NegotiationEventList/NegotiationEventListTable/DownloadConfirmationModal/DownloadConfirmationModal'

interface Props {
  selectedNegotiationEvents: NegotiationEvent[]
}

export const BatchActionsBar = ({ selectedNegotiationEvents }: Props) => {
  const [startConfirmationModalOpen, setStartConfirmationModalOpen] = useState(false)
  const [startConfirmationModalLoading, setStartConfirmationModalLoading] = useState(false)
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false)
  const [deleteConfirmationModalLoading, setDeleteConfirmationModalLoading] = useState(false)
  const [downloadConfirmationModalOpen, setDownloadConfirmationModalOpen] = useState(false)
  const [downloadConfirmationModalLoading, setDownloadConfirmationModalLoading] = useState(false)

  const {
    availableActions,
    getNegotiationEventsForAction,
    handleNegotiationEventStartAction,
    handleNegotiationEventDeleteAction,
    handleNegotiationEventsDownloadAction,
  } = useNegotiationEventActions(selectedNegotiationEvents)

  return (
    <>
      <BatchActionsContainer>
        <BatchActionButton
          startIcon={<DeleteOutlineIcon />}
          disabled={!availableActions.includes(NegotiationEventAction.DELETE)}
          onClick={() => setDeleteConfirmationModalOpen(true)}
        >
          Delete
        </BatchActionButton>
        <BatchActionButton
          startIcon={<PlayCircleOutlineIcon />}
          disabled={!availableActions.includes(NegotiationEventAction.START)}
          onClick={() => setStartConfirmationModalOpen(true)}
        >
          Start
        </BatchActionButton>
        <BatchActionButton
          id={'bulk-export-negotiation-events'}
          startIcon={<DownloadIcon />}
          onClick={() => setDownloadConfirmationModalOpen(true)}
        >
          Download results
        </BatchActionButton>
      </BatchActionsContainer>
      <StartConfirmationModal
        selectedNegotiationEvents={selectedNegotiationEvents.length}
        actionableNegotiationEvents={
          getNegotiationEventsForAction(NegotiationEventAction.START).length
        }
        open={startConfirmationModalOpen}
        onCancel={() => setStartConfirmationModalOpen(false)}
        onSubmit={async () => {
          try {
            setStartConfirmationModalLoading(true)
            await handleNegotiationEventStartAction()
          } finally {
            setStartConfirmationModalOpen(false)
            setStartConfirmationModalLoading(false)
          }
        }}
        loading={startConfirmationModalLoading}
      />
      <DeleteConfirmationModal
        selectedNegotiationEvents={selectedNegotiationEvents.length}
        actionableNegotiationEvents={
          getNegotiationEventsForAction(NegotiationEventAction.DELETE).length
        }
        open={deleteConfirmationModalOpen}
        onCancel={() => setDeleteConfirmationModalOpen(false)}
        onSubmit={async () => {
          try {
            setDeleteConfirmationModalLoading(true)
            await handleNegotiationEventDeleteAction()
          } finally {
            setDeleteConfirmationModalOpen(false)
            setDeleteConfirmationModalLoading(false)
          }
        }}
        loading={deleteConfirmationModalLoading}
      />
      <DownloadConfirmationModal
        selectedNegotiationEvents={selectedNegotiationEvents.length}
        actionableNegotiationEvents={selectedNegotiationEvents.length}
        open={downloadConfirmationModalOpen}
        onCancel={() => setDownloadConfirmationModalOpen(false)}
        onSubmit={async () => {
          try {
            setDownloadConfirmationModalLoading(true)
            await handleNegotiationEventsDownloadAction()
          } finally {
            setDownloadConfirmationModalOpen(false)
            setDownloadConfirmationModalLoading(false)
          }
        }}
        loading={downloadConfirmationModalLoading}
      />
    </>
  )
}
