import { NavigationItem } from 'src/main/types/suite-module'
import { matchPath, NavLink, useLocation } from 'react-router-dom'
import { Link, Stack, styled, Theme, Typography } from '@mui/material'
import { useProjects } from '@hooks'
import { ArrowOutward } from '@mui/icons-material'

export const NavigationListItem = ({ item }: { item: NavigationItem }) => {
  const { activeOrgTag, activeProjectTag } = useProjects()
  const location = useLocation()

  // If item.path is a function, we want to provide activeProjectTag to it to generate complete path
  // If activeProjectTag is missing in that case, it is invalid state (though it occurs during render)
  // in all other cases, treat item.path just as a path
  let completePath: string
  if (!activeOrgTag && !activeProjectTag && typeof item.path === 'function') {
    return null
  } else if (activeOrgTag && typeof item.path === 'function') {
    completePath = item.path(activeOrgTag, activeProjectTag)
  } else {
    completePath = item.path as string
  }

  const isActive = Boolean(matchPath({ path: completePath, end: true }, location.pathname))

  if (item.isExternal) {
    return (
      <StyledLink href={completePath} target='_blank'>
        {item.icon()}
        <NavLinkText active={false}>{item.name}</NavLinkText>
        <ArrowOutward
          sx={(theme) => ({ width: theme.spacing(2), height: theme.spacing(2), ml: 0.5, mt: -1 })}
        />
      </StyledLink>
    )
  }

  return (
    <StyledNavLink to={completePath} data-test-navigation-item={item.name}>
      {item.icon()}
      <NavLinkText active={isActive}>{item.name}</NavLinkText>
    </StyledNavLink>
  )
}

export const NavigationList = styled(Stack)({
  padding: '0 24px',
  justifyContent: 'center',
  flexDirection: 'row',
})

const navStyle = (theme: Theme) => ({
  textDecoration: 'none',
  display: 'inline-flex',
  ' .MuiSvgIcon-root': {
    marginRight: '8px',
  },
  alignItems: 'center',
  margin: '0 16px',
  '&:last-child': {
    marginRight: 0,
  },
  color: theme.palette.common.white,
})

const StyledNavLink = styled(NavLink)(({ theme }) => navStyle(theme))
const StyledLink = styled(Link)(({ theme }) => navStyle(theme))

const NavLinkText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active: boolean }>(({ theme, active }) => ({
  fontWeight: active ? 'bold' : 'normal',
  color: theme.palette.common.white,
}))
