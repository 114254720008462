import React, { MouseEvent, useEffect, useState } from 'react'
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  styled,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import PublicIcon from '@mui/icons-material/Public'
import HelpIcon from '@mui/icons-material/Help'
import { useSnackbar } from 'notistack'
import { AnchorOfferStrategy, ItemData, UserInput } from '@common/types'
import { useScenarioDetailsData } from 'src/merchandising/pages/Scenarios/ScenarioPreparation/ScenarioPreparation'
import { isBackendApiErrorResponse, useUpdateScenarioInputsMutation } from 'src/merchandising/store'
import { UpdateScenarioRequest } from '@common/dto'
import { useFormatter } from '@shared/hooks'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { ErrorTooltip } from '../ErrorTooltip'

type StrategyData = {
  value: AnchorOfferStrategy
  label: string
  getColor: (theme: Theme) => string
}

const STRATEGIES: Record<AnchorOfferStrategy, StrategyData> = {
  conservative: {
    value: 'conservative',
    label: 'Conservative',
    getColor: (theme) => theme.palette.common.charts.fifth,
  },
  neutral: {
    value: 'neutral',
    label: 'Neutral',
    getColor: (theme) => theme.palette.success.main,
  },
  aggressive: {
    value: 'aggressive',
    label: 'Aggressive',
    getColor: (theme) => theme.palette.error.main,
  },
}

interface Props {
  row: ItemData
  canChangeStrategy: boolean
  errorMsg?: string
}

export const NameCell = ({ row, canChangeStrategy, errorMsg }: Props) => {
  const { activeProjectTag } = useActiveProject()
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)
  const [selectedStrategy, setSelectedStrategy] = useState<StrategyData>(
    STRATEGIES[row.anchor_offer_strategy!] || STRATEGIES.neutral,
  )
  const { detailsData, setDetailsData, sessionKey } = useScenarioDetailsData()
  const [triggerRowUpdate] = useUpdateScenarioInputsMutation()
  const { enqueueSnackbar } = useSnackbar()
  const menuOpen = Boolean(menuAnchor)

  useEffect(() => {
    // Update strategy when Reset values clicked
    const updatedStrategy = getItemStrategyFromUserInput(row.commercial_id, detailsData.userInputs)

    if (updatedStrategy) {
      setSelectedStrategy(updatedStrategy)
    }
  }, [row.commercial_id, detailsData])

  const handleMenuButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (canChangeStrategy) {
      setMenuAnchor(event.currentTarget)
    }
  }

  const handleMenuClose = () => {
    setMenuAnchor(null)
  }

  const updateStrategy = async (strategy: StrategyData) => {
    setSelectedStrategy(strategy)
    setMenuAnchor(null)

    try {
      const payload = generateUpdatePayload(row.commercial_id, strategy, detailsData.userInputs)
      const updatedScenarioData = await triggerRowUpdate({
        sessionKey,
        payload,
        projectTag: activeProjectTag,
      }).unwrap()

      setDetailsData({
        ...detailsData,
        userInputs: updatedScenarioData.userInputs,
        state: {
          ...detailsData.state,
          ...updatedScenarioData.state,
        },
      })
    } catch (err) {
      enqueueSnackbar(
        isBackendApiErrorResponse(err)
          ? err.data.message
          : 'There was an error during scenario update',
        { variant: 'error' },
      )
    }
  }

  const onErrorColor = errorMsg ? { color: 'error' } : {}

  return (
    <CellContainer justifyContent='space-between' alignItems='center'>
      <Stack>
        <Stack direction='row'>
          <Tooltip title={row.name}>
            <Typography {...onErrorColor}>{row.name}</Typography>
          </Tooltip>
          <ErrorTooltip error={errorMsg} />
        </Stack>
        <RecommendedRetailPrice recommendedRetailPrice={row.recommended_retail_price} />
      </Stack>
      <StyledStrategyMenuButton
        disableRipple
        selectedStrategy={selectedStrategy}
        onClick={handleMenuButtonClick}
      >
        {canChangeStrategy && (
          <KeyboardArrowDownIcon sx={{ rotate: menuOpen ? '180deg' : '0deg' }} />
        )}
      </StyledStrategyMenuButton>
      <Menu
        anchorEl={menuAnchor}
        open={menuOpen}
        onClose={handleMenuClose}
        PaperProps={{ sx: { width: '250px' } }}
      >
        <StrategyDescription />
        {Object.values(STRATEGIES).map((strategy) => (
          <MenuItem key={strategy.label}>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              sx={{ width: '100%' }}
              onClick={() => updateStrategy(strategy)}
            >
              {strategy.label}
              <StrategyBadge strategy={strategy} />
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </CellContainer>
  )
}

const RecommendedRetailPrice = ({
  recommendedRetailPrice,
}: {
  recommendedRetailPrice: ItemData['recommended_retail_price']
}) => {
  const formatter = useFormatter()
  if (!recommendedRetailPrice) {
    return null
  }

  const priceValue = formatter.currency(recommendedRetailPrice.price, {
    currency: recommendedRetailPrice.currency,
  })

  return (
    <Stack
      direction='row'
      alignItems='center'
      sx={{ fontSize: '10px', color: 'text.secondary' }}
      fontWeight={400}
    >
      RRP: {priceValue}
      {!recommendedRetailPrice.market_id && <PublicIcon sx={{ ml: 1, fontSize: '10px' }} />}
    </Stack>
  )
}

const StrategyDescription = () => (
  <Stack p={1} direction='row' justifyContent='center' alignItems='center'>
    <Typography align='center' component='span' fontWeight='bold' variant='body2'>
      Negotiation strategy
    </Typography>
    <Tooltip title='Negotiation strategy determines how aggressively we push for a price close to the Target, as opposed to more easily reaching an agreement at LAA. For example, an aggressive strategy is to make an anchor offer far below the Target value, while a more conservative strategy is to anchor only slightly below the Target value. Consequently, the system is aiming to reach the target value in all scenarios, but goes more or less aggressively about it.'>
      <HelpIcon sx={{ ml: 0.5, fontSize: 'inherit' }} />
    </Tooltip>
  </Stack>
)

const StyledStrategyMenuButton = styled(IconButton, {
  shouldForwardProp: (prop) => !['selectedStrategy'].includes(prop as string),
})<{ selectedStrategy: StrategyData }>(({ theme, selectedStrategy }) => ({
  width: '16px',
  height: '16px',
  borderRadius: '100%',
  backgroundColor: selectedStrategy.getColor(theme),

  ' .MuiSvgIcon-root': {
    color: theme.palette.common.white,
    fontSize: '16px',
  },
}))

const StrategyBadge = styled(Box, {
  shouldForwardProp: (prop) => !['strategy'].includes(prop as string),
})<{ strategy: StrategyData }>(({ strategy }) => ({ theme }) => ({
  width: '16px',
  height: '16px',
  borderRadius: '100%',
  backgroundColor: strategy.getColor(theme),
}))

const CellContainer = styled(Stack)({
  width: '100%',
  flexDirection: 'row',
})

const generateUpdatePayload = (
  updatedItemCommercialId: string,
  updatedStrategy: StrategyData,
  currentUserInput: UserInput,
): UpdateScenarioRequest => ({
  userInputs: {
    ...currentUserInput,
    items: currentUserInput.items.map((item) => ({
      ...item,
      anchor_offer_strategy:
        item.commercial_id === updatedItemCommercialId
          ? updatedStrategy.value
          : item.anchor_offer_strategy,
    })),
  },
})

const getItemStrategyFromUserInput = (
  commercialId: string,
  userInput: UserInput,
): StrategyData | null => {
  const foundItem = userInput.items.find((item) => item.commercial_id === commercialId)

  if (foundItem?.anchor_offer_strategy) {
    return STRATEGIES[foundItem.anchor_offer_strategy]
  }

  return null
}
