import { StandardTerms, TermStage } from '../negotiation'
import { EOMPaymentDaysValue, EOM_TEMPLATE_FIELD_TYPE } from './eom'
import { ImportSchemaFieldWithExample } from '@common/types'

export type ImportSchemaCustomPaymentDaysFieldType = typeof EOM_TEMPLATE_FIELD_TYPE

export interface CustomPaymentDaysConfiguration<TValue> {
  type: CustomPaymentDaysType
  template: {
    fieldDefinition: ImportSchemaFieldWithExample & {
      optional: false
      optionalDuringImport: true
      paymentDaysField: true
    }
    validator: (value: string) => string | null
    parser: (value: string) => TValue
    valuePresent: (value: TValue) => boolean
  }
  rendering: {
    isRendererApplicable: (terms: StandardTerms, termStage: TermStage) => boolean
    render: (terms: StandardTerms, termStage: TermStage) => string
    renderShortForm: (eomValue: EOMPaymentDaysValue | null) => string | null
  }
}

export enum CustomPaymentDaysType {
  EOMX = 'EOMX',
}
