import { Box, Button, Divider, Stack, Typography } from '@mui/material'
import { AddSharp, Done, SettingsBackupRestore, Tune } from '@mui/icons-material'
import React, { useCallback, useEffect, useState } from 'react'
import { PriceVolumeAdjustmentInputs } from './PriceVolumeAdjustmentInputs'
import {
  arePointNumbersDefined,
  arePriceVolumePointsValid,
  InputFieldErrors,
  isPointValid,
  updateFieldErrors,
} from './validations'
import { PriceVolumePoint } from '@common/types'
import { PactumLoader } from 'src/shared/components/PactumLoader'

export interface PriceVolumePointInputs {
  price: number | ''
  volume: number | ''
}

interface Props {
  defaultPoints?: PriceVolumePoint[]
  priceVolumePoints: PriceVolumePoint[]
  currency: string
  setPriceVolumePoints: (points: PriceVolumePoint[]) => void
  setFocusPoint: (point: PriceVolumePoint | null) => void
  submitChanges: () => void
  setIsSafeClose: (value: boolean) => void
  isLoading: boolean
}

export const PriceVolumeAdjustment = ({
  defaultPoints,
  priceVolumePoints,
  currency,
  setPriceVolumePoints,
  submitChanges,
  setIsSafeClose,
  isLoading,
  setFocusPoint,
}: Props) => {
  const [valuesBeforeEditing, setValuesBeforeEditing] =
    useState<PriceVolumePoint[]>(priceVolumePoints)
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [errors, setErrors] = useState<InputFieldErrors>({})
  const [unsavedValues, setUnsavedValues] = useState<PriceVolumePointInputs[]>([])
  const [unsavedErrors, setUnsavedErrors] = useState<InputFieldErrors>({})

  const isSafeToClose = useCallback(
    (): boolean => !unsavedValues.length && !Object.entries(errors).length && !isEditMode,
    [errors, isEditMode, unsavedValues.length],
  )

  useEffect(() => {
    setIsSafeClose(isSafeToClose())
  }, [isSafeToClose, setIsSafeClose, priceVolumePoints, isEditMode, unsavedValues, errors])

  useEffect(() => {
    setErrors(updateFieldErrors(priceVolumePoints, errors))
  }, [priceVolumePoints, errors])

  const updatePriceVolume = (value: string, index: number, type: keyof PriceVolumePoint) => {
    const numericValue = Number(value.replace(',', '.'))

    if (!Number.isNaN(numericValue) && index >= 0 && priceVolumePoints[index]) {
      const newPriceVolumePoints = [...priceVolumePoints]

      newPriceVolumePoints.splice(index, 1, {
        ...priceVolumePoints[index],
        [type]: value === '' ? '' : numericValue,
      })
      setFocus(newPriceVolumePoints[index])
      setPriceVolumePoints(newPriceVolumePoints)
    }
  }

  const addNewRow = () => setUnsavedValues([...unsavedValues, { price: 0, volume: 0 }])

  const removeRow = (index: number) => {
    const newPriceVolumePoints = [...priceVolumePoints]

    newPriceVolumePoints.splice(index, 1)

    setFocusPoint(null)
    setPriceVolumePoints(newPriceVolumePoints)
  }

  const removeUnsavedRow = (index: number) => {
    const newUnsavedValues = [...unsavedValues]

    newUnsavedValues.splice(index, 1)

    setUnsavedErrors(updateFieldErrors(newUnsavedValues, unsavedErrors))
    setUnsavedValues(newUnsavedValues)
  }

  const confirmRow = (index: number, point: PriceVolumePointInputs) => {
    if (!arePointNumbersDefined(point)) {
      return
    }

    const newUnsavedValues = [...unsavedValues]

    newUnsavedValues.splice(index, 1)

    setUnsavedValues(newUnsavedValues)

    const newPriceVolumesSortedByPrice = [...priceVolumePoints, point].sort(
      (pointA, pointB) => Number(pointA.price) - Number(pointB.price),
    )

    setUnsavedErrors(updateFieldErrors(newUnsavedValues, unsavedErrors))

    if (arePriceVolumePointsValid(newPriceVolumesSortedByPrice)) {
      setPriceVolumePoints(newPriceVolumesSortedByPrice)
    }
  }

  const updateUnsavedPriceVolume = (value: string, index: number, type: keyof PriceVolumePoint) => {
    const numericValue = Number(value)
    if (Number.isNaN(numericValue)) {
      return
    }

    const newUnsavedValues = [...unsavedValues]

    newUnsavedValues.splice(index, 1, {
      ...unsavedValues[index],
      [type]: value === '' ? '' : numericValue,
    })

    setUnsavedErrors(updateFieldErrors([...newUnsavedValues, ...priceVolumePoints], unsavedErrors))
    setUnsavedValues(newUnsavedValues)
  }

  const resetToDefault = () => {
    if (defaultPoints) {
      setFocusPoint(null)
      setErrors({})
      setUnsavedValues([])
      setPriceVolumePoints(defaultPoints)
    }
  }

  const toggleEditing = (submitting = false) => {
    if (isEditMode) {
      setFocusPoint(null)
      setErrors({})
      setUnsavedErrors({})
      setUnsavedValues([])
    }

    if (isEditMode && !submitting) {
      setPriceVolumePoints(valuesBeforeEditing)
    }

    setIsEditMode(!isEditMode)
  }

  const applyChanges = () => {
    setValuesBeforeEditing(priceVolumePoints)

    if (isEditMode) {
      toggleEditing(true)
    }

    submitChanges()
  }

  const sortPoints = () => {
    const sortedPoints = [...priceVolumePoints].sort(
      (pointA, pointB) => pointA.price - pointB.price,
    )

    if (JSON.stringify(priceVolumePoints) !== JSON.stringify(sortedPoints)) {
      setPriceVolumePoints(sortedPoints)
    }
  }

  const setFocus = (point: PriceVolumePointInputs | PriceVolumePoint | null): void => {
    if (!isPointValid(point)) {
      setFocusPoint(null)
    } else {
      setFocusPoint(point)
    }
  }

  return (
    <Box height={'100%'}>
      <Stack justifyContent={'space-between'} height={'100%'}>
        <Box>
          <Stack direction='row' justifyContent={'space-between'}>
            <Typography variant='h6'>Values</Typography>
            <Stack direction='row'>
              {isEditMode && defaultPoints && (
                <Button
                  variant='text'
                  startIcon={<SettingsBackupRestore />}
                  onClick={resetToDefault}
                >
                  Reset
                </Button>
              )}
              <Button
                disabled={isLoading}
                variant='text'
                color={isEditMode ? 'error' : undefined}
                startIcon={<Tune />}
                onClick={() => toggleEditing()}
              >
                {isEditMode ? 'Cancel editing' : 'Edit'}
              </Button>
              {isEditMode && defaultPoints && (
                <Button
                  variant='text'
                  color='success'
                  disabled={!priceVolumePoints.length || !!Object.keys(errors).length}
                  startIcon={<Done />}
                  onClick={applyChanges}
                >
                  Apply
                </Button>
              )}
            </Stack>
          </Stack>
          <Typography variant='body1' my={2}>
            Volumes must decrease or stay the same as the price increases. Note that these data
            points <b>do not set any price boundaries</b>. Instead, lower and upper price bounds are
            defined by LAA Price and Target Price. However, they{' '}
            <b>do set the minimum and maximum volume</b> for this particular item.
          </Typography>
          {isLoading ? (
            <PactumLoader />
          ) : (
            <Stack spacing={2}>
              {isEditMode && !unsavedValues.length && (
                <Button
                  variant='text'
                  disabled={!isEditMode || !!unsavedValues.length}
                  sx={{ maxWidth: '160px' }}
                  startIcon={<AddSharp />}
                  onClick={addNewRow}
                >
                  new entry
                </Button>
              )}
              {isEditMode && !!unsavedValues.length && (
                <Divider>
                  <Typography variant='caption'>Unsaved</Typography>
                </Divider>
              )}
              {unsavedValues.map((unsavedPoint, index) => (
                <PriceVolumeAdjustmentInputs
                  errors={unsavedErrors[index]}
                  key={`unsaved-${index}`}
                  isEditMode={isEditMode}
                  point={unsavedPoint}
                  index={index}
                  currency={currency}
                  updatePriceVolume={updateUnsavedPriceVolume}
                  removeRow={removeUnsavedRow}
                  confirmRow={confirmRow}
                />
              ))}
              {isEditMode && !!unsavedValues.length && (
                <Divider>
                  <Typography variant='caption'>Saved</Typography>
                </Divider>
              )}
              {priceVolumePoints.map((point, index) => (
                <PriceVolumeAdjustmentInputs
                  errors={errors[index]}
                  key={`saved-${index}`}
                  isEditMode={isEditMode}
                  point={point}
                  index={index}
                  currency={currency}
                  updatePriceVolume={updatePriceVolume}
                  removeRow={removeRow}
                  onBlur={sortPoints}
                  onFocus={setFocus}
                />
              ))}
            </Stack>
          )}
        </Box>
      </Stack>
    </Box>
  )
}
