import { Box, Stack, Tooltip, Typography } from '@mui/material'
import { capitalizeFirstLetter } from '@utils'
import { FigureWidget } from '@common/dto/dashboard/dashboard'
import { WidgetSection } from './WidgetSection'

interface Props {
  widgetConfig: FigureWidget
}

export const FiguresWidget = ({ widgetConfig }: Props) => {
  const { figures } = widgetConfig

  return (
    <WidgetSection title={widgetConfig.title} description={widgetConfig.description} wide={true}>
      <Stack direction='row' flexWrap='wrap' gap={3}>
        {figures.map((figure) => (
          <StatisticsItem {...figure} key={figure.title} />
        ))}
      </Stack>
    </WidgetSection>
  )
}

const StatisticsItem = ({
  title,
  primaryValue,
  tooltip,
}: FigureWidget['figures'][0]): JSX.Element => (
  <Stack textAlign='left' maxHeight='88px' flex={1}>
    <Tooltip title={tooltip} placement='right' arrow key={`tooltip-${title}-title`}>
      <Box width={'fit-content'}>
        <Typography component='span' variant='normal' noWrap>
          {capitalizeFirstLetter(title)}
        </Typography>
        <Stack direction='row' mt={0.75} mb={0.25}>
          <PrimaryValue text={primaryValue} />
        </Stack>
      </Box>
    </Tooltip>
  </Stack>
)

const PrimaryValue = ({ text }: { text: string }) => (
  <Typography
    component='span'
    fontWeight={600}
    letterSpacing='-0.04em'
    fontSize='2rem'
    lineHeight='2rem'
    noWrap
  >
    {text}
  </Typography>
)
