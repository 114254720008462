import { NavigationItem } from 'src/main/types/suite-module'
import DashboardIcon from '@mui/icons-material/Dashboard'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import { SuitePermission } from '@common/types'

export enum CommercialTermsRoutes {
  HOMEPAGE = '/:orgTag/commercial-terms/:projectTag',
  DASHBOARD = '/:orgTag/commercial-terms/dashboard/:projectTag',
  NEGOTIATION_DETAILS = '/:orgTag/commercial-terms/:projectTag/negotiation/:id',
}

export const commercialTermsNavigationItems: NavigationItem[] = [
  {
    name: 'Negotiations',
    icon: () => <MenuBookIcon />,
    path: (activeOrgTag, activeProjectTag) =>
      `/${activeOrgTag}/commercial-terms/${activeProjectTag}`,
    requiredPermission: SuitePermission.READ,
  },
  {
    name: 'Dashboard',
    icon: () => <DashboardIcon />,
    path: (activeOrgTag, activeProjectTag) =>
      `/${activeOrgTag}/commercial-terms/dashboard/${activeProjectTag}`,
    requiredPermission: SuitePermission.READ,
  },
]
