import React from 'react'
import { NegotiationEvent } from '../../store/types'
import { useFormatter } from '@shared/hooks'
import { Grid, Typography } from '@mui/material'
import { ProgressCaption } from '../../components/ProgressCaption/ProgressCaption'
import { SupplierSummary } from '../../components/SupplierSummary/SupplierSummary'
import { NegotiationEventLineItemsPage } from '../NegotiationEventLineItems/NegotiationEventLineItemsPage'

type Props = {
  negotiationEvent: NegotiationEvent
}
export const NegotiationEventSummary = ({ negotiationEvent }: Props) => {
  const formatter = useFormatter()

  return (
    <>
      <Grid container spacing={1}>
        <Grid item>
          <Typography variant='h1'>{negotiationEvent.supplier.name}</Typography>
        </Grid>
      </Grid>
      <ProgressCaption negotiationEvent={negotiationEvent} formatter={formatter} />
      <SupplierSummary negotiationEvent={negotiationEvent} />
      <NegotiationEventLineItemsPage
        negotiationEventId={negotiationEvent.id}
        supplierId={negotiationEvent.supplier.id}
      />
    </>
  )
}
