import { Box, Button, Dialog, DialogContent, Stack, styled } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-pro'
import { DataGrid, dataGridProps } from '@components/table'
import { PactumDialogHeader, PactumSmallDialogFooter } from '@components/PactumDialog'
import { RowValidationError } from './RowValidationError'

interface MultiErrorDialogProps {
  open: boolean
  title: string
  errors: RowValidationError[]
  onClose: () => void
}

export const MultiErrorDialog = ({ open, title, errors, onClose }: MultiErrorDialogProps) => {
  return (
    <Dialog open={open} fullWidth>
      <PactumDialogHeader title={title} onClose={onClose} />
      <DialogContent sx={{ maxHeight: '300px' }}>
        <ErrorsTable errors={errors} />
      </DialogContent>
      <PactumSmallDialogFooter>
        <StyledButton onClick={onClose}>Ok</StyledButton>
      </PactumSmallDialogFooter>
    </Dialog>
  )
}

const commonColumnParams = { disableColumnMenu: true, sortable: false, resizable: false }
const columns: GridColDef<RowValidationError & { index: number }>[] = [
  {
    ...commonColumnParams,
    field: 'row',
    headerName: 'Row',
    flex: 1,
    renderCell: ({ value }) => <Stack alignSelf='flex-start'>{value}</Stack>,
  },
  {
    ...commonColumnParams,
    field: 'message',
    headerName: 'Error',
    flex: 9,
  },
]

const ErrorsTable = ({ errors }: { errors: RowValidationError[] }) => {
  const errorsWithIndices = errors.map((error, index) => ({
    ...error,
    index,
  }))

  return (
    <Box px={2}>
      <DataGrid
        {...dataGridProps}
        columns={columns}
        rows={errorsWithIndices}
        rowHeight={32}
        getRowId={(row: RowValidationError & { index: number }) => row.index}
        getRowHeight={() => 'auto'}
        getRowSpacing={() => ({ top: 5, bottom: 5 })}
      />
    </Box>
  )
}

const StyledButton = styled(Button)(({ theme }) => ({
  width: '50%',
  minWidth: '100px',
  padding: theme.spacing(1),
  fontWeight: 700,
  borderRadius: '2px',
  background: theme.palette.warning.main,
  ':hover': {
    background: theme.palette.warning.main,
  },
}))
