import { FormDialog } from '@components/FormDialog'
import { useCreateNegotiationEventSupplierMutation } from '@procurement/store/purchasing'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { isBackendApiErrorResponse, isQuerySuccessResponse } from '@shared/backend/error/typeGuards'
import { useEffect, useRef, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { ProductType } from '@pactum/core-backend-types'
import { validateNegotiationEventSupplierForm } from '@procurement-shared/NegotiationEventSupplierForm/validation'
import { mapToCreateNegotiationEventSupplierDto } from '@procurement-shared/NegotiationEventSupplierForm/mappers'
import { NegotiationEventSupplierForm } from '@procurement-shared/NegotiationEventSupplierForm/NegotiationEventSupplierForm'
import {
  NegotiationEventSupplierFormData,
  negotiationEventSupplierFormDataSchema,
} from '@procurement/components/NegotiationEventSupplierForm/schema'
import { useSyncNegotiationEventAttachments } from '@procurement/hooks/useSyncNegotiationEventAttachments'

interface Props {
  negotiationEventId: number
  onClose: (addedNew: boolean) => void
  open: boolean
  productType?: ProductType
}

export const AddSupplierDialog = ({ open, onClose, negotiationEventId, productType }: Props) => {
  const { activeProjectTag } = useActiveProject()
  const { enqueueSnackbar } = useSnackbar()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const errorRef = useRef<HTMLDivElement>(null)

  const [createNegotiationEventSupplier, { isLoading: isCreateSupplierLoading }] =
    useCreateNegotiationEventSupplierMutation()
  const [syncNegotiationEventAttachments, { isLoading: isSyncAttachmentsLoading }] =
    useSyncNegotiationEventAttachments()

  useEffect(() => {
    if (errorMessage) {
      errorRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [errorMessage, errorRef])

  const form = useForm<NegotiationEventSupplierFormData>({
    defaultValues: {
      attachments: [],
      contactName: '',
      detachedAttachments: [],
      email: '',
      externalId: '',
      locale: '',
      name: '',
      phone: '',
      requisitionId: negotiationEventId,
    },
    resolver: zodResolver(negotiationEventSupplierFormDataSchema),
  })

  const resetAndClose = (addedNew = false) => {
    onClose(addedNew)
    form.reset()
    setErrorMessage(null)
  }

  const updateErrorMessage = (error: unknown) => {
    const message = isBackendApiErrorResponse(error)
      ? error.data.message
      : 'Error occurred while creating supplier'
    setErrorMessage(message)
  }

  const save = async (data: NegotiationEventSupplierFormData) => {
    if (productType === ProductType.ContractCost) {
      try {
        validateNegotiationEventSupplierForm(data)
      } catch (error) {
        setErrorMessage((error as Error).message)
        return
      }
    }

    const createSupplierResponse = await createNegotiationEventSupplier({
      createNegotiationEventSupplierDto: mapToCreateNegotiationEventSupplierDto(data),
      negotiationEventId,
      projectTag: activeProjectTag,
    })

    if (isQuerySuccessResponse(createSupplierResponse)) {
      try {
        await syncNegotiationEventAttachments({
          data,
          negotiationEventId,
          supplierId: createSupplierResponse.data.id,
        })
        resetAndClose(true)
        enqueueSnackbar('Supplier created successfully!', {
          variant: 'success',
        })
      } catch (syncAttachmentsError) {
        updateErrorMessage(syncAttachmentsError)
      }
    } else {
      updateErrorMessage(createSupplierResponse.error)
    }
  }

  const onErrors = (errors: unknown) => {
    if (errors) {
      console.error('Form errors:', errors)
    }
  }

  return (
    <FormDialog
      open={open}
      loading={isCreateSupplierLoading || isSyncAttachmentsLoading}
      title='Add supplier'
      fullWidth
      maxWidth='md'
      buttons={[
        { type: 'cancel', label: 'Cancel' },
        { type: 'submit', label: 'Add supplier' },
      ]}
      form={form}
      onSubmit={save}
      onCancel={() => resetAndClose()}
      error={errorMessage}
      errorRef={errorRef}
      onErrors={onErrors}
    >
      <NegotiationEventSupplierForm />
    </FormDialog>
  )
}
