import { lighten } from '@mui/material'
import { Components, Theme } from '@mui/material/styles'

export const buttonTheme: Components<Theme> = {
  MuiButton: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
        textTransform: 'none',
        letterSpacing: '-0.005em',
        fontWeight: 700,
        '&:hover': {
          backgroundColor: 'none',
          boxShadow: 'none',
        },
      },
    },
    variants: [
      {
        props: { size: 'medium' },
        style: ({ theme }) => ({
          height: theme.spacing(4),
          padding: theme.spacing(1, 2),
        }),
      },
      {
        props: { size: 'large' },
        style: ({ theme }) => ({
          height: theme.spacing(5),
          padding: theme.spacing(1),
        }),
      },
      {
        props: { color: 'white' },
        style: ({ theme }) => ({
          backgroundColor: theme.palette.common.white,
          border: `1px solid ${theme.palette.grey.A700}`,
          '&:hover': {
            backgroundColor: theme.palette.grey.A200,
          },
        }),
      },
      {
        props: {
          color: 'accent',
          variant: 'contained',
        },
        style: ({ theme }) => ({
          backgroundColor: theme.palette.accent.main,
          color: theme.palette.common.white,
          '&:hover': {
            backgroundColor: lighten(theme.palette.accent.main, 0.2),
          },
        }),
      },
      {
        props: {
          variant: 'rounded',
        },
        style: ({ theme }) => ({
          color: theme.palette.text.primary,
          borderRadius: '100px',
          ':hover': {
            boxShadow: 'none',
          },
        }),
      },
      {
        props: {
          size: 'small',
        },
        style: ({ theme }) => ({
          textTransform: 'none',
          lineHeight: '0.5rem',
          padding: theme.spacing(1),
          fontWeight: 700,
          fontSize: '0.625rem',
        }),
      },
      {
        props: {
          variant: 'outlined',
        },
        style: ({ theme }) => ({
          boxSizing: 'border-box',
          border: `1px solid`,
          padding: `${theme.spacing(0.875)} ${theme.spacing(1)}`, // 3px x-axis padding because of 1px border
          borderRadius: '4px',
          ':hover': {
            borderRadius: '4px',
            border: `1px solid`,
          },
          '& .MuiTouchRipple-root': {
            borderRadius: '3px',
          },
        }),
      },
      {
        props: {
          color: 'tertiary',
        },
        style: ({ theme }) => ({
          color: theme.palette.text.primary,
          borderColor: theme.palette.tertiary.main,
          ':hover': {
            borderColor: theme.palette.common.black,
          },
        }),
      },
    ],
  },
}
