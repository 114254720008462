import { UnsuitablePaymentTermsFeedback } from '@procurement/store/types'
import { Stack, Typography } from '@mui/material'
import { FeedbackTitle } from './FeedbackTitle'
import { FeedbackReasonText } from './FeedbackReasonText'
import { isNumber } from '@shared/utils/type-guards'

interface Props {
  feedbackDetails: UnsuitablePaymentTermsFeedback
}

export const UnsuitablePaymentTerms = ({ feedbackDetails }: Props) => {
  const preferredPaymentDays = feedbackDetails.preferredPaymentDays
  const preferredGeneralDiscount = feedbackDetails.preferredGeneralDiscount

  return (
    <Stack direction='column' gap={3}>
      <FeedbackTitle>Unsuitable payment terms</FeedbackTitle>
      <Typography variant='body1'>Acceptable: {preferredPaymentDays}</Typography>
      {isNumber(preferredGeneralDiscount) && preferredGeneralDiscount !== 0 ? (
        <FeedbackReasonText
          label='Preferred general discount'
          value={preferredGeneralDiscount.toString()}
        />
      ) : null}
    </Stack>
  )
}
