import { NegotiationEventSupplierFormData } from '../schema'

export const getTotalLineItemsPrice = (lineItems: NegotiationEventSupplierFormData['lineItems']) =>
  lineItems.reduce((sum, lineItem) => {
    const { quotedUnitPrice, quantity } = lineItem

    if (quotedUnitPrice && quantity) {
      return sum + quotedUnitPrice * quantity
    }

    return sum
  }, 0)
