import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetScenarioModelQuery } from 'src/merchandising/store/'
import { NegotiationModelContainer } from 'src/merchandising/pages/NegotiationModelContainer'
import { NegotiationModel } from '@common/dto'
import { ScenarioNegotiationModel } from '@common/types'
import { useActiveProject } from '@shared/hooks/useActiveProject'

export const ScenarioModelPage = () => {
  const { activeProjectTag } = useActiveProject()
  const { sessionKey } = useParams<{ sessionKey: string }>()
  const { data, isFetching, isLoading, isError, error } = useGetScenarioModelQuery(
    {
      projectTag: activeProjectTag,
      sessionKey: sessionKey!,
    },
    {
      skip: !sessionKey,
    },
  )

  return (
    <NegotiationModelContainer
      isLoading={isLoading}
      isFetching={isFetching}
      data={data ?? ({} as NegotiationModel<ScenarioNegotiationModel>)}
      isError={isError}
      error={error}
    />
  )
}
