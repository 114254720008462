import { Stack } from '@mui/material'
import { UntenableRequestFeedback } from '@procurement/store/types'
import { FeedbackTitle } from './FeedbackTitle'
import { FeedbackReasonText } from './FeedbackReasonText'

type Props = {
  feedbackDetails: UntenableRequestFeedback
}

export const UntenableRequest = ({ feedbackDetails }: Props) => (
  <Stack direction='column' gap={3}>
    <FeedbackTitle>The request was untenable</FeedbackTitle>
    <FeedbackReasonText label='Comment' value={feedbackDetails.reason} />
  </Stack>
)
