import {
  GridFilterItem,
  GridFilterModel,
  GridLogicOperator,
  GridPaginationModel,
  GridSortDirection,
  GridSortModel,
} from '@mui/x-data-grid-pro'
import queryString from 'query-string'
import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

const PAGE_QUERY_PARAM = 'page'
const PAGE_SIZE_QUERY_PARAM = 'pageSize'
const SORT_QUERY_PARAM = 'sort'
const FILTER_QUERY_PARAM = 'filters'

interface SuiteDataGridState {
  pagination: GridPaginationModel
  sort: GridSortModel
  filters: GridFilterModel
  queryStringState: string
}

interface SuiteDataGridStateActions {
  setPagination: (pagination: GridPaginationModel) => void
  setSort: (sort: GridSortModel) => void
  setFilters: (filters: GridFilterModel) => void
  setSearch: (search: string) => void
  setFilterItems: (items: GridFilterItem[]) => void
  setFilterItem: (key: string, operator: GridLogicOperator, value: string | string[]) => void
  getFilterItem: (key: string) => GridFilterItem | undefined
}

const initialState: SuiteDataGridState = {
  pagination: {
    page: 0,
    pageSize: 10,
  },
  sort: [],
  filters: {
    items: [],
    quickFilterValues: [],
  },
  queryStringState: '',
}

interface CreateSuiteDataGridOptions {
  debug?: boolean
  defaultPageSize?: number
  sort?: GridSortModel
}

const parseFilter = (filter: GridFilterItem): GridFilterItem => {
  if (filter.operator === 'in_date_range') {
    const value = filter.value as { start: string; end: string }

    filter.value = {
      start: new Date(value.start),
      end: new Date(value.end),
    }
  }

  return filter
}

export const useSuiteDataGrid = (
  opts?: CreateSuiteDataGridOptions,
): SuiteDataGridState & SuiteDataGridStateActions => {
  const [queryParams, setSearchParams] = useSearchParams()

  const [pagination, setInternalPagination] = useState<GridPaginationModel>({
    page: queryParams.get(PAGE_QUERY_PARAM)
      ? Number(queryParams.get(PAGE_QUERY_PARAM))
      : initialState.pagination.page,
    pageSize: queryParams.get(PAGE_SIZE_QUERY_PARAM)
      ? Number(queryParams.get(PAGE_SIZE_QUERY_PARAM))
      : (opts?.defaultPageSize ?? initialState.pagination.pageSize),
  })

  const [sort, setInternalSort] = useState<GridSortModel>(
    queryParams.has(SORT_QUERY_PARAM)
      ? queryParams
          .get(SORT_QUERY_PARAM)!
          .split(',')
          .map((sortParam) => {
            const [field, sort] = sortParam.split(' ')
            return { field, sort: sort as GridSortDirection }
          })
      : (opts?.sort ?? initialState.sort),
  )

  const [filters, setInternalFilters] = useState<GridFilterModel>({
    quickFilterValues: queryParams.has(FILTER_QUERY_PARAM)
      ? (
          JSON.parse(
            decodeURIComponent(window.atob(queryParams.get(FILTER_QUERY_PARAM) ?? '{}')),
          ) as GridFilterModel
        )?.quickFilterValues
      : [],
    items: queryParams.has(FILTER_QUERY_PARAM)
      ? (
          JSON.parse(
            decodeURIComponent(window.atob(queryParams.get(FILTER_QUERY_PARAM) ?? '{}')),
          ) as GridFilterModel
        )?.items?.map(parseFilter)
      : [],
  })

  const setSort = (sort: GridSortModel) => {
    setInternalSort(sort)
  }

  const setFilters = (filters: GridFilterModel) => {
    setInternalFilters(filters)
  }

  const setPagination = (pagination: GridPaginationModel) => {
    setInternalPagination(pagination)
  }

  const setSearch = (search: string) => {
    setInternalFilters({
      ...filters,
      quickFilterValues: [search],
    })
  }

  const setFilterItems = useCallback(
    (items: GridFilterItem[]) => {
      setInternalFilters({
        ...filters,
        items,
      })
    },
    [filters],
  )

  const setFilterItem = useCallback(
    (key: string, operator: GridLogicOperator | string, value: string | string[]) => {
      setInternalFilters({
        ...filters,
        items: [
          ...filters.items.filter((item) => item.field !== key),
          {
            id: key,
            field: key,
            value,
            operator,
          },
        ],
      })
    },
    [filters],
  )

  const getFilterItem = (key: string): GridFilterItem | undefined => {
    return filters.items.find((item) => item.field === key)
  }

  const queryStringState = useMemo(() => {
    const values: Record<string, unknown> = {
      page: pagination.page,
      pageSize: pagination.pageSize,
      sort: sort.map((sort) => `${sort.field} ${sort.sort}`),
    }

    if (filters?.quickFilterValues?.length || filters?.items?.length) {
      values.filters = window.btoa(encodeURIComponent(JSON.stringify(filters)))
    }

    return queryString.stringify(values, {
      arrayFormat: 'comma',
    })
  }, [pagination.page, pagination.pageSize, sort, filters])

  useEffect(() => {
    setSearchParams(queryStringState)
  }, [queryStringState, setSearchParams])

  if (opts?.debug) {
    console.log('SuiteDataGridProvider', queryStringState)
  }

  return {
    pagination,
    sort,
    filters,
    queryStringState,
    setSort,
    setFilters,
    setPagination,
    setSearch,
    setFilterItems,
    setFilterItem,
    getFilterItem,
  }
}

const SuiteDataGridContext = createContext<SuiteDataGridState & SuiteDataGridStateActions>({
  ...initialState,
  setSort: (_sort: GridSortModel) => {},
  setFilters: (_filters: GridFilterModel) => {},
  setPagination: (_pagination: GridPaginationModel) => {},
  setSearch: (_search: string) => {},
  setFilterItems: (_items: GridFilterItem[]) => {},
  setFilterItem: (_key: string, _operator: GridLogicOperator, _value: string | string[]) => {},
  getFilterItem: (_key: string) => undefined,
})

export const SuiteDataGridProvider = SuiteDataGridContext.Provider
