import { Dialog, DialogContent, Button, Typography, styled } from '@mui/material'
import { PactumSmallDialogFooter, PactumDialogHeader } from '@components/PactumDialog'
import { ReactNode } from 'react'
import { LoadingButton } from '@mui/lab'

type ConfirmType = 'positive' | 'negative'

export interface ConfirmationModalProps {
  confirmType: ConfirmType
  open: boolean
  onCancel: () => void
  onSubmit: () => void
  title: string
  content: ReactNode
  confirmButtonText: string
  loading?: boolean
}

export const ConfirmationModal = ({
  confirmType,
  open,
  title,
  content,
  confirmButtonText,
  onCancel,
  onSubmit,
  loading,
}: ConfirmationModalProps) => (
  <>
    <Dialog open={open} maxWidth={'xs'} fullWidth>
      <PactumDialogHeader title={title} onClose={onCancel} />
      <DialogContent>
        <Typography>{content}</Typography>
      </DialogContent>
      <PactumSmallDialogFooter>
        <CancelButton onClick={onCancel} sx={{ flexGrow: 1 }} disabled={loading}>
          Cancel
        </CancelButton>
        <ConfirmButton
          onClick={onSubmit}
          confirmType={confirmType}
          sx={{ flexGrow: 1 }}
          loading={loading}
        >
          {confirmButtonText}
        </ConfirmButton>
      </PactumSmallDialogFooter>
    </Dialog>
  </>
)

const CancelButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1),
  fontWeight: 700,
  textTransform: 'none',
  background: theme.palette.common.white,
  border: `1px solid ${theme.palette.grey.A700}`,
  borderRadius: '4px',
  ':hover': {
    background: theme.palette.common.white,
  },
}))

export const ConfirmButton = styled(LoadingButton, {
  shouldForwardProp: (prop) => prop !== 'confirmType',
})<{ confirmType: ConfirmType }>(({ theme, confirmType }) => ({
  padding: theme.spacing(1),
  fontWeight: 700,
  textTransform: 'none',
  borderRadius: '4px',
  ...(confirmType === 'positive' && {
    background: theme.palette.warning.main,
    ':hover': {
      background: theme.palette.warning.main,
    },
  }),
  ...(confirmType === 'negative' && {
    color: theme.palette.common.white,
    background: theme.palette.error.main,
    ':hover': {
      background: theme.palette.error.main,
    },
  }),
}))
