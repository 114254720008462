import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro'
import { ItemInfo, LumpSum } from '@common/types'
import React from 'react'
import { Formatter } from '@utils'
import { useFormatter } from '@shared/hooks'
import { Tooltip, Typography } from '@mui/material'
import { noValueText } from '@constants'
import { ScenarioInfo } from '@common/types/scenario/ScenarioState'

interface Props {
  lumpSums?: LumpSum[]
  itemsInfo: ScenarioInfo<ItemInfo>
  title: string
  currency: string
}

export const LumpSumsTable = ({ lumpSums, itemsInfo, title, currency }: Props) => {
  const formatter = useFormatter()
  if (!lumpSums || !lumpSums.length) {
    return null
  }

  const columns = createColumns(formatter, currency, itemsInfo)

  const lumpSumsWithIds = lumpSums.map((lumpSum, index) => ({
    ...lumpSum,
    id: index,
  }))

  return (
    <>
      <Typography variant='h5'>{title}</Typography>
      <DataGridPro
        columns={columns}
        rows={lumpSumsWithIds}
        columnHeaderHeight={56}
        sx={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
        autoHeight
        hideFooter
        disableColumnReorder
        disableColumnResize
      />
    </>
  )
}

const createColumns = (
  formatter: Formatter,
  currency: string,
  itemsInfo: Props['itemsInfo'],
): GridColDef<LumpSum & { id: number }>[] => [
  { field: 'name', headerName: 'Name', flex: 2, disableColumnMenu: true },
  { field: 'description', headerName: 'Description', flex: 2.75, disableColumnMenu: true },
  { field: 'type', headerName: 'Type', flex: 1, disableColumnMenu: true },
  {
    field: 'minimum_amount',
    headerName: 'Minimum amount',
    flex: 1.25,
    disableColumnMenu: true,
    valueFormatter: ({ value }) => formatter.currency(value, { currency }),
  },
  {
    field: 'target_amount',
    headerName: 'Target amount',
    flex: 1.25,
    disableColumnMenu: true,
    valueFormatter: ({ value }) => (value ? formatter.currency(value, { currency }) : noValueText),
  },
  {
    field: 'commercial_ids',
    headerName: 'Items',
    flex: 2,
    disableColumnMenu: true,
    renderCell: (cell) => (
      <Tooltip title={getItemNames(itemsInfo, cell.value)}>
        <Typography variant='body2' noWrap>
          {getItemNames(itemsInfo, cell.value)}
        </Typography>
      </Tooltip>
    ),
  },
]

const getItemNames = (itemsInfo: Props['itemsInfo'], commercialIds: LumpSum['commercial_ids']) =>
  commercialIds.map((id) => itemsInfo[id].name).join(', ')
