import { ItemData, UserInput } from '../../types'
import { ascendingOrderBy, last } from '../array'
import { ScenarioDetails } from '../../dto'

export const isLocalMarketStageInput = (input: UserInput) => !!input.market_id

export const isLocalMarketStage = (
  scenario: ScenarioDetails,
): scenario is ScenarioDetails & { userInputs: Required<ScenarioDetails['userInputs']> } =>
  isLocalMarketStageInput(scenario.userInputs)

export const getMinMaxVolumes = (item: ItemData): { min: number; max: number } | undefined => {
  if (!item.price_volume_points || item.price_volume_points.length < 1) {
    return undefined
  }
  const sortedPointsByVolumeAsc = item.price_volume_points.slice().sort(ascendingOrderBy('volume'))
  const minVolume = sortedPointsByVolumeAsc[0].volume
  const maxVolume = last(sortedPointsByVolumeAsc).volume
  return {
    min: minVolume,
    max: maxVolume,
  }
}
