import { NegotiationListEntity } from '@common/dto/pact/negotiation-list-entity'
import { ACTIONS_BUTTON_COLUMN_WIDTH, ACTIONS_BUTTON_WIDTH } from '@components/ActionsButton'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Link } from '@mui/material'
import { GRID_CHECKBOX_SELECTION_COL_DEF, GridColDef } from '@mui/x-data-grid-pro'
import { ListItemStatus, mapStatusToLabelAndColor } from '@pages/NegotiationsPage/ListItemStatus'
import { Formatter } from '@utils'
import { isToday, isYesterday } from 'date-fns'
import { ListItemActions } from './ListItemActions'

export const commonColumnParameters = {
  disableColumnMenu: true,
  filterable: true,
  resizable: true,
}

export const createColumns = (formatter: Formatter): GridColDef<NegotiationListEntity>[] => [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    cellClassName: 'select-cell',
    headerClassName: 'select-header',
    align: 'left',
    field: '__check__',
    hideable: false,
  },
  {
    ...commonColumnParameters,
    field: 'createdAt',
    headerName: 'Created',
    type: 'dateTime',
    flex: 3,
    valueGetter: ({ row }) => new Date(row.createdAt),
    renderCell: ({ row }) => {
      const date = new Date(row.createdAt)
      if (isToday(date)) {
        return `Today, ${formatter.date(date, 'p')}`
      }
      if (isYesterday(date)) {
        return `Yesterday, ${formatter.date(date, 'p')}`
      }
      return formatter.date(date, 'P')
    },
  },
  {
    ...commonColumnParameters,
    flex: 5,
    field: 'supplierName',
    headerName: 'Supplier',
  },
  {
    ...commonColumnParameters,
    field: 'chatLink',
    headerName: 'Link',
    flex: 1,
    renderCell: ({ value }) => (
      <Link href={value as string} target='_blank' underline='hover' sx={{ cursor: 'auto' }}>
        <OpenInNewIcon
          sx={{ mr: 0.5, color: 'inherit', fontSize: 'inherit', verticalAlign: 'middle' }}
        />
      </Link>
    ),
  },
  {
    ...commonColumnParameters,
    field: 'spend',
    headerName: 'Spend',
    align: 'right',
    headerAlign: 'right',
    flex: 3,
    renderCell: ({ value, row }) =>
      formatter.currency(value, { currency: row.currency.replaceAll('*', '') }),
  },
  {
    ...commonColumnParameters,
    field: 'savings',
    headerName: 'Savings',
    align: 'right',
    headerAlign: 'right',
    flex: 3,
    renderCell: ({ value, row }) =>
      formatter.currency(value, { currency: row.currency.replaceAll('*', '') }),
  },
  {
    ...commonColumnParameters,
    field: 'id',
    headerName: 'Savings rate',
    align: 'right',
    headerAlign: 'right',
    flex: 3,
    renderCell: ({ row }) => {
      if (row.savings === 0 || row.spend === 0) {
        return formatter.percent0To1(0)
      }
      return formatter.percent0To1(row.savings / row.spend)
    },
  },
  {
    ...commonColumnParameters,
    field: 'status',
    headerName: 'Status',
    flex: 3,
    valueGetter: ({ row }) =>
      mapStatusToLabelAndColor({
        status: row.status,
        isConcluded: row.isConcluded,
        isSuccessful: row.isSuccessful,
      })?.label,
    renderCell: ({ row }) => (
      <ListItemStatus
        status={row.status}
        isConcluded={row.isConcluded}
        isSuccessful={row.isSuccessful}
      />
    ),
  },
  {
    ...commonColumnParameters,
    field: 'itemCount',
    headerName: 'Items',
  },
  {
    ...commonColumnParameters,
    field: '__actions__',
    headerName: 'Actions',
    minWidth: ACTIONS_BUTTON_COLUMN_WIDTH,
    cellClassName: 'actions-cell',
    renderCell: (cell) => (
      <ListItemActions negotiation={cell.row} actionsMenuMinWidth={ACTIONS_BUTTON_WIDTH} />
    ),
  },
]
