import { useSuiteDataGrid } from '@components/SuiteDataGrid/SuiteDataGridProvider'
import { DataGrid, dataGridProps } from '@components/table'
import { ROWS_PER_PAGE_OPTIONS } from '@components/table/pagination'
import { NoUnits } from '@logistics/pages/common/NoUnits/NoUnits'
import { SpotLoadUnit } from '@logistics/pages/spotLoad/types'
import { UnitPageContext } from '@logistics/pages/spotLoad/UnitPage/context'
import { useGetSpotLoadUnitListQuery } from '@logistics/store/unitApi'
import { styled } from '@mui/material'
import { DataGridPro, GridRowParams } from '@mui/x-data-grid-pro'
import { useFormatter } from '@shared/hooks'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useContext, useMemo } from 'react'
import { createOuterUnitColumns } from './outerUnitColumns'
import { UnitDetailsPanel } from './UnitDetailsPanel'

const DEFAULT_PAGE_SIZE = 10

export const UnitList = () => {
  const { activeProjectTag } = useActiveProject()
  const gridState = useSuiteDataGrid({ defaultPageSize: DEFAULT_PAGE_SIZE })

  const paginationModel = gridState.pagination
  const setPaginationModel = gridState.setPagination

  const { setIsCreateNegotiationModalOpen } = useContext(UnitPageContext)

  const { data: unitData, isLoading } = useGetSpotLoadUnitListQuery({
    projectTag: activeProjectTag,
    skip: paginationModel.page * paginationModel.pageSize,
    limit: paginationModel.pageSize,
  })

  const formatter = useFormatter()

  const columns = useMemo(() => createOuterUnitColumns(formatter), [formatter])

  if (!isLoading && !unitData?.data?.length) {
    return <NoUnits onNewNegotiation={() => setIsCreateNegotiationModalOpen(true)} />
  }

  return (
    <StyledDataGrid
      {...dataGridProps}
      pagination
      hideFooter={false}
      pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      paginationMode='server'
      columns={columns}
      loading={isLoading}
      rowCount={unitData?.totalItems ?? 0}
      rows={unitData?.data ?? []}
      className='dg-expandable'
      getRowId={(row: SpotLoadUnit) => row.unit_id}
      getDetailPanelHeight={() => 'auto'}
      getDetailPanelContent={(params: GridRowParams<SpotLoadUnit>) => (
        <UnitDetailsPanel unit={params.row} />
      )}
    />
  )
}

const StyledDataGrid = styled(DataGrid)(() => ({
  '& .MuiDataGrid-row:hover': {
    backgroundColor: 'unset',
    cursor: 'unset',
  },
})) as typeof DataGridPro
