import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { debounce } from '@mui/material/utils'
import { SyntheticEvent, useEffect, useMemo, useState } from 'react'
import * as React from 'react'
import { TextFieldProps } from '@mui/material'
import { Controller } from 'react-hook-form'
import { errorProps, styleProps } from '@components/Form/sharedProps'
import { PlaceDetails, PlacePrediction, useGooglePlaces } from '@logistics/hooks/useGooglePlaces'

type AddressAutocompleteCtrlProps = TextFieldProps & {
  name: string
  disabled?: boolean
  onPlaceDetails?: (result: PlaceDetails | null) => void
}

export const AddressAutocompleteCtrl = (props: AddressAutocompleteCtrlProps) => {
  const [value, setValue] = useState<PlacePrediction | null>(null)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<PlacePrediction[]>([])

  const { getPlacePredictions, getPlaceDetails } = useGooglePlaces()

  const fetch = useMemo(
    () =>
      debounce((input: string, callback: (results: PlacePrediction[]) => void) => {
        getPlacePredictions(input).then(callback)
      }, 400),
    [getPlacePredictions],
  )

  useEffect(() => {
    let active = true

    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }

    fetch(inputValue, (results) => {
      if (active) {
        let newOptions: PlacePrediction[] = []

        if (value) {
          newOptions = [value]
        }

        if (results) {
          newOptions = [...newOptions, ...results]
        }

        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetch])

  return (
    <Controller
      name={props.name}
      rules={{ required: props.required }}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          sx={{ width: 300 }}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          filterSelectedOptions
          inputValue={!inputValue ? ((field.value as string) ?? '') : inputValue}
          value={value}
          noOptionsText='No locations'
          onChange={(event: SyntheticEvent, newValue: PlacePrediction | null) => {
            setOptions(newValue ? [newValue, ...options] : options)
            setValue(newValue)
            field.onChange(newValue?.description ?? null)
            if (props.onPlaceDetails !== undefined) {
              if (newValue) {
                getPlaceDetails(newValue.placeId, newValue.description).then((place) => {
                  props.onPlaceDetails && props.onPlaceDetails(place)
                })
              } else {
                props.onPlaceDetails(null)
              }
            }
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue)
          }}
          renderInput={(params) => (
            <TextField
              {...styleProps}
              {...errorProps(error)}
              required={props.required}
              {...params}
              inputRef={field.ref}
              onBlur={field.onBlur}
              label={props.label}
              fullWidth={props.fullWidth}
            />
          )}
          renderOption={(props, option) => {
            const parts = option.highlightedDescription

            return (
              <li {...props}>
                <Grid container alignItems='center'>
                  <Grid item sx={{ display: 'flex', width: 44 }}>
                    <LocationOnIcon sx={{ color: 'text.secondary' }} />
                  </Grid>
                  <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                    {parts.map((part, index) => (
                      <Box
                        key={index}
                        component='span'
                        sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                      >
                        {part.text}
                      </Box>
                    ))}
                    <Typography variant='body2' color='text.secondary'>
                      {option.secondaryText}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            )
          }}
        />
      )}
    />
  )
}
