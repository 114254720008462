import posthog from 'posthog-js'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'

import { SuitePermission } from '@common/types'
import { CookiesConsent } from '@components/CookiesConsent'
import { Layout } from '@components/Layout'
import { ProtectedRoute } from '@components/ProtectedRoute'
import { AppRoutes } from '@constants'
import { DashboardPage } from '@pages/DashboardPage/DashboardPage'
import { OrgHomePage } from '@pages/OrgHomePage/OrgHomePage'
import { RedirectToHelpPortal } from '@pages/RedirectToHelpPortal'
import { RedirectToWorkspace } from '@pages/RedirectToWorkspace'
import { SettingsPage } from '@pages/SettingsPage/SettingsPage'
import { WelcomePage } from '@pages/WelcomePage'
import { WorkspaceUnavailablePage } from '@pages/WorkspaceUnavailablePage'
import { useFreshchat } from '@shared/hooks/useFreshchat'
import { clearError, useSuiteSelector } from '@store/store'
import { useEffect } from 'react'
import { useDetectOffline } from 'src/main/hooks'
import { NoRoutePage, RedirectToOrg } from 'src/main/pages/index'
import { ChangelogPage } from 'src/platform/changelog/ChangelogPage'
import { environment } from '../environments/environment'
import { TermsAndConditionsWrapper } from '../platform/terms-and-conditions/components/TermsAndConditions'
import { useMerchandisingRedirects } from './hooks/useMerchandisingRedirects'
import { SUITE_MODULES } from './suite-modules'

export const App = () => {
  const location = useLocation()
  const nav = useNavigate()
  const merchandisingRedirects = useMerchandisingRedirects()
  const state = useSuiteSelector()

  useDetectOffline()

  useFreshchat()

  useEffect(() => {
    posthog.capture('$pageview', {
      environment: environment.REACT_APP_ENV,
    })
  }, [location])

  useEffect(() => {
    if (state.globalErrors.type === 'workspaceUnavailable') {
      nav(AppRoutes.WORKSPACE_UNAVAILABLE)
      clearError()
    }
  }, [nav, state.globalErrors.type])

  return (
    <>
      <Routes>
        {merchandisingRedirects}

        <Route path={AppRoutes.ORG_TAG}>
          {SUITE_MODULES.flatMap((module) => (
            <Route path={module.basePath} key={module.name}>
              {module.entrypoint({})}
            </Route>
          ))}
          <Route path='' element={<Layout />}>
            <Route path='' element={<TermsAndConditionsWrapper />}>
              <Route index element={<OrgHomePage />} />
              <Route
                path={AppRoutes.WELCOME}
                element={
                  <ProtectedRoute requiredPerm={SuitePermission.READ}>
                    <WelcomePage />
                  </ProtectedRoute>
                }
              />
              <Route
                path={AppRoutes.DASHBOARD}
                element={
                  <ProtectedRoute key='DashboardPage' requiredPerm={SuitePermission.READ}>
                    <DashboardPage key='DashboardPage' />
                  </ProtectedRoute>
                }
              />
              <Route
                path={AppRoutes.CHANGELOG}
                element={
                  <ProtectedRoute key='Changelog' requiredPerm={SuitePermission.READ}>
                    <ChangelogPage />
                  </ProtectedRoute>
                }
              />

              <Route path={AppRoutes.SETTINGS} element={<SettingsPage />} />
            </Route>
          </Route>
        </Route>

        <Route path={AppRoutes.HOME} element={<Layout />}>
          <Route index element={<RedirectToOrg />} />
        </Route>

        <Route path={AppRoutes.NOT_FOUND} element={<NoRoutePage />} />
        <Route path={AppRoutes.WORKSPACE_UNAVAILABLE} element={<WorkspaceUnavailablePage />} />
        <Route path={AppRoutes.REDIRECT_TO_WORKSPACE} element={<RedirectToWorkspace />} />
        <Route path={AppRoutes.REDIRECT_TO_HELP_PORTAL} element={<RedirectToHelpPortal />} />
        <Route path='*' element={<NoRoutePage />} />
      </Routes>
      <CookiesConsent />
    </>
  )
}
