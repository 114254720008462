import { useLayoutContext } from '@components/Layout'
import { InnerContainer, OuterContainer } from '@components/Layout/Containers'
import { useUserPermissions } from '@hooks'
import { Button, ButtonProps, Stack, Typography, alpha, lighten, styled } from '@mui/material'
import { SuitePermission } from 'common/types'
import { useLocation } from 'react-router-dom'

const PrimaryStyleButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  flexShrink: 0,
  '&:hover': {
    backgroundColor: lighten(theme.palette.primary.dark, 0.2),
  },
  '&:disabled': {
    backgroundColor: lighten(theme.palette.primary.dark, 0.2),
  },
}))

const OutlinedStyleButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  flexShrink: 0,
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.2),
  },
  '&:disabled': {
    backgroundColor: alpha(theme.palette.common.white, 0.2),
    cursor: 'not-allowed',
  },
}))

const PrimaryButton = (props: ButtonProps) => (
  <PrimaryStyleButton size='large' variant='contained' {...props} />
)

export const OutlinedButton = (props: ButtonProps) => (
  <OutlinedStyleButton size='large' variant='outlined' {...props} />
)

const NewBrandingActions = () => {
  const { settings } = useLayoutContext()

  return (
    <Stack ml='auto' direction='row' alignSelf='start' spacing={1.5}>
      <OutlinedButton type='button' onClick={settings?.discardChanges}>
        Discard
      </OutlinedButton>

      <PrimaryButton
        type='button'
        disabled={
          settings?.createBrandingDisabled !== undefined && settings?.createBrandingDisabled
        }
        onClick={settings?.createBranding}
      >
        Create
      </PrimaryButton>
    </Stack>
  )
}

const ExistingBrandingActions = () => {
  const { settings } = useLayoutContext()

  return (
    <Stack ml='auto' direction='row' alignSelf='start' spacing={1.5}>
      <PrimaryButton
        type='button'
        disabled={settings?.saveBrandingDisabled !== undefined && settings?.saveBrandingDisabled}
        onClick={settings?.saveBranding}
      >
        Save edits
      </PrimaryButton>
    </Stack>
  )
}

export const SettingsContextHeader = () => {
  const { userPermissionsInActiveOrg } = useUserPermissions()
  const canManageUsers = userPermissionsInActiveOrg?.has(SuitePermission.SUITE_USERS_MANAGE)

  const location = useLocation()

  const showActions = canManageUsers && location.pathname.includes('/branding')
  const showNewBrandingActions = location.pathname.includes('/branding/new')

  return (
    <OuterContainer alignContent='baseline' justifyContent='end'>
      <InnerContainer>
        <Stack direction='row' mb={5.25}>
          <Typography
            variant='h4'
            fontWeight={600}
            letterSpacing='-0.02em'
            sx={{ color: 'common.white' }}
          >
            Settings
          </Typography>

          {showActions && !showNewBrandingActions && <ExistingBrandingActions />}

          {showNewBrandingActions && <NewBrandingActions />}
        </Stack>
      </InnerContainer>
    </OuterContainer>
  )
}
