import { Chip, Stack, Typography } from '@mui/material'
import { InnerContainer, OuterContainer } from '@components/Layout/Containers'
import { Supplier } from '@logistics/types/Supplier'
import { SUPPLIER_IS_ENABLED_CONFIG } from '@logistics/types'

export type SupplierDetailsHeaderProps = {
  supplier: Supplier
}

export const SupplierDetailsHeader = ({ supplier }: SupplierDetailsHeaderProps): JSX.Element => {
  return (
    <OuterContainer alignContent='baseline' justifyContent='end'>
      <InnerContainer>
        <Stack direction='row' spacing={2} alignItems='center' mb={5.25}>
          <Typography variant='h4' fontWeight={600} sx={{ color: 'common.white' }}>
            {supplier.name}
          </Typography>
          <Chip
            size='medium'
            {...SUPPLIER_IS_ENABLED_CONFIG[supplier.isEnabled ? 'ENABLED' : 'DISABLED']}
          />
        </Stack>
      </InnerContainer>
    </OuterContainer>
  )
}
