import {
  Format,
  TimeSeriesSeriesOptions,
  TimeSeriesWidget as TimeSeriesWidgetType,
} from '@common/dto/dashboard/dashboard'
import { Box, Stack, useTheme } from '@mui/material'
import { CurrencyCode } from '@pactum/common'
import { useDashboardPeriod } from '@pages/DashboardPage/useDashboardPeriod'
import { FiltersList } from '@shared/components/FiltersList'
import { Chart as ChartJS, ScriptableContext } from 'chart.js'
import datalabelsPlugin from 'chartjs-plugin-datalabels'
import { useState } from 'react'
import { Chart } from 'react-chartjs-2'
import { useFilters } from '../../useDashboardQueryParams'
import { WidgetSection } from '../WidgetSection'
import { inspectorLinePlugin } from './InspectorLinePlugin'
import { getAggregatedData, getDaysGroupedByPeriod } from './timeSeriesUtils'

ChartJS.register(datalabelsPlugin)
ChartJS.register(inspectorLinePlugin)

export type NumberDataByPeriod = Record<string, number>
export type Dataset = {
  label: string
  data: NumberDataByPeriod
  format: Format
  options?: TimeSeriesSeriesOptions
}

interface Props {
  widgetConfig: TimeSeriesWidgetType
}

export const TimeSeriesWidget = ({ widgetConfig }: Props) => {
  const { style: chartType, title, description, series, days } = widgetConfig
  const chartStyle = useChartStyling(chartType)
  const selectedTimeFilter = useDashboardPeriod()
  const [selectedSeriesFilter, setSelectedSeriesFilter] = useState(series[0].label)

  const { currency } = useFilters()

  const getDatasets = (): Dataset[] => {
    return series.map(({ label, func, format, arguments: fields, options }) => {
      const dataPointsByPeriod = getDaysGroupedByPeriod(days, selectedTimeFilter)

      return {
        label,
        data: getAggregatedData(dataPointsByPeriod, func, fields),
        format,
        options,
      }
    })
  }

  const datasets = getDatasets()
  const selectedDataset = datasets.find((d) => d.label === selectedSeriesFilter)!
  const labels = Object.keys(selectedDataset.data)
  const optionalScaleStyle = useOptionalScaleStyling(selectedDataset.options)

  return (
    <WidgetSection
      title={title}
      description={description}
      filters={
        <Stack direction='row' justifyContent='end' gap={4}>
          <FiltersList
            options={series.map((s) => s.label)}
            selectedOption={selectedSeriesFilter}
            onClick={(option) => setSelectedSeriesFilter(option)}
          />
        </Stack>
      }
    >
      <Box sx={{ minHeight: '200px' }}>
        <Chart
          type={chartType}
          data={{
            labels: labels,
            datasets: [
              {
                data: Object.values(selectedDataset.data),
                ...chartStyle,
              },
            ],
          }}
          options={{
            scales: {
              y: {
                position: 'right',
                beginAtZero: true,
                ticks: {
                  format: getTicksFormatting(selectedDataset.format, currency),
                },
                ...optionalScaleStyle,
              },
              x: {
                grid: {
                  display: false,
                },
              },
            },
            maintainAspectRatio: false,
            interaction: {
              intersect: false,
              mode: 'x',
            },
            plugins: {
              legend: {
                display: false,
              },
              inspector: {
                display: selectedDataset?.options?.displayInspectorLine ?? false,
              },
              datalabels: {
                align: 'end',
                anchor: 'end',
                offset: -3,
                display: selectedDataset?.options?.displayLabels ?? false,
                formatter: (value) => formatLabel(value, selectedDataset, currency),
              },
            },
          }}
        />
      </Box>
    </WidgetSection>
  )
}

export const formatLabel = (
  value: number,
  selectedDataset: Dataset,
  currency: CurrencyCode,
): string => {
  if (value <= 1e-6) return ''
  const numberOfBars = Object.keys(selectedDataset.data).length
  if (numberOfBars > 12) return '' // don't show labels for more than 12 bars as they overlap
  return Intl.NumberFormat('en-US', getTicksFormatting(selectedDataset.format, currency)).format(
    value,
  )
}

export const getTicksFormatting = (
  format: Format,
  currency: CurrencyCode,
): Intl.NumberFormatOptions => {
  if (format === 'percent') {
    return {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
  }

  if (format === 'currency') {
    return {
      style: 'currency',
      currency,
      currencyDisplay: 'narrowSymbol',
      notation: 'compact',
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }
  }

  return {}
}

export const useChartStyling = (chartType: 'bar' | 'line') => {
  const theme = useTheme()

  if (chartType === 'bar') {
    return {
      borderRadius: 4,
      backgroundColor: (context: ScriptableContext<'bar'>) => {
        const ctx = context.chart.ctx
        const gradient = ctx.createLinearGradient(0, 0, 0, 200)
        gradient.addColorStop(0, theme.palette.common.charts.fourth)
        gradient.addColorStop(1, 'rgba(208, 144, 71, 0.54)')
        return gradient
      },
    }
  }

  return {
    borderColor: theme.palette.common.charts.third,
    pointBackgroundColor: theme.palette.common.charts.third,
    fill: true,
    backgroundColor: (context: ScriptableContext<'line'>) => {
      const ctx = context.chart.ctx
      const gradient = ctx.createLinearGradient(0, 50, 0, 150)
      gradient.addColorStop(0, 'rgba(128, 152, 165, 0.40)')
      gradient.addColorStop(1, 'rgba(128, 158, 165, 0)')
      return gradient
    },
  }
}

export const useOptionalScaleStyling = (options: TimeSeriesSeriesOptions | undefined) => {
  if (!options) return {}
  return {
    grace: options.displayLabels ? '10%' : '0%',
    max: options.maxYAxisValue,
  }
}
