import React from 'react'
import { LoadingPage } from '@pages/LoadingPage'
import { ErrorPage } from '@pages/ErrorPage'
import { useRedirectGitbook } from '../../hooks/useRedirectGitbook'

export const RedirectToHelpPortal = () => {
  const { error } = useRedirectGitbook()

  if (error) {
    const errorMessage =
      'status' in error
        ? `Request failed with status ${error.status}.`
        : (error.message ?? 'An unknown error occurred.')

    return (
      <ErrorPage title='Redirection Failed'>
        <p>
          We encountered an error while redirecting you to the help portal. Please try again later.
        </p>
        <pre>{errorMessage}</pre>
      </ErrorPage>
    )
  }

  return <LoadingPage />
}
