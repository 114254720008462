import styled from '@emotion/styled'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import { ChangeEvent, useRef, useState } from 'react'

type RangeBarVariant = 'default' | 'positive'

const Thumb = styled.div`
  width: 32px;
  height: 32px;
  background: #fff;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  margin-left: -16px;
  position: absolute;
  top: 16px;
  z-index: 91;
  border-radius: 100%;
  display: flex;

  svg {
    display: block;
    margin: auto;
    rotate: 90deg;
  }
`

const BarTextContainer = styled.div`
  margin: auto 0 auto 16px;

  h3,
  p {
    color: #fff;
    padding: 0;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  p {
    font-size: 12px;
  }
`

const ExternalBarTextContainer = styled.div`
  margin: auto 0 auto 16px;
  padding: 8px 0 0 0;
  position: absolute;
  top: 0;

  h3,
  p {
    color: #000;
    padding: 0;
    margin: 0;
    text-overflow: ellipsis;
    min-width: 200px;
  }

  p {
    font-size: 12px;
  }
`

const PrimaryRangeBackground = styled.div`
  background: rgb(78, 90, 93);
  background: linear-gradient(180deg, rgba(73, 88, 91, 1) 0%, rgba(73, 88, 91, 0.67) 100%);
  top: 0;
  height: 66px;
  position: absolute;
  z-index: 10;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`

const PositiveRangeBackground = styled.div`
  background: rgb(78, 90, 93);
  background: linear-gradient(180deg, rgba(57, 181, 54, 1) 0%, rgba(57, 181, 54, 0.67) 100%);
  top: 0;
  height: 66px;
  position: absolute;
  z-index: 10;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`

const RangeBarContainer = styled.div`
  position: relative;
  overflow: hidden;
`

const RangeBarInput = styled.input`
  position: relative;
  z-index: 99;
  overflow: hidden;
  width: 100%;
  -webkit-appearance: none;
  background-color: transparent;

  &::-webkit-slider-runnable-track {
    height: 66px;
    -webkit-appearance: none;
    color: transparent;
  }

  &::-webkit-slider-thumb {
    width: 32px;
    -webkit-appearance: none;
    height: 66px;
    cursor: ew-resize;
    z-index: 99;
  }

  &::-moz-range-progress {
    background-color: #43e5f7;
  }

  &::-moz-range-track {
    background-color: #9a905d;
  }
`

interface RangeBarProps {
  defaultValue?: number
  description?: string
  value?: number
  displayValue?: string | number
  variant?: RangeBarVariant
  onChange?: (value: number) => void
  calculateValue?: (value: number) => number | string
}

export const RangeBar = ({
  defaultValue = 0,
  description,
  value,
  variant = 'default',
  calculateValue,
  displayValue,
  onChange = (_) => {},
}: RangeBarProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const textRef = useRef<HTMLDivElement>(null)
  const [percent, setPercent] = useState<number>(defaultValue)

  const v = value ?? percent

  const update = (event: ChangeEvent<HTMLInputElement>) => {
    setPercent(event.target.valueAsNumber)
    onChange(event.target.valueAsNumber)
  }

  const Background = variant === 'default' ? PrimaryRangeBackground : PositiveRangeBackground

  return (
    <RangeBarContainer>
      <RangeBarInput
        disabled={value !== undefined}
        type='range'
        value={percent}
        min={0}
        max={100}
        onChange={update}
      />

      <Background
        ref={ref}
        style={{
          width: `${v}%`,
        }}
      >
        <BarTextContainer
          ref={textRef}
          style={{
            visibility:
              (textRef?.current?.scrollWidth ?? 0) > (textRef?.current?.clientWidth ?? 0)
                ? 'hidden'
                : 'visible',
          }}
        >
          <h3 className='range-background-text'>
            {calculateValue ? calculateValue(v) : displayValue}
          </h3>
          <p className='range-background-description'>{description}</p>
        </BarTextContainer>
      </Background>

      <ExternalBarTextContainer
        style={{
          left: v + '%',
          visibility:
            (textRef?.current?.scrollWidth ?? 0) > (textRef?.current?.clientWidth ?? 0)
              ? 'visible'
              : 'hidden',
        }}
      >
        <h3 className='range-background-text'>
          {calculateValue ? calculateValue(v) : displayValue}
        </h3>
        <p className='range-background-description'>{description}</p>
      </ExternalBarTextContainer>
      {value === undefined && (
        <Thumb
          style={{
            left: `${v}%`,
          }}
        >
          <UnfoldMoreIcon fontSize='small' />
        </Thumb>
      )}
    </RangeBarContainer>
  )
}
