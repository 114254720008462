import { TableWidget as TableWidgetConfig } from '@common/dto/dashboard/dashboard'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { locales } from 'common/constants'
import { useCallback, useMemo } from 'react'
// TODO: Remove dependency on product module
import { CurrencyCode } from '@pactum/common'
// eslint-disable-next-line import/no-restricted-paths
import { HeaderTableCell } from 'src/merchandising/pages/CostIncrease/CostIncreaseFormConfiguration/CostModelInsights/SupplierCommoditiesReflections'
import { Formatter } from 'src/main/utils'
import { useFilters } from '../useDashboardQueryParams'
import { WidgetSection } from './WidgetSection'

interface Props {
  widgetConfig: TableWidgetConfig
}

const formatMap: Record<
  string,
  (formatter: Formatter, v: unknown, currency?: CurrencyCode) => string
> = {
  spend: (formatter: Formatter, val: unknown, currency?: CurrencyCode) =>
    formatter.currency(Number(val), {
      currency: currency ?? CurrencyCode.USD,
      fractionDigits: 0,
    }),
  totalSavings: (formatter: Formatter, val: unknown, currency?: CurrencyCode) =>
    formatter.currency(Number(val), {
      currency: currency ?? CurrencyCode.USD,
      fractionDigits: 0,
    }),
  savingsPercentage: (_formatter: Formatter, val: unknown) => `${val}%`,
}

const columnsMap: Record<string, string> = {
  fiscalYear: 'Fiscal Year',
  count: 'Successful Negotiations',
  spend: 'Total Successful Spend',
  totalSavings: 'Savings',
  savingsPercentage: 'Savings %',
}

export const TableWidget = ({ widgetConfig }: Props) => {
  const { currency } = useFilters()

  const { title, description, subtitle, columns, values } = widgetConfig

  const formatter = useCallback(() => new Formatter(locales.us), [])

  const formatColumn = useCallback(
    (key: string, val: unknown): string =>
      formatMap[key] ? formatMap[key](formatter(), val, currency) : String(val),
    [formatter, currency],
  )

  const formatted = useMemo(
    () =>
      values.map((row) => {
        const obj: Record<string, string> = {}

        Object.keys(row).forEach((key) => (obj[key] = formatColumn(key, row[key])))

        return obj
      }),
    [values, formatColumn],
  )

  return (
    <WidgetSection title={title} subtitle={subtitle} description={description}>
      <Box sx={{ minHeight: '200px', ml: -0.75 }}>
        <TableContainer sx={{ mt: 2 }}>
          <Table sx={{ minWidth: 300 }}>
            <TableHead>
              <TableRow>
                {columns.map((col) => (
                  <HeaderTableCell key={col}>{columnsMap[col]}</HeaderTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {formatted.map((row, i) => (
                <TableRow key={`table-${i}`}>
                  {columns.map((col) => (
                    <TableCell key={col}>{row[col]}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </WidgetSection>
  )
}
