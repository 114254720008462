import { logisticsBaseApi } from './baseApi'
import { SpotLanePageResponseDto } from '@logistics/pages/spotLane/types'

export interface GetActiveSpotLanesParams {
  projectTag: string
  isExpired: boolean
  isSuccessful: boolean
  skip: number
  limit: number
}

export const spotLanesApi = logisticsBaseApi.injectEndpoints({
  endpoints: (build) => ({
    createNewSpotLanesNegotiations: build.mutation<void, { projectTag: string }>({
      query: ({ projectTag }) => ({
        url: `/suite/${projectTag}/spot-lanes/new-negotiations`,
        method: 'POST',
      }),
    }),
    getSpotLanesWithNegotiations: build.query<SpotLanePageResponseDto, GetActiveSpotLanesParams>({
      query: ({ projectTag, isExpired, isSuccessful, skip, limit }) => ({
        url: `/suite/${projectTag}/spot-lanes/latest-units-with-negotiations`,
        params: {
          isExpired,
          isSuccessful,
          skip,
          limit,
        },
      }),
    }),
  }),
})

export const { useCreateNewSpotLanesNegotiationsMutation, useGetSpotLanesWithNegotiationsQuery } =
  spotLanesApi
