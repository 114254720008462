import { io, Socket } from 'socket.io-client'
import { environment } from '../../environments/environment'
import { WebsocketServerEvent, WebsocketClientEvent } from '@logistics/types/socket'
import { getAccessToken } from '../../main/hooks/auth/useAuthInternals'
import { ProjectTag } from '@common/types'

export type Namespace = 'suite'

let socket: Socket | null = null

export async function getSocket(
  projectTag: ProjectTag,
  namespace: Namespace = 'suite',
): Promise<Socket> {
  if (socket) {
    return socket
  }

  const urlPathSuffix = '/ws'
  const urlPathPrefix =
    environment.REACT_APP_ENV === 'development'
      ? environment.REACT_APP_LOGISTICS_BACKEND_URL?.substring(
          0,
          environment.REACT_APP_LOGISTICS_BACKEND_URL?.indexOf('/api'),
        )
      : window.location.origin

  const baseUrl = `${urlPathPrefix}/${namespace}`
  const urlPath = `${environment.REACT_APP_LOGISTICS_BACKEND_RELATIVE_WEBSOCKET_URL_PATH}${urlPathSuffix}`

  socket = io(baseUrl, {
    auth: {
      token: await getAccessToken(),
    },
    path: urlPath,
    secure: true,
    rejectUnauthorized: true,
    transports: ['websocket'],
    query: {
      projectTag,
    },
  })

  socket.on('connect', () => socket?.emit(WebsocketClientEvent.SUBSCRIBE, { projectTag }))
  socket.on(WebsocketServerEvent.UNAUTHORIZED, closeSocket) // Will be reopened on next http request/refresh

  return socket
}

export function closeSocket() {
  socket?.disconnect()
  socket = null
}
