import { TargetAndLacValues } from './TargetAndLacValues'
import { ItemThresholds } from './ItemThresholds'
import { AnchorOfferStrategy } from './UserInput'
import { ContextValues } from './ContextValues'

type MarketId = string
type CommercialId = string
type Index = number
type WeeklyOrSingularMetric = WeeklyTransactionMetric | TransactionMetric

interface ScenarioState {
  item_info: ScenarioInfo<ItemInfo>
  market_info: ScenarioInfo<MarketInfo>
  historical?: {
    metrics: {
      markets: { [id: MarketId]: MarketMetrics }
      index_to_labels: IndexToLabelMapping
    }
  }
  current: {
    metrics: {
      items: { [id: CommercialId]: ItemMetrics<TransactionMetric> }
      index_to_labels: IndexToLabelMapping
    }
    items: ItemData[]
  }
  version: number
}

interface ScenarioInfo<T extends ItemInfo | MarketInfo> {
  [id: string]: T
}

interface ItemInfo {
  name: string
  has_historical_data: boolean
  in_negotiation_scope: boolean
}

type MarketInfo = ItemInfo

interface MarketMetrics {
  item_metrics: { [id: CommercialId]: ItemMetrics<WeeklyTransactionMetric> }
}

interface ItemMetrics<T extends WeeklyOrSingularMetric> {
  price: ItemPriceMetrics<T>
  volume: TransactionMetric
  spend: ItemPriceMetrics<T>
  spend_benefits: BenefitMetric<T>[]
  bsr: BenefitMetric<T>[]
}

interface ItemPriceMetrics<T extends WeeklyOrSingularMetric> {
  invoice: TransactionMetric
  net: T
}

interface BenefitMetric<T extends WeeklyOrSingularMetric> {
  name: BenefitName
  benefit_metrics: T
}

interface TransactionMetric {
  values: ValueRecord[]
  qoq?: QoQChange
}

type WeeklyTransactionMetric = Record<Week, TransactionMetric>

interface ValueRecord {
  index: Index
  value?: number | null
}

interface QoQChange {
  label: string
  change: number | null
  proportional_change: number | null
}

interface ItemData {
  commercial_id: string
  enabled: boolean
  name: string
  currency?: string
  invoice_prices: TargetAndLacValues & ContextValues
  net_prices: TargetAndLacValues & ContextValues
  volumes: TargetAndLacValues & ContextValues
  benefits: {
    gtd: TargetAndLacValues & ContextValues
    gmf: TargetAndLacValues & ContextValues
    cmf: TargetAndLacValues & ContextValues
    ctd: TargetAndLacValues & ContextValues
  }
  ipr: TargetAndLacValues
  price_volume_points: PriceVolumePoint[]
  thresholds: ItemThresholds
  historical_info: HistoricalItemInfo | null
  anchor_offer_strategy?: AnchorOfferStrategy
  recommended_retail_price: RecommendedRetailPrice | null
  target_lump_sum_per_unit?: number
  previous_invoice_price_details?: PreviousInvoicePriceDetails
}

interface PriceVolumePoint {
  price: number
  volume: number
}

interface HistoricalItemInfo {
  latest_source_fyq: string
  linked_item_id: string | null
}

interface RecommendedRetailPrice {
  price: number
  currency: string
  market_id: MarketId | null
}

interface PreviousInvoicePriceDetails {
  fyq: string
  values: { market_id: MarketId; price: number }[]
}

type Week = 'W1' | 'W2'
type IndexToLabelMapping = { [index: Index]: string }
type BenefitName = 'GTD' | 'GMF' | 'CTD' | 'CMF'
type Outcome = 'Target' | 'LAA'

// helper constants for all possible benefit names and outcomes
export const BENEFIT_NAMES: BenefitName[] = ['GTD', 'GMF', 'CTD', 'CMF']
export const OUTCOMES: Outcome[] = ['Target', 'LAA']

export type {
  ScenarioState,
  ScenarioInfo,
  ItemData,
  ItemInfo,
  MarketMetrics,
  ItemMetrics,
  BenefitMetric,
  ItemPriceMetrics,
  TransactionMetric,
  WeeklyTransactionMetric,
  WeeklyOrSingularMetric,
  MarketInfo,
  CommercialId,
  MarketId,
  Week,
  ValueRecord,
  IndexToLabelMapping,
  PriceVolumePoint,
  RecommendedRetailPrice,
  QoQChange,
  BenefitName,
  Outcome,
}
