import { Article, ArticleInput, ArticleValues } from '../../types'
import { ArticleVolume } from './types'

export const calculateRetailPrices = (
  rpp: Pick<ArticleValues, 'lac' | 'target'>,
  margin: Pick<ArticleValues, 'lac' | 'target'>,
  vat: Article['vat'],
): ArticleInput['retailPrice'] => {
  return {
    lac: calculateRetailPrice(rpp.lac, margin.lac, vat),
    target: calculateRetailPrice(rpp.target, margin.target, vat),
  }
}

const calculateRetailPrice = (
  rpp: number,
  marginPercentage: number,
  vat: Article['vat'],
): number => {
  if (!vat) {
    throw new Error(`Can't calculate numbers, VAT value is missing`)
  }

  return (rpp / (1 - marginPercentage)) * (1 + vat)
}

export const calculateRpp = (
  retailPrice: number,
  marginPercentage: number,
  vat: Article['vat'],
): number => {
  if (!vat) {
    throw new Error(`Can't calculate numbers, VAT value is missing`)
  }

  return (retailPrice / (1 + vat)) * (1 - marginPercentage)
}

export const getSingleArticleProfit = (article: Article): number | undefined => {
  if (!article.vat) {
    return undefined
  }
  const articleRetailPriceWithoutVat = article.retailPrice.current / (1 + article.vat)
  return articleRetailPriceWithoutVat - article.rpp.current
}

export const getTotalArticleProfit = (
  article: Article,
  volumeAccessor: (article: Article) => ArticleVolume = (article) => article.volume?.lastYear,
): number | undefined => {
  const volume = volumeAccessor(article)
  if (!volume) {
    return undefined
  }
  const profitPerArticle = getSingleArticleProfit(article)
  if (!profitPerArticle) {
    return undefined
  }
  return (volumeAccessor(article) ?? 0) * profitPerArticle
}
