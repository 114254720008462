import { FilterSelect } from '@components/FilterSelect'
import { MenuItem, SelectChangeEvent } from '@mui/material'
import { TimeFilters } from '@pages/DashboardPage/Widgets/constants'

const label = (str: string) => `Group by ${str.toLowerCase()}`

export const WorkspacePeriodFilter = ({
  period,
  setPeriod,
}: {
  period: TimeFilters
  setPeriod: (value: TimeFilters) => void
}) => {
  const values = TimeFilters as Record<string, string>

  const onChange = (e: SelectChangeEvent<unknown>) => setPeriod(e.target.value as TimeFilters)

  return (
    <FilterSelect
      label={label('')}
      value={period}
      onChange={onChange}
      isValuePresent={(value: unknown) => !!value}
    >
      {Object.keys(values).map((f) => (
        <MenuItem key={f} value={values[f]}>
          {label(values[f])}
        </MenuItem>
      ))}
    </FilterSelect>
  )
}
