import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Autocomplete, Box, Button, Stack, TextField } from '@mui/material'
import {
  DefaultIndividualItemInput,
  DefaultUserInput,
  IndividualItemInput,
  ItemData,
  ItemThresholds,
  LastChange,
  PriceVolumePoint,
  UserInput,
} from '@common/types'
import { InputDefaultFilledValues, RowDetailsPanel } from './RowDetailsPanel'
import { IndividualItemTable } from 'src/merchandising/components/IndividualItemTable'
import { UpdateScenarioRequest } from '@common/dto'
import { ScenarioState } from '@common/types/scenario/ScenarioState'
import { GridRowParams } from '@mui/x-data-grid-pro'
import { useDebounce } from 'src/main/hooks'
import { ITEM_UPDATE_DEBOUNCE_TIMEOUT } from '../../../../constants'
import { isItemInError } from './utils'

interface Props {
  items: ItemData[]
  userInputs: UserInput
  marketInfo: ScenarioState['market_info']
  isLoading: boolean
  onItemsUpdated: (data: UpdateScenarioRequest) => void
  onError: (isError: boolean) => void
  allowUpdates: boolean
  defaultValues: DefaultUserInput
  isFinalNegotiationStage: boolean
}

export const IndividualItems = ({
  items,
  userInputs,
  marketInfo,
  isLoading,
  defaultValues,
  allowUpdates,
  onItemsUpdated,
  onError,
  isFinalNegotiationStage,
}: Props) => {
  const [shownDevices, setShownDevices] = useState<
    {
      value: string
      label: string
    }[]
  >([])

  const [selectedDevices, setSelectedDevices] = useState<IndividualItemInput['commercial_id'][]>([])

  const defaultValuesMap = useMemo(
    (): Record<DefaultIndividualItemInput['commercial_id'], DefaultIndividualItemInput> =>
      defaultValues.items.reduce(
        (acc, values) => {
          acc[values.commercial_id] = values

          return acc
        },
        {} as Record<DefaultIndividualItemInput['commercial_id'], DefaultIndividualItemInput>,
      ),
    [defaultValues],
  )

  const [sortedItems, setSortedItems] = useState<ItemData[]>(sortItemsByCommercialIdAsc(items))

  const [itemErrors, setItemErrors] = useState<Record<ItemData['commercial_id'], boolean>>(
    checkItemErrors(items),
  )

  useEffect(() => {
    const updatedItems = sortItemsByCommercialIdAsc(items)
    setSortedItems(updatedItems)
    setShownDevices(
      updatedItems.map((item) => ({
        value: item.commercial_id,
        label: item.name,
      })),
    )
    setItemErrors(checkItemErrors(items))
    onError(updatedItems.filter((item) => item.enabled).some((item) => isItemInError(item)))
  }, [items, onError])

  const shownDeviceMap = useMemo(
    () =>
      shownDevices
        .map((device) => device.value)
        .reduce((acc, id) => ({ ...acc, [id]: true }), {} as Record<string, boolean>),
    [shownDevices],
  )

  const onItemError = useCallback(
    (commercialId: ItemData['commercial_id'], isError: boolean) => {
      setItemErrors((itemErrors) => ({ ...itemErrors, [commercialId]: isError }))
      onError(isError)
    },
    [onError],
  )

  const handleRowReset = (commercialId: IndividualItemInput['commercial_id']) => {
    const rowDefaults = defaultValues.items.find((item) => item.commercial_id === commercialId)

    if (!rowDefaults) return

    onItemsUpdated({
      userInputs: {
        ...userInputs,
        items: items.map((item) =>
          item.commercial_id === commercialId
            ? mapToInputStructure(rowDefaults)
            : mapToInputStructure(item),
        ),
      },
    })
  }

  const enableOrDisableItems = (
    itemIds: IndividualItemInput['commercial_id'][],
    enable: boolean,
  ) => {
    const updatedItems: UserInput['items'] = sortedItems.map((item) => ({
      ...item,
      ...(itemIds.includes(item.commercial_id) ? { enabled: enable } : {}),
    }))

    debounceRequest({
      userInputs: {
        ...userInputs,
        items: updatedItems,
      },
    })
  }

  const handleRowUpdate = (
    commercial_id: string,
    switchEnableState: boolean,
    data: {
      target: InputDefaultFilledValues
      lac: InputDefaultFilledValues
      thresholds: ItemThresholds
      priceVolumePoints: PriceVolumePoint[]
    },
    lastChange?: LastChange,
  ) => {
    const updatedItems: UserInput['items'] = sortedItems.map((item) => {
      // row being edited - use modified target and lac value
      if (item.commercial_id === commercial_id) {
        return {
          commercial_id: item.commercial_id,
          enabled: switchEnableState ? !item.enabled : item.enabled,
          invoice_prices: {
            target: data.target.invoice_prices ?? 0,
            lac: data.lac.invoice_prices ?? 0,
          },
          net_prices: {
            target: data.target.net_prices,
            lac: data.lac.net_prices,
          },
          volumes: {
            target: data.target.volumes,
            lac: data.lac.volumes,
          },
          benefits: {
            gtd: { target: data.target.gtd, lac: data.lac.gtd },
            gmf: { target: data.target.gmf, lac: data.lac.gmf },
            cmf: { target: data.target.cmf, lac: data.lac.cmf },
            ctd: { target: data.target.ctd, lac: data.lac.ctd },
          },
          thresholds: data.thresholds,
          price_and_volume_points: data.priceVolumePoints,
          anchor_offer_strategy: item.anchor_offer_strategy,
          target_lump_sum_per_unit: item.target_lump_sum_per_unit,
        } as IndividualItemInput
      }

      // otherwise just transform to userinput
      return mapToInputStructure(item)
    })

    const last_change = lastChange ? { last_change: lastChange } : {}

    debounceRequest({
      userInputs: {
        ...userInputs,
        items: updatedItems,
        ...last_change,
      },
    })
  }

  const debounceRequest = useDebounce(
    (data: UpdateScenarioRequest) => onItemsUpdated(data),
    ITEM_UPDATE_DEBOUNCE_TIMEOUT,
  )

  const shouldEnableItems = areSelectedItemsAllDisabled(selectedDevices, userInputs)

  return (
    <Box>
      <Autocomplete
        multiple
        sx={{ minWidth: '50%', mb: 2 }}
        options={sortedItems.map((item) => ({
          value: item.commercial_id,
          label: item.name,
        }))}
        renderInput={(params) => <TextField {...params} label='Shown items' />}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        value={shownDevices}
        onChange={(_, selectedItems) => {
          setShownDevices([...selectedItems])
        }}
      />
      <IndividualItemTable
        rows={sortedItems.filter((item) => shownDeviceMap[item.commercial_id])}
        itemErrors={itemErrors}
        isLoading={false}
        checkboxSelection={allowUpdates}
        onRowSelectionModelChange={(selectionModel) => {
          setSelectedDevices(selectionModel.map((id) => id.toString()))
        }}
        getDetailPanelContent={({ row }: { row: ItemData }) => (
          <RowDetailsPanel
            row={row}
            defaultItemInput={defaultValuesMap[row.commercial_id]}
            marketInfo={marketInfo}
            onRowUpdate={handleRowUpdate}
            onItemError={onItemError}
            isLoading={isLoading}
            resetRow={handleRowReset}
            isFinalNegotiationStage={isFinalNegotiationStage}
          />
        )}
        getDetailPanelHeight={(params: GridRowParams<ItemData>) => (params.row.enabled ? 560 : 120)}
        defaultValues={defaultValues}
      />
      {selectedDevices.length > 0 && (
        <Stack direction='row' justifyContent='flex-end' mt={2}>
          <Button
            variant='contained'
            color='primary'
            disabled={isLoading}
            onClick={() => enableOrDisableItems(selectedDevices, shouldEnableItems)}
          >
            {shouldEnableItems ? 'Enable ' : 'Disable '}selected
          </Button>
        </Stack>
      )}
    </Box>
  )
}

const mapToInputStructure = (item: ItemData | DefaultIndividualItemInput): IndividualItemInput => {
  let priceVolumePoints: PriceVolumePoint[]

  if ('price_volume_points' in item) {
    priceVolumePoints = item.price_volume_points // FIXME align type attribute names
  } else {
    priceVolumePoints = item.price_and_volume_points
  }

  return {
    commercial_id: item.commercial_id,
    enabled: item.enabled,
    invoice_prices: {
      target: item.invoice_prices.target,
      lac: item.invoice_prices.lac,
    },
    net_prices: {
      target: item.net_prices.target,
      lac: item.net_prices.lac,
    },
    volumes: {
      target: item.volumes.target,
      lac: item.volumes.lac,
    },
    benefits: {
      gtd: { target: item.benefits.gtd.target, lac: item.benefits.gtd.lac },
      gmf: { target: item.benefits.gmf.target, lac: item.benefits.gmf.lac },
      cmf: { target: item.benefits.cmf.target, lac: item.benefits.cmf.lac },
      ctd: { target: item.benefits.ctd.target, lac: item.benefits.ctd.lac },
    },
    thresholds: item.thresholds,
    price_and_volume_points: priceVolumePoints,
    anchor_offer_strategy: item.anchor_offer_strategy,
    target_lump_sum_per_unit: item.target_lump_sum_per_unit,
  }
}

const checkItemErrors = (items: ItemData[]) =>
  items.reduce<Record<ItemData['commercial_id'], boolean>>((acc, item) => {
    acc[item.commercial_id] = item.enabled && isItemInError(item)
    return acc
  }, {})

const sortItemsByCommercialIdAsc = (items: ItemData[]) =>
  items.slice().sort((item1, item2) => (item1.commercial_id < item2.commercial_id ? -1 : 1))

const areSelectedItemsAllDisabled = (
  selectedItemIds: IndividualItemInput['commercial_id'][],
  userInput: UserInput,
) =>
  userInput.items
    .filter((item) => selectedItemIds.includes(item.commercial_id))
    .every((item) => !item.enabled)
