import { PrimaryCustomDeal } from '@common/types'
import { Box, Grid, TextField, Typography } from '@mui/material'

interface Props {
  deals: PrimaryCustomDeal[]
}

export const CustomDealsOverview = ({ deals }: Props) => {
  if (!deals || deals.length === 0) {
    return null
  }
  return (
    <Box>
      <Typography variant='h5'>Custom Deals</Typography>
      <Grid container spacing={2} pt={2}>
        <Grid item xs={6}>
          <Typography variant='h2'>Main deal</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='h2'>Alternative deal</Typography>
        </Grid>
        {deals.map((deal) => (
          <Grid key={deal.title} container item spacing={2} xs={12}>
            <Grid container item spacing={2} xs={6}>
              <Grid item xs={deal.details ? 4 : 12}>
                <TextField disabled label='Title' value={deal.title} fullWidth />
              </Grid>
              {deal.details && (
                <Grid item xs={8}>
                  <TextField
                    disabled
                    label='Details'
                    multiline
                    fullWidth
                    maxRows={3}
                    value={deal.details}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container item xs={6}>
              {deal.alternativeDeal && (
                <Grid container item spacing={2}>
                  <Grid item xs={deal.alternativeDeal.details ? 4 : 12}>
                    <TextField
                      disabled
                      label='Title'
                      value={deal.alternativeDeal.title}
                      fullWidth
                    />
                  </Grid>
                  {deal.alternativeDeal.details && (
                    <Grid item xs={8}>
                      <TextField
                        disabled
                        label='Details'
                        multiline
                        fullWidth
                        maxRows={3}
                        value={deal.alternativeDeal.details}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
