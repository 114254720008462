import { SpotLoadNegotiation } from '@logistics/pages/spotLoad/types/negotiation'

export enum SpotLoadUnitStatus {
  READY = 'READY',
  IN_PROGRESS = 'IN_PROGRESS',
  RATE_CAPTURED = 'RATE_CAPTURED',
  AGREEMENT_REACHED = 'AGREEMENT_REACHED',
  AGREEMENT_DECLINED = 'AGREEMENT_DECLINED',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED',
}

export enum SpotLoadUnitAction {
  START = 'START',
  CANCEL = 'CANCEL',
  DOWNLOAD_RATES = 'DOWNLOAD RATES',
}

export interface SpotLoadUnit {
  unit_id: string
  computed_unit_id: string
  origin: string
  origin_date: Date | null
  destination: string
  destination_date: Date | null
  equipment: string
  max_limit: number | null
  captured_price: number | null
  status: SpotLoadUnitStatus
  negotiations: SpotLoadNegotiation[]
  allowed_actions: SpotLoadUnitAction[]
  expires_at: Date
  settles_at: Date
  created_at: Date
}

export const SPOT_LOAD_UNIT_STATUS_BY_INDEX: Record<SpotLoadUnitStatus, number> = {
  [SpotLoadUnitStatus.READY]: 0,
  [SpotLoadUnitStatus.IN_PROGRESS]: 1,
  [SpotLoadUnitStatus.RATE_CAPTURED]: 2,
  [SpotLoadUnitStatus.AGREEMENT_DECLINED]: 3,
  [SpotLoadUnitStatus.AGREEMENT_REACHED]: 4,
  [SpotLoadUnitStatus.EXPIRED]: 5,
  [SpotLoadUnitStatus.CANCELLED]: 6,
}

export function getUnitStatusIndex(status: SpotLoadUnitStatus): number {
  return SPOT_LOAD_UNIT_STATUS_BY_INDEX[status] ?? Number.MAX_SAFE_INTEGER
}
