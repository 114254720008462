import { Navigate, Route } from 'react-router-dom'
import { useGetOrganizationsQuery } from '../store/projects'

const oldRoutes: Record<string, string> = {
  SCENARIOS_LIST: '/scenarios',
  SCENARIO_DETAILS: '/scenario/:sessionKey',
  SCENARIO_OVERVIEW: '/scenario/:sessionKey/overview',
  SCENARIO_MODEL: '/scenario/:sessionKey/model',
  CHANGE_REQUESTS_LIST: '/change-requests',
  NEGOTIATION_PREPARATION_ADJUSTMENTS:
    '/negotiation-preparation/:negotiationPreparationId/adjustments',
  NEGOTIATION_PREPARATION_MODEL: '/negotiation-preparation/:negotiationPreparationId/model',
  COST_INCREASE: '/cost-increase',
  COST_INCREASE_OVERVIEW: '/cost-increase/:negotiationPreparationId/overview',
  COST_AVOIDANCE: '/cost-avoidance',
  COST_AVOIDANCE_OVERVIEW: '/cost-avoidance/:negotiationPreparationId/overview',
}
// TODO: Remove this in a couple of months.
export const useMerchandisingRedirects = () => {
  const { data: orgs, isLoading } = useGetOrganizationsQuery()
  const [first] = orgs ?? []

  if (isLoading || !first) {
    return null
  }

  return Object.keys(oldRoutes).map((key) => {
    return (
      <Route
        key={oldRoutes[key]}
        path={oldRoutes[key]}
        element={<Navigate to={`/${first.tag}/merchandising${oldRoutes[key]}`} replace={true} />}
      />
    )
  })
}
