import { InfoOutlined } from '@mui/icons-material'
import { Tooltip } from '@mui/material'

interface Props {
  title: string
  value?: string
}

export const TooltipWrapper = ({ value, title }: Props) => (
  <Tooltip title={title}>
    <span>
      {value} <InfoOutlined fontSize={'small'} sx={{ verticalAlign: 'sub' }} />
    </span>
  </Tooltip>
)
