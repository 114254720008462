import { NegotiationPreparationWithInputs } from '@common/dto'
import { PactumButton } from 'src/shared/components/PactumButton'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import {
  isBackendApiErrorResponse,
  useAdjustNegotiationPreparationMutation,
} from 'src/merchandising/store/'
import { useSnackbar } from 'notistack'
import { isEmailValue } from '@common/utils/validation'
import { blue } from '@mui/material/colors'
import { useUser } from '@hooks'

interface Props {
  isOpen: boolean
  data: NegotiationPreparationWithInputs
  onEditorChanged: () => void
  onClose: () => void
}

export const EditorChangeDialog = ({ isOpen, data, onEditorChanged, onClose }: Props) => {
  const { user } = useUser()
  const { enqueueSnackbar } = useSnackbar()
  const [editor, setEditor] = useState<Props['data']['editor']>(data.editor)
  const [adjustNegotiationPreparation] = useAdjustNegotiationPreparationMutation()

  const setCurrentUserAsEditor = () => {
    if (user && user.email) {
      setEditor(user.email)
    }
  }

  const handleCancel = () => {
    setEditor(data.editor)
    onClose()
  }

  const handleSubmit = async () => {
    if (!isEmailValue(editor)) {
      return
    }

    try {
      await adjustNegotiationPreparation({
        negotiationPreparationId: data.id,
        payload: {
          editor,
        },
      }).unwrap()
      enqueueSnackbar('Updated editor', { variant: 'success' })

      onEditorChanged()
    } catch (e) {
      enqueueSnackbar(isBackendApiErrorResponse(e) ? e.data.message : 'Editor update failed', {
        variant: 'error',
      })
    }
  }

  return (
    <>
      {isOpen && (
        <Dialog open={isOpen} onClose={onClose}>
          <DialogTitle>Set editor</DialogTitle>
          <DialogContent>
            <Stack p={3} minWidth={400}>
              <TextField
                value={editor}
                label='Editor'
                onChange={(e) => setEditor(e.target.value)}
                error={!isEmailValue(editor)}
                helperText={!isEmailValue(editor) ? 'Invalid email' : null}
                fullWidth
              />
              <Box display='flex' justifyContent='flex-end'>
                <UnderlineOnHoverText variant='caption' onClick={setCurrentUserAsEditor}>
                  Use your email
                </UnderlineOnHoverText>
              </Box>
            </Stack>

            <Stack direction='row' p={2} spacing={1} justifyContent='flex-end'>
              <PactumButton color='secondary' onClick={handleCancel}>
                Cancel
              </PactumButton>
              <PactumButton sx={{ mr: 1 }} onClick={handleSubmit}>
                Update
              </PactumButton>
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

const UnderlineOnHoverText = styled(Typography)({
  color: blue[800],

  cursor: 'pointer',

  ':hover': {
    color: blue[300],
    textDecoration: 'underline',
  },
})
