import { Box, Grid } from '@mui/material'
import React from 'react'

export const SignedIndicators = ({
  nrSigned,
  nrNotSigned,
}: {
  nrSigned: number
  nrNotSigned: number
}) => {
  return (
    <Grid container direction='row'>
      {Array.from(Array(nrSigned).keys()).map((i) => (
        <Grid key={`signed-${i}`} item>
          <SigningIndicationBox signed />
        </Grid>
      ))}
      {Array.from(Array(nrNotSigned).keys()).map((i) => (
        <Grid key={`not-signed-${i}`} item>
          <SigningIndicationBox signed={false} />
        </Grid>
      ))}
    </Grid>
  )
}

const SigningIndicationBox = ({ signed }: { signed: boolean }) => {
  return (
    <Box
      width='25px'
      height='4px'
      bgcolor={signed ? '#0B8E18' : '#9E9E9E'}
      borderRadius='2px'
      marginRight='1px'
    />
  )
}
