import { useState } from 'react'
import DownloadIcon from '@mui/icons-material/Download'
import { DownloadAttachmentsConfirmationModal } from 'src/shared/components/ConfirmationModal'
import { BatchActionButton } from './BatchActionButton'
import { CommercialTermsNegotiation } from '@campaigns/types/negotiation'

export type DownloadActionProps = {
  enabled: boolean
  totalNegotiationsCount: number
  actionableNegotiations: CommercialTermsNegotiation[]
  onExecute: () => void
}

export const DownloadAttachmentsAction = ({
  enabled,
  totalNegotiationsCount,
  actionableNegotiations,
  onExecute,
}: DownloadActionProps) => {
  const [isModalOpen, setModalOpen] = useState(false)

  const attachmentCount = actionableNegotiations
    .map((nego) => {
      const uploadedFiles = nego.fileUploads?.filter((request) => request.fileUploaded) ?? []
      return uploadedFiles.length
    })
    .reduce((acc, count) => acc + count, 0)

  return (
    <>
      <BatchActionButton
        startIcon={<DownloadIcon />}
        disabled={!enabled}
        onClick={() => setModalOpen(true)}
      >
        Download attachments
      </BatchActionButton>
      <DownloadAttachmentsConfirmationModal
        allNegotiations={totalNegotiationsCount}
        actionableNegotiations={actionableNegotiations.length}
        attachmentCount={attachmentCount}
        open={isModalOpen}
        onCancel={() => setModalOpen(false)}
        onSubmit={() => {
          setModalOpen(false)
          onExecute()
        }}
      />
    </>
  )
}
