import { suiteBaseApi } from '@shared/api/suite-base-api'
import { CreateUserDto, UpdateUserDto, User, UserDetails } from '@common/types/suite'

export const userManagementApi = suiteBaseApi.injectEndpoints({
  endpoints: (build) => ({
    getOrganizationUsers: build.query<User[], { orgTag: string }>({
      query: ({ orgTag }) => `/suite/organization/${orgTag}/users`,
    }),
    addOrganizationUser: build.mutation<User, { orgTag: string; user: CreateUserDto }>({
      query: ({ orgTag, user }) => ({
        url: `/suite/organization/${orgTag}/users`,
        method: 'POST',
        body: user,
      }),
    }),
    getOrganizationUserDetails: build.query<UserDetails, { orgTag: string; user: User }>({
      query: ({ orgTag, user }) => ({
        url: `/suite/organization/${orgTag}/users/${user.userId}`,
        method: 'GET',
      }),
    }),
    updateOrganizationUser: build.mutation<User, { orgTag: string; user: UpdateUserDto }>({
      query: ({ orgTag, user }) => ({
        url: `/suite/organization/${orgTag}/users/${user.userId}`,
        method: 'PUT',
        body: user,
      }),
    }),
    removeOrganizationUser: build.mutation<void, { orgTag: string; user: User }>({
      query: ({ orgTag, user }) => ({
        url: `/suite/organization/${orgTag}/users/${user.userId}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetOrganizationUsersQuery,
  useAddOrganizationUserMutation,
  useGetOrganizationUserDetailsQuery,
  useUpdateOrganizationUserMutation,
  useRemoveOrganizationUserMutation,
} = userManagementApi
