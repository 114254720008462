import {
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  styled,
  Typography,
} from '@mui/material'
import { useController } from 'react-hook-form'

type RadioGroupCtrlProps = RadioGroupProps & {
  name: string
  required?: boolean
}

/** MUI RadioGroup controlled by react-hook-form */
export const RadioGroupCtrl = ({ name, required, ...rest }: RadioGroupCtrlProps) => {
  const { field, fieldState } = useController({ name, rules: { required } })

  return (
    <>
      <RadioGroup
        name={field.name}
        value={(field.value as string) ?? ''}
        onChange={field.onChange}
        onBlur={field.onBlur}
        ref={field.ref}
        {...rest}
      />
      <Typography color='error.main' variant='body2' fontSize='0.75rem'>
        {fieldState.error?.message}
      </Typography>
    </>
  )
}

type RadioGroupCtrlItemProps = {
  label?: string
  value: unknown
}

/** Individual radio-items to be used inside RadioGroupCtrl */
export const RadioGroupCtrlItem = ({ label, value }: RadioGroupCtrlItemProps) => (
  <FormControlLabel
    value={value}
    control={<StyledRadio color='accent' />}
    label={<Typography variant='buttonText'>{label ?? String(value)}</Typography>}
  />
)

const StyledRadio = styled(Radio)(({ theme }) => ({
  padding: theme.spacing(1),
}))
