import { Box, Stack, Tooltip, TooltipProps, Typography } from '@mui/material'
import { ReactNode } from 'react'

export const CaptionedValueCell = ({
  mainContent,
  captionContent,
  removeCaptionOpacity,
  mainContentColor,
  tooltip,
  spacing = 0.75,
  textAlign = 'left',
  disabled = false,
  fontWeight,
}: {
  mainContent: string | ReactNode
  captionContent?: string | ReactNode
  mainContentColor?: string
  removeCaptionOpacity?: boolean
  tooltip?: { id: string; title: string; placement?: TooltipProps['placement'] }
  spacing?: number
  textAlign?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
  disabled?: boolean
  fontWeight?: string
}): JSX.Element => {
  const disabledColor = disabled ? 'text.disabled' : undefined
  const valueCell = (
    <Stack textAlign={textAlign} spacing={spacing} width='100%'>
      <Typography
        color={disabledColor ?? mainContentColor}
        fontWeight={fontWeight}
        component='span'
        variant='body2'
        noWrap
      >
        {mainContent}
      </Typography>
      {captionContent && (
        <Typography
          color={disabledColor}
          component='span'
          variant='caption'
          sx={{ opacity: removeCaptionOpacity ? 1 : 0.7 }}
        >
          {captionContent}
        </Typography>
      )}
    </Stack>
  )
  return tooltip ? (
    <Tooltip
      key={tooltip.id}
      placement={tooltip.placement ?? 'top'}
      title={<Box sx={{ whiteSpace: 'pre-line' }}>{tooltip.title}</Box>}
    >
      {valueCell}
    </Tooltip>
  ) : (
    valueCell
  )
}
