import { Chip, ChipProps, Stack } from '@mui/material'
import {
  NegotiationEventSupplier,
  NegotiationEventSupplierLight,
  NegotiationFeedback,
  NegotiationStatus,
  NegotiationSummary,
} from '../../store/types'
import {
  NEGOTIATION_EVENT_STATUS_CONFIG,
  NEGOTIATION_SUMMARY_STATUS_CONFIG,
  NegotiationSummaryStatusMap,
  StatusConfig,
} from '../../constants'
import ChatIcon from '@mui/icons-material/Chat'
import React, { useState } from 'react'
import { SupplierFeedback } from '@procurement-shared/SupplierFeedback'
import pluralize from 'pluralize'

interface Props {
  size?: ChipProps['size']
  variant?: 'default' | 'inverted'
}

const determineNegotiationStatusLabel = (status: NegotiationStatus): StatusConfig =>
  status ? NEGOTIATION_EVENT_STATUS_CONFIG[status] : { label: 'Imported', color: 'default' }

export const NegotiationEventListStatusChip = ({
  status,
  statusLabel,
  size = 'small',
  variant = 'default',
}: Props & {
  status: NegotiationStatus
  statusLabel?: string
  suppliers?: NegotiationEventSupplierLight[]
}) => {
  let statusConfig: StatusConfig = determineNegotiationStatusLabel(status)
  if (statusLabel) {
    statusConfig = { ...statusConfig, label: statusLabel }
  }

  return (
    <>
      <Chip {...getProps({ statusConfig, size, variant })} />
    </>
  )
}

export const NegotiationEventStatusChip = ({
  status,
  suppliers,
  size = 'small',
  variant = 'default',
}: Props & {
  status: NegotiationStatus
  suppliers?: NegotiationEventSupplier[]
}) => {
  const [isFeedbackDrawerOpen, setIsFeedbackDrawerOpen] = useState(false)

  const toggleFeedbackDrawer = () => {
    setIsFeedbackDrawerOpen((prev) => !prev)
  }

  let statusConfig: StatusConfig = determineNegotiationStatusLabel(status)

  const suppliersWithFeedbacks = (suppliers || []).filter(
    (supplier) => !!supplier?.negotiation?.outcomes?.feedback,
  )

  const feedbackCount = suppliersWithFeedbacks
    .map((supplier) => supplier?.negotiation?.outcomes?.feedback as NegotiationFeedback)
    .map((feedback) => Object.keys(feedback).length)
    .reduce((acc, val) => acc + val, 0)

  if (feedbackCount > 0) {
    statusConfig = {
      ...statusConfig,
      label: (
        <Stack direction='row' gap={0.5} alignContent='center' alignItems='center'>
          <ChatIcon />
          {statusConfig.label} ({feedbackCount} {pluralize('comment', feedbackCount)})
        </Stack>
      ),
    }
  }
  const props = getProps({ statusConfig, size, variant })

  const hasFeedback = feedbackCount > 0

  let onClickCb = undefined
  if (hasFeedback) {
    onClickCb = toggleFeedbackDrawer
  }

  const sortedSuppliers = [...suppliersWithFeedbacks].sort((a, b) => {
    const firstFeedback = a.negotiation?.outcomes?.feedback
    if (firstFeedback === undefined) {
      return 1
    }

    const secondFeedback = b.negotiation?.outcomes?.feedback
    if (secondFeedback === undefined) {
      return -1
    }

    return Object.keys(secondFeedback).length - Object.keys(firstFeedback).length
  })

  return (
    <>
      <Chip {...props} onClick={onClickCb} />
      {hasFeedback && (
        <SupplierFeedback
          suppliers={sortedSuppliers}
          isOpen={isFeedbackDrawerOpen}
          handleClose={toggleFeedbackDrawer}
        />
      )}
    </>
  )
}

export const NegotiationStatusChip = ({
  negotiationSummary,
  size = 'small',
  variant = 'default',
}: Props & {
  negotiationSummary?: NegotiationSummary | null
}) => {
  const status: keyof NegotiationSummaryStatusMap = negotiationSummary
    ? negotiationSummary.status
    : 'READY'
  const statusConfig = NEGOTIATION_SUMMARY_STATUS_CONFIG[status]
  const props = getProps({ statusConfig, size, variant })

  return <Chip {...props} />
}

const getProps = ({ statusConfig, size, variant }: Props & { statusConfig: StatusConfig }) => {
  const { color } = statusConfig
  const props = {
    sx: getStyleOverrides(variant, color),
    size,
    color,
    component: 'span',
  }

  return {
    ...statusConfig,
    ...props,
  }
}

const getStyleOverrides = (variant: Props['variant'], color: ChipProps['color']) => {
  if (variant === 'inverted' && color === 'default') {
    return {
      color: 'white',
      backgroundColor: 'transparent',
      borderColor: 'white',
    }
  }

  return {}
}
