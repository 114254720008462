import { ScenarioDetails } from '@common/dto'
import { Button, IconButton, Stack, Tooltip } from '@mui/material'
import { useSnackbar } from 'notistack'
import CopyIcon from '@mui/icons-material/ContentCopy'
import { openNegotiationInAdmin } from '@shared/utils/admin-links'

export const OpenNegotiationButtons = ({ details }: { details: ScenarioDetails }) => {
  const { enqueueSnackbar } = useSnackbar()

  if (!details.submittedAt || !details.negotiation) {
    return null
  }

  const openClientNegotiationLink = (url: string) => {
    window.open(url, '_blank')
  }

  const copyAccessKeyToClipboard = (accessKey: string) => {
    navigator.clipboard.writeText(accessKey)

    enqueueSnackbar('Negotiation access key copied to clipboard', { variant: 'success' })
  }

  const negotiation = details.negotiation
  const accessKey = negotiation.accessKey

  return (
    <Stack direction='row' mt={2}>
      {details.isAdmin && (
        <Button
          color='info'
          variant='contained'
          onClick={() => openNegotiationInAdmin(negotiation.id)}
        >
          Open in admin
        </Button>
      )}
      <Button
        color='info'
        variant='contained'
        onClick={() => openClientNegotiationLink(negotiation.clientUrl)}
        sx={{ ml: 2 }}
      >
        Open negotiation
      </Button>
      {accessKey && (
        <Tooltip placement='top' title='Copy negotiation access key to clipboard'>
          <IconButton sx={{ ml: 0.5, pt: 0.5 }} onClick={() => copyAccessKeyToClipboard(accessKey)}>
            <CopyIcon fontSize='small' />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  )
}
