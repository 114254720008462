import { GridColDef } from '@mui/x-data-grid-pro'
import { CommercialTermsNegotiation } from '@campaigns/types/negotiation'
import { commonColumnParameters } from '../helpers'
import { Formatter } from '@utils'

export const getRebatesColumns = (
  formatter: Formatter,
): GridColDef<CommercialTermsNegotiation>[] => [
  {
    ...commonColumnParameters,
    field: 'achievedRebate',
    headerName: 'Achieved rebate',
    valueGetter: (cell) => {
      const flatRebate = cell.row.contracts[0]?.standardTerms?.flatRebate?.agreed
      return flatRebate ? formatter.percent0To1(flatRebate / 100) : 'N/A'
    },
  },
]
