import { CaptionedValueCell } from 'src/shared/components/table'
import { getPriceChangeDirectionColor } from 'src/merchandising/pages/ChangeRequests/NegotiationPreparation/Adjustments/utils'

interface Props {
  currentValue: number
  previousValue: number
  captionPrefixString: string
  options: {
    formatter: { mainContent: (v: number) => string; captionContent: (v: number) => string }
    invertColors?: boolean
  }
}

export const CurrentAndPreviousValueCell = ({
  currentValue,
  previousValue,
  captionPrefixString = '',
  options,
}: Props) => (
  <CaptionedValueCell
    mainContent={options.formatter.mainContent(currentValue)}
    captionContent={`${captionPrefixString}${options.formatter.captionContent(previousValue)}`}
    removeCaptionOpacity={
      !!getPriceChangeDirectionColor(previousValue - currentValue, options.invertColors)
    }
  />
)
