import React from 'react'
import { styled, SxProps, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'

interface Props {
  datasetIndex?: number
  text: string
  bulletColor: string
  textColor: string
  sx?: SxProps<Theme>
  onClick: (datasetIndex?: number) => void
}

const LegendBullet = styled('span')({
  display: 'inline-block',
  verticalAlign: 'middle',
  width: 11,
  height: 11,
  borderRadius: 5.5,
  marginRight: 1,
  marginBottom: 2,
  backgroundColor: '#222222',
})

export function LegendBarItem({ datasetIndex, text, textColor, bulletColor, sx, onClick }: Props) {
  return (
    <Typography
      fontSize={13}
      color={textColor}
      noWrap={true}
      mr={2}
      lineHeight={'32px'}
      sx={{ ...sx, cursor: 'pointer', userSelect: 'none' }}
      onClick={() => onClick(datasetIndex)}
    >
      <LegendBullet style={{ backgroundColor: bulletColor }} /> {text}
    </Typography>
  )
}
