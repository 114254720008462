import { ScenarioDetails } from '@common/dto'
import { Button, Dialog, DialogContent, DialogTitle, Link, Stack, Typography } from '@mui/material'
import {
  isBackendApiErrorResponse,
  useDownloadDataFile,
  useUpdateScenarioItemsMutation,
} from 'src/merchandising/store/'
import { useSnackbar } from 'notistack'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { ExcelDataFileUpload } from '../../../../components/ExcelDataFileUpload'
import { fileToFormData } from '../../../../../main/utils/file'
import { useScenarioDetailsData } from '../ScenarioPreparation'
import { useSnackbarErrorEffect } from '@shared/hooks/useSnackbarErrorEffect'

interface Props {
  isOpen: boolean
  sessionKey: ScenarioDetails['sessionKey']
  onScenarioUpdated: () => void
  onClose: () => void
}

export const AddItemsDialog = ({ isOpen, sessionKey, onScenarioUpdated, onClose }: Props) => {
  const { activeProjectTag } = useActiveProject()
  const [updateScenarioItems, { isLoading: isUpdating }] = useUpdateScenarioItemsMutation()
  const { detailsData } = useScenarioDetailsData()
  const [downloadDataFile, error] = useDownloadDataFile(detailsData.sourceFileName)
  const { enqueueSnackbar } = useSnackbar()

  useSnackbarErrorEffect(error, 'Error downloading data file')

  const handleFile = async (excelFile: Blob) => {
    try {
      const formData = fileToFormData(excelFile)

      await updateScenarioItems({
        projectTag: activeProjectTag,
        sessionKey,
        payload: formData,
      }).unwrap()

      enqueueSnackbar('Updated scenario with new items', { variant: 'success' })

      onScenarioUpdated()
    } catch (e) {
      enqueueSnackbar(isBackendApiErrorResponse(e) ? e.data.message : 'Scenario updating failed', {
        variant: 'error',
      })
    }
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Add items to Scenario</DialogTitle>
      <DialogContent>
        <Stack p={3} spacing={2} minWidth={400}>
          <Typography variant='body2'>
            Upload the data file with new items to amend the current scenario. Previous user input
            will be preserved.
          </Typography>
          <Typography variant='body2'>
            The scenario's current data file can be downloaded by clicking{' '}
            <Link
              variant='normal'
              underline='hover'
              onClick={() => downloadDataFile({ projectTag: activeProjectTag, sessionKey })}
            >
              here
            </Link>
            .
          </Typography>
          <ExcelDataFileUpload fileCallback={handleFile} isLoading={isUpdating} />
        </Stack>

        <Stack direction='row' p={2} spacing={1} justifyContent='flex-end'>
          <Button variant='contained' color='secondary' onClick={onClose}>
            Cancel
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
