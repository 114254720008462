import { useFormContext } from 'react-hook-form'
import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material'
import { PurchasingUIConfig } from '@procurement/store/types'
import { Incentive } from '@procurement/store/schema'
import { FormRow } from '@procurement/components/FormCommon/FormCommon'
import { NegotiationEventSupplierFormData } from '../schema'

interface Props {
  incentives: Required<PurchasingUIConfig['suite']['incentivesOptions']>
}

export const Incentives = ({ incentives = [] }: Props) => {
  const { watch, setValue } = useFormContext<NegotiationEventSupplierFormData>()
  const selectedIncentives = watch('negotiationSettings.incentives')

  const handleCheckboxCheck = (incentive: Incentive) => {
    let updatedIncentives

    if (selectedIncentives?.includes(incentive.id)) {
      updatedIncentives = selectedIncentives.filter((incentiveId) => incentiveId !== incentive.id)
    } else {
      updatedIncentives = [...(selectedIncentives ?? []), incentive.id]
    }

    setValue('negotiationSettings.incentives', updatedIncentives)
  }

  return (
    <FormRow>
      <Grid item xs={12}>
        <Typography sx={{ mt: 3 }} variant='subtitle2' fontSize='0.875rem'>
          Incentives
        </Typography>
      </Grid>
      <Grid sx={{ mt: 0.5 }} item container rowSpacing={2}>
        <Typography variant='body2' sx={{ mb: 2 }}>
          By choosing to include any of the below incentives in the negotiation, the supplier will
          be given the opportunity to select the ones they are interested in, motivating them to
          make concessions on the negotiable terms.
        </Typography>
        {incentives.map((incentive) => (
          <Box key={incentive.id} sx={{ width: '100%' }}>
            <FormControlLabel
              checked={selectedIncentives?.includes(incentive.id)}
              onChange={() => handleCheckboxCheck(incentive)}
              control={<Checkbox sx={{ py: 0.5 }} />}
              label={<Typography variant='body2'>{incentive.primaryWording}</Typography>}
            />
          </Box>
        ))}
      </Grid>
    </FormRow>
  )
}
