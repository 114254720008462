import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarToday'
import { FormControl, TextFieldProps, Tooltip } from '@mui/material'
import {
  DateRange,
  DateRangePicker,
  PickersShortcutsItem,
  SingleInputDateRangeField,
} from '@mui/x-date-pickers-pro'
import { styleProps } from '../Form/sharedProps'
import ErrorIcon from '@mui/icons-material/Error'
import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  align-items: center;
`

type WorkspaceDateRangeFilterProps = {
  label?: string
  inputProps?: TextFieldProps
  start: Date | null
  end: Date | null
  showShortcuts?: boolean
  setRange: (value: DateRange<Date>) => void
}

const shortcutsItems: PickersShortcutsItem<DateRange<Date>>[] = [
  {
    label: '2021 - 2022',
    getValue: () => {
      return [new Date('2021-01-01'), new Date('2021-12-31')]
    },
  },
  {
    label: '2022 - 2023',
    getValue: () => {
      return [new Date('2022-01-01'), new Date('2022-12-31')]
    },
  },
  {
    label: '2023 - ...',
    getValue: () => {
      return [new Date('2023-01-01'), new Date()]
    },
  },
  { label: 'Reset', getValue: () => [null, null] },
]

const parseDate = (date: Date): Date =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate())

export const WorkspaceDateRangeFilter = ({
  label = 'Select date range',
  start,
  end,
  inputProps,
  showShortcuts = false,
  setRange,
}: WorkspaceDateRangeFilterProps) => {
  const onChange = (e: DateRange<Date>) => setRange(e)

  return (
    <Container>
      <FormControl fullWidth size='small'>
        <DateRangePicker
          onChange={onChange}
          displayWeekNumber
          label={label}
          defaultValue={start && end ? [parseDate(start), parseDate(end)] : undefined}
          sx={{
            '.MuiInputBase-root': {
              borderRadius: 2,
            },
          }}
          slotProps={{
            textField: {
              InputProps: {
                endAdornment: <CalendarTodayRoundedIcon fontSize='small' />,
              },
              ...styleProps,
              ...inputProps,
              size: 'small',
            },
            shortcuts: {
              items: showShortcuts ? shortcutsItems : [],
            },
            actionBar: {
              actions: ['clear'],
            },
          }}
          slots={{ field: SingleInputDateRangeField }}
          onAccept={(newDate) => {
            if (newDate.filter((date) => date === null).length === 2) {
              onChange(newDate)
            }
          }}
        />
      </FormControl>
      <Tooltip title='filter by negotiation creation date range' placement='top' arrow>
        <ErrorIcon sx={{ color: 'text.secondary', fontSize: '1rem', ml: 1 }} />
      </Tooltip>
    </Container>
  )
}
