import {
  NegotiationEvent,
  NegotiationSummary,
  NegotiationEventSupplier,
  UseCase,
} from '../../../store/types'
import { DataCell, DataCellProps } from '@procurement-shared/DataCell/DataCell'
import { Formatter } from '@utils'
import {
  getBaselineSupplier,
  getNegotiatedAmountFromNegotiation,
} from '../../../utils/negotiationEventGetters'
import { formatCurrency } from '@shared/utils/formatCurrency'
import { TermItem, TermsTooltip } from '@procurement-shared/TermsTooltip/TermsTooltip'
import { isSpendNegotiation } from '@procurement/components/NegotiationEventForm/utils/spend'
import { calculateSpendAmount } from '@procurement/utils/spend-calculation'

export const AmountCell = ({
  negotiationEvent,
  negotiationSummary,
  supplier,
  useCase,
  size,
  formatter,
}: {
  negotiationEvent: NegotiationEvent
  negotiationSummary: NegotiationSummary | null
  supplier: NegotiationEventSupplier
  useCase?: UseCase
  size: DataCellProps['size']
  formatter: Formatter
}) => {
  let initialAmount = supplier?.totalPrice || negotiationEvent.initialAmount
  const { currency, lineItems, suppliers } = negotiationEvent
  const negotiatedAmount = getNegotiatedAmountFromNegotiation(negotiationSummary)

  if (useCase === 'integratedPostBid') {
    initialAmount = supplier?.totalPrice ?? 0
  }

  if (isSpendNegotiation(lineItems[0])) {
    initialAmount = calculateSpendAmount({
      spendItemUnitPrice: lineItems[0].initialUnitPrice,
      spendDurationMonths: suppliers[0].negotiationSettings?.previousContractLength,
      agreementDurationMonths: suppliers[0].negotiationSettings?.contractLength,
    })
  }

  if (negotiatedAmount === 0) {
    return (
      <DataCell label={`Original quoted amount`} size={size}>
        {formatCurrency(initialAmount, currency, formatter)}
      </DataCell>
    )
  }

  const isMultiSupplier = negotiationEvent.suppliers.length > 1
  const baselineSupplier = getBaselineSupplier({ negotiationEvent: negotiationEvent, supplier })
  const baselinePrice = baselineSupplier?.totalPrice ?? 0
  const priceSavings = negotiationSummary?.outcomes?.savingsBreakdown?.price ?? 0

  const terms: TermItem[] = isMultiSupplier
    ? [
        { title: 'Original', value: formatCurrency(initialAmount, currency, formatter) },
        { title: 'Baseline', value: formatCurrency(baselinePrice, currency, formatter) },
        { title: 'Agreed', value: formatCurrency(negotiatedAmount, currency, formatter) },
        { title: 'Savings', value: formatCurrency(priceSavings, currency, formatter) },
      ]
    : []

  return (
    <TermsTooltip terms={terms}>
      <DataCell
        label='Agreed amount'
        footnote={`Baseline ${formatCurrency(baselinePrice, currency, formatter)}`}
        size={size}
      >
        {formatCurrency(negotiatedAmount, currency, formatter)}
      </DataCell>
    </TermsTooltip>
  )
}
