import { useState } from 'react'
import { useNavigate, generatePath } from 'react-router-dom'
import { Typography, styled } from '@mui/material'
import { CommercialTermsNegotiation } from '@campaigns/types/negotiation'
import { useNegotiationsActions, NegotiationAction } from 'src/main/hooks'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import {
  StartConfirmationModal,
  DeleteConfirmationModal,
  MarkAsSignedConfirmationModal,
  MarkAsNotSignedConfirmationModal,
} from 'src/shared/components/ConfirmationModal'
import { CommercialTermsRoutes } from 'src/commercial-terms/routes'
import { ActionsButton } from '@components/ActionsButton'
import { UpdateNegotiationDialog } from '../../../components/UpdateNegotiationDialog'
import { useBatchActionLoadingNegotiationsContext } from './BatchActionLoadingNegotiations'

type ListItemAction = {
  label: string
  onClick: () => unknown
}

interface Props {
  negotiation: CommercialTermsNegotiation
  actionsMenuMinWidth: number
}

export const ListItemActions = ({ negotiation, actionsMenuMinWidth }: Props) => {
  const [startConfirmationModalOpen, setStartConfirmationModalOpen] = useState(false)
  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false)
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false)

  const [markSignedDialogOpen, setMarkSignedDialogOpen] = useState(false)
  const [markNotSignedDialogOpen, setMarkNotSignedDialogOpen] = useState(false)

  const navigate = useNavigate()
  const { activeOrgTag, activeProjectTag } = useActiveProject()

  const [loading, setLoading] = useState(false)

  const { loadingIds } = useBatchActionLoadingNegotiationsContext()

  const withLoading = async (action: () => Promise<void>) => {
    setLoading(true)
    await action()
    setLoading(false)
  }

  const {
    availableActions,
    handleDeleteNegotiations,
    handleStartNegotiations,
    handleDownloadNegotiations,
    handleDownloadAttachment,
    handleMarkSignedNegotiations,
    handleMarkNotSignedNegotiations,
  } = useNegotiationsActions([negotiation])

  const allActions: Record<NegotiationAction, ListItemAction> = {
    VIEW: {
      label: 'View results',
      onClick: () =>
        navigate(
          generatePath(CommercialTermsRoutes.NEGOTIATION_DETAILS, {
            id: negotiation.id,
            orgTag: activeOrgTag,
            projectTag: activeProjectTag,
          }),
        ),
    },
    DELETE: {
      label: 'Delete',
      onClick: () => setDeleteConfirmationModalOpen(true),
    },
    START: {
      label: 'Start',
      onClick: () => setStartConfirmationModalOpen(true),
    },
    DOWNLOAD: {
      label: 'Export results',
      onClick: () => handleDownloadNegotiations(),
    },
    DOWNLOAD_ATTACHMENT: {
      label: 'Download file(s)',
      onClick: () => handleDownloadAttachment(),
    },
    EDIT: {
      label: 'Edit',
      onClick: () => setUpdateDialogOpen(true),
    },
    MARK_SIGNED: {
      label: 'Mark as signed',
      onClick: () => setMarkSignedDialogOpen(true),
    },
    MARK_NOT_SIGNED: {
      label: 'Mark as not signed',
      onClick: () => setMarkNotSignedDialogOpen(true),
    },
  }

  if (availableActions.size === 0) {
    const actionPlaceholder = ['IN_PROGRESS', 'SENT_TO_PACTUM'].includes(negotiation.status)
      ? 'Waiting results...'
      : 'No actions'

    return <StyledNoActionsText>{actionPlaceholder}</StyledNoActionsText>
  }

  return (
    <>
      <ActionsButton
        id={negotiation.id}
        allowedActions={Array.from(availableActions)}
        actionConfig={allActions}
        menuMinWidth={actionsMenuMinWidth}
        loading={loading || loadingIds.has(negotiation.id)}
      />
      <StartConfirmationModal
        loading={loading}
        open={startConfirmationModalOpen}
        onCancel={() => setStartConfirmationModalOpen(false)}
        onSubmit={() => {
          setStartConfirmationModalOpen(false)
          withLoading(() => handleStartNegotiations())
        }}
      />
      <DeleteConfirmationModal
        loading={loading}
        open={deleteConfirmationModalOpen}
        onCancel={() => setDeleteConfirmationModalOpen(false)}
        onSubmit={() => {
          setDeleteConfirmationModalOpen(false)
          withLoading(() => handleDeleteNegotiations())
        }}
      />
      <UpdateNegotiationDialog
        key={`update-negotiation-dialog-${negotiation.id}-${updateDialogOpen}`}
        open={updateDialogOpen}
        onClose={() => setUpdateDialogOpen(false)}
        negotiation={negotiation}
      />
      <MarkAsSignedConfirmationModal
        loading={loading}
        open={markSignedDialogOpen}
        onCancel={() => setMarkSignedDialogOpen(false)}
        onSubmit={() => {
          setMarkSignedDialogOpen(false)
          withLoading(() => handleMarkSignedNegotiations())
        }}
      />
      <MarkAsNotSignedConfirmationModal
        loading={loading}
        open={markNotSignedDialogOpen}
        onCancel={() => setMarkNotSignedDialogOpen(false)}
        onSubmit={() => {
          setMarkNotSignedDialogOpen(false)
          withLoading(() => handleMarkNotSignedNegotiations())
        }}
      />
    </>
  )
}

const StyledNoActionsText = styled(Typography)(({ theme }) => ({
  width: '100%',
  fontSize: '14px',
  color: theme.palette.text.secondary,
  textAlign: 'center',
  fontWeight: theme.typography.fontWeightBold,
}))
