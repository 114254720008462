import {
  CommercialTermsNegotiation,
  FileUpload,
  SucceededFileUpload,
} from '@campaigns/types/negotiation'
import { downloadFile } from '@utils'
import { useLazyDownloadAttachmentQuery } from 'src/main/store/attachment'

export const useDownloadAttachment = () => {
  const [downloadAttachment] = useLazyDownloadAttachmentQuery()

  return async (fileDetails: SucceededFileUpload, identifier: string) => {
    if (!fileDetails || !fileDetails.negotiationFileId) {
      return
    }

    const blob = await downloadAttachment(fileDetails.negotiationFileId).unwrap()
    downloadFile(`${identifier}-${fileDetails.filename}`, blob)
  }
}

export const hasAttachments = (fileUploads: FileUpload[] | null) => {
  const succesfullyUploadedFiles = (fileUploads ?? []).filter((outcome) => outcome.fileUploaded)

  return succesfullyUploadedFiles.length > 0
}

export const getFileSupplierIdentifier = (nego: CommercialTermsNegotiation) => {
  if (nego.businessUnit) {
    return `${nego.businessUnit}_${nego.supplierId}`
  }

  return nego.supplierId
}
