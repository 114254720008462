import { useSnackbar } from 'notistack'
import { isBackendApiErrorResponse } from '@shared/backend/error/typeGuards'
import {
  useCreateFollowupsMutation,
  useDeleteRfpMutation,
  useLazyDownloadRatesQuery,
  useStartNegotiationsMutation,
  useRecreateRfpNegotiationsMutation,
} from '@logistics/store/contractedLanesApi'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { downloadFile } from '@utils'
import { RequestForPrice, RequestForPriceAction } from '@logistics/pages/contractedLanes/types'
import { format } from 'date-fns'
import { EnqueueSnackbar } from '@shared/utils/snackbar'

const RESULTS_FILE_NAME_TIMESTAMP_FORMAT = 'yyyyMMddHHmmss'

export const useRFPActions = (rfp: RequestForPrice) => {
  const [downloadResults, { isLoading: isDownloadLoading }] = useLazyDownloadRatesQuery()
  const [deleteRfp, { isLoading: isDeletionLoading }] = useDeleteRfpMutation()
  const [startNegotiations, { isLoading: isStartLoading }] = useStartNegotiationsMutation()
  const [createFollowups, { isLoading: isFollowupsLoading }] = useCreateFollowupsMutation()
  const [recreateNegotiations, { isLoading: isRecreateNegotiationsLoading }] =
    useRecreateRfpNegotiationsMutation()
  const { enqueueSnackbar } = useSnackbar()
  const { activeProjectTag } = useActiveProject()

  const availableActions: RequestForPriceAction[] = rfp.allowed_actions
  const disabledActions: RequestForPriceAction[] = rfp.disabled_actions

  const handleDownloadResults = async () => {
    try {
      const downloadResultsBlob = await downloadResults({
        projectTag: activeProjectTag,
        id: rfp.id,
      }).unwrap()
      const fileName = `${rfp.name}-${format(new Date(), RESULTS_FILE_NAME_TIMESTAMP_FORMAT)}.csv`

      downloadFile(fileName, downloadResultsBlob)
    } catch (error) {
      return handleActionError(error, enqueueSnackbar)
    }
  }

  const handleStartNegotiations = async () => {
    try {
      await startNegotiations({ projectTag: activeProjectTag, rfpId: rfp.id })
      enqueueSnackbar('Successfully started RFP', {
        variant: 'success',
        autoHideDuration: 2000,
      })
    } catch (error) {
      return handleActionError(error, enqueueSnackbar)
    }
  }

  const handleCreateFollowups = async () => {
    try {
      await createFollowups({ projectTag: activeProjectTag, rfpId: rfp.id })
      enqueueSnackbar('Successfully created followup negotiations', {
        variant: 'success',
        autoHideDuration: 2000,
      })
    } catch (error) {
      return handleActionError(error, enqueueSnackbar)
    }
  }

  const handleDeleteNegotiations = async () => {
    try {
      await deleteRfp({ projectTag: activeProjectTag, rfpId: rfp.id })
      enqueueSnackbar('Successfully deleted RFP', {
        variant: 'success',
        autoHideDuration: 2000,
      })
    } catch (error) {
      return handleActionError(error, enqueueSnackbar)
    }
  }

  const handleRecreateNegotiations = async () => {
    try {
      await recreateNegotiations({ projectTag: activeProjectTag, rfpId: rfp.id })
      enqueueSnackbar(`RFP ${rfp.id} negotations will be re-created`, {
        variant: 'success',
        autoHideDuration: 2000,
      })
    } catch (error) {
      return handleActionError(error, enqueueSnackbar)
    }
  }

  return {
    availableActions,
    disabledActions,
    handleStartNegotiations,
    handleCreateFollowups,
    handleDeleteNegotiations,
    handleDownloadResults,
    handleRecreateNegotiations,
    loading:
      isDownloadLoading ||
      isStartLoading ||
      isDeletionLoading ||
      isFollowupsLoading ||
      isRecreateNegotiationsLoading,
  }
}

const handleActionError = (error: unknown, enqueueSnackbar: EnqueueSnackbar) => {
  if (isBackendApiErrorResponse(error)) {
    enqueueSnackbar(error.data.message, { variant: 'error' })
  }
}
