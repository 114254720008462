import { Box, Dialog, DialogContent, DialogTitle, MenuItem, Stack, TextField } from '@mui/material'
import { formatISO, parse } from 'date-fns'
import { useSnackbar } from 'notistack'
import { useState } from 'react'

import { NegotiationPreparationWithInputs } from '@common/dto'
import { PreparationStatus } from '@common/types'
import { getYesterdayAsDate } from '@common/utils'
import { useFormatter } from '@shared/hooks'
import { dateFormats } from '@utils'
import {
  isBackendApiErrorResponse,
  useAdjustNegotiationPreparationMutation,
} from 'src/merchandising/store/'
import { PactumButton } from 'src/shared/components/PactumButton'

interface Props {
  isOpen: boolean
  data: NegotiationPreparationWithInputs
  onStatusChanged: () => void
  onClose: () => void
}

export const StatusChangeDialog = ({ isOpen, data, onStatusChanged, onClose }: Props) => {
  const { enqueueSnackbar } = useSnackbar()
  const formatter = useFormatter()
  const [status, setStatus] = useState<NegotiationPreparationWithInputs['status']>(data.status)
  const [deadline, setDeadline] = useState(
    formatter.date(new Date(data.deadline), dateFormats.shortDate),
  )
  const [deadlineModified, setDeadlineModified] = useState(false)
  const [adjustNegotiationPreparation] = useAdjustNegotiationPreparationMutation()

  const isInputDateValid = () => {
    const alertOnParsingFailure = () =>
      alert('Failed to parse date, format is day/month/year, date must be in the future')
    try {
      const date = parse(deadline, 'dd/MM/yyyy', new Date())

      if (date.toString() === 'Invalid Date' || date <= getYesterdayAsDate()) {
        alertOnParsingFailure()
        return false
      }
    } catch (e) {
      alertOnParsingFailure()
      return false
    }
    return true
  }

  const handleSubmit = async () => {
    if (!isInputDateValid()) {
      return
    }

    try {
      if (deadlineModified) {
        const updatedDeadline = formatISO(parse(deadline, 'dd/MM/yyyy', new Date()))
        await adjustNegotiationPreparation({
          negotiationPreparationId: data.id,
          payload: {
            status,
            // @ts-expect-error it really isn't a Date object in frontend
            deadline: updatedDeadline,
          },
        }).unwrap()
        enqueueSnackbar('Updated status and deadline', { variant: 'success' })
      } else {
        await adjustNegotiationPreparation({
          negotiationPreparationId: data.id,
          payload: {
            status,
          },
        }).unwrap()
        enqueueSnackbar('Updated status', { variant: 'success' })
      }

      onStatusChanged()
    } catch (e) {
      enqueueSnackbar(
        isBackendApiErrorResponse(e) ? e.data.message : 'Status and/or deadline update failed',
        { variant: 'error' },
      )
    }
  }

  return (
    <>
      {isOpen && (
        <Dialog open={isOpen} onClose={() => onClose()}>
          <DialogTitle>Set status or deadline</DialogTitle>
          <DialogContent>
            <Box p={2}>
              <TextField
                select
                value={status}
                label='Status'
                onChange={(e) =>
                  setStatus(e.target.value as NegotiationPreparationWithInputs['status'])
                }
                fullWidth
              >
                <MenuItem value={PreparationStatus.CONDUCT_ANALYSIS}>Conduct analysis</MenuItem>
                <MenuItem value={PreparationStatus.ACCEPT_THRESHOLDS}>Accept thresholds</MenuItem>
              </TextField>
            </Box>
            <Box p={2}>
              <TextField
                label='Deadline'
                value={deadline}
                onChange={(e) => {
                  setDeadline(e.target.value as string)
                  setDeadlineModified(true)
                }}
              />
            </Box>

            <Stack direction='row' p={2} spacing={1} justifyContent='flex-end'>
              <PactumButton color='secondary' onClick={() => onClose()}>
                Cancel
              </PactumButton>
              <PactumButton sx={{ mr: 1 }} onClick={handleSubmit}>
                Update
              </PactumButton>
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
