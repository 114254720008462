import React from 'react'
import { Box, Grid, Link, styled, Typography, Stack } from '@mui/material'
import DescriptionIcon from '@mui/icons-material/Description'
import { InnerContainer } from 'src/shared/components/Layout/Containers'
import { codingImage, contactUsImage, taskCompletionImage } from 'src/shared/components/Images'
import { ProjectDataFileUpload } from 'src/commercial-terms/pages/HomePage/ProjectDataFileUpload'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useDownloadTemplateXlsx } from 'src/commercial-terms/store'
import { useSnackbarErrorEffect } from '@shared/hooks/useSnackbarErrorEffect'
import { useUserPermissions } from '@hooks'
import { SuitePermission } from '@common/types'

export const basicFont = { fontSize: '0.875rem', lineHeight: '1.5rem' }

export const WelcomePage = () => {
  const { activeProjectTag } = useActiveProject()
  const [downloadTemplateExcel, errorXlsx] = useDownloadTemplateXlsx()

  useSnackbarErrorEffect(errorXlsx, 'Error downloading template')

  const { userPermissionsInActiveOrg } = useUserPermissions()
  const canModify = userPermissionsInActiveOrg.has(SuitePermission.MODIFY)

  return (
    <ContentContainer>
      <InnerContainer>
        {canModify ? (
          <Box mt={12}>
            <Box textAlign='center'>
              <Typography variant='h1'>Welcome to Pactum Negotiation Suite</Typography>
              <SubTitle variant='body1' sx={{ mt: 3, color: 'text.secondary' }}>
                Get your negotiations going in these three easy steps
              </SubTitle>
            </Box>
            <Grid container columnSpacing={8}>
              <Grid item xs={12} sm={4} mt={12}>
                <Section
                  image={
                    <Image src={contactUsImage as string} alt='Contact us image' loading='lazy' />
                  }
                  title='1. Download the negotiation template'
                  description='We’ve made getting started easy and fast - the template here walks you through all that is needed to get the negotiations going.'
                >
                  <Link
                    component='button'
                    variant='body1'
                    underline='none'
                    onClick={() => downloadTemplateExcel(activeProjectTag)}
                    sx={{ ...basicFont, display: 'flex', alignItems: 'center', mt: 2 }}
                  >
                    <DescriptionIcon sx={{ mr: 0.5 }} />
                    Download the template
                  </Link>
                </Section>
              </Grid>
              <Grid item xs={12} sm={4} mt={12}>
                <Section
                  image={<Image src={codingImage as string} alt='Coding image' loading='lazy' />}
                  title='2. Fill in the required details'
                  description='Simply open the file in your preferred spread sheet tool. Enter the information that is needed for the negotiations to take place. Export as a CSV and you’re done.'
                />
              </Grid>
              <Grid item xs={12} sm={4} mt={12}>
                <Section
                  image={
                    <Image
                      src={taskCompletionImage as string}
                      alt='Successful task completions image'
                      loading='lazy'
                    />
                  }
                  title='3. Upload the template back to this tool'
                  description='Once uploaded, you can verify the information and send out the negotiations via Pactum Negotiation Suite.'
                >
                  <Box mt={1}>
                    <ProjectDataFileUpload />
                  </Box>
                </Section>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box mt={12} textAlign='center'>
            <Section
              image={
                <Image
                  sx={{ width: '50%' }}
                  src={contactUsImage as string}
                  alt='Contact us image'
                  loading='lazy'
                />
              }
              title='It is taking a short while...'
              description='We’ll email you as soon as we have results to show.'
            />
          </Box>
        )}
      </InnerContainer>
      <ContactSupportSection textAlign='center'>
        <Typography variant='body1' sx={{ color: 'text.secondary' }}>
          If you run into problems, contact us at{' '}
          <Link href='mailto:support@pactum.com' title='Contact support'>
            support@pactum.com
          </Link>{' '}
          and we’ll get it sorted out.
        </Typography>
      </ContactSupportSection>
    </ContentContainer>
  )
}

interface SectionProps {
  image: React.ReactNode

  title: string
  description: string
  children?: React.ReactNode
}

const Section = ({ image, title, description, children }: SectionProps) => (
  <Box>
    {image}
    <SubTitle variant='body1' mt={4}>
      {title}
    </SubTitle>
    <Typography variant='body1' mt={2} sx={{ color: 'text.secondary', ...basicFont }}>
      {description}
    </Typography>
    <Box>{children}</Box>
  </Box>
)

const Image = styled('img')({
  width: '100%',
  height: '100%',
})

const SubTitle = styled(Typography)({
  ...basicFont,
  fontWeight: 700,
})

const ContactSupportSection = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(6),
  paddingBottom: theme.spacing(1),
}))

const ContentContainer = styled(Stack)({
  flexGrow: 1,
  justifyContent: 'space-between',
})
