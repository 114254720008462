import { GridRenderCellParams, GridRowModel } from '@mui/x-data-grid-pro'
import React, { MouseEvent, useState } from 'react'
import { IconButton, Popover } from '@mui/material'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { PopoverContent } from '@procurement-shared/NegotiationEventForm/sections/LineItems/PopoverContent'
import { NegotiationEventFormData } from '../../schema'

type RowModel = NegotiationEventFormData['lineItems'][number]

type Props = GridRenderCellParams<RowModel, RowModel['deliveryLocation']> & {
  disabled: boolean
  handleUpdate: (row: GridRowModel<RowModel>) => void
}

export const DeliveryLocationCell = ({ value, row, disabled, handleUpdate }: Props) => {
  const [fieldValue, setFieldValue] = useState(value ?? '')

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setFieldValue(value ?? '')
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLocationChange = () => {
    let deliveryLocation: string | null = fieldValue
    if (deliveryLocation.trim() === '') {
      deliveryLocation = null
    }
    handleUpdate({ ...row, deliveryLocation })
    handleClose()
  }

  const open = Boolean(anchorEl)
  const popoverId = open ? 'address-popover' : undefined

  return (
    <div>
      <IconButton onClick={handleClick}>
        {value ? <LocalShippingIcon /> : <AddCircleIcon />}
      </IconButton>
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            borderRadius: 0,
          },
        }}
      >
        <PopoverContent
          fieldValue={fieldValue}
          disabled={disabled}
          setFieldValue={setFieldValue}
          handleLocationChange={handleLocationChange}
        />
      </Popover>
    </div>
  )
}
