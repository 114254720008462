import { MenuItem, SelectChangeEvent } from '@mui/material'
import { FilterSelect } from '@components/FilterSelect'

export const HasAttachmentsFilter = ({
  hasAttachments,
  setHasAttachments,
}: {
  hasAttachments: boolean | 'all'
  setHasAttachments: (hasAttachments: boolean | 'all') => void
}) => {
  const handleChange = (e: SelectChangeEvent<unknown>) => {
    if (e.target.value === 'true') {
      setHasAttachments(true)
    } else if (e.target.value === 'false') {
      setHasAttachments(false)
    } else if (e.target.value === 'all') {
      setHasAttachments('all')
    }
  }
  return (
    <FilterSelect
      label={''}
      value={hasAttachments}
      onChange={handleChange}
      isValuePresent={(_value: unknown) => true}
    >
      <MenuItem value={'all'}>Any attachments</MenuItem>
      {/* doesn't allow bools :( */}
      <MenuItem value={'true'}>Has attachments</MenuItem>
      <MenuItem value={'false'}>No attachments</MenuItem>
    </FilterSelect>
  )
}
