import {
  useUpdateNegotiationEventMutation,
  useUploadAttachmentsMutation,
} from '@procurement/store/purchasing'
import { DetachedAttachment } from '@procurement/store/schema'
import {
  NegotiationEvent,
  NegotiationEventAttachment,
  NegotiationEventSupplier,
} from '@procurement/store/types'
import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { useActiveProject } from '@shared/hooks/useActiveProject'

type UseUploadNegotiationEventAttachments = () => [
  (params: {
    attachments: DetachedAttachment[]
    negotiationEventId: NegotiationEvent['id']
    supplierId?: NegotiationEventSupplier['id']
  }) => Promise<NegotiationEventAttachment[]>,
  result: {
    error: FetchBaseQueryError | SerializedError | undefined
    isError: boolean
    isLoading: boolean
  },
]

export const useUploadNegotiationEventAttachments: UseUploadNegotiationEventAttachments = () => {
  const { activeProjectTag } = useActiveProject()
  const [
    uploadAttachments,
    {
      error: uploadAttachmentsError,
      isError: isUploadAttachmentsError,
      isLoading: isUploadAttachmentsLoading,
    },
  ] = useUploadAttachmentsMutation()
  const [
    updateNegotiationEvent,
    {
      error: updatedNegotiationEventError,
      isError: isUpdateNegotiationEventError,
      isLoading: isUpdateNegotiationEventLoading,
    },
  ] = useUpdateNegotiationEventMutation()

  return [
    async ({ attachments, negotiationEventId, supplierId }) => {
      const files = attachments.map((attachment) => attachment.file)

      const uploadedAttachments = await uploadAttachments({
        files,
        negotiationEventId,
        projectTag: activeProjectTag,
        supplierId,
      }).unwrap()

      if (uploadedAttachments.length) {
        const updatedAttachments = uploadedAttachments.map((uploadedAttachment) => {
          const matchingDetachedAttachment = attachments.find(
            (attachment) => attachment.file.name === uploadedAttachment.fileName,
          )
          if (matchingDetachedAttachment) {
            return {
              ...uploadedAttachment,
              isVisibleToSupplier: matchingDetachedAttachment.isVisibleToSupplier,
            }
          }
          return uploadedAttachment
        })

        await updateNegotiationEvent({
          negotiationEventData: {
            attachments: updatedAttachments,
          },
          negotiationEventId,
          projectTag: activeProjectTag,
        })
      }

      return uploadedAttachments
    },
    {
      error: uploadAttachmentsError || updatedNegotiationEventError,
      isError: isUploadAttachmentsError || isUpdateNegotiationEventError,
      isLoading: isUploadAttachmentsLoading || isUpdateNegotiationEventLoading,
    },
  ]
}
