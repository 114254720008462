import { CustomImportSchemaFieldWithExample } from '@common/types'
import { CheckboxCtrl } from '@components/Form'
import { Grid } from '@mui/material'
import { getCustomFieldName } from './getCustomFieldName'

export const CustomInputFieldBoolean = ({
  field,
}: {
  field: CustomImportSchemaFieldWithExample
}) => {
  return (
    <Grid item xs={12} md={4} alignItems='center' display={'flex'}>
      <CheckboxCtrl name={getCustomFieldName(field)} label={field.headerLabel}></CheckboxCtrl>
    </Grid>
  )
}
