import { ProductType, ProjectListEntity } from '@pactum/core-backend-types'
import { createApi } from '@reduxjs/toolkit/query/react'
import { suiteBaseApi } from '@shared/api/suite-base-api'
import { renderProjectAsBaselineModule } from '@shared/projectsSpecific'
import { GenericSuiteModule } from '../main/types/suite-module'
import { SuiteEntryPoint } from './entrypoint'
import { Routes, navigationItems } from './routes'
import { SuitePermission } from '@common/types'

export const baselineModule: GenericSuiteModule = {
  name: 'suite',
  api: suiteBaseApi as unknown as ReturnType<typeof createApi>,
  entrypoint: SuiteEntryPoint,
  basePath: 'suite',
  projectSwitchPath: (_?: Set<SuitePermission>) => Routes.DASHBOARD,
  isProjectCompatible: (project: ProjectListEntity) =>
    renderProjectAsBaselineModule(project) ||
    project.productType === ProductType.Bespoke ||
    (project.productType === null && project.compatibleWithNegotiationSuite),
  navigationItems: navigationItems,
}
