import { DataGrid, dataGridProps } from '@components/table/DataGrid'
import { TooltipCell } from '@components/TooltipCell'
import { AdditionalLineItemTerms } from '@procurement-shared/NegotiationEventForm/sections/LineItems/AdditionalLineItemTerms'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { Box, Button, Stack, Typography } from '@mui/material'
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowModel,
  GridValueGetterParams,
  GridValueSetterParams,
} from '@mui/x-data-grid-pro'
import { useGetConfigurationQuery } from '@procurement/store/purchasing'
import {
  ConsignmentAgreementStatusEnum,
  ContractModelTypes,
  PurchasingUIConfig,
} from '@procurement/store/types'
import { isIsoDate, parseIsoDateLocalTime } from '@procurement/utils/date'
import { labelForProduct } from '@procurement/utils/labelForProduct'
import { useFormatter } from '@shared/hooks'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { dateFormats } from '@utils'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { NegotiationEventFormContext } from '../../NegotiationEventFormContext'
import { DeliveryLocationCell } from '@procurement-shared/NegotiationEventForm/sections/LineItems/DeliveryLocationCell'
import { InvoiceDateEditCell } from '@procurement-shared/NegotiationEventForm/sections/LineItems/InvoiceDateEditCell'
import { getTotalLineItemsPrice } from '@procurement/components/NegotiationEventForm/utils/lineItems'
import { NegotiationEventFormData } from '../../schema'

const CONTRACT_INVOICE_DATES_PATH = 'contractModel.properties.invoiceDates'

type RowModel = NegotiationEventFormData['lineItems'][number]

interface Props {
  allowedIncoterms?: PurchasingUIConfig['suite']['allowedIncoterms']
  defaultIncoterm?: PurchasingUIConfig['suite']['defaultIncoterm']
  productType?: PurchasingUIConfig['suite']['productType']
  moqEnabled?: PurchasingUIConfig['suite']['moqEnabled']
}

export const ContractCostLineItemsTable = ({
  allowedIncoterms = [],
  defaultIncoterm,
  productType,
  moqEnabled,
}: Props) => {
  const formatter = useFormatter()
  const { activeProjectTag } = useActiveProject()
  const { data: configuration } = useGetConfigurationQuery({ projectTag: activeProjectTag })
  const { visibleFields, disabledFields } = useContext(NegotiationEventFormContext)
  const { control, watch, getValues, setValue } = useFormContext<NegotiationEventFormData>()
  const { append, replace } = useFieldArray({ control, name: 'lineItems' })
  const [currency, lineItems, category, contractModelId] = watch([
    'currency',
    'lineItems',
    'category',
    'contractModel.id',
  ])
  const totalLineItemsPrice = getTotalLineItemsPrice(lineItems)
  const disabled = disabledFields.includes('lineItems')
  const defaultInvoiceDate = visibleFields.includes('lineItems.invoiceDate') ? '' : undefined
  const lineItemQuantityField = configuration?.data.suite.requisitionFormFields?.find(
    (field) => field.id === 'lineItems.quantity',
  ) ?? { label: 'Quantity' }
  const lineItemLocationField = configuration?.data.suite.requisitionFormFields?.find(
    (field) => field.id === 'lineItems.deliveryLocation',
  ) ?? { label: 'Incoterm location' }

  const invoiceDatesEnabled =
    visibleFields.includes('lineItems.invoiceDate') &&
    contractModelId === ContractModelTypes.SignedOrderForm

  const addNewItem = () => {
    // 0 for empty line items
    const maxOrder = lineItems.length
      ? Math.max(...lineItems.map((item) => item.numberInCollection ?? 0))
      : 0
    const nextOrder = maxOrder + 1
    const DEFAULT_UNIT_OF_MEASURE = 'each'
    const incoterm = visibleFields.includes('lineItems.incoterm') ? defaultIncoterm : null
    const invoiceDate = invoiceDatesEnabled ? '' : undefined

    append({
      id: placeholderIdForNewItem(nextOrder),
      externalId: null,
      numberInCollection: nextOrder,
      name: 'Name',
      quantity: 1,
      unit: DEFAULT_UNIT_OF_MEASURE,
      initialUnitPrice: 1000, // TODO: should come from the configuration
      invoiceDate: defaultInvoiceDate,
      category,
      currency,
      incoterm,
    })

    const currentInvoiceDates = getValues(CONTRACT_INVOICE_DATES_PATH) ?? {}

    setValue(CONTRACT_INVOICE_DATES_PATH, {
      ...currentInvoiceDates,
      [nextOrder]: invoiceDate,
    })
  }

  const handleRowUpdate = (row: GridRowModel<RowModel>) => {
    const updatedLineItems = lineItems.map((lineItem) => (lineItem.id === row.id ? row : lineItem))

    replace(updatedLineItems)

    if (row.numberInCollection) {
      const currentInvoiceDates = getValues(CONTRACT_INVOICE_DATES_PATH) ?? {}

      setValue(CONTRACT_INVOICE_DATES_PATH, {
        ...currentInvoiceDates,
        [row.numberInCollection]: row.invoiceDate,
      })
    }

    return row
  }

  const handleRowDelete = (numberInCollection: number) => {
    const { [numberInCollection]: _, ...otherDates } = getValues(CONTRACT_INVOICE_DATES_PATH) ?? {}
    const updatedLineItems = lineItems.filter(
      (item) => item.numberInCollection !== numberInCollection,
    )

    replace(updatedLineItems)
    setValue(CONTRACT_INVOICE_DATES_PATH, otherDates)
  }

  const columns: GridColDef<RowModel>[] = [
    {
      ...commonColumnProps,
      editable: !disabled,
      field: 'numberInCollection',
      headerName: labelForProduct(productType, 'negotiationEventFormLineItemNumberInCollection'),
      type: 'number',
    },
    {
      ...commonColumnProps,
      field: 'name',
      editable: !disabled,
      headerName: 'Name',
      flex: 2,
      renderCell: ({ value }: GridRenderCellParams<RowModel, string>) => (
        <TooltipCell tooltip={value}>{value}</TooltipCell>
      ),
    },
    {
      ...commonColumnProps,
      field: 'description',
      editable: !disabled,
      headerName: 'Description',
      flex: 2,
    },
    {
      ...commonColumnProps,
      field: 'quantity',
      editable: !disabled,
      headerName: lineItemQuantityField.label,
      type: 'number',
      flex: 2,
    },
    {
      ...commonColumnProps,
      field: 'unit',
      editable: !disabled,
      headerName: 'Unit of measure',
      flex: 2,
    },
    {
      ...commonColumnProps,
      field: 'incoterm',
      editable: !disabled,
      headerName: 'Current incoterm',
      type: 'singleSelect',
      valueOptions: ['-', ...allowedIncoterms],
      valueGetter: ({ value }: GridValueGetterParams<RowModel, string>) => value ?? '-',
      flex: 2,
    },
    {
      ...commonColumnProps,
      field: 'acceptableIncoterms',
      editable: !disabled,
      headerName: 'Acceptable incoterms',
      flex: 2.5,
      type: 'singleSelect',
      valueOptions: ['-', ...allowedIncoterms],
      valueGetter: ({ value }: GridValueGetterParams<RowModel, string>) => value ?? '-',
      valueSetter: ({ value, row }: GridValueSetterParams<RowModel, string>) => ({
        ...row,
        acceptableIncoterms: value === '-' ? null : value,
      }),
    },
    {
      ...commonColumnProps,
      field: 'initialUnitPrice',
      editable: !disabled,
      flex: 2,
      type: 'number',
      headerName: 'Price per unit',
      renderCell: ({ value }) => formatter.currency(value, { currency, maxFractionDigits: 6 }),
    },
    {
      ...commonColumnProps,
      field: 'invoiceDate',
      editable: !disabled,
      flex: 2.5,
      headerName: 'Invoice date',
      renderCell: ({ value }: GridRenderCellParams<RowModel, RowModel['invoiceDate']>) => {
        if (!value) {
          return ''
        }

        // TODO: something is causing constant re-render here
        //console.log('Render value', value, isIsoDate(value), parseIsoDateLocalTime(value))

        if (isIsoDate(value)) {
          return formatter.date(parseIsoDateLocalTime(value), dateFormats.shortDate, {
            slashSeparators: true,
          })
        }

        return value
      },
      renderEditCell: (params) => <InvoiceDateEditCell {...params} />,
    },
    {
      ...commonColumnProps,
      field: 'deliveryLocation',
      editable: false, // Editing is done through popover in `renderCell`
      flex: 2,
      type: 'string',
      headerName: lineItemLocationField.label,
      renderCell: (params: GridRenderCellParams<RowModel, RowModel['deliveryLocation']>) => (
        <DeliveryLocationCell disabled={disabled} handleUpdate={handleRowUpdate} {...params} />
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      editable: !disabled,
      flex: 1,
      align: 'right',
      getActions: ({ row }: { row: RowModel }) =>
        disabled
          ? []
          : [
              <GridActionsCellItem
                icon={<DeleteOutlineIcon />}
                label='Delete'
                onClick={() => handleRowDelete(row.numberInCollection!)}
              />,
            ],
    },
  ]

  const columnVisibilityModel = {
    invoiceDate: invoiceDatesEnabled,
    deliveryLocation: visibleFields.includes('lineItems.deliveryLocation'),
    incoterm: visibleFields.includes('lineItems.incoterm'),
    acceptableIncoterms: visibleFields.includes('lineItems.incoterm'),
  }

  const noItemsAdded = lineItems.length === 0

  const additionalLineItemTerms = useCallback(
    ({ row }: { row: RowModel }) => (
      <AdditionalLineItemTerms lineItem={row} moqEnabled={moqEnabled} />
    ),
    [moqEnabled],
  )
  const [expandedRowIds, setExpandedRowIds] = useState<GridRowId[]>([])
  useEffect(() => {
    const autoExpandedRows = lineItems
      .filter(
        (row) =>
          (row.consignmentAgreementStatus &&
            row.consignmentAgreementStatus !== ConsignmentAgreementStatusEnum.NoAnswer) ||
          (moqEnabled && (row.minimumOrderQuantity || row.maximumAcceptableMoq)),
      )
      .map((row) => row.id)
      .filter((id) => id !== null && id !== undefined) as GridRowId[]

    setExpandedRowIds((expandedRowIds) => [...new Set([...expandedRowIds, ...autoExpandedRows])])
  }, [lineItems, moqEnabled])
  const handleDetailPanelExpandedRowIdsChange = (rowIds: GridRowId[]) => {
    setExpandedRowIds(rowIds)
  }
  const isConsignmentAgreementConfigured = visibleFields.includes(
    'suppliers.0.negotiationSettings.consignmentAgreementStatuses',
  )
  const showAdditionalItemTermsPanel =
    isConsignmentAgreementConfigured ||
    moqEnabled ||
    lineItems.some((item) => Boolean(item.consignmentAgreementStatus))

  const detailPanelProps = showAdditionalItemTermsPanel
    ? {
        getDetailPanelContent: additionalLineItemTerms,
        detailPanelExpandedRowIds: expandedRowIds,
        onDetailPanelExpandedRowIdsChange: handleDetailPanelExpandedRowIdsChange,
        getDetailPanelHeight: (): 'auto' => 'auto',
      }
    : {}

  return (
    <>
      <Box sx={{ height: noItemsAdded ? '75px' : 'auto' }}>
        <DataGrid
          {...detailPanelProps}
          {...dataGridProps}
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          rows={lineItems}
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              border: 1,
            },
          }}
          autoHeight={!noItemsAdded}
          processRowUpdate={handleRowUpdate}
          localeText={{
            noRowsLabel: '',
          }}
        />
      </Box>
      <Stack sx={{ mt: 1 }} direction='row' justifyContent='space-between'>
        <Button
          variant='outlined'
          size='small'
          color='tertiary'
          disabled={disabled}
          onClick={addNewItem}
        >
          + Add item
        </Button>
        <Typography variant='subtitle2' fontSize='0.875rem'>
          Total:{' '}
          {currency ? formatter.currency(totalLineItemsPrice, { currency }) : totalLineItemsPrice}
        </Typography>
      </Stack>
    </>
  )
}

const placeholderIdForNewItem = (value: number) => value * -1

const commonColumnProps = {
  disableColumnMenu: true,
  flex: 1,
}
