import {
  GridEditInputCell,
  GridRenderEditCellParams,
  useGridApiContext,
} from '@mui/x-data-grid-pro'
import { DatePicker } from '@mui/x-date-pickers-pro'
import { format } from 'date-fns'
import { isIsoDate, parseIsoDateLocalTime } from '@procurement/utils/date'
import { NegotiationEventFormData } from '../../schema'

type RowModel = NegotiationEventFormData['lineItems'][number]

export const InvoiceDateEditCell = (
  params: GridRenderEditCellParams<RowModel, RowModel['invoiceDate']>,
) => {
  const { id, field, value } = params
  const apiRef = useGridApiContext()

  if (!isIsoDate(value)) {
    return <GridEditInputCell {...params} />
  }

  const handleChange = (newValue: unknown) => {
    if (newValue instanceof Date) {
      apiRef.current.setEditCellValue({ id, field, value: format(newValue, 'yyyy-MM-dd') })
    } else {
      apiRef.current.setEditCellValue({ id, field, value: newValue })
    }
  }

  return (
    <DatePicker
      value={parseIsoDateLocalTime(value)}
      timezone='UTC'
      slotProps={{
        textField: {
          InputProps: {
            sx: {
              fontSize: '14px',
            },
          },
        },
      }}
      onChange={handleChange}
    />
  )
}
