import { FormHelperText, Grid, Typography } from '@mui/material'
import { applyNames } from '@logistics/utils/nameHelper'
import {
  DatePickerCtrl,
  NumberFieldCtrl,
  RadioGroupCtrl,
  RadioGroupCtrlItem,
  TextFieldCtrl,
  TimeFieldCtrl,
} from '@components/Form'
import { AddressAutocompleteCtrl } from '@logistics/components/AddressAutocompleteCtrl'
import { PlaceDetails } from '@logistics/hooks/useGooglePlaces'

export const UnitModalForm = ({
  onOriginDetails,
  onDestinationDetails,
}: {
  onOriginDetails: (result: PlaceDetails | null) => void
  onDestinationDetails: (result: PlaceDetails | null) => void
}) => {
  return (
    <Grid container gap={3}>
      <Grid item xs={12}>
        <Grid container direction='column' gap={3}>
          <Grid container direction='row' spacing={2}>
            <Grid item xs={12}>
              <Grid container gap={3}>
                <Typography variant='subtitle2'>Pick-up</Typography>
                <Grid item xs={12}>
                  <AddressAutocompleteCtrl
                    name='origin'
                    label='From'
                    placeholder='Enter origin city and state'
                    fullWidth
                    required
                    onPlaceDetails={onOriginDetails}
                  />
                  <FormHelperText sx={{ ml: 1 }}>
                    Enter city and the state from where the load originates
                  </FormHelperText>
                  <Grid container direction='row' spacing={2} sx={{ mt: 2 }}>
                    <Grid item container gap={3} xs={12} sm={6}>
                      <Grid item xs={12}>
                        <DatePickerCtrl
                          name='pickupDate'
                          label='Pick-up date'
                          disablePast
                          required
                          fullWidth
                        />
                        <FormHelperText sx={{ ml: 1 }}>MM/DD/YYYY</FormHelperText>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid container gap={3}>
                        <TimeFieldCtrl name='pickupTime' label='Pick-up time' required fullWidth />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Typography variant='subtitle2'>Delivery</Typography>
                <Grid item xs={12}>
                  <AddressAutocompleteCtrl
                    name='destination'
                    label='To'
                    placeholder='Enter destination city and state'
                    fullWidth
                    required
                    onPlaceDetails={onDestinationDetails}
                  />
                  <FormHelperText sx={{ ml: 1 }}>
                    Enter the city and state where the load is headed to
                  </FormHelperText>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction='column' gap={3}>
          <Typography variant='subtitle2'>{applyNames('Unit information')}</Typography>
          <Grid item xs={3}>
            <TextFieldCtrl name='froNumber' label='FRO Number' required placeholder='123456' />
          </Grid>
          <Grid container direction='row' columnSpacing={2} sx={{ mt: -1 }}></Grid>
          <Grid sx={{ mt: -1 }}>
            <NumberFieldCtrl
              name='totalWeight'
              label='Weight (lbs)'
              required
              min={1}
              placeholder='10 000 lbs'
              fullWidth
              sx={{ maxWidth: '360px' }}
            />
            <FormHelperText sx={{ ml: 1 }}>How much does your load weigh?</FormHelperText>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container direction='column' gap={2}>
          <Typography variant='subtitle2'>Equipment type</Typography>
          <Grid container direction='row'>
            <Grid item xs={6}>
              <RadioGroupCtrl name='equipmentType' required>
                <RadioGroupCtrlItem value='Dry van' />
                <RadioGroupCtrlItem value='Flat bed' />
                <RadioGroupCtrlItem value='Reefer' />
              </RadioGroupCtrl>
            </Grid>
          </Grid>
          <Grid item>
            <TextFieldCtrl
              name='comments'
              label='Comments'
              placeholder='Comments and instructions for the carriers'
              fullWidth
            />
            <FormHelperText sx={{ ml: 1 }}>
              Additional instructions you want to give to the carriers
            </FormHelperText>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
