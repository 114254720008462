import { format } from 'date-fns'
import { logisticsBaseApi } from './baseApi'
import { fileToFormData, responseAsObjectUrl } from 'src/main/utils/file'
import { createFileDownloadHook } from '@shared/hooks/createFileDownloadHook'
import { ContractedLanesUnit, RequestForPrice } from '@logistics/pages/contractedLanes/types'
import { isPopulatedArray } from '@utils/array'
import { isPopulatedString } from '@utils/string'

type ImportRecordParams = {
  projectTag: string
  name: string
  deadline: Date
  start: Date
  end: Date
  additionalRequirements?: string
  selectedCarrierIds?: string[]
  contactAllSelectedCarriers?: boolean
  file: Blob
}
export const contractedLanesApi = logisticsBaseApi.injectEndpoints({
  endpoints: (build) => ({
    downloadRFPTemplateCsv: build.query<string, string>({
      query: (projectTag) => ({
        url: `/suite/${projectTag}/contracted-lanes/template/csv`,
        responseHandler: responseAsObjectUrl,
      }),
    }),
    downloadRFPTemplateExcel: build.query<string, string>({
      query: (projectTag) => ({
        url: `/suite/${projectTag}/contracted-lanes/template/excel`,
        responseHandler: responseAsObjectUrl,
      }),
    }),
    importRFP: build.mutation<RequestForPrice, ImportRecordParams>({
      query: (params) => ({
        url: getImportRFPQueryParamsUrl(
          `/suite/${params.projectTag}/contracted-lanes/import`,
          params,
        ),
        method: 'POST',
        body: getImportFormBody(params),
      }),
      invalidatesTags: ['RFPList'],
    }),
    createNegotiations: build.mutation<void, { projectTag: string; rfpId: number }>({
      query: ({ projectTag, rfpId }) => ({
        url: `/suite/${projectTag}/contracted-lanes/${rfpId}/create-negotiations`,
        method: 'POST',
      }),
      invalidatesTags: ['RFPList', 'RFPDetails'],
    }),
    startNegotiations: build.mutation<void, { projectTag: string; rfpId: number }>({
      query: ({ projectTag, rfpId }) => ({
        url: `/suite/${projectTag}/contracted-lanes/${rfpId}/start-negotiations`,
        method: 'POST',
      }),
      invalidatesTags: ['RFPList', 'RFPDetails'],
    }),
    createFollowups: build.mutation<void, { projectTag: string; rfpId: number }>({
      query: ({ projectTag, rfpId }) => ({
        url: `/suite/${projectTag}/contracted-lanes/${rfpId}/create-followup-negotiations`,
        method: 'POST',
      }),
      invalidatesTags: ['RFPList', 'RFPDetails'],
    }),
    getRFPList: build.query<
      { items: RequestForPrice[]; total: number },
      { projectTag: string; skip: number; limit: number }
    >({
      query: ({ projectTag, skip, limit }) => ({
        url: `/suite/${projectTag}/contracted-lanes/`,
        method: 'GET',
        params: {
          skip,
          limit,
        },
      }),
      providesTags: ['RFPList'],
    }),
    getRfpDetails: build.query<RequestForPrice, { projectTag: string; id: number }>({
      query: ({ projectTag, id }) => ({
        url: `/suite/${projectTag}/contracted-lanes/${id}`,
      }),
      providesTags: ['RFPDetails'],
    }),
    getRfpUnits: build.query<
      { items: ContractedLanesUnit[]; total: number },
      { projectTag: string; id: number; skip: number; limit: number }
    >({
      query: ({ projectTag, id, skip, limit }) => ({
        url: `/suite/${projectTag}/contracted-lanes/${id}/units`,
        params: {
          limit,
          skip,
        },
      }),
    }),
    downloadRates: build.query<string, { projectTag: string; id: number }>({
      query: ({ projectTag, id }) => ({
        url: `/suite/${projectTag}/contracted-lanes/${id}/rates`,
        responseHandler: responseAsObjectUrl,
      }),
    }),
    deleteRfp: build.mutation<void, { projectTag: string; rfpId: number }>({
      query: ({ projectTag, rfpId }) => ({
        url: `/suite/${projectTag}/contracted-lanes/${rfpId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['RFPList'],
    }),
    getRfpNegotiationCount: build.query<
      { total_negotiation_count: number },
      { projectTag: string; id: number }
    >({
      query: ({ projectTag, id }) => ({
        url: `/suite/${projectTag}/contracted-lanes/${id}/units/negotiations/count`,
        method: 'GET',
      }),
    }),
    getNegotiationPreviewLink: build.query<
      { chatLink: string },
      { projectTag: string; id: number; negotiationId: number }
    >({
      query: ({ projectTag, id, negotiationId }) => ({
        url: `/suite/${projectTag}/contracted-lanes/${id}/units/negotiations/${negotiationId}/preview`,
        method: 'GET',
      }),
    }),
    recreateRfpNegotiations: build.mutation<void, { projectTag: string; rfpId: number }>({
      query: ({ projectTag, rfpId }) => ({
        url: `/suite/${projectTag}/contracted-lanes/${rfpId}/recreate-negotiations`,
        method: 'POST',
      }),
      invalidatesTags: ['RFPList', 'RFPDetails'],
    }),
  }),
})

const getImportRFPQueryParamsUrl = (baseUrl: string, importRecord: ImportRecordParams) => {
  const searchParams = new URLSearchParams()
  const dateFormat = 'yyyy-MM-dd'

  searchParams.append('project_tag', importRecord.projectTag)

  searchParams.append('name', importRecord.name)
  searchParams.append('contract_start_date', format(importRecord.start, dateFormat))
  searchParams.append('contract_end_date', format(importRecord.end, dateFormat))
  searchParams.append('request_expiry_date', format(importRecord.deadline, dateFormat))
  if (isPopulatedString(importRecord.additionalRequirements)) {
    searchParams.append('additional_requirements', importRecord.additionalRequirements)
  }
  if (isPopulatedArray(importRecord.selectedCarrierIds)) {
    searchParams.append('selected_supplier_ids', importRecord.selectedCarrierIds.join(','))
  }
  if (importRecord.contactAllSelectedCarriers === true) {
    searchParams.append('contact_all_selected_suppliers', 'true')
  }

  return `${baseUrl}?${searchParams.toString()}`
}

const getImportFormBody = (importRecord: ImportRecordParams) => fileToFormData(importRecord.file)

export const {
  useImportRFPMutation,
  useGetRFPListQuery,
  useGetRfpDetailsQuery,
  useGetRfpUnitsQuery,
  useLazyDownloadRatesQuery,
  useDeleteRfpMutation,
  useCreateNegotiationsMutation,
  useStartNegotiationsMutation,
  useCreateFollowupsMutation,
  useGetRfpNegotiationCountQuery,
  useLazyGetNegotiationPreviewLinkQuery,
  useRecreateRfpNegotiationsMutation,
} = contractedLanesApi

export const useDownloadRFPTemplateCsv = createFileDownloadHook(
  'rfp_import.csv',
  contractedLanesApi.endpoints.downloadRFPTemplateCsv.useLazyQuery,
)

export const useDownloadRFPTemplateExcel = createFileDownloadHook(
  'rfp_import.xlsx',
  contractedLanesApi.endpoints.downloadRFPTemplateExcel.useLazyQuery,
)
