import { ContractModelTypes } from '../store/types'
import { ContractModelOption } from '@procurement-shared/NegotiationEventForm/sections/ContractModel/ContractModel'

export const NON_SAAS_MODEL_ID = 'NonSaaSForm'
export const CONTRACT_MODEL_PREFIX = 'contractModel.properties'

const commonContractProperties = [
  `${CONTRACT_MODEL_PREFIX}.buyerOrganisationLegalName`,
  `${CONTRACT_MODEL_PREFIX}.buyerOrganisationLegalAddress`,
  `${CONTRACT_MODEL_PREFIX}.buyerSignerName`,
  `${CONTRACT_MODEL_PREFIX}.buyerSignerEmail`,
  `${CONTRACT_MODEL_PREFIX}.riskAssessmentDone`,
  `${CONTRACT_MODEL_PREFIX}.itamRegistrationDone`,
]

export const CONTRACT_MODELS: ContractModelOption[] = [
  {
    id: NON_SAAS_MODEL_ID,
    title: 'Services',
    description: 'Negotiate price and payment terms.',
    properties: [],
    otherRequiredFields: [],
  },
  {
    id: ContractModelTypes.SignedOrderForm,
    title: 'SaaS order form',
    description:
      'New SaaS Order Form. Negotiating pricing, payment terms and SaaS Order Form. It can be used only for Low Risk SaaS (below $5 Million spend, no integration with Systems and no business critical).',
    properties: [
      ...commonContractProperties,
      `${CONTRACT_MODEL_PREFIX}.orderFormNumber`,
      `${CONTRACT_MODEL_PREFIX}.scopeOfServices`,
    ],
    otherRequiredFields: [
      'suppliers.0.negotiationSettings.contractStartDate',
      'suppliers.0.negotiationSettings.contractLength',
      'suppliers.0.initialPaymentTerms.paymentDays',
      'paymentDaysEnabled',
    ],
  },
  {
    id: ContractModelTypes.SignedOrderFormAmendment,
    title: 'Amendment',
    description:
      'Renewal. Renegotiate existing SaaS Order Form. It can be used only for Low Risk SaaS (below $5 Million spend, no integration with Systems and no business critical).',
    properties: [...commonContractProperties, `${CONTRACT_MODEL_PREFIX}.previousContractDate`],
    otherRequiredFields: [
      'suppliers.0.negotiationSettings.contractStartDate',
      'suppliers.0.negotiationSettings.contractLength',
      'suppliers.0.initialPaymentTerms.paymentDays',
      'paymentDaysEnabled',
    ],
  },
]

export const getContractById = (contractId?: string | null) =>
  CONTRACT_MODELS.find((contract) => contract.id === contractId)
