import React from 'react'
import { Box, Button, Grid, lighten, Stack, styled, Typography } from '@mui/material'
import { noNegotiationEvents } from '@components/Images'

type Props = {
  onOpenUploadDialog: (() => void) | undefined
}

export const SetupYourFirstNegotiation = ({ onOpenUploadDialog }: Props) => (
  <Box mt={12}>
    <Box textAlign='center'>
      <Typography variant='h1'>Aww, it's so empty here!</Typography>
    </Box>
    <Grid container justifyContent='center' alignItems='center'>
      <Grid item xs={8} pt={12}>
        <Stack direction='column' spacing={1.5} alignItems='center'>
          <Image
            sx={{ width: '50%' }}
            src={noNegotiationEvents as string}
            alt='No negotiation events in the system'
            loading='lazy'
          />
          {onOpenUploadDialog && (
            <>
              <SubTitle variant='body1' sx={{ mt: 3, color: 'text.secondary' }}>
                How about setting up a new negotiation in the system?
              </SubTitle>
              <UploadNegotiationEventButton onClick={onOpenUploadDialog}>
                Upload negotiations
              </UploadNegotiationEventButton>
            </>
          )}
        </Stack>
      </Grid>
    </Grid>
  </Box>
)

const Image = styled('img')({
  width: '100%',
  height: '100%',
})

const UploadNegotiationEventButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  flexShrink: 0,
  '&:hover': {
    backgroundColor: lighten(theme.palette.primary.main, 0.2),
  },
  '&:disabled': {
    backgroundColor: lighten(theme.palette.primary.main, 0.2),
  },
}))

const SubTitle = styled(Typography)({
  fontSize: '0.875rem',
  lineHeight: '1.5rem',
  fontWeight: 700,
})
