import { Navigate, useParams } from 'react-router-dom'

type LegacyPageRedirectToProps = {
  to: string
}

export const LegacyPageRedirectTo: React.FC<LegacyPageRedirectToProps> = ({ to }) => {
  const { negotiationEventId, orgTag, projectTag } = useParams()

  let redirectPath = to

  if (orgTag) {
    redirectPath = redirectPath.replace(':orgTag', orgTag)
  }

  if (projectTag) {
    redirectPath = redirectPath.replace(':projectTag', projectTag)
  }

  if (negotiationEventId) {
    redirectPath = redirectPath.replace(':negotiationEventId', negotiationEventId)
  }

  return <Navigate to={redirectPath} />
}
