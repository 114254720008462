import { EOMPaymentDaysValue } from './custom-payment-days/eom'
import { ISO2Language } from '../dto/languages'
import { ISO3Country } from '../dto/countries'

export type NegotiationStatus =
  | 'MISSING_DATA'
  | 'PROCESSING'
  | 'READY'
  | 'CONTACTED'
  | 'SENT'
  | 'STARTED'
  | 'STARTING_FAILED'
  | 'DEAL_REACHED'
  | 'NO_AGREEMENT'
  | 'SIGNED'
  | 'CANCELLED'

export interface CommercialTermsNegotiation {
  id: string
  status: NegotiationStatus
  createdAt: string
  linkOpenedAt: string | null
  completedAt: string | null
  sentOut: string | null
  lastStatusChange: string | null
  supplierId: string | null
  supplierCompanyName: string | null
  supplierRepresentativeName: string | null
  supplierEmail: string | null
  supplierPhone: string | null
  businessUnit: string | null
  language: ISO2Language | null
  country: ISO3Country | null
  contracts: Contract[]
  pastSpend: number | null
  pastSpendYear: number | null
  // only payment days for now
  forecastedSavingsInCurrency: number | null
  forecastedSavingsPercent: number
  forecastedCompletionDays: number
  achievedSavingsInCurrency: number | null
  achievedSavingsPercent: number | null
  readOnlyChatLink: string | null // chat links are missing when core negotiation has not been created
  restartableChatLink: string | null
  currency: string | null
  signatures: {
    docusignEnvelopeUrl: string | null
    docusignEnvelopeSigners: Signer[]
    manualSigner?: Omit<Signer, 'hasSigned'>
  }
  customFields: CustomFieldValues | null
  agreedGrowthProportionPercent: number | null
  // It's valid for supplier to select zero incentives, thus we differentiate empty array from null
  selectedIncentives: string[] | null
  fileUploads: FileUpload[] | null
  supplierFeedback: Feedback | null
  durationMin: number | null
}

export interface Feedback {
  score: number
  comment: string
}

export interface Contract {
  key: string
  standardTerms: StandardTerms
  customTerms: CustomTerms | null
  totalSavings: SavingsItem | null
  status?: ContractStatus
}

export type ContractStatus = 'IN_PROGRESS' | 'AGREED' | 'DECLINED' | 'DELEGATED'

export type CustomTerm = Term<number> & {
  label: string
}

export type CustomTerms = Record<string, CustomTerm>

export enum TermStage {
  previous = 'previous',
  agreed = 'agreed',
}
export interface Term<T> {
  previous: T | null
  agreed: T | null
  savings: SavingsItem | null
}

export interface StandardTerms {
  paymentDays: Term<number> | null
  earlyPaymentDiscount: Term<number> | null
  earlyPaymentDiscountDays: Term<number> | null
  eomPaymentDays?: Term<EOMPaymentDaysValue> | null
  growthRebateStructure: Term<GrowthRebatesTerm> | null
  flatRebate: Term<number> | null
  generalDiscount: Term<number> | null
}

export interface Signer {
  name: string
  email: string
  hasSigned: boolean
}

export type GrowthRebatesTerm = {
  tiers: GrowthRebateTier[]
  type: 'spend' | 'growth' | null
  displayType: 'currency' | 'percentage'
}

export type GrowthRebateTier = {
  min: number
  max?: number
  rebatePercent: number
}

export interface SavingsItem {
  savingsInCurrency: number
  savingsPercent: number
}

export type FileUpload = SucceededFileUpload | FailedFileUpload

export interface SucceededFileUpload {
  fileUploaded: true
  label: string
  description?: string
  negotiationFileId: number | null
  filename: string
  contentType: string
  contentLength: number
}

export interface FailedFileUpload {
  fileUploaded: false
  label: string
  description?: string
  failReason: string
}

export type CustomFieldValues = Record<string, CustomFieldValue>
export type CustomFieldValue = string | boolean | number | null
