import { GridColDef } from '@mui/x-data-grid-pro'
import { Formatter } from '@utils'
import { NegotiationPreparationWithInputs } from '@common/dto'
import { CaptionedValueCell } from 'src/shared/components/table'
import {
  CurrentAndPreviousValueCell,
  IncomeWithYearlyCell,
  PriceWithDateCell,
  UnitsSoldWithYoyChangeCell,
  ValueDifferenceCell,
} from './NegotiationConfigurationCells'
import {
  getCurrentTotalArticleProfit,
  getInputTotalArticleProfit,
  getSingleArticleProfit,
  getTotalArticleProfit,
} from './utils'

export const createColumns: (
  formatter: Formatter,
) => GridColDef<NegotiationPreparationWithInputs['articles'][0]>[] = (formatter) => [
  {
    field: 'name',
    headerName: 'Article',
    disableColumnMenu: true,
    filterable: false,
    resizable: false,
    flex: 3,
    renderCell: (params) => (
      <CaptionedValueCell mainContent={params.row.name} captionContent={`EAN: ${params.row.ean}`} />
    ),
  },
  {
    field: 'updateAt',
    headerName: 'Old PP',
    disableColumnMenu: true,
    filterable: false,
    flex: 1.1,
    valueGetter: (params) => params.row.rpp.current,
    renderCell: (params) => (
      <PriceWithDateCell
        price={params.row.rpp.current}
        date={params.row.previousRppChangeDate}
        datePrefixString={'From '}
      />
    ),
  },
  {
    field: 'proposedRPP',
    headerName: 'New Offered PP',
    disableColumnMenu: true,
    filterable: false,
    flex: 1.2,
    valueGetter: (params) => params.row.rpp.request,
    renderCell: (params) => (
      <PriceWithDateCell
        price={params.row.rpp.request}
        date={params.row.purchasePriceChangeDetails.newRppValidFromDate}
        datePrefixString={'From '}
      />
    ),
  },
  {
    field: 'rppChange',
    headerName: 'Change',
    disableColumnMenu: true,
    filterable: false,
    flex: 1,
    valueGetter: (params) => params.row.rpp.current - params.row.rpp.request,
    renderCell: (params) => (
      <ValueDifferenceCell
        previousValue={params.row.rpp.current}
        currentValue={params.row.rpp.request}
        invertColors
      />
    ),
  },
  {
    field: 'newRetailPrice',
    headerName: 'New Sales Price',
    disableColumnMenu: true,
    filterable: false,
    flex: 1.25,
    valueGetter: (params) => params.row.inputs.retailPrice.lac,
    renderCell: (params) => (
      <CurrentAndPreviousValueCell
        currentValue={params.row.inputs.retailPrice.lac}
        previousValue={params.row.retailPrice.current}
        captionPrefixString={'Previously '}
        options={{
          formatter: {
            mainContent: formatter.currency,
            captionContent: formatter.currency,
          },
        }}
      />
    ),
  },
  {
    field: 'newBillingMargin',
    headerName: 'New Billing Margin',
    disableColumnMenu: true,
    filterable: false,
    flex: 1.25,
    valueGetter: (params) => params.row.inputs.margin.lac,
    renderCell: (params) => (
      <CurrentAndPreviousValueCell
        currentValue={params.row.inputs.margin.lac}
        previousValue={params.row.marginPercentage.current}
        captionPrefixString={'Previously '}
        options={{
          formatter: {
            mainContent: formatter.percent0To1,
            captionContent: formatter.percent0To1,
          },
          invertColors: true,
        }}
      />
    ),
  },
  {
    field: 'unitsSold',
    headerName: 'Units sold, YoY',
    disableColumnMenu: true,
    filterable: false,
    flex: 1,
    valueGetter: (params) => params.row.volume?.lastYear,
    renderCell: (params) => (
      <UnitsSoldWithYoyChangeCell
        lastYearValue={params.row.volume?.lastYear}
        yearBeforeValue={params.row.volume?.yearBefore}
      />
    ),
  },
  {
    field: 'newRevenue',
    headerName: 'New Revenue, yearly',
    disableColumnMenu: true,
    filterable: false,
    flex: 1,
    valueGetter: (params) =>
      params.row.volume ? params.row.volume.lastYear * params.row.inputs.retailPrice.lac : 0,
    renderCell: (params) => (
      <IncomeWithYearlyCell
        newIncomePerArticle={params.row.inputs.retailPrice.lac}
        currentIncomePerArticle={params.row.retailPrice.current}
        lastYearVolume={params.row.volume?.lastYear}
      />
    ),
  },
  {
    field: 'newProfit',
    headerName: 'New Profit, yearly',
    disableColumnMenu: true,
    filterable: false,
    flex: 1,
    valueGetter: (params) =>
      getTotalArticleProfit({
        retailPrice: params.row.inputs.retailPrice.lac,
        rpp: params.row.inputs.rpp.lac,
        vat: params.row.vat,
        volume: params.row.volume?.lastYear,
      }) ?? 0,
    renderCell: (params) => (
      <IncomeWithYearlyCell
        newIncomePerArticle={getSingleArticleProfit({
          retailPrice: params.row.inputs.retailPrice.lac,
          rpp: params.row.inputs.rpp.lac,
          vat: params.row.vat,
        })}
        currentIncomePerArticle={getSingleArticleProfit({
          retailPrice: params.row.retailPrice.current,
          rpp: params.row.rpp.current,
          vat: params.row.vat,
        })}
        lastYearVolume={params.row.volume?.lastYear}
      />
    ),
  },
  {
    field: 'profitChange',
    headerName: 'Profit Change, YoY',
    disableColumnMenu: true,
    filterable: false,
    flex: 1,
    valueGetter: (params) => {
      const inputTotalArticleProfit = getInputTotalArticleProfit(params.row)
      const currentTotalArticleProfit = getCurrentTotalArticleProfit(params.row)
      if (inputTotalArticleProfit && currentTotalArticleProfit) {
        return inputTotalArticleProfit! - currentTotalArticleProfit!
      } else if (!inputTotalArticleProfit) {
        return undefined
      } else if (!currentTotalArticleProfit) {
        return inputTotalArticleProfit
      }
    },
    renderCell: (params) => (
      <ValueDifferenceCell
        previousValue={getCurrentTotalArticleProfit(params.row)}
        currentValue={getInputTotalArticleProfit(params.row)}
      />
    ),
  },
]
