import { Grid, Typography } from '@mui/material'
import { PurchasingUIConfig } from '@procurement/store/types'
import { FormRow } from '../../../FormCommon/FormCommon'
import { NumberFieldCtrl } from '@components/Form'
import { LineItems } from '@procurement-shared/NegotiationEventForm/sections/LineItems/LineItems'

interface Props {
  allowedIncoterms?: PurchasingUIConfig['suite']['allowedIncoterms']
  defaultIncoterm?: PurchasingUIConfig['suite']['defaultIncoterm']
  termsBasisOptions?: PurchasingUIConfig['suite']['termsBasisOptions']
  productType?: PurchasingUIConfig['suite']['productType']
  moqEnabled?: PurchasingUIConfig['suite']['moqEnabled']
  spendEnabled?: PurchasingUIConfig['suite']['spendEnabled']
}

export const LineItemsWithRebate = ({
  allowedIncoterms,
  defaultIncoterm,
  termsBasisOptions,
  productType,
  moqEnabled,
  spendEnabled,
}: Props) => {
  return (
    <>
      <LineItems
        productType={productType}
        allowedIncoterms={allowedIncoterms}
        defaultIncoterm={defaultIncoterm}
        termsBasisOptions={termsBasisOptions}
        moqEnabled={moqEnabled}
        spendEnabled={spendEnabled}
      />
      <FormRow>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography variant='subtitle2' fontSize='0.875rem'>
            Rebate
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <NumberFieldCtrl
            style={{ width: '100%' }}
            helperText='Rebate that has already been agreed with the supplier'
            variant='outlined'
            name={'suppliers.0.negotiationSettings.rebates.benchmark'}
            label={'Benchmark rebate (%)'}
          />
        </Grid>
        <Grid item xs={6}>
          <NumberFieldCtrl
            style={{ width: '100%' }}
            helperText='Deal will not be made unless we get at least this rebate'
            variant='outlined'
            name={'suppliers.0.negotiationSettings.rebates.minimum'}
            label={'Minimum acceptable rebate (%)'}
          />
        </Grid>
      </FormRow>
      <FormRow>
        <Grid item xs={6}>
          <NumberFieldCtrl
            style={{ width: '100%' }}
            helperText='This number needs to be greater than the minimum acceptable rebate'
            variant='outlined'
            name={'suppliers.0.negotiationSettings.rebates.target'}
            label={'Rebate I would like to get (%)'}
          />
        </Grid>
      </FormRow>
    </>
  )
}
