// Importing from product modules allowed here as an exception
/* eslint-disable import/no-restricted-paths */

import { SuiteModule } from './types/suite-module'
import { costDecreaseModule, merchandisingSuiteModule } from 'src/merchandising'
import { commercialTermsSuiteModule } from 'src/commercial-terms'
import { logisticsSuiteModule } from 'src/logistics'
import { purchasingSuiteModule } from 'src/procurement/legacy/purchasing'
import { contractCostSuiteModule } from 'src/procurement/legacy/contract-cost'
import { baselineModule } from '../suite'
import { procurementSuiteModule } from '../procurement'
import { priceListSuiteModule } from '../price-list'

export const SUITE_MODULES: SuiteModule[] = [
  merchandisingSuiteModule,
  costDecreaseModule,
  commercialTermsSuiteModule,
  logisticsSuiteModule,
  purchasingSuiteModule,
  contractCostSuiteModule,
  procurementSuiteModule,
  priceListSuiteModule,
  baselineModule,
]
