import { TextField, TextFieldProps } from '@mui/material'
import { FieldValues, RegisterOptions, useController } from 'react-hook-form'
import { errorProps, styleProps } from './sharedProps'
import { EMAIL_REGEX } from '@common/utils/validation'

type Rules =
  | Omit<
      RegisterOptions<FieldValues, string>,
      'setValueAs' | 'disabled' | 'valueAsNumber' | 'valueAsDate'
    >
  | undefined

export type TextFieldCtrlProps = TextFieldProps & {
  name: string
}

/** MUI TextField controlled by react-hook-form */
export const TextFieldCtrl = ({ name, required, ...rest }: TextFieldCtrlProps) => {
  let rules: Rules = { required }

  if (rest.type === 'email') {
    rules = { ...rules, pattern: { value: EMAIL_REGEX, message: 'Invalid email' } }
  }

  const {
    field,
    fieldState: { error },
  } = useController({ name, rules })

  return (
    <TextField
      {...styleProps}
      required={required}
      name={field.name}
      value={(field.value as string) ?? ''}
      onChange={field.onChange}
      onBlur={field.onBlur}
      inputRef={field.ref}
      {...rest}
      {...errorProps(error)}
    />
  )
}
