import { useEffect, useRef, useState } from 'react'
import { useImportCoupaRequisitionMutation } from '../../store/purchasing'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { FormDialog } from '@components/FormDialog'
import { Grid, Typography } from '@mui/material'
import { UseFormReturn } from 'react-hook-form'
import { isBackendApiErrorResponse } from '@shared/backend/error/typeGuards'
import { TextFieldCtrl } from '@components/Form'
import { ImportCoupaEntityFormData } from '@procurement/store/schema'

interface Props {
  open: boolean
  onClose: () => void
}

export const ImportCoupaRequisitionFormDialog = ({ open, onClose }: Props) => {
  const [loading, setLoading] = useState(false)
  const [generalFormError, setGeneralFormError] = useState('')
  const { activeProjectTag } = useActiveProject()
  const [importCoupaRequisition] = useImportCoupaRequisitionMutation()
  const errorRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (generalFormError) {
      errorRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [generalFormError, errorRef])

  const handleFormSubmit = async (
    data: ImportCoupaEntityFormData,
    form: UseFormReturn<ImportCoupaEntityFormData>,
  ) => {
    setLoading(true)
    setGeneralFormError('')

    await importCoupaRequisition({
      projectTag: activeProjectTag,
      requisitionId: data.id ?? 0,
    })
      .unwrap()
      .then(() => {
        form.reset()
        onClose()
      })
      .catch((err) => handleErrors(err))
      .finally(() => {
        setLoading(false)
      })
  }
  const handleErrors = (error: unknown) => {
    if (isBackendApiErrorResponse(error)) {
      setGeneralFormError(error.data.message)
    }
  }
  if (!open) {
    return null
  }

  return (
    <FormDialog
      open={open}
      loading={loading}
      title={'Import from Coupa'}
      error={generalFormError}
      errorRef={errorRef}
      buttons={[
        { type: 'cancel', label: 'Cancel' },
        { type: 'submit', label: 'Import' },
      ]}
      onSubmit={(data, form) => handleFormSubmit(data, form)}
      onCancel={(form) => {
        form.reset()
        onClose()
        setGeneralFormError('')
      }}
    >
      <Grid>
        <Typography my={1}>
          You can import requisitions directly from Coupa. Simply enter the Coupa requisition ID
          into the field below.
        </Typography>
        <Typography my={1}>Importing can take a few seconds to complete.</Typography>
        <TextFieldCtrl
          fullWidth
          type='number'
          name='id'
          placeholder='Coupa requisition ID'
          required
          margin='normal'
        />
      </Grid>
    </FormDialog>
  )
}
