import { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { Grid, InputAdornment, Typography } from '@mui/material'
import { NumberFieldCtrl } from '@components/Form'
import {
  createGetFieldControllerParams,
  FormRow,
  HideableBlock,
} from '@procurement/components/FormCommon/FormCommon'
import { getCurrencySymbol } from '@procurement/utils/currency'
import { NegotiationEventFormContext } from '../../NegotiationEventFormContext'
import { NegotiationEventFormData } from '../../schema'

const CurrencySign = ({ selectedCurrency }: { selectedCurrency?: string }) => {
  return <InputAdornment position='start'>{getCurrencySymbol(selectedCurrency)}</InputAdornment>
}

export const TotalMaxPrice = () => {
  const { watch } = useFormContext<NegotiationEventFormData>()
  const { requiredFields, visibleFields, disabledFields } = useContext(NegotiationEventFormContext)
  const getFieldControllerParams = createGetFieldControllerParams({
    requiredFields,
    disabledFields,
  })
  const currency = watch('currency')

  return (
    <HideableBlock
      hidden={!visibleFields.includes('suppliers.0.negotiationSettings.maxTotalPrice')}
    >
      <FormRow>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography variant='subtitle2' fontSize='0.875rem'>
            Set total price limit
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <NumberFieldCtrl
            helperText='We do not make a deal if the total price goes over this limit.'
            variant='outlined'
            InputProps={{
              startAdornment: <CurrencySign selectedCurrency={currency} />,
            }}
            {...getFieldControllerParams(
              'suppliers.0.negotiationSettings.maxTotalPrice',
              "Max price I'm willing to pay",
            )}
          />
        </Grid>
      </FormRow>
    </HideableBlock>
  )
}
