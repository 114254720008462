import { Chip, SxProps, Theme } from '@mui/material'
import { NegotiationStatus } from '@campaigns/types'
import { statusStyle, statusText } from 'src/commercial-terms/pages/HomePage/negotiationStatus'

interface Props {
  status: NegotiationStatus
  size?: 'small' | 'medium'
  sx?: SxProps<Theme>
}

export const StatusChip = ({ status, sx, size }: Props) => (
  <Chip
    label={statusText(status)}
    size={size ?? 'small'}
    sx={{ color: 'common.white', ...statusStyle(status), ...sx }}
  />
)
