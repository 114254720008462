import { SuitePermission } from '@common/types'
import { ProtectedRoute } from '@components/ProtectedRoute'
import { useProjects } from '@hooks'
import { LoadingPage } from '@pages/LoadingPage'
import { Fragment } from 'react'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import { AutomationSettingsPage } from './AutomationSettingsPage'
import { IncentivesPage } from './IncentivesSettingsPage'
import { NegotiationsSettingsPage } from './NegotiationsSettingsPage'
import { SectionNavItem, SectionNavMenu } from './SectionNavMenu'
import { ContentContainer, SettingsContainer } from './SettingsContainers'
import { VirtualWorkforceSettingsPage } from './VirtualWorkforceSettingsPage'
import { WorkspaceGeneralSettingsPage } from './WorkspaceGeneralSettingsPage'
import { Typography } from '@mui/material'

interface WorkspaceNavigationItem {
  to: string
  label: string
}

const workspaceNavigation: WorkspaceNavigationItem[] = [
  { to: 'automations', label: 'Automations' },
  { to: 'incentives', label: 'Incentives' },
  { to: 'negotiations', label: 'Negotiations' },
  { to: 'virtual-workforce', label: 'Virtual workforce' },
]

const WorkspaceRouter = ({
  isInternalUser,
  canManageConfig,
}: {
  isInternalUser: boolean
  canManageConfig: boolean
}) => {
  const { activeOrgTag, projects } = useProjects()

  const params = useParams<{ orgTag: string; workspaceTag: string }>()
  const enableSubmenu = false

  return (
    <>
      <SectionNavMenu>
        {projects.map((project) => (
          <Fragment key={project.tag}>
            <SectionNavItem
              to={`/${activeOrgTag}/settings/workspaces/${project.tag}`}
              end
              key={project.tag}
            >
              {project.name}
            </SectionNavItem>
            {enableSubmenu &&
              params.workspaceTag === project.tag &&
              workspaceNavigation.map((item) => (
                <SectionNavItem
                  submenu
                  to={`/${activeOrgTag}/settings/workspaces/${project.tag}/${item.to}`}
                  end
                  key={`${project.tag}-${item.to}`}
                >
                  {item.label}
                </SectionNavItem>
              ))}
          </Fragment>
        ))}
      </SectionNavMenu>
      <ContentContainer>
        <Routes>
          <Route
            path=''
            element={
              <ProtectedRoute anyOf={[SuitePermission.READ]}>
                <WorkspaceGeneralSettingsPage
                  isInternalUser={isInternalUser}
                  canManageConfig={canManageConfig}
                />
              </ProtectedRoute>
            }
          />

          <Route
            path='automations'
            element={
              <ProtectedRoute anyOf={[SuitePermission.READ]}>
                <AutomationSettingsPage />
              </ProtectedRoute>
            }
          />

          <Route
            path='incentives'
            element={
              <ProtectedRoute anyOf={[SuitePermission.READ]}>
                <IncentivesPage />
              </ProtectedRoute>
            }
          />

          <Route
            path='negotiations'
            element={
              <ProtectedRoute anyOf={[SuitePermission.READ]}>
                <NegotiationsSettingsPage />
              </ProtectedRoute>
            }
          />

          <Route
            path='virtual-workforce'
            element={
              <ProtectedRoute anyOf={[SuitePermission.READ]}>
                <VirtualWorkforceSettingsPage />
              </ProtectedRoute>
            }
          />
        </Routes>
      </ContentContainer>
    </>
  )
}

export const WorkspacesSettingsPage = ({
  isInternalUser,
  canManageConfig,
}: {
  isInternalUser: boolean
  canManageConfig: boolean
}) => {
  const { projects, loadingProjects } = useProjects()

  if (loadingProjects) {
    return <LoadingPage />
  }

  if (!projects.length) {
    return (
      <Typography variant='h2' style={{ textAlign: 'center', marginTop: 16 }}>
        No projects found
      </Typography>
    )
  }

  return (
    <SettingsContainer py={3}>
      <Routes>
        <Route path='' element={<Navigate to={`${projects?.[0]?.tag}`} />} />
        <Route
          path=':workspaceTag/*'
          element={
            <WorkspaceRouter canManageConfig={canManageConfig} isInternalUser={isInternalUser} />
          }
        />
      </Routes>
    </SettingsContainer>
  )
}
