import { eomXPaymentDaysConfig } from '@campaigns/types'
import z from 'zod'
import { ISO3Countries } from '@campaigns/dto/countries'
import { ISO2Languages } from '@campaigns/dto/languages'
import { CustomImportSchemaFieldWithExample } from '@common/types'
import { getCustomFieldName } from './custom/getCustomFieldName'
import { Currency } from '@campaigns/types'

const trimIfStringOrNull = (u: unknown) => {
  if (typeof u !== 'string') {
    return null
  }
  const trimmed = u.trim()
  return trimmed.length > 0 ? trimmed : null
}

const customValidateNumber = (value: unknown, predicate: (value: number) => boolean): boolean => {
  if (!value || typeof value !== 'number') {
    return true // No validation
  }
  return predicate(value)
}

function zodEnumFromObjKeys<K extends string, T>(obj: Record<K, T>): z.ZodEnum<[K, ...K[]]> {
  const [firstKey, ...otherKeys] = Object.keys(obj) as K[]
  return z.enum([firstKey, ...otherKeys])
}

const zodBaseFieldsDef = {
  supplierCompanyName: z.preprocess(trimIfStringOrNull, z.string().nonempty()),
  organisationName: z.preprocess(trimIfStringOrNull, z.string().nullable().default(null)),
  supplierRepresentativePhone: z.preprocess(
    trimIfStringOrNull,
    z.string().nullable().default(null),
  ),
  supplierId: z.preprocess(trimIfStringOrNull, z.string().nullable().default(null)),
  supplierRepresentativeName: z.preprocess(trimIfStringOrNull, z.string().nullable().default(null)),
  supplierRepresentativeEmail: z.preprocess(
    trimIfStringOrNull,
    z.string().email().nullable().default(null),
  ),
  pastContractPeriod: z.number().int().nullable().default(null),
  pastContractValue: z.union([z.number().min(0).nullable().default(null), z.nan()]).optional(),
  currency: z.nativeEnum(Currency).nullable().default(null),
  country: zodEnumFromObjKeys(ISO3Countries).nullable().default(null),
  language: zodEnumFromObjKeys(ISO2Languages).nullable().default(null),
  pastContractDiscount: z
    .union([
      z
        .number()
        .nullable()
        .default(null)
        .refine(
          (value) => customValidateNumber(value, (value) => value >= 0 && value <= 100),
          'Discount % must be between 0 and 100',
        ),
      z.nan(),
    ])
    .optional(),
  pastContractDiscountDays: z
    .union([
      z
        .number()
        .min(0)
        .nullable()
        .default(null)
        .refine(
          (value) => customValidateNumber(value, (value) => value >= 0),
          'Discount days must be greater than or equal to 0',
        ),
      z.nan(),
    ])
    .optional(),
  pastContractPaymentDays: z
    .union([
      z
        .number()
        .min(0)
        .nullable()
        .default(null)
        .refine(
          (value) => customValidateNumber(value, (value) => value >= 0),
          'Payment days must be greater than or equal to 0',
        ),
      z.nan(),
    ])
    .optional(),
  eomPaymentDays: z.preprocess(
    trimIfStringOrNull,
    z
      .string()
      .optional()
      .nullable()
      .refine((value) => {
        if (!value) {
          return true
        }
        return !eomXPaymentDaysConfig.template.validator(value)
      }, 'EOM Payment days must be in format of X EOM Y')
      .default(null),
  ),
}

const baseFieldsSchema = z.object(zodBaseFieldsDef)
export type NegotiationBaseFormSchema = z.infer<typeof baseFieldsSchema>

// I would instead do z.intersection(z.object, z.record) here, but Zod does not support it 🥲 https://github.com/colinhacks/zod/issues/2195
export const getNegotiationSchema = (customFields: CustomImportSchemaFieldWithExample[]) => {
  const fieldTypeToZodValidatorGetterMap = {
    BOOL: () => z.boolean().nullable().default(null),
    STRING: () => z.preprocess(trimIfStringOrNull, z.string().nullable().default(null)),
    NUMBER: () => z.union([z.nan(), z.number().nullable().default(null)]),
  }

  const zodCustomFieldsDef = customFields.reduce((acc, field) => {
    return {
      ...acc,
      [getCustomFieldName(field)]: fieldTypeToZodValidatorGetterMap[field.type](),
    }
  }, {})

  return z.object({ ...zodBaseFieldsDef, ...zodCustomFieldsDef }).refine(
    (data) => {
      if (!data.pastContractPaymentDays || !data.pastContractDiscountDays) {
        return true
      }
      return data.pastContractPaymentDays >= data.pastContractDiscountDays
    },
    {
      message: 'Discount days must be less or equal than payment days',
      path: ['pastContractDiscountDays'],
    },
  )
}
