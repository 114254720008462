import { Chip } from '@mui/material'
import { Timer } from '@components/Timer'
import {
  getChipForNegotiationStatus,
  getChipForUnitStatus,
} from '@logistics/pages/spotLoad/constants'
import { SpotLoadUnit } from '@logistics/pages/spotLoad/types'

export interface UnitStatusCellProps {
  unit: SpotLoadUnit
  supplierExternalId?: string
}

export const UnitStatusCell = ({ unit, supplierExternalId }: UnitStatusCellProps) => {
  const expiresAt = new Date(unit.expires_at)
  const createdAt = new Date(unit.created_at)

  const negotiation = unit.negotiations.find(
    (negotiation) => negotiation.supplier_id === supplierExternalId,
  )
  if (negotiation) {
    return <Chip size='small' {...getChipForNegotiationStatus(negotiation.status)} />
  }

  if (unit.status === 'IN_PROGRESS') {
    return (
      <Timer
        absoluteStartTime={createdAt}
        absoluteEndTime={expiresAt}
        colorSwitchDisabled={false}
        label='until expired'
      />
    )
  }

  return <Chip size='small' {...getChipForUnitStatus(unit.status)} />
}
