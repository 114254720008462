import { useFormatter } from '@shared/hooks'
import { useMemo } from 'react'
import { Box, styled, Typography } from '@mui/material'
import { DataGrid, dataGridProps } from '@components/table'
import { SpotLaneLaneNegotiationDto } from '@logistics/pages/spotLane/types'
import { createLaneNegotiationColumns } from '@logistics/pages/spotLane/ActiveLanes/laneNegotiationColumns'

export const LaneNegotiationsList = ({
  negotiations,
}: {
  negotiations: SpotLaneLaneNegotiationDto[]
}) => {
  const formatter = useFormatter()
  const columns = useMemo(() => createLaneNegotiationColumns(formatter), [formatter])

  return (
    <Box px={6} pb={1}>
      <Separator py={1} />
      <Typography variant='h2' pb={2}>
        Negotiations
      </Typography>
      <DataGrid
        {...dataGridProps}
        slots={{ ...dataGridProps.slots }}
        autoHeight={false}
        columns={columns}
        rows={negotiations}
        getRowId={(row: SpotLaneLaneNegotiationDto) => row.negotiation.id}
      />
      <Separator py={1} />
    </Box>
  )
}

const Separator = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '1px',
  borderTop: `1px solid ${theme.palette.borders.main}`,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}))
