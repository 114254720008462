import { ArrowDropDown } from '@mui/icons-material'
import { FormControl, styled } from '@mui/material'
import {
  DateRangePickerProps,
  DateRangePicker,
  SingleInputDateRangeField,
} from '@mui/x-date-pickers-pro'

export const DateFilterSelect = ({
  label,
  ...datePickerProps
}: {
  label: string
} & DateRangePickerProps<unknown>) => (
  <FormControl fullWidth size='small'>
    <StyledDateRangePicker
      {...datePickerProps}
      slots={{ field: SingleInputDateRangeField }}
      slotProps={{
        textField: {
          size: 'small',
          label,
          InputProps: {
            sx: { borderRadius: 2 },
            endAdornment: <ArrowDropDown sx={{ fill: '#747474' }} />,
          },
        },
        actionBar: {
          actions: ['clear'],
        },
      }}
      sx={{ cursor: 'pointer', minWidth: '300px' }}
      format='PP'
    />
  </FormControl>
)
const StyledDateRangePicker = styled(DateRangePicker)({
  border: 'none',
  borderRadius: 8,
  padding: 0,
}) as typeof DateRangePicker
