import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useValidationErrorEffect } from '@components/ValidationErrors'
import { useSnackbarSuccessEffect } from '@shared/hooks/useSnackbarSuccessEffect'
import { useRef } from 'react'
import { useImportNegotiationEventsMutation } from '../../store/price-list'
import { NegotiationsFileUpload } from '../NegotiationsFileUpload/NegotiationsFileUpload'

const MIME_TYPE_XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

type Props = {
  onFinishUpload: () => void
}

export const NegotiationEventsImport = ({ onFinishUpload }: Props) => {
  const { activeProjectTag } = useActiveProject()
  const activeProjectTagRef = useRef(activeProjectTag)
  const [importNegotiationEvents, { isLoading, isSuccess, error }] =
    useImportNegotiationEventsMutation()

  useSnackbarSuccessEffect(isSuccess, 'Negotiation events imported successfully!')
  useValidationErrorEffect(
    error,
    'Error occurred while importing negotiation events. Please try again later.',
  )

  return (
    <NegotiationsFileUpload
      callback={(file) => {
        importNegotiationEvents({ projectTag: activeProjectTagRef.current, file })
          .then(onFinishUpload)
          .catch(onFinishUpload)
      }}
      isLoading={isLoading}
      fileTypes={[MIME_TYPE_XLSX]}
    />
  )
}
