import { Formatter } from '@utils'
import { Box, Typography } from '@mui/material'
import { NegotiationEvent } from '../../store/types'
import { formatDate } from '../../utils/formatting'

export const ProgressCaption = ({
  negotiationEvent,
  formatter,
}: {
  negotiationEvent: NegotiationEvent
  formatter: Formatter
}) => {
  if (negotiationEvent.supplier?.negotiation?.concludedAt) {
    return (
      <Box component='span'>
        <Typography variant='caption' color='textSecondary'>
          Completed {formatDate(negotiationEvent.supplier?.negotiation?.concludedAt, formatter)}
        </Typography>
      </Box>
    )
  }

  if (negotiationEvent.startedAt) {
    return (
      <Box component='span'>
        <Typography variant='caption' color='textSecondary'>
          {`Started ${formatDate(negotiationEvent.startedAt, formatter)}, `}
        </Typography>
        <Typography variant='caption' color='error'>
          deadline {formatDate(negotiationEvent.deadline, formatter)}
        </Typography>
      </Box>
    )
  }

  return (
    <Box component='span'>
      <Typography variant='caption' color='textSecondary'>
        {`Created ${formatDate(negotiationEvent.createdAt, formatter)}, `}
      </Typography>
      <Typography variant='caption' color='error'>
        deadline {formatDate(negotiationEvent.deadline, formatter)}
      </Typography>
    </Box>
  )
}
