import { User as Auth0User } from '@auth0/auth0-spa-js'
import { User as OrgUser, UserRole, UserRoleDisplayName } from '@common/types/suite'
import { CELL_X_PADDING } from '@components/table/DataGrid'
import { GridColDef } from '@mui/x-data-grid-pro'
import { OrganizationMemberProjectsAccess } from '@pactum/core-backend-types'
import { ListItemActions } from './ListItemActions'
import { ListItemUserStatus } from './ListItemUserStatus'

const ACTIONS_BUTTON_WIDTH = 160
const ACTION_COLUMN_WIDTH = ACTIONS_BUTTON_WIDTH + 2 * CELL_X_PADDING

export type ProjectIdToNameMap = Record<number, string>

export const createColumns = (
  currentUser: Auth0User | null,
  projects: ProjectIdToNameMap,
  refetchUsers: () => void,
): GridColDef<OrgUser>[] => {
  const commonColumnParameters = {
    disableColumnMenu: true,
    filterable: true,
    resizable: true,
  }

  return [
    {
      ...commonColumnParameters,
      field: 'name',
      headerName: 'User',
      flex: 4,
      editable: true,
      renderCell: ({ row }) => row.name,
    },
    {
      ...commonColumnParameters,
      field: 'email',
      headerName: 'Email',
      flex: 4,
      editable: true,
      renderCell: ({ row }) => row.email,
    },
    {
      ...commonColumnParameters,
      field: 'projectIdsAccessList',
      headerName: 'Workspaces',
      flex: 4,
      renderCell: ({ row }) => {
        const workspaces = row.projectIdsAccessList as OrganizationMemberProjectsAccess
        if (workspaces === null) {
          return 'All'
        } else {
          const workspaceNames = workspaces
            .map((workspace) => projects[workspace])
            .filter((workspace) => workspace !== undefined)
          return workspaceNames.join(', ')
        }
      },
      valueFormatter: ({ value }: { value: number[] }) => {
        if (value === null) {
          return 'ALL'
        }

        return value
          .map((ws) => projects[ws])
          .filter((workspace) => workspace !== undefined)
          .join(', ')
      },
    },
    {
      ...commonColumnParameters,
      field: 'role',
      headerName: 'Role',
      flex: 1.2,
      editable: true,
      renderCell: ({ row }) => UserRoleDisplayName[row.role as UserRole],
    },
    {
      ...commonColumnParameters,
      field: 'status',
      headerName: 'Status',
      flex: 1.5,
      renderCell: (cell) => <ListItemUserStatus status={cell.row.status} />,
    },
    {
      ...commonColumnParameters,
      field: 'actions',
      headerName: 'Actions',
      width: ACTION_COLUMN_WIDTH,
      renderCell: (cell) => {
        if (currentUser !== null && currentUser.email !== cell.row.email) {
          return (
            <ListItemActions
              user={cell.row}
              actionsMenuMinWidth={ACTIONS_BUTTON_WIDTH}
              refetchUsers={refetchUsers}
            />
          )
        }
      },
    },
  ]
}
