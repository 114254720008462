import { differenceInSeconds, secondsInMinute, secondsInHour, minutesInHour } from 'date-fns'

export const formatTime = (timeInSeconds: number): string => {
  const seconds = formatTimeUnit(timeInSeconds % secondsInMinute)
  const minutes = formatTimeUnit(Math.floor((timeInSeconds / secondsInMinute) % minutesInHour))
  const hours = formatTimeUnit(Math.floor(timeInSeconds / secondsInHour))

  return `${hours}:${minutes}:${seconds}`
}

export const getTimeDiffInSeconds = (
  date1: Date,
  date2: Date = new Date(),
  roundingMethod: 'floor' | 'ceil' | 'trunc' = 'floor',
): number => {
  return differenceInSeconds(date1, date2, {
    roundingMethod,
  })
}

export function formatTimeUnit(amount: number): string {
  return String(amount).padStart(2, '0')
}
