import {
  CreateNegotiationEventSupplierDto,
  isPaymentDaysObject,
  NegotiationEventSupplier,
  UpdateNegotiationEventSupplierDto,
} from '@procurement/store/types'
import { getPaymentDaysNumericValue } from '@procurement/utils/paymentDays'
import { NegotiationEventSupplierFormData } from './schema'

export function mapToCreateNegotiationEventSupplierDto(
  form: NegotiationEventSupplierFormData,
): CreateNegotiationEventSupplierDto {
  return {
    contactName: form.contactName,
    email: form.email,
    externalId: form.externalId,
    initialPaymentTerms: {
      discount:
        typeof form.discount === 'number' && !Number.isNaN(form.discount)
          ? form.discount / 100
          : null,
      discountDaysObject: isPaymentDaysObject(form.discountDays) ? form.discountDays : null,
      paymentDaysObject: isPaymentDaysObject(form.paymentDays) ? form.paymentDays : null,
    },
    locale: form.locale || null,
    name: form.name,
    phone: form.phone || null,
  }
}

export function mapToUpdateNegotiationEventSupplierDto(
  form: NegotiationEventSupplierFormData,
  negotiationEventSupplier: NegotiationEventSupplier,
): UpdateNegotiationEventSupplierDto {
  let lineItems = undefined

  if (form.lineItems.length) {
    lineItems = form.lineItems.map((lineItem) => ({
      incoterm: lineItem.incoterm,
      numberInCollection: lineItem.numberInCollection,
      quantity: lineItem.quantity,
      unitPrice: lineItem.quotedUnitPrice ?? undefined,
    }))
  }

  return {
    contactName: form.contactName,
    email: form.email,
    externalId: form.externalId,
    initialPaymentTerms: {
      discount:
        typeof form.discount === 'number' && !Number.isNaN(form.discount)
          ? form.discount / 100
          : null,
      discountDaysObject: isPaymentDaysObject(form.discountDays) ? form.discountDays : null,
      paymentDaysObject: isPaymentDaysObject(form.paymentDays) ? form.paymentDays : null,
    },
    locale: form.locale || null,
    lineItems,
    name: form.name,
    negotiationSettings: {
      ...negotiationEventSupplier.negotiationSettings,
      minPaymentDays: form.negotiationSettings?.minPaymentDays
        ? getPaymentDaysNumericValue(form.negotiationSettings?.minPaymentDays)
        : undefined,
      maxPaymentDays: form.negotiationSettings?.maxPaymentDays
        ? getPaymentDaysNumericValue(form.negotiationSettings?.maxPaymentDays)
        : undefined,
      maxTotalPrice: form.negotiationSettings?.maxTotalPrice ?? undefined,
      totalPriceTarget: form.negotiationSettings?.totalPriceTarget ?? undefined,
      incentives: form.negotiationSettings?.incentives ?? [],
    },
    phone: form.phone || null,
  }
}
