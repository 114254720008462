import { useContext } from 'react'
import { Grid } from '@mui/material'
import { NumberFieldCtrl, SelectCtrl, TextFieldCtrl } from '@components/Form'

import { ProductType } from '@pactum/core-backend-types'
import { labelForProduct } from '../../../utils/labelForProduct'
import { NegotiationEventFormContext } from '../NegotiationEventFormContext'
import {
  createGetFieldControllerParams,
  FormRow,
  FormSectionTitle,
  HideableBlock,
} from '../../FormCommon/FormCommon'

interface Props {
  productType?: ProductType
  usingStandardSequences?: boolean
  categoryOptions: string[]
}

export const PurchaseDetails = ({
  productType,
  usingStandardSequences,
  categoryOptions,
}: Props) => {
  const { visibleFields, requiredFields, disabledFields } = useContext(NegotiationEventFormContext)
  const getFieldControllerParams = createGetFieldControllerParams({
    requiredFields,
    disabledFields,
  })

  const isNotPurchasingProduct = productType !== ProductType.Purchasing

  return (
    <>
      <HideableBlock
        hidden={!visibleFields.includes('purchaseDetailsTitle') && isNotPurchasingProduct}
      >
        <FormSectionTitle>
          {labelForProduct(productType, 'negotiationEventFormDetailsTitle')}
        </FormSectionTitle>
      </HideableBlock>
      <FormRow>
        <HideableBlock hidden={!visibleFields.includes('title') && isNotPurchasingProduct}>
          <Grid item xs={12}>
            <TextFieldCtrl
              helperText={labelForProduct(productType, 'negotiationEventFormDescriptionHelperText')}
              fullWidth
              {...getFieldControllerParams('title', 'Description')}
            />
          </Grid>
        </HideableBlock>
      </FormRow>
      <FormRow>
        <HideableBlock hidden={!visibleFields.includes('externalId') && isNotPurchasingProduct}>
          <Grid item xs={6}>
            <TextFieldCtrl
              helperText={labelForProduct(productType, 'negotiationEventFormReferenceIdHelperText')}
              fullWidth
              {...getFieldControllerParams('externalId', 'Reference ID')}
            />
          </Grid>
        </HideableBlock>
        <HideableBlock hidden={isNotPurchasingProduct}>
          <Grid item xs={6}>
            <TextFieldCtrl
              helperText={'Supplier quote ID'}
              fullWidth
              {...getFieldControllerParams('quoteId', 'Quote ID')}
            />
          </Grid>
        </HideableBlock>
        <HideableBlock hidden={!visibleFields.includes('category') && isNotPurchasingProduct}>
          <Grid item xs={6}>
            {categoryOptions.length === 0 && (
              <TextFieldCtrl
                helperText='Category or code'
                fullWidth
                {...getFieldControllerParams('category', 'Category')}
              />
            )}
            {categoryOptions.length > 0 && (
              <SelectCtrl
                helperText='Category or code'
                fullWidth
                variant='outlined'
                options={categoryOptions.map((option) => ({ label: option, value: option }))}
                {...getFieldControllerParams('category', 'Category')}
              />
            )}
          </Grid>
        </HideableBlock>
        <HideableBlock hidden={!visibleFields.includes('buyer') && isNotPurchasingProduct}>
          <Grid item xs={6}>
            <TextFieldCtrl
              helperText='Name of the person managing the negotiation'
              fullWidth
              {...getFieldControllerParams('buyerContactName', 'Buyer name')}
            />
          </Grid>
          <Grid item xs={6}>
            <TextFieldCtrl
              helperText='The email of the person managing the negotiation'
              fullWidth
              {...getFieldControllerParams('buyerContactEmail', 'Buyer email')}
            />
          </Grid>
        </HideableBlock>
        <HideableBlock
          hidden={!visibleFields.includes('negotiationsExpireDays') && isNotPurchasingProduct}
        >
          <Grid item xs={6}>
            {!usingStandardSequences && (
              <SelectCtrl
                helperText='Deadline for negotiations (in business days)'
                variant='outlined'
                options={[
                  { label: '2 days', value: 2 },
                  { label: '3 days', value: 3 },
                  { label: '4 days', value: 4 },
                  { label: '5 days', value: 5 },
                ]}
                {...getFieldControllerParams('negotiationsExpireDays', 'Deadline')}
              />
            )}

            {usingStandardSequences && (
              <NumberFieldCtrl
                min={0.5}
                max={50}
                variant='outlined'
                helperText={'Number of days (up to 50 days) in which the results are needed.'}
                fullWidth
                {...getFieldControllerParams(
                  'negotiationsExpireDays',
                  'Deadline for negotiations (in business days)',
                )}
              />
            )}
          </Grid>
        </HideableBlock>
      </FormRow>
    </>
  )
}
