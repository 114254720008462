import { createApi } from '@reduxjs/toolkit/query/react'
import { purchasingApi } from '../../store/purchasing'
import { contractCostEntrypoint } from './entrypoint'
import { ProductType, ProjectListEntity } from '@pactum/core-backend-types'
import { contractCostNavigationItems, ContractCostRoutes } from './routes'
import { GenericSuiteModule } from '../../../main/types/suite-module'
import { renderProjectAsBaselineModule } from '@shared/projectsSpecific'
import { SuitePermission } from '@common/types'

export const contractCostSuiteModule: GenericSuiteModule = {
  name: 'contract-cost',
  api: purchasingApi as unknown as ReturnType<typeof createApi>,
  entrypoint: contractCostEntrypoint,
  basePath: 'contract-cost',
  projectSwitchPath: (userPermissions?: Set<SuitePermission>) =>
    userPermissions?.has(SuitePermission.MODIFY)
      ? ContractCostRoutes.REQUISITION_LIST
      : ContractCostRoutes.DASHBOARD,
  isProjectCompatible: (project: ProjectListEntity) =>
    !renderProjectAsBaselineModule(project) &&
    project.compatibleWithNegotiationSuite &&
    project.productType === ProductType.ContractCost,
  navigationItems: contractCostNavigationItems,
}
