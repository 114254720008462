import { Alert, Link, styled } from '@mui/material'

export const SelectAllNotification = ({
  show,
  selectedNegotiationsCount,
  allNegotiationsCount,
  onSelectAll,
  onClearSelection,
  onClose,
}: {
  show: boolean
  selectedNegotiationsCount: number
  allNegotiationsCount: number
  onSelectAll: () => void
  onClearSelection: () => void
  onClose: () => void
}) => {
  if (!show) {
    return null
  }
  if (selectedNegotiationsCount === allNegotiationsCount) {
    return (
      <SelectionAlert severity='info' icon={false} onClose={onClose}>
        All {selectedNegotiationsCount} negotiations selected.{' '}
        <Link
          onClick={onClearSelection}
          underline='always'
          sx={{
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
        >
          Clear selection
        </Link>
      </SelectionAlert>
    )
  }
  return (
    <SelectionAlert severity='info' icon={false} onClose={onClose}>
      All {selectedNegotiationsCount} negotiations on this page selected.{' '}
      <Link
        onClick={onSelectAll}
        underline='always'
        sx={{
          cursor: 'pointer',
          textDecoration: 'underline',
        }}
      >
        Select all {allNegotiationsCount} negotiations
      </Link>{' '}
      or{' '}
      <Link
        onClick={onClearSelection}
        underline='always'
        sx={{
          cursor: 'pointer',
          textDecoration: 'underline',
        }}
      >
        clear selection
      </Link>
    </SelectionAlert>
  )
}

const SelectionAlert = styled(Alert)(({ theme }) => ({
  width: '100%',
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.text.primary,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#EEEEEE', // needs to be under theme
  '& .MuiIconButton-root': {
    display: 'block',
    textAlign: 'center',
  },
}))
