import { TableColDef } from './RowDetailsPanel'
import { RowValue } from 'src/merchandising/types'
import { equalOrGreaterThanZero, greaterThanZero } from './validation.utils'

export const columns: TableColDef[] = [
  {
    key: 'invoice_prices',
    editable: true,
    unit: 'currency',
    validations: {
      laa: greaterThanZero,
      target: greaterThanZero,
    },
  },
  {
    key: 'ipr',
    editable: false,
    unit: '%',
  },
  {
    key: 'net_prices',
    editable: true,
    unit: 'currency',
    validations: {
      laa: greaterThanZero,
      target: greaterThanZero,
    },
  },
  {
    key: 'volumes',
    editable: false,
    unit: '',
    validations: {
      laa: equalOrGreaterThanZero,
      target: greaterThanZero,
    },
  },
  {
    key: 'gtd',
    editable: true,
    unit: 'currency',
    validations: {
      laa: equalOrGreaterThanZero,
      target: equalOrGreaterThanZero,
    },
  },
  {
    key: 'gmf',
    editable: true,
    unit: 'currency',
    validations: {
      laa: equalOrGreaterThanZero,
      target: equalOrGreaterThanZero,
    },
  },
  {
    key: 'ctd',
    editable: true,
    unit: 'currency',
    validations: {
      laa: equalOrGreaterThanZero,
      target: equalOrGreaterThanZero,
    },
  },
  {
    key: 'cmf',
    editable: true,
    unit: 'currency',
    validations: {
      laa: equalOrGreaterThanZero,
      target: equalOrGreaterThanZero,
    },
  },
]

export const rowDetailsMap: { key: keyof RowValue; name: string; tooltipText: string }[] = [
  {
    key: 'erfq',
    name: 'eRFQ',
    tooltipText: 'Previously submitted value by supplier for Electronic Request For Quotation',
  },
  {
    key: 'preliminary',
    name: 'Global Pricing outcome',
    tooltipText: 'Agreement in Global Pricing negotiation',
  },
  {
    key: 'last_quarter',
    name: 'Last quarter',
    tooltipText:
      'Values from the previous quarter (when this item was most recently purchased):\n' +
      'Last quarter invoice price-per-item is calculated based on spend and quantity. ' +
      'Spend is calculated using POs, Invoice Prices, also accounting for Free-of-charge items. ' +
      'Free-of-charge POs are not included. ' +
      'The spend can then be divided by the quantity to get the "effective invoice price."',
  },
  {
    key: 'predict',
    name: 'Prediction',
    tooltipText: 'Predicted value in the upcoming quarter based on historical trends',
  },
  {
    key: 'batna',
    name: 'LAA',
    tooltipText: 'Least Acceptable Agreement value based on supplier data',
  },
  {
    key: 'stretch',
    name: 'Stretch',
    tooltipText:
      'Stretch values represent ambitious, yet theoretically achievable, set of targets that go beyond standard or expected outcomes.',
  },
  {
    key: 'bau',
    name: 'BAU',
    tooltipText:
      'Business As Usual represent forecast values that are rooted in standard ' +
      'operating conditions and historical performance data, aggregated across different markets. In contrast to Stretch' +
      ' targets, BAU forecasts do not anticipate exceptional performance or strategic changes but rather provide a ' +
      'stable and predictable baseline that reflects the expected outcomes under normal business conditions.',
  },
]
