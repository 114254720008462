import { styled } from '@mui/material'
import { PropsWithChildren } from 'react'
import { NavLink } from 'react-router-dom'

interface SectionNavMenuProps {}

const NavMenu = styled('ul')(() => ({
  listStyle: 'none',
  margin: 0,
  padding: 0,
  width: '100%',
  maxWidth: '280px',
}))

const NavMenuItem = styled('li')(() => ({}))

const RootMenuNavLink = styled(NavLink)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: theme.typography.fontWeightBold,
  textDecoration: 'none',
  transition: theme.transitions.create('color'),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingBottom: '2px',
  display: 'block',
  fontSize: '14px',
  padding: theme.spacing(1),
  '&:hover': {
    color: theme.palette.text.secondary,
  },
  '&.active': {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
  },
}))

const SubMenuNavLink = styled(NavLink)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textDecoration: 'none',
  transition: theme.transitions.create('color'),
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  display: 'block',
  fontSize: '14px',
  '&:hover': {
    color: theme.palette.text.secondary,
  },
  '&.active': {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
  },
}))

interface NavItemProps {
  to: string
  end?: boolean
  submenu?: boolean
}

export const SectionNavItem = ({ submenu, ...props }: NavItemProps & PropsWithChildren) => {
  const Link = submenu ? SubMenuNavLink : RootMenuNavLink
  return (
    <NavMenuItem>
      <Link {...props} />
    </NavMenuItem>
  )
}

export const SectionNavMenu = ({ children }: SectionNavMenuProps & PropsWithChildren) => {
  return <NavMenu>{children}</NavMenu>
}
