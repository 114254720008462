import { ContextValues, ItemData, TargetAndLacValues } from '@common/types'
import { TableColDef } from './RowDetailsPanel'
import { getNumberFormatter } from '@utils'
import { FlatRow, InputErrors } from './types'
import { columns } from './constants'
import { getCurrencySymbol } from '@utils'

export const formatValue = (
  column: TableColDef,
  value?: number | null,
  currency: string = 'EUR',
) => {
  let fractionDigits = 0

  if (!value && value !== 0) {
    return null
  }

  let formattedValue = value

  if (column.unit === '%') {
    formattedValue *= 100
  }

  if (!Number.isInteger(formattedValue)) {
    fractionDigits = 2
  }

  const formatter = getNumberFormatter(fractionDigits)

  const unit = column.unit === 'currency' ? getCurrencySymbol(currency) : column.unit
  return `${formatter.format(formattedValue)}${unit}`
}

export const shouldShowNA = (
  columnKey: TableColDef['key'],
  rowKey: keyof TargetAndLacValues | keyof ContextValues,
): boolean => {
  if (
    (columnKey === 'invoice_prices' || columnKey === 'ipr') &&
    (rowKey === 'stretch' || rowKey === 'bau')
  ) {
    return true
  }

  if (columnKey === 'volumes' && rowKey === 'erfq') {
    return true
  }

  const notAvailableColkeys: (typeof columnKey)[] = ['gtd', 'gmf', 'ctd', 'cmf']
  const notAvailableRowKeys: (typeof rowKey)[] = ['predict', 'batna', 'stretch', 'bau']

  return notAvailableColkeys.includes(columnKey) && notAvailableRowKeys.includes(rowKey)
}

export const isItemInError = (item: ItemData): boolean => {
  const flatItem = { ...item, ...item.benefits }
  const targetErrors = checkErrors(flatItem, 'target')
  const laaErrors = checkErrors(flatItem, 'lac')
  return [...Object.values(targetErrors), ...Object.values(laaErrors)].some(
    (errors) => errors.isError,
  )
}

export const checkErrors = (row: FlatRow, type: 'target' | 'lac'): InputErrors =>
  columns.reduce(
    (inputErrors, column) => ({
      ...inputErrors,
      [column.key]: column.validations
        ? column.validations[type === 'lac' ? 'laa' : 'target'](row[column.key][type]) // need to map between lac in dto and renamed to laa in here
        : { isError: false },
    }),
    {} as InputErrors,
  )
