import { styled } from '@mui/material'
import { PropsWithChildren } from 'react'
import { NavLink } from 'react-router-dom'
import { SettingsPageContainer } from './SettingsPageContainer'

const NavMenu = styled('ul')(({ theme }) => ({
  display: 'flex',
  listStyle: 'none',
  margin: 0,
  marginTop: theme.spacing(4),
  padding: 0,
  '& > li': {
    marginRight: theme.spacing(0),
  },
}))

const NavMenuItem = styled('li')(() => ({}))

const MenuNavLink = styled(NavLink)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: theme.typography.fontWeightBold,
  textDecoration: 'none',
  transition: theme.transitions.create('color'),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingBottom: '2px',
  display: 'block',
  '&:hover': {
    color: theme.palette.text.secondary,
  },
  '&.active': {
    color: theme.palette.text.secondary,
    borderBottom: '1px solid currentColor',
  },
}))

interface NavItemProps {
  to: string
  end?: boolean
}

interface PageNavBarProps {
  hidden?: boolean
}

const StyledNav = styled('nav')``

export const NavItem = ({ to, end, children }: NavItemProps & PropsWithChildren) => {
  return (
    <NavMenuItem>
      <MenuNavLink to={to} end={end}>
        {children}
      </MenuNavLink>
    </NavMenuItem>
  )
}

export const PageNavBar = ({ hidden = false, children }: PageNavBarProps & PropsWithChildren) => {
  return (
    <SettingsPageContainer>
      <StyledNav sx={{ display: hidden ? 'none' : 'block' }}>
        <NavMenu>{children}</NavMenu>
      </StyledNav>
    </SettingsPageContainer>
  )
}
