import { suiteBaseApi } from '@shared/api/suite-base-api'

export const gitbookVisitorApi = suiteBaseApi.injectEndpoints({
  endpoints: (build) => ({
    gitbookVisitorRedirect: build.query<{ redirectUrl: string }, string | void>({
      query: (location) => ({
        url: `gitbook-visitor/generate-url${location ? `?location=${encodeURIComponent(location)}` : ''}`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useGitbookVisitorRedirectQuery, useLazyGitbookVisitorRedirectQuery } =
  gitbookVisitorApi
