import { useEffect } from 'react'
import {
  AppBar,
  Grid,
  Link,
  Stack,
  styled,
  Theme,
  ThemeProvider,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material'
import { FullPactumLogo } from 'src/shared/components/PactumLogo'
import { UserBadge } from 'src/shared/components/Layout/UserBadge'
import { APP_HEADER_HEIGHT } from 'src/main/constants'
import { InnerContainer } from './Containers'
import { Locale } from '@common/constants'
import { FormatterContext, useFormatterContext } from '@shared/hooks'
import { Content } from '@components/Layout/Content'
import { useCurrentSuiteModule } from 'src/main/hooks/useCurrentSuiteModule'
import { NavigationList, NavigationListItem } from './NavigationListItem'
import { CommandBarProvider } from '@components/CommandBar/CommandBarProvider'
import { useNavigate } from 'react-router-dom'
import { ActiveOrgAndProjectProvider, useProjects } from 'src/main/hooks/useProjects'
import { useGetOrganizationsConfigurationQuery } from '../../../main/store/projects'
import { OrganizationMenuItem } from '@common/types/suite'
import HomeIcon from '@mui/icons-material/Home'
import DashboardIcon from '@mui/icons-material/Dashboard'
import NewspaperIcon from '@mui/icons-material/Newspaper'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import { ScheduledMaintenanceBanner } from '@components/ScheduledMaintenanceBanner'
import { SandboxLabel } from '@components/SandboxLabel'
import { NavigationItem } from 'src/main/types/suite-module'
import { ProtectedComponent } from '@components/ProtectedComponent/ProtectedComponent'
import { ProductType } from '@pactum/core-backend-types'
import { LayoutProvider } from './context'

interface Props {
  createInnerTheme?: (outerTheme: Theme) => Theme
  locale?: Locale
}

const mapMenuItem = (item: OrganizationMenuItem) => ({
  name: item.name,
  path: item.path,
  icon: () => {
    switch (item.icon) {
      case 'home':
        return <HomeIcon />
      case 'dashboard':
        return <DashboardIcon />
      case 'newspaper':
        return <NewspaperIcon />
      case 'book':
        return <MenuBookIcon />
      default:
        return <></>
    }
  },
  isExternal: item.isExternal,
  requiredPermission: item.requiredPermission,
})

export const Layout = ({ createInnerTheme, locale }: Props): JSX.Element => {
  return (
    <ActiveOrgAndProjectProvider>
      <LayoutInner createInnerTheme={createInnerTheme} locale={locale} />
    </ActiveOrgAndProjectProvider>
  )
}

const LOCALE_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  month: 'long',
  day: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  hour12: true,
  timeZoneName: 'shortGeneric',
}

const LayoutInner = ({ createInnerTheme, locale }: Props): JSX.Element => {
  const { activeOrgTag: orgTag, activeProject } = useProjects()
  const { data } = useGetOrganizationsConfigurationQuery({ orgTag: orgTag! }, { skip: !orgTag })
  const formatterContextValue = useFormatterContext({ locale })
  const navigate = useNavigate()
  const activeModule = useCurrentSuiteModule()
  const theme = useTheme()

  const organizationNavigationItems: OrganizationMenuItem[] = data?.menuItems ?? []
  const moduleNavigationItems: NavigationItem[] = activeModule?.navigationItems ?? []

  const navigationItems: NavigationItem[] = activeModule
    ? moduleNavigationItems
    : organizationNavigationItems.map(mapMenuItem)

  // sets body background color to have seamless color when scrolling against top-bottom boundaries
  useEffect(() => {
    document.body.setAttribute('style', `background: ${theme.palette.common.petrol};`)

    return () => {
      document.body.removeAttribute('style')
    }
  })

  return (
    <ThemeProvider theme={createInnerTheme || {}}>
      <LayoutProvider>
        <CommandBarProvider>
          <FormatterContext.Provider value={formatterContextValue}>
            <AppContainer>
              <StyledAppBar>
                <InnerContainer>
                  <Toolbar disableGutters>
                    <Stack
                      direction='row'
                      justifyContent='space-between'
                      alignItems='center'
                      width='100%'
                    >
                      <Stack direction='row' alignItems='center'>
                        <FullPactumLogo
                          onClick={() => {
                            navigate(`/${orgTag}`)
                          }}
                        />
                        {activeProject?.isClientSandbox && <SandboxLabel />}
                      </Stack>
                      <Stack direction='row'>
                        <NavigationList>
                          {navigationItems.map((naviItem) => (
                            <ProtectedComponent
                              key={naviItem.name}
                              requiredPerm={naviItem.requiredPermission}
                            >
                              <NavigationListItem key={naviItem.name} item={naviItem} />
                            </ProtectedComponent>
                          ))}
                        </NavigationList>
                        <UserBadge />
                      </Stack>
                    </Stack>
                  </Toolbar>
                </InnerContainer>
                <ScheduledMaintenanceBanner
                  startDate={new Date('2024-09-03T00:00:00Z')}
                  endDate={new Date('2024-09-04T11:30:00Z')}
                  productTypesForScheduledMaintenance={[
                    ProductType.ContractCost,
                    ProductType.Purchasing,
                  ]}
                  text={`Upcoming scheduled infrastructure maintenance on ${new Date(
                    '2024-09-04T11:00:00Z',
                  ).toLocaleString(
                    undefined,
                    LOCALE_FORMAT_OPTIONS,
                  )}. Suite will not be available for 30 minutes.`}
                />
              </StyledAppBar>
              <Content />
              <Footer justifyContent='center'>
                <InnerContainer>
                  <Grid container justifyContent='space-between' alignItems='center'>
                    <Grid item>
                      <Link href='https://pactum.com/privacy' target='_blank'>
                        <Typography variant='normal' color='white'>
                          Privacy Policy
                        </Typography>
                      </Link>
                    </Grid>
                    <Grid item>
                      <Typography variant='normal' color='white'>
                        Powered by Pactum. All rights reserved.
                      </Typography>
                    </Grid>
                  </Grid>
                </InnerContainer>
              </Footer>
            </AppContainer>
          </FormatterContext.Provider>
        </CommandBarProvider>
      </LayoutProvider>
    </ThemeProvider>
  )
}

const AppContainer = styled(Stack)({
  height: '100%',
  minHeight: '100vh',
  minWidth: '100%',
})

const Footer = styled(Stack)(({ theme }) => ({
  height: '2rem',
  backgroundColor: theme.palette.common.petrol,
}))

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  height: `${APP_HEADER_HEIGHT}px`,
  boxShadow: 'none',
  backgroundColor: theme.palette.primary.main,
}))
