import { CommercialTermsNegotiation } from '@campaigns/types/negotiation'
import { useNegotiationsActions } from 'src/main/hooks'
import {
  BatchActionsContainer,
  DeleteAction,
  DownloadAction,
  StartAction,
} from '@components/BatchActions'
import { DownloadAttachmentsAction } from '@components/BatchActions/DownloadAttachmentsAction'
import { MarkAsSignedAction } from '@components/BatchActions/MarkSignedAction'
import { MarkAsNotSignedAction } from '@components/BatchActions/MarkNotSignedAction'
import { useState } from 'react'
import { useBatchActionLoadingNegotiationsContext } from './BatchActionLoadingNegotiations'

interface Props {
  selectedNegotiations: CommercialTermsNegotiation[]
}

export const BatchActionsBar = ({ selectedNegotiations }: Props) => {
  const {
    availableActions,
    getNegotiationsForAction,
    handleDeleteNegotiations,
    handleStartNegotiations,
    handleDownloadNegotiations,
    handleDownloadAttachment,
    handleMarkSignedNegotiations,
    handleMarkNotSignedNegotiations,
  } = useNegotiationsActions(selectedNegotiations)

  const [enabled, setEnabled] = useState(true)
  const { addLoadingIds, removeLoadingIds } = useBatchActionLoadingNegotiationsContext()

  const withLoading = async (action: () => Promise<void>) => {
    const ids = new Set(selectedNegotiations.map((negotiation) => negotiation.id))
    setEnabled(false)
    addLoadingIds(ids)

    await action()

    setEnabled(true)
    removeLoadingIds(ids)
  }

  return (
    <BatchActionsContainer>
      <DeleteAction
        enabled={enabled && availableActions.has('DELETE')}
        totalNegotiationsCount={selectedNegotiations.length}
        actionableNegotiationsCount={getNegotiationsForAction('DELETE').length}
        onExecute={() => withLoading(handleDeleteNegotiations)}
      />
      <StartAction
        enabled={enabled && availableActions.has('START')}
        totalNegotiationsCount={selectedNegotiations.length}
        actionableNegotiationsCount={getNegotiationsForAction('START').length}
        onExecute={() => withLoading(handleStartNegotiations)}
      />
      <DownloadAction
        enabled={enabled && availableActions.has('DOWNLOAD')}
        totalNegotiationsCount={selectedNegotiations.length}
        actionableNegotiationsCount={getNegotiationsForAction('DOWNLOAD').length}
        onExecute={() => withLoading(handleDownloadNegotiations)}
      />
      <DownloadAttachmentsAction
        enabled={enabled && availableActions.has('DOWNLOAD_ATTACHMENT')}
        totalNegotiationsCount={selectedNegotiations.length}
        actionableNegotiations={getNegotiationsForAction('DOWNLOAD_ATTACHMENT')}
        onExecute={() => withLoading(handleDownloadAttachment)}
      />
      {availableActions.has('MARK_SIGNED') && (
        <MarkAsSignedAction
          enabled={enabled}
          totalNegotiationsCount={selectedNegotiations.length}
          actionableNegotiations={getNegotiationsForAction('MARK_SIGNED')}
          onExecute={() => withLoading(handleMarkSignedNegotiations)}
        />
      )}
      {availableActions.has('MARK_NOT_SIGNED') && (
        <MarkAsNotSignedAction
          enabled={enabled && availableActions.has('MARK_NOT_SIGNED')}
          totalNegotiationsCount={selectedNegotiations.length}
          actionableNegotiations={getNegotiationsForAction('MARK_NOT_SIGNED')}
          onExecute={() => withLoading(handleMarkNotSignedNegotiations)}
        />
      )}
    </BatchActionsContainer>
  )
}
