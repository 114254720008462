import {
  amber,
  blue,
  blueGrey,
  brown,
  deepOrange,
  lime,
  green,
  orange,
  purple,
  red,
  teal,
} from '@mui/material/colors'

const INTENSITIES_TO_USE = [500, 900]
const COLORS_TO_USE = [
  amber,
  blue,
  blueGrey,
  brown,
  deepOrange,
  lime,
  green,
  orange,
  purple,
  red,
  teal,
]

const getIntensitiesForColor = (colorMap: { [key: number]: string }) =>
  INTENSITIES_TO_USE.map((intensityToUse) => colorMap[intensityToUse])

// basically get these color maps {100: '#cc00000', 200: '#cc1234'....}, pick out intensities we want using above function
// and add them into one giant list where we can pick from
export const colors = COLORS_TO_USE.reduce(
  (acc, intensities) => [...acc, ...getIntensitiesForColor(intensities)],
  [] as string[],
)
