import { Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { OptionsObject, SnackbarAction, SnackbarKey, useSnackbar } from 'notistack'
import { useCallback } from 'react'

interface Notification {
  message: string
  options: OptionsObject & {
    dismissAction?: boolean
  }
}

const buildSnackbarOptions = (
  notification: Notification,
  closeSnackbar: (key?: SnackbarKey) => void,
): OptionsObject => {
  if (notification.options.dismissAction) {
    return {
      ...notification.options,
      action: (snackbarId: SnackbarAction) => (
        <IconButton
          aria-label='close'
          color='inherit'
          onClick={() => closeSnackbar(snackbarId as SnackbarKey)}
        >
          <Close />
        </IconButton>
      ),
    }
  }

  return notification.options
}

export const useNotifications = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const showNotification = useCallback(
    (notification: Notification) => {
      enqueueSnackbar(notification.message, buildSnackbarOptions(notification, closeSnackbar))
    },
    [enqueueSnackbar, closeSnackbar],
  )

  const showError = useCallback(
    (errorMsg: string) => showNotification({ message: errorMsg, options: { variant: 'error' } }),
    [showNotification],
  )

  const showSuccess = useCallback(
    (msg: string) => showNotification({ message: msg, options: { variant: 'success' } }),
    [showNotification],
  )

  return { showError, showSuccess, closeSnackbar }
}
