import { NavigationItem } from '../../../main/types/suite-module'
import { SuitePermission } from '@common/types'
import DashboardIcon from '@mui/icons-material/Dashboard'
import ReceiptLong from '@mui/icons-material/ReceiptLong'

export enum ContractCostRoutes {
  DASHBOARD = '/:orgTag/contract-cost/:projectTag/dashboard',
  REQUISITION_LIST = '/:orgTag/contract-cost/:projectTag',
  REQUISITION_DETAILS = '/:orgTag/contract-cost/:projectTag/requisition/:negotiationEventId',
}

export const contractCostNavigationItems: NavigationItem[] = [
  {
    name: 'Negotiation events',
    icon: () => <ReceiptLong />,
    path: (activeOrgTag, activeProjectTag) => `/${activeOrgTag}/contract-cost/${activeProjectTag}`,
    requiredPermission: SuitePermission.MODIFY,
  },
  {
    name: 'Dashboard',
    icon: () => <DashboardIcon />,
    path: (activeOrgTag, activeProjectTag) =>
      `/${activeOrgTag}/contract-cost/${activeProjectTag}/dashboard`,
    requiredPermission: SuitePermission.READ,
  },
]
