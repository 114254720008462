export const APP_HEADER_HEIGHT = 64

export const STATS_HEADER_HEIGHT = 106
export const GREETING_HEADER_HEIGHT = 162

export const APP_HEADER_AND_GREETING_HEIGHT = APP_HEADER_HEIGHT + GREETING_HEADER_HEIGHT

export const APP_HEADER_AND_STATS_HEIGHT = APP_HEADER_HEIGHT + STATS_HEADER_HEIGHT

export const LAYOUT_CONTAINER_X_PADDING = 6

export const CONTENT_TITLE_PADDING = '2.438rem' // based on design we align on baseline of the text, which is 39px
