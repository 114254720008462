import { CircularProgress, Link } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useLazyGetContractPreviewQuery } from '../../store/purchasing'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { NegotiationEvent } from '../../store/types'
import { downloadFile } from '@utils'
import { isBackendApiErrorResponse } from '@shared/backend/error/typeGuards'

interface Props {
  event: NegotiationEvent
}

export const ContractPreviewLink = ({ event }: Props) => {
  const { activeProjectTag: projectTag } = useActiveProject()
  const { enqueueSnackbar } = useSnackbar()
  const [getContractPreview, { isLoading }] = useLazyGetContractPreviewQuery()

  const handleLinkClick = async () => {
    try {
      const { filename, fileContent } = await getContractPreview({
        projectTag,
        requisitionId: event.id,
        supplierId: event.suppliers[0].id,
      }).unwrap()

      downloadFile(filename, fileContent)
    } catch (error) {
      const errorMessage = isBackendApiErrorResponse(error)
        ? error?.data.message
        : (error as Error)?.message

      enqueueSnackbar(errorMessage, { variant: 'error' })
    }
  }

  return (
    <Link sx={{ cursor: 'pointer' }} onClick={handleLinkClick}>
      Download preview
      {isLoading && (
        <CircularProgress
          size={12}
          thickness={5}
          sx={{ ml: 0.8, color: 'inherit', fontSize: 'inherit', verticalAlign: 'middle' }}
        />
      )}
    </Link>
  )
}
