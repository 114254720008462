import { useState } from 'react'
import { Tabs } from '@mui/material'
import { ArticleWithInput, NegotiationAnalysis } from '@common/types'
import { SidePanel } from 'src/shared/components/SidePanel'
import { HistoricalData, SupplierRequest } from './tabs'
import { StyledTab } from '@components/StyledTab'

type AnalyticsTabs = 'supplierRequest' | 'historicalData'

interface Props {
  open: boolean
  article: ArticleWithInput
  analysis: NegotiationAnalysis
  onPanelClose: () => void
}

export const AnalyticsPanel = ({ open, article, analysis, onPanelClose }: Props) => {
  const [analyticsTab, setAnalyticsTab] = useState<AnalyticsTabs>('supplierRequest')

  return (
    <SidePanel title='Pactum Analytics' open={open} onClose={onPanelClose}>
      <Tabs
        value={analyticsTab}
        TabIndicatorProps={{ sx: { backgroundColor: '#0F3A4F' } }}
        sx={{ mt: 3, width: '100%', minHeight: 'unset' }}
        onChange={(e, newTab) => setAnalyticsTab(newTab)}
      >
        <StyledTab label='Supplier request' value='supplierRequest' />
        <StyledTab label='Historical data' value='historicalData' />
      </Tabs>
      {analyticsTab === 'supplierRequest' && (
        <SupplierRequest article={article} analysis={analysis} />
      )}
      {analyticsTab === 'historicalData' && (
        <HistoricalData historicalData={analysis.historical?.[article.ean]} />
      )}
    </SidePanel>
  )
}
