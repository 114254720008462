import { Alert, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useUpdateSingleSupplierMutation } from '@logistics/store/supplierApi'
import { FieldErrors, FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { editSupplierFormSchema } from '@logistics/pages/common/SupplierDetailsPage/types'
import { RatingCtrl } from '@components/Form/RatingCtrl'
import { DataCellCtrl } from '@components/Form/DataCellCtrl'
import { TextFieldCtrl } from '@components/Form'
import { Supplier } from '@logistics/types/Supplier'
// TODO: Remove dependency on another product module
// eslint-disable-next-line import/no-restricted-paths
import { ContactList } from '@logistics/pages/common/SupplierDetailsPage/ContactList'
import { camelCaseToTitleCase } from '@logistics/utils/nameHelper'
import { PactumLoader } from '@components/PactumLoader'
import { extractErrorMessage } from '@logistics/utils/error'

type Props = {
  supplier: Supplier
  requiredAttributes: string[]
}

export const SupplierDetailsForm = ({ supplier, requiredAttributes }: Props) => {
  const { activeProjectTag } = useActiveProject()
  const [
    updateSingleSupplier,
    { isLoading: updateInProgress, isSuccess: updateSucceeded, error: updateError },
  ] = useUpdateSingleSupplierMutation()
  const [formErrors, setFormErrors] = useState<FieldErrors<Supplier> | undefined>()

  const form = useForm({
    resolver: zodResolver(editSupplierFormSchema(requiredAttributes)),
    defaultValues: supplier,
  })

  useEffect(() => {
    form.reset(supplier)
  }, [form, supplier])

  function onSubmit(data: Supplier) {
    updateSingleSupplier({ projectTag: activeProjectTag, supplier: data })
  }

  function onError(errors: FieldErrors) {
    setFormErrors(errors)
  }

  function handleChange() {
    setFormErrors(undefined)
    form.handleSubmit(onSubmit, onError)()
  }

  function parseFormErrors(formErrors: FieldErrors) {
    return Object.entries(formErrors).map((field) => {
      const [fieldName, fieldErrors] = field
      if (Array.isArray(fieldErrors)) {
        return fieldErrors.map(() => (
          <Alert sx={{ marginBottom: 2 }} severity='error'>
            {`Form validation failed for the ${fieldName} field.`}
          </Alert>
        ))
      } else {
        return (
          <Alert sx={{ marginBottom: 2 }} severity='error'>
            {`Form validation failed for the ${fieldName} field${
              fieldErrors ? `: ${fieldErrors?.message}` : '.'
            }.`}
          </Alert>
        )
      }
    })
  }

  function defaultErrorMessage() {
    return (
      <Alert sx={{ marginBottom: 2 }} severity='error'>
        {'Changes could not be saved. Please check the fields and apply the changes again. '}
        {'If the issue persists, please contact support.'}
      </Alert>
    )
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit, onError)}>
        {updateInProgress && <PactumLoader />}
        {updateSucceeded && (
          <Alert sx={{ marginBottom: 2 }} severity='success'>
            {'Changes have been successfully saved.'}
          </Alert>
        )}
        {updateError && (
          <>
            <Alert sx={{ marginBottom: 2 }} severity='error'>
              {extractErrorMessage(updateError)}
            </Alert>
            {defaultErrorMessage()}
          </>
        )}
        {formErrors && (
          <>
            {parseFormErrors(formErrors)}
            {defaultErrorMessage()}
          </>
        )}
        <Stack spacing={5}>
          <Stack direction='row' gap={4}>
            <DataCellCtrl
              key='name'
              name='name'
              label='Name'
              size='medium'
              variant='standard'
              onBlur={handleChange}
            ></DataCellCtrl>
            <DataCellCtrl
              key='externalId'
              name='externalId'
              label='Carrier ID'
              size='medium'
              variant='standard'
              onBlur={handleChange}
            />
            <DataCellCtrl
              key='mcNumber'
              name='mcNumber'
              label='MC Number'
              size='medium'
              variant='standard'
              onBlur={handleChange}
            />
            {requiredAttributes
              ? requiredAttributes.map((a) => (
                  <DataCellCtrl
                    key={'attributes.' + a}
                    name={'attributes.' + a}
                    label={camelCaseToTitleCase(a)}
                    size='medium'
                    variant='standard'
                    onBlur={handleChange}
                  />
                ))
              : null}
          </Stack>

          <ContactList supplierData={supplier} />

          <Stack spacing={3}>
            <Typography variant='h3' component='h3'>
              Performance
            </Typography>
            <RatingCtrl sx={{ maxWidth: '150px' }} onChange={handleChange} name='rating' />
            <TextFieldCtrl
              name='attributes.notes'
              required={false}
              onBlur={handleChange}
              label='Notes'
              fullWidth
              multiline
              rows={6}
            />
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  )
}
