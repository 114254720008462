import { Tooltip, Typography } from '@mui/material'
import { ReactNode } from 'react'

interface TooltipCellProps {
  tooltip: ReactNode
  children: ReactNode
}

export const TooltipCell = ({ tooltip, children }: TooltipCellProps) => (
  <Tooltip title={tooltip}>
    <Typography noWrap variant='inherit'>
      {children}
    </Typography>
  </Tooltip>
)
