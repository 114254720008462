import React, { useState } from 'react'
import { Box, Stack, styled, Tab, Tabs } from '@mui/material'
import { NegotiationEventSupplier, NegotiationFeedback } from '@procurement/store/types'
import { InvalidSupplierContactInformation } from '@procurement-shared/SupplierFeedback/InvalidSupplierContactInformation'
import { UnsuitableDeliveryDate } from '@procurement-shared/SupplierFeedback/UnsuitableDeliveryDate'
import { UnsuitableDeliveryLocation } from '@procurement-shared/SupplierFeedback/UnsuitableDeliveryLocation'
import { UnsuitablePaymentTerms } from '@procurement-shared/SupplierFeedback/UnsuitablePaymentTerms'
import { SidePanel } from '@components/SidePanel'
import { NoAgreement } from '@procurement-shared/SupplierFeedback/NoAgreement'
import { UnsuitableIncentives } from '@procurement-shared/SupplierFeedback/UnsuitableIncentives'
import { QuoteAlreadyDelivered } from '@procurement-shared/SupplierFeedback/QuoteAlreadyDelivered'
import { UntenableRequest } from '@procurement-shared/SupplierFeedback/UntenableRequest'
import { InvalidBaseline } from '@procurement-shared/SupplierFeedback/InvalidBaseline'
import { ZeroDiscount } from '@procurement-shared/SupplierFeedback/ZeroDiscount'
import { NoIncentivesSelected } from '@procurement-shared/SupplierFeedback/NoIncentivesSelected'
import { UnableToOfferBetterTerms } from '@procurement-shared/SupplierFeedback/UnableToOfferBetterTerms'

type Props = {
  suppliers: NegotiationEventSupplier[]
  isOpen: boolean
  handleClose: () => void
}

const FeedbackDetailsConfig = {
  noAgreement: NoAgreement,
  zeroDiscount: ZeroDiscount,
  invalidBaseline: InvalidBaseline,
  untenableRequest: UntenableRequest,
  unsuitableIncentives: UnsuitableIncentives,
  noIncentivesSelected: NoIncentivesSelected,
  quoteAlreadyDelivered: QuoteAlreadyDelivered,
  unsuitableDeliveryDate: UnsuitableDeliveryDate,
  unsuitablePaymentTerms: UnsuitablePaymentTerms,
  unsuitableDeliveryLocation: UnsuitableDeliveryLocation,
  unableToOfferBetterTerms: UnableToOfferBetterTerms,
  invalidSupplierContactInformation: InvalidSupplierContactInformation,
}

export const SupplierFeedback = ({ suppliers, handleClose, isOpen }: Props) => {
  const [supplier, setSupplier] = useState(suppliers[0])

  const handleChange = (_: React.SyntheticEvent, newValue: NegotiationEventSupplier) => {
    setSupplier(newValue)
  }

  const renderFeedback = (feedback?: NegotiationFeedback) => (
    <Stack direction='column' gap={6}>
      {feedback &&
        Object.entries(FeedbackDetailsConfig).map(([key, Component]) => {
          const feedbackDetails = feedback[key as keyof NegotiationFeedback]
          return feedbackDetails ? (
            <Component key={key} feedbackDetails={feedbackDetails as never} />
          ) : null
        })}
    </Stack>
  )

  const negotiation = supplier.negotiation
  if (!negotiation) return null

  return (
    <SidePanel open={isOpen} onClose={handleClose} title='Supplier feedback'>
      <Tabs
        value={supplier}
        variant='scrollable'
        scrollButtons='auto'
        onChange={handleChange}
        sx={{ marginTop: '32px', width: '100%', minHeight: 'unset' }}
      >
        {suppliers.map((supplier) => (
          <StyledTab key={supplier.id} value={supplier} label={supplier.name || supplier.email} />
        ))}
      </Tabs>
      <Box py={4} pl={4} width='100%'>
        {renderFeedback(negotiation.outcomes.feedback)}
      </Box>
    </SidePanel>
  )
}

interface StyledTabProps {
  label: string
  value: NegotiationEventSupplier
}

const StyledTab = styled((props: StyledTabProps) => <Tab {...props} />)({
  paddingTop: '5px',
  paddingBottom: '5px',
  minHeight: 'unset',
  color: '#6C6C6C',
  textTransform: 'none',
  fontWeight: 'bold',
  '&.Mui-selected': {
    color: '#0F3A4F',
  },
})
