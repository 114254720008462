import { CompetitorsPrices } from '@common/types'
import { ascendingOrderBy, descendingOrder } from '@common/utils'
import { Box, Theme, Typography, useTheme } from '@mui/material'
import { capitalizeFirstLetter, percentFormatter, usdFormatter } from '@utils'
import { useCallback, useMemo } from 'react'
import { getPriceFor } from 'src/merchandising/pages/ChangeRequests/NegotiationPreparation/Adjustments/PricingInsights/utils'
import { DynamicMultilineRow } from 'src/shared/components/DynamicMultilineRow'
import {
  CellColor,
  MultilineDataCellData,
} from 'src/shared/components/DynamicMultilineRow/MultilineCell'
import { DenseButton } from 'src/shared/components/PactumButton'
import { PricesWithPrevious } from './types'

const notAvailable = 'N/A'

interface Props {
  prices: CompetitorsPrices[]
  onUseCompetitorPrice: (value: number) => void
  isEditingDisabled: boolean
}

export const CompetitorSalesPriceBenchmark = ({
  prices,
  onUseCompetitorPrice,
  isEditingDisabled,
}: Props) => {
  const theme = useTheme()

  const getCompetitorPriceCellsWithPrevious = useCallback(
    (
      competitorPrices: PricesWithPrevious[],
      latestBaseline: PricesWithPrevious | undefined,
      hidePreviousValue: boolean,
    ): MultilineDataCellData[] =>
      competitorPrices.map((competitor) => ({
        title: `${capitalizeFirstLetter(competitor.name)} (% of us)`,
        primaryValue: `${usdFormatter(competitor.price)}${
          latestBaseline?.price
            ? getPercentageOf(competitor.price, latestBaseline.price)
            : notAvailable
        }`,
        secondaryValue: getFormattedPreviousPrice(competitor, latestBaseline, hidePreviousValue),
        actionComponent: (
          <DenseButton onClick={() => onUseCompetitorPrice(competitor.price)}>
            Use this price
          </DenseButton>
        ),
        options: {
          color: {
            primary: getPriceChangeDirectionColor(competitor, latestBaseline),
          },
          outerStyle: isEditingDisabled
            ? {
                ...getOuterPaddingAndBorderStyle(theme),
              }
            : {
                ...getOuterPaddingAndBorderStyle(theme),
                '&:hover': {
                  border: `${theme.spacing(0.25)} solid ${theme.palette.accent.light}`,
                  borderRadius:
                    typeof theme.shape.borderRadius === 'number'
                      ? theme.shape.borderRadius / 4
                      : theme.shape.borderRadius,
                },
              },
          showActionComponent: !isEditingDisabled && !!competitor.price,
        },
      })),
    [isEditingDisabled, onUseCompetitorPrice, theme],
  )

  const getUpdatedDatesCell = useCallback(
    (
      latestDate: Date,
      previousToLatestDate: Date | undefined,
      showPreviousToLatestDate: boolean,
    ) => {
      const shownPreviousLatestDate =
        previousToLatestDate && !showPreviousToLatestDate
          ? new Date(previousToLatestDate).toLocaleDateString()
          : undefined
      return {
        title: 'Updated',
        primaryValue: new Date(latestDate).toLocaleDateString(),
        secondaryValue: shownPreviousLatestDate,
        options: {
          outerStyle: getOuterPaddingAndBorderStyle(theme),
        },
      }
    },
    [theme],
  )

  const getSalesBenchmarkCells = useCallback((): MultilineDataCellData[] | undefined => {
    const pricesSortedByDateDesc: CompetitorsPrices[] = getPricesSortedByDateDesc(prices)
    const latestPrices = pricesSortedByDateDesc[0]

    if (!latestPrices || latestPrices.competitors.length === 0) {
      return undefined
    }

    const previousToLatestPrices = pricesSortedByDateDesc[1]

    const latestCompetitorPricesWithPrevious: PricesWithPrevious[] = latestPrices.competitors
      .map((latestPrice) => ({
        ...latestPrice,
        previousPrice: getPriceFor(latestPrice.name, previousToLatestPrices?.competitors),
      }))
      .sort(ascendingOrderBy('priority'))

    const hidePreviousDateAndValues = isNoCompetitorWithPreviousPrice(
      latestCompetitorPricesWithPrevious,
    )

    const latestBaselineWithPrevious = {
      ...latestPrices.baseline,
      previousPrice: previousToLatestPrices?.baseline.price,
    }

    return [
      ...getCompetitorPriceCellsWithPrevious(
        latestCompetitorPricesWithPrevious,
        latestBaselineWithPrevious,
        hidePreviousDateAndValues,
      ),
      getUpdatedDatesCell(
        latestPrices.date,
        previousToLatestPrices?.date,
        hidePreviousDateAndValues,
      ),
    ]
  }, [getCompetitorPriceCellsWithPrevious, getUpdatedDatesCell, prices])

  const salesPriceBenchmarkCells = useMemo(() => getSalesBenchmarkCells(), [getSalesBenchmarkCells])
  if (!salesPriceBenchmarkCells) {
    return null
  }

  return (
    <Box>
      <Typography variant='h6' mb={1}>
        Sales Price Benchmark
      </Typography>
      <DynamicMultilineRow data={salesPriceBenchmarkCells} />
    </Box>
  )
}

const getPricesSortedByDateDesc = (competitorPrices: Props['prices']): Props['prices'] =>
  competitorPrices.slice().sort(descendingOrder((price) => new Date(price.date).getTime()))

const getFormattedPreviousPrice = (
  competitor: PricesWithPrevious,
  latestBaseline: PricesWithPrevious | undefined,
  hidePreviousValue: boolean,
): string | undefined => {
  if (hidePreviousValue) {
    return undefined
  }
  if (competitor.previousPrice) {
    const percentageOfUs = latestBaseline?.previousPrice
      ? getPercentageOf(competitor.previousPrice, latestBaseline.previousPrice)
      : ''
    return `${usdFormatter(competitor.previousPrice)}${percentageOfUs}`
  }
  return notAvailable
}

const getOuterPaddingAndBorderStyle = (theme: Theme) => ({
  padding: theme.spacing(1),
  paddingBottom: theme.spacing(1.5),
  border: `${theme.spacing(0.25)} solid ${theme.palette.common.white}`,
})

const isNoCompetitorWithPreviousPrice = (prices: PricesWithPrevious[]) => {
  return !prices.some((price) => !!price.previousPrice)
}

const getPriceChangeDirectionColor = (
  competitor: PricesWithPrevious,
  baseline?: PricesWithPrevious,
): CellColor | undefined => {
  if (!competitor.previousPrice || !baseline || !baseline.price || !baseline.previousPrice) {
    return undefined
  }
  if (competitor.price === baseline.price && competitor.previousPrice === baseline.previousPrice) {
    return undefined
  }
  if (competitor.price / baseline.price > competitor.previousPrice / baseline.previousPrice) {
    return 'red'
  }
  return 'green'
}

const getPercentageOf = (competitorPrice: number, baselinePrice: number) =>
  ' (' + percentFormatter(competitorPrice / baselinePrice) + ')'
