import { NegotiationEvent, NegotiationEventSupplier } from '../../../store/types'
import { DataCell, DataCellProps } from '@procurement-shared/DataCell/DataCell'
import { Formatter } from '@utils'
import { formatCurrency } from '@shared/utils/formatCurrency'

export const TargetPriceCell = ({
  negotiationEvent,
  supplier,
  size,
  formatter,
}: {
  negotiationEvent: NegotiationEvent
  supplier: NegotiationEventSupplier
  size: DataCellProps['size']
  formatter: Formatter
}) => {
  const targetPrice = supplier.negotiationSettings?.totalPriceTarget

  return (
    <DataCell label={'Target price'} size={size}>
      {!targetPrice && '-'}
      {targetPrice && formatCurrency(targetPrice, negotiationEvent.currency, formatter)}
    </DataCell>
  )
}
