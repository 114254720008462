import { CancelOutlined } from '@mui/icons-material'
import { Autocomplete, TextField, TextFieldProps, Tooltip } from '@mui/material'
import { Controller, FieldError } from 'react-hook-form'
import { styleProps, errorProps } from '@components/Form/sharedProps'
import { PaymentDayObject } from '../../store/types'
import { useState } from 'react'

type ExistingPaymentDaysFieldCtrlProps = TextFieldProps & {
  name: string
  options: PaymentDayObject[]
  disabled?: boolean
  freeSolo?: boolean
  getOptionLabel?: (option: unknown) => string
}

/**
 * Matches a NET, EOM or EOW payment days string with the appropriate payment day object
 */
const parsePaymentDays = (paymentDaysString: string): PaymentDayObject | undefined => {
  const netDaysRegexp = /^\s*(NET\s+)?([0-9]{1,3})\s*$/i // e.g. "NET 50" or "35"
  const eomDaysRegexp = /^\s*((NET\s+)?([0-9]{1,3})\s+)?EOM(\s+([0-9]{1,3}))?\s*$/i // e.g. "NET 50 EOM 5", "20 EOM 10", "EOM 5" or "EOM"
  const eowDaysRegexp = /^\s*((NET\s+)?([0-9]{1,3})\s+)?EOW(\s+([0-9]{1,3}))?\s*$/i // e.g. "NET 50 EOW 5", "20 EOW 10", "EOW 5" or "EOW"

  const netDaysMatch = netDaysRegexp.exec(paymentDaysString)
  const eomDaysMatch = eomDaysRegexp.exec(paymentDaysString)
  const eowDaysMatch = eowDaysRegexp.exec(paymentDaysString)

  // All regexes are mutually exclusive for matching
  // pull out exact capture groups for property values
  if (netDaysMatch) {
    return {
      type: 'net',
      netDays: Number(netDaysMatch[2]),
    } as PaymentDayObject
  } else if (eomDaysMatch) {
    const netDays = Number(eomDaysMatch[3] ?? 0)
    const eomDays = Number(eomDaysMatch[5] ?? 0)
    const averageDays = netDays + eomDays + 15
    return {
      type: 'eom',
      netDays,
      eomDays,
      averageDays,
    } as PaymentDayObject
  } else if (eowDaysMatch) {
    const netDays = Number(eowDaysMatch[3] ?? 0)
    const eowDays = Number(eowDaysMatch[5] ?? 0)
    const averageDays = netDays + eowDays + 4
    return {
      type: 'eow',
      netDays,
      eowDays,
      averageDays,
    } as PaymentDayObject
  } else {
    return undefined
  }
}

const existingDaysErrorProps = (error: FieldError | undefined, daysError: boolean) => {
  const props = errorProps(error)
  return {
    error: props.error || daysError,
    helperText: daysError ? 'Unknown payment days' : props.helperText,
  }
}
/**
 * MUI TextField with MUI Autocomplete controlled by react-hook-form.
 * Also contains a clear button to reset the field.
 * Specialized for payment days.
 */
export const ExistingPaymentDaysFieldCtrl = ({
  name,
  options,
  required,
  disabled,
  getOptionLabel,
  freeSolo = true,
  ...rest
}: ExistingPaymentDaysFieldCtrlProps) => {
  const [validExistingDays, setValidExistingDays] = useState(true)
  return (
    <Controller
      name={name}
      rules={{ required }}
      render={({ field, fieldState: { error } }) => (
        <Tooltip
          title='Select or type existing payment days, e.g.&nbsp;40, 30&nbsp;EOM&nbsp;10 or 10&nbsp;EOW&nbsp;3'
          placement='top-start'
        >
          <Autocomplete
            freeSolo={freeSolo}
            options={options}
            disabled={disabled}
            clearIcon={<CancelOutlined color='primary' />}
            clearText='Clear'
            getOptionLabel={getOptionLabel}
            value={(field.value as string) ?? null}
            onInputChange={(_, value: string) => {
              // Check whether we can understand the new input value
              const parsedPaymentDays = parsePaymentDays(value)
              setValidExistingDays(Boolean(parsedPaymentDays) || value === '')
            }}
            renderInput={(params) => (
              <TextField
                {...styleProps}
                variant='filled'
                required={required}
                {...params}
                inputRef={field.ref}
                onBlur={(e) => {
                  // Change form state on blur, since it also triggers formatting for input
                  const parsedPaymentDays = parsePaymentDays(e.target.value)
                  const parsedLabel = getOptionLabel ? getOptionLabel(parsedPaymentDays) : ''
                  // first check whether we have similar option in config
                  const matchedOption = options.find(
                    (option) => getOptionLabel && getOptionLabel(option) === parsedLabel,
                  )
                  if (matchedOption) {
                    // Use matching option if available
                    field.onChange(matchedOption)
                  } else {
                    // Otherwise use parsed payment days
                    field.onChange(parsedPaymentDays ?? null)
                  }
                }}
                {...rest}
                {...existingDaysErrorProps(error, !validExistingDays)}
              />
            )}
          />
        </Tooltip>
      )}
    />
  )
}
