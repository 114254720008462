import { Box, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { HeaderPrimaryButton, useLayoutContext } from '@components/Layout'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import {
  useDisableSingleSupplierMutation,
  useEnableSingleSupplierMutation,
  useGetRequiredSupplierAttributesForProjectQuery,
  useGetSingleSupplierQuery,
  useGetSingleSupplierStatisticsHeaderDataQuery,
} from '@logistics/store/supplierApi'
import { useSnackbar } from 'notistack'
import { useFormatter } from '@shared/hooks'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { LogisticsRoutes } from '@logistics/routes'
import { SupplierDetailsForm } from 'src/logistics/pages/common/SupplierDetailsPage/SupplierDetailsForm'
import { isBackendApiErrorResponse } from '@shared/backend/error/typeGuards'
import { PactumLoader } from '@components/PactumLoader'
import { noValueText } from '@constants'
import { UnitHistory } from '@logistics/pages/common/SupplierDetailsPage/UnitHistory'

export const SupplierDetailsPage = () => {
  const { activeProjectTag, activeOrgTag } = useActiveProject()
  const { enqueueSnackbar } = useSnackbar()
  const formatter = useFormatter()

  const { id: supplierId } = useParams()
  const navigate = useNavigate()

  const { setStatisticsHeaderData, setActionsComponent, setSupplier } = useLayoutContext()
  const [enableSingleSupplier] = useEnableSingleSupplierMutation()
  const [disableSingleSupplier] = useDisableSingleSupplierMutation()

  const { data: headerStatisticsResponse } = useGetSingleSupplierStatisticsHeaderDataQuery(
    {
      projectTag: activeProjectTag,
      supplierId: String(supplierId),
    },
    { skip: !supplierId },
  )

  const {
    data: requiredSupplierAttributes,
    isLoading: loadingRequiredSupplierAttributes,
    isError: getRequiredAttributesQueryError,
    isSuccess: loadedRequiredSupplierAttributes,
  } = useGetRequiredSupplierAttributesForProjectQuery({
    projectTag: activeProjectTag,
  })

  const {
    data: supplierData,
    isLoading: loadingSupplier,
    isError: failedLoadingSupplier,
    error: getSupplierQueryError,
  } = useGetSingleSupplierQuery({
    projectTag: activeProjectTag,
    supplierId: String(supplierId),
  })

  useEffect(() => {
    if (failedLoadingSupplier && getSupplierQueryError) {
      enqueueSnackbar(
        isBackendApiErrorResponse(getSupplierQueryError)
          ? getSupplierQueryError.data.message
          : 'There was an error fetching carrier details',
        { variant: 'error' },
      )
    }
  }, [failedLoadingSupplier, getSupplierQueryError, enqueueSnackbar])

  useEffect(() => {
    setSupplier(supplierData ?? null)
  }, [supplierData, setSupplier])

  useEffect(() => {
    let successRate: number =
      (headerStatisticsResponse?.unitsWonYearToDate ?? 0) /
      (headerStatisticsResponse?.totalUnitsYearToDate ?? 0)
    if (isNaN(successRate)) successRate = 0

    const avgUnitPrice = headerStatisticsResponse?.avgUnitPriceYearToDate

    setStatisticsHeaderData([
      {
        title: 'Total Negotiations',
        primaryValue: headerStatisticsResponse?.totalUnitsYearToDate ?? '',
        secondaryValue: 'Year to date',
      },
      {
        title: 'Avg. price',
        primaryValue:
          avgUnitPrice === undefined || isNaN(avgUnitPrice)
            ? noValueText
            : formatter.currency(avgUnitPrice, { fractionDigits: 0 }),
        secondaryValue: 'Last year',
      },
      {
        title: 'Success rate',
        primaryValue: formatter.percent0To1(successRate, {
          fractionDigits: 0,
          showChangeSign: false,
        }),
        secondaryValue: headerStatisticsResponse?.totalUnitsYearToDate
          ? `${headerStatisticsResponse.totalUnitsYearToDate} offered, ${headerStatisticsResponse.unitsWonYearToDate} agreed`
          : noValueText,
      },
    ])

    if (supplierData) {
      setActionsComponent(
        <HeaderPrimaryButton
          onClick={async () => {
            if (!supplierData || !supplierData.id) {
              return
            }
            if (supplierData.isEnabled) {
              try {
                await disableSingleSupplier({
                  supplierId: supplierData.id,
                  projectTag: activeProjectTag,
                }).unwrap()
                enqueueSnackbar('Successfully disabled carrier', {
                  variant: 'success',
                })
              } catch (error) {
                enqueueSnackbar('Failed to disable carrier', {
                  variant: 'error',
                })
              }
            } else {
              try {
                await enableSingleSupplier({
                  supplierId: supplierData.id,
                  projectTag: activeProjectTag,
                }).unwrap()
                enqueueSnackbar('Successfully enabled carrier', {
                  variant: 'success',
                })
              } catch (error) {
                enqueueSnackbar('Failed to enable carrier', {
                  variant: 'error',
                })
              }
            }
          }}
        >
          {supplierData?.isEnabled ? 'Disable Carrier' : 'Enable Carrier'}
        </HeaderPrimaryButton>,
      )
    }

    return () => {
      setActionsComponent(null)
      setStatisticsHeaderData([])
    }
  }, [
    setStatisticsHeaderData,
    headerStatisticsResponse,
    setActionsComponent,
    activeProjectTag,
    enqueueSnackbar,
    formatter,
    supplierData,
    enableSingleSupplier,
    disableSingleSupplier,
  ])

  return (
    <>
      <Stack direction='row' alignItems='center' mt={4} pl={2}>
        <Box position='relative' right={(theme) => theme.spacing(0.75)}>
          <IconButton
            onClick={() =>
              navigate(
                generatePath(LogisticsRoutes.SUPPLIERS, {
                  orgTag: activeOrgTag,
                  projectTag: activeProjectTag,
                }),
              )
            }
            sx={{ alignSelf: 'flex-start' }}
          >
            <ArrowBackIcon fontSize='small' sx={{ color: 'black' }} />
          </IconButton>
        </Box>
        <Typography variant='h2' component='h2'>
          Carrier information
        </Typography>
      </Stack>

      {loadingSupplier || loadingRequiredSupplierAttributes ? (
        <PactumLoader />
      ) : getSupplierQueryError ? (
        <>
          {isBackendApiErrorResponse(getSupplierQueryError) &&
            getSupplierQueryError.data.statusCode === 404 && (
              <Typography sx={{ ml: 3, mt: 3 }} variant='h2' component='h2'>
                Carrier not found
              </Typography>
            )}
        </>
      ) : getRequiredAttributesQueryError ? (
        <Typography sx={{ ml: 3, mt: 3 }} variant='h2' component='h2'>
          Project configuration could not be loaded for the carrier. Please try refreshing the page
          page or contact us.
        </Typography>
      ) : supplierData && loadedRequiredSupplierAttributes ? (
        <Stack mt={2} mb={2} ml={4} spacing={5}>
          <SupplierDetailsForm
            supplier={supplierData}
            requiredAttributes={requiredSupplierAttributes}
          />
          <Box sx={{ width: { xl: 1 / 2 } }}>
            <UnitHistory supplierExternalId={supplierData.externalId} />
          </Box>
        </Stack>
      ) : null}
    </>
  )
}
