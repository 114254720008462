import { Grid, Typography } from '@mui/material'
import { FormRow, FormSectionTitle } from '@procurement/components/FormCommon/FormCommon'
import { AttachmentsList } from './AttachmentsList'
import { AttachmentsUpload } from './AttachmentsUpload'

interface AttachmentsProps {
  canUpload?: boolean
  description: string
}

export const Attachments = ({ canUpload = true, description }: AttachmentsProps) => (
  <>
    <FormSectionTitle>File attachment</FormSectionTitle>
    <FormRow>
      <Grid item xs={12}>
        <Typography variant='body2'>{description}</Typography>
      </Grid>
    </FormRow>
    <FormRow>
      <AttachmentsList />
      {canUpload && <AttachmentsUpload />}
    </FormRow>
  </>
)
