import { ItemInfo, MarketInfo, ScenarioInfo } from '@common/types/scenario/ScenarioState'

export const filterWithHistoricalOrInScopeData = <T extends MarketInfo | ItemInfo>(
  scenarioInfo: ScenarioInfo<T>,
): ScenarioInfo<T> =>
  Object.keys(scenarioInfo)
    .filter((id) => scenarioInfo[id].has_historical_data || scenarioInfo[id].in_negotiation_scope)
    .reduce((info, id) => {
      info[id] = scenarioInfo[id]
      return info
    }, {} as ScenarioInfo<T>)
