import { Button, Grid, Typography } from '@mui/material'
import { useMemo } from 'react'
import { calculateRpp, descendingOrder } from '@common/utils'
import { ArticleWithInput, CompetitorsPrices } from '@common/types'
import { InPlaceKey } from './RowDetailsPanel'
import { useFormatter } from '@shared/hooks'

interface Props {
  article: ArticleWithInput
  competitorPrices?: CompetitorsPrices[]
  onChange: (value: number | null, key: InPlaceKey) => void
  isDisabled: boolean
}

const getLatestCompetitorPriceByDateDesc = (
  competitorPrices?: CompetitorsPrices[],
): CompetitorsPrices | undefined => {
  if (!competitorPrices || !competitorPrices.length) {
    return undefined
  }

  return [...competitorPrices].sort(
    descendingOrder((competitorPrices) => new Date(competitorPrices.date).getTime()),
  )[0]
}

export const CompetitorIndexButtons = ({
  competitorPrices,
  onChange,
  article,
  isDisabled,
}: Props): JSX.Element | null => {
  const latestCompetitorPrice = useMemo(
    () => getLatestCompetitorPriceByDateDesc(competitorPrices),
    [competitorPrices],
  )
  const formatter = useFormatter()
  const onCompetitorIndexApply = (multiplier: number) => {
    if (!latestCompetitorPrice) {
      return
    }

    const desiredRetailPrice = latestCompetitorPrice.baseline.price * multiplier
    const rpp = calculateRpp(desiredRetailPrice, article.inputs.margin.lac, article.vat)

    onChange(rpp, 'rpp.lac')
  }

  if (!latestCompetitorPrice) {
    return null
  }

  const isButtonDisabled = isDisabled || !latestCompetitorPrice.baseline.price

  const priceMultipliers = [1, 1.05, 1.1, 1.15] as const

  return (
    <Grid container direction='row'>
      <Grid item xs={12} md={5.5}>
        <Typography sx={{ lineHeight: '40px' }} variant='body2'>
          Apply main competitor Index % as Sales Price:
        </Typography>
      </Grid>
      {priceMultipliers.map((multiplier) => (
        <Grid item key={multiplier} sm={3} md='auto' pt={0.5}>
          <Button
            disabled={isButtonDisabled}
            size='small'
            onClick={() => onCompetitorIndexApply(multiplier)}
          >
            {formatter.percent0To1(multiplier, { fractionDigits: 0 })}
          </Button>
        </Grid>
      ))}
    </Grid>
  )
}
