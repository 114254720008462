import { useState } from 'react'
import { MultiErrorAlert } from './MultiErrorAlert'
import { MultiErrorDialog } from './MultiErrorDialog'
import { useValidationErrors } from './ValidationErrorsProvider'

interface Props {
  topOffset: number
}

/**
 * When validation errors are set through {@link useValidationErrorEffect} hook,
 * renders an alert bar, which, in response to click, opens a dialog with error details.
 *
 * When no errors are present, renders nothing.
 */
export const ValidationErrorsBar = ({ topOffset }: Props) => {
  const { validationErrors, setValidationErrors } = useValidationErrors()
  const [isDialogOpen, setDialogOpen] = useState(false)

  if (validationErrors.length === 0) {
    return null
  }

  return (
    <>
      <MultiErrorAlert
        topOffset={topOffset}
        errorCount={validationErrors.length}
        onClick={() => setDialogOpen(true)}
        onClose={() => setValidationErrors([])}
      />
      <MultiErrorDialog
        open={isDialogOpen}
        title='Could not upload file'
        errors={validationErrors}
        onClose={() => setDialogOpen(false)}
      />
    </>
  )
}
