import styled from '@emotion/styled'
import { format } from 'date-fns'
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined'
import { ChangeLogWithWorkspaces } from './ChangelogPage'
import Tooltip from '@mui/material/Tooltip'
import { Link } from '@mui/material'

interface ChangelogItemProps {
  item: ChangeLogWithWorkspaces
}

const ListItem = styled.ul`
  list-style: none;
  padding-bottom: 20px;
  padding-left: 0;
  max-width: 640px;
`

const Title = styled.div`
  color: #222222;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 5px;
`

const DateDisplay = styled.div`
  color: #6c6c6c;
  padding-top: 4px;
  padding-left: 5px;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
`

const Content = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
`

const LabelIcon = styled(LabelOutlinedIcon)`
  color: #6c6c6c;
  display: inline-block;
  transform: scale(0.7);
  vertical-align: middle;
`

const Tags = styled.div`
  color: #6c6c6c;
  font-size: 10px;
  padding-left: 2px;
  padding-top: 2px;
  display: inline-block;
  vertical-align: middle;
`

const AffectedWorkspaces = styled.div`
  color: #6c6c6c;
  font-size: 10px;
  padding-left: 5px;
  padding-top: 2px;
  vertical-align: middle;
`

const AffectedWorkspacesList = styled.div`
  padding-left: 5px;
  display: inline-block;
`

const ExternalLink = styled(Link)`
  color: #0072ce;
  padding-left: 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
`

function formatTags(tags: string[]) {
  return tags
    .map((item) =>
      item
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' '),
    )
    .join(', ')
}

export const ChangelogItem = ({ item }: ChangelogItemProps) => {
  const sortedWorkspaces = item.affectedWorkspacesName.sort()
  let firstFiveWorkspaces = sortedWorkspaces.slice(0, 5).join(', ')
  let remainingWorkspaces = sortedWorkspaces.slice(5).join(', ')
  if (sortedWorkspaces.length > 5) {
    firstFiveWorkspaces = `${firstFiveWorkspaces}...`
    remainingWorkspaces = `...${remainingWorkspaces}`
  }
  return (
    <ListItem>
      <Title>{item.title}</Title>
      <DateDisplay>{format(new Date(item.date), 'MMMM dd, yyyy')}</DateDisplay>
      <LabelIcon />
      <Tags>{formatTags(Array.from(item.scope))}</Tags>
      <AffectedWorkspaces>
        Affected workspaces:
        <Tooltip title={remainingWorkspaces} arrow placement='right-end'>
          <AffectedWorkspacesList>{firstFiveWorkspaces}</AffectedWorkspacesList>
        </Tooltip>
      </AffectedWorkspaces>
      <Content>{item.content}</Content>
      {item.externalLink?.title && (
        <ExternalLink href={item.externalLink.url} title={item.externalLink.title}>
          {item.externalLink.title}
        </ExternalLink>
      )}
    </ListItem>
  )
}
