import { TextFieldProps } from '@mui/material'
import { FieldError } from 'react-hook-form'

export const styleProps: Pick<TextFieldProps, 'variant' | 'color'> = {
  variant: 'outlined',
  color: 'accent',
}

export const errorProps = (error?: FieldError): Pick<TextFieldProps, 'error' | 'helperText'> => {
  if (!error) {
    return {}
  }

  return {
    error: !!error,
    helperText: error?.message,
  }
}
