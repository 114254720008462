import { FormDialog } from '@components/FormDialog'
import { Grid, Link } from '@mui/material'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useDownloadTemplateData } from '../../../hooks/useDownloadTemplateData'
import { ProductType } from '@pactum/core-backend-types'
import { NegotiationEventsImport } from '../NegotiationEventsImport/NegotiationEventsImport'
import React from 'react'

interface Props {
  onClose: () => void
  open: boolean
  productType: ProductType.ContractCost | ProductType.Purchasing
}

export const UploadNegotiationEventsDialog = ({ open, onClose, productType }: Props) => {
  const { activeProjectTag } = useActiveProject()
  const downloadTemplateData = useDownloadTemplateData(activeProjectTag, productType)

  const onClickDownloadTemplate = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    downloadTemplateData()
  }

  return (
    <FormDialog
      open={open}
      loading={false}
      title='Upload negotiations'
      fullWidth
      maxWidth='sm'
      buttons={[{ type: 'submit', label: 'Close' }]}
      onSubmit={onClose}
      onCancel={onClose}
    >
      <Grid container py={1} spacing={1}>
        <Grid item xs={12} my={1}>
          <NegotiationEventsImport onFinishUpload={onClose} productType={productType} />
        </Grid>
        <Grid item xs={12}>
          <Link variant='normal' href='#' onClick={onClickDownloadTemplate}>
            Download the requisitions negotiation template
          </Link>
        </Grid>
      </Grid>
    </FormDialog>
  )
}
