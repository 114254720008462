import React, { useMemo, useState } from 'react'

interface SupplierPageContextType {
  setIsCreateSupplierModalOpen: (val: boolean) => void
  isCreateSupplierModalOpen: boolean
}

const SupplierPageContext = React.createContext<SupplierPageContextType>({
  setIsCreateSupplierModalOpen: () => {},
  isCreateSupplierModalOpen: true,
})

const SupplierContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [isCreateSupplierModalOpen, setIsCreateSupplierModalOpen] = useState(false)

  const providerValue = useMemo(
    () => ({
      isCreateSupplierModalOpen,
      setIsCreateSupplierModalOpen,
    }),
    [isCreateSupplierModalOpen],
  )

  return (
    <SupplierPageContext.Provider value={providerValue}>{children}</SupplierPageContext.Provider>
  )
}

export { SupplierPageContext, SupplierContextProvider }
