import { useContext } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { DataGrid, dataGridProps } from '@components/table/DataGrid'
import { TooltipCell } from '@components/TooltipCell'
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowModel,
} from '@mui/x-data-grid-pro'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useFormatter } from '@shared/hooks'
import { PurchasingUIConfig } from '../../../../store/types'
import { DeliveryLocationCell } from '@procurement-shared/NegotiationEventForm/sections/LineItems/DeliveryLocationCell'
import { NegotiationEventFormContext } from '../../NegotiationEventFormContext'
import { AcceptableIncotermsCell } from '@procurement-shared/NegotiationEventForm/sections/LineItems/AcceptableIncotermsCell'
import { labelForProduct } from '../../../../utils/labelForProduct'
import { ProductType } from '@pactum/core-backend-types'
import { getTotalLineItemsPrice } from '@procurement/components/NegotiationEventForm/utils/lineItems'
import { NegotiationEventFormData } from '../../schema'

type RowModel = NegotiationEventFormData['lineItems'][number]

interface Props {
  allowedIncoterms?: PurchasingUIConfig['suite']['allowedIncoterms']
  defaultIncoterm?: PurchasingUIConfig['suite']['defaultIncoterm']
  productType?: ProductType
}

const placeholderIdForNewItem = (value: number) => value * -1

const commonColumnProps = {
  disableColumnMenu: true,
  flex: 1,
}

export const PurchasingLineItemsTable = ({
  allowedIncoterms = [],
  defaultIncoterm,
  productType,
}: Props) => {
  const formatter = useFormatter()
  const { visibleFields, disabledFields } = useContext(NegotiationEventFormContext)
  const { control, watch } = useFormContext<NegotiationEventFormData>()
  const { append, replace } = useFieldArray({ control, name: 'lineItems' })
  const [currency, lineItems, category] = watch(['currency', 'lineItems', 'category'])
  const totalLineItemsPrice = getTotalLineItemsPrice(lineItems)
  const disabled = disabledFields.includes('lineItems')

  const addNewItem = () => {
    // 0 for empty line items
    const maxOrder = lineItems.length
      ? Math.max(...lineItems.map((item) => item.numberInCollection ?? 0))
      : 0
    const nextOrder = maxOrder + 1
    const DEFAULT_UNIT_OF_MEASURE = 'each'
    const incoterm = visibleFields.includes('lineItems.incoterm') ? defaultIncoterm : null

    append({
      id: placeholderIdForNewItem(nextOrder),
      externalId: null,
      numberInCollection: nextOrder,
      name: 'Name',
      quantity: 1,
      unit: DEFAULT_UNIT_OF_MEASURE,
      initialUnitPrice: 1000, // TODO: should come from the configuration
      category,
      currency,
      incoterm,
    })
  }

  const handleRowUpdate = (row: GridRowModel<RowModel>) => {
    const updatedLineItems = lineItems.map((lineItem) => (lineItem.id === row.id ? row : lineItem))

    replace(updatedLineItems)

    return row
  }

  const handleRowDelete = (numberInCollection: number) => {
    const updatedLineItems = lineItems.filter(
      (item) => item.numberInCollection !== numberInCollection,
    )

    replace(updatedLineItems)
  }

  const handleAcceptableIncotermsChange = (row: RowModel) => (acceptableIncoterms: string[]) => {
    handleRowUpdate({
      ...row,
      acceptableIncoterms,
    })
  }

  const columns: GridColDef<RowModel>[] = [
    {
      ...commonColumnProps,
      editable: !disabled,
      field: 'numberInCollection',
      headerName: labelForProduct(productType, 'negotiationEventFormLineItemNumberInCollection'),
      type: 'number',
    },
    {
      ...commonColumnProps,
      field: 'name',
      editable: !disabled,
      headerName: 'Name',
      flex: 2,
      renderCell: ({ value }: GridRenderCellParams<RowModel, string>) => (
        <TooltipCell tooltip={value}>{value}</TooltipCell>
      ),
    },
    {
      ...commonColumnProps,
      field: 'description',
      editable: !disabled,
      headerName: 'Description',
      flex: 2,
    },
    {
      ...commonColumnProps,
      field: 'quantity',
      editable: !disabled,
      headerName: 'Quantity',
      type: 'number',
      flex: 2,
    },
    {
      ...commonColumnProps,
      field: 'unit',
      editable: !disabled,
      headerName: 'Unit of measure',
      flex: 2,
    },
    {
      ...commonColumnProps,
      field: 'incoterm',
      editable: !disabled,
      headerName: 'Current incoterm',
      type: 'singleSelect',
      valueOptions: ['-', ...allowedIncoterms],
      flex: 2,
    },
    {
      ...commonColumnProps,
      field: 'acceptableIncoterms',
      editable: false,
      headerName: 'Acceptable incoterms',
      type: 'string',
      flex: 2.5,
      renderCell: ({ value = [], row }) => {
        if (disabled) {
          return (value as string[]).join(', ')
        }

        return (
          <AcceptableIncotermsCell
            acceptableIncoterms={value as string[]}
            allowedIncoterms={allowedIncoterms}
            onChange={handleAcceptableIncotermsChange(row)}
          />
        )
      },
    },
    {
      ...commonColumnProps,
      field: 'initialUnitPrice',
      editable: !disabled,
      flex: 2,
      type: 'number',
      headerName: 'Price per unit',
      renderCell: ({ value }) => formatter.currency(value, { currency, maxFractionDigits: 6 }),
    },
    {
      ...commonColumnProps,
      field: 'deliveryLocation',
      editable: false, // Editing is done through popover in `renderCell`
      flex: 2,
      type: 'string',
      headerName: 'Incoterm location',
      renderCell: (params: GridRenderCellParams<RowModel, RowModel['deliveryLocation']>) => (
        <DeliveryLocationCell disabled={disabled} handleUpdate={handleRowUpdate} {...params} />
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      editable: !disabled,
      flex: 1,
      align: 'right',
      getActions: ({ row }: { row: RowModel }) =>
        disabled
          ? []
          : [
              <GridActionsCellItem
                icon={<DeleteOutlineIcon />}
                label='Delete'
                onClick={() => handleRowDelete(row.numberInCollection!)}
              />,
            ],
    },
  ]

  const columnVisibilityModel = {
    deliveryLocation: visibleFields.includes('lineItems.deliveryLocation'),
    incoterm: visibleFields.includes('lineItems.incoterm'),
    acceptableIncoterms: visibleFields.includes('lineItems.incoterm'),
  }

  const noItemsAdded = lineItems.length === 0

  return (
    <>
      <Box sx={{ height: noItemsAdded ? '75px' : 'auto' }}>
        <DataGrid
          {...dataGridProps}
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          rows={lineItems}
          autoHeight={!noItemsAdded}
          processRowUpdate={handleRowUpdate}
          localeText={{
            noRowsLabel: '',
          }}
        />
      </Box>
      <Stack sx={{ mt: 1 }} direction='row' justifyContent='space-between'>
        <Button
          variant='outlined'
          size='small'
          color='tertiary'
          disabled={disabled}
          onClick={addNewItem}
        >
          + Add item
        </Button>
        <Typography variant='subtitle2' fontSize='0.875rem'>
          Total: {formatter.currency(totalLineItemsPrice, { currency })}
        </Typography>
      </Stack>
    </>
  )
}
