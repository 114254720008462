export const downloadFile = (fileName: string, fileObjectUrl: string) => {
  const link = document.createElement('a')
  link.href = fileObjectUrl
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export const stringToObjectUrl = (fileContent: string) =>
  window.URL.createObjectURL(new Blob([fileContent]))

export const responseAsObjectUrl = async (response: Response) => {
  if (!response.ok) {
    const clonedResponse = response.clone()
    const text = await response.text()

    return text.length ? (clonedResponse.json() as unknown) : null
  }

  return URL.createObjectURL(await response.blob())
}

export const getChecksumFromHeaders = (response: Response) => {
  const contentDisposition = response.headers.get('content-disposition')
  const checkSumMatch = contentDisposition?.match(/checkSum=([a-fA-F0-9]+)/)

  if (!checkSumMatch) {
    return ''
  }

  return checkSumMatch[1]
}

export const getFilenameFromHeaders = (response: Response) => {
  const contentDisposition = response.headers.get('content-disposition')

  let filenameMatch = contentDisposition?.match(/filename\*=UTF-8''(.+)/)

  if (!filenameMatch) {
    filenameMatch = contentDisposition?.match(/filename="(.+)"/)
  }

  if (!filenameMatch) {
    return 'unknown'
  }

  return window.decodeURIComponent(filenameMatch[1].replace(/['"]/g, ''))
}

/** Wraps file blob inside FormData object */
export const fileToFormData = (file: Blob): FormData => {
  const formData = new FormData()
  formData.append('file', file)
  return formData
}

export const filesToFormData = (files: File[]): FormData => {
  const formData = new FormData()
  files.forEach((file) => formData.append('files', file))
  return formData
}
