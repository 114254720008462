import React from 'react'
import { FormDialog } from '@components/FormDialog'
import { useDownloadTemplateData } from '../../hooks/useDownloadTemplateData'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { Grid, Link } from '@mui/material'
import { NegotiationEventsImport } from '../NegotiationEventImport/NegotiationEventsImport'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const UploadNegotiationEvent = ({ isOpen, handleClose }: Props) => {
  const { activeProjectTag } = useActiveProject()
  const startDownload = useDownloadTemplateData(activeProjectTag)

  return (
    <FormDialog
      open={isOpen}
      loading={false}
      title='Upload negotiation events'
      fullWidth
      maxWidth='sm'
      buttons={[{ type: 'submit', label: 'Close' }]}
      onSubmit={handleClose}
      onCancel={handleClose}
    >
      <Grid container py={1} spacing={1}>
        <Grid item xs={12} my={1}>
          <NegotiationEventsImport onFinishUpload={handleClose} />
        </Grid>
        <Grid item xs={12}>
          <Link variant='normal' href='#' onClick={startDownload}>
            Download the requisitions negotiation template
          </Link>
        </Grid>
      </Grid>
    </FormDialog>
  )
}
