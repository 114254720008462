// Importing from product modules allowed here as an exception
/* eslint-disable import/no-restricted-paths */

import { Stack, styled } from '@mui/material'
import { ContextHeader } from '@components/Layout/ContextHeader'
import { Outlet } from 'react-router-dom'
import { useLayoutContext } from '@components/Layout/context'
import { ValidationErrorsBar, ValidationErrorsProvider } from '@components/ValidationErrors'
import { APP_HEADER_AND_STATS_HEIGHT, APP_HEADER_HEIGHT } from '@constants'
import { SuccessAlertProvider } from '../../../commercial-terms/pages/HomePage/NegotiationsList/SuccessAlert'
import { ReadonlyWorkspaceBanner } from 'src/shared/components/ReadonlyWorkspaceBanner'

export const Content = () => {
  const { isReadonlyWorkspace, statisticsHeaderData } = useLayoutContext()

  return (
    <StyledStack>
      <ContextHeader />
      <ValidationErrorsProvider>
        <ValidationErrorsBar
          topOffset={
            statisticsHeaderData.length > 0 ? APP_HEADER_AND_STATS_HEIGHT : APP_HEADER_HEIGHT
          }
        />
        {isReadonlyWorkspace && <ReadonlyWorkspaceBanner />}
        <SuccessAlertProvider>
          <InnerContentContainer maxWidth='xl'>
            <Outlet />
          </InnerContentContainer>
        </SuccessAlertProvider>
      </ValidationErrorsProvider>
    </StyledStack>
  )
}

const StyledStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '100%',
  flex: '1 1 auto',
  backgroundColor: theme.palette.background.default,
}))

const InnerContentContainer = styled(Stack)({
  margin: '0 auto',
  width: '100%',
  flex: '1 1 auto',
})
