import { DialogProps, styled } from '@mui/material'
import { applyNames } from '@logistics/utils/nameHelper'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { UnitModalForm } from './UnitModalForm'
import { useCreateSingleUnitMutation } from '@logistics/store/unitApi'
import { CreateUnitForm, getCreateUnitFormDefaultValues } from './form'
import { extractErrorMessage } from '@logistics/utils/error'
import { useSnackbar } from 'notistack'
import { FormDialog } from '@components/FormDialog'
import { UseFormReturn } from 'react-hook-form'
import { useCallback, useState } from 'react'
import { LocationDetails, SpotLoadCreateUnitDto } from '@logistics/pages/spotLoad/types'
import { PlaceDetails } from '@logistics/hooks/useGooglePlaces'
import { getIso3ByIso2Code, ISO2CountryCode } from '@pactum/common'

interface Props {
  isOpen: DialogProps['open']
  onClose: () => unknown
}

export const UnitModal = ({ isOpen, onClose }: Props) => {
  const { activeProjectTag } = useActiveProject()
  const { enqueueSnackbar } = useSnackbar()
  const [createSingleUnit, { isLoading }] = useCreateSingleUnitMutation()

  const [originDetails, setOriginDetails] = useState<PlaceDetails | null>(null)
  const [destinationDetails, setDestinationDetails] = useState<PlaceDetails | null>(null)

  const resetAndClose = useCallback(
    (form: UseFormReturn<CreateUnitForm>) => {
      form.reset()
      onClose()
    },
    [onClose],
  )

  const saveUnit = useCallback(
    async (unitPrep: CreateUnitForm, form: UseFormReturn<CreateUnitForm>) => {
      const { pickupDate, pickupTime, comments, ...unitPrepOthers } = unitPrep

      const unit: SpotLoadCreateUnitDto = {
        ...unitPrepOthers,
        originDetails: toLocationDetails(originDetails),
        destinationDetails: toLocationDetails(destinationDetails),
        pickupDateTime: joinDateAndTimeToIsoString(pickupDate, pickupTime),
        comments: comments === '' ? null : comments,
        useCase: 'SPOT_LOAD',
      }

      try {
        await createSingleUnit({ unit, projectTag: activeProjectTag }).unwrap()

        enqueueSnackbar(applyNames('Unit created successfully!'), {
          variant: 'success',
        })

        resetAndClose(form)
      } catch (e) {
        const message = extractErrorMessage(e)

        enqueueSnackbar(message ?? 'Error occurred while starting unit(s)', {
          variant: 'error',
        })
      }
    },
    [
      createSingleUnit,
      activeProjectTag,
      enqueueSnackbar,
      resetAndClose,
      originDetails,
      destinationDetails,
    ],
  )

  return (
    <StyledFormDialog
      fullWidth
      title={applyNames('New unit')}
      open={isOpen}
      loading={isLoading}
      defaultValues={getCreateUnitFormDefaultValues()}
      onSubmit={saveUnit}
      onCancel={resetAndClose}
      buttons={[
        { type: 'cancel', label: 'Cancel' },
        { type: 'submit', label: applyNames('Create Unit') },
      ]}
    >
      <UnitModalForm
        onOriginDetails={setOriginDetails}
        onDestinationDetails={setDestinationDetails}
      />
    </StyledFormDialog>
  )
}

const StyledFormDialog = styled(FormDialog)(() => ({
  '& .MuiPaper-root': {
    maxWidth: '800px',
  },
})) as typeof FormDialog

const joinDateAndTimeToIsoString = (date: Date, time: Date): string => {
  time.setMilliseconds(0)
  time.setSeconds(0)

  const dateIso = date.toISOString().split('T')[0]
  const timeIso = time.toISOString().split('T')[1]

  return `${dateIso}T${timeIso}`
}

function toLocationDetails(place: PlaceDetails | null): LocationDetails | undefined {
  if (place === null) {
    return undefined
  } else {
    return {
      fullName: place.description ?? '',
      city: place.city ?? '',
      country: place.country ? (getIso3ByIso2Code(place.country as ISO2CountryCode) ?? '') : '', // since we do matching based on 3-letter country ISO codes
      postcode: place.postalCode ?? '',
      lat: place.latitude,
      lon: place.longitude,
    }
  }
}
