import { Avatar, Typography, Stack, Box, Button } from '@mui/material'
import { useRef, useState } from 'react'
import { useUser } from '@hooks'
import { ArrowDropDown } from '@mui/icons-material'
import { UserBadgeMenu } from './UserBadgeMenu'

export const UserBadge = () => {
  const { user } = useUser()

  const buttonRef = useRef(null)
  const [popoverOpen, setPopoverOpen] = useState(false)

  if (!user) {
    return null
  }

  return (
    <Box>
      <Button
        color='accent'
        variant='contained'
        size='large'
        ref={buttonRef}
        onClick={() => setPopoverOpen((oldOpen) => !oldOpen)}
        // Since popover list is child to have popover same size as button,
        // mouse events to children are propagated to this parent
        disableRipple={popoverOpen}
        data-test-user-menu
      >
        <Stack direction='row' alignItems='center' spacing={1}>
          <Avatar
            variant='rounded'
            alt='avatar'
            src={user.picture}
            imgProps={{ referrerPolicy: 'no-referrer' }}
            sx={{ width: '24px', height: '24px' }}
          />
          <Typography
            aria-label='logout'
            component='span'
            variant='body2'
            fontWeight={700}
            letterSpacing='-0.005em'
            sx={{ color: 'background.default' }}
          >
            {user.name}
          </Typography>
          <ArrowDropDown />
        </Stack>
      </Button>
      <UserBadgeMenu buttonRef={buttonRef} open={popoverOpen} setOpen={setPopoverOpen} />
    </Box>
  )
}
