import { SpotLoadUnit, SpotLoadUnitAction } from '../../types'
import { ActionConfigMap, ActionsButton } from '@components/ActionsButton'
import { CellPlaceholderText } from '@components/table'
import { useSnackbar } from 'notistack'
import { useApplyUnitActionMutation } from '@logistics/store/unitApi'
import { useCallback } from 'react'
import { format } from 'date-fns'
import { downloadFile, pascalCase } from '@utils'
import { extractErrorMessage } from '@logistics/utils/error'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useLazyDownloadSpotRatesQuery } from '@logistics/store/spotLoadApi'
import { OUTER_ACTIONS_WIDTH } from '@logistics/pages/spotLoad/UnitPage/UnitList/constants'

export interface Props {
  unit: SpotLoadUnit
}

const RESULTS_FILE_NAME_TIMESTAMP_FORMAT = 'yyyyMMddHHmmss'

export const UnitActions = ({ unit }: Props) => {
  const { activeProjectTag } = useActiveProject()
  const { enqueueSnackbar } = useSnackbar()
  const [downloadResults, { isLoading: isDownloadLoading }] = useLazyDownloadSpotRatesQuery()
  const [applyUnitAction, { isLoading: isActionInProgress }] = useApplyUnitActionMutation()

  const onUnitActionButtonClick = useCallback(
    async (id: string, action: SpotLoadUnitAction) => {
      try {
        if (action === SpotLoadUnitAction.DOWNLOAD_RATES) {
          const downloadResultsBlob = await downloadResults({
            projectTag: activeProjectTag,
            unitId: unit.computed_unit_id,
          }).unwrap()

          const fileName = `${unit.computed_unit_id}-${format(
            new Date(),
            RESULTS_FILE_NAME_TIMESTAMP_FORMAT,
          )}.csv`

          downloadFile(fileName, downloadResultsBlob)
        } else {
          await applyUnitAction({
            unitIds: [id],
            projectTag: activeProjectTag,
            action,
          }).unwrap()

          enqueueSnackbar('Success!', {
            variant: 'success',
          })
        }
      } catch (e) {
        const message = extractErrorMessage(e)

        enqueueSnackbar(message ?? `Error occurred while performing ${pascalCase(action)} action`, {
          variant: 'error',
        })
      }
    },
    [applyUnitAction, enqueueSnackbar, activeProjectTag, downloadResults, unit.computed_unit_id],
  )

  const getUnitActionConfig = useCallback(
    () =>
      Object.values(SpotLoadUnitAction).reduce(
        (acc, action: SpotLoadUnitAction) => ({
          ...acc,
          [action]: {
            label: pascalCase(action),
            onClick: async (id: string) => {
              await onUnitActionButtonClick(id, action)
            },
          },
        }),
        {} as ActionConfigMap<SpotLoadUnitAction, SpotLoadUnit['unit_id']>,
      ),
    [onUnitActionButtonClick],
  )

  if (!unit.allowed_actions.length) {
    return <CellPlaceholderText>No actions</CellPlaceholderText>
  }

  return (
    <ActionsButton
      id={unit.unit_id}
      loading={isActionInProgress || isDownloadLoading}
      allowedActions={unit.allowed_actions}
      actionConfig={getUnitActionConfig()}
      menuMinWidth={OUTER_ACTIONS_WIDTH}
    />
  )
}
