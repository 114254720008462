import { GridColDef } from '@mui/x-data-grid-pro'
import { CaptionedValueCell } from '@components/table'
import { ActionsButton } from '@components/ActionsButton/ActionsButton'
import React from 'react'
import { ActionConfigMap } from '@components/ActionsButton'
import { Supplier, SupplierAction, SupplierWithUnitCount } from '@logistics/types/Supplier'
import { Formatter } from '@utils'

const ROW_X_PADDING = 10
const OUTER_ACTIONS_WIDTH = 144

const OUTER_COLUMN_WIDTHS = {
  ACTIONS: OUTER_ACTIONS_WIDTH + 8 * ROW_X_PADDING,
  STATUS: 96 + 2 * ROW_X_PADDING,
  NEGOTIATED_PRICE: 96 + 2 * ROW_X_PADDING,
} as const

export const createColumns = (
  actionConfig: ActionConfigMap<SupplierAction, Supplier['id']>,
  formatter: Formatter,
): GridColDef<SupplierWithUnitCount>[] => [
  {
    field: 'name',
    headerName: 'Carrier',
    disableColumnMenu: true,
    sortable: false,
    flex: 1.0,
  },
  {
    field: 'email',
    headerName: 'Carrier email',
    disableColumnMenu: true,
    sortable: false,
    flex: 1.0,
    valueGetter: (params) => params.row.contacts.find((c) => c.isPrimary)?.email ?? 'N/A',
  },
  {
    field: 'externalId',
    headerName: 'Carrier ID',
    disableColumnMenu: true,
    sortable: false,
    flex: 1.0,
  },
  {
    field: 'mcNumber',
    headerName: 'MC Number',
    disableColumnMenu: true,
    sortable: false,
    flex: 1.0,
    renderCell: ({ row }) => (
      <CaptionedValueCell
        disabled={!row.isEnabled}
        mainContent={row.mcNumber ?? 'N/A'}
        spacing={0}
      />
    ),
  },
  {
    field: 'successfulUnits',
    headerName: 'Number of lanes',
    disableColumnMenu: true,
    sortable: false,
    align: 'right',
    type: 'number',
  },
  {
    field: 'totalUnits',
    headerName: 'Engagements',
    disableColumnMenu: true,
    sortable: false,
    align: 'right',
    headerAlign: 'right',
    flex: 1.0,
    renderCell: ({ row }) => (
      <CaptionedValueCell
        textAlign={'right'}
        disabled={!row.isEnabled}
        mainContent={
          row.totalUnits !== 0
            ? formatter.percent0To1(row.successfulUnits / row.totalUnits, { fractionDigits: 0 })
            : '0%'
        }
        captionContent={`${row.successfulUnits} of ${row.totalUnits}`}
        spacing={0}
      />
    ),
  },
  {
    field: 'actions',
    headerName: 'Actions',
    disableColumnMenu: true,
    sortable: false,
    align: 'right',
    headerAlign: 'right',
    width: OUTER_COLUMN_WIDTHS.ACTIONS,
    renderCell: ({ row: { id, isEnabled } }) => (
      <ActionsButton
        id={id}
        allowedActions={[
          SupplierAction.DETAILS,
          isEnabled ? SupplierAction.DISABLE : SupplierAction.ENABLE,
        ]}
        actionConfig={actionConfig}
        menuMinWidth={OUTER_ACTIONS_WIDTH}
      />
    ),
  },
]
