import { StandardTerms, TermStage } from '../negotiation'
import { eomXPaymentDaysConfig } from './eom'

const CUSTOM_FIELD_CONFIGS = [eomXPaymentDaysConfig]

export const getCustomFieldRenderer = (terms: StandardTerms, termStage: TermStage) => {
  return CUSTOM_FIELD_CONFIGS.find(({ rendering }) =>
    rendering.isRendererApplicable(terms, termStage),
  )
}
