import { OrganizationConfiguration, User } from '@common/types/suite'
import { Organization, ProjectListEntity, UserOrganization } from '@pactum/core-backend-types'
import { suiteBaseApi } from '@shared/api/suite-base-api'
import { getChecksumFromHeaders, responseAsObjectUrl } from 'src/main/utils/file'
import { TermsAndConditionsResponse } from './term-and-conditions'

export class UserConsentRequest {
  consentStatus: boolean = false
  termsAndConditionsChecksum: string = ''
}

export type UserProject = ProjectListEntity

export const projectsApi = suiteBaseApi
  .enhanceEndpoints({ addTagTypes: ['Projects', 'Organizations', 'TermsAndConditions'] })
  .injectEndpoints({
    endpoints: (build) => ({
      getProjects: build.query<UserProject[], void>({
        query: () => '/suite/user/organizations',
        providesTags: ['Projects'],
        transformResponse: (organizations: UserOrganization[]) =>
          organizations.flatMap((organization) => organization.projects),
      }),
      getOrganizations: build.query<UserOrganization[], void>({
        query: () => '/suite/user/organizations',
        providesTags: ['Organizations'],
      }),
      getOrganizationsConfiguration: build.query<
        OrganizationConfiguration,
        { orgTag: Organization['tag'] }
      >({
        query: ({ orgTag }) => `/suite/organization/${orgTag}/configuration`,
      }),
      fetchTermsAndConditionsDocument: build.query<TermsAndConditionsResponse, string>({
        query: (orgTag) => ({
          url: `/terms-and-conditions/${orgTag}`,
          responseHandler: async (response): Promise<TermsAndConditionsResponse> => ({
            fileContent: (await responseAsObjectUrl(response)) as string,
            checkSum: getChecksumFromHeaders(response),
          }),
        }),
      }),
      saveUserConsent: build.mutation<User, UserConsentRequest>({
        query: (consent) => ({
          url: `/terms-and-conditions/consent/`,
          method: 'POST',
          body: consent,
        }),
      }),
    }),
  })

export const {
  useGetProjectsQuery,
  useGetOrganizationsQuery,
  useGetOrganizationsConfigurationQuery,
  useFetchTermsAndConditionsDocumentQuery,
  useSaveUserConsentMutation,
} = projectsApi
