import { Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useController } from 'react-hook-form'

const Container = styled('div')`
  display: flex;
  margin-bottom: 16px;
  flex-direction: column;
`

const ImageContainer = styled('div')`
  display: flex;
`
const HiddenInput = styled('input')`
  display: none;
`
const PreviewImage = styled('img')(({ theme }) => ({
  maxWidth: '200px',
  maxHeight: '200px',
  border: '1px solid #c4c4c4',
  borderRadius: theme.shape.borderRadius,
}))

type ImageUploadWithPreviewProps = {
  name: string
  label: string
  direction?: 'row' | 'column'
  imgStyle?: React.CSSProperties
  disabled?: boolean
}

const ChangeButton = styled('label')(() => ({
  textDecoration: 'underline',
}))

export const ImageUploadWithPreview = ({
  name,
  label,
  direction,
  imgStyle,
  disabled,
}: ImageUploadWithPreviewProps) => {
  const { field } = useController({ name })

  const [preview, setPreview] = useState<string | null>()

  const handleUploadedFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (file) {
      field.onChange(file)
      const urlImage = URL.createObjectURL(file)

      setPreview(urlImage)
    }
  }

  useEffect(() => {
    // This is for the values coming from BE on initial load
    // value is a public bucket URL
    if (field.value && !preview) {
      setPreview(field.value)
    }
  }, [field.value, preview])

  useEffect(() => {
    return () => {
      if (preview) {
        URL.revokeObjectURL(preview as string)
      }
    }
  }, [preview])

  return (
    <Container>
      <ImageContainer
        style={{
          flexDirection: direction ?? 'column',
          alignItems: direction === 'row' ? 'center' : 'flex-start',
        }}
      >
        {preview && <PreviewImage src={preview} style={imgStyle} />}
        <Typography variant='body2' sx={{ display: 'inline-flex' }}>
          {label}{' '}
          {!disabled && (
            <>
              {' ('}
              <ChangeButton>
                change
                <HiddenInput
                  type='file'
                  accept='image/jpeg, image/png, image/gif'
                  name={name}
                  onChange={handleUploadedFile}
                />
              </ChangeButton>
              {')'}
            </>
          )}
        </Typography>
      </ImageContainer>
      <Typography variant='caption' color='textSecondary' style={{ marginTop: '8px' }}>
        Optimal size ix ???x??? px. Supported formats: jpg, png, gif.
      </Typography>
    </Container>
  )
}
