import { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { isBackendApiErrorResponse } from '@shared/backend/error/typeGuards'

/**
 * When error is present, renders the error in snackbar.
 * Tries to use message from the error itself, but if not available, uses the provided default.
 */
export const useSnackbarErrorEffect = (
  error: unknown,
  defaultMessage: string = 'Unknown error occurred',
) => {
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (error) {
      enqueueSnackbar(isBackendApiErrorResponse(error) ? error.data.message : defaultMessage, {
        variant: 'error',
      })
    }
  }, [error, defaultMessage, enqueueSnackbar])
}
