import { styled } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { DataGrid, dataGridProps } from '@components/table'
import { DataGridPro, GridRowParams, GridPaginationModel } from '@mui/x-data-grid-pro'
import { useFormatter } from '@shared/hooks'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useGetRfpUnitsQuery } from '@logistics/store/contractedLanesApi'
import { ContractedLanesUnit } from '../types'
import { ROWS_PER_PAGE_OPTIONS } from '@components/table/pagination'
import { createRFPDetailsColumns } from './rfpColumns'
import { RFPDetailsPanel } from './RFPDetailsPanel'

const DEFAULT_PAGE_SIZE = 10

type RFPDetailsListProps = {
  rfpId: number
  rfpExpiresAt: Date
}

export const RFPDetailsList = ({ rfpId, rfpExpiresAt }: RFPDetailsListProps) => {
  const { activeProjectTag } = useActiveProject()

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  })

  const {
    data: rfpUnits,
    isLoading,
    isFetching,
  } = useGetRfpUnitsQuery({
    id: rfpId,
    projectTag: activeProjectTag,
    skip: paginationModel.page * paginationModel.pageSize,
    limit: paginationModel.pageSize,
  })

  const getDetailsPanel = useCallback(
    (params: GridRowParams<ContractedLanesUnit>) => (
      <RFPDetailsPanel expiresAt={rfpExpiresAt} negotiations={params.row.negotiations} />
    ),
    [rfpExpiresAt],
  )

  const formatter = useFormatter()

  const columns = useMemo(() => createRFPDetailsColumns(formatter), [formatter])

  return (
    <StyledDataGrid
      {...dataGridProps}
      pagination
      hideFooter={false}
      pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      paginationMode='server'
      columns={columns}
      loading={isLoading || isFetching}
      rows={rfpUnits?.items ?? []}
      rowCount={rfpUnits?.total ?? 0}
      className='dg-expandable'
      getRowId={(row: ContractedLanesUnit) => row.id}
      getDetailPanelHeight={() => 'auto'}
      getDetailPanelContent={getDetailsPanel}
    />
  )
}

const StyledDataGrid = styled(DataGrid)(() => ({
  '& .MuiDataGrid-row:hover': {
    backgroundColor: 'unset',
    cursor: 'unset',
  },
  '&.dg-expandable .MuiDataGrid-cell:first-of-type': {
    position: 'relative',
    left: '12px',
    justifyContent: 'unset',
  },
})) as typeof DataGridPro
