import { useProjects } from '@hooks'
import { createAction, Priority, useRegisterActions } from 'kbar'

export const useProjectSwitchActions = (activeOrgTag: string, isAdmin: boolean) => {
  const { setActiveProject, projects } = useProjects()

  const projectSwitcher = createAction({
    name: 'Switch workspace',
    section: 'Workspace',
    keywords: 'workspace project',
    priority: Priority.HIGH,
  })

  const subActions = projects
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((project) =>
      createAction({
        name: project.name,
        parent: projectSwitcher.id,
        perform: () => {
          setActiveProject(project.tag)
        },
      }),
    )

  useRegisterActions([projectSwitcher, ...subActions], [activeOrgTag, projects, isAdmin])
}
