import { CoreBackendPermission } from '@pactum/core-backend-types'

export enum SuitePermission {
  READ = 'suite:read',
  MODIFY = CoreBackendPermission.AUTH_CLIENT,
  SUITE_USERS_MANAGE = 'suite:users:manage',
  SUITE_CONFIG_MANAGE = 'suite:config:manage',
  MERCHANDISING_NEGOTIATION_PREP_MANAGEMENT = 'merch:negotiation-prep-management',
  MERCHANDISING_SCENARIO_MANAGEMENT = 'merch:scenario-management',
  LOGISTICS_NORA_CLIENT = 'logistics:nora:client',
}

export enum ExternalApiPermission {
  COMMERCIAL_TERMS_API = 'commercial-terms-api:write',
}

export type PermissionType = CoreBackendPermission | SuitePermission | ExternalApiPermission
