import { DialogProps, styled } from '@mui/material'
import { applyNames } from '@logistics/utils/nameHelper'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { SupplierModalForm } from './SupplierModalForm'
import { extractErrorMessage } from '@logistics/utils/error'
import { useSnackbar } from 'notistack'
import { FormDialog } from '@components/FormDialog'
import { UseFormReturn } from 'react-hook-form'
import { CreateSupplierDto } from '@logistics/types/CreateSupplierDto'
import { useCreateSingleSupplierMutation } from '@logistics/store/supplierApi'
import { CreateSupplierForm } from '@logistics/pages/common/SupplierPage/SupplierModal/form'
import { zodResolver } from '@hookform/resolvers/zod'
import { createSupplierFormSchema } from '@logistics/pages/common/SupplierDetailsPage/types'

interface Props {
  isOpen: DialogProps['open']
  onClose: () => unknown
  requiredAttributes: string[]
}

export const SupplierModal = ({ isOpen, onClose, requiredAttributes }: Props) => {
  const { activeProjectTag } = useActiveProject()
  const { enqueueSnackbar } = useSnackbar()
  const [createSingleSupplier, { isLoading: isCreatingNewSupplier }] =
    useCreateSingleSupplierMutation()

  const saveSupplier = async (
    formData: CreateSupplierForm,
    form: UseFormReturn<CreateSupplierForm>,
  ) => {
    if (requiredAttributes == null) {
      enqueueSnackbar(
        'Project configuration could not be loaded for the carrier, please try again or contact us.',
        {
          variant: 'error',
        },
      )
      return
    }

    const requiredAttributeValues: Record<string, unknown> = {}

    for (const attribute of requiredAttributes) {
      requiredAttributeValues[attribute] = formData[attribute]
    }

    const supplier: CreateSupplierDto = {
      ...formData,
      attributes: {
        notes: formData.notes,
      },
      projectTag: activeProjectTag,
      isEnabled: true,
    }

    if (requiredAttributeValues) {
      supplier.attributes = {
        ...supplier.attributes,
        ...requiredAttributeValues,
      }
    }

    await createSingleSupplier({
      supplier,
      projectTag: activeProjectTag,
    })
      .catch((e) => {
        const message = extractErrorMessage(e)
        enqueueSnackbar(
          'Carrier could not be created: ' +
            (message ?? 'An unexpected error occurred while creating the carrier'),
          {
            variant: 'error',
          },
        )
      })
      .then(() => {
        enqueueSnackbar(applyNames('Carrier created successfully!'), {
          variant: 'success',
        })

        resetAndClose(form)
      })
  }

  const resetAndClose = (form: UseFormReturn<CreateSupplierForm>) => {
    form.reset()
    onClose()
  }

  return (
    <StyledFormDialog
      fullWidth
      title='New Carrier'
      open={isOpen}
      loading={isCreatingNewSupplier}
      resolver={zodResolver(createSupplierFormSchema(requiredAttributes))}
      onSubmit={saveSupplier}
      onCancel={resetAndClose}
      buttons={[
        { type: 'cancel', label: 'Discard changes' },
        { type: 'submit', label: 'Create' },
      ]}
    >
      <SupplierModalForm requiredAttributes={requiredAttributes} />
    </StyledFormDialog>
  )
}

const StyledFormDialog = styled(FormDialog)(() => ({
  '& .MuiPaper-root': {
    maxWidth: '800px',
  },
})) as typeof FormDialog
