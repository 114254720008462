import { InvalidSupplierContactInformationFeedback } from '@procurement/store/types'
import { Stack, Typography } from '@mui/material'
import { isNullish } from '@procurement/utils/isNullish'
import { FeedbackTitle } from './FeedbackTitle'
import { FeedbackReasonText } from './FeedbackReasonText'

interface Props {
  feedbackDetails: InvalidSupplierContactInformationFeedback
}

export const InvalidSupplierContactInformation = ({ feedbackDetails }: Props) => {
  const contactName = feedbackDetails.suggestedContactName
  const contactEmail = feedbackDetails.suggestedContactEmail
  const noInformation = isNullish(contactName) && isNullish(contactEmail)

  return (
    <Stack direction='column' gap={3}>
      <FeedbackTitle>Invalid contact information</FeedbackTitle>
      <Typography variant='body1'>
        Supplier has indicated that the negotiation should take place with a different contact.{' '}
        {noInformation ? 'However, no new contact information has been provided so far.' : ''}
      </Typography>
      {!isNullish(contactEmail) && (
        <FeedbackReasonText label='New contact email' value={contactEmail} />
      )}
      {!isNullish(contactName) && (
        <FeedbackReasonText label='New contact name' value={contactName} />
      )}
    </Stack>
  )
}
