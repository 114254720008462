import { styled } from '@mui/material'
import { GridToolbarQuickFilter } from '@mui/x-data-grid-pro'

/**
 * A rounded-corners version of QuickFilter for Grid Toolbar
 */
export const QuickFilter = styled(GridToolbarQuickFilter)(({ theme }) => ({
  width: '168px',
  '.MuiInputBase-root': {
    border: '1px solid #9E9E9E',
    borderRadius: '100px',
    fontSize: '10px',
    fontWeight: 700,
    height: '24px',
    paddingLeft: '10px',
    paddingRight: '1px',
    color: theme.typography.normal.color,
  },
  paddingBottom: 0,
  // Tweak text position inside the box
  '.MuiInputBase-input': { marginTop: '1px', marginLeft: '10px' },
  // Placeholder text
  '.MuiInputBase-input::placeholder': { color: theme.palette.text.secondary, opacity: 1 },
  // Hourglass icon
  '.MuiSvgIcon-root': { width: '18.33px', height: '18.33px', color: theme.palette.text.secondary },
  // Delete button (X)
  '.MuiButtonBase-root': { width: '22px', height: '22px' },
  // Hide the Material UI underline
  '.MuiInputBase-root:before': { display: 'none' },
  '.MuiInputBase-root:after': { display: 'none' },
}))
