import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { AppRoutes } from '../../main/constants'
import { FreshChatAwareWindow } from '../../main/utils/freshchat'

declare let window: FreshChatAwareWindow

const FRESHCHAT_SRC = 'https://wchat.eu.freshchat.com/js/widget.js'
const FRESHCHAT_HOST = 'https://pactum-help.freshchat.com'

const modules = [
  'merchandising',
  'commercial-terms',
  'logistics',
  'purchasing',
  'contract-cost',
  'suite',
] as const

type Module = (typeof modules)[number]

interface FreschatWidgetConfiguration {
  token: string
  host: string
  widgetUuid: string
}

const defaultConfiguration = {
  token: 'f7e6ace0-153b-412a-98e3-d5342c6f8559',
  host: FRESHCHAT_HOST,
  widgetUuid: '0ba535d9-467b-4c9a-8daf-f9b8898afd1c',
}

const configurations: Record<Module, FreschatWidgetConfiguration> = {
  merchandising: defaultConfiguration,
  'commercial-terms': {
    token: 'f7e6ace0-153b-412a-98e3-d5342c6f8559',
    host: FRESHCHAT_HOST,
    widgetUuid: '5e464647-0536-4fc4-b90a-00e3d3216829',
  },
  logistics: defaultConfiguration,
  purchasing: {
    token: 'f7e6ace0-153b-412a-98e3-d5342c6f8559',
    host: FRESHCHAT_HOST,
    widgetUuid: '18b48e8f-39e7-4d6d-bee4-f62c2abf141e',
  },
  'contract-cost': {
    token: 'f7e6ace0-153b-412a-98e3-d5342c6f8559',
    host: FRESHCHAT_HOST,
    widgetUuid: 'cc772f7f-b5ea-48b4-8472-9bf87aa61237',
  },
  suite: defaultConfiguration,
}

const createScript = (): HTMLScriptElement => {
  const el = document.createElement('script')

  el.src = FRESHCHAT_SRC
  el.async = true

  document.body.appendChild(el)

  return el
}

// https://usehooks-ts.com/react-hook/use-interval#hook
// use window.setInterval and window.clearInterval instead of setInterval to avoid type confusion
// which is coming from `@types/node`
const useInterval = (callback: () => void, delay: number | null) => {
  const id = useRef<number>()
  const savedCallback = useRef(callback)

  useLayoutEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (!delay && delay !== 0) {
      return
    }

    id.current = window.setInterval(() => savedCallback.current(), delay)

    return () => window.clearInterval(id.current)
  }, [delay])
}

const useCurrentSuiteModule = (): Module | undefined => {
  const loc = useLocation()

  return modules.find((module) =>
    matchPath({ path: `${AppRoutes.ORG_TAG}${module}`, end: false }, loc.pathname),
  )
}

export const useFreshchat = (interval = 150) => {
  const [delay, setDelay] = useState<number | null>(interval)
  const ref = useRef<HTMLScriptElement | null>(null)
  const currentSuiteModule = useCurrentSuiteModule()

  useInterval(() => {
    if (window?.fcWidget) {
      if (!window?.fcWidget.isInitialized()) {
        window.fcWidget?.init!(
          currentSuiteModule ? configurations[currentSuiteModule] : defaultConfiguration,
        )

        setDelay(null)
      }
    }
  }, delay)

  useEffect(() => {
    try {
      const el = document.querySelector(`script[src="${FRESHCHAT_SRC}"]`)

      window?.fcWidget?.destroy()

      if (el) {
        document.body.removeChild(el)
      }

      if (!ref.current) {
        ref.current = createScript()
      }

      setDelay(interval)
    } catch (e) {
      console.warn(e)
    }
  }, [currentSuiteModule, interval])
}
