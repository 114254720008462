import {
  ContactInfo,
  CreateScenarioResponse,
  NegotiationModel,
  ScenarioDetails as ScenarioDetailsResponse,
  ScenarioListItem,
  UpdateScenarioDetailsResponse,
  UpdateScenarioNameRequest,
  UpdateScenarioOwnerRequest,
  UpdateScenarioRequest,
} from '@common/dto'
import { ProjectTag } from '@common/types'
import { ScenarioGroup, ScenarioNegotiationModel } from '@common/types/scenario'
import { merchandisingBaseApi } from './api'
import { createNamedFileDownloadHook } from '@shared/hooks/createFileDownloadHook'
import { responseAsObjectUrl } from '../../main/utils/file'

interface RequestParams<T> {
  projectTag: ProjectTag
  sessionKey: string
  payload: T
}

const basePath = '/suite/cost-decrease'

export const scenarioApi = merchandisingBaseApi
  .enhanceEndpoints({ addTagTypes: ['Scenario', 'ScenarioList'] })
  .injectEndpoints({
    endpoints: (build) => ({
      createScenario: build.mutation<
        CreateScenarioResponse,
        { data: FormData; projectTag: ProjectTag }
      >({
        query: ({ projectTag, data }) => ({
          url: `${basePath}/${projectTag}/scenario`,
          method: 'POST',
          body: data,
        }),
        invalidatesTags: ['ScenarioList'],
      }),
      getScenarios: build.query<ScenarioListItem[], ProjectTag>({
        query: (projectTag) => `${basePath}/${projectTag}/scenarios`,
        providesTags: ['ScenarioList'],
      }),
      getScenario: build.query<
        ScenarioDetailsResponse,
        { projectTag: ProjectTag; sessionKey: string }
      >({
        query: ({ projectTag, sessionKey }) => `${basePath}/${projectTag}/scenario/${sessionKey}`,
        providesTags: ['Scenario'],
      }),
      downloadDataFile: build.query<string, { projectTag: ProjectTag; sessionKey: string }>({
        query: ({ projectTag, sessionKey }) => ({
          url: `${basePath}/${projectTag}/scenario/${sessionKey}/data-file`,
          responseHandler: responseAsObjectUrl,
        }),
      }),
      getScenarioModel: build.query<
        NegotiationModel<ScenarioNegotiationModel>,
        { projectTag: ProjectTag; sessionKey: string }
      >({
        query: ({ projectTag, sessionKey }) =>
          `${basePath}/${projectTag}/scenario/${sessionKey}/model`,
      }),
      updateScenarioItems: build.mutation<void, RequestParams<FormData>>({
        query: ({ projectTag, sessionKey, payload }) => ({
          url: `${basePath}/${projectTag}/scenario/${sessionKey}/items`,
          method: 'PUT',
          body: payload,
        }),
        invalidatesTags: ['Scenario'],
      }),
      updateScenarioInputs: build.mutation<
        UpdateScenarioDetailsResponse,
        RequestParams<UpdateScenarioRequest>
      >({
        query: ({ projectTag, sessionKey, payload }) => ({
          url: `${basePath}/${projectTag}/scenario/${sessionKey}`,
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: ['Scenario'],
      }),
      updateScenarioName: build.mutation<void, RequestParams<UpdateScenarioNameRequest>>({
        query: ({ projectTag, sessionKey, payload }) => ({
          url: `${basePath}/${projectTag}/scenario/${sessionKey}/name`,
          method: 'PUT',
          body: payload,
        }),
        invalidatesTags: ['Scenario', 'ScenarioList'],
      }),
      updateOwner: build.mutation<void, RequestParams<UpdateScenarioOwnerRequest>>({
        query: ({ projectTag, sessionKey, payload }) => ({
          url: `${basePath}/${projectTag}/scenario/${sessionKey}/owner`,
          method: 'PUT',
          body: payload,
        }),
        invalidatesTags: ['Scenario', 'ScenarioList'],
      }),
      updateScenarioGroups: build.mutation<ScenarioGroup[], RequestParams<ScenarioGroup[]>>({
        query: ({ projectTag, sessionKey, payload }) => ({
          url: `${basePath}/${projectTag}/scenario/${sessionKey}/groups`,
          method: 'POST',
          body: payload,
        }),
      }),
      submitWithContactInfo: build.mutation<void, RequestParams<ContactInfo>>({
        query: ({ projectTag, sessionKey, payload }) => ({
          url: `${basePath}/${projectTag}/scenario/${sessionKey}/submit`,
          method: 'POST',
          body: payload,
        }),
        invalidatesTags: ['Scenario', 'ScenarioList'],
      }),
      getPreviewLink: build.mutation<string, RequestParams<ContactInfo>>({
        query: ({ projectTag, sessionKey, payload }) => ({
          url: `${basePath}/${projectTag}/scenario/${sessionKey}/preview`,
          method: 'POST',
          body: payload,
        }),
      }),
      deleteScenario: build.mutation<void, { projectTag: ProjectTag; scenarioId: number }>({
        query: ({ projectTag, scenarioId }) => ({
          url: `${basePath}/${projectTag}/scenario/${scenarioId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['ScenarioList'],
      }),
    }),
  })

export const {
  useCreateScenarioMutation,
  useGetScenariosQuery,
  useGetScenarioQuery,
  useGetScenarioModelQuery,
  useUpdateScenarioItemsMutation,
  useUpdateScenarioInputsMutation,
  useUpdateScenarioNameMutation,
  useUpdateOwnerMutation,
  useUpdateScenarioGroupsMutation,
  useSubmitWithContactInfoMutation,
  useDeleteScenarioMutation,
  useGetPreviewLinkMutation,
} = scenarioApi

export const useDownloadDataFile = createNamedFileDownloadHook(
  scenarioApi.endpoints.downloadDataFile.useLazyQuery,
)
