import { isBackendApiErrorResponse } from '@shared/backend/error/typeGuards'

export type PurchasingValidationErrorResponse = {
  data: {
    details: {
      context: {
        key: string
        label: string
        value: unknown
      }
      message: string
      path: (number | string)[]
      type: string
      row?: number
    }[]
    message: string
    path: string
    statusCode: number
    timestamp: string
  }
}

export const isPurchasingValidationErrorResponse = (
  error: unknown,
): error is PurchasingValidationErrorResponse => {
  return (
    isBackendApiErrorResponse(error) &&
    'details' in error.data &&
    Array.isArray(error.data.details) &&
    error.data.details.every((detail) => 'context' in detail)
  )
}

export type LogisticsValidationErrorResponse = {
  data: {
    details: {
      target: Record<string, unknown>
      constraints: Record<string, string>
    }[]
    message: string
    path: string
    statusCode: number
    timestamp: string
  }
}

export const isLogisticsValidationErrorResponse = (
  error: unknown,
): error is LogisticsValidationErrorResponse => {
  return (
    isBackendApiErrorResponse(error) &&
    'details' in error.data &&
    Array.isArray(error.data.details) &&
    error.data.details.every((detail) => 'target' in detail && 'constraints' in detail)
  )
}
