import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React from 'react'

interface Props {
  open: boolean
  setOpen: (value: boolean) => void
  handleForceClose: () => void
}

export const AreYouSureDialog = ({ open, setOpen, handleForceClose }: Props) => (
  <Dialog open={open} onClose={() => setOpen(false)}>
    <DialogTitle>Changes not saved</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Are you sure you want to leave without applying the changes?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setOpen(false)} autoFocus>
        No
      </Button>
      <Button onClick={handleForceClose}>Yes</Button>
    </DialogActions>
  </Dialog>
)
