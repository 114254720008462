import {
  isQuerySuccessResponse,
  useUploadNegotiationTemplateMutation,
} from 'src/commercial-terms/store'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { SideBlockFileUpload } from '@components/SideBlock'
import { useValidationErrorEffect } from '@components/ValidationErrors'
import pluralize from 'pluralize'
import { ImportResult } from '@campaigns/types/ImportResult'
import { useSuccessAlert } from './NegotiationsList/SuccessAlert'
import { useFormatter } from '@shared/hooks'
import { formatCompletionDays } from '../../utils/formatCompletionDays'

export interface ProjectDataFileUploadProps {
  callback?: () => void
}

export const ProjectDataFileUpload = (props: ProjectDataFileUploadProps) => {
  const [uploadTemplate, { isLoading, error }] = useUploadNegotiationTemplateMutation()
  const { activeProjectTag } = useActiveProject()
  const { setMessage } = useSuccessAlert()
  const formatter = useFormatter()

  useValidationErrorEffect(error, 'Error occurred while importing. Please try again later.')

  const callback = async (file: Blob) => {
    const response = await uploadTemplate({ projectTag: activeProjectTag, file })
    if (isQuerySuccessResponse(response)) {
      handleSuccess(response.data)
    }
    if (props.callback) {
      props.callback()
    }
  }

  const handleSuccess = (importResult: ImportResult) => {
    const total = pluralize('negotiation', importResult.total, true)
    const missing = importResult.missingData
      ? ` (${importResult.missingData} have missing data).`
      : '.'

    const minSavingsFormatted = formatter.currency(importResult.estimatedMinSavings, {
      currency: importResult.currency,
    })
    const maxSavingsFormatted = formatter.currency(importResult.estimatedMaxSavings, {
      currency: importResult.currency,
    })

    const message = `Successfully uploaded ${total}${missing} Estimated savings are ${minSavingsFormatted} - ${maxSavingsFormatted} available in ${formatCompletionDays(
      importResult.estimatedCompletionDays,
    )}.`

    setMessage(message)
  }

  return (
    <SideBlockFileUpload
      isLoading={isLoading}
      callback={callback}
      fileTypes={['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
    />
  )
}
