import { PropsWithChildren } from 'react'
import { ProcurementTermsTooltip } from '../ProcurementTooltip/ProcurementTooltip'
import { Grid } from '@mui/material'
import { Fragment } from 'react'
import { Typography } from '@mui/material'
import React from 'react'

export interface TermItem {
  title: string
  value: string | null
}

export const TermsTooltip = ({ terms, children }: PropsWithChildren<{ terms: TermItem[] }>) => {
  if (!terms.length) {
    return <>{children}</>
  }
  return (
    <ProcurementTermsTooltip title={<TooltipText terms={terms} />}>
      <span>{children}</span>
    </ProcurementTermsTooltip>
  )
}
const TooltipText = ({ terms }: { terms: TermItem[] }) => {
  return (
    <Grid container sx={{ m: 1 }}>
      {terms.map((term) => (
        <Fragment key={term.title}>
          <Grid item xs={6}>
            <Typography variant='body2'>{term.title}:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
              {term.value}
            </Typography>
          </Grid>
        </Fragment>
      ))}
    </Grid>
  )
}
