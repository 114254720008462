import { InsertDriveFileRounded } from '@mui/icons-material'
import { Grid, Link, Stack, Typography } from '@mui/material'
import { useDownloadNegotiationEventAttachmentQuery } from '@procurement/hooks/useDownloadNegotiationEventAttachmentQuery'
import {
  AttachmentTagEnum,
  NegotiationEvent,
  NegotiationEventAttachment,
  NegotiationEventSupplier,
} from '@procurement/store/types'
import { MouseEvent } from 'react'

interface FilesVisibleToSupplierProps {
  negotiationEvent: NegotiationEvent
  supplier: NegotiationEventSupplier
}

export const FilesVisibleToSupplier = ({
  negotiationEvent: { attachments, id, projectTag },
  supplier,
}: FilesVisibleToSupplierProps) => {
  const download = useDownloadNegotiationEventAttachmentQuery()

  const onDownload = (attachmentId: number) => (event: MouseEvent) => {
    event.preventDefault()

    download({ attachmentId, negotiationEventId: id, projectTag })
  }

  const byAdditionalInformation = (attachment: NegotiationEventAttachment) =>
    attachment.tag === AttachmentTagEnum.AdditionalInformationForSuppliers &&
    attachment.isVisibleToSupplier &&
    (!attachment.supplierId || attachment.supplierId === supplier.id)

  const filesVisibleToSupplier = attachments.filter(byAdditionalInformation)

  if (!filesVisibleToSupplier.length) {
    return null
  }

  return (
    <>
      <Typography variant='h3' sx={{ mt: 4 }}>
        Files visible to supplier
      </Typography>
      <Grid item xs={6} sx={{ mt: 2 }}>
        {filesVisibleToSupplier.map((attachment) => (
          <Stack key={attachment.id} direction='row' alignItems='center' sx={{ pt: 2, pb: 2 }}>
            <InsertDriveFileRounded sx={{ ml: 1 }} fontSize='extra-small' />
            <Typography
              whiteSpace='nowrap'
              textOverflow='ellipsis'
              overflow='hidden'
              variant='inherit'
              fontSize={(theme) => theme.typography.body2.fontSize}
            >
              <Link sx={{ ml: 2, cursor: 'pointer' }} onClick={onDownload(attachment.id)}>
                {attachment.fileName}
              </Link>
            </Typography>
          </Stack>
        ))}
      </Grid>
    </>
  )
}
