import { ExpandableSection } from 'src/shared/components/ExpandableSection'
import { DetailedCostModel } from './DetailedCostModel'
import { SupplierCommoditiesReflections } from './SupplierCommoditiesReflections'
import { useNegotiationPreparationData } from 'src/merchandising/pages/ChangeRequests/NegotiationPreparation/NegotiationPreparationPage'

export const CostModelInsights = (): JSX.Element | null => {
  const { data } = useNegotiationPreparationData()

  const { supplierCommodityReflections, detailedCostModel } = data.analysis

  const anyArticleCommoditiesNeedsToBeShownInReflections = (): boolean => {
    if (!supplierCommodityReflections) {
      return false
    }
    return Object.values(supplierCommodityReflections)
      .flat()
      .map((commodityReflection) => commodityReflection.showInReflections ?? false)
      .reduce((acc, showInReflection) => acc || showInReflection)
  }

  if (
    (!supplierCommodityReflections || !anyArticleCommoditiesNeedsToBeShownInReflections()) &&
    !detailedCostModel
  ) {
    console.warn('No Cost Model Insights data')
    return null
  }

  return (
    <ExpandableSection
      title='Cost Model Insights'
      description='The following table shows cost model insights from different sources.'
    >
      <SupplierCommoditiesReflections />
      <DetailedCostModel />
    </ExpandableSection>
  )
}
