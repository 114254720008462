import { createApi } from '@reduxjs/toolkit/query/react'
import { logisticsBaseApi } from './store/baseApi'
import { LogisticsEntryPoint } from './entrypoint'
import { ProductType, ProjectListEntity } from '@pactum/core-backend-types'
import { logisticsNavigationItems, LogisticsRoutes } from './routes'
import { GenericSuiteModule } from 'src/main/types/suite-module'
import { renderProjectAsBaselineModule } from '@shared/projectsSpecific'
import { SuitePermission } from '@common/types'

export const logisticsSuiteModule: GenericSuiteModule = {
  name: 'logistics',
  api: logisticsBaseApi as unknown as ReturnType<typeof createApi>,
  entrypoint: LogisticsEntryPoint,
  basePath: 'logistics',
  projectSwitchPath: (userPermissions?: Set<SuitePermission>) =>
    userPermissions?.has(SuitePermission.MODIFY)
      ? LogisticsRoutes.PROJECT_PATH
      : LogisticsRoutes.DASHBOARD,
  isProjectCompatible: (project: ProjectListEntity) =>
    !renderProjectAsBaselineModule(project) &&
    project.productType === ProductType.Logistics &&
    project.compatibleWithNegotiationSuite,
  navigationItems: logisticsNavigationItems,
}
