import { QuoteAlreadyDeliveredFeedback } from '@procurement/store/types'
import { Stack } from '@mui/material'
import { FeedbackTitle } from './FeedbackTitle'
import { FeedbackReasonText } from './FeedbackReasonText'

interface Props {
  feedbackDetails: QuoteAlreadyDeliveredFeedback
}

export const QuoteAlreadyDelivered = ({ feedbackDetails }: Props) => (
  <Stack direction='column' gap={3}>
    <FeedbackTitle>Quote was already delivered</FeedbackTitle>
    <FeedbackReasonText label='Comment' value={feedbackDetails.reason} />
  </Stack>
)
