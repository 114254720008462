import { ChipProps } from '@mui/material'

export type DistanceFormatter = (locale: string, fractionDigits: number) => Intl.NumberFormat

export type ChipConfig = { label: string; color: ChipProps['color'] }
export type StatusConfig<T extends string> = Record<T, ChipConfig>

export type SupplierStatusConfigMap = StatusConfig<'ENABLED' | 'DISABLED'>

export const SUPPLIER_IS_ENABLED_CONFIG: SupplierStatusConfigMap = {
  ENABLED: { label: 'Enabled', color: 'success' },
  DISABLED: { label: 'Disabled', color: 'error' },
}

export enum Locale {
  enUS = 'en-US',
  deDE = 'de-DE',
  koKR = 'ko-KR',
  enGB = 'en-GB',
  etEE = 'et-EE',
  lvLV = 'lv-LV',
  ltLT = 'lt-LT',
  esMX = 'es-MX',
  esES = 'es-ES',
  jaJP = 'ja-JP',
  esCL = 'es-CL',
  itIT = 'it-IT',
  enCA = 'en-CA',
  nlNL = 'nl-NL',
  ptPT = 'pt-PT',
  zhHantTW = 'zh-Hant-TW',
  frFR = 'fr-FR',
  zhHansCN = 'zh-Hans-CN',
  csCZ = 'cs-CZ',
  huHU = 'hu-HU',
  plPL = 'pl-PL',
  roRO = 'ro-RO',
}
