import { errorProps } from '@components/Form/sharedProps'
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  SelectProps,
} from '@mui/material'
import { FieldValues, RegisterOptions, useController } from 'react-hook-form'

export type MultiSelectCtrlProps = SelectProps & {
  // We should also allow passing in an array of objects with label and value properties.
  // For now this is not implemented as we don't yet need it.
  options: string[] | number[]
  name: string
  helperText?: string
}

type Rules =
  | Omit<
      RegisterOptions<FieldValues, string>,
      'setValueAs' | 'disabled' | 'valueAsNumber' | 'valueAsDate'
    >
  | undefined

export const MultiSelectCtrl = ({
  options,
  name,
  required,
  helperText,
  label,
  ...rest
}: MultiSelectCtrlProps) => {
  const rules: Rules = { required }

  const {
    field,
    fieldState: { error },
  } = useController({ name, rules })

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event

    const values = typeof value === 'string' ? value.split(', ') : value

    field.onChange(values)
  }

  const labelId = `${name}-label`

  return (
    <FormControl sx={{ width: '100%' }}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        multiple
        required={required}
        name={field.name}
        value={(field.value as string[]) ?? []}
        onChange={handleChange}
        onBlur={field.onBlur}
        input={<OutlinedInput label={label} />}
        inputRef={field.ref}
        labelId={labelId}
        renderValue={(selected) => (selected as string[]).join(', ')}
        fullWidth
        {...rest}
        {...errorProps(error)}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option} sx={{ py: 0, px: 0 }}>
            <Checkbox checked={((field.value as unknown[]) ?? []).indexOf(option) > -1} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
      {(helperText || error) && (
        <FormHelperText error={!!error}>{error?.message ?? helperText}</FormHelperText>
      )}
    </FormControl>
  )
}
