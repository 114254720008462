import { handleOnCacheEntryAdded, logisticsBaseApi } from './baseApi'
import { UnitListResponse } from '@logistics/types/UnitListResponse'
import { WebsocketServerEvent } from '@logistics/types/socket'
import { Socket } from 'socket.io-client'
import { QueryCacheLifecycleApi } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { BaseQueryFn } from '@reduxjs/toolkit/query/react'
import { fileToFormData } from 'src/main/utils/file'
import {
  getUnitStatusIndex,
  SpotLoadCreateUnitDto,
  SpotLoadUnit,
  SpotLoadUnitAction,
} from '@logistics/pages/spotLoad/types'
import { SpotLoadStatisticsHeaderData } from '@logistics/pages/spotLoad/utils/statisticsHeader'
import { ProjectTag } from '@common/types'
import {
  getNegotiationStatusIndex,
  SpotLoadNegotiation,
} from '@logistics/pages/spotLoad/types/negotiation'

type Unit = SpotLoadUnit
type StatisticsHeaderData = SpotLoadStatisticsHeaderData
type CreateUnitDto = SpotLoadCreateUnitDto

// TODO: move to spot load api
export const unitApi = logisticsBaseApi.injectEndpoints({
  endpoints: (build) => ({
    importUnits: build.mutation<void, { projectTag: ProjectTag; file: Blob }>({
      query: ({ projectTag, file }) => ({
        url: `/suite/${projectTag}/units/import`,
        method: 'POST',
        body: fileToFormData(file),
      }),
      invalidatesTags: ['UnitList', 'StatisticsHeader', 'UnitCountByUseCase'],
    }),
    getStatisticsHeaderData: build.query<
      StatisticsHeaderData,
      { projectTag: ProjectTag; periodInDays: number }
    >({
      query: ({ projectTag, periodInDays }) => ({
        url: `/suite/${projectTag}/units/statistics/${periodInDays}`,
        method: 'GET',
      }),
      providesTags: ['StatisticsHeader'],
      onCacheEntryAdded: (args, api) =>
        handleOnCacheEntryAdded(args.projectTag, api, async (connectedSocket) => {
          connectedSocket.on(
            WebsocketServerEvent.UNIT_STATISTICS_UPDATED,
            (data: SpotLoadStatisticsHeaderData) => {
              api.updateCachedData((draft) => {
                Object.assign(draft, data)
              })
            },
          )
        }),
    }),
    getUnitList: build.query<
      UnitListResponse<Unit>,
      {
        skip: number
        limit: number
        projectTag: ProjectTag
        supplierExternalId?: string
      }
    >({
      query: ({ projectTag, supplierExternalId, skip, limit }) => ({
        url: `/suite/${projectTag}/units`,
        method: 'GET',
        params: {
          limit,
          skip,
          supplierExternalId,
        },
      }),
      providesTags: ['UnitList'],
      onCacheEntryAdded: ({ projectTag }, api) =>
        handleOnCacheEntryAdded(projectTag, api, async (connectedSocket) =>
          onUnitListQueryCacheEntryAdded(connectedSocket, api),
        ),
    }),
    getSpotLoadUnitList: build.query<
      UnitListResponse<SpotLoadUnit>,
      {
        skip: number
        limit: number
        projectTag: ProjectTag
        supplierExternalId?: string
      }
    >({
      query: ({ projectTag, supplierExternalId, skip, limit }) => ({
        url: `/spot-load/${projectTag}/units`,
        method: 'GET',
        params: {
          limit,
          skip,
          supplierExternalId,
        },
      }),
      providesTags: ['UnitList'],
      onCacheEntryAdded: ({ projectTag }, api) =>
        handleOnCacheEntryAdded(projectTag, api, async (connectedSocket) =>
          onUnitListQueryCacheEntryAdded(connectedSocket, api),
        ),
    }),
    applyUnitAction: build.mutation<
      void,
      {
        projectTag: ProjectTag
        action: SpotLoadUnitAction
        unitIds: Unit['unit_id'][]
      }
    >({
      query: ({ projectTag, action, unitIds }) => ({
        url: `/suite/${projectTag}/units/${action}`,
        method: 'POST',
        body: { unitIds },
      }),
      invalidatesTags: ['UnitList', 'StatisticsHeader'],
    }),
    createSingleUnit: build.mutation<
      Unit,
      {
        unit: CreateUnitDto
        projectTag: ProjectTag
      }
    >({
      query: ({ unit, projectTag }) => ({
        url: `/suite/${projectTag}/units/create`,
        method: 'POST',
        body: unit,
      }),
      invalidatesTags: ['UnitList', 'StatisticsHeader', 'UnitCountByUseCase'],
    }),
  }),
})

/**
 * TODO: this should be revisited, ideally we would like to do something like this:
 *
 * socket.on('event', (data) => {
 *   api.endpoints.getUnitList.updatedCachedData((draft) => {
 *     Object.assign(draft, data)
 *   })
 * })
 *
 * Unfortunately I couldn't figure out how to do it with RTK in reasonable time, so I will revisit this issue in the followup.
 */
function onUnitListQueryCacheEntryAdded(
  connectedSocket: Socket,
  api: QueryCacheLifecycleApi<unknown, BaseQueryFn, UnitListResponse<Unit>>,
): void {
  connectedSocket.on(WebsocketServerEvent.UNIT_BULK_UPDATED, (data: Unit[]) => {
    api.updateCachedData((draft) => {
      data.forEach((unit) => {
        const idx = draft.data.findIndex((u) => u.unit_id === unit.unit_id)

        if (idx === -1) {
          return
        }

        draft.data[idx] = unit
      })
    })
  })

  connectedSocket.on(WebsocketServerEvent.UNIT_UPDATED, (data: Unit) => {
    api.updateCachedData((draft) => {
      const idx = draft.data.findIndex((u) => u.unit_id === data.unit_id)

      if (idx === -1) {
        return
      }

      if (getUnitStatusIndex(draft.data[idx].status) <= getUnitStatusIndex(data.status)) {
        draft.data[idx] = {
          ...data,
          negotiations: draft.data[idx].negotiations,
        } as Unit
      }

      for (let i = 0; i < draft.data[idx].negotiations.length; i++) {
        const negotiation = draft.data[idx].negotiations[i]

        const negotiationUpdate = findMatchingNegotiationUpdate(negotiation, data.negotiations)

        if (negotiationUpdate) {
          draft.data[idx].negotiations[i] = negotiationUpdate
        }
      }
    })
  })
}

function findMatchingNegotiationUpdate(
  negotiationToFind: SpotLoadNegotiation,
  negotiationUpdates: SpotLoadNegotiation[],
): SpotLoadNegotiation | null {
  for (const negotiationUpdate of negotiationUpdates) {
    if (negotiationToFind.id !== negotiationUpdate.id) {
      continue
    }

    // When multiple negs are updated simultaneously, we need to check that incoming data is not older than the data we show right now
    if (
      getNegotiationStatusIndex(negotiationToFind.status) >
      getNegotiationStatusIndex(negotiationUpdate.status)
    ) {
      continue
    }

    return negotiationUpdate
  }

  return null
}

export const {
  useGetStatisticsHeaderDataQuery,
  useGetUnitListQuery,
  useGetSpotLoadUnitListQuery,
  useApplyUnitActionMutation,
  useCreateSingleUnitMutation,
} = unitApi
