import { Supplier, SupplierWithUnitCount } from '@logistics/types/Supplier'
import Checkbox from '@mui/material/Checkbox'
import { Autocomplete, Chip, TextField, Typography } from '@mui/material'
import { useController } from 'react-hook-form'
import { CheckBox, CheckBoxOutlineBlank, Error } from '@mui/icons-material'
import { PactumLoader } from '@components/PactumLoader'
import { useGetSupplierListQuery } from '@logistics/store/supplierApi'
import React from 'react'
import { isPopulatedString } from '../../../../main/utils/string'

interface PaginatedSupplierMenuProps {
  projectTag: string
  name: string
}

export const SelectSuppliersForRfp = ({ projectTag, name }: PaginatedSupplierMenuProps) => {
  const { data: supplierData, isLoading: isLoadingSuppliers } = useGetSupplierListQuery({
    projectTag: projectTag,
    skip: 0,
    // TODO: https://pactum.atlassian.net/browse/LP-1963
    limit: 1000,
  })
  const suppliers = supplierData?.items ?? []

  const { field } = useController({ name })

  const handleSelectedSuppliersChange = (
    event: React.SyntheticEvent,
    value: SupplierWithUnitCount[],
  ) => {
    let selectedSupplierIds: string[] = []
    // TODO: https://pactum.atlassian.net/browse/LP-1954
    // This special handling can be removed once LP-1954 is done.
    if (projectTag.includes('maersk')) {
      selectedSupplierIds = value
        .filter((supplier) => isPopulatedString(supplier.attributes.vendorCode))
        .map((supplier) => supplier.attributes.vendorCode as string)
    } else {
      selectedSupplierIds = value.map((supplier) => supplier.externalId)
    }
    field.onChange(selectedSupplierIds)
  }

  function isSupplierMissingId(supplier: Supplier) {
    // TODO: https://pactum.atlassian.net/browse/LP-1954
    // This special handling can be removed once LP-1954 is done.
    if (projectTag.includes('maersk')) {
      return !isPopulatedString(supplier.attributes.vendorCode)
    }
    return !isPopulatedString(supplier.externalId)
  }

  function getSupplierIdMissingWarning(supplier: Supplier) {
    // TODO: https://pactum.atlassian.net/browse/LP-1954
    // This special handling can be removed once LP-1954 is done.
    return isSupplierMissingId(supplier) ? (
      !projectTag.includes('maersk') ? (
        <Chip
          label='ID Missing'
          color='warning'
          variant='filled'
          icon={<Error />}
          style={{ marginLeft: 10 }}
        />
      ) : (
        <Chip
          label='Vendor Code Missing'
          color='warning'
          variant='filled'
          icon={<Error />}
          style={{ marginLeft: 10 }}
        />
      )
    ) : null
  }

  function getSupplierDisabledWarning(supplier: Supplier) {
    return !supplier.isEnabled ? (
      <Chip
        label='Disabled'
        color='warning'
        variant='filled'
        icon={<Error />}
        style={{ marginLeft: 10 }}
      />
    ) : null
  }

  const loaderWithMessage = (
    <>
      <Typography>Loading suppliers...</Typography>
      <PactumLoader />
    </>
  )

  return (
    <Autocomplete
      autoComplete
      multiple
      disableCloseOnSelect
      id='selectedCarrierIds'
      limitTags={3}
      options={suppliers}
      getOptionLabel={(supplier) => supplier.name}
      defaultValue={[]}
      loading={isLoadingSuppliers}
      loadingText={loaderWithMessage}
      noOptionsText={<Typography>'No available suppliers.'</Typography>}
      onChange={handleSelectedSuppliersChange}
      onBlur={field.onBlur}
      getOptionDisabled={(supplier) => {
        return isSupplierMissingId(supplier) || !supplier.isEnabled
      }}
      renderOption={(props, supplier, { selected }) => {
        const { ...optionProps } = props
        return (
          <li key={supplier.id} {...optionProps}>
            <Checkbox
              icon={<CheckBoxOutlineBlank fontSize='small' />}
              checkedIcon={<CheckBox fontSize='small' />}
              checked={selected}
              style={{ marginRight: 10 }}
            />
            {supplier.name}
            {getSupplierIdMissingWarning(supplier)}
            {getSupplierDisabledWarning(supplier)}
          </li>
        )
      }}
      renderInput={(params) => (
        <TextField multiline maxRows={1} placeholder='Search by Supplier Name' {...params} />
      )}
      fullWidth
    />
  )
}
