import { useConfirm, useNotifications } from '@hooks'
import {
  Box,
  Button,
  FormHelperText,
  List,
  ListItemButton,
  Typography,
  styled,
} from '@mui/material'
import { Branding } from '@pactum/core-backend-types'
import { useGetBrandingListQuery, useSetBrandingAsDefaultMutation } from '@store/branding'
import { useEffect, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

type BrandingListProps = {
  orgTag: string
  disabled: boolean
}

const ButtonLabel = styled(`span`)(({ theme }) => ({
  color: theme.palette.darkGrey.main,
}))

export const BrandingList = ({ disabled, orgTag }: BrandingListProps) => {
  const { isLoading, data: brandings } = useGetBrandingListQuery(orgTag)
  const [setDefaultBranding] = useSetBrandingAsDefaultMutation()
  const [selectedBranding, setSelectedBranding] = useState<Branding | null>(null)
  const navigate = useNavigate()
  const { showSuccess, showError } = useNotifications()
  const { dialog, confirm } = useConfirm()
  const onSetBrandingAsDefault = () => {
    confirm(
      {
        type: 'positive',
        title: `Set ${selectedBranding?.label} business identity as primary`,
        content: `Are you sure you want to set ${selectedBranding?.label} business identity as primary?`,
      },
      async () => {
        const response = await setDefaultBranding(selectedBranding?.uuid as string)
        if ('error' in response) {
          showError(`Failed to set ${selectedBranding?.label} business identity as primary`)
          return
        } else {
          showSuccess(`${selectedBranding?.label} business identity is primary now`)
        }
      },
    )
  }

  useEffect(() => {
    if (!isLoading && brandings && brandings.length > 0) {
      setSelectedBranding(brandings[0])
    }
  }, [brandings, isLoading])

  return (
    <>
      <Typography variant='h3' component='h3' mb={2}>
        Business Identities
      </Typography>

      <FormHelperText sx={{ mb: 2 }}>
        A business identity contains a set of elements, labels and images that define and represent
        the different brands within your organization in your negotiations.
      </FormHelperText>

      <List>
        {brandings?.map((branding) => (
          <ListItemButton
            sx={{ fontSize: 14 }}
            key={branding.uuid}
            onClick={() => setSelectedBranding(branding)}
            selected={selectedBranding?.uuid === branding.uuid}
            style={
              selectedBranding?.uuid === branding.uuid
                ? { background: 'rgba(236, 241, 242, 1)' }
                : {}
            }
          >
            {branding.label}
            {branding.isDefault && ' - primary'}
          </ListItemButton>
        ))}
      </List>

      {!disabled && (
        <Box display='flex' alignItems='center' gap={1} width='100%' mt={2}>
          <Button
            variant='outlined'
            onClick={() => navigate('./branding/new')}
            data-testid='create-branding'
            color='tertiary'
          >
            <ButtonLabel>+ Add</ButtonLabel>
          </Button>

          <Button
            variant='outlined'
            disabled={!selectedBranding}
            data-testid='edit-branding'
            color='tertiary'
            onClick={() =>
              navigate(
                generatePath('./branding/:brandingId', {
                  brandingId: selectedBranding?.uuid as string,
                }),
              )
            }
          >
            <ButtonLabel>Edit</ButtonLabel>
          </Button>

          <Button
            variant='outlined'
            disabled={!selectedBranding || selectedBranding.isDefault}
            data-testid='set-branding-as-default'
            color='tertiary'
            onClick={onSetBrandingAsDefault}
            sx={(theme) => ({ borderColor: theme.palette.tertiary.main })}
          >
            <ButtonLabel>Set as primary</ButtonLabel>
          </Button>
        </Box>
      )}
      {dialog}
    </>
  )
}
