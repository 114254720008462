import { NegotiationEvent, NegotiationEventSupplier } from '../../../store/types'
import { DeleteSupplierConfirmationModal } from '@procurement/pages/NegotiationEventDetails/DeleteSupplierConfirmationModal/DeleteSupplierConfirmationModal'
import { UpdateSupplierDialog } from '@procurement/pages/NegotiationEventDetails/UpdateSupplierDialog/UpdateSupplierDialog'
import { useState } from 'react'
import { ActionConfigMap, ActionsButton } from '@components/ActionsButton'
import {
  useGetConfigurationQuery,
  useSelectWinnerMutation,
  useUpdateNegotiationEventSupplierMutation,
} from '../../../store/purchasing'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { isBackendApiErrorResponse, isQuerySuccessResponse } from '@shared/backend/error/typeGuards'
import { useSnackbar } from 'notistack'
import { NegotiationEventSupplierListAction } from './SupplierActions'

interface Props {
  supplier: NegotiationEventSupplier
  negotiationEvent: NegotiationEvent
  actions: NegotiationEventSupplierListAction[]
}

export const SupplierListActions = ({ actions, negotiationEvent, supplier }: Props) => {
  const [isActionsButtonLoading, setIsActionsButtonLoading] = useState(false)
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [selectWinnerMutation] = useSelectWinnerMutation()
  const [updateRequisitionSupplierMutation] = useUpdateNegotiationEventSupplierMutation()
  const { activeProjectTag: projectTag } = useActiveProject()
  const { data: configuration } = useGetConfigurationQuery({ projectTag })
  const { enqueueSnackbar } = useSnackbar()

  const useCase = configuration?.data.suite.useCase

  const confirmOrChangeWinner = async (action: 'change' | 'confirm') => {
    setIsActionsButtonLoading(true)
    const response = await selectWinnerMutation({
      projectTag,
      requisitionId: negotiationEvent.id,
      requisitionSupplierId: supplier.id,
    })
    setIsActionsButtonLoading(false)

    if (isQuerySuccessResponse(response)) {
      enqueueSnackbar(
        action === 'change' ? 'Winner changed successfully' : 'Winner confirmed successfully',
        {
          variant: 'success',
        },
      )
    } else {
      enqueueSnackbar(action === 'change' ? 'Error changing winner' : 'Error confirming winner', {
        variant: 'error',
      })
    }
  }

  const toggleIsNegotiationEventSupplierEnabled = async (isEnabledForNegotiation: boolean) => {
    if (!supplier) {
      return
    }

    const response = await updateRequisitionSupplierMutation({
      updateNegotiationEventSupplierDto: {
        externalId: supplier.externalId,
        isEnabledForNegotiation,
      },
      projectTag,
      negotiationEventId: negotiationEvent.id,
      supplierId: supplier.id,
    })

    if (isQuerySuccessResponse(response)) {
      enqueueSnackbar(
        isEnabledForNegotiation
          ? 'Supplier enabled successfully!'
          : 'Supplier disabled successfully!',
        {
          variant: 'success',
        },
      )
    } else {
      const actionMessage = isEnabledForNegotiation
        ? 'Error occurred while enabling supplier'
        : 'Error occurred while disabling supplier'
      const message = isBackendApiErrorResponse(response.error)
        ? response.error.data.message
        : actionMessage
      enqueueSnackbar(message, {
        variant: 'error',
      })
    }
  }

  const actionsConfig: ActionConfigMap<NegotiationEventSupplierListAction, number> = {
    edit: {
      label: 'Edit',
      onClick: () => setEditDialogOpen(true),
    },
    delete: {
      label: 'Delete',
      onClick: () => setConfirmationDialogOpen(true),
    },
    selectWinner: {
      label: 'Change winner',
      onClick: () => confirmOrChangeWinner('change'),
    },
    confirmWinner: {
      label: 'Confirm winner',
      onClick: () => confirmOrChangeWinner('confirm'),
    },
    disable: {
      label: 'Disable',
      onClick: () => toggleIsNegotiationEventSupplierEnabled(false),
    },
    enable: {
      label: 'Enable',
      onClick: () => toggleIsNegotiationEventSupplierEnabled(true),
    },
  }

  return (
    <>
      <ActionsButton
        id={supplier.id}
        allowedActions={actions}
        actionConfig={actionsConfig}
        menuMinWidth={144}
        loading={isActionsButtonLoading}
      />
      <DeleteSupplierConfirmationModal
        open={confirmationDialogOpen}
        onCancel={() => setConfirmationDialogOpen(false)}
        onSubmit={() => setConfirmationDialogOpen(false)}
        supplier={supplier}
        negotiationEventId={negotiationEvent.id}
      />
      <UpdateSupplierDialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        supplier={supplier}
        negotiationEvent={negotiationEvent}
        useCase={useCase}
      />
    </>
  )
}
