import { useRef, useState } from 'react'
import {
  alpha,
  Button,
  ButtonGroup,
  lighten,
  Menu,
  MenuItem,
  MenuProps,
  styled,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ChatLink } from '@common/types/suite'
import { useBrandedChatLink } from '@shared/hooks/useBrandedChatLink'

type ChatLinksButtonProps = {
  chatLinks: ChatLink[]
}

export const ChatLinksButton = ({ chatLinks }: ChatLinksButtonProps) => {
  const [actionsOpen, setActionsOpen] = useState<HTMLElement | null>(null)
  const buttonRef = useRef<HTMLDivElement | null>(null)

  const { wrapChatLink } = useBrandedChatLink()

  return (
    <ActionButtonGroup ref={buttonRef}>
      <Button
        variant='contained'
        color='accent'
        onClick={() => setActionsOpen(buttonRef.current)}
        sx={{
          float: 'left',
          justifyContent: 'left',
        }}
      >
        Select demo
      </Button>
      <>
        <ArrowButton
          variant='contained'
          color='accent'
          aria-haspopup='menu'
          onClick={() => setActionsOpen(buttonRef.current)}
        >
          <ExpandMoreIcon fontSize='extra-small' />
        </ArrowButton>
        <StyledMenu
          open={!!actionsOpen}
          anchorEl={actionsOpen}
          onClose={() => setActionsOpen(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          sx={{ width: buttonRef.current?.offsetWidth }}
        >
          {chatLinks.map((link) => (
            <MenuItem
              key={link.name}
              sx={(theme) => ({
                color: 'common.white',
                py: 0.5,
                ':hover': { backgroundColor: alpha(theme.palette.common.white, 0.2) },
              })}
              onClick={() => {
                setActionsOpen(null)
                window.open(wrapChatLink(link.url))
              }}
            >
              {link.name}
            </MenuItem>
          ))}
        </StyledMenu>
      </>
    </ActionButtonGroup>
  )
}

const ActionButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  '& .MuiButtonGroup-grouped': {
    minWidth: theme.spacing(4),
  },
}))

const ArrowButton = styled(Button)(({ theme }) => ({
  padding: 0,
  border: 0,
  background: theme.palette.menu.main,
  '&:hover': {
    background: lighten(theme.palette.menu.main, 0.2),
  },
}))

const StyledMenu = styled(Menu)<MenuProps>(({ theme }) => ({
  marginTop: theme.spacing(0.25),
  borderRadius: theme.shape.borderRadius,
  '& .MuiMenu-paper': {
    background: theme.palette.menu.main,
  },
}))
