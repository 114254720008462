import MenuBookIcon from '@mui/icons-material/MenuBook'
import { NavigationItem } from '../main/types/suite-module'
import { SuitePermission } from '@common/types'

export const homeRouteWithoutOrg = '/merchandising/home'

export enum MerchandisingRoutes {
  SCENARIOS_LIST = '/:orgTag/merchandising/:projectTag/scenarios',
  DEPRECATED_SCENARIOS_LIST = '/:orgTag/merchandising/scenarios',
  SCENARIO_DETAILS = '/:orgTag/merchandising/:projectTag/scenario/:sessionKey',
  DEPRECATED_SCENARIO_DETAILS = '/:orgTag/merchandising/scenario/:sessionKey',
  SCENARIO_OVERVIEW = '/:orgTag/merchandising/:projectTag/scenario/:sessionKey/overview',
  DEPRECATED_SCENARIO_OVERVIEW = '/:orgTag/merchandising/scenario/:sessionKey/overview',
  SCENARIO_MODEL = '/:orgTag/merchandising/:projectTag/scenario/:sessionKey/model',
  CHANGE_REQUESTS_LIST = '/:orgTag/merchandising/change-requests',
  NEGOTIATION_PREPARATION_ADJUSTMENTS = '/:orgTag/merchandising/negotiation-preparation/:negotiationPreparationId/adjustments',
  NEGOTIATION_PREPARATION_MODEL = '/:orgTag/merchandising/negotiation-preparation/:negotiationPreparationId/model',
  COST_INCREASE = '/:orgTag/merchandising/cost-increase',
  COST_INCREASE_OVERVIEW = '/:orgTag/merchandising/cost-increase/:negotiationPreparationId/overview',
  COST_AVOIDANCE = '/:orgTag/merchandising/cost-avoidance',
  COST_AVOIDANCE_OVERVIEW = '/:orgTag/merchandising/cost-avoidance/:negotiationPreparationId/overview',
}

export const costDecreaseNavicationItems: NavigationItem[] = [
  {
    name: 'Scenarios',
    icon: () => <MenuBookIcon />,
    path: (activeOrgTag, activeProjectTag) =>
      `/${activeOrgTag}/merchandising/${activeProjectTag}/scenarios`,
    requiredPermission: SuitePermission.READ,
  },
]
