import { matchPath, useLocation } from 'react-router-dom'
import { SUITE_MODULES } from '../suite-modules'
import { isGenericSuiteModule } from '../types/suite-module'
import { AppRoutes } from '@constants'

export const useCurrentSuiteModule = () => {
  const location = useLocation()

  const genericSuiteModules = SUITE_MODULES.filter(isGenericSuiteModule)

  return genericSuiteModules.find((module) =>
    matchPath({ path: `${AppRoutes.ORG_TAG}${module.basePath}`, end: false }, location.pathname),
  )
}
