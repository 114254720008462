import { ChangeLog } from '@common/dto/platform/types'
import { suiteBaseApi } from '@shared/api/suite-base-api'

export const changelogApi = suiteBaseApi.injectEndpoints({
  endpoints: (build) => ({
    getChangelog: build.query<ChangeLog[], void>({
      query: () => `suite/changelog`,
    }),
  }),
})

export const { useGetChangelogQuery } = changelogApi
