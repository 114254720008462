import { UpdateUserDto, User, getDisplayNameFromRole, getRoleFromDisplayName } from '@common/types'
import { FormDialog } from '@components/FormDialog'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  AddOrEditUserFormSchema,
  AddOrEditUserSchema,
} from '@pages/SettingsPage/ManageUsersPage/AddOrEditUserSchema'
import { captureException } from '@sentry/react'
import { isBackendApiErrorResponse, isQuerySuccessResponse } from '@store'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useOrgTag } from '../../../../hooks/useOrgTag'
import {
  useGetOrganizationUserDetailsQuery,
  useUpdateOrganizationUserMutation,
} from '@store/userManagement'
import { EditUserModalForm } from './EditUserModalForm'

interface Props {
  isOpen: boolean
  user: User
  onClose: () => void
  refetchUsers: () => void
}

export const EditUserDialog = ({ isOpen, user, onClose, refetchUsers }: Props) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const form = useForm<AddOrEditUserFormSchema>({
    resolver: zodResolver(AddOrEditUserSchema),
    values: {
      name: user.name,
      email: user.email,
      role: getDisplayNameFromRole(user.role) ?? '',
      workspaces: user.projectIdsAccessList,
    },
  })
  const [updateUser] = useUpdateOrganizationUserMutation()
  const orgTag = useOrgTag()

  const { data: userDetails } = useGetOrganizationUserDetailsQuery({
    orgTag: orgTag,
    user: user,
  })
  const { enqueueSnackbar } = useSnackbar()

  const resetAndClose = () => {
    onClose()
    form.reset()
    setErrorMessage(null)
  }

  const onSubmit = async (data: AddOrEditUserFormSchema) => {
    const role = getRoleFromDisplayName(data.role)

    if (role === null) {
      const errorMessage = `Manage users: unknown user role ${data.role}`
      console.error(errorMessage)
      captureException(new Error(errorMessage))
      setErrorMessage(errorMessage)
    } else {
      let updateUserRequest: UpdateUserDto

      // we don't send name for the federated users as we can't change it
      if (userDetails?.federatedIdentity) {
        updateUserRequest = {
          userId: user.userId,
          role: role,
          projectIdsAccessList: data.workspaces,
        }
      } else {
        updateUserRequest = {
          userId: user.userId,
          name: data.name,
          role: role,
          projectIdsAccessList: data.workspaces,
        }
      }

      const response = await updateUser({
        orgTag: orgTag,
        user: updateUserRequest,
      })

      if (isQuerySuccessResponse(response)) {
        resetAndClose()
        enqueueSnackbar(`User ${data.name} updated successfully!`, {
          variant: 'success',
        })
        refetchUsers()
      } else {
        const message = isBackendApiErrorResponse(response.error)
          ? `Error updating user: ${response.error.data.message}`
          : 'Unknown error updating user'
        setErrorMessage(message)
      }
    }
  }

  return (
    <FormDialog
      title={'Edit user'}
      open={isOpen}
      onSubmit={onSubmit}
      form={form}
      onCancel={resetAndClose}
      buttons={[
        { type: 'cancel', label: 'Discard changes' },
        { type: 'submit', label: 'Save' },
      ]}
      error={errorMessage}
      resolver={zodResolver(AddOrEditUserSchema)}
    >
      <EditUserModalForm
        form={form}
        disallowUserNameChange={userDetails ? userDetails.federatedIdentity : true}
      />
    </FormDialog>
  )
}
