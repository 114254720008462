import { KBarProvider } from 'kbar'
import { CommandBar } from '@components/CommandBar/CommandBar'
import { CoreBackendPermission } from '@pactum/core-backend-types'
import { useProjects, useUserPermissions } from '@hooks'
import { useLayoutContext } from '@components/Layout'

interface Props {
  children: React.ReactNode
}

export const CommandBarProvider = ({ children }: Props) => {
  const { userPermissionsInAllOrgs } = useUserPermissions()
  const { activeOrg, activeProject, orgs } = useProjects()
  const { negotiationEvent } = useLayoutContext()

  // we load kbar only once the redirect to the default org has happened, many action rely on active org being present
  if (!userPermissionsInAllOrgs || !activeOrg) {
    return <>{children}</>
  }

  const isAdmin = userPermissionsInAllOrgs.has(CoreBackendPermission.AUTH_ADMIN)

  return (
    <KBarProvider>
      <CommandBar
        activeNegotationEvent={negotiationEvent}
        activeOrg={activeOrg}
        activeProject={activeProject}
        isAdmin={isAdmin}
        organizations={orgs}
      />
      {children}
    </KBarProvider>
  )
}
