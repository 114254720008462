import { QuickFilter } from '@components/QuickFilter'
import { Box, IconButton, Stack, styled, Typography } from '@mui/material'
import { GridToolbarColumnsButton, GridToolbarContainer } from '@mui/x-data-grid-pro'
import { Dispatch, SetStateAction } from 'react'
import { LAYOUT_CONTAINER_X_PADDING } from '@constants'
import { BatchActionsBar } from '@pages/NegotiationUnitsPage/BatchActionsBar'
import { NegotiationUnitEntity } from '@common/dto/pact/negotiation-unit-entity'
import { SelectAllNotification } from '@pages/NegotiationUnitsPage/SelectAllNotification'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

export const Toolbar = ({
  negotiationId,
  showBatchActions,
  showSelectAllNotification,
  selectedNegotiationUnits,
  allNegotiationUnitsCount,
  setAnchorElMenu,
  onSelectAllNotifications,
  onClearSelection,
  onSelectAllNotificationClose,
  navigateBack,
}: {
  negotiationId: number
  showBatchActions: boolean
  showSelectAllNotification: boolean
  selectedNegotiationUnits: NegotiationUnitEntity[]
  allNegotiationUnitsCount: number
  setAnchorElMenu: Dispatch<SetStateAction<HTMLButtonElement | null>>
  onSelectAllNotifications: () => void
  onClearSelection: () => void
  onSelectAllNotificationClose: () => void
  navigateBack: () => void
}) => (
  <ToolbarContainer>
    <Stack flexDirection='row' alignItems='center'>
      <Box position='relative' right={(theme) => theme.spacing(0.75)}>
        <IconButton onClick={navigateBack} sx={{ alignSelf: 'flex-start' }}>
          <ArrowBackIcon fontSize='small' sx={{ color: 'black' }} />
        </IconButton>
      </Box>
      <Typography variant='subtitle1'>Negotiation units</Typography>
    </Stack>
    {showBatchActions && (
      <BatchActionsBar
        negotiationId={negotiationId}
        allNegotiationsCount={allNegotiationUnitsCount}
        selectedNegotiationUnits={selectedNegotiationUnits}
      />
    )}
    <ColumnSelectButton ref={setAnchorElMenu} />
    <QuickFilter placeholder='Search negotiation units' />
    <SelectAllNotification
      show={showSelectAllNotification}
      allNegotiationUnitsCount={allNegotiationUnitsCount}
      selectedNegotiationUnitsCount={selectedNegotiationUnits.length}
      onSelectAll={onSelectAllNotifications}
      onClearSelection={onClearSelection}
      onClose={onSelectAllNotificationClose}
    />
  </ToolbarContainer>
)

const ColumnSelectButton = styled(GridToolbarColumnsButton)(() => ({
  marginLeft: 'auto',
}))

const ToolbarContainer = styled(GridToolbarContainer)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  marginLeft: '10px',
  paddingLeft: theme.spacing(LAYOUT_CONTAINER_X_PADDING),
}))
