import { NegotiationEvent } from '../../../store/types'
import { Formatter } from '@utils'
import { Box, Typography } from '@mui/material'
import { formatDate, formatDeadlineDate } from '../../../utils/formatting'

export const ProgressCaption = ({
  negotiationEvent,
  formatter,
}: {
  negotiationEvent: NegotiationEvent
  formatter: Formatter
}) => {
  if (negotiationEvent.negotiationsEndedAt) {
    return (
      <Box component='span'>
        <Typography variant='caption' color='textSecondary'>
          Completed {formatDate(negotiationEvent.negotiationsEndedAt, formatter)}
        </Typography>
      </Box>
    )
  }

  if (negotiationEvent.negotiationsStartedAt) {
    return (
      <Box component='span'>
        <Typography variant='caption' color='textSecondary'>
          {`Started ${formatDate(negotiationEvent.negotiationsStartedAt, formatter)}, `}
        </Typography>
        <Typography variant='caption' color='error'>
          deadline{' '}
          {formatDeadlineDate(
            negotiationEvent.negotiationsExpireTime,
            negotiationEvent.negotiationsExpireDays,
            formatter,
          )}
        </Typography>
      </Box>
    )
  }

  return (
    <Box component='span'>
      <Typography variant='caption' color='textSecondary'>
        {`Created ${formatDate(negotiationEvent.createdAt, formatter)}, `}
      </Typography>
      <Typography variant='caption' color='error'>
        deadline{' '}
        {formatDeadlineDate(
          negotiationEvent.negotiationsExpireTime,
          negotiationEvent.negotiationsExpireDays,
          formatter,
        )}
      </Typography>
    </Box>
  )
}
