import { Chart, Plugin } from 'chart.js'

export const inspectorLinePlugin: Plugin = {
  id: 'inspector',
  afterDraw: (chart: Chart, _args: Record<string, never>, options: InspectorOptions) => {
    if (options.display && chart.tooltip?.opacity && chart.tooltip?.opacity > 0) {
      const { ctx } = chart
      const { caretX } = chart.tooltip
      const topY = chart.scales.y.top
      const bottomY = chart.scales.y.bottom

      ctx.save()
      ctx.setLineDash([3, 3])
      ctx.beginPath()
      ctx.moveTo(caretX, topY - 5)
      ctx.lineTo(caretX, bottomY)
      ctx.lineWidth = 1
      ctx.strokeStyle = '#607E85'
      ctx.stroke()
      ctx.restore()
    }
  },
}

export interface InspectorOptions {
  display?: boolean
}
