import { styled, Tab } from '@mui/material'

export const PactumTab = styled(Tab)(({ theme }) => ({
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  minHeight: 'unset',
  color: theme.palette.text.secondary,
  textTransform: 'none',
  fontWeight: 'bold',
  '&.Mui-selected': {
    color: theme.palette.accent.main,
  },
}))
