import { useState } from 'react'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import { MarkAsSignedConfirmationModal } from 'src/shared/components/ConfirmationModal'
import { BatchActionButton } from './BatchActionButton'
import { CommercialTermsNegotiation } from '@campaigns/types/negotiation'

export type DownloadActionProps = {
  enabled: boolean
  totalNegotiationsCount: number
  actionableNegotiations: CommercialTermsNegotiation[]
  onExecute: () => void
}

export const MarkAsSignedAction = ({
  enabled,
  totalNegotiationsCount,
  actionableNegotiations,
  onExecute,
}: DownloadActionProps) => {
  const [isModalOpen, setModalOpen] = useState(false)

  return (
    <>
      <BatchActionButton
        startIcon={<BorderColorIcon />}
        disabled={!enabled}
        onClick={() => setModalOpen(true)}
      >
        Mark as signed
      </BatchActionButton>
      <MarkAsSignedConfirmationModal
        allNegotiations={totalNegotiationsCount}
        actionableNegotiations={actionableNegotiations.length}
        open={isModalOpen}
        onCancel={() => setModalOpen(false)}
        onSubmit={() => {
          setModalOpen(false)
          onExecute()
        }}
      />
    </>
  )
}
