import { NavigationItem } from 'src/main/types/suite-module'
import DashboardIcon from '@mui/icons-material/Dashboard'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import { SuitePermission } from '@common/types'
import { Money } from '@mui/icons-material'

export enum PriceListRoutes {
  DASHBOARD = '/:orgTag/price-list/:projectTag/dashboard',
  NEGOTIATION_EVENTS = '/:orgTag/price-list/:projectTag/negotiation-events',
  NEGOTIATION_EVENT_DETAILS = '/:orgTag/price-list/:projectTag/negotiation-events/:negotiationEventId',
  NEGOTIATION_LIST = '/:orgTag/price-list/:projectTag/negotiations',
  NEGOTIATION_UNITS = '/:orgTag/price-list/:projectTag/negotiations/:negotiationId/units',
}

export const priceListNavigationItems: NavigationItem[] = [
  {
    name: 'Negotiation events',
    icon: () => <MenuBookIcon />,
    path: (activeOrgTag, activeProjectTag) =>
      `/${activeOrgTag}/price-list/${activeProjectTag}/negotiation-events`,
    requiredPermission: SuitePermission.MODIFY,
  },
  {
    name: 'Negotiations',
    icon: () => <Money />,
    path: (activeOrgTag, activeProjectTag) =>
      `/${activeOrgTag}/price-list/${activeProjectTag}/negotiations`,
    requiredPermission: SuitePermission.MODIFY,
  },
  {
    name: 'Dashboard',
    icon: () => <DashboardIcon />,
    path: (activeOrgTag, activeProjectTag) =>
      `/${activeOrgTag}/price-list/${activeProjectTag}/dashboard`,
    requiredPermission: SuitePermission.READ,
  },
]
