import { NegotiationEvent, NegotiationEventSupplier } from '../../../store/types'
import { DataGrid, dataGridProps } from '@components/table'
import { GridColDef, GridEventListener, GridRenderCellParams } from '@mui/x-data-grid-pro'
import { useFormatter } from '@shared/hooks'
import { useTheme } from '@mui/material'
import { NegotiationStatusChip } from '@procurement-shared/NegotiationStatusChip/NegotiationStatusChip'
import {
  renderActionsCell,
  renderDeliveryDateCell,
  renderPaymentTermsCell,
  renderSavingsCell,
  renderSupplierCell,
  renderTotalCostCell,
  setRowClassName,
} from '@procurement/utils/supplierList'

interface SuppliersListProps {
  negotiationEvent: NegotiationEvent
  selectSupplier: (index: number) => void
}

export const SuppliersList = ({ negotiationEvent, selectSupplier }: SuppliersListProps) => {
  const theme = useTheme()
  const formatter = useFormatter()
  const { suppliers, currency } = negotiationEvent

  const handleCellClick: GridEventListener<'cellClick'> = (cell) => {
    const actionableFields = ['__actions__', '__check__']

    if (!actionableFields.includes(cell.field)) {
      const supplier = cell.row as NegotiationEventSupplier
      const index = suppliers.indexOf(supplier)

      selectSupplier(index)
    }
  }

  return (
    <>
      <DataGrid
        {...dataGridProps}
        disableColumnMenu={true}
        sx={{
          mb: 5,
          '& .winner-row': {
            color: '#0B8E18',
            fontWeight: 'bold',
          },
          '& .disabled-row': {
            color: theme.palette.grey[400],
          },
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'id', sort: 'asc' }],
          },
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        columns={
          [
            { field: 'id', headerName: 'ID', flex: 0.5 },
            {
              field: 'name',
              headerName: 'Supplier',
              flex: 1,
              renderCell: (params: GridRenderCellParams) => renderSupplierCell(params),
            },
            {
              field: 'totalCost',
              headerName: 'Total cost',
              flex: 1,
              renderCell: (params: GridRenderCellParams) =>
                renderTotalCostCell(params, currency, formatter),
            },
            {
              field: 'paymentDays',
              headerName: 'Payment days',
              flex: 1,
              renderCell: (params: GridRenderCellParams) =>
                renderPaymentTermsCell(params, currency, formatter),
            },
            {
              field: 'savings',
              headerName: 'Total savings',
              flex: 1,
              renderCell: (params: GridRenderCellParams) =>
                renderSavingsCell(params, currency, formatter),
            },
            {
              field: 'deliveryDate',
              headerName: 'Delivery date',
              flex: 1,
              renderCell: (params: GridRenderCellParams) =>
                renderDeliveryDateCell(params.row, negotiationEvent.deliveryDate, formatter),
            },
            {
              field: 'negotiationStatus',
              headerName: 'Negotiation status',
              flex: 1,
              type: 'string',
              renderCell: (params) => (
                <NegotiationStatusChip negotiationSummary={params.row.negotiation} />
              ),
            },
            {
              field: 'actions',
              headerName: 'Actions',
              flex: 1.2,
              renderCell: (params: GridRenderCellParams) =>
                renderActionsCell(params, negotiationEvent),
            },
          ] as GridColDef<NegotiationEventSupplier>[]
        }
        onCellClick={handleCellClick}
        rows={suppliers}
        getRowClassName={(params) => setRowClassName(params)}
      />
    </>
  )
}
