import React from 'react'
import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'

interface WidgetSectionProps {
  title: string
  subtitle?: string
  description?: string
  wide?: boolean
  filters?: React.ReactNode
  children: React.ReactNode
}

export const WidgetSection = ({
  title,
  subtitle,
  description,
  filters,
  children,
  wide,
}: WidgetSectionProps) => (
  <Grid item xs={12} lg={wide ? 12 : 6}>
    <Box pt={5} pb={wide ? 7 : 0}>
      <Stack direction='row' justifyContent='space-between'>
        <Stack direction='row' alignItems='center'>
          <Typography variant='subtitle2'>{title}</Typography>
          {description ? (
            <Tooltip title={description} placement='top' arrow>
              <ErrorIcon sx={{ color: 'text.secondary', fontSize: '1rem', ml: 1 }} />
            </Tooltip>
          ) : null}
        </Stack>
        {filters}
      </Stack>
      {subtitle ? (
        <Box>
          <Typography
            variant='body1'
            sx={{
              fontWeight: 'bold',
              fontSize: 12,
              padding: '20px 0px 0px 10px',
              color: '#999999',
            }}
          >
            {subtitle}
          </Typography>
        </Box>
      ) : null}
      <Box pt={2}>{children}</Box>
    </Box>
  </Grid>
)
