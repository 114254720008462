import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material'
import { Chart } from 'react-chartjs-2'
import { useNegotiationPreparationData } from 'src/merchandising/pages/ChangeRequests/NegotiationPreparation/NegotiationPreparationPage'
import { useFormatter } from '@shared/hooks'
import { Article, NegotiationAnalysis, ArticleHistoricalData } from '@common/types'
import { colors } from './colors'
import { useMemo, useState } from 'react'
import { ChartOptions, TooltipItem } from 'chart.js'
import { Formatter, dateFormats } from '@utils'

const getLabels = (
  formatter: Formatter,
  historicalData: NegotiationAnalysis['historical'],
  graph: keyof ArticleHistoricalData,
) =>
  Object.values(historicalData!)[0]?.[graph].map((datapoint) =>
    formatter.date(new Date(datapoint.month), dateFormats.monthAndYear, {
      slashSeparators: true,
    }),
  )

const getEanToArticleNameMap = (articles: Article[]) =>
  articles.reduce(
    (acc, article) => ({
      ...acc,
      [article.ean]: article.name,
    }),
    {} as { [key: string]: string },
  )

const getPriceDatasets = (
  historicalData: NegotiationAnalysis['historical'],
  eanToArticleNameMap: { [key: keyof NonNullable<NegotiationAnalysis['historical']>]: string },
  colorMap: Record<keyof NonNullable<NegotiationAnalysis['historical']>, string>,
) => {
  return Object.keys(historicalData!)
    .sort()
    .map((ean) => {
      const data = historicalData![ean].retailPrice.map(({ value }) => value)

      return {
        label: `${eanToArticleNameMap[ean]} (${ean})`,
        data,
        borderColor: colorMap[ean],
      }
    })
}

const getBillingMarginDatasets = (
  historicalData: NegotiationAnalysis['historical'],
  eanToArticleNameMap: { [key: keyof NonNullable<NegotiationAnalysis['historical']>]: string },
  colorMap: Record<keyof NonNullable<NegotiationAnalysis['historical']>, string>,
) => {
  return Object.keys(historicalData!)
    .sort()
    .map((ean) => {
      const data = historicalData![ean].billingMargin.map(({ value }) => value)

      return {
        type: 'line' as const,
        label: `${eanToArticleNameMap[ean]} (${ean})`,
        data,
        borderColor: colorMap[ean],
      }
    })
}

const getRPPDatasets = (
  historicalData: NegotiationAnalysis['historical'],
  eanToArticleNameMap: { [key: keyof NonNullable<NegotiationAnalysis['historical']>]: string },
  colorMap: Record<keyof NonNullable<NegotiationAnalysis['historical']>, string>,
) => {
  return Object.keys(historicalData!)
    .sort()
    .map((ean) => {
      const data = historicalData![ean].rpp.map(({ value }) => value)

      return {
        type: 'line' as const,
        label: `${eanToArticleNameMap[ean]} (${ean})`,
        data,
        borderColor: colorMap[ean],
      }
    })
}

const getChartOptions = (formatter: (value: number) => string) =>
  ({
    scales: {
      yAxes: {
        ticks: {
          callback: (value) => formatter(value as number),
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem: TooltipItem<'line'>) =>
            `${tooltipItem.dataset.label}: ${formatter(tooltipItem.raw as number)}`,
        },
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
  }) as ChartOptions

interface ArticleSelectorElement {
  value: string
  label: string
}

const getHideArticleOptions = (articles: Article[]): ArticleSelectorElement[] =>
  articles.map((article) => ({ value: article.ean, label: `${article.name} (${article.ean})` }))

const getSelectedHistoricalData = (
  historicalData: NegotiationAnalysis['historical'],
  selection: ArticleSelectorElement[],
): NegotiationAnalysis['historical'] => {
  const eanSelection = selection.map((element) => element.value)

  if (!historicalData) return undefined

  return Object.fromEntries(
    Object.entries(historicalData).filter(([ean]) => eanSelection.includes(ean)),
  )
}

const getColorMap = (
  historicalData: NegotiationAnalysis['historical'],
): Record<keyof NonNullable<NegotiationAnalysis['historical']>, string> => {
  return historicalData
    ? Object.entries(historicalData).reduce(
        (acc, [ean], currentIndex) => {
          acc[ean] = colors[currentIndex]
          return acc
        },
        {} as Record<keyof NonNullable<NegotiationAnalysis['historical']>, string>,
      )
    : {}
}

export const HistoricalNumbersGraph = (): JSX.Element | null => {
  const formatter = useFormatter()
  const { data } = useNegotiationPreparationData()
  const [shownArticles, setShownArticles] = useState<{ value: string; label: string }[]>(
    getHideArticleOptions(data.articles),
  )
  const colorList = useMemo(() => getColorMap(data.analysis.historical), [data])

  const shownHistoricalData = useMemo(
    () => getSelectedHistoricalData(data.analysis.historical, shownArticles),
    [data, shownArticles],
  )

  if (!data.analysis.historical) {
    console.warn('No Historical Numbers data')
    return null
  }

  const eanToArticleNameMap = getEanToArticleNameMap(data.articles)
  return (
    <Box mt={4}>
      <Typography variant='h5' mb={2}>
        Historical Numbers
      </Typography>
      <Autocomplete
        multiple
        sx={{ minWidth: '50%', mb: 2 }}
        options={getHideArticleOptions(data.articles)}
        renderInput={(params) => <TextField {...params} label='Shown items' />}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        value={shownArticles}
        onChange={(_, selectedItems) => {
          setShownArticles([...selectedItems])
        }}
      />
      <Grid container spacing={2} mt={4}>
        <Grid item sm={12} lg={6}>
          <Typography variant='h6' mb={2}>
            Retail price
          </Typography>
          <Chart
            type={'line' as const}
            data={{
              labels: getLabels(formatter, shownHistoricalData, 'retailPrice'),
              datasets: getPriceDatasets(shownHistoricalData, eanToArticleNameMap, colorList),
            }}
            options={getChartOptions(formatter.currency)}
          />
        </Grid>
        <Grid item sm={12} lg={6}>
          <Typography variant='h6' mb={2}>
            Billing margin
          </Typography>
          <Chart
            type={'line' as const}
            data={{
              labels: getLabels(formatter, shownHistoricalData, 'billingMargin'),
              datasets: getBillingMarginDatasets(
                shownHistoricalData,
                eanToArticleNameMap,
                colorList,
              ),
            }}
            options={getChartOptions(formatter.percent0To1)}
          />
        </Grid>
        <Grid item sm={12} lg={6}>
          <Typography variant='h6' mb={2}>
            RPP
          </Typography>
          <Chart
            type={'line' as const}
            data={{
              labels: getLabels(formatter, shownHistoricalData, 'rpp'),
              datasets: getRPPDatasets(shownHistoricalData, eanToArticleNameMap, colorList),
            }}
            options={getChartOptions(formatter.currency)}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
