import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { PermissionType } from '@common/types/AuthPermissions'
import { AppRoutes } from '@constants'
import { useUserPermissions } from '@hooks'
import { LoadingPage } from 'src/main/pages/LoadingPage'

interface Props {
  requiredPerm?: PermissionType
  anyOf?: PermissionType[]
  children: React.ReactNode
}

export const ProtectedRoute = ({ requiredPerm, anyOf, children }: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true)
  const [isAllowed, setIsAllowed] = useState(false)
  const { userPermissionsInActiveOrg } = useUserPermissions()

  useEffect(() => {
    const checkPermissions = async () => {
      if (requiredPerm) {
        setIsAllowed(userPermissionsInActiveOrg.has(requiredPerm))
      } else if (anyOf) {
        setIsAllowed(anyOf.some((p) => userPermissionsInActiveOrg.has(p)))
      } else {
        setIsAllowed(false)
      }

      setIsLoading(false)
    }

    checkPermissions()
  }, [userPermissionsInActiveOrg, requiredPerm, anyOf])

  if (isLoading) {
    return <LoadingPage />
  }

  return isAllowed ? <>{children}</> : <Navigate to={AppRoutes.NOT_FOUND} replace={true} />
}
