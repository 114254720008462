import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { CircularProgress, Link } from '@mui/material'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { isBackendApiErrorResponse } from '@shared/backend/error/typeGuards'
import { useSnackbar } from 'notistack'
import { MouseEvent } from 'react'
import { useBrandedChatLink } from '@shared/hooks/useBrandedChatLink'
import { Negotiation, NegotiationEvent } from '../../store/types'
import { useCreateNegotiationPreviewLinkMutation } from '../../store/price-list'
import { FINAL_NEGOTIATION_STATUSES } from '../../constants'

interface NegotiationLinkProps {
  negotiationEvent: NegotiationEvent
  negotiation: Negotiation | null | undefined
}

export const NegotiationLink = ({ negotiationEvent, negotiation }: NegotiationLinkProps) => {
  const { activeProjectTag: projectTag } = useActiveProject()
  const { enqueueSnackbar } = useSnackbar()
  const [createNegotiationPreviewLink, { isLoading }] = useCreateNegotiationPreviewLinkMutation()

  const onCreateLink = async (event: MouseEvent) => {
    event.preventDefault()

    try {
      const { chatLink } = await createNegotiationPreviewLink({
        projectTag,
        negotiationEventId: negotiationEvent.id,
      }).unwrap()

      openLinkInNewTab(chatLink)
    } catch (error) {
      const errorMessage = isBackendApiErrorResponse(error)
        ? error?.data.message
        : (error as Error)?.message

      enqueueSnackbar(errorMessage, { variant: 'error' })
    }
  }

  const getLinkParameters = () => {
    const negotiationHasStarted = FINAL_NEGOTIATION_STATUSES.includes(negotiationEvent.status)

    if (negotiationHasStarted) {
      return {
        label: 'Review negotiation',
        href: negotiation?.chatLinks.readOnly,
        onLinkClick: () => {},
      }
    }

    return {
      label: 'Try out negotiation',
      onLinkClick: onCreateLink,
    }
  }

  const { label, href, onLinkClick } = getLinkParameters()

  const { wrapChatLink } = useBrandedChatLink()

  return (
    <Link
      href={href ? wrapChatLink(href) : undefined}
      target='_blank'
      underline='hover'
      onClick={onLinkClick}
      sx={{ cursor: 'pointer' }}
    >
      {isLoading ? (
        <CircularProgress
          size={12}
          thickness={5}
          sx={{ mr: 0.8, color: 'inherit', fontSize: 'inherit', verticalAlign: 'middle' }}
        />
      ) : (
        <OpenInNewIcon
          sx={{ mr: 0.5, color: 'inherit', fontSize: 'inherit', verticalAlign: 'middle' }}
        />
      )}
      {label}
    </Link>
  )
}

const openLinkInNewTab = (url: string) => {
  const link = document.createElement('a')
  link.target = '_blank'
  link.href = url
  document.body.appendChild(link)
  link.click()
  link.remove()
}
