export const BASE_COLOR = {
  r: 141,
  g: 168,
  b: 174,
}

export const getColorVariations = (baseColor: typeof BASE_COLOR, numberOfVariations: number) => {
  if (numberOfVariations === 0) {
    return []
  }

  if (numberOfVariations === 1) {
    return [colorToColorString(baseColor)]
  }

  const stepSize = Math.round(255 / numberOfVariations / 2)
  const midPoint = numberOfVariations / 2
  const numberOfLightVariations = Number.isInteger(midPoint) ? midPoint - 1 : Math.floor(midPoint)
  const numberOfDarkVariations = Number.isInteger(midPoint) ? midPoint : Math.floor(midPoint)

  const lightVariations = [...new Array<undefined>(numberOfLightVariations)]
    .map((val, idx) =>
      colorToColorString({
        r: Math.min(255, baseColor.r + stepSize * (idx + 1)),
        g: Math.min(255, baseColor.g + stepSize * (idx + 1)),
        b: Math.min(255, baseColor.b + stepSize * (idx + 1)),
      }),
    )
    .reverse()

  const darkVariations = [...new Array<undefined>(numberOfDarkVariations)].map((val, idx) =>
    colorToColorString({
      r: Math.max(0, baseColor.r - stepSize * (idx + 1)),
      g: Math.max(0, baseColor.g - stepSize * (idx + 1)),
      b: Math.max(0, baseColor.b - stepSize * (idx + 1)),
    }),
  )

  return [...lightVariations, colorToColorString(baseColor), ...darkVariations]
}

const colorToColorString = (color: typeof BASE_COLOR) => `rgb(${color.r}, ${color.g}, ${color.b})`
