import { PrimaryCustomDeal } from '@common/types'
import { InputError } from 'src/merchandising/types'

export const validateTitle = (title: string): InputError => {
  if (!isTitleValid(title)) {
    return {
      isError: true,
      error: 'Cannot be empty',
    }
  }
  return {
    isError: false,
  }
}

export const isTitleValid = (title: string) => title.length > 0

export const isAlternativeDealValid = (deal: PrimaryCustomDeal) =>
  !(deal.alternativeDeal && !isTitleValid(deal.alternativeDeal.title))
