import { List, ListItem, styled, Typography } from '@mui/material'
import { ReactNode } from 'react'

interface Props {
  children: ReactNode
}

export const NumberedList = ({ children }: Props) => <StyledList>{children}</StyledList>

export const NumberedListItem = ({ children }: Props) => (
  <ListItem sx={{ fontWeight: 'normal' }}>
    <Typography variant={'normal'}>{children}</Typography>
  </ListItem>
)

const StyledList = styled(List)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  listStyleType: 'decimal',

  '.MuiListItem-root': {
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    display: 'list-item',
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
  },
}))
