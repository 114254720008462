import { ActionConfigMap } from '@components/ActionsButton'
import { useSuiteDataGrid } from '@components/SuiteDataGrid/SuiteDataGridProvider'
import { DataGrid, dataGridProps } from '@components/table'
import { ROWS_PER_PAGE_OPTIONS } from '@components/table/pagination'
import { LogisticsRoutes } from '@logistics/routes'
import {
  useDisableSingleSupplierMutation,
  useEnableSingleSupplierMutation,
  useGetSupplierListQuery,
} from '@logistics/store/supplierApi'
import { Supplier, SupplierAction, SupplierWithUnitCount } from '@logistics/types/Supplier'
import { styled } from '@mui/material'
import { DataGridPro, GridRowClassNameParams } from '@mui/x-data-grid-pro'
import { useFormatter } from '@shared/hooks'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useSnackbar } from 'notistack'
import { generatePath, useNavigate } from 'react-router-dom'
import { createColumns } from './columns'
import { NoSuppliers } from './NoSuppliers'

const DEFAULT_PAGE_SIZE = 50

export const SupplierList = () => {
  const { activeProjectTag, activeOrgTag } = useActiveProject()
  const gridState = useSuiteDataGrid({ defaultPageSize: DEFAULT_PAGE_SIZE })

  const paginationModel = gridState.pagination
  const setPaginationModel = gridState.setPagination

  const [enableSingleSupplier] = useEnableSingleSupplierMutation()
  const [disableSingleSupplier] = useDisableSingleSupplierMutation()
  const formatter = useFormatter()

  const {
    data: supplierData,
    isLoading,
    isFetching,
  } = useGetSupplierListQuery({
    projectTag: activeProjectTag,
    skip: paginationModel.page * paginationModel.pageSize,
    limit: paginationModel.pageSize,
  })

  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const supplierActionConfig: ActionConfigMap<SupplierAction, Supplier['id']> = {
    [SupplierAction.DETAILS]: {
      label: 'Details',
      onClick: (id: string) =>
        navigate(
          generatePath(LogisticsRoutes.SUPPLIER_DETAILS, {
            id: id,
            orgTag: activeOrgTag,
            projectTag: activeProjectTag,
          }),
        ),
    },
    [SupplierAction.ENABLE]: {
      label: 'Enable',
      onClick: async (id: string) => {
        await enableSingleSupplier({ supplierId: id, projectTag: activeProjectTag })
        enqueueSnackbar('Successfully enabled carrier', {
          variant: 'success',
          autoHideDuration: 2000,
        })
      },
    },
    [SupplierAction.DISABLE]: {
      label: 'Disable',
      onClick: async (id: string) => {
        await disableSingleSupplier({ supplierId: id, projectTag: activeProjectTag })
        enqueueSnackbar('Successfully disabled carrier', {
          variant: 'success',
          autoHideDuration: 2000,
        })
      },
    },
  }

  const columns = createColumns(supplierActionConfig, formatter)

  if (!isLoading && supplierData?.total === 0) {
    return <NoSuppliers />
  }

  return (
    <StyledDataGrid
      {...dataGridProps}
      sx={{
        '& .disabled': {
          color: 'text.disabled',
        },
      }}
      pagination
      hideFooter={false}
      paginationMode='server'
      pageSizeOptions={ROWS_PER_PAGE_OPTIONS}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      columns={columns}
      loading={isFetching}
      rowCount={supplierData?.total ?? 0}
      rows={supplierData?.items ?? []}
      getRowId={(row: SupplierWithUnitCount) => row.id}
      getRowClassName={(params) => setRowClassName(params)}
    />
  )
}

const StyledDataGrid = styled(DataGrid)(() => ({
  '& .MuiDataGrid-row:hover': {
    backgroundColor: 'unset',
    cursor: 'unset',
  },
})) as typeof DataGridPro

function setRowClassName(params: GridRowClassNameParams<Supplier>) {
  return params.row.isEnabled ? '' : 'disabled'
}
