import { Contract, CustomTerm as CustomTermType } from '@campaigns/types'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useGetProjectConfigQuery } from '../../store'
import { Box, Grid } from '@mui/material'
import { CustomTermConfiguration, CustomTermDisplayType } from '@campaigns/types/custom-terms'
import { useFormatter } from '@shared/hooks'
import { Formatter } from '@utils'
import { basicFont } from './NegotiationDetailsView'

export const CustomTerms = ({ contract, currency }: { contract: Contract; currency: string }) => {
  const { activeProjectTag } = useActiveProject()

  const { data: projectConfig } = useGetProjectConfigQuery(activeProjectTag)
  const customTerms = projectConfig?.customTerms

  if (!customTerms) {
    return null
  }

  if (!contract.customTerms) {
    return null
  }

  return (
    <Box mt={5}>
      <Grid container mt={1}>
        {['Term', 'Previous', 'Agreed'].map((label) => (
          <Grid
            key={label}
            item
            xs={4}
            mt={2}
            sx={{ ...basicFont, color: 'text.secondary', mb: 0.75 }}
          >
            {label}
          </Grid>
        ))}
      </Grid>
      <Grid container mt={1}>
        {Object.entries(contract.customTerms)
          .filter(([key]) => customTerms[key])
          .map(([key, value]) => (
            <CustomTerm
              key={key}
              value={value}
              customTermConfig={customTerms[key]}
              currency={currency}
            />
          ))}
      </Grid>
    </Box>
  )
}

const CustomTerm = ({
  value,
  customTermConfig,
  currency,
}: {
  value: CustomTermType
  customTermConfig: CustomTermConfiguration
  currency: string
}) => {
  const formatter = useFormatter()

  const { previous, agreed, label } = value

  return (
    <>
      <Grid container mt={1}>
        <Grid item xs={4} mt={2} sx={{ fontWeight: 700 }}>
          {label}
        </Grid>
        <Grid item xs={4} mt={2}>
          {previous !== null
            ? formatCustomTermValue(previous, customTermConfig.type, formatter, currency)
            : 'N/A'}
        </Grid>
        <Grid item xs={4} mt={2}>
          {agreed !== null
            ? formatCustomTermValue(agreed, customTermConfig.type, formatter, currency)
            : 'N/A'}
        </Grid>
      </Grid>
    </>
  )
}

const formatCustomTermValue = (
  value: number,
  type: CustomTermDisplayType,
  formatter: Formatter,
  currency: string,
): string => {
  switch (type) {
    case 'number':
      return formatter.number(value)
    case 'currency':
      return formatter.currency(value, { currency })
    case 'percentage':
      return formatter.percent0To1(value / 100, { fractionDigits: 1 })
  }
}
