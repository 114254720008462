import { Typography } from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { GridActionsCellItem, GridColDef, GridEditInputCell } from '@mui/x-data-grid-pro'
import { useFormContext } from 'react-hook-form'
import { useFormatter } from '@shared/hooks'
import {
  adjustTotalForAlternative,
  getContractLengthOptions,
} from '@procurement/utils/contractLength'
import { ContractLengthAlternative } from '@procurement/store/types'
import { useAlternativeContractLength } from '../../hooks/useAlternativeContractLength'
import { NegotiationEventFormData } from '../../schema'
import { ContractLengthGrid } from './ContractLengthGrid'
import { calculateSpendAmount } from '@procurement/utils/spend-calculation'

const MAX_CONTRACT_LENGTH_MONTHS = 60
const DEFAULT_REBATE_PERCENTAGE = 2

export const ContractLengthAlternativesRebates = () => {
  const formatter = useFormatter()
  const { watch } = useFormContext<NegotiationEventFormData>()
  const [previousContractLength, contractLength, currency, spendAmount] = watch([
    'suppliers.0.negotiationSettings.previousContractLength',
    'suppliers.0.negotiationSettings.contractLength',
    'currency',
    'spendAmount',
  ])

  const contractLengthOptions = getContractLengthOptions(contractLength, MAX_CONTRACT_LENGTH_MONTHS)
  const { onRowAdd, onRowUpdate, onRowDelete } = useAlternativeContractLength({
    defaultPriceChangePercentage: DEFAULT_REBATE_PERCENTAGE,
    maxContractLength: MAX_CONTRACT_LENGTH_MONTHS,
    rebates: true,
  })

  const columns: GridColDef<ContractLengthAlternative>[] = [
    {
      ...commonColumnProps,
      field: 'contractLengthMonths',
      editable: Boolean(contractLength),
      headerName: 'Agreement length',
      type: 'singleSelect',
      valueOptions: contractLengthOptions,
      valueFormatter: ({ value }) => `${value} months`,
    },
    {
      ...commonColumnProps,
      field: 'priceChangePercentage',
      editable: Boolean(contractLength),
      headerName: 'Minimum acceptable rebate',
      type: 'number',
      align: 'right',
      headerAlign: 'right',
      renderCell: ({ row: { priceChangePercentage } }) => `${priceChangePercentage}%`,
      renderEditCell: (params) => (
        <GridEditInputCell {...params} inputProps={{ min: 0, max: 100 }} />
      ),
    },
    {
      ...commonColumnProps,
      field: '-',
      editable: false,
      headerName: 'Total estimated spend',
      align: 'right',
      headerAlign: 'right',
      renderCell: ({ row: { contractLengthMonths, priceChangePercentage } }) => {
        const totalSpendAmount = calculateSpendAmount({
          spendItemUnitPrice: spendAmount ?? 0,
          spendDurationMonths: contractLength,
          agreementDurationMonths: previousContractLength,
        })

        if (!contractLength || !totalSpendAmount) {
          return null
        }

        const alternativeSpendAmount = adjustTotalForAlternative({
          baseContractLength: contractLength,
          total: totalSpendAmount,
          alternativeContractLength: Number(contractLengthMonths),
          priceChangePercentage: Number(priceChangePercentage),
          priceChangeDirection: 'decrease',
        })

        return (
          <Typography color='GrayText' variant='body2'>
            {formatter.currency(alternativeSpendAmount, { currency })}
          </Typography>
        )
      },
    },
    {
      field: 'actions',
      type: 'actions',
      editable: true,
      flex: 0.25,
      align: 'right',
      getActions: ({ id }) => [
        <GridActionsCellItem
          icon={<DeleteOutlineIcon />}
          label='Delete'
          onClick={() => onRowDelete(Number(id))}
        />,
      ],
    },
  ]

  return <ContractLengthGrid columns={columns} onRowAdd={onRowAdd} onRowUpdate={onRowUpdate} />
}

const commonColumnProps = {
  disableColumnMenu: true,
  flex: 1,
}
