import { NegotiationStatus } from '@campaigns/types'

type StatusVisuals = {
  text: string
  backgroundColor: string
}

const statusMap: Record<NegotiationStatus, StatusVisuals> = {
  MISSING_DATA: {
    text: 'Missing data',
    backgroundColor: 'error.main',
  },
  PROCESSING: {
    text: 'Processing',
    backgroundColor: 'common.charts.eight',
  },
  READY: {
    text: 'Ready',
    backgroundColor: 'common.charts.third',
  },
  CONTACTED: {
    text: 'Contacted',
    backgroundColor: 'common.charts.eight',
  },
  SENT: {
    text: 'Negotiation sent',
    backgroundColor: 'common.charts.eight',
  },
  STARTED: {
    text: 'Started',
    backgroundColor: 'common.charts.fifth',
  },
  DEAL_REACHED: {
    text: 'Agreement reached',
    backgroundColor: 'success.main',
  },
  SIGNED: {
    text: 'Signed',
    backgroundColor: 'success.main',
  },
  NO_AGREEMENT: {
    text: 'Agreement not reached',
    backgroundColor: 'error.main',
  },
  CANCELLED: {
    text: 'Cancelled',
    backgroundColor: 'error.main',
  },
  STARTING_FAILED: {
    text: 'Starting failed',
    backgroundColor: 'error.light',
  },
}

export const allStatuses = () => Object.keys(statusMap) as NegotiationStatus[]
export const statusText = (status: NegotiationStatus) => statusMap[status].text

export const statusStyle = (status: NegotiationStatus) => ({
  backgroundColor: statusMap[status].backgroundColor,
})
