import { ItemData, TargetAndLacValues } from '@common/types'
import {
  getGridNumericOperators,
  GridApiCommon,
  GridFilterInputValueProps,
  GridSortCellParams,
} from '@mui/x-data-grid-pro'
import { getEmptyValueFilter, getNumberValueFilter } from './utils'
import { FilterInput } from './FilterInput'
import React from 'react'

export const operatorsNotNeedingValueInput = ['isEmpty', 'isNotEmpty']

export const laaComparator = (
  first: TargetAndLacValues,
  second: TargetAndLacValues,
  firstCellParams: GridSortCellParams,
  secondCellParams: GridSortCellParams,
) => {
  const api = firstCellParams.api as unknown as GridApiCommon
  // eslint-disable @typescript-eslint/no-unsafe-member-access
  const isFirstRowEnabled = (api.getRow(firstCellParams.id) as ItemData).enabled
  // eslint-disable @typescript-eslint/no-unsafe-member-access
  const isSecondRowEnabled = (api.getRow(secondCellParams.id) as ItemData).enabled

  const castA = isFirstRowEnabled ? (first.lac ?? -1) : -1
  const castB = isSecondRowEnabled ? (second.lac ?? -1) : -1

  return castA - castB
}

export const numericFilterOperators = getGridNumericOperators()
  .filter((operator) => !['isAnyOf'].includes(operator.value)) // not necessary operator
  .map((operator) => {
    if (operatorsNotNeedingValueInput.includes(operator.value)) {
      return {
        ...operator,
        getApplyFilterFn: getEmptyValueFilter(operator),
        InputComponent: FilterInput as React.JSXElementConstructor<GridFilterInputValueProps>,
      }
    }

    return {
      ...operator,
      getApplyFilterFn: getNumberValueFilter(operator),
      InputComponent: FilterInput as React.JSXElementConstructor<GridFilterInputValueProps>,
    }
  })
