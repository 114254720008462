import React, { useMemo, useState } from 'react'

interface UnitPageContextType {
  setIsCreateNegotiationModalOpen: (val: boolean) => void
  isCreateNegotiationModalOpen: boolean
}

const UnitPageContext = React.createContext<UnitPageContextType>({
  setIsCreateNegotiationModalOpen: () => {},
  isCreateNegotiationModalOpen: false,
})

const UnitPageContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [isCreateNegotiationModalOpen, setIsCreateNegotiationModalOpen] = useState(false)

  const providerValue = useMemo(
    () => ({
      isCreateNegotiationModalOpen,
      setIsCreateNegotiationModalOpen,
    }),
    [isCreateNegotiationModalOpen],
  )

  return <UnitPageContext.Provider value={providerValue}>{children}</UnitPageContext.Provider>
}

export { UnitPageContext, UnitPageContextProvider }
