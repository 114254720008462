import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  DataGridPro,
  GridFilterModel,
  GridPaginationModel,
  GridRowParams,
} from '@mui/x-data-grid-pro'
import { useFormatter } from '@shared/hooks'
import { DataGrid, dataGridProps } from '@components/table'
import { styled } from '@mui/material'
import { useGetSpotLanesWithNegotiationsQuery } from '@logistics/store/spotLanesApi'
import { SpotLaneLaneResponseDto, SpotLanesUnitStatus } from '@logistics/pages/spotLane/types'
import { LaneNegotiationsList } from '@logistics/pages/spotLane/ActiveLanes/LaneNegotiationsList'
import {
  createLaneColumns,
  STATUS_COLUMN_NAME,
} from '@logistics/pages/spotLane/ActiveLanes/laneColumns'
import { DEFAULT_PAGE_SIZE, ROWS_PER_PAGE_OPTIONS } from '@logistics/pages/spotLane/constants'
import { Toolbar } from '@logistics/pages/spotLane/ActiveLanes/Toolbar'
import { PaginationFooter } from '@components/table/pagination'

export const SpotLanesList = () => {
  const { activeProjectTag } = useActiveProject()

  const [statusFilter, setStatusFilter] = useState(SpotLanesUnitStatus.ACTIVE)

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  })

  const {
    data: spotLanesWithNegotiations,
    isLoading,
    isFetching,
    refetch: refetchSpotLanes,
  } = useGetSpotLanesWithNegotiationsQuery({
    projectTag: activeProjectTag,
    isExpired: statusFilter === SpotLanesUnitStatus.EXPIRED,
    isSuccessful: statusFilter === SpotLanesUnitStatus.SUCCESSFUL,
    skip: paginationModel.page * paginationModel.pageSize,
    limit: paginationModel.pageSize,
  })

  useEffect(() => {
    refetchSpotLanes()
  }, [statusFilter, refetchSpotLanes])

  const getLaneNegotiationsList = useCallback(
    (params: GridRowParams<SpotLaneLaneResponseDto>) => (
      <LaneNegotiationsList negotiations={params.row.negotiations} />
    ),
    [],
  )

  const formatter = useFormatter()

  const columns = useMemo(() => createLaneColumns(formatter), [formatter])

  const onFilterModelChange = (model: GridFilterModel) => {
    const statusColumn = model.items.find((item) => item.field === STATUS_COLUMN_NAME)

    if (!statusColumn) {
      setStatusFilter(SpotLanesUnitStatus.ACTIVE)
    }

    if (statusColumn && statusColumn.value in SpotLanesUnitStatus) {
      setStatusFilter(statusColumn.value)
    }
  }

  return (
    <StyledDataGrid
      {...dataGridProps}
      pagination
      hideFooter={false}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      onFilterModelChange={onFilterModelChange}
      paginationMode='server'
      columns={columns}
      loading={isLoading || isFetching}
      rows={spotLanesWithNegotiations?.items ?? []}
      rowCount={spotLanesWithNegotiations?.total ?? 0}
      className='dg-expandable'
      getRowId={(row: SpotLaneLaneResponseDto) => row.id}
      getDetailPanelHeight={() => 'auto'}
      getDetailPanelContent={getLaneNegotiationsList}
      slots={{
        ...dataGridProps.slots,
        toolbar: Toolbar,
        pagination: () => PaginationFooter({ rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS }),
      }}
    />
  )
}

const StyledDataGrid = styled(DataGrid)(() => ({
  '& .MuiDataGrid-row:hover': {
    backgroundColor: 'unset',
    cursor: 'unset',
  },
  '&.dg-expandable .MuiDataGrid-cell:first-of-type': {
    position: 'relative',
    left: '12px',
    justifyContent: 'unset',
  },
})) as typeof DataGridPro
