import { PaymentDayObject, NegotiationEventSupplier } from '../../../store/types'
import { DataCell, DataCellProps } from '@procurement-shared/DataCell/DataCell'
import { NoValueCell } from '@components/table'
import { getMinMaxPaymentDaysLabel } from '../../../utils/paymentDays'

export const MinMaxPaymentDaysCell = ({
  supplier,
  size,
  paymentDays,
}: {
  supplier: NegotiationEventSupplier
  size: DataCellProps['size']
  paymentDays: (number | PaymentDayObject)[]
}) => {
  const { minPaymentDays, maxPaymentDays } = supplier.negotiationSettings ?? {}
  if (!minPaymentDays || !maxPaymentDays) {
    return <NoValueCell />
  }

  const formatMinMaxPaymentDays = getMinMaxPaymentDaysLabel(paymentDays)
  return (
    <DataCell label='Min & max payment days' size={size}>
      {formatMinMaxPaymentDays(minPaymentDays)} - {formatMinMaxPaymentDays(maxPaymentDays)} days
    </DataCell>
  )
}
