import React from 'react'
import { NoEntities } from '@logistics/components/NoEntities'

export const NoUnits = ({ onNewNegotiation }: { onNewNegotiation: () => void }) => {
  return (
    <NoEntities
      title='It’s empty here. How about you try creating your first spot rate negotiation?'
      buttonText='New negotiation'
      onButtonClick={onNewNegotiation}
    />
  )
}
