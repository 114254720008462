import { BrandingStatus, LocalizedBranding } from '@pactum/core-backend-types'
import { z } from 'zod'

export const PACTUM_PRIMARY_COLOR = '#092330'
export const PACTUM_SECONDARY_COLOR = '#A59080'

export const brandingContentSchema = z.object({
  chatSidebarName: z.string(),
  shortChatName: z.string(),
  clientName: z.string(),
  botAvatar: z.union([z.string(), z.instanceof(File)]).nullable(),
  botAvatarUrl: z.union([z.literal(''), z.string().trim().url()]).optional(),
  botAvatarLabel: z.string(),
  logo: z.union([z.string(), z.instanceof(File)]).nullable(),
  logoUrl: z.union([z.literal(''), z.string().trim().url()]).optional(),
  color: z.string().regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/),
  tableColor: z.string().regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/),
})

export type BrandingContentPayload = z.infer<typeof brandingContentSchema>

export const brandingSchema = z.object({
  label: z.string().min(1, 'Label is required'),
  status: z.nativeEnum(BrandingStatus).optional(),
  description: z.string().optional(),
  projectIds: z.array(z.number()),
})

export type BrandingPayload = z.infer<typeof brandingSchema>

export const brandingValidationSchema = brandingContentSchema.merge(brandingContentSchema)

export const createBrandingSchema = z.object({
  label: z.string().min(1, 'Label is required'),
  description: z.string().optional(),
})

export type CreateBrandingPayload = z.infer<typeof createBrandingSchema>

export const brandingContentFormDefaults = (data?: LocalizedBranding): BrandingContentPayload => ({
  chatSidebarName: data?.chatSidebarName ?? ' ',
  shortChatName: data?.shortChatName ?? ' ',
  clientName: data?.clientName ?? ' ',
  botAvatar: data?.botAvatar ? (data?.botAvatar as string) : null,
  botAvatarLabel: data?.botAvatarLabel ?? ' ',
  logo: data?.logo ? (data?.logo as string) : null,
  color: data?.color ?? PACTUM_PRIMARY_COLOR,
  tableColor: data?.tableColor ?? PACTUM_SECONDARY_COLOR,
})

export const brandingDefaultsFormData = (data?: LocalizedBranding): FormData => {
  const formData = new FormData()
  const defaults = brandingContentFormDefaults(data)

  Object.entries(defaults).forEach(([key, value]) => {
    value && formData.append(key, value)
  })

  return formData
}

export const brandingFormValuesWithDefaults = (
  data?: LocalizedBranding,
): BrandingPayload & BrandingContentPayload => ({
  label: data?.label ?? ' ',
  description: data?.description ?? ' ',
  projectIds: data?.projectIds ?? [],
  ...brandingContentFormDefaults(data),
})
