import { useContext } from 'react'
import { Grid } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { CheckboxCtrl } from '@components/Form'
import { NegotiationEventFormContext } from '../NegotiationEventFormContext'
import { FormRow, HideableBlock } from '../../FormCommon/FormCommon'
import { NON_SAAS_MODEL_ID } from '@procurement/utils/contractModel'
import { NegotiationEventFormData } from '../schema'

export const Prerequisites = () => {
  const { watch } = useFormContext<NegotiationEventFormData>()
  const { visibleFields } = useContext(NegotiationEventFormContext)
  const contractModelId = watch('contractModel.id')
  const nonSaasContractSelected = Boolean(contractModelId && contractModelId !== NON_SAAS_MODEL_ID)

  return (
    <>
      <HideableBlock
        hidden={
          !visibleFields.includes('contractModel.properties.riskAssessmentDone') ||
          !nonSaasContractSelected
        }
      >
        <FormRow>
          <Grid item xs={12}>
            <CheckboxCtrl
              name='contractModel.properties.riskAssessmentDone'
              label='I confirm that TPRM Assessment has been completed for the risk in scope (if any). TPRM Assessment is required any time bribery, privacy, infosecurity or responsible sourcing risk are in scope.'
              labelProps={{ fontWeight: 700 }}
            />
          </Grid>
        </FormRow>
      </HideableBlock>
      <HideableBlock
        hidden={
          !visibleFields.includes('contractModel.properties.itamRegistrationDone') ||
          !nonSaasContractSelected
        }
      >
        <FormRow>
          <Grid item xs={12}>
            <CheckboxCtrl
              name='contractModel.properties.itamRegistrationDone'
              label='I confirm that ITAM registration has been completed (if required). ITAM registration is required for any software and/or application used in P&G.'
              labelProps={{ fontWeight: 700 }}
            />
          </Grid>
        </FormRow>
      </HideableBlock>
    </>
  )
}
