import { ProjectTag, SuitePermission } from '@common/types'
import { useUserPermissions } from '@hooks'
import { Box } from '@mui/material'
import { Organization, ProjectListEntity } from '@pactum/core-backend-types'
import { LoadingPage } from '@pages/LoadingPage'
import React, { PropsWithChildren, useCallback, useContext, useMemo } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useGetOrganizationsQuery, useGetProjectsQuery, UserProject } from 'src/main/store/projects'
import { SUITE_MODULES } from '../suite-modules'
import { isGenericSuiteModule } from '../types/suite-module'

type OrganizationTag = Organization['tag']

type ActiveOrgAndProject = {
  activeOrgTag: OrganizationTag | null
  activeProjectTag: ProjectTag | null
  activeOrg: Organization | null
  activeProject: UserProject | null
  orgs: Organization[]
  projects: UserProject[]
  loadingProjects: boolean
  loadingOrgs: boolean
  getActiveOrgRedirectPath: (orgTag?: OrganizationTag) => string | undefined
  setActiveProject: (projectTag: ProjectTag) => unknown
}

export const ActiveOrgAndProjectContext = React.createContext<ActiveOrgAndProject>({
  activeOrgTag: null,
  activeProjectTag: null,
  activeOrg: null,
  activeProject: null,
  orgs: [],
  projects: [],
  loadingProjects: true,
  loadingOrgs: true,
  getActiveOrgRedirectPath: (_orgTag?: OrganizationTag) => undefined,
  setActiveProject: (_projectId) => {},
})

export const ActiveOrgAndProjectProvider = ({ children }: PropsWithChildren<{}>) => {
  const { data: orgs, isLoading: loadingOrgs } = useGetOrganizationsQuery()
  const { data: projects, isLoading: loadingProjects } = useGetProjectsQuery()
  const { userPermissionsInActiveOrg } = useUserPermissions()

  const { orgTag: activeOrgTag, projectTag: activeProjectTag } = useParams()

  const activeOrg = useMemo(
    () => orgs?.find((org) => org.tag === activeOrgTag) ?? null,
    [orgs, activeOrgTag],
  )

  const activeProject = useMemo(
    () => activeOrg?.projects?.find((project) => project.tag === activeProjectTag),
    [activeOrg, activeProjectTag],
  )

  const navigate = useNavigate()

  const getActiveOrgRedirectPath = useCallback(
    (orgTag?: OrganizationTag) =>
      getOrgSwitchPath(orgTag, orgs, userPermissionsInActiveOrg as Set<SuitePermission>),
    [orgs, userPermissionsInActiveOrg],
  )

  const setActiveProject = useCallback(
    (projectTag: ProjectTag) => {
      const projectSwitchPath = getProjectSwitchPath(
        projectTag,
        activeOrgTag,
        projects,
        userPermissionsInActiveOrg as Set<SuitePermission>,
      )

      navigate(projectSwitchPath ?? `/${activeOrgTag}`)
    },
    [activeOrgTag, projects, userPermissionsInActiveOrg, navigate],
  )

  const projectsCompatibleWithAnySuiteModule = useMemo(() => {
    const projectsUnderOrg = (orgs ?? []).find((org) => org.tag === activeOrgTag)?.projects ?? []
    return getProjectsCompatibleWithAnyModule(projectsUnderOrg)
  }, [orgs, activeOrgTag])

  const contextValue: ActiveOrgAndProject = useMemo(
    () => ({
      activeOrgTag: activeOrgTag ?? null,
      activeProjectTag: activeProjectTag ?? null,
      activeOrg: activeOrg ?? null,
      activeProject: activeProject ?? null,
      orgs: orgs ?? [],
      projects: projectsCompatibleWithAnySuiteModule,
      getActiveOrgRedirectPath,
      setActiveProject,
      loadingOrgs,
      loadingProjects,
    }),
    [
      projectsCompatibleWithAnySuiteModule,
      activeOrgTag,
      orgs,
      activeProjectTag,
      activeOrg,
      activeProject,
      getActiveOrgRedirectPath,
      setActiveProject,
      loadingOrgs,
      loadingProjects,
    ],
  )

  if (loadingOrgs || loadingProjects) {
    return (
      <Box p={4}>
        <LoadingPage />
      </Box>
    )
  }

  return (
    <ActiveOrgAndProjectContext.Provider value={contextValue}>
      {children}
    </ActiveOrgAndProjectContext.Provider>
  )
}

const getOrgSwitchPath = (
  orgTag?: OrganizationTag,
  orgs?: Organization[],
  userPermissions?: Set<SuitePermission>,
) => {
  if (!orgs || orgs.length < 1) {
    console.warn(`No organizations found`)
    return
  }

  let orgToSwitchTo

  if (orgTag) {
    orgToSwitchTo = orgs.find((org) => org.tag === orgTag)

    if (!orgToSwitchTo) {
      console.warn(`Cannot find organization '${orgTag}' to switch to`)
      return
    }
  }

  orgToSwitchTo =
    orgToSwitchTo ??
    orgs.find(({ projects }) => getProjectsCompatibleWithAnyModule(projects)?.length)

  if (!orgToSwitchTo?.projects?.length) {
    console.warn(`No projects under organization ${orgToSwitchTo?.name}`)
    return
  }

  const compatibleProjects = getProjectsCompatibleWithAnyModule(orgToSwitchTo?.projects)

  if (compatibleProjects.length < 1) {
    console.warn('Cannot find compatible projects')
    return
  }

  const moduleToSwitchTo = findUseCaseModuleToSwitchTo(compatibleProjects[0])

  if (!moduleToSwitchTo) {
    console.warn('Cannot find module to switch to')
    return
  }

  return generatePath(moduleToSwitchTo.projectSwitchPath(userPermissions), {
    orgTag: orgToSwitchTo.tag,
    projectTag: compatibleProjects[0].tag,
  })
}

export const getProjectSwitchPath = (
  projectTag: ProjectTag,
  orgTag?: OrganizationTag,
  projects?: UserProject[],
  userPermissions?: Set<SuitePermission>,
) => {
  const projectToSwitchTo = projects?.find((project) => project.tag === projectTag)

  if (!projectToSwitchTo) {
    console.warn('Cannot find project to switch to')
    return
  }

  const moduleToSwitchTo = findUseCaseModuleToSwitchTo(projectToSwitchTo)

  if (!moduleToSwitchTo) {
    console.warn('Cannot find module to switch to')
    return
  }

  return generatePath(moduleToSwitchTo.projectSwitchPath(userPermissions), {
    orgTag,
    projectTag,
  })
}

const findUseCaseModuleToSwitchTo = (project: UserProject) =>
  SUITE_MODULES.filter(isGenericSuiteModule).find((suiteModule) => {
    return suiteModule.isProjectCompatible(project)
  })

export const getProjectsCompatibleWithAnyModule = <TProjectType extends ProjectListEntity>(
  projects: TProjectType[],
) => {
  const compatibleProjects = projects.filter((project) =>
    SUITE_MODULES.some(
      (suiteModule) =>
        isGenericSuiteModule(suiteModule) && suiteModule.isProjectCompatible(project),
    ),
  )
  const sandboxProjects = compatibleProjects.filter((project) => project.isClientSandbox)
  const nonSandboxProjects = compatibleProjects.filter((project) => !project.isClientSandbox)

  return [...nonSandboxProjects, ...sandboxProjects]
}

export const useProjects = () => useContext(ActiveOrgAndProjectContext)
