import { useDeleteAttachmentMutation } from '@procurement/store/purchasing'
import { NegotiationEvent, NegotiationEventAttachment } from '@procurement/store/types'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react'
import { SerializedError } from '@reduxjs/toolkit'

type UseDeleteNegotiationEventAttachments = () => [
  (params: {
    attachments: Pick<NegotiationEventAttachment, 'id'>[]
    negotiationEventId: NegotiationEvent['id']
  }) => Promise<void>,
  result: {
    error: FetchBaseQueryError | SerializedError | undefined
    isError: boolean
    isLoading: boolean
  },
]

export const useDeleteNegotiationEventAttachments: UseDeleteNegotiationEventAttachments = () => {
  const { activeProjectTag } = useActiveProject()
  const [deleteAttachment, { error, isError, isLoading }] = useDeleteAttachmentMutation()

  return [
    async ({ attachments, negotiationEventId }) => {
      await Promise.all(
        attachments.map(async (attachment) => {
          await deleteAttachment({
            attachmentId: attachment.id,
            negotiationEventId,
            projectTag: activeProjectTag,
          }).unwrap()
        }),
      )
    },
    { error, isError, isLoading },
  ]
}
