import { DashboardData } from '@common/dto/dashboard/dashboard'
import { CurrencyCode } from '@pactum/common'
import { Organization, Project } from '@pactum/core-backend-types'
import { suiteBaseApi } from '@shared/api/suite-base-api'

export interface DashboardParams {
  projectIds?: string
  currency?: CurrencyCode
  start?: string
  end?: string
  countries?: string
  regions?: string
  categories1?: string
  categories2?: string
  businessUnits?: string
  queryVariant?: string
  excludeNonnegotiableSpend?: string
}

export interface GetDashboardConfigParams {
  currency?: CurrencyCode
  start?: string
  end?: string
  countries?: string[]
  regions?: string[]
  categories1?: string[]
  categories2?: string[]
  businessUnits?: string[]
  queryVariant?: string
  excludeNonnegotiableSpend?: string
}

interface GetOrganizationDashboardConfigParams extends GetDashboardConfigParams {
  organizationTag: Organization['tag']
  projectIds?: number[]
  countries?: string[]
  regions?: string[]
  categories1?: string[]
  categories2?: string[]
  businessUnits?: string[]
  queryVariant?: string
  excludeNonnegotiableSpend?: string
}

interface GetProjectDashboardConfigParam extends GetDashboardConfigParams {
  projectTag: Project['tag']
}

export const dashboardApi = suiteBaseApi.injectEndpoints({
  endpoints: (build) => ({
    getProjectDashboardConfig: build.query<DashboardData, GetProjectDashboardConfigParam>({
      query: ({
        projectTag,
        currency,
        countries,
        regions,
        categories1,
        categories2,
        businessUnits,
        start,
        end,
        queryVariant,
        excludeNonnegotiableSpend,
      }) => {
        let url = `/dashboard/project/${projectTag}`

        const qp: DashboardParams = {}

        if (start && end) {
          qp.start = start
          qp.end = end
        }

        if (currency) {
          qp.currency = currency
        }

        if (countries && countries.length > 0) {
          qp.countries = countries.join(',')
        }

        if (regions && regions.length > 0) {
          qp.regions = regions.join(',')
        }

        if (categories1 && categories1.length > 0) {
          qp.categories1 = categories1.join(',')
        }

        if (categories2 && categories2.length > 0) {
          qp.categories2 = categories2.join(',')
        }

        if (businessUnits && businessUnits.length > 0) {
          qp.businessUnits = businessUnits.join(',')
        }

        if (queryVariant) {
          qp.queryVariant = queryVariant
        }

        if (excludeNonnegotiableSpend) {
          qp.excludeNonnegotiableSpend = excludeNonnegotiableSpend
        }

        if (Object.keys(qp).length > 0) {
          url += '?' + new URLSearchParams({ ...qp })
        }

        return url
      },
    }),
    getOrganizationDashboardConfig: build.query<
      DashboardData,
      GetOrganizationDashboardConfigParams
    >({
      query: ({
        organizationTag,
        projectIds,
        countries,
        regions,
        currency,
        categories1,
        categories2,
        businessUnits,
        start,
        end,
        queryVariant,
        excludeNonnegotiableSpend,
      }) => {
        let url = `/dashboard/organization/${organizationTag}`

        const qp: DashboardParams = {
          currency,
        }

        if (start && end) {
          qp.start = start
          qp.end = end
        }

        if (projectIds && projectIds.length > 0) {
          qp.projectIds = projectIds.join(',')
        }

        if (currency) {
          qp.currency = currency
        }

        if (countries && countries.length > 0) {
          qp.countries = countries.join(',')
        }

        if (regions && regions.length > 0) {
          qp.regions = regions.join(',')
        }

        if (categories1 && categories1.length > 0) {
          qp.categories1 = categories1.join(',')
        }

        if (categories2 && categories2.length > 0) {
          qp.categories2 = categories2.join(',')
        }

        if (businessUnits && businessUnits.length > 0) {
          qp.businessUnits = businessUnits.join(',')
        }

        if (queryVariant) {
          qp.queryVariant = queryVariant
        }

        if (excludeNonnegotiableSpend) {
          qp.excludeNonnegotiableSpend = excludeNonnegotiableSpend
        }

        if (Object.keys(qp).length > 0) {
          url += '?' + new URLSearchParams({ ...qp })
        }

        return url
      },
    }),
  }),
})

export const { useGetProjectDashboardConfigQuery, useGetOrganizationDashboardConfigQuery } =
  dashboardApi
