import { createAction, Priority, useRegisterActions } from 'kbar'
import { Organization } from '@pactum/core-backend-types'
import { useNavigate } from 'react-router-dom'
import { useBrandedChatLink } from '@shared/hooks/useBrandedChatLink'

export function useOrgSwitchActions(
  organizations: Pick<Organization, 'name' | 'tag'>[],
  isAdmin: boolean,
): void {
  const navigate = useNavigate()
  const { clearBranding, getBrandingUuid } = useBrandedChatLink()

  const parentOrgSwitcher = createAction({
    name: 'Switch organization',
    section: 'Organization',
    keywords: 'org organization organisation',
    priority: Priority.HIGH,
  })

  const subActions = organizations.map((organization) =>
    createAction({
      name: organization.name,
      parent: parentOrgSwitcher.id,
      perform: () => {
        if (getBrandingUuid()) {
          clearBranding()
        }
        navigate(`/${organization.tag}`)
      },
    }),
  )
  useRegisterActions([parentOrgSwitcher, ...subActions], [isAdmin, organizations])
}
