import React from 'react'
import { Route } from 'react-router-dom'
import { Layout } from 'src/shared/components/Layout'
import { ProtectedRoute } from 'src/shared/components/ProtectedRoute'
import { CommercialTermsRoutes } from './routes'
import { HomePage } from './pages/HomePage/HomePage'
import { NegotiationDetails } from './pages/NegotiationDetails'
import { DashboardPage } from '@pages/DashboardPage/DashboardPage'
import { SuitePermission } from '@common/types'

export const CommercialTermsEntryPoint = () => {
  return (
    <>
      <Route element={<Layout locale={'en-US'} />}>
        <Route
          path={CommercialTermsRoutes.DASHBOARD}
          element={
            <ProtectedRoute anyOf={[SuitePermission.READ, SuitePermission.MODIFY]}>
              <DashboardPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={CommercialTermsRoutes.HOMEPAGE}
          element={
            <ProtectedRoute anyOf={[SuitePermission.READ, SuitePermission.MODIFY]}>
              <HomePage />
            </ProtectedRoute>
          }
        />
        <Route
          path={CommercialTermsRoutes.NEGOTIATION_DETAILS}
          element={
            <ProtectedRoute anyOf={[SuitePermission.READ, SuitePermission.MODIFY]}>
              <NegotiationDetails />
            </ProtectedRoute>
          }
        />
      </Route>
    </>
  )
}
