import { logisticsBaseApi } from '@logistics/store/baseApi'
import { responseAsObjectUrl } from '../../main/utils/file'

export const spotLoadApi = logisticsBaseApi.injectEndpoints({
  endpoints: (build) => ({
    downloadSpotRates: build.query<string, { projectTag: string; unitId: string }>({
      query: ({ projectTag, unitId }) => ({
        url: `spot-load/${projectTag}/${unitId}/rates`,
        responseHandler: responseAsObjectUrl,
      }),
    }),
  }),
})

export const { useLazyDownloadSpotRatesQuery } = spotLoadApi
