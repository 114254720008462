import {
  BENEFIT_NAMES,
  MarketId,
  Outcome,
  ScenarioState,
  Week,
} from '@common/types/scenario/ScenarioState'
import { ascendingOrder, sumNumbers } from '@common/utils'
import { Box, Grid, Stack, useTheme } from '@mui/material'
import { useFormatter } from '@shared/hooks'
import { useState } from 'react'
import { Chart } from 'react-chartjs-2'
import { BSRFilters, ChartFilter, FilterData, Filters } from '../components/Filters'
import { ChartType } from '../components/Filters/types'
import { isBenefitToSpendRatioAggregation } from '../components/Filters/utils'
import { ITEMS_AGGREGATE_OPTION } from '../constants'
import { BSRWeekSelector } from './BSRWeekSelector'
import { getChartOptions, getDataToLabelsMap } from './utils/chartUtils'
import { filterWithHistoricalOrInScopeData } from './utils/commonUtils'
import { getChartData } from './utils/dataUtils'
import { ChartData, SpendBenefitName } from './utils/types'

export const SPEND_BENEFIT_NAMES: SpendBenefitName[] = ['Net', 'Total', ...BENEFIT_NAMES]

interface Props {
  allItems: ScenarioState['item_info']
  allMarkets: ScenarioState['market_info']
  historical: Required<ScenarioState>['historical']
  current: ScenarioState['current']['metrics']
  defaultInputs: { marketId?: MarketId }
  currency: string
}

export const BSRRatioContainer = ({
  allItems,
  allMarkets,
  historical,
  current,
  defaultInputs,
  currency,
}: Props) => {
  const theme = useTheme()
  const defaultMarket = defaultInputs.marketId
  const barsMultiplier = 2 // Each market section is 2 bars - left for normal items, right for aggregations

  const [selectedWeek, setSelectedWeek] = useState<Week>(defaultMarket ? 'W2' : 'W1')
  const [selectedFilters, setSelectedFilters] = useState<Omit<BSRFilters, 'week'>>({
    items: [],
    markets: [],
    timeRange: [],
  })
  const formatter = useFormatter()
  const handleFilterChange = (filterData: FilterData) => {
    const orderedMarkets = getOrderedOptions(filterData.markets)
    const orderedTimeRanges = getOrderedOptions(filterData.timeRange)
    setSelectedFilters({
      ...filterData,
      markets: orderedMarkets,
      timeRange: orderedTimeRanges,
    })
  }

  let { chartData } = getChartData({
    historical,
    current,
    filters: { ...selectedFilters, week: selectedWeek },
  })

  if (isBenefitToSpendRatioAggregation(selectedFilters)) {
    chartData = convertDataToPercentages(chartData)
  }

  chartData = addEmptyDataPoints(chartData, selectedFilters)

  const hasDataToShow =
    selectedFilters.items.length > 0 &&
    selectedFilters.markets.length > 0 &&
    selectedFilters.timeRange.length > 0

  const getNumberOfChartLabels = () => {
    const numberOfSelectedHistoricalQuarters = selectedFilters.timeRange.filter(
      (timeRange) => !Filters.isFutureSelection(timeRange),
    ).length
    const numberOfSelectedMarkets = selectedFilters.markets.length
    const futureQuarterMarketOffset = Filters.isFutureTimeRangeSelected(selectedFilters) ? 1 : 0

    const allMarketsToRender =
      numberOfSelectedHistoricalQuarters * numberOfSelectedMarkets + futureQuarterMarketOffset

    return allMarketsToRender * barsMultiplier
  }

  const getChartLabels = () => {
    return Array.from(
      {
        length: getNumberOfChartLabels(),
      },
      (_, index) => {
        if (!hasDataToShow) {
          return ''
        }
        const allSingleItems = selectedFilters.items.filter(Filters.isSingleSelection)
        const singleItemLabel =
          allSingleItems.length > 1 ? `${allSingleItems.length} items` : allSingleItems[0]?.label
        const itemsAggregationLabel = Filters.isItemAggregationSelected(selectedFilters)
          ? ITEMS_AGGREGATE_OPTION.label
          : ''

        return index % 2 === 0 ? singleItemLabel : itemsAggregationLabel
      },
    )
  }

  const dataToLabelsMap = getDataToLabelsMap(theme)
  const dataFieldNames = getDataFieldNames(selectedFilters.chartType?.value)
  const chartConfiguration = {
    labels: getChartLabels(),
    datasets: dataFieldNames.map((field) => ({
      label: dataToLabelsMap[field].label,
      backgroundColor: dataToLabelsMap[field].color,
      borderRadius: 4,
      hidden: field === 'Total',
      data: chartData,
      parsing: {
        yAxisKey: field,
      },
    })),
  }

  const biggestChartDataValue = Math.max(
    ...chartData.map((data) =>
      sumNumbers(Object.entries(data).map(([key, value]) => (key === 'Total' ? 0 : value))),
    ),
  )

  const quarterLabels = selectedFilters.timeRange.map((timeRangeFilter) => {
    if (
      Filters.isFutureSelection(timeRangeFilter) ||
      Filters.isAggregateSelection(timeRangeFilter)
    ) {
      return timeRangeFilter.value // shouldn't this be `timeRangeFilter.label`?
    }

    return historical?.metrics.index_to_labels[Number(timeRangeFilter.value)] as string
  })

  const selectedMarketLabels = selectedFilters.markets.map((marketFilter) => marketFilter.label)

  const isBiggestValueGood = isFinite(biggestChartDataValue) && biggestChartDataValue > 0
  const chartOptions = getChartOptions({
    quarters: quarterLabels,
    markets: selectedMarketLabels,
    outcome: selectedFilters.outcome?.label as Outcome,
    chartType: selectedFilters.chartType?.label as ChartType,
    biggestChartDataValue: isBiggestValueGood ? biggestChartDataValue : 10, // fallback to make sure top labels are rendered correctly without data
    shouldRenderAnnotations: hasDataToShow,
    barsMultiplier,
    selectedFilters,
    formatter,
    theme,
    currency,
  })

  return (
    <Box>
      <ChartFilter
        items={filterWithHistoricalOrInScopeData(allItems)}
        markets={filterWithHistoricalOrInScopeData(allMarkets)}
        quarters={historical?.metrics.index_to_labels}
        outcomes={current.index_to_labels}
        filtersToShow={['chartType', 'items', 'markets', 'timeRange', 'outcome']}
        defaultFilters={{ markets: defaultMarket }}
        onChange={handleFilterChange}
      />
      <Stack direction='row' alignItems='center' pt={2.5}>
        <BSRWeekSelector
          selectedWeek={selectedWeek}
          onWeekSelected={(week) => setSelectedWeek(week)}
        />
      </Stack>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={2} mb={2}>
        <Grid item xs={12} sx={{ height: '400px' }}>
          <Chart type='bar' data={chartConfiguration} options={chartOptions} />
        </Grid>
      </Grid>
    </Box>
  )
}

const convertDataToPercentages = (chartData: ChartData): ChartData =>
  chartData.map((dataPiece) =>
    Object.entries(dataPiece).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: key === 'x' ? value : Number(value) * 100,
      }),
      {} as ChartData[0],
    ),
  )

const addEmptyDataPoints = (
  chartData: ChartData,
  selectedFilters: Omit<BSRFilters, 'week'>,
): ChartData => {
  // This function adds empty values to the chart data so that aggregations are pushed to the right
  // and single items are pushed to the left section of the same market
  const isItemAggregationSelected = Filters.isItemAggregationSelected(selectedFilters)

  if (selectedFilters.items.length > 1 && isItemAggregationSelected) {
    return chartData
  }

  return chartData.flatMap((dataPiece) =>
    isItemAggregationSelected ? [{ x: 0 }, dataPiece] : [dataPiece, { x: 0 }],
  )
}

const getDataFieldNames = (chartType?: ChartType) => {
  if (!chartType) {
    return []
  }

  if (chartType === 'Benefit to spend ratio') {
    return BENEFIT_NAMES
  }

  return SPEND_BENEFIT_NAMES
}

const getOrderedOptions = (filterOptions: FilterData['markets'] | FilterData['timeRange']) => {
  const sortedIndices = filterOptions
    .filter(Filters.isSingleSelection)
    .sort(ascendingOrder((filterOption) => Number(filterOption.value)))
  const maybeFutureSelection = filterOptions.find(Filters.isFutureSelection)
  const maybeAggregateSelection = filterOptions.find(Filters.isAggregateSelection)

  return [
    ...sortedIndices,
    ...(maybeFutureSelection ? [maybeFutureSelection] : []),
    ...(maybeAggregateSelection ? [maybeAggregateSelection] : []),
  ]
}
