import { downloadFile } from '@utils'
import { useSnackbar } from 'notistack'
import { isBackendApiErrorResponse } from '@shared/backend/error/typeGuards'
import { useDownloadNegotiationEventTemplateMutation } from '../store/price-list'

export const useDownloadTemplateData = (projectTag: string) => {
  const { enqueueSnackbar } = useSnackbar()
  const [downloadNegotiationEventTemplate] = useDownloadNegotiationEventTemplateMutation()

  return async () => {
    try {
      const fileObjectURL = await downloadNegotiationEventTemplate(projectTag).unwrap()
      downloadFile('Download the negotiation template', fileObjectURL)
    } catch (error) {
      const errorMessage = isBackendApiErrorResponse(error)
        ? error.data.message
        : 'Error occurred while downloading template file'

      enqueueSnackbar(errorMessage, { variant: 'error' })
    }
  }
}
