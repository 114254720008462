import { useActiveProject } from '@shared/hooks/useActiveProject'
import { FormDialog } from '@components/FormDialog'
import { RFPDetailsForm } from './RFPDetailsForm'
import { useCallback, useState } from 'react'
import {
  useCreateNegotiationsMutation,
  useImportRFPMutation,
} from '@logistics/store/contractedLanesApi'
import { Alert } from '@mui/material'
import { extractErrorMessage } from '@logistics/utils/error'
import { addDays, endOfDay } from 'date-fns'

export interface UploadDialogProps {
  isOpen: boolean
  onClose: () => void
}

type FormInputs = {
  name: string
  start: Date
  end: Date
  deadline: Date
  additionalRequirements?: string
  selectedCarrierIds?: string[]
  contactAllSelectedCarriers?: boolean
}

const errorKeyDisplayMap = {
  lane_id: 'Lane ID',
  trip_type: 'Trip Type (Optional)',
  origin_country: 'Origin Country (ISO alpha-3)',
  origin_city: 'Origin City (Optional)',
  origin_postcode: 'Origin Postcode',
  origin_client_code: 'Origin Client Code (Optional)',
  destination_country: 'Destination Country (ISO alpha-3)',
  destination_city: 'Destination City (Optional)',
  destination_postcode: 'Destination Postcode',
  destination_client_code: 'Destination Client Code (Optional)',
  distance_km: 'Distance in Kms (Optional)',
  expected_annual_volume: 'Expected Annual Volume (Optional)',
  hazardous_class: 'Hazardous Goods (Hazmat Class or No)',
  equipment_type: 'Equipment Type',
  equipment_size: 'Equipment Size (Optional)',
  currency: 'Currency',
}

export const UploadDialog = (props: UploadDialogProps) => {
  const defaultErrorMessage =
    'Please review the RFP file and try again. If the error persists, please contact support.'

  const { activeProjectTag } = useActiveProject()
  const [formErrorMessage, setFormErrorMessage] = useState<string | null>(null)
  const [uploadedFile, setUploadedFile] = useState<Blob | null>(null)
  const [importRFP, { isLoading: isLoadingRFPImport }] = useImportRFPMutation<{
    error: { data?: { details?: unknown } }
    isLoading?: boolean
  }>()

  const [createNegotiations, { isLoading: isLoadingNegotiationCreation }] =
    useCreateNegotiationsMutation()

  const onSubmit = useCallback(
    async ({
      name,
      start,
      end,
      deadline,
      additionalRequirements,
      selectedCarrierIds,
      contactAllSelectedCarriers,
    }: FormInputs) => {
      setFormErrorMessage(null)

      if (!uploadedFile) {
        setFormErrorMessage('Please upload the RFP file.')
        return
      }

      if (!name || !name.trim()) {
        setFormErrorMessage('Please specify a name for the RFP file.')
        return
      }

      if (start >= end) {
        setFormErrorMessage('Contract start date must be before contract end date.')
        return
      }

      if (deadline >= start) {
        setFormErrorMessage('Deadline must be before contract start date.')
        return
      }

      if (endOfDay(deadline).getTime() < addDays(new Date(), 2).getTime()) {
        setFormErrorMessage('The deadline must be at least 2 days away.')
        return
      }

      if (endOfDay(deadline).getTime() > addDays(new Date(), 30).getTime()) {
        setFormErrorMessage('The deadline must be at most 30 days away.')
        return
      }

      if ((!selectedCarrierIds || selectedCarrierIds.length === 0) && contactAllSelectedCarriers) {
        setFormErrorMessage(
          'If contacting all selected suppliers is enabled, at least one supplier must be selected.',
        )
        return
      }

      const importResult = await importRFP({
        name,
        deadline,
        start,
        end,
        additionalRequirements,
        selectedCarrierIds,
        contactAllSelectedCarriers,
        file: uploadedFile,
        projectTag: activeProjectTag,
      })

      if (!('error' in importResult)) {
        const negotiationCreationResult = await createNegotiations({
          projectTag: activeProjectTag,
          rfpId: importResult.data.id,
        })

        if (!('error' in negotiationCreationResult)) {
          props.onClose()
        } else {
          setFormErrorMessage(extractErrorMessage(negotiationCreationResult.error))
        }
      } else {
        setFormErrorMessage(
          extractErrorMessage(importResult.error, errorKeyDisplayMap, defaultErrorMessage),
        )
      }
    },
    [activeProjectTag, createNegotiations, importRFP, props, uploadedFile],
  )

  return (
    <FormDialog<FormInputs>
      open={props.isOpen}
      title='Upload New RFP'
      fullWidth
      maxWidth='lg'
      buttons={[
        { type: 'cancel', label: 'Cancel' },
        { type: 'submit', label: 'Upload' },
      ]}
      onCancel={() => props.onClose()}
      onSubmit={onSubmit}
      loading={isLoadingRFPImport || isLoadingNegotiationCreation}
    >
      {formErrorMessage && (
        <Alert sx={{ marginBottom: 2 }} severity='error'>
          {formErrorMessage}
        </Alert>
      )}
      <RFPDetailsForm
        isLoading={isLoadingRFPImport ?? false}
        onUploadDone={(file) => {
          setUploadedFile(file)
        }}
      />
      {uploadedFile && (
        <Alert sx={{ marginTop: 2 }} severity='success'>
          RFP file selected.
        </Alert>
      )}
    </FormDialog>
  )
}
