import React from 'react'
import {
  TargetLacRangePreFormat,
  targetLacRangeToString,
} from 'src/merchandising/components/IndividualItemTable/utils'
import { TooltipCell } from 'src/merchandising/components/IndividualItemTable/TooltipCell'
import { GridRowId, GridRenderCellParams } from '@mui/x-data-grid-pro'
import { DefaultUserInput, ItemData, TargetAndLacValues } from '@common/types'
import { ValueTooltipIcon } from '../../pages/Scenarios/ScenarioPreparation/ScenarioDetails/components/ValueTooltipIcon'
import { ValueWarningIcon } from '../../pages/Scenarios/ScenarioPreparation/ScenarioDetails/components/ValueWarningIcon'

interface Props {
  defaultValues: DefaultUserInput
  id: GridRowId
  row: ItemData
  value: TargetLacRangePreFormat
  colDef: GridRenderCellParams['colDef']
  field: string
  tooltip?: string
}

type FlatRow = Pick<ItemData, 'invoice_prices' | 'net_prices' | 'volumes' | 'ipr'> &
  Pick<ItemData['benefits'], 'gmf' | 'gtd' | 'cmf' | 'ctd'>

const fieldsToWarnAbout: (keyof FlatRow)[] = ['invoice_prices', 'net_prices']

const warningText =
  'LAA Price is equal to Target Price, meaning that this is our final offer and there is no room for negotiation.'

export const HighlightedCell = ({
  defaultValues,
  id,
  row,
  value,
  colDef,
  field,
  tooltip,
}: Props) => {
  const defaultRow = defaultValues.items.find((defaultValue) => defaultValue.commercial_id === id)

  const flatRow = { ...row, ...row.benefits }
  const flatDefaultRow = { ...defaultRow, ...defaultRow!.benefits }

  const rawValue = flatRow[field as keyof FlatRow]

  const defaultValue = flatDefaultRow[field as keyof typeof flatDefaultRow] as TargetAndLacValues

  const { lac, target } = rawValue

  const equalLacAndTarget = value.lac === value.target
  const warnAboutEqualLacAndTarget =
    fieldsToWarnAbout.includes(field as keyof FlatRow) && equalLacAndTarget
  const tooltipText = warnAboutEqualLacAndTarget
    ? tooltip
      ? warningText + '\n' + tooltip
      : warningText
    : tooltip

  const highlightStyle = { backgroundColor: 'lightyellow' }
  const isLacChanged = defaultValue?.lac && defaultValue?.lac !== lac
  const isTargetChanged = defaultValue?.target && defaultValue?.target !== target
  const tooltipContent = tooltipText ?? targetLacRangeToString(value)

  if (equalLacAndTarget) {
    return (
      <TooltipCell content={tooltipContent} colDef={colDef}>
        <span>
          <span style={isTargetChanged || isLacChanged ? highlightStyle : undefined}>
            {value.target}
          </span>
          {warnAboutEqualLacAndTarget ? <ValueWarningIcon /> : tooltip && <ValueTooltipIcon />}
        </span>
      </TooltipCell>
    )
  }
  return (
    <TooltipCell content={tooltipContent} colDef={colDef}>
      <span>
        <span style={isTargetChanged ? highlightStyle : undefined}>{value.target}</span>
        {value.separator}
        <span style={isLacChanged ? highlightStyle : undefined}>{value.lac}</span>
        {tooltip && <ValueTooltipIcon />}
      </span>
    </TooltipCell>
  )
}
