import { ExpandableSection } from 'src/shared/components/ExpandableSection'
import { SupplierHistoricalCogsTable } from './SupplierHistoricalCogsTable'
import { PriceElasticityTable } from 'src/merchandising/pages/ChangeRequests/NegotiationPreparation/Adjustments/SupportiveInsights/PriceElasticityTable'
import { PricingRevionicsTable } from './PricingRevionicsTable'
import { useNegotiationPreparationData } from 'src/merchandising/pages/ChangeRequests/NegotiationPreparation/NegotiationPreparationPage'

export const SupportiveInsights = (): JSX.Element | null => {
  const { data } = useNegotiationPreparationData()

  const { pricingStrategy, priceElasticity, supplierHistoricalCogs } = data.analysis

  if (!pricingStrategy && !priceElasticity && !supplierHistoricalCogs) {
    console.warn('No Supportive Insights data')
    return null
  }

  return (
    <ExpandableSection
      title='Supportive Insights'
      description='The following section shows supportive insights from different sources and perspectives.'
    >
      <PricingRevionicsTable />
      <PriceElasticityTable />
      <SupplierHistoricalCogsTable />
    </ExpandableSection>
  )
}
