import { Contract } from '@campaigns/types'
import { styled, Tab, Tabs, Typography } from '@mui/material'

export const ContractTabs = ({
  contracts,
  selectedContract,
  setContract,
}: {
  contracts: Contract[]
  selectedContract: Contract
  setContract: (index: number) => void
}) => {
  return (
    <>
      <Typography variant='h2'>Contracts</Typography>
      <StyledTabs
        value={contracts.indexOf(selectedContract)}
        TabIndicatorProps={{ sx: { backgroundColor: 'accent.main' } }}
        onChange={(_, index) => setContract(index)}
      >
        {contracts.map((contract, key) => (
          <StyledTab label={contract.key} value={key} key={key} />
        ))}
      </StyledTabs>
    </>
  )
}

const StyledTabs = styled(Tabs)({
  marginTop: '12px',
  width: '100%',
  minHeight: 'unset',
})

const StyledTab = styled(Tab)({
  paddingTop: '5px',
  paddingBottom: '5px',
  minHeight: 'unset',
  color: '#6C6C6C',
  textTransform: 'none',
  fontWeight: 'bold',
  '&.Mui-selected': {
    color: '#0F3A4F',
  },
})
