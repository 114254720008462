import { FormControl, InputLabel, Select, SelectProps, styled } from '@mui/material'

export const FilterSelect = ({
  label,
  isValuePresent,
  ...selectProps
}: {
  label: string
  isValuePresent: (value: unknown) => boolean
} & SelectProps) => (
  <FormControl fullWidth size='small'>
    <InputLabel shrink={false}>{!isValuePresent(selectProps.value) ? label : null}</InputLabel>
    <StyledSelect variant='outlined' {...selectProps} />
  </FormControl>
)
const StyledSelect = styled(Select)({
  border: 'none',
  borderRadius: 8,
  padding: 0,
})
