import { isNumber } from '@shared/utils/type-guards'

export const calculateSpendItemUnitPrice = ({
  spendAmount,
  spendDurationMonths,
  agreementDurationMonths,
}: {
  spendAmount: number
  spendDurationMonths: number | null | undefined
  agreementDurationMonths: number | null | undefined
}) => {
  if (
    !isNumber(spendAmount) ||
    !isNumber(spendDurationMonths) ||
    !isNumber(agreementDurationMonths)
  ) {
    return spendAmount
  }

  return spendAmount * (agreementDurationMonths / spendDurationMonths)
}

export const calculateSpendAmount = ({
  spendItemUnitPrice,
  spendDurationMonths,
  agreementDurationMonths,
}: {
  spendItemUnitPrice: number
  spendDurationMonths: number | null | undefined
  agreementDurationMonths: number | null | undefined
}) => {
  if (
    !isNumber(spendItemUnitPrice) ||
    !isNumber(spendDurationMonths) ||
    !isNumber(agreementDurationMonths)
  ) {
    return spendItemUnitPrice
  }

  return spendItemUnitPrice * (spendDurationMonths / agreementDurationMonths)
}
