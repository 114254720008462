import { LAYOUT_CONTAINER_X_PADDING } from '@constants'
import { Box } from '@mui/material'
import { ErrorPage } from '@pages/ErrorPage'
import { LoadingPage } from '@pages/LoadingPage'
import { NegotiationsList } from '@pages/NegotiationsPage/NegotiationsList'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { CONTENT_TITLE_PADDING } from '../../constants/layout'
import { useGetPactNegotiationsQuery } from '../../store/pact'
import { isBackendHttpError } from '../../types/http-errors'

type Props = {
  unitsRoute: string
}

export const NegotiationsListPage = ({ unitsRoute }: Props) => {
  const { activeProjectTag: projectTag } = useActiveProject()
  const { data, isLoading, error } = useGetPactNegotiationsQuery({
    projectTag,
  })

  if (isBackendHttpError(error)) {
    return (
      <ErrorPage title='Error loading negotiation list'>
        <pre>{error.data.message}</pre>
      </ErrorPage>
    )
  }
  if (isLoading || !data) {
    return <LoadingPage />
  }

  return (
    <Box pr={LAYOUT_CONTAINER_X_PADDING} pt={CONTENT_TITLE_PADDING} pb={4}>
      <NegotiationsList negotiations={data} unitsRoute={unitsRoute} />
    </Box>
  )
}
