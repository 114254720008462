import { Grid, Tooltip, Typography } from '@mui/material'
import { CurrencyCode } from '@pactum/common'
import { locales } from 'common/constants'
import { KeyValueList } from '@common/dto/dashboard/dashboard'
import { useCallback, useMemo } from 'react'
import { Formatter } from '../../../utils'
import { useFilters } from '../useDashboardQueryParams'
import { WidgetSection } from './WidgetSection'

interface Props {
  widgetConfig: KeyValueList
}

const formatMap: Record<
  string,
  (formatter: Formatter, v: unknown, currency?: CurrencyCode) => string
> = {
  totalSuccessfulSpend: (formatter: Formatter, val: unknown, currency) =>
    formatter.currency(Number(val), { currency: currency ?? CurrencyCode.USD, fractionDigits: 0 }),
  annualSuccessfulSpend: (formatter: Formatter, val: unknown, currency) =>
    formatter.currency(Number(val), { currency: currency ?? CurrencyCode.USD, fractionDigits: 0 }),
  savings: (formatter: Formatter, val: unknown, currency) =>
    formatter.currency(Number(val), { currency: currency ?? CurrencyCode.USD, fractionDigits: 0 }),
  annualSavings: (formatter: Formatter, val: unknown, currency) =>
    formatter.currency(Number(val), { currency: currency ?? CurrencyCode.USD, fractionDigits: 0 }),
  savingsPercentage: (_formatter: Formatter, val: unknown) => `${val}%`,
}

export const KeyValueListWidget = ({ widgetConfig }: Props) => {
  const { currency } = useFilters()
  const figureGroups = widgetConfig.figures

  const formatter = useCallback(() => new Formatter(locales.us), [])

  const formatColumn = useCallback(
    (key: string, val: unknown): string =>
      formatMap[key] ? formatMap[key](formatter(), val, currency) : String(val),
    [formatter, currency],
  )

  const formattedfigureGroups = useMemo(() => {
    return figureGroups.map((figures) => {
      return figures.map((figure) => {
        return {
          ...figure,
          primaryValue: figure.key
            ? formatColumn(figure.key, figure?.primaryValue)
            : figure?.primaryValue,
        }
      })
    })
  }, [figureGroups, formatColumn])

  return (
    <WidgetSection
      title={widgetConfig.title}
      subtitle={widgetConfig.subtitle}
      description={widgetConfig.description}
      wide={false}
    >
      <Grid container>
        {formattedfigureGroups.map((figures, index) => (
          <Grid item xs={6} pr={3} pl={1} key={`grid-item-${index}`}>
            {figures.map((figure, figureIndex) => (
              <Tooltip
                title={figure.tooltip}
                placement='right'
                arrow
                key={`tooltip-${figureIndex}-${index}`}
              >
                <Grid container p={1}>
                  <Grid item xs={7}>
                    <Typography component='span' variant='normal'>
                      {figure.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={5} textAlign='right'>
                    <Typography component='span' variant='normal' noWrap>
                      {figure.primaryValue}
                    </Typography>
                  </Grid>
                </Grid>
              </Tooltip>
            ))}
          </Grid>
        ))}
      </Grid>
    </WidgetSection>
  )
}
