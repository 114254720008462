import { CommercialTermsNegotiation } from '@campaigns/types/negotiation'
import { useSuiteDataGrid } from '@components/SuiteDataGrid/SuiteDataGridProvider'
import { styled } from '@mui/material'
import {
  DataGridPro,
  GridCellParams,
  GridEventListener,
  GridPaginationModel,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro'
import { useFormatter } from '@shared/hooks'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useEffect, useMemo, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { CommercialTermsRoutes } from 'src/commercial-terms/routes'
import { DataGrid, dataGridProps } from 'src/shared/components/table'
import { createColumns } from './columns'
import { Toolbar } from './Toolbar'

interface Props {
  negotiations: CommercialTermsNegotiation[]
}

const PAGE_SIZE = 25

export const NegotiationsList = ({ negotiations }: Props) => {
  const { activeOrgTag, activeProjectTag } = useActiveProject()
  const navigate = useNavigate()
  const formatter = useFormatter()
  const [selectedNegotiationIds, setSelectedNegotiationIds] = useState<GridRowSelectionModel>([])
  const [displayedNegotiationIds, setDisplayedNegotiationIds] = useState<string[]>([])

  const [showSelectAllNotification, setShowSelectAllNotification] = useState<boolean>(false)
  const [selectedEverything, setSelectedEverything] = useState<boolean>(false)

  const gridState = useSuiteDataGrid({
    defaultPageSize: PAGE_SIZE,
    sort: [{ field: 'lastStatusChange', sort: 'desc' }],
  })

  const paginationModel = gridState.pagination
  const setPaginationModel = gridState.setPagination

  const filterModel = gridState.filters
  const setFilterModel = gridState.setFilters

  useEffect(() => {
    setSelectedEverything(false)
    setShowSelectAllNotification(false)
    setSelectedNegotiationIds([])
  }, [filterModel])

  const columns = useMemo(() => createColumns(formatter, negotiations), [formatter, negotiations])

  const selectedNegotiations = negotiations.filter((negotiation) =>
    selectedNegotiationIds.includes(negotiation.id),
  )

  const handleCellClick: GridEventListener<'cellClick'> = (
    cell: GridCellParams<CommercialTermsNegotiation>,
  ) => {
    const actionableFields = ['__actions__', '__check__']

    if (cell.row.status === 'PROCESSING') {
      return
    }

    if (!actionableFields.includes(cell.field)) {
      navigate(
        generatePath(CommercialTermsRoutes.NEGOTIATION_DETAILS, {
          id: String(cell.id),
          orgTag: activeOrgTag,
          projectTag: activeProjectTag,
        }),
      )
    }
  }

  const handleStateChange: GridEventListener<'stateChange'> = (props: {
    filter: { filteredRowsLookup: Record<string, boolean> }
  }) => {
    const filteredRowsMap = props.filter.filteredRowsLookup
    const filteredRows = Object.keys(filteredRowsMap).filter((key) => filteredRowsMap[key])

    setDisplayedNegotiationIds(filteredRows)
  }

  const handlePageChange = (paginationModel: GridPaginationModel) => {
    setPaginationModel(paginationModel)

    // if everything is selected via the notification, we want to allow the user to browse pages without resetting selection
    if (selectedNegotiationIds.length !== negotiations.length) {
      setSelectedNegotiationIds([])
      setShowSelectAllNotification(false)
    }
  }

  const handleSelection = (selectedRows: GridRowSelectionModel) => {
    // after notification is used to select everything, we want the select all box to unselect everything across all pages
    if (selectedEverything) {
      setSelectedEverything(false)
      setShowSelectAllNotification(false)
      setSelectedNegotiationIds([])
    } else {
      setSelectedNegotiationIds(selectedRows)
    }

    // if user clicked select all on a full page, and all page negotiations were selected
    if (selectedRows.length === paginationModel.pageSize) {
      setShowSelectAllNotification(true)
    }

    const finalPageNumber = Math.ceil(displayedNegotiationIds.length / paginationModel.pageSize)
    const userOnFinalPage = paginationModel.page + 1 === finalPageNumber // page number is 0 indexed

    const finalPageNegotiationCount = displayedNegotiationIds.length % paginationModel.pageSize

    if (userOnFinalPage && selectedRows.length === finalPageNegotiationCount) {
      setShowSelectAllNotification(true)
    }

    if (selectedRows.length === 0) {
      setShowSelectAllNotification(false)
    }
  }

  const handleSelectAllNotificationClicked = (selected: boolean) => {
    if (selected) {
      setSelectedNegotiationIds(displayedNegotiationIds)
      setSelectedEverything(true)
    } else {
      setShowSelectAllNotification(false)
      setSelectedEverything(false)
    }
  }

  const handleClearSelectionClicked = () => {
    setSelectedNegotiationIds([])
    setShowSelectAllNotification(false)
    setSelectedEverything(false)
  }

  return (
    <StyledDataGrid
      {...dataGridProps}
      pagination
      paginationMode='client'
      paginationModel={paginationModel}
      pageSizeOptions={[]}
      hideFooter={false}
      hideFooterSelectedRowCount
      checkboxSelection
      checkboxSelectionVisibleOnly
      disableRowSelectionOnClick
      className={selectedNegotiationIds.length > 0 ? 'show-selection' : ''}
      columns={columns}
      rows={negotiations ?? []}
      isRowSelectable={() => true}
      rowSelectionModel={selectedNegotiationIds}
      onCellClick={handleCellClick}
      onPaginationModelChange={handlePageChange}
      onRowSelectionModelChange={handleSelection}
      slots={{ ...dataGridProps.slots, toolbar: Toolbar }}
      slotProps={{
        toolbar: {
          showBatchActions: selectedNegotiationIds.length > 0,
          selectedNegotiations,
          filterModel,
          setFilterModel,
          negotiations,
          displayedNegotiationIds,
          showSelectAllNotification,
          showClearSelectionNotification: selectedEverything,
          onSelectAll: handleSelectAllNotificationClicked,
          onClearSelection: handleClearSelectionClicked,
        },
        pagination: {
          rowsPerPageOptions: [],
        },
      }}
      onStateChange={handleStateChange}
      initialState={{
        columns: {
          columnVisibilityModel: {
            // hidden column used for filtering
            hasAttachments: false,
          },
        },
      }}
      filterModel={filterModel}
      sortModel={gridState.sort}
      onSortModelChange={gridState.setSort}
      disableColumnFilter={true}
    />
  )
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  ' .MuiDataGrid-virtualScroller, .MuiDataGrid-main, .MuiDataGrid-cell.actions-cell, .MuiDataGrid-columnHeaders':
    {
      overflow: 'visible !important',
    },
  ' .MuiDataGrid-columnHeader .MuiBadge-badge': {
    display: 'none',
  },
  ' .select-cell, .select-header': {
    opacity: 0,
  },
  ' .MuiDataGrid-row:hover .select-cell': {
    opacity: 1,
  },
  ' .MuiDataGrid-columnHeaders:hover .select-header': {
    opacity: 1,
  },
  '&.show-selection': {
    ' .select-cell, .select-header': {
      opacity: 1,
    },
  },
  '.MuiDataGrid-footerContainer': {
    paddingLeft: 0,
  },
  '.MuiDataGrid-columnHeader--numeric .MuiDataGrid-columnHeaderTitleContainer': {
    flexDirection: 'row',
  },
  '.MuiDataGrid-cell--textRight': {
    justifyContent: 'flex-start',
  },
  '.MuiTablePagination-displayedRows': {
    display: 'none',
  },
  paddingTop: theme.spacing(4),
})) as typeof DataGridPro
