import { ChangeEvent, useEffect, useState } from 'react'
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material'
import { BENEFIT_NAMES, BenefitName, ItemData } from '@common/types/scenario/ScenarioState'
import { LumpSum } from '@common/types'
import { isMinimumAmountValid, isNameValid, isTargetAmountValid } from './validations'
import { MultiSelectFilter } from '../components/Filters/MultiSelectFilter'
import { FilterOption } from '../components/Filters'
import { ALL_ITEMS_OPTION } from '../constants'
import { DeleteButton } from '../../../../../components/DeleteButton'
import { getCurrencySymbol } from '@utils'

interface Props {
  lumpSum: LumpSum
  currency: string
  items: ItemData[]
  onChange: (lumpSum: LumpSum) => void
  onDelete: () => void
}

const RENAMED_ALL_ITEMS_OPTION = {
  ...ALL_ITEMS_OPTION,
  label: 'All items',
}

export const LumpSumRow = ({ lumpSum, items, currency, onChange, onDelete }: Props) => {
  const [selectedItems, setSelectedItems] = useState<FilterOption<string>[]>(
    getInitialSelectedItems(lumpSum, items),
  )
  const formattedItems = [RENAMED_ALL_ITEMS_OPTION, ...toFilterValues(items)]

  const currencySymbol = getCurrencySymbol(currency)

  useEffect(() => {
    setSelectedItems(
      toFilterValues(items.filter((item) => lumpSum.commercial_ids.includes(item.commercial_id))),
    )
  }, [lumpSum, items, setSelectedItems])

  return (
    <Stack mt={2} direction='row' spacing={2}>
      <TextField
        sx={{ flex: 2 }}
        required
        label='Name'
        value={lumpSum.name}
        error={!isNameValid(lumpSum.name)}
        onChange={(e) =>
          onChange({
            ...lumpSum,
            name: e.target.value,
          })
        }
      />
      <TextField
        sx={{ flex: 2.5 }}
        label='Description'
        value={lumpSum.description}
        onChange={(e) =>
          onChange({
            ...lumpSum,
            description: e.target.value,
          })
        }
      />
      <FormControl sx={{ flex: 1 }}>
        <InputLabel id='lump-sum-type'>Type</InputLabel>
        <Select
          labelId='lump-sum-type'
          label='Type'
          value={lumpSum.type}
          onChange={(e) =>
            onChange({
              ...lumpSum,
              type: e.target.value as BenefitName,
            })
          }
        >
          {BENEFIT_NAMES.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        sx={{ flex: 1.25 }}
        label='Minimum amount'
        type='number'
        required
        error={!isMinimumAmountValid(lumpSum.minimum_amount)}
        value={lumpSum.minimum_amount.toFixed(2)}
        InputProps={{
          startAdornment: <InputAdornment position='start'>{currencySymbol}</InputAdornment>,
        }}
        onChange={(e) =>
          onChange({
            ...lumpSum,
            minimum_amount: getMinimumAmountValue(e),
          })
        }
      />
      <TextField
        sx={{ flex: 2 }}
        label='Target amount (optional)'
        error={!isTargetAmountValid(lumpSum.target_amount, lumpSum.minimum_amount)}
        type='number'
        value={lumpSum.target_amount?.toFixed(2) ?? ''}
        InputProps={{
          startAdornment: <InputAdornment position='start'>{currencySymbol}</InputAdornment>,
        }}
        onChange={(e) =>
          onChange({
            ...lumpSum,
            target_amount: getTargetAmountValue(e),
          })
        }
      />
      <Box sx={{ flex: 2.5 }}>
        <MultiSelectFilter
          value={selectedItems}
          options={formattedItems}
          label='Select applicable items'
          onChange={(options) => {
            setSelectedItems(options)
            onChange({
              ...lumpSum,
              commercial_ids: options.map(({ value }) => value),
            })
          }}
          fieldProps={{
            error: selectedItems.length === 0,
            helperText: selectedItems.length === 0 ? 'Please select at least one item' : null,
          }}
          conf={{
            renderAllOptionWhenAllSelected: true,
          }}
        />
      </Box>
      <DeleteButton onDelete={onDelete} />
    </Stack>
  )
}

const getMinimumAmountValue = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  const parsedValue = parseFloat(e.target.value)

  return Number.isNaN(parsedValue) ? 0 : parsedValue
}

const getTargetAmountValue = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  const parsedValue = parseFloat(e.target.value)

  return Number.isNaN(parsedValue) ? null : parsedValue
}

const getInitialSelectedItems = (
  lumpSum: LumpSum,
  items: Props['items'],
): FilterOption<string>[] =>
  lumpSum.commercial_ids.length === 0
    ? [RENAMED_ALL_ITEMS_OPTION]
    : toFilterValues(items.filter((item) => lumpSum.commercial_ids.includes(item.commercial_id)))

const toFilterValues = (items: ItemData[]): FilterOption<string>[] =>
  items.map((item) => ({
    label: item.name,
    value: item.commercial_id,
    type: 'SINGLE',
  }))
