import React, { useEffect, useState } from 'react'
import { Outlet, useOutletContext, useParams } from 'react-router-dom'
import { Alert, Box, Container, Typography } from '@mui/material'

import { ScenarioDetails as ScenarioDetailsResponse } from '@common/dto'
import { isBackendApiErrorResponse, useGetScenarioQuery } from 'src/merchandising/store/'
import { PactumLoader } from 'src/shared/components/PactumLoader'
import { useActiveProject } from '@shared/hooks/useActiveProject'

type ContextType = {
  detailsData: ScenarioDetailsResponse
  setDetailsData: (data: ScenarioDetailsResponse) => void
  sessionKey: string
}

export const ScenarioPreparation = () => {
  const { activeProjectTag } = useActiveProject()
  const { sessionKey } = useParams<{ sessionKey: string }>()
  const [detailsData, setDetailsData] = useState<ScenarioDetailsResponse | undefined>()

  const {
    data: initialScenarioData,
    isLoading,
    error,
  } = useGetScenarioQuery(
    { projectTag: activeProjectTag, sessionKey: sessionKey! },
    { skip: !sessionKey },
  )

  useEffect(() => {
    if (initialScenarioData?.state) {
      setDetailsData(initialScenarioData)
    }
  }, [initialScenarioData])

  if (!sessionKey) {
    return (
      <Alert severity='error'>
        We were unable to get scenario ID. Probably you followed a broken link.
      </Alert>
    )
  }

  const isVersionMismatchError = isBackendApiErrorResponse(error) && error.data.statusCode === 409
  if (isVersionMismatchError) {
    return (
      <Container maxWidth='xl' sx={{ py: 10, px: 3 }}>
        <Typography align='center'>
          This scenario can not be opened anymore. Please create a new scenario.
        </Typography>
        <Typography align='center'>
          If access to this scenario is necessary, please contact your account manager at Pactum.
        </Typography>
      </Container>
    )
  }

  if (isLoading || !detailsData) {
    return (
      <Container maxWidth='xl' sx={{ p: 3 }}>
        <PactumLoader />
      </Container>
    )
  }

  return (
    <Box>
      <Outlet context={{ detailsData, setDetailsData, sessionKey }} />
    </Box>
  )
}

export const useScenarioDetailsData = () => {
  return useOutletContext<ContextType>()
}
