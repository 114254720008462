import {
  DataGridPro,
  GridEventListener,
  GridFeatureMode,
  GridValueFormatterParams,
} from '@mui/x-data-grid-pro'
import capitalize from 'lodash/capitalize'
import { NegotiationEvent } from '../../store/types'
import { DataGrid, dataGridProps } from '@components/table'
import { Box, styled } from '@mui/material'
import { PriceListRoutes } from '../../priceListRoutes'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { generatePath, useNavigate } from 'react-router-dom'
import { useSuiteDataGrid } from '@components/SuiteDataGrid/SuiteDataGridProvider'
import { Toolbar } from './Toolbar'
import { UploadNegotiationEvent } from '../../components/UploadNegotiationEvent/UploadNegotiationEvent'
import { useState } from 'react'
import { NegotiationEventStatusChip } from '../../components/NegotiationEventStatusChip/NegotiationEventStatusChip'
import { useFormatter } from '@shared/hooks'
import { Formatter } from '@utils'
import { formatDate } from '../../utils/formatting'

export type GridPaginationModel = {
  page: number
  pageSize: number
}

export const NegotiationEventsList = ({
  rowCount,
  negotiationEvents,
  gridState,
}: {
  rowCount: number
  negotiationEvents: NegotiationEvent[]
  gridState: ReturnType<typeof useSuiteDataGrid>
}) => {
  const navigate = useNavigate()
  const formatter = useFormatter()
  const { activeOrgTag } = useActiveProject()
  const [uploadDialogOpened, setUploadDialogOpen] = useState<boolean>(false)

  const paginationOptions = {
    rowCount,
    pagination: true,
    paginationMode: 'server' as GridFeatureMode,
    page: gridState.pagination.page,
    pageSize: gridState.pagination.pageSize,
    hideFooter: false,
    onPageChange: (page: number) => gridState.setPagination({ ...gridState.pagination, page }),
    onPageSizeChange: (pageSize: number) =>
      gridState.setPagination({ ...gridState.pagination, pageSize }),
    rowsPerPageOptions: [10, 25, 50, 100],
  }

  const handleCellClick: GridEventListener<'cellClick'> = (cell) => {
    const actionableFields = ['__actions__', '__check__']
    const { projectTag } = cell.row as NegotiationEvent

    if (!actionableFields.includes(cell.field)) {
      navigate(
        generatePath(PriceListRoutes.NEGOTIATION_EVENT_DETAILS, {
          negotiationEventId: String(cell.id),
          orgTag: activeOrgTag,
          projectTag,
        }),
        { state: { from: PriceListRoutes.NEGOTIATION_EVENTS } },
      )
    }
  }

  return (
    <Box sx={{ pr: 6, pl: 6.5, pt: 5.5, pb: 4 }}>
      <UploadNegotiationEvent
        isOpen={uploadDialogOpened}
        handleClose={() => setUploadDialogOpen(false)}
      />
      <StyledDataGrid
        {...dataGridProps}
        columns={getColumns(formatter)}
        rows={negotiationEvents}
        sortModel={gridState.sort}
        filterModel={gridState.filters}
        pageSizeOptions={[10, 15, 25, 50]}
        onSortModelChange={gridState.setSort}
        paginationModel={gridState.pagination}
        onFilterModelChange={gridState.setFilters}
        onPaginationModelChange={gridState.setPagination}
        onCellClick={handleCellClick}
        slots={{ ...dataGridProps.slots, toolbar: Toolbar }}
        slotProps={{
          toolbar: {
            onOpenUploadDialog: () => setUploadDialogOpen(true),
          },
        }}
        {...paginationOptions}
      />
    </Box>
  )
}

const getColumns = (formatter: Formatter) => {
  return [
    {
      field: 'id',
      headerName: '#',
      flex: 0.6,
    },
    {
      field: 'title',
      headerName: 'Title',
      flex: 3,
    },
    {
      field: 'supplier.name',
      headerName: 'Supplier name',
      flex: 1.5,
      valueGetter: ({ row }: { row: NegotiationEvent }) => row?.supplier?.name,
    },
    {
      field: 'trigger',
      headerName: 'Negotiation trigger',
      flex: 1.5,
      valueFormatter: (params: GridValueFormatterParams<NegotiationEvent['trigger']>) =>
        params.value.split('_').map(capitalize).join(' '),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1.5,
      valueGetter: (cell: { row: NegotiationEvent }) => {
        return cell.row.status
      },
      renderCell: (cell: { row: NegotiationEvent }) => (
        <NegotiationEventStatusChip status={cell.row.status} />
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Created at',
      flex: 1.5,
      valueFormatter: (params: GridValueFormatterParams<NegotiationEvent['createdAt']>) =>
        formatDate(params.value as unknown as string, formatter),
    },
    {
      field: 'deadline',
      headerName: 'Negotiation deadline',
      flex: 1.5,
      valueFormatter: (params: GridValueFormatterParams<NegotiationEvent['deadline']>) =>
        formatDate(params.value as unknown as string, formatter),
    },
  ]
}

const StyledDataGrid = styled(DataGrid)(() => ({
  ' .MuiDataGrid-columnHeader .MuiBadge-badge': {
    display: 'none',
  },
  ' .select-cell, .select-header': {
    opacity: 0,
  },
  ' .MuiDataGrid-row:hover .select-cell': {
    opacity: 1,
  },
  ' .MuiDataGrid-columnHeaders:hover .select-header': {
    opacity: 1,
  },
  '&.show-selection': {
    ' .select-cell, .select-header': {
      opacity: 1,
    },
  },
})) as typeof DataGridPro
