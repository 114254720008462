import React from 'react'
import { Badge, Box, IconButton, Tooltip } from '@mui/material'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { GridColumnHeaderParams, useGridApiContext } from '@mui/x-data-grid-pro'

export interface ColumnHeaderFilterIconButtonProps {
  field: string
  counter?: number
  onClick?: (params: GridColumnHeaderParams, event: React.MouseEvent<HTMLButtonElement>) => void
}

// borrowed from admin-frontend
export const CustomColumnHeaderFilterIconButton = ({
  counter,
  field,
  onClick,
}: ColumnHeaderFilterIconButtonProps) => {
  const apiRef = useGridApiContext()
  const isFilterable = apiRef.current.getColumn(field).filterable

  const toggleFilter = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      event.stopPropagation()

      apiRef.current.showFilterPanel(field)

      if (onClick) {
        onClick(apiRef.current.getColumnHeaderParams(field), event)
      }
    },
    [apiRef, field, onClick],
  )

  if (!isFilterable) {
    return null
  }

  const iconButton = (
    <IconButton
      onClick={toggleFilter}
      color='default'
      aria-label='column-header-filters-label'
      size='small'
      tabIndex={-1}
    >
      <FilterAltIcon fontSize='small' />
    </IconButton>
  )

  return (
    <Tooltip title='Apply filter' enterDelay={1000}>
      <Box sx={{ display: 'flex', opacity: 0.25, ':hover': { opacity: 0.6 } }}>
        {counter && counter > 1 ? (
          <Badge badgeContent={counter} color='default'>
            {iconButton}
          </Badge>
        ) : (
          iconButton
        )}
      </Box>
    </Tooltip>
  )
}
