import { useDebounce } from '@hooks'
import { Search } from '@mui/icons-material'
import { InputAdornment, TextField, styled } from '@mui/material'
import { GridFilterModel } from '@mui/x-data-grid-pro'
import { ChangeEvent, useEffect, useState } from 'react'

export const SearchFilter = ({
  filterModel,
  setFilterModel,
}: {
  filterModel: GridFilterModel
  setFilterModel: (model: GridFilterModel) => void
}) => {
  // not controlling inputValue based on filterModel could cause problems when there are multiple of these,
  // but let's fix this when we get there :)
  const [inputValue, setInputValue] = useState(filterModel?.quickFilterValues?.join(' ') ?? '')

  const handleInputValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  const handleTextSearchValueChange = useDebounce((value: string) => {
    if (value.length > 0) {
      setFilterModel({
        ...filterModel,
        quickFilterValues: [value],
      })
    } else {
      setFilterModel({
        ...filterModel,
        quickFilterValues: [],
      })
    }
  }, 200)

  useEffect(() => {
    handleTextSearchValueChange(inputValue)
  }, [handleTextSearchValueChange, inputValue])

  return (
    <StyledTextField
      placeholder='Search...'
      size='small'
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <Search />
          </InputAdornment>
        ),
      }}
      value={inputValue}
      onChange={handleInputValueChange}
    />
  )
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '168px',
  '.MuiInputBase-root': {
    borderRadius: '100px',
    fontSize: '10px',
    fontWeight: 700,
    height: '24px',
    paddingLeft: '10px',
    paddingRight: '1px',
    paddingBottom: '2px',
    color: theme.typography.normal.color,
    boxShadow: 'none',
    borderColor: '#9E9E9E',
  },
  // Tweak text position inside the box
  '.MuiInputBase-input': { marginTop: '1px', marginLeft: '10px' },
  // Placeholder text
  '.MuiInputBase-input::placeholder': { color: theme.palette.text.secondary, opacity: 1 },
  // Hourglass icon
  '.MuiSvgIcon-root': { width: '18.33px', height: '18.33px', color: theme.palette.text.secondary },
  // Delete button (X)
  '.MuiButtonBase-root': { width: '22px', height: '22px' },
  // Hide the Material UI underline
  '.MuiInputBase-root:before': { display: 'none' },
  '.MuiInputBase-root:after': { display: 'none' },
}))
