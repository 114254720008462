import { useFieldArray, useFormContext } from 'react-hook-form'
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid-pro'
import { Checkbox, Grid, Typography } from '@mui/material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { DataGrid, dataGridProps } from '@components/table/DataGrid'
import { DetachedAttachment } from '@procurement/store/schema'

export const AttachmentsList = () => {
  const { control, watch } = useFormContext<{ detachedAttachments: DetachedAttachment[] }>()
  const { remove, update } = useFieldArray({ control, name: 'detachedAttachments' })

  const detachedAttachments = watch('detachedAttachments')

  const toggleSupplierVisibility = (fileName: string) => {
    const attachmentIndexToUpdate = getAttachmentIndexByFileName(detachedAttachments, fileName)
    const attachmentToUpdate = detachedAttachments[attachmentIndexToUpdate]

    if (attachmentToUpdate) {
      update(attachmentIndexToUpdate, {
        ...attachmentToUpdate,
        isVisibleToSupplier: !attachmentToUpdate.isVisibleToSupplier,
      })
    }
  }

  const handleFileDelete = (fileName: string) => {
    remove(getAttachmentIndexByFileName(detachedAttachments, fileName))
  }

  const columnProps = {
    editable: false,
    sortable: false,
    disableColumnMenu: true,
  }

  const columns: GridColDef<DetachedAttachment>[] = [
    {
      ...columnProps,
      field: 'isVisibleToSupplier',
      type: 'boolean',
      headerName: 'Show to supplier(s)',
      flex: 1.25,
      align: 'center',
      renderCell: ({ row }) => (
        <Checkbox
          checked={row.isVisibleToSupplier}
          onChange={() => toggleSupplierVisibility(row.file.name)}
          onClick={(event) => event.stopPropagation()}
        />
      ),
    },
    {
      ...columnProps,
      field: 'fileName',
      type: 'string',
      headerName: 'Available documents',
      flex: 3,
      renderCell: ({ row }) => <Typography noWrap>{row.file.name}</Typography>,
    },
    {
      ...columnProps,
      field: '__actions__',
      type: 'actions',
      headerName: '',
      flex: 1,
      align: 'right',
      getActions: ({ row }) => [
        <GridActionsCellItem
          label='Delete'
          icon={<DeleteOutlineIcon />}
          onClick={() => handleFileDelete(row.file.name)}
        />,
      ],
    },
  ]

  return (
    <Grid item xs={8}>
      <DataGrid
        {...dataGridProps}
        columns={columns}
        rows={detachedAttachments}
        getRowId={(row: DetachedAttachment) => row.file.name}
        localeText={{
          noRowsLabel: 'No attachments',
        }}
        onRowClick={({ row }: GridRowParams<DetachedAttachment>) => {
          toggleSupplierVisibility(row.file.name)
        }}
      />
    </Grid>
  )
}

const getAttachmentIndexByFileName = (attachments: DetachedAttachment[], fileName: string) => {
  return attachments.findIndex((attachment) => attachment.file.name === fileName)
}
