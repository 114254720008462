import React from 'react'
import { Box, Link } from '@mui/material'

type Props = {
  title: string
  children: React.ReactNode
}

export const ErrorPage = ({ title, children }: Props): JSX.Element => {
  return (
    <Box
      py={2}
      px={4}
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box>
        <h1>{title}</h1>
        {children}
        <p>
          If you need help, please contact{' '}
          <Link href='mailto:support@pactum.com' title='Contact support'>
            support@pactum.com
          </Link>
          . Otherwise please visit{' '}
          <Link href='https://www.pactum.com' title='Open pactum webpage'>
            www.pactum.com
          </Link>
        </p>
      </Box>
    </Box>
  )
}
