import { AlertTitle, Paper, Stack, StackProps, styled, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { PactumLoader } from 'src/shared/components/PactumLoader'

export interface SingleFileUploadProps {
  callback: (file: Blob) => void
  isLoading: boolean
  fileTypes: string[]
  className?: string
  customDropAreaContent?: React.FC<StackProps>
}

export const SingleFileUpload = ({
  callback,
  isLoading,
  fileTypes,
  className,
  children,
  customDropAreaContent,
}: PropsWithChildren<SingleFileUploadProps>): JSX.Element => {
  const errorTimeoutMs = 10000
  const onDrop = useCallback(
    (files: Blob[]) => {
      if (files.length === 1) {
        callback(files[0])
      }
    },
    [callback],
  )
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [rejectedFile, setRejectedFile] = useState<FileRejection | null>(null)

  const { fileRejections, getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    useFsAccessApi: false,
    maxSize: 10 * 1024 * 1024,
    accept: {
      ...fileTypes
        .map((acceptString) => ({ [acceptString]: [] }))
        .reduce((acc, acceptObj) => ({ ...acc, ...acceptObj }), {}),
    },
    onDropRejected: (fileRejection) => {
      setRejectedFile(fileRejection[0])
    },
  })
  const resetRejected = useCallback(() => {
    closeSnackbar()
    setRejectedFile(null)
  }, [closeSnackbar])

  if (rejectedFile) {
    setTimeout(resetRejected, errorTimeoutMs)
  }

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <span key={file.name}>
      <AlertTitle>File upload error</AlertTitle>
      <p>
        <b>File:</b> {file.name}
      </p>
      <p>
        <b>Size:</b> {file.size} bytes
      </p>
      <p>
        <b>Reasons:</b>
        {errors.map((e) => (
          <Typography key={e.code}>{e.message}</Typography>
        ))}
      </p>
    </span>
  ))

  useEffect(() => {
    if (rejectedFile) {
      enqueueSnackbar(fileRejectionItems, { variant: 'error', onClose: resetRejected })
    }
  }, [fileRejectionItems, resetRejected, rejectedFile, enqueueSnackbar])

  const DropAreaContent = customDropAreaContent ?? DefaultDropAreaContent

  return (
    <DropArea {...getRootProps()} className={className} elevation={0}>
      {isLoading ? (
        <PactumLoader sizePx={36} />
      ) : (
        <>
          <input {...getInputProps()} data-testid='file-input-test' />
          <DropAreaContent>
            {isDragActive ? <Typography>Drop the file here ...</Typography> : children}
          </DropAreaContent>
        </>
      )}
    </DropArea>
  )
}

const DropArea = styled(Paper)(({ theme }) => ({
  textAlign: 'center',
  border: `1px dashed ${theme.palette.grey['400']}`,
  width: '100%',
  minHeight: '84px',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',

  ':hover': {
    backgroundColor: theme.palette.grey['200'],
    transition: 'background-color 300ms ease-out',
  },
}))

const DefaultDropAreaContent = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  height: '100%',
})
