import { Outcome, Week } from '@common/types/scenario/ScenarioState'

export type FilterOptionValue = number | string | Outcome | Price | ChartType

export type FilterData = {
  items: FilterOption<string>[]
  markets: FilterOption<string>[]
  timeRange: FilterOption<string>[]
  outcome?: FilterOption<string>
  price?: FilterOption<Price>
  chartType?: FilterOption<ChartType>
}

export type FilterOption<T extends FilterOptionValue> = {
  label: string
  value: T
  type: OptionType
  withDivider?: boolean
}

export type OptionType = 'SINGLE' | 'ALL' | 'AGGREGATE' | 'FUTURE'

export type BSRFilters = Pick<
  FilterData,
  'items' | 'markets' | 'timeRange' | 'outcome' | 'chartType'
> & {
  week: Week
}

export type HistoricalFilters = Pick<FilterData, 'items' | 'markets' | 'price'>

export type ChartType = 'Benefit to spend ratio' | 'Spend and benefits'
export type Price = 'Invoice' | 'Global Pricing (net)' | 'Local Pricing (net)'

export type AvailableDefaultFilters = Extract<keyof FilterData, 'markets'>

function isSingleSelection<T extends FilterOptionValue>(filter: FilterOption<T>): boolean {
  return filter.type === 'SINGLE'
}

function getSingleSelectionValues<T extends FilterOptionValue>(
  specificFilters: FilterOption<T>[],
): T[] {
  return specificFilters.filter(Filters.isSingleSelection).map((filter) => filter.value)
}

function isAggregateSelection<T extends FilterOptionValue>(filter: FilterOption<T>): boolean {
  return filter.type === 'AGGREGATE'
}

function isFutureSelection<T extends FilterOptionValue>(filter: FilterOption<T>): boolean {
  return filter.type === 'FUTURE'
}

function isAllSelection<T extends FilterOptionValue>(filter: FilterOption<T>): boolean {
  return filter.type === 'ALL'
}

function isFutureTimeRangeSelected(filters: Pick<FilterData, 'timeRange'>): boolean {
  return filters.timeRange.findIndex(Filters.isFutureSelection) >= 0
}

function isTimeRangeAggregationSelected(filters: Pick<FilterData, 'timeRange'>): boolean {
  return filters.timeRange.findIndex(Filters.isAggregateSelection) >= 0
}

function isMarketAggregationSelected(filters: Pick<FilterData, 'markets'>): boolean {
  return filters.markets.findIndex(Filters.isAggregateSelection) >= 0
}

function isItemAggregationSelected(filters: Pick<FilterData, 'items'>): boolean {
  return filters.items.findIndex(Filters.isAggregateSelection) >= 0
}

export const Filters = {
  isSingleSelection,
  getSingleSelectionValues,
  isAggregateSelection,
  isFutureSelection,
  isAllSelection,
  isFutureTimeRangeSelected,
  isTimeRangeAggregationSelected,
  isMarketAggregationSelected,
  isItemAggregationSelected,
}
