import { Grid } from '@mui/material'
import { PurchasingUIConfig } from '@procurement/store/types'
import { Prices } from './sections/Prices'
import { PurchaseDetails } from './sections/PurchaseDetails'
import { ProductType } from '@pactum/core-backend-types'
import { Incoterms } from '@procurement-shared/NegotiationEventForm/sections/Incoterms'
import { Incentives } from '@procurement-shared/NegotiationEventForm/sections/Incentives'
import { Delivery } from '@procurement-shared/NegotiationEventForm/sections/Delivery'
import { SupplierInformation } from '@procurement-shared/NegotiationEventForm/sections/SupplierInformation'
import { ContractModel } from '@procurement-shared/NegotiationEventForm/sections/ContractModel/ContractModel'
import { ContractLength } from '@procurement-shared/NegotiationEventForm/sections/ContractLength/ContractLength'
import { Warranty } from '@procurement-shared/NegotiationEventForm/sections/Warranty'
import { PaymentDays } from '@procurement-shared/NegotiationEventForm/sections/PaymentDays'
import { Prerequisites } from '@procurement-shared/NegotiationEventForm/sections/Prerequisites'
import { useNegotiationEventForm } from '@procurement-shared/NegotiationEventForm/NegotiationEventFormContext'
import { Attachments } from '@procurement/components/FormSections/Attachments'

interface Props {
  productType?: ProductType
  suiteConfig?: PurchasingUIConfig['suite']
  isEditing?: boolean
}

export const NegotiationEventForm = ({ productType, suiteConfig, isEditing }: Props) => {
  const {
    incentivesOptions = [],
    paymentDaysOptions = [],
    categoryOptions = [],
    defaultContractModelId,
    allowedIncoterms,
    defaultIncoterm,
    termsBasisOptions,
    usingStandardSequences,
    moqEnabled,
    spendEnabled,
  } = suiteConfig || {}
  const { disabledFields, visibleFields } = useNegotiationEventForm()
  const isContractCost = productType === ProductType.ContractCost
  const isPurchasing = productType === ProductType.Purchasing

  return (
    <Grid container direction='column' gap={2}>
      <PurchaseDetails
        productType={productType}
        usingStandardSequences={usingStandardSequences}
        categoryOptions={categoryOptions}
      />
      <Delivery productType={productType} />
      {isContractCost && (
        <ContractModel productType={productType} defaultContractModelId={defaultContractModelId} />
      )}
      <SupplierInformation paymentDays={paymentDaysOptions} productType={productType} />
      <Prices
        productType={productType}
        allowedIncoterms={allowedIncoterms}
        defaultIncoterm={defaultIncoterm}
        termsBasisOptions={termsBasisOptions}
        moqEnabled={isContractCost ? moqEnabled : false}
        spendEnabled={spendEnabled}
        isEditing={isEditing}
      />
      {isContractCost && (
        <>
          <ContractLength />
          <Incoterms allowedIncoterms={allowedIncoterms} />
          <Warranty />
          <PaymentDays paymentDays={paymentDaysOptions} />
          <Incentives incentives={incentivesOptions} />
        </>
      )}
      {(visibleFields.includes('attachments') || isPurchasing) && (
        <Attachments
          canUpload={!disabledFields.includes('attachments')}
          description='You can attach files to use as background material, such as quotes and SOWs. Attachments will be made visible for all suppliers in the requisition'
        />
      )}
      {isContractCost && <Prerequisites />}
    </Grid>
  )
}
