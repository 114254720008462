import { ChartDataset, TooltipItem } from 'chart.js'
import { Theme } from '@mui/material'
import {
  CommercialId,
  ItemInfo,
  MarketInfo,
  MarketMetrics,
  ScenarioInfo,
  ScenarioState,
  TransactionMetric,
  ValueRecord,
} from '@common/types/scenario/ScenarioState'
import { FilterData, Filters, Price } from '../components/Filters/types'
import { averageNumbers, sumNumbers } from '@common/utils'
import {
  capitalizeFirstLetter,
  Formatter,
  integerFormatter,
  isNumberZeroInclusive,
  percentFormatter,
  twoFractionsNumberFormatter,
} from '@utils'
import {
  DataField,
  GetDatasetsParameters,
  HistoricalChartDataset,
  PreviousAndCurrentValue,
  QoQChange,
  ValuesFieldAccessor,
} from './types'

const dataFields: DataField[] = ['price', 'volume', 'spend']

export const getDatasets = ({ filters, historical, theme }: GetDatasetsParameters) => {
  const datasetsPerField = {} as Record<DataField, ChartDataset[]>
  const price = filters.price?.value ?? 'Invoice'

  dataFields.forEach((field) => {
    const datasets: HistoricalChartDataset[] = []

    filters.markets.forEach((marketFilter, marketIndex) => {
      const market = historical.metrics.markets[marketFilter.value]

      filters.items.forEach((itemFilter, itemIndex) => {
        const item = market?.item_metrics[itemFilter.value]
        const { lineDash, lineColor } = getLineStyles(
          marketFilter,
          itemFilter,
          marketIndex,
          itemIndex,
          theme,
        )

        if (
          Filters.isAggregateSelection(marketFilter) &&
          Filters.isAggregateSelection(itemFilter)
        ) {
          // all items and all markets aggregation
          const allItemMetricsAcrossMarkets = getAllItemMetricsAcrossMarkets(historical)
          datasets.push({
            market: marketFilter.label,
            backgroundColor: lineColor,
            borderColor: lineColor,
            borderDash: lineDash,
            data: getItemsAggregationValues(allItemMetricsAcrossMarkets, field, price),
            item: itemFilter.label,
            qoqChanges: getQoqChanges(
              allItemMetricsAcrossMarkets,
              (itemMetrics) => getItemValues(itemMetrics, field, price),
              getAggregation(field),
            ),
            field,
          })

          return
        }

        if (
          Filters.isAggregateSelection(marketFilter) &&
          !Filters.isAggregateSelection(itemFilter)
        ) {
          // single item aggregation across all markets
          const selectedItemMetricsAcrossMarkets = Object.values(
            historical.metrics.markets,
          ).flatMap((marketMetrics) => marketMetrics.item_metrics[itemFilter.value])
          datasets.push({
            market: marketFilter.label,
            backgroundColor: lineColor,
            borderColor: lineColor,
            data: getItemsAggregationValues(selectedItemMetricsAcrossMarkets, field, price),
            item: itemFilter.label,
            qoqChanges: getQoqChanges(
              selectedItemMetricsAcrossMarkets,
              (itemMetrics) => getItemValues(itemMetrics, field, price),
              getAggregation(field),
            ),
            field,
          })

          return
        }

        if (Filters.isAggregateSelection(itemFilter) && market) {
          // all items aggregation in single selected market
          const allItemsMetricsForSelectedMarket = Object.values(market.item_metrics)
          datasets.push({
            market: marketFilter.label,
            backgroundColor: lineColor,
            borderColor: lineColor,
            borderDash: lineDash,
            data: getItemsAggregationValues(allItemsMetricsForSelectedMarket, field, price),
            item: itemFilter.label,
            qoqChanges: getQoqChanges(
              allItemsMetricsForSelectedMarket,
              (itemMetrics) => getItemValues(itemMetrics, field, price),
              getAggregation(field),
            ),
            field,
          })

          return
        }

        if (item) {
          // single item in single market
          const itemValues = getItemValues(item, field, price)
          datasets.push({
            market: marketFilter.label,
            borderColor: lineColor,
            backgroundColor: lineColor,
            borderDash: lineDash,
            data: itemValues.map((value) => value.value as number),
            item: itemFilter.label,
            qoqChanges: getQoqChanges(
              [item],
              (itemMetrics) => getItemValues(itemMetrics, field, price),
              getAggregation(field),
            ),
            field,
          })
        }
      })
    })

    datasetsPerField[field] = datasets
  })

  return datasetsPerField
}

export const getAllItemMetricsAcrossMarkets = (
  historical: Required<ScenarioState>['historical'],
) => {
  return Object.values(historical.metrics.markets).flatMap((marketMetrics) =>
    Object.values(marketMetrics.item_metrics),
  )
}

const getItemsAggregationValues = (
  selectedItemMetrics: Array<MarketMetrics['item_metrics'][CommercialId]>,
  field: DataField,
  price: Price,
): number[] =>
  selectedItemMetrics
    .filter(Boolean)
    .flatMap((itemMetrics) => getItemValues(itemMetrics, field, price))
    .reduce(collectNonNullValuesPerIndex(), [] as number[][])
    .map(aggregateValuesForField(field))

const getAggregatedValuesWithPreviousByIndex = (
  itemMetrics: Array<MarketMetrics['item_metrics'][CommercialId]>,
  fieldAccessor: ValuesFieldAccessor,
  aggregationOp: (array: number[]) => number,
): Record<number, PreviousAndCurrentValue> => {
  const fieldValuesPerIndex: number[][] = itemMetrics
    .filter(Boolean)
    .flatMap(fieldAccessor)
    .reduce(collectValuesPerIndex, [] as number[][])
  const indexValuesWithPrevious: Record<number, PreviousAndCurrentValue> = {}
  for (let i = 1; i < fieldValuesPerIndex.length; i++) {
    const currentIndexValues = fieldValuesPerIndex[i]
    const previousIndexValues = fieldValuesPerIndex[i - 1]
    if (currentIndexValues?.length > 0 && previousIndexValues?.length > 0) {
      indexValuesWithPrevious[i] = [
        aggregationOp(previousIndexValues),
        aggregationOp(currentIndexValues),
      ]
    }
  }
  return indexValuesWithPrevious
}

export const getQoqAggregationValues = (
  itemMetrics: Array<MarketMetrics['item_metrics'][CommercialId]>,
  fieldAccessor: ValuesFieldAccessor,
  aggregationOp: ReturnType<typeof getAggregation>,
): QoQChange => {
  const indexValuesWithPreviousByIndex = getAggregatedValuesWithPreviousByIndex(
    itemMetrics,
    fieldAccessor,
    aggregationOp,
  )
  const indexValuesWithPrevious = Object.values(indexValuesWithPreviousByIndex)
  return {
    change: averageNumbers(indexValuesWithPrevious.map(calculateChange)),
    proportionalChange: averageNumbers(indexValuesWithPrevious.map(calculateProportionalChange)),
  }
}

const calculateChange = ([previous, current]: PreviousAndCurrentValue) => current - previous
const calculateProportionalChange = ([previous, current]: PreviousAndCurrentValue) =>
  (current - previous) / previous

const getQoqChanges = (
  itemMetrics: Array<MarketMetrics['item_metrics'][CommercialId]>,
  fieldAccessor: ValuesFieldAccessor,
  aggregationOp: ReturnType<typeof getAggregation>,
): HistoricalChartDataset['qoqChanges'] => {
  const indexValuesWithPreviousByIndex = Object.entries(
    getAggregatedValuesWithPreviousByIndex(itemMetrics, fieldAccessor, aggregationOp),
  )
  return indexValuesWithPreviousByIndex.reduce(
    (acc, [index, previousAndCurrentValues]) => ({
      ...acc,
      [Number(index)]: {
        change: calculateChange(previousAndCurrentValues),
        proportionalChange: calculateProportionalChange(previousAndCurrentValues),
      },
    }),
    {} as HistoricalChartDataset['qoqChanges'],
  )
}

// Values for Index are ignored and not added to returning list when there are no numeric values
// for specific Index
const collectNonNullValuesPerIndex = () => (acc: number[][], valueRecord: ValueRecord) => {
  const valuesPerIndex = acc[valueRecord.index]
  if (valuesPerIndex && isNumberZeroInclusive(valueRecord.value)) {
    acc[valueRecord.index].push(valueRecord.value)
  } else if (valueRecord.value) {
    acc[valueRecord.index] = [valueRecord.value]
  }
  return acc
}

// Values for Index are set to empty list if there are no numeric values for specific Index
const collectValuesPerIndex = (acc: number[][], valueRecord: ValueRecord) => {
  const valuesPerIndex = acc[valueRecord.index]
  if (valuesPerIndex && isNumberZeroInclusive(valueRecord.value)) {
    acc[valueRecord.index].push(valueRecord.value)
  } else if (valueRecord.value) {
    acc[valueRecord.index] = [valueRecord.value]
  } else {
    acc[valueRecord.index] = []
  }
  return acc
}

const aggregateValuesForField = (field: DataField) => (valuesPerIndex: number[]) => {
  const filteredValues = valuesPerIndex.filter(Boolean)
  if (field === 'volume' || field === 'spend') {
    return sumNumbers(filteredValues)
  }
  // else 'price'
  return averageNumbers(filteredValues)
}

export const getChartOptions = (formatter: Formatter) => {
  const tooltipTitle = (tooltipItems: TooltipItem<'line'>[]) => {
    const tooltipData = tooltipItems.map((tooltipItem) => ({
      market: (tooltipItem.dataset as HistoricalChartDataset).market,
      item: (tooltipItem.dataset as HistoricalChartDataset).item,
    }))

    return tooltipData.map(({ market, item }) => `${market}, ${item}`)
  }

  const tooltipLabel = (tooltipItem: TooltipItem<'line'>) => {
    const { field, qoqChanges } = tooltipItem.dataset as HistoricalChartDataset
    const fieldFormatter = field === 'volume' ? formatter.number : formatter.currency

    const qoqChange = qoqChanges[tooltipItem.dataIndex]

    const qoqRows = qoqChange
      ? [
          `QoQ change: ${formatValue(qoqChange.change)}`,
          `QoQ proportional: ${formatPercentageValue(qoqChange.proportionalChange)}`,
        ]
      : []

    return [
      `${capitalizeFirstLetter(field)}: ${fieldFormatter(tooltipItem.raw as number)}`,
      ...qoqRows,
    ]
  }

  return {
    spanGaps: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          title: tooltipTitle,
          label: tooltipLabel,
        },
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'y' as const,
        },
      },
      datalabels: {
        display: false,
      },
    },
  }
}

export const getVodafoneColors = (count: number) => {
  // TODO: replace with colors from theme
  const VODAFONE_COLORS = [
    '#FEC800',
    '#EB9700',
    '#AB6300',
    '#A8B400',
    '#00B0CA',
    '#007E92',
    '#9C2AA0',
    '#5E2750',
    '#000000',
  ] as const

  const colors = []

  for (let i = 0; i < count; i++) {
    colors.push(VODAFONE_COLORS[i % VODAFONE_COLORS.length])
  }

  return colors
}

const getItemValues = (
  item: MarketMetrics['item_metrics'][CommercialId],
  field: DataField,
  price: Price,
): TransactionMetric['values'] => {
  if (field === 'volume') {
    return item.volume.values
  }

  if (price === 'Invoice') {
    return item[field].invoice.values
  }

  const week = price === 'Global Pricing (net)' ? 'W1' : 'W2'
  return item[field].net[week].values
}

export const getAggregation = (field: DataField) =>
  field === 'price' ? averageNumbers : sumNumbers

export const formatValue = (value: number | null, missingValueSymbol: string = '-'): string => {
  if (value && Number.isInteger(value)) {
    return integerFormatter(value)
  } else if (value && isNumberZeroInclusive(value)) {
    return twoFractionsNumberFormatter(value)
  }
  return missingValueSymbol
}

export const formatPercentageValue = (
  value: number | null,
  missingValueSymbol: string = '-',
): string => {
  if (value && isNumberZeroInclusive(value)) {
    return percentFormatter(value)
  }
  return missingValueSymbol
}

const getLineStyles = (
  marketFilter: FilterData['markets'][0],
  itemFilter: FilterData['items'][0],
  marketIndex: number,
  itemIndex: number,
  theme: Theme,
) => {
  // temporarily filter out 7th color because it's not visible on the white background
  const colors = Object.values(theme.palette.common.charts).filter(
    (color) => color !== theme.palette.common.charts.seventh,
  )
  const lineDashLengthMultiplier = 2
  const lineDash = [lineDashLengthMultiplier * marketIndex + lineDashLengthMultiplier]
  const lineColor = colors[itemIndex % colors.length]

  return {
    lineDash: Filters.isAggregateSelection(marketFilter) ? undefined : lineDash,
    lineColor: Filters.isAggregateSelection(itemFilter) ? theme.palette.common.black : lineColor,
  }
}

export const filterWithHistoricalData = <T extends MarketInfo | ItemInfo>(
  scenarioInfo: ScenarioInfo<T>,
): ScenarioInfo<T> =>
  Object.keys(scenarioInfo)
    .filter((id) => scenarioInfo[id].has_historical_data)
    .reduce((info, id) => {
      info[id] = scenarioInfo[id]
      return info
    }, {} as ScenarioInfo<T>)
