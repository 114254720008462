import {
  isBackendApiErrorResponse,
  isBackendValidationErrorResponse,
} from '@shared/backend/error/typeGuards'
import { ValidationError } from '@logistics/types/error'

export function extractErrorMessage(
  error: unknown,
  keyDisplayMap?: Record<string, string>,
  additionalMessage?: string,
): string | null {
  if (isBackendValidationErrorResponse<{ data: ValidationError }>(error)) {
    const errors =
      error.data.details?.reduce(
        (acc, { constraints }) => ({ ...acc, ...constraints }),
        {} as Record<string, string>,
      ) ?? {}

    // TODO: nicer way of representing multiline errors
    return `${error.data.message}: ${Object.entries(errors)
      .map(([k, v]) => {
        if (keyDisplayMap?.[k]) {
          return `"${keyDisplayMap?.[k]}: ${v}"`
        }
        return `"${k}: ${v}"`
      })
      .join(',')}`
  }

  if (isBackendApiErrorResponse(error)) {
    return `An error occurred: ${error.data.message}. ${additionalMessage ?? ''}`.trimEnd()
  }

  return `An unexpected error occurred. ${additionalMessage ?? ''}`.trimEnd()
}
