import React, { PropsWithChildren } from 'react'
import { Container, Stack, styled } from '@mui/material'
import { APP_HEADER_AND_GREETING_HEIGHT, LAYOUT_CONTAINER_X_PADDING } from 'src/main/constants'

export const InnerContainer = ({ children }: PropsWithChildren<{}>): JSX.Element => (
  <Container maxWidth='xl' disableGutters sx={{ px: LAYOUT_CONTAINER_X_PADDING }}>
    {children}
  </Container>
)

export const OuterContainer = styled(Stack)(({ theme }) => ({
  minHeight: `${APP_HEADER_AND_GREETING_HEIGHT}px`,
  backgroundColor: theme.palette.primary.main,
}))
