import React, { useEffect, useState } from 'react'

import { PermissionType } from '@common/types/AuthPermissions'
import { useUserPermissions } from '@hooks'

interface Props {
  requiredPerm?: PermissionType
  anyOf?: PermissionType[]
  children: React.ReactNode
}

export const ProtectedComponent = ({ requiredPerm, anyOf, children }: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true)
  const [isAllowed, setIsAllowed] = useState(false)
  const { userPermissionsInActiveOrg } = useUserPermissions()

  useEffect(() => {
    const checkPermissions = async () => {
      if (requiredPerm) {
        setIsAllowed(userPermissionsInActiveOrg.has(requiredPerm))
      } else if (anyOf) {
        setIsAllowed(anyOf.some((p) => userPermissionsInActiveOrg.has(p)))
      } else {
        setIsAllowed(false)
      }
      setIsLoading(false)
    }

    checkPermissions()
  }, [userPermissionsInActiveOrg, requiredPerm, anyOf, isAllowed, children])

  if (isLoading) {
    return <></>
  }

  return isAllowed ? <>{children}</> : <></>
}
