import { Grid, Stack, Typography } from '@mui/material'

export interface TermItem {
  title: string
  subtitle: string
  value: string
  secondaryValue?: string
  altTitle?: string
  altValue?: string
}

interface TermGridProps {
  items: TermItem[]
}

export const TermGrid = ({ items }: TermGridProps) => (
  <Grid container rowSpacing={4} mt={3}>
    {items.map((item) => (
      <Grid item xs={6} key={item.title}>
        <Typography variant='h3' marginBottom={2.75}>
          {item.title}
        </Typography>
        <Stack direction='row' marginLeft={1}>
          <Stack gap={1} flexGrow={1} sx={{ minWidth: { lg: '288px' } }}>
            <Typography variant='body2' fontWeight='bold'>
              {item.subtitle}
            </Typography>
            <Typography variant='body2'>{item.value}</Typography>
            {item.secondaryValue !== undefined ? (
              <Typography variant='caption' color='text.secondary'>
                {item.secondaryValue}
              </Typography>
            ) : null}
          </Stack>
          {item.altTitle !== undefined ? (
            <Stack gap={1} flexGrow={1}>
              <Typography color='text.secondary' variant='body2'>
                {item.altTitle}
              </Typography>
              {item.altValue !== undefined ? (
                <Typography variant='body2'>{item.altValue}</Typography>
              ) : null}
            </Stack>
          ) : null}
        </Stack>
      </Grid>
    ))}
  </Grid>
)
