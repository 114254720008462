import { NegotiationListEntity } from '@common/dto/pact/negotiation-list-entity'
import { NegotiationId, ProjectTag } from '@common/types'
import { suiteBaseApi } from '@shared/api/suite-base-api'
import { responseAsObjectUrl } from '../utils/file'
import { NegotiationUnitEntity } from '@common/dto/pact/negotiation-unit-entity'

export const pactApi = suiteBaseApi.injectEndpoints({
  endpoints: (build) => ({
    getPactNegotiations: build.query<NegotiationListEntity[], { projectTag: ProjectTag }>({
      query: ({ projectTag }) => ({
        url: `/pact/negotiations/${projectTag}`,
      }),
    }),
    getPactNegotiationUnits: build.query<
      NegotiationUnitEntity[],
      { projectTag: ProjectTag; negotiationId: number }
    >({
      query: ({ projectTag, negotiationId }) => ({
        url: `/pact/negotiations/${projectTag}/${negotiationId}/units`,
      }),
    }),
    downloadPactNegotiationsCsv: build.query<
      string,
      { projectTag: ProjectTag; ids?: NegotiationId[] }
    >({
      query: ({ projectTag, ids }) => ({
        url: `/pact/negotiations/${projectTag}/csv${ids ? `?ids=${ids.join(',')}` : ''}`,
        responseHandler: responseAsObjectUrl,
      }),
    }),
    downloadPactNegotiationUnitsCsv: build.query<
      string,
      { projectTag: ProjectTag; negotiationId: number; ids?: NegotiationId[] }
    >({
      query: ({ projectTag, negotiationId, ids }) => ({
        url: `/pact/negotiations/${projectTag}/${negotiationId}/units/csv${ids ? `?ids=${ids.join(',')}` : ''}`,
        responseHandler: responseAsObjectUrl,
      }),
    }),
  }),
})

export const {
  useGetPactNegotiationsQuery,
  useLazyDownloadPactNegotiationsCsvQuery,
  useGetPactNegotiationUnitsQuery,
  useLazyDownloadPactNegotiationUnitsCsvQuery,
} = pactApi
