import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useGetConfigurationQuery } from '../../store/purchasing'
import { PurchasingUIConfig } from '../../store/types'
import { useActiveProject } from '@shared/hooks/useActiveProject'

interface ConfigurableFields {
  requiredFields: string[]
  visibleFields: string[]
  disabledFields: string[]
}

interface NegotiationEventFormContextType extends ConfigurableFields {
  updateFields: (configurableFields: Partial<ConfigurableFields>) => void
  setFields: (configurableFields: Partial<ConfigurableFields>) => void
}

export const NegotiationEventFormContext = createContext<NegotiationEventFormContextType>({
  requiredFields: [],
  visibleFields: [],
  disabledFields: [],
  updateFields: () => {},
  setFields: () => {},
})

export const useNegotiationEventForm = () => useContext(NegotiationEventFormContext)

export const NegotiationEventFormContextProvider = ({ children }: PropsWithChildren) => {
  const { activeProjectTag } = useActiveProject()
  const { data: configuration } = useGetConfigurationQuery({ projectTag: activeProjectTag })
  const [requiredFields, setRequiredFields] = useState<string[]>([])
  const [visibleFields, setVisibleFields] = useState<string[]>([])
  const [disabledFields, setDisabledFields] = useState<string[]>([])

  const updateFields = useCallback(
    (updatedFields: Partial<ConfigurableFields>) => {
      setRequiredFields([...new Set(updatedFields.requiredFields ?? requiredFields)])
      setVisibleFields([...new Set(updatedFields.visibleFields ?? visibleFields)])
      setDisabledFields([...new Set(updatedFields.disabledFields ?? disabledFields)])
    },
    [disabledFields, requiredFields, visibleFields],
  )

  const setFields = useCallback((updatedFields: Partial<ConfigurableFields>) => {
    setRequiredFields([...new Set(updatedFields.requiredFields)])
    setVisibleFields([...new Set(updatedFields.visibleFields)])
    setDisabledFields([...new Set(updatedFields.disabledFields)])
  }, [])

  useEffect(() => {
    const fields = buildConfigurableFields(configuration?.data.suite.requisitionFormFields)

    setRequiredFields(fields.requiredFields)
    setVisibleFields(fields.visibleFields)
    setDisabledFields(fields.disabledFields)
  }, [configuration?.data.suite.requisitionFormFields])

  const providerValue = useMemo(
    () => ({
      requiredFields,
      visibleFields,
      disabledFields,
      updateFields,
      setFields,
    }),
    [requiredFields, visibleFields, disabledFields, updateFields, setFields],
  )

  return (
    <NegotiationEventFormContext.Provider value={providerValue}>
      {children}
    </NegotiationEventFormContext.Provider>
  )
}

export const buildConfigurableFields = (
  formFields: PurchasingUIConfig['suite']['requisitionFormFields'],
): ConfigurableFields => {
  const requiredFields: string[] = []
  const disabledFields: string[] = []
  const visibleFields: string[] = []

  formFields?.forEach((field) => {
    if (field.disabled) {
      disabledFields.push(field.id)
    }

    if (field.visible) {
      visibleFields.push(field.id)
    }

    if (field.required) {
      requiredFields.push(field.id)
    }
  })

  return {
    requiredFields,
    disabledFields,
    visibleFields,
  }
}
