import { PropsWithChildren } from 'react'
import { Typography, Stack } from '@mui/material'
import { capitalizeFirstLetter } from '@utils'
import { InnerContainer, OuterContainer } from './Containers'
import { User } from '@auth0/auth0-spa-js'
import { useUser } from '@hooks'

const getGreeting = (): string => {
  const today = new Date()
  const hours = today.getHours()

  if (hours < 4) {
    return 'Good night'
  } else if (hours < 12) {
    return 'Good morning'
  } else if (hours < 18) {
    return 'Good afternoon'
  }

  return 'Good evening'
}

const getGreetingName = (user: User | null): string => {
  if (user && user.given_name) {
    return `, ${capitalizeFirstLetter(user.given_name)}`
  }

  return ''
}

export const GreetingHeader = ({ children }: PropsWithChildren): JSX.Element => {
  const { user } = useUser()

  return (
    <OuterContainer alignContent='baseline' justifyContent='end'>
      <InnerContainer>
        <Stack direction='row'>
          <Typography
            variant='h4'
            fontWeight={600}
            mb={5.25}
            letterSpacing='-0.02em'
            sx={{ color: 'common.white' }}
          >
            {getGreeting()}
            {getGreetingName(user)}
          </Typography>
          {children}
        </Stack>
      </InnerContainer>
    </OuterContainer>
  )
}
