import { CustomImportSchemaFieldWithExample } from '@common/types'
import { TextFieldCtrl } from '@components/Form'
import { Grid } from '@mui/material'
import { getCustomFieldName } from './getCustomFieldName'

export const CustomInputFieldString = ({
  field,
}: {
  field: CustomImportSchemaFieldWithExample
}) => {
  return (
    <Grid item xs={12} md={4}>
      <TextFieldCtrl
        name={getCustomFieldName(field)}
        label={field.headerLabel}
        fullWidth
        variant='outlined'
      />
    </Grid>
  )
}
