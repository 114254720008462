import { Rating, RatingProps } from '@mui/material'
import { useController } from 'react-hook-form'
import { SyntheticEvent } from 'react'

type RatingCtrlProps = RatingProps & {
  name: string
  onChange?: (event: SyntheticEvent, value: number | null) => void
}

/** MUI Rating controlled by react-hook-form */
export const RatingCtrl = ({ name, onChange, ...rest }: RatingCtrlProps) => {
  const { field } = useController({ name })

  function handleChange(event: SyntheticEvent, value: number | null) {
    field.onChange(event)
    if (onChange) {
      onChange(event, value)
    }
  }

  return (
    <Rating
      name={field.name}
      value={field.value ? Number(field.value) : null}
      onChange={handleChange}
      onBlur={field.onBlur}
      ref={field.ref}
      {...rest}
    />
  )
}
