export const formatPaymentTerms = (
  termsDiscount: number | null | undefined,
  termsDiscountDays: number | null | undefined,
  paymentDays: number | null | undefined,
) => {
  const noDiscount = termsDiscount === null || termsDiscount === undefined || termsDiscount <= 0
  const noDiscountDays =
    termsDiscountDays === null || termsDiscountDays === undefined || termsDiscountDays <= 0

  if (paymentDays === null) {
    return null
  }

  if (noDiscount || noDiscountDays) {
    return `Net ${paymentDays}`
  }

  return `${termsDiscount}/${termsDiscountDays} Net ${paymentDays}`
}
