import React from 'react'
import { Box, Container, Link, Typography } from '@mui/material'
import { APP_HEADER_HEIGHT } from '@constants'

export const ReadonlyWorkspaceBanner = () => (
  <Box
    display='flex'
    justifyContent='left'
    alignItems='center'
    pr={6}
    px={1}
    py={1}
    sx={(theme) => ({
      backgroundColor: 'rgba(255, 206, 32, 0.4)',
      position: 'sticky',
      top: APP_HEADER_HEIGHT,
      zIndex: theme.zIndex.contentOverlay,
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)', // Explicit from Figma
    })}
  >
    <Container sx={{ px: 6, height: '100%' }} maxWidth='xl' disableGutters>
      <Typography variant='h3' fontWeight={500}>
        This workspace is in read-only mode. Contact{' '}
        <Link href='mailto:support@pactum.com' title='Contact support'>
          support@pactum.com
        </Link>{' '}
        for changes.
      </Typography>
    </Container>
  </Box>
)
