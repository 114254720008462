import { NegotiationEvent, NegotiationEventSupplier } from '../../../store/types'

export type NegotiationEventSupplierListAction =
  | 'edit'
  | 'delete'
  | 'selectWinner'
  | 'confirmWinner'
  | 'disable'
  | 'enable'

export const getSupplierActions = (
  negotiationEvent: NegotiationEvent,
  negotiationEventSupplier: NegotiationEventSupplier,
): NegotiationEventSupplierListAction[] => {
  const negotiationsStarted = negotiationEvent.negotiationsStartedAt !== null
  const negotiationsClosed = negotiationEvent.negotiationsClosedAt !== null
  const isOriginalSupplier = negotiationEventSupplier.isOriginalSupplier
  const isWinningNegotiationConfirmed = negotiationEvent.winningNegotiationConfirmedAt !== null
  const isAgreementReached = negotiationEventSupplier.negotiation?.status === 'AGREEMENT_REACHED'
  const isWinner = negotiationEventSupplier.isWinner
  const isComplementaryRequest = negotiationEvent.complementaryRequest
  const isFromAutomatedTacticalSourcing =
    negotiationEventSupplier.origin === 'AUTOMATED_TACTICAL_SOURCING'

  if (!negotiationsStarted) {
    if (!negotiationEventSupplier.isEnabledForNegotiation) {
      return ['enable']
    }

    if (isOriginalSupplier) {
      return ['edit', 'disable']
    } else {
      return ['edit', 'disable', 'delete']
    }
  }

  if (!negotiationsClosed || !isAgreementReached || (isWinningNegotiationConfirmed && isWinner)) {
    return []
  }

  if (
    isAgreementReached &&
    !isWinner &&
    !isComplementaryRequest &&
    !isFromAutomatedTacticalSourcing
  ) {
    return ['selectWinner']
  }

  if (
    isWinner &&
    !isWinningNegotiationConfirmed &&
    !isComplementaryRequest &&
    !isFromAutomatedTacticalSourcing
  ) {
    return ['confirmWinner']
  }

  return []
}
