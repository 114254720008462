import { zodResolver } from '@hookform/resolvers/zod'
import { useNotifications } from '@hooks'
import BackArrowIcon from '@mui/icons-material/ArrowBack'
import { Alert, Box, Button, Grid, IconButton, Stack, styled, Typography } from '@mui/material'
import { SupportedChatLocale } from '@pactum/common'
import { Branding, LocalizedBranding } from '@pactum/core-backend-types'
import { LoadingPage } from '@pages/LoadingPage'
import { useGetBrandingContentQuery, useGetBrandingQuery } from '@store/branding'
import { useGetUserOrganizationsQuery } from '@store/userOrganizations'
import { useCallback, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import { BrandingInnerContainer } from '../SettingsContainers'
import { BrandingContentForm } from './components/BrandingContentForm'
import { BrandingForm } from './components/BrandingForm'
import { BrandingPreview } from './components/BrandingPreview'
import { BrandingTab, BrandingTabs } from './components/Tabs'
import {
  BrandingContentPayload,
  brandingFormValuesWithDefaults,
  BrandingPayload,
  brandingSchema,
  brandingValidationSchema,
} from './types'

const PreviewContainer = styled('div')(() => ({
  position: 'sticky',
  top: '96px',
  margin: '0 auto',
  width: '100%',
  maxWidth: '544px',
  marginLeft: 'auto',
}))

const languageNames = new Intl.DisplayNames(['en'], {
  type: 'language',
})

const BrandingDetailsHeader = ({
  brandingId,
  branding,
}: {
  brandingId?: string
  branding?: Branding
}) => {
  const navigate = useNavigate()
  return (
    <Stack
      direction='row'
      alignItems='center'
      sx={(theme) => ({
        my: theme.spacing(4),
        ml: theme.spacing(1.4),
      })}
    >
      <IconButton onClick={() => navigate(-1)}>
        <BackArrowIcon fontSize='small' sx={{ color: 'black' }} />
      </IconButton>

      <Typography
        variant='h3'
        sx={(theme) => ({
          fontSize: theme.typography.pxToRem(32),
          fontWeight: 600,
        })}
      >
        {brandingId === 'new' ? 'Create Business Identity' : branding?.label}
      </Typography>
    </Stack>
  )
}

const allChatLocales = Object.values(SupportedChatLocale)
  .map((locale) => ({
    value: locale,
    label: languageNames.of(locale) || locale,
  }))
  .toSorted((a, b) => a.label.localeCompare(b.label))

export const BrandingDetails = ({ canManageConfig }: { canManageConfig: boolean }) => {
  const location = useLocation()
  const { orgTag, brandingId, locale } = useParams<{
    brandingId: string
    orgTag: string
    locale?: string
  }>()

  const isDraft = location.pathname.endsWith('/new')

  const navigate = useNavigate()
  const { showSuccess, showError } = useNotifications()

  const { data: organizations, isLoading: areOrganizationsLoading } = useGetUserOrganizationsQuery()
  const {
    data: branding,
    isLoading: isBrandingLoading,
    error: brandingLoadingError,
  } = useGetBrandingQuery(brandingId as string, {
    skip: brandingId === 'new',
  })

  const defaultBrandingContent = useGetBrandingContentQuery(
    {
      brandingId,
      locale: branding?.defaultLocale ?? locale,
    },
    {
      skip: !branding?.defaultLocale,
    },
  )
  const { data: brandingContent } = useGetBrandingContentQuery(
    { brandingId, locale },
    {
      skip: brandingId === 'new' || !locale || isDraft,
    },
  )
  const organization = useMemo(
    () => organizations?.find((o) => o.tag === orgTag),
    [organizations, orgTag],
  )

  const navigateToBranding = useCallback(
    (brandingId: string) =>
      navigate(
        generatePath('/:orgTag/settings/general/branding/:brandingId', {
          orgTag: orgTag as string,
          brandingId,
        }),
      ),
    [navigate, orgTag],
  )

  const isNew = branding === undefined || brandingId === 'new'

  const brandingContentToUse: LocalizedBranding | null | undefined = useMemo(() => {
    if (!locale) {
      return null
    }

    if (isDraft && defaultBrandingContent.data) {
      return defaultBrandingContent.data
    }

    if (brandingContent) {
      return brandingContent
    }

    return brandingFormValuesWithDefaults() as LocalizedBranding
  }, [brandingContent, defaultBrandingContent.data, isDraft, locale])

  const form = useForm<BrandingPayload & BrandingContentPayload>({
    resolver: zodResolver(isNew ? brandingSchema : brandingValidationSchema),
    values: brandingFormValuesWithDefaults(
      branding && isDraft
        ? {
            ...branding,
            ...defaultBrandingContent.data!,
          }
        : brandingContent
          ? {
              ...branding,
              ...brandingContent,
            }
          : undefined,
    ),
  })

  const navigateToLocale = useCallback(
    (locale: string) =>
      navigate(
        generatePath('/:orgTag/settings/general/branding/:brandingId/:locale', {
          orgTag: orgTag as string,
          brandingId: brandingId as string,
          locale,
        }),
        { replace: true },
      ),
    [navigate, orgTag, brandingId],
  )

  const createNewDraft = (locale: string) => {
    navigate(
      generatePath('/:orgTag/settings/general/branding/:brandingId/:locale/new', {
        orgTag: orgTag as string,
        brandingId: brandingId as string,
        locale,
      }),
      { replace: true },
    )
  }

  const clearNewDraft = () => {
    navigate(
      generatePath('/:orgTag/settings/general/branding/:brandingId', {
        orgTag: orgTag as string,
        brandingId: brandingId as string,
      }),
      { replace: true },
    )
  }

  useEffect(() => {
    if (!branding) {
      return
    }
    if (!locale && branding.supportedLocales[0]) {
      navigateToLocale(branding.supportedLocales[0])
    }
  }, [branding, locale, navigateToLocale])

  if (isBrandingLoading || areOrganizationsLoading) {
    return <LoadingPage />
  }

  if (brandingLoadingError) {
    return (
      <>
        <BrandingDetailsHeader brandingId={brandingId as string} branding={branding} />
        <Alert severity='error'>Business identity not found.</Alert>
      </>
    )
  }

  return (
    <>
      <BrandingDetailsHeader brandingId={brandingId as string} branding={branding} />

      <BrandingInnerContainer>
        <FormProvider {...form}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} lg={4}>
              <BrandingForm
                organizationId={organization?.id as string}
                disabled={!canManageConfig}
                existingBranding={branding}
                onBrandingSaved={(branding) => {
                  if (brandingId === 'new') {
                    navigateToBranding(branding.uuid)
                  }

                  showSuccess(`Business identity ${branding.label} saved`)
                }}
                onError={() => showError('Failed to save business identity')}
              />

              {brandingId !== 'new' && (
                <BrandingTabs
                  aria-label='Navigation'
                  sx={({ spacing }) => ({
                    my: spacing(2),
                  })}
                  locales={allChatLocales.map((locale) => ({
                    ...locale,
                    supported: branding?.supportedLocales.includes(locale.value) ?? false,
                  }))}
                  onAddLocale={createNewDraft}
                  disabled={!canManageConfig}
                  selectedLocalesCount={isDraft ? 1 : (branding?.supportedLocales.length ?? 0)}
                >
                  {isDraft && (
                    <BrandingTab
                      to={`/${orgTag}/settings/general/branding/${brandingId}/${locale}/new`}
                    >
                      {locale && languageNames.of(locale)?.split(' (')[0]} (draft)
                    </BrandingTab>
                  )}
                  {branding?.supportedLocales.map((supportedLocale) =>
                    supportedLocale ? (
                      <BrandingTab
                        key={supportedLocale}
                        to={`/${orgTag}/settings/general/branding/${brandingId}/${supportedLocale}`}
                      >
                        {languageNames.of(supportedLocale)?.split(' (')[0]}
                      </BrandingTab>
                    ) : null,
                  )}
                </BrandingTabs>
              )}

              {brandingContentToUse && (
                <BrandingContentForm
                  key={brandingContentToUse?.contentId}
                  disabled={!canManageConfig}
                  brandingId={brandingId as string}
                  locale={locale}
                  existingBrandingContent={brandingContentToUse}
                  onBrandingContentSaved={() => {
                    navigateToLocale(locale as string)
                    showSuccess('Business identity content saved')
                  }}
                  onBrandingContentDeleted={(deletedLocale) => {
                    const firstExistingLocale = branding?.supportedLocales.find(
                      (l) => l !== deletedLocale,
                    )
                    firstExistingLocale
                      ? navigateToLocale(firstExistingLocale)
                      : navigateToBranding(brandingId as string)
                    showSuccess('Business identity content removed')
                  }}
                  onError={() => showError('Failed to save business identity')}
                />
              )}

              {isDraft && (
                <Box sx={{ my: 2, display: 'flex' }}>
                  <Button
                    onClick={clearNewDraft}
                    variant='contained'
                    color='secondary'
                    sx={{ ml: 'auto' }}
                  >
                    Discard draft
                  </Button>
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sm={6} lg={8} sx={{ pr: 4 }}>
              <PreviewContainer>
                <BrandingPreview />
              </PreviewContainer>
            </Grid>
          </Grid>
        </FormProvider>
      </BrandingInnerContainer>
    </>
  )
}
