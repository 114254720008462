import { DataGrid, dataGridProps } from '@components/table'
import { PaginationFooter, ROWS_PER_PAGE_OPTIONS } from '@components/table/pagination'
import { useGetUnitListQuery } from '@logistics/store/unitApi'
import { Box, Button, Stack, styled, Typography } from '@mui/material'
import { DataGridPro, GridPaginationModel } from '@mui/x-data-grid-pro'
import { useFormatter } from '@shared/hooks'
import { useActiveProject } from '@shared/hooks/useActiveProject'
import { useMemo, useState } from 'react'
import { NoNegotiations } from './NoNegotiations'
import { createColumns } from './columns'
import { SpotLoadUnit } from '@logistics/pages/spotLoad/types'

const SMALL_PAGE_SIZE = 3
const FIRST_PAGE = 0

interface UnitHistoryProps {
  supplierExternalId: string
}

export const UnitHistory = ({ supplierExternalId }: UnitHistoryProps) => {
  const { activeProjectTag } = useActiveProject()
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: SMALL_PAGE_SIZE,
  })

  function toggleShowAll(): void {
    setPaginationModel({
      pageSize: showingPagination ? SMALL_PAGE_SIZE : ROWS_PER_PAGE_OPTIONS[0],
      page: FIRST_PAGE,
    })
  }

  const showingPagination = useMemo(
    () => paginationModel.pageSize !== SMALL_PAGE_SIZE,
    [paginationModel.pageSize],
  )

  // TODO: uses spot load specific endpoint, needs generalising
  const {
    data: unitData,
    isFetching,
    isLoading,
  } = useGetUnitListQuery({
    projectTag: activeProjectTag,
    skip: paginationModel.page * paginationModel.pageSize,
    limit: paginationModel.pageSize,
    supplierExternalId: supplierExternalId,
  })

  const formatter = useFormatter()
  const columns = useMemo(
    () => createColumns(formatter, supplierExternalId),
    [formatter, supplierExternalId],
  )

  if (!isLoading && unitData?.totalItems === 0) {
    return <NoNegotiations />
  }

  return (
    <Stack spacing={1}>
      <Typography variant='h3' component='h3'>
        Negotiation history
      </Typography>

      <StyledDataGrid
        {...dataGridProps}
        slots={{
          ...dataGridProps.slots,
          pagination: () => PaginationFooter({ rowsPerPageOptions: [3, ...ROWS_PER_PAGE_OPTIONS] }),
        }}
        pagination={showingPagination}
        hideFooter={false}
        paginationMode='server'
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        columns={columns}
        loading={isFetching}
        rowCount={unitData?.totalItems ?? 0}
        rows={unitData?.data ?? []}
        getRowId={(row: SpotLoadUnit) => row.unit_id}
      />

      {unitData && unitData.totalItems > SMALL_PAGE_SIZE && (
        <Box>
          <Button variant='outlined' size='small' color='tertiary' onClick={toggleShowAll}>
            {showingPagination ? 'Show less' : 'Show more'}
          </Button>
        </Box>
      )}
    </Stack>
  )
}

const StyledDataGrid = styled(DataGrid)(() => ({
  '& .MuiDataGrid-row:hover': {
    backgroundColor: 'unset',
    cursor: 'unset',
  },
})) as typeof DataGridPro
